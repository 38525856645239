import React, { useEffect, useState } from "react";
import NewCannedMessage from "../../../../src/components/common/Templates/NewCannedMessage";
import * as eventEmitter from "../../../utils/eventEmitter";
import { MenuIcon, PanelTitle } from "../../common";
import { X } from "react-feather";

const NewCannedMessageWrapper = props => {

    const [cannedMessage, setNewCannedMessage] = useState({ isActive: false, isUpdate: false, updateData: null });

    const closeNewMessagePrompt = () => {
        setNewCannedMessage({
            isActive: false,
            isUpdate: false,
            updateData: null
        })
    };

    const openNewMessagePrompt = (data) => {
        setNewCannedMessage({
            isActive: true,
            isUpdate: data.isUpdate,
            updateData: data.updateData
        })
    };

    useEffect(() => {
        eventEmitter.subscribeToEvents("open_new_canned_editor", ({ isUpdate, updateData }) => {

            openNewMessagePrompt({ isUpdate, updateData });

        }, "new_canned_message_wrapper");

    }, []);

    return (
        <>
            {cannedMessage.isActive &&
                <div className={'new-canned-message-wrapper'}>
                    <div className={'new-canned-message-inner-wrp'}>
                        <div className={'modal-header d-flex'}>
                            <PanelTitle title={cannedMessage.isUpdate ? 'Update Canned Message' : 'Create Canned Message'} />
                            <MenuIcon icon={<X />}
                                onClick={closeNewMessagePrompt}
                                key={'esc'}
                                size={'sm'} />
                        </div>

                        <div className={'modal-body-wrp'}>
                            <NewCannedMessage isUpdate={cannedMessage.isUpdate} updateData={cannedMessage.updateData} />
                        </div>
                    </div>
                </div>
            }


        </>
    );
};

export default NewCannedMessageWrapper;
