import LiveChatAvFactoryE6 from "../../../../lib/livechatVideo/livechat_video_debug";
import sdkHandler from "../../../../lib";
import uuid from "uuid/v1";
import * as utility from './utility';
import { virtualBackgroundStream ,RemoveVirtualBackgroundStreams} from '../../../common/VirtualBackground/StreamUtility';

/* #region  ---------------------------- Make Call*/

/* #endregion  ---------------------------- Make Call*/

const _sdkUtil = {};
const callType = Object.freeze({
  Video: "VIDEO_CALL",
  Audio: "AUDIO_CALL",
  ScreenShare: "SCREEN_SHARING",
  ScreenShareWithCall: "SCREEN_SHARING_CALL",
  ScreenShareIncoming: "SCREEN_SHARING_INCOMING",
});


Object.defineProperties(_sdkUtil, {
  liveChatAvFactoryE6: {
    value: null,
    writable: true,
  },
  avSdk: {
    value: null,
    writable: true,
  },
  callEvents: {
    value: {},
    writable: true,
  },
  bandwidth: {
    value: null,
    writable: true,
  },
  callType: {
    value: "",
    writable: true,
  },
  userIds: {
    value: null,
    writable: true,
  },
  acceptObject: {
    value: null,
    writable: true,
  },
  screenShareAcceptObject: {
    value: null,
    writable: true,
  },
  sessionId: {
    value: null,
    writable: true,
  },
  callDirection: {
    value: null,
    writable: true,
  },
  callId: {
    value: null,
    writable: true,
  },
  validateEvents: {
    value: true,
    writable: true,
  },
  activeSpeaker: {
    value: null,
    writable: true,
  },
  activeSpeakerTimestamp: {
    value: Date.now(),
    writable: true,
  },
  backendImage: {
    value: null,
    writable: true,
  },
  mediaStream: {
    value: null,
    writable: true,
  },
});

class PhoneEventHandler {
  constructor() {}

  _callbackSupport = {
    removeStream: async () => {
      _sdkUtil.callId = null;
      _sdkUtil.callType = null;
      console.log("PhoneSDK", "Callback", "removeStream", "removeStream");
    },
  };

  phone_sdk_logger = {
    warn : (sessionId , function_name,message, data)=>{
      try {
        console.warn(`PhoneSDKLogs [${sessionId}] PhoneSDK ${function_name} ${message} ${data}`);
      } catch (error) {
        console.error("PhoneSDK", "phone_sdk_logger", "warn", error);
      }
    }
  };

  Initialize = (
    token,
    webSocketUrl,
    callEventParams,
    socketEvt,
    bandwidth,
    phoneConfig
  ) => {
    try {
    console.log("PhoneSDK" , "initialize","start phone initialization" );
    _sdkUtil.callEvents = callEventParams;
    _sdkUtil.bandwidth = bandwidth;
    _sdkUtil.validateEvents = phoneConfig.validateEvents;

    console.log("PhoneSDK" , "initialize","liveChatAvFactoryE6" ,"initializing");
    _sdkUtil.liveChatAvFactoryE6 = new LiveChatAvFactoryE6();

    console.log("PhoneSDK" , "initialize","initiateSignaling" ,"initializing");
    _sdkUtil.avSdk = sdkHandler.initiateSignaling(_sdkUtil.liveChatAvFactoryE6,phoneConfig.basic_config.sdk.overrideLogs);

    console.log("PhoneSDK" , "initialize","config" ,"initializing");

    var config = {
      MediaTransport: phoneConfig.MediaTransport,
      MediaCapture: phoneConfig.MediaCapture,
      Signaling: phoneConfig.Signaling,
      Network: phoneConfig.Network,
      Display: {
        // Triggered when call is connected
        // Both variables remoteStreams and localStream are Arrays of MediaStream objects
        // remoteUserInfo - map streamId -> { userId, streamType, ... }
        OnConnected: (
          remoteStreams,
          localStreams,
          remoteUserInfo,
          callId = null
        ) => {
          try {

            this.phone_sdk_logger.warn(_sdkUtil.sessionId,"OnConnected",'---------- OnConnected -----------', `callId : ${callId} ,remoteStreams length : ${ remoteStreams ? remoteStreams.length : 'null'}, remoteUserInfo : ${remoteUserInfo}`);

            console.log(
              "PhoneSDK",
              "Callback",`sessionId : ${_sdkUtil.sessionId}`, `callId : ${callId}`,
              "OnConnected",
              "bind local and remote streams"
            );
            if (
              _sdkUtil.validateEvents &&
              callId !== null &&
              _sdkUtil.callId !== callId
            ) {
              console.error(
                "PhoneSDK",
                "Callback",
                "OnConnected",
                `Event Fired In Invalid State. _sdkUtil.callId:${_sdkUtil.callId}, callId:${callId}`
              );
              return;
            }

            switch (_sdkUtil.callDirection) {
              case "inbound":
                if (_sdkUtil.callEvents["AnsweredIncomingCall"]) {
                  _sdkUtil.callEvents["AnsweredIncomingCall"](
                    true,
                    localStreams[0],
                    remoteStreams,
                    remoteUserInfo
                  );
                }
                break;
              case "outbound":
                if (_sdkUtil.callEvents["OnRemoteAnswer"]) {
                  _sdkUtil.callEvents["OnRemoteAnswer"](
                    remoteUserInfo,
                    localStreams[0],
                    remoteStreams
                  );
                }
                break;
              default:
              // code block
            }
          } catch (ex) {
            console.error("OnConnected", ex);
          }
        },
        // Triggered when call is ended
        // 0 - hangup, 1 - connection_error, 2 - call_disconnect 4 - HANGUP_CONTINUE_INTERACTION 5 -CALL_REJECTED_CONTINUE_INTERACTION
        OnDisconnected: (reasonCode, callId = null) => {
          try {
            this.phone_sdk_logger.warn(_sdkUtil.sessionId,"OnDisconnected",'---------- OnDisconnected -----------', `callId : ${callId},  reasonCode: ${reasonCode}`);
            console.log(
              "PhoneSDK",
              "Callback",`sessionId : ${_sdkUtil.sessionId}`, `callId : ${callId}`,
              "OnDisconnected",
              `reasonCode : ${reasonCode} , callType : ${_sdkUtil.callType}`
            );
            if (
              _sdkUtil.validateEvents &&
              callId !== null &&
              _sdkUtil.callId !== callId
            ) {
              console.error(
                "PhoneSDK",
                "Callback",
                "OnDisconnected",
                `Event Fired In Invalid State. _sdkUtil.callId:${_sdkUtil.callId}, callId:${callId}`
              );
              return;
            }
            _sdkUtil.callId = null;
            _sdkUtil.callType = null;
            _sdkUtil.activeSpeaker = null;
            switch (reasonCode) {
              case 0:
              case 3:
              case 4:
              case 5:
                //if (_sdkUtil.sessionId !== event.session_id) return;
                if (_sdkUtil.callEvents["RemoteHangup"])
                  _sdkUtil.callEvents["RemoteHangup"](reasonCode);
                this._callbackSupport.removeStream();
                break;
              case 1:
                if (_sdkUtil.callEvents["onConnectionError"]) {
                  _sdkUtil.callEvents["onConnectionError"](reasonCode);
                }
                break;
              case "Max Allowed Session Exceeded":
              case "LICENSE_VALIDATION_FAILED_MAX_SESSION_REACHED":
                if (_sdkUtil.callEvents["RemoteHangup"])
                  _sdkUtil.callEvents["RemoteHangup"](reasonCode);
                if (_sdkUtil.callEvents["onSessionExceededError"]) {
                  _sdkUtil.callEvents["onSessionExceededError"](
                    "Max concurrent call sessions allowed for your license reached"
                  );
                }

                this._callbackSupport.removeStream();
                break;
              default:
            }
          } catch (ex) {
            console.error("OnDisconnected", ex);
          }
        },

        // Triggered when call put on hold/unhold by this end
        OnLocalHold: (callId = null) => {
          this.phone_sdk_logger.warn(_sdkUtil.sessionId,"OnLocalHold",'---------- OnLocalHold -----------', `callId : ${callId}`);
          console.log("PhoneSDK", "Callback",`sessionId : ${_sdkUtil.sessionId}`, `callId : ${callId}`, "OnLocalHold", "OnLocalHold");
          if (
            _sdkUtil.validateEvents &&
            callId !== null &&
            _sdkUtil.callId !== callId
          ) {
            console.error(
              "PhoneSDK",
              "Callback",
              "OnLocalHold",
              `Event Fired In Invalid State. _sdkUtil.callId:${_sdkUtil.callId}, callId:${callId}`
            );
            return;
          }
        },
        OnLocalUnhold: (callId = null) => {
          this.phone_sdk_logger.warn(_sdkUtil.sessionId,"OnLocalUnhold",'---------- OnLocalUnhold -----------', `callId : ${callId}`);
          console.log("PhoneSDK", "Callback",`sessionId : ${_sdkUtil.sessionId}`, `callId : ${callId}`, "OnLocalUnhold", `OnLocalUnhold`);
          if (
            _sdkUtil.validateEvents &&
            callId !== null &&
            _sdkUtil.callId !== callId
          ) {
            console.error(
              "PhoneSDK",
              "Callback",
              "OnLocalUnhold",
              `Event Fired In Invalid State. _sdkUtil.callId:${_sdkUtil.callId}, callId:${callId}`
            );
            return;
          }
        },

        // Triggered when call put on hold/unhold by remote end
        OnRemoteHold: (callId = null) => {
          this.phone_sdk_logger.warn(_sdkUtil.sessionId,"OnRemoteHold",'---------- OnRemoteHold -----------', `callId : ${callId}`);
          console.log("PhoneSDK", "Callback",`sessionId : ${_sdkUtil.sessionId}`, `callId : ${callId}`, "OnRemoteHold", `OnRemoteHold`);
          if (
            _sdkUtil.validateEvents &&
            callId !== null &&
            _sdkUtil.callId !== callId
          ) {
            console.error(
              "PhoneSDK",
              "Callback",
              "OnRemoteHold",
              `Event Fired In Invalid State. _sdkUtil.callId:${_sdkUtil.callId}, callId:${callId}`
            );
            return;
          }
        },
        OnRemoteUnhold: (callId = null) => {
          this.phone_sdk_logger.warn(_sdkUtil.sessionId,"OnRemoteUnhold",'---------- OnRemoteUnhold -----------', `callId : ${callId}`);
          console.log(
            "PhoneSDK",
            "Callback",`sessionId : ${_sdkUtil.sessionId}`, `callId : ${callId}`,
            "OnRemoteUnhold",
            `OnRemoteUnhold`
          );
          if (
            _sdkUtil.validateEvents &&
            callId !== null &&
            _sdkUtil.callId !== callId
          ) {
            console.error(
              "PhoneSDK",
              "Callback",
              "OnRemoteUnhold",
              `Event Fired In Invalid State. _sdkUtil.callId:${_sdkUtil.callId}, callId:${callId}`
            );
            return;
          }
        },

        // Triggered when a error occurs causing the call to fail. The errorCode may be
        // one of the LiveChatAvErrorCodes
        // add calee_offline, call_not_answered, rejected, canceled to LiveChatAvErrorCodes
        OnFail: (errorCode, callId, isScreenshare) => {
          try {
            this.phone_sdk_logger.warn(_sdkUtil.sessionId,"OnFail",'---------- OnFail -----------', `callId : ${callId} ,errorCode: ${errorCode} , isScreenshare:${isScreenshare}`);
            console.log(
              "PhoneSDK",
              "Callback",`sessionId : ${_sdkUtil.sessionId}`, `callId : ${callId}`,
              "OnFail",
              `errorCode: ${errorCode} , callId: ${callId}, isScreenshare:${isScreenshare}`
            );
            if (
              _sdkUtil.validateEvents &&
              callId !== null &&
              _sdkUtil.callId !== callId
            ) {
              console.error(
                "PhoneSDK",
                "Callback",
                "OnFail",
                `Event Fired In Invalid State. _sdkUtil.callId:${_sdkUtil.callId}, callId:${callId}`
              );
              return;
            }
           
            switch (errorCode) {
              case "calee_offline":
              case 2000:
                //if (_sdkUtil.sessionId !== event.session_id) return;
                if (_sdkUtil.callEvents["onCalleeOffline"])
                  _sdkUtil.callEvents["onCalleeOffline"]();
                this._callbackSupport.removeStream();
                break;
              case 2001:
              case "call_not_answered":
                //if (_sdkUtil.sessionId !== event.session_id) return;
                if (_sdkUtil.callEvents["OnCallNotAnswered"])
                  _sdkUtil.callEvents["OnCallNotAnswered"]();
                this._callbackSupport.removeStream();
                break;
              case 2002:
              case "CALL_REJECTED":
              case "rejected":
                //if (_sdkUtil.sessionId !== event.session_id) return;
                if (_sdkUtil.callEvents["OnCallRemoteReject"])
                  _sdkUtil.callEvents["OnCallRemoteReject"]();
                this._callbackSupport.removeStream();
                break;
              case 2003:
              case 2004:
              case "CALL_CANCELED":
              case "canceled":
                //if (_sdkUtil.sessionId !== event.session_id) return;
                if (_sdkUtil.callEvents["RemoteHangup"])
                  _sdkUtil.callEvents["RemoteHangup"]();
                this._callbackSupport.removeStream();
                break;

              case 3000:              
              case 3003:
              case 3004:
              case 3005:
              case 3006:
              case 3007:
              case 3008:
              case 3009:
              case "SYSTEM_Error":
              case "SYSTEM_ERROR":
              case "SCREENSHARE_ERROR":
              case 1000:
              case "AUDIO_VIDEO_CAPTURE_ERROR":
              case 1001:
                if (isScreenshare) {                  
                  if (_sdkUtil.callEvents["onScreenShareError"])
                    _sdkUtil.callEvents["onScreenShareError"](
                      _sdkUtil.callType !== callType.ScreenShare,
                      errorCode,
                      callId
                    );
                } else {
                  if (_sdkUtil.callEvents["onError"])
                    _sdkUtil.callEvents["onError"](errorCode, callId);
                  this._callbackSupport.removeStream();
                }

                break;
              case 4003:
              case "4003":
              case "Max Allowed Session Exceeded":
              case "LICENSE_VALIDATION_FAILED_MAX_SESSION_REACHED":
              case 3025:
                if (_sdkUtil.callEvents["onError"])
                  _sdkUtil.callEvents["onError"](errorCode, callId);
                this._callbackSupport.removeStream();
                break;
              case 3001:
              case 4005:
                if (isScreenshare) {
                    if (_sdkUtil.callEvents["onScreenShareRequestRejected"])
                      _sdkUtil.callEvents["onScreenShareRequestRejected"](
                        _sdkUtil.callType !== callType.ScreenShare,
                        errorCode,
                        callId
                      );
                }else{
                  if (_sdkUtil.callEvents["onError"])
                    _sdkUtil.callEvents["onError"](errorCode, callId);
                }
                break;
              default: // case 3010:
            }
          } catch (ex) {
            console.error( "PhoneSDK", "Callback","OnFail", ex);
          }
        },

        OnRemoteRinging: (userId, callId = null) => {
          try {
            this.phone_sdk_logger.warn(_sdkUtil.sessionId,"OnRemoteRinging",'---------- OnRemoteRinging -----------', `callId : ${callId} , userId: ${userId}`);
            console.log("PhoneSDK", "Callback",`sessionId : ${_sdkUtil.sessionId}`, `callId : ${callId}`, "OnRemoteRinging", `${userId}`);
            if (
              _sdkUtil.validateEvents &&
              callId !== null &&
              _sdkUtil.callId !== null
            ) {
              console.error(
                "PhoneSDK",
                "Callback",
                "OnRemoteRinging",
                `Event Fired In Invalid State. _sdkUtil.callId:${_sdkUtil.callId}, callId:${callId}`
              );
              return;
            }
            _sdkUtil.callId = callId;
            if (_sdkUtil.callEvents["OnRemoteRinging"]) {
              _sdkUtil.callEvents["OnRemoteRinging"]();
            }
          } catch (ex) {
            console.error("OnRemoteRinging", ex);
          }
        },

        // acceptObject is a LiveChatAvAcceptInterface instance that user can use to
        // accept/reject a call.
        OnIncomingCall: (userIds, acceptObject, callId = null) => {
          try {
            _sdkUtil.sessionId = uuid();
            console.group( `%c Inbound Session ID : ${_sdkUtil.sessionId} ,OnIncomingCall ,callId : ${callId} , Active Callee :  ${userIds}`, "color:#FF00FF; font-family:'Ubuntu'; display: block;font-weight:bold; font-size:18px;background: #34eb4f;" );
            
            this.phone_sdk_logger.warn(_sdkUtil.sessionId,"OnIncomingCall",'---------- OnIncomingCall -----------', `callId : ${callId} , userIds: ${userIds}, Inbound SessionID : ${_sdkUtil.sessionId}`);
            
            console.log("PhoneSDK", "Callback",`sessionId : ${_sdkUtil.sessionId}`, `callId : ${callId}`, "OnIncomingCall", `${userIds}`);
            if (
              _sdkUtil.validateEvents &&
              callId !== null &&
              _sdkUtil.callId !== null
            ) {
              console.error(
                "PhoneSDK",
                "Callback",
                "OnIncomingCall",
                `Event Fired In Invalid State.call rejected. _sdkUtil.callId:${_sdkUtil.callId}, callId:${callId}`
              );
              acceptObject.reject();
              return;
            }
            _sdkUtil.activeSpeaker = null;
            _sdkUtil.callId = callId;            
            _sdkUtil.acceptObject = acceptObject;
            _sdkUtil.callDirection = "inbound";
            switch (acceptObject.type()) {
              case "AUDIO":
              case 1:
                _sdkUtil.callType = callType.Audio;
                break;
              case "VIDEO":
              case 2:
                _sdkUtil.callType = callType.Video;
                break;
              case "SCREEN":
              case 3:
                _sdkUtil.callType = callType.ScreenShare;
                break;

              default:
                _sdkUtil.callType = callType.Video;
            }

            //  do this for temporally to support old and new call SDK. later we can remove above switch case
            if(acceptObject.offering instanceof Function && acceptObject.offering()){
              switch (acceptObject.offering()) {
                case "AUDIO":
                case "audio":
                case 1:
                  _sdkUtil.callType = callType.Audio;
                  break;
                case "VIDEO":
                case "video":
                case 2:
                  _sdkUtil.callType = callType.Video;
                  break;
                case "SCREEN":
                case "screenshare":
                case 3:
                  _sdkUtil.callType = callType.ScreenShare;
                  break;
  
                default:
                  _sdkUtil.callType = callType.Video;
              }
            }

            const event = {
              callee: userIds[0],
              sessionId: _sdkUtil.sessionId,
              callType: _sdkUtil.callType,
            };
            if (_sdkUtil.callEvents["OnIncomingCall"])
              _sdkUtil.callEvents["OnIncomingCall"](event);
          } catch (error) {
            console.error("PhoneSDK", "Callback", "OnIncomingCall", error);
          }
        },

        OnSignalStrengthChange: (strength, callId = null) => {
          /* console.log(
            "PhoneSDK",
            "Callback",
            "OnSignalStrengthChange",
            `${strength}`
          ); */
          if (_sdkUtil.callEvents["onSlowLink"]) {
            _sdkUtil.callEvents["onSlowLink"](strength);
          }
        },
        OnStats: async (statsReport, callId) => {
          /* console.log(
            "PhoneSDK",
            "Callback",
            "OnRemoteUnmute",
            `callId:${callId}`
          ); */
          /*  const diffStats = await this.tfiStatsCollector.pushStats(statsReport);

          let strength = diffStats.audio.local.mos;
          if (_sdkUtil.callType !== callType.Audio && diffStats.video.local.mos> 0) {
            strength =
              (diffStats.audio.local.mos + diffStats.video.local.mos) / 2;
          }
          if (_sdkUtil.callEvents["onSlowLink"]) {
            _sdkUtil.callEvents["onSlowLink"](strength);
          } */
        },
        // deprecated: Triggered when call is put on hold by the remote end
        OnHold: (callId = null) => {
          
          this.phone_sdk_logger.warn(_sdkUtil.sessionId,"OnHold",'---------- OnHold -----------', `callId : ${callId} `);
          console.log("PhoneSDK", "Callback",`sessionId : ${_sdkUtil.sessionId}`, `callId : ${callId}`, "OnHold", `OnHold`);
          if (
            _sdkUtil.validateEvents &&
            callId !== null &&
            _sdkUtil.callId !== callId
          ) {
            console.error(
              "PhoneSDK",
              "Callback",
              "OnHold",
              `Event Fired In Invalid State. _sdkUtil.callId:${_sdkUtil.callId}, callId:${callId}`
            );
            return;
          }
        },

        // deprecated: Triggered when previously hold call is put on unhold by the remote end
        OnUnhold: (callId = null) => {
          
          this.phone_sdk_logger.warn(_sdkUtil.sessionId,"OnUnhold",'---------- OnUnhold -----------', `callId : ${callId} `);
          console.log("PhoneSDK", "Callback",`sessionId : ${_sdkUtil.sessionId}`, `callId : ${callId}`, "OnUnhold", `OnUnhold`);
          if (
            _sdkUtil.validateEvents &&
            callId !== null &&
            _sdkUtil.callId !== callId
          ) {
            console.error(
              "PhoneSDK",
              "Callback",
              "OnUnhold",
              `Event Fired In Invalid State. _sdkUtil.callId:${_sdkUtil.callId}, callId:${callId}`
            );
            return;
          }
        },
        OnUserJoining: (userId, callId = null) => {
          this.phone_sdk_logger.warn(_sdkUtil.sessionId,"OnUserJoining",'---------- OnUserJoining -----------', `callId : ${callId} , userId : ${userId}`);
          console.log(
            "PhoneSDK",
            "Callback",`sessionId : ${_sdkUtil.sessionId}`, `callId : ${callId}`,
            "OnUserJoining",
            `userId : ${userId} , callId : ${callId}`
          );
          if (
            _sdkUtil.validateEvents &&
            callId !== null &&
            _sdkUtil.callId !== callId
          ) {
            console.error(
              "PhoneSDK",
              "Callback",
              "OnUserJoining",
              `Event Fired In Invalid State. _sdkUtil.callId:${_sdkUtil.callId}, callId:${callId}`
            );
            return;
          }
          if (_sdkUtil.callEvents["OnUserJoining"]) {
            _sdkUtil.callEvents["OnUserJoining"](
              userId.replace("user:", ""),
              callId
            );
          }
        },
        OnUserLeft: (userId, reason, callId = null) => {
          
          this.phone_sdk_logger.warn(_sdkUtil.sessionId,"OnUserLeft",'---------- OnUserLeft -----------', `callId : ${callId} , userId : ${userId}`);
          console.log(
            "PhoneSDK",
            "Callback",`sessionId : ${_sdkUtil.sessionId}`, `callId : ${callId}`,
            "OnUserLeft",
            `userId : ${userId} , callId : ${callId} , reason : ${reason}`
          );
          if (
            _sdkUtil.validateEvents &&
            callId !== null &&
            _sdkUtil.callId !== callId
          ) {
            console.error(
              "PhoneSDK",
              "Callback",
              "OnUserLeft",
              `Event Fired In Invalid State. _sdkUtil.callId:${_sdkUtil.callId}, callId:${callId}`
            );
            return;
          }
          if (_sdkUtil.callEvents["OnUserLeft"]) {
            _sdkUtil.callEvents["OnUserLeft"](
              userId.replace("user:", ""),
              callId
            );
          }
        },
        OnStreamsChanged: (
          remoteStreams,
          localStreams,
          remoteUserInfo,
          callId = null
        ) => {
          
          this.phone_sdk_logger.warn(_sdkUtil.sessionId,"OnStreamsChanged",'---------- OnStreamsChanged -----------', `callId : ${callId} , remoteStreams length : ${ remoteStreams ? remoteStreams.length : 'null'},localStreams : ${localStreams ? localStreams.length : 'null'}, remoteUserInfo : ${remoteUserInfo}`);
          if (
            _sdkUtil.validateEvents &&
            callId !== null &&
            _sdkUtil.callId !== callId
          ) {
            console.error(
              "PhoneSDK",
              "Callback",
              "OnStreamsChanged",
              `Event Fired In Invalid State. _sdkUtil.callId:${_sdkUtil.callId}, callId:${callId}`
            );
            return;
          }
          if (remoteStreams === null || remoteStreams.length === 0) {
            console.error(
              "PhoneSDK",
              "Callback",
              "OnStreamsChanged",
              `No Streams found. _sdkUtil.callId:${_sdkUtil.callId}, callId:${callId}`
            );
            return;
          }
          let upgradeToVideo = false;
          if (
            _sdkUtil.callEvents["OnUpgradeToVideo"] &&
            _sdkUtil.callType === callType.Audio
          ) {
            try {
              if (
                remoteStreams &&
                remoteStreams[0] &&
                remoteUserInfo[remoteStreams[0].id] &&
                remoteUserInfo[remoteStreams[0].id].streamType === "video"
              ) {
                console.log("Phone SDK", "OnUpgradeToVideo...");
                upgradeToVideo = true;
                _sdkUtil.callEvents["OnUpgradeToVideo"]();
              }
            } catch (error) {
              console.error("OnUpgradeToVideo", error);
            }
          }
          if (_sdkUtil.callEvents["OnStreamsChanged"]) {
            _sdkUtil.callEvents["OnStreamsChanged"](
              remoteStreams,
              localStreams,
              remoteUserInfo,
              callId,
              upgradeToVideo // _sdkUtil.callType === callType.Audio
            );
          }
        },
        OnIncomingScreenshare: (userIds, acceptObject, callId) => {
          try {
            _sdkUtil.sessionId = uuid();
            console.group( `%c Inbound Session ID : ${_sdkUtil.sessionId} ,OnIncomingScreenshare ,callId : ${callId} , Active Callee :  ${userIds}`, "color:#FF00FF; font-family:'Ubuntu'; display: block;font-weight:bold; font-size:18px;background: #34eb4f;" );
           
            this.phone_sdk_logger.warn(_sdkUtil.sessionId,"OnIncomingScreenshare",'---------- OnIncomingScreenshare -----------', `callId : ${callId} , userIds : ${userIds} Inbound SessionID : ${_sdkUtil.sessionId}`);
            console.log("PhoneSDK", "Callback", "OnIncomingScreenshare", `${userIds}`);
            if (_sdkUtil.validateEvents && callId !== null && _sdkUtil.callId !== null) {
              console.error( "PhoneSDK", "Callback", "OnIncomingScreenshare", `Event Fired In Invalid State.call rejected. _sdkUtil.callId:${_sdkUtil.callId}, callId:${callId}`);
              acceptObject.reject();
              return;
            }

            if (_sdkUtil.callId === callId && _sdkUtil.callType !== callType.ScreenShare) {
              _sdkUtil.callType = callType.ScreenShareWithCall;
            }else{
              _sdkUtil.callType = callType.ScreenShare;
            }
            const event = {
              callee: userIds[0],
              sessionId: _sdkUtil.sessionId,
              callType: _sdkUtil.callType,
            };

            if (_sdkUtil.callType === callType.ScreenShareWithCall ) {              
              _sdkUtil.screenShareAcceptObject = acceptObject;
              if (_sdkUtil.callEvents["onScreenShareRequest"])
                _sdkUtil.callEvents["onScreenShareRequest"](event);
              return;
            }
            _sdkUtil.activeSpeaker = null;
            _sdkUtil.callId = callId;
            _sdkUtil.sessionId = uuid();            
            _sdkUtil.callDirection = "inbound";
            _sdkUtil.acceptObject = acceptObject;

            if (_sdkUtil.callEvents["OnIncomingCall"])
              _sdkUtil.callEvents["OnIncomingCall"](event);
              
          } catch (error) {
            console.error("PhoneSDK", "Callback", "OnIncomingScreenshare", error);
          }
        },
        OnScreenshareConnected: (
          remoteStreams,
          remoteUserInfo,
          callId = null
        ) => {
          
          this.phone_sdk_logger.warn(_sdkUtil.sessionId,"OnScreenshareConnected",'---------- OnScreenshareConnected -----------', `callId : ${callId} ,remoteUserInfo : ${remoteUserInfo} ,remoteStreams length : ${ remoteStreams ? remoteStreams.length : 'null'}`);
          if (
            _sdkUtil.validateEvents &&
            callId !== null &&
            _sdkUtil.callId !== null &&
            _sdkUtil.callId !== callId
          ) {
            console.error(
              "PhoneSDK",
              "Callback",
              "OnScreenshareConnected",
              `Event Fired In Invalid State. _sdkUtil.callId:${_sdkUtil.callId}, callId:${callId}`
            );
            return;
          }
          if (_sdkUtil.callId === callId && _sdkUtil.callType !== callType.ScreenShare) {
            _sdkUtil.callType = callType.ScreenShareWithCall;
          }
          _sdkUtil.callId = callId;
          if (
            _sdkUtil.callType === callType.Audio ||
            _sdkUtil.callType === callType.Video ||
            _sdkUtil.callType === callType.ScreenShareWithCall
          ) {
            if (_sdkUtil.callEvents["OnScreenShareCallConnected"]) {
              _sdkUtil.callEvents["OnScreenShareCallConnected"](
                remoteStreams,
                remoteUserInfo,
                callId
              );
            }
          } else {
            _sdkUtil.callType = callType.ScreenShare;
            if (_sdkUtil.callEvents["OnScreenShareConnected"]) {
              _sdkUtil.callEvents["OnScreenShareConnected"](
                remoteStreams,
                remoteUserInfo,
                callId
              );
            }
          }
        },
        OnScreenshareDisconnected: (reasonCode, callId = null) => {
          
          this.phone_sdk_logger.warn(_sdkUtil.sessionId,"OnScreenshareDisconnected",'---------- OnScreenshareDisconnected -----------', `callId : ${callId} ,reasonCode : ${reasonCode}`);
          if (
            _sdkUtil.validateEvents &&
            callId !== null &&
            _sdkUtil.callId !== callId
          ) {
            console.error(
              "PhoneSDK",
              "Callback",
              "OnScreenshareDisconnected",
              `Event Fired In Invalid State. _sdkUtil.callId:${_sdkUtil.callId}, callId:${callId}`
            );
            return;
          }

          if (_sdkUtil.callType === callType.ScreenShare) {
            _sdkUtil.callType = null;
            _sdkUtil.callId = null;
            if (_sdkUtil.callEvents["OnScreenShareDisconnected"]) {
              _sdkUtil.callEvents["OnScreenShareDisconnected"]();
            }
          } else {
            if (_sdkUtil.callEvents["OnScreenShareCallDisconnected"]) {
              _sdkUtil.callEvents["OnScreenShareCallDisconnected"]();
            }
          }
        },
        OnVoiceActivity: (userId, callId = null) => {
          /* console.log(
            "PhoneSDK",
            "Callback",
            "OnVoiceActivity",
            `userId : ${userId}`
          ); */
          if (
            _sdkUtil.validateEvents &&
            callId !== null &&
            _sdkUtil.callId !== callId
          ) {
            console.error(
              "PhoneSDK",
              "Callback",`sessionId : ${_sdkUtil.sessionId}`, `callId : ${callId}`,
              "OnVoiceActivity",
              `Event Fired In Invalid State. _sdkUtil.callId:${_sdkUtil.callId}, callId:${callId}`
            );
            return;
          }

          if (
            userId === "" ||
            userId === null ||
            userId === undefined ||
            callId === "" ||
            callId === null ||
            callId === undefined
          ) {
            /* console.log(
              "PhoneSDK",
              "Callback",
              "OnVoiceActivity",
              `No Active Speaker userId : ${userId} , callId:${callId}`
            ); */
            return;
          }

          const id = userId.replace("user:", "");
          if (
            _sdkUtil.activeSpeaker !== id ||
            _sdkUtil.activeSpeaker === null
          ) {
            const res =
              Math.abs(Date.now() - _sdkUtil.activeSpeakerTimestamp) / 1000;
            const seconds = res % 60;

            if (
              _sdkUtil.callEvents["OnVoiceActivity"] &&
              seconds > phoneConfig.VoiceActivity.EventTriggeredDuration
            ) {
              const previousUserId = _sdkUtil.activeSpeaker;
              _sdkUtil.activeSpeaker = id;
              _sdkUtil.activeSpeakerTimestamp = Date.now();
              _sdkUtil.callEvents["OnVoiceActivity"](userId, previousUserId);
            }
          }
        }, // evData - { event: name, data: { ... } }
        OnEvent: (evData) => {
          // console.log('PhoneSDK','Callback','OnEvent',`evData : ${JSON.stringify(evData)}`);
          try {
            /* console.log(
              "PhoneSDK",
              "Callback",
              "OnEvent",
              "UI-NOT-USE",
              `evData : ${JSON.stringify(evData)}`
            ); */
          } catch (error) {}
        },
        OnCallEvents: (userId, status, callId) => {
          try {
            
            this.phone_sdk_logger.warn(_sdkUtil.sessionId,"OnCallEvents",'---------- OnCallEvents -----------', `callId : ${callId} ,userId : ${userId}, status : ${status}`);
            if (
              _sdkUtil.validateEvents &&
              callId !== null &&
              _sdkUtil.callId !== callId
            ) {
              console.error(
                "PhoneSDK",
                "Callback",
                "OnCallEvents",
                `Event Fired In Invalid State. _sdkUtil.callId:${_sdkUtil.callId}, callId:${callId}`
              );
              return;
            }

            if (_sdkUtil.callEvents["OnSignalingCallEvents"]) {
              _sdkUtil.callEvents["OnSignalingCallEvents"](
                `SIGNALING${status}`,
                userId
              );
            }
          } catch (error) {
            console.error(
              "PhoneSDK",
              "OnCallEvents",
              "OnCallEvents",
              `userId:${userId}, status: ${status} , callId :${callId}`,
              error
            );
          }
        },
        OnRemoteMute: (userId, type, callId) => {
          this.phone_sdk_logger.warn(_sdkUtil.sessionId,"OnRemoteMute",'---------- OnRemoteMute -----------', `callId : ${callId} ,userId : ${userId}, type : ${type}`);
          console.log(
            "PhoneSDK",
            "Callback",`sessionId : ${_sdkUtil.sessionId}`, `callId : ${callId}`,
            "OnRemoteMute",
            `userId:${userId}, type:${type}, callId:${callId}`
          );
          if (
            _sdkUtil.validateEvents &&
            callId !== null &&
            _sdkUtil.callId !== callId
          ) {
            console.error(
              "PhoneSDK",
              "Callback",
              "OnRemoteMute",
              `Event Fired In Invalid State. _sdkUtil.callId:${_sdkUtil.callId}, callId:${callId}`
            );
            return;
          }
          if (_sdkUtil.callEvents["OnRemoteMuteUnmute"]) {
            _sdkUtil.callEvents["OnRemoteMuteUnmute"](
              true,
              userId,
              type,
              callId
            );
          }
        },
        OnRemoteUnmute: (userId, type, callId) => {
          
          this.phone_sdk_logger.warn(_sdkUtil.sessionId,"OnRemoteUnmute",'---------- OnRemoteUnmute -----------', `callId : ${callId} ,userId : ${userId}, type : ${type}`);
          console.log(
            "PhoneSDK",
            "Callback",`sessionId : ${_sdkUtil.sessionId}`, `callId : ${callId}`,
            "OnRemoteUnmute",
            `userId:${userId}, type:${type}, callId:${callId}`
          );
          if (
            _sdkUtil.validateEvents &&
            callId !== null &&
            _sdkUtil.callId !== callId
          ) {
            console.error(
              "PhoneSDK",
              "Callback",
              "OnRemoteUnmute",
              `Event Fired In Invalid State. _sdkUtil.callId:${_sdkUtil.callId}, callId:${callId}`
            );
            return;
          }
          if (_sdkUtil.callEvents["OnRemoteMuteUnmute"]) {
            _sdkUtil.callEvents["OnRemoteMuteUnmute"](
              false,
              userId,
              type,
              callId
            );
          }
        },
        OnSetBackgroundToStream:phoneConfig.basic_config.sdk.virtual_background_feature_activate !== true ? null :(callId,mediaStream)=>{
          
          this.phone_sdk_logger.warn(_sdkUtil.sessionId,"OnSetBackgroundToStream",'---------- OnSetBackgroundToStream -----------', `callId : ${callId} , edge_blur_amount :${phoneConfig.basic_config.sdk.edge_blur_amount}`);
          console.log(
            "PhoneSDK",
            "OnSetBackgroundToStream", `callId : ${callId} , edge_blur_amount :${phoneConfig.basic_config.sdk.edge_blur_amount}`
          );
          if(_sdkUtil.backendImage === null || _sdkUtil.backendImage === undefined){
            this.phone_sdk_logger.warn(_sdkUtil.sessionId,"OnSetBackgroundToStream",'Background Image not set. not applying virtual background', `callId : ${callId} , edge_blur_amount :${phoneConfig.basic_config.sdk.edge_blur_amount}`);
            _sdkUtil.mediaStream = mediaStream;
            return mediaStream.clone();
          }
          return  virtualBackgroundStream(mediaStream,phoneConfig.basic_config.sdk.edge_blur_amount,_sdkUtil.backendImage,mediaStream,phoneConfig.basic_config.sdk.use_virtual_background_configurations === true ? phoneConfig.basic_config.sdk.virtual_background_configurations : null );
        },
        OnRemoveVirtualBackgroundStreams:phoneConfig.basic_config.sdk.virtual_background_feature_activate !== true ? null :(callId,mediaStream)=>{
          
          this.phone_sdk_logger.warn(_sdkUtil.sessionId,"OnRemoveVirtualBackgroundStreams",'---------- OnRemoveVirtualBackgroundStreams -----------', `callId : ${callId} `);
          console.log(
            "PhoneSDK",
            "OnRemoveVirtualBackgroundStreams", `callId : ${callId}`
          );
          const removeStream = ()=>{
            try {
              if( _sdkUtil.mediaStream ){
                let tracks = _sdkUtil.mediaStream.getTracks();
                tracks && tracks.forEach(function(track) {
                  track.stop();
                });
          
                var audioTrack = _sdkUtil.mediaStream.getAudioTracks();
                audioTrack && audioTrack.forEach(function (track) {
                  _sdkUtil.mediaStream.removeTrack(track);
                });
          
                var videoTrack = _sdkUtil.mediaStream.getVideoTracks();
                videoTrack && videoTrack.forEach(function (track) {
                  _sdkUtil.mediaStream.removeTrack(track);
                });
                console.debug("PhoneSDK","OnRemoveVirtualBackgroundStreams","selfremoveStreamview","done" );
              }
            } catch (error) {
              console.error("PhoneSDK", "OnRemoveVirtualBackgroundStreams","removeStream",error);
            }
          }
          if(_sdkUtil.backendImage === null || _sdkUtil.backendImage === undefined){
            removeStream();
            return;
          }         
          return RemoveVirtualBackgroundStreams(mediaStream);
        },
        OnDeviceChange: async(e) => {
          
          this.phone_sdk_logger.warn(_sdkUtil.sessionId,"OnDeviceChange",'---------- OnDeviceChange -----------', ` `);
          console.log(
            "PhoneSDK",
            "Callback",
            "onDeviceChange",
            `event:${e}`
          );
          try {
            if (_sdkUtil.callEvents["onDeviceChange"] && phoneConfig.basic_config.console.mediaDevice.active === true) {
                const event = await  utility.CheckDeviceSupport();
               _sdkUtil.callEvents["onDeviceChange"](
                 event
               );
             }
             else{
              console.log("PhoneSDK" , "initialize","CheckDeviceSupport","disable with configurations" );  
             }
          } catch (error) {
            console.error("PhoneSDK" , "initialize","OnDeviceChange",error);
          }  
        },
      },      
      VoiceActivity: phoneConfig.VoiceActivity,
      isAllowedVideoCall:
        phoneConfig.isAllowedVideoCall === null
          ? true
          : phoneConfig.isAllowedVideoCall,
    };
    console.log(
      "PhoneSDK",
      "SetAvConfigs",
      "SetAvConfigs",
      `config : ${JSON.stringify(config)}`
    );
    _sdkUtil.avSdk.SetAvConfigs(config);

    console.log("PhoneSDK" , "initialize","phone initialization config done","set SetAvConfigs" );  
    
    /* try {
      navigator.mediaDevices.ondevicechange = async(e)=> {
        try {
          if (_sdkUtil.callEvents["onDeviceChange"]) {
            //const event = await  navigator.mediaDevices.enumerateDevices();
            const event = await  utility.CheckDeviceSupport();
             _sdkUtil.callEvents["onDeviceChange"](
               event
             );
           }
        } catch (error) {
          console.error("PhoneSDK" , "initialize","navigator.mediaDevices.ondevicechange-callback",error);
        }        
      }
    } catch (error) {
      console.error("PhoneSDK" , "initialize","navigator.mediaDevices.ondevicechange",error);
    } */

    if(phoneConfig.basic_config.console.mediaDevice.active === true){
      console.log("PhoneSDK" , "initialize","CheckDeviceSupport","calling CheckDeviceSupport" );  
      return utility.CheckDeviceSupport();
    }
    return null;
  } catch (error) {
    console.error("PhoneSDK" , "initialize",error);
    return null;
  }

  };

  phoneFunctions = {
    CheckDeviceSupport: async ()=>{
      
      this.phone_sdk_logger.warn(_sdkUtil.sessionId,"CheckDeviceSupport",'---------- CheckDeviceSupport -----------', ` `);
      return utility.CheckDeviceSupport();
    },
    SetInputDevice: (audioSource, videoSource) => {
      
      this.phone_sdk_logger.warn(_sdkUtil.sessionId,"SetInputDevice",'---------- SetInputDevice -----------', `audioSource :${audioSource}, videoSource : ${videoSource} `);
      _sdkUtil.avSdk.SetInputDevice(audioSource, videoSource);
    },
    Dial: async (caller, callee, call_type,sessionId,data) => {
      try {
        _sdkUtil.sessionId = sessionId;        
        this.phone_sdk_logger.warn(_sdkUtil.sessionId,"Dial",'---------- Dial -----------', `caller : ${caller}, callee : ${callee}, call_type : ${call_type}, Outbound SessionID : ${_sdkUtil.sessionId} , data : ${JSON.stringify(data)}`);
        console.log(
          `%c PhoneSDK phoneFunctions Dial  caller : ${caller}, callee : ${callee}, call_type : ${call_type} `,
          "color:#FF00FF; font-family:'Ubuntu'; display: block;font-weight:bold; font-size:14px;background: #041d82;"
        );

        
        _sdkUtil.callDirection = "outbound";
        _sdkUtil.callType = call_type;
        console.log(`dial call SID : ${caller} callee : ${callee}`);
        _sdkUtil.callId = null;
        const config = {
          call: call_type === callType.Video ? 2 : 1,
          externalId: data?.externalId
        };

        _sdkUtil.avSdk.StartAvCall(
          caller,
          callee,
          config
        );
        /* _sdkUtil.avSdk.StartAvCall(
          caller,
          callee,
          call_type === callType.Video ? 2 : 1
        ); */
        
      } catch (ex) {
        console.error("Fail to Make Call", ex);
        if (_sdkUtil.callEvents["onError"]) {
          _sdkUtil.callEvents["onError"](`Fail To Make Call : ${callee}`, ex);
        }
      }
    },
    answer: async (desc) => {
      try {
        
        this.phone_sdk_logger.warn(_sdkUtil.sessionId,"answer",'---------- answer -----------', ` desc : ${desc}`);
        console.log(
          `%c PhoneSDK phoneFunctions answer  desc : ${desc}`,
          "color:#FF00FF; font-family:'Ubuntu'; display: block;font-weight:bold; font-size:14px;background: #041d82;"
        );

        _sdkUtil.acceptObject.accept();
        console.log("call SDK answer function");
      } catch (e) {
        if (_sdkUtil.callEvents["AnsweredIncomingCall"]) {
          _sdkUtil.callEvents["AnsweredIncomingCall"](false);
        }
        console.error(`Failed to answer: ${e.toString()}`);
      }
    },
    hangup: async (caller, callee) => {
      try {
        
        this.phone_sdk_logger.warn(_sdkUtil.sessionId,"hangup",'---------- hangup -----------', ` caller : ${caller} , callee : ${callee}`);
        console.log(
          `%c PhoneSDK phoneFunctions hangup.  caller : ${caller} , callee : ${callee}`,
          "color:#FF00FF; font-family:'Ubuntu'; display: block;font-weight:bold; font-size:14px;background: #041d82;"
        );

        _sdkUtil.avSdk.EndAvCall();
        _sdkUtil.callType = null;
        _sdkUtil.callId = null;
        console.log(
          "PhoneSDK",
          "phoneFunctions",
          "hangup",
          `call ended. caller : ${caller} , callee : ${callee}`
        );
      } catch (ex) {
        console.error("Fail To hangup", ex);
        if (_sdkUtil.callEvents["onError"]) {
          _sdkUtil.callEvents["onError"]("Fail To Hangup Call", ex);
        }
      }
    },
    rejectCall: async (caller, callee) => {
      try {
        
        this.phone_sdk_logger.warn(_sdkUtil.sessionId,"rejectCall",'---------- rejectCall -----------', ` caller : ${caller} , callee : ${callee}`);
        console.log(
          `%c PhoneSDK phoneFunctions rejectCall.  caller : ${caller} , callee : ${callee}`,
          "color:#FF00FF; font-family:'Ubuntu'; display: block;font-weight:bold; font-size:14px;background: #041d82;"
        );

        _sdkUtil.acceptObject.reject();
        _sdkUtil.callType = null;
        _sdkUtil.callId = null;
      } catch (ex) {
        console.error("Fail To rejectCall", ex);
        if (_sdkUtil.callEvents["onError"]) {
          _sdkUtil.callEvents["onError"]("Fail to Reject Call", ex);
        }
      }
    },
    acceptScreenShareRequest: async ( caller, callee) => {
      try {
        
        this.phone_sdk_logger.warn(_sdkUtil.sessionId,"acceptScreenShareRequest",'---------- acceptScreenShareRequest -----------', ` caller : ${caller} , callee : ${callee}`);
        console.log(
          `%c PhoneSDK phoneFunctions acceptScreenShareRequest.   caller : ${caller} , callee : ${callee}`,
          "color:#FF00FF; font-family:'Ubuntu'; display: block;font-weight:bold; font-size:14px;background: #041d82;"
        );
        _sdkUtil.screenShareAcceptObject.accept();
      } catch (ex) {
        console.error("Fail To acceptScreenShareRequest", ex);
        if (_sdkUtil.callEvents["onError"]) {
          _sdkUtil.callEvents["onError"]("Fail to Reject Call", ex);
        }
      }
    },
    rejectScreenShareRequest: async ( caller, callee) => {
      try {
        
        this.phone_sdk_logger.warn(_sdkUtil.sessionId,"rejectScreenShareRequest",'---------- rejectScreenShareRequest -----------', ` caller : ${caller} , callee : ${callee}`);
        console.log(
          `%c PhoneSDK phoneFunctions rejectScreenShareRequest.   caller : ${caller} , callee : ${callee}`,
          "color:#FF00FF; font-family:'Ubuntu'; display: block;font-weight:bold; font-size:14px;background: #041d82;"
        );
        _sdkUtil.screenShareAcceptObject.reject();
      } catch (ex) {
        console.error("Fail To rejectScreenShareRequest", ex);
        if (_sdkUtil.callEvents["onError"]) {
          _sdkUtil.callEvents["onError"]("Fail to Reject Call", ex);
        }
      }
    },
    muteUnmuteAudioVideo: async (muteAudio, muteVideo) => {
      try {
        
        this.phone_sdk_logger.warn(_sdkUtil.sessionId,"muteUnmuteAudioVideo",'---------- muteUnmuteAudioVideo -----------', ` muteAudio : ${muteAudio} , muteVideo : ${muteVideo}`);
        console.log(
          `%c PhoneSDK phoneFunctions muteUnmuteAudioVideo. muteAudio : ${muteAudio} , muteVideo : ${muteVideo}`,
          "color:#FF00FF; font-family:'Ubuntu'; display: block;font-weight:bold; font-size:14px;background: #041d82;"
        );

        _sdkUtil.avSdk.Pause(muteAudio, muteVideo);
      } catch (ex) {
        console.error("Fail To muteUnmuteAudioVideo", ex);
        if (_sdkUtil.callEvents["onError"]) {
          _sdkUtil.callEvents["onError"]("Fail to muteUnmute Call", ex);
        }
      }
    },
    holdUnhold: async (state) => {
      try {
        this.phone_sdk_logger.warn(_sdkUtil.sessionId,"holdUnhold",'---------- holdUnhold -----------', `  state :  ${state}`);
        console.log(
          "PhoneSDK",
          "phoneFunctions",
          "holdUnhold",
          `state : ${state} `
        );
        console.log(
          `%c PhoneSDK phoneFunctions holdUnhold. state : ${state}`,
          "color:#FF00FF; font-family:'Ubuntu'; display: block;font-weight:bold; font-size:14px;background: #041d82;"
        );

        _sdkUtil.avSdk.Hold(state);
      } catch (ex) {
        console.error("Fail To Hold", ex);
        if (_sdkUtil.callEvents["onError"]) {
          _sdkUtil.callEvents["onError"]("Fail to Hold Call", ex);
        }
      }
    },
    addUserConference: async (uid) => {
      try {
        
        this.phone_sdk_logger.warn(_sdkUtil.sessionId,"addUserConference",'---------- addUserConference -----------', ` uid :  ${uid}`);
        console.log(
          `%c PhoneSDK phoneFunctions addUserConference. uid : ${uid}`,
          "color:#FF00FF; font-family:'Ubuntu'; display: block;font-weight:bold; font-size:14px;background: #041d82;"
        );

        _sdkUtil.avSdk.Conference(uid);
      } catch (ex) {
        console.error("Fail To Add User To Conference", ex);
        if (_sdkUtil.callEvents["onError"]) {
          _sdkUtil.callEvents["onError"]("Fail To Add User To Conference", ex);
        }
      }
    },
    startScreenSharing: async (uid, withCall,sessionId) => {
      try {
        _sdkUtil.sessionId = sessionId; 
        this.phone_sdk_logger.warn(_sdkUtil.sessionId,"startScreenSharing",'---------- startScreenSharing -----------', ` uid :  ${uid} , withCall:${withCall}`);
        console.log(
          `%c PhoneSDK phoneFunctions startScreenSharing. uid : ${uid} , withCall:${withCall}`,
          "color:#FF00FF; font-family:'Ubuntu'; display: block;font-weight:bold; font-size:14px;background: #041d82;"
        );

        _sdkUtil.callType = withCall
          ? callType.ScreenShareWithCall
          : callType.ScreenShare;
        _sdkUtil.avSdk.StartScreensharing("1", uid);
      } catch (ex) {
        console.error("Fail To Start Screen Sharing", ex);
        if (_sdkUtil.callEvents["onError"]) {
          _sdkUtil.callEvents["onError"]("Fail To Start Screen Sharing", ex);
        }
      }
    },
    stopScreenSharing: async (uid, withCall) => {
      try {
        this.phone_sdk_logger.warn(_sdkUtil.sessionId,"stopScreenSharing",'---------- stopScreenSharing -----------', ` uid :  ${uid} , withCall:${withCall}`);
        console.log(
          `%c PhoneSDK phoneFunctions stopScreenSharing. uid : ${uid} , withCall:${withCall}`,
          "color:#FF00FF; font-family:'Ubuntu'; display: block;font-weight:bold; font-size:14px;background: #041d82;"
        );

        _sdkUtil.avSdk.StopScreensharing();
      } catch (ex) {
        console.error("Fail To Stop Screen Sharing", ex);
        if (_sdkUtil.callEvents["onError"]) {
          _sdkUtil.callEvents["onError"]("Fail To Stop Screen Sharing", ex);
        }
      }
    },
    SendScreenShareRequest: async (profile, calleeName, caller) => {
      try {
        
        this.phone_sdk_logger.warn(_sdkUtil.sessionId,"SendScreenShareRequest",'---------- SendScreenShareRequest -----------', ` calleeName : ${calleeName} , caller:${caller}, profile:${profile}`);
        console.log(
          `%c PhoneSDK phoneFunctions SendScreenShareRequest. calleeName : ${calleeName} , caller:${caller}`,
          "color:#FF00FF; font-family:'Ubuntu'; display: block;font-weight:bold; font-size:14px;background: #041d82;"
        );

        const userId = profile.username.replace("user:", "");
        let uniqueId = uuid();
        let msg = {
          v: 1,
          mid: uniqueId,
          sid: uniqueId,
          message_type: "SCREENSHARE_REQUEST",
          from: {
            id: caller,
            name: caller,
          },
          to: {
            id: userId,
            name: calleeName,
          },
          created_at: Date.now(),
          message_content: { command: "SCREENSHARE_REQUEST" },
          // other: { callDirection, callType, caller, callee },
          conversation_type: 0,
        };
        sdkHandler.sendAppEvent(msg, userId);
      } catch (ex) {
        console.error("Fail To SendScreenShareRequest", ex);
        if (_sdkUtil.callEvents["onError"]) {
          _sdkUtil.callEvents["onError"]("Fail To SendScreenShareRequest", ex);
        }
      }
    },
    upgradeToVideo: async (uid) => {
      try {
        
        this.phone_sdk_logger.warn(_sdkUtil.sessionId,"upgradeToVideo",'---------- upgradeToVideo -----------', `uid : ${uid}`);
        console.log(
          `%c PhoneSDK phoneFunctions upgradeToVideo. uid : ${uid} `,
          "color:#FF00FF; font-family:'Ubuntu'; display: block;font-weight:bold; font-size:14px;background: #041d82;"
        );

        _sdkUtil.avSdk.upgradeToVideo();
      } catch (ex) {
        console.error("Fail To Upgrade To Video", ex);
        if (_sdkUtil.callEvents["onError"]) {
          _sdkUtil.callEvents["onError"]("Fail To Upgrade To Video", ex);
        }
      }
    },    
    requestCameraAccess: async () => {
      try {
        
        this.phone_sdk_logger.warn(_sdkUtil.sessionId,"requestCameraAccess",'---------- requestCameraAccess -----------', ` `);
        console.log(
          `%c PhoneSDK phoneFunctions requestCameraAccess `,
          "color:#FF00FF; font-family:'Ubuntu'; display: block;font-weight:bold; font-size:14px;background: #041d82;"
        );

        return await navigator.mediaDevices.getUserMedia({'video':true,'audio':true});
      } catch (ex) {
        console.error("PhoneSDK","requestCameraAccess", ex);
        throw ex;
      }
    },
    getLocalVideo: async (phoneConfig,backendImage) => {
      try {
        
        this.phone_sdk_logger.warn(_sdkUtil.sessionId,"getLocalVideo",'---------- getLocalVideo -----------', ` `);
        console.log(
          `%c PhoneSDK phoneFunctions getLocalVideo selfview `,
          "color:#FF00FF; font-family:'Ubuntu'; display: block;font-weight:bold; font-size:14px;background: #041d82;"
        );

        //const stream = await _sdkUtil.avSdk.GetLocalVideo();
        const stream =  await navigator.mediaDevices.getUserMedia({'video':true});// await _sdkUtil.avSdk.GetLocalVideo();
        if(phoneConfig.basic_config.sdk.virtual_background_feature_activate === true){
          console.log(
            "PhoneSDK",
            "OnSetBackgroundToStream",  `callId : selfview , edge_blur_amount :${phoneConfig.basic_config.sdk.edge_blur_amount}`
          );
          if((backendImage === null || backendImage === undefined) && (_sdkUtil.backendImage === null || _sdkUtil.backendImage === undefined)){
            console.log(
              "PhoneSDK",
              "OnSetBackgroundToStream",  `callId : selfview , Background Image not set. not applying virtual background`
            );
            return stream;
          }
          _sdkUtil.backendImage = (backendImage === null || backendImage === undefined) ?_sdkUtil.backendImage : backendImage;
          return virtualBackgroundStream(stream,phoneConfig.basic_config.sdk.edge_blur_amount,_sdkUtil.backendImage,phoneConfig.basic_config.sdk.use_virtual_background_configurations === true ? phoneConfig.basic_config.sdk.virtual_background_configurations : null );
        }

        console.debug("PhoneSDK","phoneFunctions","getLocalVideo","selfview",`load local media stream: ${stream?stream.id:"00000"}`);
        return stream;
      } catch (ex) {
        console.error("Fail To getLocalVideo", ex);
        if (_sdkUtil.callEvents["onError"]) {
          _sdkUtil.callEvents["onError"]("Fail To Get Local Video", ex);
        }
      }
    },
    releaseLocalVideo: async (stream,phoneConfig) => {
      try {
        
        this.phone_sdk_logger.warn(_sdkUtil.sessionId,"releaseLocalVideo",'---------- releaseLocalVideo -----------', ` `);
        console.log(
          `%c PhoneSDK phoneFunctions ReleaseLocalVideo selfview`,
          "color:#FF00FF; font-family:'Ubuntu'; display: block;font-weight:bold; font-size:14px;background: #041d82;"
        );
        if( stream && stream.active === true){
          try {            
            if (stream.active) {
              let tracks = stream.getTracks();
              tracks.forEach(function(track) {
                track.stop();
              });
            }
      
            var audioTrack = stream.getAudioTracks();
            if (audioTrack.length > 0) {
              stream.removeTrack(audioTrack[0]);
            }
      
            var videoTrack = stream.getVideoTracks();
            if (videoTrack.length > 0) {
              stream.removeTrack(videoTrack[0]);
            }
            console.debug("PhoneSDK","releaseLocalVideo","selfview","done" );
          } catch (e) {
              console.error("PhoneSDK", "ReleaseLocalVideo","selfview",e);
          }   

          if(phoneConfig.basic_config.sdk.virtual_background_feature_activate === true){
            console.log(
              "PhoneSDK",
              "OnRemoveVirtualBackgroundStreams", `callId : selfview`
            );
            RemoveVirtualBackgroundStreams(stream);
          }
          

          console.debug("PhoneSDK","phoneFunctions","releaseLocalVideo","selfview",`release local media stream: ${stream?stream.id:"00000"}`);
          console.log(`%c [-------- PhoneSDK phoneFunctions releaseLocalVideo selfview release local media stream: ${stream?stream.id:"00000"} ------- ]`, 'background: #000000; color: #02c4f5');
          return null;
          /* const response = await _sdkUtil.avSdk.ReleaseLocalVideo(stream);
          console.debug("PhoneSDK","phoneFunctions","releaseLocalVideo","selfview",`release local media stream: ${stream?stream.id:"00000"}`);
          console.log(`%c [-------- PhoneSDK phoneFunctions releaseLocalVideo selfview release local media stream: ${stream?stream.id:"00000"} ------- ]`, 'background: #000000; color: #02c4f5');
          return response; */
        }   
        console.error("PhoneSDK","releaseLocalVideo","selfview","call release local video method without having valid stream");       
      } catch (ex) {
        console.error("Fail To ReleaseLocalVideo", ex);
        if (_sdkUtil.callEvents["onError"]) {
          _sdkUtil.callEvents["onError"]("Fail To Release Local Video", ex);
        }
      }
    },
    resetValues: async () => {
      try {
        const sid = _sdkUtil.sessionId;
        _sdkUtil.sessionId = null;
        _sdkUtil.callType = null;
        _sdkUtil.userIds = null;
        _sdkUtil.callDirection = null;
        _sdkUtil.callId = null;
        _sdkUtil.activeSpeaker = null;
        _sdkUtil.activeSpeakerTimestamp = null;

        this.phone_sdk_logger.warn(sid,"resetValues",'---------- resetValues -----------', ` sessionId : ${_sdkUtil.sessionId}`);
      } catch (error) {
        console.error("PhoneSDK","resetValues",error);  
      }
    }
    /* getLocalVideo: async () => {
      try {
        console.log(
          `%c PhoneSDK phoneFunctions getLocalVideo `,
          "color:#FF00FF; font-family:'Ubuntu'; display: block;font-weight:bold; font-size:14px;background: #041d82;"
        );

        const stream = await _sdkUtil.avSdk.GetLocalVideo();
        console.debug(
          "PhoneSDK",
          "phoneFunctions",
          "getLocalVideo",
          `load local media stream: ${stream ? stream.id : "00000"}`
        );
        return stream;
      } catch (ex) {
        console.error("PhoneSDK","getLocalVideo","Fail To getLocalVideo", ex);
        if (_sdkUtil.callEvents["onError"]) {
          _sdkUtil.callEvents["onError"]("Fail To Get Local Video", ex);
        }
      }
    },
    releaseLocalVideo: async (stream) => {
      try {
        console.log(
          `%c PhoneSDK phoneFunctions ReleaseLocalVideo`,
          "color:#FF00FF; font-family:'Ubuntu'; display: block;font-weight:bold; font-size:14px;background: #041d82;"
        );
        const response = _sdkUtil.avSdk.ReleaseLocalVideo(stream);
        console.debug(
          "PhoneSDK",
          "phoneFunctions",
          "releaseLocalVideo",
          `release local media stream: ${stream ? stream.id : "00000"}`
        );
        console.log(
          `%c [-------- PhoneSDK phoneFunctions releaseLocalVideo release local media stream: ${
            stream ? stream.id : "00000"
          } ------- ]`,
          "background: #000000; color: #02c4f5"
        );
        return response;
      } catch (ex) {
        console.error("Fail To ReleaseLocalVideo", ex);
        if (_sdkUtil.callEvents["onError"]) {
          _sdkUtil.callEvents["onError"]("Fail To Release Local Video", ex);
        }
      }
    }, */
  };
}

const phoneHandler = new PhoneEventHandler();
Object.freeze(phoneHandler);

export default phoneHandler;
