import React, { useEffect, forwardRef, useImperativeHandle } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import './styles/timeline.scss';
import { BiMessageDetail, BiVideo, BiCopy, BiGroup } from "react-icons/bi";
import RightTemplate from './Template/RightTemplate';
import { Scrollbars } from "react-custom-scrollbars";
import moment from "moment";
import * as timelineActions from "../../../redux/actions/timelineActions";

let scrollBarTimeline = null;

moment.relativeTimeThreshold('s', 60);
moment.relativeTimeThreshold('m', 60);
moment.relativeTimeThreshold('h', 24);
moment.relativeTimeThreshold('d', 30);
moment.relativeTimeThreshold('M', 365);

const TimeLine = (props) => {

    let currentDateString = "";

    let interactionList = [];

    Object.entries(props.timeline.interactions).forEach(([key, interaction]) => {

        let timeAgo = moment(
            moment.unix(interaction.fromTime / 1000)
        ).calendar(null, {
            lastDay: "[Yesterday]",
            sameDay: "[Today]",
            nextDay: "[Tomorrow]",
            lastWeek: "dddd",
            nextWeek: "dddd",
            sameElse: "L"
        });

        if (timeAgo !== currentDateString) {
            interactionList.push(<div className={'timeline-date-wrapper'}>
                {timeAgo}
            </div>)

            currentDateString = timeAgo;
        }

        let index = interaction.participants.indexOf(props.timeline.customerId);
        if (index !== -1) {
            interaction.participants.splice(index, 1);
        }

        let participantString = `Interaction with ${interaction.participants.join(', ')}`;

        let interactionStartTime = moment(moment.unix(interaction.fromTime / 1000)).format('DD-MMM-YYYY hh:mm A');

        interactionList.push(<div className="timeline">
            <RightTemplate
                type={interaction.isInteractionEscalated === true ? 'video' : 'chat'}
                templateName={interaction.channel}
                icon={<BiMessageDetail />}
                imgSrc={require("../../../assets/img/defaultGroup.svg")}
                header={participantString}
                time={interactionStartTime}
                interaction={interaction} />

        </div>)

    })

    return (
        <>
            <Scrollbars
                ref={c => {
                    scrollBarTimeline = c;
                }}
                onScrollStop={() => {
                    try {
                        if (props.timeline.loadingState === 'LOADED') {

                            let values = scrollBarTimeline ? scrollBarTimeline.getValues() : null;
                            if (values.top > 0.9) {
                                //scrolled to bottom
                                let keys = Object.keys(props.timeline.interactions);
                                let lastInteraction = props.timeline.interactions[keys[keys.length - 1]];
                                props.timeline_actions.getTimeline(props.timeline.customerId, lastInteraction.fromTime - 1, 10);
                            }

                        }


                    } catch (ex) {
                        console.error(`Error occurred while fetching last timeline record`, ex);
                    }

                }}
                autoHide
                thumbMinSize={0}
                style={{ width: 300, height: props.viewPointHeight - 210 }}>
                {props.timeline.loadingState !== "LOADING" && interactionList}
                {props.timeline.loadingState === "LOADING" && <div className={'pre-loader'}>
                    <div style={{ marginTop: 250 }} className={'pre-loader-login'}>
                        <div className="loader9" />
                    </div>
                </div>}
                {/* <div className={'timeline-date-wrapper'}>
                    TODAY
            </div>
                <div className="timeline">
                    <RightTemplate
                        type={'chat'}
                        templateName={'chat'}
                        icon={<BiMessageDetail />}
                        imgSrc={'https://source.unsplash.com/XHVpWcr5grQ/150x150'}
                        header={'Chat with Micheal Watts'}
                        time={'12–Oct-2020 09:43 AM'} />

                    <RightTemplate
                        type={'video'}
                        templateName={'Video'}
                        imgSrc={'https://source.unsplash.com/7YVZYZeITc8/150x150'}
                        icon={<BiVideo />}
                        header={'Video chat with Bernard'}
                        time={'12–Oct-2020 09:43 AM'} />

                    <RightTemplate
                        type={'chat'}
                        histroy={true}
                        templateName={'chat'}
                        imgSrc={'https://source.unsplash.com/qmA4WFkY7Q8/150x150'}
                        icon={<BiMessageDetail />}
                        header={'Chat with George Bohunicky'}
                        time={'12–Oct-2020 09:43 AM'} />

                    <RightTemplate
                        type={'groupchat'}
                        templateName={'Group Chat'}
                        imgSrc={'https://source.unsplash.com/7YVZYZeITc8/150x150'}
                        icon={<BiGroup />}
                        header={'Start Group Chat'}
                        time={'12–Oct-2020 09:43 AM'} />
                </div>

                <div className={'timeline-date-wrapper mt-3'}>
                    11-OCT
            </div>
                <div className="timeline">
                    <RightTemplate
                        type={'chat'}
                        templateName={'chat'}
                        imgSrc={'https://source.unsplash.com/wmbbbU0LUG8/150x150'}
                        icon={<BiMessageDetail />}
                        header={'Chat with Micheal Watts'}
                        time={'12–Oct-2020 09:43 AM'} />

                    <RightTemplate
                        type={'screenshare'}
                        templateName={'Screen Share'}
                        imgSrc={'https://source.unsplash.com/PMVBRYY9F3s/150x150'}
                        icon={<BiCopy />}
                        header={'Screen Share with Micheal'}
                        time={'12–Oct-2020 09:43 AM'} />
                </div> */}
            </Scrollbars>
        </>
    )
};

function mapStateToProps(state) {
    return {
        timeline: state.timeline
    };
}

function mapDispatchToProps(dispatch) {
    return {
        timeline_actions: bindActionCreators(timelineActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(TimeLine);
