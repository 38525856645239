import React from 'react';
import PropTypes from 'prop-types';
import {ProfileImage, TitleWithSmallText, Button} from "../index";


const CoBrowsingCenterMessage = (props) => (
    <div className={`center-message-wrapper co-browsing d-flex justify-content-between `}>

        <div className={'msg-left-side d-flex justify-content-center align-items-center '}>
            <div className={'msg-icon-wrp d-flex justify-content-center align-items-center'}>
                {props.icon}
            </div>
            <div className={`d-flex flex-column text-wrapper`}>
                <TitleWithSmallText
                    type={`sm-text`}
                    title={props.title}
                    msg={props.msg}/>
            </div>
        </div>

        <div className={'accept-btn-wrapper d-flex justify-content-center align-items-center'}>
            <div className={'accept-wrapper mr-2'}>
                <Button
                    title={props.allowed ? 'Deny' :'Allowed'}
                    size={'sm-deny'}/>
            </div>
            {props.allowed &&
            <div className={'accept-wrapper'}>
                <Button
                    title={'Accept'}
                    size={'sm'}/>
            </div>
            }
        </div>

    </div>
);

CoBrowsingCenterMessage.propTypes = {};

CoBrowsingCenterMessage.defaultProps = {
    src: null,
};

export default CoBrowsingCenterMessage;
