import React from 'react';
import {
    TextWithIcon
} from '../../common';
import {UserPlus, Clipboard, Calendar, Flag} from 'react-feather';
import {Link} from "react-router-dom";

function CreatNewPanelComponent(props) {

    function onPressChatFilterIcon(_filter) {
        console.log('event fire...');
        console.log(_filter);
    }

    return (
        <>
            <div className={`new-item-s-wrapper`}>
                <div className="new-item-row">
                    <Link to="/console/new/group">
                    <TextWithIcon
                        icon={<UserPlus/>}
                        onClick={onPressChatFilterIcon}
                        text={'Group'}
                    />
                    </Link>
                </div>

                <div className="new-item-row">
                    <TextWithIcon
                        icon={<Clipboard/>}
                        onClick={onPressChatFilterIcon}
                        text={'Event'}
                    />
                </div>

                <div className="new-item-row">
                    <TextWithIcon
                        icon={<Flag/>}
                        onClick={onPressChatFilterIcon}
                        text={'Task'}
                    />
                </div>
            </div>
        </>
    );
}


export default CreatNewPanelComponent;
