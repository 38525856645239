const navigationRestrictions = {
    group: {
        //shows or hides group create button on left side panel
        active: window.env.REACT_APP_NAV_GROUP_ACTIVE == "true" || window.env.REACT_APP_NAV_GROUP_ACTIVE == true || false,
    },
    events: {
        //shows or hides group events button on left side panel
        active: window.env.REACT_APP_NAV_EVENTS_ACTIVE == "true" || window.env.REACT_APP_NAV_EVENTS_ACTIVE == true || false,
    },
    notification: {
        //shows or hides group notification button on left side panel
        active: window.env.REACT_APP_NAV_NOTIFICATION_ACTIVE == "true" || window.env.REACT_APP_NAV_NOTIFICATION_ACTIVE == true || false,
    },
    screen_sharing: {
        //shows or hides screensharing button
        screen_share: {
            active: window.env.REACT_APP_NAV_SCREENSHARING_SCREENSHARE_ACTIVE == "true" || window.env.REACT_APP_NAV_SCREENSHARING_SCREENSHARE_ACTIVE == true || false,
        },
        screen_share_request: {
            active:
                window.env.REACT_APP_NAV_SCREENSHARING_SCREENSHAREREQ_ACTIVE == "true" || window.env.REACT_APP_NAV_SCREENSHARING_SCREENSHAREREQ_ACTIVE == true || false,
        },
    },
    co_browsing: {
        active: window.env.REACT_APP_NAV_COBROWSING_ACTIVE == "true" || window.env.REACT_APP_NAV_COBROWSING_ACTIVE == true || false,
        whiteboard: {
            active: window.env.REACT_APP_NAV_COBROWSING_WHITEBOARD_ACTIVE == "true" || window.env.REACT_APP_NAV_COBROWSING_WHITEBOARD_ACTIVE == true || false,
        }
    },
    recaptchaProvider: {
        active: window.env.REACT_APP_NAV_RECAPTCHAPROVIDER_ACTIVE == "true" || window.env.REACT_APP_NAV_RECAPTCHAPROVIDER_ACTIVE == true || false,
    },
    presence: {
        active: window.env.REACT_APP_NAV_PRESENCE_ACTIVE == "true" || window.env.REACT_APP_NAV_PRESENCE_ACTIVE == true || false,
        show_communication_buttons: {
            active: window.env.REACT_APP_NAV_PRESENCE_BUTTONS_ACTIVE == "true" || window.env.REACT_APP_NAV_PRESENCE_BUTTONS_ACTIVE == true || false
        },
        show_presence: {
            //activates or deactivates all presence status indicators below user images
            active: window.env.REACT_APP_NAV_PRESENCE_SHOWPRESENCE_ACTIVE == "true" || window.env.REACT_APP_NAV_PRESENCE_SHOWPRESENCE_ACTIVE == true || false,
        },
        set_presence: {
            //shows or hides set own presence section on the hover panel
            active: window.env.REACT_APP_NAV_PRESENCE_SETPRESENCE_ACTIVE == "true" || window.env.REACT_APP_NAV_PRESENCE_SETPRESENCE_ACTIVE == true || false,
        },
    },
    chat: {
        active: window.env.REACT_APP_NAV_CHAT_ACTIVE == "true" || window.env.REACT_APP_NAV_CHAT_ACTIVE == true || false,
        show_full_date: window.env.REACT_APP_NAV_CHAT_SHOWFULLDATE_ACTIVE == "true" || window.env.REACT_APP_NAV_CHAT_SHOWFULLDATE_ACTIVE == true || false,
        alerts: {
            //activates or deactivates the notification sound that comes up when a chat is received while user has minimised browser
            active: window.env.REACT_APP_NAV_CHAT_ALERTS_ACTIVE == "true" || window.env.REACT_APP_NAV_CHAT_ALERTS_ACTIVE == true || false,
        },
        emoji_panel: {
            //shows or hides emoji panel
            active: window.env.REACT_APP_NAV_CHAT_EMOJIPANEL_ACTIVE == "true" || window.env.REACT_APP_NAV_CHAT_EMOJIPANEL_ACTIVE == true || false,
        },
        video_recorder: {
            //shows or hides video recorder button
            active: window.env.REACT_APP_NAV_CHAT_VIDEORECORDER_ACTIVE == "true" || window.env.REACT_APP_NAV_CHAT_VIDEORECORDER_ACTIVE == true || false,
        },
        audio_recorder: {
            //shows or hides audio recorder button
            active: window.env.REACT_APP_NAV_CHAT_AUDIORECORDER_ACTIVE == "true" || window.env.REACT_APP_NAV_CHAT_AUDIORECORDER_ACTIVE == true || false,
        },
        search: {
            //shows or hides chat search button
            active: window.env.REACT_APP_NAV_CHAT_SEARCH_ACTIVE == "true" || window.env.REACT_APP_NAV_CHAT_SEARCH_ACTIVE == true || false,
        },
        starring: {
            //enables or disables all starring related panels and menues for chat
            active: window.env.REACT_APP_NAV_CHAT_STARRING_ACTIVE == "true" || window.env.REACT_APP_NAV_CHAT_STARRING_ACTIVE == true || false,
        },
        forwarding: {
            //enables or disables all forwarding related panels and menues for chat
            active: window.env.REACT_APP_NAV_CHAT_FORWARDING_ACTIVE == "true" || window.env.REACT_APP_NAV_CHAT_FORWARDING_ACTIVE == true || false,
        },
        empty_page: {
            heading:
                window.env.REACT_APP_NAV_CHAT_EMPTYPAGE_HEADING ||
                "It's nice to chat with someone",
            sub_heading: window.env.REACT_APP_NAV_CHAT_EMPTYPAGE_SUBHEADING || "",
        },
    },
    signature_capture: {
        active: window.env.REACT_APP_NAV_SIGNATURECAPTURE_ACTIVE == "true" || window.env.REACT_APP_NAV_SIGNATURECAPTURE_ACTIVE == true || false
    },
    media: {
        active: window.env.REACT_APP_NAV_MEDIA_ACTIVE == "true" || window.env.REACT_APP_NAV_MEDIA_ACTIVE == true || false,
        uploader: {
            active: window.env.REACT_APP_NAV_MEDIA_UPLOADER_ACTIVE == "true" || window.env.REACT_APP_NAV_MEDIA_UPLOADER_ACTIVE == true || false,
            //enables or disables media upload button
            media_upload: {
                active:
                    window.env.REACT_APP_NAV_MEDIA_UPLOADER_MEDIAUPLOAD_ACTIVE == "true" || window.env.REACT_APP_NAV_MEDIA_UPLOADER_MEDIAUPLOAD_ACTIVE == true || false,
                //shows or hides media upload drop down menu entry
            },
            document_upload: {
                active:
                    window.env.REACT_APP_NAV_MEDIA_UPLOADER_DOCUMENTUPLOAD_ACTIVE == "true" || window.env.REACT_APP_NAV_MEDIA_UPLOADER_DOCUMENTUPLOAD_ACTIVE == true || false,
            },
        },
        recent_media: {
            active: window.env.REACT_APP_NAV_MEDIA_RECENTMEDIA_ACTIVE == "true" || window.env.REACT_APP_NAV_MEDIA_RECENTMEDIA_ACTIVE == true || false,
            media_tab: {
                active:
                    window.env.REACT_APP_NAV_MEDIA_RECENTMEDIA_MEDIATAB_ACTIVE == "true" || window.env.REACT_APP_NAV_MEDIA_RECENTMEDIA_MEDIATAB_ACTIVE == true || false,
            },
            document_tab: {
                active:
                    window.env.REACT_APP_NAV_MEDIA_RECENTMEDIA_DOCUMENTTAB_ACTIVE == "true" || window.env.REACT_APP_NAV_MEDIA_RECENTMEDIA_DOCUMENTTAB_ACTIVE == true || false,
            },
            link_tab: {
                active:
                    window.env.REACT_APP_NAV_MEDIA_RECENTMEDIA_LINKTAB_ACTIVE == "true" || window.env.REACT_APP_NAV_MEDIA_RECENTMEDIA_LINKTAB_ACTIVE == true || false,
            },
        },
        allowed_media: {
            video: window.env.REACT_APP_NAV_MEDIA_ALLOWEDMEDIA_VIDEO == "true" || window.env.REACT_APP_NAV_MEDIA_ALLOWEDMEDIA_VIDEO == true || false,
            image: window.env.REACT_APP_NAV_MEDIA_ALLOWEDMEDIA_IMAGE == "true" || window.env.REACT_APP_NAV_MEDIA_ALLOWEDMEDIA_IMAGE == true || false,
            audio: window.env.REACT_APP_NAV_MEDIA_ALLOWEDMEDIA_AUDIO == "true" || window.env.REACT_APP_NAV_MEDIA_ALLOWEDMEDIA_AUDIO == true || false,
        },
    },
    calls: {
        active: window.env.REACT_APP_NAV_CALLS_ACTIVE == "true" || window.env.REACT_APP_NAV_CALLS_ACTIVE == true || false,
        setting: {
            active: window.env.REACT_APP_NAV_CALLS_SETTING_ACTIVE == "true" || window.env.REACT_APP_NAV_CALLS_SETTING_ACTIVE == true || false,
        },
        video_calls: {
            active: window.env.REACT_APP_NAV_CALLS_VIDEOCALLS_ACTIVE == "true" || window.env.REACT_APP_NAV_CALLS_VIDEOCALLS_ACTIVE == true || false,
            maximize: {
                active:
                    window.env.REACT_APP_NAV_CALLS_VIDEOCALLS_MAXIMIZE_ACTIVE == "true" || window.env.REACT_APP_NAV_CALLS_VIDEOCALLS_MAXIMIZE_ACTIVE == true || false,
            },
            conference_invite: {
                active:
                    window.env.REACT_APP_NAV_CALLS_VIDEOCALLS_CONFERENCEINVITE_ACTIVE == "true" || window.env.REACT_APP_NAV_CALLS_VIDEOCALLS_CONFERENCEINVITE_ACTIVE == true || false,
            },
            conference_invite_request: {
                active:
                    window.env.REACT_APP_NAV_CALLS_VIDEOCALLS_CONFERENCEINVITEREQUEST_ACTIVE == "true" || window.env.REACT_APP_NAV_CALLS_VIDEOCALLS_CONFERENCEINVITEREQUEST_ACTIVE == true || false,
            },
            mute: {
                active: window.env.REACT_APP_NAV_CALLS_VIDEOCALLS_MUTE_ACTIVE == "true" || window.env.REACT_APP_NAV_CALLS_VIDEOCALLS_MUTE_ACTIVE == true || false,
            },
            video_disable: {
                active:
                    window.env.REACT_APP_NAV_CALLS_VIDEOCALLS_VIDEODISABLE_ACTIVE == "true" || window.env.REACT_APP_NAV_CALLS_VIDEOCALLS_VIDEODISABLE_ACTIVE == true || false,
            },
            snapshot_capture: {
                active:
                    window.env.REACT_APP_NAV_CALLS_VIDEOCALLS_SNAPSHOTCAPTURE_ACTIVE == "true" || window.env.REACT_APP_NAV_CALLS_VIDEOCALLS_SNAPSHOTCAPTURE_ACTIVE == true || false,
            },
            send_snapshot: {
                active:
                    window.env.REACT_APP_NAV_CALLS_VIDEOCALLS_SEND_SNAPSHOT_ACTIVE == "true" || window.env.REACT_APP_NAV_CALLS_VIDEOCALLS_SEND_SNAPSHOT_ACTIVE == true || false,
            }
        },
        audio_calls: {
            active: window.env.REACT_APP_NAV_CALLS_AUDIOCALLS_ACTIVE == "true" || window.env.REACT_APP_NAV_CALLS_AUDIOCALLS_ACTIVE == true || false,
        },
        alert: {
            accept: {
                active: window.env.REACT_APP_NAV_CALLS_ALERT_ACCEPT_ACTIVE == "true" || window.env.REACT_APP_NAV_CALLS_ALERT_ACCEPT_ACTIVE == true || false,
            },
            reject: {
                active: window.env.REACT_APP_NAV_CALLS_ALERT_REJECT_ACTIVE == "true" || window.env.REACT_APP_NAV_CALLS_ALERT_REJECT_ACTIVE == true || false,
            },
        },
    },
    login: {
        active: window.env.REACT_APP_NAV_LOGIN_ACTIVE == "true" || window.env.REACT_APP_NAV_LOGIN_ACTIVE == true || false,
        forget_password: {
            active: window.env.REACT_APP_NAV_LOGIN_FORGETPASSWORD_ACTIVE == "true" || window.env.REACT_APP_NAV_LOGIN_FORGETPASSWORD_ACTIVE == true || false,
        },
        password_policy: {
            active: window.env.REACT_APP_NAV_PASSWORD_POLICY_INVOKE_SERVICE_ACTIVE || "enable", //enable|disable if enable, policy load from backend.use adminconsole to define polices
            passwordPolicy: window.env.REACT_APP_NAV_PASSWORD_POLICY || "^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!#$%&'()*+,-./:;<=>?@^_`{|}~]).{6,12}$",
            policyNames: window.env.REACT_APP_NAV_PASSWORD_POLICY_NAME || "Minimum characters[3].,Maximum characters[20].",
            minLength: window.env.REACT_APP_NAV_PASSWORD_POLICY_MIN_LENGTH || 12,
            maxLength: window.env.REACT_APP_NAV_PASSWORD_POLICY_MAX_LENGTH || 7,
            enableForLoginPage: window.env.REACT_APP_NAV_PASSWORD_POLICY_ENABLE_FOR_LOGIN_PAGE === "true" || window.env.REACT_APP_NAV_PASSWORD_POLICY_ENABLE_FOR_LOGIN_PAGE === true || false,
        },
        face_auth: {
            active: window.env.REACT_APP_NAV_LOGIN_FACE_AUTH_ACTIVE === "true" || window.env.REACT_APP_NAV_LOGIN_FACE_AUTH_ACTIVE === true || false,
            minScore: window.env.REACT_APP_NAV_LOGIN_FACE_AUTH_MIN_SCORE || 0.50,
            faceDetectTimeout: window.env.REACT_APP_NAV_LOGIN_FACE_AUTH_FACE_DETECT_TIMEOUT || 1000,
        },
        default_method: window.env.REACT_APP_NAV_LOGIN_DEFAULT_TYPE || "password", // password , faceAuth
    },
    contacts: {
        active: window.env.REACT_APP_NAV_CONTACTS_ACTIVE == "true" || window.env.REACT_APP_NAV_CONTACTS_ACTIVE == true || false,
        switcher: {
            active: window.env.REACT_APP_NAV_CONTACTS_SWITCHER_ACTIVE == "true" || window.env.REACT_APP_NAV_CONTACTS_SWITCHER_ACTIVE == true || false,
        },
        search: {
            active: window.env.REACT_APP_NAV_CONTACTS_SEARCH_ACTIVE == "true" || window.env.REACT_APP_NAV_CONTACTS_SEARCH_ACTIVE == true || false,
        },
    },
    templates: {
        active: window.env.REACT_APP_NAV_TEMPLATE_ACTIVE == "true" || window.env.REACT_APP_NAV_TEMPLATE_ACTIVE == true || false,
        canned_messages: {
            active: window.env.REACT_APP_NAV_TEMPLATE_CANNED_ACTIVE == "true" || window.env.REACT_APP_NAV_TEMPLATE_CANNED_ACTIVE == true || false,
        }
    },
    account: {
        active: window.env.REACT_APP_NAV_ACCOUNT_ACTIVE == "true" || window.env.REACT_APP_NAV_ACCOUNT_ACTIVE == true || false,
        my_profile: {
            active: window.env.REACT_APP_NAV_ACCOUNT_MYPROFILE_ACTIVE == "true" || window.env.REACT_APP_NAV_ACCOUNT_MYPROFILE_ACTIVE == true || false,
        },
        logout: {
            active: window.env.REACT_APP_NAV_ACCOUNT_LOGOUT_ACTIVE == "true" || window.env.REACT_APP_NAV_ACCOUNT_LOGOUT_ACTIVE == true || false,
        },
        workspaces: {
            active: window.env.REACT_APP_NAV_ACCOUNT_WORKSPACES_ACTIVE == "true" || window.env.REACT_APP_NAV_ACCOUNT_WORKSPACES_ACTIVE == true || false,
        },
        official_account_check: window.env.REACT_APP_NAV_ACCOUNT_OFFICIAL_ACCOUNT_CHECK === "true" || window.env.REACT_APP_NAV_ACCOUNT_OFFICIAL_ACCOUNT_CHECK === true || false,
    },
    connectivity_indicator: {
        active: window.env.REACT_APP_NAV_CONNECTIVITYINDICATOR_ACTIVE == "true" || window.env.REACT_APP_NAV_CONNECTIVITYINDICATOR_ACTIVE == true || false,
    },
    profile: {
        active: window.env.REACT_APP_NAV_PROFILE_ACTIVE == "true" || window.env.REACT_APP_NAV_PROFILE_ACTIVE == true || false,
        image: {
            active: window.env.REACT_APP_NAV_PROFILE_IMAGE_ACTIVE == "true" || window.env.REACT_APP_NAV_PROFILE_IMAGE_ACTIVE == true || false,
        },
        summerized_info: {
            active: window.env.REACT_APP_NAV_PROFILE_SUMMERIZEDINFO_ACTIVE == "true" || window.env.REACT_APP_NAV_PROFILE_SUMMERIZEDINFO_ACTIVE == true || false,
        },
    },
    panels: {
        right: {
            active: window.env.REACT_APP_NAV_PANELS_RIGHT_ACTIVE == "true" || window.env.REACT_APP_NAV_PANELS_RIGHT_ACTIVE == true || false,
            fixed: window.env.REACT_APP_NAV_PANELS_RIGHT_FIXED == "true" || window.env.REACT_APP_NAV_PANELS_RIGHT_FIXED == true || false,
        },
        profile_hover: {
            active: window.env.REACT_APP_NAV_PANELS_PROFILEHOVER_ACTIVE == "true" || window.env.REACT_APP_NAV_PANELS_PROFILEHOVER_ACTIVE == true || false,
        },
    },
    invite_panel: {
        enable_sections:
            window.env.REACT_APP_NAV_INVITEPANEL_ENABLESECTIONS == "true" || window.env.REACT_APP_NAV_INVITEPANEL_ENABLESECTIONS == true || false,
    },
    error_page: {
        login_button: {
            active: window.env.REACT_APP_NAV_ERRORPAGE_LOGINBUTTON_ACTIVE == "true" || window.env.REACT_APP_NAV_ERRORPAGE_LOGINBUTTON_ACTIVE == true || false,
        },
        heading: {
            active: window.env.REACT_APP_NAV_ERRORPAGE_HEADING_ACTIVE == "true" || window.env.REACT_APP_NAV_ERRORPAGE_HEADING_ACTIVE == true || false,
            display:
                window.env.REACT_APP_NAV_ERRORPAGE_HEADING_DISPLAY || "Error occurred",
        },
        sub_heading: {
            active: window.env.REACT_APP_NAV_ERRORPAGE_SUBHEADING_ACTIVE == "true" || window.env.REACT_APP_NAV_ERRORPAGE_SUBHEADING_ACTIVE == true || false,
            display:
                window.env.REACT_APP_NAV_ERRORPAGE_SUBHEADING_DISPLAY ||
                "Please close and re initiate login",
        },
    },
    logout_page: {
        display:
            window.env.REACT_APP_NAV_LOGOUTPAGE_DISPLAY ||
            "You Have Logged Out Successfully",
    },
    timeline: {
        active: window.env.REACT_APP_NAV_TIMELINE_ACTIVE == "true" || window.env.REACT_APP_NAV_TIMELINE_ACTIVE == true || false,
    },
    selected_user_info: {
        active: window.env.REACT_APP_NAV_SELECTED_USER_INFO_ACTIVE == "true" || window.env.REACT_APP_NAV_SELECTED_USER_INFO_ACTIVE == true || false,
    },
    meeting: {
        ext_id: {
            active: window.env.REACT_APP_NAV_MEETING_SHOW_EXT_ID == "true" || window.env.REACT_APP_NAV_MEETING_SHOW_EXT_ID == true || false,
        },
        meeting_only: window.env.REACT_APP_NAV_ONLY_MEETING === "true" || window.env.REACT_APP_NAV_ONLY_MEETING === true || false,
        dial_call_with_app_message: window.env.REACT_APP_NAV_DIAL_CALL_WITH_APP_MESSAGE === "true" || window.env.REACT_APP_NAV_DIAL_CALL_WITH_APP_MESSAGE === true || false,
    }
};

export default navigationRestrictions;
