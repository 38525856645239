import * as types from "./actionTypes";
import { TeamService } from "../../service/teamService";
import * as logger from '../../lib/logSdk/logger';


export function profileFound(profile) {
    return { type: types.PROFILE_FOUND, profile };
}

//profile remove action
export function profileRemove(profileId , callback) {
  return (dispatch)=>{
    TeamService.removeProfile(profileId)
    .then((result) => {
        dispatch({type: types.PROFILE_REMOVE, profileId });
        callback(true);
    })
    .catch((error) => {
      callback(false);
      console.error("teamActions", "validateAndRemoveContact", error);
      logger.error("teamActions", "validateAndRemoveContact", error.message);
    });
};
}

// contact remove action
export function contactRemove(profileId, callback) {
    return (dispatch)=>{
        TeamService.removeProfile(profileId)
        .then((result) => {
            dispatch({type: types.CONTACT_REMOVE, profileId });
            callback(true);
        })
        .catch((error) => {
          callback(false);
          console.error("teamActions", "validateAndRemoveContact", error);
          logger.error("teamActions", "validateAndRemoveContact", error.message);
        });
    };
}
