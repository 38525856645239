import * as baseUrls from "../config/baseUrls";
import { fetchDefaults } from "./fetchWrapper";
import * as auth from "../utils";
import * as logger from '../lib/logSdk/logger';

var apiFetch = fetchDefaults(fetch);

export default class UserService {
  /* validate_Email = async email => {
    const response = await fetch({
      method: "GET",
      url: `${baseUrls.userUrl}/sign-in/${email}`,
      headers: {
        "Content-Type": "application/json"
      }
    });
web
    return response.json().isSuccess;
  };
  signIn = body => {
    return fetch({
      method: "POST",
      url: `${baseUrls.userUrl}/sign-in`,
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(body)
    });
  }; */

  UpdateProfile(userName, profile) {
    return fetch(
      `${baseUrls.userUrl}/user-auth-service/api/v1/user/account/${userName}?org=${auth.getModeVal()}`,
      {
        method: "put",
        headers: {
          "Content-Type": "application/json",
          authorization: auth.getToken(),
        },
        body: JSON.stringify({
          firstName: profile.firstName,
          lastName: profile.lastName,
          emailId: profile.emailId,
          mobileNumber: profile.mobileNumber,
          avatarURL: profile.avatarURL,
        }),
      }
    )
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        return { isSuccess: data.status === 0 };
      })
      .catch((error) => {
        console.error("UserService", "UpdateProfile", error);
        logger.error("UserService", "UpdateProfile", error.message);
        return { isSuccess: false };
      });
  }

  updatePassword(userName, oldPassword, password) {
    return fetch(
      `${baseUrls.userUrl}/user-auth-service/api/v1/user/password/${userName}?org=${auth.getModeVal()}`, //service request to send email id
      {
        method: "put",
        headers: {
          "Content-Type": "application/json",
          authorization: auth.getToken(),
        },
        body: JSON.stringify({
          oldPassword: oldPassword,
          newPassword: password,
        }),
      }
    )
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        return { isSuccess: data.status === 0 };
      })
      .catch((error) => {
        console.error("UserService", "updatePassword", error);
        logger.error("UserService", "updatePassword", error.message);
        return { isSuccess: false };
      });
  }

  signUp(opts) {
    return fetch(`${baseUrls.userUrl}/user`, {
      method: "post",
      body: JSON.stringify(opts),
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        return data;
      })
      .catch((error) => {
        console.error("UserService", "signUp", error);
        logger.error("UserService", "signUp", error.message);
        return false;
      });
  }

  getUser(userId) {
    return fetch(
      `${baseUrls.userUrl}/user-auth-service/api/v1/user/account/${userId}?org=${auth.getModeVal()}`,
      {
        method: "get",
        headers: {
          "Content-Type": "application/json",
          authorization: auth.getToken(),
        },
      }
    )
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        return data.status === 0 ? data.data : null;
      })
      .catch((error) => {
        console.error("UserService", "getUser", error);
        logger.error("UserService", "getUser", error.message);
        return null;
      });
  }

  getUserByName(userName, token) {
    return fetch(
      `${baseUrls.userUrl}/user-auth-service/api/v1/user/account/user-nam5e/${userName}?g-recaptcha-response=${token}&org=${auth.getModeVal()}`,
      {
        method: "get",
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        return data.status === 0 ? data.data : null;
      })
      .catch((error) => {
        console.error("UserService", "getUser", error);
        logger.error("UserService", "getUser", error.message);
        return null;
      });
  }
}
