class Notification {

    constructor(obj) {
        this.timeInMinutes = obj.timeInMinutes;
        this.otherData = obj.otherData;
        this.otherDataType = obj.otherDataType;
    }

}

export { Notification }