import React, { useEffect, useState } from "react";
import { Statistic,Result,Typography ,notification } from "antd";
import moment from "moment";
import * as auth from "../../../../utils";

let logout_timeout = 10;

const AlertModal = ({phoneConfig,onFinish}) => {
  const { Countdown } = Statistic;
  
  const [deadline, setDeadline] = useState(moment().add( logout_timeout, 'minutes'));
  const { Paragraph, Text } = Typography;

  
  

  useEffect(() => {
    console.log("AlertModal", "Calling useEffect Method");

    if(phoneConfig && phoneConfig.basic_config && phoneConfig.basic_config.console && phoneConfig.basic_config.console.mediaDevice && phoneConfig.basic_config.console.mediaDevice.log_out_after){
      logout_timeout = phoneConfig.basic_config.console.mediaDevice.log_out_after;
    }
    const timerSetting = auth.getMediaDeviceErrorCount();

    /* const limit = media_deceive_accepted_error_limit + 1;
    if(timerSetting.count > limit){
      console.error("AlertModal", "ignore calculations");
      return;
    } */

    if(timerSetting && timerSetting.time ){
      console.log("AlertModal", "page reloaded","start time calculation");
      const now = moment(new Date()); 
      const end = moment(timerSetting.time); 
      var duration = moment.duration(now.diff(end));
      var seconds = duration.asSeconds();

      const due_in_secondes = (logout_timeout * 60) - seconds;
      if(due_in_secondes > 0){
        setDeadline(moment().add(due_in_secondes, 'seconds'));
      }
      else{
        setDeadline(moment().add( 10, 'seconds'));
      }
    }else {
      setDeadline(moment().add(logout_timeout, 'minutes'));
    }
    
  }, [phoneConfig]);

  const onEnd = ()=> {
    console.log("AlertModal", "onEnd event fired");
    if(onFinish){
      onFinish();
    }
  }

  
  return (
    <>
      <Result
        status="error"
        title={phoneConfig ?phoneConfig.basic_config.console.mediaDevice.title:"Attention!"}
        subTitle={phoneConfig ?phoneConfig.basic_config.console.mediaDevice.subTitle:"Media Device Error"}
        extra={ (phoneConfig && phoneConfig.basic_config && phoneConfig.basic_config.console && phoneConfig.basic_config.console.mediaDevice && phoneConfig.basic_config.console.mediaDevice.auto_logout_active === true )?([
          <Text
          key={"123654"}
          strong
          style={{
            fontSize: 16,
          }}
        >
          {phoneConfig ?phoneConfig.basic_config.console.mediaDevice.description:"You will be automatically logged out in"}
        </Text>,
          <Countdown key={"1278883654"} style={{ fontSize: 20 ,color: "#ffa39e"}} value={deadline} onFinish={onEnd} valueStyle={{ color: '#cf1322' }}/>,
        ]):
      (<Text
        key={"123654"}
        strong
        style={{
          fontSize: 20,
        }}
      >
        {phoneConfig ?phoneConfig.basic_config.console.mediaDevice.description:"Please Contact your system administrator"}
      </Text>)}
      >    
      </Result>
    </>
  );
};

export default AlertModal;
