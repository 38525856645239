import * as types from "../actions/actionTypes";
import produce from "immer";

export const whiteboardReducer = produce((draft = { wbState: "CLOSED", contactId: "", wbType: "", extraData: "" }, action) => {
    switch (action.type) {
        case types.OPEN_WHITEBOARD:
            {
                draft.wbState = "OPENED";
                draft.contactId = action.contactId;
                draft.wbType = action.wbType;
                draft.extraData = action.extraData;

                return draft;

            }
        case types.MINIMIZE_WHITEBOARD:
            {
                draft.wbState = "MINIMIZED";

                return draft;

            }
        case types.MAXIMIZE_WHITEBOARD:
            {
                draft.wbState = "OPENED";

                return draft;

            }
        case types.DISPOSE_WHITTEBOARD:
            {
                draft.wbState = "CLOSED";
                draft.contactId = "";
                draft.wbType = "";
                draft.extraData = "";

                return draft;

            }
        default:
            return draft;
    }
});