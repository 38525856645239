import { Logger } from "./logger.js";
import Parser from './libs/bowser/parser.js';

let browserInfo = (window != null && window.navigator != null && window.navigator.userAgent != null && window.navigator.userAgent !== '') ? new Parser(window.navigator.userAgent) : null;

class BrowserDeviceInfoManager {
    info;
    userAgent;

    constructor () {
        this.info = {
            Type: null, //Indicates if the type is web/react-native/nodejs
            BrowserName: null, //Indicates the name of the browser (available only if 'type' is web)
            BrowserVersion: null, //Indicates the version of the browser (available only if 'type' is web)
            OSName: null, //Indicates the name of the Operating System (available only if 'type' is web)
            OSVersion: null, //Indicates the version of the Operating System (available only if 'type' is web)
            Platform: null, //Indicates the Platform i.e. desktop/mobile/tablet (available only if 'type' is web)
        }

        if (typeof document != 'undefined')
            this.info.Type = `web`;
        else if (typeof navigator != 'undefined' && navigator.product == 'ReactNative')
            this.info.Type = `react-native`;
        else
            this.info.Type = `nodejs`;

        Logger.info(`Livechat 2 API is running on ${this.info.Type} environment`);
    }

    getAllDetails = () => {
        try {
            if (this.info.Type === "web") {
                if (browserInfo != null) {
                    this.info.BrowserName = browserInfo.getBrowser().name;
                    this.info.BrowserVersion = browserInfo.getBrowser().version;
                    this.info.OSName = browserInfo.getOS().name;
                    this.info.OSVersion = browserInfo.getOS().version;
                    this.info.Platform = browserInfo.getPlatform().type;
                }

                return this.info;
            }
            else
                return this.info;
        } catch (e) {
            Logger.error(`Error in getAllDetails`, e);
            return this.info;
        }
    }
}

export { BrowserDeviceInfoManager };