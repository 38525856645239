import { Interaction } from "./Interaction";

export class InteractionUser {
    constructor(obj) {
        this.UserId = obj.UserId;
        this.InteractionListInformation = null;

        if (obj.InteractionListInformation) {
            this.InteractionListInformation = obj.InteractionListInformation.map(interaction => {
                return new Interaction(obj.UserId, interaction);
            });

        }

    }
}