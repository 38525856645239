import React, { useState } from 'react';
import AgentConfirmation from "./agentConfirmation";
import CoBrowsingOption from "./CoBrowsingOption";


import {
    PanelTitle,
    MenuIcon,
    TextBoxWithIcon,
    ModalSelectUser, SelectUsers
} from '../index';
import { Check, X, Send } from 'react-feather';
import { Scrollbars } from "react-custom-scrollbars";
import './styles/co-browsing-modal.scss';

const CoBrowsingModal = (props) => {
    const [isConfirmCoBrowsing, setConfirmCoBrowsing] = useState(false);

    function confirmCorBrowsingShare() {
        setConfirmCoBrowsing(true);
    }

    return (
        <>
            {/*${props.isOpenCoBrowsing ? 'show-this' : 'hide-this'}`}>*/}
            <div
                className={`modal-inner-wrapper d-flex
                 justify-content-center align-items-center  
                 ${props.isOpenCoBrowsing ? 'show-this' : 'hide-this'}`}>
                <div className={`main-modal-wrapper ${props.size}`}>
                    <div className={'modal-header d-flex'}>
                        <PanelTitle title={props.title ? props.title : ""} />
                        <MenuIcon icon={<X />}
                            onClick={props.onClickClose}
                            size={'sm'} />
                    </div>

                    <AgentConfirmation
                        isConfirmCoBrowsing={isConfirmCoBrowsing}
                        confirmCoBrowsing={confirmCorBrowsingShare}
                    />

                    <CoBrowsingOption
                        onClickClose={props.onClickClose}
                        onPressMenuIcon={props.onPressMenuIcon}
                        isConfirmCoBrowsing={isConfirmCoBrowsing}
                        selectOption={props.onClickClose} />
                </div>
            </div>
        </>
    )
};

CoBrowsingModal.propTypes = {
    //status: PropTypes.string.isRequired,
};

CoBrowsingModal.defaultProps = {
    //status: 'offline',
};

export default CoBrowsingModal;
