"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _button = _interopRequireDefault(require("./button"));

var _recordButton = _interopRequireDefault(require("./record-button"));

var _stopButton = _interopRequireDefault(require("./stop-button"));

var _timer = _interopRequireDefault(require("./timer"));

var _countdown = _interopRequireDefault(require("./countdown"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : { default: obj };
}

var ActionsWrapper = _styledComponents["default"].div.withConfig({
  displayName: "render-actions__ActionsWrapper",
  componentId: "dp6lnv-0"
})([
  "position:absolute;bottom:0;left:0;right:0;display:flex;align-items:center;justify-content:center;padding-top:20px;padding-bottom:80px;"
]);

var Actions = function Actions(_ref) {
  var isVideoInputSupported = _ref.isVideoInputSupported,
    isInlineRecordingSupported = _ref.isInlineRecordingSupported,
    thereWasAnError = _ref.thereWasAnError,
    isRecording = _ref.isRecording,
    isCameraOn = _ref.isCameraOn,
    streamIsReady = _ref.streamIsReady,
    isConnecting = _ref.isConnecting,
    isRunningCountdown = _ref.isRunningCountdown,
    countdownTime = _ref.countdownTime,
    timeLimit = _ref.timeLimit,
    isReplayingVideo = _ref.isReplayingVideo,
    useVideoInput = _ref.useVideoInput,
    onTurnOnCamera = _ref.onTurnOnCamera,
    onTurnOffCamera = _ref.onTurnOffCamera,
    onOpenVideoInput = _ref.onOpenVideoInput,
    onStartRecording = _ref.onStartRecording,
    onUploadRecording = _ref.onUploadRecording,
    onCancelledRecording = _ref.onCancelledRecording,
    onStopRecording = _ref.onStopRecording,
    onStopReplaying = _ref.onStopReplaying,
    onConfirm = _ref.onConfirm;

  var renderContent = function renderContent() {
    var shouldUseVideoInput =
      !isInlineRecordingSupported && isVideoInputSupported;

    if (
      (!isInlineRecordingSupported && !isVideoInputSupported) ||
      thereWasAnError ||
      isConnecting ||
      isRunningCountdown
    ) {
      return null;
    }

    if (isReplayingVideo) {
      const el = _react["default"].createElement;

      return el(
        "div",
        null,
        el(
          _button["default"],
          {
            onClick: onStopReplaying,
            "data-qa": "start-replaying"
          },
          "Retake"
        ),
        el(
          _button["default"],
          {
            hidden: true,
            id: "REC_SEND",
            onClick: onUploadRecording,
            "data-qa": "start-uploading"
          },
          "Send"
        ),
        el(
          _button["default"],
          {
            hidden: true,
            id: "REC_CANCEL",
            onClick: onCancelledRecording,
            "data-qa": "start-canceling"
          },
          "Cancel"
        )
      );

      /*  return _react["default"].createElement(
        _button["default"],
        {
          onClick: onStopReplaying,
          "data-qa": "start-replaying"
        },
        "Retake"
      ); */
    }

    
    if (isRecording) {
      return _react["default"].createElement(_stopButton["default"], {
        onClick: onStopRecording,
        "data-qa": "stop-recording",
          hidden: true,
          id: 'REC_STOP'
      });
    }

    /* if (isCameraOn ) {
      return _react["default"].createElement(
        _button["default"],
        {
          onClick: onTurnOffCamera,
          "data-qa": "turn-off-camera"
        },
        "Turn my camera OFF"
      );
    } */

    if (isCameraOn && streamIsReady) {
     /*  return _react["default"].createElement(_recordButton["default"], {
        onClick: onStartRecording,
        "data-qa": "start-recording",
          hidden:true,
          id: 'REC_START'
      }); */
      const el = _react["default"].createElement;
      return el(
        "div",
        null,
        el(
          _recordButton["default"], {
            onClick: onStartRecording,
            "data-qa": "start-recording",
              hidden:true,
              id: 'REC_START'
          }
        ),
        el(
           _button["default"],
        {
          onClick: onTurnOffCamera,
          "data-qa": "turn-off-camera"
        },
        "Turn my camera OFF"
        )
      );
    }

    if (useVideoInput) {
      return _react["default"].createElement(
        _button["default"],
        {
          onClick: onOpenVideoInput,
          "data-qa": "open-input"
        },
        "Upload a video"
      );
    }

    

    return shouldUseVideoInput
      ? _react["default"].createElement(
        _button["default"],
        {
          onClick: onOpenVideoInput,
          "data-qa": "open-input"
        },
        "Record a video"
      )
      : _react["default"].createElement(
        _button["default"],
        {
          onClick: onTurnOnCamera,
          "data-qa": "turn-on-camera"
        },
        "Turn my camera ON"
      );
  };

  return _react["default"].createElement(
    "div",
    null,
    isRecording &&
    _react["default"].createElement(_timer["default"], {
      timeLimit: timeLimit
    }),
    isRunningCountdown &&
    _react["default"].createElement(_countdown["default"], {
      countdownTime: countdownTime
    }),
    _react["default"].createElement(ActionsWrapper, null, renderContent())
  );
};

Actions.propTypes = {
  isVideoInputSupported: _propTypes["default"].bool,
  isInlineRecordingSupported: _propTypes["default"].bool,
  thereWasAnError: _propTypes["default"].bool,
  isRecording: _propTypes["default"].bool,
  isCameraOn: _propTypes["default"].bool,
  streamIsReady: _propTypes["default"].bool,
  isConnecting: _propTypes["default"].bool,
  isRunningCountdown: _propTypes["default"].bool,
  countdownTime: _propTypes["default"].number,
  timeLimit: _propTypes["default"].number,
  isReplayingVideo: _propTypes["default"].bool,
  useVideoInput: _propTypes["default"].bool,
  onTurnOnCamera: _propTypes["default"].func,
  onTurnOffCamera: _propTypes["default"].func,
  onOpenVideoInput: _propTypes["default"].func,
  onStartRecording: _propTypes["default"].func,
  onStopRecording: _propTypes["default"].func,
  onStopReplaying: _propTypes["default"].func,
  onConfirm: _propTypes["default"].func
};
var _default = Actions;
exports["default"] = _default;
