import React from 'react';
import PropTypes from 'prop-types';
import { Video, Headphones, Link, MoreVertical, MessageSquare } from "react-feather";
import { MenuIcon, ProfileImage } from "../index";
import { Scrollbars } from "react-custom-scrollbars";
import { Popover } from "antd";
import { videoTypes, documentTypes, imageTypes, audioTypes } from "../../../config/json/fileTypeMap";
import navigationRestrictions from "../../../config/navigationRestrictions";

const content = ({ onClickReplayMessage, onClickForwardMessage, onClickOpenGallery, msgObj, sendForwardMessages, onClickStar }) => (
    <div className={'chat-hover-menu'}>
        <div
            onClick={() => onClickReplayMessage(msgObj)}
            className={'document-row'}>Reply
        </div>
        {navigationRestrictions.chat.forwarding.active && <div className={'document-row'}
            onClick={() => onClickForwardMessage((users) => {
                sendForwardMessages(users, msgObj);

            })}>
            Forward message
        </div>}
        <div className={'document-row'} onClick={() => onClickStar(msgObj, false)}>Unstar Message</div>
        {msgObj.media && msgObj.media.length > 0 &&
            <div className={'document-row'} onClick={(documentTypes.indexOf(msgObj.message_type) > -1 || msgObj.message_type === "html/link") && msgObj.media[0] ? () => window.open(msgObj.media[0].content_url, "_blank") : () => onClickOpenGallery(msgObj.media)}>View</div>}
    </div>
);

//online
//offline
//busy
//other
const StarredMessage = ({ date, UserUrl, fullName, starredDate, messageType, msg, onClickReplayMessage, onClickForwardMessage, onClickOpenGallery, msgObj, sendForwardMessages, onClickStar, onClickContent }) => {

    let displayMsg = !msg ? (msgObj.media && msgObj.media.length > 0 && msgObj.media[0].content_title ? msgObj.media[0].content_title : msgObj.media[0].content_url) : msg;

    displayMsg = displayMsg ? displayMsg : "NO-CONTENT";

    const getFileIcon = (type) => {

        if (type === "application/msword" || type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document") {
            return require("../../../assets/img/docx.svg");
        } else if (type === "application/vnd.ms-excel" || type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
            return require("../../../assets/img/xls.svg");
        } else if (type === "text/csv") {
            return require("../../../assets/img/csv.svg");
        } else if (type === "text/plain") {
            return require("../../../assets/img/textSVG.svg");
        } else {
            return require("../../../assets/img/pdf.svg");
        }

    }

    let imgUrl = imageTypes.indexOf(messageType) > -1 && msgObj.media && msgObj.media.length > 0 && msgObj.media[0].thumbnail_url ? msgObj.media[0].thumbnail_url : null;
    return (
        <div className={'starred-message-wrapper'}>
            <div className={'starred-header-wrp d-flex align-items-center  justify-content-between'}>
                <div className={'message-owner-details d-flex align-items-center'}>
                    <div className={'img-wrp'}>
                        <div>
                            <ProfileImage
                                textType={'sm'}
                                size={'sm'}
                                src={UserUrl}
                                firstLatter={fullName ? fullName.split(' ').map(fullName => fullName[0]).join('').toUpperCase() : ""}
                            />
                        </div>
                    </div>
                    <div className={'owner-name'}>
                        {fullName.substring(0, 15) + (fullName.length > 15 ? '...' : '')}
                    </div>
                </div>

                <div className={'starred-date-wrp d-flex align-items-center'}>
                    <>
                        {date}
                    </>
                </div>
                <Popover
                    content={content({
                        onClickReplayMessage,
                        onClickForwardMessage,
                        onClickOpenGallery,
                        msgObj,
                        sendForwardMessages,
                        onClickStar
                    })}
                    trigger="hover">
                    <div className={'hover-more star-message ml-1'}>
                        <MenuIcon
                            icon={<MoreVertical />} />
                    </div>
                </Popover>
            </div>

            {/*body audio-clip / video-clip */}
            <div className={'starred-message-body-wrapper d-flex'} onClick={() => onClickContent(msgObj.created_at)}>
                {messageType !== 'text' &&
                    <>
                        {/*image*/}
                        {imageTypes.indexOf(messageType) > -1 && imgUrl !== null &&
                            <div className={'start-message-type file-starred '}>
                                <img alt="C" src={imgUrl} />
                            </div>
                        }

                        {audioTypes.indexOf(messageType) > -1 &&
                            <div className={'start-message-type audio-clip  '}>
                                <Headphones />
                            </div>
                        }

                        {videoTypes.indexOf(messageType) > -1 &&
                            <div className={'start-message-type video-clip'}>
                                <Video />
                            </div>
                        }

                        {messageType === 'text/html' &&
                            <div className={'start-message-type message-starred '}>
                                <MessageSquare />
                            </div>
                        }

                        {documentTypes.indexOf(messageType) > -1 &&
                            <div className={'start-message-type doc-starred'}>
                                <img className={'pdf-starred'} src={getFileIcon(messageType)} />
                            </div>
                        }

                        {messageType === 'html/link' &&
                            <div className={'start-message-type file-starred '}>
                                <Link />
                            </div>
                        }
                    </>
                }

                <div className={'started-message-text d-flex flex-column  '}>
                    <div className={'started-title '} title={displayMsg}>
                        {displayMsg.substring(0, 29) + (displayMsg.length > 29 ? '...' : '')}
                    </div>

                </div>

            </div>
        </div>
    )
};

// OutGoingCall.propTypes = {
//     status: PropTypes.string.isRequired,
// };

StarredMessage.defaultProps = {};

export default StarredMessage;
