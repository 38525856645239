import { notificationUrl } from "../config/baseUrls";
import * as auth from "../utils";

export class NotificationService {


    /**
    * api-method
    * Method to add a scheduled meeting notification
    * @param {Object} notificationObj notification details (Cannot be null)
    * notificationObj should contain following attributes
    *   @param {String} notificationHandler name of the handler Eg:- UCHandler
    *   @param {String} startDate Date as string in 'YYYY-MM-DD" format in UTC time the notification should occur
    *   @param {String} time time as string in 'HH:MM" format in UTC time the notification should occur
    *   @param {Boolean} isRecurrence Whether notification supports recurrence
    *   @param {String} notificationData JSON string containing notification specific data
    *   @param {String} appId application id Eg:- UC
    *   @param {String} appReferenceId Reference id to group by
    *   @param {String} appReferenceType Unique Id for Notification
    *   @param {String} createdBy user id of notification creator
    * @returns {Promise} Promise
    */
    static addScheduleNotification(notificationObj) {

        console.log(
            `%c [NotificationService - addScheduleNotification] - API INVOKED - Data : ${JSON.stringify(notificationObj)}`,
            "background: #990033; color: #ffffff; font-size:12px"
        )

        return new Promise((resolve, reject) => {
            let url = `${notificationUrl}/api/v1/notification/schedule`;

            fetch(url, {
                headers: {
                    "authorization": auth.getBearerToken(),
                    "Content-Type": "application/json"
                },
                method: "POST",
                body: JSON.stringify(notificationObj)
            }).then(res => {
                return res.json();
            }).then(data => {
                console.log(
                    `%c [NotificationService - addScheduleNotification] - API RETURNED - Data : ${JSON.stringify(data)}`,
                    "background: #cc9900; color: #ffffff; font-size:12px"
                );
                resolve(data);
            }).catch(ex => {
                console.error('[NotificationService - addInstantNotification] - ERROR OCCURRED', ex);
                reject(ex);

            });

        })

    }


    /**
    * api-method
    * Method to update a scheduled meeting notification
    * @param {Object} notificationObj notification details (Cannot be null)
    * notificationObj should contain following attributes
    *   @param {String} notificationHandler name of the handler Eg:- UCHandler
    *   @param {String} startDate Date as string in 'YYYY-MM-DD" format in UTC time the notification should occur
    *   @param {String} time time as string in 'HH:MM" format in UTC time the notification should occur
    *   @param {Boolean} isRecurrence Whether notification supports recurrence
    *   @param {String} notificationData JSON string containing notification specific data
    *   @param {String} appId application id Eg:- UC
    *   @param {String} appReferenceId Reference id to group by
    *   @param {String} appReferenceType Unique Id for Notification
    *   @param {String} createdBy user id of notification creator
    * @returns {Promise} Promise
    */
    static updateScheduleNotification(notificationObj) {

        console.log(
            `%c [NotificationService - updateScheduleNotification] - API INVOKED - Data : ${JSON.stringify(notificationObj)}`,
            "background: #990033; color: #ffffff; font-size:12px"
        )

        return new Promise((resolve, reject) => {
            let url = `${notificationUrl}/api/v1/notification/schedule`;

            fetch(url, {
                headers: {
                    "authorization": auth.getBearerToken(),
                    "Content-Type": "application/json"
                },
                method: "PUT",
                body: JSON.stringify(notificationObj)
            }).then(res => {
                return res.json();
            }).then(data => {
                console.log(
                    `%c [NotificationService - updateScheduleNotification] - API RETURNED - Data : ${JSON.stringify(data)}`,
                    "background: #cc9900; color: #ffffff; font-size:12px"
                );
                resolve(data);
            }).catch(ex => {
                console.error('[NotificationService - addInstantNotification] - ERROR OCCURRED', ex);
                reject(ex);

            });

        })

    }

    /**
    * api-method
    * Method to add a instant meeting notification
    * @param {Object} notificationObj notification details (Cannot be null)
    * notificationObj should contain following attributes
    *   @param {String} notificationHandler name of the handler Eg:- UCHandler
    *   @param {String} notificationData JSON string containing notification specific data
    *   @param {String} appId application id Eg:- UC
    *   @param {String} appReferenceId Reference id to group by
    *   @param {String} appReferenceType Unique Id for Notification
    *   @param {String} createdBy user id of notification creator
    * @returns {Promise} Promise\
    */
    static addInstantNotification(notificationObj) {

        console.log(
            `%c [NotificationService - addInstantNotification] - API INVOKED - Data : ${JSON.stringify(notificationObj)}`,
            "background: #990033; color: #ffffff; font-size:12px"
        )

        return new Promise((resolve, reject) => {
            let url = `${notificationUrl}/api/v1/notification/instant`;

            fetch(url, {
                headers: {
                    "authorization": auth.getBearerToken(),
                    "Content-Type": "application/json"
                },
                method: "POST",
                body: JSON.stringify(notificationObj)
            }).then(res => {
                return res.json();
            }).then(data => {
                console.log(
                    `%c [NotificationService - addInstantNotification] - API RETURNED - Data : ${JSON.stringify(data)}`,
                    "background: #cc9900; color: #ffffff; font-size:12px"
                );
                resolve(data);
            }).catch(ex => {
                console.error('[NotificationService - addInstantNotification] - ERROR OCCURRED', ex);
                reject(ex);

            });

        })

    }
}
