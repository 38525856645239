import { coBrowseServiceUrl } from "../config/baseUrls";
import { fetchDefaults } from "./fetchWrapper";
import * as logger from '../lib/logSdk/logger';


var apiFetch = fetchDefaults(fetch);
export class coBrowseService {  
  /* static createCase = (command,postData) => {
    return apiFetch(`${coBrowseServiceUrl}/config`, {
      method: "post",
      body: JSON.stringify(postData)
    })
      .then(function(response) {
        return response.json();
      })
      .then(function(data) {
        return data.isSuccess;
      })
      .catch(error => {
        return false;
      });
  }; */

  static getCobrowsingConfigs = () => {
    return apiFetch(
      `${coBrowseServiceUrl}/cobrowsingmanager/configs`,
      {
        method: "get"
      }
    )
      .then(function(response) {
        return response.json();
      })
      .then(function(data) {
        return data.isSuccess ? data.result : [];
      })
      .catch(error => {
        console.error("getCobrowsingConfigs", error);
        logger.error("coBrowseService","getCobrowsingConfigs", error.message);
        return [];
      });
  };
  
}
