import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from "react-router-dom";
import moment from 'moment';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import {
    ButtonRounded, ProfileImage, TextBoxWithIcon, MenuIcon,
    PanelTitle, SearchResult
} from '../../common';
import { Check, ArrowRight, Smile, X } from 'react-feather';
import { Route, Switch } from "react-router-dom";
import ConsoleComponent from "../console/MainConsole";
import CreatNewPanelComponent from "../CreateNewPanel/CreatNewPanel";
import MyProfilePanelComponent from "../MyProfilePanel/MyProfilePanel";
import { Scrollbars } from 'react-custom-scrollbars';
import { searchMessages } from '../../../service/chatService';
import { getUserId } from "../../../utils";
import * as chatSearchActions from "../../../redux/actions/chatSearchActions";
import { subscribeToEvents } from "../../../utils/eventEmitter";
import renderState from "../../../service/renderStateHandler";

function ChatSearchPanelComponent(props) {

    let location = useLocation();
    let history = useHistory();

    const [searchMessage, setSearchMessage] = useState("");
    const [searchResult, setSearchResult] = useState({ status: "NOT_STARTED", searchData: [] });

    function onPressChatFilterIcon(_filter) {
        console.log('event fire...');
        console.log(_filter);
    }

    useEffect(() => {
        subscribeToEvents("clear_chat_search", data => {
            setSearchMessage("");
            setSearchResult({ status: "NOT_STARTED", searchData: [] });
        });

    }, [])

    let onChangeHandler = event => {
        if (props.selected_profile && props.selected_profile.username) {
            setSearchMessage(event.target.value);
        }
    };

    let keyPressHandler = event => {
        if (props.selected_profile && props.selected_profile.username) {
            if (event.key === "Enter" && searchMessage) {

                //call api
                searchMessages(getUserId(), props.selected_profile.username, props.selected_profile.conversation_type, searchMessage).then(searchRes => {
                    setSearchResult({ status: "DONE", searchData: searchRes ? searchRes : [] });

                }).catch(ex => {
                    setSearchResult({ status: "DONE", searchData: [] });
                });
            }
        }
    };

    let onCloseSearch = () => {

        let url = location.pathname.replace("/search", "");
        history.push(url);
        renderState.focusToLastRead = true;
        renderState.scrollReadyState = false;
        props.chat_search_actions.resetSearchMessages();
        props.onClickCloseInfoPanel();
    }

    let searchResultClickHandler = (timestamp) => {
        //dispatch
        if (props.selected_profile && props.selected_profile.username) {

            let userId = getUserId();

            props.chat_search_actions.getInitialSearchMessages(props.selected_profile.conversation_type, userId, props.selected_profile.username, timestamp, 20);


        }

    }

    let noDataFound = <></>;

    if (searchResult.status === "DONE" && (!searchResult.searchData || searchResult.searchData.length === 0)) {
        //show no data found
        noDataFound = <div>No Data Found</div>;
    }

    return (
        <>
            <div className={`chat-info-wrapper ${props.isOpenInfoPanel ? 'show-this' : 'hide-this'}`}>


                <div className={'ch-header-wrapper'}>
                    <>
                        <div className={'modal-header d-flex justify-content-between '}>
                            <PanelTitle title={props.title} />
                            <MenuIcon icon={<X />}
                                onClick={onCloseSearch}
                                size={'sm'} />
                        </div>
                    </>
                </div>

                <div className={'ch-body-wrapper search-panel'}>
                    <div className={`chat-search-wrapper`}>
                        <div className={'search-wrapper d-flex align-items-center '}>
                            <TextBoxWithIcon onChangeHandler={onChangeHandler}
                                onKeyPressHandler={keyPressHandler}
                                textValue={searchMessage}
                                style={'white'}
                                placeholder={'Search'}
                                size={'form-control-lg'} />
                        </div>

                        <Scrollbars
                            autoHide thumbMinSize={0}
                            style={{ width: 300, height: props.viewPointHeight - 200 }}>
                            {noDataFound}
                            <div className={'search-panel-body-wrapper'}>

                                {searchResult.searchData.map(result => {
                                    let key = `SEARCH_RESULT:${result.mid}`;
                                    return (<div className={'search-content-wrp d-flex flex-column'} key={key} onClick={() => searchResultClickHandler(result.created_at)}>
                                        <SearchResult
                                            date={moment.unix(result.created_at / 1000).format("DD-MM-YYYY h:mm A")}
                                            msg={result.message} />
                                    </div>)
                                })}
                                {/* <div className={'search-content-wrp d-flex flex-column'}>
                                    <SearchResult
                                        date={'yesterday'}
                                        msg={'Lorem Ipsum is simply dummy  text of the printing and type setting'} />
                                </div>

                                <div className={'search-content-wrp d-flex flex-column'}>
                                    <SearchResult
                                        date={'yesterday'}
                                        msg={'Lorem Ipsum is simply dummy  text of the printing and type setting'} />
                                </div> */}
                            </div>

                        </Scrollbars>
                    </div>
                </div>
            </div>
        </>
    );
}

ChatSearchPanelComponent.propTypes = {
    selected_profile: PropTypes.object.isRequired,
    chat_search_actions: PropTypes.object.isRequired
};

function mapDispatchToProps(dispatch) {
    return {
        chat_search_actions: bindActionCreators(chatSearchActions, dispatch)
    };
}

function mapStateToProps(state) {
    return {
        selected_profile: state.selected_profile
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ChatSearchPanelComponent);
