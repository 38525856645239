import React from 'react';
import PropTypes from 'prop-types';
import { OnlineStatus, ProfileImage } from "../index";


const ChatUser = ({
    src,
    fullName,
    lstMsg,
    lastSeen,
    count,
    firstLatter,
    status,
    isTyping,
    active,
    callStatus,
    connectStatus,
    imgSize,
    activePlayer,
    conversation_type,
    isMeeting
}) => (
    <div className={`chat-user-wrapper animated fadeIn ${active ? 'active' : ''}`}>
        <div className={`chat-user-inner-wrp d-flex justify-content-between ${status}`}>
            <div className={`c-left d-flex`}>
                <ProfileImage
                    isMeeting={isMeeting}
                    conversation_type={conversation_type}
                    status={status}
                    type={'chat-user'}
                    size={imgSize}
                    firstLatter={fullName ? fullName.split(' ').map(fullName => fullName[0]).join('').toUpperCase() : ""}
                    alt={firstLatter} src={src}
                    padding="4px"
                    fontSize="0.8rem" />

                {status === 'caller' &&
                    <div className={`user-content-wrp caller ${imgSize}`}>
                        <div className={'c-full-name caller'}>
                            {fullName}
                        </div>

                        <div className={'c-last-msg caller'}>
                            {callStatus}
                        </div>
                        <div className={'connect-status caller'}>
                            {connectStatus}
                        </div>
                    </div>
                }

                {status !== 'caller' &&
                    <div className={`user-content-wrp ${imgSize}`}>
                        <div className={'c-full-name'}>
                            {fullName}
                        </div>
                        {!isTyping &&
                            <div className={'c-last-msg'} style={{
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                maxWidth: "150px"
                            }}>
                                {lstMsg}
                            </div>
                        }

                        {/* {!isTyping && !lstMsg &&
                    <div className={'c-last-msg'}>
                        Hi there !
                    </div>
                    } */}
                        {isTyping &&
                            <div className={'c-last-msg typing'}>
                                typing...
                    </div>
                        }
                    </div>
                }
            </div>
            <div className={`c-right`}>
                {status !== 'caller' &&
                    <div className={'time-count-wrapper'}>
                        <div className={'last-seen'}>
                            {lastSeen}
                        </div>
                        {count !== 0 &&
                            <div className={'count'}>
                                {count}
                            </div>
                        }

                        {activePlayer === 'video' &&
                            <div className={'user-on-live d-flex justify-content-between align-items-center '}>
                                <div className='blink' />
                                <div className={`text`}>
                                    Live
                        </div>

                            </div>
                        }
                        {activePlayer === 'whiteboard' &&
                            <div className={'user-on-live d-flex justify-content-between align-items-center '}>
                                <div className='blink' />
                                <div className={`text`}>
                                    WB
                        </div>

                            </div>
                        }
                    </div>
                }
            </div>
        </div>
    </div>
);

ChatUser.propTypes = {
    isTyping: PropTypes.bool.isRequired,
};

ChatUser.defaultProps = {
    src: null,
    firstLatter: 'A',
    status: 'none',
    active: false,
};

export default ChatUser;
