import * as baseUrls from "../config/baseUrls";
import { fetchDefaults } from "./fetchWrapper";
import * as logger from "../lib/logSdk/logger";
var apiFetch = fetchDefaults(fetch);

export default class TmacService {
  static login = (username) => {
    const postData = {
      agentId: "",
      deviceId: username,
      forceReload: false,
      jsonData:'{"msLogin":false,"pbxLogin":false,"stationPassword":"","customAuthData":null}',
      lanId: username,
      password: "",
      sessionKey: "",
      tmacServer: "",
    };

    
    return apiFetch(`${baseUrls.tmacProxy}/Login`,{method: "post",body: JSON.stringify(postData),})
      .then(function (response) {
        return response.json();
      })
      .then(function (responseData) {        
        if(responseData?.ResultCode === 2){
          const user = {userId:responseData.d?.Data?.StationID,userName:username,firstName:responseData.d?.Data?.AgentName,lastName:undefined,emailId:undefined,mobileNumber:undefined,userType:"agent","gender":"Male",avatarURL:undefined,orgUnit:0,orgName:"",profile:"Agent",contacts:null,conversation_type:0};         
          return user;
        }else{
          throw new Error("Fail to login");
        }
      })
      .catch((error) => {
        console.error("TmacService", error);
        logger.error("TmacService", error.message);
        return null;
      });
  };
}
