import { Connection } from './connection.js';
import { MsgType, Message, FixedHeaderSerializer, StringBuffer } from './FixedHeaderSerializer.js';
import { Persist } from './Persist.js';
import { ReliableSender } from './ReliableSender.js';
import { InteractionEndModel } from './interaction.js';
import { Logger } from "./logger.js";

class Livechat {

  rsender;

  constructor (endPoint) {
    Logger.debug(`Initializing Livechat`);
    this.url = (endPoint + "/ws-chat-proxy/ws/chat");

    this.session = {
      mid: 1000,
      lastRecvMid: -1,
      sessionID: null,
      bEndReceived: "false",
      endReason: null,
      auth: null,
      refreshToken: null,
      workspace: null,
      workspaces: [],
      userid: null,
      bConnected: false,
      interactions: {},
      validationAttributes: {},
      chatSessionId: null
    };

    this.setEndReceived(false);
    this.setConnected(false);
    this.setSessionID("");
    this.traceMid = [];

    this.hbInterval = null;
    this.connection = new Connection(this.url, this);
    this.rsender = new ReliableSender(this.connection);
    this.persist = new Persist(this.session);
    //this.auth = "na";
    //this.userid = "na";

    Logger.debug(`Livechat Initialized`);
  }

  setInteractionId = (interactionId, data) => {
    if (this.session.interactions[ interactionId ] == null)
      this.session.interactions[ interactionId ] = data;
  }

  deleteInteractionId = interactionId => {
    delete this.session.interactions[ interactionId ];
  }

  setRefreshToken = refreshToken => {
    this.session.refreshToken = refreshToken;
  }

  getRefreshToken = () => {
    return this.session.refreshToken;
  }

  setWorkspace = workspace => {
    this.session.workspace = workspace;
  }

  getWorkspace = () => {
    return this.session.workspace;
  }

  setMyWorkspaces = (workspaces) => {
    this.session.workspaces = workspaces;
  }

  getMyWorkspaces = () => {
    return this.session.workspaces;
  }

  getmid = () => {
    return (this.session.mid++);
  }

  setLastRecvMid = mid => {
    return this.session.lastRecvMid = mid;
  }

  getLastRecvMid = () => {
    return this.session.lastRecvMid;
  }

  setSessionID = (sid) => {
    this.session.sessionID = sid;
  }

  getSessionID = () => {
    return this.session.sessionID;
  }

  setChatSessionId = (chatSid) => {
    this.session.chatSessionId = chatSid;
  }

  getChatSessionId = () => {
    return this.session.chatSessionId;
  }

  setEndReceived = (bStatus, endReason) => {
    this.session.bEndReceived = bStatus;
    this.session.endReason = endReason;
  }

  setConnected = bStatus => {
    this.session.bConnected = bStatus;
  }

  isConnected = () => {
    return this.session.bConnected;
  }

  isEndReceived = () => {
    return this.session.bEndReceived;
  }


  /**
   *  = = = = = = = API START = = = = = = = = = = = 
   *
   * api-method
   */
  start = (custDetailsObj, fetch) => {

    this.userData = custDetailsObj;
    this.fetchStatus = fetch;
    this.__onConnectOrReconnect = this.__onConnect;
    this.$connect();
    this.traceMid = [];
  }

  open = () => {
    Logger.debug(`Trying to open an existing session (if it exists)`);
    if (!this.persist.loadSession()) {
      Logger.info(`There is no existing session available`);
      return false;
    }

    this.session = this.persist.session;
    Logger.info(`An existing session was found: ${JSON.stringify(this.session)}`);

    this.__onConnectOrReconnect = this.__onReconnect;

    this.$connect();

    return true;
  }

  end = reason => {
    Logger.info(`Ending session with reason: ${reason}`);
    //This is to send the session-end message
    this.$sendProxyMessage(MsgType.SE, { auth: this.session.auth, from: this.session.userid, type: "end", content: reason },

      (mid, bStates, mState) => {
        if (!bStates) {
          // Handle end delivered
        }
      }
    );

    this.setEndReceived(true, reason);
    clearInterval(this.hbInterval);
    this.persist.clear();

    setTimeout(() => {
      this.connection.end(reason);
    }, 2000);

  }

  leaveInteraction = (interactionId, reason, customData = {}) => {
    try {
      Logger.info(`Leaving interaction ${interactionId} with reason: ${reason}`);
      var endObject = new InteractionEndModel();
      endObject.interactionId = interactionId;
      endObject.reason = reason;
      endObject.customData = customData;
      endObject.userId = this.session.userid;

      delete this.session.interactions[ interactionId ];

      this.$sendProxyMessage(MsgType.APP, { auth: this.session.auth, from: this.session.userid, type: "leave-interaction", content: JSON.stringify(endObject), to: "service:GMS" });
    } catch (e) {
      Logger.error(`Error in leaveInteraction`, e);
    }
  }

  uploadFile = (type, fileObj) => {
    let file = fileObj[ 0 ];
    let packetSize = 1024 * 32; //textBufferSize is 64 KB. Let us transfer only 32 KB in text mode.
    let fileSize = file.size;

    let attributes = {};
    attributes[ "fileName" ] = '' + file.name;
    attributes[ "sessionId" ] = '' + (this.getChatSessionId() == null ? this.getSessionID() : this.getChatSessionId());

    let reader = new FileReader();
    Logger.info(`Uploading File : ${file.name} of size ${fileSize}. Message type : ${type} `);

    if (fileSize <= packetSize) {
      let fileContent = "";

      reader.onloadend = () => {
        fileContent = reader.result
          .replace('data:', '')
          .replace(/^.+,/, '');
        Logger.info(`Converted file content to Base64 String. Uploading file Data.`);
        this.$sendProxyMessage(MsgType.FILE, { auth: this.session.auth, from: this.session.userid, type: type, content: fileContent, attributes: attributes });
      };
      reader.readAsDataURL(file);
    }
    else {
      this.$sendProxyMessage(MsgType.FILE, { auth: this.session.auth, from: this.session.userid, type: type, content: '', attributes: attributes }, () => {
        let rawData = new ArrayBuffer();
        let con = this.connection;

        reader.loadend = function () {
          Logger.info('load end');
        }

        reader.onload = function (e) {
          if (e.target.readyState == FileReader.DONE) {
            rawData = e.target.result;
            Logger.info('Uploading File Data');
            con.sendMessage(rawData);
          }
        }
        reader.readAsArrayBuffer(file);

      });

    }
  }

  sendLv2Message = (type, content, to = null, attributes = {}, onDeliveredCb, externalMid = null) => {
    if (!this.isConnected())
      Logger.warn(`sendLv2Message: Enqueuing message of type ${type} even though not connected`);

    if (this.session.sessionID === "") {
      Logger.error(`sendLv2Message: Cannot send message of type ${type} as there is no session Id available`);
      return;
    }

    Logger.info(`Preparing to send message with type: ${type} and content:\n${content}`)

    this.$sendProxyMessage(MsgType.APP, {
      auth: this.session.auth,
      from: this.session.userid,
      type: type,
      content: content,
      to: to,
      attributes: attributes,
      org: this.getWorkspace()
    }, onDeliveredCb, externalMid);
  }

  sendLv2StreamMessage = (type, content, to = null, attributes = {}) => {
    if (!this.isConnected())
      Logger.warn(`sendLv2StreamMessage: Enqueuing message of type ${type} even though not connected`);

    if (this.getSessionID() === "") {
      Logger.error(`sendLv2StreamMessage: Cannot send message of type ${type} as there is no session Id available`);
      return;
    }

    let msg = new Message();
    msg.setType(MsgType.STRM);
    msg.setMessageID("0000");
    msg.setSubType("");
    msg.setSessionID(this.getSessionID());
    let objContent = { auth: this.session.auth, from: this.session.userid, type: type, content: content, to: to, attributes: attributes, org: this.getWorkspace() }
    msg.setExtStrContent(JSON.stringify(objContent));

    let serializer = new FixedHeaderSerializer();
    let txtMsg = new StringBuffer();
    serializer.serialize(msg, txtMsg);
    let strMsg = txtMsg.toString();

    this.connection.sendMessage(strMsg);
  }

  sendLv2LogMessage = (type, content, to = null, attributes = {}) => {
    if (!this.isConnected())
      Logger.warn(`sendLv2LogMessage: Enqueuing message of type ${type} even though not connected`);

    let msg = new Message();
    msg.setType(MsgType.LOG);
    msg.setMessageID("0000");
    msg.setSubType("");
    msg.setSessionID(this.getSessionID());
    let objContent = { auth: '', from: this.session.userid, type: type, content: content, to: to, attributes: attributes, org: this.getWorkspace() }
    msg.setExtStrContent(JSON.stringify(objContent));

    let serializer = new FixedHeaderSerializer();
    let txtMsg = new StringBuffer();
    serializer.serialize(msg, txtMsg);
    let strMsg = txtMsg.toString();

    this.connection.sendMessage(strMsg);
  }

  /**
   * 
   * Sample messageFilter
   * 
   * { default-rest:0, "offer":1, "typing-state":1 } 
   * 
   * Meaning : 
   * 
   * send a copy "offer" &  "typing-state" messages to this listener
   * don't send any other messages
   * 
   * Sample proxyListener
   * 
   * { onEnd=()=>{},  
   *   onLv2Message=(...)=>{}, 
   *   onStartSuccess=( sid )=>{},
   *   onStartFailed=( error )=>{} 
   * }
   * 
   */
  addProxyListener = (proxyListener, messageFilter) => { }

  onEnd = (reason) => { Logger.info(`Unhandled onEnd(${reason})`); }

  onLv2Message = (type, content, from, attribs) => { Logger.info(`Unhandled onMessage`); }

  onLv2StreamMessage = (type, content, from, attribs) => { Logger.info(`Unhandled onStreamMessage`); }

  onStartSuccess = (sid, cnt) => { Logger.info(`Unhandled onStartSuccess`); }

  onStartFailed = error => { Logger.info(`Unhandled onStartFailed`); }

  processConnStateChanged = state => { Logger.info(`Unhandled processConnStateChanged (" + state + ")`); }

  onConnect = () => { Logger.info(`Unhandled onConnect`); }

  onReconnect = () => { Logger.info(`Unhandled onReconnect`); }

  onDisconnect = reason => { Logger.info(`Unhandled onDisconnect (${reason})`); }

  setUserId = userid => { this.session.userid = userid; }

  setAuthToken = authToken => { this.session.auth = authToken; }

  getAuthToken = () => { return this.session.auth; }

  getUserId = () => { return this.session.userid; }

  setValidationAttributes = attributes => { this.session.validationAttributes = attributes; }

  getValidationAttributes = () => { return this.session.validationAttributes; }

  onCaptchaSuccess = (data) => {
    Logger.info(`Unhandled onCaptchaSuccess`);
  }

  onCaptchaFailed = (error) => {
    Logger.info(`Unhandled onCaptchaFailed`);
  }

  /*

      mid : 1000,
      lastRecvMid : -1,
      sessionID : null,
      bEndReceived : "false",
      endReason : null,
      auth: null,
      userid : null,
      bConnected : false

  */
  /**
  *  = = = = = = = = = API END = = = = = = = = = = = = = = =
  */

  $sendProxyMessage = (fhType, objContent, cbOnSend, externalMid = null) => {
    if (!this.isConnected())
      Logger.warn(`$sendProxyMessage: Enqueuing message of type ${fhType} even though not connected`);

    let msg = new Message();
    msg.setType(fhType);
    msg.setMessageID(this.getmid());
    msg.setSubType("");
    msg.setSessionID(this.getSessionID());
    msg.setExtStrContent(JSON.stringify(objContent));

    let serializer = new FixedHeaderSerializer();
    let txtMsg = new StringBuffer();
    serializer.serialize(msg, txtMsg);
    let strMsg = txtMsg.toString();

    var mid = msg.getMessageID();
    this.rsender.enqueueMessage(mid, strMsg,
      (mid, bStates, mState) => {
        if (cbOnSend != undefined)
          cbOnSend(mid, bStates, mState);
        this.processConnStateChanged(bStates); // new method
      }, externalMid
    );
  }

  __onConnect = () => {
    Logger.info(`onConnect Event triggered`);

    this.processConnStateChanged(true);
    this.onConnect();
    this.setConnected(true);

    if (!this.session.userid)
      Logger.warn("UserID has not been set");

    if (!this.session.auth)
      Logger.warn("AuthToken has not been set");

    this.setLastRecvMid(-1);

    this.$sendProxyMessage(MsgType.SS,
      {
        auth: this.session.auth, from: this.session.userid, type: "start", content: this.userData,
        attributes: this.fetchStatus
      },
      (mid, bStates, mState) => {
        if (!bStates) {
          this.onStartFailed();
        }
      }
    );

    this.__onConnectOrReconnect = this.__onReconnect;
  }

  __onReconnect = () => {
    Logger.info(`onReconnect Event triggered`);

    this.setConnected(true);
    this.processConnStateChanged(true);
    this.onReconnect();

    if (this.isEndReceived()) {
      Logger.info(`Session has already been ended with reason: ${this.session.endReason}`)
      return;
    }

    this.$startHB();
  }

  __onConnectOrReconnect = () => { }

  $connect = () => {
    this.connection.connect();
  }

  $sendAck = mainMsg => {
    try {
      let reqMsg = mainMsg;
      reqMsg.setType(MsgType.ACK);
      reqMsg.setExtStrContent("");

      let serializer = new FixedHeaderSerializer();
      let txtMsg = new StringBuffer();
      serializer.serialize(reqMsg, txtMsg);
      let strMsg = txtMsg.toString();

      Logger.debug(`Sending ACK for ${txtMsg}`);
      this.connection.sendMessage(strMsg);
    } catch (e) {
      Logger.error(`Error in $sendAck`, e)
    }
  }

  $startHB = () => {
    Logger.info(`Starting to send HB`);

    if (this.session.sessionID === "") {
      Logger.error(`sendLv2Message: Cannot send HB messages as there is no session Id available`);
      return;
    }

    if (this.hbInterval != null)
      clearInterval(this.hbInterval); //Clear the interval before setting, just in case if it already exists

    this.hbInterval = setInterval(
      () => {
        this.$sendProxyMessage(MsgType.HB, {});
      }, 10000);
  }

  $onProxyMessage = (strContent, sid, proxyType) => {
    var json = JSON.parse(strContent);

    if (json.type == "startstatus") {
      var cnt = json.content;
      if ((typeof cnt) == "string")
        cnt = JSON.parse(cnt);

      if (cnt.status) {
        this.setSessionID(sid);
        this.persist.storeSession();

        if (cnt.tchatid) { //This condition will work when Chat Server is used, as Chat server will send tchatsid
          this.onStartSuccess(sid, cnt);
          this.setChatSessionId(cnt.tchatid);
        }
        else //Else we assume this is sent by CommProxy in cloud
          this.onStartSuccess(sid, null);

        this.$startHB();
      }
      else
        this.onStartFailed(json.content);

      return;
    }

    if (proxyType == MsgType.STRM)
      this.onLv2StreamMessage(json.type, json.content, json.from, json.attributes);
    else
      this.onLv2Message(json.type, json.content, json.from, json.attributes);
  }

  __onStrMessage = strMessage => {
    try {
      let serializer = new FixedHeaderSerializer();
      let fhMessage = new Message();
      if (!serializer.deserialize(strMessage, fhMessage)) {
        Logger.error(`Unable to deserialize message`);
        return;
      }

      let msgType = fhMessage.getType();
      let lastmid = this.getLastRecvMid();
      let mid = fhMessage.getMessageID();
      let content = fhMessage.getExtStrContent();

      Logger.info(`Processing message with type: ${msgType} and content: ${content}`);

      if (msgType === MsgType.ACK) {
        this.persist.updateSent(mid);
        this.rsender.ackReceived(mid);
        return;
      }
      else if (msgType === MsgType.NACK) {
        this.rsender.nackReceived(mid);
        if (content !== "") {
          try {
            if (JSON.parse(content).reasonCode === "NoSessionFound") {
              clearInterval(this.hbInterval);
              this.persist.clear();
              this.onEnd("NoSessionFound");
              this.connection.end("NoSessionFound");
            }
          } catch (e) {
            Logger.error(`Unable to process NACK content due to error`, e);
          }
        }
        return;
      }
      else if (msgType === MsgType.STRM) {
        this.$onProxyMessage(content, fhMessage.getSessionID(), MsgType.STRM);
        return;
      }
      else
        this.$sendAck(fhMessage);

      if (lastmid !== -1) {
        if ((lastmid + 1) !== mid) {
          Logger.debug(`Last received mid: ${lastmid}, Current mid: ${mid}`);

          if (lastmid === mid) {
            Logger.error(`Duplicate message, ignored`);
            return;
          }
          else if (lastmid < mid) {
            Logger.warn(`Potential message miss`);
          }
          else {
            Logger.warn(`unusual mid sequence`);
          }
        }
      }

      this.setLastRecvMid(mid);
      this.persist.updateReceived(mid);

      if (msgType === MsgType.APP) {
        if (this.traceMid.includes(mid)) {
          Logger.error(`Duplicate message, ignored. mid: ${mid} already received.`);
          return;
        }
        this.traceMid.push(mid);
        this.$onProxyMessage(content, fhMessage.getSessionID(), MsgType.APP);
        return;
      }

      if (msgType == MsgType.SE) {
        var json = JSON.parse(content);
        var endReason = "";

        if (json.content != null && JSON.parse(json.content).reason != null) //This check is done to handle Chat Server session end related messages
          endReason = JSON.parse(json.content).reason;
        else
          endReason = json.reasonCode; //This is for normal cloud solutions

        this.setEndReceived(true, endReason);

        clearInterval(this.hbInterval);
        this.persist.clear();
        this.onEnd(endReason);

        return;
      }

    }
    catch (e) {
      Logger.error(`Error in onNewMessage`, e);
    }
  }

  __onDisconnect = reason => {
    Logger.info(`onDisconnect Event triggered with reason: ${reason}`);

    this.setConnected(false);
    this.onDisconnect(reason == null ? "" : reason);
    clearInterval(this.hbInterval);

    if (this.isEndReceived() === false) //No need to call this event when session has already ended
      this.processConnStateChanged(false);

    if (this.isEndReceived()) {
      Logger.info(`Session has already been ended with reason: ${this.session.endReason}`)
      //this.onEnd( this.endReason ); onEnd not required for solicited ends
      return;
    }

    if (this.session.sessionID == "") {
      Logger.info(`No reconnect as session has not been started`);
      return;
    }

    // we gonna reconnect
    this.$connect();
  }
}

export { Livechat };