import React, { useState, useEffect } from "react";
import MenuIcon from "../MenuIcon/MenuIcon";
import { MicOff, Mic } from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import logo_md from "../../../assets/img/bg/login/logo_md.svg";

const WebNotification = () => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const [isMute, setMute] = useState(false);

  useEffect(() => {
    try {
      if (!("Notification" in window)) {
        console.log("This browser does not support desktop notification");
      } else {
        Notification.requestPermission();
      }
    } catch (error) {
      console.error("WebNotification", error);
    }
  }, []);

  useEffect(() => {
    try {
      if (state.webNotification.message && state.webNotification.onFocussed !== true)
        showNotification(
          state.webNotification.message,
          state.webNotification.duration
        );
    } catch (error) {
      console.error(
        "WebNotification",
        "state.webNotification.isShowNotification"
      );
    }
  }, [state.webNotification.controls.isShowNotification]);

  const showNotification = (msg, durations) => {
      try {
        var options = {
            body: msg,
            icon: require("../../../assets/img/notification.png"),
            dir: "ltr",
          };
          var notification = new Notification("TetherfiCloud", options);
      
          setTimeout(function () {
            notification.close();
          }, durations * 1000);
          notification.onclick = function () {
            window.focus();
          };
      } catch (error) {
        console.error(
            "WebNotification",
            "showNotification"
          );
      }   
  };

  return (<></>);
};

export default WebNotification;
