import React from 'react';
import PropTypes from 'prop-types';
import { UserPlus, Pause, Mic, MicOff, PhoneOutgoing, Airplay } from "react-feather";
import { ChatUser, MenuIcon, TitleWithSmallText, UserImage, ParticipationImage } from "../index";
import { Scrollbars } from "react-custom-scrollbars";
import ReactPlayer from "react-player";
import { Document, Page } from "react-pdf";


//online
//offline
//busy
//other
const AudioPreview = ({ src, type, onDocumentLoadSuccess }) => (
    <div className={`media-g-preview-section image`}>
        <audio controls>
            <source src={src} type={type} />
        </audio>
    </div>
);

// OutGoingCall.propTypes = {
//     status: PropTypes.string.isRequired,
// };

AudioPreview.defaultProps = {};

export default AudioPreview;
