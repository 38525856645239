import React from 'react';
import { connect } from "react-redux";
import { Button } from "../index";
import navigationRestrictions from "../../../config/navigationRestrictions";

const MissedCallButton = ({ OnClickOutGoingCall, callTo, ...props }) => {

    let showButton = false;

    if (props.presence_to && (props.presence_to.status === "online" ||
        props.presence_to.status === "available") &&
        props.call && props.call.callStatus === "Idle") {

        showButton = true;
    }


    return (
        <>
            {showButton && navigationRestrictions.calls.active && navigationRestrictions.calls.audio_calls.active && <div className={'right-side d-flex justify-content-center align-items-center'}>
                <div className={`call-back-btn `}>
                    <Button
                        title={'call back'}
                        size={'sm'}
                        onClick={OnClickOutGoingCall} />
                </div>
            </div>}
        </>

    )
};

function mapStateToProps(state, ownProps) {
    return {
        presence_to: ownProps.callTo ? state.presence.data[ownProps.callTo] : null,
        call: state.call
    };
}

export default connect(mapStateToProps)(MissedCallButton);
