import * as types from "../actions/actionTypes";
import produce from "immer";
import {
    __APP_INTERACTION_MODE__
} from "../../config/baseUrls";
import { setReceivedTypingEvent, deleteReceivedTypingEvent } from "../../service/typingDetector";

export const typingReducer = produce((draft = {}, action) => {
    switch (action.type) {
        case types.SET_TYPING:
            {
                if (!draft[action.typingInfo.user_id] || (draft[action.typingInfo.user_id] && draft[action.typingInfo.user_id].acw !== true)) {
                    draft[action.typingInfo.user_id] = { is_typing: true, typing_user: action.typingInfo.typing_user };
                    setReceivedTypingEvent(action.typingInfo.user_id);
                }

                return draft;

            }
        case types.REMOVE_TYPING:
            {
                if (draft[action.typingInfo.user_id] && draft[action.typingInfo.user_id].acw !== true) {
                    draft[action.typingInfo.user_id] = { is_typing: false };
                    deleteReceivedTypingEvent(action.typingInfo.user_id);
                }

                return draft;

            }
        case types.SET_ACW_TYPING:
            {
                if (draft[action.typingInfo.user_id]) {
                    draft[action.typingInfo.user_id] = { is_typing: false, acw: true };
                    deleteReceivedTypingEvent(action.typingInfo.user_id);
                }

                return draft;

            }
        case types.DELETE_TYPING:
            {
                delete draft[action.typingInfo.user_id];
                deleteReceivedTypingEvent(action.typingInfo.user_id);
                return draft;

            }
        default:
            return draft;
    }
});