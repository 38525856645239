import React, { useEffect, forwardRef,useImperativeHandle } from "react";
import { useTimer } from "use-timer";
import moment from "moment";

const CallDuration = forwardRef(({ nStart }, ref) => {
  const { time, start, pause, reset, isRunning } = useTimer({
    initialTime: nStart,
  });
  useEffect(() => {
    try {
      start();
    } catch (ex) {
      console.error(ex);
    }
  }, []);

  useImperativeHandle(ref, () => ({
   getTime() {
      return time;
    }
  }));
  return (
    <>
      <div className={"timer"}>
        {time === 0 ? "" : moment.utc(time * 1000).format("HH:mm:ss")}
      </div>
    </>
  );
});
export default CallDuration;
