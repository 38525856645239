class Participant {

    constructor(obj) {
        this.participant = obj.participant;
        this.type = obj.type;
        this.participantType = obj.participantType;
        this.email = obj.email;
    }

}

export { Participant }