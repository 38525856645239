import * as types from "../actions/actionTypes";
import produce from "immer";

export const cannedMessageReducer = produce((draft = { admin: {}, user: {} }, action) => {
    switch (action.type) {

        case types.ADMIN_CANNED_LOADED:
            draft.admin = action.templates;
            break;
        case types.MY_CANNED_LOADED:
            draft.user = action.templates;
            break;
        case types.APPEND_MY_CANNED_MESSAGE:
            draft.user = { ...draft.user, ...action.template };
            break;
        case types.EDIT_MY_CANNED_MESSAGE:
            if (draft.user[action.template.Uuid]) {
                draft.user[action.template.Uuid] = action.template;
            }
        case types.DELETE_MY_CANNED_MESSAGE:
            delete draft.user[action.id];
            break;
        default:
            break;
    }

    return draft;
});