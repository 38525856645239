import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { MenuIcon } from "../../common";
import SelectedUserWrapper from "../../common/UserWithFullName/SelectedUserWrapper";
import {
  Airplay,
  Search,
  Paperclip,
  Phone,
  Video,
  ArrowLeft, Instagram
} from "react-feather";
import { Popover } from "antd";
import { message } from "antd/lib/index";
import { bindActionCreators } from "redux";
import * as uiPanelActions from "../../../redux/actions/uiPanelActions";
import uuid from "uuid/v1";
import { getUserId, getWorkspace, getCustomData } from "../../../utils";
import {
  __APP_INTERACTION_MODE__,
  __APP_MAX_FILE_UPLOAD_SIZE__
} from "../../../config/baseUrls";
import {
  imageTypes,
  videoTypes,
  audioTypes,
  documentTypes,
} from "./../../../config/json/fileTypeMap";
import navigationRestrictions from "../../../config/navigationRestrictions";
import * as logger from "../../../lib/logSdk/logger";

function getAllowedMedia() {
  let allowedMedia = [];

  if (navigationRestrictions.media.allowed_media.video) {
    allowedMedia = [...allowedMedia, ...videoTypes];
  }
  if (navigationRestrictions.media.allowed_media.image) {
    allowedMedia = [...allowedMedia, ...imageTypes];
  }
  if (navigationRestrictions.media.allowed_media.audio) {
    allowedMedia = [...allowedMedia, ...audioTypes];
  }

  return allowedMedia;
}

function MobileHeaderPanel(props) {
  const [isScreenShare, setIsScreenShare] = useState(false);
  const [isClientProfile, setIsClientProfile] = useState(true);
  const allMediaTypes = getAllowedMedia();

  const customData = getCustomData();

  useEffect(() => {
    try {
      setIsScreenShare(false);
    } catch (error) {
      console.error("MobileHeaderPanel", "useEffect", error);
      logger.error("MobileHeaderPanel", "useEffect", error.message);
    }
  }, []);

  function onPressChatFilterIcon(_filter) {
    console.log("event fire...");
    console.log(_filter);
  }

  const content = (
    <div className={"document-select-wrapper"}>
      {navigationRestrictions.media.uploader.media_upload.active && (
        <input
          id="selectImage"
          accept={getAllowedMedia().join(",")}
          hidden
          multiple
          type="file"
          name="file"
          onChange={(media) => {
            let fileObj = {};

            if (__APP_MAX_FILE_UPLOAD_SIZE__ !== 0) {
              let largeFile = Array.from(media.target.files).find(file => file.size > __APP_MAX_FILE_UPLOAD_SIZE__);

              if (largeFile) {
                message.error(`File size exceeds maximum allowed size of ${__APP_MAX_FILE_UPLOAD_SIZE__} bytes`);
                return;
              }
            }

            for (const file of Array.from(media.target.files)) {
              if (allMediaTypes.indexOf(file.type) === -1) {
                message.error(`Unsupported file format`);
                return;
              }

              let fileId = uuid();
              fileObj[fileId] = {
                file_id: fileId,
                file: file,
                name: file.name,
                size: file.size,
                type: file.type,
                caption: "",
              };
            }
            props.ui_panel_actions.openMediaUpload(fileObj, "MEDIA");
            //props.onUploadFirstMedia(media);
            media.target.value = null;
          }}
        />
      )}
      {navigationRestrictions.media.uploader.media_upload.active && (
        <div
          className={"document-row"}
          onClick={() => {
            document.getElementById("selectImage").click();
            //props.onClickOpenMediaUpload();
          }}
        >
          Photos & Video
        </div>
      )}
      {/*<div className={"document-row"}>Camera</div>*/}
      {navigationRestrictions.media.uploader.document_upload.active && (
        <input
          id="selectDocument"
          accept="application/pdf"
          hidden
          multiple
          type="file"
          name="file"
          onChange={(media) => {
            let fileObj = {};

            if (__APP_MAX_FILE_UPLOAD_SIZE__ !== 0) {
              let largeFile = Array.from(media.target.files).find(file => file.size > __APP_MAX_FILE_UPLOAD_SIZE__);

              if (largeFile) {
                message.error(`File size exceeds maximum allowed size of ${__APP_MAX_FILE_UPLOAD_SIZE__} bytes`);
                return;
              }
            }

            for (const file of Array.from(media.target.files)) {
              if (
                documentTypes.indexOf(file.type) === -1 &&
                !(
                  file.name &&
                  (file.name.endsWith(".txt") || file.name.endsWith(".doc") || file.name.endsWith(".docx") || file.name.endsWith(".xls") || file.name.endsWith(".xlsx") || file.name.endsWith(".csv"))
                )
              ) {
                message.error(`Unsupported file format`);
                return;
              }
              let fileId = uuid();
              fileObj[fileId] = {
                file_id: fileId,
                file: file,
                name: file.name,
                size: file.size,
                type: file.type,
                caption: "",
              };

              if (file.name.endsWith(".doc")) {
                fileObj[fileId].type = "application/msword";
              }

              if (file.name.endsWith(".docx")) {
                fileObj[fileId].type =
                  "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
              }

              if (file.name.endsWith(".xls")) {
                fileObj[fileId].type = "application/vnd.ms-excel";
              }

              if (file.name.endsWith(".xlsx")) {
                fileObj[fileId].type =
                  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
              }

              if (file.name.endsWith(".csv")) {
                fileObj[fileId].type =
                  "text/csv";
              }

              if (file.name.endsWith(".txt")) {
                fileObj[fileId].type =
                  "text/plain";
              }
            }
            props.ui_panel_actions.openMediaUpload(fileObj, "DOCUMENT");
            //props.onUploadFirstMedia(file);
            media.target.value = null;
          }}
        />
      )}
      {navigationRestrictions.media.uploader.document_upload.active && (
        <div
          className={"document-row"}
          onClick={() => {
            document.getElementById("selectDocument").click();
            //props.onClickOpenMediaUpload();
          }}
        >
          Document
        </div>
      )}
    </div>
  );

  let showIcons =
    (!navigationRestrictions.presence.active || navigationRestrictions.presence.show_communication_buttons.active ||
      (props.presence &&
        (props.presence.status === "online" ||
          props.presence.status === "available"))) &&
    props.call &&
    props.call.callStatus === "Idle";

  console.log(
    "ButtonStatus",
    `status : ${props.presence ? props.presence.status : "not Set"
    }, callStatus : ${props.call ? props.call.callStatus : "not Set"}`
  );
  const screenSharing = (val) => {
    try {
      // setIsScreenShare(!val);
      props.onClickScreenSharing(!val);
    } catch (error) {
      console.error("MobileHeaderPanel", "screenSharing", error);
      logger.error("MobileHeaderPanel", "screenSharing", error.message);
    }
  };
  const screenSharingRequest = (val) => {
    try {
      setIsScreenShare(!val);
      props.onClickScreenSharingRequest();
    } catch (error) {
      console.error("MobileHeaderPanel", "screenSharingRequest", error);
      logger.error("MobileHeaderPanel", "screenSharingRequest", error.message);
    }
  };
  return (
    <div>
      <section className={`header-m-wrapper`}>
        {/*<div className={'m-center-bg'}>*/}
        {/*    <img src={require("../../../assets/img/menuBg.svg")}*/}
        {/*         alt="User Avatar"/>*/}
        {/*</div>*/}
        <div
          className={`pg-main-header-inner-wrapper d-flex justify-content-between`}
        >
          <div className={"m-back-to-home"}>
            <div className={`pg-header-icon-wrp  flex justify-content-center `}>
              <MenuIcon
                size={"sm-space"}
                onClick={props.onClickCloseChatPanelMobile}
                icon={<ArrowLeft />}
              />
            </div>
          </div>
          <div className={`pg-user-left mobile d-flex`}>
            <Link to={`/console/${props.selected_profile.username}/profile`}>
              <SelectedUserWrapper
                presence={props.presence}
                selected_profile={props.selected_profile}
                src={"https://source.unsplash.com/wmbbbU0LUG8/150x150"}
                fullName={"Clayton Ballard"}
                lastSeen={"09.30 am"}
                onClick={props.onClickOpenInfoPanel}
                isTyping={true}
                active={true}
                type={"proHeaderMobile"}
                status={"online"}
              />
            </Link>
          </div>
          <div className={`pg-user-action-wrp-right mobile d-flex`}>
            {/*{navigationRestrictions.media.uploader.active && props.ui_panel_header_data.buttons.file.show &&*/}
            {/*<Popover placement="bottomLeft" content={content} trigger="hover">*/}
            {/*    <div className={`pg-header-icon-wrp  flex justify-content-center `}>*/}
            {/*        <MenuIcon size={"sm-space"} icon={<Paperclip/>}/>*/}
            {/*    </div>*/}
            {/*</Popover>}*/}

            {navigationRestrictions.chat.search.active && (
              <div
                className={`pg-header-icon-wrp mobile-sm flex justify-content-center`}
              >
                <Link to={`/console/${props.selected_profile.username}/search`}>
                  <MenuIcon
                    onClick={props.onClickOpenInfoPanel}
                    size={"mobile-sm"}
                    icon={<Search />}
                  />
                </Link>
              </div>
            )}

            {/*phone action*/}
            {showIcons && (
              <>
                {navigationRestrictions.screen_sharing.screen_share.active &&
                  !isClientProfile &&
                  navigator.mediaDevices.getDisplayMedia !== undefined && (
                    <div
                      className={`pg-header-icon-wrp mobile-sm flex justify-content-center`}
                      title={isScreenShare ? "End Sharing" : "Share Screen"}
                    >
                      <MenuIcon
                        onClick={() => screenSharing(isScreenShare)}
                        status={isScreenShare ? "material" : null}
                        size={"mobile-sm"}
                        icon={<Airplay />}
                      />
                    </div>
                  )}

                {navigationRestrictions.screen_sharing.screen_share_request
                  .active &&
                  isClientProfile && (
                    <div
                      className={`pg-header-icon-wrp mobile-sm flex justify-content-center`}
                      title={"Request Screen Sharing"}
                    >
                      <MenuIcon
                        onClick={() => screenSharingRequest(isScreenShare)}
                        status={isScreenShare ? "material" : null}
                        size={"mobile-sm"}
                        icon={<Airplay />}
                      />
                    </div>
                  )}

                {navigationRestrictions.calls.active &&
                  navigationRestrictions.calls.audio_calls.active && (
                    <div
                      className={`pg-header-icon-wrp mobile-sm flex justify-content-center`}
                    >
                      <MenuIcon
                        onClick={props.OnClickOutGoingCall}
                        size={"mobile-sm"}
                        status={""}
                        icon={<Phone />}
                      />
                    </div>
                  )}
                {/*video action*/}
                {navigationRestrictions.calls.active &&
                  navigationRestrictions.calls.video_calls.active && ((__APP_INTERACTION_MODE__ === 'INTERACTION' && customData && customData.isVideo) || __APP_INTERACTION_MODE__ === 'USER') && (
                    <div
                      className={`pg-header-icon-wrp mobile-sm mr-0 flex justify-content-center`}
                    >
                      <MenuIcon
                        onClick={props.onClickVideoCall}
                        size={"mobile-sm"}
                        icon={<Video />}
                      />
                    </div>
                  )}

                {/* {navigationRestrictions.calls.active &&
                      navigationRestrictions.calls.video_calls.active && ((__APP_INTERACTION_MODE__ === 'INTERACTION' && customData && customData.isVideo) || __APP_INTERACTION_MODE__ === 'USER') && (
                        <div
                          className={`pg-header-icon-wrp mobile-sm mr-0 flex justify-content-center`}
                        >
                          <MenuIcon
                            onClick={props.onClickSelfView}
                            size={"mobile-sm"}
                            icon={<Instagram />}
                          />
                        </div>
                    )} */}

              </>
            )}
            {/* {!showIcons && props.call && props.call.callType === "SCREENSHARE" && (
                            <div
                                className={`pg-header-icon-wrp flex justify-content-center`}
                                title={isScreenShare ? "End Sharing" : "Share Screen"}
                            >
                                <Link to="/console/charlotte.daniel/search">
                                    <MenuIcon
                                        onClick={() => screenSharing(isScreenShare)}
                                        size={"x-sm-outline"}
                                        color={isScreenShare ? "red" : "white"}
                                        status={isScreenShare ? "material" : null}
                                        icon={<Airplay/>}
                                    />
                                </Link>
                            </div>
                        )} */}
            {/* {!showIcons &&
                            ///disable
                            <>
                                <div className={`pg-header-icon-wrp disable-me flex justify-content-center`}>
                                    <Link to="/console/charlotte.daniel/search">
                                        <MenuIcon
                                            onClick={() => screenSharing(isScreenShare)}
                                            size={'sm-space'}
                                            icon={<Airplay />} />
                                    </Link>
                                </div>
                                <div className={`pg-header-icon-wrp disable-me  flex justify-content-center`}>
                                    <MenuIcon
                                        size={'sm-space'}
                                        status={''}
                                        icon={<Phone />} />
                                </div>
                                <div className={`pg-header-icon-wrp disable-me flex justify-content-center`}>
                                    <MenuIcon
                                        size={'sm-space'}
                                        icon={<Video />} />
                                </div>
                            </>
                        } */}
          </div>
        </div>
      </section>
    </div>
  );
}

MobileHeaderPanel.propTypes = {
  presence: PropTypes.object,
  selected_profile: PropTypes.object.isRequired,
  call: PropTypes.object.isRequired,
  ui_panel_actions: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  let mapState = {
    selected_profile: state.selected_profile,
    call: state.call,
    ui_panel_header_data: state.ui_panel_data.header_panel,
  };

  if (state.selected_profile && state.selected_profile.username) {
    mapState.presence = state.presence.data[state.selected_profile.username];
  }
  return mapState;
}

function mapDispatchToProps(dispatch) {
  return {
    ui_panel_actions: bindActionCreators(uiPanelActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MobileHeaderPanel);
