import React, { useState } from "react";
import AdminCannedMessages from "./AdminCannedMessages";
import MyCannedMessages from "./MyCannedMessages";
import { Card } from 'react-bootstrap';
import { Collapse } from 'antd';
import './styles/message_template.scss'
import { Plus } from "react-feather";
import * as eventEmitter from "../../../utils/eventEmitter";

const { Panel } = Collapse;
const CannedMessages = props => {

    const [searchText, setSearchText] = useState("");

    let onChangeHandlerSearch = event => {
        setSearchText(event.target.value);
        eventEmitter.fireEvent("canned_message_filter", event.target.value);
    };

    let openNewCannedMessageEditor = () => {
        eventEmitter.fireEvent("open_new_canned_editor", { isUpdate: false, updateData: null });
    }

    return (
        <>

            <div className={'m-temp-search-wrapper'}>
                <input id="contact_search" type="text" value={searchText} onChange={onChangeHandlerSearch}
                    className="form-control txt-modal-search"
                    placeholder="template search here..." />
            </div>

            <div className={'create-canned-wrapper'}>
                <div className={'modal-sub-title'}>
                    Create
                </div>
                <div className={'create-new-row-wrapper'}
                    onClick={openNewCannedMessageEditor}>
                    <div className={'create-icon-wrp'}>
                        <Plus />
                    </div>
                    <div className={'create-title'}>
                        New Canned Responses
                    </div>
                </div>
            </div>


            <div className={'modal-sub-title'}>
                Canned Message
            </div>
            <Collapse bordered={false}>
                <Panel header="Admin Canned Messages" key="1">
                    <AdminCannedMessages />
                </Panel>
                <Panel header="My Canned Messages" key="2">
                    <MyCannedMessages />
                </Panel>
            </Collapse>,


            {/*<NewCannedMessage/>*/}


        </>
    );
};

export default CannedMessages;
