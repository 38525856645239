import Result from "antd/lib/result";
import React from "react";


const NotAuthorized = (props) => {
  return (
    <Result
      status="403"
      title="403"
      subTitle="Sorry, you are not authorized to access this page."
    />
  );
};

export default NotAuthorized;
