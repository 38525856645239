import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Accordion, Card, Button, useAccordionToggle } from 'react-bootstrap';
import { getTemplates } from '../../../service/templateService';
import { Scrollbars } from "react-custom-scrollbars";
import { MenuIcon } from "../../common";
import {
    ArrowDownCircle, ArrowRight
} from "react-feather";

import './styles/message_template.scss'

import * as eventEmitter from "../../../utils/eventEmitter";
import * as templateActions from "../../../redux/actions/templateActions";

function CustomToggle({ children, eventKey }) {
    const decoratedOnClick = useAccordionToggle(eventKey, () =>
        console.log('totally custom!'),
    );

    return (
        <>
            <MenuIcon
                onClick={decoratedOnClick}
                status={"material"}
                size={"sm-space"}
                icon={<ArrowDownCircle />}
            />

        </>

    );
}

const Templates = props => {

    const [searchText, setSearchText] = useState("");

    let onChangeHandlerSearch = event => {
        setSearchText(event.target.value);
    };

    const handleSearching = (sourceData) => {

        if (searchText) {
            //filter
            try {

                var regex = RegExp(searchText, "i");
                let filtered = Object.entries(sourceData).reduce((acc, [key, template]) => {

                    if (regex.test(template.TemplateName)) {
                        acc = { ...acc, ...{ [key]: template } };
                    }
                    return acc;
                }, {});

                return filtered;

            } catch (ex) {
                console.error(ex);
                return {};
            }

        } else {
            return sourceData;
        }

    }

    const formatTemplateMessage = (template) => {
        let message = template.TemplateMessage;
        if (template.templateValues) {

            template.templateValues.forEach(val => {
                message = message.replace(`{{${val.Uuid}}}`, `{{${val.Label}}}`);
            })

        }

        return message;
    }

    const deriveActualMessage = async (template) => {
        if (template.templateValues && template.templateValues.length > 0) {

            return await props.template_actions.getTemplateMessage(template);

        } else {
            return template.TemplateMessage;
        }

    }

    let filteredTemplates = handleSearching(props.templates);

    return (
        <>
            <div className={'m-temp-search-wrapper'}>
                <input id="contact_search" type="text" value={searchText} onChange={onChangeHandlerSearch}
                    className="form-control txt-modal-search"
                    placeholder="Search template by name" />
            </div>
            {
                Object.entries(filteredTemplates).map(([key, template]) => {
                    return (
                        <>
                            <div key={key} className={'msg-temp-wrapper d-flex '}
                                onClick={async () => {
                                    eventEmitter.fireEvent("add_message", await deriveActualMessage(template));
                                    props.closeModal && props.closeModal();
                                }}>
                                <div className={'msg-tem-left-wrapper ' +
                                    'd-flex flex-column'}>
                                    <div className={'msg-temp-name'}>
                                        {template.TemplateName}
                                    </div>
                                    <div className={'msg-body'}>
                                        {formatTemplateMessage(template)}
                                    </div>
                                </div>

                                <div className={'msg-tem-right-wrapper'}>
                                    <div className={'m-add-icon-wrapper'}>
                                        <ArrowRight />
                                    </div>

                                </div>
                            </div>

                        </>
                    )
                })
            }

        </>
    );
};

function mapDispatchToProps(dispatch) {
    return {
        template_actions: bindActionCreators(templateActions, dispatch)
    };
}

function mapStateToProps(state) {
    return {
        templates: state.templates
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Templates);
