import React, {
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
} from "react";
import { useHistory } from "react-router-dom";
import {
  AudioCallPlayer,
  VideoCallPlayer,
  CallAlert,
  CallDuration,
  ParticipatorStatus,
} from "../../common";
import { Minimize, Maximize, MicOff, Mic , Airplay} from "react-feather";
import PropTypes from "prop-types";
import phoneEngine from "./phoneEngine/phoneEngineHandler";
import audioFile from "../../../assets/mp3/ringing.mp3";
import audioBeep from "../../../assets/mp3/beep.mp3";
import userJoining from "../../../assets/mp3/userJoin.mp3";
import userLeaving from "../../../assets/mp3/userLeave.mp3";
import ringBackTone from "../../../assets/mp3/ringbacktone.wav";
import notificationSound from "../../../assets/mp3/alert.mp3";
import liveChatClient from "../../../lib";
import { message } from "antd/lib/index";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as auth from "../../../utils";
import uuid from "uuid/v1";
import * as ChatService from "../../../service/chatService";
import * as chatActions from "../../../redux/actions/chatActions";
import * as teamActions from "../../../redux/actions/teamActions";
import ScreenShareModal from "../../pages/ScreenShareModal/ScreenShareModal";
import ScreenShareMyViewModal from '../../pages/ScreenShareModal/ScreenShareMyViewModal';
import * as callActions from "../../../redux/actions/callActions";
import * as default_phoneConfig from "../../../config/phoneConfig";
import * as presenceActions from "../../../redux/actions/presenceActions";
import * as interactionActions from "../../../redux/actions/interactionActions";
import Draggable from "react-draggable";
import { useDispatch, useSelector } from "react-redux";
import { onShowNotification } from "../../../redux/actions/webNotificationActions";
import * as eventEmitter from "../../../utils/eventEmitter";
import { Interaction } from "../../../models/Interaction";
import {
  onParticipatorAdded,
  OnVoiceActivity,
  OnScreenShareAdded,
  OnScreenShareRemoved,
  OnRemoteMuted,
  OnRemoteUnmuted,
  OnDeviceListLoaded,
  onCallControlStateRest,
  OnMediaDeviceError,
} from "../../../redux/actions/callControlsActions";
import { Modal, Row, Col, notification, Timeline, Tree, Tag } from "antd";
import { configurationManagerService } from "../../../service/configurationManagerService";

import ringtone_01 from "../../../assets/mp3/ringtone-01.mp3";
import ringtone_02 from "../../../assets/mp3/ringtone-02.mp3";
import ringtone_03 from "../../../assets/mp3/ringtone-03.mp3";
import ringtone_04 from "../../../assets/mp3/ringtone-04.mp3";
import ringtone_05 from "../../../assets/mp3/ringtone-05.mp3";
import ringtone_06 from "../../../assets/mp3/ringtone-06.mp3";
import ringtone_07 from "../../../assets/mp3/ringtone-07.mp3";

import beep_01 from "../../../assets/mp3/beep-01.mp3";
import beep_02 from "../../../assets/mp3/beep-02.mp3";
import beep_03 from "../../../assets/mp3/beep-03.mp3";
import beep_04 from "../../../assets/mp3/beep-04.mp3";
import beep_05 from "../../../assets/mp3/beep-05.mp3";
import beep_06 from "../../../assets/mp3/beep-06.mp3";
import beep_07 from "../../../assets/mp3/beep-07.mp3";
import beep_08 from "../../../assets/mp3/beep-08.mp3";
import beep_09 from "../../../assets/mp3/beep-09.mp3";
import beep_10 from "../../../assets/mp3/beep-10.mp3";
import maskProperties from "../../../utils/maskData";
import {
  WEB_NOTIFICATION,
  PHONE_NOTIFICATION,
} from "../../../redux/actions/actionTypes";
import * as logger from "../../../lib/logSdk/logger";
import {
  ApiTwoTone,
  SoundOutlined,
  VideoCameraOutlined,
  DownOutlined,
  AudioOutlined,
} from "@ant-design/icons";
import AlertModal from "./alertMessage/AlertModal";
import { __APP_IDENTITY_PROVIDER__ } from "../../../config/baseUrls";
import AuthService from "../../../service/authService.js";
import globalFlags from "../../../utils/globalFlags";
import WorkspaceService from "../../../service/workspaceService";

function importAll(r) {
  return r.keys().map(r);
}

let agent_bg_images = importAll(require.context('../../../assets/img/virtual_background/agent_background/', true, /\.(png|jpe?g|svg)$/));

let ringtones = {};

ringtones["ringtone-01"] = ringtone_01;
ringtones["ringtone-02"] = ringtone_02;
ringtones["ringtone-03"] = ringtone_03;
ringtones["ringtone-04"] = ringtone_04;
ringtones["ringtone-05"] = ringtone_05;
ringtones["ringtone-06"] = ringtone_06;
ringtones["ringtone-07"] = ringtone_07;

ringtones["beep-01"] = beep_01;
ringtones["beep-02"] = beep_02;
ringtones["beep-03"] = beep_03;
ringtones["beep-04"] = beep_04;
ringtones["beep-05"] = beep_05;
ringtones["beep-06"] = beep_06;
ringtones["beep-07"] = beep_07;
ringtones["beep-08"] = beep_08;
ringtones["beep-09"] = beep_09;
ringtones["beep-10"] = beep_10;

let caller;
let sendScreenShareRequestStatus = false;
let miscallStatus = "answered";
let InteractionType = null;
let phoneConfig;
let defaultRingtone = new Audio(audioFile);
let autoAnswerRingtone = new Audio(audioBeep);
let callRelatedRequestAccepted = false;
let transferStatus = false;
let autoAnswer = false;
let autoAnswerEveryCall = false;
let audioDeviceId = null;
let currentInteractionId = "";
let mediaDevicesDetails = null;
let iShredMyScreen = false;
let lastDeviceChangeEventTime = Date.now();
let media_deceive_accepted_error_limit = 2;
const authService = new AuthService();
const workspaceService = new WorkspaceService();

const SoftPhone = forwardRef((props, ref) => {
  const dispatch = useDispatch();
  let history = useHistory();
  const { confirm } = Modal;
  //const state = useSelector((state) => state);
  /* --------------- react Methods ---------------------- */

  const [showCallAlert, setShowCallAlert] = useState(false);
  const [callee, setCallee] = useState();
  const [calleeName, setCalleeName] = useState();
  const [audioUpgradeToVideo, setAudioUpgradeToVideo] = useState({});
  const [callType, setCallType] = useState("");

  const [userJoin] = useState(new Audio(userJoining));
  const [userLeave] = useState(new Audio(userLeaving));
  const [alertNotification] = useState(new Audio(notificationSound));
  const [ringBack] = useState(new Audio(ringBackTone));
  const [callStatus, setCallStatus] = useState();
  const [callDirection, setCallDirection] = useState("");
  const [isHideVideoPenal, setIsHideVideoPenal] = useState(true);
  const [isHideAudioPenal, setIsHideAudioPenal] = useState(true);
  const [openParticipation, setOpenParticipation] = useState({});

  const [isScreenShare, setIsScreenShare] = useState(false);
  const [screenShareSelf, setScreenShareSelf] = useState(false);

  const [childRefAudioCall] = useState(React.createRef());
  const [childRefVideoCall] = useState(React.createRef());
  const [refCallDuration] = useState(React.createRef());
  const [remoteStreams, setRemoteStreams] = useState({});
  const [isAllowedScreenShare, setIsAllowedScreenShare] = useState(false);
  const [mainRemoteStreamId, setMainRemoteStreamId] = useState("");
  const [mainRemoteStream, setMainRemoteStream] = useState("");
  const [userDetails, setUserDetails] = useState({});
  const [screenShareSwapId, setScreenShareSwapId] = useState(null);
  const [screenShareStreams, setScreenShareStreams] = useState({});
  const [screenShareConCallStreams, setScreenShareConCallStreams] = useState(
    null
  );
  const [isVideoMinimizeScreen, setVideoMinimizeScreen] = useState(true);
  const [blockScreenShare, setBlockScreenShare] = useState(false);

  const [isAudioWithScreenshare, setisAudioWithScreenshare] = useState(false);
  const [controlledPosition, setControlledPosition] = useState(null);
  const [callDurationComponent, setCallDurationComponent] = useState(null);
  const [interactionId, setInteractionId] = useState(null);
  const [autoAnswerFeature, setAutoAnswerFeature] = useState(false);
  const [hideRejectButton, setHideRejectButton] = useState(false);
  const [hasMediaDeviceError, setHasMediaDeviceError] = useState(false);
  const [bg_images, setBg_images] = useState(agent_bg_images);
  const [callerName] = useState("Me");

  console.log(
    "%cSOFT PHONE RENDER",
    "color:#233E82; font-family:'Ubuntu'; display: block;font-weight:bold; font-size:15px;"
  );
  useEffect(() => {
    try {
      getPhoneConfig();
      caller = auth.getUserId();
      console.log(
        "%cINITIALIZING SOFT PHONE",
        "color:#233E82; font-family:'Ubuntu'; display: block;font-weight:bold; font-size:32px;"
      );
      // phoneEngine.phone.initialize(50, phoneConfig);
      defaultRingtone.loop = true;
      ringBack.loop = true;

      defaultRingtone.onloadeddata = () => {
        try {
          console.log(
            "SoftPhone",
            "useEffect",
            "onloadeddata",
            "Ringtone",
            "defaultRingtone loaded"
          );
          defaultRingtone.play();
          defaultRingtone.pause();
          console.log(
            "SoftPhone",
            "useEffect",
            "onloadeddata",
            "Ringtone",
            "defaultRingtone play/pause success"
          );
        } catch (error) {
          console.error(
            "SoftPhone",
            "useEffect",
            "onloadeddata",
            "Ringtone",
            "defaultRingtone loaded, but unable to play",
            error
          );
        }
      };
      defaultRingtone.load();

      autoAnswerRingtone.onloadeddata = () => {
        try {
          console.log(
            "SoftPhone",
            "useEffect",
            "onloadeddata",
            "Ringtone",
            "autoAnswerRingtone loaded"
          );
          autoAnswerRingtone.play();
          autoAnswerRingtone.pause();
          console.log(
            "SoftPhone",
            "useEffect",
            "onloadeddata",
            "Ringtone",
            "autoAnswerRingtone play/pause success"
          );
        } catch (error) {
          console.error(
            "SoftPhone",
            "useEffect",
            "onloadeddata",
            "Ringtone",
            "autoAnswerRingtone loaded, but unable to play",
            error
          );
        }
      };
      autoAnswerRingtone.load();
    } catch (error) {
      console.error("SoftPhone", "useEffect", error);
      logger.error("SoftPhone", "useEffect", error.message);
    }
  }, []);

  useEffect(() => {
    try {
      callRelatedRequestAccepted = props.callControls.accepted;
      currentInteractionId = props.callControls.interactionId;
      console.log(
        "SoftPhone",
        "useEffect",
        "currentInteractionId",
        `new interaction id assigned : ${currentInteractionId}`
      );
    } catch (ex) {
      console.error(ex);
      logger.error("SoftPhone", "useEffect", ex.message);
    }
  }, [
    props.callControls.accepted,
    props.callControls.controls.isInteractionTransferRequestAccepted,
    props.callControls.interactionId,
  ]);

  useEffect(() => {
    console.log("SoftPhone", "useEffect", "props.mediaDevices");
    if (
      props.mediaDevices &&
      props.mediaDevices.isWebcamAlreadyCaptured === true &&
      props.mediaDevices.isMicrophoneAlreadyCaptured === true
    ) {
      setHasMediaDeviceError(false);
      auth.resetMediaDeviceErrorCount();
    }
  }, [props.mediaDevices]);

  const dispatchDeviceLoaded = (deviceList) => {
    try {
      const mediaDevices = {
        inputs: [],
        outputs: [],
        videos: [],
        defaultSpeaker: null,
        defaultSpeakerLabel: null,
        defaultMic: null,
        defaultMicLabel: null,
        defaultCamera: null,
        defaultCameraLabel: null,
      };

      deviceList &&
        deviceList.MediaDevices &&
        deviceList.MediaDevices.map((item) => {
          if (item.kind === "audioinput") {
            mediaDevices.inputs.push({
              deviceId: item.deviceId,
              label: item.label,
            });
            if (item.label.toLowerCase().startsWith("default")) {
              mediaDevices.defaultMic = item.deviceId;
              mediaDevices.defaultMicLabel = item.label;
            }
          }
          if (item.kind === "audiooutput") {
            mediaDevices.outputs.push({
              deviceId: item.deviceId,
              label: item.label,
            });
            if (item.label.toLowerCase().startsWith("default")) {
              mediaDevices.defaultSpeaker = item.deviceId;
              mediaDevices.defaultSpeakerLabel = item.label;
            }
          }
          if (item.kind === "videoinput") {
            mediaDevices.videos.push({
              deviceId: item.deviceId,
              label: item.label,
            });
            if (item.label.toLowerCase().startsWith("default")) {
              mediaDevices.defaultCamera = item.deviceId;
              mediaDevices.defaultCameraLabel = item.label;
            }
          }
        });

      if (!mediaDevices.defaultCamera && mediaDevices.videos[0]) {
        mediaDevices.defaultCamera = mediaDevices.videos[0].deviceId;
        mediaDevices.defaultCameraLabel = mediaDevices.videos[0].label;
      }
      if (!mediaDevices.defaultSpeaker && mediaDevices.outputs[0]) {
        mediaDevices.defaultSpeaker = mediaDevices.outputs[0].deviceId;
        mediaDevices.defaultSpeakerLabel = mediaDevices.outputs[0].label;
      }
      if (!mediaDevices.defaultMic && mediaDevices.inputs[0]) {
        mediaDevices.defaultMic = mediaDevices.inputs[0].deviceId;
        mediaDevices.defaultMicLabel = mediaDevices.inputs[0].label;
      }
      mediaDevicesDetails = mediaDevices;

      dispatch(
        OnDeviceListLoaded({
          mediaDevices,
        })
      );
      dispatch(
        OnMediaDeviceError({
          isMediaDeviceAccessGranted:
            deviceList &&
            deviceList.isMicrophoneAlreadyCaptured === true &&
            deviceList.isWebcamAlreadyCaptured === true,
          isMicrophoneAlreadyCaptured: deviceList.isMicrophoneAlreadyCaptured,
          isWebcamAlreadyCaptured: deviceList.isWebcamAlreadyCaptured,
        })
      );

      if (
        deviceList &&
        (deviceList.isMicrophoneAlreadyCaptured !== true ||
          deviceList.isWebcamAlreadyCaptured !== true ||
          deviceList.isMicrophoneAlreadyCaptured !== true)
      ) {
        const error_count = auth.getMediaDeviceErrorCount().count;
        if (error_count === media_deceive_accepted_error_limit) {
          auth.setMediaDeviceErrorCount();
          setHasMediaDeviceError(true);
          auth.setMediaDeviceErrorCount();
        } else if (error_count < media_deceive_accepted_error_limit) {
          auth.setMediaDeviceErrorCount();
        }
      }
      return mediaDevices;
    } catch (error) {
      console.error("SoftPhone", "dispatchDeviceLoaded", error);
      logger.error("SoftPhone", "dispatchDeviceLoaded", error.message);
      return null;
    }
  };

  const loadBackgroundImages = async () =>{

    return new Promise(async(resolve, reject) => {
      try {
        if(phoneConfig.basic_config.sdk.virtual_background_feature_activate === true){

          
          const workspaces = auth.getWorkspaces();
          const workspace = auth.getWorkspace();
          let workspaceDetail = workspaces && workspaces.find((w)=>  w.workSpaceName === workspace );

          if(workspaceDetail === null){
            workspaceDetail = { workspaceid : phoneConfig.basic_config.console.workspace_details.workspace_id} ;
          }
        
          if(workspaceDetail.workspaceid > 0 ){
            const img_urls = await  workspaceService.getVirtualBackgrounds(workspaceDetail.workspaceid)
            const urls = [] ;
            img_urls && img_urls.map( (item)=>{
              if(item && item.active === true){
                const url = `${item.url_host ? item.url_host : ""}${item.url_host_path ? item.url_host_path:""}`
                urls.push(fetch(url)
                .then(res => {return res.blob()})
                .then(blob => {
                  const objectURL = URL.createObjectURL(blob);            
                  return objectURL; 
                }).catch(error =>{
                  console.error("SoftPhone", "getPhoneConfig", "loadBackgroundImages","unable to resolve url promise",error);
                  return null;
                }))
              }            
            });
    
            Promise.all(urls).then((values) => {  
              if(values != null && values.length > 0){
                const list = [];
                values.map((item)=>{
                  if(item !== null)
                    list.push(item);
                  return item;
                });
                if(list.length > 0){
                  setBg_images([...list]);
                  console.log( "SoftPhone", "getPhoneConfig", "loadBackgroundImages", "image list loaded" ,"Agent Image list overwriting using supervisor list"  );
                } else{
                  console.log( "SoftPhone", "getPhoneConfig", "loadBackgroundImages", "image list loaded" ,"Agent Image list not overwriting using supervisor list. no item found"  );
                }              
              }  
              resolve();
            }).catch(error =>{
              console.error("SoftPhone", "getPhoneConfig", "loadBackgroundImages","unable to resolve promise",error);
              resolve();
            });
          }
          else{
            console.log( "SoftPhone", "getPhoneConfig", "loadBackgroundImages", "image list not loaded due to invalid workspace id");
            resolve();
          }
        } 
        else{
          resolve();
        }     
      } catch (error) {
        console.error("SoftPhone", "loadBackgroundImages", error);
        logger.error("SoftPhone", "loadBackgroundImages", error.message);
        resolve();
      }
    });    
  }
  
  
  const getPhoneConfig = async () => {
    try {
      console.log(
        "SoftPhone",
        "getPhoneConfig",
        "PhoneConfig",
        "configuration start"
      );
      
      phoneConfig = { ...default_phoneConfig };
      if (phoneConfig.basic_config.console.loadConfigFromBackend) {
        console.log(
          "SoftPhone",
          "getPhoneConfig",
          "PhoneConfig",
          "invoke configurationManagerService"
        );
        const config = await configurationManagerService.getPhoneConfig();
        if (config) {
          phoneConfig = { ...config };
        }
      }

      const customData = auth.getCustomData();
      if (customData && customData.isVideo !== null) {
        phoneConfig.isAllowedVideoCall = customData.isVideo;
      }
      await loadBackgroundImages();
      console.log(
        "SoftPhone",
        "getPhoneConfig",
        "PhoneConfig",
        "configuration",
        "start phone initialization"
      );
      
      const deviceList = await phoneEngine.phone.initialize(50, phoneConfig);
      console.log(
        "SoftPhone",
        "getPhoneConfig",
        "PhoneConfig",
        "configuration",
        "start phone initialization done"
      );
      if (phoneConfig.basic_config.calls.ringtone) {
        if (ringtones[phoneConfig.basic_config.calls.ringtone]) {
          defaultRingtone = new Audio(
            ringtones[phoneConfig.basic_config.calls.ringtone]
          );
        }
      }
      if (phoneConfig.basic_config.calls.auto_answer) {
        autoAnswer = phoneConfig.basic_config.calls.auto_answer.active;
        autoAnswerEveryCall =
          phoneConfig.basic_config.calls.auto_answer.everyCall;
        setAutoAnswerFeature(autoAnswer && autoAnswerEveryCall);
        if (ringtones[phoneConfig.basic_config.calls.auto_answer.ringtone]) {
          autoAnswerRingtone = new Audio(
            ringtones[phoneConfig.basic_config.calls.auto_answer.ringtone]
          );
        }
        setHideRejectButton(
          phoneConfig.basic_config.calls.auto_answer
            .hide_reject_button_with_auto_answer
        );
      }
      auth.setPhoneConfig(phoneConfig);
      if (phoneConfig.basic_config.console.mediaDevice.active === false) {
        dispatch(
          OnMediaDeviceError({
            isMediaDeviceAccessGranted: true,
            isMicrophoneAlreadyCaptured: true,
            isWebcamAlreadyCaptured: true,
          })
        );
      } else {
        dispatchDeviceLoaded(deviceList);
      }

      if (
        phoneConfig &&
        phoneConfig.basic_config &&
        phoneConfig.basic_config.console &&
        phoneConfig.basic_config.console.mediaDevice &&
        phoneConfig.basic_config.console.mediaDevice.error_count &&
        phoneConfig.basic_config.console.mediaDevice.active
      ) {
        media_deceive_accepted_error_limit =
          phoneConfig.basic_config.console.mediaDevice.error_count;
        setHasMediaDeviceError(
          auth.getMediaDeviceErrorCount().count >
          media_deceive_accepted_error_limit
        );
      }
      console.log(
        "SoftPhone",
        "getPhoneConfig",
        "PhoneConfig",
        "mediadevice",
        deviceList
      );
      console.log(
        "SoftPhone",
        "getPhoneConfig",
        "PhoneConfig",
        "configuration",
        "phone configuration completed"
      );
    } catch (error) {
      phoneConfig = default_phoneConfig;
      console.error("SoftPhone", "getPhoneConfig", "PhoneConfig", error);
      logger.error("SoftPhone", "getPhoneConfig", "PhoneConfig", error.message);
    }
  };

  const onSwapStream = (streamId) => {
    try {
      if (!isScreenShare) {
        let temp = { ...remoteStreams };
        const tempMainStream = { ...mainRemoteStream };
        temp[tempMainStream.id] = {
          mediaStream: tempMainStream.mediaStream,
          id: tempMainStream.id,
          name: tempMainStream.name,
          audio: tempMainStream.audio,
          video: tempMainStream.video,
          active: tempMainStream.active,
          userId: tempMainStream.userId,
        };

        const selectedStream = { ...temp[streamId] };
        childRefVideoCall.current.bindMediaStreams(null, {
          mediaStream: selectedStream.mediaStream,
          id: selectedStream.id,
          name: selectedStream.name,
          audio: selectedStream.audio,
          video: selectedStream.video,
          active: selectedStream.active,
          userId: selectedStream.userId,
        });
        delete temp[streamId];
        setMainRemoteStream(selectedStream);
        setMainRemoteStreamId(streamId);
        setRemoteStreams({ ...temp });
      }
    } catch (error) {
      console.error("SoftPhone", "CallOperation", error);
      logger.error("SoftPhone", "CallOperation", error.message);
    }
  };
  const removeScreenShareStream = () => {
    try {
      setIsScreenShare(false);
      setBlockScreenShare(false);
      if (callType === "AUDIO_CALL") {
        setisAudioWithScreenshare(false);
        setScreenShareStreams({});
        return;
      }
      let temp = { ...remoteStreams };
      const user = temp[screenShareSwapId];
      if (user) {
        const userId = user.userId.replace("screen", "");
        delete temp[screenShareSwapId];
        setScreenShareSwapId(null);
        setScreenShareConCallStreams(null);
        const newActive = temp[Object.keys(temp)[0]];
        dispatch(
          OnScreenShareRemoved({
            userId: newActive.userId,
            userName: newActive.name,
            previousUserId: userId,
          })
        );
        setRemoteStreams({ ...temp });
      }
    } catch (error) {
      console.error("SoftPhone", "removeScreenShareStream", error);
      logger.error("SoftPhone", "removeScreenShareStream", error.message);
    }
  };

  const addScreenShareStream = (payload) => {
    try {
      if (isScreenShare) {
        console.error(
          "ScreenShare",
          "SCREENSHARE -addScreenShareStream - Event Fired For both-end"
        );
        logger.error(
          "SoftPhone",
          "addScreenShareStream",
          "SCREENSHARE -addScreenShareStream - Event Fired For both-end"
        );
        return;
      }
      setIsScreenShare(true);
      setBlockScreenShare(true);
      if (callType === "VIDEO_CALL" || callType === "MEETING_VIDEO_CALL") {
        addScreenshareToStreams(payload);
        /* const remoteVideo = payload.remoteStreams[0];

        var rUser = payload.remoteUserInfo[remoteVideo.id];
        let userId = "-99999";
        if (rUser && rUser.userId) {
          userId = rUser.userId.replace("user:", "");
        }
        const user = props.colleagues[userId];
        let userName = "Remote Screen";
        if (user) {
          userName = `${user.firstName} ${user.lastName}`;
        }

        childRefVideoCall.current.bindMediaStreams(payload.localStreams, {
          mediaStream: remoteVideo,
          id: remoteVideo.id,
          name: userName,
          audio: true,
          video: true,
          active: true,
        });

        let temp = { ...remoteStreams };
        temp[mainRemoteStream.id] = {
          mediaStream: mainRemoteStream.mediaStream,
          id: mainRemoteStream.id,
          name: mainRemoteStream.name,
          audio: mainRemoteStream.audio,
          video: mainRemoteStream.video,
          active: mainRemoteStream.active,
          userId: mainRemoteStream.userId,
        };
        setScreenShareSwapId(remoteVideo.id);
        setMainRemoteStreamId(remoteVideo.id);
        setRemoteStreams({ ...temp }); */
      } else if (callType === "AUDIO_CALL") {
        setScreenShareStreams(payload.remoteStreams);
        setisAudioWithScreenshare(true);
        setCallStatus("ScreenShared");
      }
    } catch (error) {
      console.error("addScreenShareStream", error);
      logger.error("SoftPhone", "addScreenShareStream", error.message);
    }
  };

  const removeRemoteStreams = (key) => {
    try {
      let temp = { ...remoteStreams };
      delete temp[key];
      setRemoteStreams({ ...temp });
    } catch (error) {
      console.error("SoftPhone", "removeRemoteStreams", error);
      logger.error("SoftPhone", "removeRemoteStreams", error.message);
    }
  };

  const getUserName = (userId) => {
    let user = { userName: "Unknown" };
    try {

      
      let temp_user = { ...props.colleagues[userId] };
      if((temp_user === null || (Object.keys(temp_user).length === 0 && temp_user.constructor === Object)) &&  props.interactions[interactionId]){
        temp_user = {...props.interactions[interactionId].UserDetails[userId]} ;
      }

      
      if (temp_user.firstName) {
        user = { ...temp_user };
        const name = `${temp_user.firstName} ${(temp_user.lastName === null || temp_user.lastName === undefined )?'' :temp_user.lastName}`;
        console.log(`---------------------------------------------- ${name}`)
        user.userName = name;
        console.log(`---------------------------------------------- ${user.userName}`)
        if (props.interactions)
          user.interactions = props.interactions[temp_user.InteractionId];
      } else {
        user.userName = userId;
      }

      /* else{
        user = {...props.temp_agents[userId]};
        user.userName = `${user? user.name:'Unknown'}`;
      } */
      console.debug(
        "SoftPhone",
        "getUserName",
        `userId:${userId} ,user:${JSON.stringify(
          maskProperties(user, [
            "customerId",
            "customerName",
            "mobileNumber",
            "userName",
            "UserIds",
            "CustomerInfo",
            "firstName",
            "lastName",
            "CustomerUserId",
          ])
        )}, colleagues:*`
      ); //colleagues:${JSON.stringify(props.colleagues)}
      return user;
    } catch (error) {
      console.error("SoftPhone", "getUserName", error);
      logger.error("SoftPhone", "getUserName", error.message);
      return user;
    }
  };
  const addScreenshareToStreams = (payload) => {
    try {
      console.log(
        "SoftPhone",
        "Callback",
        "OnStreamsChanged",
        `addScreenshareToStreams`,
        ` remoteStreams : ${payload.remoteStreams.length}`
      );
      let temp = {};
      let userName = "Unknown";
      let userId = `-99999-${uuid()}`;
      let id;
      payload.remoteStreams.map((item) => {
        var rUser = payload.remoteUserInfo[item.id];

        if (rUser && rUser.userId) {
          userId = rUser.userId.replace("user:", "");
        }

        const user = getUserName(userId);
        if (user) {
          userName = `${user.userName}  [screen] `;
        }
        console.log(
          `----------------------------------- user: ${JSON.stringify(
            user
          )} , rUser : ${JSON.stringify(rUser)}`
        );
        if (audioUpgradeToVideo.state) {
          userName = calleeName;
        }

        temp[item.id] = {
          mediaStream: item,
          id: item.id,
          name: userName,
          audio: true,
          video: true,
          active: false,
          userId: `screen${userId}`,
          isScreenShare: true,
          activeSetting: { isActive: true, zIndex: 8, currentMainId: userId },
        };
        id = item.id;
      });
      setRemoteStreams({ ...remoteStreams, ...temp });
      setScreenShareSwapId(id);
      dispatch(OnScreenShareAdded({ userId: `screen${userId}`, userName }));
    } catch (error) {
      console.error("Softphone", "addScreenshareToStreams", error);
      logger.error("SoftPhone", "addScreenshareToStreams", error.message);
    }
  };

  const addRemoteStreams = (payload) => {
    try {
      console.log(
        "SoftPhone",
        "Callback",
        "OnStreamsChanged",
        `addRemoteStreams`,
        ` remoteStreams : ${payload.remoteStreams.length},localStreams : ${payload.localStreams.length
        } payload : ${JSON.stringify(maskProperties(payload, ["userId"]))}`
      );
      if (payload && payload.remoteStreams) {
        setUserDetails({});
        let temp = {};
        let remoteVideo = {};
        const tempUsers = {};
        let mainWindowStreamId =
          payload && payload.remoteStreams && payload.remoteStreams[0]
            ? payload.remoteStreams[0].id
            : -999999;
        let mainUserId = `-99999-${uuid()}`;
        try {
          let rUser = payload.remoteUserInfo[mainWindowStreamId];
          if (rUser && rUser.userId) {
            mainUserId = rUser.userId.replace("user:", "");
          }
        } catch (error) { }
        let isScreenShare = false;
        if (screenShareConCallStreams) {
          try {
            console.log(
              "SoftPhone",
              "Callback",
              "addRemoteStreams",
              "screenShareConCallStreams",
              "Found Screenshare stream"
            );
            if (callType === "AUDIO_CALL") {
              setScreenShareStreams(screenShareConCallStreams.remoteStreams);
              setisAudioWithScreenshare(true);
            } else {
              mainUserId = `screen${mainUserId}`;
              mainWindowStreamId =
                screenShareConCallStreams.remoteStreams[0].id;
              var rUser =
                screenShareConCallStreams.remoteUserInfo[mainWindowStreamId];
              if (rUser && rUser.userId) {
                mainUserId = `screen${rUser.userId.replace("user:", "")}`;
              }

              let strm = screenShareConCallStreams.remoteStreams[0];
              strm.isScreenShare = true;
              isScreenShare = true;
              payload.remoteStreams.push(strm);
              payload.remoteUserInfo[mainWindowStreamId] =
                screenShareConCallStreams.remoteUserInfo[mainWindowStreamId];
              setScreenShareSwapId(mainWindowStreamId);
            }
          } catch (error) {
            console.error(
              "SoftPhone",
              "Callback",
              "addRemoteStreams",
              "concall",
              error
            );
            logger.error("SoftPhone", "addRemoteStreams", error.message);
          }
        }
        setMainRemoteStreamId(mainWindowStreamId);
        let userName = "Unknown";
        /*  console.warn(
          "SoftPhone",
          "Callback",
          "addNewStream",
          `----------------- Main User. ID : ${mainUserId.replace(
            "screen:",
            ""
          )} , Info : ${JSON.stringify(
            getUserName(mainUserId.replace("screen:", ""))
          )}`
        ); */

        payload.remoteStreams.map((item) => {
          var rUser = payload.remoteUserInfo[item.id];
          let userId = `-99999-${uuid()}`;
          let avatarUrl = "https://source.unsplash.com/XHVpWcr5grQ/150x150";
          if (rUser && rUser.userId) {
            userId = rUser.userId.replace("user:", "");
          }
          const user = getUserName(userId);
          userName = item.name ? item.name : "Unknown";
          let userType = "";
          if (user) {
            userName = user.userName;
            if (user.avatarURL) avatarUrl = user.avatarURL;
            if (item.isScreenShare) {
              userName = `${userName} [screen]`;
            }
            userType = user.userType;
          }

          if (audioUpgradeToVideo.state) {
            userName = calleeName;
          }
          console.warn(
            "SoftPhone",
            "Callback",
            "addNewStream",
            `user: ${JSON.stringify(user)} , rUser : ${JSON.stringify(rUser)}`
          );

          temp[item.id] = {
            mediaStream: item,
            id: item.id,
            name: userName,
            audio: true,
            video: true,
            active: false,
            userId: item.isScreenShare ? mainUserId : userId,
            isScreenShare: isScreenShare,
            activeSetting:
              mainWindowStreamId === item.id
                ? { isActive: true, zIndex: 8, currentMainId: mainUserId }
                : { isActive: false, zIndex: 9, currentMainId: mainUserId },
          };
          console.log(
            ` softphoneuserinfo ------------------ userId: ${"userId"} userName :${"userName"}--------------------`
          );
          tempUsers[userId] = {
            userId: userId,
            id: item.id,
            name: userName,
            active: false,
            avatarUrl: avatarUrl,
            userType: userType,
          };
        });
        childRefVideoCall.current.bindMediaStreams(payload.localStreams, {
          mediaStream: remoteVideo.mediaStream,
          id: remoteVideo.id,
          name: remoteVideo.name,
          audio: remoteVideo.audio,
          video: remoteVideo.video,
          active: remoteVideo.active,
        });

        setRemoteStreams({ ...temp });
        setMainRemoteStream(remoteVideo);
        tempUsers[caller] = {
          userId: caller,
          id: Array.isArray(payload.localStreams)
            ? payload.localStreams[0].id
            : payload.localStreams.id,
          name: callerName,
          active: false,
          userType: "agent",
        };
        setUserDetails({ ...tempUsers });

        if (isScreenShare) {
          dispatch(OnScreenShareAdded({ userId: mainUserId, userName }));
        }
        if (payload.remoteStreams.length === 1) {
          setTimeout(() => {
            dispatch(OnVoiceActivity({ userId: mainUserId, userName }));
          }, 1000);
        }
      }
    } catch (error) {
      console.error(
        "SoftPhone",
        "Callback",
        "addRemoteStreams",
        `payload : ${JSON.stringify(payload)}`,
        error
      );
      logger.error("SoftPhone", "addRemoteStreams-catch", error.message);
    }
  };

  const addRemoteStreams_Bak = (payload) => {
    try {
      console.log(
        "SoftPhone",
        "Callback",
        "OnStreamsChanged",
        `addRemoteStreams`,
        ` remoteStreams : ${payload.remoteStreams.length},localStreams : ${payload.localStreams.length
        } payload : ${JSON.stringify(payload)}`
      );
      if (payload && payload.remoteStreams) {
        setUserDetails({});
        let temp = {};
        let remoteVideo = {};
        const tempUsers = {};
        let mainUserId =
          payload && payload.remoteStreams && payload.remoteStreams[0]
            ? payload.remoteStreams[0].id
            : -999999;

        if (screenShareConCallStreams) {
          try {
            if (callType === "AUDIO_CALL") {
              setScreenShareStreams(screenShareConCallStreams.remoteStreams);
              setisAudioWithScreenshare(true);
            } else {
              mainUserId = screenShareConCallStreams.remoteStreams[0].id;

              /* var rUser = screenShareConCallStreams.remoteUserInfo[mainUserId];
              let userId = "-99999";
              if (rUser && rUser.userId) {
                userId = rUser.userId.replace("user:", "");
              }
              const user = props.colleagues[userId];
              let userName = "Unknown";
              if (user) {
                userName = `${user.firstName} ${user.lastName}`;
              }
 */
              let strm = screenShareConCallStreams.remoteStreams[0];
              strm.isScreenShare = true;
              payload.remoteStreams.push(strm);
              payload.remoteUserInfo[mainUserId] =
                screenShareConCallStreams.remoteUserInfo[mainUserId];
              setScreenShareSwapId(mainUserId);
            }
          } catch (error) {
            console.error(
              "SoftPhone",
              "Callback",
              "addRemoteStreams",
              "concall",
              error
            );
          }
        }
        setMainRemoteStreamId(mainUserId);

        payload.remoteStreams.map((item) => {
          var rUser = payload.remoteUserInfo[item.id];
          let userId = "-99999";
          let avatarUrl = "https://source.unsplash.com/XHVpWcr5grQ/150x150";
          if (rUser && rUser.userId) {
            userId = rUser.userId.replace("user:", "");
          }
          const user = getUserName(userId);
          let userName = item.name ? item.name : "Unknown";
          if (user) {
            userName = user.userName;
            if (user.avatarURL) avatarUrl = user.avatarURL;
          }
          console.log(
            `----------------------------------- user: ${JSON.stringify(
              user
            )} , rUser : ${JSON.stringify(rUser)}`
          );
          if (audioUpgradeToVideo.state) {
            userName = calleeName;
          }

          /* if (mainUserId === item.id && callType !== "AUDIO_CALL") {
            remoteVideo = {
              mediaStream: item,
              id: item.id,
              name: userName,
              audio: true,
              video: true,
              active: false,
              userId: userId,
            };
          } else {
            temp[item.id] = {
              mediaStream: item,
              id: item.id,
              name: userName,
              audio: true,
              video: true,
              active: false,
              userId: userId,
            };
          } */
          temp[item.id] = {
            mediaStream: item,
            id: item.id,
            name: userName,
            audio: true,
            video: true,
            active: false,
            userId: userId,
            activeSetting:
              mainUserId === item.id
                ? { isActive: true, zIndex: 8, currentMainId: mainUserId }
                : { isActive: false, zIndex: 9, currentMainId: mainUserId },
          };
          console.log(
            ` softphoneuserinfo ------------------ userId: ${userId} userName :${userName}--------------------`
          );
          tempUsers[userId] = {
            userId: userId,
            id: item.id,
            name: userName,
            active: false,
            avatarUrl: avatarUrl,
          };
        });
        childRefVideoCall.current.bindMediaStreams(payload.localStreams, {
          mediaStream: remoteVideo.mediaStream,
          id: remoteVideo.id,
          name: remoteVideo.name,
          audio: remoteVideo.audio,
          video: remoteVideo.video,
          active: remoteVideo.active,
        });

        setRemoteStreams({ ...temp });
        setMainRemoteStream(remoteVideo);
        tempUsers[caller] = {
          userId: caller,
          id: Array.isArray(payload.localStreams)
            ? payload.localStreams[0].id
            : payload.localStreams.id,
          name: callerName,
          active: false,
        };
        setUserDetails({ ...tempUsers });
      }
    } catch (error) {
      console.error(
        "SoftPhone",
        "Callback",
        "addNewStream",
        `payload : ${JSON.stringify(payload)}`,
        error
      );
    }
  };

  useImperativeHandle(ref, () => ({
    makeCall(callType, channel, interactionId, otherData) {
      return new Promise((resolve, reject) => {
        try {
          console.log(
            "SoftPhone",
            "makeCall",
            `${callType} , InteractionType : ${channel} , interactionId:${interactionId}`
          );
          setCallStatus("");
          const profile = { ...props.selectedProfile };
          InteractionType = channel;
          setInteractionId(interactionId);
          if (
            channel !== "meeting" &&
            !profile.username &&
            callType !== "SELF_VIEW"
          ) {
            console.error("Unable To Find Selected Profile");
            message.warning("Please Select Profile", 5);
            return;
          }
          setVideoMinimizeScreen(true);

          setShowCallAlert(false);
          setCallee(profile.username);
          setCalleeName(
            profile.displayName
              ? profile.displayName
              : otherData && otherData.callee
                ? otherData.callee
                : profile.username
          );

          if (callType === "SCREENSHARE_REQUEST") {
            sendScreenshareRequest(profile, calleeName);
            return;
          }
          setCallType(callType);
          if (callType === "SCREENSHARE") {
            startScreenSharing(profile.username, false, interactionId);
            return;
          }
          if (callType === "SCREENSHAREEND") {
            stopScreenSharing(false);
            return;
          }

          if (
            callType === "VIDEO_CALL" ||
            callType === "SELF_VIEW" ||
            callType === "MEETING_VIDEO_CALL"
          ) {
            setIsHideVideoPenal(false);
            setIsHideAudioPenal(true);
            if (callType === "SELF_VIEW") {
              setControlledPosition(null);
            }
          } else {
            setIsHideVideoPenal(true);
            setIsHideAudioPenal(false);
          }

          if (callType !== "SELF_VIEW") {
            setCallDirection(`Outgoing`);
            phoneEngine.phoneFunctions.dial(
              caller,
              channel === "meeting"
                ? `meeting:${interactionId}`
                : `${interactionId ? "interaction" : "user"}:${profile.username
                }`,
              callType,otherData
            );
            miscallStatus = "miscall";
            setCallStatus("Ringing");
            closeNotification("endSession-wait-for-call");
          }

          resolve(true);
        } catch (error) {
          console.error("Fail To Make Call", error);
          logger.error("SoftPhone", "addRemoteStreams-catch", error.message);
          resolve(true);
        }
      });
    },
    setMediaDevices(devices) {
      audioDeviceId = devices.outputDevice;
      phoneEngine.phoneFunctions.SetInputDevice(
        devices.inputDevice,
        devices.cameraDevice
      );
    },
  }));

  const startScreenSharing = (to, withCall = true, interaction_id) => {
    try {
      console.log("SoftPhone", "startScreenSharing");
      setIsScreenShare(true);
      setAvailability("busy");
      setCallStatus("ScreenSharing");
      iShredMyScreen = true;
      /*  setVideoMinimizeScreen(true);
       setScreenShareSelf(true);
       setControlledPosition(null); */
       setScreenShareSelf(true);
      let to_address = `user:${to}`;
      if (interactionId || interaction_id) {
        to_address = `interaction:${interactionId ? interactionId : interaction_id
          }`;
      }
      phoneEngine.phoneFunctions.startScreenSharing(to_address, withCall);
      //phoneEngine.phoneFunctions.startScreenSharing(`user:${to}`, withCall);
    } catch (error) {
      console.error("startScreenSharing", error);
      logger.error("SoftPhone", "startScreenSharing", error.message);
    }
  };

  const removeScreenShare = () => {
    try {
      console.log("SoftPhone", "removeScreenShare");
      iShredMyScreen = false;
      setBlockScreenShare(false);
      setScreenShareStreams({});
      setScreenShareConCallStreams(null);
    } catch (error) {
      console.error("SoftPhone", "removeScreenShare", error);
      logger.error("SoftPhone", "removeScreenShare", error.message);
    }
  };

  const stopScreenSharing = (withCall = true) => {
    try {
      console.log("SoftPhone", "stopScreenSharing");
      iShredMyScreen = false;
      setIsScreenShare(false);
      phoneEngine.phoneFunctions.stopScreenSharing(
        callee.replace("user:", ""),
        withCall
      );
    } catch (error) {
      console.error("stopScreenSharing", error);
      logger.error("SoftPhone", "stopScreenSharing", error.message);
    }
  };

  const stopDirectScreenSharing = () => {
    try {
      console.log("SoftPhone", "stopDirectScreenSharing");
      iShredMyScreen = false;
      removeScreenShare();
      stopScreenSharing();
      setCallType("");
    } catch (error) {
      console.error("stopScreenSharing", error);
      logger.error("SoftPhone", "stopDirectScreenSharing", error.message);
    }
  };

  const upgradeToVideo = () => {
    try {
      console.log("SoftPhone", "upgradeToVideo");
      setAudioUpgradeToVideo({
        state: true,
        time: refCallDuration.current ? refCallDuration.current.getTime() : 0,
      });
      setRemoteStreams({});
      setCallType("VIDEO_CALL");
      setIsHideAudioPenal(true);
      setIsHideVideoPenal(false);
      phoneEngine.phoneFunctions.upgradeToVideo(callee.replace("user:", ""));
    } catch (error) {
      console.error("upgradeToVideo", error);
      logger.error("SoftPhone", "upgradeToVideo", error.message);
    }
  };
  /* --------------- Phone SDK Methods ---------------------- */

  const OnAutoAnswerCall = (callType) => {
    try {
      console.warn(
        "SoftPhone",
        "OnAutoAnswerCall",
        " --------------------------- auto answer function trigged ---------------------------"
      );

      setShowCallAlert(false);

      if (callType === "VIDEO_CALL") {
        setIsHideVideoPenal(false);
        setIsHideAudioPenal(true);
        setVideoMinimizeScreen(true);
      } else {
        setIsHideVideoPenal(true);
        setIsHideAudioPenal(false);
      }
      stopRingtone();
      phoneEngine.phoneFunctions.answer();
      setCallStatus("Connecting");

      if(callType === "SCREEN_SHARING"){
        setCallStatus("Connecting_screenshare");
      }
      console.warn(
        "SoftPhone",
        "OnAutoAnswerCall",
        " ---------------------------  done  ---------------------------"
      );
    } catch (error) {
      console.error(
        "SoftPhone",
        "OnAutoAnswerCall",
        "Fail To Auto Answer Call",
        error
      );
      logger.error("SoftPhone", "OnAutoAnswerCall", error.message);
    }
  };
  const OnAnswerCall = () => {
    try {
      console.log("SoftPhone", "OnAnswerCall");
      setShowCallAlert(false);

      //if (callType === "VIDEO_CALL") setIsHideVideoPenal(false);
      if (callType === "VIDEO_CALL") {
        setIsHideVideoPenal(false);
        setIsHideAudioPenal(true);
        setVideoMinimizeScreen(true);
      } else {
        setIsHideVideoPenal(true);
        setIsHideAudioPenal(false);
      }
      stopRingtone();
      phoneEngine.phoneFunctions.answer();
      setCallStatus("Connecting");
      if(callType === "SCREEN_SHARING"){
        setCallStatus("Connecting_screenshare");
        setVideoMinimizeScreen(true);
      }
    } catch (error) {
      console.error("Fail To Answer Call", error);
      logger.error("SoftPhone", "OnAnswerCall", error.message);
    }
  };

  eventEmitter.subscribeToEvents("end_call_session", (arg) => {
    try {
      console.log("SoftPhone", "end_call_session", `arg : ${arg}`);
      if (
        phoneConfig &&
        phoneConfig.basic_config &&
        phoneConfig.basic_config.calls.hangup_call_after_chat_end &&
        interactionId === arg.interactionId
      ) {
        stopScreenSharing();
        callHangUp("end_call_session");
        sendScreenShareRequestStatus = false;
        phoneEngine.phoneFunctions.cancelScreenShareRequest(interactionId);
        closeNotification("screenshare_request");
      }
    } catch (error) {
      console.error("SoftPhone", "end_call_session", error);
      logger.error("SoftPhone", "end_call_session", error.message);
    }
  });

  const endSession = (disconnectedByAgent, reasonCode = 0) => {
    try {
      console.log(
        "SoftPhone",
        "endSession",
        `disconnectedByAgent:${disconnectedByAgent}, reasonCode: ${reasonCode}`,
        JSON.stringify(phoneConfig)
      );
      if (
        phoneConfig.basic_config.calls.end_chat_after_call_hangup &&
        InteractionType &&
        InteractionType.toLowerCase() === "video"
      ) {
        if (reasonCode === 4 || reasonCode === 5) {
          console.log(
            "SoftPhone",
            "end_chat_session",
            `end_chat_session event not fired due to disconnection reason[${reasonCode}] from : ${callee}, InteractionType:${InteractionType}`
          );
          InteractionType = null;
          setInteractionId(null);
          setTimeout(() => {
            showNotification(
              "warning",
              "Call",
              "please wait for to reconnect the call, don't end the session",
              "endSession-wait-for-call",
              "topLeft",
              30
            );
          }, 2000);

          return;
        }

        eventEmitter.fireEvent("end_chat_session", {
          customerId: callee,
          interactionId: interactionId,
          disconnectedByAgent,
        });

        console.log(
          "SoftPhone",
          "end_chat_session",
          `from : ${callee}, InteractionType:${InteractionType}`
        );

        InteractionType = null;
        setInteractionId(null);
        console.warn(
          "SoftPhone",
          "endSession",
          "InteractionType/InteractionId set to null"
        );
        logger.warn(
          "SoftPhone",
          "endSession",
          "InteractionType/InteractionId set to null"
        );
      } else {
        console.debug(
          "SoftPhone",
          "endSession",
          `invoke external api disabled end_chat_after_call_hangup:${phoneConfig.basic_config.calls.end_chat_after_call_hangup} , InteractionType[video] : ${InteractionType}`
        );
        logger.debug(
          "SoftPhone",
          "endSession",
          `invoke external api disabled end_chat_after_call_hangup:${phoneConfig.basic_config.calls.end_chat_after_call_hangup} , InteractionType[video] : ${InteractionType}`
        );
      }
    } catch (error) {
      console.error("SoftPhone", "endSession", error);
      logger.error("SoftPhone", "endSession", error.message);
    }
  };
  const callHangUp = (invoker = "phone") => {
    try {
      console.log("SoftPhone", "callHangUp");
      if (callType === "SELF_VIEW") {
        entryEvents.onIdle();
        return;
      }

      setShowCallAlert(false);
      if (isScreenShare) {
        stopScreenSharing(true);
      }
      phoneEngine.phoneFunctions.hangup(caller, callee);
      if (invoker !== "end_call_session") endSession(true);
    } catch (error) {
      console.error("Fail To End Call", error);
      logger.error("SoftPhone", "callHangUp", error.message);
    }
  };

  const rejectCall = () => {
    try {
      console.log("SoftPhone", "rejectCall");
      miscallStatus = "rejected";
      setShowCallAlert(false);
      if (isScreenShare) {
        stopScreenSharing(true);
      }
      phoneEngine.phoneFunctions.rejectCall(caller, callee);
      setCallStatus("Rejecting");
    } catch (error) {
      console.error("Fail To Reject Call", error);
      logger.error("SoftPhone", "rejectCall", error.message);
    }
  };

  const muteUnmuteAudioVideo = (muteAudio, muteVideo) => {
    try {
      console.log(
        "SoftPhone",
        "muteUnmuteAudioVideo ",
        `muteAudio : ${muteAudio} , muteVideo : ${muteVideo}`
      );
      phoneEngine.phoneFunctions.muteUnmuteAudioVideo(muteAudio, muteVideo);
      // muteUnmuteHandler(caller, callType, muteAudio);
    } catch (e) {
      console.error("muteUnmuteAudioVideo", e);
      logger.error("SoftPhone", "muteUnmuteAudioVideo", e.message);
    }
  };

  const holdUnhold = (state) => {
    try {
      console.log("SoftPhone", "holdUnhold ", `state : ${state} `);
      phoneEngine.phoneFunctions.holdUnhold(state);
    } catch (e) {
      console.error("holdUnhold", e);
      logger.error("SoftPhone", "holdUnhold", e.message);
    }
  };

  const showParticipatorStatus = (userId) => {
    try {
      dispatch(onParticipatorAdded({ userId }));
    } catch (error) {
      console.error("SoftPhone", "showParticipatorStatus", `----`, error);
      logger.error("SoftPhone", "showParticipatorStatus", error.message);
    }
  };

  const openInviteModal = () => {
    try {
      let remotePartyIds = Object.keys(userDetails);
      props.onClickOpenInviteModal(
        (selectedUsers) => {
          try {
            selectedUsers.map((user) => {
              showParticipatorStatus(user.userId);

              phoneEngine.phoneFunctions.addUserConference(
                `user:${user.userId}`
              );
            });
          } catch (e) {
            console.error("onClickOpenInviteModal", e);
            logger.error("SoftPhone", "onClickOpenInviteModal", e.message);
          }
        },
        remotePartyIds,
        false,
        false
      );
    } catch (error) {
      console.error("openInviteModal", error);
      logger.error("SoftPhone", "openInviteModal", error.message);
    }
  };

  const sendInvitationsToConference = () => {
    try {
      let remotePartyIds = Object.keys(userDetails);

      props.onClickOpenInviteModal(
        (selectedUsers) => {
          try {
            selectedUsers.map((user) => {
              showParticipatorStatus(user.userId);
              if (interactionId === null || interactionId === undefined) {
                const userInfo = getUserName(user.userId);
                if (userInfo && userInfo.InteractionId) {
                  setInteractionId(userInfo.InteractionId);
                } else {
                  console.error(
                    "SoftPhone",
                    "sendConferenceRequest",
                    "unable to find InteractionId"
                  );
                  logger.error(
                    "SoftPhone",
                    "sendConferenceRequest",
                    "unable to find InteractionId"
                  );
                }
              }
              if (interactionId) {
                liveChatClient.sendConferenceRequest(
                  user.userId,
                  interactionId,
                  false,
                  true
                );
              } else {
                message.error(
                  "Unable to send conference request due to invalid interaction id",
                  5
                );
                logger.error(
                  "SoftPhone",
                  "sendConferenceRequest",
                  "Unable to send conference request due to invalid interaction id"
                );
              }
            });
          } catch (e) {
            console.error(
              "onClickOpenInviteModal-sendInvitationsToConference",
              e
            );

            logger.error(
              "SoftPhone",
              "sendConferenceRequest",
              "onClickOpenInviteModal-sendInvitationsToConference"
            );
          }
        },
        remotePartyIds,
        true,
        false,
        "invite to conference",
        false,
        "CONFERENCE"
      );
    } catch (error) {
      console.error("openInviteModal-sendInvitationsToConference", error);
      logger.error(
        "SoftPhone",
        "openInviteModal-sendInvitationsToConference",
        error.message
      );
    }
  };

  const screenShareErrors = (payload) => {
    try {
      console.log(
        "SoftPhone",
        "subscribe",
        "stateActionsEvents",
        "SCREENSHARCALLEERROR",
        "SCREENSHAREERROR",
        `payload : ${JSON.stringify(payload)}`
      );
      setIsScreenShare(false);
      setBlockScreenShare(false);
      setisAudioWithScreenshare(false);
      //removeScreenShareStream();
      let msg = "Unable to share your screen";
      switch (payload.errorCode) {
        case 1001:
          msg = iShredMyScreen === false ? "Screenshare cancelled by remote party": null;
          break;
        case 3001:
        case 4005:
          msg = "Screenshare invitation rejected by remote party";
          break;
        case 3005:
          msg =
            callDirection === "Incoming"
              ? "screen sharing rejected by remote party"
              : "you have cancelled screen share"; //  "Permission to access a screen area was denied by the user or the current browsing instance is not permitted access to screen sharing";
          break;
        case 3007:
          msg = "Preventing the sharing of the selected source";
          break;
        case "SYSTEM_Error":
        case "SYSTEM_ERROR":
          msg = "Device doesn't support for screen-share";
          break;
        case 3000:{
          if(iShredMyScreen === true){
            msg = "you have cancelled screen share";
          }
          break;
        }
        default:
      }
      msg && message.error(msg);
    } catch (error) {
      console.error("SoftPhone", "screenShareErrors", error);
      logger.error("SoftPhone", "screenShareErrors", error.message);
    }
  };
  function muteUnmuteHandler(userId, callType, isMuted) {
    console.log(
      "SoftPhone",
      "RemoteMute",
      "muteUnmuteHandler",
      `userId:${userId}, callType:${callType}, isMuted:${isMuted}`
    );
    if (isMuted) {
      dispatch(OnRemoteMuted({ userId, callType, isMuted }));
    } else {
      dispatch(OnRemoteUnmuted({ userId, callType, isMuted }));
    }
  }

  const actionsEvents = {
    onIncomingCall: (payload) => {
      try {
        console.warn(
          "SoftPhone",
          "onIncomingCall",
          "--------------------------- Incoming Call ---------------------------"
        );

        console.log(
          "SoftPhone",
          "onIncomingCall",
          "Ringtone",
          "executing palyRingtone"
        );
        palyRingtone();
        console.log(
          "SoftPhone",
          "onIncomingCall",
          "Ringtone",
          "executed palyRingtone"
        );
        setCallType(payload.callType);

        setVideoMinimizeScreen(false);
        setCallDirection(`Incoming`);
        setIsHideVideoPenal(payload.callType === "VIDEO_CALL");
        setIsHideAudioPenal(payload.callType === "AUDIO_CALL");
        const number = payload.callee.replace("user:", "");
        //setShowCallAlert(true);
        setCallee(number);

        miscallStatus = "miscall";

        /* props.receiveCall(number);
                        props.getClientProfileByUserName(number); */
        setCalleeName("Unknown Number");
        const user = getUserName(number);
        if (user) {
          setCalleeName(`${user.userName}`);
          if (user.InteractionId) {
            console.warn(
              "SoftPhone",
              "onIncomingCall",
              "--------------------------- adding auto answer function  ---------------------------"
            );

            setAutoAnswerFeature(autoAnswer);
            InteractionType = user.interactions
              ? user.interactions.Channel
              : "";
            setInteractionId(user.InteractionId);

            if (currentInteractionId !== user.InteractionId) {
              callRelatedRequestAccepted = false;
              console.log(
                "SoftPhone",
                "onIncomingCall",
                `callRelatedRequestAccepted value reset due to new interaction id received with call. currentInteractionId : ${currentInteractionId} || interactionId with call : ${user.InteractionId} `
              );
            }

            console.warn(
              "SoftPhone",
              "onIncomingCall",
              "--------------------------- auto answer function added ---------------------------"
            );
          } else {
            console.warn(
              "SoftPhone",
              "onIncomingCall",
              "unable to find InteractionId/ auto answer not functioning"
            );
            logger.warn(
              "SoftPhone",
              "onIncomingCall",
              "unable to find InteractionId/ auto answer not functioning"
            );
          }
        } else {
          console.error(
            "SoftPhone",
            "onIncomingCall",
            "unable to find user details"
          );
          logger.error(
            "SoftPhone",
            "onIncomingCall",
            "unable to find user details"
          );
        }

        setShowCallAlert(true);
        dispatch(
          onShowNotification({
            action_type: WEB_NOTIFICATION,
            message: `You Are Receiving a Call From ${user.firstName} ${user.lastName}`,
            duration: 5,
            onFocussed: globalFlags.__FOCUSSED__,
          })
        );

        closeNotification("endSession-wait-for-call");
        console.log(
          "SoftPhone",
          "actionsEvents",
          "onIncomingCall",
          `onIncomingCall, data :${JSON.stringify(
            maskProperties(payload, [
              "customerId",
              "firstName",
              "lastName",
              "userData",
              "CustomerInfo",
            ])
          )} , user :${JSON.stringify(
            maskProperties(user, [
              "customerId",
              "firstName",
              "lastName",
              "userData",
              "CustomerInfo",
              "userName",
              "CustomerUserId",
            ])
          )} , colleagues :*`
        ); //${JSON.stringify(props.colleagues)}
      } catch (ex) {
        console.error("SoftPhone", "actionsEvents", "onIncomingCall", ex);
        logger.error("SoftPhone", "actionsEvents", ex.message);
      }
    },
    onScreenShareRequest: (payload) => {
      try {
        const id = payload.callee.replace("user:", "");
        const user = getUserName(id);
        let userName = user.userName;
        sendScreenShareRequestStatus = true;
        const screenReqTime = setTimeout(() => {
          if (sendScreenShareRequestStatus) {
            Modal.destroyAll();
            sendScreenShareRequestStatus = false;
          }
        }, 20000);
        playNotification();
        confirm({
          title: "Screen Share Request",
          content: `${userName} want to share his screen with you`,
          onOk() {
            clearTimeout(screenReqTime);
            phoneEngine.phoneFunctions.acceptScreenShareRequest(
              caller,
              userName
            );
          },
          onCancel() {
            clearTimeout(screenReqTime);
            phoneEngine.phoneFunctions.rejectScreenShareRequest(
              caller,
              userName
            );
          },
        });
        dispatch(
          onShowNotification({
            action_type: WEB_NOTIFICATION,
            message: `${userName}  want to share his screen with you`,
            duration: 5,
            onFocussed: globalFlags.__FOCUSSED__,
          })
        );
      } catch (ex) {
        console.error("SoftPhone", "actionsEvents", "onScreenShareRequest", ex);
        logger.error(
          "SoftPhone",
          "actionsEvents- onScreenShareRequest",
          ex.message
        );
      }
    },
    onRemoteAnswered: (payload) => {
      try {
        console.log(
          "SoftPhone",
          "Callback",
          "OnStreamsChanged",
          "onRemoteAnswered",
          ` remoteStreams : ${payload.remoteStreams.length},localStreams : ${payload.localStreams.length
          } payload : ${JSON.stringify(maskProperties(payload, ["userId"]))}`
        );

        stopRingbackTone();
        addRemoteStreams(payload);
        setIsAllowedScreenShare(true);
      } catch (error) {
        console.error(
          "SoftPhone",
          "actionsEvents",
          "onRemoteAnswered",
          "Fail To bind Media",
          error
        );
        logger.error("SoftPhone", "onRemoteAnswered", error.message);
      }
    },
    onStartCalling: (payload) => {
      //caller, callee
      setCallee(payload.callee);
      //
    },
    OnVoiceActivity: (payload) => {
      try {
        let userId = payload.userId;
        if (userId !== "") {
          console.log(
            "SoftPhone",
            "actionsEvents",
            "OnVoiceActivity",
            "User " + userId + " is currently speaking"
          );
          userId = userId.replace("user:", "");
          const user = getUserName(userId);
          let userName = user.userName;
          dispatch(
            OnVoiceActivity({
              userId,
              userName,
              previousUserId: payload.previousUserId,
            })
          );
        }
      } catch (error) {
        console.error(
          "SoftPhone",
          "actionsEvents",
          "OnVoiceActivity",
          "OnVoiceActivity",
          error
        );
        logger.error("SoftPhone", "OnVoiceActivity", error.message);
      }
    },
  };

  const entryEvents = {
    onConversation: () => {
      try {
        stopRingbackTone();
        setControlledPosition(null);
        setCallDurationComponent(
          <CallDuration
            ref={refCallDuration}
            nStart={audioUpgradeToVideo.time}
          />
        );
        props.OnClickOutGoingCall();
        if (callType.includes("VIDEO_CALL")) {
          props.onClickVideoCallAnz();
        } else {
          props.OnClickAnzAudioCall();
        }

        stopRingtone();

        miscallStatus = "answered";
        setShowCallAlert(false);
        props.interaction_actions.setCallEscalatedStatus(interactionId, true);
        auth.resetMediaDeviceErrorCount();
        setHasMediaDeviceError(false);
      } catch (ex) {
        console.error("SoftPhone", "entryEvents", "onConversation", ex);
        logger.error("SoftPhone", "onConversation", ex.message);
      }
    },
    onIdle: () => {
      try {
        eventEmitter.fireEvent("on_call_idle", null);

      } catch (ex) {
        console.error("SoftPhone", "entryEvents", "onIdle", ex);
        logger.error("SoftPhone", "onIdle", ex.message);
      }

      try {
        phoneEngine.phoneFunctions.resetValues();
        setisAudioWithScreenshare(false);
        setCallStatus("");
        setCallType("");
        setScreenShareSelf(false);
        callRelatedRequestAccepted = false;
        stopRingbackTone();
        setScreenShareConCallStreams(null);
        setScreenShareSwapId(null);
        setCallDurationComponent(null);
        setAudioUpgradeToVideo({ state: false, time: 0 });
        setControlledPosition({
          x: 100,
          y: 0,
        });
        setScreenShareStreams({});
        setIsAllowedScreenShare(false);
        setVideoMinimizeScreen(false);
        setRemoteStreams({});
        setIsHideVideoPenal(true);
        setIsHideAudioPenal(true);
        appendMiscallToTimeline();
        miscallStatus = "";
        stopRingtone();

        setShowCallAlert(false);

        setCalleeName("");
        if (callType.includes("VIDEO_CALL")) {
          props.onClickEndVideoCall();
        } else {
          props.OnClickAudioEndCall();
        }
        
        props.onClickCloseModal();

        setIsScreenShare(false);
        setBlockScreenShare(false);        
        onClickHideParticipation();
        closeNotification("Network_Detected");
        closeNotification("screenshare_request");
        props.interaction_actions.setCallEscalatedStatus(interactionId, false);
        setInteractionId(null);
        setAutoAnswerFeature(autoAnswer && autoAnswerEveryCall);
        transferStatus = false;
        dispatch(onCallControlStateRest());
        if (phoneConfig.basic_config.calls.selfView.active === true) {
          phoneEngine.phoneFunctions.releaseLocalVideo(phoneConfig);
          console.debug("SoftPhone", "releaseLocalVideo", "remove self view");
        }

        closeNotification("endSession-wait-for-call");

        Modal.destroyAll();
      } catch (ex) {
        console.error("SoftPhone", "entryEvents", "onIdle", ex);
        logger.error("SoftPhone", "onIdle", ex.message);
      }
    },
    onRinging: (payload) => {
      /* if (payload.type === "INCOMINGCALL") palyRingtone(); */
    },
  };

  const alertToUser = (msg, title, duration) => {
    try {
      //message.error(msg, duration);
      showNotification(
        "error",
        title,
        msg,
        "call_Permission",
        "bottomLeft",
        duration
      );

      dispatch(
        onShowNotification({
          action_type: WEB_NOTIFICATION,
          message: msg,
          duration: duration,
          onFocussed: globalFlags.__FOCUSSED__,
        })
      );
    } catch (error) {
      console.error("SoftPhone", "alertToUser", error);
    }
  };

  const mediaDeviceError = (errorCode) => {
    try {
      if (
        phoneConfig.media_error_codes &&
        phoneConfig.media_error_codes.mediaDeviceSpecificErrorCodes &&
        phoneConfig.media_error_codes.mediaDeviceSpecificErrorCodes.indexOf(
          errorCode
        ) > 0 && phoneConfig.basic_config.console.mediaDevice.active === true
      ) {
        dispatch(
          OnMediaDeviceError({
            isMediaDeviceAccessGranted: false,
            isMicrophoneAlreadyCaptured: false,
            isWebcamAlreadyCaptured: false,
          })
        );

        const error_count = auth.getMediaDeviceErrorCount().count;
        if (error_count === media_deceive_accepted_error_limit) {
          auth.setMediaDeviceErrorCount();
          setHasMediaDeviceError(true);
          auth.setMediaDeviceErrorCount(); // to be safe side. value increment twice
        } else if (error_count < media_deceive_accepted_error_limit) {
          auth.setMediaDeviceErrorCount();
        }
      } else {
        console.debug(
          "SoftPhone",
          "mediaDeviceError",
          "ignore duplicate alert/disable by config",
          `config  mediaDevice.active : ${phoneConfig.basic_config.console.mediaDevice.active}`
        );
      }
    } catch (error) {
      console.error("SoftPhone", "mediaDeviceError", error);
    }
  };

  const call_back_error_message_handler = (payload) => {
    if (
      phoneConfig.media_error_codes &&
      phoneConfig.media_error_codes.errorCodes
    ) {
      let msg = "Call related system issue triggered";
      let title = "Soft Phone";
      if (
        phoneConfig.media_error_codes.errorCodes.indexOf(payload.reasonCode) > 0
      ) {
        if (
          phoneConfig.media_error_codes.errorMessage &&
          phoneConfig.media_error_codes.errorMessage[payload.reasonCode]
        ) {
          msg =
            phoneConfig.media_error_codes.errorMessage[payload.reasonCode]
              .message;
          title =
            phoneConfig.media_error_codes.errorMessage[payload.reasonCode]
              .title;
        }
        mediaDeviceError(payload.reasonCode);
      }
      alertToUser(msg, title, 20);
    } else {
      message.error("call related system issue triggered.", 10);
    }
  };

  phoneEngine.phone.subscribe.stateActionsEvents("index", (payload) => {
    try {
      switch (payload.type) {
        case "INCOMINGCALL": {
          actionsEvents.onIncomingCall(payload);

          break;
        }
        case "ONSCREENSHAREREQUEST": {
          actionsEvents.onScreenShareRequest(payload);
          break;
        }
        case "ANSWERED":
        case "REMOTEANSWER": {
          actionsEvents.onRemoteAnswered(payload);
          break;
        }
        case "REMOTEHANGUP": {
          endSession(false, payload.reasonCode);
          if (!transferStatus) {
            showNotification(
              "info",
              "call",
              `call disconnected due to remote party hangup`,
              "call_disconnect",
              "bottomRight",
              10
            );
          }
          break;
        }
        case "CALLEEOFFLINE": {
          break;
        }
        case "REMOTERINGING": {
          palyRingbackTone();
          break;
        }
        case "STREAMCHANGE":
        case "STREAMCHANGETOVIDEO": {
          console.log(
            "SoftPhone",
            "Callback",
            "OnStreamsChanged",
            "STREAMCHANGE-STREAMCHANGETOVIDEO",
            ` remoteStreams : ${payload.remoteStreams.length},localStreams : ${payload.localStreams.length
            } payload : ${JSON.stringify(payload)}`
          );
          addRemoteStreams(payload);
          break;
        }
        case "USERJOINING": {
          const user = getUserName(payload.userId);
          if (user) {
            message.success(`${user.userName} join`, 5);
          }
          try {
            userJoin.play();
          } catch (error) {
            console.error("SoftPhone", "USERJOINING", "play sound", error);
          }

          break;
        }
        case "USERLEFT": {
          const user = getUserName(payload.userId);
          if (user) {
            message.warning(`${user.userName} left `, 5);
          }

          if (
            screenShareConCallStreams &&
            screenShareConCallStreams.id === payload.userId
          )
            setScreenShareConCallStreams(null);

          setisAudioWithScreenshare(false);

          try {
            userLeave.play();
          } catch (error) {
            console.error("SoftPhone", "USERLEFT", "play sound", error);
          }
          break;
        }
        case "STARTCALLING": {
          actionsEvents.onStartCalling(payload);
          break;
        }
        case "VOICEACTIVITY": {
          actionsEvents.OnVoiceActivity(payload);

          break;
        }
        case "SCREENSHARECONNECTED": {
          try {
            setCallStatus("ScreenShared");
            miscallStatus = "answered";
            if (isScreenShare || callType !== "SCREEN_SHARING") {
              setVideoMinimizeScreen(true);
              setCallType("SCREENSHARE");
              //setScreenShareSelf(true);
              setScreenShareStreams(payload.remoteStreams);
              setControlledPosition(null);

              console.error(
                "ScreenShare-Audio",
                "SCREENSHARE Event Fired For both-end"
              );
              logger.error(
                "SoftPhone",
                "ScreenShare-Audio",
                "SCREENSHARE Event Fired For both-end"
              );
              return;
            }
            //setScreenShareSelf(false);
            setBlockScreenShare(true);
            setControlledPosition(null);
            setCallType("SCREENSHARE");
            setScreenShareStreams(payload.remoteStreams);
            setVideoMinimizeScreen(true);
            closeNotification("screenshare_request");
          } catch (error) {
            console.error("SCREENSHARE", error);
            logger.error("SoftPhone", "SCREENSHARECONNECTED", error.message);
          }
          break;
        }
        case "SCREENSHAREREMOTEHANGUP": {
          removeScreenShare();
          setIsScreenShare(false);
          setBlockScreenShare(false);
          setisAudioWithScreenshare(false);

          break;
        }
        case "SCREENSHARECALL": {
          setScreenShareConCallStreams(payload);
          if (!payload.isConCall) {
            addScreenShareStream(payload);
          }
          setBlockScreenShare(true);
          break;
        }
        case "SCREENSHARECALLHANGUP":
        case "SCREENSHARECALLREMOTEHANGUP": {
          removeScreenShareStream();
          setIsScreenShare(false);
          setBlockScreenShare(false);
          setisAudioWithScreenshare(false);

          break;
        }
        case "UPGRADETOVIDEO": {
          setAudioUpgradeToVideo({
            state: true,
            time: refCallDuration.current
              ? refCallDuration.current.getTime()
              : 0,
          });
          setRemoteStreams({});
          setVideoMinimizeScreen(true);
          setCallType("VIDEO_CALL");
          setIsHideAudioPenal(true);
          setIsHideVideoPenal(false);
          break;
        }
        case "SCREENSHARCALLEERROR":
        case "SCREENSHARREQUESTREMOTEREJECT":
        case "SCREENSHAREERROR": {
          screenShareErrors(payload);
          break;
        }

        case "REMOTEMUTEUNMUTE": {
          muteUnmuteHandler(payload.userId, payload.callType, payload.isMuted);
          break;
        }

        case "Error":
        case "ERROR": {
          console.error(
            "SoftPhone",
            "subscribe",
            "stateActionsEvents",
            `payload : ${JSON.stringify(payload)}`
          );
          logger.error(
            "SoftPhone",
            "subscribe-ERROR",
            `payload : ${JSON.stringify(payload)}`
          );
          call_back_error_message_handler(payload);

          break;
        }
        default: {
          break;
        }
      }
      if (callType === "AUDIO_CALL" && childRefAudioCall.current) {
        childRefAudioCall.current.stateActionsEvents(payload);
      } else if (callType === "VIDEO_CALL") {
        childRefVideoCall.current.stateActionsEvents(payload);
      }
      console.log(
        "SoftPhone",
        "subscribe",
        "stateActionsEvents",
        `payload : ${JSON.stringify(maskProperties(payload, ["callee"]))}`
      );
    } catch (error) {
      console.error("SoftPhone", "subscribe", "stateActionsEvents", error);
      logger.error("SoftPhone", "subscribe", error.message);
    }
  });

  phoneEngine.phone.subscribe.stateEntryEvents("index", (state, payload) => {
    try {
      props.callActions.callProgress(state, payload.type);

      switch (state) {
        case "Conversation": {
          entryEvents.onConversation();
          setAvailability("busy");
          setCallStatus("Connecting");
          break;
        }
        case "Idle": {
          entryEvents.onIdle();
          setAvailability("available");
          break;
        }
        case "Ringing": {
          entryEvents.onRinging(payload);
          setAvailability("busy");
          break;
        }
        case "ScreenShare":
          stopRingbackTone();
          setAvailability("busy");

          break;
        case "Error":
        case "ERROR": {
          console.error(
            "SoftPhone",
            "subscribe",
            "stateActionsEvents",
            `payload : ${JSON.stringify(payload)}`
          );
          logger.error(
            "SoftPhone",
            "subscribe-stateActionsEvents-ERROR",
            `payload : ${JSON.stringify(payload)}`
          );
          entryEvents.onIdle();

          call_back_error_message_handler(payload);

          break;
        }
        default: {
          break;
        }
      }
      //setCallStatus(`${state}. . .`);
      console.log(
        "SoftPhone",
        "subscribe",
        "stateEntryEvents",
        `payload : ${JSON.stringify(payload)}`
      );
    } catch (error) {
      console.error("SoftPhone", "subscribe", "stateEntryEvents", error);
      logger.error("SoftPhone", "subscribe-stateEntryEvents", error.message);
    }
  });

  phoneEngine.phone.subscribe.networkBandwidth("index", (payload) => {
    try {
      if (payload < 2) {
        let type = "warning";
        if (payload < 1) {
          type = "error";
        }
        console.debug("SoftPhone", "networkBandwidth", `MOS : ${payload} , type : ${type}`);
        notification[type]({
          key: "Network_Detected",
          message: "Slow network is detected",
          placement: "topRight",
          duration: 5,
          style: {
            width: 300,
          },
        });
      }

    } catch (error) {
      console.error("SoftPhone", "networkBandwidth-index", error);
      logger.error("SoftPhone", "networkBandwidth-index", error.message);
    }
  });

  phoneEngine.phone.subscribe.onDeviceChange("index", (devices) => {
    try {
      console.log(
        "SoftPhone",
        "Callback",
        "onDeviceChange",
        "devices details",
        JSON.stringify(devices)
      );

      if (callType === "SELF_VIEW") {
        phoneEngine.phoneFunctions.releaseLocalVideoForcefully();
        entryEvents.onIdle();
      }
      const seconds =
        (Math.abs(Date.now() - lastDeviceChangeEventTime) / 1000) % 60;
      const eventCondition =
        seconds >
        phoneConfig.basic_config.console.deviceChangeEventTriggeredDuration;
      if (
        eventCondition &&
        phoneConfig &&
        phoneConfig.isAllowedVideoCall &&
        phoneConfig.basic_config.console.mediaDevice.active === true
      ) {
        console.debug(
          "SoftPhone",
          "onDeviceChange",
          "close notification",
          `durations :${seconds}`,
          `deviceChangeEventTriggeredDuration: ${phoneConfig.basic_config.console.deviceChangeEventTriggeredDuration}`
        );
        closeNotification("mediaDeviceAlert");
      } else {
        console.debug(
          "SoftPhone",
          "onDeviceChange",
          "ignore duplicate alert/disable by config",
          `durations :${seconds}`,
          `deviceChangeEventTriggeredDuration: ${phoneConfig.basic_config.console.deviceChangeEventTriggeredDuration}`
        );
        return;
      }

      lastDeviceChangeEventTime = Date.now();

      const mediaDevicesDetailsTemp = JSON.parse(
        JSON.stringify(mediaDevicesDetails)
      );
      const mediaDevices = dispatchDeviceLoaded(devices);

      const msg = "Media Devices Configuration/Permission Changed";
      const alertShow =
        auth.getMediaDeviceErrorCount().count >
        media_deceive_accepted_error_limit;

      notification.open({
        key: "mediaDeviceAlert",
        message: msg,
        className: "device-notification",
        description: (
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Timeline>
              <Timeline.Item>
                Previous
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                  <Col span={2}>
                    <VideoCameraOutlined style={{ color: "gray" }} />
                  </Col>
                  <Col span={22}>
                    <Tag color="#aca9a9">
                      {" "}
                      {mediaDevicesDetailsTemp.defaultCameraLabel}
                    </Tag>
                  </Col>
                </Row>
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                  <Col span={2}>
                    <AudioOutlined style={{ color: "gray" }} />
                  </Col>
                  <Col span={22}>
                    <Tag color="#aca9a9">
                      {" "}
                      {mediaDevicesDetailsTemp.defaultMicLabel}
                    </Tag>
                  </Col>
                </Row>
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                  <Col span={2}>
                    <SoundOutlined style={{ color: "gray" }} />
                  </Col>
                  <Col span={22}>
                    <Tag color="#aca9a9">
                      {" "}
                      {mediaDevicesDetailsTemp.defaultSpeakerLabel}
                    </Tag>
                  </Col>
                </Row>
              </Timeline.Item>
              <Timeline.Item color="green">
                Current
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                  <Col span={2}>
                    <VideoCameraOutlined
                      style={{
                        color:
                          mediaDevicesDetailsTemp.defaultCameraLabel ===
                            mediaDevices.defaultCameraLabel
                            ? "gray"
                            : "hotpink",
                      }}
                    />
                  </Col>
                  <Col span={22}>
                    <Tag
                      color={
                        mediaDevicesDetailsTemp.defaultCameraLabel ===
                          mediaDevices.defaultCameraLabel
                          ? "#aca9a9"
                          : "hotpink"
                      }
                    >
                      {" "}
                      {mediaDevices.defaultCameraLabel}
                    </Tag>
                  </Col>
                </Row>
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                  <Col span={2}>
                    <AudioOutlined
                      style={{
                        color:
                          mediaDevicesDetailsTemp.defaultMicLabel ===
                            mediaDevices.defaultMicLabel
                            ? "gray"
                            : "hotpink",
                      }}
                    />
                  </Col>
                  <Col span={22}>
                    <Tag
                      color={
                        mediaDevicesDetailsTemp.defaultMicLabel ===
                          mediaDevices.defaultMicLabel
                          ? "#aca9a9"
                          : "hotpink"
                      }
                    >
                      {" "}
                      {mediaDevices.defaultMicLabel}
                    </Tag>
                  </Col>
                </Row>
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                  <Col span={2}>
                    <SoundOutlined
                      style={{
                        color:
                          mediaDevicesDetailsTemp.defaultSpeakerLabel ===
                            mediaDevices.defaultSpeakerLabel
                            ? "gray"
                            : "hotpink",
                      }}
                    />
                  </Col>
                  <Col span={22}>
                    <Tag
                      color={
                        mediaDevicesDetailsTemp.defaultSpeakerLabel ===
                          mediaDevices.defaultSpeakerLabel
                          ? "#aca9a9"
                          : "hotpink"
                      }
                    >
                      {" "}
                      {mediaDevices.defaultSpeakerLabel}
                    </Tag>
                  </Col>
                </Row>
              </Timeline.Item>
            </Timeline>
          </Row>
        ),
        icon: <ApiTwoTone twoToneColor="#eb2f96" />,
        placement: alertShow ? "topLeft" : "bottomLeft",
        duration: alertShow ? 5 : 30,
        //style:{width:"500px"}
      });

      if (eventCondition) {
        dispatch(
          onShowNotification({
            action_type: WEB_NOTIFICATION,
            message: msg,
            duration: 15,
            onFocussed: globalFlags.__FOCUSSED__,
          })
        );
      }

      mediaDevicesDetails = mediaDevices;
      console.log("SoftPhone", "Callback", "onDeviceChange", "done");
    } catch (error) {
      console.error("SoftPhone", "onDeviceChange-index", error);
      logger.error("SoftPhone", "onDeviceChange-index", error.message);
    }
  });

  phoneEngine.phone.subscribe.session("index", (payload) => {
    try {
      console.info("receive session info");
      switch (payload.stateEvent) {
        case "OnOfferReply": {
          props.sendCallMessageSuccess(caller, callee, payload.session);
          break;
        }
        case "OnAnswerAcceptedReply": {
          props.setCallStatus(payload.session, callee, caller, "receive", 200);
          break;
        }
        case "SESSIONEXCEEDED": {
          message.error(payload.message, 5);
          break;
        }
        default: {
        }
      }
    } catch (ex) {
      console.error(ex);
      logger.error("SoftPhone", "session-index", ex.message);
    }
  });

  liveChatClient.subscribeEvent(
    "softPhone",
    "onConferenceRequest",
    (acceptRejectObject) => {
      try {
        console.warn(
          "SoftPhone",
          "onConferenceRequest",
          "auto accepting conference request"
        );
        playNotification();
        eventEmitter.fireEvent("show_modal", {
          title: "Accept Conference",
          yesButtonText: "Accept",
          cancelButtonText: "Reject",
          onYes: () => {
            props.team_actions.getTemporaryAgents(() => {
              let interactionInfo = new Interaction(
                null,
                acceptRejectObject.InteractionInformation
              );
              let custInfo = {
                reqSkill: "",
                type: "Customer",
                interactionId: acceptRejectObject.InteractionId,
                InteractionId: acceptRejectObject.InteractionId,
                intent: interactionInfo.Intent,
                channel: interactionInfo.Channel,
                userDetails: interactionInfo.CustomerInfo,
                userId: interactionInfo.CustomerUserId,
              };
              props.team_actions.addCustomerInfo(custInfo);
              acceptRejectObject.accept();
              callRelatedRequestAccepted = true;
              currentInteractionId = acceptRejectObject.InteractionId;
              console.log(
                "SoftPhone",
                "onConferenceRequest",
                "currentInteractionId",
                `new interaction id assigned : ${currentInteractionId}`
              );
              setAutoAnswerFeature(autoAnswer);
              setTimeout(() => {
                callRelatedRequestAccepted = false;
              }, 15000);
            });
          },
          onCancel: () => {
            acceptRejectObject.reject();
          },
        });
        // acceptRejectObject.accept();
      } catch (error) {
        console.error("SoftPhone", "onConferenceRequest", error);
        logger.error("SoftPhone", "onConferenceRequest", error.message);
      }
    }
  );

  liveChatClient.subscribeEvent(
    "softPhone",
    "onConferenceResponseAcknowledgement",
    (responseObject) => {
      try {
        console.warn(
          "SoftPhone",
          "onConferenceResponseAcknowledgement",
          responseObject
        );
        if (responseObject.isAccepted) {
          message.info("Conference request accepted");
        } else {
          message.warn("Conference request rejected");
        }
      } catch (error) {
        console.error(
          "SoftPhone",
          "onConferenceResponseAcknowledgement",
          error
        );
        logger.error(
          "SoftPhone",
          "onConferenceResponseAcknowledgement",
          error.message
        );
      }
    }
  );

  const screenShareRequestProcessor = (msg) => {
    try {
      const id = msg.from.id.replace("user:", "");
      const user = getUserName(id);
      let userName = user.userName;

      const responseHandler = () => {
        closeNotification("screenshare_request");
        if (sendScreenShareRequestStatus) {
          sendScreenShareRequestStatus = false;
          showNotification(
            "error",
            "Screenshare",
            `Screenshare request rejected by ${userName} `,
            "screenshare_request",
            "topRight"
          );
          setTimeout(function () {
            phoneEngine.phoneFunctions.cancelScreenShareRequest(id); // we need to change this in later
            console.log(
              "SoftPhone",
              "screenshareRequest",
              "softphone_screen_share",
              "onAppEvent",
              "cancel screenshare request, user not responding"
            );
          }, 3000);
        }
      };

      const requestHandler = () => {
        phoneEngine.phoneFunctions.setScreenShareRequest(id);
        sendScreenShareRequestStatus = true;
        const screenReqTime = setTimeout(() => {
          if (sendScreenShareRequestStatus) {
            Modal.destroyAll();
            sendScreenShareRequestStatus = false;
            sendScreenshareRejected(id, "SCREENSHARE_REQUEST_TIMEOUT");
          }
        }, 25000);
        playNotification();
        confirm({
          title: "Request to share your screen",
          content: `${userName} is requesting you to share your screen`,
          onOk() {
            clearTimeout(screenReqTime);
            startScreenSharing(id, false);
          },
          onCancel() {
            clearTimeout(screenReqTime);
            sendScreenshareRejected(id, "REJECT_SCREENSHARE_REQUEST");
          },
        });
        dispatch(
          onShowNotification({
            action_type: WEB_NOTIFICATION,
            message: `${userName} is requesting you to share your screen`,
            duration: 5,
            onFocussed: globalFlags.__FOCUSSED__,
          })
        );
      };

      switch (msg.message_content.command) {
        case "SCREENSHARE_REQUEST":
          requestHandler();
          break;
        case "REJECT_SCREENSHARE_REQUEST":
        case "SCREENSHARE_REQUEST_TIMEOUT":
          responseHandler();
          break;
        default:
        // code block
      }

      /* if (
          msg.message_type === "SCREENSHARE_REQUEST" &&
          msg.message_content.command === "SCREENSHARE_REQUEST"
        ) {
          phoneEngine.phoneFunctions.setScreenShareRequest(id);
          sendScreenShareRequestStatus = true;
          const screenReqTime = setTimeout(() => {
            if (sendScreenShareRequestStatus) {
              Modal.destroyAll();
              sendScreenShareRequestStatus = false;
              sendScreenshareRejected(id, "SCREENSHARE_REQUEST_TIMEOUT");
            }
          }, 25000);
          playNotification();
          confirm({
            title: "Request to share your screen",
            content: `${userName} is requesting you to share your screen`,
            onOk() {
              clearTimeout(screenReqTime);
              startScreenSharing(id, false);
            },
            onCancel() {
              clearTimeout(screenReqTime);
              sendScreenshareRejected(id, "REJECT_SCREENSHARE_REQUEST");
            },
          });
          dispatch(
            onShowNotification({
              action_type:WEB_NOTIFICATION,
              message: `${userName} is requesting you to share your screen`,
              duration: 5,
            })
          );
        } else if (
          msg.message_type === "SCREENSHARE_REQUEST" &&
          (msg.message_content.command === "REJECT_SCREENSHARE_REQUEST" ||
            msg.message_content.command === "SCREENSHARE_REQUEST_TIMEOUT")
        ) {
          closeNotification("screenshare_request");
          if (sendScreenShareRequestStatus) {
            sendScreenShareRequestStatus = false;
            showNotification(
              "error",
              "Screenshare",
              `Screenshare request rejected by ${userName} `,
              "screenshare_request",
              "topRight"
            );
            setTimeout(function () {
              phoneEngine.phoneFunctions.cancelScreenShareRequest(id); // we need to change this in later
              console.log(
                "SoftPhone",
                "screenshareRequest",
                "softphone_screen_share",
                "onAppEvent",
                "cancel screenshare request, user not responding"
              );
            }, 3000);
          }
        } */
    } catch (error) {
      console.error("SoftPhone", "screenShareRequestProcessor", error);
      logger.error("SoftPhone", "screenShareRequestProcessor", error.message);
    }
  };

  const phoneNotificationsProcessor = (msg) => {
    try {
      const uiStatusHandle = () => {
        showNotification(
          msg.message_content.type ? msg.message_content.type : "success",
          msg.message_content.message,
          msg.message_content.description,
          "uiStatusHandle",
          "bottomRight",
          msg.message_content.duration ? msg.message_content.duration : 5
        );
      };
      const connectivityStatusHandle = () => {
        dispatch(
          onShowNotification({
            action_type: PHONE_NOTIFICATION,
            type: msg.message_content.type
              ? msg.message_content.type
              : "success",
            message: msg.message_content.message,
            description: msg.message_content.description,
            key: "connectivityStatusHandle",
            placement: "bottomRight",
            duration: msg.message_content.duration
              ? msg.message_content.duration
              : 5,
            onFocussed: globalFlags.__FOCUSSED__,
          })
        );
      };

      switch (msg.message_content.command) {
        case "UI_STATUS":
          uiStatusHandle();
          break;
        case "CONNECTIVITY_STATUS":
          connectivityStatusHandle();
          break;
        default:
        // code block
      }
    } catch (error) {
      console.error("SoftPhone", "phoneNotificationsProcessor", error);
      logger.error("SoftPhone", "phoneNotificationsProcessor", error.message);
    }
  };

  /* const samapleMSg = {
    message_type : "PHONE_APP_NOTIFICATIONS",
    message_content : {
          command :"UI_STATUS", //"CONNECTIVITY_STATUS"
          message: "UI Issue",
          duration:5 // seconds
        }
  } */

  liveChatClient.subscribeEvent(
    "softphone_app_event_subscriber",
    "onAppEvent",
    (appMessage) => {
      try {
        console.log(
          "SoftPhone",
          "subscribeEvent",
          "softphone_app_event_subscriber",
          "onAppEvent",
          `appMessage : ${appMessage}`
        );
        const msg = JSON.parse(appMessage);
        switch (msg.message_type) {
          case "SCREENSHARE_REQUEST":
            screenShareRequestProcessor(msg);
            break;
          case "PHONE_APP_NOTIFICATIONS":
            phoneNotificationsProcessor(msg);
            break;
          default:
          // code block
        }
      } catch (error) {
        console.error(
          "SoftPhone",
          "subscribeEvent",
          "softphone_app_event_subscriber",
          "onAppEvent",
          `appMessage:${message}`,
          error
        );
        logger.error(
          "SoftPhone",
          "softphone_app_event_subscriber",
          error.message
        );
      }
    }
  );

  liveChatClient.subscribeEvent(
    "softPhone",
    "onInteractionTransferResponseAcknowledgement",
    (reqStatus) => {
      try {
        transferStatus = reqStatus.isAccepted;
      } catch (error) {
        console.error(
          "SoftPhone",
          "onInteractionTransferResponseAcknowledgement",
          `message:${reqStatus}`,
          error
        );
        logger.error(
          "SoftPhone",
          "onInteractionTransferResponseAcknowledgement",
          error.message
        );
      }
    }
  );

  phoneEngine.phone.subscribe.onError("index", (reason, ex) => {
    try {
      message.error(reason, 5);
    } catch (error) {
      console.error("SoftPhone", "subscribe", "onError", error);
      logger.error("SoftPhone", "subscribe-index-onError", error.message);
    }
  });

  const sendScreenshareRejected = (id, command) => {
    let uniqueId = uuid();
    let msg = {
      v: 1,
      mid: uniqueId,
      sid: uniqueId,
      message_type: "SCREENSHARE_REQUEST",
      from: {
        id: caller,
        name: caller,
      },
      to: {
        id: id,
        name: id,
      },
      created_at: Date.now(),
      message_content: { command: command },
      conversation_type: 0,
    };
    liveChatClient.sendAppEvent(msg, id);
  };

  const sendScreenshareRequest = (profile, calleeName) => {
    try {
      console.log(
        "SoftPhone",
        "screenshareRequest",
        "sendScreenshareRequest",
        `profile : ${profile}, calleeName : ${calleeName}`
      );
      sendScreenShareRequestStatus = true;
      const userId = profile.username.replace("user:", "");
      let uniqueId = uuid();
      let msg = {
        v: 1,
        mid: uniqueId,
        sid: uniqueId,
        message_type: "SCREENSHARE_REQUEST",
        from: {
          id: caller,
          name: caller,
        },
        to: {
          id: userId,
          name: calleeName,
        },
        created_at: Date.now(),
        message_content: { command: "SCREENSHARE_REQUEST" },
        // other: { callDirection, callType, caller, callee },
        conversation_type: 0,
      };
      liveChatClient.sendAppEvent(msg, userId);
      phoneEngine.phoneFunctions.sendScreenShareRequest(userId); // we need to change this in later
      const user = getUserName(userId);

      showNotification(
        "success",
        "Screenshare",
        `Screenshare request send to ${user.userName}`,
        "screenshare_request",
        "topRight",
        35
      );

      setTimeout(() => {
        if (sendScreenShareRequestStatus) {
          //closeNotification("screenshare_request");
          sendScreenShareRequestStatus = false;
          showNotification(
            "warning",
            "Screenshare",
            `Screenshare request to ${user.userName} expired`,
            "screenshare_request",
            "topRight",
            10
          );
          setTimeout(function () {
            phoneEngine.phoneFunctions.cancelScreenShareRequest(userId); // we need to change this in later
            console.log(
              "SoftPhone",
              "screenshareRequest",
              "sendScreenshareRequest",
              "cancel screenshare request due to timeout"
            );
          }, 5000);
        }
      }, 37000);
    } catch (error) {
      console.error(
        "SoftPhone",
        "screenshareRequest",
        "sendScreenshareRequest",
        error
      );
      logger.error("SoftPhone", "screenshareRequest", error.message);
    }
  };

  /* --------------- Phone SDK Events ---------------------- */

  /* --------------- Phone Ringtone ---------------------- */
  const playNotification = () => {
    try {
      if (phoneConfig.basic_config.console.playNotificationSound)
        alertNotification.play();
    } catch (error) {
      console.error("SoftPhone", "playNotification", error);
      logger.error("SoftPhone", "playNotification", error.message);
    }
  };

  function palyRingtone() {
    try {
      if (phoneConfig.basic_config.calls.auto_answer.active) {
        autoAnswerRingtone.play();
      } else {
        defaultRingtone.play();
      }
      console.log(
        "SoftPhone",
        "palyRingtone",
        "Ringtone",
        "palyRingtone success"
      );
    } catch (error) {
      console.error("SoftPhone", "palyRingtone", "Ringtone", error);
      logger.error("SoftPhone", "palyRingtone", error.message);
    }
  }

  function stopRingtone() {
    try {
      defaultRingtone.pause();
      autoAnswerRingtone.pause();
      console.log(
        "SoftPhone",
        "stopRingtone",
        "Ringtone",
        "stopRingtone success"
      );
    } catch (error) {
      console.error("SoftPhone", "stopRingtone", "Ringtone", error);
      logger.error("SoftPhone", "stopRingtone", error.message);
    }
  }

  function palyRingbackTone() {
    try {
      ringBack.play();
      console.log(
        "SoftPhone",
        "palyRingbackTone",
        "Ringtone",
        "palyRingbackTone success"
      );
    } catch (error) {
      console.error("SoftPhone", "palyRingbackTone", "Ringtone", error);
      logger.error("SoftPhone", "palyRingbackTone", error.message);
    }
  }

  function stopRingbackTone() {
    try {
      ringBack.pause();
      console.log(
        "SoftPhone",
        "stopRingbackTone",
        "Ringtone",
        "stopRingbackTone success"
      );
    } catch (error) {
      console.error("SoftPhone", "stopRingbackTone", "Ringtone", error);
      logger.error("SoftPhone", "stopRingbackTone", error.message);
    }
  }

  const appendMiscallToTimeline = () => {
    try {
      if (
        miscallStatus !== "answered" &&
        miscallStatus !== "" &&
        callDirection === "Incoming" &&
        callType !== ""
      ) {
        let uniqueId = uuid();

        const userId = callee.replace("user:", "");
        let msg = {
          v: 1,
          mid: uniqueId,
          sid: uniqueId,
          message_type: callType === 'AUDIO_CALL' ? 'CHAT_MISSED_AUDIO_CALL' : (callType === 'VIDEO_CALL' ? 'CHAT_MISSED_VIDEO_CALL' : (callType === 'SCREEN_SHARING' ? 'CHAT_MISSED_SCREEN_SHARING' : callType)),
          from: {
            id: caller,
            name: caller,
          },
          to: {
            id: userId,
            name: userId,
          },
          created_at: Date.now(),
          //message_content: `${setCallerName()} Try To Contact ${calleeName} via ${callType === "AUDIO_CALL"?'Audio':'Video' } Call`,
          message_content: "",
          other: { callDirection, callType, caller, callee },
          conversation_type: 0, //user to user 0 user to gr 3 other 4
          message_action: miscallStatus === "rejected" ? -9 : 1,
        };

        ChatService.sendChatMessage(0, msg.message_type, userId, msg);
        props.chat_actions.sendNewMessageSuccess(msg);
        eventEmitter.fireEvent("scrollbar", { scrollbar_status: "down" });
      }
    } catch (error) {
      console.error("SoftPhone", "appendMiscallToTimeline", error);
      logger.error("SoftPhone", "appendMiscallToTimeline", error.message);
    }
  };

  //==== Participation action ==============
  const onClickOpenParticipation = () => {
    console.log("show participation ");
    setOpenParticipation({
      isOpenParticipation: true,
      prtPanelAnimation: "show-me",
    });
  };
  const onClickHideParticipation = () => {
    console.log("hide participation ");
    setOpenParticipation({
      isOpenParticipation: false,
      prtPanelAnimation: "hide-me",
    });
  };

  //==== phone minimize action ==============
  // on click minimize
  const onClickUnlockVideo = (state, e) => {
    console.log("unlock video ");
    setControlledPosition(
      state
        ? null
        : {
          x: 0,
          y: 0,
        }
    );
    setVideoMinimizeScreen(state);
    if (state) {
      setOpenParticipation({
        isOpenParticipation: false,
        prtPanelAnimation: "hide-me",
      });
    }
  };

  const setAvailability = (status) => {
    try {
      let presenceObj = { userid: caller, status };
      props.presence_actions.sendPresenceSuccess(presenceObj);
      liveChatClient.sendPresenceNotification(JSON.stringify(presenceObj));
    } catch (error) {
      console.error(
        "SoftPhone",
        "setAvailability",
        `Fail to Set Status Message`,
        error
      );
      logger.error("SoftPhone", "setAvailability", error.message);
    }
  };

  const showNotification = (
    type,
    message,
    description,
    key,
    placement,
    duration = 5
  ) => {
    try {
      notification[type]({
        key,
        message,
        description,
        placement,
        duration,
        style: {
          width: 300,
        },
      });
    } catch (error) {
      console.error("SoftPhone", "showNotification", error);
      logger.error("SoftPhone", "showNotification", error.message);
    }
  };

  const closeNotification = (key) => {
    try {
      notification.close(key);
    } catch (error) {
      console.error("SoftPhone", "closeNotification", error);
      logger.error("SoftPhone", "closeNotification", error.message);
    }
  };

  const OnAutoLogout = () => {
    try {
      if (
        auth.getMediaDeviceErrorCount().count >
        media_deceive_accepted_error_limit
      ) {
        liveChatClient
          .logoutFromSystem("", "MEDIA_DEVICE_ISSUE", "")
          .then((response) => {
            __APP_IDENTITY_PROVIDER__ === "GUARDIAN" &&
              authService.logout(auth.getRefreshToken(), "NORMAL");
            liveChatClient.endSession();
            phoneEngine.phoneFunctions.hangup("caller", "callee");
            auth.userLogOut();
            setTimeout(() => {
              if (__APP_IDENTITY_PROVIDER__ === "IAM") {
                props.history.push("/logout");
              } else {
                props.history.push("/");
                window.location.reload();
                console.log(
                  "LeftSideNavigationComponent",
                  "uireload",
                  "window.location.reload"
                );
              }
            }, 2000);
          })
          .catch((error) => {
            console.error(
              "SoftPhone",
              "OnAutoLogout",
              "logout-logoutFromSystem",
              error
            );
            logger.error("SoftPhone", "logout-logoutFromSystem", error.message);
            message.error("Error in logout");
          });
      } else {
        console.log(
          "SoftPhone",
          "OnAutoLogout",
          "OnAutoLogout event fired but count reset"
        );
      }
    } catch (error) {
      console.error("SoftPhone", "OnAutoLogout", error);
      logger.error("SoftPhone", "OnAutoLogout", error.message);
    }
  };
  return (
    <div>
        
      <ParticipatorStatus />
      {/* notification Area */}
      {showCallAlert && (
        <section>
          <div className={`outgoing-main-wrapper  show-this show-this`}>
            <CallAlert
              callType={callType}
              fullName={calleeName}
              src={null}
              status={"caller"}
              onClickEndCall={rejectCall}
              OnClickAnswerCall={OnAnswerCall}
              callDirection={callDirection}
              autoAnswer={autoAnswerFeature}
              hideRejectButton={hideRejectButton}
              OnAutoAnswerCall={OnAutoAnswerCall}
              autoAnswerDelayed={
                phoneConfig.basic_config.calls.auto_answer.auto_answer_delayed
              }
              callRelatedRequestAccepted={callRelatedRequestAccepted}
              loadLocalView_p={showCallAlert}
            />
          </div>
        </section>
      )}

      {/* <section>
        <div
          className={`outgoing-main-wrapper  show-this ${
            showCallAlert ? "show-this" : "hide-this"
          }`}
        >
          <CallAlert
            callType={callType}
            fullName={calleeName}
            src={null}            
            status={"caller"}
            onClickEndCall={rejectCall}
            OnClickAnswerCall={OnAnswerCall}
            callDirection={callDirection}
            autoAnswer={phoneConfig.basic_config.calls.auto_answer.active}
            OnAutoAnswerCall={OnAutoAnswerCall}
            autoAnswerDelayed={phoneConfig.basic_config.calls.auto_answer.auto_answer_delayed}
          />
        </div>
      </section> */}

      {/*======= video/audio ======== */}
      <section>
        {/*======= video hide-this ======== */}

        {
          <Draggable
            cancel="strong"
            disabled={!isVideoMinimizeScreen}
            position={controlledPosition}
            // positionOffset={controlledPosition}
            bounds="body"
          >
            <div
              className={`outgoing-main-wrapper ${(callType === "VIDEO_CALL" ||
                callType === "SELF_VIEW" ||
                callType === "MEETING_VIDEO_CALL") &&
                isVideoMinimizeScreen
                ? "minimize-video draggable "
                : "enable-fullscreen"
                }`}
            >
              <VideoCallPlayer
                callDurationComponent={callDurationComponent}
                key={"951357894456321"}
                onClickEndVideoCall={callHangUp}
                onClickOpenInviteModal={openInviteModal}
                sendInvitationsToConference={sendInvitationsToConference}
                onClickOpenParticipation={onClickOpenParticipation}
                onClickHideParticipation={onClickHideParticipation}
                prtPanelAnimation={openParticipation.prtPanelAnimation}
                isOpenParticipationPanel={openParticipation.isOpenParticipation}
                isVideoMinimizeScreen={isVideoMinimizeScreen}
                onClickUnlockVideo={onClickUnlockVideo}
                onClickLockVideo={props.onClickLockVideo}
                onClickFullScreenVideo={props.onClickFullScreenVideo}
                isVideoPinStatus={props.isVideoPinStatus}
                isVideoFullScreen={props.isVideoFullScreen}
                viewPointHeight={props.viewPointHeight}
                viewPointWidth={props.viewPointWidth}
                muteUnmuteAudioVideo={muteUnmuteAudioVideo}
                holdUnhold={holdUnhold}
                calleeName={calleeName}
                callee={callee ? callee.replace("user:", "") : ""}
                callDirection={callDirection}
                callStatus={callStatus}
                callDurations={""}
                startScreenSharing={startScreenSharing}
                stopScreenSharing={stopScreenSharing}
                colleagues={props.colleagues}
                callType={callType}
                isHideVideoPenal={isHideVideoPenal}
                ref={childRefVideoCall}
                remoteStreams={remoteStreams}
                isAllowedScreenShare={isAllowedScreenShare}
                isScreenShare={isScreenShare}
                userDetails={userDetails}
                onSwapStream={onSwapStream}
                blockScreenShare={blockScreenShare}
                setVideoMinimizeScreen={setVideoMinimizeScreen}
                caller={caller}
                audioDeviceId={audioDeviceId}
                voiceActivity={
                  phoneConfig && phoneConfig.VoiceActivity
                    ? phoneConfig.VoiceActivity.AudioLevelCheckEnabled
                    : true
                }
                bg_images = {bg_images}
                interactionId = {interactionId}
              />
            </div>
          </Draggable>
        }
        {/*======= audio ======== */}
        {
          <AudioCallPlayer
            callDurationComponent={callDurationComponent}
            key={"121325875648521369"}
            OnClickAudioEndCall={callHangUp}
            onClickOpenInviteModal={openInviteModal}
            viewPointHeight={props.viewPointHeight}
            viewPointWidth={props.viewPointWidth}
            muteUnmuteAudioVideo={muteUnmuteAudioVideo}
            holdUnhold={holdUnhold}
            calleeName={calleeName}
            callee={callee ? callee.replace("user:", "") : ""}
            callDurations={""}
            callDirection={callDirection}
            startScreenSharing={startScreenSharing}
            stopScreenSharing={stopScreenSharing}
            colleagues={props.colleagues}
            upgradeToVideo={upgradeToVideo}
            callType={callType}
            callStatus={callStatus}
            isHideAudioPenal={isHideAudioPenal}
            ref={childRefAudioCall}
            remoteStreams={remoteStreams}
            isAllowedScreenShare={isAllowedScreenShare}
            isScreenShare={isScreenShare}
            userDetails={userDetails}
            screenShareStreams={screenShareStreams}
            src={null}
            blockScreenShare={blockScreenShare}
            isOpenInfoPanel={props.isOpenInfoPanel}
            caller={caller}
            phoneConfig={phoneConfig}
          />
        }

          {(callType === "SCREENSHARE" || callType === "SCREEN_SHARING" || isAudioWithScreenshare ) && (callStatus === "ScreenShared" || callStatus === "Connecting_screenshare") && (screenShareSelf === false) && (
            <Draggable
              disabled={!isVideoMinimizeScreen}
              position={controlledPosition}
              bounds="body"
            >
              <div
                className={`outgoing-main-wrapper ${isVideoMinimizeScreen
                  ? screenShareSelf
                    ? "minimize-screenshare draggable"
                    : "minimize-video draggable "
                  : "enable-fullscreen"
                  }`}
              >
                <ScreenShareModal
                  isViewScreen={!isScreenShare}
                  stopScreenSharing={stopDirectScreenSharing}
                  callType={callType}
                  mediaStream={screenShareStreams[0]}
                  isVideoMinimizeScreen={isVideoMinimizeScreen}
                  viewPointHeight={props.viewPointHeight}
                  viewPointWidth={props.viewPointWidth}
                  onClickUnlockVideo={onClickUnlockVideo}
                  muteUnmuteAudioVideo={muteUnmuteAudioVideo}
                  OnClickAudioEndCall={callHangUp}
                  caller={caller}
                  screenShareSelf={screenShareSelf}
                />
              </div>
            </Draggable>
          )}

          {(callType === "SCREENSHARE" || callType === "SCREEN_SHARING" ) && (callStatus === "ScreenShared" || callStatus === "ScreenSharing") && (screenShareSelf === true) && (
            <Draggable  >
              <div className="screenshare-self-wrapper">
                <ScreenShareMyViewModal 
                isViewScreen={!isScreenShare}
                stopScreenSharing={stopDirectScreenSharing}
                callType={callType}
                mediaStream={screenShareStreams[0]}
                isVideoMinimizeScreen={isVideoMinimizeScreen}
                viewPointHeight={props.viewPointHeight}
                viewPointWidth={props.viewPointWidth}
                onClickUnlockVideo={onClickUnlockVideo}
                muteUnmuteAudioVideo={muteUnmuteAudioVideo}
                OnClickAudioEndCall={callHangUp}
                caller={caller}
                callStatus={callStatus}
                screenShareSelf={screenShareSelf}/>
              </div>          
            </Draggable>              
          )}
        
        
        {/* show alert only for video call users */}
        {phoneConfig &&
          phoneConfig.isAllowedVideoCall &&
          phoneConfig.basic_config.console.mediaDevice.active === true &&
          hasMediaDeviceError && (
            <Draggable bounds="body">
              <div
                className={`alert-modal-main-wrapper minimize-alert_modal draggable`}
              >
                <AlertModal
                  phoneConfig={phoneConfig}
                  onFinish={OnAutoLogout}
                  media_deceive_accepted_error_limit={
                    media_deceive_accepted_error_limit
                  }
                />
              </div>
            </Draggable>
          )}
      </section>
    </div>
  );
});

SoftPhone.propTypes = {
  OnClickOutGoingCall: PropTypes.func.isRequired,
  onClickOpenInviteModal: PropTypes.func.isRequired,
  //onClickOpenParticipation: PropTypes.func.isRequired,
  //onClickHideParticipation: PropTypes.func.isRequired,
  onClickUnlockVideo: PropTypes.func.isRequired,
  onClickLockVideo: PropTypes.func.isRequired,
  onClickFullScreenVideo: PropTypes.func.isRequired,
  isVideoPinStatus: PropTypes.bool.isRequired,
  prtPanelAnimation: PropTypes.string.isRequired,
  isOpenParticipation: PropTypes.bool.isRequired,
  isVideoFullScreen: PropTypes.bool.isRequired,
  viewPointHeight: PropTypes.number.isRequired,
  viewPointWidth: PropTypes.number.isRequired,
  OnClickAudioEndCall: PropTypes.func.isRequired,
  OnClickAnzAudioCall: PropTypes.func.isRequired,
  onClickVideoCallAnz: PropTypes.func.isRequired,
  onClickEndVideoCall: PropTypes.func.isRequired,
  //selectedProfile: PropTypes.object.isRequired,
  //colleagues: PropTypes.object.isRequired
};

const mapStateToProps = (state) => {
  return {
    selectedProfile: state.selected_profile,
    colleagues: state.team,
    presence_actions: PropTypes.object.isRequired,
    interactions: state.interactions,
    callControls: state.callControls,
    mediaDevices: state.callControls,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    chat_actions: bindActionCreators(chatActions, dispatch),
    team_actions: bindActionCreators(teamActions, dispatch),
    callActions: bindActionCreators(callActions, dispatch),
    presence_actions: bindActionCreators(presenceActions, dispatch),
    interaction_actions: bindActionCreators(interactionActions, dispatch),
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  return ownProps;
};

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(SoftPhone);
