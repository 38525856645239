import React from 'react';
import PropTypes from 'prop-types';
import { MenuIcon } from "../index";
import { Plus } from "react-feather";


const InviteWithText = ({ onClick, title, size, icon }) => (
    <div className={'invite-with-text d-flex'} onClick={onClick}>
        <div className={`invite-icon d-flex align-items-center justify-content-center ${size}`}>
            <MenuIcon icon={icon} />
        </div>
        <div className={'text d-flex align-items-center justify-content-center'}>
            {title}
        </div>
    </div>
);

InviteWithText.propTypes = {
    onClick: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    size: PropTypes.string.isRequired,
};

InviteWithText.defaultProps = {
    size: 'md',
    title: 'null'
};

export default InviteWithText;
