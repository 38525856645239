import * as types from "../actions/actionTypes";
import produce from "immer";
import renderState from "../../service/renderStateHandler";

export const selectedProfileReducer = produce((draft = {}, action) => {
    switch (action.type) {
        case types.PROFILE_SELECTED:
            {
                draft = action.profile;
                renderState.focusToLastRead = true;
                return draft;
            }
        case types.SET_SELECTED_PROFILE_ACW:
            {
                if (draft.InteractionId === action.interactionId) {
                    draft.isACW = true;
                }
                return draft;
            }
        default:
            return draft;
    }
});