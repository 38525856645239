import React from 'react';
import PropTypes from 'prop-types';
import {Video} from 'react-feather';
import ReactPlayer from 'react-player'

const VideoThumbnail = ({duration, onClickChangePreview, src, playing, active}) => (
    <div
        className={`gallery-thumbnail-wrp video  d-flex flex-column justify-content-center align-items-center ${active ? 'active' : ''}`}
        onClick={onClickChangePreview}>
        <div className={'icon-duration d-flex justify-content-center align-items-center'}>
            <Video/>
        </div>

        <div className={'gallery-thumbnail-video-player'}>
            <ReactPlayer
                width={80}
                playing={playing}
                height={80}
                url={src}/>
        </div>
    </div>
);


VideoThumbnail.defaultProps = {
    playing: false
};

export default VideoThumbnail;
