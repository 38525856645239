import React from 'react';
import PropTypes from 'prop-types';
import {MenuIcon} from "../index";
import {Mic, MicOff, Video, VideoOff} from "react-feather";


const LocalVideoUserInfo = ({userType, src, fullName, mic, video}) => (
    <div className={'window-caller-info-wrapper d-flex justify-content-between'}>
        <div className={'caller-user-details d-flex'}>
            {/*<div className={'ca-v-profile-img'}>*/}
            {/*    {src === null &&*/}
            {/*    <img src={require("../../../assets/img/defaultUser.svg")}*/}
            {/*         alt="User Avatar"/>*/}
            {/*    }*/}
            {/*    {src !== null &&*/}
            {/*    <img src={src}*/}
            {/*         className={'with-url'}*/}
            {/*         alt="User Avatar"/>*/}
            {/*    }*/}
            {/*</div>*/}
            <div className={'window-user-details d-flex flex-column'}>
                <div className={'window-caller-name'}>
                    {fullName}
                </div>
                <div className={'window-user-type'}>
                    {userType}
                </div>

            </div>
        </div>
        {/*active-user*/}
        <div className={`right-wrp icon-wrapper d-flex  `}>
            <div className={'icon-wrp mic-wrp'}>
                {mic &&
                <MenuIcon icon={<Mic/>}/>
                }
                {!mic &&
                <MenuIcon icon={<MicOff/>}/>
                }
            </div>
            <div className={'icon-wrp video-wrp'}>
                {video &&
                <MenuIcon icon={<Video/>}/>
                }
                {!video &&
                <MenuIcon icon={<VideoOff/>}/>
                }
            </div>
        </div>
    </div>
);

// LocalVideoUserInfo.propTypes = {
//     onClick: PropTypes.func.isRequired,
//     title: PropTypes.string.isRequired,
//     size: PropTypes.string.isRequired,
//     type: PropTypes.string.isRequired,
//     disabled: PropTypes.bool,
// };

LocalVideoUserInfo.defaultProps = {
    type: 'btn-primary',
    size: 'btn-sm',
    disabled: false,
};

export default LocalVideoUserInfo;
