import * as types from "./actionTypes";

export function openMediaUpload(mediaFiles, mediaType) {
    return dispatch => {
        dispatch({ type: types.UI_OPEN_MEDIA_UPLOAD, mediaFiles, mediaType });
        dispatch(enableDisableHeaderPanelButtons("FILE", false));
    }
}

export function closeMediaUpload() {
    return dispatch => {
        dispatch({ type: types.UI_CLOSE_MEDIA_UPLOAD });
        dispatch(enableDisableHeaderPanelButtons("FILE", true));
    }
}

export function enableDisableHeaderPanelButtons(panelButton, enable) {
    let data = { panelButton, enable };
    return { type: types.UI_HEADER_PANEL_BUTTON_VISIBILITY_CHANGE, data };
}

export function userListReadyState(state) {
    return { type: types.UI_ACTION_PANEL_READY_STATE, state };
}

export function changeProfileTab(tabValue) {
    return { type: types.UI_USER_PROFILE_PANEL_TAB_CHANGE, tabValue };
}