import React, { useEffect } from "react";
import { notification } from 'antd';
import { useDispatch } from "react-redux";
import { subscribeToEvents } from "../../../utils/eventEmitter";
import uuid from "uuid/v1";
import * as whiteboardActions from '../../../redux/actions/whiteboardActions';

const WhiteboardEvents = () => {

    const dispatch = useDispatch();


    useEffect(() => {

        subscribeToEvents("whiteboard_events", ({ fullName }) => {

            let uniqueId = uuid();

            notification['info']({
                key: uniqueId,
                message: 'Whiteboard Notification',
                placement: 'bottomRight',
                duration: 0,
                description: (<div className="row">
                    <div className="row">
                        <div className="col-md-12" style={{ marginTop: "6px" }}>{`${fullName} joined whiteboard session`}</div>
                    </div>
                </div>)
            });



        });

        subscribeToEvents("cobrowse_events", ({ fullName, from }) => {

            let uniqueId = uuid();

            notification['info']({
                key: uniqueId,
                message: 'Co-Browse Notification',
                placement: 'bottomRight',
                duration: 0,
                description: (<div className="row">
                    <div className="row">
                        <div className="col-md-12" style={{ marginTop: "6px" }}>{`Co-Browse initiated by ${fullName}`}</div>
                        <div className="col-md-12 mt-10">
                            <div className="btn btn-primary col-md-4 mr-5" onClick={() => {
                                let elem = document.getElementById(`chat_user_wrapper_${from}`);
                                if (elem) {
                                    elem.click();
                                    dispatch(whiteboardActions.disposeWhiteboard());
                                    setTimeout(() => {
                                        dispatch(whiteboardActions.openWhiteboard(from, 'WHITEBOARD'));
                                    }, 1000);

                                    notification.close(uniqueId);
                                } else {
                                    notification.close(uniqueId);
                                }

                            }}>Join</div>
                            <div className="btn btn-primary col-md-4" onClick={() => {
                                notification.close(uniqueId);
                            }}>Cancel</div>
                        </div>
                    </div>
                </div>)
            });



        });



    }, []);
    return (<></>);
}

export default React.memo(WhiteboardEvents);

