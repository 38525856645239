import React from 'react';


//online
//offline
//busy
//other
const CheckBox = ({ icon, selected }) => (
    <div className={`selected-wrapper ${selected ? 'selected' : ''}`}>
        <div className={`select d-flex align-items-center justify-content-center `}>
            {icon}
        </div>
    </div>
);

// OutGoingCall.propTypes = {
//     status: PropTypes.string.isRequired,
// };

CheckBox.defaultProps = {};

export default CheckBox;
