import * as types from "./actionTypes";

export function receiveCall(number) {
  return { type: types.INCOMING_CALL, number };
}

export function callProgress(callStatus, callType) {
  const data = { callStatus, callType };
  return { type: types.CALL_PROGRESS, data };
}

export function callEscalated(duration) {  
  return { type: types.CALL_ESCALATED, duration };
}


