import React from 'react';
import PropTypes from 'prop-types';
import { UserPlus, Pause, Mic, MicOff, PhoneOutgoing, Airplay } from "react-feather";
import { ChatUser, MenuIcon, TitleWithSmallText, UserImage, ParticipationImage } from "../index";
import { Scrollbars } from "react-custom-scrollbars";
import ReactPlayer from "react-player";


//online
//offline
//busy.
//other
const VideoPreview = ({ src, playing, caption, isPreviewSupported }) => (
    <div className={`media-g-preview-section image ${src.startsWith('blob') || src.endsWith('_recorded') ? 'mirror-media' : ''}`}>

        {isPreviewSupported ? <ReactPlayer
            width='500px'
            controls={true}
            playing={playing}
            height='400px'
            url={src} /> : <div style={{ fontSize: "20px", fontWeight: "bold" }}>No Preview Available</div>}

        <div className={'caption-wrp'}>
            {caption}
        </div>
    </div>
);

// OutGoingCall.propTypes = {
//     status: PropTypes.string.isRequired,
// };

VideoPreview.defaultProps = {};

export default VideoPreview;
