import * as types from "./actionTypes";
import { TeamService } from "../../service/teamService";
import * as chatActions from "./chatActions";
import * as selectedProfileActions from "./selectedProfileActions";
import * as interactionActions from "./interactionActions";
import {
  __APP_USER_LOADING_METHOD__,
  __APP_DEFAULT_WORKSPACE__,
  __APP_INTERACTION_MODE__,
  externalCrmUrl,
} from "../../config/baseUrls";
import { getUserId } from "../../utils";
import { OpenCustomer360 } from "./externalApiActions";
import navigationRestrictions from "../../config/navigationRestrictions";
import * as auth from "../../utils";
import * as phoneConfig from "../../config/phoneConfig";
import * as logger from '../../lib/logSdk/logger';

export function getTeamMembers(userId) {
  return (dispatch) => {
    let ws = auth.getWorkspace();
    let mode = auth.getMode();
    let promise =
      (__APP_USER_LOADING_METHOD__ === "UAS" || (mode && mode.type === "OCM"))
        ? TeamService.getAllTeamMembers()
        : (__APP_USER_LOADING_METHOD__ === "OCM" ? TeamService.getOCMTeamMembers(ws) : TeamService.getMyContacts(userId));
    //TeamService.getAllTeamMembers()
    //TeamService.getMyContacts(userId)
    promise
      .then((teamMembers) => {
        if(navigationRestrictions.meeting.meeting_only === true && Object.keys(teamMembers).length === 0){
          const userId = auth.getUserId();
          const userName = auth.getUser();
          const userType = auth.getUserType();
          teamMembers[userId] = {userId,userName,userType};
        }
        __APP_INTERACTION_MODE__ !== "INTERACTION" &&
          dispatch({ type: types.RELOAD_RM_USERS, rmUsers: teamMembers });
        __APP_INTERACTION_MODE__ !== "INTERACTION" &&
          Object.keys(teamMembers).length > 0 &&
          dispatch(
            chatActions.getAllUnreadCountsForUserIds(
              userId,
              Object.keys(teamMembers)
            )
          );

        __APP_INTERACTION_MODE__ === "INTERACTION" &&
          dispatch(getTemporaryAgents());
      })
      .catch((error) => {
        console.error("teamActions", "getTeamMembers", error);
        logger.error("teamActions", "getTeamMembers", error.message);
      });
  };
}

export function addCustomersForInteractions(customers) {
  return (dispatch) => {
    dispatch(rmUsersLoaded(customers));
  };
}

export function validateAndAddContact(userId) {
  return (dispatch) => {
    TeamService.validateAndGetProfile(userId)
      .then((teamMember) => {
        if (teamMember) {
          dispatch(newContactAdded(teamMember));
        }
      })
      .catch((error) => {
        console.error("teamActions", "validateAndAddContact", error);
        logger.error("teamActions", "validateAndAddContact", error.message);
      });
  };
}

export function getTemporaryAgents(callback) {
  return (dispatch) => {
    let promise = TeamService.getTemporaryAgents();
    promise
      .then((tempAgents) => {
        dispatch({ type: types.TEMP_AGENTS_LOADED, tempAgents });
        callback && callback();
      })
      .catch((error) => {
        console.error("teamActions", "getTemporaryAgents", error);
        logger.error("teamActions", "getTemporaryAgents", error.message);
        callback && callback();
      });
  };
}

export function addCustomerInfo(contactInfo) {
  try {
    let custInfoObj = contactInfo && contactInfo.userDetails
      ? JSON.parse(contactInfo.userDetails)
      : null;

    if (custInfoObj) {
      return (dispatch) => {
        let tempUser = {
          userId: contactInfo.userId.replace("user:", ""),
          customerId: custInfoObj.customerId,
          userType: contactInfo.type,
          conversation_type: 0,
          isTemp: true,
          InteractionId: contactInfo.interactionId,
          createdAt: Date.now(),
          firstName: custInfoObj.customerName,
          lastName: " ",
          userData: custInfoObj,
          InteractionType: contactInfo.channel,
        };

        dispatch(newContactAdded(tempUser));
      };
    } else {
      console.error("teamActions", "Customer info not found - [addCustomerInfo]");
      logger.error("teamActions", "addCustomerInfo", "Customer info not found - [addCustomerInfo]");
    }
  } catch (error) {
    console.error("teamActions", "addCustomerInfo", error);
    logger.error("teamActions", "addCustomerInfo", error.message);
  }
}

export function addContactOnUserFoundEvent(contactInfo) {
  try {
    let custInfoObj = contactInfo && contactInfo.userDetails
      ? JSON.parse(contactInfo.userDetails)
      : null;

    let userId = "";
    let firstName = "";
    let customerId = "";

    userId = contactInfo.userId.replace("user:", "");
    firstName = custInfoObj ? custInfoObj.customerName : "";
    customerId = custInfoObj ? custInfoObj.customerId : "";
    return (dispatch) => {

      let tempUser = {
        userId: userId,
        customerId: customerId,
        userType: contactInfo.type,
        conversation_type: 0,
        isTemp: true,
        InteractionId: contactInfo.interactionId,
        createdAt: Date.now(),
        firstName: firstName,
        lastName: " ",
        userData: custInfoObj,
        InteractionType: contactInfo.channel,
      };

      //"reqSkill": "139151", "reqId": "391303", "type": "Customer", "interactionId": "eb688187-4532-4489-81ad-d5d3b80c849a", "intent": "account", "channel": "chat", "userDetails": "{\"mobileNo\":null,\"pageDesc\":null,\"authentication\":null,\"id\":\"78964\",\"name\":\"ttttrw\"

      //"InteractionId": "98773b00-3b34-4628-895f-bd36d1656bff", "IsCallEscalated": false, "StartDateTime": "2020-06-30T07:38:11+00:00", "Channel": "chat", "SubChannel": "", "Intent": "account", "Skill": "139151", "UserIds": ["966437", "84747"]
      dispatch(newContactAdded(tempUser));

      if (custInfoObj) {

        let open_external_app = phoneConfig.basic_config.console.open_external_app;
        const open_external_app_db = auth.getPhoneConfig();
        if (open_external_app_db && open_external_app_db.basic_config && open_external_app_db.basic_config.console) {
          open_external_app = open_external_app_db.basic_config.console.open_external_app;
        }
        if (open_external_app) {
          let url = `${externalCrmUrl}?`;
          const url_db = auth.getBaseUrlName("externalCrmUrl");
          if (url_db) {
            url = `${url_db}?`;
          }
          if (custInfoObj.mobileNumber) {
            url = `${url}c__primaryNumber=${custInfoObj.mobileNumber}&`;
          }
          if (custInfoObj.customerId) {
            url = `${url}c__ucicId=${custInfoObj.customerId}`;
          }

          dispatch(OpenCustomer360(url, { InteractionId: contactInfo.interactionId, Channel: contactInfo.channel, Intent: contactInfo.intent, Skill: contactInfo.reqSkill }));
        }

      }


      let interactionObj = {
        InteractionId: contactInfo.interactionId,
        IsCallEscalated: false,
        createdAt: Date.now(),
        Channel: contactInfo.channel,
        SubChannel: "",
        Intent: contactInfo.intent,
        Skill: contactInfo.reqSkill,
        UserIds: [getUserId(), userId],
        conversation_type: 4,
        isTemp: true,
        CustomerInfo: custInfoObj,
        CustomData: contactInfo.customData,
        CustomerUserId: userId,
      };

      dispatch(interactionActions.interactionAdded(interactionObj));

      dispatch(
        selectedProfileActions.profileSelected({
          username: contactInfo.interactionId,
          conversation_type: 4,
          profileId: "",
          displayName: firstName
            ? firstName
            : contactInfo.interactionId,
          conversation_id: null,
          avatar: null,
          createdAt: interactionObj.createdAt,
          isTemp: true,
          userData: custInfoObj,
          InteractionId: interactionObj.InteractionId,
          InteractionInfo: interactionObj,
        })
      );


    };
  } catch (error) {
    console.error("teamActions", "addContactOnUserFoundEvent", error);
    logger.error("teamActions", "addContactOnUserFoundEvent", error.message);
  }
}

export function setAvatar(userId, avatarURL) {
  let data = { userId, avatarURL };
  return { type: types.SET_AVATAR_URL, data };
}

export function setACWStatus(userId) {
  return (dispatch) => {
    dispatch({ type: types.SET_CONTACT_ACW_STATUS, userId });
    dispatch(selectedProfileActions.setAcwState());
  };
}

export function newContactAdded(user) {
  return { type: types.NEW_CONTACT_ADDED, user };
}

export function rmUsersLoaded(rmUsers) {
  return { type: types.RM_GROUP_FOUND, rmUsers };
}

export function getAllTeamMembersSuccess(team) {
  return { type: types.TEAM_MEMBERS_LOAD_SUCCESS, team };
}

export function removeContact(userId) {
  return { type: types.REMOVE_CONTACT, userId };
}
