import React, { useState } from 'react';
import PropTypes from 'prop-types';
import PanelTitle from "../PanelTitle/PanelTitle";

import './styles/search-text.scss'
import './styles/search-text-resposive.scss'

//online
//offline
//busy
//other
const SearchTextBox = ({ id, textValue, placeholder, onChangeHandler, onKeyPressHandler, onFocusOutHandler }) => {

    const onTextChangeHandler = event => {
        if (onChangeHandler) {
            onChangeHandler(event.target.value);
        }
    };

    const onTextFocusOutHandler = event => {
        if (onFocusOutHandler) {
            onFocusOutHandler(event.target.value);
        }
    };
    return (<>
        <input id={id} type="text"
            value={textValue}
            className="form-control txt-search"
            onChange={onTextChangeHandler}
            onBlur={onTextFocusOutHandler}
            onKeyPress={onKeyPressHandler}
            placeholder={placeholder}
        />
    </>)

};

SearchTextBox.propTypes = {
    placeholder: PropTypes.string.isRequired,
};

PanelTitle.defaultProps = {
    placeholder: 'Type here...',
};


export default SearchTextBox;
