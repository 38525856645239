import React from 'react';
import PropTypes from 'prop-types';
import {LocalVideoUserInfo, VideoElement} from "../index";
import {Scrollbars} from "react-custom-scrollbars";


const LocalVideoIncome = ({localVideoRef, userType, fullName, isMic, isVideo, status, active, onClick, isVideoMinimizeScreen,audioDeviceId}) => (
    <div className={`local-video-wrapper active ${isVideoMinimizeScreen ? 'minimizer-screen' : 'full-screen'} ` }  style={{zIndex:10}}
         onClick={onClick}>
        <VideoElement
            videoRef={localVideoRef}
            userState={status}
            size={"video"}
            active={active}
            isVideoMinimizeScreen={isVideoMinimizeScreen}
            title={fullName}
            audioDeviceId={audioDeviceId}/>
        {/*{!isVideoMinimizeScreen &&*/}
        {/*<LocalVideoUserInfo*/}
        {/*    src={null}*/}
        {/*    userType={userType}*/}
        {/*    fullName={fullName}*/}
        {/*    mic={isMic}*/}
        {/*    video={isVideo}/>*/}
        {/*}*/}
    </div>
);


LocalVideoIncome.defaultProps = {};

export default LocalVideoIncome;
