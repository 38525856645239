import React, {useState, useEffect} from "react";
import {Timeline, Button, Tag} from "antd";
import {
    CheckCircleOutlined,
    CloseCircleOutlined,
    SyncOutlined,
    QuestionCircleOutlined,
} from "@ant-design/icons";
import {useLocation} from "react-router-dom";
import {useDispatch} from "react-redux";
import {OnMediaDeviceError} from "../../../redux/actions/callControlsActions";

const DeviceValidating = ({phoneEngine, deviceStatus, reload, cancel, visible}) => {
    const dispatch = useDispatch();
    let location = useLocation();
    const list = {
        hasMicrophone: {
            name: "Microphone Available",
            availability: true,
            availabilityMsg: "Microphone Not Available",
            color: "#131f7d",
            dot: null,
            deviceNames: [],
        },
        hasWebcam: {
            name: "Webcam Available",
            availability: true,
            availabilityMsg: "Webcam Not Available",
            color: "#131f7d",
            dot: null,
            deviceNames: [],
        },
        isMicrophoneAlreadyCaptured: {
            name: "Microphone Allowed",
            availability: true,
            availabilityMsg: "Microphone Not Allowed",
            color: "#131f7d",
            dot: null,
            deviceNames: [],
        },
        isWebcamAlreadyCaptured: {
            name: "Webcam Allowed",
            availability: true,
            availabilityMsg: "Webcam Not Allowed",
            color: "#131f7d",
            dot: null,
            deviceNames: [],
        },
        isScreenshareSupported: {
            name: "Screenshare Supported",
            availability: true,
            availabilityMsg: "Screenshare Not Supported",
            color: "#131f7d",
            dot: null,
            deviceNames: [],
        },
        isWebRTCSupported: {
            name: "WebRTC Supported",
            availability: true,
            availabilityMsg: "WebRTC Not Supported",
            color: "#131f7d",
            dot: null,
            deviceNames: [],
        },
    };
    const [showAccessButton, setShowAccessButton] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [conditionList, setConditionList] = useState(list);
    let isValidationPass = true;

    useEffect(() => {
        console.log("DeviceValidating", "useEffect", "device validation run");
        setTimeout(() => {
            console.log("DeviceValidating", "useEffect", "device validation start");
            loadDeviceList();
        }, 1000);
    }, []);

    const appendComponentWithEffect = async (deviceList) => {
        const getDeviceName = (key) => {
            const list = [];
            try {
                deviceList.MediaDevices.map((item) => {
                    if (item.kind === "audioinput" && key === "hasMicrophone") {
                        list.push(item.label);
                    }
                    if (item.kind === "audiooutput") {
                    }
                    if (item.kind === "videoinput" && key === "hasWebcam") {
                        list.push(item.label);
                    }
                });
            } catch (error) {
                console.error("DeviceValidating", "getDeviceName", error);
            }

            return list;
        };
        const appendItem = (key) => {
            return new Promise((resolve, reject) => {
                try {
                    setTimeout(() => {
                        const temp = {...conditionList};
                        if (!temp[key]) {
                            resolve(null);
                            return;
                        }
                        temp[key].color = "blue";
                        temp[key].dot = (
                            <SyncOutlined
                                spin
                                style={{fontSize: "20px", color: "#8dba5f"}}
                            />
                        );

                        if (deviceList) {
                            if (deviceList[key] === true) {
                                temp[key].color = "green";
                                temp[key].dot = (
                                    <CheckCircleOutlined
                                        style={{
                                            fontSize: "20px",
                                            color: "#54a14a",
                                            borderRadius: "25px",
                                            backgroundColor: "rgba(84, 161, 74, 0.5)",
                                        }}
                                    />
                                );
                                temp[key].availability = true;
                            } else {
                                temp[key].color = "red";
                                temp[key].dot = (
                                    <CloseCircleOutlined
                                        style={{
                                            fontSize: "20px",
                                            color: "#db0413",
                                            borderRadius: "25px",
                                            backgroundColor: "rgba(219, 4, 19, 0.5)",
                                        }}
                                    />
                                );
                                setShowAccessButton(true);
                                isValidationPass = false;
                                temp[key].availability = false;
                                temp[key].error = deviceList[key];
                            }
                            temp[key].deviceNames = getDeviceName(key);
                        }
                        setConditionList(temp);
                        resolve("Success!");
                    }, 1000);
                } catch (error) {
                    console.error("DeviceValidating", "appendComponentWithEffect", error);
                    resolve(null);
                }
            });
        };

        return appendItem("hasMicrophone")
            .then(() => appendItem("hasWebcam"))
            .then(() => appendItem("isMicrophoneAlreadyCaptured"))
            .then(() => appendItem("isWebcamAlreadyCaptured"))
            .then(() => appendItem("isScreenshareSupported"))
            .then(() => appendItem("isWebRTCSupported"))
            .then(() => appendItem(""));
    };

    const loadDeviceList = (callback) => {
        try {
            appendComponentWithEffect(null);
            setTimeout(async () => {
                const deviceList = await phoneEngine.phoneFunctions.CheckDeviceSupport();
                setTimeout(async () => {
                    try {
                        await appendComponentWithEffect(deviceList);
                        if (isValidationPass) {
                            dispatch(
                                OnMediaDeviceError({
                                    isMediaDeviceAccessGranted: true,
                                    isMicrophoneAlreadyCaptured: true,
                                    isWebcamAlreadyCaptured: true
                                })
                            );
                        }
                        if (deviceStatus) {
                            deviceStatus(isValidationPass);
                        }
                        if (callback) {
                            callback(isValidationPass);
                        }
                        console.log("DeviceValidating", "loadDeviceList", "last step");
                    } catch (error) {
                        console.error(
                            "DeviceValidating",
                            "loadDeviceList",
                            "callback",
                            error
                        );
                        if (callback) {
                            callback(false);
                        }
                    }
                }, 3000);
                console.log("DeviceValidating", "loadDeviceList", "CheckDeviceSupport", "devices details", JSON.stringify(deviceList));
            }, 5000);
        } catch (error) {
            console.error("DeviceValidating", "loadDeviceList", error);
        }
    };

    const requestAccess = async () => {
        try {
            console.error("DeviceValidating", "requestAccess", "request access");
            setIsLoading(true);
            setShowAccessButton(false);
            setConditionList(list);
            const stream = await phoneEngine.phoneFunctions.requestCameraAccess();
            setTimeout(async () => {
                phoneEngine.phoneFunctions.releaseLocalVideo(stream);
                console.log("DeviceValidating", "requestAccess", "media released");
            }, 3000);
            console.log("DeviceValidating", "requestAccess", "access accepted");
        } catch (error) {
            console.error("DeviceValidating", "requestAccess", error);
        }
    };

    const retest = async () => {
        try {

            console.log("DeviceValidating", "retest", "retest called");
            window.location.reload();
            /* setShowAccessButton(false);
            setIsLoading(false);
            loadDeviceList((e) => {
              if (e) {
                setTimeout(async () => {
                  console.log("DeviceValidating", "retest", "retest callback executed");
                  reload();
                }, 1000);
              }
            }); */

            /*   setShowAccessButton(false);
                  setIsLoading(false);
                  loadDeviceList(); */
        } catch (error) {
            console.error("DeviceValidating", "retest", error);
        }
    };

    return (
        <>
            <section className={`main-login-wrapper`}>
                {/* <div className={"top-left-leaf-bg"}>
          <img
            src={require("../../../assets/img/bg/login/topRightLeaf.svg")}
            alt="User Avatar"
          />
        </div> */}

                {/*====== login from main wrapper start here ========*/}
                <div>
                    <div className={"login-layout-wrp d-flex justify-content-between"}>
                        <div className="deviceStatus-form-main-wrapper ">
                            <div className="deviceStatus-form-inner-wrapper">
                                <div className={`deviceStatus-form`}>
                                    <div
                                        className={
                                            "deviceStatusCharacter-wrp error-page text-center"
                                        }
                                    >
                                        <img
                                            src={require("../../../assets/img/bg/login/MainCharacter.svg")}
                                            alt="User Avatar"
                                        />
                                    </div>
                                    <div className={"sign-in-body-wrapper "}>
                                        <div className={"text-wrapper deviceStatus "}>
                                            <Timeline mode="left">
                                                {Object.values(conditionList).map((item) => {
                                                    return (
                                                        <Timeline.Item
                                                            key={item.name}
                                                            color={item.color}
                                                            dot={
                                                                item.dot ? (
                                                                    item.dot
                                                                ) : (
                                                                    <QuestionCircleOutlined
                                                                        style={{
                                                                            fontSize: "20px",
                                                                            color: "#5da4de",
                                                                        }}
                                                                    />
                                                                )
                                                            }
                                                        >
                                                            <div className={"connectivityStatusMsg "}>
                                                                {item.availability
                                                                    ? item.name
                                                                    : item.availabilityMsg}
                                                            </div>
                                                            {item.deviceNames.map((name) => {
                                                                return (
                                                                    <Tag color="rgb(160 160 160)"
                                                                         key={name}>{name}</Tag>
                                                                );
                                                            })}
                                                            {!item.availability &&
                                                            <Tag color="red" key={item.error}>{item.error}</Tag>}
                                                        </Timeline.Item>
                                                    );
                                                })}
                                            </Timeline>
                                        </div>

                                        <div className={'device-mobile-footer-wrapper'}>
                                            {showAccessButton && (
                                                <Button type="danger" block onClick={requestAccess}>
                                                    Request Permission!
                                                </Button>
                                            )}

                                            {(
                                                <Button block onClick={retest}>
                                                    Test Again
                                                </Button>
                                            )}
                                            {cancel && (
                                                <Button type="secondary" block onClick={cancel}>
                                                    cancel
                                                </Button>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={"login-footer-bg-wrapper d-flex"}>
                    <div className={"left-plant"}>
                        <img
                            src={require("../../../assets/img/bg/login/footerPlantLeft.svg")}
                            alt="User Avatar"
                        />
                    </div>
                    <div className={"transparent-plant"}>
                        <img
                            src={require("../../../assets/img/bg/login/transparentPlant.svg")}
                            alt="User Avatar"
                        />
                    </div>
                    <div className={"left-single-plant"}>
                        <img
                            src={require("../../../assets/img/bg/login/singlePlant.svg")}
                            alt="User Avatar"
                        />
                    </div>

                    <div className={"right-plant"}>
                        <img
                            src={require("../../../assets/img/bg/login/rightPlant.svg")}
                            alt="User Avatar"
                        />
                    </div>
                </div>

                <div className={"sky-bg"}>
                    <img
                        src={require("../../../assets/img/bg/login/skyBg.svg")}
                        alt="User Avatar"
                    />
                </div>

                <div className={"left-round-wrp"}/>
                <div className={"top-bg-round-wrp"}/>

                <div className={"stars-wrapper"}>
                    <img
                        src={require("../../../assets/img/bg/login/stars.svg")}
                        alt="User Avatar"
                    />
                </div>
                <div className={"stars-wrapper-2"}>
                    <img
                        src={require("../../../assets/img/bg/login/starsOnly.svg")}
                        alt="User Avatar"
                    />
                </div>
            </section>
        </>
    );
};

export default DeviceValidating;
