import React, {
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
} from "react";
import {
  UserPlus,
  Pause,
  Mic,
  MicOff,
  Airplay,
  Video,
  MoreVertical,
  PhoneCall,
  MoreHorizontal
} from "react-feather";
import { MenuIcon, AudioListener, MuteButton } from "../index";
import { Popconfirm, Popover } from "antd";
import ActiveCaller from './ActiveCaller';

const localAudioRef = React.createRef();
const AudioCallPlayer = forwardRef(
  (
    {
      viewPointWidth,
      OnClickAudioEndCall,
      onClickOpenInviteModal,
      muteUnmuteAudioVideo,
      holdUnhold,
      callee,
      callDirection,
      startScreenSharing,
      stopScreenSharing,
      upgradeToVideo,
      callType,
      callStatus,
      isHideAudioPenal,
      localStreams,
      remoteStreams,
      isAllowedScreenShare,
      isScreenShare,
      blockScreenShare,
      isOpenInfoPanel,
      callDurationComponent, caller, calleeName, phoneConfig
    },
    ref
  ) => {
    const [isMute, setMute] = useState(false);
    const [isHold, setHold] = useState(false);


    console.log(
      "%cAUDIO PLAYER RENDER",
      "color:#233E82; font-family:'Ubuntu'; display: block;font-weight:bold; font-size:15px;"
    );
    useEffect(() => {
      try {
        setMute(false);
        setHold(false);
        if (localAudioRef.current) {
          localAudioRef.current.srcObject = localStreams;
        }

        // setActiveCaller(Object.values(Object.keys(remoteStreams)[0]).name);
        console.log("Audio Call", "Calling useEffect Method");
      } catch (error) {
        console.error("AudioCallPlayer", "useEffect", error);
      }
    }, []);

    useImperativeHandle(ref, () => ({
      stateActionsEvents(payload) {
        try {
          switch (payload.type) {
            case "REMOTEHANGUP":
            case "CALLEEOFFLINE": {
              removeStreams();
              break;
            }
            case "Error": {
              removeStreams();
              console.error(payload);
              break;
            }
            default: {
              break;
            }
          }
        } catch (error) {
          console.error("useImperativeHandle - stateActionsEvents", error);
        }
      },
    }));

    const muteUnmuteAudio = (val) => {
      setMute(val);
      muteUnmuteAudioVideo(val, true);
    };

    const holdUnholdCall = (val) => {
      setHold(val);
      holdUnhold(val);
    };

    const removeStreams = () => {
      try {
        setMute(false);
        setHold(false);
        localAudioRef.current.src = "";
        localAudioRef.current.srcObject = null;
        localAudioRef.current.removeAttribute("src");
      } catch (error) {
        console.error("", error);
      }
    };

    const endCall = () => {
      try {
        removeStreams();
        OnClickAudioEndCall();
      } catch (error) {
        console.error("endCall", error);
      }
    };
    const sharing = (val) => {
      try {
        if (val) {
          stopScreenSharing();
          return;
        }
        startScreenSharing(callee);
      } catch (error) {
        console.error("sharing", error);
      }
    };

    const conferencingCallContent = (
      <div
        className={"document-select-wrapper audio-conferencing-call-wrapper"}
      >
        {Object.values(remoteStreams).map((item) => {
          return (
            <div className={`audio-conferencing-caller d-flex`} key={item.name}>
              <div className={"caller-image-id"}>
                <img
                  src={require("../../../assets/img/defaultUser.svg")}
                  alt="User Avatar"
                />
              </div>
              <div className={"aconf-caller-name d-flex align-items-center"}>
                {item.name}
              </div>
              <AudioListener mediaStream={item.mediaStream} />
            </div>
          );
        })}
      </div>
    );

    const actionIconsContent = (
      <>
        {isAllowedScreenShare && !blockScreenShare && !isScreenShare && navigator.mediaDevices.getDisplayMedia !== undefined && (
          <div title={isScreenShare ? "End Sharing" : "Share Screen"}
            className={`pg-header-icon-wrp dark-bg  flex justify-content-center`} >
            <MenuIcon
              onClick={() => sharing(isScreenShare)}
              size={"transparent-bg"}
              color={isScreenShare ? "red" : "white"}
              status={null}
              icon={<Airplay />}
            />
          </div>
        )}

        {isAllowedScreenShare && (
          <div title="Add Users" className={`pg-header-icon-wrp dark-bg flex justify-content-center`} >
            <MenuIcon
              onClick={onClickOpenInviteModal}
              size={"transparent-bg"}
              status={null}
              icon={<UserPlus />}
            />
          </div>
        )}
        {/* temporally disable this functionality till backend completed  */}
        {false && isAllowedScreenShare && (
          <div title="Hold Call"
            className={`pg-header-icon-wrp dark-bg   flex justify-content-center ${isHold ? "active" : ""
              }`}
          >
            <MenuIcon
              onClick={() => holdUnholdCall(!isHold)}
              size={"transparent-bg"}
              status={null}
              icon={<Pause />}
            />
          </div>
        )}
        {isAllowedScreenShare && (
          <MuteButton caller={caller} className={"pg-header-icon-wrp dark-bg flex justify-content-center"} muteUnmuteAudioVideo={muteUnmuteAudioVideo} size={"transparent-bg"} color={null} status={null} />
        )}
        {phoneConfig && phoneConfig.basic_config && phoneConfig.basic_config.calls && phoneConfig.basic_config.calls.button_swap_audio_to_video === true && isAllowedScreenShare && !blockScreenShare && !isScreenShare && (
          <Popconfirm
            title="Switch to video call"
            onConfirm={upgradeToVideo}
            // onCancel={cancel}
            okText="Switch"
            cancelText="cancel"
          >
            <div
              title="To Video"
              className={`pg-header-icon-wrp dark-bg   flex justify-content-center`}
            >
              <MenuIcon
                // onClick={upgradeToVideo}
                size={"transparent-bg"}
                status={null}
                icon={<Video />}
              />
            </div>
          </Popconfirm>
        )}
      </>
    )

    return callType === "AUDIO_CALL" && !isHideAudioPenal ? (
      <>
        <div className={`audio-call-player-wrapper d-flex justify-content-between `} >
          <div className={"audio-call-bg-wrapper"}>
            <img
              src={require("../../../assets/img/audioCallIBG.svg")}
              alt="User Avatar"
            />
          </div>

          <audio
            title={"Me"}
            ref={localAudioRef}
            autoPlay
            playsInline
            muted
          ></audio>
          <div className={`left-wp right-wp d-flex align-items-center`}>
            <div className={`d-flex align-items-center`}>
              <div className={"phone-icon-wrp d-flex justify-content-center align-items-center"}>
                <MenuIcon
                  size={"transparent-bg"}
                  status={null}
                  icon={<PhoneCall />}
                />
              </div>
              <div className={`d-flex  flex-column`}>
                <div
                  className={`title-name dark-bg`}
                >{`${callDirection} - ${callStatus}`}</div>
                <div className={`time-count dark-bg`}>
                  {callDurationComponent}
                </div>
              </div>
            </div>
          </div>
          {isAllowedScreenShare && (
            <div className={"active-call-list d-flex"}>
              <div
                className={
                  "active-call-inner-wrapper d-flex justify-content-between  "
                }
              >
                <div
                  className={"d-flex align-items-center justify-content-center"}
                >
                  <>
                    <div className={"active-caller-img-wrp"}>
                      <img
                        src={require("../../../assets/img/defaultUser.svg")}
                        alt="User Avatar"
                      />
                    </div>
                    <div
                      className={
                        "active-caller-name d-flex flex-column text-left "
                      }
                    >
                      <div className={"title"}>Caller</div>
                      <div className={"caller-full-name"}>
                        <ActiveCaller calleeName={calleeName} />
                        {Object.values(remoteStreams).map((item) => {
                          return (
                            <AudioListener
                              key={item.id}
                              mediaStream={item.mediaStream}
                            />
                          );
                        })}
                      </div>
                    </div>
                  </>
                  {/*{Object.values(remoteStreams[Object.key]).map(item => {})}*/}
                </div>
                <Popover
                  placement="bottom"
                  content={conferencingCallContent}
                  trigger="hover"
                >
                  <div
                    className={`d-flex align-items-center justify-content-center`}
                  >
                    <div className={"more-call-details-wrp"}>
                      <MenuIcon
                        size={"transparent-bg"}
                        status={null}
                        icon={<MoreVertical />}
                      />
                    </div>
                  </div>
                </Popover>
              </div>
            </div>
          )}

          <div className={`right-wp d-flex align-items-center`}>
            <div className={"miscellaneous-action-icons"}>
              {actionIconsContent}
            </div>
            <div className={"miscellaneous-action-icons-mobile"}>
              <Popover
                placement="bottom"
                content={actionIconsContent}
                trigger="hover"
              >
                <div className={`pg-header-icon-wrp dark-bg   flex justify-content-center`} >
                  <div className={"more-call-details-wrp"}>
                    <MenuIcon
                      size={"transparent-bg"}
                      status={null}
                      icon={<MoreHorizontal />}
                    />
                  </div>
                </div>
              </Popover>
            </div>

            <div
              title="End Call"
              className={`pg-header-icon-wrp active end-call flex justify-content-center`}
            >
              <MenuIcon
                onClick={endCall}
                size={"x-sm-outline"}
                status={null}
                iconType={"material"}
                icon={<i className="material-icons">call_end</i>}
              />
            </div>
          </div>
        </div>
      </>
    ) : (
      <></>
    );
  }
);

AudioCallPlayer.defaultProps = {};

export default AudioCallPlayer;
