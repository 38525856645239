import React, { useState, useEffect } from "react";
import { Modal } from 'antd';
import { subscribeToEvents } from "../../../utils/eventEmitter";

let onCancel = () => {
    console.warn("onCancel - Method Not Implemented");
}

let onYes = () => {
    console.warn("onYes - Method Not Implemented");
}

const UIPopupModal = props => {

    const [modalStatus, setModalStatus] = useState({
        openState: false,
        title: "",
        yesButtonText: "Yes",
        cancelButtonText: "Cancel"
    });

    useEffect(() => {

        subscribeToEvents('show_modal', data => {

            onCancel = data.onCancel;
            onYes = data.onYes;

            setModalStatus({
                openState: true,
                title: data.title,
                yesButtonText: data.yesButtonText,
                cancelButtonText: data.cancelButtonText
            })

        })

    }, []);


    return (
        <>
            <Modal
                title={modalStatus.title}
                closable={false}
                visible={modalStatus.openState}
                maskClosable={false}
                okText={modalStatus.yesButtonText}
                cancelText={modalStatus.cancelButtonText}
                onOk={() => {
                    setModalStatus({
                        openState: false,
                        title: "",
                        yesButtonText: "Yes",
                        cancelButtonText: "Cancel"
                    });

                    onYes();

                }}
                confirmLoading={false}
                onCancel={() => {

                    setModalStatus({
                        openState: false,
                        title: "",
                        yesButtonText: "Yes",
                        cancelButtonText: "Cancel"
                    });

                    onCancel();
                }}
            >
                <p><img style={{ width: "fit-content", display: "block", marginLeft: "auto", marginRight: "auto" }} src={require("../../../assets/img/chatEmpty1.svg")}
                    alt="User Avatar" />
                </p>
            </Modal>
        </>
    );
};

export default UIPopupModal;
