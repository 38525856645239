
import { 
    CALL_CONTROL_RESET,CALL_MUTE_UNMUTE,  CALL_VIDEO_MUTE_UNMUTE  ,PARTICIPATOR_ADDED,PARTICIPATOR_STATUS,VOICE_ACTIVITY,SCREEN_SHARE_ADDED,SCREEN_SHARE_REMOVED,CALL_REMOTE_MUTED,CALL_REMOTE_UNMUTED,DEVICE_LIST_LOADED,VIDEO_DEVICE_CHANGED,INTERACTION_TRANSFER_REQUEST_ACCEPTED,MEDIA_DEVICE_ACCESS_ERROR
 } from '../actions/actionTypes'

 export const onCallControlStateRest = (data)=> ({type: CALL_CONTROL_RESET, payload: data});
export const onCallMuteUnmute = (data)=> ({type: CALL_MUTE_UNMUTE, payload: data});
export const onCallVideoMuteUnmute = (data)=> ({type: CALL_VIDEO_MUTE_UNMUTE, payload: data});
export const onParticipatorAdded = (data)=> ({type: PARTICIPATOR_ADDED, payload: data});
export const onParticipatorStatusChange = (data)=> ({type: PARTICIPATOR_STATUS, payload: data});
export const OnVoiceActivity = (data)=> ({type: VOICE_ACTIVITY, payload: data});
export const OnScreenShareAdded = (data)=> ({type: SCREEN_SHARE_ADDED, payload: data});
export const OnScreenShareRemoved = (data)=> ({type: SCREEN_SHARE_REMOVED, payload: data});
export const OnRemoteMuted = (data)=> ({type: CALL_REMOTE_MUTED, payload: data});
export const OnRemoteUnmuted = (data)=> ({type: CALL_REMOTE_UNMUTED, payload: data});
export const OnDeviceListLoaded = (data)=> ({type: DEVICE_LIST_LOADED, payload: data});
export const OnVideoDeviceChanged = (data)=> ({type: VIDEO_DEVICE_CHANGED, payload: data});
export const OnMediaDeviceError = (data)=> ({type: MEDIA_DEVICE_ACCESS_ERROR, payload: data});
export const onInteractionTransferAccepted = (data)=> ({type: INTERACTION_TRANSFER_REQUEST_ACCEPTED, payload: data});

