import React from 'react';
import PropTypes from 'prop-types';
import { ChatFooter, ForwardedLabel, MenuIcon, ProfileImage } from "../index";
import { Popover } from "antd";
import { MoreVertical } from "react-feather";
import navigationRestrictions from "../../../config/navigationRestrictions";

const openDoc = (src) => {
    window.open(src, "_blank");
}

const content = ({ onClickReplayMessage, onClickForwardMessage, onDownloadClick, contentUrl, filename, onClickOpenGallery, mediaFiles, msgObj, sendForwardMessages, onClickStar, isStarred, fileType }) => (
    <div className={'chat-hover-menu'}>
        <div
            onClick={() => onClickReplayMessage(msgObj)}
            className={'document-row'}>Reply
        </div>
        {navigationRestrictions.chat.forwarding.active && <div className={'document-row'}
            onClick={() => onClickForwardMessage((users) => {

                sendForwardMessages(users, msgObj);

            })}>
            Forward message
        </div>}
        {navigationRestrictions.chat.starring.active && <div className={'document-row'} onClick={() => onClickStar(msgObj, !isStarred)}>{isStarred ? 'Unstar Message' : 'Star Message'}</div>}
        <div className={'document-row'} onClick={() => (fileType === 'application/pdf' || fileType === 'document/pdf') ? openDoc(contentUrl) : onDownloadClick(mediaFiles)}>View</div>
        <div className={'document-row'} onClick={() => onDownloadClick(mediaFiles)}>Download</div>
    </div>
);

const ChatLeftDocument = ({ src, msgObj, isForwarded, mid, fullName, msg, isImage, lastSeen, fileType, onClickReplayMessage, onClickForwardMessage, onDownloadClick, contentUrl, filename, onClickOpenGallery, mediaFiles, sendForwardMessages, onClickStar, isStarred, ...props }) => {


    let iconPath = require("../../../assets/img/pdf.svg");

    if (fileType === "application/msword" || fileType === "application/vnd.openxmlformats-officedocument.wordprocessingml.document") {
        iconPath = require("../../../assets/img/docx.svg");
    } else if (fileType === "application/vnd.ms-excel" || fileType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
        iconPath = require("../../../assets/img/xls.svg");
    } else if (fileType === "text/csv") {
        iconPath = require("../../../assets/img/csv.svg");
    } else if (fileType === "text/plain") {
        iconPath = require("../../../assets/img/textSVG.svg");
    }

    return (
        <div key={mid} id={mid} className={`msg d-flex left-msg ${isImage ? '' : 'with-out-img'} `}>
            <div className="msg-img">
                <ProfileImage
                    textType={'chatLeft'}
                    firstLatter={fullName ? fullName.split(' ').map(fullName => fullName[0]).join('').toUpperCase() : ""}
                    size={'sm'}
                    src={src}
                    padding="4px"
                    fontSize="0.8rem" />
            </div>
            <div className={`inner-msg-bubble ${isImage ? null : 'with-out-name'}`}>
                {(props.conversation_type === 3 || props.conversation_type === 4) && <div className="msg-info-name">{fullName}</div>}

                <div className={'msg-text'}>
                    <ForwardedLabel isForwardedMsg={isForwarded}
                        textalign={'left'} />
                    <div className="msg-text-with-document d-flex flex-column ">


                        <div className={`document-wrapper d-flex ${msg ? 'with-replay' : 'default '}`}>
                            <div className={'inner-dco-wrapper img-icon d-flex justify-content-center align-items-center'}>
                                <img src={iconPath}
                                    alt="User Avatar" />
                            </div>
                            <div className={'file-name d-flex justify-content-center align-items-center'} onClick={() => (fileType === 'application/pdf' || fileType === 'document/pdf') ? openDoc(contentUrl) : onDownloadClick(mediaFiles)}>
                                {filename}
                            </div>
                        </div>
                        {msg}
                        <Popover
                            content={content({ onClickReplayMessage, onClickForwardMessage, onDownloadClick, contentUrl, filename, onClickOpenGallery, mediaFiles, msgObj, sendForwardMessages, onClickStar, isStarred, fileType })}
                            trigger="hover">
                            <div className={'hover-more'}>
                                <MenuIcon
                                    icon={<MoreVertical />} />
                            </div>
                        </Popover>
                    </div>

                </div>
                <ChatFooter lastSeen={lastSeen}
                    isStarred={isStarred} />
            </div>
        </div>
    )
};

ChatLeftDocument.propTypes = {
    fullName: PropTypes.string.isRequired,
    msg: PropTypes.string.isRequired,
    isImage: PropTypes.bool.isRequired,
    isLastSeen: PropTypes.bool.isRequired,
};

ChatLeftDocument.defaultProps = {
    src: null,
};

export default ChatLeftDocument;
