import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { MenuIcon } from "../../common";
import SelectedUserWrapper from "../../common/UserWithFullName/SelectedUserWrapper";
import {
    Airplay,
    Search,
    Paperclip,
    Phone,
    Video,
    Shield,
    XSquare,
    Zap,
    Repeat, Settings, PenTool, Triangle, AlertTriangle, Maximize, Minimize
} from "react-feather";
import { Form, Popover, Tooltip, Drawer, Select, Button, message } from "antd";
import { bindActionCreators } from "redux";
import ChatEndTimer from "../../../components/common/ChatEndTimer/ChatEndTimer";
import * as uiPanelActions from "../../../redux/actions/uiPanelActions";
import * as teamActions from "../../../redux/actions/teamActions";
import * as selectedProfileActions from "../../../redux/actions/selectedProfileActions";
import uuid from "uuid/v1";
import liveChatClient from "../../../lib";
import {
    imageTypes,
    videoTypes,
    audioTypes,
    documentTypes,
} from "./../../../config/json/fileTypeMap";
import navigationRestrictions from "../../../config/navigationRestrictions";
import { acwUsers, addTimer } from "../../../utils/acwUsersHandler";
import {
    __APP_CHAT_END_ACW_TIME__,
    __APP_CHAT_END_ACW_ALLOWED__,
    __APP_DEFAULT_WORKSPACE__,
    __APP_INTERACTION_MODE__,
    __APP_MAX_FILE_UPLOAD_SIZE__
} from "../../../config/baseUrls";
import { getUserId, getWorkspace, getCustomData } from "../../../utils";
import "./styles/header-panel.scss";
import "./styles/header-panel-responsive.scss";
import * as interactionActions from "../../../redux/actions/interactionActions";
import * as externalApiActions from "../../../redux/actions/externalApiActions";
import * as eventEmitter from "../../../utils/eventEmitter";
import * as phoneConfig from "../../../config/phoneConfig";
import sdkHandler from "../../../lib";
import * as auth from "../../../utils";
import * as logger from "../../../lib/logSdk/logger";
import renderState from "../../../service/renderStateHandler";
import * as whiteboardActions from '../../../redux/actions/whiteboardActions';

const { Option } = Select;

function getAllowedMedia() {
    let allowedMedia = [];

    if (navigationRestrictions.media.allowed_media.video) {
        allowedMedia = [...allowedMedia, ...videoTypes];
    }
    if (navigationRestrictions.media.allowed_media.image) {
        allowedMedia = [...allowedMedia, ...imageTypes];
    }
    if (navigationRestrictions.media.allowed_media.audio) {
        allowedMedia = [...allowedMedia, ...audioTypes];
    }

    return allowedMedia;
}


function HeaderPanelComponent(props) {
    const [isScreenShare, setIsScreenShare] = useState(false);
    const [isClientProfile, setIsClientProfile] = useState(true);
    const [inputDeviceList, setInputDeviceList] = useState([]);
    const [outputDeviceList, setOutputDeviceList] = useState([]);
    const [videoDeviceList, setVideoDeviceList] = useState([]);
    const [showDeviceSetting, setShowDeviceSetting] = useState(false);
    const [inputDevice, setInputDevice] = useState();
    const [outputDevice, setOutputDevice] = useState();
    const [cameraDevice, setCameraDevice] = useState();
    const [showSettingButton, setShowSettingButton] = useState(false);
    const customData = getCustomData();

    let history = useHistory();

    const allMediaTypes = getAllowedMedia();
    useEffect(() => {
        try {
            setIsScreenShare(false);
            if (props.mediaDevices) {
                setInputDeviceList(props.mediaDevices.inputs);
                setOutputDeviceList(props.mediaDevices.outputs);
                setVideoDeviceList(props.mediaDevices.videos);
                setInputDevice(props.mediaDevices.defaultMic);
                setOutputDevice(props.mediaDevices.defaultSpeaker);
                setCameraDevice(props.mediaDevices.defaultCamera);
                setShowSettingButton(true);
            }
        } catch (error) {
            console.error("HeaderPanelComponent", "useEffect", error);
            logger.error("HeaderPanelComponent", "useEffect", error.message);
        }
    }, [props.mediaDevices]);

    const setMediaDevices = () => {
        try {
            props.setMediaDevices({ inputDevice, outputDevice, cameraDevice });
            setShowDeviceSetting(false);

            setInputDevice(inputDevice);
            setOutputDevice(outputDevice);
            setCameraDevice(cameraDevice);

        } catch (error) {
            console.error("HeaderPanelComponent", "setMediaDevices", "fail to set media devices", error);
            logger.error("HeaderPanelComponent", "setMediaDevices", error.message);
        }
    }
    function onPressChatFilterIcon(_filter) {
        console.log("event fire...");
        console.log(_filter);
    }

    const interactionTransferUsersAdded = (users) => {
        if (users.length > 0) {
            liveChatClient.sendInteractionTransferRequest(
                users[0].userId,
                props.selected_profile.InteractionId
            );
        }
    };


    const content = (
        <div className={"document-select-wrapper"}>
            {navigationRestrictions.media.uploader.media_upload.active && (
                <input
                    id="selectImage"
                    accept={getAllowedMedia().join(",")}
                    hidden
                    multiple
                    type="file"
                    name="file"
                    onChange={(media) => {
                        let fileObj = {};

                        if (__APP_MAX_FILE_UPLOAD_SIZE__ !== 0) {
                            let largeFile = Array.from(media.target.files).find(file => file.size > __APP_MAX_FILE_UPLOAD_SIZE__);

                            if (largeFile) {
                                message.error(`File size exceeds maximum allowed size of ${__APP_MAX_FILE_UPLOAD_SIZE__} bytes`);
                                return;
                            }
                        }

                        for (const file of Array.from(media.target.files)) {
                            if (allMediaTypes.indexOf(file.type) === -1) {
                                message.error(`Unsupported file format`);
                                return;
                            }

                            let fileId = uuid();
                            fileObj[fileId] = {
                                file_id: fileId,
                                file: file,
                                name: file.name,
                                size: file.size,
                                type: file.type,
                                caption: "",
                            };
                        }
                        props.ui_panel_actions.openMediaUpload(fileObj, "MEDIA");
                        //props.onUploadFirstMedia(media);
                        media.target.value = null;
                    }}
                />
            )}
            {navigationRestrictions.media.uploader.media_upload.active && (
                <div
                    className={"document-row"}
                    onClick={() => {
                        document.getElementById("selectImage").click();
                        //props.onClickOpenMediaUpload();
                    }}
                >
                    Media
                </div>
            )}
            {/*<div className={"document-row"}>Camera</div>*/}
            {navigationRestrictions.media.uploader.document_upload.active && (
                <input
                    id="selectDocument"
                    accept={documentTypes.join(",")}
                    hidden
                    multiple
                    type="file"
                    name="file"
                    onChange={(media) => {
                        let fileObj = {};

                        if (__APP_MAX_FILE_UPLOAD_SIZE__ !== 0) {
                            let largeFile = Array.from(media.target.files).find(file => file.size > __APP_MAX_FILE_UPLOAD_SIZE__);

                            if (largeFile) {
                                message.error(`File size exceeds maximum allowed size of ${__APP_MAX_FILE_UPLOAD_SIZE__} bytes`);
                                return;
                            }
                        }

                        for (const file of Array.from(media.target.files)) {
                            if (
                                documentTypes.indexOf(file.type) === -1 &&
                                !(
                                    file.name &&
                                    (file.name.endsWith(".txt") || file.name.endsWith(".doc") || file.name.endsWith(".docx") || file.name.endsWith(".xlsx") || file.name.endsWith(".xls") || file.name.endsWith(".csv"))
                                )
                            ) {
                                message.error(`Unsupported file format`);
                                return;
                            }
                            let fileId = uuid();
                            fileObj[fileId] = {
                                file_id: fileId,
                                file: file,
                                name: file.name,
                                size: file.size,
                                type: file.type,
                                caption: "",
                            };

                            if (file.name.endsWith(".doc")) {
                                fileObj[fileId].type = "application/msword";
                            }

                            if (file.name.endsWith(".docx")) {
                                fileObj[fileId].type =
                                    "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
                            }

                            if (file.name.endsWith(".xls")) {
                                fileObj[fileId].type = "application/vnd.ms-excel";
                            }

                            if (file.name.endsWith(".xlsx")) {
                                fileObj[fileId].type =
                                    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
                            }

                            if (file.name.endsWith(".csv")) {
                                fileObj[fileId].type =
                                    "text/csv";
                            }

                            if (file.name.endsWith(".txt")) {
                                fileObj[fileId].type =
                                    "text/plain";
                            }
                        }
                        props.ui_panel_actions.openMediaUpload(fileObj, "DOCUMENT");
                        //props.onUploadFirstMedia(file);
                        media.target.value = null;
                    }}
                />
            )}
            {navigationRestrictions.media.uploader.document_upload.active && (
                <div
                    className={"document-row"}
                    onClick={() => {
                        document.getElementById("selectDocument").click();
                        //props.onClickOpenMediaUpload();
                    }}
                >
                    Document
                </div>
            )}
        </div>
    );

    let showIcons =
        (!navigationRestrictions.presence.active || navigationRestrictions.presence.show_communication_buttons.active ||
            (props.presence &&
                (props.presence.status === "online" ||
                    props.presence.status === "available"))) &&
        props.call &&
        props.call.callStatus === "Idle";

    console.log(
        "ButtonStatus",
        `status : ${props.presence ? props.presence.status : "not Set"
        }, callStatus : ${props.call ? props.call.callStatus : "not Set"
        }, showIcons:${showIcons}`
    );

    console.log(navigationRestrictions.presence.show_communication_buttons.active);
    const screenSharing = (val) => {
        try {
            //setIsScreenShare(!val);
            props.onClickScreenSharing(
                !val,
                props.selected_profile.InteractionInfo
                    ? props.selected_profile.InteractionInfo.Channel
                    : null,
                props.selected_profile.InteractionId
            );
        } catch (error) {
            console.error("HeaderPanelComponent", "screenSharing", error);
            logger.error("HeaderPanelComponent", "screenSharing", error.message);
        }
    };

    const screenSharingRequest = (val) => {
        try {
            // setIsScreenShare(!val);
            props.onClickScreenSharingRequest(
                props.selected_profile.InteractionInfo
                    ? props.selected_profile.InteractionInfo.Channel
                    : null,
                props.selected_profile.InteractionId
            );
        } catch (error) {
            console.error("HeaderPanelComponent", "screenSharingRequest", error);
            logger.error("HeaderPanelComponent", "screenSharingRequest", error.message);
        }
    };

    const onRemovePress = () => {
        sdkHandler.updateInteractionActions(props.selected_profile.username, "CLOSE-TAB");
        props.interaction_actions.removeInteraction(
            props.selected_profile.username
        );
        props.selected_profile.userData &&
            props.team_actions.removeContact(props.selected_profile.userData.customerId);
        props.selected_profile_actions.profileSelected({});
        props.onClickCloseInfoPanel();
        history.push('/console')
    };

    const onStopPress = () => {
        //remove contact
        props.interaction_actions.setACWStatus(props.selected_profile.username);

        if (__APP_CHAT_END_ACW_ALLOWED__ === "YES") {
            if (__APP_CHAT_END_ACW_TIME__) {
                addTimer(props.selected_profile.username, __APP_CHAT_END_ACW_TIME__);
            }
        } else {
            props.interaction_actions.removeInteraction(
                props.selected_profile.username
            );
            props.selected_profile.userData &&
                props.team_actions.removeContact(props.selected_profile.userData.customerId);
            props.selected_profile_actions.profileSelected({});
        }

        let ws = getWorkspace();

        let endMessage = {
            from: getUserId(),
            to: props.selected_profile.username,
            ended_at: Date.now(),
            tenantId: ws ? ws : __APP_DEFAULT_WORKSPACE__,
        };
        liveChatClient.leaveInteraction(
            props.selected_profile.InteractionId,
            "AGENT_END",
            endMessage
        );

        let invoke_external_api_with_session_end = phoneConfig.basic_config.console.invoke_external_api_with_session_end;
        const open_external_app_db = auth.getPhoneConfig();
        if (open_external_app_db && open_external_app_db.basic_config && open_external_app_db.basic_config.console) {
            invoke_external_api_with_session_end = open_external_app_db.basic_config.console.invoke_external_api_with_session_end;
        }

        if (invoke_external_api_with_session_end) {
            if (
                props.selected_profile &&
                props.selected_profile.InteractionInfo &&
                props.selected_profile.InteractionId &&
                props.selected_profile.userData &&
                props.selected_profile.userData.customerId
            ) {
                props.external_api_actions.onEndSession(
                    props.selected_profile.InteractionInfo
                        ? props.selected_profile.InteractionInfo.Channel
                        : null,
                    props.selected_profile.InteractionId,
                    props.selected_profile.InteractionInfo.CustomerUserId, props.selected_profile.InteractionInfo.CustomData ? props.selected_profile.InteractionInfo.CustomData.dialogId : "-999"
                );
            } else {
                console.error("HeaderPanelComponent", "onStopPress", "method onEndSession, not invoking due to insufficient data");
                logger.error("HeaderPanelComponent", "onStopPress", "method onEndSession, not invoking due to insufficient data");
            }
        }
        if (
            phoneConfig.basic_config.calls.hangup_call_after_chat_end &&
            props.selected_profile &&
            props.selected_profile.InteractionId
        ) {
            eventEmitter.fireEvent("end_call_session", {
                from: getUserId(),
                interactionId: props.selected_profile.InteractionId,
            });
        }
    };

    const layout = {
        labelCol: {
            span: 4,
        },
        wrapperCol: {
            span: 20,
        },
    };
    const tailLayout = {
        wrapperCol: {
            offset: 4,
            span: 2,
        },
    };
    return (
        <div>
            <section className={`header-m-wrapper`}>
                <div
                    className={`pg-main-header-inner-wrapper d-flex justify-content-between`}
                >
                    <div className={`pg-user-left d-flex`}>
                        <Link to={`/console/${props.selected_profile.username}/profile`}>
                            <SelectedUserWrapper
                                presence={props.presence}
                                selected_profile={props.selected_profile}
                                src={"https://source.unsplash.com/wmbbbU0LUG8/150x150"}
                                fullName={"Clayton Ballard"}
                                lastSeen={"09.30 am"}
                                type={props.type}
                                onClick={props.onClickOpenInfoPanel}
                                isTyping={true}
                                active={true}
                                status={"online"}
                                isMeeting={props.selected_profile.InteractionInfo ? props.selected_profile.InteractionInfo.IsMeeting : false}
                            />
                        </Link>

                    </div>

                    <div className={`pg-user-action-wrp-right d-flex`}>
                        <>
                            {showIcons && !props.selected_profile.isACW && navigationRestrictions.calls.active && navigationRestrictions.calls.setting && navigationRestrictions.calls.setting.active && (
                                <>

                                    {showSettingButton && (<div
                                        className={`pg-header-icon-wrp flex justify-content-center`}
                                        title={"End Chat"}
                                    >
                                        <MenuIcon
                                            onClick={() => setShowDeviceSetting(!showDeviceSetting)}
                                            size={"sm-space"}
                                            icon={<Settings />}
                                        />
                                    </div>)}

                                    <Drawer
                                        title="Device Settings"
                                        placement={"top"}
                                        closable={true}
                                        onClose={() => setShowDeviceSetting(false)}
                                        visible={showDeviceSetting}
                                        key={"top"}
                                        getContainer={false}
                                        style={{ position: 'absolute' }}
                                    >

                                        <Form
                                            {...layout}
                                            name="basic"
                                            initialValues={{
                                                remember: true,
                                                size: "small"
                                            }}
                                            size={"small"}
                                        >
                                            <Form.Item
                                                label="Microphone"
                                                name="Microphone"
                                            >
                                                <Select value={inputDevice} onChange={(val) => setInputDevice(val)}>
                                                    {inputDeviceList.map((item) => {
                                                        return <Option value={item.deviceId} key={item.deviceId}>{item.label}</Option>
                                                    })}
                                                </Select>
                                            </Form.Item>

                                            <Form.Item
                                                label="Speaker"
                                                name="Speaker"
                                            >
                                                <Select value={outputDevice} onChange={(val) => setOutputDevice(val)}>
                                                    {outputDeviceList.map((item) => {
                                                        return <Option value={item.deviceId} key={item.deviceId}>{item.label}</Option>
                                                    })}
                                                </Select>
                                            </Form.Item>

                                            <Form.Item
                                                label="Camera"
                                                name="Camera"
                                            >
                                                <Select value={cameraDevice} onChange={(val) => setCameraDevice(val)}>
                                                    {videoDeviceList.map((item) => {
                                                        return <Option value={item.deviceId} key={item.deviceId}>{item.label}</Option>
                                                    })}
                                                </Select>
                                            </Form.Item>

                                            <Form.Item {...tailLayout}>
                                                <Button type="primary" onClick={setMediaDevices}>Set</Button>
                                            </Form.Item>
                                        </Form>
                                    </Drawer>
                                </>
                            )}

                            {props.selected_profile.isTemp && !(props.selected_profile.InteractionInfo && props.selected_profile.InteractionInfo.IsMeeting) &&
                                props.selected_profile.createdAt && (
                                    <div className={`pg-user-left d-flex`}>
                                        <ChatEndTimer
                                            startTimestamp={props.selected_profile.createdAt}
                                            selected_profile={props.selected_profile}
                                        />
                                    </div>
                                )}

                            {props.selected_profile.isTemp && !(props.selected_profile.InteractionInfo && props.selected_profile.InteractionInfo.IsMeeting) &&
                                props.selected_profile.createdAt &&
                                !props.selected_profile.isACW && (
                                    <div
                                        className={`pg-header-icon-wrp flex justify-content-center`}
                                    >
                                        <MenuIcon
                                            title={"End Chat"}
                                            onClick={onStopPress}
                                            size={"sm-space"}
                                            icon={<XSquare />}
                                        />
                                        
                                    </div>
                                )}
                            {navigationRestrictions.co_browsing.whiteboard.active && (
                                <>
                                    {(props.whiteboard.wbState === 'OPENED' || props.activeMenu === 'MINIMIZED') && props.selected_profile.username === props.whiteboard.contactId && <div className={`pg-header-icon-wrp flex justify-content-center`}>
                                        <MenuIcon
                                            onClick={() => {
                                                props.whiteboard_actions.disposeWhiteboard();
                                                /* props.onPressMenuIcon('chat'); */
                                                renderState.focusToLastRead = true;
                                                renderState.scrollReadyState = false;
                                                let chatTextArea = document.getElementById("chat_text_wrapper_main_window");
                                                chatTextArea && chatTextArea.focus();
                                            }}
                                            size={"sm-space"}
                                            title={"Exit Whiteboard"}
                                            icon={<AlertTriangle color="red" />}
                                        />

                                    </div>}

                                    {showIcons && props.whiteboard.wbState === 'CLOSED' && <div className={`pg-header-icon-wrp flex justify-content-center`}>
                                        <MenuIcon
                                            title={"Whiteboard"}
                                            onClick={() => {
                                                props.whiteboard_actions.openWhiteboard(props.selected_profile.username, 'WHITEBOARD');
                                                /* props.onPressMenuIcon('whiteboard'); */
                                            }}
                                            size={"sm-space"}
                                            icon={<Triangle />}
                                        />

                                    </div>}
                                    {(props.whiteboard.wbState === 'OPENED' || props.whiteboard.wbState === 'MINIMIZED') && props.selected_profile.username === props.whiteboard.contactId && <div
                                        className={`pg-header-icon-wrp flex justify-content-center`}
                                    >
                                        {props.whiteboard.wbState === 'OPENED' ? <MenuIcon
                                            onClick={() => {
                                                props.whiteboard_actions.minimizeWhiteboard();
                                                renderState.focusToLastRead = true;
                                                renderState.scrollReadyState = false;
                                                let chatTextArea = document.getElementById("chat_text_wrapper_main_window");
                                                chatTextArea && chatTextArea.focus();
                                            }}
                                            size={"sm-space"}
                                            title={"Minimize Whiteboard"}
                                            icon={<Minimize color="red" />}
                                        /> : <MenuIcon
                                            title={"Restore Whiteboard"}
                                            onClick={() => {
                                                props.whiteboard_actions.maximizeWhiteboard();
                                            }}
                                            size={"sm-space"}
                                            icon={<Maximize color="green" />}
                                        />}

                                    </div>}
                                </>
                            )}
                            {props.selected_profile &&
                                !props.selected_profile.isACW &&
                                props.selected_profile.InteractionInfo &&
                                !props.selected_profile.InteractionInfo.IsMeeting &&
                                props.selected_profile.conversation_type === 4 &&
                                __APP_INTERACTION_MODE__ === 'INTERACTION' &&
                                props.selected_profile.InteractionInfo.UserIds && (
                                    <div
                                        className={`pg-header-icon-wrp flex justify-content-center`}
                                    >
                                        <MenuIcon
                                            onClick={() =>
                                                props.onClickOpenInviteModal(
                                                    interactionTransferUsersAdded,
                                                    props.selected_profile.InteractionInfo.UserIds,
                                                    false,
                                                    false,
                                                    "Chat Transfer",
                                                    false,
                                                    "TRANSFER"
                                                )
                                            }
                                            title={"Transfer"}
                                            size={"sm-space"}
                                            icon={<Repeat />}
                                        />
                                    </div>
                                )}

                            {props.selected_profile.isTemp && !(props.selected_profile.InteractionInfo && props.selected_profile.InteractionInfo.IsMeeting) && props.selected_profile.createdAt && props.selected_profile.isACW && !__APP_CHAT_END_ACW_TIME__ &&
                                <div className={`pg-header-icon-wrp flex justify-content-center`}>
                                    <MenuIcon
                                        onClick={onRemovePress}
                                        size={"sm-space"}
                                        title={"Close Tab"}
                                        icon={<Zap />}
                                    />
                                </div>
                            }
                        </>

                        {navigationRestrictions.media.uploader.active &&
                            props.ui_panel_header_data.buttons.file.show &&
                            !props.selected_profile.isACW && (
                                <Popover
                                    placement="bottomLeft"
                                    content={content}
                                    trigger="hover"
                                >
                                    <div
                                        className={`pg-header-icon-wrp  flex justify-content-center `}
                                    >
                                        <Tooltip placement="topRight" title={"File Upload"}>
                                            <MenuIcon size={"sm-space"} icon={<Paperclip />} />
                                        </Tooltip>
                                    </div>
                                </Popover>
                            )}
                        {navigationRestrictions.chat.search.active &&
                            !props.selected_profile.isACW && (
                                <div
                                    className={`pg-header-icon-wrp flex justify-content-center`}
                                >
                                    <Link
                                        to={`/console/${props.selected_profile.username}/search`}
                                    >
                                        <Tooltip placement="topRight" title={"Search"}>
                                            <MenuIcon
                                                title={"Search"}
                                                onClick={props.onClickOpenInfoPanel}
                                                size={"sm-space"}
                                                icon={<Search />}
                                            />
                                        </Tooltip>
                                    </Link>
                                </div>
                            )}

                        {navigationRestrictions.signature_capture.active && showIcons && props.selected_profile.conversation_type !== 3 && !props.selected_profile.isACW && !(props.selected_profile.InteractionInfo && props.selected_profile.InteractionInfo.IsMeeting) &&
                            !props.selected_profile.isACW && (
                                <div className={`pg-header-icon-wrp flex justify-content-center`}>
                                    <MenuIcon
                                        onClick={() => {
                                            //eventEmitter.fireEvent('show_signature_modal', null);
                                            sdkHandler.requestSignatureCapture(props.selected_profile.username);
                                            message.info(`Signature capture requested`);
                                        }}
                                        size={"sm-space"}
                                        title={"Request Signature"}
                                        icon={<PenTool />}
                                    />
                                </div>
                            )}

                        {/*phone action*/}
                        {showIcons && !props.selected_profile.isACW && (
                            <>
                                {/*  navigationRestrictions.screen_sharing.active && !isClientProfile && */}
                                {navigationRestrictions.screen_sharing.screen_share.active &&
                                    navigator.mediaDevices.getDisplayMedia !== undefined && (
                                        <div
                                            className={`pg-header-icon-wrp flex justify-content-center`}
                                        >
                                            <MenuIcon
                                                onClick={() => screenSharing(isScreenShare)}
                                                title={isScreenShare ? "End Sharing" : "Share Screen"}
                                                status={isScreenShare ? "material" : null}
                                                size={"sm-space"}
                                                icon={<Airplay />}
                                            />
                                        </div>
                                    )}
                                {/* navigationRestrictions.screen_sharing.active && isClientProfile &&  */}
                                {navigationRestrictions.screen_sharing.screen_share_request.active &&
                                    isClientProfile && (
                                        <div
                                            className={`pg-header-icon-wrp flex justify-content-center`}
                                        >
                                            <MenuIcon
                                                onClick={() => screenSharingRequest(isScreenShare)}
                                                status={"material"}
                                                size={"sm-space"}
                                                title={"Request Screen Sharing"}
                                                icon={<Shield />}
                                            />
                                        </div>
                                    )}

                                {navigationRestrictions.calls.active &&
                                    navigationRestrictions.calls.audio_calls.active && (
                                        <div
                                            className={`pg-header-icon-wrp flex justify-content-center`}
                                        >
                                            <MenuIcon
                                                onClick={() =>
                                                    props.OnClickOutGoingCall(
                                                        props.selected_profile.InteractionInfo
                                                            ? props.selected_profile.InteractionInfo.Channel
                                                            : null,
                                                        props.selected_profile.InteractionId
                                                    )
                                                }
                                                size={"sm-space"}
                                                title={"Audio Call"}
                                                status={""}
                                                icon={<Phone />}
                                            />
                                        </div>
                                    )}
                                {/*video action*/}
                                {navigationRestrictions.calls.active &&
                                    navigationRestrictions.calls.video_calls.active && ((__APP_INTERACTION_MODE__ === 'INTERACTION' && customData && customData.isVideo) || __APP_INTERACTION_MODE__ === 'USER') && (
                                        <div
                                            className={`pg-header-icon-wrp flex justify-content-center`}
                                        >
                                            <MenuIcon
                                                onClick={() =>
                                                    props.onClickVideoCall(
                                                        props.selected_profile.InteractionInfo
                                                            ? props.selected_profile.InteractionInfo.Channel
                                                            : null,
                                                        props.selected_profile.InteractionId
                                                    )
                                                }
                                                size={"sm-space"}
                                                title={"Video Call"}
                                                icon={<Video />}
                                            />
                                        </div>
                                    )}

                                {/* {navigationRestrictions.calls.active && navigationRestrictions.calls.video_calls.active  && phoneConfig.basic_config.calls.selfView.active === true &&  ((__APP_INTERACTION_MODE__ === 'INTERACTION' && customData && customData.isVideo) || __APP_INTERACTION_MODE__ === 'USER') &&(
                                        <div
                                            className={`pg-header-icon-wrp flex justify-content-center`}
                                            title={"Self View"}
                                        >
                                            <MenuIcon
                                                onClick={() =>
                                                    props.onClickSelfView(
                                                        props.selected_profile.InteractionInfo
                                                            ? props.selected_profile.InteractionInfo.Channel
                                                            : null,
                                                        props.selected_profile.InteractionId
                                                    )
                                                }
                                                size={"sm-space"}
                                                icon={<Instagram />}
                                            />
                                        </div>
                                    )} */}



                            </>
                        )}


                        {/* {!showIcons && props.call && props.call.callType === "SCREENSHARE" && (
                            <div
                                className={`pg-header-icon-wrp flex justify-content-center`}
                                title={isScreenShare ? "End Sharing" : "Share Screen"}
                            >
                                <Link to="/console/charlotte.daniel/search">
                                    <MenuIcon
                                        onClick={() => screenSharing(isScreenShare)}
                                        size={"x-sm-outline"}
                                        color={isScreenShare ? "red" : "white"}
                                        status={isScreenShare ? "material" : null}
                                        icon={<Airplay/>}
                                    />
                                </Link>
                            </div>
                        )} */}
                        {/* {!showIcons &&
                            ///disable
                            <>
                                <div className={`pg-header-icon-wrp disable-me flex justify-content-center`}>
                                    <Link to="/console/charlotte.daniel/search">
                                        <MenuIcon
                                            onClick={() => screenSharing(isScreenShare)}
                                            size={'sm-space'}
                                            icon={<Airplay />} />
                                    </Link>
                                </div>
                                <div className={`pg-header-icon-wrp disable-me  flex justify-content-center`}>
                                    <MenuIcon
                                        size={'sm-space'}
                                        status={''}
                                        icon={<Phone />} />
                                </div>
                                <div className={`pg-header-icon-wrp disable-me flex justify-content-center`}>
                                    <MenuIcon
                                        size={'sm-space'}
                                        icon={<Video />} />
                                </div>
                            </>
                        } */}
                    </div>
                </div>
            </section>
        </div>
    );
}

HeaderPanelComponent.propTypes = {
    presence: PropTypes.object,
    selected_profile: PropTypes.object.isRequired,
    call: PropTypes.object.isRequired,
    ui_panel_actions: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
    let mapState = {
        selected_profile: state.selected_profile,
        call: state.call,
        ui_panel_header_data: state.ui_panel_data.header_panel,
        whiteboard: state.whiteboard
    };

    if (state.selected_profile && state.selected_profile.username) {
        mapState.presence = state.presence.data[state.selected_profile.username];
    }
    return mapState;
}

function mapDispatchToProps(dispatch) {
    return {
        ui_panel_actions: bindActionCreators(uiPanelActions, dispatch),
        team_actions: bindActionCreators(teamActions, dispatch),
        interaction_actions: bindActionCreators(interactionActions, dispatch),
        selected_profile_actions: bindActionCreators(
            selectedProfileActions,
            dispatch
        ),
        external_api_actions: bindActionCreators(externalApiActions, dispatch),
        whiteboard_actions: bindActionCreators(whiteboardActions, dispatch)
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(HeaderPanelComponent);
