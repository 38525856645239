import * as types from "../actions/actionTypes";
import produce from "immer";

export const interactionReducer = produce((draft = {}, action) => {

    switch (action.type) {
        case types.GET_INTERACTIONS_SUCCESS:
            {
                draft = action.interactions;
                return draft;
            }
        case types.ADD_INTERACTION: {
            draft[action.interactionInfo.InteractionId] = action.interactionInfo;
            return draft;
        }
        case types.SET_INTERACTION_ACW_STATUS:
            {
                if (draft[action.interactionId]) {
                    draft[action.interactionId].isACW = true;
                }
                return draft;
            }
        case types.SET_CALL_ESCALATION_STATUS:
            {
                if (draft[action.interactionId]) {
                    draft[action.interactionId].IsCallEscalated = action.isCallEscalated;
                }
                return draft;
            }

        case types.ADD_INTERACTION_USER: {
            if (draft[action.interactionId] && draft[action.interactionId].UserIds) {
                draft[action.interactionId].UserIds.push(action.userId);
            }
            if (action.userInfo && draft[action.interactionId].UserDetails) {
                draft[action.interactionId].UserDetails[action.userId] = action.userInfo;
            }
            return draft;
        }
        case types.REMOVE_INTERACTION_USER: {
            if (draft[action.interactionId] && draft[action.interactionId].UserIds) {

                const index = draft[action.interactionId].UserIds.indexOf(action.userId);
                if (index > -1) {
                    draft[action.interactionId].UserIds.splice(index, 1);
                }

                if (draft[action.interactionId].UserDetails) {
                    delete draft[action.interactionId].UserDetails[action.userId];
                }
            }
            return draft;
        }
        case types.REMOVE_INTERACTION:
            {
                if (draft[action.interactionId]) {
                    delete draft[action.interactionId];

                }
                return draft;
            }
        default:
            return draft;
    }

});