import React, { useEffect, useState } from "react";
import { MenuIcon, MuteButton, VideoLiveCount, AudioListener } from "../../common";
import { Minimize, Maximize, MicOff, Mic , Airplay} from "react-feather";
import { SvideoRefpin, Tooltip, Spin ,Tag ,Row,Col } from "antd";
import { useDispatch, useSelector } from "react-redux";
import navigationRestrictions from "../../../config/navigationRestrictions";
import { onCallMuteUnmute } from "../../../redux/actions/callControlsActions";

const videoRef = React.createRef();

const ScreenShareMyViewModal = (props) => {
  const [displayStatusClassNameTopLevel] = useState("pin-this");
  const [isLoading, setIsLoading] = useState(true);
  const [isMute, setMute] = useState(false);

  const state = useSelector((state) => state);
  const dispatch = useDispatch();


  useEffect(() => {
    console.log("ScreenShareMyViewModal", "Calling useEffect Method");
  }, []);

  useEffect(() => {
    try {
      if (videoRef.current) {
        videoRef.current.srcObject = props.mediaStream;
        videoRef.current.addEventListener("loadeddata", (event) => {
          console.debug(
            "ScreenShareMyViewModal",
            "useEffect",
            "bind stream to video element"
          );
        });
      }
    } catch (error) {
      console.error("ScreenShareMyViewModal", "useEffect", error);
    }
  }, [props.mediaStream]);

  useEffect(() => {
    try {
      setMute(state.callControls.controls.isCallMuted);
    } catch (error) {
      console.log("ScreenShareMyViewModal", "useEffect", error);
    }
  }, [state.callControls.controls.isCallMuted]);

  const muteUnmuteAudio = (val) => {
    setMute(val);
    props.muteUnmuteAudioVideo(val, state.callControls.controls.isVideoMuted);
    dispatch(
      onCallMuteUnmute({
        userId: props.caller,
        callType: "AUDIO_CALL",
        isOperated: true,
        isMuted: val,
      })
    );
  };
  return (
    <>
        <span>{props.callStatus?.toLowerCase()}</span> 
        <button className={`button ${props.callStatus === "ScreenShared" ? "enable" : "disabled"} ${isMute ? "active":""} `} title={isMute? "Unmute":"Mute"} onClick={() => muteUnmuteAudio(!isMute)}>{isMute ? <MicOff></MicOff> : <Mic></Mic>}</button>
        <button className={`button ${props.callStatus === "ScreenShared" ? "enable" : "disabled"}`} title="End Sharing" onClick={() => {props.stopScreenSharing()}}><Airplay/></button>
    </>
  );
};

export default ScreenShareMyViewModal;
