import * as types from "../actions/actionTypes";
import initialState from "../reducers/initialState";
import produce from "immer";

export const callReducer = produce((draft = initialState.call, action) => {
  switch (action.type) {
    case types.INCOMING_CALL: {
      draft = action.number;
      return draft;
    }
    case types.CALL_PROGRESS: {
      draft = action.data;
      return draft;
    }
    case types.CALL_ESCALATED: {
      draft = action.duration;
      return draft;
    }    
    default:
      return draft;
  }
});
