export const buildGroupList = (groupList) => {

    try {
        let formattedGroupList = { UserId: "", LastGroupListId: 0, GroupListInfo: {} };
        if (groupList && groupList.GroupListInfo) {

            formattedGroupList = groupList.GroupListInfo.reduce((acc, group) => {
                group.conversation_type = 3;
                group.added_users = [];
                group.removed_users = [];
                acc.GroupListInfo[group.Id] = group;
                return acc;
            }, { UserId: groupList.UserId, LastGroupListId: groupList.LastGroupListId, GroupListInfo: {} })

        }

        return formattedGroupList;

    } catch (ex) {
        console.log(ex);
        return {};
    }

}

export const buildCreatedGroup = (groupInfo) => {

    try {
        groupInfo.conversation_type = 3;
        groupInfo.added_users = [];
        groupInfo.removed_users = [];
        return groupInfo;

    } catch (ex) {
        console.log(ex);
        return null;
    }

}