import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { message } from "antd/lib/index";
import { Check } from 'react-feather';
import * as teamActions from "../../../redux/actions/teamActions";
import * as groupActions from "../../../redux/actions/groupActions";
import * as presenceActions from "../../../redux/actions/presenceActions";
import * as chatActions from "../../../redux/actions/chatActions";
import * as selectedProfileActions from "../../../redux/actions/selectedProfileActions";
import { getWorkspaces, getWorkspace, getUser, getRefreshTokenObj, getUserId } from "../../../utils";
import livechatclient from "../../../lib";
import { authUrl, __APP_INTERACTION_MODE__ } from "../../../config/baseUrls";
import { Scrollbars } from "react-custom-scrollbars";
import navigationRestrictions from "../../../config/navigationRestrictions";


const WorkspaceSwitcher = (props) => {

    let history = useHistory();

    const [selectedWSName, setSelectedWSName] = useState(getWorkspace());

    let wsList = getWorkspaces();
    let myWorkspaceList = <></>;

    if (wsList) {
        myWorkspaceList = getWorkspaces().map(workspace => {
            return (<div id={`WORKSPACE_${workspace.workSpaceName}`} key={`WORKSPACE_${workspace.workSpaceName}`}
                className={`work-space-list-wrp ${props.type} d-flex justify-content-between align-items-center ${workspace.workSpaceName === selectedWSName ? "active" : ""}`}
                onClick={() => onChangeHandler(workspace.workSpaceName)}>
                <div className={'workspace-name'}>
                    {workspace.workSpaceName}
                </div>
                <div className={'workspace-check-list'}>
                    <div className={`c-right d-flex align-items-center `}>
                        <div
                            className={`selected-wrapper ${workspace.workSpaceName === selectedWSName ? "selected" : ""}`}>
                            <div className={`select d-flex align-items-center justify-content-center `}>
                                <Check />
                            </div>
                        </div>
                    </div>
                </div>
            </div>);
        });
    }

    useEffect(() => {

        let getUserGroupListRecursive = (nextId, groupList) => {

            livechatclient.subscribeEvent("groups", "onUserGroupListing", (currentList) => {

                if (currentList.GroupListInfo && currentList.GroupListInfo.length > 0) {
                    getUserGroupListRecursive(currentList.LastGroupListId, [...groupList, ...currentList.GroupListInfo]);
                } else {
                    props.group_actions.groupListLoaded({ GroupListInfo: groupList });
                }

            });

            livechatclient.getUserGroupList(null, nextId);


        };

        livechatclient.subscribeEvent(
            "workspaceSwitch",
            "onWorkspaceSwitched",
            () => {
                props.team_actions.getTeamMembers(getUserId());
                //__APP_INTERACTION_MODE__ === "INTERACTION" && props.team_actions.getTemporaryAgents();               
                __APP_INTERACTION_MODE__ === "INTERACTION" && livechatclient.getUserInteractionListForUser([getUserId()]);
                props.selected_profile_actions.profileSelected({});
                navigationRestrictions.group.active && getUserGroupListRecursive(0, []);
                if (navigationRestrictions.presence.active) {
                    props.presence_actions.getPresenceAll(getWorkspace(), getUserId());
                }

                //props.chat_actions.getAllUnreadCounts(getUserId());

                history.push("/console");

            }
        );

        livechatclient.subscribeEvent(
            "workspaceSwitch",
            "onStartSuccess",
            sid => {
                console.log('Workspace Switch Process', 'onStartSuccess', `sid : ${sid}`);
                props.team_actions.getTeamMembers(getUserId());
                //__APP_INTERACTION_MODE__ === "INTERACTION" && props.team_actions.getTemporaryAgents();
                __APP_INTERACTION_MODE__ === "INTERACTION" && livechatclient.getUserInteractionListForUser([getUserId()]);

                props.selected_profile_actions.profileSelected({});
                navigationRestrictions.group.active && getUserGroupListRecursive(0, []);
                if (navigationRestrictions.presence.active) {
                    props.presence_actions.getPresenceAll(getWorkspace(), getUserId());
                }

                let presenceObj = { userid: getUserId(), status: 'online' };
                props.presence_actions.sendPresenceSuccess(presenceObj);
                livechatclient.sendPresenceNotification(JSON.stringify(presenceObj));

                //props.chat_actions.getAllUnreadCounts(getUserId());

                history.push("/console");

            }
        );

    }, [])

    const onChangeHandler = (workspace) => {
        if (workspace !== getWorkspace()) {
            let refreshTokenData = getRefreshTokenObj();
            if (refreshTokenData) {

                let username = getUser();

                livechatclient.switchWorkspace(authUrl, username, refreshTokenData.refreshToken, workspace).then(response => {

                    if (response.isSuccess) {
                        message.success(`Switched to workspace ${workspace}`);
                        setSelectedWSName(workspace);
                    } else {
                        message.error(`Error occurred when switching to workspace ${workspace}`);
                        return;
                    }

                }).catch(ex => {
                    message.error(`Error occurred when switching to workspace ${workspace}`);
                    console.error(ex);
                    return;
                })

            }

        }

    }

    livechatclient.subscribeEvent("workspaceSwitch", "onAddOfficialWorkspace", workspace => {
        onChangeHandler(workspace);
    });


    return (
        <>
            <div className={`menu-title ${props.type}`}>
                Workspace
            </div>

            <div className={`work-space-body ${props.type}`}>
                <Scrollbars
                    autoHeightMin={0}
                    autoHeightMax={props.type === 'mobile' ? 200 : 250}
                    autoHide
                    autoHeight>
                    {myWorkspaceList}
                </Scrollbars>
            </div>
            {/* {props.type === 'mobile' &&
                <div className={`work-space-body ${props.type}`}>
                    <Scrollbars
                        autoHeightMin={0}
                        autoHeightMax={props.type === 'mobile' ? 200 : 250}
                        autoHide
                        autoHeight>
                        {myWorkspaceList}

                    </Scrollbars>

                </div>
            } */}
        </>


    )
};

function mapDispatchToProps(dispatch) {
    return {
        selected_profile_actions: bindActionCreators(
            selectedProfileActions,
            dispatch
        ),
        team_actions: bindActionCreators(teamActions, dispatch),
        presence_actions: bindActionCreators(presenceActions, dispatch),
        chat_actions: bindActionCreators(chatActions, dispatch),
        group_actions: bindActionCreators(groupActions, dispatch)
    };
}

export default connect(null, mapDispatchToProps)(WorkspaceSwitcher);
