import React from 'react';
import PropTypes from 'prop-types';
import {UserPlus, Pause, Mic, MicOff, PhoneOutgoing, Airplay, X} from "react-feather";
import {ChatUser, MenuIcon, TitleWithSmallText, UserImage, ParticipationImage} from "../index";
import {Scrollbars} from "react-custom-scrollbars";


//online
//offline
//busy
//other
const UploadImageThumbnail = ({active, src, onClickRemoveImage, onClickImage, ...props}) => (
    <div key={props.key} className={`upload-img image-view ${active ? 'active' : ''}`}>
        <div className={'remove-wrp d-flex justify-content-center align-items-center'}>
            <MenuIcon icon={<X/>}
                      onClick={onClickRemoveImage}
                      size={'sm'}/>
        </div>
        <img onClick={onClickImage}
             src={src}/>
    </div>
);

// OutGoingCall.propTypes = {
//     status: PropTypes.string.isRequired,
// };

UploadImageThumbnail.defaultProps = {};

export default UploadImageThumbnail;
