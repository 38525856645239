import { clienteleUrl } from "../config/baseUrls";
import { fetchDefaults } from "./fetchWrapper";
import * as logger from '../lib/logSdk/logger';


var apiFetch = fetchDefaults(fetch);
export class ClienteleService {
  static getClientProfileByContact = (contact, contactType) => {
    return apiFetch(
      `${clienteleUrl}/clientele/user/contact/${contact}/contactType/${contactType}`,
      {
        method: "get"
      }
    )
      .then(function(response) {
        return response.json();
      })
      .then(function(data) {
        return data.isSuccess ? data.result : null;
      })
      .catch(error => {
        console.error("ClienteleService","getClientProfileByContact", error.message);
        logger.error("ClienteleService","getClientProfileByContact", error.message);
        return null;
      });
  };
  static getClientProfileByUserName = userName => {
    return apiFetch(
      `${clienteleUrl}/user-auth-service/api/v1/user/account/${userName}`,
      {
        method: "get"
      }
    )
      .then(function(response) {
        return response.json();
      })
      .then(function(data) {
        return data.status === 0 ? data.data : null;
      })
      .catch(error => {
        console.error("ClienteleService","getClientProfileByUserName", error);
        logger.error("ClienteleService","getClientProfileByUserName", error.message);
        return null;
      });
  };

  static saveClientProfile = postData => {
    return apiFetch(`${clienteleUrl}/clientele/user`, {
      method: "post",
      body: JSON.stringify(postData)
    })
      .then(function(response) {
        return response.json();
      })
      .then(function(data) {
        return data.isSuccess;
      })
      .catch(error => {
        console.error("ClienteleService","saveClientProfile", error);
        logger.error("ClienteleService","saveClientProfile", error.message);
        return false;
      });
  };
}
