import React, { Component } from "react";
import { Switch, Route, Redirect, BrowserRouter } from "react-router-dom";
import { ConnectedRouter } from "connected-react-router";
import { Provider } from "react-redux";

import SignInComponent from "../components/pages/user/SignIn";
import AutoLogin from "../components/pages/user/AutoLogin";
import CallbackRedirect from "../components/pages/user/CallbackRedirect";
import AutoLogout from "../components/pages/user/AutoLogout";
import SessionTakeOverLogout from "../components/pages/user/SessionTakeOverLogout";

import Invitations from "../components/pages/user/Invitations";

import ConsoleComponent from "../components/pages/console/MainConsole";
import SocketDisconnect from "../components/pages/Errors/SocketDisconnect";
import createStore from "../redux/store";
import { __APP_AUTO_LOGIN_ALLOWED__, iamRedirectUrl, __APP_IDENTITY_PROVIDER__, anonymousJoinAllowed } from "../config/baseUrls";
import navigationRestrictions from "../config/navigationRestrictions";

import ForgotPasswordComponent from "../components/pages/user/ForgotPassword";
import ExternalMeetingJoin from "../components/pages/user/externalMeetingJoin";
import ResetPassword from "../components/pages/user/ResetPassword";
import SingUpComponent from "../components/pages/user/SignUp";
import FirstLogin from "../components/pages/user/FirstLogin";

import DeviceValidation from "../components/pages/user/DeviceValidation";
import * as phoneConfig from "../config/phoneConfig";
import {MeetingJoinTMAC} from "../components/pages/user/MeetingJoinTMAC";
import NotAuthorized from "../components/pages/Errors/notAuthorized";

const { store, persistor, history } = createStore();
// AppRouter component is used to declare the routes through the application.
// All the pages are imported here and assigned to either a PublicRoute or a PrivateRoute
// You can create new types of routes as required and set them up here
class AppRouter extends Component {
  componentDidMount() { }

  isNeedToValidateDevices = (path) => {
    try {
      if (phoneConfig && phoneConfig.basic_config && phoneConfig.basic_config.console && phoneConfig.basic_config.console.mediaDevice && phoneConfig.basic_config.console.mediaDevice.active !== true) {
        return false;
      }
      if (path && path.location.search) {
        const params = new Map(
          path.location.search
            .slice(1)
            .split("&")
            .map((kv) => kv.split("="))
        );
        if (params) {
          const isVideo = params.get("isVideo");
          const username = params.get("username");
          const password = params.get("password");
          if (isVideo === undefined || isVideo.toLowerCase() !== "true") {
            console.log(
              "Device Validation",
              `---- Routing to Auto Login - ----`
            );
            return false;
          } else {
            console.log(
              "Device Validation",
              `---- Routing to device validation  - Device Validation - ----`
            );
            return true;
          }
        }
      }
      return true;

    } catch (error) {
      console.error("AppRouter", "isNeedToValidateDevices", error);
      return true;
    }
  };

  render() {
    return (
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <Switch>
            <Route path="/sign-up">
              <Invitations history={history} />
            </Route>
            {navigationRestrictions.recaptchaProvider &&
              navigationRestrictions.recaptchaProvider.active && (
                <Route path="/register">
                  <SingUpComponent history={history} />
                </Route>
              )}

            {navigationRestrictions.recaptchaProvider &&
              navigationRestrictions.recaptchaProvider.active && (
                <Route path="/forgot-password">
                  <ForgotPasswordComponent history={history} />
                </Route>
              )}
            {anonymousJoinAllowed === 'YES' && (
              <Route path="/meeting/:id/join/:callType">
                <ExternalMeetingJoin history={history} />
              </Route>
            )}

            {navigationRestrictions.recaptchaProvider &&
              navigationRestrictions.recaptchaProvider.active && (
                <Route path="/resetpassword">
                  <ResetPassword history={history} />
                </Route>
              )}

            {navigationRestrictions.recaptchaProvider &&
              navigationRestrictions.recaptchaProvider.active && (
                <Route path="/updatepassword">
                  <FirstLogin history={history} />
                </Route>
              )}

            {/* {__APP_AUTO_LOGIN_ALLOWED__ === "YES" && (
              <Route path="/auto_login">
                <AutoLogin history={history} />
              </Route>
            )} */}
            {__APP_AUTO_LOGIN_ALLOWED__ === "YES" && (
              <Route path="/login_url">
                <AutoLogin history={history} />
              </Route>
            )}
            <Route path='/iam_login' component={() => {
              window.location.href = iamRedirectUrl;
              return null;
            }} />
            {__APP_AUTO_LOGIN_ALLOWED__ === "YES" && (
              <Route
                path="/auto_login"
                component={(path) => {
                  return this.isNeedToValidateDevices(path) === true ? (
                    <DeviceValidation history={history} />
                  ) : (
                    <AutoLogin history={history} />
                  );
                }}
                condition={this.condition}
              />
            )}


            <Route exact path="/logout">
              {<AutoLogout history={history} />}
            </Route>
            <Route exact path="/session_lost">
              {<SessionTakeOverLogout />}
            </Route>
            <Route exact path="/error">
              {<SocketDisconnect history={history} />}
            </Route>
            <Route path="/console">
              <ConsoleComponent history={history} />
            </Route>
            {/* <Route exact path="/">
              <SignInComponent history={history} />
            </Route> */}
            <Route exact path="/login" component={() => {
              if (__APP_IDENTITY_PROVIDER__ === 'IAM') {
                window.location.href = iamRedirectUrl;
                return null;
              } else {
                return <SignInComponent history={history} />
              }

            }} />
            {/* <SignInComponent history={history} />
            </Route> */}
            <Route path="/callback_validate">
              <CallbackRedirect history={history} />
            </Route>
            <Route exact path="/callback">
              {this.isNeedToValidateDevices(null) === true ? <DeviceValidation history={history} /> : <CallbackRedirect history={history} />}

            </Route>
            <Route exact path="/" component={() => {
              if (__APP_IDENTITY_PROVIDER__ === 'IAM') {
                window.location.href = iamRedirectUrl;
                return null;
              } else {
                return this.isNeedToValidateDevices(null) === true ? <DeviceValidation history={history} /> : <SignInComponent history={history} />
              }

            }} />
            {/* {this.isNeedToValidateDevices(null) === true ? <DeviceValidation history={history} /> : <SignInComponent history={history} />}
            </Route> */}
            {/*  <Route exact path="/">
              <DeviceValidation history={history} />
            </Route> */}
            <Route exact path="/test">
              <DeviceValidation history={history} />
            </Route>
            <Route exact path="/meeting/test">
              <MeetingJoinTMAC history={history} />
            </Route>
            <Route exact path="/403">
              <NotAuthorized history={history} />
            </Route>
            <Route path="*">
              <Redirect to="/" />
            </Route>
          </Switch>
        </ConnectedRouter>
      </Provider>
    );
  }
}

export default AppRouter;
