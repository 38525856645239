import React from 'react';
import PropTypes from 'prop-types';
import {
    Button, SelectedIcon
} from '../../common';
import {Check} from "react-feather";


const RadioButton = ({onClick, size, selected, text}) => (
    <div className={`radio-btn-wrapper ${size} ${selected ? 'active' : ''} `} onClick={onClick}>
        <div className={'text d-flex align-items-center'}>
            <SelectedIcon
                active={selected}
                size={'sm'}
                icon={<Check/>} onClick={onClick}/>
            <div className={'radio-text-wrp'}>
                {text}
            </div>

        </div>
    </div>

);

RadioButton.propTypes = {
    size: PropTypes.string.isRequired,
};

RadioButton.defaultProps = {
    size: 'md'
};

export default RadioButton;
