import React from 'react';
import PropTypes from 'prop-types';
import { UserPlus, Pause, Mic, MicOff, PhoneOutgoing, Airplay } from "react-feather";
import { ChatUser, MenuIcon, TitleWithSmallText, UserImage, ParticipationImage } from "../index";
import { Scrollbars } from "react-custom-scrollbars";


//online
//offline
//busy
//other
const SearchResult = ({ date, msg }) => (
    <div className={'d-flex flex-column'}>
        <div className={'msg d-flex'}>
            {msg}
        </div>
        <div className={'msg d-flex'}>{date}</div>
    </div>
);

// OutGoingCall.propTypes = {
//     status: PropTypes.string.isRequired,
// };

SearchResult.defaultProps = {};

export default SearchResult;
