import React, { useState, useEffect } from "react";
import { DeviceNotSupported } from "../../common";
import { connect } from "react-redux";
import { message } from "antd/lib/index";
import ScrollAnimation from "react-animate-on-scroll";
import sdkHandler from "../../../lib";
import { useParams } from "react-router-dom";

import "./styles/user-landing.scss";
import "./styles/forgotpwd-responsive.scss";
import * as auth from "../../../utils";
import { bindActionCreators } from "redux";
import * as selectedProfileActions from "../../../redux/actions/selectedProfileActions";
import phoneEngine from '../phone/phoneEngine/phoneEngineHandler';
import * as logger from "../../../lib/logSdk/logger";

import AuthService from "../../../service/authService.js";

const ExternalMeetingJoin = (props) => {

    let { id ,callType } = useParams();
    const [displayName, setDisplayName] = useState("");

    useEffect(() => {
        sdkHandler.subscribeEvent(
            "ext_meeting_login-onStartSuccess",
            "onStartSuccess",
            sid => {
                console.log('External Meeting Login Process', 'onStartSuccess', `sid : ${sid}`);
                auth.setSession(sid);
                console.log(`Routing to Main Console`);
                props.history.push(`/console`);
                return;
            }
        );
        sdkHandler.subscribeEvent(
            "ext_meeting_login-onStartFailed",
            "onStartFailed",
            error => {

                let errorObj = JSON.parse(error);

                console.log('Ext Meeting Login Process', 'onStartFailed');
                logger.error("AgentConsole", "onStartFailed", `onStartFailed - Current Session : ${auth.getSession()}`);
                if (errorObj.reasonCode === 'SESSION_EXIST') {
                    console.warn(`Session Exists - Current Session : ${auth.getSession()}`);
                    logger.warn("AgentConsole", "onStartFailed", `Session Exists - Current Session : ${auth.getSession()}`);

                } else {

                    if (errorObj.reasonCode === "LICENSE_VALIDATION_FAILED_MAX_SESSION_REACHED") {
                        console.log('Ext Meeting Login Process', 'onStartFailed - Licence error');
                        message.error("Max sessions allowed for your license reached");
                    }

                }

                phoneEngine.phoneFunctions.hangup("caller", "callee");
                sdkHandler.logoutFromSystem("", "SESSION_END", "");
                sdkHandler.endSession();
                auth.userLogOut();

                message.error('Error Joining Meeting');

            }
        );

    }, []);

    let login = () => {
        sdkHandler.anonymousLogin(displayName).then(response => {
            console.log('Auto Login Process', `loginToSystem - isAuthenticationSuccess : ${response.isSuccess}`);
            if (response.isSuccess) {
                auth.setToken(response.token, response.userId);
                auth.setUser("Anonymous");
                auth.setUserType("MEETING");
                auth.setCustomData({ meetingId: id ,isVideo: callType && (callType.toLowerCase() === 'video' || callType.toLowerCase() === 'audio')});
                auth.setDisplayName(displayName);
                response.tokenInfo && response.tokenInfo.organizations && auth.setWorkspaces(response.tokenInfo.organizations);
                auth.setRefreshToken(response.tokenInfo && response.tokenInfo.refreshToken ? response.tokenInfo.refreshToken : response.token, "Anonymous");
                response.tokenInfo && auth.setWorkspace(response.tokenInfo.organization, "Anonymous");
                auth.setPassword("");
                props.selected_profile_actions.profileSelected({});
                auth.resetMediaDeviceErrorCount();
            } else {
                message.error("Invalid login details.");
                logger.error("AgentConsole", "loginToSystem", "Invalid login details.");
            }

        }).catch(ex => {
            console.error(ex);
            logger.error("AgentConsole", "loginToSystem", ex.message);
        });
    }

    return (
        <>
            <DeviceNotSupported />
            <section className={`main-login-wrapper sign-up`}>
                <div className={"top-left-leaf-bg"}>
                    <img
                        src={require("../../../assets/img/bg/login/topRightLeaf.svg")}
                        alt="User Avatar"
                    />
                </div>

                {/*====== login from main wrapper start here ========*/}
                <div className={"container"}>
                    <div className={"login-layout-wrp  d-flex justify-content-between"}>
                        {/*======== login form start here ========*/}
                        <div className="sign-up-form-main-wrapper" style={{ height: "300px", marginTop: "200px" }}>
                            <div className="sign-up-form-inner-wrapper">
                                <div>
                                    <div className={`sign-up-form`}>
                                        {/*<div className={'sign-up-character'}>*/}
                                        {/*    <img src={require("../../../assets/img/bg/login/MainCharacter.svg")}/>*/}
                                        {/*</div>*/}
                                        <ScrollAnimation
                                            delay="150"
                                            animateIn="fadeInUp"
                                            animateOut="fadeOutDown"
                                        >
                                            <div className={`login-header-wrapper mt-5 text-center`}>
                                                <h2 className={"text-center"}>Join Meeting</h2>
                                                <small>Please enter your name below</small>
                                            </div>
                                        </ScrollAnimation>

                                        <div className={"sign-in-body-wrapper mt-5"}>
                                            <div className={"fadeIn "}>
                                                <ScrollAnimation
                                                    delay="80"
                                                    animateIn="fadeIn"
                                                    animateOut="fadeOut"
                                                >
                                                    <div className="form-group">
                                                        <form className={"fadeIn"}>
                                                            <label htmlFor="exampleInputEmail1">
                                                                Display Name <span>*</span>
                                                            </label>
                                                            <input
                                                                id="displayNameMeeting"
                                                                name="displayNameMeeting"
                                                                className="form-control form-control-lg txt-login"
                                                                aria-describedby="emailHelp"
                                                                maxLength={50}
                                                                onChange={(evt => {
                                                                    setDisplayName(evt.target.value);
                                                                })}

                                                            />
                                                        </form>
                                                    </div>
                                                </ScrollAnimation>
                                                <div className={`login-button-wrapper`}>
                                                    <ScrollAnimation
                                                        delay="120"
                                                        animateIn="fadeInUp"
                                                        animateOut="fadeOutDown"
                                                    >
                                                        <div className={`login-bnt-hide`}>
                                                            <button disabled={!displayName} className="btn btn-rounded btn-lg btn-block btn-primary" onClick={login}>
                                                                Join
                                                            </button>

                                                        </div>
                                                    </ScrollAnimation>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={"login-form-bg-sm"} />
                                    </div>

                                    <div className={"login-form-bg-lg hide-mobile"} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={"login-footer-bg-wrapper d-flex"}>
                    <div className={"left-plant"}>
                        <img
                            src={require("../../../assets/img/bg/login/footerPlantLeft.svg")}
                            alt="User Avatar"
                        />
                    </div>
                    <div className={"transparent-plant"}>
                        <img
                            src={require("../../../assets/img/bg/login/transparentPlant.svg")}
                            alt="User Avatar"
                        />
                    </div>
                    <div className={"left-single-plant"}>
                        <img
                            src={require("../../../assets/img/bg/login/singlePlant.svg")}
                            alt="User Avatar"
                        />
                    </div>

                    <div className={"right-plant"}>
                        <img
                            src={require("../../../assets/img/bg/login/rightPlant.svg")}
                            alt="User Avatar"
                        />
                    </div>
                </div>

                <div className={"sky-bg"}>
                    <img
                        src={require("../../../assets/img/bg/login/skyBg.svg")}
                        alt="User Avatar"
                    />
                </div>

                <div className={"left-round-wrp"} />
                <div className={"top-bg-round-wrp"} />

                <div className={"stars-wrapper"}>
                    <img
                        src={require("../../../assets/img/bg/login/stars.svg")}
                        alt="User Avatar"
                    />
                </div>
                <div className={"stars-wrapper-2"}>
                    <img
                        src={require("../../../assets/img/bg/login/starsOnly.svg")}
                        alt="User Avatar"
                    />
                </div>
            </section>
        </>
    );
};

function mapDispatchToProps(dispatch) {
    return {
        selected_profile_actions: bindActionCreators(selectedProfileActions, dispatch)
    };
}

export default connect(
    null,
    mapDispatchToProps
)(ExternalMeetingJoin);

