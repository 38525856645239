import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import {
  PanelTitle,
  MenuIcon,
  TextBoxWithIcon,
  ModalSelectUser,
  SelectUser,
} from "../../common";
import { Check, X, Play, Send } from "react-feather";
import { Scrollbars } from "react-custom-scrollbars";
import VideoRecorderX from "./../video-recorder";
//online
//offline
//busy
//other
const startRecord = () => {};
let rec_state = 0;

const VideoRecorder = ({
  size,
  onClickClose,
  title,
  startRecord,
  isStartVideoRecord,
  sendRecord,
  cancelRecord,
  endRecord,
}) => {
  const [isCamOn, setIsCamOn] = useState(false);  

  return (
    <div className={`main-modal-wrapper ${size}`}>
      <div className={"modal-header d-flex"}>
        <PanelTitle title={title} />
        {!isCamOn && (
          <MenuIcon
            icon={<X />}
            onClick={() => {
              if (document.getElementById("REC_CANCEL")) {
                document.getElementById("REC_CANCEL").click();
              } else {
                if (document.getElementById("REC_STOP") && rec_state === 1) {
                  rec_state = 0;
                  document.getElementById("REC_STOP").click();
                  setTimeout(() => {
                    if (document.getElementById("REC_CANCEL")) {
                      document.getElementById("REC_CANCEL").click();
                    }
                  }, 1500);
                }
              }
              rec_state = 0;
              onClickClose();
            }}
            size={"sm"}
          />
        )}
      </div>
      <div className={"mdl-user-list-wrapper"} style={{ height: 450 }}>
        <div className={"video-income-wrapper"}>
          {/*<img alt="A" src="https://source.unsplash.com/XHVpWcr5grQ/450x450"/>*/}
          <VideoRecorderX
            isOnInitially={false}
            onRecordingComplete={sendRecord}
            onCancelledRecording={cancelRecord}
            timeLimit={10000}
            isReplayingVideo={true}
            chunkSize={250}
            isFlipped={true}
            onTurnOnCamera={()=>setIsCamOn(true)}
            onTurnOffCamera={()=>setIsCamOn(false)}
            constraints={{ audio: true, video: true }}
            onStopReplaying={() => {
              rec_state = 0;
              endRecord();
              //setIsOnInitially(!isOnInitially);
            }}
          />
          {/*{isStartVideoRecord &&*/}
          {/*<>*/}
          {/*    <div className={'voice-record-live'}/>*/}
          {/*    < div className={'time-counter-wrapper'}>*/}
          {/*        05:00*/}
          {/*    </div>*/}
          {/*</>*/}
          {/*}*/}
        </div>
      </div>

      <div className={"video-recorder-action-wrp"}>
        {!isStartVideoRecord && (
          <div
            className={
              "modal-send-btn-wrapper d-flex align-items-center justify-content-center"
            }
            onClick={() => {
              //alert("1 click");
              if (document.getElementById("REC_START")) {
                startRecord();
                rec_state = 1;
                document.getElementById("REC_START").click();
              }
            }}
          >
            <Play />
          </div>
        )}

        {isStartVideoRecord && (
          <div
            className={
              "modal-send-btn-wrapper green d-flex align-items-center justify-content-center"
            }
            onClick={() => {
              //alert("2 click " + rec_state);
              if (document.getElementById("REC_STOP") && rec_state === 1) {
                rec_state = 2;
                document.getElementById("REC_STOP").click();
              } else if (
                document.getElementById("REC_SEND") /*&& rec_state === 2*/
              ) {
                rec_state = 0;
                document.getElementById("REC_SEND").click();
                if (document.getElementById("REC_CANCEL")) {
                  document.getElementById("REC_CANCEL").click();
                }
                onClickClose();
              }
            }}
          >
            <Send />
          </div>
        )}
      </div>
    </div>
  );
};

// VideoRecorder.propTypes = {
//     status: PropTypes.string.isRequired,
//
// };

VideoRecorder.defaultProps = {
  status: "offline",
  title: "Invite",
  size: "default",
};

export default VideoRecorder;
