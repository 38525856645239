import { Logger } from './logger.js';
import { CurrentGeoLocationResponseModel } from "./location.js";
import { GenerateUUID } from './common.js';
import { SignatureCaptureResponseObject, signatureManager, SnapshotCaptureResponseObject, snapshotManager } from './capture.js';

class MessageModel {
    v;
    mid;
    sid;
    message_type;
    from;
    to;
    created_at;
    message_content;
    conversation_type;
    other;

    constructor(message_content, from, to, mid) {
        this.v = MessageConstants.version;
        this.mid = mid ? mid : GenerateUUID();
        this.sid = GenerateUUID()
        this.message_type = MessageConstants.message_type;
        this.from = new UserModel(from);
        this.to = new UserModel(to);
        this.created_at = new Date().getTime();
        this.message_content = message_content;
        this.conversation_type = getConversationType(to);
        this.other = "";
    }
}

class UserModel {
    id;

    constructor(id) {
        if (id.indexOf("user:") > -1 || id.indexOf("interaction:") > -1 || id.indexOf("group:") > -1) {
            this.id = id.split(":")[1];
        }
        else {
            this.id = id;
        }
    }
}

class TypingStateModel {
    from;
    to;
    conversation_type;
    status;

    constructor(status, from, to) {
        this.from = from;
        this.to = this.FormatAddress(to);
        this.conversation_type = getConversationType(to);
        this.status = status;
    }

    FormatAddress = (to) => {
        if (to.indexOf("user:") > -1 || to.indexOf("interaction:") > -1) {
            return to.split(":")[1];
        }
        else {
            return to;
        }
    }
}

class ReadReceiptModel {
    from;
    to;
    conversation_type;
    mid;
    seen_at;
    v;
    constructor(mid, from, to) {
        this.v = MessageConstants.version;
        this.mid = mid;
        this.from = new UserModel(from);
        this.to = new UserModel(to);
        this.seen_at = new Date().getTime();
        this.conversation_type = getConversationType(to);
    }
}

const MessageConstants = {
    version: 1,
    message_type: "text/html"
}

function getConversationType(toAddress) {
    if (toAddress.indexOf("interaction:") > -1) {
        return 4;
    }
    else if (toAddress.indexOf("group:") > -1) {
        return 3;
    }
    else
        return 0;
}

class AppEventMessageProcessor {
    __isAppEventMessage;
    messageContent;
    constructor(messageContent) {
        if (messageContent != null && typeof messageContent === "object")
            if (messageContent.__isAppEventMessage != null && messageContent.__isAppEventMessage) {
                this.__isAppEventMessage = true;
                this.messageContent = messageContent;
            }
            else
                this.__isAppEventMessage = false;
        else
            this.__isAppEventMessage = false;
    }

    ProcessAppEvent = livechat2apiReference => {
        if (this.__isAppEventMessage) {
            switch (this.messageContent.messageSubType) {
                case "request-current-location":
                    livechat2apiReference.onCurrentLocationRequest(new CurrentGeoLocationResponseModel(this.messageContent.content, this.messageContent.requesterUserId, livechat2apiReference));
                    break;
                case "response-current-location":
                    livechat2apiReference.onCurrentLocationResponse(this.messageContent.content);
                    break;
                case "signature-request":
                    livechat2apiReference.onSignatureRequest(new SignatureCaptureResponseObject(this.messageContent.content, livechat2apiReference));
                    break;
                case "user-signature-request-ack":
                    signatureManager.ProcessSignatureResponseAck(this.messageContent.content);
                    livechat2apiReference.onSignatureRequestAck(this.messageContent.content);
                    break;
                case "user-snapshot-request":
                    livechat2apiReference.onSnapshotRequest(new SnapshotCaptureResponseObject(this.messageContent.content, livechat2apiReference));
                    break;
                case "user-snapshot-request-ack":
                    snapshotManager.ProcessSnapshotResponseAck(this.messageContent.content);
                    livechat2apiReference.onSnapshotRequestAck(this.messageContent.content);
                    break;
                default:
                    Logger.warn("Unknown messageSubType received: " + this.messageContent.messageSubType)
                    break;
            }
            return true;
        }
        else
            return false;
    }
}

class AppEventMessage {
    __isAppEventMessage;
    messageSubType;
    requesterUserId;
    content;
    constructor() {
        this.__isAppEventMessage = true;
        this.requesterUserId = "";
        this.messageSubType = "";
        this.content = {};
    }
}

export {
    MessageModel, UserModel, TypingStateModel, MessageConstants, ReadReceiptModel, AppEventMessage, AppEventMessageProcessor
};
