import React from 'react';
import PropTypes from 'prop-types';
import { TitleWithSmallText } from "../index";


const ChatUnknownTypeMessage = (props) => (
    <div id={props.msgObj.mid} key={props.msgObj.mid} className={`center-message-wrapper d-flex justify-content-between`}>

        <div className={'msg-left-side d-flex justify-content-center align-items-center '}>
            <div className={`d-flex flex-column text-wrapper`}>
                <TitleWithSmallText
                    type={`sm-text`}
                    title={"Unknown Type"}
                    msg={props.msgObj.message_type} />
            </div>
        </div>

    </div>
);

ChatUnknownTypeMessage.propTypes = {
    messageType: PropTypes.string.isRequired,
};

export default ChatUnknownTypeMessage;
