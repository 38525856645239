import { Machine, interpret } from "xstate";
import phoneSdk from "./phoneSdkHandler";
import * as logger from '../../../../lib/logSdk/logger';
import uuid from "uuid/v1";

let _actionsEvent = {};
let _entryEvent = {};
let _networkBandwidth = {};
let _onDeviceChange = {};
let _onError = {};
let _session = {};
let myViewStream = null; 
let _ui_se_session_id = "";
/* #region  ---------------------------- State Engine --------------------------------- */
const toggleMachine = Machine(
  {
    id: "toggle",
    initial: "Offline",
    states: {
      Offline: {
        entry: "onEntryOffline",
        on: {
          INITIALIZING: {
            target: "Idle",
            actions: ["Initializing"],
          },
          ERROR: {
            target: "Idle",
            actions: ["onError"],
          },
        },
      },
      /* online: {
              on: {
                Registering: {
                  target: "Idle",
                  // transition actions
                  actions: ["Registering", "printTime"]
                }
              }
            }, */
      Idle: {
        entry: "onEntryIdle",
        on: {
          INITIALIZING: {
            target: "Idle",
            actions: ["ResetEngine"],
          },
          STARTCALLING: {
            target: "Dialling",
            actions: ["idleToDialling"],
          },
          INCOMINGCALL: {
            target: "Ringing",
            actions: ["idleToRinging"],
          },
          UNINITIALIZED: {
            target: "Offline",
            actions: ["idleToOffline"],
          },
          SCREENSHARE: {
            target: "Dialling",
            actions: ["IdleToScreenShare"],
          },
          SCREENSHAREREQUEST: {
            target: "ScreenShareRequest",
            actions: ["SendScreenShareRequest"],
          },
          ONSCREENSHAREREQUEST: {
            target: "ScreenShareRequest",
          },
          SCREENSHAREINCOMING: {
            target: "ScreenShare",
            actions: ["IdleToScreenShareIncoming"],
          },

          ERROR: {
            target: "Idle",
            actions: ["onError"],
          },
        },
      },
      Dialling: {
        entry: "onEntryDialling",
        on: {
          INITIALIZING: {
            target: "Idle",
            actions: ["ResetEngine"],
          },
          HANGUP: {
            target: "Idle",
            actions: ["DiallingToIdleHangup"],
          },
          REMOTERINGING: {
            target: "Ringing",
            // transition actions
            actions: ["DiallingToRinging"],
          },
          REMOTEREJECTED: {
            target: "Idle",
            // transition actions
            actions: ["DiallingToIdle"],
          },
          CALLEEOFFLINE: {
            target: "Idle",
            actions: ["DiallingToIdle"],
          },
          SIGNALINGDISCONNECTED: {
            target: "Idle",
            actions: ["DiallingToIdle"],
          },
          SCREENSHARECONNECTED: {
            target: "ScreenShare",
            actions: ["ScreenShareConnected"],
          },
          SCREENSHARCALLEERROR:{
            target: "Idle",
            actions: ["DiallingToIdle"],
          },
          ERROR: {
            target: "Idle",
            actions: ["onError"],
          },
        },
      },
      Ringing: {
        entry: "onEntryRinging",
        on: {
          INITIALIZING: {
            target: "Idle",
            actions: ["ResetEngine"],
          },
          HANGUP: {
            target: "Idle",
            // transition actions
            actions: ["RingingToIdleHangup"],
          },
          REMOTEHANGUP: {
            target: "Idle",
            // transition actions
            actions: ["RemoteHangup"],
          },
          REMOTEANSWER: {
            target: "Conversation",
            actions: ["RingingToConversation"],
          },
          REMOTEREJECTED: {
            target: "Idle",
            actions: ["RingingToIdle"],
          },
          ANSWERING: {
            target: "Answering",
            actions: ["RingingToAnswering"],
          },
          REJECTED: {
            target: "Idle",
            actions: ["RingingRejectedToIdle"],
          },
          TIMEOUT: {
            target: "Idle",
            actions: ["RingingToIdle"],
          },
          CALLEEOFFLINE: {
            target: "Idle",
            actions: ["RingingToIdle"],
          },
          SIGNALINGDISCONNECTED: {
            target: "Idle",
            actions: ["RingingToIdle"],
          },
          SCREENSHARECONNECTED: {
            target: "ScreenShare",
            actions: ["ScreenShareConnected"],
          },
          SCREENSHAREERROR:{
            target: "Idle",
            actions: ["RingingToIdle"],
          },
          ERROR: {
            target: "Idle",
            actions: ["onError"],
          },
        },
      },
      Answering: {
        entry: "onEntryAnswering",
        on: {
          INITIALIZING: {
            target: "Idle",
            actions: ["ResetEngine"],
          },
          HANGUP: {
            target: "Idle",
            // transition actions
            actions: ["AnsweringToIdle"],
          },
          ANSWERED: {
            target: "Conversation",
            actions: ["RingingToConversation"],
          },
          REMOTEHANGUP: {
            target: "Idle",
            // transition actions
            actions: ["RemoteHangup"],
          },
          TIMEOUT: {
            target: "Idle",
            actions: ["RingingToIdle"],
          },
          SIGNALINGDISCONNECTED: {
            target: "Idle",
            actions: ["RingingToIdle"],
          },
          SCREENSHARECALL: {
            actions: ["ScreenShareConCall"],
          },
          SCREENSHARECONNECTED: {
            target: "ScreenShare",
            actions: ["ScreenShareConnected"],
          },
          CALLEEOFFLINE: {
            target: "Idle",
            actions: ["DiallingToIdle"],
          },
          SCREENSHAREERROR:{
            target: "Idle",
            actions: ["ScreenShareRejected"],
          },
          ERROR: {
            target: "Idle",
            actions: ["onError"],
          },
        },
      },
      Conversation: {
        entry: "onEntryConversation",
        on: {
          INITIALIZING: {
            target: "Idle",
            actions: ["ResetEngine"],
          },
          HANGUP: {
            target: "Idle",
            // transition actions
            actions: ["ConversationToIdle"],
          },
          REMOTEHANGUP: {
            target: "Idle",
            // transition actions
            actions: ["RemoteHangup"],
          },
          HOLD: {
            target: "Hold",
            // transition actions
            actions: ["ConversationToHold"],
          },
          STREAMCHANGE: {
            target: "Conference",
            actions: ["ConversationToConference"],
          },
          STREAMCHANGETOVIDEO: {
            target: "Conversation",
            actions: ["ConversationToConference"],
          },
          SCREENSHARECALL: {
            target: "ScreenShare",
            actions: ["ConversationToScreenShare"],
          },
          SCREENSHARECALLSTART: {
            target: "ScreenShareRequest",
            actions: ["ConversationToScreenShareStart"],
          },
          SCREENSHARECALLREMOTEHANGUP: {
            target: "Conversation",
            actions: ["ScreenShareCallRemoteHangup"],
          },
          SCREENSHARCALLEERROR: {
            actions: ["ScreenShareCallError"],
          },
          SCREENSHAREERROR: {
            target: "Idle",
            actions: ["ScreenShareError"],
          },
          SCREENSHARECONNECTED: {
            target: "ScreenShare",
            actions: ["ScreenShareConnected"],
          },
          ONSCREENSHAREREQUEST: {
            target: "ScreenShareRequest",
            actions: ["ConversationToScreenShareRequest"],
          },
          REMOTEMUTEUNMUTE: {
            actions: ["MuteStateChange"],
          },
          USERJOINING: {
            actions: ["ConferenceToUserJoin"],
          },
          ERROR: {
            target: "Idle",
            actions: ["onError"],
          },
        },
      },
      ScreenShare: {
        entry: "onEntryScreenShare",
        on: {
          INITIALIZING: {
            target: "Idle",
            actions: ["ResetEngine"],
          },
          HANGUP: {
            target: "Idle",
            actions: ["ScreenShareToIdle"],
          },
          CALLEEOFFLINE: {
            target: "Idle",
            actions: ["DiallingToIdle"],
          },
          SCREENSHAREHANGUP: {
            target: "Idle",
            actions: ["ScreenShareHangup"],
          },
          SCREENSHAREREMOTEHANGUP: {
            target: "Idle",
            actions: ["ScreenShareRemoteHangup"],
          },
          SCREENSHARECALLHANGUP: {
            //target: "Conversation",
            actions: ["ScreenShareHangup"],
          },
          SCREENSHARECALLREMOTEHANGUP: {
            target: "Conversation",
            actions: ["ScreenShareCallRemoteHangup"],
          },
          REMOTEHANGUP: {
            target: "Idle",
            actions: ["RemoteHangup"],
          },
          STREAMCHANGE: {
            actions: ["ConversationToConference"],
          },
          SCREENSHARCALLEERROR: {
            target: "Conversation",
            actions: ["ScreenShareCallError"],
          },
          SCREENSHAREERROR: {
            target: "Idle",
            actions: ["ScreenShareError"],
          },
          REMOTEMUTEUNMUTE: {
            actions: ["MuteStateChange"],
          },
          REMOTEREJECTED: {
            target: "Idle",
            actions: ["DiallingToIdle"],
          },
          USERJOINING: {
            actions: ["ConferenceToUserJoin"],
          },         
          ERROR: {
            target: "Idle",
            actions: ["onError"],
          },
        },
      },
      ScreenShareRequest: {
        entry: "onEntryScreenShare",
        on: {
          reject_screenshare_request: {
            target: "Idle",
          },
          ACCEPT: {
            target: "ScreenShare",
            actions: ["ScreenShareRequestAccepted"],
          },
          REJECTED: {
            target: "Conversation",
            actions: ["ScreenShareRequestRejected"],
          },
          SCREENSHARREQUESTREMOTEREJECT: {
            target: "Conversation",
            actions: ["ScreenShareCallError"],
          },
          INCOMINGCALL: {
            target: "Ringing",
            actions: ["idleToRinging"],
          },
          SCREENSHARECALL: {
            target: "ScreenShare",
            actions: ["ConversationToScreenShare"],
          },
          SCREENSHARE: {
            target: "ScreenShare",
            actions: ["IdleToScreenShare"],
          },
          SCREENSHARCALLEERROR: {
            target: "Conversation",
            actions: ["ScreenShareCallError"],
          },
          ONSCREENSHAREREQUEST: {            
            actions: ["ConversationToScreenShareRequest"],// add this to prevent screen share popup cancellation issue. onfail event not fired to UI
          },
          REMOTEHANGUP: {
            target: "Idle",
            actions: ["RemoteHangup"],
          },
          HANGUP: {
            target: "Idle",
            // transition actions
            actions: ["ConferenceToIdle"],
          },
          ERROR: {
            target: "Idle",
            actions: ["onError"],
          },
        },
      },
      Conference: {
        entry: "onEntryConference",
        on: {
          INITIALIZING: {
            target: "Idle",
            actions: ["ResetEngine"],
          },
          REMOTEHANGUP: {
            target: "Idle",
            actions: ["RemoteHangup"],
          },
          HANGUP: {
            target: "Idle",
            // transition actions
            actions: ["ConferenceToIdle"],
          },
          STREAMCHANGE: {
            target: "Conference",
            actions: ["ConferenceToStreamChange"],
          },
          USERJOINING: {
            target: "Conference",
            actions: ["ConferenceToUserJoin"],
          },
          USERLEFT: {
            target: "Conference",
            actions: ["ConferenceToUserLeft"],
          },
          SCREENSHARECALL: {
            target: "ScreenShare",
            actions: ["ConversationToScreenShare"],
          },
          SCREENSHARECALLSTART: {
            target: "ScreenShareRequest",
            actions: ["ConversationToScreenShareStart"],
          },
          ONSCREENSHAREREQUEST: {
            actions: ["ConversationToScreenShareRequest"], 
          },
          SIGNALING: {
            actions: ["ConferenceSignaling"],
          },
          SCREENSHARECALLREMOTEHANGUP: {
            target: "Conversation",
            actions: ["ScreenShareCallRemoteHangup"],
          },
          SCREENSHARCALLEERROR: {
            actions: ["ScreenShareCallError"],
          },
          REMOTEMUTEUNMUTE: {
            actions: ["MuteStateChange"],
          },
          ERROR: {
            target: "Idle",
            actions: ["onError"],
          },
        },
      },
      Hold: {
        entry: "onEntryHold",
        on: {
          INITIALIZING: {
            target: "Idle",
            actions: ["ResetEngine"],
          },
          HANGUP: {
            target: "Idle",
            // transition actions
            actions: ["HoldToIdle"],
          },
          REMOTEHANGUP: {
            target: "Idle",
            // transition actions
            actions: ["RemoteHangup"],
          },
          UNHOLD: {
            target: "Conversation",
            // transition actions
            actions: ["HoldToConversation"],
          },
          ERROR: {
            target: "Idle",
            actions: ["onError"],
          },
        },
      },
    },
  },
  {
    actions: {
      Initializing: async (context, stateEvent) => {
        console.log(
          "PhoneEngine",
          "StateEngine",
          "TransitionActions",
          `Initializing: ${JSON.stringify(stateEvent)}`
        );
        Object.values(_actionsEvent).map((fn) => fn(stateEvent));
      },
      ResetEngine: async (context, stateEvent) => {
        console.log(
          "PhoneEngine",
          "StateEngine",
          "TransitionActions",
          `ResetEngine: ${JSON.stringify(stateEvent)}`
        );
        Object.values(_actionsEvent).map((fn) => fn(stateEvent));
      },
      idleToDialling: async (context, stateEvent) => {
        console.log(
          "PhoneEngine",
          "StateEngine",
          "TransitionActions",
          `idleToDialling: ${JSON.stringify(stateEvent)}`
        );
        await phoneSdk.phoneFunctions.Dial(
          stateEvent.caller,
          stateEvent.callee,
          stateEvent.callType, stateEvent.sessionId,stateEvent.data
        );
        Object.values(_actionsEvent).map((fn) => fn(stateEvent));
      },
      idleToRinging: (context, stateEvent) => {
        console.log(
          "PhoneEngine",
          "StateEngine",
          "TransitionActions",
          `idleToRinging: ${JSON.stringify(stateEvent)}`
        );
        Object.values(_actionsEvent).map((fn) => fn(stateEvent));
      },
      idleToOffline: (context, stateEvent) => {
        console.log(
          "PhoneEngine",
          "StateEngine",
          "TransitionActions",
          `idleToOffline: ${JSON.stringify(stateEvent)}`
        );
        Object.values(_actionsEvent).map((fn) => fn(stateEvent));
      },

      DiallingToIdleHangup: async (context, stateEvent) => {
        console.log(
          "PhoneEngine",
          "StateEngine",
          "TransitionActions",
          `DiallingToIdleHangup: ${JSON.stringify(stateEvent)}`
        );
        await phoneSdk.phoneFunctions.hangup(
          stateEvent.caller,
          stateEvent.callee
        );
        Object.values(_actionsEvent).map((fn) => fn(stateEvent));
      },
      RingingToIdleHangup: async (context, stateEvent) => {
        console.log(
          "PhoneEngine",
          "StateEngine",
          "TransitionActions",
          `RingingToIdleHangup: ${JSON.stringify(stateEvent)}`
        );
        await phoneSdk.phoneFunctions.hangup(
          stateEvent.caller,
          stateEvent.callee
        );
        Object.values(_actionsEvent).map((fn) => fn(stateEvent));
      },
      DiallingToIdle: (context, stateEvent) => {
        console.log(
          "PhoneEngine",
          "StateEngine",
          "TransitionActions",
          `DiallingToIdle: ${JSON.stringify(stateEvent)}`
        );
        Object.values(_actionsEvent).map((fn) => fn(stateEvent));
      },
      DiallingToRinging: (context, stateEvent) => {
        console.log(
          "PhoneEngine",
          "StateEngine",
          "TransitionActions",
          `DiallingToRinging: ${JSON.stringify(stateEvent)}`
        );
        Object.values(_actionsEvent).map((fn) => fn(stateEvent));
      },
      ScreenShareRejected: (context, stateEvent) => {
        console.log(
          "PhoneEngine",
          "StateEngine",
          "TransitionActions",
          `ScreenShareRejected: ${JSON.stringify(stateEvent)}`
        );
        Object.values(_actionsEvent).map((fn) => fn(stateEvent));
      },
      RingingToIdle: (context, stateEvent) => {
        console.log(
          "PhoneEngine",
          "StateEngine",
          "TransitionActions",
          `RingingToIdle: ${JSON.stringify(stateEvent)}`
        );
        Object.values(_actionsEvent).map((fn) => fn(stateEvent));
      },
      RingingRejectedToIdle: async (context, stateEvent) => {
        console.log(
          "PhoneEngine",
          "StateEngine",
          "TransitionActions",
          `RingingRejectedToIdle: ${JSON.stringify(stateEvent)}`
        );
        await phoneSdk.phoneFunctions.rejectCall(
          stateEvent.caller,
          stateEvent.callee
        );
        Object.values(_actionsEvent).map((fn) => fn(stateEvent));
      },
      AnsweringToIdle: async (context, stateEvent) => {
        console.log(
          "PhoneEngine",
          "StateEngine",
          "TransitionActions",
          `AnsweringToIdle: ${JSON.stringify(stateEvent)}`
        );
        await phoneSdk.phoneFunctions.hangup(
          stateEvent.caller,
          stateEvent.callee
        );
        Object.values(_actionsEvent).map((fn) => fn(stateEvent));
      },
      RingingToAnswering: async (context, stateEvent) => {
        console.log(
          "PhoneEngine",
          "StateEngine",
          "TransitionActions",
          `RingingToAnswering: ${JSON.stringify(stateEvent)}`
        );
        await phoneSdk.phoneFunctions.answer();
        Object.values(_actionsEvent).map((fn) => fn(stateEvent));
      },
      RingingToConversation: (context, stateEvent) => {
        console.log(
          "PhoneEngine",
          "StateEngine",
          "TransitionActions",
          `RingingToConversation: ${JSON.stringify(stateEvent)}`
        );
        Object.values(_actionsEvent).map((fn) => fn(stateEvent));
      },
      ConversationToScreenShareRequest : async (context, stateEvent) => {
        console.log(
          "PhoneEngine",
          "StateEngine",
          "TransitionActions",
          `ConversationToScreenShareRequest: ${JSON.stringify(stateEvent)}`
        );        
        Object.values(_actionsEvent).map((fn) => fn(stateEvent));
      },
      ConversationToIdle: async (context, stateEvent) => {
        console.log(
          "PhoneEngine",
          "StateEngine",
          "TransitionActions",
          `ConversationToIdle: ${JSON.stringify(stateEvent)}`
        );
        await phoneSdk.phoneFunctions.hangup(
          stateEvent.caller,
          stateEvent.callee
        );
        Object.values(_actionsEvent).map((fn) => fn(stateEvent));
      },
      ScreenShareToIdle: async (context, stateEvent) => {
        console.log(
          "PhoneEngine",
          "StateEngine",
          "TransitionActions",
          `ScreenShareToIdle: ${JSON.stringify(stateEvent)}`
        );
        await phoneSdk.phoneFunctions.hangup(
          stateEvent.caller,
          stateEvent.callee
        );
        Object.values(_actionsEvent).map((fn) => fn(stateEvent));
      },
      ScreenShareHangup: async (context, stateEvent) => {
        console.log(
          "PhoneEngine",
          "StateEngine",
          "TransitionActions",
          `ScreenShareHangup: ${JSON.stringify(stateEvent)}`
        );
        await phoneSdk.phoneFunctions.stopScreenSharing(
          stateEvent.uid,
          stateEvent.withCall
        );
        Object.values(_actionsEvent).map((fn) => fn(stateEvent));
      },
      ScreenShareRemoteHangup: async (context, stateEvent) => {
        console.log(
          "PhoneEngine",
          "StateEngine",
          "TransitionActions",
          `ScreenShareRemoteHangup: ${JSON.stringify(stateEvent)}`
        );
        Object.values(_actionsEvent).map((fn) => fn(stateEvent));
      },
      ScreenShareCallRemoteHangup: async (context, stateEvent) => {
        console.log(
          "PhoneEngine",
          "StateEngine",
          "TransitionActions",
          `ScreenShareCallRemoteHangup: ${JSON.stringify(stateEvent)}`
        );
        Object.values(_actionsEvent).map((fn) => fn(stateEvent));
      },

      ScreenShareCallError: async (context, stateEvent) => {
        console.log(
          "PhoneEngine",
          "StateEngine",
          "TransitionActions",
          `ScreenShareCallError: ${JSON.stringify(stateEvent)}`
        );
        Object.values(_actionsEvent).map((fn) => fn(stateEvent));
      },
      ScreenShareRequestAccepted: async (context, stateEvent) => {
        console.log(
          "PhoneEngine",
          "StateEngine",
          "TransitionActions",
          `ScreenShareRequestAccepted: ${JSON.stringify(stateEvent)}`
        );
        await phoneSdk.phoneFunctions.acceptScreenShareRequest(stateEvent.callee);
      },
      ScreenShareRequestRejected: async (context, stateEvent) => {
        console.log(
          "PhoneEngine",
          "StateEngine",
          "TransitionActions",
          `ScreenShareRequestRejected: ${JSON.stringify(stateEvent)}`
        );
        await phoneSdk.phoneFunctions.rejectScreenShareRequest(stateEvent.callee);
      },
      ScreenShareError: async (context, stateEvent) => {
        console.log(
          "PhoneEngine",
          "StateEngine",
          "TransitionActions",
          `ScreenShareError: ${JSON.stringify(stateEvent)}`
        );
        Object.values(_actionsEvent).map((fn) => fn(stateEvent));
      },
      MuteStateChange: async (context, stateEvent) => {
        console.log(
          "PhoneEngine",
          "StateEngine",
          "TransitionActions",
          `MuteStateChange: ${JSON.stringify(stateEvent)}`
        );
        Object.values(_actionsEvent).map((fn) => fn(stateEvent));
      },

      RemoteHangup: async (context, stateEvent) => {
        console.log(
          "PhoneEngine",
          "StateEngine",
          "TransitionActions",
          `RemoteHangup: ${JSON.stringify(stateEvent)}`
        );
        Object.values(_actionsEvent).map((fn) => fn(stateEvent));
      },
      ConversationToHold: (context, stateEvent) => {
        console.log(
          "PhoneEngine",
          "StateEngine",
          "TransitionActions",
          `ConversationToHold: ${JSON.stringify(stateEvent)}`
        );
        Object.values(_actionsEvent).map((fn) => fn(stateEvent));
      },
      HoldToIdle: async (context, stateEvent) => {
        console.log(
          "PhoneEngine",
          "StateEngine",
          "TransitionActions",
          `HoldToIdle: ${JSON.stringify(stateEvent)}`
        );
        await phoneSdk.phoneFunctions.hangup(
          stateEvent.caller,
          stateEvent.callee
        );
        Object.values(_actionsEvent).map((fn) => fn(stateEvent));
      },
      HoldToConversation: (context, stateEvent) => {
        console.log(
          "PhoneEngine",
          "StateEngine",
          "TransitionActions",
          `HoldToConversation: ${JSON.stringify(stateEvent)}`
        );
        Object.values(_actionsEvent).map((fn) => fn(stateEvent));
      },
      ConversationToConference: (context, stateEvent) => {
        console.log(
          "PhoneEngine",
          "StateEngine",
          "TransitionActions",
          `ConversationToConference: ${JSON.stringify(stateEvent)}`
        );
        Object.values(_actionsEvent).map((fn) => fn(stateEvent));
      },
      IdleToScreenShare: async (context, stateEvent) => {
        console.log(
          "PhoneEngine",
          "StateEngine",
          "TransitionActions",
          `IdleToScreenShare: ${JSON.stringify(stateEvent)}`
        );
        await phoneSdk.phoneFunctions.startScreenSharing(
          stateEvent.uid,
          stateEvent.withCall,stateEvent.sessionId
        );
        Object.values(_actionsEvent).map((fn) => fn(stateEvent));
      },
      ScreenShareConnected: async (context, stateEvent) => {
        console.log(
          "PhoneEngine",
          "StateEngine",
          "TransitionActions",
          `ScreenShareConnected: ${JSON.stringify(stateEvent)}`
        );
        Object.values(_actionsEvent).map((fn) => fn(stateEvent));
      },
      ConversationToScreenShare: async (context, stateEvent) => {
        console.log(
          "PhoneEngine",
          "StateEngine",
          "TransitionActions",
          `ConversationToScreenShare: ${JSON.stringify(stateEvent)}`
        );
        Object.values(_actionsEvent).map((fn) => fn(stateEvent));
      },
      ScreenShareConCall: async (context, stateEvent) => {
        console.log(
          "PhoneEngine",
          "StateEngine",
          "TransitionActions",
          `ScreenShareConCall: ${JSON.stringify(stateEvent)}`
        );
        stateEvent.isConCall = true;
        Object.values(_actionsEvent).map((fn) => fn(stateEvent));
      },
      ConversationToScreenShareStart: async (context, stateEvent) => {
        console.log(
          "PhoneEngine",
          "StateEngine",
          "TransitionActions",
          `ConversationToScreenShareStart: ${JSON.stringify(stateEvent)}`
        );

        await phoneSdk.phoneFunctions.startScreenSharing(
          stateEvent.uid,
          stateEvent.withCall,stateEvent.sessionId
        );
        Object.values(_actionsEvent).map((fn) => fn(stateEvent));
      },
      ConferenceToStreamChange: (context, stateEvent) => {
        console.log(
          "PhoneEngine",
          "StateEngine",
          "TransitionActions",
          `ConferenceToStreamChange: ${JSON.stringify(stateEvent)}`
        );
        Object.values(_actionsEvent).map((fn) => fn(stateEvent));
      },
      ConferenceToUserJoin: (context, stateEvent) => {
        console.log(
          "PhoneEngine",
          "StateEngine",
          "TransitionActions",
          `ConferenceToUserJoin: ${JSON.stringify(stateEvent)}`
        );
        Object.values(_actionsEvent).map((fn) => fn(stateEvent));
      },
      ConferenceToUserLeft: (context, stateEvent) => {
        console.log(
          "PhoneEngine",
          "StateEngine",
          "TransitionActions",
          `ConferenceToUserLeft: ${JSON.stringify(stateEvent)}`
        );
        Object.values(_actionsEvent).map((fn) => fn(stateEvent));
      },
      ConferenceSignaling: (context, stateEvent) => {
        console.log(
          "PhoneEngine",
          "StateEngine",
          "TransitionActions",
          `ConferenceSignaling: ${JSON.stringify(stateEvent)}`
        );
        Object.values(_actionsEvent).map((fn) => fn(stateEvent));
      },

      onEntryOffline: (context, stateEvent) => {
        console.log(
          "PhoneEngine",
          "StateEngine",
          "TransitionActions",
          `onEntryOffline: ${JSON.stringify(stateEvent)}`
        );
        Object.values(_entryEvent).map((fn) => fn("Offline", stateEvent));
      },
      onEntryIdle: (context, stateEvent) => {
        console.log(
          "PhoneEngine",
          "StateEngine",
          "TransitionActions",
          `onEntryIdle: ${JSON.stringify(stateEvent)}`
        );
        Object.values(_entryEvent).map((fn) => fn("Idle", stateEvent));
        myViewStream = null;
        console.groupEnd();
      },
      onEntryDialling: (context, stateEvent) => {
        console.log(
          "PhoneEngine",
          "StateEngine",
          "TransitionActions",
          `onEntryDialling: ${JSON.stringify(stateEvent)}`
        );
        Object.values(_entryEvent).map((fn) => fn("Dialling", stateEvent));
      },
      onEntryRinging: (context, stateEvent) => {
        console.log(
          "PhoneEngine",
          "StateEngine",
          "TransitionActions",
          `onEntryRinging: ${JSON.stringify(stateEvent)}`
        );
        Object.values(_entryEvent).map((fn) => fn("Ringing", stateEvent));
      },
      onEntryAnswering: (context, stateEvent) => {
        console.log(
          "PhoneEngine",
          "StateEngine",
          "TransitionActions",
          `onEntryAnswering: ${JSON.stringify(stateEvent)}`
        );
        Object.values(_entryEvent).map((fn) => fn("Ringing", stateEvent));
      },
      onEntryConversation: (context, stateEvent) => {
        console.log(
          "PhoneEngine",
          "StateEngine",
          "TransitionActions",
          `onEntryConversation: ${JSON.stringify(stateEvent)}`
        );
        Object.values(_entryEvent).map((fn) => fn("Conversation", stateEvent));
      },
      onEntryScreenShare: (context, stateEvent) => {
        console.log(
          "PhoneEngine",
          "StateEngine",
          "TransitionActions",
          `onEntryScreenShare: ${JSON.stringify(stateEvent)}`
        );
        Object.values(_entryEvent).map((fn) => fn("ScreenShare", stateEvent));
      },
      onEntryHold: (context, stateEvent) => {
        console.log(
          "PhoneEngine",
          "StateEngine",
          "TransitionActions",
          `onEntryHold: ${JSON.stringify(stateEvent)}`
        );
        Object.values(_entryEvent).map((fn) => fn("Hold", stateEvent));
      },
      ConferenceToIdle: async (context, stateEvent) => {
        console.log(
          "PhoneEngine",
          "StateEngine",
          "TransitionActions",
          `ConferenceToIdle: ${JSON.stringify(stateEvent)}`
        );
        await phoneSdk.phoneFunctions.hangup(
          stateEvent.caller,
          stateEvent.callee
        );
        Object.values(_entryEvent).map((fn) => fn("Idle", stateEvent));
      },
      SendScreenShareRequest: async (context, stateEvent) => {
        console.log(
          "PhoneEngine",
          "StateEngine",
          "SendScreenShareRequest",
          `onError: ${JSON.stringify(stateEvent)}`
        );
      },
      onError: (context, stateEvent) => {
        console.log(
          "PhoneEngine",
          "StateEngine",
          "TransitionActions",
          `onError: ${JSON.stringify(stateEvent)}`
        );
        Object.values(_entryEvent).map((fn) => fn("Error", stateEvent));
      },
    },
  }
);

/* #endregion  ---------------------------- State Engine --------------------------------- */

class StateEngine {

  _service = interpret(toggleMachine).onTransition((current) => {
    /* console.info(
      "%c Current State :--------------------------------------  [%s]  ------------------------------------------------- : ",
      "background: #222; color: #FF00FF",
      current.value
    ); */

    console.log(
      "%c PhoneEngine StateEngine Current State :--------------------------------------  [%s]  ------------------------------------------------- : ",
      "color:#FF00FF; font-family:'Ubuntu'; display: block;font-weight:bold; font-size:14px;background: #222;",
      current.value
    );
  });

  constructor() {
    _ui_se_session_id = uuid();
    console.log(
      "PhoneEngine",
      "startStateEngine",`ui_session_id : ${_ui_se_session_id}`,
      `generate state engine session id : ${_ui_se_session_id}`,"------------------- State Engine Started ---------------------------- "
    );
  }

  startStateEngine = () => {
    this._service.start();
    console.log(
      "PhoneEngine",
      "startStateEngine",
      `ui_session_id : ${_ui_se_session_id}`,"------------------- State Engine Started ---------------------------- "
    );
  };

  phone = {
    initialize: (bandwidth, phoneConfig) => {
      console.log("PhoneEngine" , "initialize","start phone initialization",`ui_session_id : ${_ui_se_session_id}` , phoneConfig);
      this.startStateEngine();
      let callEvents = {
        OnRemoteAnswer: (remoteUserInfo, localStreams, remoteStreams) => {
          console.log(
            "PhoneEngine",
            "Callback",
            "OnRemoteAnswer",
            `OnRemoteAnswer`,`ui_session_id : ${_ui_se_session_id}`
          );
          this._service.send("REMOTEANSWER", {
            localStreams,
            remoteStreams,
            remoteUserInfo,
          });
        },
        RemoteHangup: (reasonCode) => {
          console.log(
            "PhoneEngine",
            "Callback",
            "RemoteHangup",
            `RemoteHangup`,`ui_session_id : ${_ui_se_session_id}`
          );
          this._service.send("REMOTEHANGUP", { reasonCode });
        },
        AnsweredIncomingCall: (
          isAnswered,
          localStreams,
          remoteStreams,
          remoteUserInfo
        ) => {
          console.log(
            "PhoneEngine",
            "Callback",
            "AnsweredIncomingCall",
            `AnsweredIncomingCall`,`ui_session_id : ${_ui_se_session_id}`
          );
          if (isAnswered) {
            this._service.send("ANSWERED", {
              localStreams,
              remoteStreams,
              remoteUserInfo,
            });
          } else {
            this._service.send("TIMEOUT");
          }
        },
        OnIncomingCall: (stateEvent) => {
          try {
            console.log(
              "PhoneEngine",
              "Callback",
              "OnIncomingCall",
              `OnIncomingCall`,`ui_session_id : ${_ui_se_session_id}`
            );
           
            this._service.send("INCOMINGCALL", {
              callee: stateEvent.callee,
              sessionId: stateEvent.session_id,
              callType: stateEvent.callType,
            });
          } catch (error) {
            this._service.send("TIMEOUT");
            console.error("PhoneEngine","OnIncomingCall",`ui_session_id : ${_ui_se_session_id}`, error);
            logger.error("PhoneEngine","OnIncomingCall", error.message);
          }
        },
        onScreenShareRequest: (stateEvent) => {
          try {
            console.log(
              "PhoneEngine",
              "Callback",
              "onScreenShareRequest",
              `onScreenShareRequest`,`ui_session_id : ${_ui_se_session_id}`
            );
           
            this._service.send("ONSCREENSHAREREQUEST", {
              callee: stateEvent.callee,
              sessionId: stateEvent.sessionId,
              callType: stateEvent.callType,
            });
          } catch (error) {
            this._service.send("TIMEOUT");
            console.error("PhoneEngine","onScreenShareRequest",`ui_session_id : ${_ui_se_session_id}`, error);
            logger.error("PhoneEngine","onScreenShareRequest", error.message);
          }
        },
        OnRemoteRinging: () => {
          console.log(
            "PhoneEngine",
            "Callback",
            "OnRemoteRinging",
            `OnRemoteRinging`,`ui_session_id : ${_ui_se_session_id}`
          );
          this._service.send("REMOTERINGING");
        },
        OnDialing: (s) => {
          console.log("PhoneEngine", "Callback", "OnDialing", `OnDialing`,`ui_session_id : ${_ui_se_session_id}`);
          Object.values(_session).map((fn) => fn(s));
        },
        onCalleeOffline: () => {
          console.log(
            "PhoneEngine",
            "Callback",
            "onCalleeOffline",
            `onCalleeOffline`,`ui_session_id : ${_ui_se_session_id}`
          );
          this._service.send("CALLEEOFFLINE");
        },
        OnCallNotAnswered: () => {
          console.log(
            "PhoneEngine",
            "Callback",
            "OnCallNotAnswered",
            `OnCallNotAnswered`,`ui_session_id : ${_ui_se_session_id}`
          );
          this._service.send("TIMEOUT");
        },
        OnCallRemoteReject: () => {
          console.log(
            "PhoneEngine",
            "Callback",
            "OnCallRemoteReject",
            `OnCallRemoteReject`,`ui_session_id : ${_ui_se_session_id}`
          );
          this._service.send("REMOTEREJECTED");
        },
        onConnectionError: (reasonCode, callId) => {
          console.log(
            "PhoneEngine",
            "Callback",
            "onConnectionError",
            `onConnectionError reasonCode:${reasonCode}, callId:${callId}`,`ui_session_id : ${_ui_se_session_id}`
          );
          this._service.send("ERROR", { reasonCode, callId });
        },
        onError: (reasonCode, callId) => {
          console.log(
            "PhoneEngine",
            "Callback",
            "onError",
            `reason:${reasonCode}, callId:${callId}`,`ui_session_id : ${_ui_se_session_id}`
          );
          this._service.send("ERROR", { reasonCode, callId });
          /*  setTimeout(() => {
            this._service.send("ERROR");
            Object.values(_onError).map((fn) => fn(reason, callId));
          }, 1000); */
        },
        onSlowLink: (e) => {
          //console.log("PhoneEngine", "Callback", "onSlowLink", `onSlowLink`);
          Object.values(_networkBandwidth).map((fn) => fn(e));
        },
        OnOfferReply: (e) => {
          console.log(
            "PhoneEngine",
            "Callback",
            "OnOfferReply",
            `OnOfferReply`,`ui_session_id : ${_ui_se_session_id}`
          );
          Object.values(_session).map((fn) =>
            fn({ session: e, stateEvent: "OnOfferReply" })
          );
        },
        OnAnswerAcceptedReply: (e) => {
          console.log(
            "PhoneEngine",
            "Callback",
            "OnAnswerAcceptedReply",
            `OnAnswerAcceptedReply`,`ui_session_id : ${_ui_se_session_id}`
          );
          Object.values(_session).map((fn) =>
            fn({ session: e, stateEvent: "OnAnswerAcceptedReply" })
          );
        },
        OnCancelled: (e) => {
          console.log("PhoneEngine", "Callback", "OnCancelled", `OnCancelled`,`ui_session_id : ${_ui_se_session_id}`);
          this._service.send("REMOTEHANGUP");
        },
        OnUserJoining: (userId, callId) => {
          console.log(
            "PhoneEngine",
            "Callback",
            "OnUserJoining",
            `OnUserJoining`,`ui_session_id : ${_ui_se_session_id}`
          );
          this._service.send("USERJOINING", { userId });
        },
        OnUserLeft: (userId, callId) => {
          console.log("PhoneEngine", "Callback", "OnUserLeft", `OnUserLeft`,`ui_session_id : ${_ui_se_session_id}`);
          this._service.send("USERLEFT", { userId });
        },
        OnStreamsChanged: (
          remoteStreams,
          localStreams,
          remoteUserInfo,
          callId,
          upgradeToVideo
        ) => {
          console.log(
            "PhoneEngine",
            "Callback",
            "OnStreamsChanged",
            `OnStreamsChanged`,`ui_session_id : ${_ui_se_session_id}`
          );
          this._service.send(
            upgradeToVideo ? "STREAMCHANGETOVIDEO" : "STREAMCHANGE",
            {
              remoteStreams,
              localStreams,
              remoteUserInfo,
              callId,
            }
          );
        },
        OnScreenShareConnected: (remoteStreams, remoteUserInfo, callId) => {
          console.log(
            "PhoneEngine",
            "Callback",
            "OnScreenShareConnected",
            `OnScreenShareConnected`,`ui_session_id : ${_ui_se_session_id}`
          );
          this._service.send("SCREENSHARECONNECTED", {
            remoteStreams,
            remoteUserInfo,
            callId,
          });
        },
        OnScreenShareCallConnected: (remoteStreams, remoteUserInfo, callId) => {
          console.log(
            "PhoneEngine",
            "Callback",
            "OnScreenShareCallConnected",
            `OnScreenShareCallConnected`,`ui_session_id : ${_ui_se_session_id}`
          );
          this._service.send("SCREENSHARECALL", {
            remoteStreams,
            remoteUserInfo,
            callId,
          });
        },
        OnScreenShareDisconnected: () => {
          console.log(
            "PhoneEngine",
            "Callback",
            "OnScreenShareDisconnected",
            `OnScreenShareDisconnected`,`ui_session_id : ${_ui_se_session_id}`
          );
          this._service.send("SCREENSHAREREMOTEHANGUP");
        },
        OnScreenShareCallDisconnected: () => {
          console.log(
            "PhoneEngine",
            "Callback",
            "OnScreenShareCallDisconnected",
            `OnScreenShareCallDisconnected`,`ui_session_id : ${_ui_se_session_id}`
          );
          this._service.send("SCREENSHARECALLREMOTEHANGUP");
        },
        OnUpgradeToVideo: (stateEvent) => {
          console.log(
            "PhoneEngine",
            "Callback",
            "OnUpgradeToVideo",
            `OnUpgradeToVideo`,`ui_session_id : ${_ui_se_session_id}`
          );
          Object.values(_actionsEvent).map((fn) =>
            fn({ type: "UPGRADETOVIDEO" })
          );
        },
        OnVoiceActivity: (userId, previousUserId) => {
          /* console.log(
            "PhoneEngine",
            "Callback",
            "OnVoiceActivity",
            `OnVoiceActivity`
          ); */
          Object.values(_actionsEvent).map((fn) =>
            fn({ type: "VOICEACTIVITY", userId: userId, previousUserId })
          );
        },
        OnSignalingCallEvents: (command, userId) => {
          try {
            /* console.log(
              "PhoneEngine",
              "Callback",
              "OnSignalingCallEvents",
              `OnSignalingCallEvents. command:${command}, userId:${userId}`
            ); */
            this._service.send("SIGNALING", { command, userId });
          } catch (error) {
            console.error(
              "PhoneEngine",
              "Callback",
              "OnSignalingCallEvents",
              `OnSignalingCallEvents. command:${command}, userId:${userId}`,
              error
            );
            logger.error("PhoneEngine","OnSignalingCallEvents",`OnSignalingCallEvents. command:${command}, userId:${userId}, message: ${ error.message}`);
          }
        },
        onScreenShareError: (withCall, errorCode, callId) => {
          try {
            console.log(
              "PhoneEngine",
              "Callback",
              "onScreenShareError",
              `onScreenShareError.withCall:${withCall}, errorCode:${errorCode}, callId:${callId}`,`ui_session_id : ${_ui_se_session_id}`
            );
            this._service.send(
              withCall ? "SCREENSHARCALLEERROR" : "SCREENSHAREERROR",
              { withCall, callId, errorCode }
            );
          } catch (error) {
            console.error(
              "PhoneEngine",
              "Callback",
              "onScreenShareError",
              `onScreenShareError.withCall:${withCall}, errorCode:${errorCode}, callId:${callId}`,`ui_session_id : ${_ui_se_session_id}`,
              error
            );
            logger.error("PhoneEngine","onScreenShareError",`onScreenShareError.withCall:${withCall}, errorCode:${errorCode}, callId:${callId}, message: ${ error.message}`);
          }
        },
        onScreenShareRequestRejected: (withCall, errorCode, callId) => {
          try {
            console.log(
              "PhoneEngine",
              "Callback",
              "onScreenShareRequestRejected",
              `onScreenShareRequestRejected.withCall:${withCall}, errorCode:${errorCode}, callId:${callId}`,`ui_session_id : ${_ui_se_session_id}`
            );
            this._service.send( "SCREENSHARREQUESTREMOTEREJECT" , { withCall, callId, errorCode } );
          } catch (error) {
            console.error(
              "PhoneEngine",
              "Callback",
              "onScreenShareRequestRejected",
              `onScreenShareRequestRejected.withCall:${withCall}, errorCode:${errorCode}, callId:${callId}`,`ui_session_id : ${_ui_se_session_id}`,
              error
            );
            logger.error("PhoneEngine","onScreenShareRequestRejected",`onScreenShareRequestRejected.withCall:${withCall}, errorCode:${errorCode}, callId:${callId}, message: ${ error.message}`);
          }
        },
        OnRemoteMuteUnmute: (isMuted, userId, callType, callId) => {
          console.log(
            "PhoneEngine",
            "Callback",
            "OnRemoteMuteUnmute",
            `isMuted:${isMuted}, userId:${userId},callType:${callType},callId:${callId}`,`ui_session_id : ${_ui_se_session_id}`
          );
          this._service.send("REMOTEMUTEUNMUTE", {
            isMuted,
            userId,
            callType,
            callId,
          });
        },
        onSessionExceededError: (message) => {
          console.log(
            "PhoneEngine",
            "Callback",
            "onSessionExceededError",
            `msg:${message}`,`ui_session_id : ${_ui_se_session_id}`
          );
          //this._service.send("SESSIONEXCEEDED", {message});
          Object.values(_session).map((fn) =>
            fn({ stateEvent: "SESSIONEXCEEDED", message })
          );
        },
        onDeviceChange: (devices) => {
          console.log(
            "PhoneEngine",
            "Callback",
            "onDeviceChange",`ui_session_id : ${_ui_se_session_id}`,
          );
          Object.values(_onDeviceChange).map((fn) => fn(devices));
        },
      };
      const socketEvents = {
        success: () => {
          console.log("PhoneEngine","socketEvents","on websocket Connection succeeded",`ui_session_id : ${_ui_se_session_id}`);
        },
        open: () => {
          console.log("PhoneEngine","socketEvents","on socket open",`ui_session_id : ${_ui_se_session_id}`);
        },
        connect_error: () => {
          console.error("PhoneEngine","socketEvents","on socket connect_error",`ui_session_id : ${_ui_se_session_id}`);
          logger.error("PhoneEngine","socketEvents",`on socket connect_error`);
        },
        connect_timeout: () => {
          console.error("PhoneEngine","socketEvents","on socket connect_timeout",`ui_session_id : ${_ui_se_session_id}`);
          logger.error("PhoneEngine","socketEvents",`on socket connect_timeout`);
        },
        reconnect: () => {
          console.error("PhoneEngine","socketEvents","on socket reconnect",`ui_session_id : ${_ui_se_session_id}`);
          logger.error("PhoneEngine","socketEvents",`on socket reconnect`);
        },
        disconnect: () => {
          console.error("PhoneEngine","socketEvents","on socket disconnect",`ui_session_id : ${_ui_se_session_id}`);
          logger.error("PhoneEngine","socketEvents",`on socket disconnect`);
        },
      };
      const response = phoneSdk.Initialize(
        "",
        "",
        callEvents,
        socketEvents,
        bandwidth,
        phoneConfig
      );
      console.log("PhoneEngine" , "initialize","phone initialization config done","Send INITIALIZING Command to Engine",`ui_session_id : ${_ui_se_session_id}` );    
      this._service.send("INITIALIZING");
      return response;
    },
    uninitialized: () => {
      console.log("PhoneEngine" , "uninitialized",`ui_session_id : ${_ui_se_session_id}`);
      this._service.send("UNINITIALIZED");
    },
    subscribe: {
      onDeviceChange: (name, fn) => {
        try {
          _onDeviceChange[name] = fn;
        } catch (error) {
          console.error("PhoneEngine","onDeviceChange",`ui_session_id : ${_ui_se_session_id}`,error);
          logger.error("PhoneEngine","onDeviceChange",error.message);
        }
      },
      networkBandwidth: (name, fn) => {
        try {
          _networkBandwidth[name] = fn;
        } catch (error) {
          console.error("PhoneEngine","networkBandwidth",`ui_session_id : ${_ui_se_session_id}`,error);
          logger.error("PhoneEngine","networkBandwidth",error.message);
        }
      },
      onError: (name, fn) => {
        try {
          _onError[name] = fn;
        } catch (error) {
          console.error("PhoneEngine","onError",`ui_session_id : ${_ui_se_session_id}`,error);
          logger.error("PhoneEngine","onError",error.message);
        }
      },
      session: (name, fn) => {
        try {
          _session[name] = fn;
        } catch (error) {
          console.error("PhoneEngine","session",`ui_session_id : ${_ui_se_session_id}`,error);
          logger.error("PhoneEngine","session",error.message);
        }
      },
      stateActionsEvents: (name, fn) => {
        try {
          _actionsEvent[name] = fn;
        } catch (error) {
          console.error("PhoneEngine","stateActionsEvents",`ui_session_id : ${_ui_se_session_id}`,error);
          logger.error("PhoneEngine","stateActionsEvents",error.message);
        }
      },
      stateEntryEvents: (name, fn) => {
        try {
          _entryEvent[name] = fn;
        } catch (error) {
          console.error("PhoneEngine","stateEntryEvents",`ui_session_id : ${_ui_se_session_id}`,error);
          logger.error("PhoneEngine","stateEntryEvents",error.message);
        }
      },
    },
  };

  phoneFunctions = {
    CheckDeviceSupport:()=>{
      return phoneSdk.phoneFunctions.CheckDeviceSupport();
    },
    SetInputDevice: (audioSource, videoSource) => {
      phoneSdk.phoneFunctions.SetInputDevice(audioSource, videoSource);
    },
    dial: async (caller, callee, callType,data) => {
      _ui_se_session_id = uuid();
      
      console.group( `%c Outbound Session ID : ${_ui_se_session_id} , dial  ,caller : ${caller} , callee : ${callee} , callType : ${callType}`, "color:#FF00FF; font-family:'Ubuntu'; display: block;font-weight:bold; font-size:18px;background: #40E0D0;" );
      
      console.log(
        "PhoneEngine",
        "phoneFunctions",
        "dial",
        `Outbound Session ID : ${_ui_se_session_id} ,caller : ${caller} , callee : ${callee} , callType : ${callType}`,`ui_session_id : ${_ui_se_session_id}`
      );

      callType = callType ? callType : "AUDIO_CALL";
      this._service.send("STARTCALLING", { caller, callee, callType, sessionId :_ui_se_session_id , data});
    },
    answer: async () => {
      console.log("PhoneEngine", "phoneFunctions", "answer", `answer`,`ui_session_id : ${_ui_se_session_id}`);
      this._service.send("ANSWERING");
    },
    hangup: async (caller, callee) => {
      console.log(
        "PhoneEngine",
        "phoneFunctions",
        "hangup",
        `caller : ${caller} , callee : ${callee} `,`ui_session_id : ${_ui_se_session_id}`
      );
      this._service.send("HANGUP", { caller, callee });
    },
    rejectCall: async (caller, callee) => {
      console.log(
        "PhoneEngine",
        "phoneFunctions",
        "rejectCall",
        `caller : ${caller} , callee : ${callee} `,`ui_session_id : ${_ui_se_session_id}`
      );
      this._service.send("REJECTED", { caller, callee });
    },
    muteUnmuteAudioVideo: async (muteAudio, muteVideo) => {
      console.log(
        "PhoneEngine",
        "phoneFunctions",
        "muteUnmuteAudioVideo",
        `muteAudio : ${muteAudio} , muteVideo : ${muteVideo} `,`ui_session_id : ${_ui_se_session_id}`
      );
      await phoneSdk.phoneFunctions.muteUnmuteAudioVideo(muteAudio, muteVideo);
    },
    holdUnhold: async (state) => {
      console.log(
        "PhoneEngine",
        "phoneFunctions",
        "holdUnhold",
        `state : ${state}`,`ui_session_id : ${_ui_se_session_id}`
      );
      await phoneSdk.phoneFunctions.holdUnhold(state);
    },
    addUserConference: async (uid) => {
      console.log(
        "PhoneEngine",
        "phoneFunctions",
        "addUserConference",
        `uid : ${uid}`,`ui_session_id : ${_ui_se_session_id}`
      );
      await phoneSdk.phoneFunctions.addUserConference(uid);
    },
    sendScreenShareRequest: async (uid) => {
      _ui_se_session_id = uuid();
      console.group( `%c Outbound Session ID : ${_ui_se_session_id} , sendScreenShareRequest  ,uid : ${uid} `, "color:#FF00FF; font-family:'Ubuntu'; display: block;font-weight:bold; font-size:18px;background: #8fbfba;" );
      console.log(
        "PhoneEngine",
        "phoneFunctions",
        "sendScreenShareRequest",
        `uid : ${uid},`,`ui_session_id : ${_ui_se_session_id}`
      );
      this._service.send("SCREENSHAREREQUEST", {
        uid,sessionId:_ui_se_session_id
      });
    },
    // later we have to change this
    setScreenShareRequest: async (uid) => {
      console.log(
        "PhoneEngine",
        "phoneFunctions",
        "sendScreenShareRequest",
        `uid : ${uid},`,`ui_session_id : ${_ui_se_session_id}`
      );
      this._service.send("ONSCREENSHAREREQUEST", {
        uid,
      });
    },
    cancelScreenShareRequest: async (uid) => {
      console.log(
        "PhoneEngine",
        "phoneFunctions",
        "sendScreenShareRequest",
        `uid : ${uid},`,`ui_session_id : ${_ui_se_session_id}`
      );
      this._service.send("reject_screenshare_request", {
        uid,
      });
    },
    acceptScreenShareRequest: async (caller, callee) => {
      console.log(
        "PhoneEngine",
        "phoneFunctions",
        "acceptScreenShareRequest",
        `caller : ${caller} , callee : ${callee} `,`ui_session_id : ${_ui_se_session_id}`
      );
     // this._service.send("ACCEPT", { caller, callee });
     await phoneSdk.phoneFunctions.acceptScreenShareRequest(caller,callee);
    },
    rejectScreenShareRequest: async (caller, callee) => {
      console.log(
        "PhoneEngine",
        "phoneFunctions",
        "rejectScreenShareRequest",
        `caller : ${caller} , callee : ${callee} `,`ui_session_id : ${_ui_se_session_id}`
      );
      this._service.send("REJECTED", { caller, callee });
    },
    startScreenSharing: async (uid, withCall) => {
      if(withCall === false){
        _ui_se_session_id = uuid();
        console.group( `%c Outbound Session ID : ${_ui_se_session_id} , startScreenSharing  ,uid : ${uid} , withCall : ${withCall}`, "color:#FF00FF; font-family:'Ubuntu'; display: block;font-weight:bold; font-size:18px;background: #a2f5ec;" );
      }
      console.log(
        "PhoneEngine",
        "phoneFunctions",
        "startScreenSharing",
        `uid : ${uid}, withCall : ${withCall}`,`ui_session_id : ${_ui_se_session_id}`
      );
      this._service.send(withCall ? "SCREENSHARECALLSTART" : "SCREENSHARE", {
        uid,
        withCall,sessionId:_ui_se_session_id
      });
    },
    stopScreenSharing: async (uid, withCall) => {
      console.log(
        "PhoneEngine",
        "phoneFunctions",
        "stopScreenSharing",
        `uid : ${uid}, withCall : ${withCall}`,`ui_session_id : ${_ui_se_session_id}`
      );
      this._service.send(
        withCall ? "SCREENSHARECALLHANGUP" : "SCREENSHAREHANGUP",
        { uid, withCall }
      );
    },
    upgradeToVideo: async (uid) => {
      console.log(
        "PhoneEngine",
        "phoneFunctions",
        "upgradeToVideo",
        `upgradeToVideo`,`ui_session_id : ${_ui_se_session_id}`
      );
      await phoneSdk.phoneFunctions.upgradeToVideo(uid);
    },
    requestCameraAccess: async () => {
      console.log(
        "PhoneEngine",
        "phoneFunctions",
        "requestCameraAccess",
        `requestCameraAccess`,`ui_session_id : ${_ui_se_session_id}`
      );      
      return await phoneSdk.phoneFunctions.requestCameraAccess();      
    },
    getLocalVideo: async (phoneConfig,backendImage) => {
      console.debug(
        "PhoneEngine",
        "phoneFunctions",
        "selfview",
        `getLocalVideo`
      );
      
      if(myViewStream && myViewStream.active === true){
        console.debug(
          "PhoneEngine",
          "phoneFunctions",
          "selfview",
          `getLocalVideo`,
          `returning same stream ${myViewStream.id}`
        );
        return myViewStream;
      }
      myViewStream = {active :false};
      myViewStream = await phoneSdk.phoneFunctions.getLocalVideo(phoneConfig,backendImage);
      if(!myViewStream){
        console.error("PhoneEngine","phoneFunctions","getLocalVideo","selfview","fail to get local stream. sdk returns null");
        return null;
      }
      console.debug("PhoneEngine","phoneFunctions","getLocalVideo","selfview",`returning new local media stream: ${myViewStream.id}`);
     return  myViewStream
    },
    releaseLocalVideo: async (phoneConfig) => {
      try {
        console.log(
          "PhoneEngine",
          "phoneFunctions",
          "selfview",
          `releaseLocalVideo`
        );        
        if(myViewStream && myViewStream.active === true){
          const id = myViewStream.id;
          await phoneSdk.phoneFunctions.releaseLocalVideo(myViewStream,phoneConfig);
          myViewStream = null;
          console.debug("PhoneEngine","phoneFunctions","releaseLocalVideo","selfview",`release local media stream: ${id}`);
          console.log(`%c [-------- PhoneEngine phoneFunctions releaseLocalVideo release local media stream: ${id} ------- ]`, 'background: #000000; color: #02c4f5');
          
          return true;
        }
        myViewStream = null;
        console.error("PhoneEngine","releaseLocalVideo","selfview","call release local video method without having valid stream");     
      } catch (error) {
        console.error("PhoneEngine","releaseLocalVideo","selfview",error);     
      }
    },
    releaseLocalVideoForcefully : async()=>{
      try {
        myViewStream = null;
      } catch (error) {
        console.error("PhoneEngine","releaseLocalVideoForcefully",error);     
      }
    },
    sendToIdle: async()=>{
      try {
        console.log(
          "PhoneEngine",
          "phoneFunctions",
          "sendToIdle",
        );        
        this._service.send("ERROR");
      } catch (error) {
        console.error("PhoneEngine","sendToIdle",error);     
      }
    },
    resetValues: async()=>{
      await phoneSdk.phoneFunctions.resetValues();
    }
    /* getLocalVideo: async () => {
      console.log(
        "PhoneEngine",
        "phoneFunctions",
        "getLocalVideo",
        `getLocalVideo`,`ui_session_id : ${_ui_se_session_id}`
      );
      if(myViewStream){
        return myViewStream;
      }
      myViewStream = await phoneSdk.phoneFunctions.getLocalVideo();
      if(!myViewStream){
        console.error("PhoneEngine","phoneFunctions","getLocalVideo","fail to get local stream. sdk returns null",`ui_session_id : ${_ui_se_session_id}`);
        return null;
      }
      console.debug("PhoneEngine","phoneFunctions","getLocalVideo",`load local media stream: ${myViewStream.id}`,`ui_session_id : ${_ui_se_session_id}`);
      console.error("PhoneEngine", "binding local video-------------",);
      return  myViewStream
    },
    releaseLocalVideo: async () => {
      console.log(
        "PhoneEngine",
        "phoneFunctions",
        "releaseLocalVideo",
        `releaseLocalVideo`,`ui_session_id : ${_ui_se_session_id}`
      );
      if(myViewStream){
        phoneSdk.phoneFunctions.releaseLocalVideo(myViewStream);
        console.debug("PhoneEngine","phoneFunctions","releaseLocalVideo",`release local media stream: ${myViewStream.id}`,`ui_session_id : ${_ui_se_session_id}`);
        console.log(`%c [-------- PhoneEngine phoneFunctions releaseLocalVideo release local media stream: ${myViewStream.id} ------- ]`, 'background: #000000; color: #02c4f5');
        myViewStream = null;
        return true;
      }
      console.error("PhoneEngine","releaseLocalVideo","call release local video method without having valid stream",`ui_session_id : ${_ui_se_session_id}`);      
    }, */

  };
}

const phoneEngine = new StateEngine();
Object.freeze(phoneEngine);

export default phoneEngine;
