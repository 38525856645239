import React, { useState } from 'react';

import PropTypes from 'prop-types';
import { connect } from "react-redux";
import * as auth from "../../../utils";
import { ButtonRounded, ProfileImage, MenuIcon, SubTitle, RadioButton } from '../../common';
import { Plus, MessageCircle, Calendar, Bell, LogOut, Check, X } from 'react-feather';
import presenceStateMap from "../../../config/json/presenseMap";
import ScrollAnimation from 'react-animate-on-scroll';
import { Popover } from 'antd';
import WorkspaceSwitcher from "../../common/WorkspaceSwitcher/WorkspaceSwitcher";
import {
    Link
} from "react-router-dom";
import navigationRestrictions from "../../../config/navigationRestrictions";
import liveChatClient from "../../../lib";
import { bindActionCreators } from "redux";
import * as presenceActions from "../../../redux/actions/presenceActions";
import * as selectedProfileActions from "../../../redux/actions/selectedProfileActions";
import phoneEngine from "../phone/phoneEngine/phoneEngineHandler";
import './styles/left-navigation.scss';
import './styles/responsive-left-navigation.scss';
import { Scrollbars } from "react-custom-scrollbars";
import AuthService from "../../../service/authService";
import { __APP_IDENTITY_PROVIDER__ } from "../../../config/baseUrls";
import { message } from "antd/lib/index";
import * as logger from "../../../lib/logSdk/logger";

const authService = new AuthService();

let status = "offline";
let myAvailabilityStatus = "offline";

function LeftSideNavigationComponent(props) {

    const [availabilityStatus, setAvailabilityStatus] = useState("offline");

    if (props.presence_user) {
        let tempStatus = props.presence_user.status ? props.presence_user.status : "offline";
        status = presenceStateMap[tempStatus];

        if (!status) {
            status = "offline";
        }
    }


    const setAvailability = status => {
        try {
            //setAvailabilityStatus(status);
            let presenceObj = { userid: auth.getUserId(), status };
            props.presence_actions.sendPresenceSuccess(presenceObj);
            liveChatClient.sendPresenceNotification(JSON.stringify(presenceObj));
        } catch (error) {
            console.error("MobileLeftSideNavigation", "setAvailability", error);
            logger.error("MobileLeftSideNavigation", "setAvailability", error.message);
        }
    };

    if (props.presence_user) {
        let defaultstatus = props.presence_user.status
            ? props.presence_user.status
            : "offline";
        myAvailabilityStatus = presenceStateMap[defaultstatus] ? presenceStateMap[defaultstatus] : defaultstatus;
    }

    const logout = () => {
        try {

            liveChatClient.logoutFromSystem("", "NORMAL", "").then(response => {
                __APP_IDENTITY_PROVIDER__ === 'GUARDIAN' && authService.logout(auth.getRefreshToken(), "NORMAL");
                liveChatClient.endSession();
                phoneEngine.phoneFunctions.hangup("caller", "callee");
                auth.userLogOut();
                setTimeout(() => {

                    if (__APP_IDENTITY_PROVIDER__ === 'IAM') {

                        props.history.push("/logout");

                    } else {
                        props.history.push("/");
                        window.location.reload();
                        console.log("LeftSideNavigationComponent", "uireload", "window.location.reload");
                    }

                }, 2000);

            }).catch(error => {
                console.error("MobileLeftSideNavigation", "logout-logoutFromSystem", error);
                logger.error("MobileLeftSideNavigation", "logout-logoutFromSystem", error.message);
                message.error("Error in logout");
            });

        } catch (error) {
            console.error("MobileLeftSideNavigation", "logout", error);
            logger.error("MobileLeftSideNavigation", "logout", error.message);
            message.error("Error in logout");
        }
    };

    const content = (
        <>
            {navigationRestrictions.panels.profile_hover.active && <div className={'my-profile-panel-wrp'}>

                {/*<div className={'my-profile-header-wrp d-flex  justify-content-end'}>*/}
                {/*    <div className={'sign-out'}>*/}
                {/*        Sign Out*/}
                {/*    </div>*/}
                {/*</div>*/}

                {/*======  My profile =======*/}
                {navigationRestrictions.account.my_profile.active && <Link to="/console/my-profile">
                    <div className={'panel-menu-wrapper with-link row-one'} onClick={() => {
                        props.selected_profile_actions.profileSelected({});
                        props.onClickCloseInfoPanel();
                    }}>
                        <div className={'menu-title d-flex align-items-center'}>
                            My profile
                        </div>
                    </div>
                </Link>}

                {/*======  workspace =======*/}
                {navigationRestrictions.account.workspaces.active && <div className={'panel-menu-wrapper'}>
                    <WorkspaceSwitcher />

                </div>}


                {navigationRestrictions.account.logout.active && <div className={'panel-menu-wrapper'}>
                    <div onClick={logout} className={"d-flex log-out  align-items-center"}>
                        Log Out
                    </div>
                </div>}

            </div>}

            {/*====== background =======*/}
            <div className={'profile-menu-bg-top'}>
                <img
                    src={require("../../../assets/img/menuBg.svg")}
                    alt="User Avatar"
                />
            </div>
            <div className={'profile-menu-bg-left'}>
                <img
                    src={require("../../../assets/img/menuBg.svg")}
                    alt="User Avatar"
                />
            </div>
        </>
    );


    return (

        <>
            <section
                className={`mobile-left-side-navigation-wrapper ${props.isOpenMobileProfile ? 'm-show-me' : 'm-hide-me'}`}>
                <div className={'m-left-menu-close-wrp'}>
                    <div className={"pg-header-icon-wrp  btn-panel-close"}>
                        <MenuIcon
                            icon={<X />}
                            onClick={props.onClickCloseMobileProfileMenu}
                            size={"sm"}
                        />
                    </div>
                </div>

                <div className={`profile-wrp d-flex justify-content-center hover-action`}>
                    <ProfileImage
                        type={'mobileProfileBg'}
                        src={props.my_profile && props.my_profile.avatarURL ? props.my_profile.avatarURL : null}
                        status={status} />
                    <div className={'m-bubble-wrapper'}>
                        <div className={'bubble-set one'}>
                            <div className={'bubble'} />
                            <div className={'bubble'} />
                        </div>
                        <div className={'bubble-set two'}>
                            <div className={'bubble'} />
                            <div className={'bubble'} />
                        </div>
                    </div>
                </div>


                {/*===== profile-details ======*/}
                <Scrollbars
                    autoHide
                    thumbMinSize={0}
                    renderView={props => (
                        <div {...props} style={{
                            overflowX: 'hidden',
                        }} />
                    )}
                    style={{
                        height: props.viewPointHeight - 150
                    }}>
                    <div className={'m-profile-details d-flex flex-column'}>
                        <div className={'full-name'}>
                            {props.my_profile && props.my_profile.firstName ? props.my_profile.firstName + ' ' + props.my_profile.lastName : '-'}
                        </div>
                        <div className={'email-id'}>
                            {props.my_profile && props.my_profile.emailId ? props.my_profile.emailId : null}
                        </div>
                    </div>


                    {/*===== work space list ======*/}
                    <div className={'m-work-space-wrapper'}>
                        {navigationRestrictions.account.workspaces.active &&
                            <div className={'panel-menu-wrapper'}>
                                <WorkspaceSwitcher
                                    type={'mobile'} />
                            </div>}
                    </div>
                    {/*===== work space list ======*/}
                    <div className={'m-work-space-wrapper'}>
                        {/*====== Availability =======*/}
                        {navigationRestrictions.presence.set_presence.active &&
                            <div className={'panel-menu-wrapper'}>
                                <div className={'menu-title'}>
                                    Availability
                            </div>

                                <div className={`work-space-body`}>
                                    <div className={"d-flex flex-wrap"}>
                                        <div
                                            className={"radio-btn-wrp  mleft-5"}
                                            onClick={() => setAvailability("busy")}>
                                            <RadioButton
                                                size={"sm"}
                                                text={"Busy"}
                                                selected={myAvailabilityStatus === "busy"}
                                            />
                                        </div>
                                        <div
                                            className={"radio-btn-wrp mleft-5"}
                                            onClick={() => setAvailability("available")}
                                        >
                                            <RadioButton
                                                size={"sm"}
                                                text={"Available"}
                                                selected={myAvailabilityStatus === "online"}
                                            />
                                        </div>
                                        <div
                                            className={"radio-btn-wrp mleft-5"}
                                            onClick={() => setAvailability("offline")}
                                        >
                                            <RadioButton
                                                size={"sm"}
                                                text={"Offline"}
                                                selected={myAvailabilityStatus === "offline"}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>}
                    </div>
                </Scrollbars>
                {/*===== Log Out======*/}
                {navigationRestrictions.account.logout.active && <div className={'panel-menu-wrapper'}>
                    <div onClick={logout} className={"d-flex log-out  align-items-center"}>
                        Log Out
                    </div>
                </div>}

                <div className={'left-menu-bg'}>
                    <img src={require("../../../assets/img/leftMemuBG.svg")}
                        alt="User Avatar" />
                </div>


            </section>
        </>
    );
}

LeftSideNavigationComponent.propTypes = {
    presence_user: PropTypes.object,
    presence_actions: PropTypes.object.isRequired
};

function mapDispatchToProps(dispatch) {
    return {
        presence_actions: bindActionCreators(presenceActions, dispatch),
        selected_profile_actions: bindActionCreators(
            selectedProfileActions,
            dispatch
        )
    };
}

function mapStateToProps(state) {
    console.log('====3434234241@#######');
    console.log(state);
    return {
        presence_user: state.presence.data[auth.getUserId()],
        my_profile: state.team[auth.getUserId()],
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LeftSideNavigationComponent);
