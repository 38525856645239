/* eslint-disable import/no-anonymous-default-export */
import moment from 'moment-timezone';

export const getLocalDate = (date) => {
    let dateObj = moment.utc(date).toDate();
    return moment(dateObj).local().format('YYYY-MM-DD');
};

export const getLocalTime = (time) => {
    let date = new Date();
    date = moment(date).format("YYYY-MM-DD");
    let dateObj = moment(date + ' ' + time).format("YYYY-MM-DD HH:mm");
    dateObj = moment.utc(dateObj).toDate();
    return moment(dateObj).local().format("hh:mm a");
};

export const setUTCDate = (date) => {
    return moment(date).utc().format('YYYY-MM-DD')
};

export const setUTCTime = (time) => {
    let date = new Date();
    date = moment(date).format("YYYY-MM-DD");
    let dateObj = moment(date + ' ' + time).format("YYYY-MM-DD HH:mm");
    return moment(dateObj).utc().format("HH:mm");
};

export const addMinsToTime = (time, mins) => {
    let date = new Date();
    date = moment(date).format("YYYY-MM-DD");
    let dateObj = moment(date + ' ' + time).format("YYYY-MM-DD HH:mm");
    dateObj = moment.utc(dateObj).toDate();
    let newTime = moment(dateObj).local().format("HH:mm");
    return moment(newTime, "HH:mm").add(mins, 'minutes').format('hh:mm a');
};

export default {
    getLocalDate,
    getLocalTime,
    setUTCDate,
    setUTCTime,
    addMinsToTime
};