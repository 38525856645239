import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { MenuIcon, MuteButton, VideoMuteButton } from "../index";
import { Airplay, Maximize, Mic, MicOff, Minimize, UserPlus, Video, VideoOff, Users, ZoomIn, ZoomOut, Camera } from "react-feather";
import { Tooltip, Tag } from "antd";
import navigationRestrictions from "../../../config/navigationRestrictions";

import { WEB_NOTIFICATION, PHONE_NOTIFICATION, RESET_NOTIFICATION } from '../../../redux/actions/actionTypes';
import { onShowNotification } from "../../../redux/actions/webNotificationActions";
import { useDispatch, useSelector } from "react-redux";
import liveChatClient from "../../../lib";
import { message as messagePopup } from "antd/lib/index";
import { fireEvent } from '../../../utils/eventEmitter';
import { getUserId } from '../../../utils';

const VideoFullScreenActionPanel = ({
    viewPointWidth,
    isVideoLoading,
    isAllowedScreenShare,
    onClickUnlockVideo,
    isVideoMinimizeScreen,
    isMute,
    muteUnmuteAudioVideo,
    isVideoMute,
    muteUnmuteVideo,
    blockScreenShare,
    isScreenShare,
    startScreenSharing,
    callee,
    onClickOpenInviteModal,
    userDetails,
    endCall,
    isOpenParticipationPanel,
    onClickOpenParticipation, caller, sendInvitationsToConference,
    onClickZoomInVideo,
    onClickZoomOutVideo,
    resetVideoZoom
}) => {
    const dispatch = useDispatch();
    const state = useSelector((state) => state);
    const [showTagMessage, setShowTagMessage] = useState(false);
    const [message, setMessage] = useState("");

    useEffect(() => {
        try {
            console.log("VideoFullScreenActionPanel", "useEffect", "rendering video full screen component");
            if (state.webNotification.action_type === "PHONE_NOTIFICATION") {
                setShowTagMessage(true);
                setMessage(state.webNotification.message);
                var timer1 = setTimeout(() => {
                    setShowTagMessage(false); clearTimeout(timer1);
                    dispatch(onShowNotification({
                        action_type: RESET_NOTIFICATION,
                        message: `RESET`,
                        duration: 5
                    }));
                }, (state.webNotification.duration * 1000));
            }
        } catch (error) {
            console.error(
                "WebNotification",
                "state.webNotification.isShowNotification"
            );
        }
    }, [dispatch, state.webNotification.action_type, state.webNotification.controls.isShowPhoneNotification, state.webNotification.duration, state.webNotification.message]);




    return (
        <>
            {!isVideoLoading && (
                <div className={`d-flex justify-content-center align-items-center`}>
                    {showTagMessage && (<Tag style={{ zIndex: 15000, alignContent: 'center', position: 'absolute', top: '50%', color: '#f2f2f2', backgroundColor: '#ec3c3c', textAlign: 'center' }}>{message}</Tag>)}
                    <div className={`video-call-action-wrp enable-fullscreen`}>
                        <div
                            className={"inner-wrapper-screen-share-action d-flex align-items-center"}>

                            {/*{isAllowedScreenShare &&*/}
                            {/*!isVideoMinimizeScreen &&*/}
                            {/*!blockScreenShare && (*/}
                            {/*    <>*/}
                            {/*        <div*/}
                            {/*            className={`pg-header-icon-wrp white lg-round ${*/}
                            {/*                isScreenShare ? "active" : ""*/}
                            {/*            }`}*/}
                            {/*        >*/}
                            {/*            {isScreenShare ? (*/}
                            {/*                <MenuIcon*/}
                            {/*                    size={"sm"}*/}
                            {/*                    color={"red"}*/}
                            {/*                    onClick={() => sharing(isScreenShare)}*/}
                            {/*                    icon={<StopCircle/>}*/}
                            {/*                />*/}
                            {/*            ) : (*/}
                            {/*                <MenuIcon*/}
                            {/*                    size={"sm"}*/}
                            {/*                    color={"white"}*/}
                            {/*                    onClick={() => sharing(isScreenShare)}*/}
                            {/*                    icon={<Airplay/>}*/}
                            {/*                />*/}
                            {/*            )}*/}
                            {/*        </div>*/}
                            {/*    </>*/}
                            {/*)} /!**/}

                            {/*===== minimize-video ======*/}
                            {/*{isAllowedScreenShare && (*/}
                            {/*    <Tooltip title={'Minimize'}>*/}
                            {/*        <div*/}
                            {/*            className={`pg-header-icon-wrp white lg-round ${isVideoMinimizeScreen ? "active" : ""}`}>*/}
                            {/*            <MenuIcon*/}
                            {/*                size={"sm"}*/}
                            {/*                color={"white"}*/}
                            {/*                onClick={() =>*/}
                            {/*                    onClickUnlockVideo(!isVideoMinimizeScreen)*/}
                            {/*                }*/}
                            {/*                icon={*/}
                            {/*                    isVideoMinimizeScreen ? <Minimize/> : <Maximize/>*/}
                            {/*                }*/}
                            {/*            />*/}
                            {/*        </div>*/}
                            {/*    </Tooltip>*/}
                            {/*)}*/}
                            {navigationRestrictions.calls.video_calls.maximize.active && (
                                <Tooltip title={`${isVideoMinimizeScreen ? 'Maximize' : 'Minimize'}`}>
                                    <div
                                        className={`pg-header-icon-wrp white lg-round ${isVideoMinimizeScreen ? 'minimizer-screen active-max-screen ' : 'full-screen'} `}>
                                        <MenuIcon
                                            size={'sm'}
                                            color={'white'}
                                            onClick={() => {
                                                onClickUnlockVideo(!isVideoMinimizeScreen)
                                                if (!isVideoMinimizeScreen)
                                                    resetVideoZoom();
                                            }

                                            }
                                            icon={
                                                isVideoMinimizeScreen ? <Minimize /> : <Maximize />
                                            } />
                                    </div>
                                </Tooltip>
                            )}

                            {navigationRestrictions.calls.video_calls.mute.active && isAllowedScreenShare && (
                                <Tooltip >
                                    <MuteButton caller={caller} className={"pg-header-icon-wrp white lg-round"} muteUnmuteAudioVideo={muteUnmuteAudioVideo} size={"sm"} color={"white"} status={null} />
                                </Tooltip>
                            )}
                            {navigationRestrictions.calls.video_calls.video_disable.active && isAllowedScreenShare && (
                                <Tooltip>
                                    <VideoMuteButton caller={caller} className={"pg-header-icon-wrp white lg-round"} muteUnmuteAudioVideo={muteUnmuteAudioVideo} size={"sm"} color={"white"} status={null} />
                                </Tooltip>

                            )}
                            <Tooltip title={'End call'}>
                                <div className={`pg-header-icon-wrp active end-call full-screen-btn`}>
                                    <MenuIcon
                                        size={"sm"}
                                        color={"white"}
                                        onClick={endCall}
                                        iconType={"material"}
                                        icon={<i className="material-icons">call_end</i>}
                                    />
                                </div>
                            </Tooltip>
                            {navigationRestrictions.screen_sharing.screen_share.active && isAllowedScreenShare && !blockScreenShare && !isScreenShare && navigator.mediaDevices.getDisplayMedia !== undefined && (
                                <Tooltip title={'Screen share'}>
                                    <div
                                        className={`pg-header-icon-wrp white lg-round ${isScreenShare ? "active" : ""}`}>
                                        <MenuIcon
                                            size={"sm"}
                                            color={"white"}
                                            onClick={() => startScreenSharing(callee)}//sharing(isScreenShare)}
                                            icon={<Airplay />}
                                        />
                                    </div>
                                </Tooltip>
                            )}

                            {navigationRestrictions.calls.video_calls.conference_invite.active && isAllowedScreenShare && !isVideoMinimizeScreen && (
                                <Tooltip title={'Conference'}>
                                    <div className={`pg-header-icon-wrp white lg-round`}>
                                        <MenuIcon
                                            size={"sm"}
                                            color={"white"}
                                            onClick={onClickOpenInviteModal}
                                            icon={<UserPlus />}
                                        />

                                    </div>
                                </Tooltip>
                            )}

                            {navigationRestrictions.calls.video_calls.conference_invite_request.active && isAllowedScreenShare && !isVideoMinimizeScreen && (
                                <Tooltip title={'invite to conference'}>
                                    <div className={`pg-header-icon-wrp white lg-round`}>
                                        <MenuIcon
                                            size={"sm"}
                                            color={"white"}
                                            onClick={sendInvitationsToConference}
                                            icon={<UserPlus />}
                                        />

                                    </div>
                                </Tooltip>
                            )}
                            {navigationRestrictions.calls.video_calls.snapshot_capture.active && isAllowedScreenShare && Object.keys(userDetails).length <= 2 && (
                                <Tooltip title={'Request Snapshot'}>
                                    <div className={`pg-header-icon-wrp white lg-round`}>
                                        <MenuIcon
                                            size={"sm"}
                                            color={"white"}
                                            onClick={() => {
                                                messagePopup.info("Snapshot capture requested");
                                                liveChatClient.requestSnapshotCapture(callee);
                                            }}
                                            icon={<Camera />}
                                        />

                                    </div>
                                </Tooltip>
                            )}

                            {navigationRestrictions.calls.video_calls.send_snapshot.active && isAllowedScreenShare && Object.keys(userDetails).length > 1 && (
                                <Tooltip title={'Send Snapshot'}>
                                    <div className={`pg-header-icon-wrp white lg-round`}>
                                        <MenuIcon
                                            size={"sm"}
                                            color={"white"}
                                            onClick={() => {
                                                let acceptOrRejectObject = {
                                                    requesterUserId: callee,
                                                    userId: getUserId()
                                                }
                                                fireEvent('capture_snapshot', { toUser: acceptOrRejectObject.requesterUserId, acceptOrRejectObject });
                                                //liveChatClient.requestSnapshotCapture(callee);
                                            }}
                                            icon={<Camera />}
                                        />

                                    </div>
                                </Tooltip>
                            )}

                            {
                                <Tooltip title={'Zoom In'}>
                                    <div className={`pg-header-icon-wrp white lg-round`}>
                                        <MenuIcon
                                            size={"sm"}
                                            color={"white"}
                                            onClick={onClickZoomInVideo}
                                            icon={<ZoomIn />}
                                        />
                                    </div>
                                </Tooltip>
                            }

                            {
                                <Tooltip title={'Zoom Out'}>
                                    <div className={`pg-header-icon-wrp white lg-round`}>
                                        <MenuIcon
                                            size={"sm"}
                                            color={"white"}
                                            onClick={onClickZoomOutVideo}
                                            icon={<ZoomOut />}
                                        />
                                    </div>
                                </Tooltip>
                            }
                            {/* { isAllowedScreenShare && !isVideoMinimizeScreen && !isOpenParticipationPanel && !isScreenShare && (
                                <Tooltip title={ `Participation` }>
                                    <div
                                        className={ `pg-header-icon-wrp white lg-round ${isVideoMinimizeScreen ? 'minimizer-screen ' : 'full-screen'} ` }>
                                        <MenuIcon
                                            size={ 'sm' }
                                            color={ 'white' }
                                            onClick={ () =>
                                                onClickOpenParticipation(!isVideoMinimizeScreen)
                                            }
                                            icon={ <Users /> } />
                                        { Object.keys(userDetails).length > 2 && (
                                            <div
                                                className={
                                                    "participation-count video-c-count top-action d-flex  justify-content-center align-items-center"
                                                }>
                                                { Object.keys(userDetails).length }
                                            </div>
                                        ) }
                                    </div>
                                </Tooltip>
                            ) } */}
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}
// VideoFullScreenActionPanel.propTypes = {
// };

VideoFullScreenActionPanel.defaultProps = {};

export default VideoFullScreenActionPanel;
