import Spin from "antd/lib/spin";
import * as auth from "../../../utils";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { profileSelected } from "../../../redux/actions/selectedProfileActions";
import { useLocation } from "react-router-dom";
import TmacService from "../../../service/tmacService";
import navigationRestrictions from "../../../config/navigationRestrictions";

export const MeetingJoinTMAC = (props) => {
  const dispatch = useDispatch();
  let location = useLocation();
  const [width,setwidth] = useState("100%");
  const [height,setheight] = useState("100%");

  const login = useCallback(async ()=>{
    try {

        const params = new Map(location.search.slice(1).split('&').map(kv => kv.split('=')));
        setwidth(params.get('width')??"100%");
        setheight(params.get('height')??"100%");
        console.log("MeetingJoinTMAC",params);
        
        const user = {"userId":"2c92809c71d5213c0171de94a4630002","userName":"rajinda.waruna@tetherfi.com","firstName":"Rajinda","lastName":"Waruna","emailId":"rajinda.waruna@tetherfi.com","mobileNumber":"1234567890","userType":"agent","gender":"Male","avatarURL":"https://mediastreamer.qa.tetherfi.cloud/stream/media/9d9c4ce0-8dd8-11ea-afad-17d127850685","orgUnit":0,"orgName":"","profile":"Agent","contacts":null,"conversation_type":0};
        //const user = await TmacService.login(params.get('userName'));

        if(user){
          navigationRestrictions.meeting.meeting_only = true;
          auth.setToken("Anonymous", user.userId);
          auth.setUser(user.userName);
          auth.setUserType(user.userType);
          auth.setCustomData({ meetingId: undefined, isVideo: true });
          auth.setDisplayName(user.userName);
          auth.setWorkspaces([]);
          auth.setRefreshToken("Anonymous");
          auth.setWorkspace("Anonymous");
          auth.setPassword("");
  
          dispatch(profileSelected(user));
    
          props.history.push({pathname: "/console",state: { detail: { onlyMeeting: true } },});
        }
        else{
          //route to error page
          props.history.push({pathname: "/403"});
        }
        
    } catch (error) {
        
    }
  },[])

  useEffect(() => {  
    setTimeout(() => {
        login();
    }, 2000);
  }, [login]);

  
  return (
    <div style={{ position: "absolute", top: "50%", left: "50%" ,width , height}}>
      <Spin tip="Loading..." />
    </div>
  );
};
