import React from 'react';
import PropTypes from 'prop-types';
import {
    PanelTitle,
    MenuIcon,
    TextBoxWithIcon,
    ModalSelectUser, SelectUser
} from '../index';
import { Check, X, Send } from 'react-feather';
import { Scrollbars } from "react-custom-scrollbars";
import navigationRestrictions from "../../../config/navigationRestrictions";
//online
//offline
//busy
//other
const Modal = ({ size, onClickClose, title, onType, selectedUsers, forward, searchTextVal, fusers, rusers }) => {

    let emptyContent = <div>No available users</div>;
    return (

        <div className={`main-modal-wrapper ${size}`}>
            <div className={'modal-header d-flex'}>
                <PanelTitle title={title} />
                <MenuIcon icon={<X />}
                    onClick={onClickClose}
                    size={'sm'} />
            </div>

            <div className={'search-wrapper d-flex align-items-center '}>
                <TextBoxWithIcon
                    textValue={searchTextVal}
                    style={'white'}
                    placeholder={'Search people here…'}
                    size={'form-control-lg'}
                    onChangeHandler={onType}
                />
            </div>

            <div className={'mdl-user-list-wrapper'}>
                <Scrollbars
                    autoHide
                    thumbMinSize={0}
                    style={{ height: 320 }}>
                    {navigationRestrictions.invite_panel.enable_sections &&
                        <>
                            <div className={'frequently-contacted'}>
                                <div className={`pb-2`}>
                                    <PanelTitle size={'small'} title={'Frequently Contacted'} />
                                </div>
                                {fusers.length === 0 ? emptyContent : fusers}
                            </div>
                            <div className={'recent-chats'}>
                                <div className={`pb-2`}>
                                    <PanelTitle size={'small'} title={'Recent Chats'} />
                                </div>
                                {rusers.length === 0 ? emptyContent : rusers}
                            </div>
                        </>
                    }
                    {!navigationRestrictions.invite_panel.enable_sections &&
                        <>
                            <div className={'frequently-contacted'}>
                                <div className={`pb-2`}>
                                    <PanelTitle size={'small'} title={'Agent List'} />
                                </div>
                                {fusers.length === 0 ? emptyContent : fusers}
                                {rusers}
                            </div>
                        </>
                    }
                </Scrollbars>
            </div>

            <div onClick={selectedUsers ? forward : () => { }}
                className={`modal-send-btn-wrapper d-flex align-items-center justify-content-center${!selectedUsers ? " disabled" : ""}`}>
                <Send />
            </div>
            <div className={`modal-footer-wrapper d-flex align-items-center`}>
                <div className={'selected-user-name d-flex flex-wrap'}>
                    {selectedUsers}
                </div>
            </div>


        </div>
    )
};

Modal.propTypes = {
    status: PropTypes.string.isRequired,
};

Modal.defaultProps = {
    status: 'offline',
};

export default Modal;
