import {
  CALL_CONTROL_RESET,
  CALL_MUTE_UNMUTE,
  CALL_VIDEO_MUTE_UNMUTE,
  PARTICIPATOR_ADDED,
  PARTICIPATOR_STATUS,
  VOICE_ACTIVITY,
  SCREEN_SHARE_ADDED,
  SCREEN_SHARE_REMOVED,
  CALL_REMOTE_MUTED,
  CALL_REMOTE_UNMUTED,
  DEVICE_LIST_LOADED,
  VIDEO_DEVICE_CHANGED,
  INTERACTION_TRANSFER_REQUEST_ACCEPTED,
  MEDIA_DEVICE_ACCESS_ERROR
} from "../actions/actionTypes";

const callControl = {
  controls: {
    isShareScreen: false,
    isCallMuted: false,
    isVideoMuted: false,
    isMicOn: false,
    isParticipatorAdded: false,
    isParticipatorStatusChange: false,
    isVoiceActivityDetected: false,
    isScreenShareVoiceActivityDetected: false,
    isScreenAdded: false,
    isScreenRemoved: false,
    isRemoteUnmuted: false,
    isRemoteMuted: false,
    isDeviceListLoaded: false,
    isDeviceChanged: false,
    isMediaDeviceAccessGranted: true,
    isInteractionTransferRequestAccepted: false,
  },
};

const callControlsReducer = (state = callControl, action) => {
  switch (action.type) {
    case CALL_CONTROL_RESET:
      return {
        ...state,
        controls: {
          ...state.controls,
          isShareScreen: false,
          isCallMuted: false,
          isVideoMuted: false,
          isMicOn: false,
          isParticipatorAdded: false,
          isParticipatorStatusChange: false,
          isVoiceActivityDetected: false,
          isScreenShareVoiceActivityDetected: false,
          isScreenAdded: false,
          isScreenRemoved: false,
          isRemoteUnmuted: false,
          isRemoteMuted: false,
          isDeviceListLoaded: false,
          isDeviceChanged: false,
          isInteractionTransferRequestAccepted: false,
        },        
      };
    case CALL_MUTE_UNMUTE:
      return {
        ...state,
        controls: {
          ...state.controls,
          isCallMuted: !state.controls.isCallMuted,
        },
        userId: action.payload.userId,
        callType: action.payload.callType,
        isMuted: action.payload.isMuted,
        isCallMuted: true,
      };
    case CALL_VIDEO_MUTE_UNMUTE:
      return {
        ...state,
        controls: {
          ...state.controls,
          isVideoMuted: !state.controls.isVideoMuted,
        },
        userId: action.payload.userId,
        callType: action.payload.callType,
        isMuted: action.payload.isMuted,
        isVideoMuted: true,
      };
    case PARTICIPATOR_ADDED:
      return {
        ...state,
        controls: {
          ...state.controls,
          isParticipatorAdded: !state.controls.isParticipatorAdded,
        },
        userId: action.payload.userId,
      };
    case PARTICIPATOR_STATUS:
      return {
        ...state,
        controls: {
          ...state.controls,
          isParticipatorStatusChange: !state.controls
            .isParticipatorStatusChange,
        },
        userId: action.payload.userId,
        status: action.payload.status,
      };
    case VOICE_ACTIVITY:
      if (state.controls.isShareScreen) {
        return {
          ...state,
          controls: {
            ...state.controls,
            isScreenShareVoiceActivityDetected: !state.controls
              .isScreenShareVoiceActivityDetected,
          },
          userId: action.payload.userId,
          userName: action.payload.userName,
          previousUserId: action.payload.previousUserId,
        };
      }
      return {
        ...state,
        controls: {
          ...state.controls,
          isVoiceActivityDetected: !state.controls.isVoiceActivityDetected,
        },
        userId: action.payload.userId,
        userName: action.payload.userName,
        previousUserId: action.payload.previousUserId,
      };
    case SCREEN_SHARE_ADDED:
      return {
        ...state,
        controls: {
          ...state.controls,
          isScreenAdded: !state.controls.isScreenAdded,
          isShareScreen: true,
        },
        userId: action.payload.userId,
        userName: action.payload.userName,
        isScreenShared: true,
      };
    case SCREEN_SHARE_REMOVED:
      return {
        ...state,
        controls: {
          ...state.controls,
          isScreenRemoved: !state.controls.isScreenRemoved,
          isShareScreen: false,
        },
        userId: action.payload.userId,
        userName: action.payload.userName,
        isScreenRemoved: true,
      };
    case CALL_REMOTE_MUTED:
      return {
        ...state,
        controls: {
          ...state.controls,
          isRemoteMuted: !state.controls.isRemoteMuted,
        },
        userId: action.payload.userId,
        callType: action.payload.callType,
        isMuted: action.payload.isMuted,
        isRemoteMuted: true,
      };
    case CALL_REMOTE_UNMUTED:
      return {
        ...state,
        controls: {
          ...state.controls,
          isRemoteUnmuted: !state.controls.isRemoteUnmuted,
        },
        userId: action.payload.userId,
        callType: action.payload.callType,
        isMuted: action.payload.isMuted,
        isRemoteUnmuted: true,
      };
    case DEVICE_LIST_LOADED:
      return {
        ...state,
        controls: {
          ...state.controls,
          mediaDevices: action.payload.mediaDevices,
          isDeviceListLoaded: !state.controls.isDeviceListLoaded,
        },
      };
    case VIDEO_DEVICE_CHANGED:
      return {
        ...state,
        controls: {
          ...state.controls,
          isDeviceChanged: !state.controls.isDeviceChanged,
        },
      };
    case INTERACTION_TRANSFER_REQUEST_ACCEPTED:
      return {
        ...state,
        controls: {
          ...state.controls,
          isInteractionTransferRequestAccepted: !state.controls
            .isInteractionTransferRequestAccepted,
        },
        accepted: action.payload.accepted,
        interactionId: action.payload.interactionId,
      };
      case MEDIA_DEVICE_ACCESS_ERROR:
      return {
        ...state,
        controls: {
          ...state.controls,
          isMediaDeviceAccessGranted: action.payload.isMediaDeviceAccessGranted,
        },
        isMicrophoneAlreadyCaptured :action.payload.isMicrophoneAlreadyCaptured,
        isWebcamAlreadyCaptured :action.payload.isWebcamAlreadyCaptured,

      };
    default:
      return state;
  }
};

export default callControlsReducer;
