import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import './styles/co-browsing-modal.scss';
import { PanelTitle } from "../index";
import CoBrowsingItem from './CoBrowsingItem'
import { Scrollbars } from "react-custom-scrollbars";
import { coBrowseService } from "../../../service/coBrowseService";
import * as whiteboardActions from '../../../redux/actions/whiteboardActions';


const CoBrowsingOption = (props) => {

    const dispatch = useDispatch();

    const selected_profile = useSelector((state) => state.selected_profile);

    const [CoBrowseFileMap, setCoBrowseFileMap] = useState([]);

    useEffect(() => {
        loadConfigurations();
    }, []);

    const loadConfigurations = async () => {
        try {
            const response = await coBrowseService.getCobrowsingConfigs();

            if (response && response.length > 0) {
                setCoBrowseFileMap(response);
            }

        } catch (ex) {
            console.error(ex);
        }
    }
    const onOptionClick = (fileUrl) => {
        window.open(fileUrl, "_blank");
        props.selectOption();
    }


    return (
        <>
            <div className={`agent-confirmation-wrapper cb-option ${props.isConfirmCoBrowsing ? 'show-option' : 'hide-option'}`}>
                <div className={'acfm-title cob-select-option  text-center'}>
                    <PanelTitle
                        type={'blue-title'}
                        title={'Co-browsing'} />

                    <PanelTitle
                        type={'sub-title'}
                        title={'Choose co-browsing option'} />
                </div>
                <Scrollbars
                    autoHide
                    thumbMinSize={0}
                    style={{ height: 420 }}>
                    <div
                        className={'cob-select-option-wrapper d-flex flex-wrap  justify-content-center align-items-center'}>

                        {CoBrowseFileMap && CoBrowseFileMap.map(file => {
                            return (<CoBrowsingItem
                                onClick={() => {

                                    file.type === 'iframe' ? dispatch(whiteboardActions.openWhiteboard(selected_profile.username, 'COBROWSE', file.url)) : onOptionClick(file.url);
                                    props.onClickClose();
                                }}
                                menuTitle={file.name}
                                type={file.type} />)
                        })}
                    </div>
                </Scrollbars>
            </div>
        </>
    )
};


export default CoBrowsingOption;
