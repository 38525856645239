import * as types from "./actionTypes";
import * as ChatService from "../../service/chatService";
import * as chatSearchActions from "./chatSearchActions";
import * as starringActions from "./starringActions";
import renderState from "../../service/renderStateHandler";
import { getUserId } from "../../utils";
import * as eventEmitter from "../../utils/eventEmitter";
import * as uiPanelActions from "./uiPanelActions";

export function addNewChatUser(user) {
    return { type: types.ADD_NEW_CHAT_USER, user };
}

export function resetMessages(user, search_mode) {
    let data = {
        user, search_mode
    };
    return { type: types.RESET_MESSAGES, data };
}

export function receiveNewMessage(message, increaseCount, deleteOld) {
    let data = {
        message,
        increaseCount,
        deleteOld
    };
    return { type: types.RECEIVE_NEW_MESSAGE, data };
}

export function resendMessage(message) {
    return dispatch => {

        let action = chatSearchActions.resetSearchMessages();
        dispatch(action);

        dispatch({ type: types.RESEND_MESSAGE, message: JSON.parse(JSON.stringify(message)) });

        ChatService.sendChatMessage(message.conversation_type, "text/html", message.to.id, JSON.parse(JSON.stringify(message)), false, message.conversation_type === 4 ? message.to.id : null);

        eventEmitter.fireEvent("scrollbar", { scrollbar_status: "down" });

    };
}

export function sendNewMessageSuccess(message) {

    return dispatch => {

        let action = chatSearchActions.resetSearchMessages();
        dispatch(action);

        dispatch({ type: types.SEND_NEW_MESSAGE_SUCCESS, message });

    };
}

export function sendSeenStatusSuccess(user, lastSeenId) {

    let data = { user, lastSeenId };

    return { type: types.SEND_SEEN_STATUS_SUCCESS, data };
}

export function setRemotePartySeen(user, seenAt, mid) {

    let data = { user, seenAt, mid };

    return { type: types.SET_REMOTE_PARTY_SEEN, data };
}

export function setMessageDeliveredState(user, mid) {

    let data = { user, mid };

    return { type: types.SET_MESSAGE_DELIVERED_STATE, data };
}

export function setMessageSendState(user, mid, state) {

    let sentState = null;

    if (state === true) {
        sentState = "SUCCESS"
    } else if (state === false) {
        sentState = "FAIL"
    }

    let data = { user, mid, sentState };

    return { type: types.SET_MESSAGE_SEND_STATE, data };
}

export function setCallStatus(sessionId, from, to, direction, status) {

    let data = { sessionId, from, to, direction, status };

    return { type: types.SET_CALL_STATUS, data };
}

export function sendCallMessageSuccess(from, to, sessionId) {

    let data = {
        message_type: 1,
        from: { id: from, name: from },
        to: { id: to, name: to },
        created_at: Date.now(),
        mid: sessionId,
        status: 0,
        direction: "send"
    }

    return { type: types.SEND_CALL_MESSAGE_SUCCESS, data };
}

export function renameChatUser(message) {
    let data = { previousId: message.original_contact.id, profileId: message.profile };
    return { type: types.CHAT_USER_RENAMED, data };
}

export function resetUnseenCount(user) {

    return { type: types.RESET_UNSEEN_COUNT, user };
}

export function getAllUnreadCounts(username) {
    return dispatch => {
        dispatch(uiPanelActions.userListReadyState('LOADING'));
        ChatService.getAllUnreadCountsV2(username)
            .then(chatCounts => {
                dispatch(getAllUnreadCountsSuccess(chatCounts));
                dispatch(uiPanelActions.userListReadyState('READY'));
                //dispatch(myClienteleActions.custChatUsersLoaded(chatCounts));
            }).catch(ex => {
                console.log(ex);
                dispatch(uiPanelActions.userListReadyState('READY'));
            });
    }
}

export function getAllUnreadCountsForUserIds(username, userIds) {
    return dispatch => {
        dispatch(uiPanelActions.userListReadyState('LOADING'));
        ChatService.getAllUnreadCountsV2Users(username, userIds)
            .then(chatCounts => {
                dispatch(getAllUnreadCountsSuccess(chatCounts));
                dispatch(uiPanelActions.userListReadyState('READY'));
                //dispatch(myClienteleActions.custChatUsersLoaded(chatCounts));
            }).catch(ex => {
                console.log(ex);
                dispatch(uiPanelActions.userListReadyState('READY'));
            });
    }
}

export function getChatTransferHistoryData(username, userIds, conversation_type, prev_timestamp, transferMsg) {
    return dispatch => {
        ChatService.getAllUnreadCountsV2Users(username, userIds)
            .then(chatCounts => {
                dispatch(getAllUnreadCountsSuccess(chatCounts));

                dispatch(getOldMessages(
                    "tetherfi",
                    conversation_type,
                    username,
                    userIds[0],
                    prev_timestamp,
                    50,
                    true
                ));

                ChatService.sendChatMessage(
                    4,
                    "text/html",
                    transferMsg.to.id,
                    transferMsg,
                    false,
                    transferMsg.to.id
                );

                dispatch(sendNewMessageSuccess(transferMsg));
                eventEmitter.fireEvent("scrollbar", { scrollbar_status: "down" });
                //dispatch(myClienteleActions.custChatUsersLoaded(chatCounts));
            }).catch(ex => {
                console.log(ex);
            });
    }
}

export function getAllUnreadCountsSuccess(chatCounts) {
    return { type: types.GET_ALL_UNREAD_CHAT_COUNT_SUCCESS, chatCounts };
}

export function starUnstarMessage(from, message, is_starred, conv_user) {
    return dispatch => {
        ChatService.starMessage(from, message.mid, is_starred)
            .then(result => {
                dispatch(starUnstarSuccess(conv_user, message.mid, is_starred));
                dispatch(chatSearchActions.starUnstarSearchSuccess(message.mid, is_starred));
                dispatch(starringActions.starUnstarMessageSuccess(conv_user, message, is_starred));
            }).catch(ex => {
                console.log(ex);
            });
    }
}

export function starUnstarSuccess(conv_user, mid, is_starred) {
    return { type: types.STAR_UNSTAR_MESSAGE_SUCCESS, conv_user, mid, is_starred };
}

export function updateMessage(message) {
    return { type: types.UPDATE_MESSAGE, message };
}

export function updateUploadFailMessage(mid, user) {
    return { type: types.UPDATE_UPLOAD_FAIL_MESSAGE, mid, user };
}

export function addMissingMessages(messages) {
    return { type: types.ADD_MISSING_MESSAGES, messages };
}

export function getUnreadChatsForUserSuccess(chats, bufferMessages) {
    let data = { chats, bufferMessages };
    return { type: types.GET_UNREAD_CHATS_SUCCESS, data };
}

export function getInitialMessages(workspace, type, from, to, timestamp, limit, is_first) {

    return dispatch => {
        if (type === 4) {
            ChatService.getOldMessagesInteractions(to, timestamp, limit)
                .then((chats) => {
                    let objOld = {
                        user: to,
                        messages: chats.messages,
                        prev_timestamp: chats.prev_timestamp,
                        unseen_messages: chats.unseen_messages,
                        is_first,
                        last_seen_mid_history: chats.last_seen_mid_history
                    }

                    ChatService.getUnreadMessagesInteraction(to, timestamp, limit, null)
                        .then(chats => {
                            let objNew = {
                                user: to,
                                messages: chats.messages,
                                unseen_messages: chats.unseen_messages
                            }
                            dispatch(getInitialChatsSuccess(objOld, objNew));
                        }).catch(ex => {
                            console.log(ex);
                        });
                }).catch(ex => {
                    console.log(ex);
                });

        } else {
            ChatService.getOldMessages(workspace, type, from, to, timestamp, limit)
                .then((chats) => {
                    let objOld = {
                        user: to,
                        messages: chats.messages,
                        prev_timestamp: chats.prev_timestamp,
                        unseen_messages: chats.unseen_messages,
                        is_first,
                        last_seen_mid_history: chats.last_seen_mid_history
                    }

                    ChatService.getUnreadMessages(workspace, type, from, to, timestamp, limit, null)
                        .then(chats => {
                            let objNew = {
                                user: to,
                                messages: chats.messages,
                                unseen_messages: chats.unseen_messages
                            }
                            dispatch(getInitialChatsSuccess(objOld, objNew));
                        }).catch(ex => {
                            console.log(ex);
                        });
                }).catch(ex => {
                    console.log(ex);
                });

        }

    }

}

export function getOldMessages(workspace, type, from, to, timestamp, limit, is_first) {

    return dispatch => {
        if (type === 4) {

            ChatService.getOldMessagesInteractions(to, timestamp, limit)
                .then((chats) => {
                    let obj = {
                        user: to,
                        messages: chats.messages,
                        prev_timestamp: chats.prev_timestamp,
                        unseen_messages: chats.unseen_messages,
                        is_first,
                        last_seen_mid_history: chats.last_seen_mid_history
                    }
                    dispatch(getOldChatMessagesSuccess(obj));
                }).catch(ex => {
                    console.log(ex);
                });

        } else {

            ChatService.getOldMessages(workspace, type, from, to, timestamp, limit)
                .then((chats) => {
                    let obj = {
                        user: to,
                        messages: chats.messages,
                        prev_timestamp: chats.prev_timestamp,
                        unseen_messages: chats.unseen_messages,
                        is_first,
                        last_seen_mid_history: chats.last_seen_mid_history
                    }
                    dispatch(getOldChatMessagesSuccess(obj));
                }).catch(ex => {
                    console.log(ex);
                });

        }

    }
}

export function getOldChatMessagesSuccess(chats) {
    return { type: types.GET_OLD_CHATS_SUCCESS, chats };
}

export function getInitialChatsSuccess(old_chats, unread_chats) {
    return { type: types.GET_INITIAL_CHAT_HISTORY_SUCCESS, old_chats, unread_chats };
}

export function getAllMessagesByDate(workspace, type, from, to, dateArr, limit) {

    let tempDateArr = [...dateArr];

    tempDateArr.sort(function (a, b) {
        return new Date(b) - new Date(a);
    });

    let date = tempDateArr[0];

    var index = tempDateArr.indexOf(date);
    if (index > -1) {
        tempDateArr.splice(index, 1);
    }


    return dispatch => {
        ChatService.getAllMessagesForDates(workspace, type, from, to, date, [], limit, null)
            .then(chats => {
                let obj = {
                    user: to,
                    messages: chats,
                    days_remaining: tempDateArr
                }
                dispatch(getAllMessagesForDatesSuccess(obj));
            }).catch(ex => {
                console.log(ex);
            });
    }
}
export function setLastSeenAnchorPoint(user_id, last_seen_anchor_point) {

    renderState.focusToLastRead = true;
    renderState.scrollReadyState = false;
    let data = {
        user_id, last_seen_anchor_point
    }
    return { type: types.SET_LAST_SEEN_ANCHOR_POINT, data };
}

export function getAllMessagesForDatesSuccess(chats) {
    return { type: types.GET_ALL_CHATS_SUCCESS, chats };
}

export function appendChatMedia(user_id, media) {
    let data = {
        user_id, media
    };
    return { type: types.APPEND_CHAT_MEDIA, data };
}

