import * as types from "../actions/actionTypes";
import produce from "immer";

export const starringReducer = produce((draft = {}, action) => {
    switch (action.type) {
        case types.STAR_UNSTAR_MESSAGE_SUCCESS_ACTION:
            if (action.is_starred) {
                let messageList = {};
                messageList[ action.message.mid ] = action.message;
                messageList = { ...messageList, ...draft }
                draft = messageList;
            }
            else {
                delete draft[ action.message.mid ]
            }
            break;
        case types.STARRED_MESSAGE_LIST_LOADED:
            draft = action.messages;
            break;
        case types.STARRED_MESSAGE_LIST_NEXT_PAGE_LOADED:
            draft = { ...draft, ...action.messages }
            break;
        default: break;
    }

    return draft;
});