import { LOGIN_FAILURE, LOGIN_REQUEST, LOGIN_REQUEST_PENDING, LOGIN_SUCCESS } from '../../config/types';

const initialState = {
    data: undefined,
    error: '',
    loading: false,
    type: undefined
};

export default (state = initialState, action) => {
    switch (action.type) {
        case LOGIN_REQUEST:
            return {
                ...state,
                error: '',
                data: undefined,
                loading: true,
                type: LOGIN_REQUEST
            };
        case LOGIN_REQUEST_PENDING:
            return {
                ...state,
                error: '',
                data: undefined,
                loading: true,
                type: LOGIN_REQUEST_PENDING
            };
        case LOGIN_FAILURE:
            return {
                ...state,
                error: 'login failure',
                loading: false,
                data: action.payload,
                type: LOGIN_FAILURE
            };
        case LOGIN_SUCCESS:
            return {
                ...state,
                error: '',
                loading: false,
                data: action.payload,
                type: LOGIN_SUCCESS
            };
        default:
            return state
    }
};
