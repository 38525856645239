class Meeting {

    constructor(obj) {
        this.id = obj.id;
        this.creator = obj.creator;
        this.title = obj.title;
        this.description = obj.description;
        this.participants = obj.participants;
        this.location = obj.location;
        this.startDate = obj.startDate;
        this.startTime = obj.startTime;
        this.duration = obj.duration;
        this.timeZone = obj.timeZone;
        this.OffSet = obj.OffSet;
        this.isAllDay = obj.isAllDay;
        this.isRecurrence = obj.isRecurrence;
        this.recurrencePattern = obj.recurrencePattern;
        this.recurrenceRange = obj.recurrenceRange;
        this.configurations = obj.configurations;
        this.url = obj.url;
        this.status = obj.status;
        this.allowGuest = obj.allowGuest;
        this.calender = obj.calender;
        this.urlType = obj.urlType;
        this.passCode = obj.passCode;
        this.isActive = obj.isActive;
        this.createdBy = obj.createdBy;
        this.createdOn = obj.createdOn;
        this.modifiedBy = obj.modifiedBy;
        this.modifiedOn = obj.modifiedOn;
        this.notifyBefore = obj.notifyBefore;
    }

}

export { Meeting };