import React from 'react';
import PropTypes from 'prop-types';
import {notification} from 'antd';

//type=success,info,warning,error
const NotificationMessage = ({title, message, onClick, type}) => (
    notification[type]({
        message: title,
        placement: 'bottomRight',
        description: message,
        onClick: () => {
            console.log('Notification Clicked!');
        },
    })
);

NotificationMessage.propTypes = {
    title: PropTypes.func.isRequired,
    message: PropTypes.string.isRequired,
};

NotificationMessage.defaultProps = {
    title: null,
    message: null,
};

export default NotificationMessage;
