import React, { useEffect, useRef, useState } from 'react';
import {
    Modal, VideoRecorder
} from '../../common';
import { Check, ArrowRight, Smile } from 'react-feather';
import { Route, Switch } from "react-router-dom";
import ConsoleComponent from "../console/MainConsole";
import CreatNewPanelComponent from "../CreateNewPanel/CreatNewPanel";
import { Scrollbars } from 'react-custom-scrollbars';
import uuid from "uuid/v1";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as chatActions from "../../../redux/actions/chatActions";
import * as VideoRecordActions from "../../../redux/actions/videoRecordActions";
import * as auth from "../../../utils";
import * as eventEmitter from "../../../utils/eventEmitter";

function VideoRecorderModal(props) {
    const [recordingState, setRecordingState] = useState(false);

    // const mounted = useRef();
    // useEffect(() => {
    //     if (!mounted.current) {
    //         mounted.current = true;
    //     }
    // });

    function startRecord() {
        setRecordingState(true);
    }

    function endRecord() {
        setRecordingState(false);
    }

    function cancelRecord() {
        //alert("CANCELLED");
    }

    function sendRecord(videoBlob,
        startedAt,
        thumbnailBlob,
        duration) {
        console.log("ADD REC TO CHAT", startedAt, thumbnailBlob, duration, videoBlob);

        try {
            const fileId = uuid();
            const urlCreator = window.URL || window.webkitURL;
            const thumbUrl =
                thumbnailBlob && urlCreator.createObjectURL(thumbnailBlob);
            const vidUrl =
                videoBlob && urlCreator.createObjectURL(videoBlob);
            const message = {
                mid: fileId,
                sid: fileId,
                message_type: "11",
                from: {
                    id: auth.getUserId(),
                    name: auth.getLoggedInUser()
                },
                to: {
                    id: props.selected_profile.username,
                    name: props.selected_profile.username
                },
                channel_type: videoBlob.type,
                created_at: Date.now(),
                message_content: "",
                videoBlob,
                startedAt,
                thumbnailBlob,
                duration,
                thumbUrl,
                vidUrl,
                conversation_id: props.selected_profile.conversation_id,
                conversation_type: props.selected_profile.conversation_type
            };
            props.chat_actions.sendNewMessageSuccess(message);
            //props.video_record_actions.closeVideoRecordModal(false);
            eventEmitter.fireEvent("scrollbar", { scrollbar_status: "down" })
        } catch (ex) {
            console.error(ex);
        }
    }

    function onClose() {
        endRecord();
        props.onClickClose();
    }

    function onPressChatFilterIcon(_filter) {
        console.log('event fire...');
        console.log(_filter);
    }

    return (
        <>
            <div
                className={`modal-inner-wrapper d-flex justify-content-center align-items-center  ${props.isEnableVideoRecorder ? 'show-this' : 'hide-this'}`}>
                <VideoRecorder
                    title={'Video Record'}
                    onClickClose={onClose}
                    startRecord={startRecord}
                    endRecord={endRecord}
                    sendRecord={sendRecord}
                    cancelRecord={cancelRecord}
                    isStartVideoRecord={recordingState}
                    isViewModal={props.isEnableVideoRecorder} />
                }
            </div>

        </>
    );
}

function mapStateToProps(state) {
    return {
        selected_profile: state.selected_profile,
        video_record_status: state.video_record_status
    };
}

function mapDispatchToProps(dispatch) {
    return {
        chat_actions: bindActionCreators(chatActions, dispatch),
        video_record_actions: bindActionCreators(VideoRecordActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(VideoRecorderModal);

