import React from 'react';
import PropTypes from 'prop-types';
import {Headphones, X} from 'react-feather';
import {MenuIcon} from "../index";

const DocumentThumbnail = ({fileSize, onclick, docLink,onClickRemoveImage}) => (
    <div className={'gallery-thumbnail-wrp document  d-flex flex-column justify-content-center align-items-center'}
         onClick={onclick}>

        <img src={require("../../../assets/img/pdf.svg")}
             alt="User Avatar"/>
        <div className={'duration'}>
            {fileSize}
        </div>
    </div>
);


DocumentThumbnail.defaultProps = {};

export default DocumentThumbnail;
