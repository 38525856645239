import React, { useEffect } from 'react';
import { Result, Button } from 'antd';
import phoneEngine from '../phone/phoneEngine/phoneEngineHandler';
import * as auth from "../../../utils";
import liveChatClient from "../../../lib";
import AuthService from "../../../service/authService";
import { __APP_IDENTITY_PROVIDER__ } from "../../../config/baseUrls";
import navigationRestrictions from "../../../config/navigationRestrictions";
import * as logger from "../../../lib/logSdk/logger";

const authService = new AuthService();

const SocketDisconnect = (props) => {

  return (
    <Result
      status="500"
      title={`${navigationRestrictions.error_page.heading.active ? navigationRestrictions.error_page.heading.display : ""}`}
      subTitle={`${navigationRestrictions.error_page.sub_heading.active ? navigationRestrictions.error_page.sub_heading.display : ""}`}
      extra={navigationRestrictions.error_page.login_button.active && <Button type="primary" onClick={() => {
        try {
          props.history.push("/");
          window.location.reload();
          console.log("SocketDisconnect","uireload","window.location.reload");
        } catch (error) {
          console.error("SocketDisconnect","Fail To Logout", error);
          logger.error("SocketDisconnect","Fail To Logout", error.message);
        }
      }}>Login</Button>}
    />
  )
};

export default SocketDisconnect;
