import React, { useState, forwardRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import PanelTitle from "../PanelTitle/PanelTitle";
import SearchTextBox from './../SearchTextBox/SearchTextBox'

const SearchTextBoxWrapper = forwardRef((props, ref) => {
    useImperativeHandle(ref, () => ({
        getValue() {
            return searchText;
        },
        setValue(val) {
            setSearchText(val);
        },
        clear() {
            setSearchText();
        }
    }));

    const [searchText, setSearchText] = useState("");

    const onTextChangeHandler = value => {
        setSearchText(value);
    };

    return (<>
        <SearchTextBox id={props.id} placeholder={props.placeholder} onKeyPressHandler={props.onKeyPressHandler} textValue={searchText}
            onChangeHandler={onTextChangeHandler} onFocusOutHandler={props.onFocusOutHandler} />
    </>)

});

SearchTextBoxWrapper.propTypes = {
    placeholder: PropTypes.string.isRequired,
};

PanelTitle.defaultProps = {
    placeholder: 'Type here...',
};


export default SearchTextBoxWrapper;
