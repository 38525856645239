import React from 'react';
import { connect } from "react-redux";
import { ProfileImage } from "../index";


const Typing = ({ src, msg, isImage, lastSeen, ...props }) => {

    let fullName = props.selected_profile && props.selected_profile.displayName ? `${props.selected_profile.displayName}` : "";
    return (
        <>
            {/*//hide-typing'*/}

            <div className={`msg d-flex left-msg active-typing}`}>
                {isImage && <div className="msg-img">
                    <ProfileImage
                        firstLatter={fullName ? fullName.split(' ').map(fullName => fullName[0]).join('').toUpperCase() : ""}
                        size={'sm'}
                        src={props.typing_profile && props.typing_profile.avatarURL ? props.typing_profile.avatarURL : null}
                        padding="4px"
                        fontSize="0.8rem" />
                </div>}
                <div className={`inner-msg-bubble typing-wrp`}>
                    <div className="msg-text">
                        <div id="preloader_4">
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                    </div>

                </div>
            </div>

            {/* <div className={`msg d-flex left-msg typing-fixed-bottom ${props.typing_status && props.typing_status.is_typing ? 'active-typing' : 'hide-typing'}`}>
                {isImage && <div className="msg-img">
                    <ProfileImage
                        firstLatter={'J'}
                        size={'sm'}
                        src={props.typing_profile && props.typing_profile.avatarURL ? props.typing_profile.avatarURL : null} />
                </div>}
                <div className={`inner-msg-bubble typing-wrp`}>
                    <div className="msg-text">
                        <div id="preloader_4">
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                    </div>

                </div>
            </div> */}
        </>

    )
};


function mapStateToProps(state) {

    let mapState = {
        selected_profile: state.selected_profile
    };

    if (state.selected_profile && state.selected_profile.username) {
        //mapState.typing_status = state.typing_status[state.selected_profile.username];
        if (state.team) {
            mapState.typing_profile = state.team[state.selected_profile.username];
        }

    }
    return mapState;
}

export default connect(
    mapStateToProps
)(Typing);
