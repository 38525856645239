import React from 'react';
import PropTypes from 'prop-types';
import { ChatFooter, ForwardedLabel, MenuIcon, ProfileImage } from "../index";
import { Popover } from "antd";
import { MoreVertical } from "react-feather";
import navigationRestrictions from "../../../config/navigationRestrictions";

const content = ({ onClickReplayMessage, onClickForwardMessage, onClickOpenGallery, onDownloadClick, contentUrl, filename, mediaFiles, msgObj, sendForwardMessages, onClickStar, isStarred }) => (
    <div className={'chat-hover-menu'}>
        <div
            onClick={() => onClickReplayMessage(msgObj)}
            className={'document-row'}>Reply
        </div>
        {navigationRestrictions.chat.forwarding.active && <div className={'document-row'}
            onClick={() => onClickForwardMessage((users) => {

                sendForwardMessages(users, msgObj);

            })}>
            Forward message
        </div>}
        {navigationRestrictions.chat.starring.active && <div className={'document-row'} onClick={() => onClickStar(msgObj, !isStarred)}>{isStarred ? 'Unstar Message' : 'Star Message'}</div>}
        {mediaFiles && mediaFiles.length > 0 && <div className={'document-row'} onClick={() => onClickOpenGallery(mediaFiles)}>View</div>}
        <div className={'document-row'} onClick={() => onDownloadClick(mediaFiles)}>Download</div>
    </div>
);

const ChatLeftAudio = ({ src, fullName, msg, isImage, lastSeen, imgDesc,
    onClickReplayMessage, onClickForwardMessage, playing, videoSrc,
    onClickOpenGallery, audioType, controls, onDownloadClick, contentUrl, filename, mediaFiles, msgObj, sendForwardMessages, onClickStar, isStarred, ...props }) => (
        <div key={props.mid} id={props.mid} className={`msg d-flex left-msg ${isImage ? '' : 'with-out-img'} `}>
            <div className="msg-img">
                <ProfileImage
                    firstLatter={fullName ? fullName.split(' ').map(fullName => fullName[0]).join('').toUpperCase() : ""}
                    textType={'chatLeft'}
                    size={'sm'}
                    src={src}
                    padding="4px"
                    fontSize="0.8rem" />
            </div>
            <div className={`inner-msg-bubble ${isImage ? null : 'with-out-name'}`}>
                {(props.conversation_type === 3 || props.conversation_type === 4) && <div className="msg-info-name">{fullName}</div>}

                <div className={'msg-text video-chat-message'}>
                    <div className="msg-text-with-video  d-flex flex-column ">
                        <ForwardedLabel isForwardedMsg={props.isForwarded} />
                        <div className={`chat-audio-wrapper d-flex ${imgDesc ? 'with-replay' : 'default '}`}>
                            <div className={'chat-audio-inner-wrapper'}>
                                <audio controls controlsList="nodownload">
                                    <source src={contentUrl} type={audioType} />
                                </audio>
                            </div>
                        </div>
                        {msg}
                        <Popover
                            content={content({ onClickReplayMessage, onClickForwardMessage, onClickOpenGallery, onDownloadClick, contentUrl, filename, mediaFiles, msgObj, sendForwardMessages, onClickStar, isStarred })}
                            trigger="hover">
                            <div className={'hover-more'}>
                                <MenuIcon
                                    icon={<MoreVertical />} />
                            </div>
                        </Popover>
                    </div>

                </div>
                <ChatFooter lastSeen={lastSeen}
                    lastSeenStatus={false}
                    deliveredStatus={false}
                    isStarred={isStarred} />
            </div>
        </div>
    );

ChatLeftAudio.propTypes = {
    fullName: PropTypes.string.isRequired,
    msg: PropTypes.string.isRequired,
    isImage: PropTypes.bool.isRequired,
    isLastSeen: PropTypes.bool.isRequired,
};

ChatLeftAudio.defaultProps = {
    src: null,
};

export default ChatLeftAudio;
