import React from 'react';
import PropTypes from 'prop-types';
import { Image, Video, File, Headphones, PhoneMissed } from "react-feather";

import '../../../assets/stylesheets/modules/variable.scss'
import './user-message-content.scss'

const UserMessageContent = ({ type, content }) => (
    <>
        <div className={'user-message-content d-flex'}>
            <div className={`message-content-icon`}>
                {type === 'photo' &&
                    <Image />
                }
                {type === 'video' &&
                    <Video />
                }
                {type === 'document' &&
                    <File />
                }
                {type === 'audio' &&
                    <Headphones />}
                {type === 'miss_audio' &&
                    <PhoneMissed />
                }
                {type === 'miss_video' &&
                    <Video />
                }
            </div>
            <div className={`message-content-text }`}>
                {content}
            </div>
        </div>

    </>
);

UserMessageContent.propTypes = {
    type: PropTypes.string.isRequired,
};

// UserMessageContent.defaultProps = {
// };

export default UserMessageContent;
