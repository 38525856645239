import React from 'react';
import moment from 'moment';

const ChatDateSeparate = ({ date }) => (
    <div className={`chat-date-separate d-flex justify-content-center align-items-center mb-4 mt-4 `}>
        {moment().format("DD-MM-YYYY") === date ? "TODAY" : date}
    </div>
);

// OutGoingCall.propTypes = {
//     status: PropTypes.string.isRequired,
// };

ChatDateSeparate.defaultProps = {};

export default ChatDateSeparate;
