import * as types from "./actionTypes";
import * as ChatService from "../../service/chatService";
import { TimelineInteraction } from "../../models/TimelineInteraction";

export function getTimeline(customerId, timestamp, limit) {
    return dispatch => {
        ChatService.getInteractionsForCustomer(customerId, timestamp, limit).then(result => {

            if (result) {
                let timelineObj = result.reduce((acc, cur) => {
                    acc[cur.details.id] = new TimelineInteraction(customerId, cur);

                    return acc;
                }, {})
                dispatch(timelineLoaded(customerId, timelineObj));
            } else {
                dispatch(timelineLoaded(customerId, {}));
            }

        }).catch(ex => {
            console.error(ex);
            dispatch(timelineLoaded(customerId, {}));
        });
    }
}

export function timelineLoaded(customerId, timeline) {
    return { type: types.TIMELINE_LOADED, customerId, timeline };
}

export function resetTimeline() {
    return { type: types.RESET_TIMELINE };
}