import React from 'react';
import './cannedMessagePanel.scss';
import {Scrollbars} from "react-custom-scrollbars";


const getTemplateMessageFormatted = (message, trigger) => {

    if (message) {
        let words = message.replace(trigger, `<b style="color:#3F2AA8;">${trigger}</b>`);
        return {__html: words};
    } else {
        return {__html: `<span>${message}</span>`};
    }
};

const CannedMessagePanel = (props) => {

    let matchingElement = props.filtered.map(cannedMessage => {
        return (<div className={'cm-row'} onClick={() => props.onTemplateClick(cannedMessage.TemplateMessage)}>
            <div dangerouslySetInnerHTML=
                     {getTemplateMessageFormatted(cannedMessage.TemplateMessage, cannedMessage.Trigger)}/>

        </div>)
    })
    return (
        <div>
            <div className={'c-message-list'}>
                {matchingElement}
            </div>
        </div>
    )
};


export default CannedMessagePanel;
