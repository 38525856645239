import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { OnlineStatus, ProfileImage } from "../index";

const SelectUser = ({
    src,
    fullName,
    userType,
    firstLatter,
    status,
    icon,
    user, selected,
    onSelectUser, id,
    layout
}) => {

    return (
        <div
            className={`chat-user-wrapper select-user ${layout}  ${selected ? "selected" : ""}`}
        >
            <div
                className={`chat-user-inner-wrp  d-flex justify-content-between`}
                onClick={() => onSelectUser(!selected, user)}>
                <div className={`c-left d-flex`}>
                    <ProfileImage key={id}
                        status={status}
                        firstLatter={fullName ? fullName.split(' ').map(fullName => fullName[0]).join('').toUpperCase() : ""}
                        alt={firstLatter}
                        src={src}
                    />
                    <div className={`user-content-wrp ${selected ? "selected" : ""}`}>
                        <div className={"c-full-name"}>{fullName}</div>

                        {/* <div className={"c-last-msg"}>{userType}</div> */}
                    </div>
                </div>
                <div className={`c-right d-flex align-items-center `}>
                    <div className={`selected-wrapper ${selected ? "selected" : ""}`}>
                        <div
                            className={`select d-flex align-items-center justify-content-center `}
                        >
                            {icon}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

SelectUser.propTypes = {
    fullName: PropTypes.string.isRequired,
    userType: PropTypes.string.isRequired
};

SelectUser.defaultProps = {
    userType: "Agent"
};

export default SelectUser;
