import React, {useState} from 'react';
import {
    MenuIcon
} from '../../common';
import {Link} from "react-router-dom";
import {X} from "react-feather";


function ImagePreview(props) {
    const [isFullScreen, setFullScreen] = useState(false);
    // function setFullScreen(_state) {
    //     console.log('event fire fullscreen mode....');
    //     isFullScreen = _state;
    // }

    return (
        <>
            <div className={`media-g-preview-section image ${isFullScreen ? 'active-full-screen' : ''}`}
                 onClick={() => setFullScreen(true)}>
                <img src={props.src} alt="Umbrella"/>
            </div>
            {isFullScreen &&
            <section className={'gv-full-screen-wrapper'}>
                <div className={'gv-close'}
                     onClick={() => setFullScreen(false)}>
                    <div className={`pg-header-icon-wrp close flex justify-content-center`}>
                        <MenuIcon
                            onClick={(() => setFullScreen(false))}
                            size={'sm-space'}
                            icon={<X/>}/>
                    </div>
                </div>
                <div className={`gv-full-screen-img-wrp ${isFullScreen ? 'active-full-screen' : 'hide-screen'}`}>
                    <img src={props.src} alt="Umbrella"/>
                </div>
            </section>
            }
        </>
    );
}

export default ImagePreview;
