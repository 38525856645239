import React from 'react';
import PropTypes from 'prop-types';
import { OnlineStatus } from "../index";
import navigationRestrictions from "../../../config/navigationRestrictions";

import './styles/profile-image.scss';


const ProfileImage = ({ src, firstLatter, status, size, textType, conversation_type, type, overrideStatusDisable, padding, fontSize, fontWeight, isMeeting }) => {

    let style = { padding: padding, fontSize: fontSize, fontWeight: fontWeight };

    if (textType === 'chatRight') {
        style.background = "rgb(227, 23, 62)";
    }

    return (
        <div className={`user-profile-wrapper ${type} ${textType} ${size}`}>
            <div className={`profile-image-wrapper ${type} ${textType} ${size} ${src == null ? 'active-latter' : ''}`}>
                {src &&
                    <img alt={firstLatter} src={src} />
                }
                {src === null && (conversation_type === 3 ? (<img src={require("../../../assets/img/defaultGroup.svg")}
                    alt="Group Avatar" />) : (isMeeting ? <img src={require('../../../assets/schedule/images/meet-1.png')}
                        alt="Meeting Avatar" /> :
                        (firstLatter ?
                            <div className="n-profileImage d-flex justify-content-center align-items-center"
                                style={{ ...style }}>
                                {firstLatter}
                            </div> :
                            <img src={require("../../../assets/img/defaultUser.svg")}
                                alt="User Avatar" />)))
                    // <div className={`default-img-text ${textType}`}>
                    //     {firstLatter}
                    // </div>
                }
            </div>
            {conversation_type !== 3 && (navigationRestrictions.presence.show_presence.active || overrideStatusDisable) &&
                <OnlineStatus type={type} size={size} status={status} />}
        </div>
    )
};

ProfileImage.propTypes = {};

ProfileImage.defaultProps = {
    src: null,
    firstLatter: 'A',
    status: 'none',
};

export default ProfileImage;
