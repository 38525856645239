import React from 'react';
import PropTypes from 'prop-types';
import {Send} from "react-feather";


const SendButton = ({onClick, title, size, type, disabled}) => (
    <div onClick={onClick}
         className={`send-btn d-flex align-items-center justify-content-center ${size}`}>
        <Send/>
    </div>
);


SendButton.defaultProps = {
    type: 'btn-primary',
    size: 'btn-sm',
    disabled: false,
};

export default SendButton;
