import React from 'react';
import PropTypes from 'prop-types';


const ButtonRounded = ({onClick, title, size, type, disabled}) => (
    <button
        type="button"
        onClick={onClick}
        className={`btn btn-rounded ${size} ${type}`}
        disabled={disabled}>
        {title}
    </button>
);

ButtonRounded.propTypes = {
    onClick: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    size: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
};

ButtonRounded.defaultProps = {
    type: 'btn-primary',
    size: 'btn-sm',
    disabled: false,
};

export default ButtonRounded;
