import React from 'react';
import { ChatFooter, ForwardedLabel, MenuIcon, ProfileImage } from "../index";
import { Popover } from "antd";
import { MoreVertical, Star, RefreshCcw } from "react-feather";
import navigationRestrictions from "../../../config/navigationRestrictions";

const content = ({ onClickReplayMessage, onClickForwardMessage, onDownloadClick, contentUrl, filename, onClickOpenGallery, mediaFiles, msgObj, sendForwardMessages, onClickStar, isStarred }) => {
    return (
        <div className={'chat-hover-menu'}>
            <div
                onClick={() => onClickReplayMessage(msgObj)}
                className={'document-row'}>Reply
            </div>
            {navigationRestrictions.chat.forwarding.active && <div className={'document-row'}
                onClick={() => onClickForwardMessage((users) => {

                    sendForwardMessages(users, msgObj);

                })}>
                Forward message
            </div>}
            {navigationRestrictions.chat.starring.active && <div className={'document-row'}
                onClick={() => onClickStar(msgObj, !isStarred)}>{isStarred ? 'Unstar Message' : 'Star Message'}</div>}
            {mediaFiles && mediaFiles.length > 0 &&
                <div className={'document-row'} onClick={() => onClickOpenGallery(mediaFiles)}>View</div>}
            <div className={'document-row'} onClick={() => onDownloadClick(mediaFiles)}>Download</div>
        </div>
    )
};

const ChatImage = ({
    direction, src, mid, fullName, isForwarded, msg, isImage, lastSeen, imgDesc, lastSeenStatus,
    onClickReplayMessage, onClickForwardMessage, onDownloadClick, imgThumb, contentUrl, filename,
    imgThumb1, imgThumb2, imgThumb3, imgThumb4,
    onClickOpenGallery, mediaFiles, msgObj, sendForwardMessages, onClickStar, isStarred, onMessageResendPressed, ...props
}) => {

    let mediaCount = mediaFiles.length;
    return (
        <div key={mid} id={mid}
            className={`msg d-flex ${direction === "send" ? "right-msg" : "left-msg"} ${isImage ? '' : 'with-out-img'} `}>
            <div className="msg-img">
                <ProfileImage
                    firstLatter={fullName ? fullName.split(' ').map(fullName => fullName[0]).join('').toUpperCase() : ""}
                    textType={`${direction === "send" ? "chatRight" : "chatLeft"}`}
                    size={'sm'}
                    src={src}
                    padding="4px"
                    fontSize="0.8rem" />
            </div>
            <div className={`inner-msg-bubble ${isImage ? null : 'with-out-name'}`}>
                {(props.conversation_type === 3 || props.conversation_type === 4) && direction !== "send" &&
                    <div className="msg-info-name">{fullName}</div>}

                <div className={'msg-text'}>
                    <div className="msg-text-with-image d-flex flex-column">
                        <ForwardedLabel isForwardedMsg={isForwarded} textAlign={direction == "send" ? "right" : "left"} />

                        <div
                            className={`image-wrapper  multiple-media-wrapper d-flex flex-column 
                            ${mediaCount >= 1 ? '' : 'width75'}
                            ${imgDesc ? 'with-replay' : 'default '}`}
                            onClick={() => onClickOpenGallery(mediaFiles)}>

                            {/*===== one image view point =====*/}
                            {mediaCount >= 1 &&
                                <div className={'image-view-wrapper one-image-view'}>
                                    {(!msgObj.isUploading || msgObj.isUploading === 'DONE') && <img src={mediaFiles[0].thumbnail_url}
                                        alt="User Avatar" />}
                                    {msgObj.isUploading === 'UPLOADING' && <div>
                                        <div style={{ marginTop: "95px" }} className="loader9" />
                                        <div style={{ marginLeft: "50px" }}>{"Uploading image..."}</div>

                                    </div>
                                    }
                                    {msgObj.isUploading === 'FAILED' && <div>
                                        <div className={'chat-media-upload-fail'}>{"Upload Failed !!!"}</div>

                                    </div>
                                    }
                                </div>
                            }
                        </div>
                        {msg}
                        <Popover
                            content={content({
                                onClickReplayMessage,
                                onClickForwardMessage,
                                onDownloadClick,
                                contentUrl,
                                filename,
                                onClickOpenGallery,
                                mediaFiles,
                                msgObj,
                                sendForwardMessages,
                                onClickStar,
                                isStarred
                            })}
                            trigger="hover">
                            {(!msgObj.isUploading || msgObj.isUploading === 'DONE') && <div className={'hover-more'}>
                                <MenuIcon
                                    icon={<MoreVertical />} />
                            </div>}
                        </Popover>
                    </div>

                </div>
                <ChatFooter lastSeen={lastSeen}
                    lastSeenStatus={direction === "send" ? lastSeenStatus : null}
                    deliveredStatus={direction === "send" ? msgObj.is_delivered : null}
                    sentStatus={props.direction === "send" ? msgObj.is_sent_successfull : null}
                    isStarred={isStarred} />
            </div>
            {direction === "send" && !lastSeenStatus && !msgObj.is_delivered && msgObj.is_sent_successfull === "FAIL" && <div style={{ marginBottom: "inherit", padding: "7px" }}>
                <MenuIcon onClick={() => onMessageResendPressed(msgObj)}

                    size={"sm-space"}
                    icon={<RefreshCcw />}
                />

            </div>}
        </div>
    )
};

export default ChatImage;
