import React, { Component } from 'react';
import Event from './Event';
import * as auth from "../../../utils";

class Events extends Component {
    constructor(props) {
        super(props);
        this.onLoadViewAll = this.onLoadViewAll.bind(this);
    }

    // componentDidUpdate(prevProps) {
    //     debugger
    //     if(prevProps.events !== this.props.events) {
    //       this.setState({events: this.props.events});
    //     }
    // }

    onLoadViewAll = () => {
        if (this.props.viewStatus) {
            this.props.getDailyEvents(this.props.selectedDate);
        } else {
            this.props.onLoadFutureEvents();
        }

    }

    render() {
        let viewAllText = 'View all from selected date';
        if (this.props.viewStatus) {
            viewAllText = 'View selected date';

        }
        return (
            <>
                <div className="weekly-pinned mb-5">{this.props.header}{this.props.isViewAllEnabled ? <div className="view-all float-right" onClick={this.onLoadViewAll}>{viewAllText}</div> : <div></div>}</div>
                {<div style={{ float: "left", overflowY: "auto", overflowX: "hidden", height: "300px" }}>{this.props.events.map((event) => {
                    let participant = event.participants.find(p => p.participant === auth.getUserId());
                    let myStatus = participant && participant.participantsMeetingStatus ? participant.participantsMeetingStatus[0].status : "";
                    return <Event setUIState={this.props.setUIState} block={event} key={event.id} onSetParticipantState={this.props.onSetParticipantState} status={myStatus} />
                })}</div>}
            </>
        );
    }
}

export default Events;
