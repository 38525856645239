import { postmasterUrl } from "../config/baseUrls";
import * as auth from "../utils";

export class EmailService {

    static SendEmail = (to, from, subject, text, html) => {

        try {

            let url = `${postmasterUrl}/email`;

            fetch(url, {
                method: "POST",
                headers: {
                    "authorization": auth.getBearerToken(),
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ to, from: (from ? from : 'notifications@tetherfi.cloud'), subject, text, html })
            }).then(res => {
                return res.json();
            }).then(data => {
                console.log(`Email send result - ${JSON.stringify(data)}`);
            }).catch(ex => {
                console.error(ex);

            });

        } catch (e) {
            console.error(e);
        }


    }

}
