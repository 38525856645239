import { externalApiInvokerServiceUrl } from "../config/baseUrls";
import { fetchDefaults } from "./fetchWrapper";
var apiFetch = fetchDefaults(fetch);
export class externalApiService {  
  static createCase = (command,postData) => {
    return apiFetch(`${externalApiInvokerServiceUrl}/external/${command}/api`, {
      method: "post",
      body: JSON.stringify(postData)
    })
      .then(function(response) {
        return response.json();
      })
      .then(function(data) {
        return data.isSuccess;
      })
      .catch(error => {
        return false;
      });
  };

  static invokeExternalApi = (postData) => {
    return apiFetch(`${externalApiInvokerServiceUrl}/external/api`, {
      method: "post",
      body: JSON.stringify(postData)
    })
      .then(function(response) {
        return response.json();
      })
      .then(function(data) {
        return data.isSuccess;
      })
      .catch(error => {
        return false;
      });
  };
  
}
