import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import * as teamActions from "../../../redux/actions/teamActions";
import * as presenceActions from "../../../redux/actions/presenceActions";
import * as chatActions from "../../../redux/actions/chatActions";
import * as groupActions from "../../../redux/actions/groupActions";
import * as selectedProfileActions from "../../../redux/actions/selectedProfileActions";
import { getUserId, getSession, getWorkspace } from "../../../utils";
import liveChatClient from "../../../lib";
import {
    PanelTitle,
    ChatFilterTab,
    SearchTextBox, ProfileImage
} from "../../common";
import { Check, ArrowRight, Smile } from "react-feather";
import { Route, Switch } from "react-router-dom";
import ConsoleComponent from "../console/MainConsole";
import CreatNewPanelComponent from "../CreateNewPanel/CreatNewPanel";
import MyProfilePanelComponent from "../MyProfilePanel/MyProfilePanel";
import CreateNewGroup from "../CreateNewGroup/CreateNewGroup";
import { Scrollbars } from "react-custom-scrollbars";
import MyContacts from "../../common/MyContacts/MyContacts";
import { fireEvent } from "../../../utils/eventEmitter";
import * as chatSearchActions from "../../../redux/actions/chatSearchActions";
import renderState from "../../../service/renderStateHandler";
import { resetLastTypingEvent } from "../../../service/typingDetector";
import ActionPanelTitle from "./ActionPanelTitle";
import navigationRestrictions from "../../../config/navigationRestrictions";
import './styles/action_panel.scss';
import './styles/action-panel-responsive.scss';
import presenceStateMap from "../../../config/json/presenseMap";
import IDFCHeader from "./IDFCHeader";
import MobileHeader from "./MobileHeader";
import phoneEngine from "../phone/phoneEngine/phoneEngineHandler";
import * as auth from "../../../utils";
import { message } from "antd/lib/index";
import { __APP_INTERACTION_MODE__ } from "../../../config/baseUrls";

let status = "offline";
let actionPanelTitleRef = null;


const getTeamUser = userId => {
    return async (dispatch, getState) => {
        const state = getState();
        return state.team[userId];
    };
};

function ActionPanelComponent(props) {

    let userType = auth.getUserType();

    let history = useHistory();

    const onSelectionHandler = filterValue => {
        //call event
        fireEvent("FILTER_MY_CONTACTS", filterValue);
        actionPanelTitleRef && actionPanelTitleRef.current.onSelectionChange(filterValue);
    };

    const onSearchHandler = searchValue => {
        //call event
        fireEvent("SEARCH_MY_CONTACTS", searchValue);
    };

    useEffect(() => {
        actionPanelTitleRef = React.createRef();
        liveChatClient.subscribeEvent(
            "groups",
            "onGroupNameModified",
            groupInfo => {
                //Reload Group
                props.group_actions.updateGroupChat(groupInfo);
            }
        );

        liveChatClient.subscribeEvent("groups", "onUserAddedToGroup", groupInfo => {
            props.group_actions.updateGroupChat(groupInfo);
        });

        liveChatClient.subscribeEvent("groups", "onUserLeftGroup", groupInfo => {
            props.group_actions.removeParticipant(groupInfo.GroupId, groupInfo.UserId);
        });

        liveChatClient.subscribeEvent("groups", "onGroupAdminAdded", groupInfo => {
            props.group_actions.groupAdminAdded(groupInfo.GroupId, groupInfo.UserId);
        });

        liveChatClient.subscribeEvent("groups", "onGroupCreated", groupInfo => {
            props.group_actions.createGroupChat(groupInfo);

            console.log(`GROUP_PARTICIPANTS_START: ${JSON.stringify(groupInfo.Participants)}`);

            if (groupInfo.Participants) {
                console.log(`GROUP_PARTICIPANTS_SUCCESS`);
                let groupCreatedUser = groupInfo.Participants.find(usr => usr.IsAdmin && usr.Id === getUserId());

                if (groupCreatedUser) {

                    console.log(`GROUP_IS_CREATED_USER_FOUND`);

                    props.chat_search_actions.resetSearchMessages();
                    renderState.focusToLastRead = true;
                    renderState.scrollReadyState = false;
                    //props.onClickCloseInfoPanel();
                    resetLastTypingEvent();
                    props.selected_profile_actions.profileSelected({
                        username: groupInfo.Id,
                        conversation_type: 3,
                        profileId: null,
                        displayName: groupInfo.Name,
                        conversation_id: null,
                        avatar: null
                    });

                    props.onPressMenuIcon("chat");

                    let chatTextArea = document.getElementById("chat_text_wrapper_main_window");
                    chatTextArea && chatTextArea.focus();

                    props.chat_actions.addNewChatUser({ username: groupInfo.Id });

                    let path = `/console/${groupInfo.Id}`;

                    if (navigationRestrictions.panels.right.fixed) {
                        path = `/console/${groupInfo.Id}/profile`;
                        props.onClickOpenInfoPanel();
                    }

                    history.push(path);

                } else {
                    console.log(`GROUP_IS_CREATED_USER_NOT_FOUND`);
                }
            } else {
                console.log(`GROUP_PARTICIPANTS_ERROR: ${JSON.stringify(groupInfo.Participants)}`);
            }

        });


        liveChatClient.subscribeEvent("groups", "onGroupAdminDismissed", groupInfo => {
            props.group_actions.groupAdminDismissed(groupInfo.GroupId, groupInfo.UserId);
        });

        liveChatClient.subscribeEvent(
            "groups",
            "onUserRemovedFromGroup",
            groupInfo => {
                if (groupInfo.Participants && groupInfo.Participants.length > 0) {
                    if (groupInfo.Participants[0] === getUserId()) {
                        props.group_actions.groupDeleted(groupInfo.GroupId);
                    }
                    props.group_actions.removeParticipant(groupInfo.GroupId, groupInfo.Participants[0]);
                }

            }
        );

        liveChatClient.subscribeEvent("groups", "onGroupDeleted", groupId => {
            props.group_actions.groupDeleted(groupId);
        });

        liveChatClient.subscribeEvent(
            "presence",
            "onPresenceNotification",
            content => {
                let presence = JSON.parse(content);
                if (presence.status === 'online') {
                    props.store_actions
                        .getTeamUser(presence.userid)
                        .then(obj => {
                            if (!obj) {
                                props.team_actions.validateAndAddContact(presence.userid);
                            }
                        });
                }
                if (auth.getUserId() !== presence.userid) {
                    props.presence_actions.receivePresence(presence);
                }

            }
        );

        props.team_actions.getTeamMembers(getUserId());
        //__APP_INTERACTION_MODE__ === "INTERACTION" && props.team_actions.getTemporaryAgents();

        if (navigationRestrictions.presence.active) {
            props.presence_actions.getPresenceAll(getWorkspace(), getUserId());
        }
    }, []);


    if (props.presence_user) {
        let tempStatus = props.presence_user.status ? props.presence_user.status : "offline";
        status = presenceStateMap[tempStatus];

        if (!status) {
            status = "offline";
        }
    }

    const logout = () => {
        try {
            liveChatClient.logoutFromSystem("", "NORMAL", "").then(response => {
                liveChatClient.endSession();
                phoneEngine.phoneFunctions.hangup("caller", "callee");
                auth.userLogOut();
                setTimeout(() => {

                    props.history.push("/logout");
                    window.location.reload();

                }, 2000);

                console.log("ActionPanelComponent", "uireload", "window.location.reload");

            }).catch(ex => {
                console.error('Error in logout : ', ex);
                message.error("Error in logout");
            });

            //window.location.reload();            
        } catch (error) {
            console.error("Fail To Logout", error);
        }
    };

    const checkInteractionACWStatus = () => {
        let interactionKeys = Object.keys(props.interactions);
        for (let i = 0; i < interactionKeys.length; i++) {
            if (!props.interactions[interactionKeys[i]].isACW) {
                return false;
            }
        }
        return true;
    }
    let showLogoutButton = ((!props.call || (props.call && props.call.callStatus === "Idle")) && (props.selected_profile && (!props.selected_profile.username || props.selected_profile.isACW)) && checkInteractionACWStatus());
    return (
        <>
            <Switch>
                <Route path="/console/new/group">
                    <CreateNewGroup
                        viewPointHeight={props.viewPointHeight} />
                </Route>

                <Route path="/console/new">
                    <section className={`action-panel-m-wrapper`}>
                        <div className={"apm-chat-body "}>
                            <CreatNewPanelComponent />
                        </div>
                    </section>
                </Route>

                <Route path="/console/my-profile">
                    <section className={`action-panel-m-wrapper my-profile-wrp`}>
                        <div className={"apm-chat-body"}>
                            <MyProfilePanelComponent
                                onPressMenuIcon={props.onPressMenuIcon}
                                viewPointHeight={props.viewPointHeight} history={props.history} />
                        </div>
                    </section>
                </Route>

                <Route path="/console">
                    <section
                        className={`action-panel-m-wrapper 
                                ${!navigationRestrictions.profile.summerized_info.active ? 'hide-indicator' : 'show-indicator'}
                                 ${props.selected_profile && props.selected_profile.username ? 'hide-action-me' : 'show-action-panel'}`}>
                        {/*===== mobile display =======*/}
                        <MobileHeader
                            status={status}
                            props={props}
                        />
                        {/*==== end ======*/}

                        <IDFCHeader
                            status={status}
                            props={props}
                            showLogoutButton={showLogoutButton}
                        />
                        {/*==== end ======*/}
                        <div className={`apm-header-wrapper 
                                ${navigationRestrictions.profile.summerized_info.active ? 'IDFC-header-pt-0' : ''}`}>
                            <div className={`apm-top-row d-flex justify-content-between filter`}>
                                <ActionPanelTitle
                                    ref={actionPanelTitleRef} onClickSelfView={props.onClickSelfView} />
                                {/* {navigationRestrictions.profile.summerized_info.active && (showLogoutButton ? <button style={{ marginRight: "0px" }} className={`chat-date-separate d-flex justify-content-center align-items-center`} onClick={logout}>
                                    Log Out
                                </button> : <button disabled style={{ marginRight: "0px" }} className={`chat-date-separate d-flex justify-content-center align-items-center`} onClick={logout}>
                                        Log Out
                                </button>)} */}
                                {userType !== 'MEETING' && <div className={"apm-filter-wrapper"}>
                                    <ChatFilterTab onSelectionHandler={onSelectionHandler} />
                                </div>}
                            </div>

                            {navigationRestrictions.contacts.search.active && <div className={`chat-search-wrapper`}>
                                <SearchTextBox
                                    id={"contact_search"}
                                    placeholder={"Search contact here..."}
                                    onChangeHandler={onSearchHandler}
                                />
                            </div>}
                        </div>
                        <MyContacts viewPointHeight={props.viewPointHeight}
                            onClickCloseInfoPanel={props.onClickCloseInfoPanel}
                            summerizedInfo={navigationRestrictions.profile.summerized_info.active}
                            onClickOpenInfoPanel={props.onClickOpenInfoPanel}
                            onPressMenuIcon={props.onPressMenuIcon} />
                    </section>
                </Route>
            </Switch>
        </>
    );
}

ActionPanelComponent.propTypes = {
    team_actions: PropTypes.object.isRequired,
    presence_actions: PropTypes.object.isRequired,
    chat_actions: PropTypes.object.isRequired,
    group_actions: PropTypes.object.isRequired,
    presence_user: PropTypes.object,
    selected_profile: PropTypes.object.isRequired,
};

function mapDispatchToProps(dispatch) {
    return {
        team_actions: bindActionCreators(teamActions, dispatch),
        presence_actions: bindActionCreators(presenceActions, dispatch),
        chat_actions: bindActionCreators(chatActions, dispatch),
        store_actions: bindActionCreators({ getTeamUser }, dispatch),
        group_actions: bindActionCreators(groupActions, dispatch),
        chat_search_actions: bindActionCreators(chatSearchActions, dispatch),
        selected_profile_actions: bindActionCreators(
            selectedProfileActions,
            dispatch
        )
    };
}

function mapStateToProps(state) {
    return {
        selected_profile: state.selected_profile,
        presence_user: state.presence.data[getUserId()],
        my_profile: state.team[getUserId()],
        call: state.call,
        interactions: state.interactions
    };

}

export default connect(mapStateToProps, mapDispatchToProps)(ActionPanelComponent);
