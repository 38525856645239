import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { X, Check } from "react-feather";
import { ChatUser, MenuIcon, TitleWithSmallText, UserImage, ParticipationImage } from "../index";
import { Scrollbars } from "react-custom-scrollbars";
import uuid from "uuid/v1";
import * as auth from "../../../utils";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as chatActions from "./../../../redux/actions/chatActions";
import * as audioRecorderActions from "../../../redux/actions/audioRecordActions";
import CountdownTimer from "./countdownTimer";
import { recordDurations } from "../../../config/json/fileTypeMap";
import * as eventEmitter from "../../../utils/eventEmitter";

//online
//offline
//busy
//other
let recorder;
let sendMethod = 'HALF_REC';
let recordingStateGlobal = false;
let recordingStopped = false;

function VoiceRecorder(props) {
    const [audio, setAudio] = useState();
    const [recordingState, setRecordingState] = useState("offline");
    const [recordedFile, setRecordedFile] = useState();

    const getMicrophone = async () => {
        const audio = await navigator.mediaDevices.getUserMedia({
            audio: true,
            video: false
        });
        recorder = new MediaRecorder(audio, { mimeType: "audio/webm" });
        // listen to dataavailable, which gets triggered whenever we have
        // an audio blob available
        recorder.addEventListener("dataavailable", e => {
            //alert("dataavailable");
            try {
                setRecordingState("recordingComplete");
                //var audio = document.getElementById("audio");
                setRecordedFile(e.data);
                if (sendMethod === 'HALF_REC') {
                    recordingStateGlobal = false;
                    recordingStopped = false;
                    upload(e.data);
                    props.onClickCloseVoiceRecorder();
                    audio.getTracks().forEach(track => track.stop());
                }
                // e.data contains a blob representing the recording
                //audio.src = URL.createObjectURL(e.data);
                //audio.play();
            } catch (ex) {
                //alert(`Fail to process data: ${ex.message}`)
                console.error("Fail To Process data", ex);
            }
        });
        //setRecorder(recorder);
        setAudio(audio);
    };
    const startRecording = async () => {
        try {
            //alert("rec");
            await getMicrophone();
            recorder.start();
            //setRecordingState("recording");
        } catch (ex) {
            //alert(`err rec : ${ex.message}`);
            console.error("Fail To Stop Recording", ex);
        }
    };

    const clearRecording = () => {
        recordingStateGlobal = false;
        recordingStopped = false;
        try {
            sendMethod = 'STOP_REC';
            audio.getTracks().forEach(track => track.stop());
            recorder.stop();
            setAudio(null);
            setRecordedFile(null);
            setRecordedFile('offline');
        } catch (ex) {
            console.error("Fail To Clear Recording", ex);
        }
        props.onClickCloseVoiceRecorder();
    };

    const stopRecording = () => {
        try {
            if (!recordingStopped) {
                recordingStopped = true;
                sendMethod = 'FULL_REC';
                audio.getTracks().forEach(track => track.stop());
                recorder.stop();
                setAudio(null);

            }

        } catch (ex) {
            console.error("Fail To Stop Recording", ex);
        }
    };

    const sendRecording = () => {
        try {
            if (audio === null && recordedFile) {
                upload(recordedFile);
                recordingStateGlobal = false;
                recordingStopped = false;
                props.onClickCloseVoiceRecorder();
            } else {
                sendMethod = 'HALF_REC';
                audio.getTracks().forEach(track => track.stop());
                recorder.stop();
                setAudio(null);
            }
        } catch (ex) {
            console.error("Fail To Stop Recording", ex);
            props.onClickCloseVoiceRecorder();
        }
    };

    const upload = (fileData) => {
        //alert("UPLOAD");
        try {
            const fileId = uuid();
            const message = {
                mid: fileId,
                sid: fileId,
                message_type: "10",
                from: {
                    id: auth.getUserId(),
                    name: auth.getLoggedInUser()
                },
                to: {
                    id: props.selected_profile.username,
                    name: props.selected_profile.username
                },
                channel_type: "audio/webm",
                created_at: Date.now(),
                message_content: "",
                audioBlob: (recordedFile) ? recordedFile : fileData,
                startedAt: Date.now(),
                thumbnailBlob: null,
                duration: 20,
                thumbUrl: null,
                conversation_id: props.selected_profile.conversation_id,
                conversation_type: props.selected_profile.conversation_type
            };
            props.chat_actions.sendNewMessageSuccess(message);
            eventEmitter.fireEvent("scrollbar", { scrollbar_status: "down" })
        } catch (ex) {
            //(`UPLOAD: ${ex.message}`);
            console.error(ex);
        }
    };

    const mounted = useRef();
    useEffect(() => {
        if (!recordingStateGlobal) {
            recordingStateGlobal = true;
            startRecording();
        }

        /* if (!mounted.current) {
            mounted.current = true;
            startRecording();
        } */
    }, []);

    return (
        <>
            <div className={'voice-recorder-wrapper d-flex justify-content-between'}>
                <div className={'voice-action-btn red'}
                    onClick={clearRecording}>
                    <MenuIcon
                        icon={<X />} />
                </div>
                <div className={'voice-timer-section'}>
                    <CountdownTimer
                        timeLimit={recordDurations / 1000}
                        completed={stopRecording} />
                    <div className={'voice-record-live'} />
                </div>
                <div onClick={sendRecording} className={'voice-action-btn green'}>
                    <MenuIcon icon={<Check />} />
                </div>
            </div>
        </>
    );
}

// OutGoingCall.propTypes = {
//     status: PropTypes.string.isRequired,
// };

VoiceRecorder.defaultProps = {};

function mapStateToProps(state) {
    return {
        selected_profile: state.selected_profile,
        audio_record_status: state.audio_record_status
    };
}

function mapDispatchToProps(dispatch) {
    return {
        chat_actions: bindActionCreators(chatActions, dispatch),
        audio_record_actions: bindActionCreators(audioRecorderActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(VoiceRecorder);
