import React from 'react';
import { ProfileImage } from "../index";
import { Popover } from 'antd';
import { MenuIcon, ChatFooter, ForwardedLabel } from '..';
import { MoreVertical, Star, RefreshCcw } from 'react-feather';
import navigationRestrictions from "../../../config/navigationRestrictions";

const content = ({ onClickReplayMessage, onClickForwardMessage, msgObj, sendForwardMessages, onClickStar, isStarred }) => (
    <div className={'chat-hover-menu'}>
        <div
            onClick={() => onClickReplayMessage(msgObj)}
            className={'document-row'}>Reply
        </div>
        {navigationRestrictions.chat.forwarding.active && <div className={'document-row'}
            onClick={() => onClickForwardMessage((users) => {

                sendForwardMessages(users, msgObj);

            })}>
            Forward message
        </div>}
        {navigationRestrictions.chat.starring.active && <div className={'document-row'}
            onClick={() => onClickStar(msgObj, !isStarred)}>{isStarred ? 'Unstar Message' : 'Star Message'}</div>}
    </div>
);

const ChatMessage = ({
    src, fullName, msg, isImage, lastSeen, lastSeenStatus, mid, onClickReplayMessage, onClickForwardMessage,
    isForwardedMsg,
    isLink, msgObj, sendForwardMessages, onClickStar, isStarred, onMessageResendPressed, ...props
}) => {

    let messageElement = <a rel="noopener noreferrer" href={msg} target="_blank">{msg}</a>;
    let splitmsg = msg.split(/(?:\r\n|\r|\n)/g);

    let normalMessage = splitmsg.map(msg => {
        return (<p style={{ whiteSpace: "pre-wrap" }}>{msg}</p>)
    });

    return (

        <div key={mid} id={mid} className={`msg d-flex ${props.direction === "send" ? "right-msg" : "left-msg"} ${isImage ? '' : 'with-out-img'} `}>
            <div className="msg-img">
                <ProfileImage
                    textType={`${props.direction === "send" ? "chatRight" : "chatLeft"}`}
                    firstLatter={fullName ? fullName.split(' ').map(fullName => fullName[0]).join('').toUpperCase() : ""}
                    size={'sm'}
                    src={src}
                    padding="4px"
                    fontSize="0.8rem" />
            </div>
            <div className={`inner-msg-bubble`}>
                {(props.conversation_type === 3 || props.conversation_type === 4) && props.direction !== "send" && <div className="msg-info-name">{fullName}</div>}

                <div className={`msg-text text-message ${props.direction === "send" ? "msg-text-right" : ""}`}>
                    {/*forwarded message */}
                    <ForwardedLabel
                        textAlign={`${props.direction === "send" ? "right" : "left"}`}
                        isForwardedMsg={props.isForwarded} />
                    {/*end */}
                    <>
                        {isLink ? messageElement : normalMessage}
                    </>

                    <Popover
                        content={content({
                            onClickReplayMessage,
                            onClickForwardMessage,
                            msgObj,
                            sendForwardMessages,
                            onClickStar,
                            isStarred
                        })}
                        trigger="hover">
                        <div className={'hover-more'}>
                            <MenuIcon
                                icon={<MoreVertical />} />
                        </div>
                    </Popover>
                </div>
                {/*${lastSeen ? null : 'with-out-seen'}*/}

                <ChatFooter lastSeen={lastSeen}
                    lastSeenStatus={props.direction === "send" ? lastSeenStatus : null}
                    deliveredStatus={props.direction === "send" ? msgObj.is_delivered : null}
                    sentStatus={props.direction === "send" ? msgObj.is_sent_successfull : null}
                    isStarred={isStarred} />

            </div>
            {props.direction === "send" && !lastSeenStatus && !msgObj.is_delivered && msgObj.is_sent_successfull === "FAIL" && <div style={{ marginBottom: "inherit", padding: "7px" }}>
                <MenuIcon onClick={() => onMessageResendPressed(msgObj)}

                    size={"sm-space"}
                    icon={<RefreshCcw />}
                />

            </div>}

        </div>
    )
};

ChatMessage.defaultProps = {
    src: null,
};

export default ChatMessage;
