import { __APP_CHECK_MESSAGE_SEQ__ } from "../config/baseUrls";

let __MESSAGE_SEQUENCE_RECEIVED__ = {};

let __MESSAGE_SEQUENCE_SENT__ = {};

/* let __MESSAGE_SEQUENCE_RECEIVED_BUFFER__ = {}; */

export const setNextSendSequenceNumber = (conversationId) => {

    if (__MESSAGE_SEQUENCE_SENT__[conversationId]) {
        __MESSAGE_SEQUENCE_SENT__[conversationId].seq++;
        return __MESSAGE_SEQUENCE_SENT__[conversationId].seq;

    } else {
        __MESSAGE_SEQUENCE_SENT__[conversationId] = { seq: 1 }
        return 1;
    }

}

/* export const extractReceivedBuffer = (conversationId) => {
    let buffer = __MESSAGE_SEQUENCE_RECEIVED_BUFFER__[conversationId];
    delete __MESSAGE_SEQUENCE_RECEIVED_BUFFER__[conversationId];
    return buffer;
} */

export const checkReceiveSequence = (conversationId, sender, message) => {

    if (__APP_CHECK_MESSAGE_SEQ__ === "YES" && message.seq) {
        let partyId = sender ? `${conversationId}_${sender}` : conversationId;
        if (__MESSAGE_SEQUENCE_RECEIVED__[partyId]) {
            let prevMessage = __MESSAGE_SEQUENCE_RECEIVED__[partyId];
            let expectingSeq = prevMessage.seq + 1;

            if (expectingSeq < message.seq) {

                let created_at = prevMessage.created_at;

                /* __MESSAGE_SEQUENCE_RECEIVED_BUFFER__[conversationId] = { ...__MESSAGE_SEQUENCE_RECEIVED_BUFFER__[conversationId], ...message }; */

                delete __MESSAGE_SEQUENCE_RECEIVED__[partyId];
                return { created_at, load_history: true };

                //LOAD DATA FROM HISTORY
                //RESET COUNTS - DELETE
            } else {
                __MESSAGE_SEQUENCE_RECEIVED__[partyId] = {
                    seq: message.seq,
                    created_at: message.created_at
                }
                return { created_at: null, load_history: false };
            }

        } else {
            __MESSAGE_SEQUENCE_RECEIVED__[partyId] = {
                seq: message.seq,
                created_at: message.created_at
            }
            return { created_at: null, load_history: false };
        }
    } else {
        return { created_at: null, load_history: false };
    }
}