import React, { useEffect, useState } from "react";

import { useSelector } from "react-redux";

const ActiveCaller = ({ calleeName }) => {
  const state = useSelector((state) => state);
  const [activeSpeech, setActiveSpeech] = useState(calleeName);

  useEffect(() => {
    try {
      if (state.callControls.userId && state.callControls.userName) {
        setActiveSpeech(state.callControls.userName);
      }
    } catch (ex) {
      console.error(ex);
    }
  }, [state.callControls.controls.isVoiceActivityDetected]);

  return <>{activeSpeech} </>;
};
export default ActiveCaller;
