import React, { Component } from "react";
import { connect } from "react-redux";
import { Participant } from '../../../lib/MeetingsSdk/Participant';
import * as auth from "../../../utils";

class Participants extends Component {
    state = {
        value: ''
    }
    constructor(props) {
        super(props);
        this.onSearch = this.onSearch.bind(this);
        this.onHandleParticipants = this.onHandleParticipants.bind(this);

        /* this.participants = props.participants; */
    }

    onSearch = (event) => {
        this.setState({ value: event.target.value });
    }

    onHandleParticipants = (user, e) => {
        if (e.target.checked) {
            let participant = new Participant({
                participant: user.userId,
                type: 'Required',
                participantType: user.userType,
                email: user.emailId
            });
            this.props.onSelectParticipant(participant, true);
        } else {
            let participant = new Participant({
                participant: user.userId,
                type: 'Required',
                participantType: user.userType,
                email: user.emailId
            });
            this.props.onSelectParticipant(participant, false);
        }

    }

    onLoadParticipants() {
        try {
            const { value } = this.state;
            const lowercasedValue = value.toLowerCase();
            // eslint-disable-next-line array-callback-return
            let users = this.props.users && Object.keys(this.props.users).reduce((acc, key) => {
                if (auth.getUserId() !== key) {
                    if (this.props.users[key].hasOwnProperty('userId')) {
                        if ((this.props.users[key]['firstName'] &&
                            this.props.users[key]['firstName'].toLowerCase().includes(lowercasedValue)) ||
                            (this.props.users[key]['lastName'] &&
                                this.props.users[key]['lastName'].toLowerCase().includes(lowercasedValue))) {
                            acc.push(this.props.users[key]);
                        }
                    } else {
                        if ((this.props.users[key]['Name'] &&
                            this.props.users[key]['Name'].toLowerCase().includes(lowercasedValue))) {
                            acc.push(this.props.users[key]);
                        }
                    }

                }

                return acc;
            }, []);
            return users;

        } catch (error) {
            console.log(error);
        }
    }

    componentDidMount() {


        /*  try {
             const self = this;
             fetch('./JSON/user.json', {
                 headers: {
                     'Content-Type': 'application/json',
                     'Accept': 'application/json'
                 }
             }).then(function (response) {
                 return response.json();
             }).then(function (result) {
                 self.setState({
                     "users": result
                 });
             });
         } catch (error) {
             console.log(error);
         } */

    }

    render() {

        let renderedUsers = this.onLoadParticipants();

        return (
            <>
                <div className="search-box float-left">
                    <fieldset className="field-container">
                        <input type="text" placeholder="Add Guests" className="field" onChange={this.onSearch} />
                        <div className="icons-container">
                            <div className="icon-search"></div>
                        </div>
                    </fieldset>
                </div>
                <div className="group-list float-left width">
                    {renderedUsers && Object.keys(renderedUsers).map((key) => {
                        // eslint-disable-next-line no-lone-blocks
                        {
                            if (renderedUsers[key]) {
                                return (

                                    <div className="friend-drawer friend-drawer--onhover" key={renderedUsers[key].hasOwnProperty('userId')
                                        ? renderedUsers[key]['userId'] : renderedUsers[key]['Id']}>
                                        <img className="profile-image" src={renderedUsers[key]['avatarURL']} alt="" />
                                        <div className="text">
                                            <h6>{renderedUsers[key].hasOwnProperty('userId')
                                                ? renderedUsers[key]['firstName'] + ' ' + renderedUsers[key]['lastName'] : renderedUsers[key]['Name']}</h6>
                                        </div>
                                        <span className="checed-search">
                                            <label className="form-check-label col-sm-12 float-left">
                                                <input className="styled-checkbox web-rectangle ronchi-border-1px" type="checkbox"
                                                    defaultChecked={renderedUsers[key].hasOwnProperty('userId') ?
                                                        this.props.participants && this.props.participants.filter(x => x.participant === renderedUsers[key]['userId']).length > 0
                                                        : this.props.participants && this.props.participants.filter(x => x.participant === renderedUsers[key]['Id']).length > 0}
                                                    id={renderedUsers[key].hasOwnProperty('userId') ? 'chk_' + renderedUsers[key]['userId'] : 'chk_' + renderedUsers[key]['Id']}
                                                    name={renderedUsers[key].hasOwnProperty('userId') ? 'chk_' + renderedUsers[key]['userId'] : 'chk_' + renderedUsers[key]['Id']} onClick={(e) => this.onHandleParticipants(renderedUsers[key], e)} />
                                                <label htmlFor={renderedUsers[key].hasOwnProperty('userId') ? 'chk_' + renderedUsers[key]['userId'] : 'chk_' + renderedUsers[key]['Id']}></label>
                                            </label>

                                        </span>
                                    </div>
                                );
                            } else {
                                return null;
                            }
                        }
                    })}
                </div>
            </>);
    }

}


function mapStateToProps(state) {

    return {
        users: state.team
    };
}

export default connect(mapStateToProps)(Participants);
