import React from 'react';
import PropTypes from 'prop-types';
import { ProfileImage, TitleWithSmallText, Button } from "../index";
import MissedCallButton from "./MissedCallButton";


const ChatCenterMessage = ({ messageType, OnClickOutGoingCall, title, msg, icon,enableCallBackFeature, ...props }) => (
    <div id={props.msgObj.mid} key={props.msgObj.mid} className={`center-message-wrapper d-flex justify-content-between ${messageType}`}>

        <div className={'msg-left-side d-flex justify-content-center align-items-center '}>
            <div className={'msg-icon-wrp d-flex justify-content-center align-items-center'}>
                {icon}
            </div>
            <div className={`d-flex flex-column text-wrapper`}>
                <TitleWithSmallText
                    type={`sm-text`}
                    title={title}
                    msg={msg} />
            </div>
        </div>
        {enableCallBackFeature && <MissedCallButton OnClickOutGoingCall={OnClickOutGoingCall} callTo={props.selected_profile ? props.selected_profile.username : null} />}

    </div>
);

ChatCenterMessage.propTypes = {
    messageType: PropTypes.string.isRequired,
};

ChatCenterMessage.defaultProps = {
    src: null,
};

export default ChatCenterMessage;
