import * as types from "../actions/actionTypes";
import produce from "immer";

export const groupReducer = produce((draft = { UserId: "", LastGroupListId: 0, GroupListInfo: {} }, action) => {
    switch (action.type) {

        case types.GROUP_CHAT_CREATED:
            {
                if (draft.GroupListInfo[action.groupInfo.Id]) {
                    draft.GroupListInfo[action.groupInfo.Id] = action.groupInfo;

                } else {
                    draft.GroupListInfo = { ...{ [action.groupInfo.Id]: action.groupInfo }, ...draft.GroupListInfo };
                }

                return draft;
            }
        case types.GROUP_CHAT_UPDATED:
            {
                if (draft.GroupListInfo[action.groupInfo.Id]) {
                    draft.GroupListInfo[action.groupInfo.Id] = action.groupInfo;
                } else {
                    draft.GroupListInfo = { ...{ [action.groupInfo.Id]: action.groupInfo }, ...draft.GroupListInfo };
                }

                return draft;
            }
        case types.GROUP_LIST_LOADED:
            {
                draft = action.groupList;
                /* draft.UserId = action.groupList.UserId;
                draft.LastGroupListId = action.groupList.LastGroupListId;

                draft.GroupListInfo = { ...draft.GroupListInfo, ...action.groupList.GroupListInfo }; */
                return draft;

            }
        case types.GROUP_DELETED:
            {
                delete draft.GroupListInfo[action.groupId];
                return draft;

            }
        case types.ADD_PARTICIPANT:
            {
                if (draft.GroupListInfo[action.info.groupId]) {
                    if (draft.GroupListInfo[action.info.groupId].Participants.filter(usr => usr.Id === action.info.participant.Id) === null) {
                        draft.GroupListInfo[action.info.groupId].Participants.push(action.info.participant);
                    }

                    let index = draft.GroupListInfo[action.info.groupId].removed_users.indexOf(action.info.participant.Id);
                    if (index > -1) {
                        draft.GroupListInfo[action.info.groupId].removed_users.splice(index);
                    }

                    draft.GroupListInfo[action.info.groupId].added_users.push(action.info.participant);

                }
                return draft;

            }
        case types.REMOVE_PARTICIPANT:
            {
                if (draft.GroupListInfo[action.info.groupId]) {

                    let newUserList = draft.GroupListInfo[action.info.groupId].Participants.filter(obj => {
                        return obj.Id !== action.info.userId;
                    });

                    draft.GroupListInfo[action.info.groupId].Participants = newUserList;

                }
                return draft;

            }
        case types.GROUP_ADMIN_ADDED:
            {
                if (draft.GroupListInfo[action.info.groupId]) {

                    let newUserList = draft.GroupListInfo[action.info.groupId].Participants.map(obj => {
                        if (obj.Id === action.info.userId) {
                            obj.IsAdmin = true;
                        }
                        return obj;
                    });

                    draft.GroupListInfo[action.info.groupId].Participants = newUserList;

                }
                return draft;

            }
        case types.GROUP_ADMIN_DISMISSED:
            {
                if (draft.GroupListInfo[action.info.groupId]) {

                    let newUserList = draft.GroupListInfo[action.info.groupId].Participants.map(obj => {
                        if (obj.Id === action.info.userId) {
                            obj.IsAdmin = false;
                        }
                        return obj;
                    });

                    draft.GroupListInfo[action.info.groupId].Participants = newUserList;

                }
                return draft;

            }

        default:
            return draft;
    }
});