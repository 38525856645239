import React from 'react';
import PropTypes from 'prop-types';
import { OnlineStatus, ProfileImage } from "../index";

import './styles/UserWithFullName.scss'
import SelectedUserWrapper from "./SelectedUserWrapper";


const UserWithFullName = ({
    src,
    fullName,
    lastSeen,
    firstLatter,
    status,
    isTyping,
    active,
    conversation_type,
    onClick,
    type,
    isMeeting
}) => {
    let padding = "7px";
    let fontSize = "1.2rem";

    if (type === 'proHeaderMobile') {
        padding = "4px";
        fontSize = "0.8rem";
    }
    return (
        <div className={`d-flex user-with-name-wrapper ${type}`}
            onClick={onClick}>
            <ProfileImage
                status={status}
                type={type}
                conversation_type={conversation_type}
                firstLatter={fullName ? fullName.split(' ').map(fullName => fullName[0]).join('').toUpperCase() : ""}
                alt={firstLatter} src={src}
                padding={padding}
                fontSize={fontSize}
                isMeeting={isMeeting} />
            <div className={`user-content-wrp ${type} ${active ? 'active' : ''}`}>
                <div className={`c-full-name`}>
                    {fullName}
                </div>
                {!isTyping &&
                    <div className={`c-last-msg`}>
                        {lastSeen}
                    </div>
                }
                {isTyping &&
                    <div className={`c-last-msg typing ${type}`}>
                        typing...
            </div>
                }
            </div>
        </div>
    )
};

// UserWithFullName.propTypes = {
//     isTyping: PropTypes.bool.isRequired,
// };

UserWithFullName.defaultProps = {
    src: null,
    firstLatter: 'A',
    status: 'none',
    active: false
};

export default UserWithFullName;
