import React from "react";
import {
  MenuIcon,
  PanelTitle,
  TextBoxWithIcon,
  InviteWithText,
  ParticipationUser,
} from "../../common";
import { Video, PhoneMissed, X, Plus } from "react-feather";
import { Scrollbars } from "react-custom-scrollbars";

function ParticipationListComponent(props) {
  function onPressChatFilterIcon(_filter) {
    console.log("event fire...");
    console.log(_filter);
  }

  return (
    <div className={`prt-main-wrapper`}>
      <div className={`prt-header`}>
        <div className={"modal-header d-flex"}>
          <PanelTitle title={"Participants"} />
          <MenuIcon
            icon={<X />}
            onClick={props.onClickHideParticipation}
            size={"sm"}
          />
        </div>
      </div>
      <div className={"prt-body"}>
        <div className={`prt-user-wrapper`}>
          <Scrollbars autoHide thumbMinSize={0}>
            {Object.values(props.userDetails).map((item) => {
              return (
                <div className={"prt-user-row mt-2"}>
                  <ParticipationUser
                    src={item.avatarUrl}
                    userId={item.userId}
                    fullName={item.name}
                    mic={item.audio}
                    video={item.audio}
                    active={item.active}
                    status={"connected"}
                    size={"md-30"}
                    userType={item.userType}
                  />
                </div>
              );
            })}
          </Scrollbars>
        </div>
      </div>
    </div>
  );
}

export default ParticipationListComponent;
