import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import uuid from "uuid/v1";
import * as auth from "../../../utils";
import * as fileService from "../../../service/fileService";
import { subscribeToEvents, fireEvent } from "../../../utils/eventEmitter";
import { message } from "antd/lib/index";

let _video = "";
let _canvas = "";

const VideoElement = ({
  videoRef,
  size,
  userState,
  active,
  title,
  isVideoMinimizeScreen, audioDeviceId
}) => {
  const [videoSize, setVideoSize] = useState(size);

  useEffect(() => {
    _video = document.getElementById('test123');
    _canvas = document.getElementById('video_player_canvas');

    _video.addEventListener('canplay', function (ev) {
      _canvas.setAttribute('width', _video.videoWidth);
      _canvas.setAttribute('height', _video.videoHeight);
    }, false);
    /* const audio = document.getElementById("test123")
    audio.setSinkId(audioDeviceId);
    console.log('Audio is being played on ' + audio.sinkId); */
  }, [audioDeviceId]);

  useEffect(() => {
    subscribeToEvents('capture_snapshot', data => {

      message.info("Capturing snapshot - please wait...");

      var context = _canvas.getContext('2d');
      if (_canvas.width && _canvas.height) {
        context.drawImage(_video, 0, 0, _canvas.width, _canvas.height);

        _canvas.toBlob((blob => {
          let file = new File([blob], "SelfView.png", {
            type: "image/png",
          });
          let fileId = uuid();
          let fileObj = {
            file_id: fileId,
            file: file,
            name: file.name,
            size: file.size,
            type: file.type,
            caption: ""
          };
          let cancelTokenSource = axios.CancelToken.source();
          let conversation_id = [data.acceptOrRejectObject.requesterUserId, data.acceptOrRejectObject.userId].sort().join('_')

          fileService.uploadFilePromise(file, fileId, conversation_id, cancelTokenSource, null, fileObj.type).then(uploadResult => {
            if (uploadResult.data.isSuccess && uploadResult.data.result) {
              let url = uploadResult.data.result.streamURL;
              sendMessage(uploadResult.data.result, data.acceptOrRejectObject);
              data.acceptOrRejectObject.accept && data.acceptOrRejectObject.accept(url);
              message.success("Snapshot captured");
            } else {
              message.error("Snapshot capture failed");
              console.error('File upload failed');
              data.acceptOrRejectObject.reject && data.acceptOrRejectObject.reject();
            }
          }).catch(ex => {
            message.error("Snapshot capture failed");
            data.acceptOrRejectObject.reject && data.acceptOrRejectObject.reject();
            console.error(ex);
          })
        }));
      }


    })

  }, []);

  let sendMessage = (uploadData, notificationData) => {

    fireEvent("send_snapshot", { uploadData, notificationData, snapshotType: "SELFVIEW_SNAPSHOT" });
  }


  return (
    <>
      <canvas id="video_player_canvas" style={{ display: "none" }}></canvas>
      <div
        title={title}
        onClick={() => {
          try {
            setVideoSize(videoSize === "video" ? "video" : "video");
          } catch (error) {
            console.error(error);
          }
        }}
        className={`Participation-image ${isVideoMinimizeScreen ? "minimizer-screen" : "full-screen"
          } ${active ? "active" : ""} `}
        style={{
          cursor: "not-allowed",
        }}
      >
        <video id='test123' ref={videoRef} autoPlay muted playsInline></video>
        {!isVideoMinimizeScreen && (
          <div
            className={`video-element-name ${isVideoMinimizeScreen ? "minimizer-screen" : "full-screen"
              }`}
          >
            {title}
          </div>
        )}
      </div>
    </>
  );
};

VideoElement.propTypes = {
  videoRef: PropTypes.any.isRequired,
  size: PropTypes.string.isRequired,
  userState: PropTypes.string.isRequired,
  active: PropTypes.bool.isRequired,
};

VideoElement.defaultProps = {
  videoRef: null,
  userState: "connected",
  active: false,
};

export default VideoElement;
