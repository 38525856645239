import React, { useState, useEffect } from "react";
import { Frown, Wifi, WifiOff, Instagram ,Mic ,MicOff,Video,VideoOff } from "react-feather";
import { connect } from "react-redux";
import "./connectivity-status.scss";
import { Row, Col, Modal, Drawer,Tag } from "antd";
import DeviceValidating from "../DeviceValidating/DeviceValidating";
import * as auth from "../../../utils";
import * as phoneConfig from '../../../config/phoneConfig';
import packageJson from "../../../../package.json";
import navigationRestrictions from "../../../config/navigationRestrictions";


let isAllowedVideoCall = phoneConfig? phoneConfig.isAllowedVideoCall : false;

const ConnectivityStatusIndicator = (props) => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    console.log("ConnectivityStatusIndicator", "Calling useEffect Method");    
    const customData = auth.getCustomData();
    if (customData && customData.isVideo !== null) {
      isAllowedVideoCall = customData.isVideo;
    }
  }, []);

  const showModal = () => {
    console.log(
      "ConnectivityStatusIndicator",
      "showModal",
      "device validation called"
    );
    setVisible(true);
  };

  const handleOk = (e) => {
    setVisible(false);
  };

  const handleCancel = (e) => {
    setVisible(false);
  };
  const validationResult = (isValidationPass) => {
    try {
      console.log(
        "ConnectivityStatusIndicator",
        "validationResult",
        `isValidationPass:${isValidationPass}`
      );
      if (isValidationPass) {
        setVisible(false);
      }
    } catch (error) {
      console.error("DeviceValidation", "validationResult", error);
    }
  };

  const reload = () => {
    try {
      window.location.reload();
      console.log("ConnectivityStatusIndicator", "uireload", `ui reloaded`);
    } catch (error) {
      console.error("DeviceValidation", "reload", error);
    }
  };

  return (
    <Row justify="end">
      {isAllowedVideoCall && navigationRestrictions.profile.summerized_info.active === true && props.call && props.call.callStatus === "Idle" && navigationRestrictions.calls.active && navigationRestrictions.calls.video_calls.active && phoneConfig.basic_config.calls.selfView.active === true &&(
          <Col span={6}>
          <div title="Self View" className={"connectivity-status self-view"}  onClick={(() => props.onClickSelfView())}>
              <Instagram />
            </div>         
        </Col>
        )}
      <Col span={navigationRestrictions.profile.summerized_info.active === true? 6:8}>
        {props.connectivity && (
          <div className={"connectivity-status wifi-on"}>
            <Wifi />
          </div>
        )}
        {!props.connectivity && (
          <div className={"connectivity-status wifi-off"}>
            <WifiOff />
          </div>
        )}
      </Col>
      {isAllowedVideoCall && phoneConfig && phoneConfig.basic_config.console && phoneConfig.basic_config.console.mediaDevice &&   phoneConfig.basic_config.console.mediaDevice.active === true &&(
        <>
          <Col span={navigationRestrictions.profile.summerized_info.active === true? 6:8}>
            {props.mediaDevices.isMicrophoneAlreadyCaptured === true  && (
              <div className={"connectivity-status wifi-on"}>
                <Mic                color="#00FF00"   size={20}/>
              </div>
            )}
            {props.mediaDevices.isMicrophoneAlreadyCaptured !== true && (
              <div
                className={"connectivity-status wifi-on "}
                onClick={showModal}
                style={{ cursor: "pointer" }}
              >
                <MicOff
                  color="red" style={{ cursor: "pointer" }} size={20}
                  onClick={showModal}
                  
                />

              </div>
            )}
          </Col>

          <Col span={navigationRestrictions.profile.summerized_info.active === true? 6:8}>
            {props.mediaDevices.isWebcamAlreadyCaptured === true && (
              <div className={"connectivity-status wifi-on"}>
                <Video  color="#00FF00"  size={20}/>
              </div>
            )}
            {props.mediaDevices.isWebcamAlreadyCaptured !== true && (
              <div
                className={"connectivity-status wifi-on "}
                onClick={showModal}
                style={{ cursor: "pointer" }}
              >
                <VideoOff
                color="red" style={{ cursor: "pointer" }} size={20}
                onClick={showModal}
                />

              </div>
            )}
          </Col>
        </>
      )}

      

      <Drawer
        width={"100%"}
        height={"100%"}
        title="Device Access"
        placement="right"
        closable={false}
        onClose={() => setVisible(false)}
        visible={visible}
      >
        {visible && (
          <DeviceValidating
            deviceStatus={validationResult}
            phoneEngine={props.phoneEngine}
            reload={reload}
            cancel={handleCancel}
            visible={visible}
          />
        )}
      </Drawer>
      <Col >
        <Tag align="left" color="geekblue">{packageJson.version}</Tag>
      </Col>
      
    </Row>
    
  );
};

function mapStateToProps(state) {
  return {
    connectivity: state.connectivity,
    mediaDevices: state.callControls,
    call: state.call,
  };
}

export default connect(mapStateToProps)(ConnectivityStatusIndicator);
