import { presenceApi } from "./presenceApi.js";
import { contactsApi } from "./contactsApi.js";
import { userAccountApi } from "./userAccountApi.js";
import { fileApi } from "./fileApi.js";
import { conversationApi } from "./conversationApi.js";
import { MeetingSDK } from "./MeetingsSdk";
import { captchaApi } from "./captchaApi.js";

export class ApiFactory {
    constructor(config, livechat) {
        this.presence = new presenceApi(config, livechat);
        this.contacts = new contactsApi(config, livechat);
        this.user_accounts = new userAccountApi(config, livechat);
        this.file = new fileApi(config, livechat);
        this.conversations = new conversationApi(config, livechat);
        this.meetings = new MeetingSDK(config, livechat);
        this.captchaApi = new captchaApi(config, livechat);
    }
}