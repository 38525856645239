import React from 'react';
import { connect } from "react-redux";
import navigationRestrictions from "../../../config/navigationRestrictions";

//online
//offline
//busy
//other
const PanelTitle = ({ title, size, type, ...props }) => (
    <>
        <div className={`panel-title  ${type} ${size}`}>
            {title}
        </div>
        {props.is_chat_ready === 'LOADING' && !navigationRestrictions.contacts.switcher.active && <div className={'active'} style={{ marginLeft: "166px", marginTop: "11px" }}>
            <div className={'pre-loader'}>
                <div className={'pre-loader-login'}>
                    <div className="loader9" />
                    <div style={{ fontSize: "0.7rem", marginLeft: "6px" }}>{"Chat Loading - Please Wait"}</div>
                </div>
            </div>
        </div>}
    </>
);

function mapStateToProps(state) {
    return { is_chat_ready: state.ui_panel_data.action_panel.chat_ready };
}

export default connect(mapStateToProps)(PanelTitle);
