import React from "react";
import navigationRestrictions from "../../../config/navigationRestrictions";

const SessionTakeOverLogout = props => {

    return (
        <>

            <section className={`main-login-wrapper`}>
                <div className={'top-left-leaf-bg'}>
                    <img src={require("../../../assets/img/bg/login/topRightLeaf.svg")}
                        alt="User Avatar" />
                </div>

                {/*====== login from main wrapper start here ========*/}
                <div className={'container'}>
                    <div className={'login-layout-wrp d-flex justify-content-between'}>
                        <div className="sign-up-form-main-wrapper ">
                            <div className="sign-up-form-inner-wrapper">
                                <div className={`sign-up-form`}>
                                    <div className={'character-wrp error-page text-center'}>
                                        <img src={require("../../../assets/img/bg/login/MainCharacter.svg")}
                                            alt="User Avatar" />
                                    </div>
                                    <div className={"sign-in-body-wrapper text-center"}>
                                        <div className={'text-wrapper connectivityStatusMsg '}>
                                            {`Your session has terminated`}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={'login-footer-bg-wrapper d-flex'}>
                    <div className={'left-plant'}>
                        <img src={require("../../../assets/img/bg/login/footerPlantLeft.svg")}
                            alt="User Avatar" />
                    </div>
                    <div className={'transparent-plant'}>
                        <img src={require("../../../assets/img/bg/login/transparentPlant.svg")}
                            alt="User Avatar" />
                    </div>
                    <div className={'left-single-plant'}>
                        <img src={require("../../../assets/img/bg/login/singlePlant.svg")}
                            alt="User Avatar" />
                    </div>

                    <div className={'right-plant'}>
                        <img src={require("../../../assets/img/bg/login/rightPlant.svg")}
                            alt="User Avatar" />
                    </div>

                </div>

                <div className={'sky-bg'}>
                    <img src={require("../../../assets/img/bg/login/skyBg.svg")}
                        alt="User Avatar" />
                </div>

                <div className={'left-round-wrp'} />
                <div className={'top-bg-round-wrp'} />


                <div className={'stars-wrapper'}>
                    <img src={require("../../../assets/img/bg/login/stars.svg")}
                        alt="User Avatar" />
                </div>
                <div className={'stars-wrapper-2'}>
                    <img src={require("../../../assets/img/bg/login/starsOnly.svg")}
                        alt="User Avatar" />
                </div>

            </section>

        </>
    );
};

export default SessionTakeOverLogout;
