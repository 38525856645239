export const fileTypeMap = {
  "image/jpeg": "jpgFile.svg",
  "image/png": "pngFiles.svg",
  "image/jpg": "jpgFile.svg",
  "document/pdf": "pdfFile.svg",
  "application/pdf": "pdfFile.svg",
  "video/mp4": "mp4File.svg",
  "audio/mp3": "mp3Files.svg",
  "audio/mpeg": "mp3Files.svg",
  "video/avi": "aviFiles.svg"
};

export const fileTypeMapCss = {
  "image/jpeg": "jpeg",
  "image/png": "png",
  "image/jpg": "jpg",
  "document/pdf": "pdf",
  "application/pdf": "pdf",
  "video/mp4": "video",
  "audio/mp3": "audio",
  "audio/mpeg": "audio",
  "video/avi": "video"
};

/* export const imageTypes = ["image/jpg", "image/jpeg", "image/bmp", "image/png"]; */
export const imageTypes = window.env.REACT_APP_DATA_IMAGE_TYPES.split(',');
export const documentTypes = window.env.REACT_APP_DATA_DOCUMENT_TYPES.split(',');
/* export const videoTypes = [
  "video/mp4",
  "video/3gp",
  "video/webm",
  "video/avi",
  "video/webm;codecs=vp9",
  "video/webm;codecs=h264"
]; */
export const videoTypes = window.env.REACT_APP_DATA_VIDEO_TYPES.split(',');
/* export const audioTypes = ["audio/mp3", "audio/webm", "audio/webm;codecs=opus", "audio/mpeg"]; */
export const audioTypes = window.env.REACT_APP_DATA_AUDIO_TYPES.split(',');

export const recordDurations = 10000;

export const videoPreviewSupported = ['video/mp4', 'video/webm'];
