import React, { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import moment from "moment";
import { connect, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import ChatUser from "./ChatUser";
import * as chatActions from "../../../redux/actions/chatActions";
import * as typingActions from "../../../redux/actions/typingActions";
import * as selectedProfileActions from "../../../redux/actions/selectedProfileActions";
import * as interactionActions from "../../../redux/actions/interactionActions";
import * as presenceActions from "../../../redux/actions/presenceActions";
import * as clientActions from "../../../redux/actions/clientActions";
import presenceStateMap from "../../../config/json/presenseMap";
import { resetLastTypingEvent } from "../../../service/typingDetector";
import * as auth from "../../../utils";
import * as chatSearchActions from "../../../redux/actions/chatSearchActions";
import renderState from "../../../service/renderStateHandler";
import { checkLastTypingEvent } from "../../../service/typingDetector";
import { imageTypes, audioTypes, documentTypes, videoTypes } from "../../../config/json/fileTypeMap";
import {
    UserMessageContent
} from '../../common';
import { __APP_TYPING_RECEIVE_CHECK_TIMEOUT__ } from "../../../config/baseUrls";
import navigationRestrictions from "../../../config/navigationRestrictions";
import * as whiteboardActions from '../../../redux/actions/whiteboardActions';

moment.updateLocale("en", {
    calendar: {
        lastDay: "[Yesterday]",
        sameDay: "LT",
        nextDay: "[Tomorrow]",
        lastWeek: "dddd",
        nextWeek: "dddd",
        sameElse: "L"
    }
});

moment.relativeTimeThreshold('s', 60);
moment.relativeTimeThreshold('m', 60);
moment.relativeTimeThreshold('h', 24);
moment.relativeTimeThreshold('d', 30);
moment.relativeTimeThreshold('M', 365);

const ChatUserWrapper = props => {

    const dispatch = useDispatch();

    useEffect(() => {

        if (__APP_TYPING_RECEIVE_CHECK_TIMEOUT__ !== 0 && __APP_TYPING_RECEIVE_CHECK_TIMEOUT__ !== "0") {

            let interval = null;
            if (!interval) {
                interval = setInterval(() => {
                    console.log(`%c [-------- TYPING INTERVAL RUNNING : ${props.contact_id} ------- ]`, 'background: #000000; color: #ffff00');
                    if (checkLastTypingEvent(props.contact_id)) {
                        //set typing to stop
                        console.log(`%c [-------- STOP TYPING ------- ]`, 'background: #000000; color: #ffff00');
                        props.typing_actions.removeTyping({ user_id: props.contact_id });
                    }
                }, __APP_TYPING_RECEIVE_CHECK_TIMEOUT__);
            }

            return () => {
                console.log(`%c [-------- CLEAR TYPING INTERVAL ------- ]`, 'background: #000000; color: #ffff00');
                clearInterval(interval);
                interval = null;
            }

        }



    }, []);

    let history = useHistory();
    let location = useLocation();
    let latestEventTime = null;
    let status = "offline";
    let timeAgo = "";
    let userPresence = props.presence_user;
    //let chatCount = 0;
    let loggedUserId = auth.getUserId();
    let unseenCount = 0;
    let lastMessage = "";

    if (props.chat_user) {
        if (props.chat_user.unseen_count) {
            unseenCount = props.chat_user.unseen_count;
        }
        if (props.chat_user.created_at) {
            timeAgo = moment(
                moment.unix(props.chat_user.created_at / 1000)
            ).calendar();
        }

        let msgContent = props.chat_user.message_content;
        let msgType = props.chat_user.message_type;

        if (!msgContent) {
            if (msgType) {
                //Call From
                if (msgType === "CHAT_MISSED_AUDIO_CALL") {
                    msgContent = <UserMessageContent type='miss_audio'
                        content={props.chat_user.sender_id === loggedUserId ? `Missed call` : `${props.fullName} Not Answered`} />;
                } else if (imageTypes.indexOf(msgType) > -1 || msgType === 'SIGNATURE_SNAPSHOT' || msgType === 'SELFVIEW_SNAPSHOT') {
                    msgContent = <UserMessageContent type='photo' content={'Photo'} />;
                } else if (videoTypes.indexOf(msgType) > -1 || msgType === "11") {
                    msgContent = <UserMessageContent type='video' content={'Video'} />;
                } else if (documentTypes.indexOf(msgType) > -1) {
                    msgContent = <UserMessageContent type='document' content={'Document'} />;
                } else if (audioTypes.indexOf(msgType) > -1 || msgType === "10") {
                    msgContent = <UserMessageContent type='audio' content={'Audio'} />;
                } else if (msgType === "CHAT_MISSED_VIDEO_CALL") {
                    msgContent = <UserMessageContent type='miss_video'
                        content={props.chat_user.sender_id === loggedUserId ? `Missed call` : `${props.fullName} Not Answered`} />;
                    //from  ${props.fullName}
                } else if (msgType === "COBROWSE_REQUEST") {
                    msgContent = `Co-Browse initiated`;
                }
                else if (msgType === "WHITEBOARD_REQUEST") {
                    msgContent = `User joined whiteboard session`;

                }
            }
        }

        lastMessage = msgContent;

        /* try {
          const msg = JSON.parse(props.chat_user.message_content);
          if (msg.callee)
            lastMessage = msg.callee === loggedUserId ? msg.to : msg.from;
        } catch (error) { } */
    }

    let profileClickHandler = (
        userId,
        conversation_type,
        profileId,
        displayName,
        conversation_id,
        avatar,
        isACW,
        isTemp,
        createdAt,
        userData,
        InteractionId,
        InteractionInfo
    ) => {
        props.onPressMenuIcon('chat');
        props.whiteboard.wbState === 'OPENED' && props.whiteboard_actions.minimizeWhiteboard();
        //dispatch
        if (!(props.selected_profile && props.selected_profile.username === userId)) {
            props.chat_search_actions.resetSearchMessages();
            renderState.focusToLastRead = true;
            renderState.scrollReadyState = false;
            //props.onClickCloseInfoPanel();
            resetLastTypingEvent();
            props.selected_profile_actions.profileSelected({
                username: userId,
                conversation_type,
                profileId,
                displayName,
                conversation_id,
                avatar,
                isTemp,
                isACW,
                createdAt,
                userData,
                InteractionId,
                InteractionInfo: InteractionInfo
            });

            let chatTextArea = document.getElementById("chat_text_wrapper_main_window");
            chatTextArea && chatTextArea.focus();

            let chatObjUser = props.chat_user;

            if (chatObjUser) {
                if (!props.chat_user.is_loaded) {
                    //let loggedUserId = auth.getUserId();

                    let prev_timestamp = chatObjUser.unread_timestamp
                        ? chatObjUser.unread_timestamp
                        : (chatObjUser.created_at ? chatObjUser.created_at + 1 : Date.now());

                    if (chatObjUser.last_message_seen || chatObjUser.unread_timestamp) {
                        props.chat_actions.getInitialMessages("tetherfi",
                            conversation_type,
                            loggedUserId,
                            userId,
                            prev_timestamp,
                            50,
                            true);
                    } else {
                        props.chat_actions.getOldMessages(
                            "tetherfi",
                            conversation_type,
                            loggedUserId,
                            userId,
                            prev_timestamp,
                            50,
                            true
                        );
                    }
                }
            } else {
                //Create Dummy
                props.chat_actions.addNewChatUser({ username: userId });
            }

            let path = `/console/${userId}`;

            if (location.pathname.match(/\/console\/(.+?)\//)) {
                path = path + '/';
                path = location.pathname.replace(/\/console\/(.+?)\//, path)

            } else {
                if (navigationRestrictions.panels.right.fixed) {
                    path = `/console/${userId}/profile`;
                    props.onClickOpenInfoPanel();
                }
            }

            history.push(path);
        }
    };

    if (userPresence) {
        /* if (userPresence.connectivity === 2) {
                latestEventTime = userPresence.connectivityAt > userPresence.presenceAt ? userPresence.connectivityAt : userPresence.presenceAt;
                timeAgo = moment().from(latestEventTime);
            } else if (userPresence.connectivity === 1) {
                timeAgo = "online";
            } else {
                if (userPresence.status !== "online") {
                    if (userPresence.presenceAt) {
                        timeAgo = moment().from(userPresence.presenceAt);
                    } else {
                        timeAgo = "offline";
                    }
                } else {
                    timeAgo = "online";
                }
            } */

        let tempConnectivity = userPresence.connectivity
            ? userPresence.connectivity
            : 2;
        let tempStatus = userPresence.status ? userPresence.status : "offline";
        status = presenceStateMap[tempStatus];

        if (!status) {
            status = "offline";
        }
    }

    if (props.conversation_type === 3 || props.conversation_type === 4) {
        status = null;
    }

    let isTyping = false;

    if (props.typing_status && props.typing_status.is_typing) {
        isTyping = true;

        lastMessage = "typing...";

        if (props.conversation_type === 3) {
            lastMessage = `${props.typing_status.typing_user} is typing...`;
        }
    }

    return (
        <div id={`chat_user_wrapper_${props.contact_id}`}
            onClick={() =>
                props.is_chat_ready !== 'LOADING' && profileClickHandler(
                    props.contact_id,
                    props.conversation_type,
                    null,
                    props.fullName,
                    null,
                    props.src,
                    props.isACW,
                    props.isTemp,
                    props.createdAt,
                    props.userData,
                    props.InteractionId,
                    props.user
                )
            }
        >
            <ChatUser
                conversation_type={props.conversation_type}
                src={props.src}
                imgSize={'sm'}
                fullName={props.fullName}
                lstMsg={lastMessage}
                lastSeen={timeAgo}
                count={unseenCount}
                isTyping={isTyping}
                status={status}
                active={
                    props.selected_profile.username === props.contact_id ? true : false
                }
                isMeeting={props.isMeeting}
                activePlayer={props.whiteboard.wbState !== 'CLOSED' && props.whiteboard.contactId === props.contact_id ? 'whiteboard' : ''}
            />
        </div>
    );
};

function mapStateToProps(state, ownProps) {
    return {
        selected_profile: state.selected_profile,
        chat_user: state.chats[ownProps.contact_id],
        presence_user: state.presence.data[ownProps.contact_id],
        typing_status: state.typing_status[ownProps.contact_id],
        is_chat_ready: state.ui_panel_data.action_panel.chat_ready,
        whiteboard: state.whiteboard
    };
}

function mapDispatchToProps(dispatch) {
    return {
        chat_actions: bindActionCreators(chatActions, dispatch),
        presence_actions: bindActionCreators(presenceActions, dispatch),
        selected_profile_actions: bindActionCreators(
            selectedProfileActions,
            dispatch
        ),
        interaction_actions: bindActionCreators(interactionActions, dispatch),
        client_actions: bindActionCreators(clientActions, dispatch),
        chat_search_actions: bindActionCreators(chatSearchActions, dispatch),
        typing_actions: bindActionCreators(typingActions, dispatch),
        whiteboard_actions: bindActionCreators(whiteboardActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ChatUserWrapper);
