const channelMap = {
  "3": "line",
  "6": "linkedin",
  "5": "twitter",
  "4": "facebook",
  "7": "email",
  "1": "phone",
  "8": "primary",
  "2": "webchat",
  "9": "bot",
  "10": "other",
  "11": "sdk"
};

export default channelMap;
