import * as types from "./actionTypes";
import * as uiPanelActions from "./uiPanelActions";
import * as timelineActions from "./timelineActions";
import navigationRestrictions from "../../config/navigationRestrictions";

export function profileSelected(profile) {
  return dispatch => {

    if ((navigationRestrictions.selected_user_info.active && profile.conversation_type === 4 && !(profile.InteractionInfo ? profile.InteractionInfo.IsMeeting : false))) {
      dispatch(uiPanelActions.changeProfileTab("0"));
    } else {
      dispatch(uiPanelActions.changeProfileTab(navigationRestrictions.media.recent_media.active ? "2" : (navigationRestrictions.timeline.active ? "1" : "3")));
    }

    let action = uiPanelActions.closeMediaUpload();
    dispatch(action);

    dispatch({ type: types.PROFILE_SELECTED, profile });
    if (navigationRestrictions.timeline.active && profile.InteractionInfo) {
      dispatch(timelineActions.resetTimeline());
      dispatch(timelineActions.getTimeline(profile.InteractionInfo.CustomerUserId, Date.now(), 10));
    }


  };
}

export function setAcwState(interactionId) {
  return { type: types.SET_SELECTED_PROFILE_ACW, interactionId };
}
