import React from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import AppRouter from "./routes/AppRouter";
import "../src/assets/stylesheets/_vendor/animation.css";
import "../src/assets/icon/feather/iconfont.css";
import "../node_modules/material-design-icons/iconfont/material-icons.css";
import "antd/dist/antd.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/schedule/css/bootstrap-extend.min.css";
import "./app.css";
import "./assets/schedule/css/all.css";
import "./assets/schedule/css/lightpick.css";
import "./assets/schedule/css/custom.css";
import "./assets/schedule/css/responsive.css";
import "../src/assets/stylesheets/_vendor/animation.css";
import * as auth from "../src/utils";
import navigationRestrictions from "../src/config/navigationRestrictions";

function App() {
  auth.setPasswordPolicy({
    passwordPolicy: navigationRestrictions.login.password_policy.passwordPolicy,
    policyNames: navigationRestrictions.login.password_policy.policyNames,
    minLength: navigationRestrictions.login.password_policy.minLength,
    maxLength: navigationRestrictions.login.password_policy.maxLength,
    enableForLoginPage: navigationRestrictions.login.password_policy.enableForLoginPage,
  });

  return (
    <>
      <Router>
        <AppRouter />
      </Router>
    </>
  );
}

export default App;
