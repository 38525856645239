import React, { useState } from "react";
import { DeviceNotSupported } from "../../common";
import { Spin } from "antd";
import { message } from "antd/lib/index";
import ScrollAnimation from "react-animate-on-scroll";

import "./styles/user-landing.scss";
import "./styles/forgotpwd-responsive.scss";

import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import AuthService from "../../../service/authService.js";
import { useForm } from "react-hook-form";

const authService = new AuthService();

const ForgotPasswordComponent = (props) => {
  const { register, handleSubmit, errors, watch, reset } = useForm();

  const [isLoading, setIsLoading] = useState(false);
  const [isLinkSend, setIsLinkSend] = useState(false);
  const [resendCount, setResendCount] = useState(0);

  const { executeRecaptcha } = useGoogleReCaptcha();

  const resetPassword = async (formData) => {
    try {
      setResendCount(resendCount + 1);
      if (resendCount > 3) {
        reset();
        message.error(`your exceeding maximum resend count`);
        return;
      }
      setIsLoading(true);
      let token = await executeRecaptcha("forgotPassword");
      let reply = await authService.checkAccountAvailability(
        formData.userName,
        token
      );
      if (!reply.isSuccess) {
        token = await executeRecaptcha("forgotPassword");
        reply = await authService.passwordReset(formData.userName, token);
        if (reply.isSuccess) {
          setIsLoading(true);
          setIsLinkSend(true);
          reset();
          message.success(`Password Reset Link Send To ${formData.userName}`);
          return;
        }
        setIsLoading(false);
        reset();
        message.error(
          `Fail To Send Password Reset Link To ${formData.userName}`
        );
        return;
      }
      reset();
      setIsLoading(false);
      message.error(`Invalidate Email Address ${formData.userName}`);
    } catch (e) {
      reset();
      setIsLoading(false);
      console.error(
        `Fail To Send Password Reset Link To ${formData.userName}`,
        e
      );
      return false;
    }
  };

  return (
    <>
      <DeviceNotSupported />
      <section className={`main-login-wrapper sign-up`}>
        <div className={"top-left-leaf-bg"}>
          <img
            src={require("../../../assets/img/bg/login/topRightLeaf.svg")}
            alt="User Avatar"
          />
        </div>

        {/*====== login from main wrapper start here ========*/}
        <div className={"container"}>
          <div className={"login-layout-wrp  d-flex justify-content-between"}>
            {/*======== login form start here ========*/}
            <div className="sign-up-form-main-wrapper ">
              <div className="sign-up-form-inner-wrapper">
                <div>
                  <div className={`sign-up-form`}>
                    {/*<div className={'sign-up-character'}>*/}
                    {/*    <img src={require("../../../assets/img/bg/login/MainCharacter.svg")}/>*/}
                    {/*</div>*/}
                    <ScrollAnimation
                      delay="150"
                      animateIn="fadeInUp"
                      animateOut="fadeOutDown"
                    >
                      <div className={`login-header-wrapper mt-5 text-center`}>
                        <h2 className={"text-center"}>Recover Password</h2>
                        <small>Don't worry , happens to the best of us.</small>
                      </div>
                    </ScrollAnimation>

                    <div className={"sign-in-body-wrapper mt-5"}>
                      <form
                        className={"fadeIn "}
                        onSubmit={handleSubmit(resetPassword)}
                      >
                        <ScrollAnimation
                          delay="80"
                          animateIn="fadeIn"
                          animateOut="fadeOut"
                        >
                          <div className="form-group">
                            <form className={"fadeIn"}>
                              <label htmlFor="exampleInputEmail1">
                                Email Address <span>*</span>
                              </label>
                              <input
                                type="email"
                                id="userName"
                                name="userName"
                                className="form-control form-control-lg txt-login"
                                aria-describedby="emailHelp"
                                placeholder="john@example.com"
                                maxLength={50}
                                ref={register({
                                  required: {
                                    value: true,
                                    message: "Email is required",
                                  },
                                  minLength: {
                                    value: 7,
                                    message:
                                      "Email must be at least 7 characters",
                                  },
                                  pattern: {
                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                    message:
                                      "Email must be a valid email address pattern",
                                  },
                                })}
                              />
                              {errors.userName && (
                                <div className={"error-text"}>
                                  <small style={{ color: "red" }}>
                                    {errors.userName.message}
                                  </small>
                                </div>
                              )}
                            </form>
                          </div>
                        </ScrollAnimation>
                        <div className={`login-button-wrapper`}>
                          <ScrollAnimation
                            delay="120"
                            animateIn="fadeInUp"
                            animateOut="fadeOutDown"
                          >
                            <div className={`login-bnt-hide`}>
                              {isLoading && !isLinkSend && <Spin />}
                              {!isLoading && !isLinkSend && (
                                <button
                                  type="submit"
                                  className="btn btn-rounded btn-lg btn-block btn-primary"
                                >
                                  Send
                                </button>
                              )}

                              {isLinkSend && (
                                <button
                                  type="button"
                                  className="btn btn-rounded btn-lg btn-block btn-secondary"
                                  disabled
                                >
                                  Please check your inbox
                                </button>
                              )}
                            </div>
                          </ScrollAnimation>
                        </div>
                        <div className={`login-button-wrapper`}>
                          <ScrollAnimation
                            delay="120"
                            animateIn="fadeInUp"
                            animateOut="fadeOutDown"
                          >
                            <div className={`login-bnt-hide`}>                              
                            <button
                                  type="button"
                                  className="btn btn-rounded btn-lg btn-block btn-secondary"
                                  onClick={()=> props.history.push("/")}
                                >
                                  Back
                                </button>
                            </div>
                          </ScrollAnimation>
                        </div>
                      </form>

                      <div className={"login-footer-wrp"}>
                        {/*<div className={'version'}>*/}
                        {/*    version <span>1.0.4</span>*/}
                        {/*</div>*/}
                        <div className={"rights version-text"}>
                          © 2020 tetherfi, All rights reserved.
                        </div>
                      </div>
                    </div>
                    <div className={"login-form-bg-sm"} />
                  </div>

                  <div className={"login-form-bg-lg hide-mobile"} />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={"login-footer-bg-wrapper d-flex"}>
          <div className={"left-plant"}>
            <img
              src={require("../../../assets/img/bg/login/footerPlantLeft.svg")}
              alt="User Avatar"
            />
          </div>
          <div className={"transparent-plant"}>
            <img
              src={require("../../../assets/img/bg/login/transparentPlant.svg")}
              alt="User Avatar"
            />
          </div>
          <div className={"left-single-plant"}>
            <img
              src={require("../../../assets/img/bg/login/singlePlant.svg")}
              alt="User Avatar"
            />
          </div>

          <div className={"right-plant"}>
            <img
              src={require("../../../assets/img/bg/login/rightPlant.svg")}
              alt="User Avatar"
            />
          </div>
        </div>

        <div className={"sky-bg"}>
          <img
            src={require("../../../assets/img/bg/login/skyBg.svg")}
            alt="User Avatar"
          />
        </div>

        <div className={"left-round-wrp"} />
        <div className={"top-bg-round-wrp"} />

        <div className={"stars-wrapper"}>
          <img
            src={require("../../../assets/img/bg/login/stars.svg")}
            alt="User Avatar"
          />
        </div>
        <div className={"stars-wrapper-2"}>
          <img
            src={require("../../../assets/img/bg/login/starsOnly.svg")}
            alt="User Avatar"
          />
        </div>
      </section>
    </>
  );
};

export default ForgotPasswordComponent;
