import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {OnlineStatus, ProfileImage, MenuIcon} from "../index";
import {
    Mic,
    MicOff,
    Video,
    VideoOff,
    User,
    Trash,
    MoreVertical,
} from "react-feather";
import {Popover} from "antd";

import {useSelector} from "react-redux";

const content = ({
                     showGroupExitConfirm,
                     isAdmin,
                     makeAdmin,
                     dismissAdmin,
                 }) => (
    <div className={"chat-hover-menu"}>
        {isAdmin && (
            <p className={"document-row"} onClick={dismissAdmin}>
                Dismiss as admin
            </p>
        )}
        {!isAdmin && (
            <p className={"document-row"} onClick={makeAdmin}>
                Make group admin
            </p>
        )}
        <p className={"document-row"} onClick={showGroupExitConfirm}>
            Remove
        </p>
    </div>
);

const ParticipationUser = ({
                               src,
                               fullName,
                               userType,
                               mic,
                               video,
                               status,
                               size,
                               active,
                               onDelete,
                               isLoggedUser,
                               layout,
                               isAdmin,
                               showGroupExitConfirm,
                               isLoggedUserAdmin,
                               makeAdmin,
                               dismissAdmin,
                               userId,
                           }) => {
    const state = useSelector((state) => state);
    const [isMuted, setIsMuted] = useState(false);
    const [isVideoMuted, setIsVideoMuted] = useState(false);

    useEffect(() => {
        try {
            if (
                state.callControls.userId === userId &&
                state.callControls.isRemoteMuted
            ) {
                if (
                    state.callControls.callType === "audio" ||
                    state.callControls.callType === "AUDIO_CALL"
                )
                    setIsMuted(state.callControls.isMuted);
                if (
                    state.callControls.callType === "video" ||
                    state.callControls.callType === "VIDEO_CALL"
                )
                    setIsVideoMuted(state.callControls.isMuted);
            }
        } catch (ex) {
            console.error(ex);
        }
    }, [state.callControls.controls.isRemoteMuted]);

    useEffect(() => {
        try {
            if (
                state.callControls.userId === userId &&
                state.callControls.isRemoteUnmuted
            ) {
                if (
                    state.callControls.callType === "audio" ||
                    state.callControls.callType === "AUDIO_CALL"
                )
                    setIsMuted(state.callControls.isMuted);
                if (
                    state.callControls.callType === "video" ||
                    state.callControls.callType === "VIDEO_CALL"
                )
                    setIsVideoMuted(state.callControls.isMuted);
            }
        } catch (ex) {
            console.error(ex);
        }
    }, [state.callControls.controls.isRemoteUnmuted]);

    useEffect(() => {
        try {
            if (
                state.callControls.userId === userId &&
                state.callControls.isCallMuted
            ) {
                if (
                    state.callControls.callType === "audio" ||
                    state.callControls.callType === "AUDIO_CALL"
                )
                    setIsMuted(state.callControls.isMuted);
                if (
                    state.callControls.callType === "video" ||
                    state.callControls.callType === "VIDEO_CALL"
                )
                    setIsVideoMuted(state.callControls.isMuted);
            }
        } catch (ex) {
            console.error(ex);
        }
    }, [state.callControls.controls.isCallMuted]);

    useEffect(() => {
        try {
            if (
                state.callControls.userId === userId &&
                state.callControls.isVideoMuted
            ) {
                if (
                    state.callControls.callType === "audio" ||
                    state.callControls.callType === "AUDIO_CALL"
                )
                    setIsMuted(state.callControls.isMuted);
                if (
                    state.callControls.callType === "video" ||
                    state.callControls.callType === "VIDEO_CALL"
                )
                    setIsVideoMuted(state.callControls.isMuted);
            }
        } catch (ex) {
            console.error(ex);
        }
    }, [state.callControls.controls.isVideoMuted]);

    return (
        <div className={`chat-user-wrapper  ${layout}`}>
            <div className={`chat-user-inner-wrp modal-user`}>
                <div className={`c-left d-flex justify-content-between`}>
                    <div className={`left-wrp d-flex ${status} `}>
                        <ProfileImage
                            status={status}
                            overrideStatusDisable={true}
                            size={size}
                            firstLatter={fullName ? fullName.split(' ').map(fullName => fullName[0]).join('').toUpperCase() : ""}
                            alt={fullName}
                            src={null}
                            padding="7px"
                            fontWeight="800"
                            fontSize="0.8rem"
                        />
                        <div className={`prt-user-content-wrp`}>
                            <div className={`c-full-name sm ${status}`}>
                                {isLoggedUser ? "Me" : fullName}{" "}
                                {isAdmin ? (
                                    <span style={{color: "#499c00"}}>{"[Group Admin]"}</span>
                                ) : (
                                    ""
                                )}
                            </div>
                            <div className={`c-last-msg ${status}`}>{userType}</div>
                        </div>
                    </div>


                    {!isLoggedUser && isLoggedUserAdmin && (
                        // <div className={`right-wrp user-delete-icon icon-wrapper d-flex`} onClick={showGroupExitConfirm}>
                        //     <div className={'icon-wrp mic-wrp delete-user-icon'}>
                        //         <MenuIcon
                        //             size={'md'}
                        //             icon={<Trash />} />
                        //     </div>
                        // </div>

                        <Popover
                            content={content({
                                showGroupExitConfirm,
                                isAdmin,
                                makeAdmin,
                                dismissAdmin,
                            })}
                            placement="left"
                            trigger="hover"
                        >
                            <div className={`right-wrp user-delete-icon icon-wrapper d-flex`}>
                                <div className={"icon-wrp mic-wrp more-user-icon"}>
                                    <MenuIcon size={"md"} icon={<MoreVertical/>}/>
                                </div>
                            </div>
                        </Popover>
                    )}

                    {status !== "user-info" && (
                        <div
                            className={`right-wrp icon-wrapper d-flex ${status} ${
                                active ? "active-user" : ""
                            } `}
                        >
                            <div className={"icon-wrp mic-wrp"}>
                                {!isMuted && <MenuIcon icon={<Mic/>}/>}
                                {isMuted && <MenuIcon icon={<MicOff color={"red"}/>}/>}
                            </div>
                            <div className={"icon-wrp video-wrp"}>
                                {!isVideoMuted && <MenuIcon icon={<Video/>}/>}
                                {isVideoMuted && <MenuIcon icon={<VideoOff color={"red"}/>}/>}
                            </div>
                        </div>
                    )}

                    {/*{isLoggedUser&&*/}
                    {/*<div className={`right-wrp icon-wrapper d-flex ${active ? 'display-block admin ' : 'display-none'} `}>*/}
                    {/*    <div className={'icon-wrp mic-wrp'}>*/}
                    {/*        <MenuIcon icon={<User/>}/>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    {/*}*/}
                </div>
            </div>
        </div>
    );
};

ParticipationUser.propTypes = {
    fullName: PropTypes.string.isRequired,
    userType: PropTypes.string.isRequired,
};

ParticipationUser.defaultProps = {
    userType: "Agent",
};

export default ParticipationUser;
