import React from 'react';
import PropTypes from 'prop-types';
import { MenuIcon } from "../index";
import { Star } from "react-feather";


const ChatFooter = ({ lastSeen, isStarred, lastSeenStatus, deliveredStatus, sentStatus }) => (
    <>
        <div className={`last-seen d-flex ${lastSeenStatus ? 'with-last-seen' : 'with-out-last-seen'}  `}>
            {lastSeen !== null &&
                <div className={`right-seen ${isStarred ? 'with-star' : 'with-out'}`}>
                    {lastSeen}
                </div>
            }
            <>

                {isStarred &&
                    <div className={`starred-msg-text d-flex `}>
                        <div className={`separate-line ${(lastSeenStatus || deliveredStatus || (sentStatus === "SUCCESS" || sentStatus === "FAIL" || sentStatus === "PENDING")) ? 'with-last-seen' : 'with-out-last-seen'}`} />
                        <div
                            className={`star-icon-wrp d-flex ${(lastSeenStatus || deliveredStatus || (sentStatus === "SUCCESS" || sentStatus === "FAIL" || sentStatus === "PENDING")) ? 'with-last-seen' : 'with-out-last-seen'}  `}>
                            <div className={'star-icon'}>
                                <MenuIcon
                                    iconType={'material'}
                                    icon={<i className="material-icons">star</i>} />
                            </div>
                        </div>

                        {(lastSeenStatus || deliveredStatus || (sentStatus === "SUCCESS" || sentStatus === "FAIL" || sentStatus === "PENDING")) &&
                            <div className={`separate-line right`} />
                        }
                    </div>
                }
                <>
                    {lastSeenStatus &&
                        <div>
                            <i className="material-icons">done_all</i>
                        </div>
                    }

                    {!lastSeenStatus && deliveredStatus &&
                        <div>
                            <i className="material-icons">done</i>
                        </div>
                    }

                    {!lastSeenStatus && !deliveredStatus && sentStatus === "FAIL" &&
                        <div>
                            <i className="material-icons chat-send-fail">highlight_off</i>
                        </div>
                    }

                    {!lastSeenStatus && !deliveredStatus && sentStatus === "PENDING" &&
                        <div>
                            <i className="material-icons">sync_problem</i>
                        </div>
                    }
                </>
            </>
        </div>
    </>
);

// ChatFooter.propTypes = {
//     onClick: PropTypes.func.isRequired,
//     isStarred: PropTypes.string.isRequired,
//     size: PropTypes.string.isRequired,
//     type: PropTypes.string.isRequired,
//     disabled: PropTypes.bool
// };

ChatFooter.defaultProps = {
    isStarred: false,
    lastSeenStatus: false
};

export default ChatFooter;
