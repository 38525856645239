import * as baseUrls from "../config/baseUrls";
import * as auth from "../utils";

export default class AuthService {
  saveIdentity(opts, token) {
    return fetch(
      `${baseUrls.authUrl}/guardian/identity?g-recaptcha-response=${token}`,
      {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(opts),
      }
    )
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        return data.isSuccess;
      })
      .catch((error) => {
        return false;
      });
  }

  login(username, password) {
    return fetch(`${baseUrls.authUrl}/login`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ password: password, username: username }),
    })
      .then(function (response) {
        if (response.status === 200) {
          return response.json();
        } else {
          return { isSuccess: false };
        }
      })
      .then(function (data) {
        return data;
      })
      .catch((error) => {
        return { isSuccess: false };
      });
  }

  logout(token, reason) {
    return fetch(`${baseUrls.authUrl}/logout`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        authorization: auth.getBearerToken(),
      },
      body: JSON.stringify({ token, reason }),
    })
      .then(function (response) {
        if (response.status === 200) {
          return response.json();
        } else {
          return { isSuccess: false };
        }
      })
      .then(function (data) {
        return data;
      })
      .catch((error) => {
        return { isSuccess: false };
      });
  }

  updatePassword(username, password, oldPassword) {
    return fetch(`${baseUrls.authUrl}/guardian/identity/${username}/password`, {
      method: "put",
      headers: {
        "Content-Type": "application/json",
        authorization: auth.getBearerToken(),
      },
      body: JSON.stringify({ password, oldPassword }),
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        return data;
      })
      .catch((error) => {
        return { isSuccess: false };
      });
  }

  getToken(userName, refreshToken, organization) {
    return fetch(`${baseUrls.authUrl}/login/token/${organization}`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${refreshToken}`,
      },
      body: JSON.stringify({ refreshToken: refreshToken, userName: userName }),
    })
      .then(function (response) {
        if (response.status === 200) {
          return response.json();
        } else {
          return { isSuccess: false };
        }
      })
      .then(function (data) {
        return data;
      })
      .catch((error) => {
        return { isSuccess: false };
      });
  }

  checkAccountAvailability(username, token) {
    return fetch(
      `${baseUrls.authUrl}/guardian/identity/${username}?g-recaptcha-response=${token}`,
      {
        method: "get",
        headers: {
          "Content-Type": "application/json",
          "g-captcha": token,
        },
      }
    )
      .then(function (response) {
        return response.json();
      })
      .then(function (reply) {
        return !reply.isSuccess;
      })
      .catch((error) => {
        return { isSuccess: false };
      });
  }

  passwordReset(email, token) {
    return fetch(
      `${baseUrls.authUrl}/password/reset?g-recaptcha-response=${token}`,
      {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          "g-captcha": token,
        },
        body: JSON.stringify({ userName: email, consoleName: "agent" }),
      }
    )
      .then(function (response) {
        if (response.status === 200) {
          return response.json();
        } else {
          return { isSuccess: false };
        }
      })
      .then(function (data) {
        return data;
      })
      .catch((error) => {
        return { isSuccess: false };
      });
  }

  changePassword(token, userName, password) {
    return fetch(`${baseUrls.authUrl}/password?g-recaptcha-response=${token}`, {
      method: "put",
      headers: {
        "Content-Type": "application/json",
        "g-captcha": token,
      },
      body: JSON.stringify({
        token: token,
        userName: userName,
        password: password,
      }),
    })
      .then(function (response) {
        if (response.status === 200) {
          return response.json();
        } else {
          return { isSuccess: false , error_code : response.status};
        }
      })
      .then(function (data) {
        return data;
      })
      .catch((error) => {
        console.error("changePassword",error);
        return { isSuccess: false , error_code : error.status};
      });
  }

  changePasswordRequestValidation(token, userName, rToken) {
    return fetch(
      `${baseUrls.authUrl}/password/token/validate?g-recaptcha-response=${rToken}`,
      {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          "g-captcha": token,
        },
        body: JSON.stringify({
          token: token,
          userName: userName,
        }),
      }
    )
      .then(function (response) {
        if (response.status === 200) {
          return response.json();
        } else {
          return { isSuccess: false };
        }
      })
      .then(function (data) {
        return data;
      })
      .catch((error) => {
        return { isSuccess: false };
      });
  }
}
