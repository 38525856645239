import { Livechat } from "./livechat2.js";
import { ApiFactory } from "./rest_api/index.js";
import {
  CreateGroupModel, ModifyGroupParticipantModel, GroupAdminModificationRequestModel, ModifyGroupNameModel,
  ModifyGroupDescriptionModel, DeleteGroupModel, GroupListingRequestModel, ModifyGroupIconModel, groupMessages,
} from "./group.js";
import {
  InteractionConferenceModel, interactionManager, InteractionConferenceResponseObject,
  InteractionTransferModel, InteractionTransferResponseObject, InteractionActionRequestModel
} from './interaction.js'
import { MessageModel, TypingStateModel, ReadReceiptModel, AppEventMessage, AppEventMessageProcessor } from './message.js'
import { CurrentGeoLocationRequestModel } from './location.js'
import { Logger } from "./logger.js";
import { sdkVersion } from "./version.js";
import { BrowserDeviceInfoManager } from "./browser-device-info.js";
import { GenerateUUID } from "./common.js";
import { signatureManager, snapshotManager } from "./capture.js";

class Livechat2Api {

  livechat;
  api = {};
  oPath = "https://test.tetherficloud.com:8125/user-auth-service/api/v1/users";
  logUrl = '';
  browserInfo = new BrowserDeviceInfoManager().getAllDetails();

  constructor(url = ("wss://" + window.location.host), config = {}) {
    Logger.debug(`Initializing Livechat 2 API (${sdkVersion})`);
    this.url = url;
    this.config = config;
    if (config && config.userUrl) {
      this.oPath = `${config.userUrl}/user-auth-service/api/v1/users`;
    }

    if (config && config.logUrl) {
      this.logUrl = `${config.logUrl}/ws-chat-proxy/remote-logging`;
    }
    else {
      if (url.indexOf('wss://') > -1) {
        this.logUrl = `https://${url.split('wss://')[1]}/ws-chat-proxy/remote-logging`;
      }
      else if (url.indexOf('ws://') > -1) {
        this.logUrl = `http://${url.split('ws://')[1]}/ws-chat-proxy/remote-logging`;
      }
    }

    this.serverOnlineState = false;
    this.init();
    Logger.info(`Livechat 2 API (${sdkVersion}) Initialized`);

    Logger.info(`Device Info running Livechat 2 API: ${JSON.stringify(this.browserInfo)}`)
    this.logClientMessage(`Device Info running Livechat 2 API: ${JSON.stringify(this.browserInfo)}`);
  }

  init = () => {
    this.livechat = new Livechat(this.url);
    this.api = new ApiFactory(this.config, this.livechat);
    this.nwInterval = null;

    this.livechat.onEnd = (reason) => {
      if (this.nwInterval != null)
        clearTimeout(this.nwInterval); //Clear an existing network timeout, if it is present

      this.onEnd(reason);
    };

    this.livechat.onStartSuccess = (sid, cnt = null) => {
      if (!this.getLoggedInUserId()) {
        this.livechat.setUserId(sid);
      }

      this.logClientMessage(`Session started using livechat ${sdkVersion}`, "livechat2api", "INFO");

      //When chat server is used we send the chat session id and other details
      this.onStartSuccess(sid, cnt)
    };

    this.livechat.onStartFailed = (error) => {
      this.logClientMessage(`Session Start Failed: ${error}"`, "livechat2api", "FINE");
      this.onStartFailed(error);
    };

    this.livechat.processConnStateChanged = state => {
      if (this.serverOnlineState !== state) {
        this.serverOnlineState = state;
        this.logClientMessage(`Server Status: ${this.serverOnlineState ? "ONLINE" : "OFFLINE"}`, "livechat2api", "FINE");

        setTimeout(() => { this.onServerStateChange(state); }, 0);

        if (state === false) {
          if (this.nwInterval != null)
            clearTimeout(this.nwInterval); //Clear an existing network timeout, if it is present

          this.nwInterval = setTimeout(() => { //set the interval
            if (this.serverOnlineState === false) {
              this.livechat.onEnd("SERVER_UNREACHABLE");
              this.livechat.end("SERVER_UNREACHABLE");
            }
          }, 170000);
        }
        else {
          if (this.nwInterval != null)
            clearTimeout(this.nwInterval); //Clear the timeout, if the server state is true
        }
      }
    }

    this.livechat.onConnect = () => {
      this.logClientMessage(`Connected to Comm Proxy using Livechat 2 SDK (${sdkVersion})`, "livechat2api", "INFO");
      this.onConnect();
    };

    this.livechat.onReconnect = () => {
      this.logClientMessage("onReconnect", "livechat2api", "INFO");
      this.onReconnect();
    };

    this.livechat.onDisconnect = reason => {
      this.onDisconnect(reason);
    };

    this.msgmap = {
      "user-message": (message, from) => {
        this.onUserMessage(message, from);
      },
      "cbot-message": (message, from) => {
        this.onCbotMessage(message, from);
      },
      "typing-state": (content, from) => {
        this.onTypingState(JSON.parse(content), from);
      },
      "delivery-recipt": (content, from) => {
        this.onDeliveryRecipt(content, from);
      },
      "read-recipt": (content, from) => {
        this.onReadRecipt(content, from);
      },
      "group-created": (content, from) => {
        this.onGroupCreated(JSON.parse(content));
      },
      "group-name-updated": (content, from) => {
        this.onGroupNameModified(JSON.parse(content));
      },
      "group-description-updated": (content, from) => {
        this.onGroupDescriptionModified(JSON.parse(content));
      },
      "group-deleted": (content, from) => {
        this.onGroupDeleted(content);
      },
      "group-message": (content, from) => {
        this.onGroupMessageReceived(JSON.parse(content));
      },
      "user-group-listing": (content, from) => {
        this.onUserGroupListing(JSON.parse(content));
      },
      "participants-added": (content, from) => {
        this.onUserAddedToGroup(JSON.parse(content));
      },
      "participants-removed": (content, from) => {
        this.onUserRemovedFromGroup(JSON.parse(content));
      },
      "presence": (content) => {
        this.onPresenceNotification(content);
      },
      "interaction-created": (content, from) => {
        let parsedContent = JSON.parse(content);
        if (parsedContent.interactionId != null) {
          this.livechat.setInteractionId(parsedContent.interactionId, parsedContent);
        }
        this.onUserFound(parsedContent);
      },
      "user-found": (content, from) => {
        let parsedContent = JSON.parse(content);
        if (parsedContent.interactionId != null) {
          this.livechat.setInteractionId(parsedContent.interactionId, parsedContent);
        }

        if (parsedContent.version)
          return;
        else
          this.onUserFound(parsedContent);
      },
      "request-queued": (content, from) => {
        this.onRequestQueued(JSON.parse(content));
      },
      "system-notification": (content) => {
        this.onSystemNotification(JSON.parse(content));
      },
      "queue-position-updated": (content, from) => {
        this.onQueuePositionUpdated(JSON.parse(content));
      },
      "app-event": (content, from) => {
        ///Process the app-event internally to validate the event was sent internally and needs to be processed internally
        ///If this event is not a known internal app-event type then we trigger the onAppEvent
        if (new AppEventMessageProcessor(JSON.parse(content)).ProcessAppEvent(this) === false)
          this.onAppEvent(content);
      },
      "group-admin-added": (content, from) => {
        this.onGroupAdminAdded(JSON.parse(content));
      },
      "group-admin-dismissed": (content, from) => {
        this.onGroupAdminDismissed(JSON.parse(content));
      },
      "user-left-group": (content, from) => {
        this.onUserLeftGroup(JSON.parse(content));
      },
      "group-icon-changed": (content, from) => {
        this.onGroupIconChanged(JSON.parse(content));
      },
      "user-not-found": (content, from) => {
        this.onUserNotFound(JSON.parse(content));
      },
      "interaction-ended": (content, from) => {
        interactionManager.ProcessInteractionEnd(JSON.parse(content), this.livechat);
        this.onInteractionEnd(JSON.parse(content));
      },
      "user-interaction-listing": (content, from) => {
        let parsedContent = JSON.parse(content);
        interactionManager.ProcessUserInteractionList(parsedContent, this.livechat);
        this.onUserInteractionListing(parsedContent);
      },
      "interaction-conference-request": (content, from) => {
        this.onConferenceRequest(new InteractionConferenceResponseObject(JSON.parse(content), this.livechat));
      },
      "interaction-conference-response": (content, from) => {
        if (interactionManager.ProcessConferenceResponseAck(JSON.parse(content)))
          this.onConferenceResponseAcknowledgement(JSON.parse(content));
      },
      "interaction-conference-success": (content, from) => {
        let parsedContent = JSON.parse(content);
        interactionManager.ProcessConferenceSuccess(parsedContent, this.livechat);
        this.onInteractionConferenceSuccess(parsedContent);
      },
      "interaction-transfer-request": (content, from) => {
        this.onInteractionTransferRequest(new InteractionTransferResponseObject(JSON.parse(content), this.livechat));
      },
      "interaction-transfer-response": (content, from) => {
        if (interactionManager.ProcessTransferResponseAck(JSON.parse(content)))
          this.onInteractionTransferResponseAcknowledgement(JSON.parse(content));
      },
      "interaction-transfer-success": (content, from) => {
        let parsedContent = JSON.parse(content);
        interactionManager.ProcessTransferSuccess(parsedContent, this.livechat);
        this.onInteractionTransferSuccess(parsedContent);
      },
      "interaction-started": (content, from) => {
        let parsedContent = JSON.parse(content);
        interactionManager.ProcessInteractionStart(parsedContent, this.livechat);
        this.livechat.setInteractionId(parsedContent.InteractionId, parsedContent);
        this.onInteractionStarted(parsedContent);
      },
      "user-left-interaction": (content, from) => {
        this.onUserLeftInteraction(JSON.parse(content));
      },
      "session-end": (content, from) => {
        this.onSessionEnd(JSON.parse(content));
      },
      "response-ewt": (content, from) => {
        this.onEWTStatus(JSON.parse(content));
      },
      "signature-request": () => {
        this.onSignatureRequest();
      },
      "upload-success": (rcontent, from) => {
        this.logClientMessage(`File Upload-success (${JSON.stringify(rcontent)})`, "livechat2api", "INFO");

        try {
          if (rcontent.type == "upload-signature") {
            let obj = {
              type: 'signature-response',
              content: {
                base64: '',
                url: rcontent.base64URL,
                fileName: rcontent.fileName,
              }
            }
            this.sendCustomMessage("signature-response", JSON.stringify(obj), "service:ICP");
          }
        } catch (error) {
          Logger.error(`Error while processing upload-success message`, error);
        }

        this.onFileUploadSuccess(rcontent);
      },
      "upload-failed": (content, from) => {
        this.logClientMessage(`File Upload-failed (${JSON.stringify(content)})`, "livechat2api", "INFO");
        this.onFileUploadFailure(content);
      }
    };

    this.msglisteners = {};

    this.livechat.onLv2Message = (type, content, from, attribs) => {
      var includeReportTypes = ['user-message'];

      if (includeReportTypes.includes(type)) {
        try {
          let contentJSON = JSON.parse(content);
          let deliveryContent = JSON.stringify({ mid: contentJSON.mid, to: { id: contentJSON.to ? contentJSON.to.id : "" }, from });
          let to = `user:${contentJSON.from ? contentJSON.from.id : ""}`;

          if (contentJSON.conversation_type === 4) { //Check if it is an interaction-message
            this.livechat.sendLv2Message("delivery-recipt", deliveryContent, to, { "interaction": contentJSON.to.id });
          } else if (contentJSON.conversation_type === 3) { //Check if it is an group-message
            this.livechat.sendLv2Message("delivery-recipt", deliveryContent, to, { "group": contentJSON.to.id });
          } else { //Else we assume it is a one-one (user-user) message
            this.livechat.sendLv2Message("delivery-recipt", deliveryContent, to);
          }
        } catch (e) {
          Logger.error(`Error in onLv2Message`, e);
        }
      }

      var func = this.msgmap[type];
      if (!func) {
        func = this.msglisteners[type];
      }

      if (func) {
        try {
          func(content, from);
        } catch (e) {
          Logger.error(`Error in calling registered handler for message with type: ${type}`, e);
        }
      }
    }

    this.livechat.onLv2StreamMessage = (type, content, from, attribs) => {
      var func = this.msgmap[type];

      if (!func) {
        func = this.msglisteners[type];
      }

      if (func) {
        try {
          func(content, from);
        } catch (e) {
          Logger.error(`Error in calling registered handler for message with type: ${type}`, e);
        }
      }
    }
  }

  getLoggedInUserId = () => {
    return this.livechat.getUserId();
  }

  registerListener = (type, listenerfn) => {
    this.msglisteners[type] = listenerfn;
  }

  unregisterListener = (type) => {
    delete this.msglisteners[type];
  }

  setUserAuth = (userId, authToken, validationAttributes = {}) => {
    this.init();
    const this_ = this;
    this_.livechat.setUserId(userId);
    this_.livechat.setAuthToken(authToken);
    this_.livechat.setValidationAttributes(validationAttributes);
  }

  setValidationAttributes = (validationAttributes = {}) => {
    const this_ = this;
    this_.livechat.setValidationAttributes(validationAttributes);
  }

  start = (userObject, fetch) => {
    const this_ = this;
    let attributes = this_.livechat.getValidationAttributes();
    if (fetch && fetch === true) {
      attributes["fetch"] = "true";
    }

    this.livechat.start(
      JSON.stringify({
        eCustDetails: userObject,
        "device-info": this.browserInfo,
      }),
      attributes
    );
  }

  end = (reason) => {
    this.livechat.end(reason);
    this.logClientMessage("end : " + reason, "livechat2api", "FINE");
    if (this.nwInterval != null)
      clearTimeout(this.nwInterval); //Clear an existing network timeout, if it is present
  }

  onSessionEnd = () => {

  }

  // message : {from: fromUserId, to: toUserId, ended_at: timestamp}

  /*
   * Method to send a chat message to user or interaction
   * @param {string} message message content - can be a JSON string
   * @param {string} to user or interaction the message needs to be sent to
   * @param {Function} onSentCb function to be fired upon message sned failure or success
   * @param {string} externalMid an optional external id that the client can send to get it back on the callback function
  */
  sendUserMessage = (message, to = null, onSentCb, externalMid = null) => {
    this.livechat.sendLv2Message("user-message", message, to, {}, onSentCb, externalMid);
  }

  sendUserMessageV2 = (message, to = null, onSentCb, externalMid = null) => {
    let userMsg = new MessageModel(message, this.getLoggedInUserId(), to, externalMid);
    this.livechat.sendLv2Message("user-message", JSON.stringify(userMsg), to, {}, onSentCb, externalMid);
  }

  sendTypingState = (state, to) => {
    this.livechat.sendLv2Message("typing-state", state, to);
  }

  sendTypingStateV2 = (state, to) => {
    let typingMessage = new TypingStateModel(state, this.getLoggedInUserId(), to);
    this.livechat.sendLv2Message("typing-state", JSON.stringify(typingMessage), to);
  }

  //
  sendReadRecipt = (content, to, originalSenderId = null) => {
    if ((to.indexOf("group:") > -1 || to.indexOf("interaction:") > -1) && originalSenderId != null) {
      //This indicates a group/interaction read-receipt
      if (to.indexOf("group:") > -1) {
        this.livechat.sendLv2Message("read-recipt", content, originalSenderId, { "group": to.split(":")[1] });
      }
      else if (to.indexOf("interaction:") > -1) {
        this.livechat.sendLv2Message("read-recipt", content, originalSenderId, { "interaction": to.split(":")[1] });
      }
    }
    else if (to.indexOf("user:") > -1) {
      //This indicates this is a one-one user read receipt
      this.livechat.sendLv2Message("read-recipt", content, to);
    }
    else {
      Logger.error("Unable to send read receipt");
    }
  }

  sendReadReciptV2 = (mid, to, originalSenderId = null) => {
    let readReceiptMessage = new ReadReceiptModel(mid, this.getLoggedInUserId(), to);
    this.sendReadRecipt(JSON.stringify(readReceiptMessage), to, originalSenderId);
  }

  sendAppEvent = (content, to) => {
    this.livechat.sendLv2Message("app-event", content, to);
  }

  sendPresenceNotification = (content) => {
    this.livechat.sendLv2Message("presence", content, "service:PRS");
  }

  sendCustomMessage = (type, jsonStr, to, onSentCb = null) => {
    this.livechat.sendLv2Message(type, jsonStr, to, onSentCb);
  }

  // returns a bool
  open = () => {
    return this.livechat.open();
  }

  requestCurrentLocation = userId => {
    var loggedInUserId = this.livechat.getUserId();

    if (userId == null || userId === "") {
      Logger.error("Parameter (userId) in Method (requestCurrentLocation) has to have atleast one user ID added");
      return;
    } else if (loggedInUserId == null || loggedInUserId === "") {
      Logger.error("User is not logged in");
      return;
    }
    else if (loggedInUserId === userId) {
      Logger.error("The logged-in user and the user whose location is being requested are the same");
      return;
    }

    if (userId.indexOf("user:") > -1) {
      userId = userId.split(":")[1];
    }

    var contentObject = new CurrentGeoLocationRequestModel();
    contentObject.userId = userId;

    var requestObj = new AppEventMessage();
    requestObj.content = contentObject;
    requestObj.requesterUserId = loggedInUserId;
    requestObj.messageSubType = "request-current-location";

    this.sendAppEvent(JSON.stringify(requestObj), "user:" + userId);
  }

  // Events

  onEnd = (reason) => {
    Logger.info("Unhandled onEnd (" + reason + ")");
  }

  onStartSuccess = (sid) => {
    Logger.info("Unhandled onStartSuccess");
  }

  onStartFailed = (error) => {
    Logger.info("Unhandled onStartFailed");
  }

  onConnect = () => {
    Logger.info("Unhandled onConnect ()");
  }

  onReconnect = () => {
    Logger.info("Unhandled onReconnect ()");
  }

  onDisconnect = (disConnReason) => {
    Logger.info("Unhandled onDisconnect (" + disConnReason + ")");
  }

  onUserMessage = (message, from) => {
    Logger.info("Unhandled onUserMessage : " + message + ", from : " + from);
  }

  onCbotMessage = (message, from) => {
    Logger.info("Unhandled onCbotMessage : " + message + ", from : " + from);
  }

  onTypingState = (content, from) => {
    Logger.info("Unhandled Typing State");
  }


  onDeliveryRecipt = (recipt, from) => {
    Logger.info("Unhandled delivery recipt");
  }


  onReadRecipt = (recipt, from) => {
    Logger.info("Unhandled read recipt");
  }

  onPresenceNotification = content => {
    Logger.info("Unhandled presence notif");
  }

  onAppEvent = content => {
    Logger.info("Unhandled Application Event");
  }

  onServerStateChange = state => {
    Logger.info("Unhandled onServerStateChange (" + state + ")");
  }

  onCurrentLocationRequest = content => {
    Logger.info("Unhandled onCurrentLocationRequest");
  }

  onCurrentLocationResponse = content => {
    Logger.info("Unhandled onCurrentLocationResponse");
  }

  onCaptchaSuccess = (data) => {
    Logger.info(`Unhandled onCaptchaSuccess ` + data);
  }

  onCaptchaFailed = (error) => {
    Logger.info(`Unhandled onCaptchaFailed ` + error);
  }

  /**
   * api-method
   * Sends stream message to TMC service
   * @param {string} message Contains the stream message (Cannot be blank)
   */
  sendStreamMessage = (message) => {
    try {
      if (message == null || message === "") {
        Logger.error("Parameter (message) in Method (sendStreamMessage) cannot be blank");
        return;
      }

      //Send the message
      this.livechat.sendLv2StreamMessage(message, "service:TMC");
    } catch (e) {
      Logger.error("Error in sendStreamMessage", e);
    }
  }

  /**
   * api-method
   * Logs the client log message at proxy server.
   * @param {string} message Contains the log message (Cannot be blank)
   * @param {string} method Method name where log has been written. (Optional, can be blank)
   * @param {string} level Log Level. Possible values are FINE, INFO, WARN, ERROR .Default log level is "INFO".
   * 
   */

  logReq = [];
  logClientMessage = (message, method = "", level = "INFO") => {
    try {
      if (!message) {
        Logger.error("Parameter (message) in Method (logClientMessage) cannot be blank");
        return;
      }

      if (!this.logUrl) {
        Logger.error("Remote Logging URL is not defined.");
        return;
      }

      Logger.info(`Remote logging URL: ${this.logUrl}`);

      let sid = '';
      if (this.livechat.getSessionID()) {
        sid = this.livechat.getSessionID();
      }

      this.logReq.push({ message, method, level, sid });

      setTimeout(() => {
        if (this.logReq.length != 0) {
          Logger.info(`Remote Logging with batch : ${this.logReq.length}`);

          fetch(this.logUrl, {
            method: "POST",
            headers: {
              "Content-Type": "application/json"
            },
            body: JSON.stringify(this.logReq)
          }).then(res => {
            Logger.info(`Remote Logging Response status ${res.status}`);
          }).catch(e => {
            Logger.error(`Error in Remote Logging Request`, e);
          });
        }

        this.logReq = [];
      }, 300);

    } catch (e) {
      Logger.error(`Error in logClientMessage`, e);
    }
  }

  /**
 * api-method
 * Allows user to upload the signature
 * @param {fileObj} Uploads Signature to the remote server
 */

  uploadSignature = (fileObj) => {
    try {
      if (fileObj == null || fileObj === undefined) {
        Logger.error("Parameter (fileObj) in Method (uploadSignature) cannot be null");
        return;
      }
      this.livechat.uploadFile("upload-signature", fileObj);
    }
    catch (e) {
      Logger.error("Error in uploadSignature", e);
    }
  }

  /**
  * api-method
  * Enables/Disables certain features of SDK Logs.
  * @param {boolean} isLoggingEnabled Indicates if SDK Logs need to be printed in the browser console.
  * @param {boolean} isDebugLoggingEnabled Indicates if SDK Debug Logs need to be printed in the browser console. 
  * @param {boolean} isLogMaskingEnabled Indicates if sensitive user information in SDK logs need to be masked
  * @param {object} maskingPatterns An object whose key consists of the pattern to be matched and the value consists of the replacement pattern
  */
  sdkLoggingConfigurations = (isLoggingEnabled = true, isDebugLoggingEnabled = true, isLogMaskingEnabled = true, maskingPatterns = {}) => {
    if (isLoggingEnabled != null && typeof isLoggingEnabled === "boolean") {
      Logger.isConsoleLoggingEnabled = isLoggingEnabled;

      if (isLoggingEnabled) {
        console.info("SDK Console Logging is Enabled");

        //Only if SDK Logging is enabled, then we can decide to enable debug logging & log masking
        if (isDebugLoggingEnabled != null && typeof isDebugLoggingEnabled === "boolean") {
          Logger.isLogMaskingEnabled = isDebugLoggingEnabled;

          if (isDebugLoggingEnabled)
            console.info("SDK Debug Logs are Enabled");
          else
            console.info("SDK Debug Logs are Disabled");
        }

        if (isLogMaskingEnabled != null && typeof isLogMaskingEnabled === "boolean") {
          Logger.isLogMaskingEnabled = isLogMaskingEnabled;

          if (isLogMaskingEnabled) {
            console.info("SDK Log Masking is Enabled");

            if (maskingPatterns != null)
              for (const [key, value] of Object.entries(maskingPatterns)) {
                Logger.addMaskingPattern(key, value)
              }
          }
          else
            console.info("SDK Log Masking is Disabled");
        }
      }
      else {
        console.info("SDK Console Logging is Disabled");
        console.info("SDK Debug Logs are Disabled");
        console.info("SDK Log Masking is Disabled");
      }
    }
  }

  /**
  * api-method
  * Gets the version of Livechat API used.
  */
  getVersion = () => {
    return sdkVersion;
  }

  // = = = = = = GROUP MANAGEMENT = = = = = = =

  /**
   * api-method
   * Creates a group
   * @param {string} name Name of the group (Cannot be blank)
   * @param {string} description Description (Optional, can be blank)
   * @param {Array<string>} userIdList List of RM Id(s) in the group (including the creator, cannot be blank)
   * @param {string} createdBy RM who created the group (Cannot be blank)
   */
  createGroup = (name, userIdList = [], description = "", iconPath = "") => {
    try {
      //Get the current DateTime
      var now = new Date();
      var loggedInUserId = this.livechat.getUserId();

      //Validate the parameters passed are null/undefined/blank
      if (name == null || name === "") {
        Logger.error("Parameter (name) in Method (createGroup) cannot be blank");
        return;
      } else if (description == null) {
        Logger.error("Parameter (description) in Method (createGroup) cannot be null/undefined");
        return;
      } else if (userIdList == null) {
        Logger.error("Parameter (userIdList) in Method (createGroup) cannot be null/undefined");
        return;
      } else if (userIdList.length === 0) {
        Logger.error("Parameter (userIdList) in Method (createGroup) has to have atleast one user ID added");
        return;
      } else if (loggedInUserId == null || loggedInUserId === "") {
        Logger.error(groupMessages.common.userNotLoggedIn);
        return;
      }

      //Check if the RM who created the group is present in the userIdList
      if (userIdList.indexOf(loggedInUserId) === -1) {
        //If he is not there, then we add him
        userIdList.push(loggedInUserId);
      }

      //Prepare the object to be sent in the Message
      var groupObject = new CreateGroupModel();
      groupObject.Name = name;
      groupObject.CreatedDateTime = now;
      groupObject.Description = description;
      groupObject.Participants = userIdList;
      groupObject.CreatedBy = loggedInUserId;
      groupObject.IconPath = iconPath;

      //Send the message
      this.livechat.sendLv2Message("create-group", JSON.stringify(groupObject), "service:GMS");
    } catch (e) {
      Logger.error("Error in createGroup", e);
    }
  }

  /**
   * api-callback
   * Triggered when a group is created
   * @param {object} groupInfo Contains details of the group such as
   * Id - Unique Id of the group
   * Name - Name of the Group
   * Description - Description of the Group
   * CreatedBy - User ID who created the Group
   * CreatedDateTime - DateTime when the group was created
   * Participants - List of User ID(s) participating in the group
   */
  onGroupCreated = groupInfo => {
    Logger.info("Group created with below parameters:" +
      "\n Group ID: " + groupInfo.Id +
      "\n" + "Group Name: " + groupInfo.Name +
      "\n" + "Group Icon: " + groupInfo.IconPath +
      "\n" + "Group Description: " + groupInfo.Description +
      "\n" + "Created By: " + groupInfo.CreatedBy +
      "\n" + "Created Date Time: " + groupInfo.CreatedDateTime +
      "\n" + "Group Participants: " + (JSON.stringify(groupInfo.Participants)))
  }

  /**
   * api-method
   * Deletes a group
   * @param {string} groupId Unique Id of the group
   */
  deleteGroup = groupId => {
    try {
      //Get the current DateTime
      var now = new Date();
      var loggedInUserId = this.livechat.getUserId();

      if (groupId == null || groupId === "") {
        Logger.error("Parameter (groupId) in Method (deleteGroup) cannot be blank");
        return;
      } else if (loggedInUserId == null || loggedInUserId === "") {
        Logger.error(groupMessages.common.userNotLoggedIn);
        return;
      }

      let deleteGroupObject = new DeleteGroupModel();
      deleteGroupObject.Id = groupId;
      deleteGroupObject.RequestorUserId = loggedInUserId;

      //Send the message
      this.livechat.sendLv2Message("delete-group", JSON.stringify(deleteGroupObject), "service:GMS");
    } catch (e) {
      Logger.error("Error in deleteGroup", e);
    }
  }

  /**
   * api-callback
   * Triggered when a group is deleted
   * @param {string} groupId Unique Id of the group
   */
  onGroupDeleted = groupId => {
    Logger.info("Group " + groupId + " is deleted");
  }

  /**
   * api-method
   * Adds RM(s) to an existing group (request can only be made by an existing administrator of the group)
   * @param {string} groupId Unique Id of the existing group (Cannot be blank)
   * @param {Array<string>} userIdList List of additional user(s) to be added into the existing group (Cannot be blank)
   */
  addUserToGroup = (groupId, userIdList) => {
    try {
      //Get the current DateTime
      var now = new Date();
      var loggedInUserId = this.livechat.getUserId();

      //Validate the parameters passed are null/undefined/blank
      if (groupId == null || groupId === "") {
        Logger.error("Parameter (groupId) in Method (addUserToGroup) cannot be blank");
        return;
      } else if (userIdList == null || userIdList === "" || userIdList.length === 0) {
        Logger.error("Parameter (userIdList) in Method (addUserToGroup) cannot be blank");
        return;
      } else if (loggedInUserId == null || loggedInUserId === "") {
        Logger.error(groupMessages.common.userNotLoggedIn);
        return;
      }

      //Prepare the object to be sent in the Message
      var groupParticipantObject = new ModifyGroupParticipantModel();
      groupParticipantObject.GroupId = groupId;
      groupParticipantObject.ParticipantsModifiedDateTime = now;
      groupParticipantObject.Participants = userIdList;
      groupParticipantObject.RequestorUserId = loggedInUserId;

      //Send the message
      this.livechat.sendLv2Message("add-participants", JSON.stringify(groupParticipantObject), "service:GMS");
    } catch (e) {
      Logger.error("Error in addUserToGroup", e);
    }
  }

  /**
   * api-callback
   * Triggered when users are added to a group
   * @param {object} groupInfo Contains details of the group such as
   * Id - Unique Id of the group
   * Name - Name of the Group
   * Description - Description of the Group
   * CreatedBy - User ID who created the Group
   * CreatedDateTime - DateTime when the group was created
   * Participants - List of User ID(s) participating in the group
   */
  onUserAddedToGroup = groupInfo => {
    Logger.info("Group created with below parameters:" +
      "\n Group ID: " + groupInfo.Id +
      "\n" + "Group Name: " + groupInfo.Name +
      "\n" + "Group Description: " + groupInfo.Description +
      "\n" + "Created By: " + groupInfo.CreatedBy +
      "\n" + "Created Date Time: " + groupInfo.CreatedDateTime +
      "\n" + "Group Participants: " + (JSON.stringify(groupInfo.Participants)))
  }

  /**
   * api-method
   * Removes RM(s) from an existing group (request can only be made by an existing administrator of the group)
   * @param {string} groupId Unique Id of the existing group (Cannot be blank)
   * @param {Array<string>} userIdList List of existing user(s) to be removed from the existing group (Cannot be blank)
   */
  removeUserFromGroup = (groupId, userIdList) => {
    try {
      //Get the current DateTime
      var now = new Date();
      var loggedInUserId = this.livechat.getUserId();

      //Validate the parameters passed are null/undefined/blank
      if (groupId == null || groupId === "") {
        Logger.error("Parameter (groupId) in Method (removeUserFromGroup) cannot be blank");
        return;
      } else if (userIdList == null || userIdList === "" || userIdList.length === 0) {
        Logger.error("Parameter (userIdList) in Method (removeUserFromGroup) cannot be blank");
        return;
      } else if (loggedInUserId == null || loggedInUserId === "") {
        Logger.error(groupMessages.common.userNotLoggedIn);
        return;
      }

      //Prepare the object to be sent in the Message
      var groupParticipantObject = new ModifyGroupParticipantModel();
      groupParticipantObject.GroupId = groupId;
      groupParticipantObject.ParticipantsModifiedDateTime = now;
      groupParticipantObject.Participants = userIdList;
      groupParticipantObject.RequestorUserId = loggedInUserId;

      //Send the message
      this.livechat.sendLv2Message("remove-participants", JSON.stringify(groupParticipantObject), "service:GMS");
    } catch (e) {
      Logger.error("Error in removeUserFromGroup", e);
    }
  }

  /**
   * api-callback
   * Triggered when users are removed from a group
   * @param {object} userInfo Contains details of the group participants such as
   * Participants - List of Unique user id(s) removed from the group
   * GroupId - Unique Id of the Group
   * ParticipantsModifiedDateTime - DateTime when the Participants were removed from the Group
   */
  onUserRemovedFromGroup = userInfo => {
    Logger.info("Users " + userInfo.Participants.toString() + " are removed from group with Id " + userInfo.GroupId + " at " + userInfo.ParticipantsModifiedDateTime);
  }

  /**
   * api-method
   * Sends a message in the group
   * @param {string} groupId Unique Id of the existing group (Cannot be blank)
   * @param {string} message Contains the message (Cannot be blank)
   */
  sendMessageInGroup = (groupId, message) => {
    try {
      //Validate the parameters passed are null/undefined/blank
      if (groupId == null || groupId === "") {
        Logger.error("Parameter (groupId) in Method (sendMessageInGroup) cannot be blank");
        return;
      } else if (message == null || message === "") {
        Logger.error("Parameter (message) in Method (sendMessageInGroup) cannot be blank");
        return;
      }

      //Send the message
      this.livechat.sendLv2Message("group-message", message, "group:" + groupId);
    } catch (e) {
      Logger.error("Error in sendMessageInGroup", e);
    }
  }

  /**
   * api-callback
   * Triggered when a message is received in the group
   * @param {object} groupMessageDetails Object containing details of the group message
   */
  onGroupMessageReceived = groupMessageDetails => {
    Logger.info("Group Message Received with details: " + JSON.stringify(groupMessageDetails));
  }

  /**
   * api-method
   * Changes the Name of a group (request can only be made by an existing administrator of the group)
   * @param {string} groupId Unique Id of the Group
   * @param {string} newGroupName New name of the Group
   */
  modifyGroupName = (groupId, newGroupName) => {
    try {
      //Get the current DateTime
      var now = new Date();
      var loggedInUserId = this.livechat.getUserId();

      //Validate the parameters passed are null/undefined/blank
      if (groupId == null || groupId === "") {
        Logger.error("Parameter (groupId) in Method (modifyGroupName) cannot be blank");
        return;
      } else if (newGroupName == null || newGroupName === "") {
        Logger.error("Parameter (newGroupName) in Method (modifyGroupName) cannot be blank");
        return;
      } else if (loggedInUserId == null || loggedInUserId === "") {
        Logger.error(groupMessages.common.userNotLoggedIn);
        return;
      }

      //Prepare the object to be sent in the Message
      var messageContentObject = new ModifyGroupNameModel();
      messageContentObject.Id = groupId;
      messageContentObject.Name = newGroupName;
      messageContentObject.RequestorUserId = loggedInUserId;

      //Send the message
      this.livechat.sendLv2Message("modify-group-name", JSON.stringify(messageContentObject), "service:GMS");
    } catch (e) {
      Logger.error("Error in modifyGroupName", e);
    }
  }

  /**
   * api-callback
   * Triggered when the group Name is changed
   * @param {object} groupInfo Contains details of the group such as
   * Id - Unique Id of the Group
   * Name - New Group Name
   */
  onGroupNameModified = groupInfo => {
    Logger.info("Name of the Group with Id " + groupInfo.Id + " has been changed to " + groupInfo.Name);
  }

  /**
   * api-method
   * Changes the Description of a group (request can only be made by an existing administrator of the group)
   * @param {string} groupId Unique Id of the Group
   * @param {string} newGroupDescription New description of the Group
   */
  modifyGroupDescription = (groupId, newGroupDescription = "") => {
    try {
      //Get the current DateTime
      var now = new Date();
      var loggedInUserId = this.livechat.getUserId();

      //Validate the parameters passed are null/undefined/blank
      if (groupId == null || groupId === "") {
        Logger.error("Parameter (groupId) in Method (modifyGroupDescription) cannot be blank");
        return;
      } else if (newGroupDescription == null) {
        Logger.error("Parameter (newGroupDescription) in Method (modifyGroupDescription) cannot be blank");
        return;
      } else if (loggedInUserId == null || loggedInUserId === "") {
        Logger.error(groupMessages.common.userNotLoggedIn);
        return;
      }

      //Prepare the object to be sent in the Message
      var messageContentObject = new ModifyGroupDescriptionModel();
      messageContentObject.Id = groupId;
      messageContentObject.Description = newGroupDescription;
      messageContentObject.RequestorUserId = loggedInUserId;

      //Send the message
      this.livechat.sendLv2Message("modify-group-description", JSON.stringify(messageContentObject), "service:GMS");
    } catch (e) {
      Logger.error("Error in modifyGroupDescription", e);
    }
  }

  /**
   * api-callback
   * Triggered when the group Description is changed
   * @param {object} groupInfo Contains details of the group participants such as
   * Id - Unique Id of the Group
   * Description - New Group Description
   */
  onGroupDescriptionModified = groupInfo => {
    Logger.info("Name of the Group with Id " + groupInfo.Id + " has been changed to " + groupInfo.Description);
  }

  /**
   * api-method
   * Request Group List based on the logged in User/Session
   * @param {int} lastGroupListId The last unique Id received
   *  If the group list is requested for the first time, then lastGroupListId can be passed as 0 
   *  else the lastGroupListId can be passed (which is received in the 'onUserGroupListing' callback below) so that the next subsequent batch can be requested/processed
   */
  getUserGroupList = (lastGroupListId = 0) => {
    try {
      var loggedInUserId = this.livechat.getUserId();

      if (loggedInUserId == null || loggedInUserId === "") {
        Logger.error(groupMessages.common.userNotLoggedIn);
        return;
      }

      var groupListingRequestModel = new GroupListingRequestModel();
      groupListingRequestModel.LastMessageId = lastGroupListId;
      groupListingRequestModel.UserId = loggedInUserId;

      //Send the message
      this.livechat.sendLv2Message("group-listing", JSON.stringify(groupListingRequestModel), "service:GMS");
    } catch (e) {
      Logger.error("Error in getUserGroupList", e);
    }
  }

  /**
    * api-callback
    * Triggered when a group listing for a user was requested
    * @param {object} groupListInfo Contains details of the groups whcih the user is a part of and it has the following properties
    * UserId - Unique Id of the User for whom the group list was requested
    * LastGroupListId - Last Id of the group received (from the group list) which can be used to pass in the next request
    * GroupListInfo - List<object> which contains the group information, each object in the list will contain the following properties:
    *   Id - Unique Id of the group
    *   Name - Name of the Group
    *   Description - Description of the Group
    *   CreatedBy - User ID who created the Group
    *   CreatedDateTime - DateTime when the group was created
    *   Participants - List of User ID(s) participating in the group
    * NOTE: In case there is no more group list available for the user or if there is some error in server, 'GroupListInfo' will be passed as null
    */
  onUserGroupListing = (groupListInfo) => {
    Logger.info("Group List received for user Id: " + groupListInfo.UserId + " \n Last Group Id: " + groupListInfo.LastGroupListId + " \n Group List Information: " + JSON.stringify(groupListInfo.GroupListInfo));
  }

  /**
   * api-method
   * Appoint a user as an admin of the group (request can only be made by an existing administrator of the group)
   *  @param {string} groupId Unique Id of the group that the user belongs to
   *  @param {string} userId Unique Id of the user who is to be made as an admin
   */
  makeUserAsAdmin = (groupId, userId) => {
    try {
      var loggedInUserId = this.livechat.getUserId();

      //Validate the parameters passed are null/undefined/blank
      if (groupId == null || groupId === "") {
        Logger.error("Parameter (groupId) in Method (makeUserAsAdmin) cannot be blank");
        return;
      } else if (userId == null) {
        Logger.error("Parameter (userId) in Method (makeUserAsAdmin) cannot be blank");
        return;
      } else if (loggedInUserId == null || loggedInUserId === "") {
        Logger.error(groupMessages.common.userNotLoggedIn);
        return;
      }

      var messageContentObject = new GroupAdminModificationRequestModel();
      messageContentObject.GroupId = groupId;
      messageContentObject.UserId = userId;
      messageContentObject.RequestorUserId = loggedInUserId;

      //Send the message
      this.livechat.sendLv2Message("make-user-group-admin", JSON.stringify(messageContentObject), "service:GMS");
    } catch (e) {
      Logger.error("Error in makeUserAsAdmin", e);
    }
  }

  /**
   * api-callback
   * Triggered when a user is made as admin in the group
   *  @param {object} info Contains details of user in group who is made as admin
   * GroupId - Unique Id of the Group
   * UserId - Unique Id of the user who is made as an admin in the group
   */
  onGroupAdminAdded = info => {
    Logger.info("User  " + info.UserId + " has been made an admin in group " + info.GroupId);
  }

  /**
   * api-method
   * Dismiss an existing admin in the group (request can only be made by an existing administrator of the group)
   *  @param {string} groupId Unique Id of the group that the user belongs to
   *  @param {string} userId Unique Id of the user who is to be dimissed as admin
   */
  dismissUserAsAdmin = (groupId, userId) => {
    try {
      var loggedInUserId = this.livechat.getUserId();

      //Validate the parameters passed are null/undefined/blank
      if (groupId == null || groupId === "") {
        Logger.error("Parameter (groupId) in Method (dismissUserAsAdmin) cannot be blank");
        return;
      } else if (userId == null) {
        Logger.error("Parameter (userId) in Method (dismissUserAsAdmin) cannot be blank");
        return;
      } else if (loggedInUserId == null || loggedInUserId === "") {
        Logger.error(groupMessages.common.userNotLoggedIn);
        return;
      }

      var messageContentObject = new GroupAdminModificationRequestModel();
      messageContentObject.GroupId = groupId;
      messageContentObject.UserId = userId;
      messageContentObject.RequestorUserId = loggedInUserId;

      //Send the message
      this.livechat.sendLv2Message("dismiss-user-group-admin", JSON.stringify(messageContentObject), "service:GMS");
    } catch (e) {
      Logger.error("Error in dismissUserAsAdmin", e);
    }
  }

  /**
   * api-callback
   * Triggered when a user is dismissed as admin in the group
   * @param {object} info Contains details of user in group who is dismissed as admin
   * GroupId - Unique Id of the Group
   * UserId - Unique Id of the user who is dismissed as admin in the group
   */
  onGroupAdminDismissed = info => {
    Logger.info("User  " + info.UserId + " has been dismissed as admin from group " + info.GroupId);
  }

  /**
   * api-method
   * Allows a user to leave the group
   *  @param {string} groupId Unique Id of the group that the user belongs to
   *  @param {string} userId Unique Id of the user who is to be dimissed as admin
   */
  leaveGroup = (groupId, userId) => {
    try {
      var loggedInUserId = this.livechat.getUserId();

      //Validate the parameters passed are null/undefined/blank
      if (groupId == null || groupId === "") {
        Logger.error("Parameter (groupId) in Method (leaveGroup) cannot be blank");
        return;
      } else if (userId == null) {
        Logger.error("Parameter (userId) in Method (leaveGroup) cannot be blank");
        return;
      } else if (loggedInUserId == null || loggedInUserId === "") {
        Logger.error(groupMessages.common.userNotLoggedIn);
        return;
      }

      var messageContentObject = new GroupAdminModificationRequestModel();
      messageContentObject.GroupId = groupId;
      messageContentObject.UserId = userId;
      messageContentObject.RequestorUserId = loggedInUserId;

      //Send the message
      this.livechat.sendLv2Message("user-left-group", JSON.stringify(messageContentObject), "service:GMS");
    } catch (e) {
      Logger.error("Error in leaveGroup", e);
    }
  }

  /**
   * api-callback
   * Triggered when a user has left the group
   * @param {object} info Contains details of user in group who is dismissed as admin
   * GroupId - Unique Id of the Group
   * UserId - Unique Id of the user who is dismissed as admin in the group
   */
  onUserLeftGroup = info => {
    Logger.info("User  " + info.UserId + " has left group " + info.GroupId);
  }

  /**
   * api-method
   * Allows a user to change the icon of the group (request can only be made by an existing administrator of the group)
   *  @param {string} groupId Unique Id of the group that the user belongs to (cannot be blank)
   *  @param {string} iconPath Path of the icon (cannot be blank)
   */
  changeGroupIcon = (groupId, iconPath) => {
    try {
      var loggedInUserId = this.livechat.getUserId();

      //Validate the parameters passed are null/undefined/blank
      if (groupId == null || groupId === "") {
        Logger.error("Parameter (groupId) in Method (changeGroupIcon) cannot be blank");
        return;
      } else if (iconPath == null) {
        Logger.error("Parameter (iconPath) in Method (changeGroupIcon) cannot be blank");
        return;
      } else if (loggedInUserId == null || loggedInUserId === "") {
        Logger.error(groupMessages.common.userNotLoggedIn);
        return;
      }

      var messageContentObject = new ModifyGroupIconModel();
      messageContentObject.GroupId = groupId;
      messageContentObject.IconPath = iconPath;
      messageContentObject.RequestorUserId = loggedInUserId;

      //Send the message
      this.livechat.sendLv2Message("group-icon-change", JSON.stringify(messageContentObject), "service:GMS");
    } catch (e) {
      Logger.error("Error in changeGroupIcon", e);
    }
  }

  /**
   * api-callback
   * Triggered when the group icon has changed
   * @param {object} info Contains details of the changed group icon
   * GroupId - Unique Id of the Group 
   * IconPath - Path of the icon
   */
  onGroupIconChanged = info => {
    Logger.info("Group " + info.GroupId + " icon has changed (" + info.IconPath + ")");
  }

  /**
   * api-method
   * Request Interaction List based on the logged in User/Session
   */
  getUserInteractionList = () => {
    try {
      var loggedInUserId = this.livechat.getUserId();

      if (loggedInUserId == null || loggedInUserId === "") {
        Logger.error("loggedInUserId is blank/undefined in method (getUserInteractionList)");
        return;
      }

      this.getUserInteractionListForUser([loggedInUserId]);
    } catch (e) {
      Logger.error("Error in getUserInteractionList", e);
    }
  }

  /**
   * api-method
   * Request Interaction List based for a specific user
   * @param {Array<string>} userIdList List of user Id's for whom the interaction details are to be fetched
   */
  getUserInteractionListForUser = (userIdList) => {
    try {
      if (userIdList == null || userIdList === "" || userIdList.length === 0) {
        Logger.error("Parameter (userIdList) in Method (getUserInteractionListForUser) cannot be blank");
        return;
      }

      var obj = {};
      obj.userIdList = userIdList;

      //Send the message
      this.livechat.sendLv2Message("interaction-listing", JSON.stringify(obj), "service:GMS");
    } catch (e) {
      Logger.error("Error in getUserInteractionListForUser", e);
    }
  }

  /**
    * api-callback
    * Triggered when a interaction listing for a user was requested
    * @param {object} interactionListInfo Array containing a list of objects, each object contains the following properties:
    * UserId - Id of the user who requested the interaction
    * InteractionListInformation - List<object> which contains the interaction information, each object in the list will contain the following properties:
    *    InteractionId - Interaction Id of the interaction 
    *    IsCallEscalated - boolean, indicating if the interaction is escalated to a call or not
    *    StartDateTime - DateTime when the interaction was started
    *    Channel - 
    *    SubChannel - 
    *    Intent - 
    *    Skill - 
    *    UserInformation - List<object> which contains the user information for the interaction, each object in the list will contain the following properties:
    *       Id - Unique Id of the User
    *       Name - Name of the user
    *       TransferInformation - Object which contains the transfer information for the user, it will contain the following properties:
    *           IsInteractionTransferredToUser - true indicates that the interaction was transferred to this user
    *           InteractionUserTransferList - List of users from whom the interaction was transferred from
    *       ConferenceInformation - Object which contains the conference information for the user, it will contain the following properties:
    *           IsUserConferencedToInteraction - true indicates the user is conferenced to the interaction
    *           IsChat - true indicates user is to be added to the chat interaction (Also if this chat interaction is already escalated to a call, user will be conferenced to that call as well)
    *           IsCall - true indicates user is to be added to the call in the interaction (This option is mainly used, when the user only has to be conferenced to the video call and not to the chat interaction)
    *    CustomerUserId - User Id of the customer 
    *    CustomData - Contains custom information
    * NOTE: In case there is no more interaction list available for the user or if there is some error in server, 'InteractionListInformation' property will be passed as null
    */
  onUserInteractionListing = (interactionListInfo) => {
    Logger.info("Interaction List received:\n" + JSON.stringify(interactionListInfo));
  }

  /**
   * Triggered when a interaction has been created/started
   * @param {object} interactionInformation Is an object that contains the properties of the interaction
   *    InteractionId - Interaction Id of the interaction which is to be conferenced
   *    IsCallEscalated - boolean, indicating if the interaction is escalated to a call or not
   *    StartDateTime - DateTime when the interaction was started
   *    Channel - 
   *    SubChannel - 
   *    Intent - 
   *    Skill - 
   *    UserInformation - List<object> which contains the user information for the interaction, each object in the list will contain the following properties:
   *       Id - Unique Id of the User
   *       Name - Name of the user
   *       TransferInformation - Object which contains the transfer information for the user, it will contain the following properties:
   *           IsInteractionTransferredToUser - true indicates that the interaction was transferred to this user
   *           InteractionUserTransferList - List of users from whom the interaction was transferred from
   *       ConferenceInformation - Object which contains the conference information for the user, it will contain the following properties:
   *           IsUserConferencedToInteraction - true indicates the user is conferenced to the interaction
   *           IsChat - true indicates user is to be added to the chat interaction (Also if this chat interaction is already escalated to a call, user will be conferenced to that call as well)
   *           IsCall - true indicates user is to be added to the call in the interaction (This option is mainly used, when the user only has to be conferenced to the video call and not to the chat interaction)
   *    CustomerUserId - User Id of the customer 
   *    CustomData - Contains custom information
   */
  onInteractionStarted = interactionInformation => {
    Logger.info(`Interaction Started:\n${JSON.stringify(interactionInformation)}`);
  }

  /**
   * api-method
   * Request for a conference on an existing interaction
   * @param {string} userId Contains the Id of the user to be conferenced in the call (exclude user:)
   * @param {string} interactionId Interaction Id of the existing interaction, to which the user is to be conferenced to
   * @param {boolean} isChat true indicates user is to be added to the chat interaction (Also if this chat interaction is already escalated to a call, user will be conferenced to that call as well)
   * @param {boolean} isCall true indicates user is to be added to the call in the interaction (This option is mainly used, when the user only has to be conferenced to the video call and not to the chat interaction)
   */
  sendConferenceRequest = (userId, interactionId, isChat, isCall) => {
    try {
      if (interactionManager.isConferenceRequestSent === false) {
        var loggedInUserId = this.livechat.getUserId();

        if (userId == null || userId === "") {
          Logger.error("Parameter (userId) in Method (sendConferenceRequest) cannot be blank");
          return;
        }
        else if (loggedInUserId == null || loggedInUserId === "") {
          Logger.error("loggedInUserId is blank/undefined in method (sendConferenceRequest)");
          return;
        }
        else if (interactionId == null || interactionId === "") {
          Logger.error("Parameter (interactionId) in Method (sendConferenceRequest) cannot be blank");
          return;
        }
        else if (isChat == null || typeof isChat !== "boolean") {
          Logger.error("Parameter (isChat) in Method (sendConferenceRequest) cannot be blank and its type should be boolean");
          return;
        }
        else if (isCall == null || typeof isCall !== "boolean") {
          Logger.error("Parameter (isCall) in Method (sendConferenceRequest) cannot be blank and its type should be boolean");
          return;
        }
        else if (loggedInUserId === userId) {
          Logger.error("The logged-in user and the user being conferenced are the same");
          return;
        }

        var requestModel = new InteractionConferenceModel();
        requestModel.RequestId = GenerateUUID();
        requestModel.InteractionId = interactionId;
        requestModel.RequestorUserId = loggedInUserId;
        requestModel.UserId = userId;
        requestModel.IsChat = isChat;
        requestModel.IsCall = isCall;

        //Send the message
        this.livechat.sendLv2Message("interaction-conference-request", JSON.stringify(requestModel), "service:GMS");

        interactionManager.SetConferenceRequestTimeout(requestModel.RequestId, true);
      }
      else {
        Logger.error("A conference request has already been sent with request ID: " + interactionManager.conferenceRequestId);
      }
    } catch (e) {
      Logger.error("Error in sendConferenceRequest", e);
    }
  }

  /**
    * api-callback
    * Triggered when a conference request is received
    * @param {object} acceptRejectObject Is an object that contains the following properties
    * RequestId - A unique requestId generated for the request
    * UserId - the id of the user who is to be conferenced
    * RequestorUserId - id of the user who requested the conference
    * InteractionId - Interaction Id of the chat/call where the User is to be conferenced to
    * isChat - true indicates if the user is to be conferenced to the chat (and also call if the chat is escalated) in the interaction
    * isCall - true indicates if the user is to be conferenced to the call
    * accept() - This function can be called to accept the conference request
    * reject() - This function can be called to reject the conference request 
    * InteractionInformation - Is an object that contains the properties of the interaction being conferenced
    *    InteractionId - Interaction Id of the interaction which is to be conferenced
    *    IsCallEscalated - boolean, indicating if the interaction is escalated to a call or not
    *    StartDateTime - DateTime when the interaction was started
    *    Channel - 
    *    SubChannel - 
    *    Intent - 
    *    Skill - 
    *    UserInformation - List<object> which contains the user information for the interaction, each object in the list will contain the following properties:
    *       Id - Unique Id of the User
    *       Name - Name of the user
    *       TransferInformation - Object which contains the transfer information for the user, it will contain the following properties:
    *           IsInteractionTransferredToUser - true indicates that the interaction was transferred to this user
    *           InteractionUserTransferList - List of users from whom the interaction was transferred from
    *       ConferenceInformation - Object which contains the conference information for the user, it will contain the following properties:
    *           IsUserConferencedToInteraction - true indicates the user is conferenced to the interaction
    *           IsChat - true indicates user is to be added to the chat interaction (Also if this chat interaction is already escalated to a call, user will be conferenced to that call as well)
    *           IsCall - true indicates user is to be added to the call in the interaction (This option is mainly used, when the user only has to be conferenced to the video call and not to the chat interaction)
    *    CustomerUserId - User Id of the customer 
    *    CustomData - Contains custom information
    */
  onConferenceRequest = (acceptRejectObject) => {
    Logger.info("Conference request received for interaction: " + acceptRejectObject.InteractionId);
  }

  /**
    * api-callback
    * Triggered when a conference response is acknowledged
    * @param {object} responseObject Is an object that contains the following properties
    * RequestId - A unique requestId generated for the request
    * UserId - the id of the user who is to be conferenced
    * RequestorUserId - id of the user who requested the conference
    * InteractionId - Interaction Id of the chat/call where the User is to be conferenced to
    * isChat - true indicates if the user is to be conferenced to the chat
    * isCall - true indicates if the user is to be conferenced to the call
    * isAccepted - true indicates that the recepient accepted the conference request
    * InteractionInformation - Is an object that contains the properties of the interaction being conferenced
    *    InteractionId - Interaction Id of the interaction which is to be conferenced
    *    IsCallEscalated - boolean, indicating if the interaction is escalated to a call or not
    *    StartDateTime - DateTime when the interaction was started
    *    Channel - 
    *    SubChannel - 
    *    Intent - 
    *    Skill - 
    *    UserInformation - List<object> which contains the user information for the interaction, each object in the list will contain the following properties:
    *       Id - Unique Id of the User
    *       Name - Name of the user
    *       TransferInformation - Object which contains the transfer information for the user, it will contain the following properties:
    *           IsInteractionTransferredToUser - true indicates that the interaction was transferred to this user
    *           InteractionUserTransferList - List of users from whom the interaction was transferred from
    *       ConferenceInformation - Object which contains the conference information for the user, it will contain the following properties:
    *           IsUserConferencedToInteraction - true indicates the user is conferenced to the interaction
    *           IsChat - true indicates user is to be added to the chat interaction (Also if this chat interaction is already escalated to a call, user will be conferenced to that call as well)
    *           IsCall - true indicates user is to be added to the call in the interaction (This option is mainly used, when the user only has to be conferenced to the video call and not to the chat interaction)
    *    CustomerUserId - User Id of the customer 
    *    CustomData - Contains custom information
    */
  onConferenceResponseAcknowledgement = (responseObject) => {
    if (responseObject.isAccepted) {
      Logger.info("Conference request for interaction: " + responseObject.InteractionId + " has been accepted by user " + responseObject.UserId);
    }
    else {
      Logger.info("Conference request for interaction: " + responseObject.InteractionId + " has been rejected by user " + responseObject.UserId);
    }

  }

  /**
   * api-callback
   * Triggered when a conference request is processed successfully
   * @param {object} info Is an object that contains the following properties
   * UserId - the id of the user to whom the interaction is to be conferenced to
   * RequestorUserId - id of the user who requested the conference
   * InteractionId - Interaction Id of the interaction to which the user is to be conferenced to
   * IsChat - true indicates if the user is to be conferenced to the chat (and also call if the chat is escalated) in the interaction
   * IsCall - true indicates if the user is to be conferenced to the call in the interaction
   * InteractionInformation - Is an object that contains the properties of the interaction being conferenced
   *    InteractionId - Interaction Id of the interaction which is to be conferenced
   *    IsCallEscalated - boolean, indicating if the interaction is escalated to a call or not
   *    StartDateTime - DateTime when the interaction was started
   *    Channel - 
   *    SubChannel - 
   *    Intent - 
   *    Skill - 
   *    UserInformation - List<object> which contains the user information for the interaction, each object in the list will contain the following properties:
    *       Id - Unique Id of the User
    *       Name - Name of the user
    *       TransferInformation - Object which contains the transfer information for the user, it will contain the following properties:
    *           IsInteractionTransferredToUser - true indicates that the interaction was transferred to this user
    *           InteractionUserTransferList - List of users from whom the interaction was transferred from
    *       ConferenceInformation - Object which contains the conference information for the user, it will contain the following properties:
    *           IsUserConferencedToInteraction - true indicates the user is conferenced to the interaction
    *           IsChat - true indicates user is to be added to the chat interaction (Also if this chat interaction is already escalated to a call, user will be conferenced to that call as well)
    *           IsCall - true indicates user is to be added to the call in the interaction (This option is mainly used, when the user only has to be conferenced to the video call and not to the chat interaction)
   *    CustomerUserId - User Id of the customer
   *    CustomData - Contains custom information
   */
  onInteractionConferenceSuccess = (info) => {
    Logger.info("User: " + info.UserId + " has been successfully conferenced to the interaction: " + info.InteractionId);
  }

  /**
  * api-method
  * Request for a transfer on an existing interaction
  * @param {string} userId Contains the Id of the user to whom the interaction is to be transferred to
  * @param {string} interactionId Interaction Id of the existing interaction, which has to be transferred
  */
  sendInteractionTransferRequest = (userId, interactionId) => {
    try {
      if (interactionManager.isTransferRequestSent === false) {
        var loggedInUserId = this.livechat.getUserId();

        if (userId == null || userId === "") {
          Logger.error("Parameter (userId) in Method (sendTransferRequest) cannot be blank");
          return;
        }
        else if (loggedInUserId == null || loggedInUserId === "") {
          Logger.error("loggedInUserId is blank/undefined in method (sendTransferRequest)");
          return;
        }
        else if (interactionId == null || interactionId === "") {
          Logger.error("Parameter (interactionId) in Method (sendTransferRequest) cannot be blank");
          return;
        }
        else if (loggedInUserId === userId) {
          Logger.error("The logged-in user and the user to whom the interaction is being transferred to are the same");
          return;
        }

        var requestModel = new InteractionTransferModel();
        requestModel.RequestId = GenerateUUID();
        requestModel.InteractionId = interactionId;
        requestModel.RequestorUserId = loggedInUserId;
        requestModel.UserId = userId;

        //Send the message
        this.livechat.sendLv2Message("interaction-transfer-request", JSON.stringify(requestModel), "service:GMS");

        interactionManager.SetTransferRequestTimeout(requestModel.RequestId, true);
      }
      else {
        Logger.error("A transfer request has already been sent with request ID: " + interactionManager.transferRequestId);
      }
    } catch (e) {
      Logger.error("Error in sendTransferRequest", e);
    }
  }

  /**
    * api-callback
    * Triggered when a transfer request is received
    * @param {object} acceptRejectObject Is an object that contains the following properties
    * RequestId - A unique requestId generated for the request
    * UserId - the id of the user to whom the interaction is to be transferred to
    * RequestorUserId - id of the user who requested the transfer
    * InteractionId - Interaction Id of the chat/call which is to be transferred
    * accept() - This function can be called to accept the transfer request
    * reject() - This function can be called to reject the transfer request 
    * InteractionInformation - Is an object that contains the properties of the interaction being conferenced
    *    InteractionId - Interaction Id of the interaction which is to be transferred
    *    IsCallEscalated - boolean, indicating if the interaction is escalated to a call or not
    *    StartDateTime - DateTime when the interaction was started
    *    Channel - 
    *    SubChannel - 
    *    Intent - 
    *    Skill - 
    *    UserInformation - List<object> which contains the user information for the interaction, each object in the list will contain the following properties:
    *       Id - Unique Id of the User
    *       Name - Name of the user
    *       TransferInformation - Object which contains the transfer information for the user, it will contain the following properties:
    *           IsInteractionTransferredToUser - true indicates that the interaction was transferred to this user
    *           InteractionUserTransferList - List of users from whom the interaction was transferred from
    *       ConferenceInformation - Object which contains the conference information for the user, it will contain the following properties:
    *           IsUserConferencedToInteraction - true indicates the user is conferenced to the interaction
    *           IsChat - true indicates user is to be added to the chat interaction (Also if this chat interaction is already escalated to a call, user will be conferenced to that call as well)
    *           IsCall - true indicates user is to be added to the call in the interaction (This option is mainly used, when the user only has to be conferenced to the video call and not to the chat interaction)
    *    CustomerUserId - User Id of the customer 
    *    CustomData - Contains custom information
    */
  onInteractionTransferRequest = (acceptRejectObject) => {
    Logger.info("Transfer request received for interaction: " + acceptRejectObject.InteractionId);
  }

  /**
   * api-callback
   * Triggered when a conference request is received
   * @param {object} acceptRejectObject Is an object that contains the following properties
   * RequestId - A unique requestId generated for the request
   * UserId - the id of the user to whom the interaction is to be transferred to
   * RequestorUserId - id of the user who requested the transfer
   * InteractionId - Interaction Id of the interaction which is to be transferred
   * isAccepted - true indicates that the recepient accepted the transfer request
   * InteractionInformation - Is an object that contains the properties of the interaction being conferenced
    *    InteractionId - Interaction Id of the interaction which is to be transferred
    *    IsCallEscalated - boolean, indicating if the interaction is escalated to a call or not
    *    StartDateTime - DateTime when the interaction was started
    *    Channel - 
    *    SubChannel - 
    *    Intent - 
    *    Skill - 
    *    UserInformation - List<object> which contains the user information for the interaction, each object in the list will contain the following properties:
    *       Id - Unique Id of the User
    *       Name - Name of the user
    *       TransferInformation - Object which contains the transfer information for the user, it will contain the following properties:
    *           IsInteractionTransferredToUser - true indicates that the interaction was transferred to this user
    *           InteractionUserTransferList - List of users from whom the interaction was transferred from
    *       ConferenceInformation - Object which contains the conference information for the user, it will contain the following properties:
    *           IsUserConferencedToInteraction - true indicates the user is conferenced to the interaction
    *           IsChat - true indicates user is to be added to the chat interaction (Also if this chat interaction is already escalated to a call, user will be conferenced to that call as well)
    *           IsCall - true indicates user is to be added to the call in the interaction (This option is mainly used, when the user only has to be conferenced to the video call and not to the chat interaction)
    *    CustomerUserId - User Id of the customer 
    *    CustomData - Contains custom information
   */
  onInteractionTransferResponseAcknowledgement = (responseObject) => {
    if (responseObject.isAccepted) {
      Logger.info("Transfer request for interaction: " + responseObject.InteractionId + " has been accepted by user " + responseObject.UserId);
    }
    else {
      Logger.info("Transfer request for interaction: " + responseObject.InteractionId + " has been rejected by user " + responseObject.UserId);
    }
  }

  /**
   * api-callback
   * Triggered when a transfer request is processed successfully
   * @param {object} info Is an object that contains the following properties
   * UserId - the id of the user to whom the interaction is to be transferred to
   * RequestorUserId - id of the user who requested the transfer
   * InteractionId - Interaction Id of the interaction which is to be transferred
   * InteractionInformation - Is an object that contains the properties of the interaction being transferred
   *    InteractionId - Interaction Id of the interaction which is to be transferred
   *    IsCallEscalated - boolean, indicating if the interaction is escalated to a call or not
   *    StartDateTime - DateTime when the interaction was started
   *    Channel - 
   *    SubChannel - 
   *    Intent - 
   *    Skill - 
   *    UserInformation - List<object> which contains the user information for the interaction, each object in the list will contain the following properties:
    *       Id - Unique Id of the User
    *       Name - Name of the user
    *       TransferInformation - Object which contains the transfer information for the user, it will contain the following properties:
    *           IsInteractionTransferredToUser - true indicates that the interaction was transferred to this user
    *           InteractionUserTransferList - List of users from whom the interaction was transferred from
    *       ConferenceInformation - Object which contains the conference information for the user, it will contain the following properties:
    *           IsUserConferencedToInteraction - true indicates the user is conferenced to the interaction
    *           IsChat - true indicates user is to be added to the chat interaction (Also if this chat interaction is already escalated to a call, user will be conferenced to that call as well)
    *           IsCall - true indicates user is to be added to the call in the interaction (This option is mainly used, when the user only has to be conferenced to the video call and not to the chat interaction)
   *    CustomerUserId - User Id of the customer
   *    CustomData - Contains custom information
   */
  onInteractionTransferSuccess = (info) => {
    Logger.info("Interaction: " + info.InteractionId + " has been successfully transferred from user: " + info.RequestorUserId + " to user: " + info.UserId);
  }

  /**
   * api-method
   * When the user wants to leave an interaction
   * @param {string} interactionId Interaction Id of the interaction
   * @param {string} reason Reason the user is leaving the interaction
   * @param {object} customData Is an object which has custom properties (to be sent to Contact Service)
   */
  leaveInteraction = (interactionId, reason, customData = {}) => {
    try {
      if (interactionId == null || interactionId === "") {
        Logger.error("Parameter (interactionId) in Method (leaveInteraction) cannot be blank");
        return;
      }

      if (interactionId.indexOf("interaction:") > -1) {
        interactionId = interactionId.split(":")[1];
      }

      this.livechat.leaveInteraction(interactionId, reason, customData);
    } catch (e) {
      Logger.error("Error in leaveInteraction", e);
    }
  }

  /**
   * api-callback
   * Triggered when a user has left an interaction
   * @param {object} info Is an object that contains the following properties
   * UserId - Unique Id of the User who left the interaction
   * InteractionId - Interaction Id of the interaction which the user left
   * Reason - Reason why the user left the interaction
   */
  onUserLeftInteraction = info => {
    Logger.info(`User ${info.UserId} has left the interaction ${info.InteractionId}`);
  }

  /**
   * api-callback
   * Triggered when an interaction has ended
   * @param {object} info Is an object that contains the following properties
   * Reason - Reason why the interaction ended
   * InteractionId - Interaction Id of the interaction which is ended
   */
  onInteractionEnd = info => {
    Logger.info("Interaction (" + info.InteractionId + ") ended with reason: " + info.Reason);
  }

  /**
  * api-method
  * Request to update an interaction Action in the Database
  * @param {string} action Action that needs to be updated in the Database
  * @param {string} interactionId Interaction Id of the interaction
  */
  updateInteractionActions = (interactionId, action) => {
    try {
      var loggedInUserId = this.livechat.getUserId();

      if (interactionId == null || interactionId === "") {
        Logger.error("Parameter (interactionId) in Method (updateInteractionActions) cannot be blank");
        return;
      }
      else if (action == null || action === "") {
        Logger.error("Parameter (action) in Method (updateInteractionActions) cannot be blank");
        return;
      }
      else if (loggedInUserId == null || loggedInUserId === "") {
        Logger.error("loggedInUserId is blank/undefined in method (updateInteractionActions)");
        return;
      }

      if (interactionId.indexOf("interaction:") > -1) {
        interactionId = interactionId.split(":")[1];
      }

      var request = new InteractionActionRequestModel();
      request.Action = action;
      request.InteractionId = interactionId;
      request.RequestorUserId = loggedInUserId;

      this.livechat.sendLv2Message("interaction-actions", JSON.stringify(request), "service:GMS");

    } catch (e) {
      Logger.error("Error in updateInteractionActions", e);
    }
  }

  //= = = = = = = = = = = = = = Media Signalling service = = = = = = = = = 

  sendCallSignal = (content, to) => {
    Logger.info("Sending call signal ");
    this.livechat.sendLv2Message("call-signal", content, to);
  }

  //= = = = = = = = = = = Agent Selection Service = = = = =  = == = = = = = = = =
  /**
  * api-callback
  * Triggered when suitable agent/customer is bridged.
  * @param {object} info Contains details of the other party. 
  * interactionId - string ndicates interaction id for the chat.
  * reqSkill - string requested skill corresponding to intent requested.
  * reqId - string value request Id passed during findUser method
  * type - string represents the type of user. values can be Agent or Customer orAdmin
  * intent - string intent passed in the findUser method.
  * channel - string channel value which will be passed in findUser. value can be Chat, audio, video
  * userDetails - json string which has user details with two mandotory field. 1. "id" => userid of remote party, 2. "name" => name of the remote party
  * 
  */
  onUserFound = (userFoundData) => {
    Logger.info("Found user intent:" + userFoundData.intent + " requested skill: " + userFoundData.reqSkill + " RequestId: " + userFoundData.reqId + " user type: " +
      userFoundData.type + " Interaction Id:" + userFoundData.interactionId + " channel: " + userFoundData.channel + " userDetails: " + userFoundData.userDetails);
  }

  /**
 * api-callback
 * Triggered when chat request is put into routing queue.
 * @param {object} info Contains details of call queued details.
 * position - integer value indicating the position of request queue. value -1 indicates routing api doesnt support position.
 * waittime - integer value indicating the estimate wait time in queue before connects to Agent.value -1 indicates routing api doesnt support waittime.
 * reqId - string value request Id passed during findUser method
 * reqSkill - string requested skill passed to findUserMethod
 */
  onRequestQueued = (reqQueuedData) => {
    Logger.info("Request queued position :" + reqQueuedData.position + " requested Skill: " + reqQueuedData.reqSkill + " RequestId: " + reqQueuedData.reqId);
  }

  /**
   * api-callback
   * Triggered when Server decided to provide notification instead of connecting an agent.
   * @param {object} info Contains reason code for notification. Possible reason codes are
   * reasonCode - NoStaffedAgents | QueueIsTooBig | TimedOutInQueue | ErrorInAgentDetails | ErrorAgentDetails
   */
  onSystemNotification = (notification) => {
    Logger.info("System Notification reason :" + notification.reasonCode)
  }

  /**
 * api-callback
 * Triggered when chat requests in the queues are changed
 * @param {object} info Contains details of queue positions in the waiting queue.
 * position - integer value indicating the position of request queue.
 * reqId - string value request Id passed during findUser method
 * reqSkill - string requested skill passed to findUserMethod
 */
  onQueuePositionUpdated = (queuePositionData) => {
    Logger.info("Updated queued position :" + queuePositionData.position + " requested Skill: " + queuePositionData.reqSkill + " RequestId: " + queuePositionData.reqId);
  }

  /**
 * api-callback
 * Triggered when suitable agent is not found for findUser request
 * @param {object} info Contains details of user in group who is dismissed as admin
 * reqId - string request Id passed during findUser method
 * reqSkill - string requested skill passed to findUserMethod
 * reason - error message if any.
 */
  onUserNotFound = (notfoundData) => {
    Logger.info("User Not found for requested Skill: " + notfoundData.reqSkill + " RequestId: " + notfoundData.reqId);
  }

  /**
 * api-method
 * Allows a user to send a request find a suitable agent to connect
 * @param {string} requestId Unique Id of the request
 * @param {string} customerInfo json string which has customer details in json form.
 * @param {string} intent requested intent
 * @param {string} channel requested channel type.Default value is "Chat"
 * @param {string} language use preferred language.
 */
  findUser = (requestId, customerInfo, intent, channel = "chat", language = null) => {
    try {
      var findUserModel = {};

      findUserModel.reqId = requestId;
      findUserModel.customerInfo = customerInfo;

      findUserModel.intent = intent;
      findUserModel.channel = channel;
      findUserModel.language = language;
      findUserModel.version = "100.000";

      this.livechat.sendLv2Message("find-user", JSON.stringify(findUserModel), "service:ASL");
    }
    catch (e) {
      Logger.error("Error in findUser", e);
    }
  }

  /**
  * api-method
  * Request to get the Estimated Wait Time (EWT) for a particular request
  * @param {string} requestId RequestId of the request
  */
  getEWTStatus = (requestId) => {
    try {
      var loggedInUserId = this.livechat.getUserId();

      if (requestId === "" || requestId == null) {
        Logger.error("Parameter (requestId) in Method (getEWTStatus) cannot be blank");
        return;
      }
      else if (loggedInUserId === "" || loggedInUserId == null) {
        Logger.error("loggedInUserId is blank/undefined in method (getEWTStatus)");
        return;
      }

      var requestObject = {};
      requestObject.requestId = requestId;
      requestObject.requesterUserId = loggedInUserId;

      this.livechat.sendLv2Message("request-ewt", JSON.stringify(requestObject), "service:ASL");
    }
    catch (e) {
      Logger.error("Error in getEWTStatus", e);
    }
  }

  /**
   * api-callback
   * Triggered when a response is received for an EWT request
   * @param {object} responseObj Is an object that contains the following properties
   * ErrorCode: error code
   * ErrorDetails: details of the error
   * EWT: Estimated Wait Time
   * RequestId: Request Id
   */
  onEWTStatus = (responseObj) => {
    Logger.info("Details for Estimated Wait Time received: " + JSON.stringify(responseObj));
  }

  /**
   * Requests a signature from a user
   * @param {string} userId Unique Id of the user whose signature is to be requested
   */
  requestSignatureCapture = userId => {
    if (signatureManager.isSignatureRequestSent === false) {
      if (userId == null || userId === "") {
        Logger.error("Parameter (userId) in method (requestSignatureCapture) cannot be blank");
        return;
      }

      if (userId.indexOf("user:") > -1)
        userId = userId.split(':')[1];

      let requestId = GenerateUUID();

      var messageObject = new AppEventMessage();
      messageObject.requesterUserId = this.livechat.getUserId();
      messageObject.messageSubType = "signature-request";
      messageObject.content = {};
      messageObject.content.requestId = requestId;
      messageObject.content.requesterUserId = this.livechat.getUserId();
      messageObject.content.userId = userId;

      this.sendAppEvent(JSON.stringify(messageObject), `user:${userId}`);

      signatureManager.SetSignatureRequestTimeout(requestId, true);
    }
    else
      Logger.error(`A signature capture request has already been sent with request ID: ${signatureManager.signatureRequestId}`);
  }

  /**
   * Triggers when a user requested for signature.
   * @param {object} acceptOrRejectObject Object that contains the following properties & methods
   * requestId - Unique Id for the request
   * userId - Unique Id of the user who has to sign
   * requesterUserId - Unique Id of the user who requested the signature
   * accept() - accepts the signature (by passing the signature data in the parameter)
   * reject() - rejects the signature
   */
  onSignatureRequest = acceptOrRejectObject => {
    if (acceptOrRejectObject != null)
      Logger.info(`Signature Request from user ${acceptOrRejectObject.requesterUserId}`);
    else
      Logger.info("Requested for Signature");
  }

  /**
   * An acknowledgement indicating if the user accepted/rejected the request
   * @param {object} ackInfo Object that contains the following properties
   * requestId - Unique Id for the request
   * userId - Unique Id of the user who has to sign
   * requesterUserId - Unique Id of the user who requested the signature
   * isAccepted - true indicates user accepted the request else it rejected the request
   * data - contains the signature data if user accepted the request, else it will contain null
   */
  onSignatureRequestAck = ackInfo => {
    if (ackInfo.isAccepted === true)
      Logger.info(`User ${ackInfo.userId} has accepted the signature request (${ackInfo.requestId})`);
    else
      Logger.info(`User ${ackInfo.userId} has rejected the signature request (${ackInfo.requestId})`);
  }

  onFileUploadSuccess = (responseObj) => {
    Logger.info("FileUploadSuccess: " + JSON.stringify(responseObj));
  }

  onFileUploadFailure = (responseObj) => {
    Logger.info("FileUploadFailure: " + JSON.stringify(responseObj));
  }

  /**
   * Requests a snapshot from a user
   * @param {string} userId Unique Id of the user whose snapshot is to be captured
   */
  requestSnapshotCapture = userId => {
    if (snapshotManager.isSnapshotRequestSent === false) {
      if (userId == null || userId === "") {
        Logger.error("Parameter (userId) in method (requestSnapshotCapture) cannot be blank");
        return;
      }

      if (userId.indexOf("user:") > -1)
        userId = userId.split(':')[1];

      let requestId = GenerateUUID();

      var messageObject = new AppEventMessage();
      messageObject.requesterUserId = this.livechat.getUserId();
      messageObject.messageSubType = "user-snapshot-request";
      messageObject.content = {};
      messageObject.content.requestId = requestId;
      messageObject.content.requesterUserId = this.livechat.getUserId();
      messageObject.content.userId = userId;

      this.sendAppEvent(JSON.stringify(messageObject), `user:${userId}`);

      snapshotManager.SetSnapshotRequestTimeout(requestId, true);
    }
    else
      Logger.error(`A snapshot capture request has already been sent with request ID: ${snapshotManager.snapshotRequestId}`);
  }

  /**
   * 
   * @param {object} acceptOrRejectObject Object that contains the following properties & methods
   * requestId - Unique Id for the request
   * userId - Unique Id of the user whose screenshot is to be captured
   * requesterUserId - Unique Id of the user who requested the snapshot
   * accept() - accepts the snapshot (by passing the snapshot data in the parameter)
   * reject() - rejects the signature
   */
  onSnapshotRequest = acceptOrRejectObject => {
    if (acceptOrRejectObject != null)
      Logger.info(`Snapshot Request from user ${acceptOrRejectObject.requesterUserId}`);
  }

  /**
   * An acknowledgement indicating if the user accepted/rejected the request
   * @param {object} ackInfo Object that contains the following properties
   * requestId - Unique Id for the request
   * userId - Unique Id of the user who has to sign
   * requesterUserId - Unique Id of the user who requested the signature
   * isAccepted - true indicates user accepted the request else it rejected the request
   * data - contains the snapshot data if user accepted the request, else it will contain null
   */
  onSnapshotRequestAck = ackInfo => {
    if (ackInfo.isAccepted === true)
      Logger.info(`User ${ackInfo.userId} has accepted the snapshot request (${ackInfo.requestId})`);
    else
      Logger.info(`User ${ackInfo.userId} has rejected the snapshot request (${ackInfo.requestId})`);
  }
  //= = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = 


  // = = = = = = User Account MANAGEMENT = = = = = = =
  loginV2 = (username, password, callback, params) => {
    const this_ = this;
    const authUrl = params && params.authUrl;
    let url = authUrl ? `${authUrl}/login` : this.oPath;
    Logger.info(`Logging in user: ${username} into URL ${url}`);

    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({ username, password ,options: params})
    }).then(res => {
      return res.json();
    }).then(data => {
      if (data.isSuccess && data.tokenInfo) {
        Logger.info("Login success");
        this_.livechat.setUserId(data.tokenInfo.userId);
        this_.livechat.setAuthToken(data.tokenInfo.token);
        this_.livechat.setRefreshToken(data.tokenInfo.refreshToken);
        this_.livechat.setWorkspace(data.tokenInfo.organization);
        this_.livechat.setMyWorkspaces(data.tokenInfo.organizations);

        callback(true, data.tokenInfo.token, data.tokenInfo);
      } else {
        Logger.error(`Login failed - isSuccess : ${data.isSuccess}, Message : ${data.message}, TokenInfo : ${data.tokenInfo ? 'NOT NULL' : 'NULL'}`);
        callback(false);
      }

    }).catch(e => {
      Logger.error(`Error in loginRequest`, e);
      callback(false);
    })
  }

  /**
   * api-call
   * This method can be used to validate iam session
   * @param {String} appId AppID for IAM Login
   * @param {String} session IAM redirect token to validate
   * @param {String} iamUrl IAM API Url
   * @param {String} ocmApiUrl OCM API Url
   * @param {Function} generateToken method to generate a jwt token takes 2 paramters (session, response from validate method)
   * @param {String} feature feature value to validate access permissions
   * @param {Function} callback callback method to notify operation status once completed
   */
  validateLoginSession = (appId, session, iamUrl, ocmApiUrl, generateToken, feature, callback) => {
    const this_ = this;
    let url = iamUrl ? `${iamUrl}/api/v1/access-token/validate/${appId}` : this.oPath;
    Logger.info(`Validate login appId: ${appId} session : ${session}, URL: ${iamUrl}`);

    fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        [`access-token-${appId}`]: session
      }
    }).then(res => {
      return res.json();
    }).then(data => {
      if (data.result && data.result.isTemporaryTokenExpired) {
        Logger.info("Login session validated");

        //validate user access permissions       

        let tempData = JSON.parse(JSON.stringify(data.result));

        let usrSplit = tempData.userName.split('\\');

        tempData.userName = usrSplit.length > 1 ? usrSplit[1] : tempData.userName;

        let ocmUrl = ocmApiUrl ? `${ocmApiUrl}/api/OCMApi/GetFeature?username=${tempData.userName}&feature=${feature}` : this.oPath;
        Logger.info(`Validate Access permissions: ${appId} session : ${session}, URL: ${ocmUrl}`);

        fetch(ocmUrl, {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded"
          }
        }).then(res => {
          return res.json();
        }).then(data => {

          if (data.isSuccess && data.result && data.result.isEnabled) {

            let jwt = generateToken ? generateToken(session, tempData) : "";
            this_.livechat.setAuthToken(jwt);
            this_.livechat.setWorkspace("default");
            this_.livechat.setUserId(tempData.userName);

            this_.api.user_accounts.getUserDetailsBatch([tempData.userName]).then(users => {

              if (users && users.length > 0) {
                //get org unit
                let workspace = users[0].orgUnit.toString();
                let userName = users[0].userName;
                tempData.orgUnit = workspace;
                tempData.userName = userName;
                this_.livechat.setUserId(userName);
                this_.livechat.setWorkspace(workspace);
                let jwt2 = generateToken ? generateToken(session, tempData) : "";
                this_.livechat.setAuthToken(jwt2);

                callback(true, tempData, jwt2);

              } else {
                callback(false, null, "Login user not found");
              }

            }).catch(ex => {
              callback(false, null, "Error loading login user profile");
            })

          } else {
            Logger.error(`User doesnt have requred access permissions`);
            callback(false, null, "User doesnt have requred access permissions");

          }



        }).catch(ex => {
          Logger.error(`Error in validateLoginSession - User Access Permissions`, ex);
          callback(false, null, "Access permission validation error");
        })

      } else {
        Logger.warn('Login session validation failed');
        callback(false, null, "Login session validation failed");
      }

    }).catch(e => {
      Logger.error(`Error in validateLoginSession`, e);
      callback(false, null, "Login session validation error");
    })
  }

  switchWorkspace = (authUrl, userName, refreshToken, newWorkspace, callback) => {
    const this_ = this;
    let url = `${authUrl}/login/token/${newWorkspace}`;
    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({ userName, refreshToken })
    }).then(res => {
      return res.json();
    }).then(data => {
      if (data.isSuccess && data.tokenInfo) {
        Logger.info("Workspace switch success");
        this_.livechat.setUserId(data.tokenInfo.userId);
        this_.livechat.setAuthToken(data.tokenInfo.token);
        this_.livechat.setWorkspace(data.tokenInfo.organization);

        callback(true, data.tokenInfo);
      } else {
        Logger.error(`Workspace switch - isSuccess : ${data.isSuccess}, Message : ${data.message}, TokenInfo : ${data.tokenInfo ? 'NOT NULL' : 'NULL'}`);
        callback(false);
      }

    }).catch(e => {
      Logger.error(`Error in Workspace switch request`, e);
      callback(false);
    })
  }

  login = (username, password, callback, extraParams) => {

    this.init();

    let params = extraParams ? extraParams : { displayname: "", isVideo: false };

    const this_ = this;
    let url = this.oPath + `/login`;

    Logger.info(`Logging in user: ${username} with properties (${JSON.stringify(extraParams)}) into URL ${url}`);
    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "api-secret": "tetherfi"
      },
      body: JSON.stringify({ username, password, extraParams: params })
    }).then(res => {
      return res.json();
    }).then(data => {
      if (data.status === 0) {
        let authToken = data.data.authorization;
        let userId = data.data.userId;
        Logger.info('authToken : ' + authToken + '\nuserId : ' + userId);
        if (typeof authToken !== "undefined" && authToken && authToken != null) {
          this_.livechat.setUserId(userId);
          this_.livechat.setAuthToken(authToken);
          callback(true, authToken);
        } else {
          callback(false);
        }
      } else {
        Logger.error(`Login Failed`);
        callback(false);
      }

    }).catch(e => {
      Logger.error(`Error in login Request`, e);
      callback(false);
    })

  }

  logout = (reason, callback) => {
    const this_ = this;
    let url = this.oPath + `/logout?reason=` + reason;
    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "authorization": `${this_.livechat.getAuthToken()}`
      },
      body: {}
    }).then(res => {
      return res.json();
    }).then(data => {
      if (data.status == 0) {
        callback(true);
      } else {
        Logger.error(`Logout Failed`);
        callback(false);
      }

    }).catch(e => {
      Logger.error(`Error in logout Request`, e);
      callback(false);
    })
  }

}

export { Livechat2Api };
