import React, { useState, useEffect } from 'react';
import prettyMs from 'pretty-ms';
import moment from 'moment';
import './chatend-timer.scss';

let stopTimer = null;

const ChatEndTimer = (props) => {

    const [timer, setTimer] = useState(0);


    useEffect(() => {

        if (props.selected_profile && props.selected_profile.isACW) {
            stopTimer && clearInterval(stopTimer);
            setTimer(-1);
        } else {

            stopTimer && clearInterval(stopTimer);

            if (props.startTimestamp) {
                let currentTime = Date.now() - props.startTimestamp;
                setTimer(currentTime);
            }

            stopTimer = setInterval(() => {
                //console.log("============================");
                if (props.startTimestamp) {
                    let currentTime = Date.now() - props.startTimestamp;
                    setTimer(currentTime);
                }

            }, 1000);

        }

    }, [props.selected_profile]);

    /* useEffect(() => {
        if (__APP_CHAT_END_ACW_ALLOWED__ === "YES" && __APP_CHAT_END_ACW_TIME__ && buttonState === "ACW") {

            stopButtonTimer && clearInterval(stopButtonTimer);

            setTimer(acwStartTime);

            acwButtonTimer && clearInterval(acwButtonTimer);
            let round = 1;
            acwButtonTimer = setInterval(() => {
                console.log("============================");
                let currentTime = acwStartTime - (round * 1000);
                setTimer(currentTime);
                round++;

                if (round > __APP_CHAT_END_ACW_TIME__) {
                    clearInterval(acwButtonTimer);
                    props.onACWEnd && props.onACWEnd();
                }

            }, 1000);

        }


    }, [buttonState]); */


    return (
        <>
            {/*{timer === -1 ? "CHAT ENDED"*/}
            <div className={`chat-count-down-wrp d-flex justify-content-center align-items-center ${timer === -1 ? 'w' : ''}`}>
                <div className={'chat-count'}>
                    {timer !== -1 &&
                        <>
                            {moment.utc(moment.duration(timer, "ms").asMilliseconds()).format('HH:mm:ss')}
                        </>
                    }
                    {timer === -1 &&
                        <>
                            {`Interaction Ended`}
                        </>
                    }
                </div>

                {/*{timer === -1 &&*/}
                {/*<div className={'call-end-btn'} onClick={props.onStopPress}>*/}
                {/*    Chat End*/}
                {/*</div>*/}
                {/*}*/}
            </div>
        </>


    )
};

export default ChatEndTimer;
