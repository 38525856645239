import * as types from "../actions/actionTypes";
import produce from "immer";
import navigationRestrictions from "../../config/navigationRestrictions";

const getDefaultTab = () => {
    return navigationRestrictions.selected_user_info.active ? "0" : (navigationRestrictions.media.recent_media.active ? "2" : (navigationRestrictions.timeline.active ? "1" : "3"))
}

const initialState = { media_upload_panel: { show: false, data: { files: {}, type: "" } }, header_panel: { buttons: { file: { show: true } } }, action_panel: { chat_ready: "INIT" }, user_profile_panel: { tab: getDefaultTab() } };

export const uiPanelReducer = produce((draft = initialState, action) => {
    switch (action.type) {
        case types.UI_OPEN_MEDIA_UPLOAD:
            {
                draft.media_upload_panel.show = true;
                draft.media_upload_panel.data.files = action.mediaFiles;
                draft.media_upload_panel.data.type = action.mediaType;

                return draft;

            }
        case types.UI_CLOSE_MEDIA_UPLOAD:
            {
                draft.media_upload_panel.show = false;
                draft.media_upload_panel.data.files = {};
                return draft;

            }
        case types.UI_HEADER_PANEL_BUTTON_VISIBILITY_CHANGE:
            {
                if (action.data.panelButton === "FILE") {
                    draft.header_panel.buttons.file.show = action.data.enable;
                }

                return draft;

            }
        case types.UI_ACTION_PANEL_READY_STATE: {

            draft.action_panel.chat_ready = action.state;
            return draft;

        }

        case types.UI_USER_PROFILE_PANEL_TAB_CHANGE: {

            draft.user_profile_panel.tab = action.tabValue;
            return draft;

        }

        default:
            return draft;
    }
});