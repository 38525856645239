import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { MenuIcon, VideoCallerProfile } from "../index";
import phoneEngine from "../../pages/phone/phoneEngine/phoneEngineHandler";
import * as phoneConfig from "../../../config/phoneConfig";
import { Drawer, Image ,Tag ,Checkbox ,Card } from "antd";

const myVideoRef = React.createRef();
let loadLocalView = true;

const VideoCallConnecting = ({
  viewPointWidth,
  viewPointHeight,
  calleeName,
  onClick,
  isVideoLoading,
  size,
  connectingStatus,
  callType,bg_images
}) => {
  const [isLoadingMyView, setIsLoadingMyView] = useState(true);
  const [hideMyView, setHideMyView] = useState(false); 
  const [bcolore, setBcolore] = useState(null);
  const [showImageList, setShowImageList] = useState(false); 


  useEffect(() => {    
    console.log("VideoCallConnecting", "useEffect", "rendering video call connecting component" ,bg_images);
    
    if(phoneConfig.basic_config.sdk.virtual_background_feature_activate === true && bg_images != null && bg_images.length > 0){
      setShowImageList(true);
    }

    if(phoneConfig.basic_config.calls.selfView.preload === false ){
      showLocalVideo(); 
    }

    return () => {
      // Anything in here is fired on component unmount.
      loadLocalView = true;
    }

  }, []);

  const removeStream =()=>{
    try {     
      setBcolore(null);    
      setHideMyView(true);
      setIsLoadingMyView(true);         
      myVideoRef.current && myVideoRef.current.srcObject.getVideoTracks().forEach(track => {
        track.stop()
        myVideoRef.current.srcObject.srcObject.removeTrack(track);
      });
      
    } catch (error) {
      
    }
  }
  useEffect(() => {    
    try {
      switch (connectingStatus){
        case "Connecting":
          if(phoneConfig.basic_config.calls.selfView.on_call_connecting !== true){
            removeStream();
          }
          break;
          case "Ringing":
            if(phoneConfig.basic_config.calls.selfView.on_call_ringing !== true){
              removeStream();
            }
            break;
        default:
          break;
      }
      
    } catch (error) {
      
    }
  }, [connectingStatus]);


  const showLocalVideo = async (backendImage) => {
    try {
      console.debug("VideoCallConnecting","selfview", "binding local video");
      if(isVideoLoading && phoneConfig.basic_config.calls.selfView.active === true && (callType === "SELF_VIEW" || (phoneConfig.basic_config.calls.selfView.on_call_connecting === true && connectingStatus === "Connecting") ||(phoneConfig.basic_config.calls.selfView.on_call_ringing === true && connectingStatus === "Ringing"))){
        const localStream = await phoneEngine.phoneFunctions.getLocalVideo(phoneConfig,backendImage);
        if (myVideoRef.current  && localStream && localStream.active === true) {
          myVideoRef.current.srcObject = localStream;
          myVideoRef.current.addEventListener("loadeddata", event => {
            setIsLoadingMyView(false);  
            setBcolore({backgroundColor:"#000000"});
          });          
        }
      }     
    } catch (error) {
      console.error("VideoCallConnecting", "fail to get local video", error);
    }
  };

  if(loadLocalView === true && phoneConfig.basic_config.calls.selfView.preload === true ){
    loadLocalView = false;
    showLocalVideo();    
  }

  const applyVirtualBackground = async(b_image)=>{
    try {
      setBcolore(null);    
      setIsLoadingMyView(true); 
      setHideMyView(false)
      await phoneEngine.phoneFunctions.releaseLocalVideo(phoneConfig);
      
      await showLocalVideo(b_image);
    } catch (error) {
      console.error("VideoCallConnecting",'applyVirtualBackground', "fail to apply Virtual Background", error);
    }    
  }


  return (
    <>
    
    
      <div className={`video-caller-details-wrapper ${size}` } style={bcolore}>
      
      {showImageList === true && hideMyView === false && (
        
        <Drawer className="call-connecting-self-view"
              placement="right"
              closable={false}
              visible={true}
              getContainer={false}
              style={{ position: "absolute" }}
              width="66"
            >
              
                {bg_images && bg_images.map((src)=>{                 
                 return <Card key={src} style={{ width: 55,height : 65, marginTop: 0 ,marginLeft:2 }} actions={[<Tag color="geekblue" onClick={()=>applyVirtualBackground(src)}>apply</Tag>]} > <Image width={50} height={30} src={src} style={{ height:35}}/> </Card>
                })} 
              
        </Drawer>
        )}


        <div className={`video-profile-caller-center ${size}`}>
          {isLoadingMyView && (
            <div className={`user-profile-img-wrp light ${size}`}>
              <img
                src={require("../../../assets/img/inComingCallDefaultUser.svg")}
                alt="User Avatar"
              />
            </div>
          )}
          {!hideMyView && 
          <video
          ref={myVideoRef}
          autoPlay
          muted
          playsInline
          style={{
            width: "100%",
            height: "100%",
          }}
        >
        </video> }
          

          <div
            className={`profile-full-name light ${size}`}
            style={{
              position: "absolute",
              bottom: "35px",
              left: "15px",
              textDecoration: "none",
              color: "#4CAF50"
            }}
          >
            {callType === "SELF_VIEW" ? "" :calleeName}
          </div>
          <div
            className={`video-caller-details-connecting light ${size}`}
            style={{
              position: "absolute",
              bottom: "35px",
              right: "15px",
              textDecoration: "none",
              color: "#4CAF50"
            }}
          >
            {callType === "SELF_VIEW" ? "" :connectingStatus }
          </div>
          
        </div>

        <div className={`out-going-action-wrapper ${size}`}>
          <div
            className={
              "outgoing-end-call d-flex justify-content-center align-items-center"
            }
          >
            <MenuIcon
              onClick={onClick}
              size={"sm-space"}
              status={"active"}
              color={"red"}
              iconType={"material"}
              icon={<i className="material-icons">call_end</i>}
            />
          </div>
        </div>

        
      </div>
      {/* {isVideoLoading && (
        <div className={`video-caller-details-wrapper ${size}`}>
          <VideoCallerProfile
            key={"5685412dsfsdfsdfsd"}
            src={null}
            theme={"light"}
            size={size}
            connectStatus={"Connecting..."}
            fullName={calleeName}
          />
          <video
            id="test123"
            ref={myVideoRef}
            autoPlay
            muted
            playsInline
          ></video>
          <div className={`out-going-action-wrapper ${size}`}>
            <div
              className={
                "outgoing-end-call d-flex justify-content-center align-items-center"
              }
            >
              <MenuIcon
                onClick={onClick}
                size={"sm-space"}
                status={"active"}
                color={"red"}
                iconType={"material"}
                icon={<i className="material-icons">call_end</i>}
              />
            </div>
          </div>
        </div>
      )} */}
    </>
  );
};
VideoCallConnecting.propTypes = {
  onClick: PropTypes.func.isRequired,
  //calleeName: PropTypes.string.isRequired,
};

VideoCallConnecting.defaultProps = {
  size: "",
};

export default VideoCallConnecting;
