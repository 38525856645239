import * as baseUrls from "../config/baseUrls";
import { fetchDefaults } from "./fetchWrapper";
import * as logger from '../lib/logSdk/logger';

var apiFetch = fetchDefaults(fetch);

export default class WorkspaceService {
  validateInvitationuuuu = async (workspaceId, memberId) => {
    const response = await apiFetch({
      method: "GET",
      url: `${baseUrls.workspaceUrl}/workspace/${workspaceId}/invite/${memberId}`
    });

    return response.json();
  };

  validateInvitation(workspaceId, memberId, token) {
    return apiFetch(
      `${baseUrls.workspaceUrl}/workspace/${workspaceId}/invite/${memberId}?g-recaptcha-response=${token}`,
      {
        method: "get"
      }
    )
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        return data;
      })
      .catch(error => {
        console.error("WorkspaceService","validateInvitation", error);
        logger.error("WorkspaceService","validateInvitation", error.message);
        return false;
      });
  }

  updateInvitation(workspaceId, memberId, userId, token) {
    return apiFetch(
      `${baseUrls.workspaceUrl}/workspace/${workspaceId}/invite/${memberId}/${userId}?g-recaptcha-response=${token}`,
      {
        method: "put"
      }
    )
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        return data;
      })
      .catch(error => {
        console.error("WorkspaceService","updateInvitation", error);
        logger.error("WorkspaceService","updateInvitation", error.message);
        return { isSuccess: false, data: null };;
      });
  }

  getPasswordPolicy(userName,token=null) {
    return apiFetch(
      `${baseUrls.workspaceUrl}/workspace/member/${userName}/password/policy${token? `?g-recaptcha-response=${token}`:''}`,
      {
        method: "get"
      }
    )
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        return data && data["isSuccess"] ? data["result"] : null;;
      })
      .catch(error => {
        console.error("WorkspaceService","getPasswordPolicy", error);
        logger.error("WorkspaceService","getPasswordPolicy", error.message);
        return false;
      });
  }

  save_join_request = (workspaceId, userId,username,token=null) => {
    const postData = {
      username: username
    };
    return apiFetch(`${baseUrls.workspaceUrl}/workspace/${workspaceId}/domain/user/${userId}${token? `?g-recaptcha-response=${token}`:''}`, {
      method: "post",
      body: JSON.stringify(postData)
    })
      .then(function(response) {
        return response.json();
      })
      .then(function(data) {
        return data.isSuccess ===true ? data.result:null;
      })
      .catch(error => {
        console.error("WorkspaceService","save_join_request", error);
        logger.error("WorkspaceService","save_join_request", error.message);
        return null;
      });
  };

  isOfficialDomain(domain,token=null) {
    return apiFetch(
      `${baseUrls.workspaceUrl}/workspace/officialdomain/${domain}/official${token? `?g-recaptcha-response=${token}`:''}`,
      {
        method: "get"
      }
    )
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        return data && data["isSuccess"] ? data["result"] : null;
      })
      .catch(error => {
        console.error("WorkspaceService","isOfficialDomain", error);
        logger.error("WorkspaceService","isOfficialDomain", error.message);
        return error;
      });
  }
  
  IsUserExistsInTheOrganization(workspaceid,userid,token=null) {
    return apiFetch(
      `${baseUrls.workspaceUrl}/workspace/${workspaceid}/user/${userid}${token? `?g-recaptcha-response=${token}`:''}`,
      {
        method: "get"
      }
    )
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        return data && data["isSuccess"] && data["result"] !== null;
      })
      .catch(error => {
        console.error("WorkspaceService","isOfficialDomain", error);
        logger.error("WorkspaceService","isOfficialDomain", error.message);
        return error;
      });
  }

  getVirtualBackgrounds(workspaceId,token=null) {
    return apiFetch(
      `${baseUrls.workspaceUrl}/workspace/${workspaceId}/virtualbackgrounds${token? `?g-recaptcha-response=${token}`:''}`,
      {
        method: "get"
      }
    )
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        return data && data["isSuccess"] ? data["result"] : null;;
      })
      .catch(error => {
        console.error("WorkspaceService","getVirtualBackgrounds", error);
        logger.error("WorkspaceService","getVirtualBackgrounds", error.message);
        return null;
      });
  }
  
}

