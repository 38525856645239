import React, {useEffect, useState} from 'react';
import {
    CheckBox
} from '../../common';
import {Check,} from 'react-feather';


const ShareScreenPreview = props => {

    function onPressChatFilterIcon(_filter) {
        console.log('event fire...');
        console.log(_filter);
    }


    return (
        <>
            <div className={`share-screen-preview d-flex flex-column ${props.selected ? 'selected' : ''}`}>
                <div className={'select-header d-flex align-items-center'}>
                    <CheckBox
                        icon={<Check/>}
                        selected={props.selected}/>
                </div>
                <div className={`screen-img ${props.screenType}`}>
                    {props.imgTag}
                </div>
                <div className={'screen-title'}>
                    {props.screenTitle}
                </div>
            </div>
        </>
    );
};


export default ShareScreenPreview;
