import { presenceUrl, __APP_DEFAULT_WORKSPACE__ } from "../config/baseUrls";
import { fetchDefaults } from "./fetchWrapper";
import * as auth from "../utils";
import liveChatClient from "../lib";
var apiFetch = fetchDefaults(fetch);

export class PresenceService {
  static getAuxBreaks = () => {
    const myHeaders = new Headers({
      "Authorization": auth.getBearerToken(),
      "Content-Type": "application/json"
    });

    return apiFetch(`${presenceUrl}/statusCodes`, {
      method: "get",
      headers: myHeaders
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        return data.isSuccess ? data.result : null;
      })
      .catch(error => {
        return null;
      });
  }


  static getAllPresenceByPage = (teamName, userId) => {

    //return liveChatClient.getApi().presence.getAllPresenceForWorkspace(teamName);

    const myHeaders = new Headers({
      "Authorization": auth.getBearerToken(),
      "Content-Type": "application/json"
    });

    /* let workspace = auth.getWorkspace(); */
    let workspace = teamName ? teamName : __APP_DEFAULT_WORKSPACE__;


    return new Promise((resolve, reject) => {
      let pageNo = 1;
      let presenceList = [];

      function getPresenceWithLimit(pageNo, limit) {
        let url = `${presenceUrl}/presence/${workspace}?limit=${limit}&userId=${userId}`;

        if (pageNo) {
          url = `${url}&state=${pageNo}`;
        }

        fetch(url, {
          method: "GET",
          headers: myHeaders
        })
          .then(res => {
            return res.json();
          })
          .then(data => {
            if (data && data.isSuccess) {
              if (data.result && data.result.presenceList && data.result.presenceList.length > 0) {

                if (data.result.presenceList) {
                  if (data.result.presenceList.length > 0) {

                    presenceList = presenceList.concat(data.result.presenceList);
                    if (data.result.pagingAllowed === false) {
                      resolve(presenceList);
                    } else {
                      if (data.result.presenceList.length < limit) {
                        resolve(presenceList);
                      } else {
                        getPresenceWithLimit(++pageNo, 100);
                      }
                    }

                  } else {
                    resolve(presenceList);
                  }

                } else {

                  presenceList = presenceList.concat(data.result);
                  if (data.result.presenceList.length < limit) {
                    resolve(presenceList);
                  } else {
                    getPresenceWithLimit(++pageNo, 100);
                  }

                }



              } else {
                resolve(presenceList);
              }
            } else {
              //break operation and return empty list
              resolve(presenceList);
            }
          })
          .catch(ex => {
            reject(ex);
          });
      }

      getPresenceWithLimit(pageNo, 100);
    });
  };

  static getAllPresenceForTeam = teamName => {

    /* let workspace = auth.getWorkspace(); */
    let workspace = teamName ? teamName : __APP_DEFAULT_WORKSPACE__;

    const myHeaders = new Headers({
      "Authorization": auth.getBearerToken(),
      "Content-Type": "application/json"
    });

    return new Promise((resolve, reject) => {
      let pageState = null;
      let presenceList = [];

      function getPresenceWithLimit(pageState, limit) {
        let url = `${presenceUrl}/presence/${workspace}?limit=${limit}`;

        if (pageState) {
          url = `${url}&state=${pageState}`;
        }

        fetch(url, {
          method: "GET",
          headers: myHeaders
        })
          .then(res => {
            return res.json();
          })
          .then(data => {
            if (data && data.isSuccess) {
              if (
                data.result &&
                data.result.items &&
                data.result.items.length > 0
              ) {
                presenceList = presenceList.concat(data.result.items);
                if (data.result.pageState != null) {
                  //continue to next iteration

                  pageState = data.result.pageState;
                  getPresenceWithLimit(pageState, 100);
                } else {
                  //break recursion and return current list
                  resolve(presenceList);
                }
              } else {
                resolve(presenceList);
              }
            } else {
              //break operation and return empty list
              resolve(presenceList);
            }
          })
          .catch(ex => {
            reject(ex);
          });
      }

      getPresenceWithLimit(pageState, 100);
    });
  };
}
