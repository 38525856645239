import * as types from "./actionTypes";
import * as TemplateService from "../../service/templateService";

export function getTemplates() {
    return dispatch => {
        TemplateService.getTemplates().then(result => {
            dispatch(templatesLoaded(result));
        }).catch(ex => {
            console.error(ex);
        });
    }
}

export function getTemplateMessage(template) {
    return async (dispatch, getState) => {

        const state = getState("state");
        let selected_profile = state.selected_profile;
        let message = template.TemplateMessage;

        template.templateValues.forEach(val => {
            switch (val.ReferenceValueType) {
                case "USER_PROFILE": {

                    if (selected_profile && selected_profile.username) {
                        let user = state.team[selected_profile.username];

                        let valueToReplace = user[val.ReferenceId];

                        message = message.replace(`{{${val.Uuid}}}`, valueToReplace);

                    }

                    break;

                }
                default:
                    break;
            }
        });

        return message;
    };
}

export function templatesLoaded(templates) {
    return { type: types.TEMPLATES_LOADED, templates };
}