import React, { useState } from "react";
import './styles/timeline.scss';
import { Scrollbars } from "react-custom-scrollbars";
import { BiAlarm, BiFileBlank } from "react-icons/bi";
const RightTemplate = (props) => {

    const [showPanel, setShowPanel] = useState(false);

    let minutes = Math.round(props.interaction.duration / 60);

    return (
        <>
            <div className="timeline-container-wrp right"
                onClick={() => {
                    setShowPanel(!showPanel);

                }}>
                <div className={`icon-wrapper ${props.type}`}>
                    {props.icon}
                </div>
                <div className="time-temp-wrapper">
                    <div className={'title'}>
                        {props.templateName}
                    </div>
                    <div className="content d-flex">
                        <div className={'time-line-img-wrp-left'}>
                            <img src={props.imgSrc} />
                        </div>
                        <div className={'time-line-text-wrap-right'} style={{ overflow: "hidden" }} title={`${props.header}`}>
                            <div className={'time-l-temp-header'} style={{ textOverflow: "ellipsis" }}>
                                {props.header}
                            </div>
                            <div className={'time-l-temp-time'}>
                                {props.time}
                            </div>

                            {props.type === 'groupchat' &&
                                <div className={'group-chat-view-wrapper'}>
                                    <div className={'group-user-wrapper d-flex'}>
                                        <div className={'t-g-u-img'}>
                                            <img src={props.imgSrc} />
                                        </div>
                                        <div className={'t-g-u-img'}>
                                            <img src={props.imgSrc} />
                                        </div>
                                        <div className={'t-g-u-img'}>
                                            <img src={props.imgSrc} />
                                        </div>
                                    </div>

                                </div>
                            }

                            {props.interaction && showPanel &&
                                <div className={'conversation-history-wrapper'}>
                                    <div className="c-h-timeline-wrapper">
                                        <div className={`c-h-icon-wrapper`}>
                                            <BiAlarm />
                                        </div>
                                        <div className={'c-h-time-line-text-wrap-right'}>
                                            <div className={'c-h-detail'}>
                                                {`Duration : ${minutes > 0 ? minutes + ' minutes' : props.interaction.duration + ' seconds'}`}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="c-h-timeline-wrapper">
                                        <div className={`c-h-icon-wrapper`}>
                                            <BiFileBlank />
                                        </div>
                                        <div className={'c-h-time-line-text-wrap-right'}>
                                            <div className={'c-h-detail'}>
                                                {`Disposition : ${props.interaction.disposition}`}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>


                    </div>
                </div>
            </div>
        </>
    )
};
export default RightTemplate;
