import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { connect } from "react-redux";
import UserWithFullName from "./UserWithFullName";
import presenceStateMap from "../../../config/json/presenseMap";
import { getUserId } from "../../../utils";

moment.relativeTimeThreshold('s', 60);
moment.relativeTimeThreshold('m', 60);
moment.relativeTimeThreshold('h', 24);
moment.relativeTimeThreshold('d', 30);
moment.relativeTimeThreshold('M', 365);


const SelectedUserWrapper = (props) => {

    const getGroupMemberListAsString = (groupMemeberList, team) => {

        let loggedUser = getUserId();

        return groupMemeberList.reduce((acc, member) => {
            if (acc && acc !== 'Me, ') {
                acc = acc + ", ";
            }
            let name = team[member.Id] && team[member.Id].firstName ? `${team[member.Id].firstName} ${team[member.Id].lastName ? team[member.Id].lastName : ""}` : member.Id;

            if (loggedUser === member.Id) {
                //Remove the last , which was appended 
                acc = acc.slice(0, -2);
                acc = 'Me, ' + acc;
            } else {
                acc = acc + name;
            }
            //acc = acc.substring(0, 130);
            return acc;
        }, "");

    }

    let fullName = "";
    let timeAgo = "";
    let status = "offline";
    let avatar = null;

    if (props.selected_profile && props.selected_profile.username) {
        fullName = props.selected_profile.displayName;
        avatar = props.selected_profile.avatar;

        if (props.typing_status && props.typing_status.is_typing) {
            if (props.selected_profile.conversation_type === 0 || props.selected_profile.conversation_type === 4) {
                timeAgo = "typing...";
            } else if (props.selected_profile.conversation_type === 3) {
                timeAgo = `${props.typing_status.typing_user} is typing...`;
            }

        } else {
            if (props.presence) {

                let userPresence = props.presence;
                if (userPresence.connectivity === 2) {
                    let latestEventTime = userPresence.connectivityAt > userPresence.presenceAt ? userPresence.connectivityAt : userPresence.presenceAt;
                    timeAgo = moment().from(latestEventTime, true) + " ago";
                } else if (userPresence.connectivity === 1) {
                    timeAgo = "online";
                } else {
                    if (userPresence.status !== "online" && userPresence.status !== "available") {
                        if (userPresence.presenceAt) {
                            timeAgo = moment().from(userPresence.presenceAt, true) + " ago";
                        } else {
                            timeAgo = "offline";
                        }
                    } else {
                        timeAgo = "online";
                    }
                }
            }
        }

        let tempStatus = props.presence ? props.presence.status : "offline";
        status = presenceStateMap[tempStatus];

        if (props.selected_profile.conversation_type === 3) {
            status = null;
            if (props.team && props.group && props.group.Participants && props.group.Participants.length > 0) {
                timeAgo = getGroupMemberListAsString(props.group.Participants, props.team);
            }
        }


    }

    return (
        <>
            {fullName && <UserWithFullName
                src={avatar}
                conversation_type={props.selected_profile.conversation_type}
                fullName={fullName}
                lastSeen={timeAgo}
                onClick={props.onClick}
                isTyping={false}
                type={props.type}
                active={props.active}
                status={status}
                isMeeting={props.isMeeting} />}

        </>
    )

};

SelectedUserWrapper.propTypes = {
    selected_profile: PropTypes.object.isRequired
};

function mapStateToProps(state, ownProps) {

    let mapState = {};

    if (ownProps.selected_profile && ownProps.selected_profile.username) {
        mapState.typing_status = state.typing_status[state.selected_profile.username];

        if (ownProps.selected_profile.conversation_type === 3) {
            mapState.group = state.chat_group.GroupListInfo[ownProps.selected_profile.username];
            mapState.team = state.team;
        }
    }
    return mapState;
}

export default connect(
    mapStateToProps
)(SelectedUserWrapper);
