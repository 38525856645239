import React from 'react';
import PropTypes from 'prop-types';
import {UserPlus, Pause, Mic, MicOff, PhoneOutgoing, Airplay, X} from "react-feather";
import {
    MenuIcon,
    PanelTitle,
    ShareScreenPreview,
    Button
} from "../index";
import {Scrollbars} from "react-custom-scrollbars";


//online
//offline
//busy
//other
const ScreenShareView = ({size,title,onClickClose,onClickShareScreen}) => (
    <div className={`main-modal-wrapper ${size}`}>
        <div className={'modal-header d-flex'}>
            <PanelTitle title={title}/>
            <MenuIcon icon={<X/>}
                      onClick={onClickClose}
                      size={'sm'}/>
        </div>
        <div className={'screen-share-body-wrp'}>
            <Scrollbars
                autoHide
                thumbMinSize={0}
                style={{height:430}}>
                <div className={'screen-share-wrapper d-flex flex-wrap'}>
                    <ShareScreenPreview
                        selected={false}
                        imgTag={<img src={require("../../../assets/img/screen/screenShare1.png")}
                                     alt="User Avatar"/>}
                        screenType={'screen-view'}
                        // screenOnClick={}
                        screenTitle={'Desktop 1'}/>


                    <ShareScreenPreview
                        selected={false}
                        imgTag={<img src={require("../../../assets/img/screen/screenShare2.png")}
                                     alt="User Avatar"/>}
                        screenType={'screen-view'}
                        // screenOnClick={}
                        screenTitle={'Desktop 1'}/>

                    <ShareScreenPreview
                        selected={false}
                        imgTag={<img src={require("../../../assets/img/screen/whiteBoard.svg")}
                                     alt="User Avatar"/>}
                        screenType={'white-board'}
                        // screenOnClick={}
                        screenTitle={' Whiteboard'}/>

                    <ShareScreenPreview
                        selected={false}
                        imgTag={<img src={require("../../../assets/img/screen/iphoneViaCable.svg")}
                                     alt="User Avatar"/>}
                        screenType={'iphone'}
                        // screenOnClick={}
                        screenTitle={' iphone/ipad via Cable'}/>

                    <ShareScreenPreview
                        selected={false}
                        imgTag={<img src={require("../../../assets/img/screen/screenShare3.png")}
                                     alt="User Avatar"/>}
                        screenType={'screen-view'}
                        // screenOnClick={}
                        screenTitle={'Google Chorme - zoom...'}/>
                    <ShareScreenPreview
                        selected={false}
                        imgTag={<img src={require("../../../assets/img/screen/screenShare2.png")}
                                     alt="User Avatar"/>}
                        screenType={'screen-view'}
                        // screenOnClick={}
                        screenTitle={'Google Chrome - sign in to...'}/>

                    <ShareScreenPreview
                        selected={true}
                        imgTag={<img src={require("../../../assets/img/screen/screenShare4.png")}
                                     alt="User Avatar"/>}
                        screenType={'screen-view'}
                        // screenOnClick={}
                        screenTitle={'webstrom - project...'}/>

                    <ShareScreenPreview
                        selected={false}
                        imgTag={<img src={require("../../../assets/img/screen/screenShare2.png")}
                                     alt="User Avatar"/>}
                        screenType={'screen-view'}
                        // screenOnClick={}
                        screenTitle={'Google Chrome - facebook..'}/>
                    <ShareScreenPreview
                        selected={false}
                        imgTag={<img src={require("../../../assets/img/screen/screenShare1.png")}
                                     alt="User Avatar"/>}
                        screenType={'screen-view'}
                        // screenOnClick={}
                        screenTitle={'Sketch'}/>


                </div>
            </Scrollbars>
            <div className={'screen-share-footer d-flex align-items-center justify-content-end'}>
                <>
                    <Button
                        type={'btn-primary'}
                        size={'btn-lg'}
                        onClick={onClickShareScreen}
                        title={'Share'}/>
                </>
            </div>

        </div>
    </div>
);

// OutGoingCall.propTypes = {
//     status: PropTypes.string.isRequired,
// };

ScreenShareView.defaultProps = {};

export default ScreenShareView;
