import React, { useEffect, useState } from 'react';
import {
    Modal, ModalSelectUser
} from '../../common';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as chatActions from "../../../redux/actions/chatActions";
import * as teamActions from "../../../redux/actions/teamActions";
import { getFilteredChats } from "../../common/MyContacts/MyContacts";
import * as auth from "../../../utils";
import presenceMap from '../../../config/json/presenseMap';
import { __APP_INTERACTION_MODE__ } from '../../../config/baseUrls';
import livechatclient from '../../../lib';
import { InteractionUser } from "../../../models/InteractionUser";

let showFiltered = false;
let allContacts = {};
let sortedContactsList = {};

function UserSelectModalComponent(props) {
    const [filteredContactList, setFilteredContactList] = useState(allContacts);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [searchTextVal, setSearchTextVal] = useState("");
    const [agentInteractionCounts, setAgentInteractionCounts] = useState({});
    sortedContactsList = filteredContactList;

    function onPressChatFilterIcon(_filter) {
        console.log('event fire...');
        console.log(_filter);
    }

    useEffect(() => {

        if (showFiltered) {
            sortedContactsList = filteredContactList;

        } else {
            sortedContactsList = allContacts;
        }
    }, []);

    useEffect(() => {
        if (props.isViewModal && __APP_INTERACTION_MODE__ === "INTERACTION") {

            props.team_actions.getTemporaryAgents();
            let agentIds = Object.keys(props.team).reduce((acc, key) => {

                if (props.team[key].isTempAgent) {
                    acc.push(key);
                }
                return acc;

            }, []);

            livechatclient.subscribeEvent("user_select_modal", "onUserInteractionListing", interactionData => {
                if (interactionData) {
                    let agentInteractionCounts = interactionData.reduce((acc, interactionInfo) => {
                        let tempInteractionUserData = new InteractionUser(interactionInfo);
                        acc[tempInteractionUserData.UserId] = {
                            chats: 0,
                            calls: 0,
                            escalated: 0
                        }

                        if (tempInteractionUserData.InteractionListInformation) {

                            for (let i = 0; i < tempInteractionUserData.InteractionListInformation.length; i++) {

                                /* if (interactionInfo.InteractionListInformation[i].Channel === 'call') {
                                    acc[interactionInfo.UserId].calls++;
                                } else if (interactionInfo.InteractionListInformation[i].Channel === 'chat') {
                                    acc[interactionInfo.UserId].chats++;
                                } */

                                if (tempInteractionUserData.InteractionListInformation[i].IsHandlingChat) {
                                    acc[tempInteractionUserData.UserId].chats++;
                                }

                                if (tempInteractionUserData.InteractionListInformation[i].IsHandlingCall) {
                                    acc[tempInteractionUserData.UserId].escalated++;
                                }
                            }

                        }

                        return acc;

                    }, {});

                    setAgentInteractionCounts(agentInteractionCounts);
                }

            });

            livechatclient.getUserInteractionListForUser(agentIds);



        }
    }, [props.isViewModal]);

    if (showFiltered) {
        sortedContactsList = filteredContactList;
    } else {

        if (props.team) {
            if (__APP_INTERACTION_MODE__ === "INTERACTION") {
                let agents = Object.keys(props.team).reduce((acc, key) => {

                    if (props.team[key].isTempAgent) {
                        if (agentInteractionCounts[key] && agentInteractionCounts[key].escalated > 0) {
                            props.team[key].IsCallEscalated = true;

                        } else {
                            props.team[key].IsCallEscalated = false;
                        }

                        props.team[key].chats = 0;
                        props.team[key].calls = 0;
                        props.team[key].escalated = 0;

                        if (agentInteractionCounts[key]) {
                            props.team[key].chats = agentInteractionCounts[key].chats;
                            props.team[key].calls = agentInteractionCounts[key].calls;
                            props.team[key].escalated = agentInteractionCounts[key].escalated;
                        }
                        acc[key] = props.team[key]
                    }
                    return acc;

                }, {});
                allContacts = { ...agents };
            } else {
                allContacts = { ...props.team };
            }

        }

        if (props.loadGroups && props.chat_group.GroupListInfo) {
            allContacts = { ...allContacts, ...props.chat_group.GroupListInfo };
        }

        sortedContactsList = allContacts;

    }

    if (props.chats) {

        let keyArray = Object.keys(sortedContactsList).sort(function (a, b) {

            let timestamp_a = props.chats[a] && props.chats[a].created_at ? props.chats[a].created_at : 0;
            let timestamp_b = props.chats[b] && props.chats[b].created_at ? props.chats[b].created_at : 0;

            if (timestamp_a < timestamp_b) {
                return 1;
            }
            if (timestamp_a > timestamp_b) {
                return -1;
            }

            return 0;
        });

        sortedContactsList = keyArray.reduce((acc, key) => {
            acc[key] = sortedContactsList[key];
            return acc;
        }, {});

    }


    //TODO: sort all contacts based on chat data.

    let contactListView = Object.keys(sortedContactsList).reduce((acc, key) => {

        let userId = auth.getUserId();

        let isOnline = (props.presenceUsers[key] && props.presenceUsers[key].status && presenceMap[props.presenceUsers[key].status.toLowerCase()] === "online") || (sortedContactsList[key].isTempAgent && !sortedContactsList[key].IsCallEscalated) ? true : false;

        let skipPresence = props.skipOfflineUsers && !isOnline ? false : true;

        let skipUserCheck = props.skipUsers && props.skipUsers.length > 0 && props.skipUsers.indexOf(key) > -1 ? false : true;

        if (key !== userId && skipUserCheck && skipPresence) {
            let fullName = "";
            let userType = 'Undefined';
            let status = 'offline';
            let avatarUrl = null;
            let isTempAgent = false;
            let chats = 0;
            let calls = 0;
            let escalated = 0;
            let isVideo = false;
            if (sortedContactsList[key].conversation_type === 0) {
                if (sortedContactsList[key].isTempAgent) {
                    chats = sortedContactsList[key].chats;
                    calls = sortedContactsList[key].calls;
                    escalated = sortedContactsList[key].escalated;
                    avatarUrl = null;
                    isVideo = sortedContactsList[key].isVideo;
                    isTempAgent = true;
                    fullName = sortedContactsList[key].firstName ? sortedContactsList[key].firstName : sortedContactsList[key].userId;
                    userType = "Agent";
                    if (sortedContactsList[key].IsCallEscalated) {
                        status = 'busy';
                    } else {
                        status = 'available';
                    }

                } else {

                    //USER
                    avatarUrl = sortedContactsList[key].avatarURL;
                    fullName = `${sortedContactsList[key].firstName} ${sortedContactsList[key].lastName}`;
                    userType = props.team[key].userType;
                    if (props.presenceUsers[key])
                        status = props.presenceUsers[key].status;

                }

            } else if (sortedContactsList[key].conversation_type === 3) {
                //GROUP
                fullName = sortedContactsList[key].Name;
                userType = 'Group';
            }
            let isSelected = selectedUsers.filter(item => item === key).length > 0;
            acc.push({ fullName, userType, avatarUrl, status, isSelected, key, isTempAgent, chats, calls, escalated, isVideo });
        }
        return acc;
    }, []);

    function getFrequentlyContacted() {
        return contactListView.slice(0, 5).map(contact => {
            let disabled = false;

            if (__APP_INTERACTION_MODE__ === "INTERACTION") {

                if (props.operation === 'CONFERENCE') {
                    disabled = contact.isVideo && contact.status !== "busy" ? false : true;
                } else if (props.operation === 'TRANSFER') {
                    if (props.interaction) {

                        if (!props.interaction.IsCallEscalated) {
                            //Chat Only
                            disabled = false;
                        } else {
                            if (contact.status === "busy") {
                                disabled = true;
                            } else {
                                if (contact.isVideo) {
                                    disabled = false;
                                } else {
                                    disabled = true;
                                }
                            }

                        }

                    } else {
                        disabled = true;
                    }
                }

            }

            return (
                <ModalSelectUser
                    key={"FORWARD_MODAL:" + contact.key}
                    overrideStatusDisable={contact.isTempAgent ? true : false}
                    src={contact.avatarUrl}
                    selected={contact.isSelected}
                    userType={contact.userType}
                    fullName={contact.fullName}
                    disabled={disabled}
                    chats={contact.chats}
                    calls={contact.calls}
                    escalated={contact.escalated}
                    status={contact.status}
                    onClick={() => {
                        if (selectedUsers.filter(item => item === contact.key).length === 0) {
                            if (props.multiSelectAllowed) {
                                setSelectedUsers([...selectedUsers, contact.key]);
                            } else {
                                setSelectedUsers([contact.key]);
                            }


                        } else {
                            setSelectedUsers(selectedUsers.filter(item => item !== contact.key));
                        }
                    }}
                />
            )
        })
    }

    function getRecentChats() {
        return contactListView.slice(5).map(contact => {
            let disabled = false;

            if (__APP_INTERACTION_MODE__ === "INTERACTION") {

                if (props.operation === 'CONFERENCE') {
                    disabled = contact.isVideo && contact.status !== "busy" ? false : true;
                } else if (props.operation === 'TRANSFER') {

                    if (props.interaction) {

                        if (!props.interaction.IsCallEscalated) {
                            //Chat Only
                            disabled = false;
                        } else {
                            if (contact.status === "busy") {
                                disabled = true;
                            } else {
                                if (contact.isVideo) {
                                    disabled = false;
                                } else {
                                    disabled = true;
                                }
                            }

                        }

                    } else {
                        disabled = true;
                    }

                    /* if (contact.isVideo || (props.interaction && !props.interaction.IsCallEscalated)) {
                        disabled = false;
                    } else {
                        disabled = true;
                    } */
                }

            }

            return (
                <ModalSelectUser
                    key={"FORWARD_MODAL:" + contact.key}
                    overrideStatusDisable={contact.isTempAgent ? true : false}
                    src={contact.avatarUrl}
                    selected={contact.isSelected}
                    userType={contact.userType}
                    fullName={contact.fullName}
                    disabled={disabled}
                    chats={contact.chats}
                    calls={contact.calls}
                    escalated={contact.escalated}
                    status={contact.status}
                    onClick={() => {
                        if (selectedUsers.filter(item => item === contact.key).length === 0) {
                            setSelectedUsers([...selectedUsers, contact.key]);
                        } else {
                            setSelectedUsers(selectedUsers.filter(item => item !== contact.key));
                        }
                    }}
                />
            )
        })
    }

    function onSearch(e) {
        setSearchTextVal(e.target.value);
        let searchText = e.target.value;
        if (searchText) {
            //filter
            try {

                var regex = RegExp(searchText, "i");
                let filtered = Object.keys(allContacts).reduce((acc, key) => {
                    let name = "";

                    if (allContacts[key].conversation_type === 0) {
                        //USER
                        name = `${allContacts[key].firstName} ${allContacts[key].lastName}`;
                    } else if (allContacts[key].conversation_type === 3) {
                        //GROUP
                        name = allContacts[key].Name;
                    }
                    if (regex.test(name)) {
                        acc = { ...acc, ...{ [key]: allContacts[key] } };
                    }
                    return acc;
                }, {});

                showFiltered = true;
                setFilteredContactList(filtered);

            } catch (ex) {
                console.error(ex);
            }

        } else {
            showFiltered = false;
            setFilteredContactList(allContacts);
        }
    }

    function getSelectedUserNames() {
        let names = [];
        for (let item of selectedUsers) {
            if (props.team[item]) {
                names.push(props.team[item].firstName ? props.team[item].firstName : item);
            } else if (props.chat_group.GroupListInfo[item]) {
                names.push(props.chat_group.GroupListInfo[item].Name);
            }
        }

        console.log("SELECTED_USERS", names);
        return names.join(', ');
    }

    function getSelectedUsers() {
        let users = [];
        for (let item of selectedUsers) {
            if (props.team[item]) {
                users.push(props.team[item]);
            } else if (props.chat_group[item]) {
                users.push(props.chat_group[item]);
            }
        }
        return users;
    }

    function forward() {
        let allSelectedUsers = [];
        if (selectedUsers.length > 0) {
            for (let index of selectedUsers) {
                allSelectedUsers.push(allContacts[index]);
            }
        }
        props.onCallback(allSelectedUsers);
        showFiltered = false;
        setSearchTextVal("");
        setSelectedUsers([]);
        setFilteredContactList({});
        props.onClickClose();
    }

    return (
        <>
            <div
                className={`modal-inner-wrapper d-flex justify-content-center align-items-center  ${props.isViewModal ? 'show-this' : 'hide-this'}`}>
                <Modal
                    onClickClose={() => {
                        showFiltered = false;
                        allContacts = {};
                        sortedContactsList = {};
                        setSearchTextVal("");
                        setSelectedUsers([]);
                        setFilteredContactList({});
                        props.onClickClose();
                    }}
                    searchTextVal={searchTextVal}
                    title={props.title}
                    size={'default'}
                    fusers={getFrequentlyContacted()}
                    rusers={getRecentChats()}
                    onType={onSearch}
                    selectedUsers={getSelectedUserNames()}
                    forward={forward}
                    count={contactListView.length}
                />
            </div>
        </>
    );
}

function mapStateToProps(state, ownProps) {
    let mapState = {
        team: state.team,
        chat_group: state.chat_group,
        selected_profile: state.selected_profile,
        presenceUsers: state.presence.data
    };

    if (state.selected_profile && state.selected_profile.username) {
        mapState.interaction = state.interactions[state.selected_profile.username];
    }

    if (state.chats) {
        mapState.chats = getFilteredChats(state.chats);
    }
    return mapState;
}

function mapDispatchToProps(dispatch) {
    return {
        chat_actions: bindActionCreators(chatActions, dispatch),
        team_actions: bindActionCreators(teamActions, dispatch)
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UserSelectModalComponent);
