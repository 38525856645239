import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import LeftSideNavigationComponent from "../leftNavigation/LeftSideNavigation";
import ActionPanelComponent from "../actionPanel/ActionPanel";
import HeaderPanelComponent from "../HeaderPanel/HeaderPanel";
import ChatPanelComponent from "../chatPanel/chatPanel";
import UserSelectModalComponent from "../UserSelectModal/UserSelectModal";
import { Send, Paperclip, Phone, Mic, Smile, Video } from "react-feather";
import { bindActionCreators } from "redux";
import { Scrollbars } from "react-custom-scrollbars";
import SoftPhone from "../phone/SoftPhone";
import { message } from "antd/lib/index";
import { Modal as AntDModal } from 'antd';
import ChatInfoPanelComponent from "../ChatInfoPanel/ChatInfoPanel";
import ChatSearchPanelComponent from "../ChatSearchPanel/ChatSearchPanel";
import StarredPanel from "../StarredPanel/StarredPanel";
import UserProfilePanelComponent from "../UserProfilePanel/UserProfilePanel";
import MediaPanel from "../MediaPanel/MediaPanel";
import ChatTextWrapper from "../../common/ChatTextWrapper/ChatTextWrapper";
import MediaUpload from "../MediaUpload/MediaUpload";
import MeetingsWrapper from "../Meetings/MeetingsWrapper";
import WhiteBoard from "../Whiteboard/Whiteboard";
import CoBrowse from "../Whiteboard/CoBrowse";
import WhiteboardEvents from "../Whiteboard/WhiteboardEvents";
import ProfileSearchModal from "../../common/ProfileDetails/ProfileSearchModal";
import MobileMediaUpload from "../MediaUpload/MobileMediaUpload";
import VideoRecorderModal from "../VideoRecorderModal/VideoRecorderModal";
import MediaGalleryView from "../MediaGalleryView/MediaGalleryView";
import {
  __APP_IDENTITY_PROVIDER__,
  __APP_CHAT_END_ACW_ALLOWED__,
  __APP_CHAT_END_ACW_TIME__,
  __APP_DEFAULT_WORKSPACE__,
  __APP_INTERACTION_MODE__,
  __APP_HEARTBEAT_TIMEOUT__,
  __APP_PROFANITY_LOAD_MECHANISM__,
  fileMetaServiceUrl,
  meetingTextChatEnabled
} from "../../../config/baseUrls";
import sdkHandler from "../../../lib";
import * as auth from "../../../utils";
import { fireEvent, subscribeToEvents } from "../../../utils/eventEmitter";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import produce from "immer";
import {
  ChatEmptyMessage,
  DeviceNotSupported,
  CoBrowsingModal,
  Modal,
} from "../../common";
import { ChevronDown } from "react-feather";
import DownArrowChat from "../../common/DownArrowChat/DownArrowChat";
import * as groupActions from "../../../redux/actions/groupActions";
import * as meetingActions from "../../../redux/actions/meetingActions";
import * as chatActions from "../../../redux/actions/chatActions";
import * as uiPanelActions from "../../../redux/actions/uiPanelActions";
import * as teamActions from "../../../redux/actions/teamActions";
import * as templateActions from "../../../redux/actions/templateActions";
import * as cannedMessageActions from "../../../redux/actions/cannedMessageActions";
import * as notificationActions from "../../../redux/actions/webNotificationActions";
import * as interactionActions from "../../../redux/actions/interactionActions";
import * as currentMeetingActions from "../../../redux/actions/currentMeetingActions";
import * as presenceActions from "../../../redux/actions/presenceActions";
import * as conectivityActions from "../../../redux/actions/connectivityActions";
import * as selectedProfileActions from "../../../redux/actions/selectedProfileActions";
import retrieveImageFromClipboardAsBlob from "../../../utils/fileClipboardHandler";
import { imageTypes } from "../../../config/json/fileTypeMap";
import globalFlags from "../../../utils/globalFlags";
import navigationRestrictions from "../../../config/navigationRestrictions";
import uuid from "uuid/v1";
import "./styles/console-responsive.scss";
import WebNotification from "../../common/Notification/Notification";
import MobileLeftSideNavigation from "../leftNavigation/MobileLeftSideNavigation";
import MobileHeaderPanel from "../HeaderPanel/MobileHeaderPanel";
import MobileChatTextWrapper from "../../common/ChatTextWrapper/MobileChatTextWrapper";
import AuthService from "../../../service/authService";
import * as ChatService from "../../../service/chatService";
import "./styles/console.scss";
import "./styles/console-responsive.scss";
import { addTimer } from "../../../utils/acwUsersHandler";
import phoneEngine from "../phone/phoneEngine/phoneEngineHandler";
import chatBeep from "../../../assets/mp3/chatBeep.mp3";
import UIPopupModal from "../UIPopupModal/UIPopupModal";
import SignaturePadModal from "../../common/SignaturePad/SignaturePadModal";
import MeetingAlerts from "../../common/Notification/MeetingAlerts";
import TemplateModal from "../TemplateModal/TemplateModal";
import UIDisableModal from "../UIPopupModal/UIDisableModal";
import * as phoneConfig from "../../../config/phoneConfig";
import * as externalApiActions from "../../../redux/actions/externalApiActions";
import { configurationManagerService } from "../../../service/configurationManagerService";
import default_custInfoData from "../../../config/customerInfo";
import { InteractionUser } from "../../../models/InteractionUser";
import { Interaction } from "../../../models/Interaction";
import * as callControlsActions from '../../../redux/actions/callControlsActions';
import { WEB_NOTIFICATION, PHONE_NOTIFICATION } from '../../../redux/actions/actionTypes';
import * as logger from "../../../lib/logSdk/logger";
import { loadWords } from "../../../utils/profanityFilter";
import packageJson from '../../../../package.json';
import WorkspaceRequest from '../workspaceRequest/WorkspaceRequest';
import { MeetingSDK } from '../../../lib/MeetingsSdk';
import MeetingDashboard from "../Meetings/MeetingDashboard";

const meetingSDK = new MeetingSDK();

let scrollBarTimeline = null;
let selected_profile_console = null;
let chtRingtone = new Audio(chatBeep);
const authService = new AuthService();

let isAllowedVideoCall = phoneConfig ? phoneConfig.isAllowedVideoCall : false;

let _connectivity_status_ = true;
let _last_connectivity_event_ = true;

let timer_id = null;

const startDisconnectTimer = () => {
  timer_id = setTimeout(() => {
    fireEvent('heartbeat_timeout', null);

  }, __APP_HEARTBEAT_TIMEOUT__);
}

const stopDisconnectTimer = () => {
  clearTimeout(timer_id);
}

const findCustomerIdForInteraction = (interactionId) => {
  return async (dispatch, getState) => {
    let customerId = "";
    const state = getState(interactionId);

    let interaction = state.interactions[interactionId];
    if (interaction) {
      customerId = interaction.CustomerUserId;
    }
    return { customerId, interaction };
  };
};

const findAgent = (agentId) => {
  return async (dispatch, getState) => {
    const state = getState(agentId);

    let agent = state.team[agentId];
    return agent;
  };
};

class ConsoleComponent extends Component {
  constructor(props) {
    super(props);
    console.log(
      "%cMAIN CONSOLE LOADED. version : %s",
      "color:#233E82; font-family:'Ubuntu'; display: block;font-weight:bold; font-size:15px;", packageJson.version
    );

    logger.debug('MainConsole', 'constructor', 'Main Console Constructor Got Called');
    this.state = {
      viewPointWidth: 0,
      viewPointHeight: 0,
      txtMessage: null,
      isView: false,
      activePlayer: null,
      isViewModal: false,
      skipUsers: [],
      loadGroups: true,
      chatAreaHeight: 160,
      isOpenParticipation: false,
      prtPanelAnimation: "on-load",
      isVideoPinStatus: true,
      isVideoFullScreen: false,
      activeMenu: "chat",
      activeMenuParams: [],
      callTypeStatus: "",
      isOpenInfoPanel: false,
      isOpenMedialUploadView: false,
      firstFile: null,
      isEnableReplyMessage: false,
      isEnableVoiceRecorder: false,
      isForwardMessage: false,
      modalTitle: "Invite",
      multiSelectAllowed: true,
      operation: null,
      isEnableVideoRecorder: false,
      isStartVideoRecord: false,
      isOpenGallery: false,
      openGalleryMediaFiles: null,
      replyMessage: null,
      skipOfflineUsers: false,
      userSelectModalCallback: {},
      isOpenMobileProfile: false,
      isOpenCoBrowsing: false,
      custInfoData: []
    };
    this.currentPathname = null;
    this.childRefMakeCall = React.createRef();
    /* sdkHandler.subscribeEvent("groups", "onUserGroupListing", (groupList) => {
      props.group_actions.groupListLoaded(groupList);
    }); */

    const custom_data = auth.getCustomData();
    if (custom_data && custom_data.isVideo !== null) {
      isAllowedVideoCall = custom_data.isVideo;
      const msg = isAllowedVideoCall === true ? "overwrite video call permission with custom data, phone functionality enable" : "phone functionality disable with customer data. your not able to use phone";
      console.debug("mainconsole", "softphone", msg);
      console.log(
        "%c Main Console - %s",
        "color:#FF00FF; font-family:'Ubuntu'; display: block;font-weight:bold; font-size:18px;", msg
      );
      logger.info("MainConsole", "softphone", msg);
      if (isAllowedVideoCall === false) {
        message.warning(msg);
        console.error("mainconsole", "softphone", msg);
      }
    }

    this.getConfigurations();
    if (__APP_PROFANITY_LOAD_MECHANISM__ !== "NONE") {
      loadWords();
    }

    sdkHandler.subscribeEvent("console", "onUserFound", (userData) => {

      if (__APP_INTERACTION_MODE__ === 'INTERACTION') {

        let path = `/console/${userData.interactionId}`;

        if (navigationRestrictions.panels.right.fixed) {
          path = `/console/${userData.interactionId}/profile`;
          this.onClickOpenInfoPanel();
        }

        let custInfoObj = userData.userDetails
          ? JSON.parse(userData.userDetails)
          : null;

        this.props.history.push(path);
        props.team_actions.addContactOnUserFoundEvent(userData);

        message.info(`New customer ${custInfoObj ? custInfoObj.customerName : "Unknown"} connected`);

        if ((document.visibilityState !== "visible" || !globalFlags.__FOCUSSED__) && navigationRestrictions.chat.alerts.active) {
          props.notification_actions.onShowNotification({
            action_type: WEB_NOTIFICATION,
            message: `New customer ${custInfoObj ? custInfoObj.customerName : "Unknown"} connected`,
            duration: 5
          });
        }

        if (
          navigationRestrictions.chat &&
          navigationRestrictions.chat.play_beep_with_chat_connect &&
          userData &&
          userData.channel === "chat"
        ) {
          try {
            chtRingtone.loop = false;
            chtRingtone.play();
          } catch (error) {
            console.error("MainConsole", "subscribeEvent-onUserFound", error);
            logger.error("MainConsole", "subscribeEvent-onUserFound", error.message);
          }
        }

      }


    });

    sdkHandler.subscribeEvent("console", "onInteractionTransferResponseAcknowledgement", reqStatus => {

      if (reqStatus.isAccepted) {
        message.info("Chat transfered successfully");
        console.log('MainConsole', 'onInteractionTransferResponseAcknowledgement', 'Chat transfered successfully');
        logger.debug('MainConsole', 'onInteractionTransferResponseAcknowledgement', 'Chat transfered successfully');
      } else {
        message.warn("Chat transfer rejected");
        console.log('MainConsole', 'onInteractionTransferResponseAcknowledgement', 'Chat transfer rejected');
        logger.debug('MainConsole', 'onInteractionTransferResponseAcknowledgement', 'Chat transfer rejected');
      }

    });

    sdkHandler.subscribeEvent(
      "console",
      "onInteractionTransferSuccess",
      (responseObject) => {
        let loggedUserId = auth.getUserId();
        if (responseObject.UserId === loggedUserId) {
          //add new interaction
          let path = `/console/${responseObject.InteractionId}`;

          if (navigationRestrictions.panels.right.fixed) {
            path = `/console/${responseObject.InteractionId}/profile`;
            this.onClickOpenInfoPanel();
          }

          this.props.history.push(path);
          /* {"RequestorUserId":"84747","UserId":"84751","InteractionId":"cbfa8794-1d79-454b-ad2b-f00c21484a3c","InteractionInformation":{"InteractionId":"cbfa8794-1d79-454b-ad2b-f00c21484a3c","IsCallEscalated":false,"StartDateTime":"2020-07-02T12:20:18+00:00","Channel":"chat","SubChannel":"","Intent":"135191","Skill":"135191","UserIds":["592189","84747"],"CustomerInfo":"{\"id\":\"112232\",\"name\":\"catw\",\"mobileNo\":\"919988774321\",\"pageDesc\":\"Accounts\",\"authentication\":true}"}}} */

          //"reqSkill": "139151", "reqId": "391303", "type": "Customer", "interactionId": "eb688187-4532-4489-81ad-d5d3b80c849a", "intent": "account", "channel": "chat", "userDetails": "{\"mobileNo\":null,\"pageDesc\":null,\"authentication\":null,\"id\":\"78964\",\"name\":\"ttttrw\"

          let interactionObj = new Interaction(responseObject.UserId, responseObject.InteractionInformation);

          let custInfo = {
            reqSkill: interactionObj.Skill,
            type: "Customer",
            interactionId: responseObject.InteractionId,
            intent: interactionObj.Intent,
            channel: interactionObj.Channel,
            userDetails: interactionObj.CustomerInfo,
            userId: interactionObj.CustomerUserId,
            userIds: interactionObj.UserIds,
            customData: interactionObj.CustomData
          };
          /*
                {"requestId":"45476","requestedSkill":"135191","customerId":"377376","agentId":"84747","customerInfo":"{\"id\":\"8008\",\"name\":\"cust8\",\"mobileNo\":\"919988774321\",\"pageDesc\":\"Accounts\",\"authentication\":true}","InteractionId":"7aaa9350-21cb-42fd-a921-b4bfd1efb442","createdAt":1594024363723,"uniqueId":"6b6d7d0a-2c37-4fdd-a4a3-b46f20847c0b"}
                */
          let contactData = {
            requestId: null,
            requestedSkill: interactionObj.Skill,
            customerId: interactionObj.CustomerUserId,
            agentId: auth.getUserId(),
            customerInfo: interactionObj.CustomerInfo,
            InteractionId: responseObject.InteractionId,
            channel: interactionObj.Channel,
            intent: interactionObj.Intent,
            customData: interactionObj.CustomData
          };
          sdkHandler.sendCustomMessage(
            "new-contact-added",
            JSON.stringify(contactData),
            "service:CNT"
          );
          props.team_actions.addContactOnUserFoundEvent(custInfo);
          let interactionIds = [responseObject.InteractionId];

          this.props.store_actions
            .findAgent(responseObject.RequestorUserId)
            .then((transferedAgent) => {

              let uniqueId = uuid();

              let msg = {
                v: 1,
                mid: uniqueId,
                sid: uniqueId,
                message_type: "TRANSFER",
                from: {
                  id: auth.getUserId(),
                  name: auth.getDisplayName()
                },
                to: {
                  id: responseObject.InteractionId,
                  name: responseObject.InteractionId
                },
                created_at: Date.now()
              }

              msg.other = JSON.stringify({ transferedFrom: (transferedAgent ? transferedAgent.firstName : responseObject.RequestorUserId), transferedTo: (msg.from.name ? msg.from.name : msg.from.id) });

              msg.message_content = `Chat transfered to agent ${msg.from.name ? msg.from.name : msg.from.id} from agent ${transferedAgent ? transferedAgent.firstName : responseObject.RequestorUserId}`;
              msg.conversation_type = 4;

              props.chat_actions.getChatTransferHistoryData(
                responseObject.RequestorUserId,
                interactionIds,
                4,
                Date.now(),
                msg
              );

            });




          if (
            navigationRestrictions.chat &&
            navigationRestrictions.chat.play_beep_with_chat_connect &&
            custInfo &&
            custInfo.channel === "chat"
          ) {
            try {
              chtRingtone.loop = false;
              chtRingtone.play();
            } catch (error) {
              console.error("mainconsole", "chatringtone", error);
              logger.error("mainconsole", "chatringtone", error.message);
            }
          }
        }

        if (responseObject.RequestorUserId === loggedUserId) {
          //remove interaction
          //go acw

          props.interaction_actions.setACWStatus(responseObject.InteractionId);

          if (__APP_CHAT_END_ACW_ALLOWED__ === "YES") {
            if (__APP_CHAT_END_ACW_TIME__) {
              addTimer(responseObject.InteractionId, __APP_CHAT_END_ACW_TIME__);
            }
          } else {
            props.interaction_actions.removeInteraction(
              responseObject.InteractionId
            );
            //has to remove customer
            props.selected_profile_actions.profileSelected({});
          }
        }
      }
    );

    sdkHandler.subscribeEvent(
      "console",
      "onSnapshotRequest",
      (acceptOrRejectObject) => {
        if (acceptOrRejectObject) {

          this.props.store_actions.findAgent(acceptOrRejectObject.requesterUserId).then(agent => {

            let name = agent ? agent.firstName : acceptOrRejectObject.requesterUserId;

            fireEvent("show_modal", {
              title: `Snapshot Request From ${name}`,
              yesButtonText: "Accept",
              cancelButtonText: "Reject",
              onYes: () => {
                fireEvent('capture_snapshot', { toUser: acceptOrRejectObject.requesterUserId, acceptOrRejectObject });

              },
              onCancel: () => {
                acceptOrRejectObject.reject();
              },
            });

          }).catch(ex => {
            console.error('Error occurred onSnapshotRequest', ex);
          });


        }
      }
    );

    sdkHandler.subscribeEvent(
      "console",
      "onInteractionConferenceSuccess",
      (info) => {
        //Only add if its a meeting

        if (info.InteractionInformation.CustomData && info.InteractionInformation.CustomData.meetingId && meetingTextChatEnabled === 'YES') {
          let interactionObj = {
            InteractionId: info.InteractionId,
            IsCallEscalated: info.InteractionInformation.IsCallEscalated,
            createdAt: Date.now(),
            Channel: info.InteractionInformation.Channel,
            SubChannel: info.InteractionInformation.SubChannel,
            Intent: info.InteractionInformation.Intent,
            Skill: info.InteractionInformation.Skill,
            UserIds: info.InteractionInformation.UserInformation.map(usr => usr.Id),
            conversation_type: 4,
            isTemp: true,
            CustomerInfo: info.InteractionInformation.CustomerInfo,
            CustomData: info.InteractionInformation.CustomData,
            CustomerUserId: info.InteractionInformation.CustomerUserId,
            IsMeeting: true
          };

          props.interaction_actions.addMeetingUserToInteraction(info.InteractionId, info.UserId, interactionObj, () => {

            let path = `/console/${info.InteractionId}`;

            this.props.history.push(path);
            this.onPressMenuIcon("chat");

            props.chat_actions.addNewChatUser({ username: info.InteractionId });

            props.chat_actions.getOldMessages(
              "tetherfi",
              4,
              auth.getUserId(),
              info.InteractionId,
              Date.now(),
              50,
              true
            );

          });
        }

      }
    );

    sdkHandler.subscribeEvent(
      "console",
      "onUserLeftInteraction",
      (info) => {
        //Only remove interaction if its a meeting
        if (info.InteractionInformation.CustomData && info.InteractionInformation.CustomData.meetingId && info.UserId === auth.getUserId() && meetingTextChatEnabled === 'YES') {
          props.interaction_actions.removeInteraction(info.InteractionId);
          if (selected_profile_console.username === info.InteractionId) {
            props.selected_profile_actions.profileSelected({});
          }

        } else {
          props.interaction_actions.removeUserFromInteraction(info.InteractionId, info.UserId);
        }


      }
    );

    sdkHandler.subscribeEvent(
      "console",
      "onInteractionStarted",
      (interactionInformation) => {
        //Only add if its a meeting

        if (__APP_INTERACTION_MODE__ === "USER") {

          let interactionObj = {
            InteractionId: interactionInformation.InteractionId,
            IsCallEscalated: interactionInformation.IsCallEscalated,
            createdAt: Date.now(),
            Channel: interactionInformation.Channel,
            SubChannel: interactionInformation.SubChannel,
            Intent: interactionInformation.Intent,
            Skill: interactionInformation.Skill,
            UserIds: interactionInformation.UserInformation.map(usr => usr.Id),
            conversation_type: 4,
            isTemp: true,
            CustomData: interactionInformation.CustomData,
            CustomerUserId: interactionInformation.CustomerUserId
          };

          interactionObj.CustomerInfo = interactionInformation.CustomerInfo ? JSON.parse(interactionInformation.CustomerInfo) : null;



          if (interactionInformation.CustomData && interactionInformation.CustomData.meetingId) {

            if (meetingTextChatEnabled === 'YES') {
              let path = `/console/${interactionInformation.InteractionId}`;

              this.props.history.push(path);
              interactionObj.IsMeeting = true;
              props.interaction_actions.addMeetingInteraction(interactionObj);
              this.onPressMenuIcon("chat");

              props.chat_actions.addNewChatUser({ username: interactionInformation.InteractionId });

            }


          } else {
            let path = `/console/${interactionInformation.InteractionId}`;

            this.props.history.push(path);

            props.interaction_actions.addExternalUserInteraction(interactionObj);
            this.onPressMenuIcon("chat");

            props.chat_actions.addNewChatUser({ username: interactionInformation.InteractionId });

          }



        }



      }
    );

    sdkHandler.subscribeEvent(
      "console",
      "onSnapshotRequestAck",
      (ackInfo) => {
        if (ackInfo) {

          this.props.store_actions.findAgent(ackInfo.userId).then(agent => {

            let name = agent ? agent.firstName : ackInfo.userId;

            if (ackInfo.isAccepted) {
              message.success(`Snapshot request accepted by ${name}`);
              //fireEvent("show_snapshotviewer_modal", { contentUrl: ackInfo.data });
            } else {
              message.warn(`Snapshot request declined by ${name}`);
            }

          }).catch(ex => {
            console.error('Error occurred onSnapshotRequestAck', ex);
          });


        }
      }
    );

    sdkHandler.subscribeEvent(
      "console",
      "onSignatureRequest",
      (acceptOrRejectObject) => {
        if (acceptOrRejectObject) {

          this.props.store_actions.findAgent(acceptOrRejectObject.requesterUserId).then(agent => {

            let name = agent ? agent.firstName : acceptOrRejectObject.requesterUserId;

            fireEvent("show_modal", {
              title: `Signature Request From ${name}`,
              yesButtonText: "Accept",
              cancelButtonText: "Reject",
              onYes: () => {
                fireEvent('show_signature_modal', { toUser: acceptOrRejectObject.requesterUserId, acceptOrRejectObject });

              },
              onCancel: () => {
                acceptOrRejectObject.reject();
              },
            });

          }).catch(ex => {
            console.error('Error occurred onSignatureRequest', ex);
          });


        }
      }
    );

    sdkHandler.subscribeEvent(
      "console",
      "onSignatureRequestAck",
      (ackInfo) => {
        if (ackInfo) {

          this.props.store_actions.findAgent(ackInfo.userId).then(agent => {

            let name = agent ? agent.firstName : ackInfo.userId;

            if (ackInfo.isAccepted) {
              message.success(`Signature request accepted by ${name}`);
              //fireEvent("show_snapshotviewer_modal", { contentUrl: ackInfo.data });
            } else {
              message.warn(`Signature request declined by ${name}`);
            }

          }).catch(ex => {
            console.error('Error occurred onSignatureRequestAck', ex);
          });


        }
      }
    );

    sdkHandler.subscribeEvent(
      "console",
      "onInteractionTransferRequest",
      (acceptRejectObject) => {
        if (acceptRejectObject) {
          fireEvent("show_modal", {
            title: "Accept Transfer",
            yesButtonText: "Accept",
            cancelButtonText: "Reject",
            onYes: () => {
              props.team_actions.getTemporaryAgents(() => {
                let interactionObj = new Interaction(null, acceptRejectObject.InteractionInformation);
                if (interactionObj.CustomerInfo) {
                  let custInfo = {
                    reqSkill: "",
                    type: "Customer",
                    interactionId: acceptRejectObject.InteractionId,
                    intent: interactionObj.Intent,
                    channel: interactionObj.Channel,
                    userDetails:
                      interactionObj.CustomerInfo,
                    userId:
                      interactionObj.CustomerUserId,
                  };
                  props.team_actions.addCustomerInfo(custInfo);

                }


                acceptRejectObject.accept();
                props.callControls_actions.onInteractionTransferAccepted({ accepted: true, interactionId: acceptRejectObject.InteractionId });
              });
              /* if (acceptRejectObject.InteractionInformation && acceptRejectObject.InteractionInformation.CustomerInfo) {
                
              } else {
                message.error("Customer information not found");
                acceptRejectObject.reject();
              } */

            },
            onCancel: () => {
              acceptRejectObject.reject();
            },
          });
        }
      }
    );

    /* sdkHandler.subscribeEvent("console", "onInteractionTransferResponseAcknowledgement", responseObject => {
            if (responseObject.isAccepted) {
                let loggedUserId = auth.getUserId();
                if (responseObject.UserId === loggedUserId) {
                    //add new interaction

                }

                if (responseObject.RequestorUserId === loggedUserId) {
                    //remove interaction
                    //go acw

                    props.interaction_actions.setACWStatus(responseObject.InteractionId);

                    if (__APP_CHAT_END_ACW_ALLOWED__ === "YES") {
                        if (__APP_CHAT_END_ACW_TIME__) {
                            addTimer(responseObject.InteractionId, __APP_CHAT_END_ACW_TIME__);
                        }

                    } else {
                        props.interaction_actions.removeInteraction(responseObject.InteractionId);
                        //has to remove customer
                        props.selected_profile_actions.profileSelected({});

                    }
                }
            }

        }); */

    sdkHandler.subscribeEvent(
      "console",
      "onUserInteractionListing",
      (interactionData) => {

        sdkHandler.unSubscribeEvent("console", "onUserInteractionListing");
        if (
          interactionData &&
          interactionData.length === 1 &&
          interactionData[0].UserId === auth.getUserId()
        ) {
          if (__APP_INTERACTION_MODE__ === 'INTERACTION') {
            props.interaction_actions.interactionsRetrieved(new InteractionUser(interactionData[0]));
          } else {
            props.interaction_actions.interactionsRetrievedHybridMode(new InteractionUser(interactionData[0]));

          }

        }

      }
    );

    sdkHandler.subscribeEvent(
      "console",
      "onMessageSentStatus",
      (externalId, sentStatus) => {
        let values = externalId.split(":");
        values.length === 2 && props.chat_actions.setMessageSendState(values[0], values[1], sentStatus);

      }
    );

    sdkHandler.subscribeEvent("connectivity", "onServerStateChange", (status) => {
      _last_connectivity_event_ = status;
      if (_connectivity_status_ && !status) {
        setTimeout(() => {
          if (!_last_connectivity_event_) {
            _connectivity_status_ = status;
            this.setAvailability("offline");
            props.connectivity_actions.setConnectivity(status);
            startDisconnectTimer();
            message.error("Disconnected from server");
            console.log("MainConsole", "onServerStateChange", "Disconnected from server");
            logger.debug("MainConsole", "onServerStateChange", "Disconnected from server");
          }

        }, 2000);

      }
      if (!_connectivity_status_ && status) {
        setTimeout(() => {
          if (_last_connectivity_event_) {
            _connectivity_status_ = status;
            this.setAvailability("available");
            props.connectivity_actions.setConnectivity(status);
            stopDisconnectTimer();
            message.success("Reconnected to server");
            console.log("MainConsole", "onServerStateChange", "Re-connected with server");
            logger.debug("MainConsole", "onServerStateChange", "Re-connected with server");
          }
        }, 2000);

      }



    });

    sdkHandler.subscribeEvent("console", "onSessionEnd", (content) => {
      if (content.reasonCode === "SessionFetched") {

        phoneEngine.phoneFunctions.hangup("caller", "callee");
        __APP_IDENTITY_PROVIDER__ === "GUARDIAN" &&
          authService.logout(auth.getRefreshToken(), "SESSION_FETCHED");
        //sdkHandler.logoutFromSystem("", "SESSION_FETCHED", "");
        auth.userLogOut();
        props.history.push("/session_lost");
        /* __APP_IDENTITY_PROVIDER__ === "GUARDIAN" && props.history.push("/"); */
        message.warn("Another user has taken over your session");
        console.warn("MainConsole", "onSessionEnd", "Another user has taken over your session");
        logger.warn("MainConsole", "onSessionEnd", "Another user has taken over your session");


        /* if (__APP_IDENTITY_PROVIDER__ === "UAS") {

          

        } */

      }
    });

    sdkHandler.subscribeEvent(
      "mainConsoleOnInteractionEnd",
      "onInteractionEnd",
      (info) => {
        message.warn("Customer ended the chat");
        props.interaction_actions.setACWStatus(info.InteractionId);
        if (phoneConfig.basic_config.calls.hangup_call_after_chat_end) {
          fireEvent("end_call_session", {
            from: auth.getUserId(),
            interactionId: info.InteractionId,
          });
        }

        props.store_actions
          .findCustomerIdForInteraction(info.InteractionId)
          .then((response) => {
            const customerId = response.customerId;
            // customerId, interaction
            if (__APP_CHAT_END_ACW_ALLOWED__ === "YES") {
              if (__APP_CHAT_END_ACW_TIME__) {
                addTimer(info.InteractionId, __APP_CHAT_END_ACW_TIME__);
              }
            } else {
              props.interaction_actions.removeInteraction(info.InteractionId);
              customerId && props.team_actions.removeContact(customerId);
              props.selected_profile_actions.profileSelected({});
            }

            let ws = auth.getWorkspace();

            let endMessage = {
              from: auth.getUserId(),
              to: customerId,
              ended_at: Date.now(),
              tenantId: ws ? ws : __APP_DEFAULT_WORKSPACE__,
            };

            sdkHandler.sendCustomMessage(
              "temp-contact-deleted",
              JSON.stringify(endMessage),
              "service:CNT"
            );


            let invoke_external_api_with_session_end = phoneConfig.basic_config.console.invoke_external_api_with_session_end;
            const open_external_app_db = auth.getPhoneConfig();
            if (open_external_app_db && open_external_app_db.basic_config && open_external_app_db.basic_config.console) {
              invoke_external_api_with_session_end = open_external_app_db.basic_config.console.invoke_external_api_with_session_end;
            }


            if (invoke_external_api_with_session_end) {
              props.external_api_actions.onEndSession(
                response.channel,
                info.InteractionId,
                customerId, response.CustomData ? response.CustomData.dialogId : "-999"
              );
            }
          });


      }
    );

    sdkHandler.subscribeEvent(
      "mainConsoleOnEnd",
      "onEnd",
      (reason, interactionId) => {
        try {
          phoneEngine.phoneFunctions.hangup("caller", "callee");
          __APP_IDENTITY_PROVIDER__ === "GUARDIAN" &&
            authService.logout(auth.getRefreshToken(), reason);
          sdkHandler.logoutFromSystem("", reason, "");
          sdkHandler.endSession();
          auth.userLogOut();
          __APP_IDENTITY_PROVIDER__ === "UAS" &&
            props.history.push("/session_lost");
          __APP_IDENTITY_PROVIDER__ === 'IAM' && props.history.push("/logout");
          if (__APP_IDENTITY_PROVIDER__ === "GUARDIAN") {
            setTimeout(() => {

              props.history.push("/");
              window.location.reload();
              console.log("MainConsole", "uireload", "window.location.reload");

            }, 2000);

          }
          message.warn("Session ended unexpectedly");
          /* if (reason !== "NoSessionFound") {
            
          } */
        } catch (error) {
          console.error("MainConsole", "subscribeEvent-onEnd", error);
          logger.error("MainConsole", "subscribeEvent-onEnd", error.message);
        }
      }
    );

    this.getUserGroupListRecursive = (nextId, groupList) => {

      sdkHandler.subscribeEvent("groups", "onUserGroupListing", (currentList) => {

        if (currentList.GroupListInfo && currentList.GroupListInfo.length > 0) {
          this.getUserGroupListRecursive(currentList.LastGroupListId, [...groupList, ...currentList.GroupListInfo]);
        } else {
          props.group_actions.groupListLoaded({ GroupListInfo: groupList });
        }

      });

      sdkHandler.getUserGroupList(null, nextId);


    };



    if (globalFlags.__IS_SESSION_STARTED__) {
      //normal routing
      sdkHandler.getUserInteractionListForUser([auth.getUserId()]);

      navigationRestrictions.group.active &&
        //sdkHandler.getUserGroupList(null, 0);
        this.getUserGroupListRecursive(0, []);
    } else {
      //page reload
      if(navigationRestrictions.meeting.meeting_only === false){
        this.props.history.push("/console");
      }

      if (sdkHandler.openConnection()) {
        sdkHandler.getUserInteractionListForUser([auth.getUserId()]);

        navigationRestrictions.group.active &&
          this.getUserGroupListRecursive(0, []);
        //sdkHandler.getUserGroupList(null, 0);
      }
    }

    /* if (window.performance) {
            if (performance.navigation.type == 1) {
                
            }
        } */
    this.scrollbarRef = React.createRef();
    this.downArrowRef = React.createRef();
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.onChange = this.onChange.bind(this);  
    
  }

  setAvailability = status => {
    try {
      let presenceObj = { userid: auth.getUserId(), status };
      this.props.presence_actions.sendPresenceSuccess(presenceObj);
    } catch (error) {
      console.error("MainConsole", "setAvailability", `Fail to Set Status Message`, error);
      logger.error("MainConsole", "setAvailability", error.message);
    }
  };
  getConfigurations = async () => {
    try {

      const response = await Promise.all([configurationManagerService.getCustInfoData(), configurationManagerService.getBaseUrlConfig()])
      if (response) {
        if (response[0]) {
          this.setState({
            custInfoData: response[0]
          });
        } else {
          this.setState({
            custInfoData: default_custInfoData
          });
        }
        if (response[1]) {
          auth.setBaseUrl(response[1].urls);
        }
      }


      /* const config = await configurationManagerService.getCustInfoData();
      if (config) {
        this.setState({
          custInfoData: config
        });
      } else {
        this.setState({
          custInfoData: default_custInfoData
        });
      } */
    } catch (error) {
      this.setState({
        custInfoData: default_custInfoData
      });
      console.error("MainConsole", "getConfigurations", error);
      logger.error("MainConsole", "getConfigurations", error.message);
    }
  };

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
    
  }

  forceLogout = () => {

    setTimeout(() => {

      message.error("Meeting Disconnected");
      phoneEngine.phoneFunctions.hangup("caller", "callee");
      auth.userLogOut();
      (__APP_IDENTITY_PROVIDER__ === "UAS" || __APP_IDENTITY_PROVIDER__ === 'IAM') &&
        this.props.history.push("/session_lost");
      if (__APP_IDENTITY_PROVIDER__ === "GUARDIAN") {
        this.props.history.push("/");
        window.location.reload();
      }

    }, 10000);

  }

  joinMeetingAsAnonymousUser = () => {
    let customData = auth.getCustomData();
    if (customData) {
      meetingSDK.joinMeetingAsAnonymous(customData.meetingId, auth.getUserId()).then(data => {
        if (data.statusCode === 1) {
          meetingSDK.getMeetingById(customData.meetingId).then(meeting => {

            this.props.meeting_actions.startMeeting(data.meetingId, data.interactionId, meeting.scheduleMeeting.title);
            let extIdObj = meeting.scheduleMeeting.configurations.find(x => x.feature === 'externalId');

            this.onClickVideoCall("meeting", data.interactionId, { ...meeting.scheduleMeeting, ['externalId']: extIdObj ? extIdObj.featureValue : '' });
            /* this.onClickVideoCall("meeting", data.interactionId, meeting.scheduleMeeting); */

          }).catch(ex => {
            this.forceLogout();
            console.error(ex);
          })

        } else {
          if (data.statusCode === 0) {
            message.error(data.statusMessage);

          } else {
            message.error("Error occurred while trying to join meeting");
          }

          console.log('TodayEvents', 'startMeeting', data.statusMessage);
          logger.debug('TodayEvents', 'startMeeting', data.statusMessage);

          this.forceLogout();

        }
      })

    } else {
      this.forceLogout();
    }

  }

  componentDidMount() {

    //check user type and join meeting
    let userType = auth.getUserType();

    if (userType === 'MEETING') {
      this.joinMeetingAsAnonymousUser();
    }

    this.props.history.listen((newLocation, action) => {
      if (action === "PUSH") {
        if (newLocation.pathname !== this.currentPathname) {
          // Save new location
          this.currentPathname = newLocation.pathname;

          // Clone location object and push it to history
          this.props.history.push({
            pathname: newLocation.pathname,
          });
        }
      } else {
        // Send user back if they try to navigate back
        this.props.history.go(1);
      }
    });

    navigationRestrictions.templates.active && this.props.template_actions.getTemplates();
    navigationRestrictions.templates.active && this.props.canned_message_actions.getAdminCannedMessages();
    navigationRestrictions.templates.active && this.props.canned_message_actions.getMyCannedMessages();

    sdkHandler.subscribeEvent(
      "groups_console",
      "onUserRemovedFromGroup",
      (groupInfo) => {
        if (groupInfo.Participants && groupInfo.Participants.length > 0) {
          if (
            groupInfo.Participants[0] === auth.getUserId() &&
            groupInfo.GroupId === selected_profile_console.username
          ) {
            this.props.selected_profile_actions.profileSelected({});
            this.onClickCloseInfoPanel();
            this.props.history.push("/console");
          }
        }
      }
    );
    sdkHandler.subscribeEvent(
      "console_app_event_subscriber",
      "onAppEvent",
      (appMessage) => {
        try {
          console.log("MainConsole", "subscribeEvent", "console_app_event_subscriber", "onAppEvent", `appMessage : ${appMessage}`);
          const msg = JSON.parse(appMessage);
          switch (msg.message_type) {
            case "MEETING_STARTED":
              fireEvent('meeting_started', msg);
              break;
            case "MEETING_REMINDER":
              fireEvent('meeting_reminder', msg);
              break;
            case "MEETING_CREATED":
              fireEvent('meeting_created', msg);
              break;
            case "MEETING_UPDATED":
              fireEvent('meeting_updated', msg);
              break;
            default:
              break;
          }
        } catch (error) {
          console.log("MainConsole", "subscribeEvent", "console_app_event_subscriber", "onAppEvent", `appMessage : ${appMessage}`, error);
          logger.error("MainConsole", "console_app_event_subscriber", error.message);
        }
      }
    );

    /* subscribeToEvents("acw_end", data => {
            this.props.store_actions.findCustomerIdForInteraction(data).then(customerId => {
                this.props.interaction_actions.removeInteraction(data);
                customerId && this.props.team_actions.removeContact(customerId);
                if (selected_profile_console.username === data) {
                    this.props.selected_profile_actions.profileSelected({});
                }

            })


        }); */

    subscribeToEvents("heartbeat_timeout", () => {

      let session = auth.getSession();

      if (session) {

        AntDModal.error({
          title: 'Server Unreachable - Session Timed Out',
          content: 'Please login back when connectivity is back up',
          maskClosable: false,
          closable: false,
          onOk: () => {
            phoneEngine.phoneFunctions.hangup("caller", "callee");
            auth.userLogOut();
            (__APP_IDENTITY_PROVIDER__ === "UAS" || __APP_IDENTITY_PROVIDER__ === 'IAM') &&
              this.props.history.push("/session_lost");
            if (__APP_IDENTITY_PROVIDER__ === "GUARDIAN") {
              this.props.history.push("/");
              window.location.reload();
            }

          }
        });

      }


      /* fireEvent("show_modal", {
        title: "Server is not reachable - you will be logged out. Please login once the connectivity is back up",
        yesButtonText: "OK",
        cancelButtonText: "Reject",
        onYes: () => {
          phoneEngine.phoneFunctions.hangup("caller", "callee");
          auth.userLogOut();
          __APP_IDENTITY_PROVIDER__ === "UAS" &&
            this.props.history.push("/session_lost");
          __APP_IDENTITY_PROVIDER__ === "GUARDIAN" && this.props.history.push("/");
        },
        onCancel: () => {
          console.log("");
        },
      }); */

    });


    subscribeToEvents("send_snapshot", ({ uploadData, notificationData, snapshotType }) => {

      let uniqueId = uuid();

      let conv_type = 0;

      if (notificationData.requesterUserId.indexOf('meeting:') > -1) {
        notificationData.requesterUserId = notificationData.requesterUserId.replace('meeting:', '');
        conv_type = 4;
      }

      let msg = {
        v: 1,
        mid: uniqueId,
        sid: uniqueId,
        channel: "WEBCHAT",
        message_type: snapshotType,
        from: {
          id: auth.getUserId(),
          name: auth.getDisplayName()
        },
        to: {
          id: notificationData.requesterUserId,
          name: notificationData.requesterUserId
        },
        media: [],
        created_at: Date.now(),
        message_content: "",
        other: null,
        isUploading: 'DONE',
        conversation_type: conv_type
      };

      let url = `${fileMetaServiceUrl}/stream/media/${uploadData.interaction_id}`;
      let thumbnail_url = `${fileMetaServiceUrl}/stream/thumbnail/${uploadData.interaction_id}/225`;

      msg.media.push({
        content_url: url,
        content_size: uploadData.size,
        content_title: uploadData.original_name,
        thumbnail_url: thumbnail_url,
        mimeType: uploadData.contentType,
        created_at: msg.created_at
      });

      this.props.chat_actions.sendNewMessageSuccess(msg);
      ChatService.sendChatMessage(conv_type, "text/html", notificationData.requesterUserId, msg, false, conv_type === 4 ? notificationData.requesterUserId : null);

      if (selected_profile_console.username === notificationData.requesterUserId) {
        fireEvent("scrollbar", { scrollbar_status: "down" });
      }

    });

    subscribeToEvents("on_call_idle", () => {

      if (this.props.currentMeeting && this.props.currentMeeting.meetingId) {
        //end meeting
        console.log(`Leave interaction due to leave meeting : ${JSON.stringify(this.props.currentMeeting)}`);
        logger.debug('MainConsole', 'on_call_idle', `Leave interaction due to leave meeting ${JSON.stringify(this.props.currentMeeting)}`);
        sdkHandler.leaveInteraction(
          this.props.currentMeeting.interactionId,
          "LEAVE_MEETING",
          null
        );
        this.props.interaction_actions.removeInteraction(
          this.props.currentMeeting.interactionId
        );

        if (this.props.currentMeeting.interactionId === selected_profile_console.username) {
          this.props.selected_profile_actions.profileSelected({});
        }

        this.props.current_meeting_actions.leaveMeeting(this.props.currentMeeting.meetingId);

        let userType = auth.getUserType();

        if (userType === 'MEETING') {
          //logout
          this.forceLogout();
        }

      }

    });


    subscribeToEvents("end_chat_session", (data) => {
      message.warn("Ending interaction due to call ending");
      console.log(`Ending chat session due to call ending`);
      logger.debug('MainConsole', 'end_chat_session', `Ending chat session due to call ending`);
      this.props.interaction_actions.setACWStatus(data.interactionId);

      this.props.store_actions
        .findCustomerIdForInteraction(data.interactionId)
        .then((response) => {
          const customerId = response.customerId;
          if (__APP_CHAT_END_ACW_ALLOWED__ === "YES") {
            if (__APP_CHAT_END_ACW_TIME__) {
              addTimer(data.interactionId, __APP_CHAT_END_ACW_TIME__);
            }
          } else {
            this.props.interaction_actions.removeInteraction(
              data.interactionId
            );
            customerId && this.props.team_actions.removeContact(customerId);
            this.props.selected_profile_actions.profileSelected({});
          }

          let ws = auth.getWorkspace();

          let endMessage = {
            from: auth.getUserId(),
            to: customerId,
            ended_at: Date.now(),
            tenantId: ws ? ws : __APP_DEFAULT_WORKSPACE__,
          };

          if (data.disconnectedByAgent) {
            sdkHandler.leaveInteraction(
              data.interactionId,
              "AGENT_VIDEO_CALL_END",
              endMessage
            );
          } else {
            console.log(`Chat is not ended by agent - not sending leave interaction`);
            logger.debug('MainConsole', 'end_chat_session', `Chat is not ended by agent - not sending leave interaction`);
          }



          let invoke_external_api_with_session_end = phoneConfig.basic_config.console.invoke_external_api_with_session_end;
          const open_external_app_db = auth.getPhoneConfig();
          if (open_external_app_db && open_external_app_db.basic_config && open_external_app_db.basic_config.console) {
            invoke_external_api_with_session_end = open_external_app_db.basic_config.console.invoke_external_api_with_session_end;
          }


          if (invoke_external_api_with_session_end) {
            this.props.external_api_actions.onEndSession(
              response.channel,
              response.InteractionId,
              customerId, response.CustomData ? response.CustomData.dialogId : "-999"
            );
          }
        });
    });

    window.addEventListener("blur", (event) => {
      globalFlags.__FOCUSSED__ = false;
    });

    window.addEventListener("focus", (event) => {
      globalFlags.__FOCUSSED__ = true;
    });

    /* window.addEventListener("beforeunload", function (e) {
      phoneEngine.phoneFunctions.hangup("caller", "callee");
      __APP_IDENTITY_PROVIDER__ === "GUARDIAN" &&
        authService.logout(auth.getRefreshToken(), "BROWSER_CLOSE");
      sdkHandler.logoutFromSystem("", "BROWSER_CLOSE", "");
      auth.userLogOut();
    }, false); */

    window.addEventListener(
      "paste",
      (pasteEvent) => {
        var textInput = document.getElementById(
          "chat_text_wrapper_main_window"
        );
        var isFocused = document.activeElement === textInput;
        if (
          isFocused &&
          selected_profile_console &&
          selected_profile_console.username
        ) {
          let mediaItem = retrieveImageFromClipboardAsBlob(pasteEvent);

          if (mediaItem && imageTypes.indexOf(mediaItem.type) !== -1 && navigationRestrictions.media.uploader.active && navigationRestrictions.media.uploader.media_upload.active) {
            //upload
            let fileObj = {};
            //upload
            let fileId = uuid();
            fileObj[fileId] = {
              file_id: fileId,
              file: mediaItem,
              name: mediaItem.name,
              size: mediaItem.size,
              type: mediaItem.type,
              caption: "",
            };

            this.props.ui_panel_actions.openMediaUpload(fileObj, "MEDIA");
          }
        }
      },
      false
    );
    
    if (auth.getToken()) {
      this.updateWindowDimensions();
      window.addEventListener("resize", this.updateWindowDimensions);
    } else {
      this.props.history.push(`/`);
    }

    if(this.props.history.location.state?.detail?.onlyMeeting === true){
      this.setState({
        activeMenu: "meeting",
        activeMenuParams:  []
      });
    }

    
  }

  updateWindowDimensions() {
    this.setState({
      viewPointWidth: window.innerWidth,
      viewPointHeight: window.innerHeight,
    });
  }

  /* updateWindowDimensions() {
      this.setState({
        viewPointWidth: window.innerWidth,
        viewPointHeight: window.innerHeight
      });
    } */

  onPressProfileImg = () => {
    console.log("event fire...");
  };

  onChange = (event) => {
    // this.setState({txtMessage: event.target.value});
    console.log(event);
  };

  onClickOpenInviteModal = (
    callback,
    skipUsers = [],
    loadGroups = true,
    skipOfflineUsers = false,
    title = "Invite",
    multiSelectAllowed = true,
    operation = null
  ) => {
    this.setState({
      isViewModal: true,
      modalTitle: title,
      userSelectModalCallback: callback,
      skipUsers,
      loadGroups,
      skipOfflineUsers,
      multiSelectAllowed,
      operation
    });
  };

  onClickCloseModal = (event) => {
    this.setState({
      isViewModal: false,
      skipUsers: [],
      loadGroups: true,
      skipOfflineUsers: false,
      modalTitle: "invite",
      multiSelectAllowed: true,
      operation: null
    });
  };

  onDownArrowClick = () => {
    this.scrollbarRef.current.onDownArrowClick();
  };

  scrollbarEventsHandler = {
    onScrollStop: () => {
      this.scrollbarRef.current.onScrollStop();
    },
    onScrollFrame: (values) => {
      this.downArrowRef.current.onScrollFrame(values);
    },
  };

  getScrollbarTimeline = () => {
    return scrollBarTimeline;
  };

  setScrollbarPosition = () => {
    setTimeout(() => scrollBarTimeline.scrollToBottom(), 1000);
  };

  setMediaDevices = (devices) => {
    this.childRefMakeCall.current.setMediaDevices(devices);
  }
  //====== Audio =====
  OnClickDialingAudioCall = async (channel, interactionId) => {
    try {
      this.setState({
        chatAreaHeight: 220,
      });
      console.log(
        "MainConsole",
        "phone call dialing............................................"
      );
      const response = await this.childRefMakeCall.current.makeCall(
        "AUDIO_CALL",
        channel,
        interactionId
      );
      if (response) {
        this.setState({
          callTypeStatus: "audio",
          activePlayer: "audio",
        });
      } else {
        message.error("Unable To Dial Call", 5);
        this.setState({
          chatAreaHeight: 160,
        });
      }
      fireEvent("scrollbar", { scrollbar_status: "down" });
    } catch (error) {
      console.error("MainConsole", "OnClickDialingAudioCall", "Fail To Dial Audio Call", error);
      logger.error("MainConsole", "OnClickDialingAudioCall", error.message);
      this.setState({
        chatAreaHeight: 160,
      });
    }
  };
  OnClickAnzAudioCall = (event) => {
    // this.setState({txtMessage: event.target.value});
    console.log(
      "MainConsole",
      "phone anz call............................................."
    );
    this.setState({
      callTypeStatus: "audioConnected",
      activePlayer: "audio",
      chatAreaHeight: 220,
    });
  };
  OnClickAudioEndCall = (event) => {
    // this.setState({txtMessage: event.target.value});
    console.log("MainConsole", "phone call end....");
    this.setState({
      callTypeStatus: "",
      activePlayer: null,
      chatAreaHeight: 160,
    });
  };
  //======== End Audio Call ============

  onClickSelfView = async (channel, interactionId) => {
    try {
      console.log(
        "MainConsole",
        "video phone dialing............................................."
      );
      this.setState({
        isVideoFullScreen: false,
      });

      const response = await this.childRefMakeCall.current.makeCall(
        "SELF_VIEW",
        channel,
        interactionId
      );
      if (response) {
        this.setState({
          callTypeStatus: "selfview",
          isVideoFullScreen: false,
        });
      } else {
        message.error("Unable To Dial Call", 5);
      }
    } catch (error) {
      console.error(
        "MainConsole",
        "onClickSelfView",
        "Fail To get self view",
        error
      );
      logger.error("MainConsole", "onClickSelfView", error.message);
    }
  };

  //==== video call root ==============
  onClickVideoCall = async (channel, interactionId, otherData) => {
    try {
      console.log(
        "MainConsole",
        "video phone dialing............................................."
      );
      this.setState({
        isVideoFullScreen: false,
      });

      const response = await this.childRefMakeCall.current.makeCall(
        channel === "meeting" ? "MEETING_VIDEO_CALL" : "VIDEO_CALL",
        channel,
        interactionId,
        otherData
      );
      if (response) {
        this.setState({
          callTypeStatus: "video",
          isVideoFullScreen: false,
        });
      } else {
        message.error("Unable To Dial Call", 5);
      }
    } catch (error) {
      console.error("MainConsole", "onClickVideoCall", "Fail To Dial Video Call", error);
      logger.error("MainConsole", "onClickVideoCall", error.message);
    }
  };

  onClickScreenSharing = async (val, channel, interactionId) => {
    try {
      console.log("MainConsole", "ScreenSharing ....");
      const response = await this.childRefMakeCall.current.makeCall(
        val ? "SCREENSHARE" : "SCREENSHAREEND",
        channel,
        interactionId
      );
    } catch (error) {
      console.error("MainConsole", "onClickScreenSharing", "Fail To Start ScreenSharing", error);
      logger.error("MainConsole", "onClickScreenSharing", error.message);
    }
  };
  onClickScreenSharingRequest = async (channel, interactionId) => {
    try {
      console.log("MainConsole", "onClickScreenSharingRequest ....");
      await this.childRefMakeCall.current.makeCall(
        "SCREENSHARE_REQUEST",
        channel,
        interactionId
      );
    } catch (error) {
      console.error("MainConsole", "onClickScreenSharingRequest", "Fail To ScreenSharingRequest", error);
      logger.error("MainConsole", "onClickScreenSharingRequest", error.message);
    }
  };

  onClickVideoCallAnz = (event) => {
    console.log("MainConsole", "video phone anz....");
    this.setState({
      callTypeStatus: "videoConnected",
      isVideoFullScreen: false,
      activePlayer: "video",
      chatAreaHeight: 160,
    });
  };
  onClickEndVideoCall = (event) => {
    console.log("MainConsole", "video end enable....");
    this.setState({
      callTypeStatus: "",
      isVideoFullScreen: true,
      isVideoPinStatus: true,
      activePlayer: null,
      chatAreaHeight: 160,
      isViewModal: false,
    });
  };

  onClickUnlockVideo = () => {
    console.log("unlock video ");
    // this.setState({
    //   isVideoPinStatus: false,
    //   isVideoFullScreen: false,
    //   isOpenParticipation: false,
    //   prtPanelAnimation: "hide-me",
    //   chatAreaHeight: 180
    // });
  };
  onClickLockVideo = () => {
    console.log("lock video ");
    // this.setState({
    //   isVideoPinStatus: true,
    //   isVideoFullScreen: false,
    //   isOpenParticipation: false,
    //   prtPanelAnimation: "hide-me",
    //   //chatAreaHeight: 680
    // });
  };

  onClickFullScreenVideo = () => {
    // this.setState({
    //   isVideoFullScreen: true,
    //  // chatAreaHeight: 180,
    //   isOpenParticipation: true,
    //   isVideoPinStatus: false,
    //   prtPanelAnimation: "show-me"
    // });
  };

  //==== End video call ========

  /* //==== Participation action ==============
      onClickShowParticipation = () => {
        console.log("show participation ");
        this.setState({
          isOpenParticipation: true,
          prtPanelAnimation: "show-me"
        });
      };
      onClickHideParticipation = () => {
        console.log("hide participation ");
        this.setState({
          isOpenParticipation: false,
          prtPanelAnimation: "hide-me"
        });
      }; */

  //==== menu activity ==============
  onPressMenuIcon = (__activeMenu, ...extraParams) => {
    this.setState({
      activeMenu: __activeMenu,
      activeMenuParams: extraParams ? extraParams : []
    });

    this.setState({
      isOpenInfoPanel: navigationRestrictions.panels.right.fixed ? true : false,
    });
  };

  //==== Profile Info panel activity ==============
  onClickOpenInfoPanel = () => {
    console.log("attachment open.....");
    this.setState({
      isOpenInfoPanel: true,
    });
  };

  onClickCloseInfoPanel = () => {
    console.log("attachment close.....");
    fireEvent("clear_chat_search", true);
    fireEvent("clear_star_search", true);
    this.setState({
      isOpenInfoPanel: false,
    });
    let url = window.location.pathname.replace("/profile", "").replace("/TAC", "");
    this.props.history.push(url);
  };

  onClickOpenMediaUpload = () => {
    this.setState({
      isOpenMedialUploadView: true,
    });
  };

  onClickCloseMediaUpload = () => {
    this.setState({ firstFile: null, isOpenMedialUploadView: false });
  };

  onUploadFirstMedia = (media) => {
    this.setState({
      isOpenMedialUploadView: true,
      firstFile: media.target.files[0],
    });
  };

  //======== chat Message =====

  onClickReplayMessage = (replyMessage) => {
    console.log("start replay message.....");
    this.setState(produce(draft => {
      draft.isEnableReplyMessage = true;
      draft.replyMessage = replyMessage;
      draft.chatAreaHeight = 250
      return draft;
    }));
  };

  onClickCloseReplayMessage = () => {
    console.log("end replay message.....");
    this.setState({
      isEnableReplyMessage: false,
      replyMessage: null,
      chatAreaHeight: 160,
    });
  };

  onClickForwardMessage = (callback) => {
    console.log("start replay message.....");

    this.setState({
      isForwardMessage: true,
      modalTitle: "Forward Message to",
      isViewModal: true,
      userSelectModalCallback: callback,
      skipUsers: [],
      loadGroups: true,
      skipOfflineUsers: false,
      multiSelectAllowed: true,
      operation: "FORWARDING"
    });
  };

  onClickVoiceRecorder = () => {
    console.log("start voice recorder.....");
    this.setState({
      isEnableVoiceRecorder: true,
    });
  };

  onClickCloseVoiceRecorder = () => {
    console.log("end voice recorder.....");
    this.setState({
      isEnableVoiceRecorder: false,
    });
  };

  //======== click video recorder =====
  onClickVideoRecorder = () => {
    console.log("start video recorder.....");
    this.setState({
      isEnableVideoRecorder: true,
    });
  };
  onClickCloseVideoRecorder = () => {
    console.log("start video recorder.....");
    this.setState({
      isEnableVideoRecorder: false,
      isStartVideoRecord: false,
    });
  };

  startRecord = () => {
    console.log("start video recorder.....");
    this.setState({
      isStartVideoRecord: true,
    });
  };
  sendRecord = () => {
    console.log("send video recorder.....");
    this.setState({
      isStartVideoRecord: false,
    });
  };

  showArrowOnEvent = () => {
    this.downArrowRef.current.showArrowOnEvent();
  };

  //======== Media gallery view =====
  onClickOpenGallery = (openGalleryMediaFiles) => {
    this.setState({
      isOpenGallery: true,
      openGalleryMediaFiles,
    });
  };
  onClickCLoseGallery = () => {
    this.setState({
      isOpenGallery: false,
    });
  };

  //== mobile view event
  onClickOpenMobileProfileMenu = () => {
    this.setState({
      isOpenMobileProfile: true,
    });
  };
  onClickCloseMobileProfileMenu = () => {
    this.setState({
      isOpenMobileProfile: false,
    });
  };

  onClickCloseChatPanelMobile = () => {
    console.log("event fire...");
    this.props.selected_profile_actions.profileSelected({});
  };

  onClickOpenCoBrowsing = () => {
    this.setState({
      isOpenCoBrowsing: true,
    });
  };
  onClickCloseCoBrowsing = () => {
    this.setState({
      isOpenCoBrowsing: false,
    });
  };

  render() {
    const {
      viewPointWidth,
      viewPointHeight,
      txtMessage,
      isViewModal,
      skipUsers,
      loadGroups,
      skipOfflineUsers,
      activeAudioPlayer,
      isView,
      activePlayer,
      isViewVideoModal,
      chatAreaHeight,
      isOpenParticipation,
      prtPanelAnimation,
      isVideoPinStatus,
      isVideoFullScreen,
      activeMenu,
      activeMenuParams,
      callTypeStatus,
      isOpenInfoPanel,
      isOpenMedialUploadView,
      isEnableReplyMessage,
      isEnableVoiceRecorder,
      modalTitle,
      isEnableVideoRecorder,
      isStartVideoRecord,
      isOpenGallery,
      pathName = window.location.pathname,
      isOpenMobileProfile,
      isOpenCoBrowsing,
      multiSelectAllowed,
      operation, custInfoData
    } = this.state;
    const { props, selected_profile, mediaDevices } = this.props;
    console.log(chatAreaHeight);
    console.log(
      "%cMAIN CONSOLE RENDER",
      "color:#233E82; font-family:'Ubuntu'; display: block;font-weight:bold; font-size:15px;"
    );
    return (
      <>

        <WhiteboardEvents />
        <MeetingAlerts onClickVideoCall={this.onClickVideoCall} />
        <ProfileSearchModal />
        <SignaturePadModal />
        <UIPopupModal />
        <TemplateModal />
        <UIDisableModal />
        <WebNotification />
        <DeviceNotSupported />
        {/*====== media gallery view ======== */}
        <MobileMediaUpload
          onClickCloseMediaUpload={this.onClickCloseMediaUpload}
          isOpenMedialUploadView={isOpenMedialUploadView}
          viewPointWidth={viewPointWidth}
          viewPointHeight={viewPointHeight}
          isOpenInfoPanel={isOpenInfoPanel}
          firstFile={this.state.firstFile}
        />

        {isOpenGallery && (
          <MediaGalleryView
            openGalleryMediaFiles={this.state.openGalleryMediaFiles}
            onClickOpenGallery={this.onClickOpenGallery}
            onClickCLoseGallery={this.onClickCLoseGallery}
            onClickForwardMessage={this.onClickForwardMessage}
            viewPointWidth={viewPointWidth}
            viewPointHeight={viewPointHeight}
          />
        )}

        <UserSelectModalComponent
          title={modalTitle}
          onClickClose={this.onClickCloseModal}
          isViewModal={isViewModal}
          skipUsers={skipUsers}
          loadGroups={loadGroups}
          multiSelectAllowed={multiSelectAllowed}
          operation={operation}
          skipOfflineUsers={skipOfflineUsers}
          onCallback={this.state.userSelectModalCallback}
        />
        <VideoRecorderModal
          title={"Video Record"}
          isEnableVideoRecorder={isEnableVideoRecorder}
          onClickClose={this.onClickCloseVideoRecorder}
          startRecord={this.startRecord}
          isStartVideoRecord={isStartVideoRecord}
          sendRecord={this.sendRecord}
        />

        {/*===== Desktop component ===*/}
        <LeftSideNavigationComponent
          history={this.props.history}
          activeMenu={activeMenu}
          onPressMenuIcon={this.onPressMenuIcon}
          onClickCloseInfoPanel={this.onClickCloseInfoPanel}
          onClickSelfView={this.onClickSelfView}
        />
        

        {/*===== Mobile component ===*/}
        <MobileLeftSideNavigation
          viewPointHeight={viewPointHeight}
          isOpenMobileProfile={isOpenMobileProfile}
          onClickCloseMobileProfileMenu={this.onClickCloseMobileProfileMenu}
          history={this.props.history}
          activeMenu={activeMenu}
          onPressMenuIcon={this.onPressMenuIcon}
          onClickCloseInfoPanel={this.onClickCloseInfoPanel}
          onClickSelfView={this.onClickSelfView}
        />

        <ActionPanelComponent
          activePlayer={activePlayer}
          onPressMenuIcon={this.onPressMenuIcon}
          onClickCloseInfoPanel={this.onClickCloseInfoPanel}
          onClickOpenInfoPanel={this.onClickOpenInfoPanel}
          viewPointHeight={viewPointHeight}
          viewPointWidth={viewPointWidth}
          history={this.props.history}
          onClickOpenMobileProfileMenu={this.onClickOpenMobileProfileMenu}
          onClickSelfView={this.onClickSelfView}
        />

        

        <CoBrowsingModal
          onPressMenuIcon={this.onPressMenuIcon}
          isOpenCoBrowsing={isOpenCoBrowsing}
          onClickClose={this.onClickCloseCoBrowsing}
          size={"default"}
        />

        <section
          className={`play-ground-main-wrapper ${navigationRestrictions.meeting.meeting_only? "only-meeting":""}  ${viewPointWidth > 895 ? "desktop-view " : "mobile-view"
            } 
                    ${selected_profile
              ? "m-profile-active"
              : "m-profile-none"
            }`}
          style={{ width: viewPointWidth - ( navigationRestrictions.meeting.meeting_only?  0 : (isOpenInfoPanel ? 690 : 390)) }}
        >
          {selected_profile && (
            <>
              <div
                className={`pg-header-wrapper`}
                style={{
                  width: viewPointWidth - ( navigationRestrictions.meeting.meeting_only? 0 : (isOpenInfoPanel ? 680 : 390)),
                }}
              >
                {/*===== Desktop component ===*/}
                <HeaderPanelComponent
                  //isAudioView={this.state.showNotification}
                  onPressMenuIcon={this.onPressMenuIcon}
                  onClickOpenInviteModal={this.onClickOpenInviteModal}
                  onClickCloseInfoPanel={this.onClickCloseInfoPanel}
                  onClickOpenMediaUpload={this.onClickOpenMediaUpload}
                  onClickOpenInfoPanel={this.onClickOpenInfoPanel}
                  OnClickOutGoingCall={this.OnClickDialingAudioCall}
                  onClickVideoCall={this.onClickVideoCall}
                  onClickScreenSharing={this.onClickScreenSharing}
                  onClickScreenSharingRequest={this.onClickScreenSharingRequest}
                  onUploadFirstMedia={this.onUploadFirstMedia}
                  activeMenu={activeMenu}
                  type={"desktop"}
                  mediaDevices={mediaDevices}
                  setMediaDevices={this.setMediaDevices}
                  onClickSelfView={this.onClickSelfView}
                />
              </div>

              {/*===== Mobile component ===*/}
              <div
                className={`mobile-pg-header-wrapper`}
                style={{
                  width: viewPointWidth - (isOpenInfoPanel ? 680 : 390),
                }}
              >
                <MobileHeaderPanel
                  //isAudioView={this.state.showNotification}
                  onClickOpenMediaUpload={this.onClickOpenMediaUpload}
                  onClickOpenInfoPanel={this.onClickOpenInfoPanel}
                  OnClickOutGoingCall={this.OnClickDialingAudioCall}
                  type={"mobile"}
                  onClickVideoCall={this.onClickVideoCall}
                  onClickScreenSharing={this.onClickScreenSharing}
                  onClickScreenSharingRequest={this.onClickScreenSharingRequest}
                  onUploadFirstMedia={this.onUploadFirstMedia}
                  onClickCloseChatPanelMobile={this.onClickCloseChatPanelMobile}
                  mediaDevices={mediaDevices}
                  setMediaDevices={this.setMediaDevices}
                  onClickSelfView={this.onClickSelfView}
                />
              </div>
            </>
          )}

          {isAllowedVideoCall && (
            <SoftPhone
              ref={this.childRefMakeCall}
              OnClickOutGoingCall={this.onClickEndVideoCall}
              onClickOpenInviteModal={this.onClickOpenInviteModal}
              onClickCloseModal={this.onClickCloseModal}
              onClickOpenParticipation={this.onClickShowParticipation}
              onClickHideParticipation={this.onClickHideParticipation}
              onClickUnlockVideo={this.onClickUnlockVideo}
              onClickLockVideo={this.onClickLockVideo}
              onClickFullScreenVideo={this.onClickFullScreenVideo}
              isVideoPinStatus={isVideoPinStatus}
              prtPanelAnimation={prtPanelAnimation}
              isOpenParticipation={isOpenParticipation}
              isVideoFullScreen={isVideoFullScreen}
              viewPointHeight={viewPointHeight}
              viewPointWidth={viewPointWidth}
              OnClickAudioEndCall={this.OnClickAudioEndCall}
              OnClickAnzAudioCall={this.OnClickAnzAudioCall}
              onClickVideoCallAnz={this.onClickVideoCallAnz}
              callTypeStatus={callTypeStatus}
              onClickEndVideoCall={this.onClickEndVideoCall}
              isOpenInfoPanel={isOpenInfoPanel}
            />
          )}

          <WorkspaceRequest />

          {/*======= chat body wrapper ======== */}
          {/*======== Media file upload =========*/}
          <MediaUpload
            onClickCloseMediaUpload={this.onClickCloseMediaUpload}
            isOpenMedialUploadView={isOpenMedialUploadView}
            viewPointWidth={viewPointWidth}
            viewPointHeight={viewPointHeight}
            isOpenInfoPanel={isOpenInfoPanel}
            firstFile={this.state.firstFile}
          />

          {!selected_profile && activeMenu !== 'meeting' && (
            <ChatEmptyMessage
              viewPointHeight={viewPointHeight}
              viewPointWidth={viewPointWidth}
            />
          )}

          {this.props.whiteboard.wbState !== 'CLOSED' && this.props.whiteboard.wbType === 'WHITEBOARD' && <WhiteBoard viewPointHeight={viewPointHeight}
            viewPointWidth={viewPointWidth}
            activeMenu={activeMenu} />}

          {this.props.whiteboard.wbState !== 'CLOSED' && this.props.whiteboard.wbType === 'COBROWSE' && <CoBrowse viewPointHeight={viewPointHeight}
            viewPointWidth={viewPointWidth}
            activeMenu={activeMenu}
            cobrowseSource={activeMenuParams.length > 0 ? activeMenuParams[0] : ""} />}



          <MeetingsWrapper activeMenu={activeMenu}
            onClickVideoCall={this.onClickVideoCall}
            viewPointHeight={viewPointHeight}
            viewPointWidth={viewPointWidth} />

          {/* <Meetings activeMenu={activeMenu}
            viewPointHeight={viewPointHeight}
            viewPointWidth={viewPointWidth} /> */}
          {/*style={{ height: viewPointHeight - (activePlayer === 'audio' ? 240 : chatAreaHeight) }}*/}
          {
            <>
              <div
                className={`timeline-wrapper`}
                style={{
                  height:
                    viewPointHeight -
                    (activePlayer === "audio"
                      ? isEnableReplyMessage
                        ? 300
                        : 220
                      : isEnableReplyMessage
                        ? 240
                        : chatAreaHeight),
                }}
              >
                <Scrollbars
                  autoHide
                  renderView={(props) => (
                    <div
                      {...props}
                      style={{
                        ...props.style,
                        overflowX: "hidden",
                        marginBottom: false ? "60px" : "0px",
                      }}
                    />
                  )}
                  thumbMinSize={0}
                  style={{
                    height:
                      viewPointHeight - (activePlayer ? 220 : chatAreaHeight),
                  }}
                  onScrollFrame={this.scrollbarEventsHandler.onScrollFrame}
                  onScrollStop={this.scrollbarEventsHandler.onScrollStop}
                  ref={(c) => {
                    scrollBarTimeline = c;
                  }}
                >

                  <ChatPanelComponent
                    activeMenu={activeMenu}
                    showArrowOnEvent={this.showArrowOnEvent}
                    onViewMedia={this.onViewMedia}
                    onClickOpenGallery={this.onClickOpenGallery}
                    onClickReplayMessage={this.onClickReplayMessage}
                    onClickForwardMessage={this.onClickForwardMessage}
                    ref={this.scrollbarRef}
                    setScrollbarPosition={this.setScrollbarPosition}
                    OnClickOutGoingCall={this.OnClickOutGoingCall}
                    getScrollbarTimeline={this.getScrollbarTimeline}
                    makeCall={(callType) => {
                      if (callType === "AUDIO_CALL") {
                        this.OnClickDialingAudioCall();
                      } else {
                        this.onClickVideoCall();
                      }
                    }}
                  />

                  <DownArrowChat
                    activeMenu={activeMenu}
                    ref={this.downArrowRef}
                    isOpenInfoPanel={isOpenInfoPanel}
                    isEnableReplyMessage={isEnableReplyMessage}
                    onDownArrowClick={this.onDownArrowClick}
                    getScrollbarTimeline={this.getScrollbarTimeline}
                  />
                </Scrollbars>
              </div>

              {/*======= end ======== */}

              {/*===== desktop component ===*/}
              {activeMenu === 'chat' && <ChatTextWrapper
                onClickVoiceRecorder={this.onClickVoiceRecorder}
                activePlayer={activePlayer}
                isEnableReplyMessage={isEnableReplyMessage}
                replyMessage={this.state.replyMessage}
                isOpenInfoPanel={isOpenInfoPanel}
                isEnableVoiceRecorder={isEnableVoiceRecorder}
                onClickCloseReplayMessage={this.onClickCloseReplayMessage}
                onClickReplayMessage={this.onClickReplayMessage}
                onClickVideoRecorder={this.onClickVideoRecorder}
                onClickCloseVoiceRecorder={this.onClickCloseVoiceRecorder}
                onClickOpenCoBrowsing={this.onClickOpenCoBrowsing}
                viewPointWidth={viewPointWidth}
              />}

              {/*===== Mobile component ===*/}
              {activeMenu === 'chat' && <MobileChatTextWrapper
                onClickVoiceRecorder={this.onClickVoiceRecorder}
                activePlayer={activePlayer}
                isEnableReplyMessage={isEnableReplyMessage}
                replyMessage={this.state.replyMessage}
                isOpenInfoPanel={isOpenInfoPanel}
                isEnableVoiceRecorder={isEnableVoiceRecorder}
                onClickCloseReplayMessage={this.onClickCloseReplayMessage}
                onClickReplayMessage={this.onClickReplayMessage}
                onClickVideoRecorder={this.onClickVideoRecorder}
                onClickCloseVoiceRecorder={this.onClickCloseVoiceRecorder}
                viewPointWidth={viewPointWidth}
              />
              }
            </>
          }
        </section>

      
        <Route exact path="console/meeting">
          <MeetingDashboard             
            activeMenu={"meeting"}
            onClickVideoCall={this.onClickVideoCall}
            setUIState={console.error("---------------------------------")}
            viewPointHeight={viewPointHeight}
            viewPointWidth={viewPointWidth}/>
        </Route>
        <Route exact path="/console/:handle/profile/starred-message">
          {selected_profile && (
            <StarredPanel
              title={"Starred Message"}
              viewPointHeight={viewPointHeight}
              isOpenInfoPanel={isOpenInfoPanel}
              onClickCloseInfoPanel={this.onClickCloseInfoPanel}
              onClickOpenGallery={this.onClickOpenGallery}
              onClickReplayMessage={this.onClickReplayMessage}
              onClickForwardMessage={this.onClickForwardMessage}
            />
          )}
        </Route>

        <Route exact path="/console/:handle/search">
          <ChatSearchPanelComponent
            title={"Search"}
            viewPointHeight={viewPointHeight}
            isOpenInfoPanel={isOpenInfoPanel}
            onClickCloseInfoPanel={this.onClickCloseInfoPanel}
          />
        </Route>
        <Route
          exact
          path="/console/:handle/profile"
          component={() => (
            <UserProfilePanelComponent
              title={"User Profile"}
              onClickOpenInviteModal={this.onClickOpenInviteModal}
              viewPointHeight={viewPointHeight}
              isOpenInfoPanel={isOpenInfoPanel}
              onClickCloseInfoPanel={this.onClickCloseInfoPanel}
              custInfoData={custInfoData}
            />
          )}
        ></Route>
        <Route
          path="/console/:handle/profile/media"
          component={() => (
            <MediaPanel
              title={"User Profile"}
              onClickOpenInviteModal={this.onClickOpenInviteModal}
              viewPointHeight={viewPointHeight}
              onClickForwardMessage={this.onClickForwardMessage}
              onClickOpenGallery={this.onClickOpenGallery}
              location={pathName}
              isOpenInfoPanel={isOpenInfoPanel}
              onClickCloseInfoPanel={this.onClickCloseInfoPanel}
            />
          )}
        ></Route>
      </>
    );
  }
}

function mapStateToProps(state) {
  selected_profile_console = state.selected_profile;
  return {
    selected_profile: state.selected_profile && state.selected_profile.username ? true : false,
    mediaDevices: state.callControls.controls.mediaDevices,
    currentMeeting: state.current_meeting,
    whiteboard: state.whiteboard
  };
}

function mapDispatchToProps(dispatch) {
  return {
    team_actions: bindActionCreators(teamActions, dispatch),
    chat_actions: bindActionCreators(chatActions, dispatch),
    template_actions: bindActionCreators(templateActions, dispatch),
    canned_message_actions: bindActionCreators(cannedMessageActions, dispatch),
    interaction_actions: bindActionCreators(interactionActions, dispatch),
    group_actions: bindActionCreators(groupActions, dispatch),
    notification_actions: bindActionCreators(notificationActions, dispatch),
    ui_panel_actions: bindActionCreators(uiPanelActions, dispatch),
    selected_profile_actions: bindActionCreators(
      selectedProfileActions,
      dispatch
    ),
    store_actions: bindActionCreators(
      { findCustomerIdForInteraction, findAgent },
      dispatch
    ),
    presence_actions: bindActionCreators(presenceActions, dispatch),
    external_api_actions: bindActionCreators(externalApiActions, dispatch),
    connectivity_actions: bindActionCreators(conectivityActions, dispatch),
    callControls_actions: bindActionCreators(callControlsActions, dispatch),
    current_meeting_actions: bindActionCreators(currentMeetingActions, dispatch),
    meeting_actions: bindActionCreators(meetingActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ConsoleComponent);


