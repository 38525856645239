import React from 'react';
import './styles/co-browsing-modal.scss';
import {PanelTitle} from "../index";
import {Button} from "../../common";

const agentConfirmation = (props) => {


    return (
        <>
            <div
                className={`agent-confirmation-wrapper cb-confirm ${!props.isConfirmCoBrowsing ? 'show-confirm' : 'hide-confirm'}`}>
                <div className={'acfm-banner'}>
                    <img className="img-fluid"
                         src={require("../../../assets/img/coBrowsingBg.svg")} alt="User Avatar"/>
                </div>

                <div className={'acfm-title text-center'}>
                    <PanelTitle
                        type={'blue-title'}
                        title={'Co-browsing'}/>
                </div>
                <div className={'acfm-description text-center'}>
                    Co-browsing allows you to see and interact with your customers
                    web access in real time.if you want to start co-browsing click on
                    “Start co-browsing.
                </div>

                <div className={'acfm-description text-center'}>
                    <Button
                        onClick={props.confirmCoBrowsing}
                        size={'btn-md '}
                        title={'Start Co-browsing'}/>

                </div>
            </div>

        </>
    )
};


export default agentConfirmation;
