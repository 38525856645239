
const background = require('../../../assets/img/virtual_background/background.svg');

export  const virtualBackgroundStream = async(mediaStream,edgeBlurAmount = 10 ,backendImage = background,config) =>{
  try {
    const path = `${window.origin}/virtual_background_model/mobilenet/model-stride16.json`;
    console.log("StreamUtility","virtualBackgroundStream",`model url : ${path}`);
    await window.virtualBackground.loadModel(path);
    let stream = await window.virtualBackground.virtualBackgroundStream(mediaStream, backendImage, edgeBlurAmount,config);
    return stream;
  } catch (error) {
    console.error("StreamUtility","virtualBackgroundStream","fail to add virtual background to streams" , error);
    return mediaStream;
  }
}

export  const RemoveVirtualBackgroundStreams = async ()=>{
  try {
      await window.virtualBackground.destroyStream();
  } catch (error) {
    console.error("StreamUtility","RemoveVirtualBackgroundStreams","fail to remove streams" , error);
  }
}

/*
Note : copy 'virtual-background.min.js' file and 'mobilenet' folder into agentui\public\virtual_background_model. 
before build virtual-background project you need to modify 
  <> 'initVideoStream' method. 
    * set this two attributes   video.muted,video.WebKitPlaysInline
      // local video need to be muted
      video.muted = true;
      video.WebKitPlaysInline = true;
  <> 'loadModel' method
      // need to reset this value after execute destroy method. otherwise unable to reuse virtualBackgroundStream method 
      dead = false;
*/
