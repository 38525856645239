import React, { } from 'react';
import PropTypes from 'prop-types';
import { Download, Check, Eye, ExternalLink } from "react-feather";
import { SelectedIcon, MenuIcon } from "../index";


const MediaLinks = ({ name, onClickOpenGallery, date, active, fileType, fileName, link, selectThis, linkString }) => (
    <>

        <div className={`media-doc-wrapper  ${active ? 'selected' : 'default'}`}>
            <div className={`media-header d-flex justify-content-between`}>
                <div className={'name'}>{name}</div>
                <div className={'date'}>{date}</div>
            </div>
            <clear />
            <div className={`media-doc-body-wrapper d-flex align-items-center justify-content-between `}>
                <div
                    className={'d-flex'}>
                    <div className={'selection-icon-wrapper  d-flex align-items-center'} onClick={selectThis}>
                        <SelectedIcon
                            active={active}
                            icon={<Check />} />
                    </div>
                    <div

                        className={'svg-icon-wrapper d-flex  align-items-center justify-content-center'}>
                        {fileType === 'youtube' &&
                            <img className="img-fluid"
                                src={require("../../../assets/img/youtube.svg")} alt="User Avatar" />
                        }
                        {fileType === 'facebook' &&
                            <img className="img-fluid"
                                src={require("../../../assets/img/facebook.svg")} alt="User Avatar" />
                        }
                        {fileType === 'link' &&
                            <img className="img-fluid"
                                src={require("../../../assets/img/link.svg")} alt="User Avatar" />
                        }

                    </div>
                    <div className={'file-text-wrapper d-flex flex-column justify-content-center'}>
                        <div className={'file-name'}>
                            {fileName}
                        </div>
                        <div className={'size'} onClick={onClickOpenGallery}>
                            {link}
                        </div>
                    </div>
                </div>

                <div>
                    <div className={'left-icon-wrapper d-flex '}>
                        <MenuIcon size={'sm'}
                            icon={<ExternalLink />}
                            onClick={() => {
                                window.open(link);
                            }}
                        />
                    </div>
                </div>

            </div>
        </div>
    </>
);


MediaLinks.defaultProps = {};

export default MediaLinks;
