const retrieveImageFromClipboardAsBlob = (pasteEvent) => {
    if (pasteEvent.clipboardData == false) {
        return null;
    };

    let items = pasteEvent.clipboardData.items;

    if (items == null) {
        return null;
    };

    for (let i = 0; i < items.length; i++) {
        // Skip content if not image
        if (items[i].type.indexOf("image") == -1) continue;
        // Retrieve image on clipboard as blob
        let blob = items[i].getAsFile();

        return blob;
    }
}

export default retrieveImageFromClipboardAsBlob;