import React, { useEffect, useState } from "react";
import {
    ButtonRounded,
    ProfileImage,
    OnlineStatus,
    MenuIcon,
    PanelTitle,
    ChatFilterTab,
    SearchTextBox,
    SelectUser,
    ChatUser,
    TextBoxWithLabel,
    UploadImageThumbnail
} from "../../common";
import { Check, ArrowRight, Upload, ArrowLeft } from "react-feather";
import { useHistory } from "react-router-dom";
import { Route, Switch } from "react-router-dom";
import ConsoleComponent from "../console/MainConsole";
import { Scrollbars } from "react-custom-scrollbars";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import liveChatClient from "../../../lib";
import * as auth from "../../../utils";
import { message } from "antd";
import * as groupActions from "../../../redux/actions/groupActions";
import * as logger from "../../../lib/logSdk/logger";

const CreateNewGroup = props => {

    let history = useHistory();

    const removeMeFromList = () => {
        let contactList = Object.values(props.colleagues).reduce((acc, item) => {
            if (item.userId !== auth.getUserId()) {
                acc.push({
                    userId: item.userId,
                    fullName: `${item.firstName} ${item.lastName}`,
                    avatar: item.avatar
                });
            }
            return acc;
        }, []);

        if (contactList) {
            contactList = contactList.sort(function (a, b) {
                return a.fullName.localeCompare(b.fullName);
            });
        }

        return contactList;
    }

    const groupContactList = removeMeFromList();

    const [groupNameActive, SetGroupNameActive] = useState(false);
    const [panelTitle, SetPanelTitle] = useState("Add group participants");
    const [groupName, setGroupName] = useState("");
    const [userName, setUserName] = useState("");
    const [filteredContactList, setFilteredContactList] = useState(groupContactList);
    const [participators, setParticipators] = useState({});
    const [isCreateGroup, setCreateGroup] = useState(false);


    useEffect(() => {
        liveChatClient.subscribeEvent("newGroupCreation", "onGroupCreated", groupInfo => {
            //props.group_actions.createGroupChat(groupInfo);
            message.success("Group Created Successfully", 5);
            SetGroupNameActive(false);
            SetPanelTitle("Add group participants");
            setGroupName("");
            setCreateGroup(false);

            /* props.chat_search_actions.resetSearchMessages();
            renderState.focusToLastRead = true;
            renderState.scrollReadyState = false;
            //props.onClickCloseInfoPanel();
            resetLastTypingEvent();
            props.selected_profile_actions.profileSelected({
                username: groupInfo.Id,
                conversation_type: 3,
                profileId: null,
                displayName: groupInfo.Name,
                conversation_id: null,
                avatar: null
            });

            props.chat_actions.addNewChatUser({ username: groupInfo.Id }); */
        });
    }, []);

    const escapeRegexCharacters = str => {
        return str.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
    };

    const searchUsers = (searchText) => {
        try {
            setUserName(searchText);
            if (searchText) {
                const escapedValue = escapeRegexCharacters(searchText.trim());
                if (escapedValue === "") {
                    return [];
                }
                const regex = new RegExp(escapedValue, "i");

                const filtered = groupContactList.filter(language => regex.test(language.fullName));
                setFilteredContactList(filtered);
                return;
            }
            setFilteredContactList(groupContactList);
        } catch (error) {
            console.error("CreateNewGroup", "searchUsers", error);
            logger.error("CreateNewGroup", "searchUsers", error.message);
        }
    }

    function onPressChatFilterIcon(_filter) {

        if (Object.keys(participators).length < 1) {
            message.error("Please select 1 or more participants", 5);
        } else {
            SetGroupNameActive(true);
            SetPanelTitle("Create New Group");
        }

    }

    function onPressCreateGroup(_filter) {
        try {
            if (groupName) {
                setCreateGroup(true);
                let user = auth.getUserId();
                liveChatClient.createGroup(
                    groupName,
                    user,
                    Object.keys(participators),
                    groupName
                );

            } else {
                message.error("Please specify a name for the group", 5);
            }

        } catch (error) {
            console.error("CreateNewGroup", "onPressCreateGroup", error);
            logger.error("CreateNewGroup", "onPressCreateGroup", error.message);
            setCreateGroup(false);
        }
    }

    function onPressGoBack(_filter) {
        SetGroupNameActive(false);
        SetPanelTitle("Add group participants");
    }

    const onSelectUser = (selected, user) => {
        try {
            let temp = { ...participators };
            if (selected) {
                user.selected = selected;
                temp[user.userId] = user;
            } else {
                delete temp[user.userId];
            }
            setParticipators({ ...temp })
        } catch (error) {
            console.error("CreateNewGroup", "onSelectUser", error);
            logger.error("CreateNewGroup", "onSelectUser", error.message);
        }
    };

    return (
        <>
            <section className={`action-panel-m-wrapper`}>
                <div className={`apm-header-wrapper`}>
                    <div className={`apm-top-row d-flex justify-content-between`}>
                        {!groupNameActive && (
                            <div className={"apm-title"}>
                                <PanelTitle title={panelTitle} />
                            </div>
                        )}
                        {groupNameActive && (
                            <div className={"apm-title d-flex"}>
                                <div className={"group-back"}>
                                    <MenuIcon
                                        size={"lg"}
                                        icon={<ArrowLeft />}
                                        onClick={onPressGoBack}
                                    />
                                </div>
                                <PanelTitle title={panelTitle} />
                            </div>
                        )}
                    </div>
                    {!groupNameActive && (
                        <div className={`chat-search-wrapper`}>
                            <SearchTextBox id={"group_contact_search"} placeholder={"Search Contact by Name…"} textValue={userName}
                                onChangeHandler={(text) => {
                                    searchUsers(text);
                                }} />
                        </div>
                    )}
                </div>

                <div className={"apm-chat-body"}>
                    <div
                        className={"group-user-wrapper"}
                        style={{ height: props.viewPointHeight - 200 }}
                    >
                        <Scrollbars
                            autoHide
                            thumbMinSize={0}
                            style={{ height: props.viewPointHeight - 200 }}
                        >
                            {!groupNameActive && (
                                <>
                                    {Object.values(filteredContactList).map(item => {
                                        return (
                                            <SelectUser key={item.userId}
                                                layout={'UserGroupProfile'}
                                                src={item.avatar}
                                                selected={participators[item.userId]}
                                                icon={<Check />}
                                                fullName={item.fullName}
                                                user={item}
                                                onSelectUser={onSelectUser}
                                            />
                                        );
                                    })}
                                    {/* <SelectUser
                    src={"https://source.unsplash.com/wmbbbU0LUG8/150x150"}
                    selected={false}
                    icon={<Check />}
                    fullName={"Clayton Ballard"}
                  />
                  <SelectUser
                    src={"https://source.unsplash.com/w1XuHvzcWoI/150x150"}
                    selected={false}
                    userType={"Customer"}
                    icon={<Check />}
                    fullName={"Caleb Allison"}
                  />
                  <SelectUser
                    src={"https://source.unsplash.com/HKuhUKNWFtY/150x150"}
                    selected={true}
                    icon={<Check />}
                    fullName={"Rose Bishop"}
                  />
                  <SelectUser
                    src={"https://source.unsplash.com/CjI5Zg-qzEo/150x150"}
                    selected={false}
                    fullName={"Daniel Stewart"}
                  />
                  <SelectUser
                    src={null}
                    selected={true}
                    icon={<Check />}
                    fullName={"Carol Schultz"}
                  />
                  <SelectUser
                    src={"https://source.unsplash.com/7ip-IOC2yvA/150x150"}
                    selected={true}
                    icon={<Check />}
                    userType={"Customer"}
                    fullName={"Peter Harper"}
                  />
                  <SelectUser
                    src={"https://source.unsplash.com/jmURdhtm7Ng/150x150"}
                    selected={true}
                    icon={<Check />}
                    userType={"Agent"}
                    fullName={"Sean Jensen"}
                  />
                  <SelectUser
                    src={"https://source.unsplash.com/jTSf1xnsoCs/150x150"}
                    selected={false}
                    icon={<Check />}
                    userType={"Agent"}
                    fullName={"Elizabeth Stanley"}
                  />
                  <SelectUser
                    src={null}
                    selected={false}
                    icon={<Check />}
                    userType={"Agent"}
                    fullName={"Sean Jensen"}
                  /> */}
                                </>
                            )}

                            {groupNameActive && (
                                <>
                                    <div className={`group-image-upload`}>
                                        {/*<img src={'https://source.unsplash.com/V2OyJtFqEtY'}/>*/}
                                        <div className={"upload-img"}>
                                            <img src={require("../../../assets/img/defaultGroup.svg")}
                                                alt="User Avatar" />
                                        </div>
                                        <div className={"title-upload"}>Add group image</div>
                                    </div>

                                    <div className={"group-name-wrapper"}>
                                        <TextBoxWithLabel
                                            style={"default"}
                                            labelStyle={"sm"}
                                            labelText={"Group Name"}
                                            placeholder={"group name type here..."}
                                            required={true}
                                            type={"text"}
                                            disable={false}
                                            value={groupName}
                                            onChange={e => setGroupName(e.target.value)}
                                        />
                                    </div>
                                    <div
                                        className={
                                            "footer-btn-wrapper none-border  d-flex justify-content-center mt-2"
                                        }>
                                        {/*---- group create ----*/}
                                        {!isCreateGroup &&
                                            <MenuIcon
                                                icon={<Check />}
                                                size={"highlight-lg"}
                                                onClick={onPressCreateGroup}
                                            />}

                                        {/*---- group create loading ----*/}
                                        <>
                                            {isCreateGroup &&
                                                <div className={'pre-loader-login'}>
                                                    <div className="loader9" />
                                                </div>
                                            }
                                        </>
                                    </div>
                                </>
                            )}
                        </Scrollbars>
                    </div>
                    {!groupNameActive && (
                        <div className={"footer-btn-wrapper d-flex justify-content-center"}>
                            <MenuIcon
                                icon={<ArrowRight />}
                                size={"highlight-lg"}
                                onClick={onPressChatFilterIcon}
                            />
                        </div>
                    )}
                </div>
            </section>
        </>
    );
};

const mapStateToProps = state => {


    return { selectedProfile: state.selected_profile, colleagues: state.team };
};

function mapDispatchToProps(dispatch) {
    return {
        group_actions: bindActionCreators(groupActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateNewGroup);
