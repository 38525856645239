import React, {useState, useEffect} from "react";

import './styles/profile-details.scss'

import {__APP_CUST_DYNAMIC_INFO_COUNT__} from "../../../config/baseUrls";

const ProfileDetails = ({
                            fullName,
                            lstUpdate,
                            src,
                            conversation_type,
                            userData, custInfoData
                        }) => {
    const [readMoreStatus, SetReadMoreStatus] = useState(false);

    const intials = fullName ? fullName.split(' ').map(fullName => fullName[0]).join('').toUpperCase() : "";

    let count = 0;

    let customerDynamicInfo = Object.keys(custInfoData).reduce(
        (acc, key) => {
            if (userData && userData[key]) {
                if (count < __APP_CUST_DYNAMIC_INFO_COUNT__) {
                    acc.default.push(
                        <div className="d-flex flex-column dynamic-info-wrapper">
                            <div className="u-title text-left"> {custInfoData[key]} :</div>
                            <div className="pl-1 u-value text-left"> {Boolean(userData[key])
                                ? userData[key].toString() : userData[key]}</div>
                        </div>
                    );
                } else {
                    /* acc.more.push(
                      <tr id={key} className={"dynamic-info"}>
                        <td>{custInfoData[key]}</td>
                        <td style={{ paddingLeft: "12px" }}>
                          {Boolean(userData[key])
                            ? userData[key].toString()
                            : userData[key]}
                        </td>
                      </tr>
                    ); */
                }
                count++;
            }
            return acc;
        },
        {default: [], more: []}
    );

    const readMore = () => {
        SetReadMoreStatus(true);
    };
    const lessMore = () => {
        SetReadMoreStatus(false);
    };
    return (
        <div className={'d-flex flex-column'}>
            {userData && (
                //additional data
                <div className={"collapse-wrapper w-100"}>
                    {customerDynamicInfo.default}
                    {readMoreStatus === true && customerDynamicInfo.more}

                    {readMoreStatus === false && customerDynamicInfo.more.length > 0 && (
                        <div onClick={readMore} className={"read-more  d-flex"} id="myBtn">
                            <div className={"text more"}>More</div>
                        </div>
                    )}

                    {readMoreStatus === true && customerDynamicInfo.more.length > 0 && (
                        <div
                            onClick={lessMore}
                            className={"read-more less d-flex"}
                            id="myBtn"
                        >
                            <div className={"text less"}>Less</div>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

// CallAlert.propTypes = {
//     status: PropTypes.string.isRequired,
// };

ProfileDetails.defaultProps = {};

export default ProfileDetails;
