import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import isUrl from "is-url";
import * as chatActions from "../../../redux/actions/chatActions";
import * as ChatService from "../../../service/chatService";
import * as fileService from "../../../service/fileService";
import { getUserId, getUser, getDisplayName, getWorkspace } from "../../../utils";
import { Send, Paperclip, Phone, Mic, Smile, Video, X, MessageSquare, Image, Headphones, Disc } from "react-feather";
import { message, Tooltip, Popover } from 'antd';
import { videoTypes, documentTypes, imageTypes, audioTypes } from "../../../config/json/fileTypeMap";
import './styles/chat-text-area.scss';
import './styles/chat-text-area-responsive.scss';
import {
    MenuIcon,
    OutGoingCall,
    VoiceRecorder,
    EmojiPanel
} from "../../common";
import uuid from "uuid";
import {
    setKeyPressTime,
    resetKeyPressTime
} from "../../../service/typingDetector";

import * as eventEmitter from "../../../utils/eventEmitter";
import navigationRestrictions from "../../../config/navigationRestrictions";
import { __APP_DEFAULT_WORKSPACE__ } from "../../../config/baseUrls";
import * as uiPanelActions from "../../../redux/actions/uiPanelActions";
import { filterProfanity } from "../../../utils/profanityFilter";
import { notification } from "antd/lib/index";
import * as chatTextPanelActions from "../../../redux/actions/chatTextPanelActions";

let prevSelectedProfile = null;

const ChatTextWrapper = (props) => {

    const [chatMessage, setChatMessage] = useState("");

    let replyMessage = props.text_panel_user_info && props.text_panel_user_info.reply_message ? props.text_panel_user_info.reply_message : props.replyMessage;

    useEffect(() => {
        eventEmitter.subscribeToEvents("add_message", message => {

            setChatMessage((prevState => {
                return `${prevState} ${message}`;

            }))

            document.getElementById("mobile_chat_text_wrapper_main_window").focus();

        }, "mobile_chat_text_wrapper");
    }, []);

    if (props.selected_profile && props.selected_profile.username) {
        if (prevSelectedProfile && prevSelectedProfile.username !== props.selected_profile.username) {
            //reset
            resetKeyPressTime(prevSelectedProfile.conversation_type,
                getUserId(),
                prevSelectedProfile.username, prevSelectedProfile.InteractionId, prevSelectedProfile.isACW);
            setChatMessage(props.text_panel_user_info ? props.text_panel_user_info.message : "");
            if (props.text_panel_user_info && props.text_panel_user_info.reply_message) {
                props.onClickReplayMessage(props.text_panel_user_info.reply_message)
            } else {
                props.onClickCloseReplayMessage();
                //props.chat_text_panel_actions.removeTextPanelInfo(props.selected_profile.username);
            }
        }

        prevSelectedProfile = props.selected_profile;
    }

    let onChangeHandler = event => {
        if (props.selected_profile && props.selected_profile.username) {
            setChatMessage(event.target.value);
        }
    };

    const sendMessageClick = () => {
        if (chatMessage && chatMessage.match(/^ *$/) === null) {
            resetKeyPressTime(props.selected_profile.conversation_type,
                getUserId(),
                props.selected_profile.username, props.selected_profile.InteractionId, props.selected_profile.isACW);
            //call socket

            let uniqueId = uuid();

            let msg = {
                v: 1,
                mid: uniqueId,
                sid: uniqueId,
                message_type: "text/html",
                from: {
                    id: getUserId(),
                    name: getDisplayName()
                },
                to: {
                    id: props.selected_profile.username,
                    name: props.selected_profile.username
                },
                created_at: Date.now(),
                message_content: chatMessage,
                other: null
            }

            msg.conversation_type = props.selected_profile.conversation_type;

            if (replyMessage) {
                if (replyMessage.replyMessage) {
                    delete replyMessage.replyMessage
                }
                msg.message_action = 2;
                msg.related_message = {
                    message_action: 2,
                    ...replyMessage
                }
            }

            let profanity = filterProfanity(chatMessage);

            if (profanity.isProfane) {
                notification.error({
                    message: `Message Blocked`,
                    description:
                        `Your message : "${profanity.message}", is flagged by profanity filter and cannot be sent`,
                    placement: 'bottomRight',
                    style: {
                        backgroundColor: "#ff751a",
                        color: "White"
                    },
                });
                ChatService.sendProfanityMessage(chatMessage, props.selected_profile.InteractionId);

            } else {

                if (isUrl(chatMessage)) {
                    msg.message_type = "html/link";
                    msg.media = [{ content_url: chatMessage, mimeType: "html/link", created_at: Date.now() }];

                    let ws = getWorkspace();

                    ws = ws ? ws : __APP_DEFAULT_WORKSPACE__;

                    fileService.saveLink(msg.conversation_type === 3 ? "" : msg.from.id, msg.to.id, chatMessage, ws, uniqueId);
                }

                ChatService.sendChatMessage(
                    props.selected_profile.conversation_type,
                    "text/html",
                    props.selected_profile.username,
                    msg,
                    false,
                    props.selected_profile.InteractionId
                );

                props.chat_actions.sendNewMessageSuccess(msg);
                setChatMessage("");
                eventEmitter.fireEvent("scrollbar", { scrollbar_status: "down" });
                props.onClickCloseReplayMessage();
                props.chat_text_panel_actions.removeTextPanelInfo(props.selected_profile.username);

            }


        }
    }

    let keyPressHandler = event => {
        if (props.selected_profile && props.selected_profile.username) {
            setKeyPressTime(
                props.selected_profile.conversation_type,
                getUserId(),
                props.selected_profile.username,
                props.selected_profile.InteractionId
            );
            if (event.key === "Enter" && chatMessage && chatMessage.match(/^ *$/) === null) {
                resetKeyPressTime(props.selected_profile.conversation_type,
                    getUserId(),
                    props.selected_profile.username, props.selected_profile.InteractionId, props.selected_profile.isACW);

                let uniqueId = uuid();

                let msg = {
                    v: 1,
                    mid: uniqueId,
                    sid: uniqueId,
                    message_type: "text/html",
                    from: {
                        id: getUserId(),
                        name: getDisplayName()
                    },
                    to: {
                        id: props.selected_profile.username,
                        name: props.selected_profile.username
                    },
                    created_at: Date.now(),
                    message_content: chatMessage,
                    other: null
                }

                msg.conversation_type = props.selected_profile.conversation_type;

                if (replyMessage) {
                    if (replyMessage.replyMessage) {
                        delete replyMessage.replyMessage
                    }
                    msg.message_action = 2;
                    msg.related_message = {
                        message_action: 2,
                        ...replyMessage
                    }
                }

                let profanity = filterProfanity(chatMessage);

                if (profanity.isProfane) {
                    notification.error({
                        message: `Message Blocked`,
                        description:
                            `Your message : "${profanity.message}", is flagged by profanity filter and cannot be sent`,
                        placement: 'bottomRight',
                        style: {
                            backgroundColor: "#ff751a",
                            color: "White"
                        },
                    });
                    ChatService.sendProfanityMessage(chatMessage, props.selected_profile.InteractionId);

                } else {

                    if (isUrl(chatMessage)) {
                        msg.message_type = "html/link";
                        msg.media = [{ content_url: chatMessage, mimeType: "html/link", created_at: Date.now() }];

                        let ws = getWorkspace();

                        ws = ws ? ws : __APP_DEFAULT_WORKSPACE__;

                        fileService.saveLink(msg.conversation_type === 3 ? "" : msg.from.id, msg.to.id, chatMessage, ws, uniqueId);
                    }

                    ChatService.sendChatMessage(
                        props.selected_profile.conversation_type,
                        "text/html",
                        props.selected_profile.username,
                        msg,
                        false,
                        props.selected_profile.InteractionId
                    );

                    props.chat_actions.sendNewMessageSuccess(msg);
                    setChatMessage("");
                    eventEmitter.fireEvent("scrollbar", { scrollbar_status: "down" });
                    props.onClickCloseReplayMessage();
                    props.chat_text_panel_actions.removeTextPanelInfo(props.selected_profile.username);

                }


            }
        }
    };

    let fromTeamMember = null;
    let fullName = "";

    if (replyMessage) {

        document.getElementById("mobile_chat_text_wrapper_main_window").focus();
        if (props.team) {
            let from = replyMessage.from && replyMessage.from.id ? replyMessage.from.id : replyMessage.sender_id;
            if (from) {
                fromTeamMember = props.team[from];
            }
        }

        fullName = fromTeamMember && fromTeamMember.firstName ? `${fromTeamMember.firstName} ${fromTeamMember.lastName ? fromTeamMember.lastName : ""}` : replyMessage.from.id;

    }

    let isSendIconEnabled = chatMessage && chatMessage.match(/^ *$/) === null && props.selected_profile && props.selected_profile.username


    console.log(replyMessage);
    console.log("=======================");

    let onEmojiClick = emoji => {

        if (props.selected_profile && props.selected_profile.username) {

            setChatMessage(prevMessage => prevMessage + emoji);
            document.getElementById("mobile_chat_text_wrapper_main_window").focus();
        }

    };

    const emojiPanel = (
        <>
            <EmojiPanel
                onEmojiClick={onEmojiClick}
            />
        </>
    );

    const allMediaTypes = [...imageTypes, ...videoTypes, ...audioTypes];

    const contentDocument = (
        <div className={"document-select-wrapper"}>
            {navigationRestrictions.media.uploader.media_upload.active && <input
                id="selectImage"
                accept={[...imageTypes, ...videoTypes, ...audioTypes].join(",")}
                hidden
                multiple
                type="file"
                name="file"
                onChange={media => {
                    let fileObj = {};

                    for (const file of Array.from(media.target.files)) {
                        if (allMediaTypes.indexOf(file.type) === -1) {
                            message.error(`Unsupported file format`);
                            return;
                        }

                        let fileId = uuid();
                        fileObj[fileId] = {
                            file_id: fileId,
                            file: file,
                            name: file.name,
                            size: file.size,
                            type: file.type,
                            caption: ""
                        };
                    }
                    props.ui_panel_actions.openMediaUpload(fileObj, "MEDIA");
                    //props.onUploadFirstMedia(media);
                    media.target.value = null;
                }}
            />}
            {navigationRestrictions.media.uploader.media_upload.active && <div
                className={"document-row"}
                onClick={() => {
                    document.getElementById("selectImage").click();
                    //props.onClickOpenMediaUpload();
                }}
            >
                Photos & Video
            </div>}
            {/*<div className={"document-row"}>Camera</div>*/}
            {navigationRestrictions.media.uploader.document_upload.active && <input
                id="selectDocument"
                accept="application/pdf"
                hidden
                multiple
                type="file"
                name="file"
                onChange={media => {

                    let fileObj = {};

                    for (const file of Array.from(media.target.files)) {
                        if (documentTypes.indexOf(file.type) === -1) {
                            message.error(`Unsupported file format`);
                            return;
                        }
                        let fileId = uuid();
                        fileObj[fileId] = {
                            file_id: fileId,
                            file: file,
                            name: file.name,
                            size: file.size,
                            type: file.type,
                            caption: ""
                        };
                    }
                    props.ui_panel_actions.openMediaUpload(fileObj, "DOCUMENT");
                    //props.onUploadFirstMedia(file);
                    media.target.value = null;
                }}
            />}
            {navigationRestrictions.media.uploader.document_upload.active && <div
                className={"document-row"}
                onClick={() => {
                    document.getElementById("selectDocument").click();
                    //props.onClickOpenMediaUpload();
                }}
            >
                Document
            </div>}
        </div>
    );
    const getFileIcon = (type) => {

        if (type === "application/msword" || type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document") {
            return require("../../../assets/img/docx.svg");
        } else if (type === "application/vnd.ms-excel" || type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
            return require("../../../assets/img/xls.svg");
        } else if (type === "text/csv") {
            return require("../../../assets/img/csv.svg");
        } else if (type === "text/plain") {
            return require("../../../assets/img/textSVG.svg");
        } else {
            return require("../../../assets/img/pdf.svg");
        }

    }

    return (<>

        <div className={`typing-action-wrapper mobile d-flex flex-column`}
            style={{
                width: props.viewPointWidth - 390,
                height: props.isEnableReplyMessage ? 170 : 80,
                pointerEvents: (props.selected_profile && props.selected_profile.isACW === true ? "none" : "auto")
            }}>
            <>
                {/*======= chat replay message area ========= */}
                {props.isEnableReplyMessage &&
                    <div className={'d-flex'}
                        style={{ width: props.viewPointWidth - (props.isOpenInfoPanel ? 700 : 400) }}>
                        <div className={'replay-message-tag d-flex flex-column'}>
                            <div className={'full-name'}>
                                {fullName}
                            </div>
                            <div className={'tag-message d-flex '}>
                                <div className={'message-icon d-flex'}>
                                    {(replyMessage.message_type === "text/html" || replyMessage.message_type === "html/link") &&
                                        <MessageSquare />
                                    }

                                    {documentTypes.indexOf(replyMessage.message_type) > -1 &&
                                        <div className={'replay-msg-doc-wrp'}>
                                            <img src={getFileIcon(replyMessage.message_type)}
                                                alt="document" />
                                        </div>
                                    }
                                    {imageTypes.indexOf(replyMessage.message_type) > -1 &&
                                        <Image />
                                    }
                                    {videoTypes.indexOf(replyMessage.message_type) > -1 &&
                                        <Video />
                                    }
                                    {audioTypes.indexOf(replyMessage.message_type) > -1 &&
                                        <Headphones />
                                    }
                                </div>
                                <div className={'reply-text'}>
                                    {replyMessage.message_type === "text/html" &&
                                        <>
                                            {replyMessage.message_content}
                                        </>
                                    }

                                    {replyMessage.message_type === "html/link" &&
                                        <>
                                            {replyMessage.media && replyMessage.media[0] ? replyMessage.media[0].content_url : ""}
                                        </>
                                    }

                                    {audioTypes.indexOf(replyMessage.message_type) > -1 &&
                                        <>
                                            Audio file
                                    </>
                                    }

                                    {videoTypes.indexOf(replyMessage.message_type) > -1 &&
                                        <>
                                            Video file
                                    </>
                                    }
                                    {imageTypes.indexOf(replyMessage.message_type) > -1 &&
                                        <>
                                            Image
                                    </>
                                    }
                                    {documentTypes.indexOf(replyMessage.message_type) > -1 &&
                                        <>
                                            Document
                                    </>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className={'close-wrapper'}>
                            <MenuIcon icon={<X />} size={'lg'} onClick={() => {
                                props.onClickCloseReplayMessage();
                                props.chat_text_panel_actions.removeTextPanelInfo(props.selected_profile.username);
                            }} />
                        </div>

                        <div className={'replay-msg-type-icon'}>

                        </div>
                    </div>
                }
            </>

            {/*======= chat typing area ========= */}
            <div
                className={`inner-typing-action d-flex flex-column  ${replyMessage ? 'active-reply' : 'message-only'}`}>
                <>

                    {<div className={`type-area-wrapper d-flex align-items-center`}>
                        <input id="mobile_chat_text_wrapper_main_window"
                            autoComplete="off"
                            style={{ width: props.viewPointWidth }}
                            value={chatMessage}
                            onChange={onChangeHandler}
                            onKeyPress={keyPressHandler}
                            className={`console-type-area`}
                            placeholder={`Write your message…`}
                            disabled={props.selected_profile.isACW === true ? `disabled` : ""}
                        ></input>
                    </div>}
                </>


                {/*======= chat typing area action wrapper start here========= */}
                <div className={'d-flex justify-content-between'}>
                    {navigationRestrictions.chat.emoji_panel.active && props.selected_profile.isACW !== true &&
                        <Popover content={emojiPanel} trigger="hover">
                            <div className={`left-face-panel d-flex align-items-center`}>
                                <MenuIcon
                                    icon={<Smile />}
                                    size={"sm"}
                                />
                            </div>
                        </Popover>}
                    {/*?960 : 710*/}
                    <div className={`message-handler-wrapper d-flex align-items-center 
                            ${props.isEnableVoiceRecorder ? 'active-voice' : ''}  `}>
                        <div className={`pg-user-action-wrp-right d-flex `}>

                            <Popover placement="top" content={contentDocument} trigger="hover">
                                <div className={`pg-header-icon-wrp  flex justify-content-center `}>
                                    <Tooltip placement="topRight" title={'File Upload'}>
                                        <MenuIcon size={"sm-space"} icon={<Paperclip />} />
                                    </Tooltip>
                                </div>
                            </Popover>


                            {props.isEnableVoiceRecorder &&
                                <>
                                    <VoiceRecorder
                                        onClickCloseVoiceRecorder={props.onClickCloseVoiceRecorder} />
                                </>}

                            {!props.isEnableVoiceRecorder &&
                                <div className={`d-flex justify-content-between`}>
                                    {isSendIconEnabled &&
                                        <Tooltip placement="topRight" title={'Send Message'}>
                                            <div
                                                className={`pg-header-icon-wrp active flex justify-content-end justify-content-center ml-5`}>
                                                <MenuIcon
                                                    size={"sm-space"}
                                                    status={"active"}
                                                    onClick={sendMessageClick}
                                                    icon={<Send />}
                                                />
                                            </div>
                                        </Tooltip>}

                                    {!isSendIconEnabled &&
                                        <>
                                            {navigationRestrictions.chat.video_recorder.active &&
                                                <Tooltip placement="topRight" title={'video recorder'}>
                                                    <div className={`pg-header-icon-wrp  flex justify-content-center `}>
                                                        <MenuIcon
                                                            onClick={props.onClickVideoRecorder}
                                                            size={"sm-space"}
                                                            icon={<Disc />} />
                                                    </div>
                                                </Tooltip>}
                                            {navigationRestrictions.chat.audio_recorder.active &&
                                                <Tooltip placement="topRight" title={'audio recorder'}>
                                                    <div className={`pg-header-icon-wrp   flex justify-content-center`}>
                                                        <MenuIcon
                                                            size={"sm-space"}
                                                            status={""}
                                                            onClick={props.onClickVoiceRecorder}
                                                            icon={<Mic />}
                                                        />
                                                    </div>
                                                </Tooltip>}
                                        </>
                                    }
                                </div>
                            }
                        </div>

                    </div>
                </div>
            </div>

        </div>

    </>
    )

};

ChatTextWrapper.propTypes = {
    selected_profile: PropTypes.object.isRequired,
    chat_actions: PropTypes.object.isRequired,
    ui_panel_actions: PropTypes.object.isRequired
};

function mapStateToProps(state) {

    let mapState = {
        selected_profile: state.selected_profile,
        team: state.team,
        ui_panel_header_data: state.ui_panel_data.header_panel
    };

    if (state.selected_profile && state.selected_profile.username) {
        mapState.text_panel_user_info = state.chat_text_panel_info[state.selected_profile.username];
    }
    return mapState;
}

function mapDispatchToProps(dispatch) {
    return {
        chat_actions: bindActionCreators(chatActions, dispatch),
        ui_panel_actions: bindActionCreators(uiPanelActions, dispatch),
        chat_text_panel_actions: bindActionCreators(chatTextPanelActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ChatTextWrapper);
