import { GenerateUUID } from '../common.js';
import { Logger } from './../logger.js';

export class captchaApi {
    livechatReference;
    captchaServiceUrl;
    requestId;

    constructor (config, livechatReference) {
        this.livechatReference = livechatReference;
        this.captchaServiceUrl = config ? config.captchaServiceUrl : "";
        this.requestId = "";
    }

    getCaptcha = (reset = false) => {
        if (this.captchaServiceUrl == null || this.captchaServiceUrl === "") {
            Logger.error(`Captcha URL has not been set`);
            return;
        }

        if (reset === false)
            this.requestId = GenerateUUID();

        let url = `${this.captchaServiceUrl}/ws-chat-proxy/captcha?request-id=${this.requestId}&reset=${reset}`;
        Logger.info(`Generated Captcha URL: ${url}`);
        return url;
    }

    validateCaptcha = (captchaText, userId = "") => {
        if (captchaText == null || captchaText === "") {
            Logger.error(`Captcha Text has not been set`);
            return;
        }

        if (userId === "")
            userId = GenerateUUID();

        Logger.info(`Validating entered captcha text: ${captchaText}`);

        fetch(`${this.captchaServiceUrl}/ws-chat-proxy/captcha`, {
            method: "POST",
            headers: {
                "content-type": `application/json`
            },
            body: JSON.stringify({
                userId: userId,
                requestId: this.requestId,
                enteredCaptcha: captchaText
            })
        }).then(response => response.json())
            .then(data => {
                if (data) {
                    if (data.status === 0)
                        this.livechatReference.onCaptchaSuccess(data.data);
                    else
                        this.livechatReference.onCaptchaFailed(data.error);
                }
                else {
                    Logger.error(`Error occurred`);
                    this.livechatReference.onCaptchaFailed(new Error("Error occurred"));
                }
            })
            .catch(error => {
                Logger.error(`Error occurred: ${error}`, error);
                this.livechatReference.onCaptchaFailed(error);
            });
    }
}