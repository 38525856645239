import React, {useEffect} from "react";
import {MenuIcon} from "../index";
import {MoreVertical} from "react-feather";

const ParticipatorAudio = ({
                               id,
                               fullName,
                               active, avatar,
                               mediaStream,
                               remoteStreams,
                               onClick
                           }) => {
    const audioRef = React.createRef();
    // useEffect(() => {
    //     try {
    //         audioRef.current.srcObject = mediaStream;
    //     } catch (ex) {
    //         console.error(ex);
    //     }
    // }, []);

    console.log("--------- audio call -----");
    console.log(remoteStreams);

    return (
        <>
            <div className={'active-call-inner-wrapper d-flex justify-content-between  '}>
                <div className={'d-flex align-items-center justify-content-center'}>
                    <div className={'active-caller-img-wrp'}>
                        <img
                            src={require("../../../assets/img/defaultUser.svg")}
                            alt="User Avatar"
                        />
                    </div>
                    <div className={'active-caller-name d-flex flex-column text-left '}>
                        <div className={'title'}>
                            Caller Name
                        </div>
                        <div className={'caller-full-name'}>

                        </div>
                    </div>
                </div>
                <div className={`d-flex align-items-center justify-content-center`}>
                    <div className={'more-call-details-wrp'}>
                        <MenuIcon
                            size={"transparent-bg"}
                            status={null}
                            icon={<MoreVertical/>}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

ParticipatorAudio.defaultProps = {};

export default ParticipatorAudio;
