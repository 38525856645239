import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Dropzone from "react-dropzone";
import { Modal, Button } from "antd";
import Webcam from "react-webcam";
import { Camera, RefreshCcw } from "react-feather";
import ImageUploader from "react-images-upload";

const videoConstraints = {
  width: "100%",
  height: "100%",
  facingMode: "user",
};

const ProfileAvatarUploader = ({ src, editProfile, onCaptured }) => {
  const [visible, setVisible] = useState(false);
  const [imageSrc, setImageSrc] = useState();
  const webcamRef = React.useRef(null);

  const capture = React.useCallback(() => {
    const imageSrcc = webcamRef.current.getScreenshot();
    setVisible(false);
    setImageSrc(imageSrcc);
    onCaptured(imageSrcc);
  }, [webcamRef]);

  const showModal = () => {
    if (!editProfile) return;
    setVisible(true);
  };

  const handleCancel = (e) => {
    console.log(e);
    setVisible(false);
    onCaptured(null);
  };

  const onDrop = (pictureFiles, pictureDataURLs) => {
    setImageSrc(pictureDataURLs[0]);
    onCaptured(pictureDataURLs[0]);
  };

  return (
    <>
      <div className={"profile-upload-wrapper d-flex align-items-center"}>
        <div className={"current-img"}>
          <img
            alt=""
            src={
              imageSrc
                ? imageSrc
                : src
                ? src
                : require("../../../assets/img/defaultUser.svg")
            }
          />
        </div>
        <div className={"upload-wrapper"}>
          {/*  <section>
            <div className={"d-flex flex-column "}>
              <p className={"upload-text"}></p>
              <p className={"file-format"}>
                .jpeg", ".jpg", ".png", ".svg", ".gif
              </p>
            </div>
          </section> */}

          <section>
            <div class="row">
              {editProfile && (
                <div class="col-sm">
                  <ImageUploader
                    style={{ width: "75px" }}
                    withIcon={false}
                    buttonText="+"
                    withLabel={false}
                    singleImage={true}
                    onChange={onDrop}
                    imgExtension={[".jpeg", ".jpg", ".png", ".svg", ".gif"]}
                    maxFileSize={5242880}
                  />
                </div>
              )}
              <div class="col-sm">
                <div class="red-square">
                  <Camera size={35} color="red" onClick={showModal} />
                </div>
              </div>
              {imageSrc && (
                <div class="col-sm">
                  <div class="red-square">
                    <RefreshCcw
                      size={35}
                      color="red"
                      onClick={() => {onCaptured(null);setImageSrc(null)}}
                    />
                  </div>
                </div>
              )}
            </div>

            {visible && (
              <Modal
                title=""
                visible={visible}
                onOk={capture}
                onCancel={handleCancel}
                okText={"Capture photo"}
                okType={"danger"}
                cancelText={"Exit"}
                closable={false}
              >
                <Webcam
                  audio={false}
                  ref={webcamRef}
                  screenshotFormat="image/jpeg"
                  videoConstraints={videoConstraints}
                  height={"100%"}
                  width={"100%"}
                />
              </Modal>
            )}
          </section>
        </div>
      </div>
    </>
  );
};

ProfileAvatarUploader.defaultProps = {
  type: "btn-primary",
  size: "btn-sm",
  disabled: false,
};

export default ProfileAvatarUploader;
