//Team
export const TEAM_MEMBERS_LOAD_SUCCESS = "TEAM_MEMBERS_LOAD_SUCCESS";
export const TEAM_MEMBERS_LOAD_FAIL = "TEAM_MEMBERS_LOAD_FAIL";
export const NEW_CONTACT_ADDED = "NEW_CONTACT_ADDED";
export const SET_AVATAR_URL = "SET_AVATAR_URL";
export const REMOVE_CONTACT = "REMOVE_CONTACT";
export const SET_CONTACT_ACW_STATUS = "SET_CONTACT_ACW_STATUS";

//Chat
export const ADD_NEW_CHAT_USER = "ADD_NEW_CHAT_USER";
export const UPDATE_MESSAGE = "UPDATE_MESSAGE";
export const UPDATE_UPLOAD_FAIL_MESSAGE = "UPDATE_UPLOAD_FAIL_MESSAGE";
export const RESET_MESSAGES = "RESET_MESSAGES";
export const RECEIVE_NEW_MESSAGE = "RECEIVE_NEW_MESSAGE";
export const SEND_NEW_MESSAGE_SUCCESS = "SEND_NEW_MESSAGE";
export const SEND_SEEN_STATUS_SUCCESS = "SEND_SEEN_STATUS_SUCCESS";
export const SET_REMOTE_PARTY_SEEN = "SET_REMOTE_PARTY_SEEN";
export const RESEND_MESSAGE = "RESEND_MESSAGE";
export const RESET_UNSEEN_COUNT = "RESET_UNSEEN_COUNT";
export const SET_MESSAGE_DELIVERED_STATE = "SET_MESSAGE_DELIVERED_STATE";
export const SET_MESSAGE_SEND_STATE = "SET_MESSAGE_SEND_STATE";
export const ADD_MISSING_MESSAGES = "ADD_MISSING_MESSAGES";
export const GET_ALL_UNREAD_CHAT_COUNT_SUCCESS =
  "GET_ALL_UNREAD_CHAT_COUNT_SUCCESS";
export const GET_UNREAD_CHATS_SUCCESS = "GET_UNREAD_CHATS_SUCCESS";
export const GET_OLD_CHATS_SUCCESS = "GET_OLD_CHATS_SUCCESS";
export const GET_ALL_CHATS_SUCCESS = "GET_ALL_CHATS_SUCCESS";
export const SEND_CALL_MESSAGE_SUCCESS = "SEND_CALL_MESSAGE_SUCCESS";
export const SET_CALL_STATUS = "SET_CALL_STATUS";
export const CHAT_USER_RENAMED = "CHAT_USER_RENAMED";
export const GET_INITIAL_CHAT_HISTORY_SUCCESS =
  "GET_INITIAL_CHAT_HISTORY_SUCCESS";
export const STAR_UNSTAR_MESSAGE_SUCCESS = "STAR_UNSTAR_MESSAGE_SUCCESS";
export const SET_LAST_SEEN_ANCHOR_POINT = "SET_LAST_SEEN_ANCHOR_POINT";

//Chat Search
export const RESET_SEARCH_MESSAGES = "RESET_SEARCH_MESSAGES";
export const SEARCH_BEFORE_MESSAGES_SUCCESS = "SEARCH_BEFORE_MESSAGES_SUCCESS";
export const SEARCH_AFTER_MESSAGES_SUCCESS = "SEARCH_AFTER_MESSAGES_SUCCESS";
export const SEARCH_INITIAL_MESSAGES_SUCCESS =
  "SEARCH_INITIAL_MESSAGES_SUCCESS";
export const STAR_UNSTAR_SEARCH_MESSAGE_SUCCESS =
  "STAR_UNSTAR_SEARCH_MESSAGE_SUCCESS";

//Typing
export const SET_ACW_TYPING = "SET_ACW_TYPING";
export const DELETE_TYPING = "DELETE_TYPING";
export const SET_TYPING = "SET_TYPING";
export const REMOVE_TYPING = "REMOVE_TYPING";

//Connectivity
export const SET_CONNECTIVITY = "SET_CONNECTIVITY";

//Presence
export const RECEIVE_PRESENCE = "RECEIVE_PRESENCE";
export const GET_ALL_PRESSENCE_SUCCESS = "GET_ALL_PRESSENCE_SUCCESS";
export const SEND_PRESENCE_SUCCESS = "SEND_PRESENCE_SUCCESS";
export const RECEIVE_PRESENCE_UPDATE = "RECEIVE_PRESENCE_UPDATE";
export const EXPIRE_PRESENCE = "EXPIRE_PRESENCE";
export const SET_LOCAL_CONNECTIVITY = "SET_LOCAL_CONNECTIVITY";

//Selected Profile
export const PROFILE_SELECTED = "PROFILE_SELECTED";
export const SET_SELECTED_PROFILE_ACW = "SET_SELECTED_PROFILE_ACW";

//Logged In User
export const USER_LOGGED_IN = "USER_LOGGED_IN";

//Last Seen Trigger
export const LAST_SEEN_FOUND = "LAST_SEEN_FOUND"; // on incoming call
export const INCOMING_CALL = "INCOMING_CALL";
export const CALL_PROGRESS = "CALL_PROGRESS";
export const CALL_ESCALATED = "CALL_ESCALATED";
export const CALL_REMOTE_MUTED = "CALL_REMOTE_MUTED";
export const CALL_REMOTE_UNMUTED = "CALL_REMOTE_UNMUTED";
export const CALL_MUTE_UNMUTE = "CALL_MUTE_UNMUTE";
export const CALL_CONTROL_RESET = "CALL_CONTROL_RESET";
export const CALL_VIDEO_MUTE_UNMUTE = "CALL_VIDEO_MUTE_UNMUTE";
export const PARTICIPATOR_ADDED = "PARTICIPATOR_ADDED";
export const PARTICIPATOR_STATUS = 'PARTICIPATOR_STATUS';
export const VOICE_ACTIVITY = "VOICE_ACTIVITY";
export const SCREEN_SHARE_ADDED = "SCREEN_SHARE_ADDED";
export const SCREEN_SHARE_REMOVED = "SCREEN_SHARE_REMOVED";
export const DEVICE_LIST_LOADED = "DEVICE_LIST_LOADED";
export const VIDEO_DEVICE_CHANGED = "VIDEO_DEVICE_CHANGED";
export const INTERACTION_TRANSFER_REQUEST_ACCEPTED = "INTERACTION_TRANSFER_REQUEST_ACCEPTED";
export const MEDIA_DEVICE_ACCESS_ERROR = "MEDIA_DEVICE_ACCESS_ERROR";

export const WEB_NOTIFICATION = "WEB_NOTIFICATION";
export const PHONE_NOTIFICATION = "PHONE_NOTIFICATION";
export const RESET_NOTIFICATION = "RESET_NOTIFICATION";

//Group
/* export const CUST_CHAT_USERS_LOADED = "CUST_CHAT_USERS_LOADED";
export const ADD_NEW_CLIENT_FOR_CHAT = "ADD_NEW_CLIENT_FOR_CHAT";
export const CUST_USER_RENAMED = "CUST_USER_RENAMED"; */

export const GROUP_CHAT_CREATED = "GROUP_CHAT_CREATED";
export const GROUP_LIST_LOADED = "GROUP_LIST_LOADED";
export const GROUP_CHAT_UPDATED = "GROUP_CHAT_UPDATED";
export const GROUP_ADMIN_ADDED = "GROUP_ADMIN_ADDED";
export const GROUP_ADMIN_DISMISSED = "GROUP_ADMIN_DISMISSED";
export const ADD_PARTICIPANT = "ADD_PARTICIPANT";
export const REMOVE_PARTICIPANT = "REMOVE_PARTICIPANT";
export const GROUP_DELETED = "GROUP_DELETED";
export const RM_GROUP_FOUND = "RM_GROUP_FOUND";
export const RELOAD_RM_USERS = "RELOAD_RM_USERS";

//Templates
export const TEMPLATES_LOADED = "TEMPLATES_LOADED";
export const ADMIN_CANNED_LOADED = "ADMIN_CANNED_LOADED";
export const MY_CANNED_LOADED = "MY_CANNED_LOADED";
export const APPEND_MY_CANNED_MESSAGE = "APPEND_MY_CANNED_MESSAGE";
export const EDIT_MY_CANNED_MESSAGE = "EDIT_MY_CANNED_MESSAGE";
export const DELETE_MY_CANNED_MESSAGE = "DELETE_MY_CANNED_MESSAGE";

//Temp Agents
export const TEMP_AGENTS_LOADED = "TEMP_AGENTS_LOADED";

//Interactions
export const GET_INTERACTIONS_SUCCESS = "GET_INTERACTIONS_SUCCESS";
export const ADD_INTERACTION = "ADD_INTERACTION";
export const SET_INTERACTION_ACW_STATUS = "SET_INTERACTION_ACW_STATUS";
export const ADD_INTERACTION_USER = "ADD_INTERACTION_USER";
export const REMOVE_INTERACTION_USER = "REMOVE_INTERACTION_USER";
export const CLEAR_INTERACTIONS = "CLEAR_INTERACTIONS";
export const REMOVE_INTERACTION = "REMOVE_INTERACTION";
export const SET_CALL_ESCALATION_STATUS = "SET_CALL_ESCALATION_STATUS";

//Socket Status
export const SOCKET_STATUS_CHANGED = "SOCKET_STATUS_CHANGED";

//....//
export const GET_CLIENT_PROFILE_SUCCESS = "GET_CLIENT_PROFILE_SUCCESS";
export const GET_CLIENT_PROFILE_FAIL = "GET_CLIENT_PROFILE_FAIL";
export const GET_USER_WORKSPACE = "GET_USER_WORKSPACE";

//Chat text action Status
export const OPEN_FILE_SHARE = "OPEN_FILE_SHARE";

//audio and video record
export const OPEN_AUDIO_RECORD = "OPEN_AUDIO_RECORD";
export const CLOSE_AUDIO_RECORD = "CLOSE_AUDIO_RECORD";
export const OPEN_VIDEO_RECORD = "OPEN_VIDEO_RECORD";
export const CLOSE_VIDEO_RECORD = "CLOSE_VIDEO_RECORD";

//Starring
export const STAR_UNSTAR_MESSAGE_SUCCESS_ACTION =
  "STAR_UNSTAR_MESSAGE_SUCCESS_ACTION";
export const STARRED_MESSAGE_LIST_LOADED = "STARRED_MESSAGE_LIST_LOADED";
export const STARRED_MESSAGE_LIST_NEXT_PAGE_LOADED =
  "STARRED_MESSAGE_LIST_NEXT_PAGE_LOADED";

//Chat media
export const APPEND_CHAT_MEDIA = "APPEND_CHAT_MEDIA";

//UI PANEL NAVIGATION
export const UI_OPEN_MEDIA_UPLOAD = "UI_OPEN_MEDIA_UPLOAD";
export const UI_CLOSE_MEDIA_UPLOAD = "UI_CLOSE_MEDIA_UPLOAD";
export const UI_HEADER_PANEL_BUTTON_VISIBILITY_CHANGE =
  "UI_HEADER_PANEL_BUTTON_VISIBILITY_CHANGE";
export const UI_ACTION_PANEL_READY_STATE = "UI_ACTION_PANEL_READY_STATE";
export const UI_USER_PROFILE_PANEL_TAB_CHANGE = "UI_USER_PROFILE_PANEL_TAB_CHANGE";


//externalApiActions
export const CUSTOMER_360 = "CUSTOMER_360";
export const OPEN_CUSTOMER_360 = "OPEN_CUSTOMER_360";


//Timeline
export const TIMELINE_LOADED = "TIMELINE_LOADED";
export const RESET_TIMELINE = "RESET_TIMELINE";

//TEXT AREA PANEL ACTIONS
export const SET_TEXTAREA_MESSAGE = "SET_TEXTAREA_MESSAGE";
export const REMOVE_TEXT_PANEL_INFO = "REMOVE_TEXT_PANEL_INFO";

//Meetings
export const MEETINGS_STATUSES_LOADED = "MEETINGS_STATUSES_LOADED";
export const START_MEETING = "START_MEETING";
export const DELETE_MEETING = "DELETE_MEETING";

//Ongoing Meeting
export const CREATE_CURRENT_MEETING = "CREATE_CURRENT_MEETING";
export const REMOVE_CURRENT_MEETING = "REMOVE_CURRENT_MEETING";

//Whiteboard
export const OPEN_WHITEBOARD = "OPEN_WHITEBOARD";
export const MINIMIZE_WHITEBOARD = "MINIMIZE_WHITEBOARD";
export const MAXIMIZE_WHITEBOARD = "MAXIMIZE_WHITEBOARD";
export const DISPOSE_WHITTEBOARD = "DISPOSE_WHITTEBOARD";

//Ext Profile
export const PROFILE_FOUND = "PROFILE_FOUND";

//remove profile
export const PROFILE_REMOVE = "PROFILE_REMOVE";
export const CONTACT_REMOVE = "CONTACT_REMOVE";