import * as types from "../actions/actionTypes";
import produce from "immer";

export const connectivityReducer = produce((draft = true, action) => {
    switch (action.type) {
        case types.SET_CONNECTIVITY:
            {
                draft = action.status;

                return draft;

            }
        default:
            return draft;
    }
});