import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Document, Page, pdfjs } from 'react-pdf';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;


const DocumentPreview = ({ src, type, onDocumentLoadSuccess, pageNumber }) => {
    const [docState, setDocState] = useState({
        numPages: null,
        pageNumber: pageNumber,
    });

    onDocumentLoadSuccess = ({ numPages }) => {
        setDocState({ numPages, pageNumber: 1 });
    }
    return (
        <div className={`media-g-preview-section image`}>
            <Document
                file={src}

                onLoadSuccess={onDocumentLoadSuccess}>
                <Page pageNumber={docState.pageNumber} />
                <p>Page {docState.pageNumber} of {docState.numPages}</p>
            </Document>
        </div>
    )
};

// OutGoingCall.propTypes = {
//     status: PropTypes.string.isRequired,
// };

DocumentPreview.defaultProps = {};

export default DocumentPreview;
