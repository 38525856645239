import React, { useState, useEffect } from "react";
import {
  PanelTitle,
  SubTitle,
  TextBoxWithButton,
  RadioButton,
  TextBoxWithLabel,
  ProfileAvatarUploader,
  MenuIcon,
} from "../../common";
import { X, EyeOff, Eye } from "react-feather";
import * as auth from "../../../utils";
import liveChatClient from "../../../lib";
import { Scrollbars } from "react-custom-scrollbars";
import { message, Spin, Input } from "antd";
import UserService from "../../../service/userService";
import AuthService from "../../../service/authService.js";
import { PresenceService } from "../../../service/presenceService";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import * as presenceActions from "../../../redux/actions/presenceActions";
import presenceStateMap from "../../../config/json/presenseMap";
import { Link } from "react-router-dom";
import phoneEngine from "../phone/phoneEngine/phoneEngineHandler";
import axios from "axios";
import * as fileService from "../../../service/fileService";
import uuid from "uuid/v1";
import { useDispatch } from "react-redux";
import { setAvatar } from "../../../redux/actions/teamActions";
import { useForm } from "react-hook-form";
import { __APP_IDENTITY_PROVIDER__ } from "../../../config/baseUrls";
import * as logger from "../../../lib/logSdk/logger";

let myAvailabilityStatus = "offline";

const userService = new UserService();
const authService = new AuthService();

const MyProfilePanelComponent = (props) => {
  const dispatch = useDispatch();
  const { register, handleSubmit, errors, watch, reset } = useForm();

  const [editPassword, setEditPassword] = useState(false);
  const [currentPassword, setCurrentPassword] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [userProfile, setUserProfile] = useState({});
  const [userOldProfile, setUserOldProfile] = useState({});
  const [editProfile, setEditProfile] = useState(false);
  const [auxBreaks, setAuxBreaks] = useState([]);
  const [presenceStatus, setPresenceStatus] = useState("offline");
  const [availabilityStatus, setAvailabilityStatus] = useState("offline");
  const [imageSrc, setImageSrc] = useState();
  const [cancelTokenSource, setCancelTokenSource] = useState(true);
  const [percentage, setPercentage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [inputType, setInputType] = useState("password");
  const [inputTypeNew, setInputTypeNew] = useState("password");
  const [inputTypeCon, setInputTypeCon] = useState("password");
  const [passwordPolicy] = useState(auth.getPasswordPolicy());

  useEffect(() => {
    try {
      const getUser = async () => {
        const response = await userService.getUser(auth.getUserId());
        setUserProfile(response ? response : {});
      };
      getUser();

      const loadAuxBreaks = async () => {
        const response = await PresenceService.getAuxBreaks();
        if (response) {
          setAuxBreaks(response);
        } else {
          message.error(`Fail To Load Aux break`);
        }
      };
      loadAuxBreaks();

      if (props.presence_user) {
        setPresenceStatus(
          props.presence_user.status ? props.presence_user.status : "offline"
        );
      }
    } catch (error) {
      console.error("MyProfilePanel", "useEffect", error);
      logger.error("MyProfilePanel", "useEffect", error.message);
    }
  }, []);
  const logout = () => {
    try {


      liveChatClient.logoutFromSystem("", "NORMAL", "").then(response => {

        __APP_IDENTITY_PROVIDER__ === "GUARDIAN" &&
          authService.logout(auth.getRefreshToken(), "NORMAL");
        liveChatClient.endSession();
        phoneEngine.phoneFunctions.hangup("caller", "callee");
        auth.userLogOut();
        setTimeout(() => {

          if (__APP_IDENTITY_PROVIDER__ === 'IAM') {

            props.history.push("/logout");

          } else {
            props.history.push("/");
            window.location.reload();
            console.log("LeftSideNavigationComponent", "uireload", "window.location.reload");
          }

        }, 2000);

      }).catch(error => {

        console.error("MyProfilePanel", "logout-logoutFromSystem", error);
        logger.error("MyProfilePanel", "logout-logoutFromSystem", error.message);
        message.error("Error in logout");

      });

    } catch (error) {
      console.error("MyProfilePanel", "logout", error);
      logger.error("MyProfilePanel", "logout", error.message);
      message.error("Error in logout");
    }
  };

  const updatePassword = async (formData) => {
    try {
      /* const reset = () => {
        setPassword("");
        setConfirmPassword("");
        setCurrentPassword("");
      }; */
      if (formData) {
        if (formData.password === formData.currentPassword) {
          reset();
          message.error(
            "Your new password cannot be the same as your old password",
            5
          );
          return;
        }
        if (formData.password === formData.confirmPassword) {
          const response = await authService.updatePassword(
            userProfile.emailId,
            formData.password,
            formData.currentPassword
          );
          if (response.isSuccess) {
            auth.setPassword(formData.password);
            message.success("Successfully updated password", 5);
            reset();
            return;
          }
          message.error(response.message, 5);
        } else {
          message.error(
            "The Password and Confirmation Password Do Not Match",
            5
          );
        }
        reset();
        return;
      }
    } catch (error) {
      console.error("MyProfilePanel", "updatePassword", error);
      logger.error("MyProfilePanel", "updatePassword", error.message);
      message.error(
        "Fail To Update Password",
        5
      );
    }
  };

  const uploadFile = async () => {
    try {
      let fileId = uuid();
      setCancelTokenSource(axios.CancelToken.source());

      const response = await fetch(imageSrc);
      const blob = await response.blob();

      fileService.uploadFile(
        blob,
        fileId,
        fileId,
        cancelTokenSource,
        (fileId, percentage) => {
          setPercentage(percentage);
        },
        async (result) => {
          if (result && result.data.isSuccess) {
            const postdata = {
              ...userProfile,
              avatarURL: result.data.result.streamURL,
            };
            setUserProfile(postdata);
            const uid = auth.getUserId();
            const response = await userService.UpdateProfile(uid, postdata);
            setIsLoading(false);
            if (response.isSuccess) {
              message.success("Successfully Updated Profile", 5);
              dispatch(setAvatar(uid, result.data.result.streamURL));
              return;
            }
            message.error("Fail To Update Profile", 5);
            return;
          } else {
            console.error("MyProfilePanel", "uploadFile", `Fail To Upload File ${fileId}`);
            logger.error("MyProfilePanel", "uploadFile", `Fail To Upload File ${fileId}`);
            message.error(`Unable To Save Profile Picture`, 5);
          }
          setIsLoading(false);
          console.log(
            "signup",
            "uploadFile",
            `profile picture uploaded : ${result.data.result.streamURL}`
          );
        }
      );
    } catch (error) {
      setIsLoading(false);
      message.error(`Unable To Save Profile Picture`, 5);
      console.error("MyProfilePanel", "uploadFile", error);
      logger.error("MyProfilePanel", "uploadFile", error.message);
    }
  };

  const updateProfile = async (val) => {
    try {
      setEditProfile(val);
      setUserOldProfile({ ...userProfile });
      if (!val) {
        setIsLoading(true);
        if (
          userProfile.mobileNumber.length < 7 ||
          userProfile.mobileNumber.length > 15
        ) {
          message.error(
            "Mobile Number must be between 7 and 15 characters long",
            5
          );
          setEditProfile(true);
          return;
        }
        if (imageSrc) {
          uploadFile();
        } else {
          const response = await userService.UpdateProfile(
            auth.getUserId(),
            userProfile
          );
          setIsLoading(false);
          if (response.isSuccess) {
            message.success("Successfully Update Profile", 5);
            return;
          }
          setUserProfile({ ...userOldProfile });
          message.error("Fail To Update Profile", 5);
          return;
        }
      }
    } catch (error) {
      message.error("Fail To Update Profile", 5);
      console.error("MyProfilePanel", "updateProfile", error);
      logger.error("MyProfilePanel", "updateProfile", error.message);
    }
  };

  const setMyPresence = (status) => {
    try {
      setPresenceStatus(status);
      let presenceObj = { userid: auth.getUserId(), status };
      props.presence_actions.sendPresenceSuccess(presenceObj);
      liveChatClient.sendPresenceNotification(JSON.stringify(presenceObj));
    } catch (error) {
      console.error("MyProfilePanel", "setMyPresence", error);
      logger.error("MyProfilePanel", "setMyPresence", error.message);
    }
  };

  const setMyStatusMessage = (msg) => {
    try {
      console.info(`Set Status Message`);
      /*  let presenceObj = { userid: auth.getUserId(), status };
            props.presence_actions.sendPresenceSuccess(presenceObj);
            liveChatClient.sendPresenceNotification(JSON.stringify(presenceObj)); */
    } catch (error) {
      console.error("MyProfilePanel", "setMyStatusMessage", error);
      logger.error("MyProfilePanel", "setMyStatusMessage", error.message);
    }
  };

  const setAvailability = (status) => {
    try {
      //setAvailabilityStatus(status);
      let presenceObj = { userid: auth.getUserId(), status };
      props.presence_actions.sendPresenceSuccess(presenceObj);
      liveChatClient.sendPresenceNotification(JSON.stringify(presenceObj));
    } catch (error) {
      console.error("MyProfilePanel", "setAvailability", error);
      logger.error("MyProfilePanel", "setAvailability", error.message);
    }
  };

  if (props.presence_user) {
    let defaultstatus = props.presence_user.status
      ? props.presence_user.status
      : "offline";
    myAvailabilityStatus = presenceStateMap[defaultstatus]
      ? presenceStateMap[defaultstatus]
      : defaultstatus;
  }

  let sid = auth.getSession();

  return (
    <>
      <Scrollbars
        autoHide
        thumbMinSize={0}
        style={{ height: props.viewPointHeight }}
      >
        <div className={`my-profile-wrapper`}>
          <div className={`apm-header-wrapper`}>
            <div className={`apm-top-row d-flex justify-content-between`}>
              <div className={"apm-title"}>
                <PanelTitle title={"My Profile"} />
                <SubTitle color={"gray"} title={"@worksspacename"} />
              </div>
              <div className={"pg-header-icon-wrp   btn-panel-close"}>
                <Link to="/console">
                  <MenuIcon
                    icon={<X />}
                    onClick={() => props.onPressMenuIcon("chat")}
                    size={"sm"}
                  />
                </Link>
              </div>
            </div>
          </div>

          <div className={`profile-body-wrapper`}>
            {/*====== set status wrapper =======*/}
            <div className={`set-status-wrp`}>
              <SubTitle color={"section-title"} title={"Set a status"} />
              <div className={"text-status-wrp"}>
                <TextBoxWithButton
                  size={"md"}
                  onClick={(value) => setMyStatusMessage(value)}
                />
              </div>
              <div className={"status-list-wrapper d-flex"}>
                {auxBreaks.map((el) => (
                  <div
                    key={el.id}
                    className={"radio-btn-wrp mleft-5"}
                    onClick={() => setAvailability(el.status_code)}
                  >
                    <RadioButton
                      size={"sm"}
                      text={el.status_code}
                      selected={myAvailabilityStatus === el.status_code}
                    />
                  </div>
                ))}
              </div>
            </div>

            {/*/!*====== Availability =======*!/*/}
            {/*<div className={`set-status-wrp p-top25`}>*/}
            {/*    <SubTitle color={"section-title"} title={"Availability"} />*/}
            {/*    <div className={"status-list-wrapper d-flex flex-wrap"}>*/}
            {/*        <div*/}
            {/*            className={"radio-btn-wrp  mleft-5"}*/}
            {/*            onClick={() => setAvailability("busy")}*/}
            {/*        >*/}
            {/*            <RadioButton*/}
            {/*                size={"sm"}*/}
            {/*                text={"Busy"}*/}
            {/*                selected={myAvailabilityStatus === "busy"}*/}
            {/*            />*/}
            {/*        </div>*/}
            {/*        <div*/}
            {/*            className={"radio-btn-wrp mleft-5"}*/}
            {/*            onClick={() => setAvailability("available")}*/}
            {/*        >*/}
            {/*            <RadioButton*/}
            {/*                size={"sm"}*/}
            {/*                text={"Available"}*/}
            {/*                selected={myAvailabilityStatus === "online"}*/}
            {/*            />*/}
            {/*        </div>*/}
            {/*        <div*/}
            {/*            className={"radio-btn-wrp mleft-5"}*/}
            {/*            onClick={() => setAvailability("offline")}*/}
            {/*        >*/}
            {/*            <RadioButton*/}
            {/*                size={"sm"}*/}
            {/*                text={"Offline"}*/}
            {/*                selected={myAvailabilityStatus === "offline"}*/}
            {/*            />*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}

            {/*====== Basic Profile  =======*/}
            <div className={`set-status-wrp p-top25`}>
              <div
                className={"header-with-edit d-flex justify-content-between "}
              >
                <>
                  <SubTitle color={"section-title"} title={"Basic Profile "} />
                </>
                <div className={`d-flex `}>
                  {editProfile && (
                    <div className={`edit-text-wrp`}>
                      <SubTitle
                        color={"cancel"}
                        title={"Cancel"}
                        onClick={() => {
                          setEditProfile(false);
                          setUserProfile({ ...userOldProfile });
                          setUserOldProfile(null);
                        }}
                      />
                    </div>
                  )}
                  <div className={`save-text-wrp ml-2`}>
                    <SubTitle
                      color={"edit"}
                      title={editProfile ? "Save" : "Edit"}
                      onClick={() => {
                        updateProfile(!editProfile);
                      }}
                    />
                  </div>
                </div>
              </div>

              {isLoading && <Spin />}

              {!isLoading && (
                <div className={`set-status-wrp none-boarder p-top25`}>
                  <div className={"profile-upload"}>
                    <ProfileAvatarUploader
                      editProfile={editProfile}
                      onCaptured={(url) => setImageSrc(url)}
                      src={
                        props.my_profile && props.my_profile.avatarURL
                          ? props.my_profile.avatarURL
                          : null
                      }
                    />
                  </div>

                  <div className={"full-name mb-2 d-flex"}>
                    <div className={"first-name mleft-5"}>
                      <TextBoxWithLabel
                        style={"default"}
                        labelStyle={"sm"}
                        labelText={"First Name"}
                        placeholder={"Fist Name"}
                        required={true}
                        type={"text"}
                        disable={!editProfile}
                        value={userProfile.firstName}
                        onChange={(e) =>
                          setUserProfile({
                            ...userProfile,
                            firstName: e.target.value,
                          })
                        }
                      />
                    </div>
                    <div className={"last-name"}>
                      <TextBoxWithLabel
                        style={"default"}
                        labelStyle={"sm"}
                        labelText={"Last Name"}
                        placeholder={"Last Name"}
                        required={true}
                        type={"text"}
                        disable={!editProfile}
                        value={userProfile.lastName}
                        onChange={(e) =>
                          setUserProfile({
                            ...userProfile,
                            lastName: e.target.value,
                          })
                        }
                      />
                    </div>
                  </div>

                  <div className={"full-name mt-2"}>
                    <div className={"first-name"}>
                      <TextBoxWithLabel
                        style={"default"}
                        labelStyle={"sm"}
                        labelText={"Email"}
                        placeholder={"ex: my@tetherfi.com"}
                        required={true}
                        type={"text"}
                        disable={true}
                        value={userProfile.emailId}
                        onChange={(e) =>
                          setUserProfile({
                            ...userProfile,
                            emailId: e.target.value,
                          })
                        }
                      />
                    </div>
                  </div>
                  <div className={"full-name mt-2"}>
                    <div className={"first-name"}>
                      <TextBoxWithLabel
                        style={"default"}
                        labelStyle={"sm"}
                        labelText={"Mobile"}
                        placeholder={"+94123456789"}
                        required={true}
                        type={"text"}
                        disable={!editProfile}
                        value={userProfile.mobileNumber}
                        onChange={(e) =>
                          setUserProfile({
                            ...userProfile,
                            mobileNumber: e.target.value,
                          })
                        }
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
            {/*====== Security  =======*/}
            <div className={`set-status-wrp none-boarder p-top25`}>
              <form onSubmit={handleSubmit(updatePassword)}>
                <div
                  className={"header-with-edit d-flex justify-content-between"}
                >
                  <>
                    <SubTitle color={"section-title"} title={"Security"} />
                  </>
                  <div className={`d-flex `}>
                    {editPassword && (
                      <div className={`edit-text-wrp`}>
                        <SubTitle
                          color={"cancel"}
                          title={"Cancel"}
                          onClick={() => {
                            setEditPassword(false);
                            reset();
                          }}
                        />
                      </div>
                    )}
                    <div className={`save-text-wrp ml-2`}>
                      {!editPassword && (
                        <SubTitle
                          color={"edit"}
                          title={"Edit"}
                          onClick={() => {
                            setEditPassword(true);
                          }}
                        />
                      )}

                      {editPassword && (
                        <SubTitle
                          color={"edit"}
                          title={"Save"}
                          onClick={handleSubmit(updatePassword)}
                        />
                      )}
                    </div>
                  </div>
                </div>
                <div className={`set-status-wrp none-boarder p-top25`}>
                  <div className={"full-name  mb-2"}>
                    <div className="password_input_container">
                      <input
                        type={inputType}
                        className={`form-control default  ${editPassword ? "active" : "disabled"
                          } `}
                        labelStyle={"sm"}
                        placeholder="Current Password"
                        labelText={"Current Password"}
                        disabled={!editPassword}
                        maxLength={passwordPolicy.maxLength}
                        name="currentPassword"
                        ref={register({
                          required: {
                            value: true,
                            message: "Password is required",
                          },
                        })}
                      />
                      {editPassword &&
                        (inputType === "password" ? (
                          <EyeOff
                            className="password_input_img"
                            onClick={() => setInputType("text")}
                          />
                        ) : (
                          <Eye
                            className="password_input_img"
                            onClick={() => setInputType("password")}
                          />
                        ))}

                      {errors.currentPassword && (
                        <label style={{ color: "red" }}>
                          {errors.currentPassword.message}
                        </label>
                      )}
                    </div>
                  </div>

                  <div className={"full-name mt-2"}>
                    <div className={"first-name"}>
                      <div className="password_input_container">
                        <input
                          type={inputTypeNew}
                          className={`form-control default ${editPassword ? "active" : "disabled"
                            } `}
                          minLength={passwordPolicy.minLength}
                          maxLength={passwordPolicy.maxLength}
                          name="password"
                          ref={register({
                            required: {
                              value: true,
                              message: "Password is required",
                            },
                            minLength: {
                              value: passwordPolicy.minLength,
                              message: `Password must be at least ${passwordPolicy.minLength} characters`,
                            },
                            maxLength: {
                              value: passwordPolicy.maxLength,
                              message: `Password must be less than ${passwordPolicy.maxLength} characters`,
                            },
                            pattern: {
                              value: passwordPolicy.passwordPolicy
                                ? new RegExp(
                                  passwordPolicy.passwordPolicy,
                                  "i"
                                )
                                : null,
                              message: `Password must be a valid pattern. ${passwordPolicy["policyNames"]}`,
                            },
                            validate: {
                              match: (value) =>
                                value !== watch("currentPassword") ||
                                "Your new password cannot be the same as your old password",
                            },
                          })}
                          labelText={"New Password"}
                          placeholder={"New Password"}
                        />
                        {editPassword &&
                          (inputTypeNew === "password" ? (
                            <EyeOff
                              className="password_input_img"
                              onClick={() => setInputTypeNew("text")}
                            />
                          ) : (
                            <Eye
                              className="password_input_img"
                              onClick={() => setInputTypeNew("password")}
                            />
                          ))}

                        {errors.password && (
                          <label style={{ color: "red" }}>
                            {errors.password.message}
                          </label>
                        )}
                      </div>
                    </div>
                    <div className={"mt-1"}>
                      <div className="password_input_container">
                        <input
                          className={`form-control default ${editPassword ? "active" : "disabled"
                            } `}
                          type={inputTypeCon}
                          maxLength={12}
                          name="confirmPassword"
                          ref={register({
                            required: {
                              value: true,
                              message: "Confirm password is required",
                            },
                            minLength: {
                              value: 6,
                              message: "Password must be at least 6 characters",
                            },
                            validate: {
                              match: (value) =>
                                value === watch("password") ||
                                "The passwords do not match",
                            },
                          })}
                          labelText={"Confirm Password"}
                          placeholder={"Confirm Password"}
                        />
                        {editPassword &&
                          (inputTypeCon === "password" ? (
                            <EyeOff
                              className="password_input_img"
                              onClick={() => setInputTypeCon("text")}
                            />
                          ) : (
                            <Eye
                              className="password_input_img"
                              onClick={() => setInputTypeCon("password")}
                            />
                          ))}
                        {errors.confirmPassword && (
                          <label style={{ color: "red" }}>
                            {errors.confirmPassword.message}
                          </label>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </form>
              <div className={"session-id-wrapper d-flex flex-column"}>
                {sid && (
                  <>
                    <div>Session id</div>
                    <div>{sid}</div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </Scrollbars>

      {/*<div onClick={logout} className={"d-flex flex-column log-out  align-items-center"}>*/}
      {/*    Log Out*/}

      {/*</div>*/}
    </>
  );
};

MyProfilePanelComponent.propTypes = {
  presence_actions: PropTypes.object.isRequired,
};

function mapDispatchToProps(dispatch) {
  return {
    presence_actions: bindActionCreators(presenceActions, dispatch),
  };
}

function mapStateToProps(state) {
  return {
    presence_user: state.presence.data[auth.getUserId()],
    my_profile: state.team[auth.getUserId()],
    //team_member: state.team[auth.getUserId()],
    //logged_user: state.logged_user
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MyProfilePanelComponent);
