import * as types from "./actionTypes";

export function openWhiteboard(contactId, wbType, extraData) {
    return { type: types.OPEN_WHITEBOARD, contactId, wbType, extraData };
}

export function minimizeWhiteboard() {
    return { type: types.MINIMIZE_WHITEBOARD };
}

export function maximizeWhiteboard() {
    return { type: types.MAXIMIZE_WHITEBOARD };
}

export function disposeWhiteboard() {
    return { type: types.DISPOSE_WHITTEBOARD };
}