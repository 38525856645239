export class presenceApi {

    constructor (config, livechat) {
        this._livechat = livechat;
        this.presenceUrl = config ? config.presenceUrl : "";
    }

    /**
    * api-method
    * Get all presence user details for a given workspace
    * @param {string} workspace Workspace name the user has logged in to (Cannot be blank)
    * @returns {Promise} Promise object resolves to an array of objects containing presence information for eah user
    */
    getAllPresenceForWorkspace = workspace => {

        const myHeaders = new Headers({
            "Authorization": `Bearer ${this._livechat.getAuthToken()}`,
            "Content-Type": "application/json"
        });

        let presenceUrl = this.presenceUrl;


        return new Promise((resolve, reject) => {
            let pageNo = 1;
            let presenceList = [];

            function getPresenceWithLimit(pageNo, limit) {
                let url = `${presenceUrl}/presence/${workspace}?limit=${limit}`;

                if (pageNo) {
                    url = `${url}&state=${pageNo}`;
                }

                fetch(url, {
                    method: "GET",
                    headers: myHeaders
                })
                    .then(res => {
                        return res.json();
                    })
                    .then(data => {
                        if (data && data.isSuccess) {
                            if (
                                data.result &&
                                data.result.length > 0
                            ) {
                                presenceList = presenceList.concat(data.result);

                                if (data.result.length < limit) {
                                    resolve(presenceList);
                                } else {
                                    getPresenceWithLimit(++pageNo, 100);
                                }

                            } else {
                                resolve(presenceList);
                            }
                        } else {
                            //break operation and return empty list
                            resolve(presenceList);
                        }
                    })
                    .catch(ex => {
                        reject(ex);
                    });
            }

            getPresenceWithLimit(pageNo, 100);
        });
    };
}