import React from 'react';
import { getUserId } from '../../../utils';
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import liveChatClient from "../../../lib";
import { setWbState } from "./wbState";
import uuid from 'uuid/v1';
import * as ChatService from '../../../service/chatService';
import * as chatActions from '../../../redux/actions/chatActions';
import * as whiteboardActions from '../../../redux/actions/whiteboardActions';

import { whiteboardUrl, fileMetaServiceUrl, fileServiceUrl } from '../../../config/baseUrls';
let whiteboardMount = null;

class Whiteboard extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            visibility: false
        }
    }

    componentWillUnmount() {
        /* setWbState(false); */
        whiteboardMount.dispose();
        whiteboardMount = null;
    }


    componentDidMount() {

        /* setWbState(true); */

        let mountElement = document.getElementById('whiteboard_mount');

        if (mountElement && this.props.selected_profile) {

            let uniqueId = uuid();

            let msg = {
                v: 1,
                mid: uniqueId,
                sid: uniqueId,
                message_type: 'WHITEBOARD_REQUEST',
                from: {
                    id: getUserId(),
                    name: getUserId(),
                },
                to: {
                    id: this.props.selected_profile.username,
                    name: this.props.selected_profile.username,
                },
                created_at: Date.now(),
                message_content: '',
                other: {},
                conversation_type: this.props.selected_profile.conversation_type,
                message_action: 1
            };

            ChatService.sendChatMessage(this.props.selected_profile.conversation_type, msg.message_type, this.props.selected_profile.username, msg);
            this.props.chat_actions.sendNewMessageSuccess(msg);

            let conversationId = this.props.selected_profile.conversation_type === 0 ? [getUserId(), this.props.selected_profile.username].sort().join('_') : this.props.selected_profile.username;

            whiteboardMount = new window.WhiteBoard({
                Width: this.props.viewPointWidth - 380,
                Container: mountElement,
                Height: this.props.viewPointHeight,
                UserId: uuid(),
                Servers: [`${whiteboardUrl}/`],
                ServerId: 1,
                FileUploadServers: [{
                    post: fileServiceUrl,
                    retrieval: fileMetaServiceUrl
                }],
                SessionId: conversationId,
                NoOfPositionsPerRequest: 5,
                BrushColors: [
                    "#3F51B5",
                    "#E91E63",
                    "#4CAF50",
                    "#F44336",
                    "black",
                    "#FF9800",
                ],
                BrushSize: 5,
                Cobrowse: {
                    Enabled: true,
                    IsSender: false
                }
            });
        }

    }

    componentDidUpdate() {



    }

    render() {
        let displayStyle = { width: this.props.viewPointWidth - 380, height: this.props.viewPointHeight };

        if (this.props.whiteboard.wbState === 'MINIMIZED') {
            displayStyle.position = "absolute";
            displayStyle.left = "-9999px";
        }

        return (
            <>
                {/* position: "absolute", left: "-9999px" */}

                {<div className={`chat-empty-wrapper`}
                    style={displayStyle}>
                    <div style={{ position: "absolute" }} id="whiteboard_mount">
                    </div>

                </div>}
            </>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return {
        chat_actions: bindActionCreators(chatActions, dispatch),
        whiteboard_actions: bindActionCreators(whiteboardActions, dispatch)
    };
}

function mapStateToProps(state) {
    return {
        selected_profile: state.selected_profile,
        whiteboard: state.whiteboard
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Whiteboard);
