import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { MenuIcon } from "../../common";
import { ChevronDown } from 'react-feather';
import { connect } from "react-redux";

const DownArrowChat = forwardRef((props, ref) => {

    const isScrollBarAtBottom = (scrollValues) => {
        if (scrollValues.scrollTop < 50) {
            return scrollValues.top > 0.92;
        } else if (scrollValues.scrollTop >= 50 && scrollValues.scrollTop < 100) {
            return scrollValues.top > 0.98;
        } else if (scrollValues.scrollTop >= 100 && scrollValues.scrollTop < 200) {
            return scrollValues.top > 0.985;
        } else if (scrollValues.scrollTop >= 200 && scrollValues.scrollTop < 500) {
            return scrollValues.top > 0.99;
        } else if (scrollValues.scrollTop >= 500 && scrollValues.scrollTop < 1000) {
            return scrollValues.top > 0.995;
        } else if (scrollValues.scrollTop >= 1000 && scrollValues.scrollTop < 10000) {
            return scrollValues.top > 0.999;
        } else if (scrollValues.scrollTop >= 10000) {
            return scrollValues.top > 0.9999;
        }

        return false;

    }

    const [showArrow, setShowArrow] = useState(false);

    let scrollbarTL = props.getScrollbarTimeline();

    let scrollValues = scrollbarTL ? scrollbarTL.getValues() : null;

    useImperativeHandle(ref, () => ({
        onScrollFrame(values) {

            if (isScrollBarAtBottom(values)) {
                setShowArrow(false);
            } else {
                if (showArrow === false) {
                    setShowArrow(true);
                }
            }

        },
        showArrowOnEvent() {
            setShowArrow(true);
        }
    }));

    return (
        <>
            {props.activeMenu === 'chat' && showArrow && scrollValues && scrollValues.clientHeight < scrollValues.scrollHeight &&
                <div className={`scroller-down-wrapper ${props.isOpenInfoPanel ? 'with-info-panel' : 'with-out-panel'}`}
                    onClick={props.onDownArrowClick}>
                    {props.chat_info && props.chat_info.unseen_count !== 0 && !isNaN(props.chat_info.unseen_count) &&
                        <div className={'unread-message-count'}>
                            {props.chat_info.unseen_count}
                        </div>
                    }
                    <MenuIcon
                        size={'sm-space'}
                        icon={<ChevronDown />} />
                </div>}
        </>
    )
});

function mapStateToProps(state) {
    let mapState = {
        selected_profile: state.selected_profile
    }
    if (state.selected_profile && state.selected_profile.username) {
        mapState.chat_info = state.chats[state.selected_profile.username];
    }
    return mapState;
}

export default connect(mapStateToProps, null, null, {
    forwardRef: true
})(DownArrowChat);
