import { combineReducers } from "redux";
// import authReducer from './authReducer';
import { connectRouter } from "connected-react-router";
import users from "./usersReducer";
import { teamReducer } from "./teamReducer";
import { presenceReducer } from "./presenceReducer";
import { chatReducer } from "./chatReducer";
import { selectedProfileReducer } from "./selectedProfileReducer";
import { loggedUserReducer } from "./loggedUserReducer";
import { callReducer } from "./callReducer";
import { clientReducer } from "./clientReducer";
import { groupReducer } from "./groupReducer";
import { interactionReducer } from "./interactionReducer";
import { socketReducer } from "./socketReducer";
import { fileShareReducer } from "./fileShareReducer";
import { audioRecordReducer } from "./audioRecordReducer";
import { videoRecordReducer } from "./videoRecordReducer";
import { typingReducer } from "./typingReducer";
import { chatSearchReducer } from "./chatSearchReducer";
import { starringReducer } from "./starringReducer";
import { uiPanelReducer } from "./uiPanelReducer";
import { connectivityReducer } from "./connectivityReducer";
import { templateReducer } from "./templateReducer";
import { cannedMessageReducer } from "./cannedMessageReducer";
import { timelineReducer } from "./timelineReducer";
import callControlsReducer from "./callControlsReducer";
import webNotificationReducer from './webNotificationReducer';
import externalApiReducer from './externalApiReducer';
import { chatTextPanelReducer } from './chatTextPanelReducer';
import { meetingReducer } from './meetingReducer';
import { currentMeetingReducer } from './currentMeetingReducer';
import { whiteboardReducer } from './whiteboardReducer';
import { externalUserProfileReducer } from "./externalUserProfileReducer";

export default (history) =>
  combineReducers({
    router: connectRouter(history),
    users,
    team: teamReducer,
    presence: presenceReducer,
    chats: chatReducer,
    selected_profile: selectedProfileReducer,
    logged_user: loggedUserReducer,
    call: callReducer,
    client: clientReducer,
    chat_group: groupReducer,
    interactions: interactionReducer,
    socket_status: socketReducer,
    file_share_status: fileShareReducer,
    video_record_status: videoRecordReducer,
    audio_record_status: audioRecordReducer,
    typing_status: typingReducer,
    chat_search_messages: chatSearchReducer,
    starred_messages: starringReducer,
    ui_panel_data: uiPanelReducer,
    callControls: callControlsReducer,
    webNotification: webNotificationReducer,
    external_api: externalApiReducer,
    connectivity: connectivityReducer,
    templates: templateReducer,
    canned_messages: cannedMessageReducer,
    timeline: timelineReducer,
    chat_text_panel_info: chatTextPanelReducer,
    meetings: meetingReducer,
    current_meeting: currentMeetingReducer,
    whiteboard: whiteboardReducer,
    selected_ext_profile: externalUserProfileReducer
  });
