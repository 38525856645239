import { chatApiUrl, chatHistoryUrl, __APP_PROFANITY_SEND_REPORT__ } from "../config/baseUrls";
import { fetchDefaults } from "./fetchWrapper";
import liveChatClient from "../lib";
import * as auth from "../utils";
import { setNextSendSequenceNumber } from "../utils/messageSequenceHandler";
var apiFetch = fetchDefaults(fetch);

export const sendChatMessage = (
  conversationType,
  type,
  to,
  content,
  emitEvent = true,
  interactionId = null
) => {
  try {

    let seq = setNextSendSequenceNumber(content.to.id);
    let cnt = JSON.parse(JSON.stringify(content));
    cnt.seq = seq;
    if (conversationType === 1) {
      //return ChannelFactory.chat.sendDialog(type, data, content);
    } else if (conversationType === 0 || conversationType === 4) {
      let toUsr = interactionId ? interactionId : to;
      liveChatClient.sendUserMessage(JSON.stringify(cnt), interactionId ? `interaction:${interactionId}` : `user:${to}`, `${toUsr}:${cnt.mid}`);
    } else if (conversationType === 3) {
      liveChatClient.sendMessageInGroup(to, JSON.stringify(cnt));
    } else if (conversationType === 9) {
      liveChatClient.sendAppEvent(JSON.stringify(cnt), `user:${to}`);
    }

  } catch (ex) {
    console.error(ex);

  }


};

export const sendTypingEvent = (conversation_type, from, to, status, interactionId) => {
  // ChannelFactory.chat.sendTypingEvent(conversationType, from, to);
  let typingMessage = { from, to, conversation_type, status };
  liveChatClient.sendTypingState(JSON.stringify(typingMessage), interactionId ? `interaction:${interactionId}` : `user:${to}`);
};

export const sendSeenStatus = (conversation_type, type, from, to, mids, chatMessages) => {

  mids.forEach(mid => {
    let data = {
      from: { id: from },
      to: { id: to },
      conversation_type,
      mid,
      seen_at: Date.now(),
      v: 1
    };

    /* let data = {
      from,
      to,
      conversation_type,
      mid,
      seen_at: Date.now(),
      v: 1
    }; */

    if (conversation_type === 0) {
      liveChatClient.sendReadRecipt(JSON.stringify(data), `user:${to}`);
      // return ChannelFactory.chat.sendChatStatus(type, data, mid);
    } else if (conversation_type === 3) {
      let message = chatMessages[mid];
      let sender_id = message && message.from ? message.from.id : null;
      liveChatClient.sendReadRecipt(JSON.stringify(data), `group:${to}`, `user:${sender_id}`);
      // return ChannelFactory.chat.sendConferenceStatus(type, data, mid);
    } else if (conversation_type === 4) {
      let message = chatMessages[mid];
      let sender_id = message && message.from ? message.from.id : null;
      liveChatClient.sendReadRecipt(JSON.stringify(data), `interaction:${to}`, `user:${sender_id}`);
    }

  })

};

export const getAllUnreadCountsV2Users = (userId, idList) => {
  console.log(
    `%c [ service chatService getAllUnreadCountsV2Users ] - SessionId : ${auth.getSession()}, Data : ${JSON.stringify(
      { userId, idList }
    )}`,
    "background: #990033; color: #ffffff; font-size:12px"
  );
  return new Promise((resolve, reject) => {
    let url = `${chatHistoryUrl}/api/v1/conversations/${userId}`;

    let bodyJSON = JSON.stringify({ ids: idList });

    fetch(url, {
      method: "POST",
      headers: {
        "authorization": auth.getBearerToken()
      },
      body: bodyJSON
    })
      .then(res => {
        return res.json();
      })
      .then(data => {
        console.log(
          `%c [ service chatService getAllUnreadCountsV2Users Response ] - SessionId : ${auth.getSession()}, Data : ${JSON.stringify(
            data
          )}`,
          "background: #cc9900; color: #ffffff; font-size:12px"
        );
        if (data && data.isSuccess) {
          if (data.result) {
            //let funcArr = [];

            let chatCountObj = data.result.reduce((acc, item) => {

              let toUser = item.other_party_id;

              if (item.is_group) {
                //group conversation
                toUser = item.group_id;
              }
              acc[toUser] = {
                unseen_count: item.unseen_count,
                messages: {},
                conversation_dates: [],
                ...item
              };

              return acc;
            }, {});

            resolve(chatCountObj);
          } else {
            resolve({});
          }
        } else {
          //break operation and return empty list
          reject(new Error("Error occurred"));
        }
      })
      .catch(ex => {
        reject(ex);
      });
  });
};

export const getAllUnreadCountsV2 = userId => {
  return new Promise((resolve, reject) => {
    let url = `${chatHistoryUrl}/api/v1/conversations/${userId}`;

    fetch(url, {
      method: "GET",
      headers: {
        "authorization": auth.getBearerToken()
      }
    })
      .then(res => {
        return res.json();
      })
      .then(data => {
        if (data && data.isSuccess) {
          if (data.result) {
            //let funcArr = [];

            let chatCountObj = data.result.reduce((acc, item) => {

              let toUser = item.other_party_id;

              if (item.is_group) {
                //group conversation
                toUser = item.group_id;
              }
              acc[toUser] = {
                unseen_count: item.unseen_count,
                messages: {},
                conversation_dates: [],
                ...item
              };

              return acc;
            }, {});

            resolve(chatCountObj);
          } else {
            resolve({});
          }
        } else {
          //break operation and return empty list
          reject(new Error("Error occurred"));
        }
      })
      .catch(ex => {
        reject(ex);
      });
  });
};

export const getStarredMessages = (conversation_type, timestamp, from, to) => {

  console.log(
    `%c [ service chatService getStarredMessages ] - SessionId : ${auth.getSession()}, Data : ${JSON.stringify(
      { conversation_type, timestamp, from, to }
    )}`,
    "background: #990033; color: #ffffff; font-size:12px"
  );

  return new Promise((resolve, reject) => {
    let url = `${chatHistoryUrl}/api/v1/conversation/messages?type=${conversation_type}&dateFrom=${timestamp}&from=${from}&to=${to}&limit=10&filterBy=starred&filterValue=true`;

    fetch(url, {
      method: "GET",
      headers: {
        "authorization": auth.getBearerToken()
      }
    })
      .then(res => {
        return res.json();
      })
      .then(data => {
        console.log(
          `%c [ service chatService getStarredMessages Response ] - SessionId : ${auth.getSession()}, Data : ${JSON.stringify(
            data
          )}`,
          "background: #cc9900; color: #ffffff; font-size:12px"
        );
        if (data && data.isSuccess) {
          let messageList = data.result.reduce((acc, message) => {
            message.from = { id: message.sender_id };
            message.isUploading = 'DONE';
            acc[message.mid] = message;
            return acc;
          }, {});
          resolve(messageList);
        } else {
          //break operation and return empty list
          reject(new Error("Error occurred"));
        }
      })
      .catch(ex => {
        reject(ex);
      });
  });
};

export const starMessage = (from, mid, isStarring) => {

  console.log(
    `%c [ service chatService starMessage ] - SessionId : ${auth.getSession()}, Data : ${JSON.stringify(
      { from, mid, isStarring }
    )}`,
    "background: #990033; color: #ffffff; font-size:12px"
  );

  return new Promise((resolve, reject) => {
    let url = `${chatHistoryUrl}/api/v1/conversation/update/${auth.getUserId()}?from=${from}&mid=${mid}&isStarred=${isStarring}`;


    fetch(url, {
      method: "PUT",
      headers: {
        "authorization": auth.getBearerToken()
      }
    })
      .then(res => {
        return res.json();
      })
      .then(data => {

        console.log(
          `%c [ service chatService starMessage Response ] - SessionId : ${auth.getSession()}, Data : ${JSON.stringify(
            data
          )}`,
          "background: #cc9900; color: #ffffff; font-size:12px"
        );

        if (data && data.isSuccess) {
          resolve(true);
        } else {
          //break operation and return empty list
          reject(new Error("Error occurred"));
        }
      })
      .catch(ex => {
        reject(ex);
      });
  });
};

export const searchMessages = (user, to, conversation_type, searchMessage) => {
  console.log(
    `%c [ service chatService searchMessages ] - SessionId : ${auth.getSession()}, Data : ${JSON.stringify(
      { user, to, conversation_type, searchMessage }
    )}`,
    "background: #990033; color: #ffffff; font-size:12px"
  );
  return new Promise((resolve, reject) => {
    let url = `${chatHistoryUrl}/api/v1/conversation/search?user=${user}&type=${conversation_type}&to=${to}&limit=100&msg=${searchMessage}`;

    fetch(url, {
      method: "GET",
      headers: {
        "authorization": auth.getBearerToken()
      }
    })
      .then(res => {
        return res.json();
      })
      .then(data => {

        console.log(
          `%c [ service chatService searchMessages Response ] - SessionId : ${auth.getSession()}, Data : ${JSON.stringify(
            data
          )}`,
          "background: #cc9900; color: #ffffff; font-size:12px"
        );

        if (data && data.isSuccess) {
          resolve(data.result);
        } else {
          //break operation and return empty list
          reject(new Error("Error occurred"));
        }
      })
      .catch(ex => {
        reject(ex);
      });
  });
};

export const getUnreadMessages = (teamName, type, from, to, timestamp, limit, allMessages = {}, unseen_messages = []) => {
  let tempType = 0;
  if (type === 3 || type === 4) {
    tempType = 1;
  }

  console.log(
    `%c [ service chatService getUnreadMessages ] - SessionId : ${auth.getSession()}, Data : ${JSON.stringify(
      { teamName, type, from, to, timestamp, limit }
    )}`,
    "background: #990033; color: #ffffff; font-size:12px"
  );

  return new Promise(function (resolve, reject) {
    let url = `${chatHistoryUrl}/api/v1/conversation/messages/after?type=${tempType}&dateFrom=${timestamp}&from=${from}&to=${to}&limit=${limit}`;

    fetch(url, {
      method: "GET",
      headers: {
        "authorization": auth.getBearerToken()
      }
    })
      .then(res => {
        return res.json();
      })
      .then(data => {

        console.log(
          `%c [ service chatService getUnreadMessages Response ] - SessionId : ${auth.getSession()}, Data : ${JSON.stringify(
            data
          )}`,
          "background: #cc9900; color: #ffffff; font-size:12px"
        );

        if (data && data.isSuccess && data.result) {
          let next_timestamp = null;
          let messages = data.result.reduce((acc, item) => {

            next_timestamp = item.created_at;

            if (!item.is_seen) {
              unseen_messages.push(item.mid);
            }

            if (item.file_uris && Array.isArray(item.file_uris) && item.file_uris.length > 0) {
              item.content_url = item.file_uris[0].content_url;
              item.thumbnail_url = item.file_uris[0].thumbnail_url;
            }

            item.isUploading = "DONE";
            item.from = { id: item.sender_id };
            if (item.related_message && item.related_message.sender_id) {
              item.related_message.from = { id: item.related_message.sender_id }
            }
            acc[item.mid] = item;

            return acc;
          }, {});

          allMessages = { ...allMessages, ...messages };

          if (data.result && data.result.length === limit) {
            //have more messages
            return getUnreadMessages(
              teamName,
              type,
              from,
              to,
              next_timestamp,
              limit,
              allMessages,
              unseen_messages
            )
              .then(data => {
                resolve(data);
              })
              .catch(ex => {
                resolve(null);
              });
          } else {
            resolve({ messages: allMessages, unseen_messages });
          }
        } else {
          //break operation and return empty list
          resolve(null);
        }
      })
      .catch(ex => {
        reject(ex);
      });
  });
};

export const getUnreadMessagesInteraction = (to, timestamp, limit, allMessages = {}, unseen_messages = []) => {
  console.log(
    `%c [ service chatService getUnreadMessagesInteraction ] - SessionId : ${auth.getSession()}, Data : ${JSON.stringify(
      { to, timestamp, limit }
    )}`,
    "background: #990033; color: #ffffff; font-size:12px"
  );
  return new Promise(function (resolve, reject) {
    let url = `${chatHistoryUrl}/api/v1/interaction/messages/after?interactionId=${to}&dateFrom=${timestamp}&limit=${limit}`;

    fetch(url, {
      method: "GET",
      headers: {
        "authorization": auth.getBearerToken()
      }
    })
      .then(res => {
        return res.json();
      })
      .then(data => {
        console.log(
          `%c [ service chatService getUnreadMessagesInteraction Response ] - SessionId : ${auth.getSession()}, Data : ${JSON.stringify(
            data
          )}`,
          "background: #cc9900; color: #ffffff; font-size:12px"
        );
        if (data && data.isSuccess && data.result) {
          let next_timestamp = null;
          let messages = data.result.reduce((acc, item) => {

            next_timestamp = item.created_at;

            if (!item.is_seen) {
              unseen_messages.push(item.mid);
            }

            if (item.file_uris && Array.isArray(item.file_uris) && item.file_uris.length > 0) {
              item.content_url = item.file_uris[0].content_url;
              item.thumbnail_url = item.file_uris[0].thumbnail_url;
            }

            item.from = { id: item.sender_id };
            item.isUploading = "DONE";
            if (item.related_message && item.related_message.sender_id) {
              item.related_message.from = { id: item.related_message.sender_id }
            }
            acc[item.mid] = item;

            return acc;
          }, {});

          allMessages = { ...allMessages, ...messages };

          if (data.result && data.result.length === limit) {
            //have more messages
            return getUnreadMessagesInteraction(
              to,
              next_timestamp,
              limit,
              allMessages,
              unseen_messages
            )
              .then(data => {
                resolve(data);
              })
              .catch(ex => {
                resolve(null);
              });
          } else {
            resolve({ messages: allMessages, unseen_messages });
          }
        } else {
          //break operation and return empty list
          resolve(null);
        }
      })
      .catch(ex => {
        reject(ex);
      });
  });
};

export const getBeforeMessages = (type, from, to, timestamp, limit) => {
  let tempType = 0;
  if (type === 3 || type === 4) {
    tempType = 1;
  }

  console.log(
    `%c [ service chatService getBeforeMessages ] - SessionId : ${auth.getSession()}, Data : ${JSON.stringify(
      { type, from, to, timestamp, limit }
    )}`,
    "background: #990033; color: #ffffff; font-size:12px"
  );

  let url = `${chatHistoryUrl}/api/v1/conversation/messages/before?type=${tempType}&dateFrom=${timestamp}&from=${from}&to=${to}&limit=${limit}`;

  return new Promise(function (resolve, reject) {
    fetch(url, {
      method: "GET",
      headers: {
        "authorization": auth.getBearerToken()
      }
    })
      .then(res => {
        return res.json();
      })
      .then(data => {

        console.log(
          `%c [ service chatService getBeforeMessages Response ] - SessionId : ${auth.getSession()}, Data : ${JSON.stringify(
            data
          )}`,
          "background: #cc9900; color: #ffffff; font-size:12px"
        );

        if (data && data.isSuccess && data.result) {
          resolve({ result: data.result });
        } else {
          //break operation and return empty list
          resolve(null);
        }
      })
      .catch(ex => {
        reject(ex);
      });
  });
};

export const getAfterMessages = (type, from, to, timestamp, limit) => {

  let tempType = 0;
  if (type === 3 || type === 4) {
    tempType = 1;
  }
  console.log(
    `%c [ service chatService getAfterMessages ] - SessionId : ${auth.getSession()}, Data : ${JSON.stringify(
      { type, from, to, timestamp, limit }
    )}`,
    "background: #990033; color: #ffffff; font-size:12px"
  );
  let url = `${chatHistoryUrl}/api/v1/conversation/messages/after?type=${tempType}&dateFrom=${timestamp}&from=${from}&to=${to}&limit=${limit}`;

  return new Promise(function (resolve, reject) {
    fetch(url, {
      method: "GET",
      headers: {
        "authorization": auth.getBearerToken()
      }
    })
      .then(res => {
        return res.json();
      })
      .then(data => {

        console.log(
          `%c [ service chatService getAfterMessages Response ] - SessionId : ${auth.getSession()}, Data : ${JSON.stringify(
            data
          )}`,
          "background: #cc9900; color: #ffffff; font-size:12px"
        );

        if (data && data.isSuccess && data.result) {
          resolve({ result: data.result });
        } else {
          //break operation and return empty list
          resolve(null);
        }
      })
      .catch(ex => {
        reject(ex);
      });
  });
};

export const getSearchAfterMessages = (type, from, to, timestamp, limit) => {

  console.log(
    `%c [ service chatService getSearchAfterMessages ] - SessionId : ${auth.getSession()}, Data : ${JSON.stringify(
      { type, from, to, timestamp, limit }
    )}`,
    "background: #990033; color: #ffffff; font-size:12px"
  );

  return new Promise(function (resolve, reject) {
    let next_timestamp = null;
    let search_anchor_point = null;

    getAfterMessages(type, from, to, timestamp, limit).then(data => {

      if (data && data.result) {

        let messages = data.result.reduce((acc, item) => {
          if (!search_anchor_point) {
            search_anchor_point = item.mid;
          }
          next_timestamp = item.created_at;

          if (item.file_uris && Array.isArray(item.file_uris) && item.file_uris.length > 0) {
            item.content_url = item.file_uris[0].content_url;
            item.thumbnail_url = item.file_uris[0].thumbnail_url;
          }

          item.from = { id: item.sender_id };
          item.isUploading = "DONE";
          if (item.related_message && item.related_message.sender_id) {
            item.related_message.from = { id: item.related_message.sender_id }
          }
          acc[item.mid] = item;

          return acc;
        }, {});

        resolve({ messages, next_timestamp, search_anchor_point })

      } else {
        //break operation and return empty list
        resolve(null);
      }

    }).catch(ex => {
      reject(ex);
    });

  });
};

export const getSearchBeforeMessages = (type, from, to, timestamp, limit) => {

  console.log(
    `%c [ service chatService getSearchBeforeMessages ] - SessionId : ${auth.getSession()}, Data : ${JSON.stringify(
      { type, from, to, timestamp, limit }
    )}`,
    "background: #990033; color: #ffffff; font-size:12px"
  );

  return new Promise(function (resolve, reject) {
    let previous_timestamp = null;
    let search_anchor_point = null;

    getBeforeMessages(type, from, to, timestamp, limit).then(data => {

      if (data && data.result) {

        let messages = data.result.reduce((acc, item) => {
          if (!previous_timestamp) {
            previous_timestamp = item.created_at;
          }
          search_anchor_point = item.mid;
          item.from = { id: item.sender_id };
          item.isUploading = "DONE";
          if (item.related_message && item.related_message.sender_id) {
            item.related_message.from = { id: item.related_message.sender_id }
          }
          if (item.file_uris && Array.isArray(item.file_uris) && item.file_uris.length > 0) {
            item.content_url = item.file_uris[0].content_url;
            item.thumbnail_url = item.file_uris[0].thumbnail_url;
          }
          acc[item.mid] = item;

          return acc;
        }, {});

        resolve({ messages, previous_timestamp, search_anchor_point })

      } else {
        //break operation and return empty list
        resolve(null);
      }

    }).catch(ex => {
      reject(ex);
    });

  });
};

export const getInitialSearchMessages = (type, from, to, timestamp, limit) => {

  console.log(
    `%c [ service chatService getInitialSearchMessages ] - SessionId : ${auth.getSession()}, Data : ${JSON.stringify(
      { type, from, to, timestamp, limit }
    )}`,
    "background: #990033; color: #ffffff; font-size:12px"
  );

  return new Promise(function (resolve, reject) {

    let funcArray = [];

    funcArray.push(getBeforeMessages(type, from, to, timestamp, limit));
    funcArray.push(getAfterMessages(type, from, to, timestamp, limit));

    Promise.all(funcArray).then(data => {
      let before = data[0];
      let after = data[1];

      let previous_timestamp = null;
      let next_timestamp = null;
      let search_anchor_point = null;
      let messages = {};
      let finalMessages = null;
      if (before && before.result) {

        messages = before.result.reduce((acc, item) => {

          if (!previous_timestamp) {
            previous_timestamp = item.created_at;
          }

          item.from = { id: item.sender_id };
          item.isUploading = "DONE";
          if (item.related_message && item.related_message.sender_id) {
            item.related_message.from = { id: item.related_message.sender_id }
          }
          if (item.file_uris && Array.isArray(item.file_uris) && item.file_uris.length > 0) {
            item.content_url = item.file_uris[0].content_url;
            item.thumbnail_url = item.file_uris[0].thumbnail_url;
          }
          acc[item.mid] = item;

          return acc;
        }, {});
      }

      if (after && after.result) {
        finalMessages = after.result.reduce((acc, item) => {

          if (!search_anchor_point) {
            search_anchor_point = item.mid;
          }
          next_timestamp = item.created_at;

          if (item.file_uris && Array.isArray(item.file_uris) && item.file_uris.length > 0) {
            item.content_url = item.file_uris[0].content_url;
            item.thumbnail_url = item.file_uris[0].thumbnail_url;
          }

          item.from = { id: item.sender_id };
          item.isUploading = "DONE";

          if (item.related_message && item.related_message.sender_id) {
            item.related_message.from = { id: item.related_message.sender_id }
          }
          acc[item.mid] = item;

          return acc;
        }, messages);

      }

      if (!finalMessages) {
        finalMessages = messages;
      }

      resolve({ messages: finalMessages, previous_timestamp, next_timestamp, search_anchor_point });
    })

  });

};

export const getOldMessages = (teamName, type, from, to, timestamp, limit) => {
  let tempType = 0;
  if (type === 3 || type === 4) {
    tempType = 1;
  }

  console.log(
    `%c [ service chatService getOldMessages ] - SessionId : ${auth.getSession()}, Data : ${JSON.stringify(
      { teamName, type, from, to, timestamp, limit }
    )}`,
    "background: #990033; color: #ffffff; font-size:12px"
  );

  let unseen_messages = [];

  return new Promise(function (resolve, reject) {
    let url = `${chatHistoryUrl}/api/v1/conversation/messages/before?type=${tempType}&dateFrom=${timestamp}&from=${from}&to=${to}&limit=${limit}`;

    let last_seen_mid_history = null;

    fetch(url, {
      method: "GET",
      headers: {
        "authorization": auth.getBearerToken()
      }
    })
      .then(res => {
        return res.json();
      })
      .then(data => {

        console.log(
          `%c [ service chatService getOldMessages Response ] - SessionId : ${auth.getSession()}, Data : ${JSON.stringify(
            data
          )}`,
          "background: #cc9900; color: #ffffff; font-size:12px"
        );

        if (data && data.isSuccess && data.result) {
          let prev_timestamp = null;

          let messages = data.result.reduce((acc, item) => {
            if (!prev_timestamp) {
              prev_timestamp = item.created_at;
            }

            if (!item.is_seen) {
              unseen_messages.push(item.mid);
            }
            last_seen_mid_history = item.mid;
            item.from = { id: item.sender_id };
            item.isUploading = "DONE";

            if (item.related_message && item.related_message.sender_id) {
              item.related_message.from = { id: item.related_message.sender_id }
            }

            if (item.file_uris && Array.isArray(item.file_uris) && item.file_uris.length > 0) {
              item.content_url = item.file_uris[0].content_url;
              item.thumbnail_url = item.file_uris[0].thumbnail_url;
            }

            acc[item.mid] = item;

            return acc;
          }, {});

          resolve({ messages, prev_timestamp, unseen_messages, last_seen_mid_history })

        } else {
          //break operation and return empty list
          resolve({ messages: {}, prev_timestamp: null, unseen_messages, last_seen_mid_history });
        }
      })
      .catch(ex => {
        reject(ex);
      });
  });
};

export const getOldMessagesInteractions = (to, timestamp, limit) => {
  console.log(
    `%c [ service chatService getOldMessagesInteractions ] - SessionId : ${auth.getSession()}, Data : ${JSON.stringify(
      { to, timestamp, limit }
    )}`,
    "background: #990033; color: #ffffff; font-size:12px"
  );

  let unseen_messages = [];

  return new Promise(function (resolve, reject) {
    let url = `${chatHistoryUrl}/api/v1/interaction/messages/before?interactionId=${to}&dateFrom=${timestamp}&limit=${limit}`;

    let last_seen_mid_history = null;

    fetch(url, {
      method: "GET",
      headers: {
        "authorization": auth.getBearerToken()
      }
    })
      .then(res => {
        return res.json();
      })
      .then(data => {

        console.log(
          `%c [ service chatService getOldMessagesInteractions Response ] - SessionId : ${auth.getSession()}, Data : ${JSON.stringify(
            data
          )}`,
          "background: #cc9900; color: #ffffff; font-size:12px"
        );

        if (data && data.isSuccess && data.result) {
          let prev_timestamp = null;

          let messages = data.result.reduce((acc, item) => {
            if (!prev_timestamp) {
              prev_timestamp = item.created_at;
            }

            if (!item.is_seen) {
              unseen_messages.push(item.mid);
            }
            last_seen_mid_history = item.mid;
            item.from = { id: item.sender_id };
            item.isUploading = "DONE";

            if (item.related_message && item.related_message.sender_id) {
              item.related_message.from = { id: item.related_message.sender_id }
            }

            if (item.file_uris && Array.isArray(item.file_uris) && item.file_uris.length > 0) {
              item.content_url = item.file_uris[0].content_url;
              item.thumbnail_url = item.file_uris[0].thumbnail_url;
            }

            acc[item.mid] = item;

            return acc;
          }, {});

          resolve({ messages, prev_timestamp, unseen_messages, last_seen_mid_history })

        } else {
          //break operation and return empty list
          resolve({ messages: {}, prev_timestamp: null, unseen_messages, last_seen_mid_history });
        }
      })
      .catch(ex => {
        reject(ex);
      });
  });
};

export const getAllMessagesForDates = (
  teamName,
  type,
  from,
  to,
  date,
  dateArr,
  limit,
  pageState,
  allMessages = {}
) => {
  let currentPageState = pageState;
  let currentDate = date;

  return new Promise(function (resolve, reject) {
    let url = `${chatApiUrl}/conversation/messages?type=${type}&from=${from}&to=${to}&date=${currentDate}&limit=${limit}`;

    if (currentPageState) {
      url = `${url}&state=${currentPageState}`;
    }

    fetch(url, {
      method: "GET",
      headers: {
        "authorization": auth.getToken(),
        "Content-Type": "application/json",
        "tenant-token": auth.getTenantToken()
      }
    })
      .then(res => {
        return res.json();
      })
      .then(data => {
        if (data && data.isSuccess && data.result) {
          let messages = data.result.messages.reduce((acc, item) => {
            acc[item.mid] = item;

            return acc;
          }, {});

          allMessages = { ...allMessages, ...messages };

          if (data.result.pageState) {
            //have more messages
            return getAllMessagesForDates(
              teamName,
              type,
              from,
              to,
              currentDate,
              dateArr,
              limit,
              data.result.pageState,
              allMessages
            )
              .then(data => {
                resolve(data);
              })
              .catch(ex => {
                resolve(null);
              });
          } else {
            if (dateArr && dateArr.length > 0) {
              let newDate = dateArr[0];
              dateArr.shift();
              return getAllMessagesForDates(
                teamName,
                type,
                from,
                to,
                newDate,
                dateArr,
                limit,
                null,
                allMessages
              )
                .then(data => {
                  resolve(data);
                })
                .catch(ex => {
                  resolve(null);
                });
            } else {
              resolve(allMessages);
            }
          }
        } else {
          //break operation and return empty list
          resolve(null);
        }
      })
      .catch(ex => {
        reject(ex);
      });
  });
};

//Interactions
export const getInteractions = user => {

  console.log(
    `%c [ service chatService getInteractions ] - SessionId : ${auth.getSession()}, Data : ${JSON.stringify(
      { user }
    )}`,
    "background: #990033; color: #ffffff; font-size:12px"
  );

  return new Promise(function (resolve, reject) {
    let url = `${chatApiUrl}/interactions/${user}`;

    fetch(url, {
      method: "GET",
      headers: {
        "authorization": auth.getToken(),
        "Content-Type": "application/json",
        "tenant-token": auth.getTenantToken()
      }
    })
      .then(res => {
        return res.json();
      })
      .then(data => {

        console.log(
          `%c [ service chatService getInteractions Response ] - SessionId : ${auth.getSession()}, Data : ${JSON.stringify(
            data
          )}`,
          "background: #cc9900; color: #ffffff; font-size:12px"
        );

        if (data && data.isSuccess) {
          let interactions = data.result.reduce((acc, item) => {
            acc[item.interaction_id] = item;
            return acc;
          }, {});

          resolve(interactions);
        } else {
          //break operation and return empty list
          reject(new Error("Error occurred"));
        }
      })
      .catch(ex => {
        reject(ex);
      });
  });
};

export const migrateChatData = (userName, contact, displayName) => {
  return apiFetch(
    `${chatApiUrl}/conversation/${userName}/migrateTo/${contact}/${displayName} `,
    {
      method: "put"
    }
  )
    .then(function (response) {
      return response.json();
    })
    .then(function (data) {
      return data.isSuccess;
    })
    .catch(error => {
      return false;
    });
};

export const getMediaFromHistory = (type, dateFrom, from, to, limit) => {
  console.log(
    `%c [ service chatService getMediaFromHistory ] - SessionId : ${auth.getSession()}, Data : ${JSON.stringify(
      { type, dateFrom, from, to, limit }
    )}`,
    "background: #990033; color: #ffffff; font-size:12px"
  );

  return new Promise(function (resolve, reject) {
    let url = `${chatHistoryUrl}/api/v1/conversation/messages?type=${type}&dateFrom=${dateFrom}&from=${from}&to=${to}&limit=${limit}&filterBy=media&filterValue=true`;

    fetch(url, {
      method: "GET",
      headers: {
        "authorization": auth.getBearerToken()
      }
    }).then(res => {
      return res.json();
    }).then(data => {
      console.log(
        `%c [ service chatService getMediaFromHistory Response ] - SessionId : ${auth.getSession()}, Data : ${JSON.stringify(
          data
        )}`,
        "background: #cc9900; color: #ffffff; font-size:12px"
      );
      if (data && data.isSuccess) {
        resolve(data.result);
      } else {
        //break operation and return empty list
        reject(new Error("Error occurred"));
      }
    })
      .catch(ex => {
        reject(ex);
      });
  });
};

export const getInteractionsForCustomer = (customerId, timestamp, limit) => {
  console.log(
    `%c [ service chatService getInteractionsForCustomer ] - SessionId : ${auth.getSession()}, Data : ${JSON.stringify(
      { customerId, timestamp, limit }
    )}`,
    "background: #990033; color: #ffffff; font-size:12px"
  );
  return new Promise(function (resolve, reject) {
    let url = `${chatHistoryUrl}/api/v1/interaction/timeline/${customerId}?dateFrom=${timestamp}&limit=${limit}`;

    fetch(url, {
      method: "GET",
      headers: {
        "authorization": auth.getBearerToken()
      }
    }).then(res => {
      return res.json();
    }).then(data => {

      console.log(
        `%c [ service chatService getInteractionsForCustomer Response ] - SessionId : ${auth.getSession()}, Data : ${JSON.stringify(
          data
        )}`,
        "background: #cc9900; color: #ffffff; font-size:12px"
      );

      if (data && data.isSuccess) {
        resolve(data.result);
      } else {
        //break operation and return empty list
        reject(new Error("Error occurred"));
      }
    })
      .catch(ex => {
        reject(ex);
      });
  });
};

export const sendProfanityMessage = (message, interactionId) => {

  if (__APP_PROFANITY_SEND_REPORT__ === "YES") {

    let profanityData = {
      data: {
        timestamp: Date.now(),
        agentId: auth.getUserId(),
        agentName: auth.getDisplayName(),
        sessionId: auth.getSession(),
        message: message,
        interactionId
      },
      type: "profanity-message"
    };
    liveChatClient.sendCustomMessage(
      "profanity-message",
      JSON.stringify(profanityData),
      "service:RPT"
    );

  }

}
