import React, { useState ,useEffect} from "react";
import MenuIcon from "../MenuIcon/MenuIcon";
import { VideoOff, Video } from "react-feather";
import { useDispatch, useSelector ,shallowEqual} from "react-redux";
import { onCallVideoMuteUnmute } from "../../../redux/actions/callControlsActions";

const VideoMuteButton = ({
  muteUnmuteAudioVideo,
  size,
  color,
  status,
  className,caller
}) => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const [isVideoMute, setVideoMute] = useState(state.callControls.controls.isVideoMuted);

  const muteUnmuteVideo = (val) => {
    try {     
      setVideoMute(val);
      muteUnmuteAudioVideo(state.callControls.controls.isCallMuted, val);
      dispatch(onCallVideoMuteUnmute({userId:caller,callType:'VIDEO_CALL' ,isOperated:true ,isMuted:val}));
    } catch (error) {
      console.error("VideoMuteButtonE", error);
    }
  };

 
  console.log(
    "%cSOFT PHONE RENDER BUTTON VideoMuteButton",
    "color:#04A746; font-family:'Ubuntu'; display: block;font-weight:bold; font-size:12px;"
  );
  return (
    <div
      title={`Video ${isVideoMute ? "Enable" : "Disable"}`}
      className={`${className} ${isVideoMute ? "active" : ""}`}
    >
      <MenuIcon
        size={size}
        color={color}
        status={status}
        onClick={() => muteUnmuteVideo(!isVideoMute)}
        icon={isVideoMute ? <VideoOff /> : <Video />}
      />
    </div>
  );
};

export default VideoMuteButton;
