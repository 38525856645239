import * as types from "../actions/actionTypes";
import produce from "immer";

export const loggedUserReducer = produce((draft = {}, action) => {
    switch (action.type) {
        case types.USER_LOGGED_IN:
            {
                draft = { ...action.profile }
                return draft;
            }
        default:
            return draft;
    }
});