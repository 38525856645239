import * as types from "./actionTypes";
import { ClienteleService } from "../../service/clienteleService";
import initialState from "../reducers/initialState";
import UserService from "../../service/userService";
import chatMap from "../../config/json/channelMap";
import * as logger from '../../lib/logSdk/logger';

const userService = new UserService();

export function getClientProfileSuccess(clientProfile) {
  return { type: types.GET_CLIENT_PROFILE_SUCCESS, clientProfile };
}

export function getClientProfileFail(clientProfile) {
  return { type: types.GET_CLIENT_PROFILE_FAIL, clientProfile };
}

export function getClientProfileByContact(contactId, contactTypeId) {
  return async dispatch => {
    try {
      const contactType = chatMap[contactTypeId];

      let clientProfile = await ClienteleService.getClientProfileByContact(
        contactId,
        contactType
      );
      if (clientProfile) {
        clientProfile.hadProfile = true;
      }
      let loadedProfile =
        clientProfile == null ? initialState.clientProfile : clientProfile;

      loadedProfile = {
        ...loadedProfile,
        contact: contactId,
        contactType: contactType
      };

      dispatch(getClientProfileSuccess(loadedProfile));
    } catch (error) {
      console.error("clientAction","getClientProfileByContact", error);
      logger.error("clientAction","getClientProfileByContact", error.message);
      dispatch(getClientProfileFail(initialState.clientProfile));
    }
  };
}

export function getClientProfileByUserName(userName) {
  return async dispatch => {
    try {
      let clientProfile = await ClienteleService.getClientProfileByUserName(
        userName
      );
      if (clientProfile) {
        clientProfile.hadProfile = true;
      }
      let loadedProfile =
        clientProfile == null
          ? { ...initialState.clientProfile }
          : clientProfile;
      loadedProfile.userName = userName;
      dispatch(getClientProfileSuccess(loadedProfile));
    } catch (error) {
      console.error("clientAction","getClientProfileByUserName", error);
      logger.error("clientAction","getClientProfileByUserName", error.message);
      dispatch(getClientProfileFail(initialState.clientProfile));
    }
  };
}

export function getClientProfileByAgentName(userName) {
  return async dispatch => {
    try {
      let clientProfile = await userService.getUser(userName);
      if (clientProfile) {
        clientProfile.hadProfile = true;
        clientProfile.avatarUrl = clientProfile.user.avatar_url;
      }
      let loadedProfile =
        clientProfile == null ? initialState.clientProfile : clientProfile;

      dispatch(getClientProfileSuccess(loadedProfile));
    } catch (error) {
      console.error("clientAction","getClientProfileByAgentName", error);
      logger.error("clientAction","getClientProfileByAgentName", error.message);
      dispatch(getClientProfileFail(initialState.clientProfile));
    }
  };
}
