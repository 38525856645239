import React from 'react';
import PropTypes from 'prop-types';
import {UserPlus, Pause, Mic, MicOff, PhoneOutgoing, Airplay} from "react-feather";
import {ChatUser, MenuIcon, TitleWithSmallText, UserImage, ParticipationImage} from "../index";
import {Scrollbars} from "react-custom-scrollbars";


//online
//offline
//busy
//other
const SelectedIcon = ({active,icon,size,onClick}) => (
    <>
        <div className={`selected-wrapper ${size} ${active ? 'selected' : ''}` } onClick={onClick}>
            <div className={`select d-flex align-items-center justify-content-center ${size}  `} onClick={onClick}>
                {icon}
            </div>
        </div>
    </>
);

// OutGoingCall.propTypes = {
//     status: PropTypes.string.isRequired,
// };

SelectedIcon.defaultProps = {};

export default SelectedIcon;
