import * as types from "../actions/actionTypes";
import produce from "immer";
import initialState from "../reducers/initialState";

export const clientReducer = produce(
  (draft = initialState.clientProfile, action) => {
    switch (action.type) {
      case types.GET_CLIENT_PROFILE_FAIL:
      case types.GET_CLIENT_PROFILE_SUCCESS: {
        draft = action.clientProfile;
        return draft;
      }
      default:
        return draft;
    }
  }
);
