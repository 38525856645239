import React from 'react';
import PropTypes from 'prop-types';


const ForwardedLabel = ({isForwardedMsg, textAlign}) => (
    <>
        {/*forwarded message
        ${textAlign === 'left' ? 'justify-content-start' : 'justify-content-end'}
        */}
        <div className={`${isForwardedMsg ? 'enable-forwarded' : 'disable-forwarded'}`}>
            <div
                className={`forwarded-msg-wrp d-flex justify-content-start`}>
                <div className={'forwarded-icon'}>
                    <i className={'material-icons'}>
                        reply
                    </i>
                </div>
                <div className={'forwarded-text'}>Forwarded</div>
            </div>
        </div>
        {/*end */}
    </>
);

ForwardedLabel.propTypes = {
    isForwardedMsg: PropTypes.bool,
};

ForwardedLabel.defaultProps = {
    isForwardedMsg: false
};

export default ForwardedLabel;
