import React, { useEffect } from "react";
import { notification } from 'antd';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as meetingActions from "../../../redux/actions/meetingActions";
import { MeetingSDK } from '../../../lib/MeetingsSdk';
import * as auth from "../../../utils";
import { message } from "antd/lib/index";
import * as logger from "../../../lib/logSdk/logger";
import { subscribeToEvents } from "../../../utils/eventEmitter";
import uuid from "uuid/v1";
import momentTz from 'moment-timezone';
import { NotificationService } from '../../../service/notificationService';
import { meetingSnoozeDuration } from '../../../config/baseUrls';

const meetingSDK = new MeetingSDK();

const MeetingAlerts = (props) => {

    useEffect(() => {

        subscribeToEvents("meeting_created", (msg) => {
            let uniqueId = uuid();

            if (msg.otherData && msg.otherData.creator && msg.otherData.creator !== auth.getUserId()) {
                notification['info']({
                    key: uniqueId,
                    message: 'Meeting Created',
                    placement: 'bottomRight',
                    duration: 0,
                    description: (<div className="row">
                        <div className="row">
                            <div className="col-md-12" style={{ marginTop: "6px" }}>{`Meeting ${msg.otherData.title} has been created and is scheduled to start at ${momentTz.tz(`${momentTz(msg.otherData.startDate).tz('UTC').format('YYYY-MM-DD')} ${msg.otherData.startTime}`, 'UTC').local().format('YYYY-MM-DD hh:mm A')}`}</div>

                        </div>
                    </div>)
                });

            }



        });

        subscribeToEvents("meeting_updated", (msg) => {
            let uniqueId = uuid();

            if (msg.otherData && msg.otherData.creator && msg.otherData.creator !== auth.getUserId()) {
                notification['info']({
                    key: uniqueId,
                    message: 'Meeting Updated',
                    placement: 'bottomRight',
                    duration: 0,
                    description: (<div className="row">
                        <div className="row">
                            <div className="col-md-12" style={{ marginTop: "6px" }}>{`Meeting ${msg.otherData.title} has been updated by owner and is scheduled to start at ${momentTz.tz(`${momentTz(msg.otherData.startDate).tz('UTC').format('YYYY-MM-DD')} ${msg.otherData.startTime}`, 'UTC').local().format('YYYY-MM-DD hh:mm A')}`}</div>

                        </div>
                    </div>)
                });

            }



        });

        subscribeToEvents("meeting_started", (msg) => {

            let uniqueId = uuid();

            if (msg.otherData && msg.otherData.creator && msg.otherData.creator !== auth.getUserId()) {
                notification['info']({
                    key: uniqueId,
                    message: 'Meeting Reminder',
                    placement: 'bottomRight',
                    duration: 0,
                    description: (<div className="row">
                        <div className="row">
                            <div className="col-md-12" style={{ marginTop: "6px" }}>{`Meeting ${msg.otherData.title} has been started`}</div>
                            <div className="col-md-12 mt-10">
                                <div className="btn btn-primary col-md-4 mr-5" onClick={() => {
                                    meetingSDK.getMeetingById(msg.otherData.meetingId).then(mData => {

                                        meetingSDK.joinMeeting(msg.otherData.meetingId, auth.getUserId()).then(data => {
                                            if (data.statusCode === 1) {
                                                notification.close(uniqueId);
                                                props.meeting_actions.startMeeting(data.meetingId, data.interactionId, msg.otherData.title);
                                                let extIdObj = mData.scheduleMeeting.configurations.find(x => x.feature === 'externalId');

                                                props.onClickVideoCall("meeting", data.interactionId, { ...mData.scheduleMeeting, ['externalId']: extIdObj ? extIdObj.featureValue : '' });
                                                /* props.onClickVideoCall("meeting", data.interactionId, mData.scheduleMeeting); */
                                            } else {
                                                if (data.statusCode === 0) {
                                                    message.error(data.statusMessage);

                                                } else {
                                                    message.error("Error occurred while trying to join meeting");
                                                }

                                                console.log('MeetingAlerts', 'joinMeeting', data.statusMessage);
                                                logger.debug('MeetingAlerts', 'joinMeeting', data.statusMessage);

                                            }
                                        }).catch(ex => {
                                            console.log('MeetingAlerts', 'joinMeeting', ex);
                                            logger.debug('MeetingAlerts', 'joinMeeting', ex);
                                            message.error("Error occurred while trying to join meeting");

                                        })

                                    }).catch(ex => {
                                        console.log('MeetingAlerts', 'joinMeeting', ex);
                                        logger.debug('MeetingAlerts', 'joinMeeting', ex);
                                        message.error("Error occurred while trying to join meeting");

                                    })

                                }}>Join</div>
                                <div className="btn btn-primary col-md-4" onClick={() => {
                                    notification.close(uniqueId);
                                }}>Cancel</div>
                            </div>
                        </div>
                    </div>)
                });

            }



        });

        subscribeToEvents("meeting_reminder", (msg) => {

            let uniqueId = uuid();
            let snoozeTime = parseInt(meetingSnoozeDuration.split(',')[0]);

            if (msg.otherData) {

                meetingSDK.getMeetingById(msg.otherData.meetingId).then(mData => {

                    notification['info']({
                        key: uniqueId,
                        message: 'Meeting Reminder',
                        placement: 'bottomRight',
                        duration: 0,
                        description: (<div className="row">
                            <div className="row">
                                <div className="col-md-12" style={{ marginTop: "6px" }}>{`Meeting ${mData.scheduleMeeting.title} is scheduled to start at ${momentTz.tz(`${momentTz(mData.scheduleMeeting.startDate).tz('UTC').format('YYYY-MM-DD')} ${mData.scheduleMeeting.startTime}`, 'UTC').local().format('YYYY-MM-DD hh:mm A')}`}</div>
                                <div className="col-md-3" style={{ marginTop: "6px" }}>Snooze</div>
                                <div id="meetingReminderAlert" className="col-md-5">
                                    <select name="snoozeFor"
                                        onChange={(evt) => {
                                            snoozeTime = evt.target.value;
                                            //setSnoozeTime(evt.target.value);
                                        }} className="form-control web-rectangle ronchi-border-1px " id="">
                                        {
                                            meetingSnoozeDuration.split(',').map(duration => {
                                                return (<option value={parseInt(duration)}>{`${duration} minutes`}</option>)
                                            })
                                        }
                                    </select>
                                </div>
                                <div className="col-md-4"></div>
                                <div className="col-md-12 mt-10">
                                    <div className="btn btn-primary col-md-4 mr-5" onClick={() => {

                                        NotificationService.addScheduleNotification({
                                            notificationHandler: "UCHandler",
                                            startDate: momentTz().add(snoozeTime, 'minutes').tz("UTC").format('YYYY-MM-DD'),
                                            time: momentTz().add(snoozeTime, 'minutes').tz("UTC").format('HH:mm'),
                                            isRecurrence: false,
                                            notificationData: JSON.stringify({
                                                Message: JSON.stringify({ message_type: 'MEETING_REMINDER', message: 'Meeting Reminder', otherData: { meetingId: msg.otherData.meetingId, title: msg.otherData.title, creator: msg.otherData.creator, startDate: msg.otherData.startDate, startTime: msg.otherData.startTime } }),
                                                MessageType: 'SNOOZEMEETING',
                                                MeetingCreator: msg.otherData.creator,
                                                Participants: [auth.getUserId()]
                                            }),
                                            appId: "UC",
                                            appReferenceId: `${msg.otherData.meetingId}`,
                                            appReferenceType: `Snooze_${msg.otherData.meetingId}`,
                                            createdBy: auth.getUserId()
                                        }).then(result => {

                                            if (result.statusCode === 0) {
                                                message.error(result.statusMessage);
                                            } else {
                                                if (result.statusCode !== 1) {
                                                    message.error('Error occurred setting meeting reminder');
                                                }
                                            }

                                        }).catch(ex => {

                                            console.error(ex);

                                            message.error('Error occurred setting meeting reminder');

                                        });

                                        notification.close(uniqueId);
                                    }}>Snooze</div>
                                    <div className="btn btn-primary col-md-4" onClick={() => {
                                        notification.close(uniqueId);
                                    }}>Dismiss</div>
                                </div>
                            </div>
                        </div>)
                    });

                }).catch(ex => {
                    console.error(ex);
                })


            }



        });



    }, []);
    return (<></>);
}

function mapDispatchToProps(dispatch) {
    return {
        meeting_actions: bindActionCreators(meetingActions, dispatch),
    };
}

export default connect(null, mapDispatchToProps)(MeetingAlerts);

