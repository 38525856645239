import React from 'react';
import PropTypes from 'prop-types';
import {MenuIcon} from "../index";
import {Star} from "react-feather";

import './device-not-support.scss'


const DeviceNotSupported = ({lastSeen, isStarred, lastSeenStatus}) => (
    <>
        <section className={'device-not-supported-wrapper not-supported'}>

            <div className={'device-inner-wrapper'}>
                    <div className={'text-wrapper'}>
                        Did you Know ?
                    </div>

                    <div className={'text-wrapper-body'}>
                        Responsive design is not supported everywhere,<br/>
                        Please switch to your desktop or laptop to use
                        tetherfiCloud
                    </div>
            </div>
        </section>
    </>
);

// ChatFooter.propTypes = {
//     onClick: PropTypes.func.isRequired,
//     isStarred: PropTypes.string.isRequired,
//     size: PropTypes.string.isRequired,
//     type: PropTypes.string.isRequired,
//     disabled: PropTypes.bool
// };

DeviceNotSupported.defaultProps = {
    isStarred: false,
    lastSeenStatus: false
};

export default DeviceNotSupported;
