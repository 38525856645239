class CreateGroupModel {
    Name;
    Id;
    CreatedDateTime;
    Description;
    CreatedBy;
    Participants;
    IconPath;

    constructor () {
        this.Name = "";
        this.Id = "";
        this.CreatedDateTime = new Date();
        this.Description = "";
        this.CreatedBy = "";
        this.Participants = [];
        this.IconPath = "";
    }
}

class ModifyGroupParticipantModel {
    GroupId;
    ParticipantsModifiedDateTime;
    Participants;
    RequestorUserId;

    constructor () {
        this.GroupId = "";
        this.ParticipantsModifiedDateTime = new Date();
        this.Participants = [];
        this.RequestorUserId = "";
    }
}

class GroupMessageContentDetails {
    Message;
    MessageType;
    MessageDateTime;

    constructor () {
        this.Message = "";
        this.MessageType = "";
        this.MessageDateTime = "";
    }
}

class ModifyGroupNameModel {
    Id;
    Name;
    RequestorUserId;

    constructor () {
        this.Id = "";
        this.Name = "";
        this.RequestorUserId = "";
    }
}

class ModifyGroupDescriptionModel {
    Id;
    Description;
    RequestorUserId;

    constructor () {
        this.Id = "";
        this.Description = "";
        this.RequestorUserId = "";
    }
}

class DeleteGroupModel {
    Id;
    RequestorUserId;
    constructor () {
        this.Id = "";
        this.RequestorUserId = "";
    }
}

class GroupListingRequestModel {
    UserId;
    LastMessageId;
    constructor () {
        this.UserId = "";
        this.LastMessageId = 0;
    }
}

class GroupAdminModificationRequestModel {
    GroupId;
    UserId;
    RequestorUserId;
    constructor () {
        this.GroupId = "";
        this.UserId = "";
        this.RequestorUserId = "";
    }
}

class ModifyGroupIconModel {
    GroupId;
    IconPath;
    RequestorUserId;

    constructor () {
        this.GroupId = "";
        this.IconPath = "";
        this.RequestorUserId = "";
    }
}

const groupMessages = {
    common: {
        userNotLoggedIn: "User is not logged in",
    },
}

export {
    CreateGroupModel, ModifyGroupParticipantModel, GroupMessageContentDetails, ModifyGroupNameModel, ModifyGroupDescriptionModel,
    DeleteGroupModel, GroupListingRequestModel, GroupAdminModificationRequestModel, ModifyGroupIconModel, groupMessages
};