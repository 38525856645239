exports.fetchDefaults = function (fetch) {
  const auth = require("../utils");
  const token = auth.getToken();
  const defaults = {
    headers: {
      "Content-Type": "application/json",
      "tenant-token": auth.getTenantToken()
    }
  };

  if (token) {
    defaults.headers.authorization = token;
  }
  return Object.assign(exports.fetch.bind(null, fetch, null, defaults), fetch);
};

exports.fetch = function (fetch, rootUrl, defaults, url, opts) {
  if (rootUrl != null) url = rootUrl.resolve(url);
  if (typeof defaults === "function") defaults = defaults(url, opts);
  return fetch(
    url,
    opts == null ? defaults : Object.assign({}, defaults, opts)
  );
};
