import { Logger } from './logger.js';

class Persist {
    constructor (session) {
        this.session = session;
    }

    storeSession = () => {
        sessionStorage.setItem("livechat.session", JSON.stringify(this.session));
    }

    updateSent = () => {
        sessionStorage.setItem("livechat.lsent", this.session.mid);
    }

    updateReceived = () => {
        sessionStorage.setItem("livechat.lreceived", this.session.lastRecvMid);
    }

    loadSession = () => {
        var ss = sessionStorage.getItem("livechat.session");

        if (!ss)
            return false;

        try {
            this.session = JSON.parse(ss);
        }
        catch (e) {
            return false;
        }

        var basic = this.session.sessionID &&
            this.session.auth &&
            this.session.userid &&
            (!this.session.bEndReceived);

        if (!basic) {
            Logger.info("Basic session loading failed");
            return false;
        }

        var sent = sessionStorage.getItem("livechat.lsent");
        var received = sessionStorage.getItem("livechat.lreceived");

        if (!(sent && received)) {
            Logger.warn("mids not found");
            return false;
        }

        this.session.mid = parseInt(sent);
        this.session.lastRecvMid = parseInt(received);

        if (!(this.session.mid >= 0 && this.session.lastRecvMid >= 0)) {
            Logger.warn("mids are not ints");
            return false;
        }

        return true;
    }

    clear = () => {
        Logger.debug(`Removing Session Storage for Persist`);
        sessionStorage.removeItem("livechat.lsent");
        sessionStorage.removeItem("livechat.lreceived");
        sessionStorage.removeItem("livechat.session");
    }

}

export { Persist };