import * as types from "./actionTypes";
import { MeetingSDK } from "../../lib/MeetingsSdk";
import * as currentMeetingActions from "./currentMeetingActions";
import { message } from "antd/lib/index";

let meetingsClient = new MeetingSDK();

export function getMeetingStatuses(userIds) {
    return dispatch => {
        meetingsClient.getMeetingsStatus(userIds).then(result => {

            if (result && result.meetingStatuses) {
                let statuses = result.meetingStatuses.reduce((acc, statusInfo) => {
                    acc[statusInfo.meetingId] = statusInfo;
                    return acc;
                }, {});
                dispatch(meetingStatusesLoaded(statuses));
            } else {
                dispatch(meetingStatusesLoaded({}));
            }

        }).catch(ex => {
            console.error(ex);
            dispatch(meetingStatusesLoaded({}));
        });
    }
}

export function startMeeting(meetingId, interactionId, title) {
    return dispatch => {
        let data = { meetingId, interactionId };
        dispatch({ type: types.START_MEETING, data });
        dispatch(currentMeetingActions.joinMeeting(meetingId, interactionId, title));
    }
}

export function deleteMeeting(meetingId) {
    return { type: types.DELETE_MEETING, meetingId }
}

export function meetingStatusesLoaded(meetingStatusList) {
    return { type: types.MEETINGS_STATUSES_LOADED, meetingStatusList };
}