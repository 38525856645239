import { AppEventMessage } from "./message.js";
import { Logger } from './logger.js';

class GeoLocation {

    watchId = 0;

    constructor () {
        if (this.GetGeoLocationBrowserCompatibilityStatus() === false) {
            Logger.error("window.navigator.geolocation is not supported in this browser");
            throw "NOT_SUPPORTED";
        }
    }

    GetGeoLocationBrowserCompatibilityStatus = () => {
        if (window.navigator != null && 'geolocation' in window.navigator)
            return true;
        else
            return false;
    }

    GetCurrentLocation = (successCallbackFunction, errorCallbackFunction = null, options = null) => {
        window.navigator.geolocation.getCurrentPosition(successCallbackFunction, errorCallbackFunction, options);
    }

    StartWatchingLocation = (successCallbackFunction, errorCallbackFunction = null, options = null) => {
        this.watchId = window.navigator.geolocation.watchPosition(successCallbackFunction, errorCallbackFunction, options);
    }

    StopWatchingLocation = () => {
        window.navigator.geolocation.clearWatch(this.watchId);
    }
}

class GeoLocationObject {
    userId;
    errorCode;
    errorMessage;
    timestamp;
    accuracy;
    altitude;
    altitudeAccuracy;
    heading;
    latitude;
    longitude;
    speed;

    constructor () {
        this.userId = "";
        this.errorCode = 0;
        this.errorMessage = "";
        this.coordinates = {};
        this.timestamp = {};
        this.accuracy = 0;
        this.altitude = null;
        this.altitudeAccuracy = null;
        this.heading = null;
        this.latitude = 0
        this.longitude = 0;
        this.speed = null;
    }
}
class CurrentGeoLocationRequestModel {
    userId;

    constructor () {
        this.userId = "";
    }
}

class CurrentGeoLocationResponseModel extends CurrentGeoLocationRequestModel {
    livechat2api;
    requesterUserId;
    constructor (messageContent, requesterUserId, livechat2api) {
        super();
        this.userId = messageContent.userId;
        this.livechat2api = livechat2api;
        this.requesterUserId = requesterUserId;
    }

    accept = () => {
        try {
            new GeoLocation().GetCurrentLocation(
                (params) => {
                    try {
                        var location = new GeoLocationObject();
                        location.userId = this.userId;
                        location.timestamp = params.timestamp;
                        location.accuracy = params.coords.accuracy;
                        location.altitude = params.coords.altitude;
                        location.altitudeAccuracy = params.coords.altitudeAccuracy;
                        location.heading = params.coords.heading;
                        location.latitude = params.coords.latitude;
                        location.longitude = params.coords.longitude;
                        location.speed = params.coords.speed;

                        var responseObject = new AppEventMessage();
                        responseObject.requesterUserId = "";
                        responseObject.messageSubType = "response-current-location";
                        responseObject.content = location;

                        this.livechat2api.sendAppEvent(JSON.stringify(responseObject), "user:" + this.requesterUserId);
                    } catch (error) {
                        Logger.error(`Error in GetCurrentLocation 1`, error);
                    }
                },
                (params) => {
                    try {
                        var location = new GeoLocationObject();
                        location.userId = this.userId;
                        location.errorCode = params.code;
                        location.errorMessage = params.message;

                        var responseObject = new AppEventMessage();
                        responseObject.requesterUserId = "";
                        responseObject.messageSubType = "response-current-location";
                        responseObject.content = location;

                        this.livechat2api.sendAppEvent(JSON.stringify(responseObject), "user:" + this.requesterUserId);
                    } catch (error) {
                        Logger.error(`Error in GetCurrentLocation 2`, error);
                    }
                })
        } catch (error) {
            var location = new GeoLocationObject();
            location.userId = this.userId;
            location.errorCode = 3;
            location.errorMessage = error;

            var responseObject = new AppEventMessage();
            responseObject.requesterUserId = "";
            responseObject.messageSubType = "response-current-location";
            responseObject.content = location;

            this.livechat2api.sendAppEvent(JSON.stringify(responseObject), "user:" + this.requesterUserId);
        }
    }

    reject = () => {
        var location = new GeoLocationObject();
        location.userId = this.userId;
        location.errorCode = 4;
        location.errorMessage = "REQUEST_REJECTED";

        var responseObject = new AppEventMessage();
        responseObject.requesterUserId = "";
        responseObject.messageSubType = "response-current-location";
        responseObject.content = location;

        this.livechat2api.sendAppEvent(JSON.stringify(responseObject), "user:" + this.userId);
    }
}

export { GeoLocation, CurrentGeoLocationRequestModel, CurrentGeoLocationResponseModel };