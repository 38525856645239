import React from 'react';
import { getUserId } from '../../../utils';
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import liveChatClient from "../../../lib";
import { setWbState } from "./wbState";
import uuid from 'uuid/v1';
import * as ChatService from '../../../service/chatService';
import * as chatActions from '../../../redux/actions/chatActions';
import * as whiteboardActions from '../../../redux/actions/whiteboardActions';

class CoBrowse extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            visibility: false
        }
    }

    componentWillUnmount() {
        this.props.whiteboard_actions.disposeWhiteboard();
        //setWbState(false);
    }


    componentDidMount() {

        /* setWbState(true); */

        if (this.props.selected_profile) {

            let uniqueId = uuid();

            let msg = {
                v: 1,
                mid: uniqueId,
                sid: uniqueId,
                message_type: 'COBROWSE_REQUEST',
                from: {
                    id: getUserId(),
                    name: getUserId(),
                },
                to: {
                    id: this.props.selected_profile.username,
                    name: this.props.selected_profile.username,
                },
                created_at: Date.now(),
                message_content: '',
                other: {},
                conversation_type: this.props.selected_profile.conversation_type,
                message_action: 1
            };

            ChatService.sendChatMessage(this.props.selected_profile.conversation_type, msg.message_type, this.props.selected_profile.username, msg);
            this.props.chat_actions.sendNewMessageSuccess(msg);
        }

    }

    componentDidUpdate() {



    }



    render() {

        let displayStyle = { width: this.props.viewPointWidth - 380, height: this.props.viewPointHeight };

        if (this.props.whiteboard.wbState === 'MINIMIZED') {
            displayStyle.position = "absolute";
            displayStyle.left = "-9999px";
        }

        return (
            <>

                <div className={`chat-empty-wrapper`}
                    style={displayStyle}>
                    <iframe width={this.props.viewPointWidth - 380} height={this.props.viewPointHeight} src={`${this.props.whiteboard.extraData}?sessionid=${this.props.selected_profile.conversation_type === 0 ? [getUserId(), this.props.selected_profile.username].sort().join('_') : this.props.selected_profile.username}&userid=${uuid()}&cobrowse=true&cbsender=true`} style={{ position: "absolute" }}>
                    </iframe>

                </div>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        selected_profile: state.selected_profile,
        whiteboard: state.whiteboard
    };
}

function mapDispatchToProps(dispatch) {
    return {
        chat_actions: bindActionCreators(chatActions, dispatch),
        whiteboard_actions: bindActionCreators(whiteboardActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(CoBrowse);
