import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Accordion, Card, Button, useAccordionToggle } from 'react-bootstrap';
import { Scrollbars } from "react-custom-scrollbars";
import { MenuIcon } from "../../common";
import * as cannedMessageActions from '../../../redux/actions/cannedMessageActions';
import {
    Edit,
    ArrowDownCircle,
    Trash
} from "react-feather";
import { message as msg } from "antd/lib/index";

import * as eventEmitter from "../../../utils/eventEmitter";

function CustomToggle({ children, eventKey }) {
    const decoratedOnClick = useAccordionToggle(eventKey, () =>
        console.log('totally custom!'),
    );

    return (
        <>
            <MenuIcon
                onClick={decoratedOnClick}
                status={"material"}
                size={"sm-space"}
                icon={<ArrowDownCircle />}
            />

        </>

    );
}

const MyCannedMessages = props => {

    const [searchText, setSearchText] = useState("");

    const handleSearching = (sourceData) => {

        if (searchText) {
            //filter
            try {

                var regex = RegExp(searchText, "i");
                let filtered = Object.entries(sourceData).reduce((acc, [key, template]) => {

                    if (regex.test(template.TemplateName)) {
                        acc = { ...acc, ...{ [key]: template } };
                    }
                    return acc;
                }, {});

                return filtered;

            } catch (ex) {
                console.error(ex);
                return {};
            }

        } else {
            return sourceData;
        }

    }

    useEffect(() => {

        eventEmitter.subscribeToEvents("canned_message_filter", (searchText => {
            setSearchText(searchText);
        }), "my_canned");

    }, []);

    let filteredTemplates = handleSearching(props.my_canned_messages);

    const getTemplateMessageFormatted = (template) => {


        let message = template.TemplateMessage;

        if (template.Trigger) {

            let words = message.replace(template.Trigger, `<span style="color:blue;">${template.Trigger}</span>`);
            return { __html: words };

        } else {
            return { __html: `<span>${message}</span>` };
        }

    }


    return (
        <>
            {
                Object.entries(filteredTemplates).map(([key, template]) => {
                    return (
                        <div key={key} className={'msg-temp-wrapper sm d-flex '}>
                            <div className={'msg-tem-left-wrapper ' +
                                'd-flex flex-column'}>
                                <div className={'msg-temp-name trigger'}>
                                    {template.TemplateName}
                                </div>
                                <div className={'msg-body'}>
                                    <div dangerouslySetInnerHTML={getTemplateMessageFormatted(template)}>

                                    </div>
                                </div>
                            </div>
                            <div className={'msg-tem-right-wrapper edit-bar d-flex'}>
                                <div className={'action-btn-wrp btn-edit mr-1'} onClick={() => {
                                    eventEmitter.fireEvent("open_new_canned_editor", { isUpdate: true, updateData: template })
                                }}>
                                    <Edit />
                                </div>
                                <div className={'action-btn-wrp btn-delete'} onClick={() => {
                                    props.canned_message_actions.deleteUserCannedMessage(template.Uuid, (result) => {

                                        if (result) {
                                            msg.success("Canned message deleted");
                                        } else {
                                            msg.error("Error deleting canned message");
                                        }

                                    });
                                }}>
                                    <Trash />
                                </div>

                            </div>
                        </div>


                    )
                })
            }
        </>
    );
};

function mapStateToProps(state) {
    return {
        my_canned_messages: state.canned_messages.user
    };
}

function mapDispatchToProps(dispatch) {
    return {
        canned_message_actions: bindActionCreators(cannedMessageActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(MyCannedMessages);
