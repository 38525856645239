import { WEB_NOTIFICATION,PHONE_NOTIFICATION,RESET_NOTIFICATION} from "../actions/actionTypes";

const notificationControl = {
  controls: {
    isShowNotification: false,
    isShowPhoneNotification: false,
  },
};

const webNotificationReducer = (state = notificationControl, action) => {
  switch (action.type) {
    case WEB_NOTIFICATION:
      return {
        ...state,
        controls: {
          ...state.controls,
          isShowNotification: !state.controls.isShowNotification,
        },
        message: action.payload.message,
        duration: action.payload.duration,
        ...action.payload,
      };
      case PHONE_NOTIFICATION:
      return {
        ...state,
        controls: {
          ...state.controls,
          isShowPhoneNotification: !state.controls.isShowPhoneNotification,
         
        },
        ...action.payload,
      };
      case RESET_NOTIFICATION:
      return {
        ...state,
        controls: {
          ...state.controls,
          isShowPhoneNotification: !state.controls.isShowPhoneNotification,
        },
        ...action.payload,
      };
    default:
      return state;
  }
};

export default webNotificationReducer;

