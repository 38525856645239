import React from 'react';
import PropTypes from 'prop-types';
import { Download, Check, Eye } from "react-feather";
import { SelectedIcon, MenuIcon } from "../index";
import { Scrollbars } from "react-custom-scrollbars";
import * as fileService from "../../../service/fileService";


const MediaDocument = ({ name, date, active, fileType, fileName, size, viewPointHeight, selectThis, onClickOpenGallery, url }) => (
    <>
        <div className={`media-doc-wrapper  ${active ? 'selected' : 'default'}`}>
            <div className={`media-header d-flex justify-content-between`}>
                <div className={'name'}>{name}</div>
                <div className={'date'}>{date}</div>
            </div>
            <clear />
            <div className={`media-doc-body-wrapper d-flex align-items-center justify-content-between `}>
                <div className={'d-flex'}>
                    <div className={'selection-icon-wrapper  d-flex align-items-center'} onClick={selectThis}>
                        <SelectedIcon
                            active={active}
                            icon={<Check />} />
                    </div>
                    <div className={'svg-icon-wrapper d-flex  align-items-center justify-content-center'}
                        onClick={onClickOpenGallery}>
                        {fileType === 'txt' &&
                            <img className="img-fluid"
                                src={require("../../../assets/img/textSVG.svg")} alt="Document" />
                        }
                        {fileType === 'csv' &&
                            <img className="img-fluid"
                                src={require("../../../assets/img/csv.svg")} alt="Document" />
                        }
                        {fileType === 'ppt' &&
                            <img className="img-fluid"
                                src={require("../../../assets/img/pdf.svg")} alt="Document" />
                        }{fileType === 'pdf' &&
                            <img className="img-fluid"
                                src={require("../../../assets/img/pdf.svg")} alt="Documentr" />
                        }{(fileType === 'docx' || fileType === 'doc') &&
                            <img className="img-fluid"
                                src={require("../../../assets/img/docx.svg")} alt="Document" />
                        }{(fileType === 'xlsx' || fileType === 'xls') &&
                            <img className="img-fluid"
                                src={require("../../../assets/img/xls.svg")} alt="Document" />
                        }
                    </div>
                    <div className={'file-text-wrapper d-flex flex-column justify-content-center'}>
                        <div className={'file-name'}>
                            {fileName}
                        </div>
                        <div className={'size'}>
                            {size}
                        </div>
                    </div>
                </div>
                <div>
                    <div className={'left-icon-wrapper d-flex '}>
                        <MenuIcon size={'sm'}
                            icon={<Eye />}
                            onClick={fileType === 'pdf' ? () => {
                                window.open(url)
                            } : () => {
                                fileService.directDownloadFile(fileName ? fileName : 'file', url);
                            }} />
                    </div>
                </div>

            </div>
        </div>
    </>
);


MediaDocument.defaultProps = {};

export default MediaDocument;
