import * as types from "./actionTypes";
import * as ChatService from "../../service/chatService";
import * as logger from '../../lib/logSdk/logger';

export function getStarredMessages(conversation_type, timestamp, from, to) {
    return dispatch => {
        ChatService.getStarredMessages(conversation_type, timestamp, from, to).then(result => {
            dispatch(starredMessagesLoaded(result));
        }).catch(error => {
            console.error("starringActions", "getStarredMessages", error);
            logger.error("starringActions","getStarredMessages", error.message);
        });
    }
}

export function getNextPageOfStarredMessages(conversation_type, timestamp, from, to) {
    return dispatch => {
        ChatService.getStarredMessages(conversation_type, timestamp, from, to).then(result => {
            dispatch(starredMessagesPageLoaded(result));
        }).catch(error => {
            console.error("starringActions", "getNextPageOfStarredMessages", error);
            logger.error("starringActions","getNextPageOfStarredMessages", error.message);
        });
    }
}

export function starUnstarMessageSuccess(conv_user, message, is_starred) {
    return dispatch => { dispatch({ type: types.STAR_UNSTAR_MESSAGE_SUCCESS_ACTION, conv_user, message, is_starred }) };
}

export function starredMessagesLoaded(messages) {
    return { type: types.STARRED_MESSAGE_LIST_LOADED, messages };
}

export function starredMessagesPageLoaded(messages) {
    return { type: types.STARRED_MESSAGE_LIST_NEXT_PAGE_LOADED, messages };
}