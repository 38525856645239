import { __APP_PROFANITY_LOAD_MECHANISM__, profanityFilterUrl } from "../config/baseUrls";
import * as badwords from 'bad-words';

let filter = {};

const loadFromFile = () => {
    return new Promise((resolve, reject) => {

        fetch(profanityFilterUrl, {
            method: "GET"
        }).then(res => {
            return res.text();
        }).then(data => {
            if (data) {
                let wordArr = data.split(',');
                resolve(wordArr);
            } else {
                //break operation and return empty list
                resolve([]);
            }
        }).catch(ex => {
            console.error(ex);
            resolve([]);
        });

    });

}

const loadWords = () => {
    switch (__APP_PROFANITY_LOAD_MECHANISM__) {
        case "EXTERNAL": {
            //read file add to window            
            loadFromFile().then(words => {
                filter = new badwords({ list: words, placeHolder: '*' });
            });
            break;

        }
        case "INTERNAL": {
            //load from package
            filter = new badwords({ placeHolder: '*' });
            break;
        }
        case "BOTH": {
            //do both above
            filter = new badwords({ placeHolder: '*' });
            loadFromFile().then(words => {
                filter.addWords(...words);
            });
            break;
        }
        default:
            break;
    }

}

const filterProfanity = (sentence) => {

    let output = {
        isProfane: false,
        message: sentence
    }

    if ((__APP_PROFANITY_LOAD_MECHANISM__ === 'EXTERNAL' || __APP_PROFANITY_LOAD_MECHANISM__ === 'INTERNAL' ||
        __APP_PROFANITY_LOAD_MECHANISM__ === 'BOTH') && filter.isProfane(sentence)) {
        output.isProfane = true;
        output.message = filter.clean(sentence);
    }

    return output;
}

export { loadWords, filterProfanity }