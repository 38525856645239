import React from "react";
import { useDispatch, useSelector } from "react-redux";
import './styles/profile-details.scss';
import { __APP_CUST_DYNAMIC_INFO_COUNT__ } from "../../../config/baseUrls";
import SocialMediaIcon from "../SocialMediaIcon/SocialMediaIcon";
import { message } from "antd/lib/index";
import { Trash2 } from 'react-feather';
import { MenuIcon } from '../../common';
import { profileRemove, contactRemove } from "../../../redux/actions/externalUserProfileActions";
import { Popconfirm } from "antd";


const ExtProfileContacts = (props) => {
    let dispatch = useDispatch();

    const extProfile = useSelector((state) => state.selected_ext_profile);

    // let x=extProfile.contacts.reduce( function (previous, current) {
    //     return current;
    // }, {});


    //profie remove
    const removeSelectedContact = (Id) => {
        //check remove profile or contact
        if (props.userData.customerId === extProfile.contacts[0].contactId) {
            dispatch(contactRemove(Id, (status) => {
                status ? message.success(`Contact deleted successfully`) : message.error('Error occurred deleting contact');
            }));
        } else {
            dispatch(profileRemove(Id, (status) => {
                status ? message.success(`Contact deleted successfully`) : message.error('Error occurred deleting contact');
            }));
        }
    }

    return (
        <>
            {extProfile && extProfile.contacts && <div>{extProfile.contacts.map(contact => {
                return (<div key={`ExtContactInfo_${contact.id}`} className="d-flex flex-row dynamic-info-wrapper">
                    <SocialMediaIcon size="fa-2x" channel={contact.contactType} />
                    <div style={{ marginTop: "5px", paddingLeft: "5px", fontWeight: "bold" }}>{contact.display}</div>

                    {/* <div className="pl-1 u-value text-left"> {contact.display}</div> */}
                    <div style={{ marginTop: "-4px", paddingLeft: "5px" }}>

                        <Popconfirm
                            title="Delete contact ?"
                            onConfirm={() => {
                                removeSelectedContact(contact.id);
                            }}
                            okText="Delete"
                            cancelText="Cancel"
                        >
                            <MenuIcon
                                title={"delete"}
                                onClick={() => { }}
                                size={"sm-space"}
                                icon={<Trash2 />}
                            />
                        </Popconfirm>
                    </div>
                </div>)
            }

            )}
            </div>}

        </>

    );

};

export default ExtProfileContacts;
