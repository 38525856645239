import React from "react";
import { useLocation } from "react-router-dom";
import { DeviceValidating } from '../../common/index';
import phoneEngine from "../phone/phoneEngine/phoneEngineHandler";
import { __APP_IDENTITY_PROVIDER__ } from '../../../config/baseUrls';

const DeviceValidation = (props) => {
  let location = useLocation();

  /* useEffect(() => {

    console.log(`---- Mounting Device Validation ----`);

    if (location.search) {
      const params = new Map(location.search.slice(1).split('&').map(kv => kv.split('=')));
      if (params) {
        const isVideo = params.get('isVideo');
        const username = params.get('username');
        const password = params.get('password');
        if (username && password && isVideo && isVideo.toLowerCase() !== "true") {
          console.log(`---- Routing to Auto Login - useEffect - Device Validation ----`);
          props.history.push(`/login_url${location.search}`);
        }
      }

    }

  }, []); */


  const validationResult = (isValidationPass) => {
    try {
      if (isValidationPass) {

        if (location.state && location.state.detail) {
          console.log(`---- Routing to Auto Login - validationResult Method - Device Validation (1) ----`);

          if (__APP_IDENTITY_PROVIDER__ === 'IAM') {
            props.history.push({
              pathname: "/callback_validate",
              state: location.state,
            });

          } else {
            props.history.push({
              pathname: "/login_url",
              state: location.state,
            });

          }


        } else if (location.search) {
          console.log(`---- Routing to Auto Login - validationResult Method - Device Validation (2) ----`);
          if (__APP_IDENTITY_PROVIDER__ === 'IAM') {
            props.history.push(`/callback_validate${location.search}`);

          } else {
            props.history.push(`/login_url${location.search}`);
          }

        } else {
          console.log(`---- Routing to Login - validationResult Method - Device Validation (3) ----`);
          props.history.push({
            pathname: "/login",
            state: { deviceValidation: true },
          });
        }
      }
    } catch (error) {
      console.error("DeviceValidation", "validationResult", error);
    }
  };

  const reload = () => {
    //window.location.reload();
    console.log("DeviceValidation", "uireload", "window.location.reload");
  }

  return (
    <>
      <DeviceValidating deviceStatus={validationResult} phoneEngine={phoneEngine} reload={reload} />
    </>

  );
};


export default DeviceValidation;
