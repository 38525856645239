import React, { useState } from 'react';
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as selectedProfileActions from "../../../redux/actions/selectedProfileActions";
import * as groupActions from "../../../redux/actions/groupActions";
import * as uiPanelActions from "../../../redux/actions/uiPanelActions";
import PropTypes from 'prop-types';
import {
    MenuIcon, PanelTitle, UserProfileInfo, TextWithIcon, InviteWithText, ParticipationUser, SubTitle,
    TimeLine, ProfileDetails
} from '../../common';
import { Check, ArrowRight, Smile, X, ChevronRight, Plus, Info, TrendingUp, Repeat, XSquare} from 'react-feather';
import { Link, Route, Switch } from "react-router-dom";
import { Scrollbars } from 'react-custom-scrollbars';
import liveChatClient from "../../../lib";
import { getUserId } from "../../../utils";
import { Modal } from 'antd';
import navigationRestrictions from "../../../config/navigationRestrictions";
import { __APP_INTERACTION_MODE__ } from "../../../config/baseUrls";
import ExtProfileDetails from '../../common/ProfileDetails/ExtProfileDetails';
import ExtProfileContacts from '../../common/ProfileDetails/ExtProfileContacts';


import './styles/userProfilePanel.scss';


import { Tabs } from 'antd';

const { TabPane } = Tabs;


function UserProfilePanelComponent(props) {

    const getDefaultTab = () => {
        return navigationRestrictions.selected_user_info.active ? "0" : (navigationRestrictions.media.recent_media.active ? "2" : (navigationRestrictions.timeline.active ? "1" : "3"))
    }

    /* const [tabStatus, setTabStatus] = useState({ tab: getDefaultTab() }); */


    let history = useHistory();
    let { confirm } = Modal;

    function onPressChatFilterIcon(_filter) {
        console.log('event fire...');
        console.log(_filter);
    }

    let avatar = "";
    let fullName = "";

    if (props.selected_profile) {
        fullName = props.selected_profile.displayName;
        avatar = props.selected_profile.avatar;
    }

    const interactionTransferUsersAdded = (users) => {
        if (users.length > 0) {
            liveChatClient.sendInteractionTransferRequest(users[0].userId, props.selected_profile.InteractionId);
        }

    }

    const newGroupUsersAdded = (users) => {
        let userIds = users.map(user => user.userId);
        liveChatClient.addUserToGroup(props.selected_profile.username, userIds);
    };

    const removeUser = (userId) => {
        liveChatClient.removeUserFromGroup(props.selected_profile.username, [userId]);
    };

    const leaveGroup = (userId) => {
        liveChatClient.leaveGroup(props.selected_profile.username, userId);
        //if this is logged in user remove group and set selected user none

        if (userId === getUserId()) {
            props.group_actions.groupDeleted(props.selected_profile.username);
            props.selected_profile_actions.profileSelected({});
            props.onClickCloseInfoPanel();
            history.push('/console')

        }
    };

    const makeAdmin = (userId) => {
        liveChatClient.makeUserAsAdmin(props.selected_profile.username, userId);
    };

    const dismissAdmin = (userId) => {
        liveChatClient.dismissUserAsAdmin(props.selected_profile.username, userId);
    };

    function showGroupExitConfirm(userId, title, exitType) {
        confirm({
            title,
            icon: <Info />,
            content: '',
            onOk() {
                //user group exit function goes here
                if (exitType === 1) {
                    removeUser(userId);
                } else {
                    leaveGroup(userId);
                }

            },
            onCancel() {
                console.log('Cancel');
            },
        });
    }

    let isLoggedUserAdmin = false;

    if (props.group_info && props.group_info.Participants && props.group_info.Participants.length > 0) {

        let adminUsr = props.group_info.Participants.find(obj => obj.Id === getUserId() && obj.IsAdmin);

        if (adminUsr) {
            isLoggedUserAdmin = true;
        }

    }

    const onClickTabChange = (key) => {
        console.log(key);
        props.ui_panel_actions.changeProfileTab(key);
        /* setTabStatus({
            tab: key
        }) */

    };


    return (
        <>

            <div className={`chat-info-wrapper ${props.isOpenInfoPanel ? 'show-this' : 'hide-this'}`}>

                <div className={'ch-header-wrapper'}>
                    <div className={'modal-header d-flex'}>
                        <PanelTitle
                            title={props.selected_profile && props.selected_profile.isTemp ? (props.selected_profile.InteractionInfo.IsMeeting ? `Meeting Information` : `Customer Information`) : (props.selected_profile && props.selected_profile.conversation_type === 3 ? 'Group Profile' : 'User Profile')} />

                        {!navigationRestrictions.panels.right.fixed && <MenuIcon icon={<X />}
                            onClick={props.onClickCloseInfoPanel}
                            size={'sm'} />}
                    </div>
                </div>


                <div className={'ch-body-wrapper'}>

                    <div className={'ch-profile-info d-flex flex-column'}>
                        <div className={'d-flex flex-column header-profile-wrapper'}>
                            <div style={{ marginLeft: "-12px" }}>
                                <UserProfileInfo
                                    conversation_type={props.selected_profile.conversation_type}
                                    channel={props.selected_profile.conversation_type === 4 && __APP_INTERACTION_MODE__ === 'USER' && props.selected_profile.InteractionInfo && props.selected_profile.InteractionInfo.CustomerInfo ? props.selected_profile.InteractionInfo.CustomerInfo.smChannel : null}
                                    src={avatar}
                                    fullName={fullName}
                                    isMeeting={props.selected_profile.InteractionInfo ? props.selected_profile.InteractionInfo.IsMeeting : false} />
                                <div className={'ch-profile-bg'}>
                                    <img src={require("../../../assets/img/profileBg.svg")}
                                        alt="User Avatar" />
                                </div>
                            </div>
                            <div className={'profile-tab-wrapper'}>
                                <Tabs defaultActiveKey={props.tab ? props.tab : "0"} onChange={onClickTabChange}>
                                    {(navigationRestrictions.selected_user_info.active && props.selected_profile.conversation_type === 4 && !(props.selected_profile.InteractionInfo ? props.selected_profile.InteractionInfo.IsMeeting : false)) && <TabPane tab="Profile" key="0" />}
                                    {(navigationRestrictions.selected_user_info.active && props.selected_profile.conversation_type === 4 && __APP_INTERACTION_MODE__ === 'USER' && !(props.selected_profile.InteractionInfo ? props.selected_profile.InteractionInfo.IsMeeting : false)) && <TabPane tab="Contacts" key="4" />}
                                    {navigationRestrictions.timeline.active && <TabPane tab="Timeline"
                                        key="1" />}
                                    {(navigationRestrictions.media.recent_media.active || navigationRestrictions.chat.starring.active) && <TabPane tab="Media"
                                        key="2" />}
                                    {props.selected_profile && props.selected_profile.conversation_type === 3 && props.group_info && props.group_info.Participants &&
                                        <TabPane tab="Participants" key="3" />
                                    }
                                </Tabs>
                            </div>
                        </div>


                    </div>

                    {props.tab === "0" &&
                        <div className={'ch-profile-info d-flex flex-column'}>
                            <div className={'align-items-start'}>
                                {__APP_INTERACTION_MODE__ === 'USER' ? <ExtProfileDetails userData={props.selected_profile.userData} custInfoData={props.custInfoData} /> :
                                    <ProfileDetails
                                        conversation_type={props.selected_profile.conversation_type}
                                        src={avatar}
                                        fullName={fullName}
                                        lstUpdate={''}
                                        userData={props.selected_profile.userData}
                                        custInfoData={props.custInfoData} />}
                            </div>
                        </div>
                    }

                    {props.tab === "4" &&
                        <div className={'ch-profile-info d-flex flex-column'}>
                            <div className={'align-items-start'}>
                                <ExtProfileContacts userData={props.selected_profile.userData} custInfoData={props.custInfoData} />
                            </div>
                            
                        </div>
                    }


                    {props.tab === "1" &&
                        <TimeLine
                            viewPointHeight={props.viewPointHeight}
                        />
                    }

                    {props.tab === "2" &&
                        <Scrollbars
                            autoHide
                            thumbMinSize={0}
                            style={{ width: 300, height: props.viewPointHeight - 300 }}>


                            <div className={'links-wrp d-flex flex-column '}>
                                <div className={'links-inner-wrp '}>
                                    {navigationRestrictions.media.recent_media.active && <Link
                                        to={`/console/${props.selected_profile ? props.selected_profile.username : 'default'}/profile/media/media`}>
                                        <TextWithIcon icon={<ChevronRight />}
                                            size={'link'}
                                            text={'Media links and Docs'}
                                            space={'justify-content-between'}
                                            iconPosition={'right-position'} />
                                    </Link>}
                                </div>
                                <div className={'links-inner-wrp '}>
                                    {navigationRestrictions.chat.starring.active && <Link
                                        to={`/console/${props.selected_profile ? props.selected_profile.username : 'default'}/profile/starred-message`}>
                                        <TextWithIcon icon={<ChevronRight />}
                                            size={'link'}
                                            text={'Starred Message'}
                                            space={'justify-content-between'}
                                            iconPosition={'right-position'} />
                                    </Link>}
                                </div>

                            </div>
                        </Scrollbars>
                    }

                    {props.tab === "3" &&
                        <Scrollbars
                            autoHide
                            thumbMinSize={0}
                            style={{ width: 300, height: props.viewPointHeight - 300 }}>
                            {props.selected_profile && props.selected_profile.conversation_type === 3 && props.group_info && props.group_info.Participants &&
                                <div className={'group-info'}>
                                    <div className={'sub-title-wrapper'}>
                                        <SubTitle title={`${props.group_info.Participants.length} Participants`} />
                                    </div>
                                    {isLoggedUserAdmin && <InviteWithText
                                        onClick={() => props.onClickOpenInviteModal(newGroupUsersAdded, props.group_info.Participants.map(p => p.Id), false, false, "", true, "GROUP")}
                                        icon={<Plus />}
                                        size={'md-30'}
                                        title={'Add User'} />}
                                    <div className={'prt-user-row mt-2'}>
                                        <>

                                            {
                                                props.group_info.Participants.map(participant => {
                                                    return (

                                                        <ParticipationUser
                                                            layout={'rightUserGroup'}
                                                            isLoggedUser={participant.Id === getUserId()}
                                                            makeAdmin={() => {
                                                                makeAdmin(participant.Id)
                                                            }}
                                                            dismissAdmin={() => {
                                                                dismissAdmin(participant.Id)
                                                            }}
                                                            showGroupExitConfirm={() => {
                                                                showGroupExitConfirm(participant.Id, "Do you wish to remove this user ?", 1)
                                                            }}
                                                            src={null}
                                                            fullName={props.team && props.team[participant.Id] ? `${props.team[participant.Id].firstName} ${props.team[participant.Id].lastName}` : participant.Id}
                                                            active={true}
                                                            isLoggedUserAdmin={isLoggedUserAdmin}
                                                            isAdmin={participant.IsAdmin}
                                                            userType=""
                                                            /* userType={props.team && props.team[participant.Id] ? props.team[participant.Id].userType : ""} */
                                                            status={'user-info'}
                                                            mic={false}
                                                            video={false}
                                                            size={'md-30'} />


                                                    )
                                                })

                                            }
                                        </>
                                    </div>

                                    <div
                                        onClick={() => {
                                            showGroupExitConfirm(getUserId(), "Do you wish to leave this group ?", 2)
                                        }}
                                        className={'exit-group-wrapper text-center'}>
                                        Leave Group
                                    </div>

                                </div>}

                            {/* {props.selected_profile && props.selected_profile.conversation_type === 4 && props.selected_profile.InteractionInfo && props.selected_profile.InteractionInfo.UserIds &&
                            <div className={'group-info'}>
                                <div className={'sub-title-wrapper'}>
                                    <SubTitle title={`${props.selected_profile.InteractionInfo.UserIds.length} Participants`} />
                                </div>
                                <div className={'prt-user-row mt-2'}>
                                    <>

                                        {
                                            props.selected_profile.InteractionInfo.UserIds.map(participant => {
                                                return (

                                                    <ParticipationUser
                                                        layout={'rightUserGroup'}
                                                        isLoggedUser={participant === getUserId()}
                                                        src={null}
                                                        fullName={props.selected_profile.InteractionInfo.CustomerInfo && props.selected_profile.InteractionInfo.CustomerUserId === participant ? props.selected_profile.InteractionInfo.CustomerInfo.customerName : (props.team && props.team[participant] ? `${props.team[participant].firstName} ${props.team[participant].lastName}` : participant)}
                                                        active={true}
                                                        isAdmin={false}
                                                        userType={props.selected_profile.InteractionInfo.CustomerUserId === participant ? "Customer" : (props.team && props.team[participant] ? props.team[participant].userType : "")}
                                                        status={'user-info'}
                                                        mic={false}
                                                        video={false}
                                                        size={'md-30'} />


                                                )
                                            })

                                        }
                                    </>
                                </div>

                            </div>} */}

                        </Scrollbars>
                    }
                </div>
            </div>

        </>
    );
}

UserProfilePanelComponent.propTypes = {
    selected_profile: PropTypes.object.isRequired
};

function mapDispatchToProps(dispatch) {
    return {
        group_actions: bindActionCreators(groupActions, dispatch),
        selected_profile_actions: bindActionCreators(
            selectedProfileActions,
            dispatch
        ),
        ui_panel_actions: bindActionCreators(uiPanelActions, dispatch)
    };
}

function mapStateToProps(state) {

    let mapState = {
        selected_profile: state.selected_profile,
        team: state.team,
        tab: state.ui_panel_data.user_profile_panel.tab
    }

    if (state.selected_profile && state.selected_profile.conversation_type === 3) {
        mapState.group_info = state.chat_group.GroupListInfo[state.selected_profile.username]
    }

    return mapState;
}

export default connect(
    mapStateToProps, mapDispatchToProps
)(UserProfilePanelComponent);
