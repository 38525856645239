import React from 'react';
import PropTypes from 'prop-types';
import { ChatFooter, ForwardedLabel, MenuIcon, ProfileImage } from "../index";
import { Popover } from "antd";
import { MoreVertical, RefreshCcw } from "react-feather";
import navigationRestrictions from "../../../config/navigationRestrictions";

const openDoc = (src) => {
    window.open(src, "_blank");
}

const content = ({ onClickReplayMessage, onClickForwardMessage, onDownloadClick, contentUrl, filename, onClickOpenGallery, mediaFiles, msgObj, sendForwardMessages, onClickStar, isStarred, fileType }) => (
    <div className={'chat-hover-menu'}>
        <div
            onClick={() => onClickReplayMessage(msgObj)}
            className={'document-row'}>Reply
        </div>
        {navigationRestrictions.chat.forwarding.active && <div className={'document-row'}
            onClick={() => onClickForwardMessage((users) => {

                sendForwardMessages(users, msgObj);

            })}>
            Forward message
        </div>}
        {navigationRestrictions.chat.starring.active && <div className={'document-row'} onClick={() => onClickStar(msgObj, !isStarred)}>{isStarred ? 'Unstar Message' : 'Star Message'}</div>}
        <div className={'document-row'} onClick={() => (fileType === 'application/pdf' || fileType === 'document/pdf') ? openDoc(contentUrl) : onDownloadClick(mediaFiles)}>View</div>
        <div className={'document-row'} onClick={() => onDownloadClick(mediaFiles)}>Download</div>
    </div>
);

const ChatRightDocument = ({ src, isForwarded, mid, msgObj, fullName, msg, isImage, lastSeen, lastSeenStatus, fileType, onClickReplayMessage, onClickForwardMessage, onDownloadClick, contentUrl, filename, onClickOpenGallery, mediaFiles, sendForwardMessages, onClickStar, isStarred, onMessageResendPressed }) => {

    let iconPath = require("../../../assets/img/pdf.svg");

    if (fileType === "application/msword" || fileType === "application/vnd.openxmlformats-officedocument.wordprocessingml.document") {
        iconPath = require("../../../assets/img/docx.svg");
    } else if (fileType === "application/vnd.ms-excel" || fileType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
        iconPath = require("../../../assets/img/xls.svg");
    } else if (fileType === "text/csv") {
        iconPath = require("../../../assets/img/csv.svg");
    } else if (fileType === "text/plain") {
        iconPath = require("../../../assets/img/textSVG.svg");
    }
    return (
        <div key={mid} id={mid} className={`msg d-flex right-msg ${isImage ? '' : 'with-out-img'} `}>
            <div className="msg-img">
                <ProfileImage
                    textType={'chatRight'}
                    firstLatter={fullName ? fullName.split(' ').map(fullName => fullName[0]).join('').toUpperCase() : ""}
                    size={'sm'}
                    src={src}
                    padding="4px"
                    fontSize="0.8rem" />
            </div>
            <div className={`inner-msg-bubble ${isImage ? null : 'with-out-name'}`}>
                <div className={'msg-text'}>
                    <ForwardedLabel isForwardedMsg={isForwarded}
                        textAlign={'right'} />
                    <div className="msg-text-with-document d-flex flex-column ">
                        {(!msgObj.isUploading || msgObj.isUploading === 'UPLOADING' || msgObj.isUploading === 'FAILED') && <div className={`document-wrapper d-flex ${msg ? 'with-replay' : 'default '}`}>
                            <div className={'inner-dco-wrapper d-flex justify-content-center align-items-center'}>
                                <img src={iconPath}
                                    alt="User Avatar" />
                            </div>
                            {msgObj.isUploading === 'UPLOADING' && <div style={{ marginBlock: "auto" }} className="loader9" />}
                            {msgObj.isUploading === 'UPLOADING' && <div>{`Uploading Document...`}</div>}
                            {msgObj.isUploading === 'FAILED' && <div className={'chat-media-upload-fail'}>{`Upload Failed !!!`}</div>}

                        </div>
                        }
                        {(!msgObj.isUploading || msgObj.isUploading === 'DONE') && <div className={`document-wrapper d-flex ${msg ? 'with-replay' : 'default '}`}>
                            <div className={'inner-dco-wrapper d-flex justify-content-center align-items-center'}>
                                <img src={iconPath}
                                    alt="User Avatar" />
                            </div>
                            <div className={'file-name d-flex justify-content-center align-items-center'} onClick={() => (fileType === 'application/pdf' || fileType === 'document/pdf') ? openDoc(contentUrl) : onDownloadClick(mediaFiles)}>
                                {filename}
                            </div>
                        </div>}
                        {msg}
                        <Popover
                            content={content({ onClickReplayMessage, onClickForwardMessage, onDownloadClick, contentUrl, filename, onClickOpenGallery, mediaFiles, msgObj, sendForwardMessages, onClickStar, isStarred, fileType })}
                            trigger="hover">
                            {(!msgObj.isUploading || msgObj.isUploading === 'DONE') && <div className={'hover-more'}>
                                <MenuIcon
                                    icon={<MoreVertical />} />
                            </div>}
                        </Popover>
                    </div>

                </div>
                <ChatFooter lastSeen={lastSeen}
                    lastSeenStatus={lastSeenStatus}
                    deliveredStatus={msgObj.is_delivered}
                    sentStatus={msgObj.is_sent_successfull}
                    textRight={true}
                    isStarred={isStarred} />
            </div>
            {msgObj.is_sent_successfull === "FAIL" && !lastSeenStatus && !msgObj.is_delivered && <div style={{ marginBottom: "inherit", padding: "7px" }}>
                <MenuIcon onClick={() => onMessageResendPressed(msgObj)}

                    size={"sm-space"}
                    icon={<RefreshCcw />}
                />

            </div>}
        </div>
    )
};

// ChatRightDocument.propTypes = {
//     fullName: PropTypes.string.isRequired,
//     msg: PropTypes.string.isRequired,
//     isImage: PropTypes.bool.isRequired,
//     isLastSeen: PropTypes.bool.isRequired,
// };

ChatRightDocument.defaultProps = {
    src: null,
};

export default ChatRightDocument;
