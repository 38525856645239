import React from "react";

class CommentBox extends React.Component {
    state = { comment: '' }
    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.state.comment = props.comment;
    }
    handleChange = (event) => {
        try {
            const target = event.target;
            let key = target.name;
            let value = target.value;
            this.setState({ [key]: value });
        } catch (error) {
            console.log(error);
        }
    }
    render() {
        return (
            <>
                <div className="form-group float-left width-100-p pt-10 pb-5">
                    <label className="sourcesanspro-semi-bold-steel-blue-18px" htmlFor="comments">Comments</label>
                    <textarea type="textarea" className="form-control web-rectangle-2 ronchi-border-1px textarea-box h-120"
                        id="comment" value={this.state.comment} name="comment" onChange={this.handleChange} ></textarea>
                </div>
            </>);
    }
}

export default CommentBox;


