import * as types from "./actionTypes";

export function openAudioRecordModal(windowState) {
    return {type: types.OPEN_AUDIO_RECORD, status: windowState};
}

export function closeAudioRecordModal(windowState) {
    return {type: types.CLOSE_AUDIO_RECORD, status: windowState};
}

