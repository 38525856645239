import React from 'react';
import PropTypes from 'prop-types';
import { Headphones, X } from "react-feather";
import { ChatUser, MenuIcon, TitleWithSmallText, UserImage, ParticipationImage } from "../index";
import { Scrollbars } from "react-custom-scrollbars";


//online
//offline
//busy
//other
const UploadFileThumbnail = ({ fileSize, active, onclick, onClickRemoveImage, fileType, type }) => {
    let iconPath = require("../../../assets/img/pdf.svg");

    if (type === "application/msword" || type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document") {
        iconPath = require("../../../assets/img/docx.svg");
    } else if (type === "application/vnd.ms-excel" || type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
        iconPath = require("../../../assets/img/xls.svg");
    } else if (type === "text/csv") {
        iconPath = require("../../../assets/img/csv.svg");
    } else if (type === "text/plain") {
        iconPath = require("../../../assets/img/textSVG.svg");
    }

    return (
        <div
            className={`upload-img document-view  ${fileType}  d-flex flex-column justify-content-center align-items-center ${active ? 'active' : ''}`}>
            <div className={'remove-wrp d-flex justify-content-center align-items-center'}>
                <MenuIcon icon={<X />}
                    onClick={onClickRemoveImage}
                    size={'sm'} />
            </div>
            <div onClick={onclick}>
                {fileType === 'file' &&
                    <img src={iconPath}
                        alt="User Avatar" />
                }
                {fileType === 'audio' &&
                    <MenuIcon
                        icon={<Headphones />} />
                }
                <div className={'duration'}>
                    {fileSize}
                </div>

            </div>

        </div>
    )
};

// OutGoingCall.propTypes = {
//     status: PropTypes.string.isRequired,
// };

UploadFileThumbnail.defaultProps = {};

export default UploadFileThumbnail;
