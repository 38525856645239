class RecurrencePattern {

    constructor(obj) {
        this.type = obj.type;
        this.every = obj.every;
        this.month = obj.month;
        this.weekDays = obj.weekDays;
        this.date = obj.date;
    }

}

class RecurrenceRange {

    constructor(obj) {
        this.type = obj.type;
        this.value = obj.value;
    }

}

export { RecurrencePattern, RecurrenceRange }