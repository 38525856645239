export const uiBaseUrl = window.env.REACT_APP_UI_BASE_URL || "localhost:3000/TAC";
export const userUrl = window.env.REACT_APP_API_USER || "https://test.tetherficloud.com:8125";
export const ocmUsrUrl = window.env.REACT_APP_API_OCM_USER || "https://tmac.tetherfilabs.com/OCM_Server";
export const iamUrl = window.env.REACT_APP_API_IAM || "https://43.229.86.109:25216";
export const workspaceUrl = window.env.REACT_APP_API_WORKSPACE || "https://tetherfi.cloud:13010";
export const authUrl = window.env.REACT_APP_API_IDENTITY || "https://tetherfi.cloud:13005";
export const presenceUrl = window.env.REACT_APP_API_PRESENCE || "https://tetherfi.cloud:13009";
export const logUrl = window.env.REACT_APP_API_LOG_URL || "https://test.tetherficloud.com:15012";
export const clienteleUrl = "https://test.tetherficloud.com:8125";
export const conversationsUrl = "https://tetherfi.cloud:30d01";
export const fileServiceUrl = window.env.REACT_APP_API_FILESERVICE || "https://tetherfi.cloud:13019";
export const fileMetaServiceUrl = window.env.REACT_APP_API_FILEMETA || "https://tetherfi.cloud:13022";
export const conferenceUrl = "https://tetherfi.cloud:30d23";
export const chatApiUrl = "https://tetherfi.cloud:30d02";
export const configurationManagerUrl = "http://localhost:3548";

export const chatHistoryUrl = window.env.REACT_APP_API_CONVERSATION || "https://test.tetherficloud.com:25213";
export const contactServiceUrl = window.env.REACT_APP_API_CONTACT || "https://tetherfi.cloud:13023";
export const templateServiceUrl = window.env.REACT_APP_API_TEMPLATE || "https://localhost:3055";
export const meetingUrl = window.env.REACT_APP_API_MEETING || "https://dice.tetherfi.cloud:55008";
export const notificationUrl = window.env.REACT_APP_API_NOTIFICATION || "https://dice.tetherfi.cloud:55011";
export const webSocketUrl = window.env.REACT_APP_API_WEBSOCKET || "wss://test.tetherficloud.com:15012";
export const profanityFilterUrl = window.env.REACT_APP_API_PROFANITY || "http://localhost:3245/profanityFilter.txt";

export const externalCrmUrl = window.env.REACT_APP_API_EXTERNAL_CRM_URL || "https://idfcfirstbank--crmuat.lightning.force.com/lightning/cmp/c__IFB_LaunchC360FromCTI";
export const externalApiInvokerServiceUrl = window.env.REACT_APP_API_EXTERNAL_API_INVOKER_URL || "http://localhost:3546";

export const coBrowseServiceUrl = window.env.REACT_APP_API_COBROWSER_SERVICE_URL || "http://localhost:3547";
export const consoleConfigurationServiceUrl = window.env.REACT_APP_API_CONSOLE_CONFIGURATION_SERVICE_URL || "http://localhost:3548";

export const iamRedirectUrl = window.env.REACT_APP_DATA_IAM_REDIRECT_URL || "https://tmac.tetherfilabs.com/IAM?ApplicationIdentity=OCM";

export const faceAuthUrl = window.env.REACT_APP_API_FACE_AUTH_URL || "http://34.71.244.48";
export const whiteboardUrl = window.env.REACT_APP_API_WHITEBOARD_URL || "wss://dice.tetherfi.cloud:8095";
export const postmasterUrl = window.env.REACT_APP_API_POSTMASTER_URL || "https://postmaster.qa.tetherfi.cloud";

export const iamAppId = window.env.REACT_APP_DATA_IAM_APP_ID || "tac";

export const presenceMethod = window.env.REACT_APP_DATA_PRESENCE_METHOD || "PAGING"; //PAGING, PAGE_STATE
export const __APP_AUTO_LOGIN_ALLOWED__ = window.env.REACT_APP_DATA_AUTO_LOGIN_ALLOWED || "YES"; //YES, NO

export const token = window.env.REACT_APP_DATA_TOKEN ||
  "eyJhbGciOiJIUzUxMiJ9.eyJqdGkiOiIxZDhhMjFkMS1kZDAzLTQ1ZDItOTg1Ni1mNjQ1ZDFjYTA5Y2QiLCJpYXQiOjE1ODE2NzQ0MDksInN1YiI6IntcImVtYWlsXCI6XCJkaW51c2hhLmthbm5hbmdhcmFAdGV0aGVyZmkuY29tXCIsXCJ1c2VySWRcIjpcIjQwMjhlMzgxNmZhZGNjNjEwMTZmYjIxMzg3ZDUwMDA5XCIsXCJ0ZW5hbnRUb2tlblwiOlwiYWJjLTEyMy14eXpcIn0iLCJleHAiOjE1ODE2ODA0MDl9.p3yV2w2JHaSAw-OXVzKoZ0thtNU0js0GEVJHn4n2b_sYADN-YD2uZTfliCbvFs6CfYJsYr7pRv6Ie2FbHHrDgQ";

export const __APP_MODES__ = window.env.REACT_APP_DATA_MODES || "[{\"display\":\"UC\", \"value\": \"UC\", \"type\": \"UC\"},{\"display\":\"TRS\", \"value\": \":OCM\", \"type\": \"OCM\"}]";
export const __APP_IDENTITY_PROVIDER__ = window.env.REACT_APP_DATA_IDENTITY_PROVIDER || "UAS"; //GUARDIAN, UAS, IAM
export const __APP_AUTH_TYPE__ = window.env.REACT_APP_DATA_AUTH_TYPE || "cloud";
export const __APP_INTERACTION_MODE__ = window.env.REACT_APP_DATA_APP_INTERACTION_MODE || "INTERACTION"; //INTERACTION, USER
export const __APP_USER_LOADING_METHOD__ = window.env.REACT_APP_DATA_USER_LOADING_METHOD || "CONTACT"; //CONTACT, UAS
export const __APP_TYPING_STOP_TIMER_ENABLED__ = window.env.REACT_APP_DATA_TYPING_STOP_TIMER_ENABLED || "NO"; //YES, NO

export const __APP_DEFAULT_WORKSPACE__ = window.env.REACT_APP_DATA_DEFAULT_WORKSPACE || "tetherfi";

export const site_key = window.env.REACT_APP_DATA_SITE_KEY || "6LdJX-kUAAAAAI8LjfeqTBYJNEgZPiTjExGYGwnC";

export const __APP_CHAT_END_ACW_TIME__ = window.env.REACT_APP_DATA_CHAT_END_ACW_TIME || 0;
export const __APP_CHAT_END_ACW_ALLOWED__ = window.env.REACT_APP_DATA_CHAT_END_ACW_ALLOWED || "YES"; //YES, NO
export const __APP_CUST_DYNAMIC_INFO_COUNT__ = window.env.REACT_APP_DATA_CUST_DYNAMIC_INFO_COUNT || 15;
export const __APP_HEARTBEAT_TIMEOUT__ = window.env.REACT_APP_DATA_HEARTBEAT_TIMEOUT || 180000; //in milliseconds
export const __APP_MASK_CUSTOMER_DATA__ = window.env.REACT_APP_DATA_MASK_CUSTOMER_DATA || "YES"; //YES, NO
export const __APP_CHECK_MESSAGE_SEQ__ = window.env.REACT_APP_DATA_MESSAGE_SEQUENCING || "NO"; //YES, NO
export const __APP_PROFANITY_LOAD_MECHANISM__ = window.env.REACT_APP_DATA_PROFANITY_LOAD_MECHANISM || "BOTH"; //INTERNAL, EXTERNAL, BOTH, NONE
export const __APP_PROFANITY_SEND_REPORT__ = window.env.REACT_APP_DATA_APP_PROFANITY_SEND_REPORT || "YES"; //YES, NO
export const __APP_TYPING_RECEIVE_TIMEOUT__ = window.env.REACT_APP_DATA_TYPING_RECEIVE_TIMEOUT || 15000; //in milliseconds
export const __APP_TYPING_RECEIVE_CHECK_TIMEOUT__ = window.env.REACT_APP_DATA_TYPING_RECEIVE_CHECK_TIMEOUT || 0; //in milliseconds
export const __APP_MAX_FILE_UPLOAD_SIZE__ = window.env.REACT_APP_DATA_MAX_FILE_UPLOAD_SIZE || 0; //in bytes
export const ocmAccessFeature = window.env.REACT_APP_DATA_OCM_ACCESS_FEATURE || 'IsTMeetAccessAllowed';
export const ocmUserHierarchyType = window.env.REACT_APP_DATA_OCM_USER_HIERARCHY_TYPE || 'team';
export const meetingDurations = window.env.REACT_APP_DATA_MEETING_DURATIONS || '15,30,45,60'; //in minutes
export const meetingReminderDuration = window.env.REACT_APP_DATA_MEETING_REMINDER_DURATION || 30; //in minutes
export const meetingSnoozeDuration = window.env.REACT_APP_DATA_MEETING_SNOOZE_DURATION || '5,10,15'; //in minutes
export const anonymousJoinAllowed = window.env.REACT_APP_DATA_MEETING_ANONYMOUS_JOIN_ALLOWED || 'YES'; //YES, NO
export const meetingTextChatEnabled = window.env.REACT_APP_DATA_MEETING_TEXT_CHAT_ENABLED || 'YES'; //YES, NO
export const meetingCreatorLevel = window.env.REACT_APP_DATA_MEETING_CREATOR_LEVEL || 'ALL'; //ADMIN, AGENT, SUPERVISOR

export const tmacProxy = window.env.REACT_APP_API_TMAC_PROXY_URL || "https://dice.tetherfi.cloud/Dev_TMACSDKProxy/TmacProxy.asmx"