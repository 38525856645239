export class userAccountApi {

    constructor(config, livechat) {
        this._livechat = livechat;
        this.userUrl = config ? config.userUrl : "";
        this.ocmUsrUrl = config ? config.ocmUsrUrl : "";
        this.ocmUserHierarchyType = config ? config.ocmUserHierarchyType : "";
        this.ocmAccessFeature = config ? config.ocmAccessFeature : "";
    }

    /**
    * api-method
    * Get user profile details for a given user id
    * @param {string} userId User Id of the user to get user profile details of (Cannot be blank)
    * @returns {Promise} Promise object an object of user profile information for given user id
    */
    getUserDetails = (userId) => {

        return new Promise((resolve, reject) => {
            let url = `${this.userUrl}/user-auth-service/api/v1/user/account/${userId}`;

            fetch(url, {
                headers: {
                    "authorization": `Bearer ${this._livechat.getAuthToken()}`,
                    "Content-Type": "application/json",
                    "tenant-token": this._livechat.getWorkspace()
                },
                method: "GET"
            }).then(res => {
                return res.json();
            }).then(data => {
                resolve(data.data);
            }).catch(ex => {
                reject(ex);

            });

        })

    }

    /**
    * api-method
    * Get multiple user profiles at once for a set of user id’s provided
    * @param {Array<string>} userIdList Array of user id's (Cannot be blank)
    * @returns {Promise} A promise that resolves to an array of objects containing user profile details for the given ids.
    */
    getUserDetailsBatch = (userIdList) => {

        return new Promise((resolve, reject) => {
            let url = `${this.userUrl}/user-auth-service/api/v1/user/accounts`;

            let userIdListStr = JSON.stringify(userIdList);

            fetch(url, {
                headers: {
                    "authorization": `Bearer ${this._livechat.getAuthToken()}`,
                    "Content-Type": "application/json",
                    "tenant-token": this._livechat.getWorkspace()
                },
                method: "POST",
                body: userIdListStr
            }).then(res => {
                return res.json();
            }).then(data => {
                resolve(data.data.users);
            }).catch(ex => {
                reject(ex);

            });

        })

    }

    /**
    * api-method
    * Get OCM team for orgUnit
    * @param {String} workspace orgUnit to be filtered (Cannot be blank)
    * @returns {Promise} A promise that resolves to an array of objects containing user profile details based on OCM user profiles.
    */
    getOCMTeamMembers = (workspace) => {

        return new Promise((resolve, reject) => {

            let url = `${this.ocmUsrUrl}/api/OCMApi/GetUserHierarchy`;

            var urlencoded = new URLSearchParams();
            urlencoded.append("type", this.ocmUserHierarchyType);
            urlencoded.append("orgUnit", workspace);
            urlencoded.append("agentId", this._livechat.getUserId());

            fetch(url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                },
                body: urlencoded
            }).then(res => {
                return res.json();

            }).then(data => {
                if (data) {

                    let userList = data.reduce((acc, user) => {

                        if (user.agentFeature) {

                            let allowedUser = user.agentFeature.find(featureInfo => {
                                return featureInfo.feature === this.ocmAccessFeature && featureInfo.isEnable;
                            });

                            if (allowedUser) {

                                let tempUser = { userId: user.lanId, firstName: user.agentName, lastName: " ", userType: user.profile, conversation_type: 0, isTemp: false, createdAt: Date.now(), InteractionId: null };

                                acc[user.lanId] = tempUser;

                            }

                        }

                        return acc;


                    }, {});

                    resolve(userList);

                }
                else {
                    reject(new Error("Error occurred"));
                }
            }).catch(ex => {
                reject(ex);

            });

        });
    };

    /**
    * api-method
    * Get multiple user profiles by page and limit
    * @param {integer} pageId Page number (Cannot be blank)
    * @param {integer} limit number of records to retrieve (Cannot be blank)
    * @returns {Promise} A promise that resolves to an array of objects containing user profile details by page and limit.
    */
    getUserAccountsByPage = (pageId, limit) => {

        return new Promise((resolve, reject) => {

            let url = `${this.userUrl}/user-auth-service/api/v1/user/accounts?pageNo=${pageId}&pageSize=${limit}`;

            fetch(url, {
                method: "GET",
                headers: {
                    "authorization": `Bearer ${this._livechat.getAuthToken()}`,
                    "Content-Type": "application/json",
                    "tenant-token": this._livechat.getWorkspace()
                }
            }).then(res => {
                return res.json();

            }).then(data => {
                resolve(data.data.users);
            }).catch(ex => {
                reject(ex);
            })
        })
    }

}