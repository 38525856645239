import { userUrl } from "../config/baseUrls";
import * as auth from "../utils";

export default class UserAuthService {
  saveProfile(profileData, token, workspaceName) {
    return fetch(`${userUrl}/user-auth-service/api/v1/user/account?g-recaptcha-response=${token}&org=${auth.getModeVal()}`, {
      method: "post",
      headers: {
        "Content-Type": "application/json", "tenant-token": workspaceName
      },
      body: JSON.stringify(profileData),
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (reply) {
        return {
          isSuccess: reply && reply["status"] === 0,
          data: reply["data"],
        };
      })
      .catch((error) => {
        return { isSuccess: false, data: null };
      });
  }
}
