import { CUSTOMER_360, OPEN_CUSTOMER_360 } from "./actionTypes";
import { externalApiService } from "../../service/externalApiService";
import navigationRestrictions from "../../config/navigationRestrictions";
import * as auth from "../../utils";
import * as phoneConfig from "../../config/phoneConfig";
import * as logger from '../../lib/logSdk/logger';

export function onEndSession(channel, conversationId, uid, dialogId) {
  return async (dispatch) => {
    try {
      let invoke_external_api_with_session_end = phoneConfig.basic_config.console.invoke_external_api_with_session_end;
            const open_external_app_db = auth.getPhoneConfig();
            if (open_external_app_db && open_external_app_db.basic_config && open_external_app_db.basic_config.console) {
              invoke_external_api_with_session_end = open_external_app_db.basic_config.console.invoke_external_api_with_session_end;
            }

      if (invoke_external_api_with_session_end) {
        let postData = {
          commands: ["Customer360", "ActionParticipant"],
          conversationId: conversationId,
          timestamp: new Date(),
          ucic: uid,
          subCategory: channel === "chat" ? "Chat" : "Video",
          subject: channel === "chat" ? "Chat" : "Video",
          customField: [],
          dialogId: dialogId,
        };

        if (channel === "chat") {
          postData.customField = [
            {
              name: "WorkUnitItem",
              value: "2345678890987",
            },
            {
              name: "Remarks",
              value: "Chat",
            },
            {
              name: "Mode",
              value: "Chat",
            },
          ];
        } else {
          postData.customField = [
            {
              name: "PageId",
              value: "2345678890987",
            },
            {
              name: "PageInformation",
              value: "Video",
            },
            {
              name: "Remarks",
              value: "Video",
            },
            {
              name: "WorkUnitItem",
              value: "2345678890987",
            },
            {
              name: "Mode",
              value: "Video",
            },
          ];
        }
        console.log(
          "external api actions",
          "onEndSession",
          `postData : ${JSON.stringify(postData)}`
        );
        let response = await externalApiService.invokeExternalApi(postData);
        //let response = await externalApiService.createCase('Customer360',postData);

        console.log(
          "external api actions",
          "onEndSession",
          `response : ${JSON.stringify(response)}`
        );
        dispatch({ type: CUSTOMER_360, payload: response });
      } else {
        console.error(
          "externalApiActions",
          "onEndSession",
          `invoke_external_api_with_session_end:${navigationRestrictions.invoke_external_api_with_session_end}`
        );
        logger.error("externalApiActions","onEndSession", `invoke_external_api_with_session_end:${navigationRestrictions.invoke_external_api_with_session_end}`);

        dispatch({
          type: CUSTOMER_360,
          payload: { result: null, isSuccess: false },
        });
      }
    } catch (error) {
      console.error("external api actions", "onEndSession", error);
      logger.error("externalApiActions","onEndSession", error.message);
      dispatch({
        type: CUSTOMER_360,
        payload: { result: null, isSuccess: false },
      });
    }
  };
}

export function OpenCustomer360(url,data) {
  return async (dispatch) => {
    try {
      let open_external_app = phoneConfig.basic_config.console.open_external_app;
      const open_external_app_db = auth.getPhoneConfig();
      if (open_external_app_db && open_external_app_db.basic_config && open_external_app_db.basic_config.console) {
        open_external_app = open_external_app_db.basic_config.console.open_external_app;
      }

      console.log(
        "external api actions",
        "OpenCustomer360",
        `invoke_external_api_with_session_end:${open_external_app}, url : ${url}`
      );
      if (open_external_app) {
        window.open(url, "_blank");
        let response = await externalApiService.invokeExternalApi({ 
          commands: ["DumpData"],
          url: url,
          method: 'OpenCustomer360',
          post_data: data,
          response: null,
          error_detail: null,
          error_code: null,
          workspace: null,
          moduleName: 'Agent UI',
          functionName: 'OpenCustomer360',
          type: 'EXTERNALSERVICEINVOKER',
          session_id:data.InteractionId,
          transaction_created_at: Date.now(),
          transaction_updated_at: Date.now(),});

        console.log(
          "external api actions",
          "onEndSession",
          `response : ${JSON.stringify(response)}`
        );
        dispatch({
          type: OPEN_CUSTOMER_360,
          payload: { result: url, isSuccess: true },
        });
      } else {
        dispatch({
          type: OPEN_CUSTOMER_360,
          payload: { result: url, isSuccess: false },
        });
      }
    } catch (error) {
      console.error("external api actions", "OpenCustomer360", error);
      logger.error("externalApiActions","OpenCustomer360", error.message);
      dispatch({
        type: OPEN_CUSTOMER_360,
        payload: { result: url, isSuccess: false },
      });
    }
  };
}
