import * as types from "./actionTypes";

export function joinMeeting(meetingId, interactionId, title) {
    let data = { meetingId, interactionId, title };
    return { type: types.CREATE_CURRENT_MEETING, data };
}

export function leaveMeeting(meetingId) {
    return { type: types.REMOVE_CURRENT_MEETING, meetingId };
}
