import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import isUrl from "is-url";
import * as chatActions from "../../../redux/actions/chatActions";
import * as ChatService from "../../../service/chatService";
import * as fileService from "../../../service/fileService";
import * as chatTextPanelActions from "../../../redux/actions/chatTextPanelActions";
import { getUserId, getUser, getDisplayName, getWorkspace } from "../../../utils";
import {
    Send,
    Paperclip,
    Phone,
    Mic,
    Smile,
    Video,
    X,
    MessageSquare,
    Image,
    Headphones,
    Disc,
    Layout,
    BookOpen
} from "react-feather";

import { BiMessageDetail, BiVideo, BiCopy, BiGroup } from "react-icons/bi";

import { videoTypes, documentTypes, imageTypes, audioTypes } from "../../../config/json/fileTypeMap";
import { Popover, Modal, Tooltip } from 'antd';
import './styles/chat-text-area.scss';
import './styles/chat-text-area-responsive.scss';
import {
    MenuIcon,
    OutGoingCall,
    VoiceRecorder,
    EmojiPanel,
    CannedMessagePanel
} from "../../common";
import uuid from "uuid";
import {
    setKeyPressTime,
    resetKeyPressTime
} from "../../../service/typingDetector";

import * as eventEmitter from "../../../utils/eventEmitter";
import navigationRestrictions from "../../../config/navigationRestrictions";
import { __APP_DEFAULT_WORKSPACE__ } from "../../../config/baseUrls";
import { filterProfanity } from "../../../utils/profanityFilter";
import { notification } from "antd/lib/index";

let prevSelectedProfile = null;

const ChatTextWrapper = (props) => {

    const [chatMessage, setChatMessage] = useState("");
    const [matchingCanned, setMatchingCanned] = useState([]);

    let replyMessage = props.text_panel_user_info && props.text_panel_user_info.reply_message ? props.text_panel_user_info.reply_message : props.replyMessage;

    useEffect(() => {
        eventEmitter.subscribeToEvents("add_message", message => {

            setChatMessage((prevState => {
                return `${prevState} ${message}`;

            }))
            document.getElementById("chat_text_wrapper_main_window").focus();

        }, "chat_text_wrapper");
    }, []);

    let showIcons =
        (!navigationRestrictions.presence.active || navigationRestrictions.presence.show_communication_buttons.active ||
            (props.presence &&
                (props.presence.status === "online" ||
                    props.presence.status === "available"))) &&
        props.call &&
        props.call.callStatus === "Idle";

    if (props.selected_profile && props.selected_profile.username) {
        if (prevSelectedProfile && prevSelectedProfile.username !== props.selected_profile.username) {
            //reset
            resetKeyPressTime(prevSelectedProfile.conversation_type,
                getUserId(),
                prevSelectedProfile.username, prevSelectedProfile.InteractionId, prevSelectedProfile.isACW);
            //set prev message
            props.chat_text_panel_actions.setTextAreaMessage(prevSelectedProfile.username, chatMessage, props.replyMessage);
            //set current message
            setChatMessage(props.text_panel_user_info ? props.text_panel_user_info.message : "");
            if (props.text_panel_user_info && props.text_panel_user_info.reply_message) {
                props.onClickReplayMessage(props.text_panel_user_info.reply_message)
            } else {
                props.onClickCloseReplayMessage();
                props.chat_text_panel_actions.removeTextPanelInfo(props.selected_profile.username);
            }
        }

        prevSelectedProfile = props.selected_profile;
    }

    let onChangeHandler = event => {
        if (props.selected_profile && props.selected_profile.username) {
            setChatMessage(event.target.value);
        }
    };

    const sendMessageClick = () => {
        if (chatMessage && chatMessage.match(/^\s*$/) === null) {
            setMatchingCanned([]);
            resetKeyPressTime(props.selected_profile.conversation_type,
                getUserId(),
                props.selected_profile.username, props.selected_profile.InteractionId, props.selected_profile.isACW);
            //call socket

            let uniqueId = uuid();

            let msg = {
                v: 1,
                mid: uniqueId,
                sid: uniqueId,
                message_type: "text/html",
                from: {
                    id: getUserId(),
                    name: getDisplayName()
                },
                to: {
                    id: props.selected_profile.username,
                    name: props.selected_profile.username
                },
                created_at: Date.now(),
                message_content: chatMessage,
                other: null
            }

            msg.conversation_type = props.selected_profile.conversation_type;

            if (replyMessage) {
                if (replyMessage.replyMessage) {
                    delete replyMessage.replyMessage
                }
                msg.message_action = 2;
                msg.related_message = {
                    message_action: 2,
                    ...replyMessage
                }
            }

            let profanity = filterProfanity(chatMessage);

            if (profanity.isProfane) {

                notification.error({
                    message: `Message Blocked`,
                    description:
                        `Your message : "${profanity.message}", is flagged by profanity filter and cannot be sent`,
                    placement: 'bottomRight',
                    style: {
                        backgroundColor: "#ff751a",
                        color: "White"
                    },
                });
                ChatService.sendProfanityMessage(chatMessage, props.selected_profile.InteractionId);

            } else {
                if (isUrl(chatMessage)) {
                    msg.message_type = "html/link";
                    msg.media = [{ content_url: chatMessage, mimeType: "html/link", created_at: Date.now() }];

                    let ws = getWorkspace();

                    ws = ws ? ws : __APP_DEFAULT_WORKSPACE__;

                    fileService.saveLink(msg.conversation_type === 3 ? "" : msg.from.id, msg.to.id, chatMessage, ws, uniqueId);
                }

                ChatService.sendChatMessage(
                    props.selected_profile.conversation_type,
                    "text/html",
                    props.selected_profile.username,
                    msg,
                    false,
                    props.selected_profile.InteractionId
                );

                props.chat_actions.sendNewMessageSuccess(msg);
                setChatMessage("");
                eventEmitter.fireEvent("scrollbar", { scrollbar_status: "down" });
                props.onClickCloseReplayMessage();
                props.chat_text_panel_actions.removeTextPanelInfo(props.selected_profile.username);

            }


        }
    }

    const checkCannedTriggerMatch = (word) => {

        if (word) {
            let allCannedMessages = [...Object.entries(props.canned_messages.admin).map(([key, value]) => value), ...Object.entries(props.canned_messages.user).map(([key, value]) => value)];

            let filtered = allCannedMessages.filter(value => value.Trigger === word);
            return filtered;
        }

        return [];


    }

    let keyUpHandler = event => {
        if (props.selected_profile && props.selected_profile.username) {
            /* event.key === "Enter" */
            if (event.key === "Enter" && !event.shiftKey && chatMessage && chatMessage.match(/^\s*$/) === null) {
                setMatchingCanned([]);
            } else {
                let words = chatMessage.trim().split(' ');
                let lastWord = words[words.length - 1];

                setMatchingCanned(checkCannedTriggerMatch(lastWord));
            }
        }
    };

    let keyPressHandler = event => {
        if (props.selected_profile && props.selected_profile.username) {
            setKeyPressTime(
                props.selected_profile.conversation_type,
                getUserId(),
                props.selected_profile.username,
                props.selected_profile.InteractionId
            );



            /* event.key === "Enter" */
            if (event.key === "Enter" && !event.shiftKey && chatMessage && chatMessage.match(/^\s*$/) === null) {
                setMatchingCanned([]);
                event.preventDefault();
                resetKeyPressTime(props.selected_profile.conversation_type,
                    getUserId(),
                    props.selected_profile.username, props.selected_profile.InteractionId, props.selected_profile.isACW);

                let uniqueId = uuid();

                let msg = {
                    v: 1,
                    mid: uniqueId,
                    sid: uniqueId,
                    message_type: "text/html",
                    from: {
                        id: getUserId(),
                        name: getDisplayName()
                    },
                    to: {
                        id: props.selected_profile.username,
                        name: props.selected_profile.username
                    },
                    created_at: Date.now(),
                    message_content: chatMessage,
                    other: null
                }

                msg.conversation_type = props.selected_profile.conversation_type;

                if (replyMessage) {
                    if (replyMessage.replyMessage) {
                        delete replyMessage.replyMessage
                    }
                    msg.message_action = 2;
                    msg.related_message = {
                        message_action: 2,
                        ...replyMessage
                    }
                }

                let profanity = filterProfanity(chatMessage);

                if (profanity.isProfane) {
                    notification.error({
                        message: `Message Blocked`,
                        description:
                            `Your message : "${profanity.message}", is flagged by profanity filter and cannot be sent`,
                        placement: 'bottomRight',
                        style: {
                            backgroundColor: "#ff751a",
                            color: "White"
                        },
                    });
                    ChatService.sendProfanityMessage(chatMessage, props.selected_profile.InteractionId);

                } else {

                    if (isUrl(chatMessage)) {
                        msg.message_type = "html/link";
                        msg.media = [{ content_url: chatMessage, mimeType: "html/link", created_at: Date.now() }];

                        let ws = getWorkspace();

                        ws = ws ? ws : __APP_DEFAULT_WORKSPACE__;

                        fileService.saveLink(msg.conversation_type === 3 ? "" : msg.from.id, msg.to.id, chatMessage, ws, uniqueId);
                    }

                    ChatService.sendChatMessage(
                        props.selected_profile.conversation_type,
                        "text/html",
                        props.selected_profile.username,
                        msg,
                        false,
                        props.selected_profile.InteractionId
                    );

                    props.chat_actions.sendNewMessageSuccess(msg);
                    setChatMessage("");
                    eventEmitter.fireEvent("scrollbar", { scrollbar_status: "down" });
                    props.onClickCloseReplayMessage();
                    props.chat_text_panel_actions.removeTextPanelInfo(props.selected_profile.username);

                }


            } else {
                let words = chatMessage.trim().split(' ');
                let lastWord = words[words.length - 1];

                setMatchingCanned(checkCannedTriggerMatch(lastWord));
            }
        }
    };

    let fromTeamMember = null;
    let fullName = "";

    if (replyMessage) {

        document.getElementById("chat_text_wrapper_main_window").focus();
        if (props.team) {
            let from = replyMessage.from && replyMessage.from.id ? replyMessage.from.id : replyMessage.sender_id;
            if (from) {
                fromTeamMember = props.team[from];
            }
        }

        fullName = fromTeamMember && fromTeamMember.firstName ? `${fromTeamMember.firstName} ${fromTeamMember.lastName ? fromTeamMember.lastName : ""}` : replyMessage.from.id;

    }

    let isSendIconEnabled = chatMessage && chatMessage.match(/^\s*$/) === null && props.selected_profile && props.selected_profile.username


    console.log(replyMessage);
    console.log("=======================");

    let onEmojiClick = emoji => {

        if (props.selected_profile && props.selected_profile.username) {

            setChatMessage(prevMessage => prevMessage + emoji);
            document.getElementById("chat_text_wrapper_main_window").focus();
        }

    };

    const emojiPanel = (
        <>
            <EmojiPanel
                onEmojiClick={onEmojiClick}
            />
        </>
    );

    const cannedMessagePanel = (
        <>
            <CannedMessagePanel
                filtered={matchingCanned}
                onTemplateClick={(message) => {

                    setChatMessage((prevState => {
                        let newMessage = `${prevState.trim()} `.replace(/\S* $/g, `${message}`);
                        return `${newMessage}`;

                    }))
                    setMatchingCanned([]);
                    document.getElementById("chat_text_wrapper_main_window").focus();

                }} />
        </>
    );

    const getFileIcon = (type) => {

        if (type === "application/msword" || type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document") {
            return require("../../../assets/img/docx.svg");
        } else if (type === "application/vnd.ms-excel" || type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
            return require("../../../assets/img/xls.svg");
        } else if (type === "text/csv") {
            return require("../../../assets/img/csv.svg");
        } else if (type === "text/plain") {
            return require("../../../assets/img/textSVG.svg");
        } else {
            return require("../../../assets/img/pdf.svg");
        }

    }
    return (<>

        <div className={`typing-action-wrapper desktop d-flex flex-column`}
            style={{
                width: props.viewPointWidth - 390,
                height: replyMessage ? 170 : 80
            }}>
            <>
                {/*======= chat replay message area ========= */}
                {replyMessage &&
                    <div className={'d-flex'}
                        style={{ width: props.viewPointWidth - (props.isOpenInfoPanel ? 700 : 400) }}>
                        <div className={'replay-message-tag d-flex flex-column'}>
                            <div className={'full-name'}>
                                {fullName}
                            </div>
                            <div className={'tag-message d-flex '}>
                                <div className={'message-icon d-flex'}>
                                    {(replyMessage.message_type === "text/html" || replyMessage.message_type === "html/link") &&
                                        <MessageSquare />
                                    }

                                    {documentTypes.indexOf(replyMessage.message_type) > -1 &&
                                        <div className={'replay-msg-doc-wrp'}>
                                            <img src={getFileIcon(replyMessage.message_type)}
                                                alt="document" />
                                        </div>
                                    }
                                    {imageTypes.indexOf(replyMessage.message_type) > -1 &&
                                        <Image />
                                    }
                                    {videoTypes.indexOf(replyMessage.message_type) > -1 &&
                                        <Video />
                                    }
                                    {audioTypes.indexOf(replyMessage.message_type) > -1 &&
                                        <Headphones />
                                    }
                                </div>
                                <div className={'reply-text'}>
                                    {replyMessage.message_type === "text/html" &&
                                        <>
                                            {replyMessage.message_content}
                                        </>
                                    }

                                    {replyMessage.message_type === "html/link" &&
                                        <>
                                            {replyMessage.media && replyMessage.media[0] ? replyMessage.media[0].content_url : ""}
                                        </>
                                    }

                                    {audioTypes.indexOf(replyMessage.message_type) > -1 &&
                                        <>
                                            Audio file
                                    </>
                                    }

                                    {videoTypes.indexOf(replyMessage.message_type) > -1 &&
                                        <>
                                            Video file
                                    </>
                                    }
                                    {imageTypes.indexOf(replyMessage.message_type) > -1 &&
                                        <>
                                            Image
                                    </>
                                    }
                                    {documentTypes.indexOf(replyMessage.message_type) > -1 &&
                                        <>
                                            Document
                                    </>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className={'close-wrapper'}>
                            <MenuIcon icon={<X />} size={'lg'} onClick={() => {
                                props.onClickCloseReplayMessage();
                                props.chat_text_panel_actions.removeTextPanelInfo(props.selected_profile.username);
                            }} />
                        </div>

                        <div className={'replay-msg-type-icon'}>

                        </div>
                    </div>
                }
            </>

            {/*======= chat typing area ========= */}
            <div
                className={`inner-typing-action d-flex flex-column ${replyMessage ? 'active-reply' : 'message-only'}`}>
                <div className={'txt-top-wrapper d-flex'}>

                    {navigationRestrictions.chat.emoji_panel.active && props.selected_profile.isACW !== true &&
                        <Popover content={emojiPanel} trigger="hover">
                            <div className={`left-face-panel d-flex align-items-center`}>
                                <MenuIcon
                                    icon={<Smile />}
                                    size={"lg"}
                                />
                            </div>
                        </Popover>}
                    {/*?960 : 710*/}
                    {<div className={`type-area-wrapper d-flex align-items-center`}>
                        {/* <input id="chat_text_wrapper_main_window"
                            autocomplete="off"
                            style={{ width: props.viewPointWidth - (props.isOpenInfoPanel ? 930 : 620) }}
                            value={chatMessage}
                            onChange={onChangeHandler}
                            onKeyPress={keyPressHandler}
                            className={`console-type-area`}
                            placeholder={`Write your message…`}
                        ></input> */}
                        <Popover content={cannedMessagePanel}
                            visible={matchingCanned.length > 0 ? true : false}
                            placement="topLeft"
                            trigger="click">
                            <textarea id="chat_text_wrapper_main_window"
                                autoComplete="off"
                                maxLength="1000"
                                style={{ width: props.viewPointWidth - (props.isOpenInfoPanel ? 930 : 620) }}
                                value={chatMessage}
                                onChange={onChangeHandler}
                                onKeyPress={keyPressHandler}
                                onKeyUp={keyUpHandler}
                                className={`console-type-area`}
                                placeholder={`Write your message…`}
                                disabled={props.selected_profile.isACW === true ? `disabled` : ""}></textarea>
                        </Popover>
                    </div>}

                    {isSendIconEnabled &&
                        <Tooltip placement="topRight" title={'Send Message'}>
                            <div
                                className={`pg-header-icon-wrp send-message right  flex justify-content-end justify-content-center ml-5 mt-2`}>
                                <MenuIcon
                                    size={"sm-space"}
                                    status={"active"}
                                    onClick={sendMessageClick}
                                    icon={<Send />}
                                />
                            </div>
                        </Tooltip>}
                </div>


                <div>
                    {/*======= chat typing area action wrapper start here========= */}
                    {props.selected_profile.isACW !== true && <div
                        className={`message-handler-wrapper d-flex  ${props.isEnableVoiceRecorder ? 'active-voice' : ''}  `}>
                        <div className={`pg-user-action-wrp-right d-flex `}>
                            {props.isEnableVoiceRecorder &&
                                <>
                                    <VoiceRecorder
                                        onClickCloseVoiceRecorder={props.onClickCloseVoiceRecorder} />
                                </>}

                            {!props.isEnableVoiceRecorder &&
                                <div className={`d-flex justify-content-between`}>


                                    {!isSendIconEnabled &&
                                        <>

                                            {navigationRestrictions.templates.active &&
                                                <Tooltip placement="topRight" title={'Templates'}>
                                                    <div className={`pg-header-icon-wrp sm  flex justify-content-center `}>
                                                        <MenuIcon
                                                            size={"sm-space"}
                                                            status={"active"}
                                                            onClick={() => {
                                                                eventEmitter.fireEvent("show_template_modal", null);
                                                            }}
                                                            icon={<BookOpen />}
                                                        />
                                                    </div>
                                                </Tooltip>}

                                            {navigationRestrictions.co_browsing.active && props.whiteboard.wbState === 'CLOSED' && showIcons &&
                                                <Tooltip placement="topRight" title={'co-browsing'}>
                                                    <div className={`pg-header-icon-wrp sm  flex justify-content-center `}>
                                                        <MenuIcon
                                                            onClick={props.onClickOpenCoBrowsing}
                                                            size={"sm-space"}
                                                            icon={<Layout />} />
                                                    </div>
                                                </Tooltip>}
                                            {navigationRestrictions.chat.video_recorder.active &&
                                                <Tooltip placement="topRight" title={'video recorder'}>
                                                    <div className={`pg-header-icon-wrp sm  flex justify-content-center `}>
                                                        <MenuIcon
                                                            onClick={props.onClickVideoRecorder}
                                                            size={"sm-space"}
                                                            icon={<Disc />} />
                                                    </div>
                                                </Tooltip>}
                                            {navigationRestrictions.chat.audio_recorder.active &&
                                                <Tooltip placement="topRight" title={'audio recorder'}>
                                                    <div className={`pg-header-icon-wrp sm   flex justify-content-center`}>
                                                        <MenuIcon
                                                            size={"sm-space"}
                                                            status={""}
                                                            onClick={props.onClickVoiceRecorder}
                                                            icon={<Mic />}
                                                        />
                                                    </div>
                                                </Tooltip>}
                                        </>
                                    }
                                </div>
                            }
                        </div>

                    </div>}
                </div>
            </div>
        </div>

    </>
    )

};

ChatTextWrapper.propTypes = {
    selected_profile: PropTypes.object.isRequired,
    chat_actions: PropTypes.object.isRequired
};

function mapStateToProps(state) {

    let tempProps = {
        call: state.call,
        selected_profile: state.selected_profile,
        team: state.team,
        canned_messages: state.canned_messages,
        whiteboard: state.whiteboard
    }
    if (state.selected_profile && state.selected_profile.username) {
        tempProps.text_panel_user_info = state.chat_text_panel_info[state.selected_profile.username];
        tempProps.presence = state.presence.data[state.selected_profile.username];
    }
    return tempProps;
}

function mapDispatchToProps(dispatch) {
    return {
        chat_actions: bindActionCreators(chatActions, dispatch),
        chat_text_panel_actions: bindActionCreators(chatTextPanelActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ChatTextWrapper);
