import React from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as meetingActions from "../../../redux/actions/meetingActions";
import Utils from '../../../utils/common';
import { MeetingSDK } from '../../../lib/MeetingsSdk';
import * as auth from "../../../utils";
import { message } from "antd/lib/index";
import * as logger from "../../../lib/logSdk/logger";
import { MenuIcon } from "../../common";
import { getUserId } from "../../../utils";
import {
    Trash, Link
} from "react-feather";
import { Popconfirm } from "antd";
import { NotificationService } from '../../../service/notificationService';
import { uiBaseUrl, anonymousJoinAllowed } from '../../../config/baseUrls';
import liveChatClient from "../../../lib";
import navigationRestrictions from '../../../config/navigationRestrictions';

const meetingSDK = new MeetingSDK();
let activeMeeting = {};
let activeInteractionId = "";
const userId = auth.getUserId() ;

const TodayEvents = (props) => {

    liveChatClient.subscribeEvent("TodayEvents_app_event_subscriber","onAppEvent",(appMessage) => {
          try {
            logger.info("TodayEvents","TodayEvents_app_event_subscriber",`appMessage : ${appMessage}`);
            const msg = JSON.parse(appMessage);
            if(msg.Type === "MEETING_JOINING_REQUEST_SUCCESS" && msg.StatusCode === 1 && msg.Status === "Joined" && activeMeeting.id === msg.MeetingId && activeInteractionId === msg.InteractionId && msg.UserId === userId){
                if(navigationRestrictions.meeting.dial_call_with_app_message === true){
                    let extIdObj = activeMeeting.configurations.find(x => x.feature === 'externalId');
                    props.onClickVideoCall("meeting", activeInteractionId, { ...msg.message_content, ['externalId']: extIdObj ? extIdObj.featureValue : '' });
                }else{
                    logger.error("TodayEvents","TodayEvents_app_event_subscriber","meeting call should start without app message event");
                }                
            }            
          } catch (error) {            
            logger.error("TodayEvents","TodayEvents_app_event_subscriber",error.message);
          }
        }
    );

    return <div className="meeting-list-items float-left width-100 mt-15" style={{ float: "left", overflowY: "auto", height: "210px" }}>
        {props.schedules.map(meeting => {
            if (props.meeting_statuses[meeting.id] && props.meeting_statuses[meeting.id].status !== 'Ended') {
                meeting.callee = meeting.title;
                
                return (
                    <div className="m-item rectangle-499" key={meeting.id}>
                        <div className="col-sm-5 col-md-5 col-lg-5 col-xl-5 float-left pl-0">
                            <div className="img-round"><img className="" alt="meet1.png" src={require('../../../assets/schedule/images/meet-1.png')} /></div>
                            <div className="call-doctor-for-test-7" title={meeting.title} style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "no-wrap" }}>{meeting.title}</div>
                            <div style={{ fontWeight: "bold", color: "Blue", marginLeft: "10px" }}>{`${props.meeting_statuses[meeting.id] ? props.meeting_statuses[meeting.id].status : ""}`} </div>
                            <p className="x15-mar-2020-900-a-6 sourcesanspro-normal-steel-blue-15px">{Utils.getLocalTime(meeting.startTime)} - {Utils.addMinsToTime(meeting.startTime, meeting.duration)}</p>
                        </div>
                        <div className="col-sm-7 col-md-7 col-lg-7 col-xl-7 float-left pr-0">

                            <div className="float-right mt-5" style={anonymousJoinAllowed === 'YES' && meeting.creator === props.userId ? { display: 'block' } : { display: 'none' }}>


                                <MenuIcon
                                    title="Copy Url"
                                    onClick={() => {
                                        navigator.clipboard.writeText(`${uiBaseUrl}/meeting/${meeting.id}/join/video`);
                                        message.success('Meeting url coppied to clipboard');
                                    }}
                                    size={"sm-space"}
                                    icon={<Link />}
                                />


                            </div>


                            <div className="float-right mt-5 ml-1" style={meeting.creator === props.userId && props.meeting_statuses[meeting.id] && (props.meeting_statuses[meeting.id].status !== 'Started') ? { display: 'block' } : { display: 'none' }}>
                                <Popconfirm
                                    title="Delete meeting ?"
                                    onConfirm={() => {
                                        meetingSDK.deactivateMeeting(meeting.id, auth.getUserId()).then(result => {
                                            if (result.statusCode === 1) {
                                                //success
                                                props.onDeleteMeeting(meeting.id);
                                                message.success(`Meeting Deleted Successfully`);
                                                props.meeting_actions.deleteMeeting(meeting.id);
                                            } else if (result.statusCode === 0) {
                                                message.error(result.statusMessage);
                                            } else {
                                                message.error('Error occurred deleting meeting');
                                            }

                                        }).catch(ex => {
                                            message.error('Error occurred deleting meeting');
                                            console.error(`Error deleting meeting`, ex);
                                        });
                                    }}
                                    // onCancel={cancel}
                                    okText="Delete"
                                    cancelText="Cancel"
                                >

                                    <MenuIcon
                                        title="Remove"
                                        onClick={() => { }}
                                        size={"sm-space"}
                                        icon={<Trash />}
                                    />
                                </Popconfirm>

                            </div>

                            <button className="btn btn-primary group-10 float-right mt-5 ml-1"
                                style={meeting.creator === props.userId && props.meeting_statuses[meeting.id] && (props.meeting_statuses[meeting.id].status === 'Created' || props.meeting_statuses[meeting.id].status === 'Updated') ? { display: 'block' } : { display: 'none' }}>
                                <div className="personal-4 sourcesanspro-normal-white-14px mt-2" onClick={() => {
                                    props.setUIState('MEETING_INFO_UPDATE', meeting);
                                }}>Reschedule</div>
                            </button>
                            {meeting.creator === props.userId && props.current_meeting && !props.current_meeting.interactionId && props.meeting_statuses[meeting.id] && props.meeting_statuses[meeting.id].status !== 'Started' && props.meeting_statuses[meeting.id].status !== 'Ended' && <button className="btn btn-primary group-9 float-right mt-5 ml-1" onClick={() => {
                                activeMeeting = meeting;
                                meetingSDK.startMeeting(meeting.id, auth.getUserId()).then(data => {
                                    activeInteractionId = data?.interactionId;
                                    
                                    if (data.statusCode === 1) {                                        
                                        props.meeting_actions.startMeeting(data.meetingId, data.interactionId, meeting.title);
                                        if(navigationRestrictions.meeting.dial_call_with_app_message === false){
                                                let extIdObj = meeting.configurations.find(x => x.feature === 'externalId');
                                                props.onClickVideoCall("meeting", data.interactionId, { ...meeting, ['externalId']: extIdObj ? extIdObj.featureValue : '' }); 
                                        }                                       

                                        NotificationService.addInstantNotification({
                                            notificationHandler: "UCHandler",
                                            notificationData: JSON.stringify({
                                                Message: JSON.stringify({ message_type: 'MEETING_STARTED', message: 'Meeting has started', otherData: { meetingId: data.meetingId, title: meeting.title, creator: meeting.creator } }),
                                                MessageType: 'STARTMEETING',
                                                MeetingCreator: getUserId()
                                            }),
                                            appId: "UC",
                                            appReferenceId: `${meeting.id}`,
                                            appReferenceType: `MeetingStart_${meeting.id}`,
                                            createdBy: getUserId()
                                        }).then(result => {

                                            if (result.statusCode === 0) {
                                                message.error(result.statusMessage);
                                            } else {
                                                if (result.statusCode !== 1) {
                                                    message.error('Error occurred setting meeting reminder');
                                                }
                                            }

                                        }).catch(ex => {

                                            console.error(ex);

                                            message.error('Error occurred setting meeting reminder');

                                        });
                                    } else {
                                        if (data.statusCode === 0) {
                                            message.error(data.statusMessage);

                                        } else {
                                            message.error("Error occurred while trying to start meeting");

                                        }
                                        console.log('TodayEvents', 'startMeeting', data.statusMessage);
                                        logger.debug('TodayEvents', 'startMeeting', data.statusMessage);


                                    }
                                })

                            }}>
                                <div className="personal-4 sourcesanspro-normal-white-14px">Start</div>
                            </button>}
                            {((meeting.creator !== props.userId && props.current_meeting && !props.current_meeting.interactionId) || (meeting.creator === props.userId && props.current_meeting && !props.current_meeting.interactionId && props.meeting_statuses[meeting.id] && props.meeting_statuses[meeting.id].status === 'Started' && props.current_meeting.meetingId !== meeting.id)) && <button className="btn btn-primary group-9 float-right mt-5 ml-1" onClick={() => {
                                activeMeeting = meeting;
                                meetingSDK.joinMeeting(meeting.id, auth.getUserId()).then(data => {
                                    activeInteractionId = data?.interactionId;
                                    
                                    if (data.statusCode === 1) {
                                        props.meeting_actions.startMeeting(data.meetingId, data.interactionId, meeting.title);
                                        if(navigationRestrictions.meeting.dial_call_with_app_message === false){
                                            let extIdObj = meeting.configurations.find(x => x.feature === 'externalId');
                                            props.onClickVideoCall("meeting", data.interactionId, { ...meeting, ['externalId']: extIdObj ? extIdObj.featureValue : '' }); 
                                        }  
                                    } else {
                                        if (data.statusCode === 0) {
                                            message.error(data.statusMessage);

                                        } else {
                                            message.error("Error occurred while trying to join meeting");
                                        }

                                        console.log('TodayEvents', 'startMeeting', data.statusMessage);
                                        logger.debug('TodayEvents', 'startMeeting', data.statusMessage);

                                    }
                                })

                            }}>
                                <div className="personal-4 sourcesanspro-normal-white-14px">Join</div>
                            </button>}
                        </div>
                    </div>
                );

            }

        })}
    </div>;
}

function mapDispatchToProps(dispatch) {
    return {
        meeting_actions: bindActionCreators(meetingActions, dispatch),
    };
}

function mapStateToProps(state) {

    return {
        meeting_statuses: state.meetings,
        current_meeting: state.current_meeting
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(TodayEvents);