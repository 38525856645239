class Configuration {

    constructor(obj) {
        this.feature = obj.feature;
        this.featureValue = obj.featureValue;
        this.enable = obj.enable;
    }

}

export { Configuration };