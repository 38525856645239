import React from 'react';
import PropTypes from 'prop-types';
import { ChatFooter, ForwardedLabel, MediaThumbnail, MenuIcon, ProfileImage } from "../index";
import { Popover } from "antd";
import { Headphones, MoreVertical, Video, RefreshCcw } from "react-feather";
import ReactPlayer from "react-player";
import { videoTypes, documentTypes, imageTypes, audioTypes } from "../../../config/json/fileTypeMap";
import navigationRestrictions from "../../../config/navigationRestrictions";


const getThumbnail = (mediaRecord) => {
    if (imageTypes.indexOf(mediaRecord.mimeType) > -1) {
        return (
            <img src={mediaRecord.thumbnail_url} alt="User Avatar" />
        )
    } else if (videoTypes.indexOf(mediaRecord.mimeType) > -1) {
        return (
            <MediaThumbnail medialType={'video'} />
        )
    } else if (audioTypes.indexOf(mediaRecord.mimeType) > -1) {
        return (
            <MediaThumbnail medialType={'audio'} />
        )
    } else {
        return (
            <img src={null} alt="User Avatar" />
        )
    }

}

const content = ({ onClickReplayMessage, onClickForwardMessage, msgObj, sendForwardMessages, onClickStar, isStarred }) => (
    <div className={'chat-hover-menu'}>
        <div
            onClick={() => onClickReplayMessage(msgObj)}
            className={'document-row'}>Reply
        </div>
        {navigationRestrictions.chat.forwarding.active && <div className={'document-row'}
            onClick={() => onClickForwardMessage((users) => {

                sendForwardMessages(users, msgObj);

            })}>
            Forward message
        </div>}
        {navigationRestrictions.chat.starring.active && <div className={'document-row'} onClick={() => onClickStar(msgObj, !isStarred)}>{isStarred ? 'Unstar Message' : 'Star Message'}</div>}
    </div>
);

const getReplyTextFormatted = (message) => {
    let splitmsg = message.split(/(?:\r\n|\r|\n)/g);

    return splitmsg.map(msg => {
        return (<p style={{ whiteSpace: "pre-wrap" }}>{msg}</p>)
    });
}

const ChatRightReplay = ({
    src, fullName, msg, msgObj, isImage, lastSeen, lastSeenStatus, onClickReplayMessage, fullNameReply,
    onClickForwardMessage, relatedMessageType, relatedMessageMediaSrc, controls, onClickOpenGallery, sendForwardMessages, onClickStar, isStarred, onMessageResendPressed, ...props
}) => {
    let mediaCount = msgObj.related_message.media ? msgObj.related_message.media.length : 0;

    let relatedIconPath = require("../../../assets/img/pdf.svg");

    if (relatedMessageType === "application/msword" || relatedMessageType === "application/vnd.openxmlformats-officedocument.wordprocessingml.document") {
        relatedIconPath = require("../../../assets/img/docx.svg");
    } else if (relatedMessageType === "application/vnd.ms-excel" || relatedMessageType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
        relatedIconPath = require("../../../assets/img/xls.svg");
    } else if (relatedMessageType === "text/csv") {
        relatedIconPath = require("../../../assets/img/csv.svg");
    } else if (relatedMessageType === "text/plain") {
        relatedIconPath = require("../../../assets/img/textSVG.svg");
    }
    return (
        <div key={props.mid} id={props.mid} className={`msg d-flex right-msg ${isImage ? '' : 'with-out-img'} `}>
            <div className="msg-img">
                <ProfileImage
                    textType={'chatRight'}
                    firstLatter={fullName ? fullName.split(' ').map(fullName => fullName[0]).join('').toUpperCase() : ""}
                    size={'sm'}
                    src={src}
                    padding="4px"
                    fontSize="0.8rem" />
            </div>
            <div className={`inner-msg-bubble ${isImage ? null : 'with-out-name'}`}>

                <div className={'msg-text'}>
                    <ForwardedLabel
                        textAlign={'right'}
                        isForwardedMsg={false} />


                    <div className="msg-text-with-document d-flex flex-column ">

                        <div
                            className={`document-wrapper replay-wrapper d-flex flex-column ${msgObj.related_message.message_content ? 'with-replay' : ''}`}
                            onClick={() => props.relatedMessageClick(msgObj.related_message)}>
                            <div className={'replay-msg-sender-name'}>
                                {fullNameReply}
                            </div>

                            {/*===== is replay  document =====*/}
                            {documentTypes.indexOf(relatedMessageType) > -1 &&
                                <>
                                    <div className={'d-flex'}>
                                        <div
                                            className={'inner-dco-wrapper replay-doc-wrp d-flex justify-content-center align-items-center'}>
                                            <img src={relatedIconPath}
                                                alt="Icon" />
                                        </div>
                                        <div className={'file-name d-flex justify-content-center align-items-center'}>
                                            {msgObj.related_message.media && msgObj.related_message.media.length > 0 && msgObj.related_message.media[0].content_title ? msgObj.related_message.media[0].content_title : msgObj.related_message.message_content}
                                        </div>

                                    </div>
                                    <p>{msgObj.related_message.message_content}</p>
                                </>
                            }

                            {/*===== is text message =====*/}
                            {(relatedMessageType === "text/html" || relatedMessageType === "html/link") &&
                                <div className={'d-flex'}>
                                    <div className={'file-name d-flex justify-content-center align-items-center'}>
                                        <div style={{ display: 'inline !important' }}>
                                            {getReplyTextFormatted(msgObj.related_message.message_content)}

                                        </div>
                                    </div>
                                </div>
                            }

                            {/*===== is image =====*/}
                            {(imageTypes.indexOf(relatedMessageType) > -1 || relatedMessageType === 'SELFVIEW_SNAPSHOT' || relatedMessageType === 'SIGNATURE_SNAPSHOT') &&
                                <>
                                    <div
                                        className={`replay-main-view-wrapper image-wrapper show-me}`}>
                                        {msgObj.related_message.message_content && <div
                                            className={'file-name  with-reply-img d-flex'}>
                                            {msgObj.related_message.message_content}
                                        </div>}
                                        <div
                                            className={`image-wrapper  multiple-media-wrapper d-flex flex-column 
                            ${mediaCount === 1 ? '' : 'width75'}
                            ${msg ? 'with-replay' : 'default '}`}>

                                            {/*===== one image view point =====*/}
                                            {mediaCount === 1 &&
                                                <div className={'image-view-wrapper one-image-view'}>
                                                    <img src={msgObj.related_message.media[0].thumbnail_url}
                                                        alt="User Avatar" />
                                                </div>
                                            }

                                            {mediaCount >= 2 &&
                                                <div className={'multiple-media-img-wrp t2-img d-flex'}>
                                                    <div className={'image-view-wrapper multiple-view border-radius-1'}>
                                                        {getThumbnail(msgObj.related_message.media[0])}
                                                    </div>
                                                    <div className={'image-view-wrapper multiple-view border-radius-1'}>
                                                        {getThumbnail(msgObj.related_message.media[1])}
                                                        {mediaCount === 3 && <div className={'more-img-element'}>
                                                            +1
                                    </div>}
                                                    </div>
                                                </div>
                                            }


                                            {mediaCount >= 4 &&
                                                <>
                                                    <div className={'d-flex'}>
                                                        <div className={'image-view-wrapper multiple-view border-radius-1'}>
                                                            {getThumbnail(msgObj.related_message.media[2])}
                                                        </div>
                                                        <div className={'image-view-wrapper multiple-view border-radius-1'}>
                                                            {mediaCount > 4 && <div className={'more-img-element'}>
                                                                +{mediaCount - 4}
                                                            </div>}
                                                            {getThumbnail(msgObj.related_message.media[3])}
                                                        </div>
                                                    </div>
                                                </>
                                            }
                                        </div>
                                        {/* <div className={'image-view-wrapper'}>
                                            <img src={relatedMessageMediaSrc}
                                                alt="User Avatar" />
                                        </div> */}
                                    </div>
                                </>
                            }

                            {/*===== is video =====*/}
                            {videoTypes.indexOf(relatedMessageType) > -1 &&
                                <>
                                    <div className={`d-flex ${msgObj.related_message.message_content ? 'show-me' : 'hide-me'}`}>
                                        <div
                                            className={'file-name  with-reply-img d-flex justify-content-center align-items-center'}>
                                            {msgObj.related_message.message_content}
                                        </div>
                                        <div
                                            className={'inner-dco-wrapper replay-video-wrp d-flex  flex-wrap justify-content-center align-items-center'}>
                                            <div className={'gallery-thumbnail-wrp audio '}>
                                                <ReactPlayer
                                                    width={200}
                                                    playing={false}
                                                    controls={controls}
                                                    url={relatedMessageMediaSrc} />

                                                <div
                                                    className={'reply-icon-duration d-flex justify-content-center align-items-center'}>
                                                    {/* <div className={'duration'}>
                                                        0:30
                                                    </div> */}
                                                    <Video />

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div
                                        className={`replay-main-view-wrapper ${msgObj.related_message.message_content ? 'hide-me' : 'show-me'}`}>
                                        <div className={'chat-video-wrapper reply-preview'}>
                                            <ReactPlayer
                                                width={200}
                                                playing={false}
                                                controls={controls}
                                                url={relatedMessageMediaSrc} />

                                            <div
                                                className={'reply-icon-duration video-lg d-flex justify-content-center align-items-center'}>
                                                {/* <div className={'duration'}>
                                                    0:30
                                                </div> */}
                                                <Video />

                                            </div>
                                        </div>
                                    </div>
                                </>
                            }

                            {/*===== is audio =====*/}
                            {audioTypes.indexOf(relatedMessageType) > -1 &&
                                <>
                                    <div
                                        className={`replay-main-view-wrapper d-flex justify-content-between ${msgObj.related_message.message_content ? 'show-me' : 'hide-me'}`}>
                                        <div
                                            className={`file-name  with-reply-img d-flex justify-content-center align-items-center`}>
                                            {msgObj.related_message.message_content}
                                        </div>
                                        <div
                                            className={`inner-dco-wrapper replay-video-wrp d-flex  flex-wrap justify-content-center align-items-center  justify-content-end`}>
                                            <div className={'gallery-thumbnail-wrp reply-preview audio '}>
                                                <Headphones />
                                                {/* <div className={'duration'}>
                                                    0:30
                                                </div> */}
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className={`replay-main-view-wrapper ${msgObj.related_message.message_content ? 'hide-me' : 'show-me'}`}>
                                        <div className={`chat-audio-inner-wrapper`}>
                                            <audio controls>
                                                <source src={relatedMessageMediaSrc} type="audio/ogg" />
                                            </audio>
                                        </div>
                                    </div>
                                </>
                            }

                        </div>
                        {getReplyTextFormatted(msg)}
                        <Popover
                            content={content({ onClickReplayMessage, onClickForwardMessage, msgObj, sendForwardMessages, onClickStar, isStarred })}
                            trigger="hover">
                            <div className={'hover-more'}>
                                <MenuIcon
                                    icon={<MoreVertical />} />
                            </div>
                        </Popover>
                    </div>

                </div>
                <ChatFooter lastSeen={lastSeen}
                    lastSeenStatus={lastSeenStatus}
                    deliveredStatus={msgObj.is_delivered}
                    sentStatus={msgObj.is_sent_successfull}
                    textRight={true}
                    isStarred={isStarred} />
            </div>
            {msgObj.is_sent_successfull === "FAIL" && !lastSeenStatus && !msgObj.is_delivered && <div style={{ marginBottom: "inherit", padding: "7px" }}>
                <MenuIcon onClick={() => onMessageResendPressed(msgObj)}

                    size={"sm-space"}
                    icon={<RefreshCcw />}
                />

            </div>}
        </div>
    )
};

ChatRightReplay.propTypes = {
    fullName: PropTypes.string.isRequired,
    msg: PropTypes.string.isRequired,
    isImage: PropTypes.bool.isRequired,
    isLastSeen: PropTypes.bool.isRequired,
};

ChatRightReplay.defaultProps = {
    src: null,
};

export default ChatRightReplay;
