import React from 'react';
import PropTypes from 'prop-types';
import { Video, X } from "react-feather";
import { MenuIcon } from "../index";
import { Scrollbars } from "react-custom-scrollbars";


//online
//offline
//busy
//other
const UploadVideoThumbnail = ({ active, src, type, onClickRemoveImage, onClickVideo, fileSize }) => (
    <div
        className={`upload-img video-view   d-flex flex-column justify-content-center align-items-center ${active ? 'active' : ''}`}>
        <div className={'remove-wrp d-flex justify-content-center align-items-center'}>
            <MenuIcon icon={<X />}
                onClick={onClickRemoveImage}
                size={'sm'} />
        </div>
        <div onClick={onClickVideo}>

            <MenuIcon
                icon={<Video />} />
            {fileSize && <div className={'duration'}>
                {fileSize}
            </div>}

        </div>

    </div>
);

// OutGoingCall.propTypes = {
//     status: PropTypes.string.isRequired,
// };

UploadVideoThumbnail.defaultProps = {};

export default UploadVideoThumbnail;
