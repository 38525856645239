import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { site_key } from "../src/config/baseUrls";
import navigationRestrictions from "../src/config/navigationRestrictions";

console.log(
  "%cTETHERFI COLLABORATION APP",
  "color:#233E82; font-family:'Ubuntu'; display: block;font-weight:bold; font-size:60px;"
);
/* ReactDOM.render(<App />, document.getElementById('root')); */

if (navigationRestrictions.recaptchaProvider &&
  navigationRestrictions.recaptchaProvider.active ) {
  ReactDOM.render(
    <GoogleReCaptchaProvider reCaptchaKey={site_key}>
      <App />
    </GoogleReCaptchaProvider>,
    document.getElementById("root")
  );
} else {
  ReactDOM.render(<App />, document.getElementById("root"));
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
