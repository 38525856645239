import React from 'react';
import PropTypes from 'prop-types';


const EmojiPanel = ({ onEmojiClick }) => (
    <div className={'emoji-main-wrapper'}>
        <div className={'emoji-list d-flex flex-wrap'}>
            <div data-code={'U+1F44B'} className={'emoji-item'}
                onClick={() => onEmojiClick('👋')}>
                👋
            </div>
            <div data-code={'U+1F44C'} className={'emoji-item'}
                onClick={() => onEmojiClick('👌')}>
                👌
            </div>
            <div data-code={'U+1F446'} className={'emoji-item'}
                onClick={() => onEmojiClick('👆')}>
                👆
            </div>
            <div data-code={'U+1F44D'} className={'emoji-item'}
                onClick={() => onEmojiClick('👍')}>
                👍
            </div>
            <div data-code={'U+1F64F'} className={'emoji-item'}
                onClick={() => onEmojiClick('🙏')}>
                🙏
            </div>
            <div data-code={'U+1F44F'} className={'emoji-item'}
                onClick={() => onEmojiClick('👏')}>
                👏
            </div>
            <div data-code={'U+1F600'} className={'emoji-item'}
                onClick={() => onEmojiClick('😀')}>
                😀
            </div>
            <div data-code={'U+1F606'} className={'emoji-item'}
                onClick={() => onEmojiClick('😆')}>
                😆
            </div>
            <div data-code={'U+1F923'} className={'emoji-item'}
                onClick={() => onEmojiClick('😶')}>😶
            </div>
            <div data-code={'U+1F62D'} className={'emoji-item'}
                onClick={() => onEmojiClick('😭')}>
                😭
            </div>
            <div data-code={'U+1F642'} className={'emoji-item'}
                onClick={() => onEmojiClick('🥺')}>
                🥺
            </div>
            <div data-code={'U+1F643'} className={'emoji-item'}
                onClick={() => onEmojiClick('🔥')}>
                🔥
            </div>
            <div data-code={'U+1F641'} className={'emoji-item'}
                 onClick={() => onEmojiClick('🤐')}>
                🤐
            </div>
            <div data-code={'U+1F609'} className={'emoji-item'}
                onClick={() => onEmojiClick('😉')}>
                😉
            </div>
            <div data-code={'U+1F60A'} className={'emoji-item'}
                onClick={() => onEmojiClick('😊')}>
                😊
            </div>
            <div data-code={'U+1F607'} className={'emoji-item'}
                onClick={() => onEmojiClick('😇')}>
                😇
            </div>
            <div data-code={'U+1F60D'} className={'emoji-item'}
                onClick={() => onEmojiClick('😍')}>
                😍
            </div>
            <div data-code={'U+1F60B'} className={'emoji-item'}
                onClick={() => onEmojiClick('😋')}>
                😋
            </div>
            <div data-code={'U+1F92A'} className={'emoji-item'}
                onClick={() => onEmojiClick('🤪')}>
                🤪
            </div>
            <div data-code={'U+1F636'} className={'emoji-item'}
                onClick={() => onEmojiClick('😎')}>
                😎
            </div>
            <div data-code={'U+1F600'} className={'emoji-item'}
                onClick={() => onEmojiClick('😥')}>
                😥
            </div>
            <div data-code={'U+1F621'} className={'emoji-item'}
                onClick={() => onEmojiClick('😡')}>
                😡
            </div>
            <div data-code={'U+1F60E'} className={'emoji-item'}
                onClick={() => onEmojiClick('💪')}>
                💪
            </div>
            <div data-code={'U+1F47B'} className={'emoji-item'}
                onClick={() => onEmojiClick('👻')}>
                👻
            </div>
            <div data-code={'U+1F648'} className={'emoji-item'}
                onClick={() => onEmojiClick('🙈')}>
                🙈
            </div>
        </div>
    </div>
);

EmojiPanel.propTypes = {
    //onClick: PropTypes.func.isRequired,
};



export default EmojiPanel;
