import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import './styles/profile-details.scss';
import { __APP_CUST_DYNAMIC_INFO_COUNT__ } from "../../../config/baseUrls";
import { TeamService } from "../../../service/teamService";
import { TextBoxWithLabel, Button } from "../../common";

import { fireEvent } from "../../../utils/eventEmitter";

import { profileFound } from "../../../redux/actions/externalUserProfileActions";

const ExtProfileDetails = (props) => {

    let dispatch = useDispatch();

    const extProfile = useSelector((state) => state.selected_ext_profile);

    const [formData, setFormData] = useState({ searchVal: "", dataLoaded: false });
    useEffect(() => {
        //get contact info
        if (props.userData) {
            TeamService.findContact(props.userData.customerId, props.userData.smChannel).then(data => {
                dispatch(profileFound(data));
                setFormData({ ...formData, searchVal: "", dataLoaded: true });

            }).catch(ex => {
                dispatch(profileFound(null));
                setFormData({ ...formData, searchVal: "", dataLoaded: true });
                console.error(ex);
            })

        }


    }, []);


    return (
        <>
            {!extProfile && formData.dataLoaded && <div style={{ padding: "15px" }}>
                <TextBoxWithLabel
                    style={"default"}
                    labelStyle={"sm"}
                    labelText={"Phone Number"}
                    placeholder={""}
                    required={true}
                    type={"text"}
                    disable={false}
                    value={formData.searchVal}
                    onChange={(e) => {
                        setFormData({ ...formData, searchVal: e.target.value });
                    }}
                />
                <br />
                <Button
                    title={"Search"}
                    size={"md"}
                    onClick={() => {
                        fireEvent("show_profile_search_modal", {
                            searchVal: formData.searchVal, dynamicData: props.custInfoData, contactDetails: props.userData, onSuccess: (profile) => {
                                dispatch(profileFound(profile));
                                setFormData({ ...formData, searchVal: "" });

                            }
                        });
                    }}
                />

            </div>}

            {extProfile && formData.dataLoaded && <div>{Object.keys(props.custInfoData).map(key => {
                return (<div key={`ExtProfileInfo_${key}`} className="d-flex flex-column dynamic-info-wrapper">
                    <div className="u-title text-left"> {props.custInfoData[key]} :</div>
                    <div className="pl-1 u-value text-left"> {Boolean(extProfile[key])
                        ? extProfile[key].toString() : extProfile[key]}</div>
                </div>)
            }

            )}
            </div>}

        </>

    );
};

export default ExtProfileDetails;
