import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Accordion, Card, Button, useAccordionToggle } from 'react-bootstrap';
import { Scrollbars } from "react-custom-scrollbars";
import { MenuIcon } from "../../common";
import {
    ArrowDownCircle, ArrowRight
} from "react-feather";

import * as eventEmitter from "../../../utils/eventEmitter";

function CustomToggle({ children, eventKey }) {
    const decoratedOnClick = useAccordionToggle(eventKey, () =>
        console.log('totally custom!'),
    );

    return (
        <>
            <MenuIcon
                onClick={decoratedOnClick}
                status={"material"}
                size={"sm-space"}
                icon={<ArrowDownCircle />}
            />

        </>

    );
}

const AdminCannedMessages = props => {

    const [searchText, setSearchText] = useState("");

    const handleSearching = (sourceData) => {

        if (searchText) {
            //filter
            try {

                var regex = RegExp(searchText, "i");
                let filtered = Object.entries(sourceData).reduce((acc, [key, template]) => {

                    if (regex.test(template.TemplateName)) {
                        acc = { ...acc, ...{ [key]: template } };
                    }
                    return acc;
                }, {});

                return filtered;

            } catch (ex) {
                console.error(ex);
                return {};
            }

        } else {
            return sourceData;
        }

    }

    const getTemplateMessageFormatted = (template) => {


        let message = template.TemplateMessage;

        if (template.Trigger) {

            let words = message.replace(template.Trigger, `<span style="color:blue;">${template.Trigger}</span>`);
            return { __html: words };

        } else {
            return { __html: `<span>${message}</span>` };
        }

    }

    useEffect(() => {

        eventEmitter.subscribeToEvents("canned_message_filter", (searchText => {
            setSearchText(searchText);
        }), "admin_canned");

    }, []);

    let filteredTemplates = handleSearching(props.admin_canned_messages);


    return (
        <>

            {
                Object.entries(filteredTemplates).map(([key, template]) => {
                    return (
                        <>
                            <div key={key} className={'msg-temp-wrapper sm d-flex '}>
                                <div className={'msg-tem-left-wrapper ' +
                                    'd-flex flex-column'}>
                                    <div className={'msg-temp-name trigger'}>
                                        {template.TemplateName}
                                    </div>
                                    <div className={'msg-body'}>
                                        <div dangerouslySetInnerHTML={getTemplateMessageFormatted(template)}>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    )
                })
            }
        </>
    );
};

function mapStateToProps(state) {
    return {
        admin_canned_messages: state.canned_messages.admin
    };
}

export default connect(mapStateToProps)(AdminCannedMessages);
