import React, { useState, useEffect } from "react";
import { Modal } from 'antd';
import { subscribeToEvents } from "../../../utils/eventEmitter";

const UIDisableModal = props => {

    const [modalStatus, setModalStatus] = useState({
        openState: false
    });

    useEffect(() => {

        subscribeToEvents('disable_ui', data => {

            setModalStatus({
                openState: true
            })

        })

        subscribeToEvents('enable_ui', data => {

            setModalStatus({
                openState: false
            })

        })

    }, []);


    return (
        <>
            <Modal
                title=""
                closable={false}
                visible={modalStatus.openState}
                maskClosable={false}
                confirmLoading={false}
                footer={null}
                mask={false}
            >
                <>
                    <div style={{ display: "flex" }}>
                        <div style={{ margin: "auto" }}>Synchronizing Chat Messages - Please Wait</div>

                    </div>
                    <div>
                        <div className="loader9"></div>

                    </div>

                </>


            </Modal>
        </>
    );
};

export default UIDisableModal;
