import { consoleConfigurationServiceUrl } from "../config/baseUrls";
import { fetchDefaults } from "./fetchWrapper";
import * as logger from '../lib/logSdk/logger';

var apiFetch = fetchDefaults(fetch);
export class configurationManagerService {
  static getPhoneConfig = () => {
    try {
      return apiFetch(`${consoleConfigurationServiceUrl}/configurationmanager/phone/config`, {
        method: "get",
      })
        .then(function (response) {
          return response.json();
        })
        .then(function (data) {
          return data.isSuccess ? data.result : null;
        })
        .catch((error) => {
          console.error("configurationManagerService","getPhoneConfig", error);
          logger.error("configurationManagerService","getPhoneConfig", error.message);
          return null;
        });
    } catch (error) {
      console.error("configurationManagerService","getPhoneConfig", error);
          logger.error("configurationManagerService","getPhoneConfig", error.message);
          return null;
    }    
  };

  static getCustInfoData = () => {
    return apiFetch(`${consoleConfigurationServiceUrl}/configurationmanager/custInfo/config`, {
      method: "get",
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        return data.isSuccess ? data.result : null;
      })
      .catch((error) => {
        console.error("configurationManagerService","getCustInfoData",error);
        logger.error("configurationManagerService","getCustInfoData", error.message);
        return null;
      });
  };

  static getBaseUrlConfig = () => {
    return apiFetch(`${consoleConfigurationServiceUrl}/configurationmanager/baseurl/config`, {
      method: "get",
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        return data.isSuccess ? data.result : null;
      })
      .catch((error) => {
        console.error("configurationManagerService","getBaseUrlConfig", error);
        logger.error("configurationManagerService","getBaseUrlConfig", error.message);
        return null;
      });
  };

  
}
