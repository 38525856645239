import * as logger from '../../../../lib/logSdk/logger';
const removeStream = async (localStream) => {
    
    setTimeout(() => {
        try {
            console.log("utility", "removeStream");
          if (localStream.active) {
            let tracks = localStream.getTracks();
            tracks.forEach(function(track) {
              track.stop();
            });
          }
    
          var audioTrack = localStream.getAudioTracks();
          if (audioTrack.length > 0) {
            localStream.removeTrack(audioTrack[0]);
          }
    
          var videoTrack = localStream.getVideoTracks();
          if (videoTrack.length > 0) {
            localStream.removeTrack(videoTrack[0]);
          }
        } catch (e) {
            console.log("utility", "removeStream",e);
        }        
      }, 1000);

    
  }

const checkPermission =  (getUserMedia,constraint) =>{
    
    return new Promise(async(resolve, rejection) => {
        if (getUserMedia) {
            try {
                /* const mConstraint = constraint==='video'?{'video':true}:{'audio':true};
                const stream = await getUserMedia({'video':true,'audio':true}); */

                const mConstraint = constraint==='all'?{'video':true,'audio':true} : constraint==='video'?{'video':true}:{'audio':true};
                const stream = await navigator.mediaDevices.getUserMedia(mConstraint);
                const mediaStreamTrack = constraint==='video'?stream.getVideoTracks()[0]:stream.getAudioTracks()[0];
                if (typeof mediaStreamTrack != "undefined") {
                    /* mediaStreamTrack.onended = function () {//for Chrome.
                        resolve(`Your ${constraint} device is busy`)
                    } */
                    resolve(true);
                } else {
                    resolve('Permission denied!');
                }
                removeStream(stream);
            } catch (e) {
                var message;
                switch (e.name) {
                    case 'AbortError':
                        message = `Access abort`;
                        break;
                    case 'InvalidStateError':
                        message = `Device state issue`;
                        break;                    
                    case 'NotReadableError':
                        message = `Not Readable Error`;
                        break; 
                    case 'OverconstrainedError':
                        message = `Over constrained`;
                        break;                       
                    case 'NotFoundError':
                    case 'DevicesNotFoundError':
                        message = `Devices Not Found`;
                        break;
                    case 'SourceUnavailableError':
                        message = `${constraint} device is busy`;
                        break;                    
                    case 'NotAllowedError':
                    case 'PermissionDeniedError':
                    case 'SecurityError':
                        message = 'Permission denied!';
                        break;
                    default: 
                    message = 'OS level permission issue!';
                        break;
                }
                console.error("utility","checkPermission",`message: ${message}`,e);
                logger.error("utility", "checkPermission", e.message);
                resolve(message);
            }           
        } else {
            resolve('Incompatible browser!')
        };
        /* if (location.protocol === 'https:') {
            
        } else {
            resolve('Use https protocol for open this page.');
        } */
    })
    
}
export const  CheckDeviceSupport =  ()=> {
    if (navigator.mediaDevices && navigator.mediaDevices.enumerateDevices) {
        // Firefox 38+ seems having support of enumerateDevicesx
        navigator.enumerateDevices = function (callback) {
            navigator.mediaDevices.enumerateDevices().then(callback);
        };
    }

    var MediaDevices = [];
    var isHTTPs = Location.protocol === 'https:';
    var canEnumerate = false;

    if (typeof MediaStreamTrack !== 'undefined' && 'getSources' in MediaStreamTrack) {
        canEnumerate = true;
    } else if (navigator.mediaDevices && !!navigator.mediaDevices.enumerateDevices) {
        canEnumerate = true;
    }

    var hasMicrophone = false;
    var hasSpeakers = false;
    var hasWebcam = false;

    var isMicrophoneAlreadyCaptured = false;
    var isWebcamAlreadyCaptured = false;
    var isWebRTCSupported = false;
    var isScreenshareSupported = false;

    return new Promise(async (resolutionFunc, rejectionFunc) => {
        var getUserMediaFn = navigator.mediaDevices.getUserMedia || navigator.getUserMedia || navigator.webkitGetUserMedia || navigator.mozGetUserMedia || navigator.msGetUserMedia;
        var getDisplayMediaFn = navigator.mediaDevices.getDisplayMedia || navigator.getDisplayMedia;

        if (getUserMediaFn != null) {
            isWebRTCSupported = true;
        }

        if (getDisplayMediaFn != null) {
            isScreenshareSupported = true;
        }

        if (!canEnumerate) {
            return resolutionFunc({ hasWebcam, hasMicrophone, isMicrophoneAlreadyCaptured, isWebcamAlreadyCaptured, MediaDevices: null, isWebRTCSupported, isScreenshareSupported });
        }

        if (!navigator.enumerateDevices && window.MediaStreamTrack && window.MediaStreamTrack.getSources) {
            navigator.enumerateDevices = window.MediaStreamTrack.getSources.bind(window.MediaStreamTrack);
        }

        if (!navigator.enumerateDevices && navigator.enumerateDevices) {
            navigator.enumerateDevices = navigator.enumerateDevices.bind(navigator);
        }

        if (!navigator.enumerateDevices) {
            resolutionFunc({ hasWebcam, hasMicrophone, isMicrophoneAlreadyCaptured, isWebcamAlreadyCaptured, MediaDevices: null, isWebRTCSupported, isScreenshareSupported });
        }

               
        MediaDevices = [];
        navigator.enumerateDevices(function (devices) {
            if(devices){
                devices.forEach(function (_device) {
                    var device = {};
                    for (var d in _device) {
                        device[ d ] = _device[ d ];
                    }
        
                    if (device.kind === 'audio') {
                        device.kind = 'audioinput';
                    }
        
                    if (device.kind === 'video') {
                        device.kind = 'videoinput';
                    }
        
                    var skip;
                    MediaDevices.forEach(function (d) {
                        if (d.id === device.id && d.kind === device.kind) {
                            skip = true;
                        }
                    });
        
                    if (skip) {
                        return;
                    }
        
                    if (!device.deviceId) {
                        device.deviceId = device.id;
                    }
        
                    if (!device.id) {
                        device.id = device.deviceId;
                    }
        
                    if (!device.label) {
                        device.label = 'Please invoke getUserMedia once.';
                        if (!isHTTPs) {
                            device.label = 'HTTPs is required to get label of this ' + device.kind + ' device.';
                        }
                    } else {
                        if (device.kind === 'videoinput' && !isWebcamAlreadyCaptured) {
                            isWebcamAlreadyCaptured = true;
                        }
        
                        if (device.kind === 'audioinput' && !isMicrophoneAlreadyCaptured) {
                            isMicrophoneAlreadyCaptured = true;
                        }
                    }
        
                    if (device.kind === 'audioinput') {
                        hasMicrophone = true;
                    }
        
                    if (device.kind === 'audiooutput') {
                        hasSpeakers = true;
                    }
        
                    if (device.kind === 'videoinput') {
                        hasWebcam = true;
                    }
        
                    // there is no 'videoouput' in the spec.
                    delete device.toJSON;
                    delete device.getCapabilities;
                    MediaDevices.push(device);
                });
            }
        });

        //checkPermission(getUserMediaFn,{'video':true,'audio':true});
        isWebcamAlreadyCaptured = await checkPermission(getUserMediaFn,"video");
       
        isMicrophoneAlreadyCaptured = await checkPermission(getUserMediaFn,"audio");

        checkPermission(getUserMediaFn,"all");
        resolutionFunc({ hasWebcam, hasMicrophone, isMicrophoneAlreadyCaptured, isWebcamAlreadyCaptured, MediaDevices, isWebRTCSupported, isScreenshareSupported });
        
    });
}
