import React from 'react';
import PropTypes from 'prop-types';

const TitleWithSmallText = ({time, title, type,msg,color}) => (
    <>
        {type !== 'sm-text' &&
        <>
            <div className={`title-name ${color}`}>{title}</div>
            <div className={`time-count ${color}`}>{time}</div>
        </>
        }

        {type === 'sm-text' &&
        <>
            <div className={`sm-title-name ${color}`}>{title}</div>
            <div className={`sm-msg ${color}`}>{msg}</div>
        </>
        }
    </>
);

// TitleWithSmallText.propTypes = {
//     title: PropTypes.string.isRequired,
//     small: PropTypes.string.isRequired,
//     type: PropTypes.string.isRequired,
//     disabled: PropTypes.bool,
// };

// TitleWithSmallText.defaultProps = {
//     type: 'btn-primary',
//     size: 'btn-sm',
//     disabled: false,
// };

export default TitleWithSmallText;
