export class TempAgent {
    constructor(obj) {
        this.createdAt = obj.time;
        this.userId = obj.userId;
        this.firstName = obj.userName ? obj.userName : "";
        this.lastName = " ";
        this.isTempAgent = true;
        this.conversation_type = 0;
        this.isVideo = obj.isVideo;
    }
}