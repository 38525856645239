import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'antd';
import navigationRestrictions from "../../../config/navigationRestrictions";
//online
//offline
//busy
//other
const ChatFilterTab = (props) => {

    const [selectedTab, setSelectedTab] = useState(1);

    const selectTab = (tabValue) => {

        setSelectedTab(tabValue);
        if (selectTab !== tabValue) {
            props.onSelectionHandler(tabValue);
        }

    }
    return (
        navigationRestrictions.contacts.switcher.active && <div className={`filter-inner-wrp d-flex justify-content-between `}>

            <Tooltip title={'Chat'}>
                <div onClick={() => selectTab(1)}
                    className={`menu-icon-wrp ${selectedTab === 1 ? `active` : ""} d-flex align-items-center`}>
                    <i className="material-icons">
                        insert_comment
                </i>
                </div>
            </Tooltip>

            <Tooltip title={'Group'}>
                <div onClick={() => selectTab(2)}
                    className={`menu-icon-wrp ${selectedTab === 2 ? `active` : ""} d-flex align-items-center`}>
                    <i className="material-icons">
                        people_outline
                </i>
                </div>
            </Tooltip>

            {/* <Tooltip title={'Customer'}>
                <div onClick={() => selectTab(3)}
                    className={`menu-icon-wrp ${selectedTab === 3 ? `active` : ""} d-flex align-items-center`}>
                    <i className="material-icons">
                        face
                </i>
                </div>
            </Tooltip> */}


            <Tooltip title={'Colleague'}>
                <div onClick={() => selectTab(4)}
                    className={`menu-icon-wrp ${selectedTab === 4 ? `active` : ""} d-flex align-items-center`}>
                    <i className="material-icons">
                        perm_identity
                </i>
                </div>
            </Tooltip>
        </div>
    )
};

ChatFilterTab.propTypes = {
    //onClick: PropTypes.func.isRequired,
};


export default ChatFilterTab;
