import React, { useEffect, useState } from "react";
import { MenuIcon, MuteButton, VideoLiveCount, AudioListener } from "../../common";
import { Minimize, Maximize, MicOff, Mic , Airplay} from "react-feather";
import { SvideoRefpin, Tooltip, Spin ,Tag ,Row,Col } from "antd";
import { useDispatch, useSelector } from "react-redux";
import navigationRestrictions from "../../../config/navigationRestrictions";
import { onCallMuteUnmute } from "../../../redux/actions/callControlsActions";

const videoRef = React.createRef();

const ScreenShareModal = (props) => {
  const [displayStatusClassNameTopLevel] = useState("pin-this");
  const [isLoading, setIsLoading] = useState(true);
  const [isMute, setMute] = useState(false);

  const state = useSelector((state) => state);
  const dispatch = useDispatch();


  useEffect(() => {
    console.log("ScreenShareModal", "Calling useEffect Method");
  }, []);

  useEffect(() => {
    try {
      if (videoRef.current) {
        videoRef.current.srcObject = props.mediaStream;
        videoRef.current.addEventListener("loadeddata", (event) => {
          console.debug(
            "ScreenShareModal",
            "useEffect",
            "bind stream to video element"
          );
        });
      }
    } catch (error) {
      console.error("ScreenShareModal", "useEffect", error);
    }
  }, [props.mediaStream]);

  useEffect(() => {
    try {
      setMute(state.callControls.controls.isCallMuted);
    } catch (error) {
      console.log("ScreenShareModal", "useEffect", error);
    }
  }, [state.callControls.controls.isCallMuted]);

  const muteUnmuteAudio = (val) => {
    setMute(val);
    props.muteUnmuteAudioVideo(val, state.callControls.controls.isVideoMuted);
    dispatch(
      onCallMuteUnmute({
        userId: props.caller,
        callType: "AUDIO_CALL",
        isOperated: true,
        isMuted: val,
      })
    );
  };



  return (
    <>
            
      {/* {props.screenShareSelf && (
        <div
        className={`video-call-player-wrapper d-flex justify-content-between ${displayStatusClassNameTopLevel} `}
        style={{
          width: 200,
          height: 42,
        }}
      >    

        <Row>          
          <Col xs={14} sm={14} md={14} lg={14} xl={14}>
            <Tag color="#e4deff" style={{marginTop: "12px" ,   marginLeft: "12px" , marginRight:"30px"}}>ScreenShared </Tag>
          </Col>
          <Col xs={10} sm={10} md={10} lg={10} xl={10}>
            <Row>
              <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                <button title={isMute? "Unmute":"Mute"} className={"pg-header-icon-wrp white lg-round"} style={{ border: "none" , marginTop: "2px" }} onClick={() => muteUnmuteAudio(!isMute)}>{isMute ? <MicOff></MicOff> : <Mic></Mic>}</button>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                <button title="End Sharing" className={"pg-header-icon-wrp white lg-round"} style={{ border: "none" ,backgroundColor:"#FF5B5B", marginTop: "2px"}} onClick={() => {props.stopScreenSharing()}}><Airplay/></button> 
              </Col>
            </Row>
          </Col>
          <Col>
          { props.mediaStream && <AudioListener mediaStream={ props.mediaStream } />}
          </Col>
        </Row>
      </div>
      )} */}

  {!props.screenShareSelf && ( 
    <div
    className={`video-call-player-wrapper d-flex justify-content-between ${displayStatusClassNameTopLevel} `}
    style={{
      width: props.isVideoMinimizeScreen ? 500 : props.viewPointWidth,
      height: props.isVideoMinimizeScreen ? 300 : props.viewPointHeight,
    }}
  >
    <div
      className={`video-wrapper d-flex ${
        props.isVideoMinimizeScreen ? "minimizer-screen" : "full-screen"
      }`}
    >
      <div
        className={`main-video-wrapper ${
          props.isVideoMinimizeScreen ? "minimizer-screen" : "full-screen"
        }`}
      >
        {/*======= callDurations ==========*/}
        <VideoLiveCount
          isVideoLoading={false}
          isVideoMinimizeScreen={props.isVideoMinimizeScreen}
          onClickOpenParticipation={() => {}}
          isAllowedScreenShare={true}
          onClickUnlockVideo={props.onClickUnlockVideo}
          isOpenParticipationPanel={false}
          userDetails={""}
          callDurations={""}
          isScreenShare={true}
        />
        {isLoading && (
              <Spin tip="waiting for user..."
                style={{ margin: "auto",  position: "absolute",  top: `${props.isVideoMinimizeScreen ? "40%" : "47%"}` , left: `${props.isVideoMinimizeScreen ? "40%" : "47%"}`, }}
              ></Spin>              
            )}
            <video
              onLoadedData={() => setIsLoading(false)}
              id="screenshareVideo"
              ref={videoRef}
              /*  ref={(video) => {
                console.error("+++++++++++++++++++++++++++++++++++")
                if (props.mediaStream !== undefined && video != null)
                  video.srcObject = props.mediaStream;
              }} */
              autoPlay
              playsInline
            ></video>
        {/* {props.mediaStream !== undefined && (
          <>
            {isLoading && (
              <Spin
                style={{
                  margin: "auto",
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                }}
              />
            )}
            <video
              onLoadedData={() => setIsLoading(false)}
              id="screenshareVideo"
              ref={videoRef}
              autoPlay
              playsInline
            ></video>
          </>
        )} */}
        <>
          <div className={`local-video-block local-video-show`}>
            {/*===== Full Screen Action wrapper =====*/}
            {!props.isVideoMinimizeScreen && (
              <div
                className={`d-flex justify-content-center align-items-center`}
              >
                <div className={`video-call-action-wrp enable-fullscreen`}>
                  <div
                    className={
                      "inner-wrapper-screen-share-action d-flex align-items-center"
                    }
                  >
                    {
                      <Tooltip
                        title={`${
                          props.isVideoMinimizeScreen
                            ? "Maximize"
                            : "Minimize"
                        }`}
                      >
                        <div
                          className={`pg-header-icon-wrp white lg-round ${  props.isVideoMinimizeScreen ? "minimizer-screen active-max-screen " : "full-screen" } `}
                        >
                          <button
                                className={"pg-header-icon-wrp white lg-round"}
                                style={{ border: "none" }}
                                onClick={() =>
                                  props.onClickUnlockVideo(
                                    !props.isVideoMinimizeScreen
                                  )
                                }
                              >
                                {props.isVideoMinimizeScreen ? (
                                  <Maximize />
                                ) : (
                                  <Minimize />
                                )}
                              </button>

                        </div>
                      </Tooltip>
                    }
                    {navigationRestrictions.calls.video_calls.mute
                          .active && (
                          <>
                            <button
                              className={"pg-header-icon-wrp white lg-round"}
                              style={{ border: "none" }}
                              onClick={() => muteUnmuteAudio(!isMute)}
                            >
                              {isMute ? <MicOff></MicOff> : <Mic></Mic>}
                            </button>
                          </>
                        )}


                        <Tooltip title={"End call"}>
                          <div
                            className={`pg-header-icon-wrp active end-call full-screen-btn`}
                          >
                            <MenuIcon
                              size={"sm"}
                              color={"white"}
                              onClick={props.stopScreenSharing}
                              iconType={"material"}
                              icon={
                                <i className="material-icons">call_end</i>
                              }
                            />
                          </div>
                        </Tooltip>
                    
                  </div>
                </div>
              </div>
            )}
          </div>
        </>
      </div>
    </div>
    {props.isVideoMinimizeScreen && (
      <div className={`d-flex justify-content-center align-items-center`}>
        <div className={`video-call-action-wrp minimize-video`}>
          <div
            className={
              "inner-wrapper-screen-share-action d-flex  justify-content-center align-items-center"
            }
          >
            {
              <>
                <Tooltip
                  title={`${
                    props.isVideoMinimizeScreen ? "Maximize" : "Minimize"
                  }`}
                >
                  <button
                            className={`pg-header-icon-wrp white lg-round full-screen`}
                            style={{ border: "none" }}
                            onClick={() =>
                              props.onClickUnlockVideo(
                                !props.isVideoMinimizeScreen
                              )
                            }
                          >
                            {props.isVideoMinimizeScreen ? (
                              <Maximize/>
                            ) : (
                              <Minimize/>
                            )}
                          </button>

                </Tooltip>
                {navigationRestrictions.calls.video_calls.mute.active && (
                  <button
                    className={`pg-header-icon-wrp white lg-round full-screen ${isMute ? "active":""}`}
                    style={{ border: "none" }}
                    onClick={() => muteUnmuteAudio(!isMute)}
                  >
                    {isMute ? <MicOff></MicOff> : <Mic></Mic>}
                  </button>
                )}

                <Tooltip title={"End call"}>
                          <div
                            className={`pg-header-icon-wrp active end-call`}
                          >
                            <MenuIcon
                              size={"sm"}
                              color={"white"}
                              onClick={props.stopScreenSharing}
                              iconType={"material"}
                              icon={
                                <i className="material-icons">call_end</i>
                              }
                            />
                          </div>
                  </Tooltip>
              </>
            }
          </div>
        </div>
      </div>
    )}
  </div>
  )}
        
    </>
  );
};

export default ScreenShareModal;
