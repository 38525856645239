import React, { useState, useEffect } from "react";
import { Modal } from 'antd';
import { subscribeToEvents } from "../../../utils/eventEmitter";
import { Tabs, Tab } from 'react-bootstrap';
import Templates from '../../common/Templates/Templates';
import CannedMessages from '../../common/Templates/CannedMessages';
import { INTERACTION_TRANSFER_REQUEST_ACCEPTED } from "../../../redux/actions/actionTypes";
import { MenuIcon, PanelTitle } from "../../common";
import { X } from "react-feather";
import NewCannedMessageWrapper from "../../../../src/components/common/Templates/NewCannedMessageWrapper";


import './styles/template-modal.scss';
import { Scrollbars } from "react-custom-scrollbars";

const TemplateModal = props => {

    const [modalStatus, setModalStatus] = useState({ openState: false });
    const [tabStatus, setTabStatus] = useState({ tabName: 'chat' });


    useEffect(() => {

        subscribeToEvents('show_template_modal', data => {

            setModalStatus({
                openState: true
            });

        })

    }, []);

    const onClickClose = () => {
        setModalStatus({
            openState: false
        })
    };

    const onChangeTemplateTab = (_tabName) => {
        setTabStatus({
            tabName: _tabName
        })
    };


    return (
        <>
            <div
                className={`modal-inner-wrapper d-flex
                 justify-content-center align-items-center  
                 ${modalStatus.openState ? 'show-this' : 'hide-this'}`}>
                <div className={`main-modal-wrapper md-screen`}>
                    <div className={'modal-header d-flex'}>
                        <PanelTitle title={'Template'} />
                        <MenuIcon icon={<X />}
                            onClick={onClickClose}
                            key={'esc'}
                            size={'sm'} />
                    </div>

                    {/*==== tab panel start here ====*/}
                    <section className={'com-tab-panel-wrapper'}>
                        <div className={'com-tab-title-wrapper'}>
                            <div className={`com-tab-title  
                            ${tabStatus.tabName === 'chat' ? ' active' : 'default'}`}
                                onClick={() => onChangeTemplateTab('chat')}>
                                Chat Template

                            </div>
                            <div className={`com-tab-title
                                ${tabStatus.tabName === 'canned' ? '  active' : 'default'}`}
                                onClick={() => onChangeTemplateTab('canned')}>
                                Canned Message
                            </div>
                        </div>
                        <div className={`active-arrow
                         ${tabStatus.tabName === 'chat' ? 'chat-active' : 'canned-active'}`} />
                    </section>


                    {/*==== template view section ====*/}
                    <section className={'com-template-view-wrapper'}>
                        <div className={'com-temp-view-inner-wrapper'}>
                            <Scrollbars
                                autoHide
                                thumbMinSize={0}
                                style={{ height: 430 }}>
                                {tabStatus.tabName === 'chat' &&
                                    <>
                                        <Templates
                                            closeModal={onClickClose}
                                        />
                                    </>
                                }
                                {tabStatus.tabName === 'canned' &&
                                    <>

                                        <CannedMessages />
                                    </>
                                }
                            </Scrollbars>
                        </div>

                    </section>

                    <NewCannedMessageWrapper />
                </div>
            </div>


        </>
    );
};

export default TemplateModal;
