import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { MenuIcon, ProfileImage, ChatFooter, ForwardedLabel } from "../index";
import { Popover } from "antd";
import { Check, MoreVertical, X, RefreshCcw } from "react-feather";
import { fileMetaServiceUrl } from "../../../config/baseUrls";
import {
    imageTypes,
    audioTypes,
    videoTypes
} from "../../../config/json/fileTypeMap";
import * as auth from "../../../utils";
import uuid from "uuid/v1";
import axios from "axios";
import * as ChatService from "../../../service/chatService";
import * as eventEmitter from "../../../utils/eventEmitter";
import { Progress, message } from "antd";
import * as fileService from "../../../service/fileService";
import navigationRestrictions from "../../../config/navigationRestrictions";

const content = (onClickReplayMessage, onClickForwardMessage, onClickOpenGallery, onDownloadClick, contentUrl, filename, mediaFiles, msgObj, sendForwardMessages, onClickStar, isStarred) => (
    <div className={'chat-hover-menu'}>
        <div
            onClick={() => onClickReplayMessage(msgObj)}
            className={'document-row'}>Reply
        </div>
        {navigationRestrictions.chat.forwarding.active && <div className={'document-row'}
            onClick={() => onClickForwardMessage((users) => {

                sendForwardMessages(users, msgObj);

            })}>
            Forward message
        </div>}
        {navigationRestrictions.chat.starring.active && <div className={'document-row'} onClick={() => onClickStar(msgObj, !isStarred)}>{isStarred ? 'Unstar Message' : 'Star Message'}</div>}
        {mediaFiles && mediaFiles.length > 0 && <div className={'document-row'} onClick={() => onClickOpenGallery(mediaFiles)}>View</div>}
        <div className={'document-row'} onClick={() => onDownloadClick(mediaFiles)}>Download</div>
    </div>
);

let msgObj = {};
function ChatRightAudio(props) {
    const [uploading, setUploading] = useState(true);
    const [percentage, setPercentage] = useState(0);
    const [cancelTokenSource, setCancelTokenSource] = useState(true);
    const [fileUrl, setFileUrl] = useState();

    const mounted = useRef();
    useEffect(() => {
        if (!mounted.current) {
            mounted.current = true;
            //alert("UPLOADINGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGG");
            try {
                if (props.isUpload) {
                    uploadFile(props.mid);
                } else {
                    setUploading(false);
                }
            } catch (ex) {
                console.error(ex);
            }
        }
    });

    const shareMedia = (fileId, fileName, fileType, fileSize, mid) => {
        try {
            let url = `${fileMetaServiceUrl}/stream/media/${fileId}`;
            setFileUrl(url);
            if (imageTypes.indexOf(fileType) !== -1) {
                url = `${fileMetaServiceUrl}/stream/thumbnail/${fileId}/225`;
            }

            // let msg = {
            //     v: 1,
            //     mid: uniqueId,
            //     sid: uniqueId,
            //     message_type: fileType,
            //     from: {
            //         id: auth.getUserId(),
            //         name: auth.getUser()
            //     },
            //     to: {
            //         id: props.selected_profile.username,
            //         name: props.selected_profile.displayName
            //     },
            //     created_at: Date.now(),
            //     content_url: url,
            //     message_content: url,
            //     other: null
            // };

            let msg = {
                v: 1,
                mid: mid,
                sid: mid,
                channel: "WEBCHAT",
                message_type: fileType,
                from: {
                    id: auth.getUserId(),
                    name: auth.getDisplayName()
                },
                to: {
                    id: props.toUser.username,
                    name: props.toUser.username
                },
                media: [{
                    content_url: url,
                    content_size: fileSize,
                    content_title: '',
                    thumbnail_url: url,
                    mimeType: fileType
                }],
                isUploading: "DONE",
                created_at: Date.now(),
                message_content: '',
                other: null
            };

            msg.conversation_type = props.toUser.conversation_type;

            msgObj = msg;

            ChatService.sendChatMessage(
                props.toUser.conversation_type,
                fileType,
                props.toUser.username,
                msg,
                false,
                props.toUser.InteractionId
            );

            //props.chat_actions.sendNewMessageSuccess(msg);
            eventEmitter.fireEvent("scrollbar", { scrollbar_status: "down" });
            setUploading(false);
        } catch (ex) {
            console.error(`Fail To Share File ${fileId} ${fileName} ${fileType} :  ${ex.message}`);
        }
    };
    const uploadFile = async mid => {
        try {
            let fileId = uuid();
            setCancelTokenSource(axios.CancelToken.source());

            const _conversation_id = [props.toUser.conversation_type === 3 ? "" : auth.getUserId(), props.toUser.username].sort().join('_');

            fileService.uploadFile(
                props.audioBlob,
                fileId,
                _conversation_id,
                cancelTokenSource,
                (fileId, percentage) => {
                    setPercentage(percentage);
                },
                result => {
                    if (result && result.data.isSuccess) {
                        //message.success("profile successfully save...", 5);
                        shareMedia(fileId, fileId, props.audioBlob.type, props.audioBlob.size, mid);
                        props.addRefUploadFinished(props.mid);
                    } else {
                        console.error(`Fail To Upload File ${fileId}`);
                        message.error(`Fail To Upload File ${fileId}`, 5);
                    }
                }
            );
        } catch (ex) {
            console.error("RecordUpload - Fail To Upload File", ex);
            message.error(`Fail To Upload File`, 5);
        }
    };

    let tempMsgObj = props.msgObj ? props.msgObj : msgObj;

    return (
        <div key={props.mid} id={props.mid} className={`msg d-flex right-msg ${props.isImage ? '' : 'with-out-img'} `}>
            <div className="msg-img">
                <ProfileImage
                    firstLatter={props.fullName ? props.fullName.split(' ').map(fullName => fullName[0]).join('').toUpperCase() : ""}
                    textType={'chatRight'}
                    size={'sm'}
                    src={props.src} />
            </div>
            <div className={`inner-msg-bubble ${props.isImage ? null : 'with-out-name'}`}>

                <div className={'msg-text video-chat-message'}>
                    <ForwardedLabel
                        textAlign={'right'}
                        isForwardedMsg={props.isForwarded} />

                    <div className="msg-text-with-video  d-flex flex-column ">
                        {!(uploading || tempMsgObj.isUploading === 'UPLOADING') && tempMsgObj.isUploading !== 'FAILED' && <div className={`chat-audio-wrapper d-flex ${props.imgDesc ? 'with-replay' : 'default '}`}>
                            <div className={'chat-audio-inner-wrapper'}>
                                <audio controls controlsList="nodownload">
                                    <source src={props.audioSrc} type={props.audioType} />
                                </audio>
                            </div>
                        </div>}
                        {(uploading || tempMsgObj.isUploading === 'UPLOADING') && <div className={`chat-audio-wrapper`}>
                            <div className="loader9" />
                            {"Uploading audio file..."}

                        </div>
                        }
                        {tempMsgObj.isUploading === 'FAILED' && <div className={`chat-audio-wrapper`}>
                            <div className={'chat-media-upload-fail'}>{"Upload Failed !!!"}</div>


                        </div>
                        }
                        {!uploading && props.msg}
                        <Popover
                            content={content(props.onClickReplayMessage, props.onClickForwardMessage, props.onClickOpenGallery, props.onDownloadClick, props.contentUrl, props.mid, props.mediaFiles, (props.msgObj) ? props.msgObj : msgObj, props.sendForwardMessages, props.onClickStar, props.isStarred)}
                            trigger="hover">
                            {(!tempMsgObj.isUploading || tempMsgObj.isUploading === 'DONE') && <div className={'hover-more'}>
                                <MenuIcon
                                    icon={<MoreVertical />} />
                            </div>}
                        </Popover>
                    </div>

                </div>


                <ChatFooter lastSeen={props.lastSeen}
                    lastSeenStatus={props.lastSeenStatus}
                    deliveredStatus={props.isDelivered ? props.isDelivered : false}
                    sentStatus={props.is_sent_successfull}
                    textRight={true}
                    isStarred={props.isStarred} />
            </div>
            {props.is_sent_successfull === "FAIL" && !props.lastSeenStatus && !props.is_delivered && <div style={{ marginBottom: "inherit", padding: "7px" }}>
                <MenuIcon onClick={() => props.onMessageResendPressed(tempMsgObj)}

                    size={"sm-space"}
                    icon={<RefreshCcw />}
                />

            </div>}
        </div>
    );
}

ChatRightAudio.propTypes = {
    fullName: PropTypes.string.isRequired,
    msg: PropTypes.string.isRequired,
    isImage: PropTypes.bool.isRequired
};

ChatRightAudio.defaultProps = {
    src: null,
};

// function mapStateToProps(state, ownProps) {
//     let mapState = {};
//     if (
//         ownProps.direction === "send" && ownProps.toUser &&
//         ownProps.toUser.id &&
//         ownProps.mid &&
//         state.chats &&
//         state.chats[ownProps.toUser.id] &&
//         state.chats[ownProps.toUser.id].messages[ownProps.mid]
//     ) {
//         mapState.selected_profile = state.selected_profile;
//     }
//     return mapState;
// }

// function mapDispatchToProps(dispatch) {
//     return {
//         chat_actions: bindActionCreators(chatActions, dispatch)
//     };
// }

// export default connect(null, mapDispatchToProps)(ChatRightAudio);

export default ChatRightAudio;
