import React, { useState, useEffect } from "react";
import { ButtonRounded, DeviceNotSupported } from "../../common";
import { Spin } from "antd";
import { message } from "antd/lib/index";
import ScrollAnimation from "react-animate-on-scroll";

import "./styles/user-landing.scss";
import "./styles/forgotpwd-responsive.scss";

import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import AuthService from "../../../service/authService.js";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import { EyeOff, Eye } from "react-feather";
import * as auth from "../../../utils";

const authService = new AuthService();

const FirstLogin = (props) => {
  const { register, handleSubmit, errors, watch } = useForm();
  let location = useLocation();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [isLoading, setIsLoading] = useState(false);
  /* const [password, setPassword] = useState();
  const [confirmPassword, setConfirmPassword] = useState(); */
  const [username, setUsername] = useState();
  const [inputType, setInputType] = useState("password");
  const [inputNewType, setInputNewType] = useState("password");
  const [inputConType, setInputConType] = useState("password");
  const [passwordPolicy] = useState(auth.getPasswordPolicy());

  useEffect(() => {
    if (
      location &&
      location.state &&
      location.state.detail &&
      location.state.detail.userName &&
      location.state.detail.userPassword
    ) {
      setUsername(location.state.detail.userName);
    } else {
      console.error("FirstLogin", "Invalid request");
      message.error("Invalid request");
      props.history.push("/");
    }
  }, []);

  const updatePassword = async (formData) => {
    try {
      if(formData.password !== location.state.detail.userPassword){
        message.error(`Invalid Old Password`);
        return;
      }
      setIsLoading(true);
      const reply = await authService.updatePassword(
        username,
        formData.newPassword,formData.password
      );
      if (reply.isSuccess) {
        props.history.push({
          pathname: "/auto_login",
          state: { detail: { username, password: formData.newPassword,isVideo:true } },
        });
      } else {
        setIsLoading(false);
        message.error(`Fail to Update Password`);
      }
    } catch (error) {
      setIsLoading(false);
      console.error("ChangePasswordComponent", "setPassword", error);
      message.error(`Fail to Update Password`);
    }
  };

  return (
    <>
      <DeviceNotSupported />
      <section className={`main-login-wrapper sign-up`}>
        <div className={"top-left-leaf-bg"}>
          <img
            src={require("../../../assets/img/bg/login/topRightLeaf.svg")}
            alt="User Avatar"
          />
        </div>

        {/*====== login from main wrapper start here ========*/}
        <div className={"container"}>
          <div className={"login-layout-wrp  d-flex justify-content-between"}>
            {/*======== login form start here ========*/}
            <div className="sign-up-form-main-wrapper ">
              <div className="sign-up-form-inner-wrapper">
                <div>
                  <div className={`sign-up-form`}>
                    {/*<div className={'sign-up-character'}>*/}
                    {/*    <img src={require("../../../assets/img/bg/login/MainCharacter.svg")}/>*/}
                    {/*</div>*/}
                    <ScrollAnimation
                      delay="150"
                      animateIn="fadeInUp"
                      animateOut="fadeOutDown"
                    >
                      <div className={`login-header-wrapper mt-5 text-center`}>
                        <h2>Enter your password</h2>
                      </div>
                    </ScrollAnimation>

                    <div className={"sign-in-body-wrapper mt-5"}>
                      <form onSubmit={handleSubmit(updatePassword)}>
                        <div className="form-group password_input_container">
                          <label htmlFor="exampleInputEmail1">
                            Old Password <span>*</span>
                          </label>
                          <input
                             minLength={passwordPolicy.minLength}
                             maxLength={passwordPolicy.maxLength}
                            name="password"
                            ref={register({
                              required: {
                                value: true,
                                message: "Password is required",
                              },
                              minLength: {
                                value: passwordPolicy.minLength,
                                message:`Password must be at least ${passwordPolicy.minLength} characters`,
                              },
                              maxLength: {
                                value: passwordPolicy.maxLength,
                                message:`Password must be less than ${passwordPolicy.maxLength} characters`,
                              },
                            })}
                            type={inputType}
                            className="form-control form-control-lg txt-login"
                            placeholder="************"
                          />
                          {inputType === "password" ? (
                            <EyeOff
                              className="user_password_input_img"
                              onClick={() => setInputType("text")}
                            />
                          ) : (
                            <Eye
                              className="user_password_input_img"
                              onClick={() => setInputType("password")}
                            />
                          )}
                          {errors.password && (
                            <div className={"error-text"}>
                              <small style={{ color: "red" }}>
                                {errors.password.message}
                              </small>
                            </div>
                          )}
                        </div>

                        <div className="form-group password_input_container">
                          <label htmlFor="exampleInputEmail1">
                            New Password <span>*</span>
                          </label>
                          <input
                            maxLength={12}
                            name="newPassword"
                            ref={register({
                              required: {
                                value: true,
                                message: "New password is required",
                              },
                              minLength: {
                                value: 6,
                                message:
                                  "New password must be at least 6 characters",
                              },
                            })}
                            type={inputNewType}
                            className="form-control form-control-lg txt-login"
                            placeholder="************"
                          />
                          {inputNewType === "password" ? (
                            <EyeOff
                              className="user_password_input_img"
                              onClick={() => setInputNewType("text")}
                            />
                          ) : (
                            <Eye
                              className="user_password_input_img"
                              onClick={() => setInputNewType("password")}
                            />
                          )}
                          {errors.newPassword && (
                            <div className={"error-text"}>
                              <small style={{ color: "red" }}>
                                {errors.newPassword.message}
                              </small>
                            </div>
                          )}
                        </div>

                        <div className="form-group password_input_container">
                          <label htmlFor="exampleInputEmail1">
                            Confirm Password <span>*</span>
                          </label>
                          <input
                            maxLength={12}
                            name="confirmPassword"
                            ref={register({
                              required: {
                                value: true,
                                message: "Confirm password is required",
                              },
                              minLength: {
                                value: 6,
                                message:
                                  "Password must be at least 6 characters",
                              },
                              validate: {
                                match: (value) =>
                                  value === watch("newPassword") ||
                                  "The passwords do not match",
                              },
                            })}
                            type={inputConType}
                            className="form-control form-control-lg txt-login"
                            placeholder="************"
                          />
                          {inputConType === "password" ? (
                            <EyeOff
                              className="user_password_input_img"
                              onClick={() => setInputConType("text")}
                            />
                          ) : (
                            <Eye
                              className="user_password_input_img"
                              onClick={() => setInputConType("password")}
                            />
                          )}
                          {errors.confirmPassword && (
                            <div className={"error-text"}>
                              <small style={{ color: "red" }}>
                                {errors.confirmPassword.message}
                              </small>
                            </div>
                          )}
                        </div>

                        <div className={`login-button-wrapper`}>
                          <div className={`login-bnt-hide`}>
                            <div className={"mr-2"}>
                              {isLoading ? (
                                <Spin />
                              ) : (
                                <button
                                  type="submit"
                                  class="btn btn-rounded btn-lg btn-block btn-primary"
                                >
                                  SET PASSWORD
                                </button>
                              )}
                            </div>
                          </div>
                          {/*<div className={'skip'}*/}
                          {/*     onClick={onPressBack}>*/}
                          {/*    Back*/}
                          {/*</div>*/}
                        </div>
                      </form>

                      <div className={"login-footer-wrp"}>
                        {/*<div className={'version'}>*/}
                        {/*    version <span>1.0.4</span>*/}
                        {/*</div>*/}
                        <div className={"rights version-text"}>
                          © 2020 tetherfi, All rights reserved.
                        </div>
                      </div>
                    </div>
                    <div className={"login-form-bg-sm"} />
                  </div>

                  <div className={"login-form-bg-lg hide-mobile"} />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={"login-footer-bg-wrapper d-flex"}>
          <div className={"left-plant"}>
            <img
              src={require("../../../assets/img/bg/login/footerPlantLeft.svg")}
              alt="User Avatar"
            />
          </div>
          <div className={"transparent-plant"}>
            <img
              src={require("../../../assets/img/bg/login/transparentPlant.svg")}
              alt="User Avatar"
            />
          </div>
          <div className={"left-single-plant"}>
            <img
              src={require("../../../assets/img/bg/login/singlePlant.svg")}
              alt="User Avatar"
            />
          </div>

          <div className={"right-plant"}>
            <img
              src={require("../../../assets/img/bg/login/rightPlant.svg")}
              alt="User Avatar"
            />
          </div>
        </div>

        <div className={"sky-bg"}>
          <img
            src={require("../../../assets/img/bg/login/skyBg.svg")}
            alt="User Avatar"
          />
        </div>

        <div className={"left-round-wrp"} />
        <div className={"top-bg-round-wrp"} />

        <div className={"stars-wrapper"}>
          <img
            src={require("../../../assets/img/bg/login/stars.svg")}
            alt="User Avatar"
          />
        </div>
        <div className={"stars-wrapper-2"}>
          <img
            src={require("../../../assets/img/bg/login/starsOnly.svg")}
            alt="User Avatar"
          />
        </div>
      </section>
    </>
  );
};

export default FirstLogin;
