import React from "react";

import { __APP_CUST_DYNAMIC_INFO_COUNT__ } from "../../../config/baseUrls";
import SocialMediaIcon from "../SocialMediaIcon/SocialMediaIcon";

const UserProfileInfo = ({
    fullName,
    lstUpdate,
    src,
    conversation_type,
    isMeeting,
    channel
}) => {

    const intials = fullName ? fullName.split(' ').map(fullName => fullName[0]).join('').toUpperCase() : "";

    return (
        <div class="d-flex">
            <div class="p-2">
                <div className={"ch-img-wrp d-flex"}>
                    {src ? (
                        <img alt="C" src={src} />
                    ) : conversation_type === 3 ? (
                        <img
                            src={require("../../../assets/img/defaultGroup.svg")}
                            alt="Group Avatar"
                        />
                    ) : (isMeeting ? <img
                        src={require("../../../assets/schedule/images/meet-1.png")}
                        alt="Group Avatar"
                    /> : (
                        intials ? <div className="n-profileImage m-text d-flex justify-content-center align-items-center"> {intials} </div> :
                            <img src={require("../../../assets/img/defaultUser.svg")} alt="User Avatar" />
                    ))}
                </div>
            </div>
            <div class="p-2"><div className={"full-name text-center"} style={{ paddingTop: "5px" }}>{fullName}</div></div>
            {channel && !isMeeting && <div class="ml-auto p-2" style={{ marginTop: "5px" }}><SocialMediaIcon size="fa-2x" channel={channel} /></div>}
        </div>
    )


    /* return (
        <div className={'d-flex flex-row float-left'}>
            <div className={"ch-img-wrp d-flex"}>
                {src ? (
                    <img alt="C" src={src} />
                ) : conversation_type === 3 ? (
                    <img
                        src={require("../../../assets/img/defaultGroup.svg")}
                        alt="Group Avatar"
                    />
                ) : (isMeeting ? <img
                    src={require("../../../assets/schedule/images/meet-1.png")}
                    alt="Group Avatar"
                /> : (
                    intials ? <div className="n-profileImage m-text d-flex justify-content-center align-items-center"> {intials} </div> :
                        <img src={require("../../../assets/img/defaultUser.svg")} alt="User Avatar" />
                ))}
            </div>

            <div className={"d-flex flex-row align-items-center justify-content-center ml-3"}>
                <div className={"full-name text-center"}>{fullName}</div>
                <div className="fr-0">ffdsfdsf</div>
            </div>
        </div>
    ); */
};

// CallAlert.propTypes = {
//     status: PropTypes.string.isRequired,
// };

UserProfileInfo.defaultProps = {};

export default UserProfileInfo;
