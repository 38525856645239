import React, { useState, useEffect } from "react";
import { Modal } from 'antd';
import { subscribeToEvents } from "../../../utils/eventEmitter";
import SignatureCanvas from 'react-signature-canvas';
import uuid from "uuid/v1";
import axios from "axios";
import * as auth from "../../../utils";
import * as fileService from "../../../service/fileService";
import { message } from "antd/lib/index";
import * as eventEmitter from "../../../utils/eventEmitter";

let acceptOrRejectObject = null;

const SignaturePadModal = props => {

    const [modalStatus, setModalStatus] = useState({ state: false, toUser: "", showOk: false });
    let sigPad = {};

    useEffect(() => {

        subscribeToEvents('show_signature_modal', data => {
            acceptOrRejectObject = data.acceptOrRejectObject;
            setModalStatus((prevState) => { return { ...prevState, state: true, toUser: data.toUser } });

        })

    }, []);

    let sendMessage = (uploadData, notificationData) => {

        eventEmitter.fireEvent("send_snapshot", { uploadData, notificationData, snapshotType: "SIGNATURE_SNAPSHOT" });
    }


    return (
        <>
            {modalStatus.state && <Modal
                title="Signature Pad"
                closable={false}
                visible={modalStatus.state}
                maskClosable={false}
                okText="Upload"
                cancelText="Clear"
                okButtonProps={{ disabled: !modalStatus.showOk }}
                onOk={() => {
                    let canvas = sigPad.getCanvas();
                    canvas.toBlob((blob => {
                        let file = new File([blob], "Signature.png", {
                            type: "image/png",
                        });
                        let fileId = uuid();
                        let fileObj = {
                            file_id: fileId,
                            file: file,
                            name: file.name,
                            size: file.size,
                            type: file.type,
                            caption: ""
                        };
                        let cancelTokenSource = axios.CancelToken.source();
                        let conversation_id = [auth.getUserId(), modalStatus.toUser].sort().join('_')

                        fileService.uploadFilePromise(file, fileId, conversation_id, cancelTokenSource, null, fileObj.type).then(uploadResult => {
                            if (uploadResult.data.isSuccess && uploadResult.data.result) {
                                sendMessage(uploadResult.data.result, acceptOrRejectObject);
                                acceptOrRejectObject.accept(uploadResult.data.result.streamURL);
                            } else {
                                acceptOrRejectObject.reject();
                                message.error('File upload failed');
                            }
                            setModalStatus({ state: false, toUser: "", showOk: false });
                        }).catch(ex => {
                            setModalStatus({ state: false, toUser: "", showOk: false });
                        })
                    }));


                }}
                confirmLoading={false}
                onCancel={() => {

                    sigPad.clear();
                    setModalStatus((prevState) => { return { ...prevState, showOk: false } });
                }}
            >
                <p><SignatureCanvas penColor='green'
                    canvasProps={{ width: 500, height: 200, className: 'sigCanvas' }} ref={(ref) => { sigPad = ref }} onEnd={() => {
                        setModalStatus((prevState) => { return { ...prevState, showOk: true } });

                    }} />
                </p>
            </Modal>}
        </>
    );
};

export default React.memo(SignaturePadModal);