import { Configuration } from "./Configuration";
import { Meeting } from "./Meeting";
import { Participant } from "./Participant";
import { RecurrencePattern, RecurrenceRange } from "./Recurrence";
import { Notification } from "./Notification";
import { MeetingStatus } from "./MeetingStatus";
import { meetingUrl } from "../../config/baseUrls";

class MeetingSDK {

    /**
    * api-method
    * Method to add a new meeting
    * @param {Object<Meeting>} meeting meeting details (Cannot be null)
    * meeting object should consist of below parameters
    *   @param {String} creator creators name
    *   @param {String} title meeting title
    *   @param {String} description meeting description
    *   @param {String} location meeting location
    *   @param {String} startDate meeting start date - should be in following format '2021-02-02T09:02:31.678Z'
    *   @param {String} startTime meeting start time - should be in following format in 24 hr style '13:30'
    *   @param {Integer} duration meeting duration in minutes
    *   @param {String} timeZone timezone of the meeting creator as a time zone abbrieviation eg:- "IST"
    *   @param {String} OffSet timezone off set (eg: +05:30)
    *   @param {Boolean} isAllDay meeting is a all day meeting or not
    *   @param {Boolean} isRecurrence meeting is a recurring meeting or not
    *   @param {String} url meeting url
    *   @param {Boolean} allowGuest meeting allowed for guests or not
    *   @param {String} calendar meeting calendar type - allowed values are "GOOGLE" or "OUTLOOK"
    *   @param {String} urlType where the meeting takes place - eg: "zoom" or "tmeet"
    *   @param {String} passCode meeting passcode
    * 
    *   @param {Array<Participant>} participants Array of meeting participant objects
    *   participant object should consist of below parameters
    *       @param {String} participant participant name / id
    *       @param {String} type type - can be either "Optional" or "Required"
    *       @param {String} participantType participant type - can be either of the following "Supervisor", "Agent" or "Vendor"
    *       @param {String} email email of the participant
    * 
    *   @param {Object<RecurrencePattern>} recurrencePattern Object defining recurrence pattern
    *   recurrencePattern object should consist of following parameters
    *       @param {Integer} type recurrence type - can be any of the following values (0 = "Daily", 1 = "Monthly", 2 = "Yearly")
    *       @param {Integer} every how often recurrence type should happen - should be a number eg: 1
    *       @param {String} month comma separated string list of month numbers the recurring should happen - eg: "1,2,3,4,5,6,7,8,9,10,11,12"
    *       @param {String} weekDays comma separated string list of days of week names the recurring should happen - eg: "monday,tuesday"
    *       @param {String} date comma separated string list of day numbers of the month the recurring should happen - eg: "1,2,15,30,31"
    * 
    *   @param {Object<RecurrenceRange>} recurrenceRange Object defining recurrence range
    *   recurrence range object should consist of following parameters
    *       @param {Integer} type recurrence end type - can be any of the following values (0 = "EndBy", 1 = "EndAfter", 2 = "NoEnd")
    *       @param {String} value date the recurrence should end - eg:- "2025-02-04T06:05:36.458Z"
    * 
    *   @param {Array<Configuration>} configurations Array of configuration keys and values the client ui can define additionally
    *   configuration object should consist of following parameters
    *       @param {String} feature feature name to identify uniquely - eg: HostVideoOn
    *       @param {String} featureValue feature value as a string - eg:- "true"
    *       @param {Boolean} enable whether the feature should be enabled or not
    * 
    *   @param {Object<Notification>} notifyBefore Object defining notification message configuration to users before meeting start
    *   notifyBefore object should consist of following parameters
    *       @param {Integer} timeInMinutes time in minutes before the meeting the user should be notified
    *       @param {String} otherData Message to the user - eg:- "This is about Enhancement"
    *       @param {String} otherDataType Reminder type
    * 
    * @returns {Promise} Promise
    */
    addNewMeeting(meeting) {

        console.log(
            `%c [MeetingsSDK - addNewMeeting] - API INVOKED - Data : ${JSON.stringify(meeting)}`,
            "background: #990033; color: #ffffff; font-size:12px"
        );

        return new Promise((resolve, reject) => {

            meeting.participants.map(user => new Participant(user));
            meeting.recurrenceRange = meeting.recurrenceRange ? new RecurrenceRange(meeting.recurrenceRange) : null;
            meeting.recurrencePattern = meeting.recurrencePattern ? new RecurrencePattern(meeting.recurrencePattern) : null;
            meeting.configurations.map(conf => new Configuration(conf));
            meeting.notifyBefore = meeting.notifyBefore ? new Notification(meeting.notifyBefore) : null;
            meeting.status = 'CREATED';
            meeting.isActive = true;
            meeting.createdBy = 'TACUser';
            meeting.createdOn = new Date().toISOString();

            let url = `${meetingUrl}/api/v1/meeting`;

            fetch(url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(new Meeting(meeting))
            }).then(res => {
                return res.json();

            }).then(data => {
                console.log(
                    `%c [MeetingsSDK - addNewMeeting] - API RETURNED - Data : ${JSON.stringify(data)}`,
                    "background: #cc9900; color: #ffffff; font-size:12px"
                );
                resolve(data);
            }).catch(ex => {
                console.error('[MeetingsSDK - addNewMeeting] - ERROR OCCURRED', ex);
                reject(ex);

            });

        });

    }

    /**
    * api-method
    * Method to update a meeting
    * @param {Object<Meeting>} meeting meeting details (Cannot be null)
    * meeting object should consist of all the Meeting
    *   @param {Integer} id meeting id to update
    *   @param {String} creator creators name
    *   @param {String} title meeting title
    *   @param {String} description meeting description
    *   @param {String} location meeting location
    *   @param {String} startDate meeting start date - should be in following format '2021-02-02T09:02:31.678Z'
    *   @param {String} startTime meeting start time - should be in following format in 24 hr style '13:30'
    *   @param {Integer} duration meeting duration in minutes
    *   @param {String} timeZone timezone of the meeting creator as a time zone abbrieviation eg:- "IST"
    *   @param {String} OffSet timezone off set (eg: +05:30)
    *   @param {Boolean} isAllDay meeting is a all day meeting or not
    *   @param {Boolean} isRecurrence meeting is a recurring meeting or not
    *   @param {String} url meeting url
    *   @param {Boolean} allowGuest meeting allowed for guests or not
    *   @param {String} calendar meeting calendar type - allowed values are "GOOGLE" or "OUTLOOK"
    *   @param {String} urlType where the meeting takes place - eg: "zoom" or "tmeet"
    *   @param {String} passCode meeting passcode
    *   @param {Boolean} isActive is meeting active or not
    *   @param {String} status meeting status
    *   @param {String} createdBy user id of the user who created the meeting
    *   @param {String} createdOn meeting created date time in ISO format
    * 
    *   @param {Array<Participant>} participants Array of meeting participant objects
    *   participant object should consist of below parameters
    *       @param {String} participant participant name / id
    *       @param {String} type type - can be either "Optional" or "Required"
    *       @param {String} participantType participant type - can be either of the following "Supervisor", "Agent" or "Vendor"
    *       @param {String} email email of the participant
    * 
    *   @param {Object<RecurrencePattern>} recurrencePattern Object defining recurrence pattern
    *   recurrencePattern object should consist of following parameters
    *       @param {Integer} type recurrence type - can be any of the following values (0 = "Daily", 1 = "Monthly", 2 = "Yearly")
    *       @param {Integer} every how often recurrence type should happen - should be a number eg: 1
    *       @param {String} month comma separated string list of month numbers the recurring should happen - eg: "1,2,3,4,5,6,7,8,9,10,11,12"
    *       @param {String} weekDays comma separated string list of days of week names the recurring should happen - eg: "monday,tuesday"
    *       @param {String} date comma separated string list of day numbers of the month the recurring should happen - eg: "1,2,15,30,31"
    * 
    *   @param {Object<RecurrenceRange>} recurrenceRange Object defining recurrence range
    *   recurrence range object should consist of following parameters
    *       @param {Integer} type recurrence end type - can be any of the following values (0 = "EndBy", 1 = "EndAfter", 2 = "NoEnd")
    *       @param {String} value date the recurrence should end - eg:- "2025-02-04T06:05:36.458Z"
    * 
    *   @param {Array<Configuration>} configurations Array of configuration keys and values the client ui can define additionally
    *   configuration object should consist of following parameters
    *       @param {String} feature feature name to identify uniquely - eg: HostVideoOn
    *       @param {String} featureValue feature value as a string - eg:- "true"
    *       @param {Boolean} enable whether the feature should be enabled or not
    * 
    *   @param {Object<Notification>} notifyBefore Object defining notification message configuration to users before meeting start
    *   notifyBefore object should consist of following parameters
    *       @param {Integer} timeInMinutes time in minutes before the meeting the user should be notified
    *       @param {String} otherData Message to the user - eg:- "This is about Enhancement"
    *       @param {String} otherDataType Reminder type
    * 
    * @returns {Promise} Promise
    */
    updateMeeting(meeting) {

        console.log(
            `%c [MeetingsSDK - updateMeeting] - API INVOKED - Data : ${JSON.stringify(meeting)}`,
            "background: #990033; color: #ffffff; font-size:12px"
        );

        return new Promise((resolve, reject) => {

            meeting.participants.map(user => new Participant(user));
            meeting.recurrenceRange = meeting.recurrenceRange ? new RecurrenceRange(meeting.recurrenceRange) : null;
            meeting.recurrencePattern = meeting.recurrencePattern ? new RecurrencePattern(meeting.recurrencePattern) : null;
            meeting.configurations.map(conf => new Configuration(conf));
            meeting.notifyBefore = meeting.notifyBefore ? new Notification(meeting.notifyBefore) : null;
            meeting.updatedBy = 'TACUser';
            meeting.updatedOn = new Date().toISOString();

            let url = `${meetingUrl}/api/v1/meeting`;

            fetch(url, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(new Meeting(meeting))
            }).then(res => {
                return res.json();

            }).then(data => {
                console.log(
                    `%c [MeetingsSDK - updateMeeting] - API RETURNED - Data : ${JSON.stringify(data)}`,
                    "background: #cc9900; color: #ffffff; font-size:12px"
                );
                resolve(data);
            }).catch(ex => {
                console.error('[MeetingsSDK - updateMeeting] - ERROR OCCURRED', ex);
                reject(ex);

            });

        });

    }

    /**
    * api-method
    * Method to deactivate a meeting
    * @param {Integer} meetingId id of the meeting to be deactivated (Cannot be null)
    * @param {String} initiator meeting initiator id of the meeting to be deactivated (Cannot be null)
    * 
    * @returns {Promise} Promise
    */
    deactivateMeeting(meetingId, initiator) {

        return new Promise((resolve, reject) => {

            let url = `${meetingUrl}/api/v1/meeting/${meetingId}/${initiator}/inactive`;

            fetch(url, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json"
                }
            }).then(res => {
                return res.json();

            }).then(data => {
                resolve(data);
            }).catch(ex => {
                reject(ex);

            });

        });

    }


    /**
    * api-method
    * Method to delete a meeting
    * @param {Integer} meetingId id of the meeting to be deleted (Cannot be null)
    * 
    * @returns {Promise} Promise
    */
    deleteMeeting(meetingId) {

        return new Promise((resolve, reject) => {

            let url = `${meetingUrl}/api/v1/meeting/${meetingId}`;

            fetch(url, {
                method: "DELETE",
                headers: {
                    "Content-Type": "application/json"
                }
            }).then(res => {
                return res.json();

            }).then(data => {
                resolve(data);
            }).catch(ex => {
                reject(ex);

            });

        });

    }

    /**
    * api-method
    * Method to get meetings
    * 
    * @returns {Promise} Promise that resolves to an array of Meetings
    */
    getMeetings() {

        return new Promise((resolve, reject) => {

            let url = `${meetingUrl}/api/v1/meetings`;

            fetch(url, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json"
                }
            }).then(res => {
                return res.json();

            }).then(data => {

                data.scheduleMeetings = data.scheduleMeetings.map((meeting) => {
                    meeting.participants = meeting.participants.map(user => new Participant(user));
                    meeting.recurrencePattern = meeting.recurrencePattern ? new RecurrencePattern(meeting.recurrencePattern) : null;
                    meeting.recurrenceRange = meeting.recurrenceRanges ? new RecurrenceRange(meeting.recurrenceRanges) : null;
                    meeting.configurations = meeting.configurations.map(conf => new Configuration(conf));
                    meeting.notifyBefore = meeting.notifyBefore ? new Notification(meeting.notifyBefore) : null;
                    return new Meeting(meeting);
                });
                resolve(data.scheduleMeetings);
            }).catch(ex => {
                reject(ex);

            });

        });

    }

    /**
    * api-method
    * Method to get a meeting by id
    * @param {Integer} id id of the meeting to get info of (Cannot be null)
    *  
    * @returns {Promise} Promise that resolves to a Meeting object
    */
    getMeetingById(id) {

        return new Promise((resolve, reject) => {

            let url = `${meetingUrl}/api/v1/meeting/${id}`;

            fetch(url, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json"
                }
            }).then(res => {
                return res.json();

            }).then(data => {

                data.scheduleMeeting.participants = data.scheduleMeeting.participants.map(user => new Participant(user));
                data.scheduleMeeting.recurrencePattern = data.scheduleMeeting.recurrencePattern ? new RecurrencePattern(data.scheduleMeeting.recurrencePattern) : null;
                data.scheduleMeeting.recurrenceRange = data.scheduleMeeting.recurrenceRanges ? new RecurrenceRange(data.scheduleMeeting.recurrenceRanges) : null;
                data.scheduleMeeting.configurations = data.scheduleMeeting.configurations.map(conf => new Configuration(conf));
                data.scheduleMeeting.notifyBefore = data.scheduleMeeting.notifyBefore ? new Notification(data.scheduleMeeting.notifyBefore) : null;
                data.scheduleMeeting = new Meeting(data.scheduleMeeting);

                resolve(data);
            }).catch(ex => {
                reject(ex);

            });

        });

    }

    /**
    * api-method
    * Method to get a list of meetings by meeting status
    * @param {String} status status of a meeting to filter with (Cannot be null)
    *  
    * @returns {Promise} Promise that resolves to an array of Meetings
    */
    getMeetingsByStatus(status) {

        return new Promise((resolve, reject) => {

            let url = `${meetingUrl}/api/v1/activemeetings`;

            fetch(url, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json"
                }
            }).then(res => {
                return res.json();

            }).then(data => {

                data.scheduleMeetings = data.scheduleMeetings.map((meeting) => {
                    meeting.participants = meeting.participants.map(user => new Participant(user));
                    meeting.recurrencePattern = meeting.recurrencePattern ? new RecurrencePattern(meeting.recurrencePattern) : null;
                    meeting.recurrenceRange = meeting.recurrenceRanges ? new RecurrenceRange(meeting.recurrenceRanges) : null;
                    meeting.configurations = meeting.configurations.map(conf => new Configuration(conf));
                    meeting.notifyBefore = meeting.notifyBefore ? new Notification(meeting.notifyBefore) : null;
                    return new Meeting(meeting);
                });
                resolve(data);
            }).catch(ex => {
                reject(ex);

            });

        });

    }

    /**
    * api-method
    * Method to get a list of meetings by meeting status and creator
    * @param {String} creator creator of a meeting to filter with (Cannot be null)
    * @param {String} status status of a meeting to filter with (Cannot be null)
    *  
    * @returns {Promise} Promise that resolves to an array of Meetings
    */
    getMeetingsByCreatorAndStatus(creator, status) {

        return new Promise((resolve, reject) => {

            let url = `${meetingUrl}/api/v1/meeting/${creator}/${status}`;

            fetch(url, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json"
                }
            }).then(res => {
                return res.json();

            }).then(data => {

                data.scheduleMeetings = data.scheduleMeetings.map((meeting) => {
                    meeting.participants = meeting.participants.map(user => new Participant(user));
                    meeting.recurrencePattern = meeting.recurrencePattern ? new RecurrencePattern(meeting.recurrencePattern) : null;
                    meeting.recurrenceRange = meeting.recurrenceRanges ? new RecurrenceRange(meeting.recurrenceRanges) : null;
                    meeting.configurations = meeting.configurations.map(conf => new Configuration(conf));
                    meeting.notifyBefore = meeting.notifyBefore ? new Notification(meeting.notifyBefore) : null;
                    return new Meeting(meeting);
                });
                resolve(data);
            }).catch(ex => {
                reject(ex);

            });

        });

    }

    /**
    * api-method
    * Method to get a list of meetings by participant
    * @param {String} participant id or name of the participant (Cannot be null)
    *  
    * @returns {Promise} Promise that resolves to an array of Meetings
    */
    getMeetingsByParticipant(participant) {

        return new Promise((resolve, reject) => {

            let url = `${meetingUrl}/api/v1/meetings/participant/${participant}`;

            fetch(url, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json"
                }
            }).then(res => {
                return res.json();

            }).then(data => {

                data.scheduleMeetings = data.scheduleMeetings.map((meeting) => {
                    meeting.participants = meeting.participants.map(user => new Participant(user));
                    meeting.recurrencePattern = meeting.recurrencePattern ? new RecurrencePattern(meeting.recurrencePattern) : null;
                    meeting.recurrenceRange = meeting.recurrenceRanges ? new RecurrenceRange(meeting.recurrenceRanges) : null;
                    meeting.configurations = meeting.configurations.map(conf => new Configuration(conf));
                    meeting.notifyBefore = meeting.notifyBefore ? new Notification(meeting.notifyBefore) : null;
                    return new Meeting(meeting);
                });
                resolve(data);
            }).catch(ex => {
                reject(ex);

            });

        });

    }

    /**
    * api-method
    * Method to get a list of meetings by participant and date
    * @param {String} participant id or name of the participant (Cannot be null)
    * @param {String} fromDate from date to query the meetings - Should be in the format (YYYY-MM-DD) and in UTC converted date (Cannot be null)
    * @param {String} toDate to date to query the meetings - Should be in the format (YYYY-MM-DD) and in UTC converted date (Cannot be null)
    * @param {String} fromTime from time to query the meetings - Should be in the format (HH:mm) and in UTC converted time (Cannot be null)
    * @param {String} toTime to time to query the meetings - Should be in the format (HH:mm) and in UTC converted time (Cannot be null)
    *  
    * @returns {Promise} Promise that resolves to an array of Meetings
    */
    getMeetingsByDateAndParticipant(participant, fromDate, toDate, fromTime, toTime) {
        return new Promise((resolve, reject) => {

            let url = `${meetingUrl}/api/v1/meetings/participant/${participant}/${fromDate}/${toDate}/${fromTime}/${toTime}`;

            fetch(url, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json"
                }
            }).then(res => {
                return res.json();

            }).then(data => {

                data.scheduleMeetings = data.scheduleMeetings.map((meeting) => {
                    meeting.participants = meeting.participants.map(user => new Participant(user));
                    meeting.recurrencePattern = meeting.recurrencePattern ? new RecurrencePattern(meeting.recurrencePattern) : null;
                    meeting.recurrenceRange = meeting.recurrenceRanges ? new RecurrenceRange(meeting.recurrenceRanges) : null;
                    meeting.configurations = meeting.configurations.map(conf => new Configuration(conf));
                    meeting.notifyBefore = meeting.notifyBefore ? new Notification(meeting.notifyBefore) : null;
                    return new Meeting(meeting);
                });
                resolve(data);
            }).catch(ex => {
                reject(ex);

            });

        });

    }

    /**
    * api-method
    * Method to get a list of meetings by participant and date
    * @param {Array[String]} meetingIds list of meeting id to get statuses of (Cannot be null)
    *  
    * @returns {Promise} Promise that resolves to an object of meeting statuses as key value pairs
    */
    getMeetingsStatus(meetingIds) {

        return new Promise((resolve, reject) => {

            let url = `${meetingUrl}/api/v1/meetings/status/${meetingIds.join(',')}`;

            fetch(url, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json"
                }
            }).then(res => {
                return res.json();

            }).then(data => {

                if (data.statusCode === 1) {
                    data.meetingStatuses = data.meetingStatuses.map(statusInfo => {
                        return new MeetingStatus(statusInfo);
                    });
                    resolve(data);
                } else {
                    resolve({});
                }
            }).catch(ex => {
                reject(ex);

            });

        });

    }

    /**
    * api-method
    * Method to get a list of meetings by participant and date
    * @param {Integer} meetingId meeting id of the meeting to be started (Cannot be null)
    * @param {String} initiatorId user id of the person initiation meeting start (Cannot be null)
    *  
    * @returns {Promise} Promise that resolves to an object of start status
    */
    startMeeting(meetingId, initiatorId) {

        console.log(
            `%c [MeetingsSDK - startMeeting] - API INVOKED - Data : ${JSON.stringify({ meetingId, initiatorId })}`,
            "background: #990033; color: #ffffff; font-size:12px"
        );

        return new Promise((resolve, reject) => {

            let url = `${meetingUrl}/api/v1/meeting/start/${meetingId}/${initiatorId}`;

            fetch(url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                }
            }).then(res => {
                return res.json();

            }).then(data => {

                console.log(
                    `%c [MeetingsSDK - startMeeting] - API RETURNED - Data : ${JSON.stringify(data)}`,
                    "background: #cc9900; color: #ffffff; font-size:12px"
                );

                resolve(data);
            }).catch(ex => {
                reject(ex);

            });

        });

    }

    /**
    * api-method
    * Method to get a list of meetings by participant and date
    * @param {Integer} meetingId meeting id of the meeting to be started (Cannot be null)
    * @param {String} participantId user id of the person joining meeting (Cannot be null)
    *  
    * @returns {Promise} Promise that resolves to an object of join status
    */
    joinMeetingAsAnonymous(meetingId, participantId) {

        console.log(
            `%c [MeetingsSDK - joinMeetingAsAnonymous] - API INVOKED - Data : ${JSON.stringify({ meetingId, participantId })}`,
            "background: #990033; color: #ffffff; font-size:12px"
        );

        return new Promise((resolve, reject) => {

            let url = `${meetingUrl}/api/v1/meeting/anonymous/join/${meetingId}/${participantId}`;

            fetch(url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                }
            }).then(res => {
                return res.json();

            }).then(data => {
                console.log(
                    `%c [MeetingsSDK - joinMeetingAsAnonymous] - API RETURNED - Data : ${JSON.stringify(data)}`,
                    "background: #cc9900; color: #ffffff; font-size:12px"
                );

                resolve(data);
            }).catch(ex => {
                reject(ex);

            });

        });

    }

    /**
    * api-method
    * Method to get a list of meetings by participant and date
    * @param {Integer} meetingId meeting id of the meeting to be started (Cannot be null)
    * @param {String} participantId user id of the person joining meeting (Cannot be null)
    *  
    * @returns {Promise} Promise that resolves to an object of join status
    */
    joinMeeting(meetingId, participantId) {

        console.log(
            `%c [MeetingsSDK - joinMeeting] - API INVOKED - Data : ${JSON.stringify({ meetingId, participantId })}`,
            "background: #990033; color: #ffffff; font-size:12px"
        );

        return new Promise((resolve, reject) => {

            let url = `${meetingUrl}/api/v1/meeting/join/${meetingId}/${participantId}`;

            fetch(url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                }
            }).then(res => {
                return res.json();

            }).then(data => {
                console.log(
                    `%c [MeetingsSDK - joinMeeting] - API RETURNED - Data : ${JSON.stringify(data)}`,
                    "background: #cc9900; color: #ffffff; font-size:12px"
                );

                resolve(data);
            }).catch(ex => {
                reject(ex);

            });

        });

    }

    /**
    * api-method
    * Method to get a list of meetings by participant and date
    * @param {Integer} meetingId meeting id of the meeting to be started (Cannot be null)
    * @param {String} participantId user id of the participant that the state needs to be set (Cannot be null)
    * @param {String} status status to update (Accept, Deny, MayBe)
    *  
    * @returns {Promise} Promise that resolves to an object of status update result
    */
    setParticipantStatus(meetingId, participantId, status) {

        console.log(
            `%c [MeetingsSDK - setParticipantStatus] - API INVOKED - Data : ${JSON.stringify({ meetingId, participantId, status })}`,
            "background: #990033; color: #ffffff; font-size:12px"
        );

        return new Promise((resolve, reject) => {

            let url = `${meetingUrl}/api/v1/meeting/participant/status/${meetingId}/${status}/${participantId}`;

            fetch(url, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json"
                }
            }).then(res => {
                return res.json();

            }).then(data => {

                console.log(
                    `%c [MeetingsSDK - setParticipantStatus] - API RETURNED - Data : ${JSON.stringify(data)}`,
                    "background: #cc9900; color: #ffffff; font-size:12px"
                );

                resolve(data);
            }).catch(ex => {
                reject(ex);

            });

        });

    }



}



export { MeetingSDK };