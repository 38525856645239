import React, { useEffect, useState } from "react";
import { Scrollbars } from 'react-custom-scrollbars';
import ChatUserWrapper from '../../common/ChatUser/ChatUserWrapper';
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { subscribeToEvents } from "../../../utils/eventEmitter";
import * as auth from "../../../utils";
import * as types from "../../../redux/actions/actionTypes";
import { createSelector } from "reselect";
import ChatPreLoader from "../ChatPreLoader/ChatPreLoader";
import { __APP_INTERACTION_MODE__ } from "../../../config/baseUrls";

const getChats = (chats) => chats;

export const getFilteredChats = createSelector(
    [getChats],
    (chats) => {
        return Object.keys(chats).reduce((acc, key) => {
            acc[key] = {
                created_at: chats[key].created_at
            }
            return acc;
        }, {})
    }
)

let showFiltered = false;
let selectionCriteriaContacts = {
    search_text: "",
    filter_value: 1
};

const MyContacts = props => {

    const [searchText, setSearchText] = useState("");
    const [filterValue, setFilterValue] = useState(1);

    const handleSearching = (sourceData) => {

        if (searchText) {
            //filter
            try {

                var regex = RegExp(searchText, "i");
                let filtered = Object.keys(sourceData).reduce((acc, key) => {
                    let name = "";

                    if (sourceData[key].conversation_type === 0) {
                        //USER
                        name = `${sourceData[key].firstName} ${sourceData[key].lastName}`;
                    } else if (sourceData[key].conversation_type === 3) {
                        //GROUP
                        name = sourceData[key].Name;
                    } else if (sourceData[key].conversation_type === 4) {
                        //Interaction
                        name = sourceData[key].InteractionId;
                    }
                    if (regex.test(name)) {
                        acc = { ...acc, ...{ [key]: sourceData[key] } };
                    }
                    return acc;
                }, {});

                return filtered;

            } catch (ex) {
                console.error(ex);
                return {};
            }

        } else {
            return sourceData;
        }

    }

    const handleFiltering = (allContacts) => {

        switch (filterValue) {
            case 1: {

                return handleSearching(allContacts);
            }
            case 2: {
                let filtered = Object.keys(allContacts).reduce((acc, key) => {

                    if (allContacts[key].conversation_type === 3) {
                        acc = { ...acc, ...{ [key]: allContacts[key] } };
                    }
                    return acc;
                }, {});

                return handleSearching(filtered, selectionCriteriaContacts.search_text, true);
            }
            case 3: {

                let filtered = Object.keys(allContacts).reduce((acc, key) => {

                    if (allContacts[key].conversation_type === 0 && allContacts[key].userType && allContacts[key].userType.toUpperCase() === 'CUSTOMER') {
                        acc = { ...acc, ...{ [key]: allContacts[key] } };
                    }
                    return acc;
                }, {});

                return handleSearching(filtered, selectionCriteriaContacts.search_text, true);
            }
            case 4: {
                let filtered = Object.keys(allContacts).reduce((acc, key) => {

                    if (allContacts[key].conversation_type === 0 && (allContacts[key].userType && (allContacts[key].userType.toUpperCase() === 'AGENT' || allContacts[key].userType.toUpperCase() === 'ADMIN'))) {
                        acc = { ...acc, ...{ [key]: allContacts[key] } };
                    }
                    return acc;
                }, {});

                return handleSearching(filtered, selectionCriteriaContacts.search_text, true);
            }
            default: {
                return handleSearching(allContacts);
            }
        }

    }

    useEffect(() => {

        subscribeToEvents("FILTER_MY_CONTACTS", (filterValue) => {
            setFilterValue(filterValue);
        })


        subscribeToEvents("SEARCH_MY_CONTACTS", (searchText) => {
            setSearchText(searchText);
        });
    }, []);


    let allContacts = {};

    if (__APP_INTERACTION_MODE__ === "USER" && props.team) {
        //CODE_CHANGE
        /*allContacts = { ...props.team }; */
        allContacts = { ...props.team, ...props.interactions };
        //CODE_CHANGE_END
    }

    if (__APP_INTERACTION_MODE__ === "INTERACTION" && props.interactions) {
        allContacts = { ...props.interactions };
    }

    if (props.chat_group) {
        allContacts = { ...allContacts, ...props.chat_group };
    }

    let sortedContactsList = handleFiltering(allContacts);

    /* if (props.presence_list) {

        let keyArray = Object.keys(sortedContactsList).sort(function (a, b) {

            let date_a = new Date(null);
            let date_b = new Date(null);

            if (props.presence_list.data[a] && props.presence_list.data[a].presenceAt) {
                date_a = new Date(props.presence_list.data[a].presenceAt);
            }

            if (props.presence_list.data[b] && props.presence_list.data[b].presenceAt) {
                date_b = new Date(props.presence_list.data[b].presenceAt);
            }

            if (date_a < date_b) {
                return 1;
            }
            if (date_a > date_b) {
                return -1;
            }

            return 0;
        });

        sortedContactsList = keyArray.reduce((acc, key) => {
            acc[key] = sortedContactsList[key];
            return acc;
        }, {});

    } */

    if (props.chats && sortedContactsList) {

        let keyArray = Object.keys(sortedContactsList).sort(function (a, b) {

            let timestamp_a = props.chats[a] && props.chats[a].created_at ? props.chats[a].created_at : 0;
            let timestamp_b = props.chats[b] && props.chats[b].created_at ? props.chats[b].created_at : 0;

            if (timestamp_a < timestamp_b) {
                return 1;
            }
            if (timestamp_a > timestamp_b) {
                return -1;
            }

            return 0;
        });

        sortedContactsList = keyArray.reduce((acc, key) => {
            acc[key] = sortedContactsList[key];
            return acc;
        }, {});

    }


    //TODO: sort all contacts based on chat data

    let contactListView = Object.keys(sortedContactsList).map((key, index) => {

        let userId = auth.getUserId();

        if (key !== userId) {
            let fullName = "";
            let customerInfo = null;

            if (sortedContactsList[key].conversation_type === 0) {
                //USER
                fullName = sortedContactsList[key].firstName ? `${sortedContactsList[key].firstName} ${sortedContactsList[key].lastName}` : key;
            } else if (sortedContactsList[key].conversation_type === 3) {
                //GROUP
                fullName = sortedContactsList[key].Name;
            } else if (sortedContactsList[key].conversation_type === 4) {
                if (sortedContactsList[key].IsMeeting) {
                    //MEETING

                    fullName = sortedContactsList[key].MeetingInfo ? sortedContactsList[key].MeetingInfo.title : "Meeting";
                    customerInfo = null;

                } else {
                    //INTERACTION

                    fullName = sortedContactsList[key].CustomerInfo ? sortedContactsList[key].CustomerInfo.customerName : sortedContactsList[key].InteractionId;

                    customerInfo = sortedContactsList[key].CustomerInfo ? sortedContactsList[key].CustomerInfo : null;

                }


            }
            return (
                <ChatUserWrapper
                    key={`CONTACTS_${key}`}
                    onPressMenuIcon={props.onPressMenuIcon}
                    onClickCloseInfoPanel={props.onClickCloseInfoPanel}
                    onClickOpenInfoPanel={props.onClickOpenInfoPanel}
                    contact_id={key}
                    conversation_type={sortedContactsList[key].conversation_type}
                    user={sortedContactsList[key]}
                    src={sortedContactsList[key].avatarURL ? sortedContactsList[key].avatarURL : null}
                    fullName={fullName}
                    isACW={sortedContactsList[key].isACW}
                    isTemp={sortedContactsList[key].isTemp}
                    createdAt={sortedContactsList[key].createdAt}
                    userData={customerInfo}
                    InteractionId={sortedContactsList[key].InteractionId}
                    isMeeting={sortedContactsList[key].IsMeeting} />)
        }

    })

    // .navigationRestrictions.profile.summerized_info.active
    return (
        <div className={`apm-chat-body ${props.is_chat_ready === 'LOADING' && 'action-panel-disabled'}`}>
            <div className={'apm-chat-user-wrapper'}>
                <Scrollbars
                    autoHide
                    thumbMinSize={0}
                    style={{ height: props.viewPointHeight - (props.viewPointWidth > 895 ? props.summerizedInfo ? 200 : 130 : props.summerizedInfo ? 200 : 160) }}>
                    {contactListView}

                </Scrollbars>
            </div>


        </div>
    );
};

MyContacts.propTypes = {
    team: PropTypes.object.isRequired,
    chat_group: PropTypes.object.isRequired
};

function mapStateToProps(state) {

    let mapState = {
        team: state.team,
        chat_group: state.chat_group.GroupListInfo,
        interactions: state.interactions,
        is_chat_ready: state.ui_panel_data.action_panel.chat_ready,
    }

    if (state.chats) {
        mapState.chats = getFilteredChats(state.chats);
    }

    if (state.presence.renderEvent === types.GET_ALL_PRESSENCE_SUCCESS || state.presence.renderEvent === types.RECEIVE_PRESENCE) {
        mapState.presence_list = state.presence;
    }
    return mapState;
}

export default connect(mapStateToProps)(MyContacts);
