import * as types from "../actions/actionTypes";
import produce from "immer";

export const socketReducer = produce((draft = { status: "disconnected" }, action) => {
    switch (action.type) {
        case types.SOCKET_STATUS_CHANGED:
            {
                draft.status = action.socketState;
                return draft;
            }
        default:
            return draft;
    }
});