import { Logger } from './logger.js';

class FixedHeaderSerializer {

    constructor() {
    }

    deserialize = (rInStr, rOutMessage) => {
        try {

            if (rInStr.length < SplitPoints.body()) {
                Logger.error(`Invalid message: too small`);
                return false;
            }

            for (let [rpFirst, rpSecond] of s_validateTemplate.entries()) {
                // Logger.log(rpFirst);
                // Logger.log(rpSecond);
                let msgTag = rInStr.substring(rpFirst, (rpFirst + rpSecond.length));

                if (msgTag !== rpSecond) {
                    Logger.error(`Invalid message: format error`);
                    return false;
                }
            }

            let cret = rInStr.substring(SplitPoints.type()[0], SplitPoints.type()[1]).charAt(0);
            switch (cret) {
                case 'M':
                    rOutMessage.setType(MsgType.APP);
                    break;

                case 'A':
                    rOutMessage.setType(MsgType.ACK);
                    break;

                case 'N':
                    rOutMessage.setType(MsgType.NACK);
                    break;

                case 'H':
                    rOutMessage.setType(MsgType.HB);
                    break;

                case 'S':
                    rOutMessage.setType(MsgType.SS);
                    break;

                case 'E':
                    rOutMessage.setType(MsgType.SE);
                    break;

                case 'L':
                    rOutMessage.setType(MsgType.LOG);
                    break;

                case 'R':
                    rOutMessage.setType(MsgType.STRM);
                    break;

                default:
                    Logger.error(`Invalid message: unknown type`);
                    return false;
            }

            let ret = rInStr.substring(SplitPoints.subtype()[0], SplitPoints.subtype()[1]);
            rOutMessage.setSubType(ret.trim());

            ret = rInStr.substring(SplitPoints.mid()[0], SplitPoints.mid()[1]);

            try {
                rOutMessage.setMessageID(parseInt(ret.trim()));
            }
            catch (e) {
                Logger.error(`Invalid message: Message ID must be a number`, e);
                return false;
            }

            ret = rInStr.substring(SplitPoints.sid()[0], SplitPoints.sid()[1]);
            rOutMessage.setSessionID(ret);

            rOutMessage.setExtStrContent(rInStr.substring(SplitPoints.body()));
        }
        catch (e) {
            Logger.error(`Unexpected Error in FixedHeaderSerializer has occurred`, e);
        }

        return true;
    }

    static justifyRight(num, val) { //Using arrow function in a static class, does not work in Mac/iOS Safari
        let re = new RegExp(".{" + num + "}$");
        let pad = "";
        const ch = " ";
        do {
            pad += ch;
        } while (pad.length < num);
        return re.exec(pad + val)[0];
    }

    serialize = (rMsg, rOutStr) => {
        try {
            if (parseInt(rMsg.getMessageID(), 10) > 9999) {
                Logger.error("Invalid Message Id");
                return false;
            }

            if (rMsg.getSessionID().length > 32) {
                Logger.error("Invalid Session Id");
                return false;
            }

            if (!(rOutStr && rOutStr instanceof StringBuffer)) {
                Logger.error("rOutStr is not instance StringBuffer");
                return false;
            }

            rOutStr.append("[T]");

            let type = rMsg.getType();
            rOutStr.append(type);
            rOutStr.append(FixedHeaderSerializer.justifyRight(4, rMsg.getSubType() == undefined ? "" : rMsg.getSubType()));

            rOutStr.append("[M]");
            rOutStr.append(FixedHeaderSerializer.justifyRight(4, rMsg.getMessageID() == undefined ? "" : rMsg.getMessageID()));

            rOutStr.append("[S]");
            rOutStr.append(FixedHeaderSerializer.justifyRight(32, rMsg.getSessionID() == undefined ? "" : rMsg.getSessionID()));

            rOutStr.append("[B]");
            rOutStr.append(rMsg.getExtStrContent());
        }
        catch (e) {
            Logger.error(`Error in Serialize`, e)
            return false;
        }
        return true;
    }

}

class SplitPoints {
    //Static properties do not work in Mac/iOS Safari
    static type() { return [3, 4] };
    static subtype() { return [4, 8] };
    static mid() { return [11, 15] };
    static sid() { return [18, 50] };
    static body() { return 53 };
}

class StringBuffer {
    constructor(data) {
        if (data) {
            this.result = data;
        }
        else {
            this.result = "";
        }
    }

    getResult = () => {
        return this.result;
    }
    setResult = (res) => {
        this.result = res;
    }

    append = (data) => {
        if (data) {
            this.setResult(this.getResult().concat(data));
        }
    }

    toString = () => {
        return this.getResult();
    }
}

class Message {

    getSessionID = () => {
        return this.m_sSessionID;
    }

    setSessionID = (m_sSessionID) => {
        this.m_sSessionID = m_sSessionID;
    }

    getMessageID = () => {
        return this.m_iMessageID;
    }

    setMessageID = (m_iMessageID) => {
        this.m_iMessageID = m_iMessageID;
    }

    getExtStrContent = () => {
        return this.m_sExtStrContent;
    }

    setExtStrContent = (m_sExtStrContent) => {
        this.m_sExtStrContent = m_sExtStrContent;
    }

    getSubType = () => {
        return this.m_sSubType;
    }

    getSendState = () => {
        return this.m_eSendState;
    }

    setSendState = (m_eSendState) => {
        this.m_eSendState = m_eSendState;
    }

    setSubType = (m_sSubType) => {
        this.m_sSubType = m_sSubType;
    }

    getType = () => {
        return this.m_eType;
    }

    setType = (m_eType) => {
        this.m_eType = m_eType;
    }
}

const MsgType = {
    APP: 'M', // Some content from application
    ACK: 'A', // Ack for any previous message
    NACK: 'N', // Nack for a message ( e.g. when session not found )
    HB: 'H', // Heart-beat message
    SS: 'S', // Session start message
    SE: 'E', // Session end message
    LOG: 'L', // Log message
    STRM: 'R', // Streaming message
    FILE: 'F' //File Transfer message
}

const templateArr = [[0, "[T]"], [8, "[M]"], [15, "[S]"], [50, "[B]"]];
const s_validateTemplate = new Map(templateArr);


export { FixedHeaderSerializer, StringBuffer, Message, MsgType };