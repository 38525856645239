import React from 'react';
import PropTypes from 'prop-types';
import { MenuIcon, VideoCallerProfile } from "../index";
import {
    Maximize,
    Minimize,
    Users,
} from "react-feather";
import { Tooltip } from "antd";


//online
//offline
//busy
//icon = <MessageCircle/>
//other
const VideoLiveCount = ({
    callDurations,
    isVideoLoading,
    isVideoMinimizeScreen,
    onClickUnlockVideo,
    isAllowedScreenShare,
    onClickOpenParticipation,
    isOpenParticipationPanel,
    userDetails,
    isScreenShare,callDurationComponent,participatorStatusComponent
}) => (
        <>
            { !isVideoLoading && (<>
                <div
                    className={ `top-left-action-wrapper d-flex ${isVideoMinimizeScreen ? 'minimizer-screen' : 'full-screen'}` } style={{zIndex:10}}>
                    { !isScreenShare &&
                        <div
                            className={
                                "timer-wrapper d-flex justify-content-center align-items-center"
                            }>
                            <div className={ "video-status" } />
                            {/* <div className={ "timer" }>{ callDurations }</div> */}
                            {callDurationComponent}
                        </div>
                    }

                        

                    {/* { isAllowedScreenShare && (
                        <Tooltip title={ `${isVideoMinimizeScreen ? 'Maximize' : 'Minimize'}` }>
                            <div
                                className={ `pg-header-icon-wrp video-top-menu  sm-round ${isVideoMinimizeScreen ? 'minimizer-screen active-max-screen ' : 'full-screen'} ` }>
                                <MenuIcon
                                    size={ 'sm' }
                                    color={ 'white' }
                                    onClick={ () =>
                                        onClickUnlockVideo(!isVideoMinimizeScreen)
                                    }
                                    icon={
                                        isVideoMinimizeScreen ? <Minimize /> : <Maximize />
                                    } />
                            </div>
                        </Tooltip>
                    ) }*/}
                    { isAllowedScreenShare && !isVideoMinimizeScreen && !isOpenParticipationPanel && !isScreenShare && (
                        <Tooltip title={ `Participation` }>
                            <div
                                className={ `pg-header-icon-wrp video-top-menu  sm-round ${isVideoMinimizeScreen ? 'minimizer-screen ' : 'full-screen'} ` }>
                                <MenuIcon
                                    size={ 'sm' }
                                    color={ 'white' }
                                    onClick={ () =>
                                        onClickOpenParticipation(!isVideoMinimizeScreen)
                                    }
                                    icon={ <Users /> } />
                                { Object.keys(userDetails).length > 2 && (
                                    <div
                                        className={
                                            "participation-count video-c-count top-action d-flex  justify-content-center align-items-center"
                                        }>
                                        { Object.keys(userDetails).length }
                                    </div>
                                ) }
                            </div>
                        </Tooltip>
                    ) } 
                </div>
                {participatorStatusComponent}
                </>
            ) }
        </>
    );


VideoLiveCount.defaultProps = {};

export default VideoLiveCount;
