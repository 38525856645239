import * as types from "./actionTypes";
import * as groupService from "../../service/groupService";
import { getUserId } from "../../utils";
import * as chatActions from "./chatActions";

/* export function custChatUsersLoaded(chatUsers) {
    return { type: types.CUST_CHAT_USERS_LOADED, chatUsers };
}

export function renameCustomer(message) {
    let data = { previousId: message.original_contact.id, profileId: message.profile, displayName: message.from.name };
    return { type: types.CUST_USER_RENAMED, data };
}

export function addNewClientForChat(chat) {
    return (dispatch, getState) => {

        let tempchat = {
            message: chat
        }
        if (chat.conversation_type === 3) {
            tempchat.conversation_type = 3;

        } else {
            const state = getState();
            if (state.team && !state.team[chat.from.id]) {
                tempchat.conversation_type = 1;
            } else {
                tempchat.conversation_type = 0;
            }

        }


        dispatch({ type: types.ADD_NEW_CLIENT_FOR_CHAT, chat: tempchat })
    }
} */

export function addParticipant(groupId, participant) {
    let info = { groupId, participant };
    return { type: types.ADD_PARTICIPANT, info };
}

export function removeParticipant(groupId, userId) {
    let info = { groupId, userId };
    return { type: types.REMOVE_PARTICIPANT, info };
}

export function groupAdminAdded(groupId, userId) {
    let info = { groupId, userId };
    return { type: types.GROUP_ADMIN_ADDED, info };
}

export function groupAdminDismissed(groupId, userId) {
    let info = { groupId, userId };
    return { type: types.GROUP_ADMIN_DISMISSED, info };
}

export function createGroupChat(groupInfo) {
    let formatted = groupService.buildCreatedGroup(groupInfo);
    return { type: types.GROUP_CHAT_CREATED, groupInfo: formatted };
}

export function updateGroupChat(groupInfo) {
    let formatted = groupService.buildCreatedGroup(groupInfo);
    return { type: types.GROUP_CHAT_UPDATED, groupInfo: formatted };
}

export function groupListLoaded(groupList) {
    return dispatch => {

        let formatted = groupService.buildGroupList(groupList);

        dispatch({ type: types.GROUP_LIST_LOADED, groupList: formatted });

        Object.keys(formatted.GroupListInfo).length > 0 &&
            dispatch(
                chatActions.getAllUnreadCountsForUserIds(
                    getUserId(),
                    Object.keys(formatted.GroupListInfo)
                )
            );



    };

}

export function groupDeleted(groupId) {
    return { type: types.GROUP_DELETED, groupId }
}