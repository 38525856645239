/* const custInfoData = {
    customerId: "UCIC",
    customerName: "Customer Name",
    intent: "Intent",
    pageId: "Page Description",
    mobileAuthFlag: "Authentication Flag",
    mobileNumber: "Mobile",
    channel: "Channel"
}; */

import { __APP_INTERACTION_MODE__ } from './baseUrls';

const custInfoData = __APP_INTERACTION_MODE__ === 'USER' ? {
    firstName: "First Name",
    lastName: "Last Name",
    emailId: "Email",
    gender: "Gender",
    userName: "Username",
    mobileNumber: "Mobile",
    userType: "User Type"
} : {
    customerId: "UCIC",
    customerName: "Customer Name",
    intent: "Intent",
    pageId: "Page Description",
    mobileAuthFlag: "Authentication Flag",
    mobileNumber: "Mobile",
    channel: "Channel"
};

export default custInfoData;
