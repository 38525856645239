import React from 'react';
import {
    MediaImage,
    MediaVideo,
} from '../../common';
import { Scrollbars } from "react-custom-scrollbars";

let scrollBarMedia = null;

function MediaFile(props) {


    return (
        <>
            <Scrollbars
                ref={c => {
                    scrollBarMedia = c;
                }}
                thumbMinSize={0}
                onScrollStop={() => {
                    let values = scrollBarMedia ? scrollBarMedia.getValues() : null;
                    if (values.top > 0.999) {
                        //scrolled to bottom
                        props.onScrollEnd("media");
                    }
                }}
                style={{ width: 300, height: props.viewPointHeight - 80 }}>
                <div className={'media-body-wrapper d-flex flex-wrap'}>
                    <div className={'media-body-wrapper d-flex flex-wrap'}>
                        {props.media}
                    </div>
                </div>

            </Scrollbars>




        </>
    );
}


export default MediaFile;
