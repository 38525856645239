import React from 'react';
import PropTypes from 'prop-types';
import {Headphones} from 'react-feather';

const AudioThumbnail = ({duration, onclick}) => (
    <div className={'gallery-thumbnail-wrp audio  d-flex flex-column justify-content-center align-items-center'}
         onClick={onclick}>
        <Headphones/>
        <div className={'duration'}>
            {duration}
        </div>
    </div>
);


AudioThumbnail.defaultProps = {};

export default AudioThumbnail;
