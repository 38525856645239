import { Livechat2Api } from "./livechat2api";
import * as auth from "../utils";
import globalFlags from "../utils/globalFlags";
import {
  __APP_IDENTITY_PROVIDER__,
  authUrl,
  webSocketUrl,
  __APP_AUTH_TYPE__,
  __APP_MASK_CUSTOMER_DATA__,
  iamAppId
} from "../config/baseUrls";
import * as config from "../config/baseUrls";
import maskProperties from "../utils/maskData";
import * as logger from "../lib/logSdk/logger";

const handlerMap = {};
let liveChatClient;
class SdkHandler {
  constructor() {
    try {
      console.log("%cSDK HANDLER LOADED.",
        "color:#233E82; font-family:'Ubuntu'; display: block;font-weight:bold; font-size:15px;", "SdkHandler", "livechat2api", "create livechat2api instance");

      liveChatClient = new Livechat2Api(webSocketUrl, config);

      let maskingPatterns = {};
      maskingPatterns['userDetails.+?(?="})'] = 'userDetails\\":\\"{***}';
      maskingPatterns['customerInfo.+?(?="})'] = 'customerInfo\\":\\"{***}';

      liveChatClient.sdkLoggingConfigurations(
        true,
        true,
        __APP_MASK_CUSTOMER_DATA__ === "YES" ? true : false,
        maskingPatterns
      );

      window.livechat2Api = liveChatClient;

      /* let token = auth.getToken();

      if (token) {
        console.log(`%c [ Lib-Index liveChatClient open]`, 'background: #222; color: #bada55; font-size:12px');
        this._connectionStatus = liveChatClient.open();
      } */
      liveChatClient.onStartSuccess = (sid) => {
        console.log(
          `%c [ Lib-Index liveChatClient onStartSuccess ] - Data : ${JSON.stringify(
            { sid }
          )}`,
          "background: #000000; color: #ff3399"
        );
        logger.info(
          "SdkHandler",
          "onStartSuccess",
          `[ Lib-Index liveChatClient onStartSuccess ] - Data : ${JSON.stringify(
            { sid }
          )}`
        );
        if (handlerMap["onStartSuccess"]) {
          globalFlags.__IS_SESSION_STARTED__ = true;
          Object.keys(handlerMap["onStartSuccess"]).forEach((key) => {
            handlerMap["onStartSuccess"][key](sid);
          });
        }
      };

      liveChatClient.onStartFailed = (error) => {
        console.log(
          `%c [ Lib-Index liveChatClient onStartFailed ] - Data : ${JSON.stringify(
            { error }
          )}`,
          "background: #000000; color: #ff3399"
        );
        logger.info(
          "SdkHandler",
          "onStartFailed",
          `[ Lib-Index liveChatClient onStartFailed ] - Data : ${JSON.stringify(
            { error }
          )}`
        );
        if (handlerMap["onStartFailed"]) {
          Object.keys(handlerMap["onStartFailed"]).forEach((key) => {
            handlerMap["onStartFailed"][key](error);
          });
        }
      };

      liveChatClient.onInteractionConferenceSuccess = (interactionInformation) => {

        console.log(
          `%c [ Lib-Index liveChatClient onInteractionConferenceSuccess ] - SessionId : ${auth.getSession()}, Data : ${JSON.stringify(
            maskProperties(interactionInformation, ["userDetails"])
          )}`,
          "background: #000000; color: #ff3399"
        );
        logger.info(
          "SdkHandler",
          "onInteractionConferenceSuccess",
          `[ Lib-Index liveChatClient onInteractionConferenceSuccess ] - SessionId : ${auth.getSession()}, Data : ${JSON.stringify(
            maskProperties(interactionInformation, ["userDetails"])
          )}`
        );
        if (handlerMap["onInteractionConferenceSuccess"]) {
          Object.keys(handlerMap["onInteractionConferenceSuccess"]).forEach((key) => {
            handlerMap["onInteractionConferenceSuccess"][key](interactionInformation);
          });
        }

      }


      liveChatClient.onInteractionStarted = (interactionInformation) => {

        console.log(
          `%c [ Lib-Index liveChatClient onInteractionStarted ] - SessionId : ${auth.getSession()}, Data : ${JSON.stringify(
            maskProperties(interactionInformation, ["userDetails"])
          )}`,
          "background: #000000; color: #ff3399"
        );
        logger.info(
          "SdkHandler",
          "onInteractionStarted",
          `[ Lib-Index liveChatClient onInteractionStarted ] - SessionId : ${auth.getSession()}, Data : ${JSON.stringify(
            maskProperties(interactionInformation, ["userDetails"])
          )}`
        );
        if (handlerMap["onInteractionStarted"]) {
          Object.keys(handlerMap["onInteractionStarted"]).forEach((key) => {
            handlerMap["onInteractionStarted"][key](interactionInformation);
          });
        }

      }

      liveChatClient.onUserLeftInteraction = (info) => {

        console.log(
          `%c [ Lib-Index liveChatClient onUserLeftInteraction ] - SessionId : ${auth.getSession()}, Data : ${JSON.stringify(
            maskProperties(info, ["userDetails"])
          )}`,
          "background: #000000; color: #ff3399"
        );
        logger.info(
          "SdkHandler",
          "onUserLeftInteraction",
          `[ Lib-Index liveChatClient onUserLeftInteraction ] - SessionId : ${auth.getSession()}, Data : ${JSON.stringify(
            maskProperties(info, ["userDetails"])
          )}`
        );
        if (handlerMap["onUserLeftInteraction"]) {
          Object.keys(handlerMap["onUserLeftInteraction"]).forEach((key) => {
            handlerMap["onUserLeftInteraction"][key](info);
          });
        }

      }


      liveChatClient.onSnapshotRequest = (acceptOrRejectObject) => {
        console.log(
          `%c [ Lib-Index liveChatClient onSnapshotRequest ] - SessionId : ${auth.getSession()}`,
          "background: #000000; color: #ff3399"
        );
        logger.info(
          "SdkHandler",
          "onSnapshotRequest",
          `[ Lib-Index liveChatClient onSnapshotRequest ] - SessionId : ${auth.getSession()}`
        );
        if (handlerMap["onSnapshotRequest"]) {
          Object.keys(handlerMap["onSnapshotRequest"]).forEach((key) => {
            handlerMap["onSnapshotRequest"][key](acceptOrRejectObject);
          });
        }

      }

      liveChatClient.onSignatureRequest = (acceptOrRejectObject) => {
        console.log(
          `%c [ Lib-Index liveChatClient onSignatureRequest ] - SessionId : ${auth.getSession()}`,
          "background: #000000; color: #ff3399"
        );
        logger.info(
          "SdkHandler",
          "onSignatureRequest",
          `[ Lib-Index liveChatClient onSignatureRequest ] - SessionId : ${auth.getSession()}`
        );
        if (handlerMap["onSignatureRequest"]) {
          Object.keys(handlerMap["onSignatureRequest"]).forEach((key) => {
            handlerMap["onSignatureRequest"][key](acceptOrRejectObject);
          });
        }

      }


      liveChatClient.onSnapshotRequestAck = (ackInfo) => {
        console.log(
          `%c [ Lib-Index liveChatClient onSnapshotRequestAck ] - SessionId : ${auth.getSession()} Data : ${JSON.stringify(
            ackInfo
          )}`,
          "background: #000000; color: #ff3399"
        );
        logger.info(
          "SdkHandler",
          "onSnapshotRequestAck",
          `[ Lib-Index liveChatClient onSnapshotRequestAck ] - SessionId : ${auth.getSession()} Data : ${JSON.stringify(
            ackInfo
          )}`
        );
        if (handlerMap["onSnapshotRequestAck"]) {
          Object.keys(handlerMap["onSnapshotRequestAck"]).forEach((key) => {
            handlerMap["onSnapshotRequestAck"][key](ackInfo);
          });
        }

      }

      liveChatClient.onSignatureRequestAck = (ackInfo) => {
        console.log(
          `%c [ Lib-Index liveChatClient onSignatureRequestAck ] - SessionId : ${auth.getSession()} Data : ${JSON.stringify(
            ackInfo
          )}`,
          "background: #000000; color: #ff3399"
        );
        logger.info(
          "SdkHandler",
          "onSignatureRequestAck",
          `[ Lib-Index liveChatClient onSignatureRequestAck ] - SessionId : ${auth.getSession()} Data : ${JSON.stringify(
            ackInfo
          )}`
        );
        if (handlerMap["onSignatureRequestAck"]) {
          Object.keys(handlerMap["onSignatureRequestAck"]).forEach((key) => {
            handlerMap["onSignatureRequestAck"][key](ackInfo);
          });
        }

      }


      liveChatClient.onUserFound = (userData) => {
        //let deepCopy = JSON.parse(JSON.strigify(userData));
        //delete deepCopy.userDetails;
        console.log(
          `%c [ Lib-Index liveChatClient onUserFound ] - SessionId : ${auth.getSession()}, Data : ${JSON.stringify(
            maskProperties(userData, ["userDetails"])
          )}`,
          "background: #000000; color: #ff3399"
        );
        logger.info(
          "SdkHandler",
          "onUserFound",
          `[ Lib-Index liveChatClient onUserFound ] - SessionId : ${auth.getSession()}, Data : ${JSON.stringify(
            maskProperties(userData, ["userDetails"])
          )}`
        );
        if (handlerMap["onUserFound"]) {
          Object.keys(handlerMap["onUserFound"]).forEach((key) => {
            handlerMap["onUserFound"][key](userData);
          });
        }
      };

      liveChatClient.onDeliveryRecipt = (recipt) => {
        console.log(
          `%c [ Lib-Index liveChatClient onDeliveryRecipt ] - SessionId : ${auth.getSession()}, Data : ${JSON.stringify(
            { recipt }
          )}`,
          "background: #000000; color: #ff3399"
        );
        logger.info(
          "SdkHandler",
          "onDeliveryRecipt",
          `[ Lib-Index liveChatClient onDeliveryRecipt ] - SessionId : ${auth.getSession()}, Data : ${JSON.stringify(
            { recipt }
          )}`
        );
        if (handlerMap["onDeliveryRecipt"]) {
          Object.keys(handlerMap["onDeliveryRecipt"]).forEach((key) => {
            handlerMap["onDeliveryRecipt"][key](recipt);
          });
        }
      };

      liveChatClient.onGroupMessageReceived = (message) => {
        console.log(
          `%c [ Lib-Index liveChatClient onGroupMessageReceived ] - SessionId : ${auth.getSession()}, Data : ${JSON.stringify(
            { message }
          )}`,
          "background: #000000; color: #ff3399"
        );
        logger.info(
          "SdkHandler",
          "onGroupMessageReceived",
          `[ Lib-Index liveChatClient onGroupMessageReceived ] - SessionId : ${auth.getSession()}, Data : ${JSON.stringify(
            { message }
          )}`
        );
        if (handlerMap["onGroupMessageReceived"]) {
          Object.keys(handlerMap["onGroupMessageReceived"]).forEach((key) => {
            handlerMap["onGroupMessageReceived"][key](message);
          });
        }
      };

      liveChatClient.onPresenceNotification = (content) => {
        console.log(
          `%c [ Lib-Index liveChatClient onPresenceNotification ] - SessionId : ${auth.getSession()}, Data : ${JSON.stringify(
            { content }
          )}`,
          "background: #000000; color: #ff3399"
        );
        logger.info(
          "SdkHandler",
          "onPresenceNotification",
          `[ Lib-Index liveChatClient onPresenceNotification ] - SessionId : ${auth.getSession()}, Data : ${JSON.stringify(
            { content }
          )}`
        );
        if (handlerMap["onPresenceNotification"]) {
          Object.keys(handlerMap["onPresenceNotification"]).forEach((key) => {
            handlerMap["onPresenceNotification"][key](content);
          });
        }
      };

      liveChatClient.onTypingState = (state, from) => {
        console.log(
          `%c [ Lib-Index liveChatClient onTypingState ] - SessionId : ${auth.getSession()}, Data : ${JSON.stringify(
            { state, from }
          )}`,
          "background: #000000; color: #ff3399"
        );
        logger.info(
          "SdkHandler",
          "onTypingState",
          `[ Lib-Index liveChatClient onTypingState ] - SessionId : ${auth.getSession()}, Data : ${JSON.stringify(
            { state, from }
          )}`
        );
        if (handlerMap["onTypingState"]) {
          Object.keys(handlerMap["onTypingState"]).forEach((key) => {
            handlerMap["onTypingState"][key](state, from);
          });
        }
      };

      liveChatClient.onUserGroupListing = (groupList) => {
        console.log(
          `%c [ Lib-Index liveChatClient onUserGroupListing] - SessionId : ${auth.getSession()}`,
          "background: #000000; color: #ff3399"
        );
        logger.info(
          "SdkHandler",
          "onUserGroupListing",
          `[ Lib-Index liveChatClient onUserGroupListing] - SessionId : ${auth.getSession()}`
        );
        if (handlerMap["onUserGroupListing"]) {
          Object.keys(handlerMap["onUserGroupListing"]).forEach((key) => {
            handlerMap["onUserGroupListing"][key](groupList);
          });
        }
      };

      liveChatClient.onUserInteractionListing = (interactionListInfo) => {
        console.log(
          `%c [ Lib-Index liveChatClient onUserInteractionListing] - SessionId : ${auth.getSession()}`,
          "background: #000000; color: #ff3399"
        );
        logger.info(
          "SdkHandler",
          "onUserInteractionListing",
          `[ Lib-Index liveChatClient onUserInteractionListing] - SessionId : ${auth.getSession()}`
        );
        if (handlerMap["onUserInteractionListing"]) {
          Object.keys(handlerMap["onUserInteractionListing"]).forEach((key) => {
            handlerMap["onUserInteractionListing"][key](interactionListInfo);
          });
        }
      };

      liveChatClient.onReadRecipt = (recipt) => {
        console.log(
          `%c [ Lib-Index liveChatClient onReadRecipt ] - SessionId : ${auth.getSession()}, Data : ${JSON.stringify(
            { recipt }
          )}`,
          "background: #000000; color: #ff3399"
        );
        logger.info(
          "SdkHandler",
          "onReadRecipt",
          `[ Lib-Index liveChatClient onReadRecipt ] - SessionId : ${auth.getSession()}, Data : ${JSON.stringify({
            recipt,
          })}`
        );
        if (handlerMap["onReadRecipt"]) {
          Object.keys(handlerMap["onReadRecipt"]).forEach((key) => {
            handlerMap["onReadRecipt"][key](recipt);
          });
        }
      };

      liveChatClient.onGroupCreated = (groupInfo) => {
        console.log(
          `%c [ Lib-Index liveChatClient onGroupCreated ] - SessionId : ${auth.getSession()}, Data : ${JSON.stringify(
            { groupInfo }
          )}`,
          "background: #000000; color: #ff3399"
        );
        logger.info(
          "SdkHandler",
          "onGroupCreated",
          `[ Lib-Index liveChatClient onGroupCreated ] - SessionId : ${auth.getSession()}, Data : ${JSON.stringify(
            { groupInfo }
          )}`
        );
        if (handlerMap["onGroupCreated"]) {
          Object.keys(handlerMap["onGroupCreated"]).forEach((key) => {
            handlerMap["onGroupCreated"][key](groupInfo);
          });
        }
      };

      liveChatClient.onUserRemovedFromGroup = (groupInfo) => {
        console.log(
          `%c [ Lib-Index liveChatClient onUserRemovedFromGroup ] - SessionId : ${auth.getSession()}, Data : ${JSON.stringify(
            { groupInfo }
          )}`,
          "background: #000000; color: #ff3399"
        );
        logger.info(
          "SdkHandler",
          "onUserRemovedFromGroup",
          `[ Lib-Index liveChatClient onUserRemovedFromGroup ] - SessionId : ${auth.getSession()}, Data : ${JSON.stringify(
            { groupInfo }
          )}`
        );
        if (handlerMap["onUserRemovedFromGroup"]) {
          Object.keys(handlerMap["onUserRemovedFromGroup"]).forEach((key) => {
            handlerMap["onUserRemovedFromGroup"][key](groupInfo);
          });
        }
      };

      liveChatClient.onGroupAdminAdded = (groupInfo) => {
        console.log(
          `%c [ Lib-Index liveChatClient onGroupAdminAdded ] - SessionId : ${auth.getSession()}, Data : ${JSON.stringify(
            { groupInfo }
          )}`,
          "background: #000000; color: #ff3399"
        );
        logger.info(
          "SdkHandler",
          "onGroupAdminAdded",
          `[ Lib-Index liveChatClient onGroupAdminAdded ] - SessionId : ${auth.getSession()}, Data : ${JSON.stringify(
            { groupInfo }
          )}`
        );
        if (handlerMap["onGroupAdminAdded"]) {
          Object.keys(handlerMap["onGroupAdminAdded"]).forEach((key) => {
            handlerMap["onGroupAdminAdded"][key](groupInfo);
          });
        }
      };

      liveChatClient.onGroupAdminDismissed = (groupInfo) => {
        console.log(
          `%c [ Lib-Index liveChatClient onGroupAdminDismissed ] - SessionId : ${auth.getSession()}, Data : ${JSON.stringify(
            { groupInfo }
          )}`,
          "background: #000000; color: #ff3399"
        );
        logger.info(
          "SdkHandler",
          "onGroupAdminDismissed",
          `[ Lib-Index liveChatClient onGroupAdminDismissed ] - SessionId : ${auth.getSession()}, Data : ${JSON.stringify(
            { groupInfo }
          )}`
        );
        if (handlerMap["onGroupAdminDismissed"]) {
          Object.keys(handlerMap["onGroupAdminDismissed"]).forEach((key) => {
            handlerMap["onGroupAdminDismissed"][key](groupInfo);
          });
        }
      };

      liveChatClient.onUserAddedToGroup = (groupInfo) => {
        console.log(
          `%c [ Lib-Index liveChatClient onUserAddedToGroup ] - SessionId : ${auth.getSession()}, Data : ${JSON.stringify(
            { groupInfo }
          )}`,
          "background: #000000; color: #ff3399"
        );
        logger.info(
          "SdkHandler",
          "onUserAddedToGroup",
          `[ Lib-Index liveChatClient onUserAddedToGroup ] - SessionId : ${auth.getSession()}, Data : ${JSON.stringify(
            { groupInfo }
          )}`
        );
        if (handlerMap["onUserAddedToGroup"]) {
          Object.keys(handlerMap["onUserAddedToGroup"]).forEach((key) => {
            handlerMap["onUserAddedToGroup"][key](groupInfo);
          });
        }
      };

      liveChatClient.onSessionEnd = (content) => {
        console.log(
          `%c [ Lib-Index liveChatClient onSessionEnd ] - SessionId : ${auth.getSession()}, Data : ${JSON.stringify(
            { content }
          )}`,
          "background: #000000; color: #ff3399"
        );
        logger.info(
          "SdkHandler",
          "onSessionEnd",
          `[ Lib-Index liveChatClient onSessionEnd ] - SessionId : ${auth.getSession()}, Data : ${JSON.stringify({ content })}`
        );
        if (handlerMap["onSessionEnd"]) {
          Object.keys(handlerMap["onSessionEnd"]).forEach((key) => {
            handlerMap["onSessionEnd"][key](content);
          });
        }
      };

      liveChatClient.onUserLeftGroup = (groupInfo) => {
        console.log(
          `%c [ Lib-Index liveChatClient onUserLeftGroup ] - SessionId : ${auth.getSession()}, Data : ${JSON.stringify(
            { groupInfo }
          )}`,
          "background: #000000; color: #ff3399"
        );
        logger.info(
          "SdkHandler",
          "onUserLeftGroup",
          `[ Lib-Index liveChatClient onUserLeftGroup ] - SessionId : ${auth.getSession()}, Data : ${JSON.stringify(
            { groupInfo }
          )}`
        );
        if (handlerMap["onUserLeftGroup"]) {
          Object.keys(handlerMap["onUserLeftGroup"]).forEach((key) => {
            handlerMap["onUserLeftGroup"][key](groupInfo);
          });
        }
      };

      liveChatClient.onInteractionTransferSuccess = (info) => {
        console.log(
          `%c [ Lib-Index liveChatClient onInteractionTransferSuccess ] - SessionId : ${auth.getSession()}, Data : ${JSON.stringify(
            maskProperties(info, ["CustomerInfo"])
          )}`,
          "background: #000000; color: #ff3399"
        );
        logger.info(
          "SdkHandler",
          "onInteractionTransferSuccess",
          `[ Lib-Index liveChatClient onInteractionTransferSuccess ] - SessionId : ${auth.getSession()}, Data : ${JSON.stringify(
            maskProperties(info, ["CustomerInfo"])
          )}`
        );
        if (handlerMap["onInteractionTransferSuccess"]) {
          Object.keys(handlerMap["onInteractionTransferSuccess"]).forEach(
            (key) => {
              handlerMap["onInteractionTransferSuccess"][key](info);
            }
          );
        }
      };

      liveChatClient.onServerStateChange = (status) => {
        console.log(
          `%c [ Lib-Index liveChatClient onServerStateChange ] - SessionId : ${auth.getSession()}, Data : ${JSON.stringify(
            { status }
          )}`,
          "background: #000000; color: #ff3399"
        );
        logger.info(
          "SdkHandler",
          "onServerStateChange",
          `[ Lib-Index liveChatClient onServerStateChange ] - SessionId : ${auth.getSession()}, Data : ${JSON.stringify(
            { status }
          )}`
        );
        if (handlerMap["onServerStateChange"]) {
          Object.keys(handlerMap["onServerStateChange"]).forEach((key) => {
            handlerMap["onServerStateChange"][key](status);
          });
        }
      };

      liveChatClient.onGroupNameModified = (groupInfo) => {
        console.log(
          `%c [ Lib-Index liveChatClient onGroupNameModified ] - SessionId : ${auth.getSession()}, Data : ${JSON.stringify(
            { groupInfo }
          )}`,
          "background: #000000; color: #ff3399"
        );
        logger.info(
          "SdkHandler",
          "onGroupNameModified",
          `[ Lib-Index liveChatClient onGroupNameModified ] - SessionId : ${auth.getSession()}, Data : ${JSON.stringify(
            { groupInfo }
          )}`
        );
        if (handlerMap["onGroupNameModified"]) {
          Object.keys(handlerMap["onGroupNameModified"]).forEach((key) => {
            handlerMap["onGroupNameModified"][key](groupInfo);
          });
        }
      };

      liveChatClient.onGroupDeleted = (groupId) => {
        console.log(
          `%c [ Lib-Index liveChatClient onGroupDeleted ] - SessionId : ${auth.getSession()}, Data : ${JSON.stringify(
            { groupId }
          )}`,
          "background: #000000; color: #ff3399"
        );
        logger.info(
          "SdkHandler",
          "onGroupDeleted",
          `[ Lib-Index liveChatClient onGroupDeleted ] - SessionId : ${auth.getSession()}, Data : ${JSON.stringify(
            { groupId }
          )}`
        );
        if (handlerMap["onGroupDeleted"]) {
          Object.keys(handlerMap["onGroupDeleted"]).forEach((key) => {
            handlerMap["onGroupDeleted"][key](groupId);
          });
        }
      };

      liveChatClient.onUserMessage = (message, to) => {
        console.log(
          `%c [ Lib-Index liveChatClient onUserMessage ] - SessionId : ${auth.getSession()}, Data : ${JSON.stringify(
            { message, to }
          )}`,
          "background: #000000; color: #ff3399"
        );
        logger.info(
          "SdkHandler",
          "onUserMessage",
          `[ Lib-Index liveChatClient onUserMessage ] - SessionId : ${auth.getSession()}, Data : ${JSON.stringify(
            { message, to }
          )}`
        );
        if (handlerMap["onUserMessage"]) {
          Object.keys(handlerMap["onUserMessage"]).forEach((key) => {
            handlerMap["onUserMessage"][key](message, to);
          });
        }
      };

      liveChatClient.onConferenceCreated = (conferenceData) => {
        return {
          isSuccess: true,
          id: conferenceData.InteractionId,
          name: conferenceData.name,
        };
      };
      liveChatClient.onEnd = (reason, interactionId, customerUserId) => {
        console.log(
          `%c [ Lib-Index liveChatClient onEnd ] - SessionId : ${auth.getSession()}, Data : reason: ${reason}, interactionId: ${interactionId}, customerUserId: ${customerUserId}`,
          "background: #000000; color: #ff3399"
        );
        logger.info(
          "SdkHandler",
          "onEnd",
          `[ Lib-Index liveChatClient onEnd ] - SessionId : ${auth.getSession()}, Data : sessionId: ${auth.getSession()}, reason: ${reason}, interactionId: ${interactionId}, customerUserId: ${customerUserId}`
        );
        if (handlerMap["onEnd"]) {
          Object.keys(handlerMap["onEnd"]).forEach((key) => {
            handlerMap["onEnd"][key](reason, interactionId, customerUserId);
          });
        }
      };

      liveChatClient.onAppEvent = (message, from) => {
        console.log(
          `%c [ Lib-Index liveChatClient onAppEvent ] - SessionId : ${auth.getSession()}, message :  ${message} ,from :${from}`,
          "background: #000000; color: #ff3399"
        );
        logger.info(
          "SdkHandler",
          "onAppEvent",
          `[ Lib-Index liveChatClient onAppEvent ] - SessionId : ${auth.getSession()}, message :  ${message} ,from :${from}`
        );
        if (handlerMap["onAppEvent"]) {
          Object.keys(handlerMap["onAppEvent"]).forEach((key) => {
            handlerMap["onAppEvent"][key](message, from);
          });
        }
      };

      liveChatClient.onConferenceRequest = (acceptRejectObject) => {
        console.log(
          `%c [ Lib-Index liveChatClient onConferenceRequest ] - SessionId : ${auth.getSession()}`,
          "background: #000000; color: #ff3399"
        );
        logger.info(
          "SdkHandler",
          "onConferenceRequest",
          `[ Lib-Index liveChatClient onConferenceRequest ] - SessionId : ${auth.getSession()}`
        );
        if (handlerMap["onConferenceRequest"]) {
          Object.keys(handlerMap["onConferenceRequest"]).forEach((key) => {
            handlerMap["onConferenceRequest"][key](acceptRejectObject);
          });
        }
      };

      liveChatClient.onConferenceResponseAcknowledgement = (responseObject) => {
        console.log(
          `%c [ Lib-Index liveChatClient onConferenceResponseAcknowledgement ] - SessionId : ${auth.getSession()}, Data : ${JSON.stringify(
            maskProperties(responseObject, ["CustomerInfo"])
          )}`,
          "background: #000000; color: #ff3399"
        );
        logger.info(
          "SdkHandler",
          "onConferenceResponseAcknowledgement",
          ` [ Lib-Index liveChatClient onConferenceResponseAcknowledgement ] - SessionId : ${auth.getSession()}, Data : ${JSON.stringify(
            maskProperties(responseObject, ["CustomerInfo"])
          )}`
        );
        if (handlerMap["onConferenceResponseAcknowledgement"]) {
          Object.keys(
            handlerMap["onConferenceResponseAcknowledgement"]
          ).forEach((key) => {
            handlerMap["onConferenceResponseAcknowledgement"][key](
              responseObject
            );
          });
        }
      };

      liveChatClient.onInteractionEnd = (info) => {
        console.log(
          `%c [ Lib-Index liveChatClient onInteractionEnd ] - SessionId : ${auth.getSession()}, Data : ${JSON.stringify(
            maskProperties(info, ["CustomerInfo"])
          )}`,
          "background: #000000; color: #ff3399"
        );
        logger.info(
          "SdkHandler",
          "onInteractionEnd",
          `[ Lib-Index liveChatClient onInteractionEnd ] - SessionId : ${auth.getSession()}, Data : ${JSON.stringify(
            maskProperties(info, ["CustomerInfo"])
          )}`
        );
        if (handlerMap["onInteractionEnd"]) {
          Object.keys(handlerMap["onInteractionEnd"]).forEach((key) => {
            handlerMap["onInteractionEnd"][key](info);
          });
        }
      };

      liveChatClient.onInteractionTransferRequest = (acceptRejectObject) => {
        console.log(
          `%c [ Lib-Index liveChatClient onInteractionTransferRequest ] - SessionId : ${auth.getSession()}`,
          "background: #000000; color: #ff3399"
        );
        logger.info(
          "SdkHandler",
          "onInteractionTransferRequest",
          `[ Lib-Index liveChatClient onInteractionTransferRequest ] - SessionId : ${auth.getSession()}`
        );
        if (handlerMap["onInteractionTransferRequest"]) {
          Object.keys(handlerMap["onInteractionTransferRequest"]).forEach(
            (key) => {
              handlerMap["onInteractionTransferRequest"][key](
                acceptRejectObject
              );
            }
          );
        }
      };

      liveChatClient.onInteractionTransferResponseAcknowledgement = (
        responseObject
      ) => {
        console.log(
          `%c [ Lib-Index liveChatClient onInteractionTransferResponseAcknowledgement ] - SessionId : ${auth.getSession()}, Data : ${JSON.stringify(
            maskProperties(responseObject, ["CustomerInfo"])
          )}`,
          "background: #000000; color: #ff3399"
        );
        logger.info(
          "SdkHandler",
          "onInteractionTransferResponseAcknowledgement",
          `[ Lib-Index liveChatClient onInteractionTransferResponseAcknowledgement ] - SessionId : ${auth.getSession()}, Data : ${JSON.stringify(
            maskProperties(responseObject, ["CustomerInfo"])
          )}`
        );
        if (handlerMap["onInteractionTransferResponseAcknowledgement"]) {
          Object.keys(
            handlerMap["onInteractionTransferResponseAcknowledgement"]
          ).forEach((key) => {
            handlerMap["onInteractionTransferResponseAcknowledgement"][key](
              responseObject
            );
          });
        }
      };
    } catch (ex) {
      console.error("SdkHandler", ex);
      logger.error("SdkHandler", "constructor", ex.message);
    }
  }

  initiateSignaling = (liveChatAvFactoryE6, overrideLogs) => {
    try {
      console.log(
        "SdkHandler",
        "initiateSignaling",
        `initiate signaling, overrideLogs : ${overrideLogs}`
      );
      const logOverride = {
        getSessionId: () => {
          return "";
        },

        onVideoMessage: (msg) => { },

        getUserId: () => {
          return "user:" + liveChatClient.getLoggedInUserId();
        },

        SendAppMessage: (msg, to) => {
          liveChatClient.livechat.sendLv2Message("call-signal", msg, to);
        },

        slog: (type, unit, msg) => {
          console.log(`${type} : [${unit}]: ${msg}`);
          logger.log(type, "livechat_video_debug", unit, msg);
          // Note: this method is overridden in init method
        },

        _init: () => {
          try {
            if (liveChatClient.logClientMessage != undefined) {
              logOverride.slog = (type, unit, msg) => {
                try {
                  console.log(`${type} : [${unit}]: ${msg}`);
                  logger.log(type, "livechat_video_debug", unit, msg);
                  liveChatClient.logClientMessage(msg, unit, type);
                } catch (error) {
                  console.error("SdkHandler", "_init-logOverride.slog", error);
                  logger.error("SdkHandler", "_init-logOverride.slog", error.message);
                }
              };
            }

            liveChatClient.registerListener("call-signal", function (
              content,
              from
            ) {
              var msg = JSON.parse(content);
              msg.from = from;
              logOverride.onVideoMessage(JSON.stringify(msg));

            });
          } catch (error) {
            console.error("SdkHandler", "_init", error);
            logger.error("SdkHandler", "_init", error.message);
          }
        },
      }
      if (overrideLogs) {
        const response = liveChatAvFactoryE6.CreateUsingCustom(logOverride);
        logOverride._init();
        return response;
      } else {
        return liveChatAvFactoryE6.CreateUsingLivechat2(liveChatClient);
      }
      /* return overrideLogs
        ? liveChatAvFactoryE6.CreateUsingCustom(comm)
        : liveChatAvFactoryE6.CreateUsingLivechat2(liveChatClient); */
    } catch (ex) {
      console.error(
        "SdkHandler",
        "initiateSignaling",
        "fail to initiate signaling",
        ex
      );
      logger.error("SdkHandler", "initiateSignaling", ex.message);
    }
  };

  unSubscribeEvent = (subscriber, event) => {
    if (handlerMap[event] && handlerMap[event][subscriber]) {
      delete handlerMap[event][subscriber];
    }
  };

  subscribeEvent = (subscriber, event, handler) => {
    if (!handlerMap[event]) {
      handlerMap[event] = {};
    }
    handlerMap[event][subscriber] = handler;
    //handlerMap[event][subscriber] = [...handlerMap[event], handler];
  };

  openConnection = () => {
    let token = auth.getToken();

    if (token) {
      console.log(
        `%c [ Lib-Index liveChatClient open] - SessionId : ${auth.getSession()}`,
        "background: #222; color: #bada55; font-size:12px"
      );
      logger.info(
        "SdkHandler",
        "openConnection",
        `[ Lib-Index liveChatClient open] - SessionId : ${auth.getSession()}`
      );
      let result = liveChatClient.open();

      if (result) {
        globalFlags.__IS_SESSION_STARTED__ = true;
      }
      console.log(
        `%c [ Lib-Index liveChatClient onOpen ] - SessionId : ${auth.getSession()}, Data : ${result}`,
        "background: #000000; color: #ff3399"
      );
      logger.info(
        "SdkHandler",
        "openConnection",
        `[ Lib-Index liveChatClient onOpen ] - SessionId : ${auth.getSession()}, Data : ${result}`
      );
      return result;
    } else {
      console.log(
        `%c [ Lib-Index liveChatClient open-fail - NO TOKEN] - SessionId : ${auth.getSession()}`,
        "background: #222; color: #bada55; font-size:12px"
      );
      logger.info(
        "SdkHandler",
        "openConnection",
        `[ Lib-Index liveChatClient open-fail - NO TOKEN] - SessionId : ${auth.getSession()}`
      );
      return false;
    }
  };

  getApi = () => {
    return liveChatClient.api;
  };

  sendAppEvent = (message, to = null) => {
    console.log(
      `%c [ Lib-Index liveChatClient sendAppEvent ] - SessionId : ${auth.getSession()}, Data : ${JSON.stringify({
        message,
        to,
      })}`,
      "background: #222; color: #bada55; font-size:12px"
    );
    logger.info(
      "SdkHandler",
      "sendAppEvent",
      `[ Lib-Index liveChatClient sendAppEvent ] - SessionId : ${auth.getSession()}, Data : ${JSON.stringify({
        message,
        to,
      })}`
    );
    liveChatClient.sendAppEvent(JSON.stringify(message), `user:${to}`);
  };

  sendUserMessage = (message, to = null, externalId) => {
    console.log(
      `%c [ Lib-Index liveChatClient sendUserMessage ] - SessionId : ${auth.getSession()}, Data : ${JSON.stringify(
        { message, to }
      )}`,
      "background: #222; color: #bada55; font-size:12px"
    );
    logger.info(
      "SdkHandler",
      "sendUserMessage",
      `[ Lib-Index liveChatClient sendUserMessage ] - SessionId : ${auth.getSession()}, Data : ${JSON.stringify({
        message,
        to,
      })}`
    );
    liveChatClient.sendUserMessage(
      message,
      to,
      (sentId, sentStatus) => {
        console.log(
          `%c [ Lib-Index liveChatClient onMessageSentStatus ] - SessionId : ${auth.getSession()}, Data : ${JSON.stringify(
            { sentId, sentStatus }
          )}`,
          "background: #000000; color: #ff3399"
        );
        logger.info(
          "SdkHandler",
          "sendUserMessage",
          `[ Lib-Index liveChatClient onMessageSentStatus ] - SessionId : ${auth.getSession()}, Data : ${JSON.stringify(
            { sentId, sentStatus }
          )}`
        );

        if (handlerMap["onMessageSentStatus"]) {
          Object.keys(handlerMap["onMessageSentStatus"]).forEach((key) => {
            handlerMap["onMessageSentStatus"][key](sentId, sentStatus);
          });
        }
      },
      externalId
    );
  };

  sendMessageInGroup = (groupId, message) => {
    console.log(
      `%c [ Lib-Index liveChatClient sendMessageInGroup ] - SessionId : ${auth.getSession()}, Data : ${JSON.stringify(
        { groupId, message }
      )}`,
      "background: #222; color: #bada55; font-size:12px"
    );
    logger.info(
      "SdkHandler",
      "sendMessageInGroup",
      `[ Lib-Index liveChatClient sendMessageInGroup ] - SessionId : ${auth.getSession()}, Data : ${JSON.stringify(
        { groupId, message }
      )}`
    );
    liveChatClient.sendMessageInGroup(groupId, message);
  };

  createConference = (groupName, userIdList) => {
    return new Promise(function (resolve, reject) {
      liveChatClient.createConference(userIdList);
    });
  };

  createGroup = (name, createdBy, userIdList = [], description = "") => {
    console.log(
      `%c [ Lib-Index liveChatClient createGroup ] - SessionId : ${auth.getSession()}, Data : ${JSON.stringify({
        name,
        createdBy,
        userIdList,
        description,
      })}`,
      "background: #222; color: #bada55; font-size:12px"
    );
    logger.info(
      "SdkHandler",
      "createGroup",
      `[ Lib-Index liveChatClient createGroup ] - SessionId : ${auth.getSession()}, Data : ${JSON.stringify({
        name,
        createdBy,
        userIdList,
        description,
      })}`
    );
    liveChatClient.createGroup(name, userIdList, description);
  };

  updateInteractionActions = (interactionId, action) => {
    console.log(
      `%c [ Lib-Index liveChatClient updateInteractionActions ] - SessionId : ${auth.getSession()}, Data : ${JSON.stringify(
        { interactionId, action }
      )}`,
      "background: #222; color: #bada55; font-size:12px"
    );
    logger.info(
      "SdkHandler",
      "updateInteractionActions",
      `[ Lib-Index liveChatClient updateInteractionActions ] - SessionId : ${auth.getSession()}, Data : ${JSON.stringify(
        { interactionId, action }
      )}`
    );
    liveChatClient.updateInteractionActions(interactionId, action);
  };

  deleteGroup = (groupId) => {
    console.log(
      `%c [ Lib-Index liveChatClient deleteGroup ] - SessionId : ${auth.getSession()}, Data : ${JSON.stringify({
        groupId,
      })}`,
      "background: #222; color: #bada55; font-size:12px"
    );
    logger.info(
      "SdkHandler",
      "deleteGroup",
      `[ Lib-Index liveChatClient deleteGroup ] - SessionId : ${auth.getSession()}, Data : ${JSON.stringify({
        groupId,
      })}`
    );
    liveChatClient.deleteGroup(groupId);
  };

  modifyGroupName = (groupId, newGroupName) => {
    console.log(
      `%c [ Lib-Index liveChatClient modifyGroupName ] - SessionId : ${auth.getSession()}, Data : ${JSON.stringify(
        { groupId, newGroupName }
      )}`,
      "background: #222; color: #bada55; font-size:12px"
    );
    logger.info(
      "SdkHandler",
      "modifyGroupName",
      `[ Lib-Index liveChatClient modifyGroupName ] - SessionId : ${auth.getSession()}, Data : ${JSON.stringify({
        groupId,
        newGroupName,
      })}`
    );
    liveChatClient.modifyGroupName(groupId, newGroupName);
  };

  addUserToGroup = (groupId, userIdList) => {
    console.log(
      `%c [ Lib-Index liveChatClient addUserToGroup ] - SessionId : ${auth.getSession()}, Data : ${JSON.stringify(
        { groupId, userIdList }
      )}`,
      "background: #222; color: #bada55; font-size:12px"
    );
    logger.info(
      "SdkHandler",
      "addUserToGroup",
      `[ Lib-Index liveChatClient addUserToGroup ] - SessionId : ${auth.getSession()}, Data : ${JSON.stringify({
        groupId,
        userIdList,
      })}`
    );
    liveChatClient.addUserToGroup(groupId, userIdList);
  };

  makeUserAsAdmin = (groupId, userId) => {
    console.log(
      `%c [ Lib-Index liveChatClient makeUserAsAdmin ] - SessionId : ${auth.getSession()}, Data : ${JSON.stringify(
        { groupId, userId }
      )}`,
      "background: #222; color: #bada55; font-size:12px"
    );
    logger.info(
      "SdkHandler",
      "makeUserAsAdmin",
      `[ Lib-Index liveChatClient makeUserAsAdmin ] - SessionId : ${auth.getSession()}, Data : ${JSON.stringify({
        groupId,
        userId,
      })}`
    );
    liveChatClient.makeUserAsAdmin(groupId, userId);
  };

  dismissUserAsAdmin = (groupId, userId) => {
    console.log(
      `%c [ Lib-Index liveChatClient dismissUserAsAdmin ] - SessionId : ${auth.getSession()}, Data : ${JSON.stringify(
        { groupId, userId }
      )}`,
      "background: #222; color: #bada55; font-size:12px"
    );
    logger.info(
      "SdkHandler",
      "dismissUserAsAdmin",
      `[ Lib-Index liveChatClient dismissUserAsAdmin ] - SessionId : ${auth.getSession()}, Data : ${JSON.stringify(
        { groupId, userId }
      )}`
    );
    liveChatClient.dismissUserAsAdmin(groupId, userId);
  };

  removeUserFromGroup = (groupId, userIdList) => {
    console.log(
      `%c [ Lib-Index liveChatClient removeUserFromGroup ] - SessionId : ${auth.getSession()}, Data : ${JSON.stringify(
        { groupId, userIdList }
      )}`,
      "background: #222; color: #bada55; font-size:12px"
    );
    logger.info(
      "SdkHandler",
      "removeUserFromGroup",
      ` [ Lib-Index liveChatClient removeUserFromGroup ] - SessionId : ${auth.getSession()}, Data : ${JSON.stringify(
        { groupId, userIdList }
      )}`
    );
    liveChatClient.removeUserFromGroup(groupId, userIdList);
  };

  leaveGroup = (groupId, userId) => {
    console.log(
      `%c [ Lib-Index liveChatClient removeUserFromGroup ] - SessionId : ${auth.getSession()}, Data : ${JSON.stringify(
        { groupId, userId }
      )}`,
      "background: #222; color: #bada55; font-size:12px"
    );
    logger.info(
      "SdkHandler",
      "leaveGroup",
      `[ Lib-Index liveChatClient removeUserFromGroup ] - SessionId : ${auth.getSession()}, Data : ${JSON.stringify(
        { groupId, userId }
      )}`
    );
    liveChatClient.leaveGroup(groupId, userId);
  };

  getUserInteractionListForUser = (userIdList) => {
    console.log(
      `%c [ Lib-Index liveChatClient getUserInteractionListForUser ] - SessionId : ${auth.getSession()}, Data : ${JSON.stringify(
        { userIdList }
      )}`,
      "background: #222; color: #bada55; font-size:12px"
    );
    logger.info(
      "SdkHandler",
      "getUserInteractionListForUser",
      ` [ Lib-Index liveChatClient getUserInteractionListForUser ] - SessionId : ${auth.getSession()}, Data : ${JSON.stringify(
        { userIdList }
      )}`
    );
    liveChatClient.getUserInteractionListForUser(userIdList);
  };

  sendPresenceNotification = (content) => {
    console.log(
      `%c [ Lib-Index liveChatClient sendPresenceNotification ] - SessionId : ${auth.getSession()}, Data : ${JSON.stringify(
        { content }
      )}`,
      "background: #222; color: #bada55; font-size:12px"
    );
    logger.info(
      "SdkHandler",
      "sendPresenceNotification",
      `[ Lib-Index liveChatClient sendPresenceNotification ] - SessionId : ${auth.getSession()}, Data : ${JSON.stringify(
        { content }
      )}`
    );
    liveChatClient.sendPresenceNotification(content);
  };

  // message : {from: fromUserId, to: toUserId, ended_at: timestamp}
  leaveInteraction = (interactionId, reason, customData) => {
    console.log(
      `%c [ Lib-Index liveChatClient leaveInteraction ] - SessionId : ${auth.getSession()}, Data : ${JSON.stringify(
        { interactionId, reason, customData }
      )}`,
      "background: #222; color: #bada55; font-size:12px"
    );
    logger.info(
      "SdkHandler",
      "leaveInteraction",
      `[ Lib-Index liveChatClient leaveInteraction ] - SessionId : ${auth.getSession()}, Data : ${JSON.stringify({
        interactionId,
        reason,
        customData,
      })}`
    );
    liveChatClient.leaveInteraction(interactionId, reason, customData);
  };

  /*  createGroup = (name, createdBy, userIdList = [], description = "") => {
     console.log(`%c [ Lib-Index liveChatClient createGroup ] - Data : ${JSON.stringify({ name, createdBy, userIdList, description })}`, 'background: #222; color: #bada55; font-size:12px');
     liveChatClient.createGroup(name, createdBy, userIdList, description);
   }; */

  sendTypingState = (state, to) => {
    console.log(
      `%c [ Lib-Index liveChatClient sendTypingState ] - SessionId : ${auth.getSession()}, Data : ${JSON.stringify(
        { state, to }
      )}`,
      "background: #222; color: #bada55; font-size:12px"
    );
    logger.info(
      "SdkHandler",
      "sendTypingState",
      `[ Lib-Index liveChatClient sendTypingState ] - SessionId : ${auth.getSession()}, Data : ${JSON.stringify({
        state,
        to,
      })}`
    );
    liveChatClient.sendTypingState(state, to);
  };

  requestSnapshotCapture = (userId) => {
    console.log(
      `%c [ Lib-Index liveChatClient requestSnapshotCapture ] - SessionId : ${auth.getSession()}, Data : ${JSON.stringify(
        { userId }
      )}`,
      "background: #222; color: #bada55; font-size:12px"
    );
    logger.info(
      "SdkHandler",
      "requestSnapshotCapture",
      `[ Lib-Index liveChatClient requestSnapshotCapture ] - SessionId : ${auth.getSession()}, Data : ${JSON.stringify({
        userId
      })}`
    );
    liveChatClient.requestSnapshotCapture(userId);
  };

  requestSignatureCapture = (userId) => {
    console.log(
      `%c [ Lib-Index liveChatClient requestSignatureCapture ] - SessionId : ${auth.getSession()}, Data : ${JSON.stringify(
        { userId }
      )}`,
      "background: #222; color: #bada55; font-size:12px"
    );
    logger.info(
      "SdkHandler",
      "requestSignatureCapture",
      `[ Lib-Index liveChatClient requestSignatureCapture ] - SessionId : ${auth.getSession()}, Data : ${JSON.stringify({
        userId
      })}`
    );
    liveChatClient.requestSignatureCapture(userId);
  };

  sendReadRecipt = (content, to, from) => {
    console.log(
      `%c [ Lib-Index liveChatClient sendReadRecipt ] - SessionId : ${auth.getSession()}, Data : ${JSON.stringify(
        { content, to, from }
      )}`,
      "background: #222; color: #bada55; font-size:12px"
    );
    logger.info(
      "SdkHandler",
      "sendReadRecipt",
      `[ Lib-Index liveChatClient sendReadRecipt ] - SessionId : ${auth.getSession()}, Data : ${JSON.stringify({
        content,
        to,
        from,
      })}`
    );
    liveChatClient.sendReadRecipt(content, to, from);
  };

  sendCustomMessage = (type, jsonStr, to) => {
    console.log(
      `%c [ Lib-Index liveChatClient sendCustomMessage ] - SessionId : ${auth.getSession()}, Data : ${JSON.stringify(
        { type, jsonStr: maskProperties(jsonStr, ["customerInfo"]), to }
      )}`,
      "background: #222; color: #bada55; font-size:12px"
    );
    logger.info(
      "SdkHandler",
      "sendCustomMessage",
      `[ Lib-Index liveChatClient sendCustomMessage ] - SessionId : ${auth.getSession()}, Data : ${JSON.stringify(
        { type, jsonStr: maskProperties(jsonStr, ["customerInfo"]), to }
      )}`
    );
    liveChatClient.sendCustomMessage(type, jsonStr, to);
  };

  getUserGroupList = (userId, nextId) => {
    console.log(
      `%c [ Lib-Index liveChatClient getUserGroupList ] - SessionId : ${auth.getSession()}, Data : ${JSON.stringify(
        { userId, nextId }
      )}`,
      "background: #222; color: #bada55; font-size:12px"
    );
    logger.info(
      "SdkHandler",
      "getUserGroupList",
      `[ Lib-Index liveChatClient getUserGroupList ] - SessionId : ${auth.getSession()}, Data : ${JSON.stringify({
        userId,
        nextId,
      })}`
    );
    liveChatClient.getUserGroupList(nextId);
  };

  switchWorkspace = (authUrl, userName, refreshToken, newWorkspace) => {
    console.warn(
      `------------------------ calling Switch Workspace Method ${userName} ------------------------`
    );
    logger.warn(
      "SdkHandler",
      "switchWorkspace",
      `------------------------ calling Switch Workspace Method ${userName} ------------------------`
    );
    return new Promise((resolve, reject) => {
      try {
        liveChatClient.switchWorkspace(
          authUrl,
          userName,
          refreshToken,
          newWorkspace,
          (response, tokenInfo) => {
            if (response && tokenInfo) {
              auth.setToken(tokenInfo.token);
              auth.setWorkspace(tokenInfo.organization, userName);
              //this.startSession(true);
              if (handlerMap["onWorkspaceSwitched"]) {
                Object.keys(handlerMap["onWorkspaceSwitched"]).forEach((key) => {
                  handlerMap["onWorkspaceSwitched"][key]();
                });
              }
            }

            resolve({ isSuccess: response, tokenInfo });
          }
        );
      } catch (ex) {
        console.error("switchWorkspace", ex);
        reject(ex);
      }
    });
  };

  validateLoginSession = (session) => {
    console.warn(
      `------------------------ calling validateLoginSession ${session} ------------------------`
    );
    logger.warn(
      "SdkHandler",
      "validateLoginSession",
      `------------------------ calling validateLoginSession Method ${session} ------------------------`
    );
    return new Promise((resolve, reject) => {
      try {
        liveChatClient.validateLoginSession(iamAppId, session, config.iamUrl, config.ocmUsrUrl, (s, response) => {

          let token = auth.createToken(response);
          return token;

        }, config.ocmAccessFeature, (validated, result, token) => {
          if (validated) {
            console.info(
              `------------------------ Session Validated ${JSON.stringify({ validated, result, token })} ------------------------`
            );
            logger.info(
              "SdkHandler",
              "validateLoginSession",
              `------------------------ Session Validated ${JSON.stringify({ validated, result, token })} ------------------------`
            );
            this.startSession();
            resolve({ validated, result, token });

          } else {
            console.error(
              `------------------------ Session Validation Error ${JSON.stringify({ validated, result, token })} ------------------------`
            );
            logger.error(
              "SdkHandler",
              "validateLoginSession",
              `------------------------ Session Validation Error ${JSON.stringify({ validated, result, token })} ------------------------`
            );
            resolve({ validated, result, token });
          }

        })

      } catch (ex) {
        console.error(
          `------------------------ Session Validation Error ------------------------`, ex);
        logger.error(
          "SdkHandler",
          "validateLoginSession",
          `------------------------ Session Validation Error ------------------------`
        );
        reject(ex);
      }
    });
  };

  anonymousLogin = (displayName) => {

    return new Promise(async (resolve, reject) => {

      liveChatClient.login("", "", (response, authToken, tokenInfo) => {

        let userId = liveChatClient.getLoggedInUserId();

        if (response) {
          this.startSession();
        }
        resolve({
          isSuccess: response,
          token: authToken,
          tokenInfo,
          userId,
        });

      }, { displayname: displayName, isAnonymous: true, isVideo: true })

    });

  }

  loginToSystem = (userName, userPassword, extraParams) => {
    console.warn(
      `------------------------ calling SDK Login Method ${userName} ------------------------`
    );
    logger.warn(
      "SdkHandler",
      "loginToSystem",
      `------------------------ calling SDK Login Method ${userName} ------------------------`
    );

    let login = liveChatClient.login;

    let params = extraParams;
    if (__APP_IDENTITY_PROVIDER__ === "GUARDIAN") {
      login = liveChatClient.loginV2;
      params = { ...extraParams, authUrl };
    }

    return new Promise(async (resolve, reject) => {
      try {
        login(
          userName,
          userPassword,
          (response, authToken, tokenInfo) => {
            console.warn(
              `------------------------ Calling SDK startSession In Login Method Callback  ${userName} ------------------------`
            );
            logger.warn(
              "SdkHandler",
              "loginToSystem-login",
              `------------------------ Calling SDK startSession In Login Method Callback  ${userName} ------------------------`
            );
            if (response) {
              liveChatClient.setValidationAttributes({
                authType: __APP_AUTH_TYPE__,
              });

              if (__APP_IDENTITY_PROVIDER__ === "GUARDIAN") {
                auth.setRefreshToken(tokenInfo.refreshToken, userName);

                let newWorkspace = null;
                let officialOrg = null;

                let usernameSplit = userName.split('@');

                let mydomain = usernameSplit.length === 2 ? usernameSplit[1] : null;

                if (tokenInfo.organizations && tokenInfo.organizations.length > 0) {
                  officialOrg = tokenInfo.organizations.find(org => org.officialDomain === mydomain ? true : false);
                  newWorkspace = officialOrg ? officialOrg.workSpaceName : tokenInfo.organizations[0].workSpaceName;
                }
                /* let newWorkspace =
                  tokenInfo.organizations && tokenInfo.organizations.length > 0
                    ? tokenInfo.organizations[0].workSpaceName
                    : null; */

                liveChatClient.switchWorkspace(
                  authUrl,
                  userName,
                  tokenInfo.refreshToken,
                  newWorkspace,
                  (switchResponse, switchTokenInfo) => {
                    if (switchResponse && switchTokenInfo) {
                      auth.setToken(switchTokenInfo.token);
                      auth.setWorkspace(switchTokenInfo.organization, userName);

                      tokenInfo.token = switchTokenInfo.token;
                      tokenInfo.organization = switchTokenInfo.organization;

                      if (auth.isNeedToResetPassword(switchTokenInfo.token)) {
                        resolve({
                          isSuccess: switchResponse,
                          token: switchTokenInfo.token,
                          tokenInfo,
                          resetPassword: true,
                        });
                        return;
                      }
                      this.startSession();
                      if (officialOrg) {
                        auth.setOfficialDomainRequest(mydomain);
                      } else {
                        auth.setOfficialDomainRequest(null);
                      }
                      resolve({
                        isSuccess: switchResponse,
                        token: switchTokenInfo.token,
                        tokenInfo
                      });
                    } else {
                      /* const ex = new Error("Workspace switching error");
                      console.error(ex);
                      logger.error(
                        "SdkHandler",
                        "loginToSystem-login",
                        ex.message
                      );
                      reject(ex); */
                      auth.setToken(tokenInfo.token);
                      auth.setWorkspace(tokenInfo.organization, userName);

                      this.startSession();
                      if (officialOrg) {
                        auth.setOfficialDomainRequest(mydomain);
                      } else {
                        auth.setOfficialDomainRequest(null);
                      }

                      resolve({
                        isSuccess: true,
                        token: tokenInfo.token,
                        tokenInfo
                      });
                      
                    }
                  }
                );
              } else {
                let userId = liveChatClient.getLoggedInUserId();
                if (auth.isNeedToResetPassword(authToken)) {
                  resolve({
                    isSuccess: response,
                    token: authToken,
                    tokenInfo,
                    userId,
                    resetPassword: true,
                  });
                  return;
                }

                this.startSession();
                resolve({
                  isSuccess: response,
                  token: authToken,
                  tokenInfo,
                  userId,
                });
              }
            } else {
              reject(new Error("No response from login method"));
            }
          },
          params
        );
      } catch (ex) {
        console.error("loginToSystem", ex);
        logger.error("SdkHandler", "loginToSystem", ex.message);
        reject(ex);
      }
    });
  };

  logoutFromSystem = (connectivity, status, content) => {
    return new Promise((resolve, reject) => {
      try {
        // there is no method n sdk library
        if (__APP_IDENTITY_PROVIDER__ === "UAS") {
          let logoutMsg = `[ Lib-Index liveChatClient logoutFromSystem ] - Logging out from system - SessionId : ${auth.getSession()}`;
          console.log(`%c ${logoutMsg}`, "background: #222; color: #bada55; font-size:12px");
          logger.info("SdkHandler", "logoutFromSystem", logoutMsg);
          fetch(
            `${config.userUrl}/user-auth-service/api/v1/users/logout?reason=${status}`,
            {
              method: "post",
              headers: {
                "Content-Type": "application/json",
                authorization: auth.getToken(),
              },
            }
          )
            .then(function (response) {
              return response.json();
            })
            .then(function (data) {
              console.log(`log out success - SessionId : ${auth.getSession()}`);
              logger.info("SdkHandler", "logoutFromSystem", `log out success - SessionId : ${auth.getSession()}`);
              logger.closeLogger();
              resolve({ isSuccess: true });
            })
            .catch((error) => {
              console.error("Logout", error);
              logger.error("SdkHandler", "logoutFromSystem", error.message);
              reject(error);
            });
        } else {
          resolve({ isSuccess: true });
        }
      } catch (ex) {
        console.error("logoutFromSystem", ex);
        logger.error("SdkHandler", "logoutFromSystem", ex.message);
        reject(ex);
      }
    });
  };

  startSession = (override = false) => {
    let tempDetails = { name: "Test", address: "Test", override };
    console.log(
      `%c [ Lib-Index liveChatClient startSession ] - Data : ${JSON.stringify(
        tempDetails
      )}`,
      "background: #222; color: #bada55; font-size:12px"
    );
    logger.info(
      "SdkHandler",
      "startSession",
      `[ Lib-Index liveChatClient startSession ] - Data : ${JSON.stringify(
        tempDetails
      )}`
    );
    if (override) {
      liveChatClient.start(tempDetails, true);
    } else {
      liveChatClient.start(tempDetails);
    }
  };

  endSession = () => {
    console.log(
      `%c [ Lib-Index liveChatClient endSession] - SessionId : ${auth.getSession()}`,
      "background: #222; color: #bada55; font-size:12px"
    );
    logger.info(
      "SdkHandler",
      "endSession",
      `[ Lib-Index liveChatClient endSession] - SessionId : ${auth.getSession()}`
    );
    liveChatClient.end("logout");
  };

  sendConferenceRequest = (userId, interactionId, isChat, isCall) => {
    console.log(
      `%c [ Lib-Index liveChatClient sendConferenceRequest ] - SessionId : ${auth.getSession()}, Data : ${JSON.stringify(
        { userId, interactionId, isChat, isCall }
      )}`,
      "background: #222; color: #bada55; font-size:12px"
    );
    logger.info(
      "SdkHandler",
      "sendConferenceRequest",
      `[ Lib-Index liveChatClient sendConferenceRequest ] - SessionId : ${auth.getSession()}, Data : ${JSON.stringify(
        { userId, interactionId, isChat, isCall }
      )}`
    );
    liveChatClient.sendConferenceRequest(userId, interactionId, isChat, isCall);
  };

  sendInteractionTransferRequest = (userId, interactionId) => {
    console.log(
      `%c [ Lib-Index liveChatClient sendInteractionTransferRequest ] - SessionId : ${auth.getSession()}, Data : ${JSON.stringify(
        { userId, interactionId }
      )}`,
      "background: #222; color: #bada55; font-size:12px"
    );
    logger.info(
      "SdkHandler",
      "sendInteractionTransferRequest",
      `[ Lib-Index liveChatClient sendInteractionTransferRequest ] - SessionId : ${auth.getSession()}, Data : ${JSON.stringify(
        { userId, interactionId }
      )}`
    );
    liveChatClient.sendInteractionTransferRequest(userId, interactionId);
  };

  addedToOfficialWorkspace = (workspace) => {
    try {
      if (handlerMap["onAddOfficialWorkspace"] && workspace) {
        auth.addWorkspace(workspace);
        Object.keys(handlerMap["onAddOfficialWorkspace"]).forEach((key) => {
          handlerMap["onAddOfficialWorkspace"][key](workspace.workSpaceName);
        });
      }

    } catch (error) {
      console.error("SdkHandler", "addedToOfficialWorkspace", "fail to switch workspace after added to official account", error);
      logger.error("SdkHandler", "addedToOfficialWorkspace", "fail to switch workspace after added to official account");
    }

  }
}

const sdkHandler = new SdkHandler();
Object.freeze(sdkHandler);

export default sdkHandler;
