import React, { useState, useEffect } from "react";
import { Modal } from 'antd';
import { subscribeToEvents } from "../../../utils/eventEmitter";
import { TeamService } from "../../../service/teamService";
import { message } from "antd/lib/index";

const ProfileSearchModal = props => {

    const [modalStatus, setModalStatus] = useState({ state: false, profileInfo: {}, dynamicData: {}, contactDetails: {}, onSuccess: null });

    useEffect(() => {

        subscribeToEvents('show_profile_search_modal', ({ searchVal, dynamicData, contactDetails, onSuccess }) => {
            //call api
            TeamService.getAccountByPhoneNumber(searchVal).then(acc => {

                if (acc) {
                    setModalStatus((prevState) => { return { ...prevState, state: true, profileInfo: acc, dynamicData, contactDetails, onSuccess } });
                } else {
                    setModalStatus((prevState) => { return { ...prevState, state: false } });
                    message.error('No profile found for provided phone number');
                }

            }).catch(ex => {

                console.error(ex);
            })

        })

    }, []);

    /* let sendMessage = (uploadData, notificationData) => {

        eventEmitter.fireEvent("send_snapshot", { uploadData, notificationData, snapshotType: "SIGNATURE_SNAPSHOT" });
    } */


    return (
        <>
            {modalStatus.state && <Modal
                title="Profile Search Result"
                closable={false}
                visible={modalStatus.state}
                maskClosable={false}
                okText="Add Contact"
                cancelText="Cancel"
                okButtonProps={{ disabled: !modalStatus.profileInfo }}
                onOk={() => {
                    let contact = {
                        contactId: modalStatus.contactDetails.customerId,
                        contactType: modalStatus.contactDetails.smChannel,
                        display: modalStatus.contactDetails.customerName,
                        other: "",
                        userId: modalStatus.profileInfo.userId
                    }
                    TeamService.addContact(contact).then(result => {

                        if (result) {
                            let profile = { ...modalStatus.profileInfo, contacts: result }
                            modalStatus.onSuccess && modalStatus.onSuccess(profile);
                        }


                    }).catch(ex => {
                        console.error(ex);
                    })
                    setModalStatus((prevState) => { return { ...prevState, state: false } });
                }}
                confirmLoading={false}
                onCancel={() => {
                    setModalStatus((prevState) => { return { ...prevState, state: false } });
                }}
            >
                <div>{Object.keys(modalStatus.dynamicData).map(key => {
                    return (<div key={`ProfileInfoModal_${key}`} className="d-flex flex-column dynamic-info-wrapper">
                        <div className="u-title text-left"> {modalStatus.dynamicData[key]} :</div>
                        <div className="pl-1 u-value text-left"> {Boolean(modalStatus.profileInfo[key])
                            ? modalStatus.profileInfo[key].toString() : modalStatus.profileInfo[key]}</div>
                    </div>)
                }

                )}
                </div>
            </Modal>}
        </>
    );
};

export default React.memo(ProfileSearchModal);