import React from 'react';
import PropTypes from 'prop-types';
import { OnlineStatus, ProfileImage } from "../index";
import { EyeOff, Check } from 'react-feather';
import { __APP_INTERACTION_MODE__ } from '../../../config/baseUrls';


const ModalSelectUser = ({
    src,
    fullName,
    userType,
    firstLatter,
    status,
    icon,
    selected,
    disabled,
    onClick,
    ...props
}) => (
        <div key={props.key} onClick={disabled ? () => { } : onClick} className={`chat-user-wrapper select-user ${selected ? 'selected' : ''} ${disabled ? 'disabled' : ''}`}>
            <div className={`chat-user-inner-wrp modal-user  d-flex justify-content-between`}>
                <div className={`c-left d-flex`}>
                    <div className={`c-right d-flex align-items-center mr-3 `}>
                        {
                            !disabled ? (<div className={`selected-wrapper ${selected ? 'selected' : ''}`}>
                                <div className={`select d-flex align-items-center justify-content-center `}>
                                    <Check />
                                </div>
                            </div>) : <EyeOff />
                        }
                        {/* <div className={`selected-wrapper ${selected ? 'selected' : ''}`}>
                            <div className={`select d-flex align-items-center justify-content-center `}>
                                {icon}
                            </div>
                        </div> */}
                    </div>

                    <ProfileImage
                        status={status}
                        size={'md-s'}
                        type={'md-s'}
                        overrideStatusDisable={props.overrideStatusDisable}
                        firstLatter={fullName ? fullName.split(' ').map(fullName => fullName[0]).join('').toUpperCase() : ""}
                        alt={firstLatter}
                        src={src}
                        fontWeight="800"
                        fontSize="0.8rem" />
                    <div className={`user-content-wrp ${selected ? 'selected' : ''}`}>
                        <div className={'c-full-name'}>
                            {fullName}
                        </div>

                        <div className={'c-last-msg'}>
                            {`${userType ? userType.charAt(0).toUpperCase() + userType.slice(1) : ""} ${__APP_INTERACTION_MODE__ === 'INTERACTION' && props.escalated !== undefined ? `- (chats : ${props.chats} , calls : ${props.escalated})` : ""}`}
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );

ModalSelectUser.propTypes = {
    fullName: PropTypes.string.isRequired,
    userType: PropTypes.string.isRequired,
};

ModalSelectUser.defaultProps = {
    userType: 'Agent'
};

export default ModalSelectUser;
