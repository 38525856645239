
export const validateEvents =
  window.env.REACT_APP_DATA_PHONE_CONFIG_VALIDATE_EVENTS == true ||  window.env.REACT_APP_DATA_PHONE_CONFIG_VALIDATE_EVENTS === "true" ||  false;

const getMediaTransport = () => {
  const tempMedia = {
    iceServers: [
      {
        urls: "turn:3.233.143.234:3478?transport=tcp",
        username: "tetherfi",
        credential: "tetherfi",
      },
    ],
    iceTransportPolicy: window.env.REACT_APP_DATA_PHONE_CONFIG_MEDIA_TRANSPORT_ICE_SERVERS_ICE_TRANSPORT_POLICY ||     "all",
  };

  try {
    if (window.env.REACT_APP_DATA_PHONE_CONFIG_MEDIA_TRANSPORT_ICE_SERVERS) {
      const servers = window.env.REACT_APP_DATA_PHONE_CONFIG_MEDIA_TRANSPORT_ICE_SERVERS.split("||");
      let iceServers = [];
      servers.map((item) => {
        if (item) {
          const server = item.split(";");
          if (server) {
            iceServers.push({
              urls: server[0],
              username: server[1],
              credential: server[2],
            });
          }
        }
      });
      tempMedia.iceServers = iceServers;
    }
  } catch (error) {
    console.error("getMediaTransport", error);
  }
  return tempMedia;
};
export const MediaTransport = getMediaTransport();

export const MediaTransport_old = {
  iceServers: [
    {
      urls:window.env.REACT_APP_DATA_PHONE_CONFIG_MEDIA_TRANSPORT_ICE_SERVERS_URLS ||"turn:3.233.143.234:3478?transport=tcp",
      username: window.env.REACT_APP_DATA_PHONE_CONFIG_MEDIA_TRANSPORT_ICE_SERVERS_USER_NAME || "tetherfi",
      credential: window.env.REACT_APP_DATA_PHONE_CONFIG_MEDIA_TRANSPORT_ICE_SERVERS_CREDENTIAL || "tetherfi",
    },
  ],
  iceTransportPolicy: window.env.REACT_APP_DATA_PHONE_CONFIG_MEDIA_TRANSPORT_ICE_SERVERS_ICE_TRANSPORT_POLICY ||    "all",
};

export const MediaCapture = {
  audio: {
    // Refer to https://www.w3.org/TR/mediacapture-streams/#dom-mediatrackconstraintset
    // for all values supported by this section and detailed meaning.
    enabled:      window.env.REACT_APP_DATA_PHONE_CONFIG_MEDIA_CAPTURE_AUDIO_ENABLED == true || window.env.REACT_APP_DATA_PHONE_CONFIG_MEDIA_CAPTURE_AUDIO_ENABLED === "true" || true,
    /* autoGainControl: true,
      noiseSuppression: true,
      echoCancellation: true */
  },
  video: {
    // Refer to https://www.w3.org/TR/mediacapture-streams/#dom-mediatrackconstraintset
    // for all values supported by this section and detailed meaning.
    enabled: window.env.REACT_APP_DATA_PHONE_CONFIG_MEDIA_CAPTURE_VIDEO_ENABLED == true || window.env.REACT_APP_DATA_PHONE_CONFIG_MEDIA_CAPTURE_VIDEO_ENABLED === "true" || true,
    /* width: 640,
      height: 480,
      frameRate: 30.0,
      deviceId: "IntegratedWebCam_1"
      aspectRatio: 1.333 // vga standard 
       */
    
     /*  width: { min: 640, ideal: 1920, max: 1920 },
      height: { min: 400, ideal: 1080 },
      aspectRatio: 1.777777778,
      frameRate: { max: 30 },
      facingMode: { exact: "user" } */
      
  },
};
export const Signaling = {
  // Refer to https://www.w3.org/TR/webrtc/#dom-rtcofferoptions for all values
  // supported by this section and detailed meaning.
  iceRestart: window.env.REACT_APP_DATA_PHONE_CONFIG_SIGNALING_ICE_RESTART == true ||  window.env.REACT_APP_DATA_PHONE_CONFIG_SIGNALING_ICE_RESTART === "true" || true,
};
export const Network = {
  Bandwidth: {
    // The max kbps for a single audio track (integer between 6 - 64)
    SingleAudioTrackLimit: window.env.REACT_APP_DATA_PHONE_CONFIG_NETWORK_BANDWIDTH_SINGLE_AUDIO_TRACK_LIMIT || 32,

    // The max kbps for a single video track (integer between 128 - 2048)
    SingleVideoTrackLimit: window.env.REACT_APP_DATA_PHONE_CONFIG_NETWORK_BANDWIDTH_SINGLE_VIDEO_TRACK_LIMIT || 2048,

    // The max kbps for all video and audio tracks (integer) 8192
    CumelativeSessionLimit: window.env.REACT_APP_DATA_PHONE_CONFIG_NETWORK_BANDWIDTH_CUMELATIVE_SESSION_LIMIT || 8192, //8192
  },
  CdcAutoSelection: window.env.REACT_APP_DATA_PHONE_CONFIG_NETWORK_CDC_AUTO_SELECTION == true || window.env.REACT_APP_DATA_PHONE_CONFIG_NETWORK_CDC_AUTO_SELECTION === "true" || true,
};
export const VoiceActivity = {
  AudioLevelCheckEnabled: window.env.REACT_APP_DATA_PHONE_CONFIG_VOICE_ACTIVITY_AUDIO_LEVEL_CHECK_ENABLED == true || window.env.REACT_APP_DATA_PHONE_CONFIG_VOICE_ACTIVITY_AUDIO_LEVEL_CHECK_ENABLED === "true" || false,
  AudioVideoLevelThreshold:window.env.REACT_APP_DATA_PHONE_CONFIG_VOICE_ACTIVITY_AUDIO_VIDEO_LEVEL_THRESHOLD || 0.31,
  EventTriggeredDuration: window.env.REACT_APP_DATA_PHONE_CONFIG_VOICE_ACTIVITY_EVENT_TRIGGERED_DURATION || 2,
};

export const isAllowedVideoCall =  window.env.REACT_APP_DATA_PHONE_CONFIG_IS_ALLOWED_VIDEO_CALL == true || window.env.REACT_APP_DATA_PHONE_CONFIG_IS_ALLOWED_VIDEO_CALL === "true" || false;

export const basic_config = {
  sdk: {
    overrideLogs:window.env.REACT_APP_DATA_PHONE_CONFIG_BASIC_CONFIG_SDK_OVERRIDE_LOGS === true || window.env.REACT_APP_DATA_PHONE_CONFIG_BASIC_CONFIG_SDK_OVERRIDE_LOGS === "true" || false,
    virtual_background_feature_activate : window.env.REACT_APP_DATA_PHONE_CONFIG_BASIC_CONFIG_SDK_VIRTUAL_BACKGROUND_FEATURE_ACTIVATE === true || window.env.REACT_APP_DATA_PHONE_CONFIG_BASIC_CONFIG_SDK_VIRTUAL_BACKGROUND_FEATURE_ACTIVATE === "true" || false,    
    edge_blur_amount : window.env.REACT_APP_DATA_PHONE_CONFIG_BASIC_CONFIG_SDK_EDGE_BLUR_AMOUNT || 3,
    use_virtual_background_configurations: window.env.REACT_APP_DATA_PHONE_CONFIG_BASIC_CONFIG_SDK_USE_VIRTUAL_BACKGROUND_CONFIGURATIONS === true || window.env.REACT_APP_DATA_PHONE_CONFIG_BASIC_CONFIG_SDK_USE_VIRTUAL_BACKGROUND_CONFIGURATIONS === "true" || false,    
    virtual_background_configurations :{      
      flipHorizontal: window.env.REACT_APP_DATA_PHONE_CONFIG_BASIC_CONFIG_SDK_FLIP_HORIZONTAL || false,
      internalResolution: window.env.REACT_APP_DATA_PHONE_CONFIG_BASIC_CONFIG_SDK_INTERNAL_RESOLUTION || 'medium', // 'low', 'medium', 'high' or a positive number
      segmentationThreshold: window.env.REACT_APP_DATA_PHONE_CONFIG_BASIC_CONFIG_SDK_SEGMENTATION_THRESHOLD || 0.6,
      maxDetections: window.env.REACT_APP_DATA_PHONE_CONFIG_BASIC_CONFIG_SDK_MAX_DETECTIONS || 1,
      scoreThreshold: window.env.REACT_APP_DATA_PHONE_CONFIG_BASIC_CONFIG_SDK_SCORE_THRESHOLD || 0.2
    }
  },
  calls: {
    hangup_call_after_chat_end: window.env.REACT_APP_DATA_PHONE_CONFIG_BASIC_CONFIG_CALLS_HANGUP_CALL_AFTER_CHAT_END == true || window.env.REACT_APP_DATA_PHONE_CONFIG_BASIC_CONFIG_CALLS_HANGUP_CALL_AFTER_CHAT_END === "true" || false,
    end_chat_after_call_hangup: window.env.REACT_APP_DATA_PHONE_CONFIG_BASIC_CONFIG_CALLS_END_CHAT_AFTER_CALL_HANGUP == true || window.env.REACT_APP_DATA_PHONE_CONFIG_BASIC_CONFIG_CALLS_END_CHAT_AFTER_CALL_HANGUP === "true" || false,
    auto_answer: {
      active: window.env.REACT_APP_DATA_PHONE_CONFIG_BASIC_CONFIG_CALLS_AUTO_ANSWER_ACTIVE == true || window.env.REACT_APP_DATA_PHONE_CONFIG_BASIC_CONFIG_CALLS_AUTO_ANSWER_ACTIVE ==="true" ||false,
      everyCall:window.env.REACT_APP_DATA_PHONE_CONFIG_BASIC_CONFIG_CALLS_AUTO_ANSWER_EVERY_CALL ==true || window.env.REACT_APP_DATA_PHONE_CONFIG_BASIC_CONFIG_CALLS_AUTO_ANSWER_EVERY_CALL ==="true" ||false,
      ringtone:window.env.REACT_APP_DATA_PHONE_CONFIG_BASIC_CONFIG_CALLS_AUTO_ANSWER_RINGTONE ||"ringtone-07",
      auto_answer_delayed:window.env.REACT_APP_DATA_PHONE_CONFIG_BASIC_CONFIG_CALLS_AUTO_ANSWER_AUTO_ANSWER_DELAYED ||5,
      hide_reject_button_with_auto_answer : window.env.REACT_APP_DATA_PHONE_CONFIG_BASIC_CONFIG_CALLS_AUTO_ANSWER_HIDE_REJECT_BUTTON_WITH_AUTO_ANSWER === "true" || window.env.REACT_APP_DATA_PHONE_CONFIG_BASIC_CONFIG_CALLS_AUTO_ANSWER_HIDE_REJECT_BUTTON_WITH_AUTO_ANSWER === true || false,
    },
    selfView :{
      active:window.env.REACT_APP_NAV_CALLS_SELF_VIEW_ACTIVE === true || window.env.REACT_APP_NAV_CALLS_SELF_VIEW_ACTIVE === "true" ||false,
      preload :window.env.REACT_APP_NAV_CALLS_SELF_VIEW_PRELOAD === true || window.env.REACT_APP_NAV_CALLS_SELF_VIEW_PRELOAD === "true" ||false,
      on_call_alert :window.env.REACT_APP_NAV_CALLS_SELF_VIEW_ON_CALL_ALERT === true || window.env.REACT_APP_NAV_CALLS_SELF_VIEW_ON_CALL_ALERT === "true" ||false,
      on_call_connecting :window.env.REACT_APP_NAV_CALLS_SELF_VIEW_ON_CALL_CONNECTING === true || window.env.REACT_APP_NAV_CALLS_SELF_VIEW_ON_CALL_CONNECTING === "true" ||false,
      on_call_ringing :window.env.REACT_APP_NAV_CALLS_SELF_VIEW_ON_CALL_RINGING === true || window.env.REACT_APP_NAV_CALLS_SELF_VIEW_ON_CALL_RINGING === "true" ||false,
    },
    button_swap_audio_to_video : window.env.REACT_APP_DATA_PHONE_CONFIG_BASIC_CONFIG_BUTTON_SWAP_AUDIO_TO_VIDEO === true || window.env.REACT_APP_DATA_PHONE_CONFIG_BASIC_CONFIG_BUTTON_SWAP_AUDIO_TO_VIDEO === "true" ||false,   
  },
  console: {
    open_external_app: window.env.REACT_APP_DATA_PHONE_CONFIG_BASIC_CONFIG_CONSOLE_OPEN_EXTERNAL_APP ==true ||window.env.REACT_APP_DATA_PHONE_CONFIG_BASIC_CONFIG_CONSOLE_OPEN_EXTERNAL_APP ==="true" || false, // open external crm
    invoke_external_api_with_session_end:window.env.REACT_APP_DATA_PHONE_CONFIG_BASIC_CONFIG_CONSOLE_INVOKE_EXTERNAL_API_WITH_SESSION_END ||false, // call external api after session end
    playNotificationSound:window.env.REACT_APP_DATA_PHONE_CONFIG_BASIC_CONFIG_CONSOLE_PLAY_NOTIFICATION_SOUND === "true" ||window.env.REACT_APP_DATA_PHONE_CONFIG_BASIC_CONFIG_CONSOLE_PLAY_NOTIFICATION_SOUND == true || false,
    loadConfigFromBackend : window.env.REACT_APP_DATA_PHONE_CONFIG_BASIC_CONFIG_CONSOLE_LOAD_CONFIG_FROM_BACKEND === "true" ||window.env.REACT_APP_DATA_PHONE_CONFIG_BASIC_CONFIG_CONSOLE_LOAD_CONFIG_FROM_BACKEND == true || false,
    deviceChangeEventTriggeredDuration: window.env.REACT_APP_DATA_PHONE_CONFIG_BASIC_CONFIG_CONSOLE_DEVICE_CHANGE_EVENT_TRIGGERED_DURATION || 3,
    mediaDevice:{
      active:window.env.REACT_APP_DATA_PHONE_CONFIG_BASIC_CONFIG_CONSOLE_MEDIA_DEVICE_ACTIVE === "true"||window.env.REACT_APP_DATA_PHONE_CONFIG_BASIC_CONFIG_CONSOLE_MEDIA_DEVICE_ACTIVE === true|| false,
      auto_logout_active:window.env.REACT_APP_DATA_PHONE_CONFIG_BASIC_CONFIG_CONSOLE_MEDIA_DEVICE_AUTO_LOGOUT_ACTIVE === "true"||window.env.REACT_APP_DATA_PHONE_CONFIG_BASIC_CONFIG_CONSOLE_MEDIA_DEVICE_AUTO_LOGOUT_ACTIVE === true|| false,
      error_count:window.env.REACT_APP_DATA_PHONE_CONFIG_BASIC_CONFIG_CONSOLE_MEDIA_DEVICE_ERROR_COUNT ||3,
      log_out_after :window.env.REACT_APP_DATA_PHONE_CONFIG_BASIC_CONFIG_CONSOLE_MEDIA_DEVICE_LOG_OUT_AFTER ||30,
      title:window.env.REACT_APP_DATA_PHONE_CONFIG_BASIC_CONFIG_CONSOLE_MEDIA_DEVICE_TITLE ||"Attention!",
      subTitle:window.env.REACT_APP_DATA_PHONE_CONFIG_BASIC_CONFIG_CONSOLE_MEDIA_DEVICE_SUBTITLE ||"Media Device Not Found",
      description:window.env.REACT_APP_DATA_PHONE_CONFIG_BASIC_CONFIG_CONSOLE_MEDIA_DEVICE_DESCRIPTION ||"You will be automatically logged out in"
    },
    workspace_details :{
      workspace_id :window.env.REACT_APP_DATA_PHONE_CONFIG_BASIC_CONFIG_CONSOLE_MEDIA_WORKSPACE_DETAILS_WORKSPACE_ID || -10
    }
  },
};

export const media_error_codes = {  
  errorCodes: [1,3025,3003,3005,1001,3006,3007,3008,3009,4003],
  errorMessage : {
     /*    1 :{message : "Call disconnected due to network issues" , title:"Soft Phone"},
        3025 : {message : "Required Media device is not found/attached on the system.", title:"Media Device"},
      
        3003 : {message : "Required Media device is not found/attached on the system.", title:"Media Device"},
        3005 : {message : "Required Media device is not found/attached on the system.", title:"Media Device"},
        1001 : {message : "System error encountered.", title:"Soft Phone"},
        3006 : {message : "Required Media device is not found/attached on the system.", title:"Media Device"},
        3007 : {message : "Required Media device is not found/attached on the system.", title:"Media Device"},
        3008 : {message : "Required Media device is not found/attached on the system.", title:"Media Device"},
        3009 : {message : "Required Media device is not found/attached on the system.", title:"Media Device"},
      
        4003 : {message : "Max concurrent call sessions allowed for your license reached", title:"Soft Phone"}, */


        
        1 :{message : "Call disconnected due to network issues" , title:"Soft Phone"},
        3025 : {message : "You don't have permission to get video call", title:"Media Device"},
      
        /**
	      * An error or failure that doesn't match any of the other exceptions below occurred
        * ABORT_ERROR: 3003,
	      */
        3003 : {message : "Although the user and operating system both granted access to the hardware device, some problem occurred which prevented the device from being used.", title:"Media Device"},
        
        /**
         * Permission to access a screen area was denied by the user, 
         * or the current browsing instance is not permitted access to screen sharing.
         * NOT_ALLOWED_ERROR: 3005,
         */	
        3005 : {message : "Permission to access a media devices was denied by the user or the current browsing instance is not permitted access to media devices", title:"Media Device"},
        /**
        * A error due to system state or system not supporting a mandatory feature.
        * If this error is present during screenshare, it indicates screensharing is not supported on the browser.
        * SYSTEM_ERROR: 1001,  
        */	
        1001 : {message : "A error due to system state or system not supporting a mandatory feature.", title:"Media Device"},
        /**
        * No sources of screen video are available for capture.
        * NOT_FOUND_ERROR: 3006,        */
      
        3006 : {message : "No sources of media device are available for capture.", title:"Media Device"},
        /**
         * The user selected a screen, window, tab, or other source of screen data, 
         * but a hardware or operating system level error or lockout occurred, 
         * preventing the sharing of the selected source.
         * NOT_READABLE_ERROR: 3007,
        */
        3007 : {message : "hardware or operating system level error or lockout occurred, preventing the sharing of the selected source", title:"Media Device"},
        /**
	      * After creating the stream, applying the specified constraints fails because no compatible stream could be generated.
        * OVERCONSTRAINED_ERROR: 3008,
	      */
        3008 : {message : "applying the specified constraints fails because no compatible stream could be generated", title:"Media Device"},
        /**
        * The specified constraints include constraints which are not permitted when calling getDisplayMedia(). 
        * These unsupported constraints are advanced and any constraints which in turn have a member named min or exact.
        * TYPE_ERROR: 3009,
        */
        3009 : {message : "not permitted access specific media device", title:"Media Device"},
        /**
	      * Call failed due to licence limit reached or failure for licence validation.
        * LICENSE_VALIDATION_FAILED: 4003
	      */
        4003 : {message : "Max concurrent call sessions allowed for your license reached", title:"Soft Phone"}, 
  },
  mediaDeviceSpecificErrorCodes: [3003,3005,1001,3006,3007,3008,3009],
}
