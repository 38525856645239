import React, { useState, useEffect, useCallback } from "react";
import { ButtonRounded, DeviceNotSupported } from "../../common";
import { Spin } from "antd";
import { message } from "antd/lib/index";
import ScrollAnimation from "react-animate-on-scroll";

import "./styles/user-landing.scss";
import "./styles/forgotpwd-responsive.scss";

import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import AuthService from "../../../service/authService.js";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import { EyeOff, Eye } from "react-feather";
import navigationRestrictions from "../../../config/navigationRestrictions";
import * as auth from "../../../utils";
import WorkspaceService from "../../../service/workspaceService";

const authService = new AuthService();
const workspaceService = new WorkspaceService();
let isUpdated = false;

const ResetPassword = (props) => {
  const { register, handleSubmit, errors, watch } = useForm();
  let location = useLocation();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [isLoading, setIsLoading] = useState(false);
  /* const [password, setPassword] = useState();
  const [confirmPassword, setConfirmPassword] = useState(); */
  const [username, setUsername] = useState();
  const [inputType, setInputType] = useState("password");
  const [inputConType, setInputConType] = useState("password");
  const [isLoadingConfig, setIsLoadingConfig] = useState(true);
  const [passwordPolicy, setPasswordPolicy] = useState(auth.getPasswordPolicy());
  
  const getPasswordPolicy = async () => {
    try {
      if (navigationRestrictions.login.password_policy.active !== "enable") {
        return;
      }
      setIsLoadingConfig(true);
      const token = await executeRecaptcha("SignInComponent");

      const policy = await workspaceService.getPasswordPolicy("sddsfs", token);
      if (policy) {
        setPasswordPolicy(policy);
        auth.setPasswordPolicy(policy);
      }
    } catch (error) {
      console.error("SignInComponent", error);
    } finally {
      setIsLoadingConfig(false);
    }
  };
  

  const validateRequest = useCallback(async (uname,token) => {
    try {      
      if(executeRecaptcha === undefined )
        return;

      if(isUpdated === true){
        return;
      }
      getPasswordPolicy();
      const rToken = await executeRecaptcha("resetPassword");
      const reply = await authService.changePasswordRequestValidation(
        token,
        uname,
        rToken
      );
      if (!reply.isSuccess) {
        console.error("FirstLogin", "Invalid request",isUpdated);
        message.error("Invalid request");
        props.history.push("/");
      }
    } catch (error) {
      console.error("ResetPassword", "validateRequest", error);
      message.error("Unable to validate request");
      props.history.push("/");
    }
  });

  const updatePassword = async (formData) => {
    try {
      isUpdated = true;
      setIsLoading(true);
      const token = await executeRecaptcha("forgotPassword");
      const reply = await authService.changePassword(
        token,
        username,
        formData.password
      );
      if (reply.isSuccess) {
        props.history.push({
          pathname: "/auto_login",
          state: { detail: { username, password: formData.password ,isVideo:true} },
        });
      } else {
        setIsLoading(false);
        message.error(reply.error_code === 412 ? 'Not allowed to reuse old passwords.': `Fail to Update Password`);
      }
    } catch (error) {
      setIsLoading(false);
      console.error("ChangePasswordComponent", "setPassword", error);
      message.error(`Fail to Update Password`);
    }
  };
  

  useEffect(() => {
    const params = new Map(location.search.slice(1).split("&").map((kv) => kv.split("=")));
      const uname = params.get("username");
      const token = params.get("token");
      if (uname && token) {
        setUsername(uname);
        validateRequest(uname,token);    
      } else {
        message.error("Invalid request");
        props.history.push("/");
      }
      return () => {
        
        console.log( "ResetPassword" , "cleaned up");
      };
    
  }, [validateRequest]);

  return (
    <>
      <DeviceNotSupported />
      <section className={`main-login-wrapper sign-up`}>
        <div className={"top-left-leaf-bg"}>
          <img
            src={require("../../../assets/img/bg/login/topRightLeaf.svg")}
            alt="User Avatar"
          />
        </div>

        {/*====== login from main wrapper start here ========*/}
        <div className={"container"}>
          <div className={"login-layout-wrp  d-flex justify-content-between"}>
            {/*======== login form start here ========*/}
            <div className="sign-up-form-main-wrapper ">
              <div className="sign-up-form-inner-wrapper">
                <div>
                  <div className={`sign-up-form`}>
                    {/*<div className={'sign-up-character'}>*/}
                    {/*    <img src={require("../../../assets/img/bg/login/MainCharacter.svg")}/>*/}
                    {/*</div>*/}
                    <ScrollAnimation
                      delay="150"
                      animateIn="fadeInUp"
                      animateOut="fadeOutDown"
                    >
                      <div className={`login-header-wrapper mt-5 text-center`}>
                        <h2>Enter your password</h2>
                      </div>
                    </ScrollAnimation>

                    <div className={"sign-in-body-wrapper mt-5"}>
                      <form onSubmit={handleSubmit(updatePassword)}>
                        <div className="form-group password_input_container">
                          <label htmlFor="exampleInputEmail1">
                            Password <span>*</span>
                          </label>
                          <input
                            minLength={passwordPolicy.minLength}
                            maxLength={passwordPolicy.maxLength}
                            name="password"
                            ref={register({
                              required: {
                                value: true,
                                message: "Password is required",
                              },
                              minLength: {
                                value: passwordPolicy.minLength,
                                message:`Password must be at least ${passwordPolicy.minLength} characters`,
                              },
                              maxLength: {
                                value: passwordPolicy.maxLength,
                                message:`Password must be less than ${passwordPolicy.maxLength} characters`,
                              },
                              pattern: {
                                value: passwordPolicy["passwordPolicy"]
                                  ? new RegExp(
                                      passwordPolicy["passwordPolicy"],
                                      "i"
                                    )
                                  : null,
                                message: `Password must be a valid pattern. ${passwordPolicy["policyNames"]}`,
                              },
                            })}
                            type={inputType}
                            className="form-control form-control-lg txt-login"
                            placeholder="************"
                          />
                          {inputType === "password" ? (
                            <EyeOff
                              className="user_password_input_img"
                              onClick={() => setInputType("text")}
                            />
                          ) : (
                            <Eye
                              className="user_password_input_img"
                              onClick={() => setInputType("password")}
                            />
                          )}
                          {errors.password && (
                            <div className={"error-text"}>
                              <small style={{ color: "red" }}>
                                {errors.password.message}
                              </small>
                            </div>
                          )}
                        </div>

                        <div className="form-group password_input_container">
                          <label htmlFor="exampleInputEmail1">
                            Confirm Password <span>*</span>
                          </label>
                          <input
                            minLength={passwordPolicy.minLength}
                            maxLength={passwordPolicy.maxLength}
                            name="confirmPassword"
                            ref={register({
                              required: {
                                value: true,
                                message: "Confirm password is required",
                              },                             
                              validate: {
                                match: (value) =>
                                  value === watch("password") ||
                                  "The passwords do not match",
                              },
                            })}
                            type={inputConType}
                            className="form-control form-control-lg txt-login"
                            placeholder="************"
                          />
                          {inputConType === "password" ? (
                            <EyeOff
                              className="user_password_input_img"
                              onClick={() => setInputConType("text")}
                            />
                          ) : (
                            <Eye
                              className="user_password_input_img"
                              onClick={() => setInputConType("password")}
                            />
                          )}
                          {errors.confirmPassword && (
                            <div className={"error-text"}>
                              <small style={{ color: "red" }}>
                                {errors.confirmPassword.message}
                              </small>
                            </div>
                          )}
                        </div>

                        <div className={`login-button-wrapper`}>
                          <div className={`login-bnt-hide`}>
                            <div className={"mr-2"}>
                              {isLoading ? (
                                <Spin />
                              ) : (
                                <button
                                  disabled={isLoadingConfig}
                                  type="submit"
                                  class="btn btn-rounded btn-lg btn-block btn-primary"
                                >
                                  SET PASSWORD
                                </button>
                              )}
                            </div>
                          </div>
                          {/*<div className={'skip'}*/}
                          {/*     onClick={onPressBack}>*/}
                          {/*    Back*/}
                          {/*</div>*/}
                        </div>
                      </form>

                      <div className={"login-footer-wrp"}>
                        {/*<div className={'version'}>*/}
                        {/*    version <span>1.0.4</span>*/}
                        {/*</div>*/}
                        <div className={"rights version-text"}>
                          © 2020 tetherfi, All rights reserved.
                        </div>
                      </div>
                    </div>
                    <div className={"login-form-bg-sm"} />
                  </div>

                  <div className={"login-form-bg-lg hide-mobile"} />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={"login-footer-bg-wrapper d-flex"}>
          <div className={"left-plant"}>
            <img
              src={require("../../../assets/img/bg/login/footerPlantLeft.svg")}
              alt="User Avatar"
            />
          </div>
          <div className={"transparent-plant"}>
            <img
              src={require("../../../assets/img/bg/login/transparentPlant.svg")}
              alt="User Avatar"
            />
          </div>
          <div className={"left-single-plant"}>
            <img
              src={require("../../../assets/img/bg/login/singlePlant.svg")}
              alt="User Avatar"
            />
          </div>

          <div className={"right-plant"}>
            <img
              src={require("../../../assets/img/bg/login/rightPlant.svg")}
              alt="User Avatar"
            />
          </div>
        </div>

        <div className={"sky-bg"}>
          <img
            src={require("../../../assets/img/bg/login/skyBg.svg")}
            alt="User Avatar"
          />
        </div>

        <div className={"left-round-wrp"} />
        <div className={"top-bg-round-wrp"} />

        <div className={"stars-wrapper"}>
          <img
            src={require("../../../assets/img/bg/login/stars.svg")}
            alt="User Avatar"
          />
        </div>
        <div className={"stars-wrapper-2"}>
          <img
            src={require("../../../assets/img/bg/login/starsOnly.svg")}
            alt="User Avatar"
          />
        </div>
      </section>
    </>
  );
};

export default ResetPassword;
