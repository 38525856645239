import React from 'react';
import PropTypes from 'prop-types';
import {OnlineStatus, ProfileImage} from "../index";


const UserImage = ({src, fullName, size, type, activeUser}) => (
    <>
        <div className={`caller-user ${size} ${type} ${activeUser ? 'active-me' : 'hide-me'}`}>
            {src &&
            <img alt={fullName.substring(1, 0)} src={src}/>
            }
            {src === null &&
            <div className={'default-img-text '}>
                {fullName.substring(1, 0)}
            </div>
            }
        </div>
    </>
);

UserImage.propTypes = {
    src: PropTypes.string.isRequired,
    fullName: PropTypes.string.isRequired,
};

UserImage.defaultProps = {
    src: null,
    firstLatter: 'A',
    fullName: 'aa'
};

export default UserImage;
