export const info = async (className, methodName, message) => {
  try {
    if(window.env.REACT_APP_CONSOLE_LOG_ENABLE)
    window.uiLoggerSdk.LogInfo(className, methodName, message);
  } catch (error) {
    console.error("logger", "info", error);
  }
};

export const debug = async (className, methodName, message) => {
  try {
    if(window.env.REACT_APP_CONSOLE_LOG_ENABLE)
    window.uiLoggerSdk.LogDebug(className, methodName, message);
  } catch (error) {
    console.error("logger", "debug", error);
  }
};

export const warn = async (className, methodName, message) => {
  try {
    if(window.env.REACT_APP_CONSOLE_LOG_ENABLE)
    window.uiLoggerSdk.LogWarn(className, methodName, message);
  } catch (error) {
    console.error("logger", "warn", error);
  }
};

export const fatal = async (className, methodName, message) => {
  try {
    if(window.env.REACT_APP_CONSOLE_LOG_ENABLE)
    window.uiLoggerSdk.LogFatal(className, methodName, message);
  } catch (error) {
    console.error("logger", "fatal", error);
  }
};

export const error = async (className, methodName, message) => {
  try {
    if(window.env.REACT_APP_CONSOLE_LOG_ENABLE)
    window.uiLoggerSdk.LogError(className, methodName, message);
  } catch (error) {
    console.error("logger", "error", error);
  }
};

export const log = async (type, className, methodName, message) => {
  try {
    if(!window.env.REACT_APP_CONSOLE_LOG_ENABLE)
      return;

    switch (type) {
      case "error":
        window.uiLoggerSdk.LogError(className, methodName, message);
        break;
      case "fatal":
        window.uiLoggerSdk.LogFatal(className, methodName, message);
        break;
        case "warn":
          window.uiLoggerSdk.LogWarn(className, methodName, message);
        break;
        case "debug":
          window.uiLoggerSdk.LogDebug(className, methodName, message);
        break;
        case "info":
          window.uiLoggerSdk.LogInfo(className, methodName, message);
        break;
      default:
        break;
    }
  } catch (error) {
    console.error("logger", "log", error);
  }
};

export const closeLogger = async () => {
  try {
    window.uiLoggerSdk.CloseLogger();
  } catch (error) {
    console.error("logger", "CloseLogger", error);
  }
};
