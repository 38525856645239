import React from 'react';
import PropTypes from 'prop-types';
import { MenuIcon, ProfileImage } from "../index";
import { Headphones, Link, MoreVertical } from "react-feather";
import { Popover } from "antd";

const content = ({ onClickReplayMessage, onClickForwardMessage, onClickOpenGallery, msgObj, sendForwardMessages, onClickStar }) => (
    <div className={'chat-hover-menu'}>
        <div
            className={'document-row'}>Reply
        </div>
        <div className={'document-row'}
            onClick={() => onClickForwardMessage((users) => {
                sendForwardMessages(users, msgObj);

            })}>
            Forward message
        </div>
        <div className={'document-row'} onClick={() => onClickStar(msgObj, false)}>Unstar Message</div>
        {/*{msgObj.media && msgObj.media.length > 0 &&*/}
        {/*<div className={'document-row'} onClick={() => onClickOpenGallery(msgObj.media)}>View</div>}*/}
    </div>
);

const StarredMessageV2 = ({
    date,
    UserUrl,
    fullName,
    starredDate,
    messageType,
    msg,
    onClickReplayMessage,
    onClickForwardMessage,
    onClickOpenGallery,
    msgObj,
    sendForwardMessages,
    onClickStar,
    onClickContent
}) => (
        <div className={`starred-message-wrapper`}>
            <div className={'starred-header-wrp d-flex align-items-center  justify-content-between'}>
                {/*header */}
                <div className={'message-owner-details d-flex align-items-center'}>
                    <div className={'img-wrp'}>
                        <div>
                            <ProfileImage
                                textType={'sm'}
                                size={'sm'}
                                src={null}
                                firstLatter={fullName ? fullName.split(' ').map(fullName => fullName[0]).join('').toUpperCase() : ""}
                            />
                        </div>
                    </div>
                    <div className={'owner-name'}>
                        {fullName}
                    </div>
                </div>
                <div className={'starred-date-wrp d-flex align-items-center'}>
                    <>
                        {date}
                    </>
                    <>
                        <Popover
                            content={content({
                                onClickReplayMessage,
                                onClickForwardMessage,
                                onClickOpenGallery,
                                msgObj,
                                sendForwardMessages,
                                onClickStar
                            })}
                            trigger="hover">
                            <div className={'hover-more star-message ml-1'}>
                                <MenuIcon
                                    icon={<MoreVertical />} />
                            </div>
                        </Popover>
                    </>
                </div>
            </div>

            {/*body audio-clip / video-clip */}
            <div className={'starred-message-body-wrapper d-flex'}>
                <div className={'start-message-type file-starred '}>
                    {/*<img className={'pdf-starred'} src={require("../../../assets/img/pdf.svg")}*/}
                    {/*     alt="User Avatar" />*/}
                    <Link />
                </div>
                <div className={'started-message-text d-flex flex-column  '}>
                    <div className={'started-title '}>
                        Lorem Ipsum is simply dummy text of..
                </div>

                </div>

            </div>
        </div>
    );

StarredMessageV2.propTypes = {};

StarredMessageV2.defaultProps = {};

export default StarredMessageV2;
