import React from 'react';
import './styles/co-browsing-modal.scss';
const CoBrowsingItem = (props) => {

    let icon = "link.svg";
    return (
        <>
            <div className={'cob-select-item-wrapper d-flex justify-content-center align-items-center'} onClick={props.onClick}>
                <p><img src={require(`../../../assets/img/${icon}`)}
                    alt="User Avatar" /></p>
                <p>{props.menuTitle}</p>
            </div>

        </>
    )
};
export default CoBrowsingItem;
