import * as types from "./actionTypes";

export function setTyping(typingInfo) {
    return { type: types.SET_TYPING, typingInfo };
}

export function setACWTyping(typingInfo) {
    return { type: types.SET_ACW_TYPING, typingInfo };
}

export function deleteTyping(typingInfo) {
    return { type: types.DELETE_TYPING, typingInfo };
}

export function removeTyping(typingInfo) {
    return { type: types.REMOVE_TYPING, typingInfo };
}