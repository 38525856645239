import * as types from "./actionTypes";
import * as ChatService from "../../service/chatService";
import * as chatActions from "./chatActions";

export function resetSearchMessages() {
    return { type: types.RESET_SEARCH_MESSAGES };
}

export function getAfterSearchMessages(type, from, to, timestamp, limit) {

    return (dispatch, getState) => {
        ChatService.getSearchAfterMessages(type, from, to, timestamp, limit)
            .then(chats => {
                if (chats.messages && Object.keys(chats.messages).length === 1) {
                    let state = getState();
                    let last_mid = state.chat_search_messages.messages[Object.keys(state.chat_search_messages.messages)[Object.keys(state.chat_search_messages.messages).length - 1]].mid;
                    dispatch(chatActions.setLastSeenAnchorPoint(to, last_mid));
                    dispatch(resetSearchMessages());
                } else {
                    let obj = {
                        messages: chats.messages,
                        next_timestamp: chats.next_timestamp,
                        search_anchor_point: chats.search_anchor_point
                    }
                    dispatch(getAfterSearchMessagesSuccess(obj));
                }

            }).catch(ex => {
                console.log(ex);
            });
    }
}

export function starUnstarSearchSuccess(mid, is_starred) {
    return { type: types.STAR_UNSTAR_SEARCH_MESSAGE_SUCCESS, mid, is_starred };
}

export function getAfterSearchMessagesSuccess(chats) {
    return { type: types.SEARCH_AFTER_MESSAGES_SUCCESS, chats };
}

export function getInitialSearchMessages(type, from, to, timestamp, limit) {

    return dispatch => {
        ChatService.getInitialSearchMessages(type, from, to, timestamp, limit)
            .then(chats => {
                let obj = {
                    messages: chats.messages,
                    next_timestamp: chats.next_timestamp,
                    previous_timestamp: chats.previous_timestamp,
                    search_anchor_point: chats.search_anchor_point
                }
                dispatch(getInitialSearchMessagesSuccess(obj));
            }).catch(ex => {
                console.log(ex);
            });
    }
}

export function getInitialSearchMessagesSuccess(chats) {
    return { type: types.SEARCH_INITIAL_MESSAGES_SUCCESS, chats };
}



export function getBeforeSearchMessages(type, from, to, timestamp, limit) {

    return dispatch => {
        ChatService.getSearchBeforeMessages(type, from, to, ++timestamp, limit)
            .then((chats) => {
                let obj = {
                    messages: chats.messages,
                    previous_timestamp: chats.previous_timestamp,
                    search_anchor_point: chats.search_anchor_point
                }
                dispatch(getBeforeMessagesSuccess(obj));
            }).catch(ex => {
                console.log(ex);
            });
    }
}

export function getBeforeMessagesSuccess(chats) {
    return { type: types.SEARCH_BEFORE_MESSAGES_SUCCESS, chats };
}



