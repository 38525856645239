import React from 'react';
import PropTypes from 'prop-types';


const ImageThumbnail = ({src, onClickChangePreview, active, caption}) => (
    <div className={`gallery-thumbnail-wrp image ${active ? 'active' : ''} `}
         onClick={onClickChangePreview}>
        <img
            src={src}/>

        <div className={'caption-wrp'}>
            {caption}
        </div>
    </div>
);


ImageThumbnail.defaultProps = {};

export default ImageThumbnail;
