import React, {  useEffect ,useCallback} from "react";
import { useLocation } from "react-router-dom";
import { message } from "antd/lib/index";
import WorkspaceService from "../../../service/workspaceService";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
const workspaceService = new WorkspaceService();

const Invitations = (props) => {
  let location = useLocation();
  const { executeRecaptcha } = useGoogleReCaptcha();

  const Validating = useCallback(async () => {
    try {
      
      if(executeRecaptcha === undefined)
        return;

      const params = new Map( location.search.slice(1).split("&").map((kv) => kv.split("=")));

      const workspaceName = params.get("workspaceName");
      const memberId = params.get("memberId");
      const workspaceId = params.get("workspaceId");
      const username = params.get("username");

      if (workspaceName && memberId && workspaceId && username) {
        let token = await executeRecaptcha("Invitations");     
        const response = await workspaceService.validateInvitation(workspaceId,  memberId,token );
        if (response.isSuccess) {
          if (response.result.userid) {
            token = await executeRecaptcha("Invitations");     
            await workspaceService.updateInvitation( workspaceId, memberId,  response.result.userid,token  );
            setTimeout(() => {
              props.history.push("/sign-in");
            }, 1000);
          } else {
            props.history.push({ pathname: "/register", state: { detail: { workspaceName, memberId, workspaceId, username }, }, });
          }
        } else {
          console.log("Try To Create Profile Without Invitation");
          message.error("Try To Create Profile Without Invitation.");
          props.history.push("/sign-in");
        }
      } else {
        console.log("Try To Create Profile Without Invitation");
        message.error("Try To Create Profile Without Invitation.");
        props.history.push("/sign-in");
      }
    } catch (error) {
      console.error("Invitations", "Validating", error);
      props.history.push("/sign-in");
    }
  });

  useEffect(() => {
    Validating();
  }, [Validating]);

  return (
    <>
      <section className={`main-login-wrapper`}>
        <div className={"top-left-leaf-bg"}>
          <img
            src={require("../../../assets/img/bg/login/topRightLeaf.svg")}
            alt="User Avatar"
          />
        </div>

        {/*====== login from main wrapper start here ========*/}
        <div className={"container"}>
          <div className={"login-layout-wrp d-flex justify-content-between"}>
            <div className="sign-up-form-main-wrapper ">
              <div className="sign-up-form-inner-wrapper">
                <div className={`sign-up-form`}>
                  <div className={"character-wrp error-page text-center"}>
                    <img
                      src={require("../../../assets/img/bg/login/MainCharacter.svg")}
                      alt="User Avatar"
                    />
                  </div>
                  <div className={"sign-in-body-wrapper text-center"}>
                    <div className={"text-wrapper connectivityStatusMsg "}>
                      Please wait while we trying to validate invitation...
                    </div>
                    <div className={"pre-loader-login mt-5"}>
                      <div className="loader9" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={"login-footer-bg-wrapper d-flex"}>
          <div className={"left-plant"}>
            <img
              src={require("../../../assets/img/bg/login/footerPlantLeft.svg")}
              alt="User Avatar"
            />
          </div>
          <div className={"transparent-plant"}>
            <img
              src={require("../../../assets/img/bg/login/transparentPlant.svg")}
              alt="User Avatar"
            />
          </div>
          <div className={"left-single-plant"}>
            <img
              src={require("../../../assets/img/bg/login/singlePlant.svg")}
              alt="User Avatar"
            />
          </div>

          <div className={"right-plant"}>
            <img
              src={require("../../../assets/img/bg/login/rightPlant.svg")}
              alt="User Avatar"
            />
          </div>
        </div>

        <div className={"sky-bg"}>
          <img
            src={require("../../../assets/img/bg/login/skyBg.svg")}
            alt="User Avatar"
          />
        </div>

        <div className={"left-round-wrp"} />
        <div className={"top-bg-round-wrp"} />

        <div className={"stars-wrapper"}>
          <img
            src={require("../../../assets/img/bg/login/stars.svg")}
            alt="User Avatar"
          />
        </div>
        <div className={"stars-wrapper-2"}>
          <img
            src={require("../../../assets/img/bg/login/starsOnly.svg")}
            alt="User Avatar"
          />
        </div>
      </section>
    </>
  );
};

export default Invitations;
