import { Logger } from './logger.js';

class Connection {

  endPoint;
  callerObj;
  trace;

  constructor (endPoint, callerObj) {
    this.endPoint = endPoint;
    this.callerObj = callerObj;
    this.trace = [];
    this.socket = null;
    this.url = "";
  }

  connect = () => {
    if (this.endPoint != null && this.endPoint !== "")
      this.url = this.endPoint + `?token=`;

    if (this.callerObj != null) {
      if (this.callerObj.session.auth != undefined)
        this.url = this.url + this.callerObj.session.auth;

      this.attributes = this.callerObj.session.validationAttributes;

      if (this.attributes[ "sessionId" ] != null)
        this.url = this.url + `&sessionId=${this.attributes[ "sessionId" ]}`;

      if (this.attributes[ "authType" ] != null)
        this.url = this.url + `&authType=${this.attributes[ "authType" ]}`;

      if (this.callerObj.session.sessionID != "")
        this.url = this.url + `&sid=${this.callerObj.session.sessionID}`;
    }

    if (this.url != null && this.url !== "") {
      Logger.info(`Connecting to Websocket URL: ${this.url}`);
      this.socket = new WebSocket(this.url);

      this.mid = 1000;
      this.bEnded = false;

      this.socket.onopen = evt => {
        Logger.info(`Websocket Connection OnOpen Event`);
        this.trace.push([ "open" ]);
        this.callerObj.__onConnectOrReconnect();
      };

      this.socket.onmessage = evt => {
        Logger.info(`Websocket Connection OnMessage Event: ${JSON.stringify(evt.data)}`);
        try {
          this.trace.push([ "in", evt.data ]);
          this.callerObj.__onStrMessage(evt.data);
        }
        catch (e) {
          Logger.error(`Error Ocurred in OnMessage Event`, e);
        }
      };

      this.socket.onerror = evt => {
        Logger.info(`Websocket Connection OnError Event`);
      };

      this.socket.onclose = evt => {
        Logger.info(`Websocket Connection OnClose Event \n Code: ${evt.code} \n wasClean: ${evt.wasClean} \n reason: ${evt.reason}`);
        this.trace.push([ "close" ]);

        if (this.callerObj != null)
          this.callerObj.__onDisconnect(evt.reason);
      };
    }
  }

  canSend = () => {
    if (this.socket == null || this.socket.readyState !== 1)
      return false;
    else
      return true;
  }

  hasEnded = () => {
    return this.bEnded;
  }

  sendMessage = message => {
    if (this.socket == null || this.socket.readyState !== 1) {
      Logger.warn(`Socket is not connected, unable to send message: ${message}`)
      return false;
    }

    Logger.debug(`Sending message via websocket: ${message}`);
    this.trace.push([ "out", message ]);
    this.socket.send(message);

    return true;
  }

  end = reason => {
    try {
      Logger.info(`Closing Websocket Connection with reason: ${reason}`)
      this.bEnded = true;

      if (this.socket != null) { //Check if the socket connection is initialized
        this.socket.close(1000, reason);
        this.socket = null;
      }

      this.endPoint = "";
      this.callerObj = null;
      this.url = "";
    } catch (e) {
      Logger.error(`Error in end`, e);
    }
  }
}

export { Connection };