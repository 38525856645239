import { fileServiceUrl, fileMetaServiceUrl } from "../config/baseUrls";
import { fetchDefaults } from "./fetchWrapper";
import * as auth from "../utils";
import axios from "axios";
import { audioTypes, videoTypes, documentTypes, imageTypes } from "../config/json/fileTypeMap";

var apiFetch = fetchDefaults(fetch);

export const uploadFilePromise = (
  file,
  refId,
  conversationId,
  cancelTokenSource,
  uploadStatusHandler,
  type
) => {
  return new Promise((resolve, reject) => {

    let config = {
      headers: {
        Authorization: auth.getBearerToken()
      }
    };

    if (uploadStatusHandler) {
      config.onUploadProgress = function (progressEvent) {
        uploadStatusHandler(
          refId,
          Math.round((progressEvent.loaded * 100) / progressEvent.total)
        );
      }
    }

    if (cancelTokenSource) {
      config.cancelToken = cancelTokenSource.token;
    }

    let fd = new FormData();
    fd.append("file", file);
    fd.append("organization_id", "tetherfisl");
    fd.append("interaction_id", refId);
    fd.append("uploaded_by", auth.getUserId());
    fd.append("conv_id", conversationId);
    fd.append("mimetype", type);

    axios
      .post(`${fileServiceUrl}/upload`, fd, config)
      .then(response => {
        if (response.data.isSuccess) {
          response.data.result.contentType = type;
          resolve(response);
        } else {
          let rejectFileObj = {
            data: {
              isSuccess: false,
              result: {
                contentType: type,
                interaction_id: refId
              }
            }
          }
          resolve(rejectFileObj);

        }

      })
      .catch(error => {
        let rejectFileObj = {
          data: {
            isSuccess: false,
            result: {
              contentType: type,
              interaction_id: refId
            }
          }
        }
        resolve(rejectFileObj);
        console.log(error);
      });

  });

};

export const uploadFile = (
  file,
  refId,
  conversationId,
  cancelTokenSource,
  uploadStatusHandler,
  onUploadEnded, token = null
) => {
  let config = {
    headers: {
      Authorization: auth.getBearerToken()
    }
  };

  if (uploadStatusHandler) {
    config.onUploadProgress = function (progressEvent) {
      uploadStatusHandler(
        refId,
        Math.round((progressEvent.loaded * 100) / progressEvent.total)
      );
    }
  }

  
  if (cancelTokenSource) {
    config.cancelToken = cancelTokenSource.token;
  }

  let fd = new FormData();
  fd.append("file", file);
  fd.append("organization_id", "tetherfisl");
  fd.append("interaction_id", refId);
  fd.append("uploaded_by", auth.getUserId());
  fd.append("conv_id", conversationId);

  let postUrl = `${fileServiceUrl}/upload`;
  if (token)
    postUrl = `${fileServiceUrl}/upload_signup?g-recaptcha-response=${token}`;

  axios
    .post(postUrl, fd, config)
    .then(response => {
      onUploadEnded(response);
    })
    .catch(error => {
      onUploadEnded({ data: { isSuccess: false } });
      console.log(error);
    });
};

export const getRecentFilesForUser = (offset, limit) => {
  return new Promise((resolve, reject) => {
    let url = `${fileMetaServiceUrl}/meta/uploader/${auth.getUserId()}?skip=${offset}&take=${limit}`;

    fetch(url, {
      method: "GET",
      headers: {
        "authorization": auth.getBearerToken(),
        "Content-Type": "application/json",
        "tenant-token": auth.getTenantToken()
      }
    })
      .then(res => {
        return res.json();
      })
      .then(data => {
        if (data && data.isSuccess) {
          resolve(data.result);
        } else {
          reject(new Error("Error occurred"));
        }
      })
      .catch(ex => {
        reject(ex);
      });
  });
};

export const saveLink = (from, to, url, workspace, uniqueId) => {
  let bodyData = {
    from,
    to,
    url,
    workspace,
    interaction_id: uniqueId
  };
  return new Promise((resolve, reject) => {
    let url = `${fileServiceUrl}/meta/link`;

    fetch(url, {
      method: "POST",
      headers: {
        "authorization": auth.getBearerToken(),
        "Content-Type": "application/json"
      },
      body: JSON.stringify(bodyData)
    })
      .then(res => {
        return res.json();
      })
      .then(data => {
        if (data && data.isSuccess) {
          resolve(data.result);
        } else {
          reject(new Error("Error occurred"));
        }
      })
      .catch(ex => {
        reject(ex);
      });
  });
};

export const tagConversationToFile = (from, to, workspace, file_id, url, contentType, size, filename) => {
  let bodyData = { from, to, workspace, file_id, url, contentType, size, filename };
  return new Promise((resolve, reject) => {
    let url = `${fileServiceUrl}/meta/conversation/tag`;

    fetch(url, {
      method: "POST",
      headers: {
        "authorization": auth.getBearerToken(),
        "Content-Type": "application/json"
      },
      body: JSON.stringify(bodyData)
    })
      .then(res => {
        return res.json();
      })
      .then(data => {
        if (data && data.isSuccess) {
          resolve(data.result);
        } else {
          resolve(null);
        }
      })
      .catch(ex => {
        resolve(null);
      });
  });
};

export const getRecentFilesForConversation = (from, to, offset, limit, type) => {
  let fileTypes = [];
  if (type === "MEDIA") {
    fileTypes = [...videoTypes, ...audioTypes, ...imageTypes];
  } else if (type === "DOCUMENT") {
    fileTypes = documentTypes;
  }
  return new Promise((resolve, reject) => {
    let url = `${fileMetaServiceUrl}/meta/media?from=${from}&to=${to}&skip=${offset}&take=${limit}`;

    fetch(url, {
      method: "POST",
      headers: {
        "authorization": auth.getBearerToken(),
        "Content-Type": "application/json"
      },
      body: JSON.stringify({ fileTypes })
    })
      .then(res => {
        return res.json();
      })
      .then(data => {
        if (data && data.isSuccess) {
          resolve(data.result);
        } else {
          reject(new Error("Error occurred"));
        }
      })
      .catch(ex => {
        reject(ex);
      });
  });
};

export const getRecentLinksForConversation = (from, to, offset, limit) => {

  return new Promise((resolve, reject) => {
    let url = `${fileMetaServiceUrl}/meta/links?from=${from}&to=${to}&skip=${offset}&take=${limit}`;

    fetch(url, {
      method: "GET",
      headers: {
        "authorization": auth.getBearerToken(),
        "Content-Type": "application/json"
      }
    })
      .then(res => {
        return res.json();
      })
      .then(data => {
        if (data && data.isSuccess) {
          resolve(data.result);
        } else {
          reject(new Error("Error occurred"));
        }
      })
      .catch(ex => {
        reject(ex);
      });
  });
};

export const directDownloadFile = (fileName, url) => {

  var xhr = new XMLHttpRequest();
  xhr.open("GET", url, true);
  xhr.responseType = "blob";
  xhr.onload = function () {
    var urlCreator = window.URL || window.webkitURL;
    var imageUrl = urlCreator.createObjectURL(this.response);
    var tag = document.createElement('a');
    tag.href = imageUrl;
    tag.download = fileName;
    document.body.appendChild(tag);
    tag.click();
    document.body.removeChild(tag);
  }
  xhr.send();
};

export const downloadFile = (fileId, fileName) => {

  var xhr = new XMLHttpRequest();
  xhr.open("GET", `${fileMetaServiceUrl}/download/media/${fileId}`, true);
  xhr.responseType = "blob";
  xhr.onload = function () {
    var urlCreator = window.URL || window.webkitURL;
    var imageUrl = urlCreator.createObjectURL(this.response);
    var tag = document.createElement('a');
    tag.href = imageUrl;
    tag.download = fileName;
    document.body.appendChild(tag);
    tag.click();
    document.body.removeChild(tag);
  }
  xhr.send();
};
