import React from 'react';
import PropTypes from 'prop-types';
import {Headphones, Video} from 'react-feather';

const MediaThumbnail = ({medialType}) => (
    <>
        {medialType === 'video' &&
        <div className={'multiple-video d-flex justify-content-center align-items-center '}>
            <Video/>
        </div>
        }
        {medialType === 'audio' &&
        <div className={'multiple-video d-flex justify-content-center align-items-center '}>
            <Headphones/>
        </div>
        }
    </>
);


MediaThumbnail.defaultProps = {};

export default MediaThumbnail;
