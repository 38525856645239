import React from 'react';
import moment from 'moment-timezone';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as meetingActions from "../../../redux/actions/meetingActions";
import * as auth from "../../../utils";
import { MeetingSDK } from '../../../lib/MeetingsSdk';
import TodayEvents from './TodayEvents';
import Events from './Events';
import { message } from "antd/lib/index";
import {
    RefreshCw
} from "react-feather";
import { meetingCreatorLevel } from '../../../config/baseUrls';
import navigationRestrictions from '../../../config/navigationRestrictions';

let calendar = null;

const _meetingSDK = new MeetingSDK();

let daysMap = {
    "0": "Sunday",
    "1": "Monday",
    "2": "Tuesday",
    "3": "Wednesday",
    "4": "Thursday",
    "5": "Friday",
    "6": "Saturday"
}

class MeetingDashboard extends React.Component {

    state = {
        todaySchedules: [],
        selectedDate: moment().format('YYYY-MM-DD'),
        monthlyEvents: [],
        weeklyEvents: [],
        dailyEvents: [],
        allEvents: [],
        isViewAll: false
    }

    constructor(props) {
        super(props);
        this.getMonthlyEvents = this.getMonthlyEvents.bind(this);
        this.getWeeklyEvents = this.getWeeklyEvents.bind(this);
        this.getDailyEvents = this.getDailyEvents.bind(this);
        this.getFutureEvents = this.getFutureEvents.bind(this);

        this.getMonthlyEvents();
        this.getWeeklyEvents();
        this.getDailyEvents(moment().format('YYYY-MM-DD'));
    }

    onRefresh = () => {

        this.getMonthlyEvents();
        this.getWeeklyEvents();
        this.getDailyEvents(moment().format('YYYY-MM-DD'));

        let startDateUTCMoment = moment(`${moment().format("YYYY-MM-DD")} 00:00`).utc();
        let endDateUTCMoment = moment(`${moment().format("YYYY-MM-DD")} 23:59`).utc();
        /* let fromDate = moment(new Date()).utc().format("YYYY-MM-DD");
        let toDate = moment(new Date()).utc().format("YYYY-MM-DD"); */
        try {

            _meetingSDK.getMeetingsByDateAndParticipant(auth.getUserId(), startDateUTCMoment.format('YYYY-MM-DD'), endDateUTCMoment.format('YYYY-MM-DD'),
                startDateUTCMoment.format('HH:mm'), endDateUTCMoment.format('HH:mm'))
                .then(result => {
                    let meetings = result.scheduleMeetings;
                    this.setState({ todaySchedules: meetings });
                    let meetingIds = result.scheduleMeetings.map(meeting => meeting.id);
                    this.props.meeting_actions.getMeetingStatuses(meetingIds);

                }).catch(e => {
                    console.error(e);
                });
        } catch (error) {
            console.error(error);
        }

    }

    onDeleteMeeting = (meetingId) => {
        let delTodaySchedules = this.state.todaySchedules.filter(sch => sch.id !== meetingId);
        this.setState({ todaySchedules: delTodaySchedules });

        let delMonthlyEvents = this.state.monthlyEvents.filter(sch => sch.id !== meetingId);
        this.setState({ monthlyEvents: delMonthlyEvents });

        let delWeeklyEvents = this.state.weeklyEvents.filter(sch => sch.id !== meetingId);
        this.setState({ weeklyEvents: delWeeklyEvents });

        let delDailyEvents = this.state.dailyEvents.filter(sch => sch.id !== meetingId);
        this.setState({ dailyEvents: delDailyEvents });

        let delAllEvents = this.state.allEvents.filter(sch => sch.id !== meetingId);
        this.setState({ allEvents: delAllEvents });

    }

    getDailyEvents = (date) => {
        let startDateUTCMoment = moment(`${date} 00:00`).utc();
        let endDateUTCMoment = moment(`${date} 23:59`).utc();
        /* let startDT = moment(date).format('YYYY-MM-DD');momentTz(`${this.state.startDate} ${this.state.startTime}`).tz("UTC").format('HH:mm');
        let endDate = moment(date).format('YYYY-MM-DD'); */
        try {
            _meetingSDK.getMeetingsByDateAndParticipant(auth.getUserId(), startDateUTCMoment.format('YYYY-MM-DD'), endDateUTCMoment.format('YYYY-MM-DD'),
                startDateUTCMoment.format('HH:mm'), endDateUTCMoment.format('HH:mm'))
                .then(result => {
                    let meetings = result.scheduleMeetings;
                    this.setState({ dailyEvents: meetings, isViewAll: false });
                }).catch(e => {
                    console.log(e);
                });
        } catch (error) {
            console.log(error);
        }
    }

    getFutureEvents = () => {
        let startDateUTCMoment = moment(`${this.state.selectedDate} 00:00`).utc();
        let endDateUTCMoment = moment(`2090-12-31 23:59`).utc();
        /* let startDT = moment(this.state.selectedDate).format('YYYY-MM-DD');
        let endDate = moment("2030-12-31").format('YYYY-MM-DD'); */
        try {
            _meetingSDK.getMeetingsByDateAndParticipant(auth.getUserId(), startDateUTCMoment.format('YYYY-MM-DD'), endDateUTCMoment.format('YYYY-MM-DD'),
                startDateUTCMoment.format('HH:mm'), endDateUTCMoment.format('HH:mm'))
                .then(result => {
                    let meetings = result.scheduleMeetings;
                    this.setState({ dailyEvents: meetings, isViewAll: true });
                }).catch(e => {
                    console.log(e);
                });
        } catch (error) {
            console.log(error);
        }
    }

    getMonthlyEvents = () => {
        let startOfMonth = moment().clone().startOf('month').format('YYYY-MM-DD');
        let endOfMonth = moment().clone().endOf('month').format('YYYY-MM-DD');

        let startDateUTCMoment = moment(`${startOfMonth} 00:00`).utc();
        let endDateUTCMoment = moment(`${endOfMonth} 23:59`).utc();
        try {
            _meetingSDK.getMeetingsByDateAndParticipant(auth.getUserId(), startDateUTCMoment.format('YYYY-MM-DD'), endDateUTCMoment.format('YYYY-MM-DD'),
                startDateUTCMoment.format('HH:mm'), endDateUTCMoment.format('HH:mm'))
                .then(result => {
                    let meetings = result.scheduleMeetings;
                    this.setState({ monthlyEvents: meetings });
                }).catch(e => {
                    console.log(e);
                });
        } catch (error) {
            console.log(error);
        }
    }

    getWeeklyEvents = () => {
        let weekStart = moment().clone().startOf('isoWeek').format('YYYY-MM-DD');
        let weekEnd = moment().clone().endOf('isoWeek').format('YYYY-MM-DD');

        let startDateUTCMoment = moment(`${weekStart} 00:00`).utc();
        let endDateUTCMoment = moment(`${weekEnd} 23:59`).utc();
        try {
            _meetingSDK.getMeetingsByDateAndParticipant(auth.getUserId(), startDateUTCMoment.format('YYYY-MM-DD'), endDateUTCMoment.format('YYYY-MM-DD'),
                startDateUTCMoment.format('HH:mm'), endDateUTCMoment.format('HH:mm'))
                .then(result => {
                    let meetings = result.scheduleMeetings;
                    this.setState({ weeklyEvents: meetings });
                }).catch(e => {
                    console.log(e);
                });
        } catch (error) {
            console.log(error);
        }
    }

    onSetParticipantState = (status, meetingObj) => {
        let userId = auth.getUserId();
        _meetingSDK.setParticipantStatus(meetingObj.id, userId, status).then(result => {
            //update participant status on local state
            if (result.statusCode === 1) {
                let newMonthlyEvents = this.state.monthlyEvents.map(sch => {
                    if (sch.id === meetingObj.id) {
                        sch.participants = sch.participants.map(p => {
                            if (p.participant === userId && p.participantsMeetingStatus) {
                                p.participantsMeetingStatus[0].status = status;
                            }
                            return p;
                        })
                    }
                    return sch;
                });


                let newWeeklyEvents = this.state.weeklyEvents.map(sch => {
                    if (sch.id === meetingObj.id) {
                        sch.participants = sch.participants.map(p => {
                            if (p.participant === userId && p.participantsMeetingStatus) {
                                p.participantsMeetingStatus[0].status = status;
                            }
                            return p;
                        })
                    }
                    return sch;
                });

                let newDailyEvents = this.state.dailyEvents.map(sch => {
                    if (sch.id === meetingObj.id) {
                        sch.participants = sch.participants.map(p => {
                            if (p.participant === userId && p.participantsMeetingStatus) {
                                p.participantsMeetingStatus[0].status = status;
                            }
                            return p;
                        })
                    }
                    return sch;
                });

                let newAllEvents = this.state.allEvents.map(sch => {
                    if (sch.id === meetingObj.id) {
                        sch.participants = sch.participants.map(p => {
                            if (p.participant === userId && p.participantsMeetingStatus) {
                                p.participantsMeetingStatus[0].status = status;
                            }
                            return p;
                        })
                    }
                    return sch;
                });
                this.setState({ monthlyEvents: newMonthlyEvents, weeklyEvents: newWeeklyEvents, dailyEvents: newDailyEvents, allEvents: newAllEvents });

            } else {
                if (result.statusCode === 0) {
                    message.error(result.statusMessage);
                } else {
                    message.error('Error occurred setting user status');
                }
            }

        }).catch(ex => {
            message.error('Error occurred setting user status');
        })

    }

    componentDidMount() {
        let startDateUTCMoment = moment(`${moment().format("YYYY-MM-DD")} 00:00`).utc();
        let endDateUTCMoment = moment(`${moment().format("YYYY-MM-DD")} 23:59`).utc();
        /* let fromDate = moment(new Date()).utc().format("YYYY-MM-DD");
        let toDate = moment(new Date()).utc().format("YYYY-MM-DD"); */
        try {

            _meetingSDK.getMeetingsByDateAndParticipant(auth.getUserId(), startDateUTCMoment.format('YYYY-MM-DD'), endDateUTCMoment.format('YYYY-MM-DD'),
                startDateUTCMoment.format('HH:mm'), endDateUTCMoment.format('HH:mm'))
                .then(result => {
                    let meetings = result.scheduleMeetings;
                    this.setState({ todaySchedules: meetings });
                    let meetingIds = result.scheduleMeetings.map(meeting => meeting.id);
                    this.props.meeting_actions.getMeetingStatuses(meetingIds);

                }).catch(e => {
                    console.error(e);
                });
        } catch (error) {
            console.error(error);
        }
        /* this.props.meeting_actions.getMyMeetings(auth.getUserId()); */
    }

    componentWillUnmount() {
        calendar = null;
    }

    componentDidUpdate() {

        if (this.props.activeMenu === 'meeting' && !calendar) {
            const self = this;
            calendar = new window.Lightpick({
                field: document.getElementById('demo-13'),
                inline: true,
                onSelect: function (date) {

                    let dateTemp = moment(date).format('YYYY-MM-DD');
                    self.setState({ selectedDate: dateTemp });
                    self.getDailyEvents(dateTemp);
                    //alert(date);
                    /* if (document.getElementById('result-13')) {
                        document.getElementById('result-13').innerHTML = date.format('Do MMMM YYYY');
                    } */
                }
            });
        }

    }

    createMeetingAllowed = () => {
        if (meetingCreatorLevel === 'ALL') {
            return true;
        }
        else if (meetingCreatorLevel === 'NONE') {
            return false;
        }
        else {
            return this.props.my_profile.userType.toUpperCase() === meetingCreatorLevel;
        }

    }
    render() {
        return (
            <>

                {this.props.activeMenu === 'meeting' && <div className={`chat-empty-wrapper`}
                    style={{ width: this.props.viewPointWidth - ( navigationRestrictions.meeting.meeting_only? 0 : 380), height: this.props.viewPointHeight }}>
                    <div style={{ float: "left", overflowY: "scroll", height: "100vh" }}>
                        <div className="schedule">
                            <div className=" row rectangle-436 dove-gray-border-1px m-0 " style={{ float: "left", overflowY: "auto", height: "100vh" }}>
                                <div className="row width-100-p m-0">
                                    <div className="  col-sm-12 col-md-7 col-lg-5 col-xl-4">
                                        <form className="form-inline datepick-block">
                                            <div className="form-group col-sm-6 datepickbox p-0">
                                                <input type="text" id="demo-13" className="form-control form-control-sm" style={{ display: "none" }} />
                                            </div>
                                        </form>

                                    </div>
                                    {/* <div className="col-sm-1">
                                    </div> */}
                                    <div className="col-sm-12 col-md-5 col-lg-7 col-xl-8">
                                        <div className="">
                                            <div className="text-1 sourcesanspro-normal-dove-gray-20px ml-0 pl-0">
                                                <div style={{ float: "left" }}>Today’s schedule </div>

                                                <div style={{ float: "right", maxWidth: "46px", marginRight: "-27px", cursor: "pointer" }} onClick={() => {
                                                    this.onRefresh();
                                                }}><RefreshCw size="20px" /></div>

                                            </div>

                                            {/* <div className="float-right width-80" style={{ marginRight: "30px", marginTop: "13px" }}>
                                                    
                                                </div> */}

                                            <div className="todays-schedule-thu sourcesanspro-semi-bold-lucky-point-20px float-left width-80">
                                                <span className="span1">{moment().format('MMMM DD, YYYY')}</span>
                                                <span className="span2">{daysMap[moment().day().toString()]}</span>
                                            </div>
                                            {this.props.my_profile && this.props.my_profile.userType && this.createMeetingAllowed() && <div className="sourcesanspro-semi-bold-lucky-point-20px float-right width-20">
                                                {<div className="width-100 float-left pin-block">
                                                    <button onClick={() => {
                                                        this.props.setUIState('MEETING_INFO_SAVE');
                                                    }} className="btn btn-secondary pinbtn"><i className="fas fa-plus"></i></button>
                                                    <span className="pin-title" style={{ fontWeight: "bold" }}>Add Schedule</span>
                                                </div>}



                                            </div>}

                                            <TodayEvents onDeleteMeeting={this.onDeleteMeeting} onClickVideoCall={this.props.onClickVideoCall} schedules={this.state.todaySchedules} userId={auth.getUserId()} setUIState={this.props.setUIState} />

                                        </div>
                                    </div>
                                </div>
                                <div className="row m-0">
                                    <div className="col-sm-6 col-lg-4 mt-20">
                                        <Events setUIState={this.props.setUIState} onSetParticipantState={this.onSetParticipantState} selectedDate={this.state.selectedDate} onLoadFutureEvents={this.getFutureEvents} getDailyEvents={this.getDailyEvents} isViewAllEnabled={true} viewStatus={this.state.isViewAll}
                                            header={'Scheduled Meetings - ' + moment(this.state.selectedDate).format('MMMM DD, YYYY')}
                                            events={this.state.dailyEvents} />
                                    </div>
                                    <div className="col-sm-6 col-lg-4 mt-20">
                                        <Events setUIState={this.props.setUIState} onSetParticipantState={this.onSetParticipantState} isViewAllEnabled={false} header="Scheduled Meetings (This Week)" events={this.state.weeklyEvents} />
                                    </div>
                                    <div className="col-sm-6 col-lg-4 mt-20">
                                        <Events setUIState={this.props.setUIState} onSetParticipantState={this.onSetParticipantState} isViewAllEnabled={false} header="Scheduled Meetings (This Month)" events={this.state.monthlyEvents} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                }


            </>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return {
        meeting_actions: bindActionCreators(meetingActions, dispatch),
    };
}

function mapStateToProps(state) {

    return {
        my_profile: state.team[auth.getUserId()] 
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(MeetingDashboard);
