import React from 'react';
import PropTypes from 'prop-types';
import ScrollAnimation from "react-animate-on-scroll";


const GalleryEmptyPage = ({notFoundMessage, type}) => (
    <>
        <div className={'gallery-data-notfound-wrapper'}>


            <div className={'notfound-img-wrapper'}>
                <ScrollAnimation delay="100"
                                 animateIn="fadeInUp"
                                 animateOut="fadeOutDown">
                    {type ==='media' &&
                    <img src={require("../../../assets/img/mediaNotFound.svg")}
                         alt="User Avatar"/>
                    }
                    {type ==='doc' &&
                    <img src={require("../../../assets/img/documentNotFound.svg")}
                         alt="User Avatar"/>
                    }
                    {type ==='link' &&
                    <img src={require("../../../assets/img/LinkNotFound.svg")}
                         alt="User Avatar"/>
                    }
                </ScrollAnimation>
            </div>

            <ScrollAnimation delay="200"
                             animateIn="fadeInUp"
                             animateOut="fadeOutDown">
                <div className={'data-not-found-text'}>
                    {notFoundMessage}
                </div>
            </ScrollAnimation>

        </div>
        <div className={'bubble-set one'}>
            <div className={'bubble'}/>
            <div className={'bubble'}/>
        </div>
        <div className={'bubble-set two'}>
            <div className={'bubble'}/>
            <div className={'bubble'}/>
        </div>
    </>
);

GalleryEmptyPage.propTypes = {
    notFoundMessage: PropTypes.string.isRequired,
};

GalleryEmptyPage.defaultProps = {
    notFoundMessage: 'SORRY !!! data not found'
};

export default GalleryEmptyPage;
