import React from 'react';
import PropTypes from 'prop-types';


const SubTitle = ({title,color,onClick}) => (
    <div className={`sub-title ${color}`} onClick={onClick}>
        {title}
    </div>
);

SubTitle.propTypes = {
    title: PropTypes.string.isRequired,
};

SubTitle.defaultProps = {
    type: 'btn-primary',
    size: 'btn-sm',
    disabled: false,
};

export default SubTitle;
