import * as types from "../actions/actionTypes";
import produce from "immer";
import moment from "moment";

export const presenceReducer = produce((draft = { renderEvent: "INITIAL", data: {} }, action) => {
    switch (action.type) {
        case types.GET_ALL_PRESSENCE_SUCCESS:
            {
                draft.renderEvent = action.type;
                draft.data = action.presence;
                return draft;
            }
        case types.RECEIVE_PRESENCE:
            {
                if (!draft.data[action.presence.userid]) {
                    draft.data[action.presence.userid] = {};
                }
                if (action.presence.status) {
                    draft.data[action.presence.userid].status = action.presence.status;
                }

                if (action.presence.connectivity) {
                    draft.data[action.presence.userid].connectivity = action.presence.connectivity;
                    draft.data[action.presence.userid].connectivityAt = action.presence.connectivityAt;
                }
                let currentDate = new Date();
                draft.data[action.presence.userid].presenceAt = currentDate.toISOString();

                draft.renderEvent = action.type;
                return draft;
            }
        case types.SEND_PRESENCE_SUCCESS: {
            if (!draft.data[action.presence.userid]) {
                draft.data[action.presence.userid] = {};
            }
            draft.data[action.presence.userid].status = action.presence.status;
            draft.renderEvent = action.type;
            return draft;
        }
        case types.RECEIVE_PRESENCE_UPDATE: {
            if (draft.data[action.presence.from]) {
                draft.data[action.presence.from].connectivityAt = moment(action.presence.timestamp).toISOString();
                draft.data[action.presence.from].connectivity = 1;
                draft.renderEvent = action.type;
            }
            return draft;
        }
        case types.SET_LOCAL_CONNECTIVITY: {

            if (draft.data[action.user]) {
                draft.data[action.user].connectivity = 2;
                draft.renderEvent = action.type;
            }
            return draft;
        }
        case types.EXPIRE_PRESENCE: {
            if (draft.data[action.user]) {

                let connectivityAt = draft.data[action.user].connectivityAt ? new Date(draft.data[action.user].connectivityAt).valueOf() : null;

                if (Date.now() - connectivityAt > 180000) {
                    draft.data[action.user].time_ago = moment().from(draft.data[action.user].connectivityAt);
                    draft.data[action.user].connectivity = 2;
                    draft.renderEvent = action.type;
                }
            }
            return draft;
        }

        default:
            return draft;
    }
});