import * as types from "../actions/actionTypes";
import initialState from "../reducers/initialState";
import produce from "immer";

export const audioRecordReducer = produce(
    (draft = initialState.fileShareStatus, action) => {
        switch (action.type) {
            case types.OPEN_AUDIO_RECORD: {
                return action.status;
            }
            case types.CLOSE_AUDIO_RECORD: {
                return action.status;
            }
            default:
                return draft;
        }
    }
);
