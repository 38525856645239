import React, { useState ,useEffect} from "react";
import MenuIcon from "../MenuIcon/MenuIcon";
import { MicOff, Mic } from "react-feather";
import { useDispatch, useSelector,shallowEqual } from "react-redux";
import { onCallMuteUnmute } from "../../../redux/actions/callControlsActions";


const MuteButton = ({
  muteUnmuteAudioVideo,
  size,
  color,
  status,
  className,caller
}) => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const [isMute, setMute] = useState(state.callControls.controls.isCallMuted);


  const muteUnmuteAudio = (val) => {    
    setMute(val);
    muteUnmuteAudioVideo(val, state.callControls.controls.isVideoMuted);
    dispatch(onCallMuteUnmute({userId:caller,callType:'AUDIO_CALL',isOperated:true  ,isMuted:val}));
  };

 
  useEffect(() => {
    try {
      setMute(state.callControls.controls.isCallMuted);
    } catch (error) {
      console.log("MuteButton", "useEffect", error);
    }
  }, [state.callControls.controls.isCallMuted]);


  console.log(
    "%cSOFT PHONE RENDER BUTTON MuteButton",
    "color:#04A746; font-family:'Ubuntu'; display: block;font-weight:bold; font-size:12px;"
  );
  return (
    <div
      title={`${isMute ? "Unmute" : "Mute"}`}
      className={`${className} ${isMute ? "active" : ""}`}
    >
      <MenuIcon
        size={size}
        color={color}
        status={status}
        onClick={() => muteUnmuteAudio(!isMute)}
        icon={isMute ? <MicOff /> : <Mic />}
      />
    </div>
  );
};

export default MuteButton;
