import React, { useState, useEffect } from "react";
import { Video ,Airplay,Phone} from "react-feather";
import { MenuIcon, DecrementalTimer } from "../index";
import navigationRestrictions from "../../../config/navigationRestrictions";
import phoneEngine from "../../pages/phone/phoneEngine/phoneEngineHandler";
import * as phoneConfig from "../../../config/phoneConfig";
import { useTimer } from "use-timer";
import moment from "moment";

let loadLocalView = true;
const myVideoRef = React.createRef();

const CallAlert = ({
  status,
  onClickEndCall,
  OnClickAnswerCall,
  fullName,
  src,
  callType,
  callDirection,
  autoAnswer,hideRejectButton,
  OnAutoAnswerCall,
  autoAnswerDelayed,
  callRelatedRequestAccepted,
}) => {
  const [isLoadingMyView, setIsLoadingMyView] = useState(true);

  const { time, start, pause, reset, isRunning } = useTimer({
    initialTime: autoAnswerDelayed,
    endTime: -1,
    timerType: "DECREMENTAL",
    onTimeOver: () => {
      try {
        console.log("DecrementalTimer", "timer stop");        
        OnAutoAnswerCall(callType)
        console.log("CallAlert","OnAutoAnswerCall","call auto answered.");
        reset();
      } catch (error) {
        console.error("DecrementalTimer","onTimeOver", error);
      }
    },
  });

  
  useEffect(() => {

    console.log("CallAlert" , "useEffect", "show incoming call alert to agent.");  
    if(phoneConfig.basic_config.calls.selfView.preload === false){
      showLocalVideo(); 
    }
    if(autoAnswer){
      start();
    }

    return () => {
        // Anything in here is fired on component unmount.
        loadLocalView = true;
    }
  }, []);

  /* useEffect(() => {
    console.log("CallAlert" , "useEffect", "show incoming call alert to agent.");   
    //showLocalVideo(); 
    if(autoAnswer){
      start();
    }
  }, []); */



  if(time <= -3){
    try {
      console.log("CallAlert","OnAutoAnswerCall","trimmer safeguard ","call auto answered.");
      OnAutoAnswerCall(callType);
      reset();
    } catch (error) {
      console.error("DecrementalTimer","onTimeOver","trimmer safeguard ", error);
    }    
  }

  const showLocalVideo = async () => {
    try {  
      console.debug("CallAlert","selfview", "binding local video" );
      if(callType === "VIDEO_CALL" && phoneConfig.basic_config.calls.selfView.active === true && phoneConfig.basic_config.calls.selfView.on_call_alert === true )    {
        const localStream = await phoneEngine.phoneFunctions.getLocalVideo(phoneConfig);
        if (myVideoRef.current && localStream && localStream.active === true) {
          myVideoRef.current.srcObject = localStream;
          myVideoRef.current.addEventListener("loadeddata", event => {
            setIsLoadingMyView(false);
          });        
        }
      }      
    } catch (error) {
      console.error("CallAlert", "fail to get local video", error);
    }
  };

  if(loadLocalView === true && phoneConfig.basic_config.calls.selfView.preload === true ){
    loadLocalView = false;
    showLocalVideo();    
  }

  

  return (
    <>
      <video
        ref={myVideoRef}
        autoPlay
        muted
        playsInline
        style={{
          zIndex: 5000,
          position: "absolute",
          width: "100%",
          height:"100%",
        }}
      ></video>

      <div
        style={{
          zIndex: 6000,
          position: "fixed",
          width: "100%",
        }}
      >
        <div className={"outgoing-bg-inside"}>
          <img
            src={require("../../../assets/img/callBgLg.svg")}
            alt="User Avatar"
          />
        </div>
        <div className={"outgoing-bg"}>
          <img
            src={require("../../../assets/img/callBg.svg")}
            alt="User Avatar"
          />
        </div>
        
        <div className={`outgoing-call-details-wrapper ${ callType === "VIDEO_CALL" ? "video" :  "audio" } ${status}`}   >
          {isLoadingMyView ? (
            <div className={"user-profile-img-wrp"}>
              {src === null && (
                <img
                  src={require("../../../assets/img/inComingCallDefaultUser.svg")}
                  alt="User Avatar"
                />
              )}
              {src !== null && (
                <img src={src} className={"with-url"} alt="User Avatar" />
              )}
            </div>
          ) : (
            <>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
            </>
          )}

          <div className={"profile-full-name"}>{fullName}</div>

          <div className={"on-call-status"}>
            {autoAnswer && (
              <div className={"timer"}>
                {time <= 0 ? "00:00:00": moment.utc(time * 1000).format("HH:mm:ss")}              
            </div>
            )}
            {callDirection}
          </div>

          <div
            className={"out-going-action-wrapper d-flex justify-content-center"}
          >
            {!hideRejectButton && !callRelatedRequestAccepted  && (
              <div className={`d-flex justify-content-between`}>
                {navigationRestrictions.calls.alert.reject.active && (
                  <div
                    className={
                      "outgoing-end-call d-flex justify-content-center align-items-center"
                    }
                  >
                    <MenuIcon
                      onClick={()=>{
                        console.log("CallAlert","onClickEndCall","agent click call reject button");
                        onClickEndCall();
                      }}
                      size={"sm-space"}
                      status={"active"}
                      color={"red"}
                      iconType={"material"}
                      icon={<i className="material-icons">call_end</i>}
                    />
                  </div>
                )}

                {navigationRestrictions.calls.alert.accept.active &&
                  callDirection === "Incoming" && (
                    <div
                      className={
                        "outgoing-anz-call d-flex justify-content-center align-items-center"
                      }
                    >
                      <MenuIcon
                        onClick={()=>{
                          console.log("CallAlert","OnClickAnswerCall","agent click call answer button");
                          OnClickAnswerCall();
                        } }
                        size={"sm-space"}
                        status={"active"}
                        color={"red"}
                        icon={callType === "VIDEO_CALL" ? (<Video />) : callType === "SCREEN_SHARING"?( <Airplay/>) : (<Phone/>)}
                      />
                    </div>
                  )}
              </div>
            )}

            {(hideRejectButton || callRelatedRequestAccepted) && (
              <div className={`d-flex justify-content-between`}>
                {navigationRestrictions.calls.alert.accept.active &&
                  callDirection === "Incoming" && (
                    <div
                      className={
                        "outgoing-auto-anz-call d-flex justify-content-center align-items-center"
                      }
                    >
                      <MenuIcon
                        onClick={()=>{
                          console.log("CallAlert","OnClickAnswerCall","agent click call answer button--");
                          OnClickAnswerCall();
                        } }
                        size={"sm-space"}
                        status={"active"}
                        color={"red"}
                        icon={callType === "VIDEO_CALL" ? (<Video />) : callType === "SCREEN_SHARING"?( <Airplay/>) : (<Phone/>)}
                      />
                    </div>
                  )}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

// CallAlert.propTypes = {
//     status: PropTypes.string.isRequired,
// };

CallAlert.defaultProps = {
  status: "offline",
};

export default CallAlert;
