import * as types from "./actionTypes";
import * as TemplateService from "../../service/templateService";

export function getAdminCannedMessages() {
    return dispatch => {
        TemplateService.getAdminCannedMessages().then(result => {
            dispatch(adminCannedMessagesLoaded(result));
        }).catch(ex => {
            console.error(ex);
        });
    }
}

export function adminCannedMessagesLoaded(templates) {
    return { type: types.ADMIN_CANNED_LOADED, templates };
}

export function getMyCannedMessages() {
    return dispatch => {
        TemplateService.getMyCannedMessages().then(result => {
            dispatch(myCannedMessagesLoaded(result));
        }).catch(ex => {
            console.error(ex);
        });
    }
}

export function myCannedMessagesLoaded(templates) {
    return { type: types.MY_CANNED_LOADED, templates };
}

export function appendMyCannedMessage(template) {
    return { type: types.APPEND_MY_CANNED_MESSAGE, template };
}

export function editMyCannedMessage(template) {
    return { type: types.EDIT_MY_CANNED_MESSAGE, template };
}

export function deleteMyCannedMessage(id) {
    return { type: types.DELETE_MY_CANNED_MESSAGE, id };
}

export function saveUserCannedMessage(data, callback) {
    return dispatch => {
        TemplateService.saveMyCannedMessages(data).then(result => {
            let savedMessage = {};
            savedMessage[result.Uuid] = result;
            callback(true);
            dispatch(appendMyCannedMessage(savedMessage));
        }).catch(ex => {
            callback(false);
            console.error(ex);
        });
    }

}

export function updateUserCannedMessage(id, data, callback) {
    return dispatch => {
        TemplateService.updateMyCannedMessages(id, data).then(result => {
            callback(true);
            dispatch(editMyCannedMessage(result));
        }).catch(ex => {
            callback(false);
            console.error(ex);
        });
    }

}

export function deleteUserCannedMessage(id, callback) {
    return dispatch => {
        TemplateService.deleteMyCannedMessage(id).then(result => {
            callback(true);
            dispatch(deleteMyCannedMessage(id));
        }).catch(ex => {
            callback(false);
            console.error(ex);
        });
    }

}