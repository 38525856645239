import * as types from "../actions/actionTypes";
import produce from "immer";

export const currentMeetingReducer = produce((draft = {}, action) => {
    switch (action.type) {
        case types.CREATE_CURRENT_MEETING:
            {
                draft = action.data;
                return draft;
            }
        case types.REMOVE_CURRENT_MEETING:
            {
                if (draft.meetingId === action.meetingId) {
                    draft = {};
                }
                return draft;
            }
        default:
            return draft;
    }
});