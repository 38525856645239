import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Card } from 'react-bootstrap';
import { TextBoxWithLabel, TextAreaWithLabel, Button } from '../../common';
import * as cannedMessageActions from '../../../redux/actions/cannedMessageActions';
import { message as msg } from "antd/lib/index";


const NewCannedMessage = props => {

    const [name, setName] = useState("");
    const [trigger, setTrigger] = useState("");
    const [message, setMessage] = useState("");

    let onChangeHandlerTrigger = event => {
        setTrigger(event.target.value);
    };

    let onChangeHandlerName = event => {
        setName(event.target.value);
    };

    let onChangeHandlerMessage = event => {
        setMessage(event.target.value);
    };

    useEffect(() => {
        if (!name && props.isUpdate && props.updateData) {
            setName(props.updateData.TemplateName);
            setTrigger(props.updateData.Trigger);
            setMessage(props.updateData.TemplateMessage);
        }

    }, [props.updateData, props.isUpdate])

    let onSaveClick = event => {

        if (props.isUpdate) {

            if (props.updateData && props.updateData.Uuid) {
                let editData = {
                    TemplateName: name,
                    Description: name,
                    TemplateMessage: message,
                    TemplateType: "CANNED_USER",
                    Trigger: trigger
                }
                props.canned_message_actions.updateUserCannedMessage(props.updateData.Uuid, editData, (result) => {

                    if (result) {
                        msg.success("Canned message updated");
                    } else {
                        msg.error("Error updating canned message");
                    }

                });
            } else {
                console.error('Error updating canned message - invalid update data')
            }


        } else {
            let saveData = {
                TemplateName: name,
                Description: name,
                TemplateMessage: message,
                TemplateType: "CANNED_USER",
                Trigger: trigger
            }
            props.canned_message_actions.saveUserCannedMessage(saveData, (result) => {

                if (result) {
                    msg.success("Canned message saved");
                } else {
                    msg.error("Error saving canned message");
                }

            });

        }


        setTrigger("");
        setName("");
        setMessage("");
    };


    return (
        <>
            <div className={'form-group'}>
                <TextBoxWithLabel labelText={"Name"}
                    required={true} value={name}
                    onChange={onChangeHandlerName}></TextBoxWithLabel>
            </div>
            <div className={'form-group'}>
                <TextBoxWithLabel labelText={"Trigger"}
                    required={true} value={trigger}
                    onChange={onChangeHandlerTrigger}>

                </TextBoxWithLabel>
            </div>
            <TextAreaWithLabel labelText={"Canned Message"}
                required={true}
                placeholder={`Enter canned message`}
                maxLength={"1000"}
                value={message} onChange={onChangeHandlerMessage} />

            <div className={'form-group mt-5'}>
                <Button title="Save"
                    size={'btn-md'}
                    onClick={onSaveClick} />
            </div>


        </>
    );
};

function mapDispatchToProps(dispatch) {
    return {
        canned_message_actions: bindActionCreators(cannedMessageActions, dispatch)
    };
}

export default connect(null, mapDispatchToProps)(NewCannedMessage);
