import * as types from "./actionTypes";

export function setTextAreaMessage(username, message, reply_message) {

    return { type: types.SET_TEXTAREA_MESSAGE, username, message, reply_message };
}

export function removeTextPanelInfo(username) {
    return { type: types.REMOVE_TEXT_PANEL_INFO, username };
}
