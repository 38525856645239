import React from 'react';
import { Check, Headphones } from "react-feather";
import { MenuIcon } from "../index";

const MediaAudio = ({ active, viewGallery, selectThis }) => (
    <div
        className={`media-wrapper ${active ? 'active' : ''}`}>
        <div className={'check'} onClick={selectThis}>
            <MenuIcon icon={<Check />}
                size={'sm'} />
        </div>
        <div style={{ marginLeft: "32%", marginTop: "32%" }}
            onClick={viewGallery}>
            <div>
                {<Headphones size={'50px'} />}
            </div>
        </div>

    </div>
);

export default MediaAudio;
