import React from "react";
import { parse } from "@babel/core";
import CryptoJS from "crypto-js";
import SecureStorage from "secure-web-storage";
import {
  __APP_IDENTITY_PROVIDER__,
  __APP_DEFAULT_WORKSPACE__,
} from "../config/baseUrls";
import ReactImageUploadComponent from "react-images-upload";
import uuid from "uuid";

const TOKEN_KEY = "@@user";
const LOGGED_USER = "@@logged_user";
const OFFICIAL_DOMAIN_REQUEST = "@@official_domain_request";
const DISPLAY_NAME = "@@display_name";
const CUSTOM_DATA = "@@custom_data";
const USER_TYPE = "@@user_type";
const SESSION_ID = "@@session_id";
const MODE = "@@mode";
const LOGGED_USER_PASSWORD = "@@logged_user_password";
const LOGGED_USER_ID = "@@logged_user_id";
const LOGGED_USER_TENANTTOKEN = "@@logged_user_TenantToken";
const workspaceKey = "@@organization---";
const workspacesKey = "@@organizations---";
const passwordPolicyKey = "@@passwordPolicyKey---";
const refreshTokenKey = "@@@refreshToken-----";
const baseUrlKey = "@@@baseUrlKey-----";
const phoneConfigKey = "@@@phoneConfigKey-----";
const jwt = require("jsonwebtoken");
const SECRET_KEY = "my secret key";

var secureStorage = new SecureStorage(sessionStorage, {
  hash: function hash(key) {
    key = CryptoJS.SHA256(key, SECRET_KEY);

    return key.toString();
  },
  encrypt: function encrypt(data) {
    data = CryptoJS.AES.encrypt(data, SECRET_KEY);

    data = data.toString();

    return data;
  },
  decrypt: function decrypt(data) {
    data = CryptoJS.AES.decrypt(data, SECRET_KEY);

    data = data.toString(CryptoJS.enc.Utf8);

    return data;
  },
});

export const createToken = (obj) => {
  let secret = Buffer.from('tetherfi', "base64");
  const token = jwt.sign({ id: obj.userName, username: obj.userName, organization: obj.orgUnit ? obj.orgUnit : __APP_DEFAULT_WORKSPACE__, aud: obj.userName }, secret, {
    expiresIn: `24h`, //was 5minutes
    subject: 'iam login',
    jwtid: uuid(),
    issuer: 'iam'
  });

  return token;
}

export const setToken = (token, userId) => {
  //console.log('login token....');
  //console.log(token);
  try {
    //secureStorage.setItem(TOKEN_KEY, token);
    token = token.replace("Bearer ", "");
    secureStorage.setItem(TOKEN_KEY, token);

    const details = jwt.decode(token);

    let uid = userId ? userId : details.id;

    if (__APP_IDENTITY_PROVIDER__ === "GUARDIAN") {
      secureStorage.setItem(LOGGED_USER_ID, uid);
      secureStorage.setItem(LOGGED_USER_TENANTTOKEN, token);
    } else {
      secureStorage.setItem(LOGGED_USER_ID, uid);
      secureStorage.setItem(LOGGED_USER_TENANTTOKEN, details.organization);
      secureStorage.setItem(
        workspaceKey,
        JSON.stringify({
          organization: details.organization,
          userName: details.aud,
        })
      );
    }
  } catch (ex) {
    console.error("setToken", ex);
  }
};

export const setPasswordPolicy = (policy) => {
  secureStorage.setItem(passwordPolicyKey, JSON.stringify(policy));
}

export const getPasswordPolicy = () => {
  return JSON.parse(secureStorage.getItem(passwordPolicyKey));
}


export const setUser = (username) => {
  secureStorage.setItem(LOGGED_USER, username);
};

export const setUserType = (type) => {
  secureStorage.setItem(USER_TYPE, type);
};

export const setOfficialDomainRequest = (domain) => {
  secureStorage.setItem(OFFICIAL_DOMAIN_REQUEST, domain);
};

export const setDisplayName = (displayname) => {
  if (displayname) {
    secureStorage.setItem(DISPLAY_NAME, displayname);
  }
};

export const setCustomData = (customData) => {
  if (customData) {
    secureStorage.setItem(CUSTOM_DATA, JSON.stringify(customData));
  }
};

export const getCustomData = () => {
  return JSON.parse(secureStorage.getItem(CUSTOM_DATA));
};

export const setSession = (sid) => {
  secureStorage.setItem(SESSION_ID, sid);
};

export const getSession = () => {
  const sid = secureStorage.getItem(SESSION_ID);
  return sid;
};

export const setMode = (mode) => {
  secureStorage.setItem(MODE, JSON.stringify(mode));
};

export const getMode = () => {
  const mode = secureStorage.getItem(MODE);
  return JSON.parse(mode);
};

export const getModeVal = () => {
  const mode = secureStorage.getItem(MODE);
  let tempMode = JSON.parse(mode);

  return tempMode ? tempMode.value : "";
};

export const getUser = () => {
  // console.log('get token....');
  const user = secureStorage.getItem(LOGGED_USER);
  return user;
};

export const getUserType = () => {
  // console.log('get token....');
  const typ = secureStorage.getItem(USER_TYPE);
  return typ;
};

export const getOfficialDomainRequest = () => {
  const domain = secureStorage.getItem(OFFICIAL_DOMAIN_REQUEST);
  return domain;
};

export const getDisplayName = () => {
  // console.log('get token....');
  let displayName = "";
  try {
    displayName = secureStorage.getItem(DISPLAY_NAME);
  } catch (ex) { }
  return displayName;
};

export const setPassword = (password) => {
  secureStorage.setItem(LOGGED_USER_PASSWORD, password);
};

export const getPassword = () => {
  // console.log('get token....');
  const user = secureStorage.getItem(LOGGED_USER_PASSWORD);
  return user;
};

export const getUserId = () => {
  // console.log('get token....');
  const user = secureStorage.getItem(LOGGED_USER_ID);
  return user;
};

export const getTenantToken = () => {
  // console.log('get token....');
  const user = secureStorage.getItem(LOGGED_USER_TENANTTOKEN);
  return user;
};

export const getToken = () => {
  // console.log('get token....');
  const token = secureStorage.getItem(TOKEN_KEY);
  return token;
};

export const getBearerToken = () => {
  // console.log('get token....');
  const token = secureStorage.getItem(TOKEN_KEY);
  return `Bearer ${token}`;
};

export const setRefreshToken = (refreshToken, userName) => {
  secureStorage.setItem(
    refreshTokenKey,
    JSON.stringify({ refreshToken, userName })
  );
};

export const getRefreshTokenObj = () => {
  return JSON.parse(secureStorage.getItem(refreshTokenKey));
};

export const getRefreshToken = () => {
  let refreshTokenObj = JSON.parse(secureStorage.getItem(refreshTokenKey));
  return refreshTokenObj ? refreshTokenObj.refreshToken : "";
};

export const setWorkspaces = (organizations) => {
  secureStorage.setItem(workspacesKey, JSON.stringify(organizations));
};

export const getWorkspaces = () => {
  return JSON.parse(secureStorage.getItem(workspacesKey));
};

export const addWorkspace = (workspace) => {
  const wdata = JSON.parse(secureStorage.getItem(workspacesKey));
  const officialOrg = wdata.find(org => org.workSpaceName === workspace.workSpaceName ? true : false);
  if (!officialOrg) {
    wdata.push(workspace);
    setWorkspaces(wdata)
  }
};

export const setWorkspace = (organization, userName) => {
  secureStorage.setItem(
    workspaceKey,
    JSON.stringify({ organization, userName })
  );
};

export const getWorkspaceData = () => {
  return JSON.parse(secureStorage.getItem(workspaceKey));
};

export const getWorkspace = () => {
  const wdata = JSON.parse(secureStorage.getItem(workspaceKey));
  return wdata ? wdata.organization : __APP_DEFAULT_WORKSPACE__;
};

export const setBaseUrl = (urls) => {
  secureStorage.setItem(baseUrlKey, JSON.stringify(urls));
};

export const getBaseUrl = () => {
  const wdata = JSON.parse(secureStorage.getItem(baseUrlKey));
  return wdata;
};

export const getBaseUrlName = (name) => {
  try {
    const wdata = JSON.parse(secureStorage.getItem(baseUrlKey));
    return wdata ? wdata[name] : null;
  } catch (error) {
    return null;
  }
};

export const setPhoneConfig = (config) => {
  secureStorage.setItem(phoneConfigKey, JSON.stringify(config));
};

export const getPhoneConfig = () => {
  const wdata = JSON.parse(secureStorage.getItem(phoneConfigKey));
  return wdata;
};

export const userLogOut = () => {
  console.log("remove token....");
  secureStorage.removeItem(TOKEN_KEY);
  secureStorage.removeItem(SESSION_ID);
  secureStorage.removeItem(workspacesKey);
  secureStorage.removeItem(workspaceKey);
  secureStorage.removeItem(refreshTokenKey);
  secureStorage.removeItem(USER_TYPE);
  secureStorage.removeItem(MODE);
  secureStorage.clear();
};

export const isLogin = () => {
  //console.log('login....');
  const token = getToken();
  if (token) {
    return true;
  }
  return false;

  /* if (token) {
      let decoded = jwt.verify(token, "tetherfi");
      if (decoded && decoded.sub && decoded.iat) {
        return true;
      } else {
        userLogOut();
        console.log("User cant be access main console : logout");
        return false;
      }
    } */
};

export const getLoggedInUser = () => {
  /* let token = getToken();
    let decoded = jwt.decode(token);

    if (decoded) {
      return decoded.sub;
    } else {
      return null;
    } */

  let user = getUser();
  return user;
};

export const userDetails = () => {
  //console.log('get  user details....');
  const token = getToken();
  if (token) {
    console.log(jwt.decode(token));
    return jwt.decode(token);
  }
};

export const isNeedToResetPassword = (token) => {
  try {
    if (token) {
      return jwt.decode(token).forceChangePassword;
    }
    return false;
  } catch (error) {
    console.error('isNeedToResetPassword', error);
    return false;
  }

};

export const getMediaDeviceErrorCount = () => {
  const count = JSON.parse(secureStorage.getItem(`${getUserId()}-MediaDeviceErrorCount`));
  return count;
};

export const setMediaDeviceErrorCount = () => {
  const count = getMediaDeviceErrorCount().count;
  secureStorage.setItem(`${getUserId()}-MediaDeviceErrorCount`, JSON.stringify({ count: count + 1, time: Date.now() }));
};

export const resetMediaDeviceErrorCount = () => {
  secureStorage.setItem(`${getUserId()}-MediaDeviceErrorCount`, JSON.stringify({ count: 1, time: null }));
};
/*
export const getUserId = () => {
  //console.log('get  user details....');
  const token = getToken();
  if (token) {
    let decoded = jwt.decode(token);
    console.log(decoded);
    if (decoded.sub) {
      return JSON.parse(decoded.sub).userId;
    } else {
      return "";
    }
  }
};
 */
