import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ButtonRounded, DeviceNotSupported } from "../../common";
import navigationRestrictions from "../../../config/navigationRestrictions";
import { Skeleton, Button, Spin, Select } from "antd";
import { message } from "antd/lib/index";
import sdkHandler from "../../../lib";
import * as auth from "../../../utils";
import * as logger from "../../../lib/logSdk/logger";
import * as selectedProfileActions from "../../../redux/actions/selectedProfileActions";
import * as presenceActions from "../../../redux/actions/presenceActions";
import { Link } from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";
import AuthService from "../../../service/authService.js";

import { __APP_IDENTITY_PROVIDER__, __APP_MODES__, iamRedirectUrl } from "../../../config/baseUrls";

import "./styles/user-landing.scss";
import "./styles/login-responsive.scss";

import { useForm } from "react-hook-form";
import { EyeOff, Eye } from "react-feather";
import { useLocation } from "react-router-dom";
import packageJson from '../../../../package.json';

import * as faceapi from 'face-api.js';

const authService = new AuthService();

let appModes = JSON.parse(__APP_MODES__);

const { Option } = Select;

let signingProgress = false;
let signingMode;
let faceAuthTryCount = 1;
let faceAuthProcessing = false;
let faceDetectTimer;



// tiny_face_detector options
let inputSize = 128
let scoreThreshold = 0.5


const localVideoRef = React.createRef();

const SignInComponent = (props) => {
  let location = useLocation();

  const { register, handleSubmit, errors, getValues, reset } = useForm();
  let isAuthenticationSuccess = false;
  const [userName, setUserName] = useState("");
  const [userPassword, setUserPassword] = useState("");
  const [uiMode, setUIMode] = useState(appModes && appModes.length > 0 ? appModes[0] : null);
  const [isLoading, setIsLoading] = useState(false);
  const [authMode, setAuthMode] = useState(navigationRestrictions.login.face_auth.active === true ? navigationRestrictions.login.default_method : 'password');
  const [isInitializeSocket, setIsInitializeSocket] = useState(false);
  const [inputType, setInputType] = useState("password");
  const [isLoadingConfig, setIsLoadingConfig] = useState(false);
  const [passwordPolicy] = useState(auth.getPasswordPolicy());
  const [loginInvalid, setLoginInvalid] = useState("");
  const [passwordInvalid, setPasswordInvalid] = useState("");

  const handleNameChange = (e) => {
    try {
      setUserName(e.target.value);
    } catch (error) {
      console.error("SignInComponent", "handleNameChange", error);
    }

  };
  const handlePasswordChange = (e) => {
    setPasswordInvalid(e.target.value ? '' : 'login-invalid');
    setUserPassword(e.target.value);
  }


  const facecCanvas = document.createElement("canvas");

  useEffect(() => {
    if (!location.state || location.state.deviceValidation === false) {
      console.error(
        "SignInComponent",
        "useEffect",
        "invalid routing. try to login without doing device validation"
      );
      props.history.push("/");
    }
    load();
  }, []);

  const enablePasswordMode = () => {
    try {
      setLoginInvalid('');
      removeStream();
      console.warn("SignInComponent", "enablePasswordMode");
      setAuthMode('password');
      signingMode = "password";
      clearTimeout(faceDetectTimer);
      setTimeout(() => {
        faceAuthTryCount = 1;
        //setIsLoading(false); 
      }, 1000); // do this to prevent faceapi drawing error 

    } catch (error) {
      console.error("SignInComponent", "enablePasswordMode", error);
    }
  }

  const load = async () => {
    try {
      if (!isFaceDetectionModelLoaded()) {
        await faceapi.nets.tinyFaceDetector.load('/models/')
      }
    } catch (error) {
      console.error("SignInComponent", "load", error);
    }
    finally {
      setIsLoading(false);
      if (navigationRestrictions.login.face_auth.active === true && navigationRestrictions.login.default_method === 'faceAuth') {
        enableFaceAuthMode();
        return;
      }
      enablePasswordMode();
    }
  }

  const isFaceDetectionModelLoaded = () => {
    return !!faceapi.nets.tinyFaceDetector.params
  }

  let faceDetectTryCount = 0;
  const detectUserFace = async () => {
    try {
      console.warn("SignInComponent", "detectUserFace", `executing face detection method. signingMode : ${signingMode} , faceAuthProcessing : ${faceAuthProcessing}`);

      const tryToLogin = () => {
        try {
          faceAuthProcessing = true;
          faceDetectTryCount = 0;

          const h = localVideoRef.current.srcObject && localVideoRef.current.clientHeight;
          const w = localVideoRef.current.srcObject && localVideoRef.current.clientWidth;

          facecCanvas.height = h;
          facecCanvas.width = w;
          if (h > 0 && w > 0) {
            const context = facecCanvas.getContext('2d');
            context && context.drawImage(localVideoRef.current, 0, 0, w, h);
            let resultB64 = facecCanvas && facecCanvas.toDataURL("image/png");

            const options = {
              userName: getValues("userName"),
              snapData: resultB64,
              isFaceAuth: true
            };

            onPressSignIn(options);
          }
        } catch (error) {
          console.error("SignInComponent", "detectUserFace", "tryToLogin", error);
        }
      }
      if (signingMode !== "faceAuth" || faceAuthProcessing === true) {
        console.warn("SignInComponent", "detectUserFace", "wait for face auth process to complete.");
        return;
      }

      const options = new faceapi.TinyFaceDetectorOptions({ inputSize, scoreThreshold });
      const result = await faceapi.detectSingleFace(localVideoRef.current, options);
      var canvas = document.getElementById("overlay");
      if (result && canvas) {
        const dims = faceapi.matchDimensions(canvas, localVideoRef.current, true);
        if (dims.height > 0 && dims.width > 0) {
          const resizedResults = faceapi.resizeResults(result, dims);
          if (resizedResults && resizedResults.imageWidth >= 0 && resizedResults.imageHeight >= 0 && resizedResults.box._height >= 0 && resizedResults.box._width >= 0) {
            faceapi.draw.drawDetections(canvas, resizedResults)
          }
        }
      }



      if (localVideoRef.current.paused || localVideoRef.current.ended || !isFaceDetectionModelLoaded()) {
        console.warn("SignInComponent", "detectUserFace", "module not loaded or video paused.");
        return;
      }

      ////faceAuthProcessing = !validateForm({userName:e.target.value}); 
      if (validateForm({ userName: getValues("userName") }) === false) {
        console.warn("SignInComponent", "detectUserFace", "auth mode changed.");
        return;
      }

      if (result && result.score >= navigationRestrictions.login.face_auth.minScore) {
        tryToLogin();
      }
      else {
        console.warn("SignInComponent", "detectUserFace", "face not detected", `faceDetectTryCount : ${faceDetectTryCount}`);
        if (faceDetectTryCount >= 5) {
          tryToLogin();
        }
        faceDetectTryCount++;
      }
    } catch (error) {
      console.error("SignInComponent", "detectUserFace", error);
    }
  }

  const enableFaceAuthMode = async () => {
    try {
      console.warn("SignInComponent", "enableFaceAuthMode", `enable faceauth mode. faceDetectTimeout: ${navigationRestrictions.login.face_auth.faceDetectTimeout} `);
      setAuthMode('faceAuth');
      signingMode = "faceAuth";
      setIsLoading(true);
      signingProgress = false;
      reset({ userName: "" });
      const localStreams = await navigator.mediaDevices.getUserMedia({ video: true, audio: false });

      if (localVideoRef.current && localStreams) {
        localVideoRef.current.srcObject = localStreams;
        localVideoRef.current.addEventListener("loadeddata", (event) => {
          try {
            setIsLoading(false);
            faceDetectTimer = setInterval(() => {
              detectUserFace();
            }, navigationRestrictions.login.face_auth.faceDetectTimeout);
          } catch (error) {
            console.error("SignInComponent", "enableFaceAuthMode", "canplay", error);
          }
        });
      }
    } catch (error) {
      console.error("SignInComponent", "enableFaceAuthMode", error);
    }
  }

  const removeStream = () => {
    try {
      clearTimeout(faceDetectTimer);
      const stream = localVideoRef.current && localVideoRef.current.srcObject;
      if (stream) {
        if (stream.active) {
          let tracks = stream.getTracks();
          tracks.forEach(function (track) {
            track.stop();
          });
        }
        var audioTrack = stream.getAudioTracks();
        if (audioTrack.length > 0) {
          stream.removeTrack(audioTrack[0]);
        }

        var videoTrack = stream.getVideoTracks();
        if (videoTrack.length > 0) {
          stream.removeTrack(videoTrack[0]);
        }
      }
      if (localVideoRef.current) {
        localVideoRef.current.src = "";
        localVideoRef.current.srcObject = null;
        localVideoRef.current.removeAttribute("src");
      }

    } catch (error) {
      console.error("SignInComponent", "removeStream", error);
    }
  }

  const closeSocketMessage = () => {
    setIsInitializeSocket(false);
    __APP_IDENTITY_PROVIDER__ === "GUARDIAN" &&
      authService.logout(auth.getRefreshToken(), "ABORTED");
    auth.userLogOut();
    setIsLoading(false);
    props.history.push(`/logout`);
    window.location.reload();
    console.log(
      "AutoLogin",
      "uireload",
      "closeSocketMessage",
      "window.location.reload"
    );
  };

  const proceedToReInitialize = () => {
    setIsInitializeSocket(false);
    sdkHandler.startSession(true);
  };

  const onPressSignIn = async (options) => {
    try {
      console.log("Login Process", "step : 01", "validateForm", `User : ${options.userName} , signingProgress : ${signingProgress}`);
      logger.debug("AgentConsole", "SignIn", `------ AgentConsole ------ User : ${options.userName} , version - ${packageJson.version}`);
      if (!validateForm(options)) {
        console.error("LOGIN_FORM_VALIDATION_ERROR_EXIT_LOGIN");
        logger.error("SignIn", "onPressSignIn", "LOGIN_FORM_VALIDATION_ERROR_EXIT_LOGIN");
        return;
      }
      signingProgress = true;
      setIsLoading(true);
      console.log("Login Process", "step : 02", "subscribeEvent - onStartSuccess");
      sdkHandler.subscribeEvent("signing-onStartSuccess", "onStartSuccess", (sid) => {

        console.log("Login Process", "step : 06 - A", "onStartSuccess", `isAuthenticationSuccess : ${response.isSuccess} sid : ${sid}`);
        auth.setSession(sid);
        if (isAuthenticationSuccess) {
          let presenceObj = { userid: auth.getUserId(), status: "online" };
          props.presence_actions.sendPresenceSuccess(presenceObj);
          sdkHandler.sendPresenceNotification(JSON.stringify(presenceObj));
          auth.setMode(uiMode);
          props.history.push(`/console`);
          setIsLoading(false);
          return;
        }
        console.error(`Signing-onStartSuccess - without Auth Success - sid : ${sid}`);

        logger.error("SignIn", "onStartSuccess", `Signing-onStartSuccess - without Auth Success - sid : ${sid}`);
      }
      );
      console.log("Login Process", "step : 03", "subscribeEvent - onStartFailed");
      sdkHandler.subscribeEvent("signing-onStartFailed", "onStartFailed", (error) => {
        console.error("SignIn", "signing-onStartFailed.", `onStartFailed , Auth mode : ${authMode}, signingProgress : ${signingProgress} set to false`);
        signingProgress = false;
        if (error) {
          let errorObj = JSON.parse(error);
          if (errorObj.reasonCode === "SESSION_EXIST") {
            setIsInitializeSocket(true);
          } else {
            if (errorObj.reasonCode === "LICENSE_VALIDATION_FAILED_MAX_SESSION_REACHED") {
              message.error("Max sessions allowed for your license reached");
            }
            console.log("Login Process", "step : 06 - B", "onStartFailed", `isAuthenticationSuccess : ${response.isSuccess}`);
            __APP_IDENTITY_PROVIDER__ === "GUARDIAN" &&
              authService.logout(auth.getRefreshToken(), "START FAILED");
            auth.userLogOut();
            // message.error("Fail To Initialize Socket Connection.");
            console.error("signing-onStartFailed.", error);
            logger.error("SignIn", "signing-onStartFailed", error.message);
            setIsLoading(false);
          }

        } else {
          console.error('Empty error message');
        }

      }
      );

      console.log("Login Process", "step : 04", "loginToSystem");
      const response = await sdkHandler.loginToSystem(options.userName, options.userPassword, { ...options });
      isAuthenticationSuccess = response.isSuccess;
      console.log("Login Process", "step : 05", `loginToSystem - isAuthenticationSuccess : ${response.isSuccess}`);
      if (response.isSuccess) {
        clearTimeout(faceDetectTimer);
        removeStream();
        auth.setToken(response.token, response.userId);
        auth.setUser(options.userName);
        response.tokenInfo && response.tokenInfo.organizations && auth.setWorkspaces(response.tokenInfo.organizations);
        auth.setRefreshToken(response.tokenInfo && response.tokenInfo.refreshToken ? response.tokenInfo.refreshToken : response.token, options.userName);
        response.tokenInfo && auth.setWorkspace(response.tokenInfo.organization, options.userName);
        auth.setPassword(options.userPassword);
        props.selected_profile_actions.profileSelected({});
        auth.resetMediaDeviceErrorCount();
        if (response.resetPassword) {
          props.history.push({ pathname: "/updatepassword", state: { detail: { userName: options.userName, userPassword: options.userPassword } }, });
        }
      } else {
        console.error("SignIn", "Invalid login details.", `Auth mode : ${authMode}, signingProgress : ${signingProgress} set to false`);
        signingProgress = false;
        if (signingMode === 'password') {
          message.error("Invalid login details.");
          return;
        }
        faceAuthProcessing = false;
        message.error("face authentication fail");
      }
    } catch (ex) {
      console.error("SignIn", "Invalid login details.", `Auth mode : ${authMode} , signingProgress : ${signingProgress} set to false`);
      setIsLoading(false);
      console.error("Invalid login details.", ex);
      logger.error("SignIn", "onPressSignIn", ex.message);
      signingProgress = false;
      if (signingMode === 'password') {
        message.error("Invalid login details.");
        return;
      }
      faceAuthProcessing = false;
      message.error("face authentication fail");
    }
    finally {
      if (signingMode !== 'password') {
        console.warn("SignIn", "onPressSignIn", `face auth try count : ${faceAuthTryCount}`);
        if (faceAuthTryCount >= 3) {
          enablePasswordMode();
        }
        faceAuthTryCount++;
      }
    }
  };

  function validateForm(options) {
    try {

      if (signingProgress === true) {
        throw new Error("signing progress, wait for response.");
      }

      if (options === undefined || options === null || options.userName === undefined || options.userName === null) {
        reset({ userName: "" });
        setLoginInvalid('login-invalid');
        throw new Error("Please enter an email address");
      }

      if (options.userName.length === 0) {
        reset({ userName: "" });
        setLoginInvalid('login-invalid');
        throw new Error("Please enter an email address");
      }

      if (!validateEmail(options.userName)) {
        setLoginInvalid('login-invalid');
        throw new Error("Invalid email format.");
      }
      setLoginInvalid('');
      if (signingMode === "faceAuth") {
        return true;
      }

      if (options.userPassword !== undefined && options.userPassword !== null && options.userPassword.length === 0) {
        throw new Error("Please enter a password");
      }
      return true;
    } catch (e) {
      signingMode !== "faceAuth" && message.error(e.message);
      console.error("LOGIN_FORM_VALIDATION_ERROR", e.message);
      logger.error("SignIn", "LOGIN_FORM_VALIDATION_ERROR", e.message);
      return false;
    }
  }

  function validateEmail(email) {
    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  /* async function onKeyPress(e) {
    if (e.key === "Enter") {
      // login();
      //setIsLoading(true);
      await onPressSignIn();
    }
  } */

  return (
    <>
      <DeviceNotSupported/>
      <section className={`main-login-wrapper`}>
        <div className={"top-left-leaf-bg"}>
          <img
            src={require("../../../assets/img/bg/login/topRightLeaf.svg")}
            alt="User Avatar"
          />
        </div>

        <div className={"planet-top-center hide-mobile"}>
          <img
            src={require("../../../assets/img/bg/login/planetTop.svg")}
            alt="User Avatar"
          />
        </div>

        {/*====== login from main wrapper start here ========*/}
        <div className={"container"} style={{ maxWidth: '75%' }}>
          <div
            className={
              "responsive-login-wrapper d-flex justify-content-between"
            }
          >
            <div className={"login-left-wrapper "}>
              <div
                className={`main-character-wrapper d-flex justify-content-center align-items-center`}
              >
                <div
                  className={
                    "main-character-wrp d-flex flex-column justify-content-center"
                  }
                >
                  <div className={"character-wrp"}>
                    <img
                      src={require("../../../assets/img/bg/login/MainCharacter.svg")}
                      alt="User Avatar"
                    />
                  </div>
                  <div className={"text-wrapper"}>
                    <h1>
                      Build the best customer
                      <br /> experiences.
                    </h1>

                  </div>
                </div>
              </div>
            </div>
            {/*======== login form start here ========*/}
            <div className={"login-right-wrapper"}>
              <div className="login-form-main-wrapper animated fadeIn">
                <div className="login-form-inner-wrapper">
                  <div>
                    <div
                      className={`login-form  ${!isInitializeSocket ? "active-this" : "hide-me-msg"
                        }`}
                    >
                      <div
                        className={"logo-wrp hide-mobile"}
                        title={`version - ${packageJson.version}`}
                      >
                        <img
                          src={require("../../../assets/img/bg/login/logo_md.svg")}
                          alt="User Avatar"
                        />
                      </div>
                      <div
                        className={"logo-wrp hide-desktop"}
                        title={`version - ${packageJson.version}`}
                      >
                        <img
                          src={require("../../../assets/img/bg/login/logo_md_white.svg")}
                          alt="User Avatar"
                        />
                      </div>
                      <div className={`login-header-wrapper`}>
                        <h2>Sign in</h2>
                      </div>

                      <div className={"sign-in-body-wrapper"}>
                        {isLoadingConfig ? (
                          <>
                            {" "}
                            <br></br>
                            <br></br> <Skeleton active avatar /> <br></br>
                            <br></br>
                            <br></br>{" "}
                          </>
                        ) : (
                          <form onSubmit={handleSubmit(onPressSignIn)}>

                            {appModes && appModes.length > 0 && <div id="modeselect_agent" className="form-group">
                              <label htmlFor="exampleInputEmail1">
                                Mode
                              </label>
                              <Select style={{ width: 120, marginLeft: "10px" }} dropdownStyle={{ borderRadius: "15px" }} value={uiMode.value} onChange={(val) => {
                                let md = appModes.find(m => m.value === val);
                                if (md) {
                                  setUIMode(md);
                                  if (md.type === 'OCM') {
                                    window.location.href = iamRedirectUrl;
                                  }

                                }


                              }}>
                                {appModes.map(mode => {
                                  return <Option value={mode.value}>{mode.display}</Option>
                                })}
                              </Select>

                            </div>}

                            <div className="form-group">
                              <label htmlFor="exampleInputEmail1">
                                Email address
                              </label>
                              <input
                                type="email"
                                id="userName"
                                className={`form-control form-control-lg txt-login ${loginInvalid}`}
                                aria-describedby="emailHelp"
                                onChange={handleNameChange}
                                placeholder="ex : user@example.com"
                                maxLength={50}
                                name="userName"
                                ref={register({
                                  required: {
                                    value: true,
                                    message: "Email is required",
                                  },
                                  pattern: {
                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                    message:
                                      "Email must be a valid email address pattern",
                                  },
                                })}
                              />

                              {errors.userName && (
                                <div className={"error-text"}>
                                  <small style={{ color: "red" }}>
                                    {errors.userName.message}
                                  </small>
                                </div>
                              )}
                            </div>

                            {authMode === 'password' && (
                              <div id="auth_mode_password" >
                                <div className="form-group user_password_input_container">
                                  <label htmlFor="exampleInputEmail1">
                                    Password
                                  </label>
                                  {passwordPolicy && passwordPolicy.enableForLoginPage === true ?
                                    (<input
                                      type={inputType}
                                      id="userPassword"
                                      className={`form-control form-control-lg txt-login ${passwordInvalid}`}
                                      aria-describedby="emailHelp"
                                      /* onKeyPress={onKeyPress} */
                                      onChange={handlePasswordChange}
                                      placeholder="**************"
                                      name="userPassword"
                                      minLength={passwordPolicy.minLength}
                                      maxLength={passwordPolicy.maxLength}
                                      ref={register({
                                        required: {
                                          value: true,
                                          message: "Password is required",
                                        },
                                        minLength: {
                                          value: passwordPolicy.minLength,
                                          message: `Password must be at least ${passwordPolicy.minLength} characters`,
                                        },
                                        maxLength: {
                                          value: passwordPolicy.maxLength,
                                          message: `Password must be less than ${passwordPolicy.maxLength} characters`,
                                        },
                                        pattern: {
                                          value: passwordPolicy && passwordPolicy["passwordPolicy"] ?
                                            new RegExp(passwordPolicy["passwordPolicy"], "i") : null,
                                          message: `Password must be a valid pattern. ${passwordPolicy && passwordPolicy["policyNames"] ? passwordPolicy["policyNames"] : ""}`,
                                        },
                                      })}
                                    />) :
                                    (<input
                                      type={inputType}
                                      id="userPassword"
                                      className={`form-control form-control-lg txt-login ${passwordInvalid}`}
                                      aria-describedby="emailHelp"
                                      /* onKeyPress={onKeyPress} */
                                      onChange={handlePasswordChange}
                                      placeholder="**************"
                                      name="userPassword"
                                      ref={register({
                                        required: {
                                          value: true,
                                          message: "Password is required",
                                        },
                                      })}
                                    />)
                                  }

                                  {inputType === "password" ? (<EyeOff className="user_password_input_img" onClick={() => setInputType("text")} />) : (<Eye className="user_password_input_img" onClick={() => setInputType("password")} />)}
                                  {errors.userPassword && (<div className={"error-text"}> <small style={{ color: "red" }}>  {errors.userPassword.message} </small>  </div>)}
                                </div>

                                <div className={`forgot-password-wrapper text-left`} >
                                  <Link to="/forgot-password">  Forgot password ? </Link>
                                </div>

                                <div className={`login-button-wrapper`}>
                                  <div className={`login-bnt-hide ${isLoading ? "hide-btn" : ""}`}  >
                                    <input type="submit" value="SIGN IN" className="btn btn-rounded btn-primary btn-lg btn-block" disabled={!(userName && userName.length > 0 && userPassword && userPassword.length > 0)} />
                                  </div>
                                  {navigationRestrictions && navigationRestrictions.login && navigationRestrictions.login.face_auth && navigationRestrictions.login.face_auth.active === true && (<Button type="link" block onClick={() => { enableFaceAuthMode() }} style={{ cursor: (isLoading === true || signingProgress === true) ? "not-allowed" : "pointer" }} disabled={(isLoading === true || signingProgress === true)} >Face Auth</Button>)}

                                  {isLoading && (<div className={"pre-loader-login"}> <div className="loader9" /> </div>)}
                                </div>
                              </div>
                            )}

                            {authMode !== 'password' && (
                              <div id="auth_mode_faceauth" style={{ height: signingMode === "faceAuth" ? "240px" : "0px" }}>
                                <div className="form-group" style={{ height: "205px" }}>
                                  <video id="video" ref={localVideoRef} style={{ margin: 0, width: 300, top: 225, position: "absolute" }} autoPlay muted playsInline>Video stream not available.</video>
                                  <canvas id="overlay" style={{ top: 225, right: 50, position: "absolute", width: 250, height: 180 }} />
                                  {isLoading && (
                                    <Spin style={{ margin: "auto", position: "absolute", top: "58%", right: "50%" }} />)}
                                </div>
                                <Button className="ant-btn-password" type="link" block onClick={() => enablePasswordMode()} style={{ cursor: (isLoading === true || signingProgress === true) ? "not-allowed" : "pointer" }} disabled={(isLoading === true || signingProgress === true)}>Using Password</Button>
                              </div>
                            )}


                          </form>
                        )}

                        <div className={"login-footer-wrp"}>
                          <div className={"version"}>
                            <ScrollAnimation
                              animateIn="fadeInUp"
                              animateOut="fadeOutDown"
                            >
                              version <span>{packageJson.version}</span>
                            </ScrollAnimation>
                          </div>
                          <div className={"rights version-text"}>
                            <ScrollAnimation
                              animateIn="fadeInUp"
                              animateOut="fadeOutDown"
                            >
                              © 2020 tetherfi, All rights reserved.
                            </ScrollAnimation>
                          </div>
                        </div>
                      </div>
                      <div className={"login-form-bg-sm"} />
                    </div>

                    <div
                      className={`confirm-box-socket-wrp ${isInitializeSocket ? "active-this" : "hide-me-msg"
                        }`}
                    >
                      <div className={"banner-img-center"}>
                        {/*<img src={require("../../../assets/img/emptyScreen/applicationSvg.svg")}*/}
                        {/*     alt="application"/>*/}
                      </div>
                      <div className={"confirm-message"}>
                        Application is open in another window. Click “Use Here”
                        to use Application in this window.
                      </div>
                      <div
                        className={
                          "confirm-box-footer d-flex flex-column justify-content-center "
                        }
                      >
                        <>
                          <ButtonRounded
                            title="Use Here"
                            size="btn-use-here"
                            type="btn-primary"
                            onClick={proceedToReInitialize}
                            disabled={false}
                          />
                        </>
                        <div className={"text"} onClick={closeSocketMessage}>
                          Close
                        </div>
                      </div>
                    </div>
                    <div className={"login-form-bg-lg hide-mobile"} />
                  </div>
                </div>
              </div>
            </div>
            {/*======== end  login form ========*/}

            <div className={"bg-center-with-green"}>
              <img
                src={require("../../../assets/img/bg/login/plantWithGreen.svg")}
                alt="User Avatar"
              />
            </div>
          </div>
        </div>

        <div className={"login-footer-bg-wrapper d-flex"}>
          <div className={"left-plant"}>
            <img
              src={require("../../../assets/img/bg/login/footerPlantLeft.svg")}
              alt="User Avatar"
            />
          </div>
          <div className={"transparent-plant"}>
            <img
              src={require("../../../assets/img/bg/login/transparentPlant.svg")}
              alt="User Avatar"
            />
          </div>
          <div className={"left-single-plant"}>
            <img
              src={require("../../../assets/img/bg/login/singlePlant.svg")}
              alt="User Avatar"
            />
          </div>

          <div className={"right-plant"}>
            <img
              src={require("../../../assets/img/bg/login/rightPlant.svg")}
              alt="User Avatar"
            />
          </div>
        </div>

        <div className={"sky-bg"}>
          <img
            src={require("../../../assets/img/bg/login/skyBg.svg")}
            alt="User Avatar"
          />
        </div>

        <div className={"left-round-wrp"} />
        <div className={"top-bg-round-wrp"} />

        <div className={"stars-wrapper"}>
          <img
            src={require("../../../assets/img/bg/login/stars.svg")}
            alt="User Avatar"
          />
        </div>
        <div className={"stars-wrapper-2"}>
          <img
            src={require("../../../assets/img/bg/login/starsOnly.svg")}
            alt="User Avatar"
          />
        </div>
      </section>
    </>
  );
};

function mapDispatchToProps(dispatch) {
  return {
    selected_profile_actions: bindActionCreators(
      selectedProfileActions,
      dispatch
    ),
    presence_actions: bindActionCreators(presenceActions, dispatch),
  };
}

export default connect(null, mapDispatchToProps)(SignInComponent);
