class Log {
    isConsoleLoggingEnabled; // Enables console logging, if set to false, logs will not be written to console and also will not be masked
    isDebugLoggingEnabled; // Enables debug console logging, if set to false, debug logs will not be written to console
    isLogMaskingEnabled; // Enables log masking

    //object will contain multiple key-value pairs
    //key will contain the pattern to be matched
    //Value will contain the replacement pattern
    maskingPatternsToMatch;

    constructor () {
        this.isConsoleLoggingEnabled = true;
        this.isDebugLoggingEnabled = true;
        this.isLogMaskingEnabled = true;
        this.maskingPatternsToMatch = {
            'userDetails\\\\":\\\\"{[A-Za-z0-9\\\\".:, -]*}\\\\"': 'userDetails\\":\\"{***}\\"',
            'CustomerInfo\\\\":\\\\"{[A-Za-z0-9\\\\".:, -]*}\\\\"': 'CustomerInfo\\":\\"{***}\\"',
            'customerInfo\\\\":\\\\"{[A-Za-z0-9\\\\".:, -]*}\\\\"': 'customerInfo\\":\\"{***}\\"',
            'CustomerInfo\\\":\\\"{[A-Za-z0-9\\\\".:, -]*}\\\"': 'CustomerInfo":"{***}"',
            'customerInfo\\\":\\\"{[A-Za-z0-9\\\\\\".:, -]*}\\\"': 'customerInfo":"{***}"',
            'CustomerInfo\\\":\\\"{[A-Za-z0-9\\\\".:, -]*}\\\"': 'CustomerInfo\":\"{***}\"',
            'CustomerInfo\\\":\\\"{[A-Za-z0-9\\\\\\".:, -]*}\\\"': 'CustomerInfo\\":\\"{***}\\"',
        };
    }

    info = async message => {
        if (this.isConsoleLoggingEnabled) {
            let dateTime = this.generateLogDateTime();
            message = this.maskMessage(message);
            if (message != null)
                console.log(`[${dateTime}] - ${message}`);
        }
    }

    debug = async message => {
        if (this.isConsoleLoggingEnabled && this.isDebugLoggingEnabled) {
            let dateTime = this.generateLogDateTime();
            message = this.maskMessage(message);
            if (message != null)
                console.debug(`[${dateTime}] - ${message}`);
        }
    }

    warn = async message => {
        if (this.isConsoleLoggingEnabled) {
            let dateTime = this.generateLogDateTime();
            message = this.maskMessage(message);
            if (message != null)
                console.warn(`[${dateTime}] - ${message}`);
        }
    }

    error = async (message, errorObject = null) => {
        if (this.isConsoleLoggingEnabled) {
            let dateTime = this.generateLogDateTime();
            message = this.maskMessage(message);
            if (message != null) {
                if (errorObject != null) {
                    if (typeof errorObject === 'object')
                        console.error(`[${dateTime}] - ${message}${errorObject.name != null ? `\nName: ${errorObject.name}` : ''}${errorObject.description != null ? `\nDescription: ${errorObject.description}` : ''}${errorObject.message != null ? `\nMessage: ${errorObject.message}` : ''}${errorObject.stack != null ? `\nStack: ${errorObject.stack}` : ''}`);
                    else
                        console.error(`[${dateTime}] - ${message}\nError Details: ${JSON.stringify(errorObject)}`)
                }
                else
                    console.error(`[${dateTime}] - ${message}`);
            }
        }
    }

    generateLogDateTime = () => {
        let date = new Date();
        return (date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear() + " " + date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds() + ":" + date.getMilliseconds()).toString();
    }

    maskMessage = message => {
        if (this.isLogMaskingEnabled && this.maskingPatternsToMatch != null)
            for (const [ key, value ] of Object.entries(this.maskingPatternsToMatch)) {
                try {
                    const matchedText = new RegExp(key, "gm").exec(message);
                    if (matchedText != null) {
                        message = message.replace(matchedText, value);
                    }
                } catch (error) {
                    console.error(error);
                }
            }
        return message;
    }

    addMaskingPattern = (patternToMatch, replacementPattern) => {
        if (patternToMatch != null && replacementPattern != null && patternToMatch !== "")
            this.maskingPatternsToMatch[ patternToMatch ] = replacementPattern;
    }
}

var Logger = new Log();

export { Logger };