import * as eventEmitter from "./eventEmitter";
let acwUsers = {};

function addTimer(userId, acwTime) {
    acwUsers[userId] = new acwTimer(userId, acwTime);
}

class acwTimer {

    constructor(userId, acwTime) {
        this.timer = null;
        this.userId = userId;
        this.acwTime = acwTime;
        this.acwStartTime = acwTime * 1000;
        this.currentTime = this.acwStartTime;

        this.runTimer();
    }

    runTimer = () => {

        this.timer && clearInterval(this.timer);
        let round = 1;
        this.timer = setInterval(() => {
            //console.log("============================");
            this.currentTime = this.acwStartTime - (round * 1000);
            round++;

            if (round > this.acwTime) {
                clearInterval(this.timer);

                if (acwUsers[this.userId]) {
                    delete acwUsers[this.userId];
                }
                //fire event
                eventEmitter.fireEvent("acw_end", this.userId);
            }

        }, 1000);

    }

    getTime = () => {
        return this.currentTime;
    }

}

export { acwUsers, addTimer };