export class TimelineInteraction {
    constructor(customerId, obj) {

        this.customerId = customerId;
        this.id = obj.details.id;
        this.fromTime = obj.details.fromTime;
        this.toTime = obj.details.toTime;
        this.isInteractionEscalated = obj.details.isInteractionEscalated;
        this.hasTransfer = obj.details.hasTransfer;
        this.hasConference = obj.details.hasConference;
        this.channel = obj.details.channel;
        this.intent = obj.details.intent;
        this.participants = obj.details.participants;
        this.duration = obj.details.duration;
        this.disposition = obj.details.disposition;
    }
}