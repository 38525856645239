import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';
import { connect } from "react-redux";
import * as auth from "../../../utils";
import { ButtonRounded, ProfileImage, MenuIcon, SubTitle, RadioButton } from '../../common';
import { Plus, MessageCircle, Calendar, Bell, Instagram } from 'react-feather';
import presenceStateMap from "../../../config/json/presenseMap";
import ScrollAnimation from 'react-animate-on-scroll';
import { Popover } from 'antd';
import WorkspaceSwitcher from "../../common/WorkspaceSwitcher/WorkspaceSwitcher";
import {
    Link
} from "react-router-dom";
import navigationRestrictions from "../../../config/navigationRestrictions";
import liveChatClient from "../../../lib";
import { bindActionCreators } from "redux";
import * as presenceActions from "../../../redux/actions/presenceActions";
import * as selectedProfileActions from "../../../redux/actions/selectedProfileActions";
import phoneEngine from "../phone/phoneEngine/phoneEngineHandler";
import './styles/left-navigation.scss';
import './styles/responsive-left-navigation.scss';
import AuthService from "../../../service/authService";
import { __APP_IDENTITY_PROVIDER__ } from "../../../config/baseUrls";
import { message } from "antd/lib/index";
import * as logger from "../../../lib/logSdk/logger";
import * as phoneConfig from "../../../config/phoneConfig";
import * as whiteboardActions from '../../../redux/actions/whiteboardActions';

let status = "offline";
let myAvailabilityStatus = "offline";

const authService = new AuthService();
let isAllowedVideoCall = phoneConfig ? phoneConfig.isAllowedVideoCall : false;

function LeftSideNavigationComponent(props) {

    let userType = auth.getUserType();
    const [availabilityStatus, setAvailabilityStatus] = useState("offline");

    useEffect(() => {
        console.log("LeftSideNavigationComponent", "Calling useEffect Method");
        const customData = auth.getCustomData();
        if (customData && customData.isVideo !== null) {
            isAllowedVideoCall = customData.isVideo;
        }
    }, []);

    if (props.presence_user) {
        let tempStatus = props.presence_user.status ? props.presence_user.status : "offline";
        status = presenceStateMap[tempStatus];

        if (!status) {
            status = "offline";
        }
    }

    let fullName = auth.getDisplayName();


    const setAvailability = status => {
        try {
            //setAvailabilityStatus(status);
            let presenceObj = { userid: auth.getUserId(), status };
            props.presence_actions.sendPresenceSuccess(presenceObj);
            liveChatClient.sendPresenceNotification(JSON.stringify(presenceObj));
        } catch (error) {
            console.error("LeftSideNavigation", "setAvailability", error);
            logger.error("LeftSideNavigation", "setAvailability", error.message);
        }
    };

    if (props.presence_user) {
        let defaultstatus = props.presence_user.status
            ? props.presence_user.status
            : "offline";
        myAvailabilityStatus = presenceStateMap[defaultstatus] ? presenceStateMap[defaultstatus] : defaultstatus;
    }

    const logout = () => {
        try {

            liveChatClient.logoutFromSystem("", "NORMAL", "").then(response => {
                __APP_IDENTITY_PROVIDER__ === 'GUARDIAN' && authService.logout(auth.getRefreshToken(), "NORMAL");
                liveChatClient.endSession();
                phoneEngine.phoneFunctions.hangup("caller", "callee");
                auth.userLogOut();
                setTimeout(() => {

                    if (__APP_IDENTITY_PROVIDER__ === 'IAM') {

                        props.history.push("/logout");

                    } else {
                        props.history.push("/");
                        window.location.reload();
                        console.log("LeftSideNavigationComponent", "uireload", "window.location.reload");
                    }


                }, 2000);

            }).catch(error => {
                console.error("LeftSideNavigation", "logout-logoutFromSystem", error);
                logger.error("LeftSideNavigation", "logout-logoutFromSystem", error.message);
                message.error("Error in logout");
            });

        } catch (error) {
            console.error("LeftSideNavigation", "logout", error);
            logger.error("LeftSideNavigation", "logout", error.message);
            message.error("Error in logout");
        }
    };

    const content = (
        <>
            {navigationRestrictions.panels.profile_hover.active && <div className={'my-profile-panel-wrp'}>

                {/*<div className={'my-profile-header-wrp d-flex  justify-content-end'}>*/}
                {/*    <div className={'sign-out'}>*/}
                {/*        Sign Out*/}
                {/*    </div>*/}
                {/*</div>*/}

                {/*======  My profile =======*/}
                {navigationRestrictions.account.my_profile.active && <Link to="/console/my-profile">
                    <div className={'panel-menu-wrapper with-link row-one'} onClick={() => {
                        props.selected_profile_actions.profileSelected({});
                        props.onClickCloseInfoPanel();
                    }}>
                        <div className={'menu-title d-flex align-items-center'}>
                            My profile
                        </div>
                    </div>
                </Link>}

                {/*======  workspace =======*/}
                {navigationRestrictions.account.workspaces.active && userType !== 'MEETING' && <div className={'panel-menu-wrapper'}>
                    <WorkspaceSwitcher />

                </div>}

                {/*====== Availability =======*/}
                {navigationRestrictions.presence.set_presence.active && userType !== 'MEETING' && <div className={'panel-menu-wrapper'}>
                    <div className={'menu-title'}>
                        Availability
                    </div>

                    <div className={`work-space-body`}>
                        <div className={"d-flex flex-wrap"}>
                            <div
                                className={"radio-btn-wrp  mleft-5"}
                                onClick={() => setAvailability("busy")}>
                                <RadioButton
                                    size={"sm"}
                                    text={"Busy"}
                                    selected={myAvailabilityStatus === "busy"}
                                />
                            </div>
                            <div
                                className={"radio-btn-wrp mleft-5"}
                                onClick={() => setAvailability("available")}
                            >
                                <RadioButton
                                    size={"sm"}
                                    text={"Available"}
                                    selected={myAvailabilityStatus === "online"}
                                />
                            </div>
                            <div
                                className={"radio-btn-wrp mleft-5"}
                                onClick={() => setAvailability("offline")}
                            >
                                <RadioButton
                                    size={"sm"}
                                    text={"Offline"}
                                    selected={myAvailabilityStatus === "offline"}
                                />
                            </div>
                        </div>
                    </div>
                </div>}

                {navigationRestrictions.account.logout.active && <div className={'panel-menu-wrapper'}>
                    <div onClick={logout} className={"d-flex log-out  align-items-center"}>
                        Log Out
                    </div>
                </div>}

            </div>}

            {/*====== background =======*/}
            <div className={'profile-menu-bg-top'}>
                <img
                    src={require("../../../assets/img/menuBg.svg")}
                    alt="User Avatar"
                />
            </div>
            <div className={'profile-menu-bg-left'}>
                <img
                    src={require("../../../assets/img/menuBg.svg")}
                    alt="User Avatar"
                />
            </div>
        </>
    );


    return (

        <>
            {!navigationRestrictions.profile.summerized_info.active &&
                <section className={`left-side-navigation-wrapper`}>
                    <Popover content={content} placement="rightTop">
                        <div className={`profile-wrp d-flex justify-content-center hover-action animated fadeInLeft`}>
                            {/*<Link to="/console/my-profile"></Link>*/}
                            <ProfileImage
                                src={props.my_profile && props.my_profile.avatarURL ? props.my_profile.avatarURL : null}
                                status={status}
                                firstLatter={fullName ? fullName.split(' ').map(fullName => fullName[0]).join('').toUpperCase() : ""}
                                padding="7px"
                                fontSize="1.2rem" />
                        </div>
                    </Popover>


                    <div className={`left-menu-icon-wrapper`}>
                        {navigationRestrictions.group.active && userType !== 'MEETING' &&
                            <div className={'left-menu-icon d-flex justify-content-center'}>
                                <ScrollAnimation delay={50}
                                    animateIn="fadeInLeft"
                                    animateOut="fadeOutRight">
                                    <Link to="/console/new">
                                        <MenuIcon
                                            title={"Create Group"}
                                            icon={<Plus />}
                                            size={'sm'}
                                            status={props.activeMenu === "new" ? 'active' : null}
                                            onClick={(() => {
                                                props.onPressMenuIcon('new');
                                                props.selected_profile_actions.profileSelected({});
                                            })} />
                                    </Link>
                                </ScrollAnimation>
                            </div>}
                        {navigationRestrictions.chat.active &&
                            <div className={'left-menu-icon d-flex justify-content-center'}>
                                <ScrollAnimation delay={100}
                                    animateIn="fadeInLeft"
                                    animateOut="fadeOutRight">
                                    <Link to="/console">
                                        <MenuIcon
                                            title={"Chat"}
                                            icon={<MessageCircle />}
                                            size={'sm'}
                                            status={props.activeMenu === "chat" ? 'active' : null}
                                            onClick={(() => props.onPressMenuIcon('chat'))} />
                                    </Link>
                                </ScrollAnimation>
                            </div>}

                        {navigationRestrictions.events.active && userType !== 'MEETING' &&
                            <div className={'left-menu-icon d-flex justify-content-center'}>
                                <ScrollAnimation delay={150}
                                    animateIn="fadeInLeft"
                                    animateOut="fadeOutRight">
                                    <Link to="/console/meeting">
                                        <MenuIcon
                                            title={"Meetings"}
                                            icon={<Calendar />}
                                            size={'sm'}
                                            status={props.activeMenu === "meeting" ? 'active' : null}

                                            onClick={(() => {
                                                props.whiteboard_actions.minimizeWhiteboard();
                                                props.onPressMenuIcon('meeting');
                                                props.selected_profile_actions.profileSelected({});
                                            })} />
                                    </Link>
                                </ScrollAnimation>
                            </div>}

                        {navigationRestrictions.notification.active && userType !== 'MEETING' &&
                            <div className={'left-menu-icon d-flex justify-content-center'}>
                                <ScrollAnimation delay={200}
                                    animateIn="fadeInLeft"
                                    animateOut="fadeOutRight">
                                    <Link to="/console/notification">
                                        <MenuIcon
                                            title={"Notifications"}
                                            icon={<Bell />}
                                            size={'sm'}
                                            status={props.activeMenu === "notification" ? 'active' : null}
                                            onClick={(() => {
                                                props.whiteboard_actions.minimizeWhiteboard();
                                                props.onPressMenuIcon('notification');
                                                props.selected_profile_actions.profileSelected({});
                                            })} />
                                    </Link>
                                </ScrollAnimation>
                            </div>}

                        {isAllowedVideoCall && userType !== 'MEETING' && props.call && props.call.callStatus === "Idle" && navigationRestrictions.calls.active && navigationRestrictions.calls.video_calls.active && phoneConfig.basic_config.calls.selfView.active === true &&
                            <div className={'left-menu-icon d-flex justify-content-center'} title="Self View">
                                <ScrollAnimation delay={200}
                                    animateIn="fadeInLeft"
                                    animateOut="fadeOutRight">
                                    <MenuIcon
                                        icon={<Instagram />}
                                        size={'sm'}
                                        status={props.activeMenu === "notification" ? 'active' : null}
                                        onClick={(() => props.onClickSelfView())} />
                                </ScrollAnimation>
                            </div>}

                    </div>

                    <div className={'left-menu-bg'}>
                        <img src={require("../../../assets/img/leftMemuBG.svg")}
                            alt="User Avatar" />
                    </div>


                </section>
            }
        </>
    );
}

LeftSideNavigationComponent.propTypes = {
    presence_user: PropTypes.object,
    presence_actions: PropTypes.object.isRequired,
    call: PropTypes.object.isRequired,
};

function mapDispatchToProps(dispatch) {
    return {
        presence_actions: bindActionCreators(presenceActions, dispatch),
        selected_profile_actions: bindActionCreators(
            selectedProfileActions,
            dispatch
        ),
        whiteboard_actions: bindActionCreators(whiteboardActions, dispatch)
    };
}

function mapStateToProps(state) {
    return {
        presence_user: state.presence.data[auth.getUserId()],
        my_profile: state.team[auth.getUserId()],
        call: state.call,
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LeftSideNavigationComponent);
