import * as types from "../actions/actionTypes";
import produce from "immer";

export const chatTextPanelReducer = produce((draft = {}, action) => {

    switch (action.type) {
        case types.SET_TEXTAREA_MESSAGE:
            {
                draft[action.username] = {
                    message: action.message,
                    reply_message: action.reply_message
                }
                return draft;
            }
        case types.REMOVE_TEXT_PANEL_INFO:
            {
                delete draft[action.username];
                return draft;
            }
        default:
            return draft;
    }

});
