import React, { useState, useEffect, useRef } from 'react';
import { useHistory, useLocation } from "react-router-dom";
import moment from 'moment';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import uuid from 'uuid/v1';
import PropTypes from "prop-types";
import {
    ButtonRounded, ProfileImage, TextBoxWithIcon, MenuIcon,
    PanelTitle, SearchResult, StarredMessage,
    StarredMessageV2
} from '../../common';
import { Check, ArrowRight, Smile, X } from 'react-feather';
import { Route, Switch } from "react-router-dom";
import ConsoleComponent from "../console/MainConsole";
import CreatNewPanelComponent from "../CreateNewPanel/CreatNewPanel";
import MyProfilePanelComponent from "../MyProfilePanel/MyProfilePanel";
import { Scrollbars } from 'react-custom-scrollbars';
import * as ChatService from "../../../service/chatService";
import * as fileService from "../../../service/fileService";
import { getUserId, getUser, getWorkspace, getDisplayName } from "../../../utils";
import * as chatSearchActions from "../../../redux/actions/chatSearchActions";
import * as chatActions from "../../../redux/actions/chatActions";
import { subscribeToEvents } from "../../../utils/eventEmitter";
import renderState from "../../../service/renderStateHandler";
import * as starringActions from '../../../redux/actions/starringActions'
import { filterProfanity } from "../../../utils/profanityFilter";
import { notification } from "antd/lib/index";
import { __APP_INTERACTION_MODE__ } from "../../../config/baseUrls";

let scrollBarStarring = null;


function StarredPanel(props) {

    const [searchMessage, setSearchMessage] = useState("");

    let loc = useLocation();
    let history = useHistory();

    function onPressChatFilterIcon(_filter) {
        console.log('event fire...');
        console.log(_filter);
    }

    const sendForwardMessages = (users, msgObj) => {

        let profanity = filterProfanity(msgObj.message_content);

        if (profanity.isProfane) {
            notification.error({
                message: `Message Blocked`,
                description:
                    `Your message : "${profanity.message}", is flagged by profanity filter and cannot be sent`,
                placement: 'bottomRight',
                style: {
                    backgroundColor: "#ff751a",
                    color: "White"
                },
            });
            ChatService.sendProfanityMessage(msgObj.message_content, props.selected_profile.InteractionId);

        } else {

            for (let user of users) {
                let uniqueId = uuid();

                let toId = user.conversation_type === 3 ? user.Id : user.userId;

                let msg = {
                    v: 1,
                    mid: uniqueId,
                    sid: uniqueId,
                    channel: "WEBCHAT",
                    message_type: msgObj.message_type,
                    from: {
                        id: getUserId(),
                        name: getDisplayName()
                    },
                    to: {
                        id: toId,
                        name: toId
                    },
                    created_at: Date.now(),
                    message_content: msgObj.message_content,
                    message_action: 3,
                    related_message: msgObj,
                    other: null,
                    media: msgObj.media,
                    isUploading: "DONE"
                };

                msg.conversation_type = user.conversation_type;
                ChatService.sendChatMessage(user.conversation_type, msgObj.message_content, toId, msg, false, user.InteractionId);
                props.chat_actions.sendNewMessageSuccess(msg);

                if (msgObj.media) {
                    msgObj.media.forEach(media => {
                        let file_id = null;
                        if (media.mimeType.startsWith("image") && media.thumbnail_url) {
                            let match = media.thumbnail_url.match(new RegExp("stream/thumbnail/" + "(.*)" + "/225"));
                            if (match && match.length === 2) {
                                file_id = match[1];
                            }
                        } else {
                            let match = media.content_url.match(new RegExp("stream/media/" + "(.*)"));
                            if (match && match.length === 2) {
                                file_id = match[1];
                            }
                        }
                        fileService.tagConversationToFile(user.conversation_type === 3 ? "" : msg.from.id, msg.to.id, getWorkspace(), file_id, media.content_url, media.mimeType, media.content_size, media.content_title);

                    });
                }

                if (msgObj.message_type === "html/link") {
                    fileService.saveLink(msg.conversation_type === 3 ? "" : msg.from.id, msg.to.id, msgObj.message_content, getWorkspace(), uniqueId);
                }
            }

        }

    }

    const starMessage = (message, isStarring) => {
        if (message) {
            props.chat_actions.starUnstarMessage(message.sender_id, message, isStarring, props.selected_profile.username);
        }
    }

    useEffect(() => {
        subscribeToEvents("clear_star_search", data => {
            setSearchMessage("");
            //setStarredMessages({ status: "NOT_STARTED", searchData: [] });
        });
        //load data
        props.starring_actions.getStarredMessages(props.selected_profile.conversation_type, Date.now(), getUserId(), props.selected_profile.username);

        /* subscribeToEvents("clear_chat_search", data => {
            setSearchMessage("");
            setSearchResult({status: "NOT_STARTED", searchData: []});
        }); */
    }, [props.selected_profile]);

    const onCloseSearch = () => {
        renderState.focusToLastRead = true;
        renderState.scrollReadyState = false;
        props.chat_search_actions.resetSearchMessages();

        let matchArr = loc.pathname.match(/^(.*?)\/profile/);
        if (matchArr && matchArr.length >= 1) {
            history.push(loc.pathname.match(/^(.*?)\/profile/)[0]);
        }
        //props.onClickCloseInfoPanel();
    }

    const starredResultClickHandler = (timestamp) => {
        //dispatch
        if (props.selected_profile && props.selected_profile.username) {

            let userId = getUserId();

            props.chat_search_actions.getInitialSearchMessages(props.selected_profile.conversation_type, userId, props.selected_profile.username, timestamp, 20);
        }
    }

    const onScrollStop = () => {

        let values = scrollBarStarring ? scrollBarStarring.getValues() : null;

        if (values.top > 0.999) {
            //load more
            let last = props.starred_messages[Object.keys(props.starred_messages)[Object.keys(props.starred_messages).length - 1]];

            if (last) {
                props.starring_actions.getNextPageOfStarredMessages(props.selected_profile.conversation_type, last.created_at, getUserId(), props.selected_profile.username);
            }
        }
    }

    const onChangeHandler = event => {
        if (props.selected_profile && props.selected_profile.username) {
            setSearchMessage(event.target.value);
        }
    };

    /* let keyPressHandler = event => {
        if (props.selected_profile && props.selected_profile.username) {
            if (event.key === "Enter" && searchMessages) {

                //call api
                searchMessages(getUserId(), props.selected_profile.username, props.selected_profile.conversation_type, searchMessages).then(searchRes => {
                    setSearchResult({ status: "DONE", searchData: searchRes });

                }).catch(ex => {
                    setSearchResult({ status: "DONE", searchData: [] });
                });
            }
        }
    }; */

    let noDataFound = <></>;

    let starredMessagesView;

    if (props.starred_messages !== undefined)
        if (Object.keys(props.starred_messages).length > 0) {
            starredMessagesView = Object.values(props.starred_messages).map(message => {

                let regex = RegExp(searchMessage, "i");

                if (!searchMessage || (searchMessage && regex.test(message.message_content))) {

                    let date = moment.unix(message.created_at / 1000).format("DD-MM-YYYY");
                    let displayDate = moment().format("DD-MM-YYYY") === date ? "TODAY" : date;

                    let fullName = message.mid;
                    let avatarURL = null;

                    let fromId = message.sender_id;

                    let fromTeamMember = null;

                    if (message.from && message.from.id) {
                        fromId = message.from.id;
                    }
                    if (props.selected_profile.conversation_type === 4 && props.interactions && props.interactions[props.selected_profile.username] && props.selected_profile.InteractionInfo && __APP_INTERACTION_MODE__ === 'USER') {
                        fromTeamMember = props.interactions[props.selected_profile.username].UserDetails[fromId];
                    } else {
                        fromTeamMember = props.team && fromId ? props.team[fromId] : null;
                    }

                    if (fromTeamMember) {

                        fullName = `${fromTeamMember.firstName} ${fromTeamMember.lastName}`;
                        avatarURL = fromTeamMember.avatarURL;
                    }

                    return (
                        <StarredMessage
                            date={displayDate}
                            UserUrl={avatarURL}
                            fullName={fullName}
                            messageType={message.message_type}
                            msg={message.message_content}
                            msgObj={message}
                            onClickOpenGallery={props.onClickOpenGallery}
                            onClickReplayMessage={props.onClickReplayMessage}
                            sendForwardMessages={sendForwardMessages}
                            onClickForwardMessage={props.onClickForwardMessage}
                            onClickStar={starMessage}
                            onClickContent={starredResultClickHandler} />
                    )
                }
            })
        } else {
            noDataFound = <div>No Data Found</div>;
        }
    else
        noDataFound = <div>No Data Found</div>;


    return (
        <>
            <div className={`chat-info-wrapper ${props.isOpenInfoPanel ? 'show-this' : 'hide-this'}`}>


                <div className={'ch-header-wrapper'}>
                    <>
                        <div className={'modal-header d-flex justify-content-between '}>
                            <PanelTitle title={props.title} />
                            <MenuIcon icon={<X />}
                                onClick={onCloseSearch}
                                size={'sm'} />
                        </div>
                    </>
                </div>

                <div className={'ch-body-wrapper search-panel'}>
                    <div className={`chat-search-wrapper`}>
                        <div className={'search-wrapper d-flex align-items-center '}>
                            <TextBoxWithIcon onChangeHandler={onChangeHandler}
                                onKeyPressHandler={searchMessage}
                                textValue={null}
                                style={'white'}
                                placeholder={'Search'}
                                size={'form-control-lg'} />
                        </div>

                        <Scrollbars
                            autoHide thumbMinSize={0}
                            onScrollStop={onScrollStop}
                            ref={c => {
                                scrollBarStarring = c;
                            }}
                            style={{ width: 300, height: props.viewPointHeight - 200 }}>
                            {/*{noDataFound}*/}
                            <div className={'search-panel-body-wrapper mt-3'}>
                                {starredMessagesView}

                                {/*<StarredMessageV2*/}
                                {/*    date={'yesterday'}*/}
                                {/*    UserUrl={'https://source.unsplash.com/wmbbbU0LUG8/150x150'}*/}
                                {/*    fullName={'Full Name'}*/}
                                {/*    starredDate={'yesterday'}*/}
                                {/*    messageType={'text'}*/}
                                {/*    msg={'Lorem Ipsum is simply dummy  text of the printing and type setting'}/>*/}


                                {/*<StarredMessage*/}
                                {/*    date={'yesterday'}*/}
                                {/*    UserUrl={'https://source.unsplash.com/wmbbbU0LUG8/150x150'}*/}
                                {/*    fullName={'Full Name'}*/}
                                {/*    starredDate={'yesterday'}*/}
                                {/*    messageType={'text'}*/}
                                {/*    msg={'Lorem Ipsum is simply dummy  text of the printing and type setting'}/>*/}
                                {/*<StarredMessage*/}
                                {/*    UserUrl={'https://source.unsplash.com/wmbbbU0LUG8/150x150'}*/}
                                {/*    date={'yesterday'}*/}
                                {/*    messageType={'img'}*/}
                                {/*    imgUrl={'https://source.unsplash.com/FJL5wYSD0Qw/150x150'}*/}
                                {/*    fullName={'Full Name'}*/}
                                {/*    msg={'Lorem Ipsum is simply dummy  text of the printing and type setting'}/>*/}
                                {/*<StarredMessage*/}
                                {/*    UserUrl={'https://source.unsplash.com/wmbbbU0LUG8/150x150'}*/}
                                {/*    date={'yesterday'}*/}
                                {/*    messageType={'audio'}*/}
                                {/*    imgUrl={null}*/}
                                {/*    fullName={'Full Name'}*/}
                                {/*    msg={'Lorem Ipsum is simply dummy  text of the printing and type setting'}/>*/}
                                {/*<StarredMessage*/}
                                {/*    UserUrl={'https://source.unsplash.com/wmbbbU0LUG8/150x150'}*/}
                                {/*    date={'yesterday'}*/}
                                {/*    messageType={'video'}*/}
                                {/*    imgUrl={null}*/}
                                {/*    fullName={'Full Name'}*/}
                                {/*    msg={'Lorem Ipsum is simply dummy  text of the printing and type setting'}/>*/}
                                {/*<StarredMessage*/}
                                {/*    UserUrl={'https://source.unsplash.com/wmbbbU0LUG8/150x150'}*/}
                                {/*    date={'yesterday'}*/}
                                {/*    messageType={'pdf'}*/}
                                {/*    imgUrl={null}*/}
                                {/*    fullName={'Full Name'}*/}
                                {/*    msg={'Lorem Ipsum is simply dummy  text of the printing and type setting'}/>*/}
                                {/*<StarredMessage*/}
                                {/*    UserUrl={'https://source.unsplash.com/wmbbbU0LUG8/150x150'}*/}
                                {/*    date={'yesterday'}*/}
                                {/*    messageType={'url'}*/}
                                {/*    imgUrl={null}*/}
                                {/*    fullName={'Full Name'}*/}
                                {/*    msg={'Lorem Ipsum is simply dummy  text of the printing and type setting'}/>*/}
                            </div>

                        </Scrollbars>
                    </div>
                </div>
            </div>
        </>
    );
}

function mapDispatchToProps(dispatch) {
    return {
        chat_search_actions: bindActionCreators(chatSearchActions, dispatch),
        chat_actions: bindActionCreators(chatActions, dispatch),
        starring_actions: bindActionCreators(starringActions, dispatch),
    };
}

function mapStateToProps(state) {
    return {
        selected_profile: state.selected_profile,
        team: state.team,
        starred_messages: state.starred_messages,
        interactions: state.interactions
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(StarredPanel);
