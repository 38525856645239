import React from 'react';
import PropTypes from 'prop-types';
import PanelTitle from "../PanelTitle/PanelTitle";

//online
//offline
//busy
//other
const TextBoxWithLabel = ({
                              placeholder, size, style, labelStyle, labelText,
                              labelDisable, required, disable, type, value, onChange
                          }) => (
    <>
        <div className={'d-flex flex-column'}>
            {!labelDisable && required &&
            <label className={`${labelStyle}`}>{labelText} <span>*</span></label>
            }
            {!labelDisable && !required &&
            <label className={`${labelStyle}`}>{labelText}</label>
            }
            <input type={type}
                   disabled={disable}
                   className={`form-control ${size} ${style} ${disable ? 'disabled' : 'active'}`}
                   placeholder={placeholder} value={value}
                   onChange={onChange}/>
        </div>
    </>
);

TextBoxWithLabel.propTypes = {
    placeholder: PropTypes.string.isRequired,
};

PanelTitle.defaultProps = {
    placeholder: 'Type here...',
    labelDisable: false,
    required: false,
    type: 'text'
};


export default TextBoxWithLabel;
