import * as types from "../actions/actionTypes";
import produce from "immer";
import renderState from "../../service/renderStateHandler";

export const chatSearchReducer = produce((draft = { messages: {}, search_mode: false }, action) => {

    switch (action.type) {
        case types.RESET_SEARCH_MESSAGES: {
            draft.messages = {};
            draft.search_mode = false;
            draft.previous_timestamp = null;
            draft.next_timestamp = null;
            return draft;
        }
        case types.SEARCH_INITIAL_MESSAGES_SUCCESS: {
            if (action.chats) {
                draft.messages = action.chats.messages;
                draft.search_mode = true;
                draft.is_initial_search = true;
                draft.next_timestamp = action.chats.next_timestamp;
                draft.previous_timestamp = action.chats.previous_timestamp;
                draft.search_anchor_point = action.chats.search_anchor_point;
                draft.anchor_position = "center";
                return draft;
            } else {
                return draft;
            }

        }
        case types.SEARCH_AFTER_MESSAGES_SUCCESS:
            {
                if (action.chats) {

                    draft.messages = { ...draft.messages, ...action.chats.messages };
                    draft.search_mode = true;
                    draft.is_initial_search = false;
                    draft.next_timestamp = action.chats.next_timestamp;
                    draft.search_anchor_point = action.chats.search_anchor_point;
                    draft.anchor_position = "end";
                    return draft;
                } else {
                    return draft;
                }
            }
        case types.SEARCH_BEFORE_MESSAGES_SUCCESS:
            {
                if (action.chats) {

                    draft.messages = { ...action.chats.messages, ...draft.messages };
                    draft.search_mode = true;
                    draft.is_initial_search = false;
                    draft.previous_timestamp = action.chats.previous_timestamp;
                    draft.search_anchor_point = action.chats.search_anchor_point;
                    draft.anchor_position = "start";

                    return draft;

                } else {
                    return draft;
                }

            }
        case types.STAR_UNSTAR_SEARCH_MESSAGE_SUCCESS:
            {
                if (draft.messages[action.mid]) {
                    draft.messages[action.mid].is_starred = action.is_starred;
                }

                return draft;
            }
        default:
            return draft;
    }

});
