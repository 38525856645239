export class contactsApi {

    constructor (config, livechat) {
        this._livechat = livechat;
        this.contactServiceUrl = config ? config.contactServiceUrl : "";
    }

    /**
    * api-method
    * Retrieves contact information for given user
    * @param {string} userId User Id of the user to get contact details of (Cannot be blank)
    * @param {string} workspace Workspace name the user has logged in to (Cannot be blank)
    * @returns {Promise} Promise object an object of contact information
    */
    getContactInfoById = (userId, workspace) => {

        let contactServiceUrl = this.contactServiceUrl;

        return new Promise((resolve, reject) => {
            let url = `${contactServiceUrl}/contact/user/${userId}/${workspace}`;

            fetch(url, {
                method: "GET",
                headers: {
                    "authorization": `Bearer ${this._livechat.getAuthToken()}`,
                    "Content-Type": "application/json"
                }
            }).then(res => {
                return res.json();

            }).then(data => {
                if (data && data.isSuccess) {

                    resolve(data.result);
                }
                else {
                    reject(new Error(`Error occurred : ${data.message}`));
                }
            }).catch(ex => {
                reject(ex);

            });

        })
    }

    /**
    * api-method
    * Retrieves all other users contact information related to given user
    * @param {string} userId User Id of the user (Cannot be blank)
    * @param {string} workspace Workspace name the user has logged in to (Cannot be blank)
    * @returns {Promise} Promise object an array of objects with contact information
    */
    getRelatedContactsForUser = (userId, workspace) => {

        let contactServiceUrl = this.contactServiceUrl;

        return new Promise((resolve, reject) => {
            let url = `${contactServiceUrl}/presence/user/${userId}/${workspace}`;

            fetch(url, {
                method: "GET",
                headers: {
                    "authorization": `Bearer ${this._livechat.getAuthToken()}`,
                    "Content-Type": "application/json"
                }
            }).then(res => {
                return res.json();

            }).then(data => {
                if (data && data.isSuccess) {
                    resolve(data.result);
                }
                else {
                    reject(new Error(`Error occurred : ${data.message}`));
                }
            }).catch(ex => {
                reject(ex);
            })
        })
    }


}