import React, { useEffect, useState } from "react";
import phoneEngine from "../../pages/phone/phoneEngine/phoneEngineHandler";
import { useSelector, useDispatch } from "react-redux";
import { onParticipatorStatusChange } from "../../../redux/actions/callControlsActions";

const StatusComponent = ({ userId, userName }) => {
  const state = useSelector((state) => state);
  const [callState, setCallState] = useState("");
  useEffect(() => {
    try {
      if (userId === state.callControls.userId) {
        setCallState(state.callControls.status);
      }
    } catch (ex) {
      console.error(ex);
    }
  }, [state.callControls.controls.isParticipatorStatusChange]);

  return (
    <div className={`conference-status-wrapper`}>
      <div>{userName}</div>
      <div>{callState}</div>
    </div>
  );
};

const ParticipatorStatus = () => {
  const state = useSelector((state) => state);
  const dispatch = useDispatch();
  const [participators, setParticipators] = useState({});
  const [showList, setShowList] = useState(true);
  useEffect(() => {
    try {
    } catch (error) {
      console.error("ParticipatorStatus", "useEffect", error);
    }
  }, []);

  useEffect(() => {
    try {
      if (state.callControls.userId) {
        addParticipator(
          state.callControls.userId,
          state.callControls.controls.status
        );
        if (!showList) setShowList(true);
      }
    } catch (error) {
      console.error(
        "ParticipatorStatus",
        "useEffect",
        "state.callControls.controls.isParticipatorAdded",
        error
      );
    }
  }, [state.callControls.controls.isParticipatorAdded]);

  const addParticipator = (userId) => {
    try {
      if (!participators[userId]) {
        let userName = "Unknown";
        if (state.team) {
          const user = state.team[userId];
          if (user) {
            userName = `${user.firstName} ${user.lastName}`;
          }
        }
        let tempParticipators = { ...participators };
        tempParticipators[userId] = { userName, userId };
        tempParticipators["userId111"] = {
          userName: "userId111",
          userId: "userId111",
        };
        tempParticipators["userId11ssss1"] = {
          userName: "userId11ssss1",
          userId: "userId11ssss1",
        };
        setParticipators({ ...tempParticipators });

        setTimeout(function () {
          broadcastStatus(userId, "aaaaaaaaaaaaaaaaaaaaaaaaaaaaaa");
          broadcastStatus("userId111", "bbbbbbbbbbbbbbbbbbbbbbbbb");
          broadcastStatus("userId11ssss1", "ccccccccccccccccccccc");
        }, 3000);
      }
    } catch (error) {
      console.error("ParticipatorStatus", "addParticipator", error);
    }
  };

  const broadcastStatus = (userId, status) => {
    dispatch(onParticipatorStatusChange({ userId, status }));
  };

  phoneEngine.phone.subscribe.stateEntryEvents(
    "ParticipatorStatus",
    (state, payload) => {
      try {
        console.log(
          "ParticipatorStatus",
          "stateEntryEvents",
          `state:${state}, payload: ${JSON.stringify(payload)}`
        );

        if (payload && state === "SIGNALING") {
          switch (payload.command) {
            case "SIGNALINGDISCONNECTED": {
              broadcastStatus(payload.userId, "Connected");
              break;
            }
            case "SIGNALINGRINGING": {
              broadcastStatus(payload.userId, "Ringing");
              break;
            }
            case "SIGNALINGCONNECTING": {
              broadcastStatus(payload.userId, "Connecting");
              break;
            }
            case "SIGNALINGANSWERING": {
              broadcastStatus(payload.userId, "Answering");
              break;
            }
            case "SIGNALINGANSWERED": {
              broadcastStatus(payload.userId, "Answered");
              break;
            }
            default: {
              break;
            }
          }
        }
      } catch (error) {
        console.error(
          "ParticipatorStatus",
          "stateEntryEvents",
          `state:${state}, payload: ${JSON.stringify(payload)}`,
          error
        );
      }
    }
  );

  return (
    <>
      {/* {Object.keys(participators).length !== 0 && (
        <div
          className={"conference-participation-status-wrapper"}
          style={{
            height: Object.keys(participators).length * 62,
          }}
        >
          {Object.values(participators).map((item) => {
            return (
              <StatusComponent userId={item.userId} userName={item.userName} />
            );
          })}
        </div>
      )} */}
    </>
  );
};

export default ParticipatorStatus;
