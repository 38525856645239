import * as types from "../actions/actionTypes";
import produce from "immer";
import { Target } from "react-feather";

export const externalUserProfileReducer = produce((draft = null, action) => {
    switch (action.type) {
        case types.PROFILE_FOUND:
            {
                draft = action.profile;
                return draft;
            }

        //profile remove
        case types.PROFILE_REMOVE:
            {             
                draft=null;
                return draft;
            }
             
        //remove contact
        case types.CONTACT_REMOVE:
            {
                let index = draft.contacts.findIndex( element => {
                    if (element.id === action.profileId) {
                      return true;
                    }
                  });
;
                if (index > -1) {
                    draft.contacts.splice(index, 1);
                }
                return draft;  
            }
            

        default:
            return draft;
    }
});

