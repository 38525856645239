import * as types from "../actions/actionTypes";
import produce from "immer";

export const templateReducer = produce((draft = {}, action) => {
    switch (action.type) {

        case types.TEMPLATES_LOADED:
            draft = action.templates;
            break;
        default: break;
    }

    return draft;
});