import mask from 'key-del';
import { __APP_MASK_CUSTOMER_DATA__ } from "../config/baseUrls";

const maskProperties = (obj, fields) => {
    try {
        if (__APP_MASK_CUSTOMER_DATA__ === "YES") {
            let deepCopy = "";
            if (typeof (obj) === 'string') {
                deepCopy = JSON.parse(obj);
            } else if (typeof (obj) === 'object') {
                deepCopy = JSON.parse(JSON.stringify(obj));
            }

            let maskedObj = mask(deepCopy, fields);
            return maskedObj;
        }
        return obj;

    } catch (ex) {
        return "ERROR MASKING OBJECT";
    }


}

export default maskProperties;