import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button } from "../../common";

const TextBoxWithButton = ({ onClick, size }) => {
  const [value, setValue] = useState("");
  return (
    <div className={`text-btn-wrapper ${size} `}>
      <div className={"text d-flex justify-content-between"}>
        <input
          type="text"
          placeholder={"Ex : Maybe"}
          className={"form-control text-non"}
          value={value}
          onChange={e => setValue(e.target.value)}
        />
        <div className={"btn-wrapper"}>
          <Button onClick={() => onClick(value)} size={"sm"} title={"Save"} />
        </div>
      </div>
    </div>
  );
};

TextBoxWithButton.propTypes = {
  size: PropTypes.string.isRequired
};

TextBoxWithButton.defaultProps = {
  size: "md"
};

export default TextBoxWithButton;
