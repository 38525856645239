import React from 'react';
import { Check } from "react-feather";
import { MenuIcon } from "../index";


const MediaImage = ({ src, active, viewGallery, selectThis }) => (
    <div
        className={`media-wrapper ${active ? 'active' : ''}`}>
        <div className={'check'} onClick={selectThis}>
            <MenuIcon icon={<Check />}
                size={'sm'} />
        </div>
        <img alt={'media'} src={src}
            onClick={viewGallery} />

    </div>
);


MediaImage.defaultProps = {};

export default MediaImage;
