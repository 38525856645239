import React from 'react';
import PropTypes from 'prop-types';
import PanelTitle from "../PanelTitle/PanelTitle";

//online
//offline
//busy
//other
const TextAreaWithLabel = ({
    placeholder, size, style, labelStyle, labelText,
    labelDisable, required, disable, value, onChange, maxLength
}) => (
        <>
            <div className={'d-flex flex-column'}>
                {!labelDisable && required &&
                    <label className={`${labelStyle}`}>{labelText} <span>*</span></label>
                }
                {!labelDisable && !required &&
                    <label className={`${labelStyle}`}>{labelText}</label>
                }
                <textarea
                    autoComplete="off"
                    maxLength="1000"
                    value={value}
                    onChange={onChange}
                    className={`form-control`}
                    placeholder={placeholder} />
            </div>
        </>
    );

TextAreaWithLabel.propTypes = {
    placeholder: PropTypes.string.isRequired,
};

PanelTitle.defaultProps = {
    placeholder: 'Type here...',
    labelDisable: false,
    required: false
};


export default TextAreaWithLabel;
