import { templateServiceUrl } from "../config/baseUrls";
import * as auth from "../utils";

export const getTemplates = () => {
    return new Promise((resolve, reject) => {
        let url = `${templateServiceUrl}/TCMTemplates/Templates?TemplateType=TEMPLATE`;

        fetch(url, {
            method: "GET",
            headers: {
                "authorization": auth.getBearerToken()
            }
        })
            .then(res => {
                return res.json();
            })
            .then(data => {
                if (data && data.isSuccess) {
                    let templates = {};
                    if (data.result) {
                        templates = data.result.reduce((acc, current) => {
                            acc[current.Uuid] = current;
                            return acc;
                        }, {});
                    }

                    resolve(templates);
                } else {
                    //break operation and return empty list
                    reject(new Error("Error occurred"));
                }
            })
            .catch(ex => {
                reject(ex);
            });
    });
};

export const getAdminCannedMessages = () => {
    return new Promise((resolve, reject) => {
        let url = `${templateServiceUrl}/TCMTemplates/Templates?TemplateType=CANNED_ADMIN`;

        fetch(url, {
            method: "GET",
            headers: {
                "authorization": auth.getBearerToken()
            }
        })
            .then(res => {
                return res.json();
            })
            .then(data => {
                if (data && data.isSuccess) {
                    let templates = {};
                    if (data.result) {
                        templates = data.result.reduce((acc, current) => {
                            acc[current.Uuid] = current;
                            return acc;
                        }, {});
                    }

                    resolve(templates);
                } else {
                    //break operation and return empty list
                    reject(new Error("Error occurred"));
                }
            })
            .catch(ex => {
                reject(ex);
            });
    });
};

export const getMyCannedMessages = () => {
    return new Promise((resolve, reject) => {
        let url = `${templateServiceUrl}/TCMTemplates/MyTemplates?TemplateType=CANNED_USER`;

        fetch(url, {
            method: "GET",
            headers: {
                "authorization": auth.getBearerToken()
            }
        })
            .then(res => {
                return res.json();
            })
            .then(data => {
                if (data && data.isSuccess) {
                    let templates = {};
                    if (data.result) {
                        templates = data.result.reduce((acc, current) => {
                            acc[current.Uuid] = current;
                            return acc;
                        }, {});
                    }

                    resolve(templates);
                } else {
                    //break operation and return empty list
                    reject(new Error("Error occurred"));
                }
            })
            .catch(ex => {
                reject(ex);
            });
    });
};

export const saveMyCannedMessages = (data) => {

    return new Promise((resolve, reject) => {
        let url = `${templateServiceUrl}/TCMTemplates/Template`;

        fetch(url, {
            method: "POST",
            headers: {
                "authorization": auth.getBearerToken(),
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data)
        })
            .then(res => {
                return res.json();
            })
            .then(data => {
                if (data && data.isSuccess) {

                    resolve(data.result);
                } else {
                    //break operation and return empty list
                    reject(new Error("Error occurred"));
                }
            })
            .catch(ex => {
                reject(ex);
            });
    });
};

export const updateMyCannedMessages = (id, data) => {

    return new Promise((resolve, reject) => {
        let url = `${templateServiceUrl}/TCMTemplates/Template/${id}`;

        fetch(url, {
            method: "PUT",
            headers: {
                "authorization": auth.getBearerToken(),
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data)
        })
            .then(res => {
                return res.json();
            })
            .then(data => {
                if (data && data.isSuccess) {

                    resolve(data.result);
                } else {
                    //break operation and return empty list
                    reject(new Error("Error occurred"));
                }
            })
            .catch(ex => {
                reject(ex);
            });
    });
};

export const deleteMyCannedMessage = (id) => {

    return new Promise((resolve, reject) => {
        let url = `${templateServiceUrl}/TCMTemplates/Template/${id}`;

        fetch(url, {
            method: "DELETE",
            headers: {
                "authorization": auth.getBearerToken(),
                "Content-Type": "application/json"
            }
        })
            .then(res => {
                return res.json();
            })
            .then(data => {
                if (data && data.isSuccess) {

                    resolve(data.result);
                } else {
                    //break operation and return empty list
                    reject(new Error("Error occurred"));
                }
            })
            .catch(ex => {
                reject(ex);
            });
    });
};
