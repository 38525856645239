import React from 'react';
import PropTypes from 'prop-types';
import { ChatFooter, ForwardedLabel, MenuIcon, ProfileImage } from "../index";
import { Popover } from "antd";
import { MoreVertical, Video } from "react-feather";
import ReactPlayer from "react-player";
import { videoPreviewSupported } from "../../../config/json/fileTypeMap";
import navigationRestrictions from "../../../config/navigationRestrictions";

const content = ({ onClickReplayMessage, onClickForwardMessage, onClickOpenGallery, onDownloadClick, contentUrl, filename, mediaFiles, msgObj, sendForwardMessages, onClickStar, isStarred }) => (
    <div className={'chat-hover-menu'}>
        <div
            onClick={() => onClickReplayMessage(msgObj)}
            className={'document-row'}>Reply
        </div>
        {navigationRestrictions.chat.forwarding.active && <div className={'document-row'}
            onClick={() => onClickForwardMessage((users) => {

                sendForwardMessages(users, msgObj);

            })}>
            Forward message
        </div>}
        {navigationRestrictions.chat.starring.active && <div className={'document-row'}
            onClick={() => onClickStar(msgObj, !isStarred)}>{isStarred ? 'Unstar Message' : 'Star Message'}</div>}
        {mediaFiles && mediaFiles.length > 0 &&
            <div className={'document-row'} onClick={() => onClickOpenGallery(mediaFiles)}>View</div>}
        <div className={'document-row'} onClick={() => onDownloadClick(mediaFiles)}>Download</div>
    </div>
);

const ChatLeftVideo = ({
    src, mid, isForwarded, fullName, msg, isImage, lastSeen, imgDesc,
    onClickReplayMessage, onClickForwardMessage, playing,
    onClickOpenGallery, controls, onDownloadClick, contentUrl, filename, mediaFiles, msgObj, sendForwardMessages, onClickStar, isStarred, ...props
}) => {
    let isPreviewAvailable = msgObj.media && msgObj.media[0] && videoPreviewSupported.indexOf(msgObj.media[0].mimeType) >= 0 ? true : false;
    return (
        <div key={mid} id={mid} className={`msg d-flex left-msg ${isImage ? '' : 'with-out-img'} `}>
            <div className="msg-img">
                <ProfileImage
                    textType={'chatLeft'}
                    firstLatter={fullName ? fullName.split(' ').map(fullName => fullName[0]).join('').toUpperCase() : ""}
                    size={'sm'}
                    src={src}
                    padding="4px"
                    fontSize="0.8rem" />
            </div>
            <div className={`inner-msg-bubble ${isImage ? null : 'with-out-name'}`}>
                {(props.conversation_type === 3 || props.conversation_type === 4) && <div className="msg-info-name">{fullName}</div>}

                <div className={'msg-text video-chat-message'}>
                    <div className="msg-text-with-video  d-flex flex-column ">
                        <ForwardedLabel
                            textAlign={'left'}
                            isForwardedMsg={isForwarded} />

                        <div className={`chat-video-wrapper d-flex ${imgDesc ? 'with-replay' : 'default '}`}>
                            <div className={`chat-video-wrapper ${contentUrl.endsWith('_recorded') ? 'mirror-media' : ''}`} onClick={() => onClickOpenGallery(mediaFiles)}>
                                <video
                                    width={200}
                                    poster={isPreviewAvailable ? false : require('../../../assets/img/no_preview.jpg')}
                                    controls={isPreviewAvailable ? controls : false}
                                    src={contentUrl} />
                                {/* <ReactPlayer
                                    width={200}
                                    playing={playing}
                                    controls={controls}
                                    url={contentUrl} /> */}
                            </div>
                            {/*<div className={'video-indicator'}>*/}
                            {/*    <MenuIcon*/}
                            {/*        icon={<Video />} />*/}
                            {/*</div>*/}
                        </div>
                        {msg}
                        <Popover
                            content={content({
                                onClickReplayMessage,
                                onClickForwardMessage,
                                onClickOpenGallery,
                                onDownloadClick,
                                contentUrl,
                                filename,
                                mediaFiles,
                                msgObj,
                                sendForwardMessages,
                                onClickStar,
                                isStarred
                            })}
                            trigger="hover">
                            <div className={'hover-more'}>
                                <MenuIcon
                                    icon={<MoreVertical />} />
                            </div>
                        </Popover>
                    </div>

                </div>
                <ChatFooter lastSeen={lastSeen}
                    isStarred={isStarred} />
            </div>
        </div>
    )
};

ChatLeftVideo.propTypes = {
    fullName: PropTypes.string.isRequired,
    msg: PropTypes.string.isRequired,
    isImage: PropTypes.bool.isRequired,
    isLastSeen: PropTypes.bool.isRequired,
};

ChatLeftVideo.defaultProps = {
    src: null,
};

export default ChatLeftVideo;
