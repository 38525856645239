const extractInteractionInfo = (currentUser, users) => {

    let userInfo = users.find(user => user.Id === currentUser);

    let info = {
        isConferenced: false,
        isTransfered: false,
        ConferenceData: null
    }

    if (userInfo) {
        if (userInfo.ConferenceInformation && userInfo.ConferenceInformation.IsUserConferencedToInteraction) {
            info.isConferenced = true;
            info.ConferenceData = { IsChat: userInfo.ConferenceInformation.IsUserConferencedToInteraction.IsChat, isCall: userInfo.ConferenceInformation.IsUserConferencedToInteraction.isCall };
        } else if (userInfo.TransferInformation && userInfo.TransferInformation.IsInteractionTransferredToUser) {
            info.isTransfered = true;
        }

    }

    return info;

}

export class Interaction {
    constructor(userId, interaction) {

        if (interaction) {

            let transInfo = extractInteractionInfo(userId, interaction.UserInformation);

            this.InteractionId = interaction.InteractionId;
            this.IsCallEscalated = interaction.IsCallEscalated;
            this.StartDateTime = interaction.StartDateTime;
            this.Channel = interaction.Channel;
            this.SubChannel = interaction.SubChannel;
            this.Intent = interaction.Intent;
            this.Skill = interaction.Skill;
            this.CustomerUserId = interaction.CustomerUserId;
            this.CustomData = interaction.CustomData;
            this.CustomerInfo = interaction.CustomerInfo;
            this.UserIds = interaction.UserInformation.map(users => users.Id);
            this.IsConferenced = transInfo.isConferenced;
            this.IsTransfered = transInfo.isTransfered;
            this.IsHandlingChat = transInfo.isConferenced ? transInfo.ConferenceData.isChat : true;
            this.IsHandlingCall = interaction.IsCallEscalated ? interaction.IsCallEscalated : (transInfo.isConferenced ? transInfo.ConferenceData.isCall : false);

        }


    }
}