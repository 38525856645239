import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";

import {
  LocalVideoIncome,
  Participator,
  VideoCallConnecting,
  VideoFullScreenActionPanel,
  VideoLiveCount,
  VideoMinimizeActionPanel,
} from "../index";
import { Scrollbars } from "react-custom-scrollbars";
import ParticipationListComponent from "../../pages/ParticipationList/ParticipationList";

import Draggable from "react-draggable";

const localVideoRef = React.createRef();
const remoteVideoRef = React.createRef();

const VideoCallPlayer = forwardRef(
  (
    {
      viewPointWidth,
      viewPointHeight,
      onClickOpenInviteModal,
      onClickOpenParticipation,
      onClickHideParticipation,
      onClickEndVideoCall,
      muteUnmuteAudioVideo,
      callDurations,
      startScreenSharing,
      calleeName,
      callee,
      callType,
      isHideVideoPenal,
      remoteStreams,
      isAllowedScreenShare,
      userDetails,
      isVideoMinimizeScreen,
      onClickUnlockVideo,
      onSwapStream,
      blockScreenShare,
      isScreenShare,
      isOpenParticipationPanel,
      callDurationComponent,
      caller,
      sendInvitationsToConference,
      audioDeviceId,
      voiceActivity,
      callStatus,
      bg_images,
      interactionId
    },
    ref
  ) => {
    const [isMute, setMute] = useState(false);
    const [isVideoMute, setVideoMute] = useState(false);
    const [isVideoLoading, setIsVideoLoading] = useState(true);
    const [mainRemoteStream, setMainRemoteStream] = useState({});
    const [videoScaleFactor, setVideoScaleFactor] = useState(1);

    console.log(
      "%cVIDEO PLAYER RENDER",
      "color:#233E82; font-family:'Ubuntu'; display: block;font-weight:bold; font-size:15px;"
    );
    useEffect(() => {
      const ac = new AbortController();
      console.log(
        "VideoCallPlayer",
        "useEffect",
        "rendering video call player component"
      );

      return () => {
        // Anything in here is fired on component unmount.
        setIsVideoLoading(true);
        setMute(false);
        setVideoMute(false);
        ac.abort();
      };
    }, []);

    useEffect(() => {
      try {
        switch (callStatus) {
          case "Connecting": {
            if (callType === "MEETING_VIDEO_CALL" && isVideoLoading !== false) {
              setTimeout(() => {
                setIsVideoLoading(false);
              }, 2000);
            }
            break;
          }
          default:
            break;
        }
      } catch (error) {}
    }, [callStatus]);

    useImperativeHandle(ref, () => ({
      bindMediaStreams(localStreams, mainRemoteStream) {
        try {
          if (localVideoRef.current && localStreams) {
            localVideoRef.current.srcObject = Array.isArray(localStreams)
              ? localStreams[0]
              : localStreams;

            localVideoRef.current.addEventListener("loadeddata", (event) => {
              // setIsVideoLoading(false);
            });
          }

          /* setConnectingStatus("Answering")
                    setMainRemoteStream(mainRemoteStream);
                    setIsVideoLoading(true) */
        } catch (error) {
          console.error("bindMediaStreams", error);
        }
      },
      stateActionsEvents(payload) {
        try {
          switch (payload.type) {
            case "REMOTEHANGUP":
            case "CALLEEOFFLINE": {
              removeStreams();
              break;
            }
            case "REMOTEANSWER": {
              break;
            }
            default: {
              break;
            }
          }
        } catch (error) {
          console.error(
            "useImperativeHandle - Video - stateActionsEvents",
            error
          );
        }
      },
    }));

    const resetStatus = () => {
      try {
        setIsVideoLoading(false);
      } catch (error) {}
    };

    const removeStreams = () => {
      try {
        setMute(false);
        setVideoMute(false);
        setIsVideoLoading(true);
        localVideoRef.current.src = "";
        localVideoRef.current.srcObject = null;
        localVideoRef.current.removeAttribute("src");

        remoteVideoRef.current.src = "";
        remoteVideoRef.current.srcObject = null;
        remoteVideoRef.current.removeAttribute("src");
      } catch (error) {
        // console.error("", error);
      }
    };

    const muteUnmuteAudio = (val) => {
      setMute(val);
      muteUnmuteAudioVideo(val, isVideoMute);
    };

    const muteUnmuteVideo = (val) => {
      setVideoMute(val);
      muteUnmuteAudioVideo(isMute, val);
    };

    const endCall = () => {
      try {
        removeStreams();
        onClickEndVideoCall();
      } catch (error) {
        console.error("endCall", error);
      }
    };

    const scaleVideo = (type) => {
      let scalingFactor = 0.5; //Value by which video is to be scaled by increment or decrement
      let minimumVideoTransformationScale = 0.5; //Minimum scale value
      let maximumVideoTransformationScale = 2; //Maximum scale value
      let defaultVideoScale = 1; //Default Video scale value

      let scale = 0;

      if (
        type != null &&
        type === "increment" &&
        videoScaleFactor < maximumVideoTransformationScale
      ) {
        scale = videoScaleFactor + scalingFactor;
      } else if (
        type != null &&
        type === "decrement" &&
        videoScaleFactor > minimumVideoTransformationScale
      ) {
        scale = videoScaleFactor - scalingFactor;
      } else if (type != null && type === "reset") {
        scale = defaultVideoScale;
      } else {
        scale = videoScaleFactor;
      }

      setVideoScaleFactor(scale);
      let remoteVideoTags = document.getElementsByClassName("remote-video-tag");
      for (let element of remoteVideoTags) {
        if (
          element.dataset.hasOwnProperty("isactive") === true &&
          element.dataset.isactive === "true"
        ) {
          element.style.transform = "scale(" + scale + ")";
        }
      }

      //document.getElementById("audio_device_id").style.transform = "scale(" + scale + ")";
    };

    const zoomInVideo = () => {
      scaleVideo("increment");
    };

    const zoomOutVideo = () => {
      scaleVideo("decrement");
    };

    const resetVideoZoom = () => {
      scaleVideo("reset");
    };

    return (
      (callType === "VIDEO_CALL" ||
        callType === "SELF_VIEW" ||
        callType === "MEETING_VIDEO_CALL") &&
      !isHideVideoPenal && (
        <>
          <div
            className={`video-call-player-wrapper d-flex justify-content-between pin-this `}
            style={{
              width: isVideoMinimizeScreen ? 500 : viewPointWidth,
              height: isVideoMinimizeScreen ? 300 : viewPointHeight,
            }}
          >
            <div
              className={`video-wrapper d-flex ${
                isVideoMinimizeScreen ? "minimizer-screen" : "full-screen"
              }`}
            >
              <div
                className={`main-video-wrapper ${
                  isVideoMinimizeScreen ? "minimizer-screen" : "full-screen"
                }`}
              >
                {callType === "MEETING_VIDEO_CALL" &&
                  Object.values(remoteStreams).length === 0 && (
                    <div
                      className="meet-status-wrapper d-flex justify-content-center align-items-center"
                      style={{
                        top: isVideoMinimizeScreen ? "35%" : "45%",
                        left: isVideoMinimizeScreen ? "35%" : "45%",
                      }}
                    >
                      <div className={"video-status"} />
                      <div className={"timer"}>waiting for others </div>
                    </div>
                  )}

                {/*======= center video style={{width: isVideoMinimizeScreen ? 400 : 600}} ==========*/}
                {/*======= callDurations ==========*/}
                <VideoLiveCount
                  callDurationComponent={callDurationComponent}
                  isVideoLoading={isVideoLoading}
                  isVideoMinimizeScreen={isVideoMinimizeScreen}
                  onClickOpenParticipation={onClickOpenParticipation}
                  isAllowedScreenShare={isAllowedScreenShare}
                  onClickUnlockVideo={(v) => {
                    /* if(isPhoneDrag){
                                            setDisplayStatusClassNameTopLevel("pin-this");
                                            return;
                                        } */
                    onClickUnlockVideo(v);
                  }}
                  isOpenParticipationPanel={isOpenParticipationPanel}
                  userDetails={userDetails}
                  callDurations={callDurations}
                  isScreenShare={false}
                />
                {/*  <video
                                    id="mainVideo"
                                    ref={ remoteVideoRef }
                                    autoPlay
                                    playsInline
                                    title={ mainRemoteStream.name }></video> */}
                <>
                  <div
                    className={`local-video-block ${
                      isVideoLoading ? "local-video-hide" : "local-video-show"
                    }`}
                  >
                    <div
                      className={`local-video-main-wrapper d-flex flex-column ${
                        isVideoMinimizeScreen
                          ? "minimizer-screen"
                          : "full-screen"
                      } `}
                    >
                      {/*======= local video ==========*/}
                      <div className="scroll-area">
                        <LocalVideoIncome
                          isVideoMinimizeScreen={isVideoMinimizeScreen}
                          onClick={null}
                          isVideoLoading={isVideoLoading}
                          localVideoRef={localVideoRef}
                          userType={""}
                          fullName={"Me"}
                          isMic={true}
                          isVideo={true}
                          status={"pending.."}
                          active={true}
                          audioDeviceId={audioDeviceId}
                        />

                        {Object.values(remoteStreams).map((item) => {
                          return (
                            <Participator
                              key={item.id}
                              isVideoMinimizeScreen={isVideoMinimizeScreen}
                              id={item.id}
                              mediaStream={item.mediaStream}
                              fullName={item.name}
                              status={"pending.."}
                              active={item.active}
                              onClick={onSwapStream}
                              userId={item.userId}
                              resetStatus={resetStatus}
                              activeSetting={item.activeSetting}
                              isScreenShare={item.isScreenShare}
                              audioDeviceId={audioDeviceId}
                              voiceActivity={voiceActivity}
                              interactionId={interactionId}
                            />
                          );
                        })}
                      </div>
                    </div>

                    {/*===== Full Screen Action wrapper =====*/}
                    {!isVideoMinimizeScreen && (
                      <strong className="no-cursor">
                        <VideoFullScreenActionPanel
                          isVideoLoading={isVideoLoading}
                          viewPointWidth={viewPointWidth}
                          onClickUnlockVideo={onClickUnlockVideo}
                          isVideoMinimizeScreen={isVideoMinimizeScreen}
                          isAllowedScreenShare={isAllowedScreenShare}
                          muteUnmuteAudioVideo={muteUnmuteAudioVideo}
                          isMute={isMute}
                          muteUnmuteVideo={muteUnmuteVideo}
                          isVideoMute={isVideoMute}
                          startScreenSharing={startScreenSharing}
                          callee={callee}
                          onClickOpenInviteModal={onClickOpenInviteModal}
                          sendInvitationsToConference={
                            sendInvitationsToConference
                          }
                          endCall={endCall}
                          userDetails={userDetails}
                          blockScreenShare={blockScreenShare}
                          isScreenShare={isScreenShare}
                          onClickOpenParticipation={onClickOpenParticipation}
                          isOpenParticipationPanel={isOpenParticipationPanel}
                          caller={caller}
                          onClickZoomInVideo={zoomInVideo}
                          onClickZoomOutVideo={zoomOutVideo}
                          resetVideoZoom={resetVideoZoom}
                        />
                      </strong>
                    )}
                  </div>
                </>
              </div>

              {/*=====Minimize isVideoAction wrapper =====*/}
              {isVideoMinimizeScreen && (
                <strong className="no-cursor">
                  <VideoMinimizeActionPanel
                    isVideoLoading={isVideoLoading}
                    viewPointWidth={viewPointWidth}
                    onClickUnlockVideo={onClickUnlockVideo}
                    isVideoMinimizeScreen={isVideoMinimizeScreen}
                    isAllowedScreenShare={isAllowedScreenShare}
                    muteUnmuteAudioVideo={muteUnmuteAudioVideo}
                    isMute={isMute}
                    muteUnmuteVideo={muteUnmuteVideo}
                    isVideoMute={isVideoMute}
                    startScreenSharing={startScreenSharing}
                    callee={callee}
                    onClickOpenInviteModal={onClickOpenInviteModal}
                    sendInvitationsToConference={sendInvitationsToConference}
                    endCall={endCall}
                    userDetails={userDetails}
                    blockScreenShare={blockScreenShare}
                    isScreenShare={isScreenShare}
                    caller={caller}
                  />
                </strong>
              )}
            </div>

            {/*======= Incoming call connection wrapper ==========*/}
            {isVideoLoading && (
              <VideoCallConnecting
                isVideoLoading={isVideoLoading}
                viewPointWidth={viewPointWidth}
                viewPointHeight={viewPointHeight}
                calleeName={calleeName}
                size={isVideoMinimizeScreen ? "sm" : "md"}
                onClick={endCall}
                connectingStatus={callStatus}
                callType={callType}
                bg_images={bg_images}
              />
            )}
          </div>

          {/*======= open participation panel ==========*/}
          {isOpenParticipationPanel && (
            <Draggable defaultPosition={{ x: 0, y: 0 }}>
              <div className={"screen-participation-wrapper"}>
                <ParticipationListComponent
                  userDetails={userDetails}
                  onClickOpenInviteModal={onClickOpenInviteModal}
                  sendInvitationsToConference={sendInvitationsToConference}
                  onClickHideParticipation={onClickHideParticipation}
                />
              </div>
            </Draggable>
          )}
        </>
      )
      //</div>
    );
  }
);

VideoCallPlayer.defaultProps = {};

export default VideoCallPlayer;
