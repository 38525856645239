import React, { useState } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import uuid from 'uuid/v1';
import { bindActionCreators } from "redux";
import * as auth from "../../../utils";
import presenceStateMap from "../../../config/json/presenseMap";
import * as ChatService from "../../../service/chatService";
import {
    ButtonRounded,
    ProfileImage,
    OnlineStatus,
    MenuIcon,
    PanelTitle,
    ChatFilterTab,
    SearchTextBox,
    SelectUser,
    ChatUser,
    TextBoxWithLabel,
    UploadImageThumbnail,
    UserWithFullName,
    ImageThumbnail,
    AudioThumbnail,
    VideoThumbnail,
    DocumentThumbnail,
    ImagePreview,
    VideoPreview,
    AudioPreview,
    DocumentPreview
} from '../../common';
import { X, Download, MessageSquare, CornerUpRight, ChevronRight, ChevronLeft } from 'react-feather';
import navigationRestrictions from "../../../config/navigationRestrictions";
import * as fileService from "../../../service/fileService";
import * as chatActions from "../../../redux/actions/chatActions";
import { videoPreviewSupported } from "../../../config/json/fileTypeMap";


const MediaGalleryView = props => {

    const [selectedMedia, setSelectedMedia] = useState(props.openGalleryMediaFiles && props.openGalleryMediaFiles.length > 0 ? {
        file: props.openGalleryMediaFiles[0],
        index: 0
    } : null);

    let fullName = "";
    let timeAgo = "";
    let status = "offline";
    let avatar = null;

    const onDownload = async () => {
        let url = selectedMedia.file.content_url;
        let filename = selectedMedia.file.content_title ? selectedMedia.file.content_title : "file";
        if (url && filename) {
            const response = await fileService.directDownloadFile(filename, url);
        }
    };

    if (props.selected_profile && props.selected_profile.username) {
        fullName = props.selected_profile.displayName;
        avatar = props.selected_profile.avatar;

        if (props.typing_status && props.typing_status.is_typing) {
            if (props.selected_profile.conversation_type === 0) {
                timeAgo = "typing...";
            } else if (props.selected_profile.conversation_type === 3 || props.selected_profile.conversation_type === 4) {
                timeAgo = `${props.typing_status.typing_user} is typing...`;
            }

        } else {
            if (props.presence.data && props.presence.data[props.selected_profile.username]) {

                let userPresence = props.presence.data[props.selected_profile.username];
                if (userPresence.connectivity === 2) {
                    let latestEventTime = userPresence.connectivityAt > userPresence.presenceAt ? userPresence.connectivityAt : userPresence.presenceAt;
                    timeAgo = moment().from(latestEventTime, true) + " ago";
                } else if (userPresence.connectivity === 1) {
                    timeAgo = "online";
                } else {
                    if (userPresence.status !== "online") {
                        if (userPresence.presenceAt) {
                            timeAgo = moment().from(userPresence.presenceAt, true) + " ago";
                        } else {
                            timeAgo = "offline";
                        }
                    } else {
                        timeAgo = "online";
                    }
                }

                let tempStatus = userPresence.status ? userPresence.status : "offline";
                status = presenceStateMap[tempStatus];
            }
        }


    }

    function onClickChangePreview(index) {
        if (props.openGalleryMediaFiles && props.openGalleryMediaFiles[index]) {
            setSelectedMedia({ file: props.openGalleryMediaFiles[index], index });
        }

    }

    function onClickNext(index) {
        if (props.openGalleryMediaFiles && props.openGalleryMediaFiles.length - 1 >= index + 1) {
            setSelectedMedia({ file: props.openGalleryMediaFiles[index + 1], index: index + 1 });
        }
    }

    function onClickPrevious(index) {
        if (props.openGalleryMediaFiles && index - 1 >= 0) {
            setSelectedMedia({ file: props.openGalleryMediaFiles[index - 1], index: index - 1 });
        }
    }

    let mediaFiles = props.openGalleryMediaFiles;

    let selectedMediaToShow = null;

    if (selectedMedia === null && mediaFiles && mediaFiles.length > 0) {
        selectedMediaToShow = mediaFiles[0];
    } else {
        if (selectedMedia) {
            selectedMediaToShow = selectedMedia.file;
        }

    }

    let selectedMediaView = <></>;

    if (selectedMediaToShow) {
        switch (selectedMediaToShow.mimeType) {
            case "image/jpeg":
            case "image/jpg":
            case "image/png":
            case "image/bmp": {
                selectedMediaView = <ImagePreview src={selectedMediaToShow.content_url} />
                break;

            }
            case "video/mp4":
            case "video/avi":
            case "video/webm": {
                selectedMediaView = <VideoPreview
                    playing={true}
                    caption={selectedMediaToShow.content_title}
                    src={selectedMediaToShow.content_url}
                    isPreviewSupported={videoPreviewSupported.indexOf(selectedMediaToShow.mimeType) >= 0 ? true : false} />
                break;

            }
            case "audio/webm":
            case "audio/mp3":
            case "audio/mpeg":
            case "audio/webm;codecs=opus": {
                selectedMediaView = <AudioPreview
                    playing={true}
                    src={selectedMediaToShow.content_url} />
                break;
            }
            case "document/pdf":
            case "application/pdf": {

                selectedMediaView = <DocumentPreview src={selectedMediaToShow.content_url} pageNumber={1} type={'pdf'} />
                break;

            }
        }
    }

    let imageThumbnailsView = <></>;

    if (selectedMediaToShow && mediaFiles && mediaFiles.length > 0) {

        imageThumbnailsView = mediaFiles.map((media, index) => {

            switch (media.mimeType) {
                case "image/jpeg":
                case "image/jpg":
                case "image/png":
                case "image/bmp": {
                    return (<ImageThumbnail
                        active={selectedMediaToShow.content_url === media.content_url}
                        onClickChangePreview={() => onClickChangePreview(index)}
                        src={media.thumbnail_url ? media.thumbnail_url : 'https://source.unsplash.com/V2OyJtFqEtY/150x150'} />);

                }
                case "video/mp4":
                case "video/avi":
                case "video/webm": {
                    return (<VideoThumbnail
                        active={selectedMediaToShow.content_url === media.content_url}
                        playing={false}
                        onClickChangePreview={() => onClickChangePreview(index)}
                        src={null} />);

                }
                case "audio/webm":
                case "audio/mp3":
                case "audio/mpeg": {
                    return (<AudioThumbnail
                        active={selectedMediaToShow.content_url === media.content_url}
                        onClickChangePreview={() => onClickChangePreview(index)} />)
                }
                case "document/pdf":
                case "application/pdf": {

                    return (<DocumentThumbnail
                        active={selectedMediaToShow.content_url === media.content_url}
                        onClickChangePreview={() => onClickChangePreview(index)}
                        fileSize={media.content_size}
                        type={'ppt'} />)

                }
            }


        })

    }

    const onSendCallback = (users) => {
        for (let user of users) {
            let uniqueId = uuid();

            let toId = user.conversation_type === 3 ? user.Id : user.userId;

            let msg = {
                v: 1,
                mid: uniqueId,
                sid: uniqueId,
                channel: "WEBCHAT",
                message_type: selectedMedia.file.mimeType,
                from: {
                    id: auth.getUserId(),
                    name: auth.getDisplayName()
                },
                to: {
                    id: toId,
                    name: toId
                },
                media: [{
                    created_at: Date.now(),
                    content_url: selectedMedia.file.content_url,
                    content_size: selectedMedia.file.content_size ? parseInt(selectedMedia.file.content_size) : 0,
                    content_title: selectedMedia.file.content_title,
                    thumbnail_url: selectedMedia.file.thumbnail_url,
                    mimeType: selectedMedia.file.mimeType
                }],
                created_at: Date.now(),
                message_content: '',
                other: null,
                isUploading: "DONE"
            };

            msg.conversation_type = user.conversation_type;
            ChatService.sendChatMessage(user.conversation_type, selectedMedia.file.mimeType, toId, msg, false, user.InteractionId);
            props.chat_actions.sendNewMessageSuccess(msg);

            if (selectedMedia.file) {
                let file_id = null;
                if (selectedMedia.file.mimeType.startsWith("image") && selectedMedia.file.thumbnail_url) {
                    let match = selectedMedia.file.thumbnail_url.match(new RegExp("stream/thumbnail/" + "(.*)" + "/225"));
                    if (match && match.length === 2) {
                        file_id = match[1];
                    }
                } else {
                    let match = selectedMedia.file.content_url.match(new RegExp("stream/media/" + "(.*)"));
                    if (match && match.length === 2) {
                        file_id = match[1];
                    }
                }
                fileService.tagConversationToFile(user.conversation_type === 3 ? "" : msg.from.id, msg.to.id, auth.getWorkspace(), file_id, selectedMedia.file.content_url, selectedMedia.file.mimeType, selectedMedia.file.content_size, selectedMedia.file.content_title);
            }
        }
    }


    return (
        <>
            <section className={'out-media-gallery-view-wrapper'}>

                {/*===== header wrapper ====== */}
                <div className={'media-gallery-header-wrapper d-flex justify-content-between align-items-center'}
                    style={{ width: props.viewPointWidth }}>
                    <div className={`media-g-left`}>

                        {fullName && <UserWithFullName
                            src={avatar}
                            conversation_type={props.selected_profile.conversation_type}
                            fullName={fullName}
                            lastSeen={timeAgo}
                            onClick={props.onClickOpenInfoPanel}
                            isTyping={false}
                            active={props.active}
                            status={status} />}
                    </div>
                    <div className={`media-g-right`}>
                        <div className={`media-g-action-wrapper d-flex`}>
                            <div className={`pg-header-icon-wrp flex justify-content-center`}>
                                <MenuIcon
                                    onClick={props.onClickCLoseGallery}
                                    size={'sm-space'}
                                    icon={<MessageSquare />} />
                            </div>
                            {navigationRestrictions.chat.forwarding.active && <div className={`pg-header-icon-wrp flex justify-content-center`}>
                                <MenuIcon
                                    onClick={() => props.onClickForwardMessage(onSendCallback)}
                                    size={'sm-space'}
                                    icon={<CornerUpRight />} />
                            </div>}
                            <div className={`pg-header-icon-wrp flex justify-content-center`}>
                                <MenuIcon
                                    onClick={onDownload}
                                    size={'sm-space'}
                                    icon={<Download />} />
                            </div>
                            <div className={`pg-header-icon-wrp close flex justify-content-center`}>
                                <MenuIcon
                                    onClick={props.onClickCLoseGallery}
                                    size={'sm-space'}
                                    icon={<X />} />
                            </div>

                        </div>
                    </div>
                </div>

                {/*===== image preview wrapper ====== */}
                <section className={`media-g-preview-wrapper`}>

                    {selectedMedia.index > 0 &&
                        <div onClick={() => onClickPrevious(selectedMedia.index)} className={'preview-navigation left' +
                            ' d-flex justify-content-between align-items-center'}>
                            <MenuIcon icon={<ChevronLeft />} />

                        </div>}


                    <div className={`media-view-main-wrapper d-flex align-items-center justify-content-center`}
                        style={{ height: props.viewPointHeight - 200, width: props.viewPointWidth - 300 }}>

                        {/*<ImagePreview src={'https://source.unsplash.com/k3eMwTb6iPw'}/>*/}

                        {/*<DocumentPreview*/}
                        {/*    src={'../../assets/pdf.pdf'}*/}
                        {/*    type={'pdf'}/>*/}

                        {selectedMediaView}

                        {/* <VideoPreview
                            playing={true}
                            caption={'Caption goes here...'}
                            src={'https://vimeo.com/90509568'} /> */}

                        {/*    <AudioPreview*/}
                        {/*    playing={true}*/}
                        {/*    src={'https://vimeo.com/90509568'}/>*/}
                    </div>

                    {mediaFiles.length - 1 !== selectedMedia.index &&
                        <div onClick={() => onClickNext(selectedMedia.index)}
                            className={'preview-navigation right d-flex justify-content-between align-items-center'}>
                            <MenuIcon icon={<ChevronRight />} />
                        </div>}

                </section>

                {/*===== all image preview footer ====== */}
                <section className={`media-g-footer-wrapper transparent`}>
                    <div className={'inner-media-g-footer'}
                        style={{ width: props.viewPointWidth - 200 }}>
                        <div className={'d-flex justify-content-center align-items-center'}>

                            {imageThumbnailsView}


                            {/* <ImageThumbnail
                                active={false}
                                onClickChangePreview={onClickChangePreview}
                                src={'https://source.unsplash.com/V2OyJtFqEtY/150x150'} />
                            <VideoThumbnail
                                playing={false}
                                onClickChangePreview={onClickChangePreview}
                                src={'https://www.youtube.com/watch?v=oUFJJNQGwhk'} />
                            <ImageThumbnail
                                active={false}
                                onClickChangePreview={onClickChangePreview}
                                src={'https://source.unsplash.com/rDKnahTD5LM/150x150'} />
                            <ImageThumbnail
                                active={false}
                                onClickChangePreview={onClickChangePreview}
                                src={'https://source.unsplash.com/k8OCHhEymME/150x150'} />
                            <VideoThumbnail
                                playing={true}
                                onClickChangePreview={onClickChangePreview}
                                active={true}
                                src={'https://vimeo.com/90509568'} />
                            <DocumentThumbnail
                                onClickChangePreview={onClickChangePreview}
                                fileSize={'2 KB'}
                                type={'ppt'} />
                            <ImageThumbnail
                                active={false}
                                onClickChangePreview={onClickChangePreview}
                                src={'https://source.unsplash.com/5E5N49RWtbA/150x150'} />

                            <VideoThumbnail
                                playing={false}
                                onClickChangePreview={onClickChangePreview}
                                src={'https://www.youtube.com/watch?v=jNgP6d9HraI'} />
                            <AudioThumbnail
                                onClickChangePreview={onClickChangePreview}
                                duration={'2.30'} /> */}
                        </div>

                    </div>

                </section>
            </section>

        </>
    );
};

MediaGalleryView.propTypes = {
    presence: PropTypes.object.isRequired,
    selected_profile: PropTypes.object.isRequired,
    chat_actions: PropTypes.object.isRequired
};

function mapStateToProps(state) {

    let mapState = {
        presence: state.presence,
        selected_profile: state.selected_profile
    };

    if (state.selected_profile && state.selected_profile.username) {
        mapState.typing_status = state.typing_status[state.selected_profile.username];
    }
    return mapState;
}

function mapDispatchToProps(dispatch) {
    return {
        chat_actions: bindActionCreators(chatActions, dispatch)
    };
}

export default connect(
    mapStateToProps, mapDispatchToProps
)(MediaGalleryView);
