import * as types from "./actionTypes";
import * as selectedProfileActions from "./selectedProfileActions";
import * as typingActions from "./typingActions";
import * as chatActions from './chatActions';
import * as teamActions from './teamActions';
import { getUserId, userDetails } from "../../utils";
import { __APP_INTERACTION_MODE__ } from "../../config/baseUrls";
import { TeamService } from "../../service/teamService";
import { MeetingSDK } from '../../lib/MeetingsSdk';
import { OpenCustomer360 } from "./externalApiActions";
import navigationRestrictions from "../../config/navigationRestrictions";
import * as auth from "../../utils";
import * as phoneConfig from "../../config/phoneConfig";

const meetingSDK = new MeetingSDK();

export function interactionsRetrieved(interactionListInfo) {

    return dispatch => {

        let formattedList = {};
        if (interactionListInfo && interactionListInfo.InteractionListInformation) {
            //transform
            formattedList = interactionListInfo.InteractionListInformation.reduce((acc, interaction) => {

                let cust = {
                    userId: interaction.CustomerUserId,
                    customerId: "",
                    userType: "Customer",
                    conversation_type: 0,
                    isTemp: true,
                    InteractionId: interaction.InteractionId,
                    createdAt: Date.parse(interaction.StartDateTime),
                    firstName: " ",
                    lastName: " ",
                    userData: null,
                    InteractionType: interaction.Channel
                };

                interaction.conversation_type = 4;
                if (interaction.CustomerInfo) {
                    interaction.CustomerInfo = JSON.parse(interaction.CustomerInfo);
                    cust.firstName = interaction.CustomerInfo.customerName;
                    cust.userData = interaction.CustomerInfo;
                    cust.customerId = interaction.CustomerInfo.customerId;
                }

                interaction.isTemp = true;
                interaction.createdAt = Date.parse(interaction.StartDateTime);
                if (!interaction.IsConferenced) {
                    acc.interactions[interaction.InteractionId] = interaction;
                }

                acc.customers[cust.userId] = cust;
                return acc;

            }, { interactions: {}, customers: {} });
        }
        let interactionCount = formattedList.interactions ? Object.keys(formattedList.interactions).length : 0;

        interactionCount && dispatch(chatActions.getAllUnreadCountsForUserIds(getUserId(), Object.keys(formattedList.interactions)));
        __APP_INTERACTION_MODE__ === 'INTERACTION' && dispatch(teamActions.addCustomersForInteractions(formattedList.customers));
        dispatch({ type: types.GET_INTERACTIONS_SUCCESS, interactions: formattedList.interactions });
    }
}

export function interactionsRetrievedHybridMode(interactionListInfo) {

    return (dispatch, getState) => {

        let formattedList = {};
        if (interactionListInfo && interactionListInfo.InteractionListInformation) {

            let state = getState();

            let team = state.team;
            //transform
            formattedList = interactionListInfo.InteractionListInformation.reduce((acc, interaction) => {

                if (!(interaction.CustomData && interaction.CustomData.meetingId)) {

                    let tempUser = {
                        userId: interaction.CustomerUserId,
                        customerId: "",
                        userType: "Customer",
                        conversation_type: 0,
                        isTemp: true,
                        InteractionId: interaction.InteractionId,
                        createdAt: Date.parse(interaction.StartDateTime),
                        firstName: " ",
                        lastName: " ",
                        userData: null,
                        InteractionType: interaction.Channel
                    };

                    interaction.conversation_type = 4;
                    if (interaction.CustomerInfo) {
                        interaction.CustomerInfo = JSON.parse(interaction.CustomerInfo);
                        tempUser.firstName = interaction.CustomerInfo.customerName;
                        tempUser.userData = interaction.CustomerInfo;
                        tempUser.customerId = interaction.CustomerInfo.customerId;
                    }

                    let info = interaction.UserIds.reduce((acc, id) => {


                        if (team[id]) {
                            acc.userDetails[id] = JSON.parse(JSON.stringify(team[id]))
                        } else {
                            if (interaction.CustomerUserId === id) {
                                acc.userDetails[id] = tempUser;
                            } else {
                                acc.idsToFetch.push(id);
                            }

                        }

                        return acc;

                    }, { userDetails: {}, idsToFetch: [] });

                    interaction.UserDetails = info.userDetails;
                    interaction.MeetingInfo = null;
                    interaction.IsMeeting = false;



                    interaction.isTemp = true;
                    interaction.createdAt = Date.parse(interaction.StartDateTime);

                    acc.interactions[interaction.InteractionId] = interaction;

                }


                return acc;

            }, { interactions: {} });
        }
        let interactionCount = formattedList.interactions ? Object.keys(formattedList.interactions).length : 0;

        interactionCount && dispatch(chatActions.getAllUnreadCountsForUserIds(getUserId(), Object.keys(formattedList.interactions)));
        /* __APP_INTERACTION_MODE__ === 'INTERACTION' && dispatch(teamActions.addCustomersForInteractions(formattedList.customers)); */
        dispatch({ type: types.GET_INTERACTIONS_SUCCESS, interactions: formattedList.interactions });
    }
}

export function addExternalUserInteraction(interactionInfo) {

    return (dispatch, getState) => {

        let custInfoObj = interactionInfo.CustomerInfo;

        let extUserId = "";
        let firstName = "";
        let customerId = "";

        extUserId = interactionInfo.CustomerUserId;
        firstName = custInfoObj ? custInfoObj.customerName : "";
        customerId = custInfoObj ? custInfoObj.customerId : "";

        let tempUser = {
            userId: extUserId,
            customerId: customerId,
            userType: "Customer",
            conversation_type: 0,
            isTemp: true,
            InteractionId: interactionInfo.InteractionId,
            createdAt: Date.now(),
            firstName: firstName,
            lastName: " ",
            userData: custInfoObj,
            InteractionType: custInfoObj.channel,
        };
        let state = getState();

        let team = state.team;

        let info = interactionInfo.UserIds.reduce((acc, id) => {
            if (team[id]) {
                acc.userDetails[id] = JSON.parse(JSON.stringify(team[id]))
            } else {
                if (extUserId === id) {
                    acc.userDetails[id] = tempUser;
                } else {
                    acc.idsToFetch.push(id);
                }

            }

            return acc;

        }, { userDetails: {}, idsToFetch: [] });

        if (info.idsToFetch.length > 0) {

            TeamService.getUserDetailsBatch(info.idsToFetch).then(userList => {

                userList.forEach(usr => {
                    info.userDetails[usr.userId] = usr;
                });

                //Call meeting api and get title

                interactionInfo.UserDetails = info.userDetails;
                interactionInfo.MeetingInfo = null;

                dispatch({ type: types.ADD_INTERACTION, interactionInfo });

                dispatch(
                    selectedProfileActions.profileSelected({
                        username: interactionInfo.InteractionId,
                        conversation_type: 4,
                        profileId: "",
                        displayName: firstName ? firstName : interactionInfo.InteractionId,
                        conversation_id: null,
                        avatar: null,
                        createdAt: interactionInfo.createdAt,
                        isTemp: true,
                        userData: custInfoObj,
                        InteractionId: interactionInfo.InteractionId,
                        InteractionInfo: interactionInfo,
                    })
                );



            }).catch(ex => {
                console.log(ex);
            })

        } else {


            interactionInfo.UserDetails = info.userDetails;
            interactionInfo.MeetingInfo = null;
            dispatch({ type: types.ADD_INTERACTION, interactionInfo })

            dispatch(
                selectedProfileActions.profileSelected({
                    username: interactionInfo.InteractionId,
                    conversation_type: 4,
                    profileId: "",
                    displayName: firstName ? firstName : interactionInfo.InteractionId,
                    conversation_id: null,
                    avatar: null,
                    createdAt: interactionInfo.createdAt,
                    isTemp: true,
                    userData: custInfoObj,
                    InteractionId: interactionInfo.InteractionId,
                    InteractionInfo: interactionInfo,
                })
            );
        }
    }

}

export function addMeetingInteraction(interactionInfo) {

    return (dispatch, getState) => {
        let state = getState();

        let team = state.team;

        let info = interactionInfo.UserIds.reduce((acc, id) => {
            if (team[id]) {
                acc.userDetails[id] = JSON.parse(JSON.stringify(team[id]))
            } else {
                acc.idsToFetch.push(id);
            }

            return acc;

        }, { userDetails: {}, idsToFetch: [] });

        if (info.idsToFetch.length > 0) {

            TeamService.getUserDetailsBatch(info.idsToFetch).then(userList => {

                userList.forEach(usr => {
                    info.userDetails[usr.userId] = usr;
                });

                //Call meeting api and get title

                meetingSDK.getMeetingById(interactionInfo.CustomData.meetingId).then(mData => {

                    interactionInfo.UserDetails = info.userDetails;
                    interactionInfo.MeetingInfo = mData.scheduleMeeting;

                    dispatch({ type: types.ADD_INTERACTION, interactionInfo });

                    dispatch(
                        selectedProfileActions.profileSelected({
                            username: interactionInfo.InteractionId,
                            conversation_type: 4,
                            profileId: "",
                            displayName: mData ? mData.scheduleMeeting.title : interactionInfo.InteractionId,
                            conversation_id: null,
                            avatar: null,
                            createdAt: interactionInfo.createdAt,
                            isTemp: true,
                            userData: null,
                            InteractionId: interactionInfo.InteractionId,
                            InteractionInfo: interactionInfo,
                        })
                    );

                }).catch(ex => {
                    console.error(ex);
                })



            }).catch(ex => {
                console.log(ex);
            })

        } else {


            meetingSDK.getMeetingById(interactionInfo.CustomData.meetingId).then(mData => {

                interactionInfo.UserDetails = info.userDetails;
                interactionInfo.MeetingInfo = mData.scheduleMeeting;
                dispatch({ type: types.ADD_INTERACTION, interactionInfo })

                dispatch(
                    selectedProfileActions.profileSelected({
                        username: interactionInfo.InteractionId,
                        conversation_type: 4,
                        profileId: "",
                        displayName: mData ? mData.scheduleMeeting.title : interactionInfo.InteractionId,
                        conversation_id: null,
                        avatar: null,
                        createdAt: interactionInfo.createdAt,
                        isTemp: true,
                        userData: null,
                        InteractionId: interactionInfo.InteractionId,
                        InteractionInfo: interactionInfo,
                    })
                );

            }).catch(ex => {
                console.error(ex);
            })
        }
    }

}

export function addMeetingUserToInteraction(interactionId, userId, interactionInfo, callback) {

    return (dispatch, getState) => {
        let state = getState();

        if (state.interactions[interactionId]) {

            let usr = state.team[userId];

            if (!usr) {

                TeamService.getUserDetailsBatch([userId]).then(userList => {

                    let usrInf = userList && userList.length > 0 ? userList[0] : null;

                    if (usrInf) {
                        dispatch({ type: types.ADD_INTERACTION_USER, interactionId, userId, userInfo: usrInf });
                    } else {
                        dispatch({ type: types.ADD_INTERACTION_USER, interactionId, userId, userInfo: null });
                    }

                }).catch(ex => {
                    console.log(ex);
                })

            } else {
                dispatch({ type: types.ADD_INTERACTION_USER, interactionId, userId, userInfo: JSON.stringify(JSON.parse(usr)) });
            }

        } else {
            if (userId === getUserId()) {
                callback();
                dispatch(addMeetingInteraction(interactionInfo));
            }

        }


    }

}

export function interactionAdded(interactionInfo) {
    return { type: types.ADD_INTERACTION, interactionInfo };
}

export function addUserToInteraction(interactionId, userId, userInfo) {
    return { type: types.ADD_INTERACTION_USER, interactionId, userId, userInfo };
}

export function removeUserFromInteraction(interactionId, userId) {
    return { type: types.REMOVE_INTERACTION_USER, interactionId, userId };
}

export function setACWStatus(interactionId) {
    return dispatch => {

        dispatch({ type: types.SET_INTERACTION_ACW_STATUS, interactionId });
        dispatch(selectedProfileActions.setAcwState(interactionId));
        dispatch(typingActions.setACWTyping({ user_id: interactionId }));
    }
}

export function setCallEscalatedStatus(interactionId, isCallEscalated) {
    return dispatch => {

        dispatch({ type: types.SET_CALL_ESCALATION_STATUS, interactionId, isCallEscalated });
    }
}

export function removeInteraction(interactionId) {
    return dispatch => {

        dispatch({ type: types.REMOVE_INTERACTION, interactionId });
        dispatch(typingActions.deleteTyping({ user_id: interactionId }));

    }

}