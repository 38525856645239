import * as types from "./actionTypes";


export function openVideoRecordModal(windowState) {
    return {type: types.OPEN_VIDEO_RECORD, status: windowState};
}
export function closeVideoRecordModal(windowState) {
    return {type: types.CLOSE_VIDEO_RECORD, status: windowState};
}

