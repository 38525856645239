import { userUrl, ocmUsrUrl, contactServiceUrl, __APP_DEFAULT_WORKSPACE__, __APP_INTERACTION_MODE__, ocmUserHierarchyType, ocmAccessFeature } from "../config/baseUrls";
import * as auth from "../utils";
import { TempAgent } from "../models/TempAgent";

export class TeamService {

    static getUserDetails(userId) {

        return new Promise((resolve, reject) => {
            let url = `${userUrl}/user-auth-service/api/v1/user/account/${userId}?org=${auth.getModeVal()}`;

            fetch(url, {
                headers: {
                    "authorization": auth.getBearerToken(),
                    "Content-Type": "application/json",
                    "tenant-token": auth.getTenantToken()
                },
                method: "GET"
            }).then(res => {
                return res.json();
            }).then(data => {
                resolve(data.data);
            }).catch(ex => {
                reject(ex);

            });

        })

    }

    static getUserDetailsBatch(userIdList) {

        return new Promise((resolve, reject) => {
            let url = `${userUrl}/user-auth-service/api/v1/user/accounts?org=${auth.getModeVal()}`;

            let userIdListStr = JSON.stringify(userIdList);

            fetch(url, {
                headers: {
                    "authorization": auth.getBearerToken(),
                    "Content-Type": "application/json",
                    "tenant-token": auth.getTenantToken()
                },
                method: "POST",
                body: userIdListStr
            }).then(res => {
                return res.json();
            }).then(data => {
                resolve(data.data.users);
            }).catch(ex => {
                reject(ex);

            });

        })

    }

    static getContactById(userId, workspace) {

        return new Promise((resolve, reject) => {
            let url = `${contactServiceUrl}/contact/user/${userId}/${workspace}`;

            fetch(url, {
                method: "GET",
                headers: {
                    "authorization": auth.getBearerToken(),
                    "Content-Type": "application/json"
                }
            }).then(res => {
                return res.json();

            }).then(data => {
                if (data && data.isSuccess) {

                    resolve(data.result);
                }
                else {
                    reject(new Error(`Error occurred : ${data.message}`));
                }
            }).catch(ex => {
                reject(ex);

            });

        })
    }

    static validateAndGetProfile(userId) {

        let workspace = auth.getWorkspace();

        if (!workspace) {
            workspace = __APP_DEFAULT_WORKSPACE__;
        }

        return new Promise((resolve, reject) => {
            let url = `${contactServiceUrl}/contact/user/${userId}/${workspace}`;

            fetch(url, {
                method: "GET",
                headers: {
                    "authorization": auth.getBearerToken(),
                    "Content-Type": "application/json"
                }
            }).then(res => {
                return res.json();

            }).then(data => {
                if (data && data.isSuccess) {

                    if (data.result) {
                        this.getUserDetails(userId).then(userProfile => {
                            if (userProfile) {
                                userProfile.conversation_type = 0;
                                if (data.result.userType) {
                                    userProfile.userType = data.result.userType;
                                }

                            }

                            resolve(userProfile);

                        })
                    } else {
                        resolve(null);
                    }

                }
                else {
                    reject(new Error(`Error occurred : ${data.message}`));
                }
            }).catch(ex => {
                reject(ex);

            });

        })
    }

    static getMyContacts(myId) {


        let workspace = auth.getWorkspace();

        if (!workspace) {
            workspace = __APP_DEFAULT_WORKSPACE__;
        }

        return new Promise((resolve, reject) => {

            let emptyObj = {};

            if (__APP_INTERACTION_MODE__ !== "INTERACTION") {

                let url = `${contactServiceUrl}/presence/user/${myId}/${workspace}`;


                fetch(url, {
                    method: "GET",
                    headers: {
                        "authorization": auth.getBearerToken(),
                        "Content-Type": "application/json"
                    }
                }).then(res => {
                    return res.json();

                }).then(data => {
                    if (data && data.isSuccess) {

                        if (Array.isArray(data.result) && data.result.length > 0) {

                            let contactDetails = data.result.reduce((acc, contact) => {
                                if (contact.isTemp) {
                                    //add to user list
                                    let tempUser = { userId: contact.user_id, userType: contact.userType, conversation_type: 0, isTemp: true, createdAt: contact.createdAt, InteractionId: contact.InteractionId };
                                    if (contact && contact.userData) {
                                        tempUser.firstName = contact.userData.customerName;
                                        tempUser.customerId = contact.userData.customerId;
                                        tempUser.lastName = " ";
                                        tempUser.userData = contact.userData;
                                    }
                                    acc.userList[contact.user_id] = tempUser;
                                } else {
                                    acc.contactMap[contact.user_id] = contact;
                                    acc.userIdList.push(contact.user_id);
                                }
                                return acc;

                            }, { userIdList: [], contactMap: {}, userList: {} });

                            if (contactDetails.userIdList.length > 0) {

                                this.getUserDetailsBatch(contactDetails.userIdList).then(usrArr => {

                                    let userList = usrArr.reduce((acc, user) => {
                                        user.conversation_type = 0;
                                        if (contactDetails.contactMap[user.userId] && contactDetails.contactMap[user.userId].userType) {
                                            user.userType = contactDetails.contactMap[user.userId].userType;
                                        }
                                        //test data
                                        /* user.userType = "customer";
                                        user.isTemp = true;
                                        user.createdAt = Date.now();
                                        user.userData = { name: "test name", customerId: "3435345345", occupation: "Designer", language: "Language", url: "URL", a: "gggggg", b: "ddddddd", c: "fffffff" }; */
                                        ////////////
                                        acc[user.userId] = user;
                                        return acc;
                                    }, contactDetails.userList);

                                    resolve(userList);

                                }).catch(ex => {
                                    resolve(emptyObj);
                                })

                            } else {
                                resolve(contactDetails.userList);
                            }


                            /* let funcArr = [];
                            data.result.forEach(userId => {
                                let promise = this.getUserDetails(userId);
                                funcArr.push(promise);
                            });
    
                            Promise.all(funcArr).then(usrArr => {
                                let userList = usrArr.reduce((acc, user) => {
                                    user.conversation_type = 0;
                                    acc[user.userId] = user;
                                    return acc;
                                }, {});
                                resolve(userList);
                            }).catch(ex => {
                                reject(ex);
                            }); */
                        } else {
                            resolve(emptyObj)
                        }

                    }
                    else {
                        //break operation and return empty list
                        console.error(`Error occurred`, new Error(data.message));
                        resolve(emptyObj);
                    }
                }).catch(ex => {
                    resolve(emptyObj);

                });

            } else {
                resolve(emptyObj);
            }


        })
    }


    static getOCMTeamMembers = (workspace) => {

        console.log(
            `%c [ service teamService getOCMTeamMembers ] - SessionId : ${auth.getSession()}, Data : ${JSON.stringify(
                { workspace, ocmUserHierarchyType }
            )}`,
            "background: #990033; color: #ffffff; font-size:12px"
        );

        return new Promise((resolve, reject) => {

            let url = `${ocmUsrUrl}/api/OCMApi/GetUserHierarchy`;

            var urlencoded = new URLSearchParams();
            urlencoded.append("type", ocmUserHierarchyType);
            urlencoded.append("orgUnit", workspace);
            urlencoded.append("agentId", auth.getUserId());

            fetch(url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                },
                body: urlencoded
            }).then(res => {
                return res.json();

            }).then(data => {
                if (data && data.result) {

                    let userList = data.result.reduce((acc, user) => {

                        if (user.agentFeature) {

                            let allowedUser = user.agentFeature.find(featureInfo => {
                                return featureInfo.feature === ocmAccessFeature && featureInfo.isEnable;
                            });

                            if (allowedUser) {

                                let tempUser = { userId: user.lanId, firstName: user.agentName, lastName: " ", userType: user.profile, conversation_type: 0, isTemp: false, createdAt: Date.now(), InteractionId: null };

                                acc[user.lanId] = tempUser;

                            }

                        }

                        return acc;


                    }, {});

                    resolve(userList);

                }
                else {
                    reject(new Error("Error occurred"));
                }
            }).catch(ex => {
                reject(ex);

            });

        });
    };



    static getAllTeamMembers = () => {

        return new Promise((resolve, reject) => {

            function getTeamMembersByPage(pageId, limit) {

                let url = `${userUrl}/user-auth-service/api/v1/user/accounts?pageNo=${pageId}&pageSize=${limit}&org=${auth.getModeVal()}`;

                fetch(url, {
                    method: "GET",
                    headers: {
                        "authorization": auth.getBearerToken(),
                        "Content-Type": "application/json",
                        "tenant-token": auth.getTenantToken()
                    }
                }).then(res => {
                    return res.json();

                }).then(data => {
                    if (data && data.data && data.data.users) {

                        let userList = data.data.users.reduce((acc, user) => {

                            user.conversation_type = 0;
                            acc[user.userId] = user;
                            return acc;
                        }, {});

                        resolve(userList);

                    }
                    else {
                        reject(new Error("Error occurred"));
                    }
                }).catch(ex => {
                    reject(ex);

                });
            }

            getTeamMembersByPage(1, 1000);

        });
    };


    static getTemporaryAgents = () => {

        return new Promise((resolve, reject) => {

            let url = `${userUrl}/user-auth-service/api/v1/user/active/agents?org=${auth.getModeVal()}`;

            fetch(url, {
                method: "GET",
                headers: {
                    "authorization": auth.getBearerToken(),
                    "Content-Type": "application/json"
                }
            }).then(res => {
                return res.json();

            }).then(data => {
                if (data && data.data && data.data.agents) {

                    let userList = data.data.agents.reduce((acc, user) => {

                        let tempAgentObj = user ? new TempAgent(user) : user;
                        acc[user.userId] = tempAgentObj;
                        return acc;
                    }, {});

                    resolve(userList);

                }
                else {
                    reject(new Error("Error occurred"));
                }
            }).catch(ex => {
                reject(ex);

            });

        });
    };

    static getAccountByPhoneNumber = (phoneNumber) => {

        return new Promise((resolve, reject) => {


            console.log(
                `%c [ service teamService getAccountByPhoneNumber ] - SessionId : ${auth.getSession()}, Data : ${JSON.stringify(
                    { phoneNumber }
                )}`,
                "background: #990033; color: #ffffff; font-size:12px"
            );

            let url = `${userUrl}/user-auth-service/api/v1/user/account/phone/${phoneNumber}?org=${auth.getModeVal()}`;

            fetch(url, {
                method: "GET",
                headers: {
                    "authorization": auth.getBearerToken(),
                    "Content-Type": "application/json"
                }
            }).then(res => {
                return res.json();

            }).then(data => {
                if (data) {
                    resolve(data.data);
                }
                else {
                    reject(new Error("Error occurred"));
                }
            }).catch(ex => {
                reject(ex);

            });

        });
    };

    static findContact = (contactId, channel) => {

        return new Promise((resolve, reject) => {


            console.log(
                `%c [ service teamService findContact ] - SessionId : ${auth.getSession()}, Data : ${JSON.stringify(
                    { contactId, channel }
                )}`,
                "background: #990033; color: #ffffff; font-size:12px"
            );

            let url = `${userUrl}/user-auth-service/api/v1/user/profile/${contactId}?type=${channel}&org=${auth.getModeVal()}`;

            fetch(url, {
                method: "GET",
                headers: {
                    "authorization": auth.getBearerToken(),
                    "Content-Type": "application/json"
                }
            }).then(res => {
                return res.json();

            }).then(data => {
                if (data) {
                    resolve(data.data);
                }
                else {
                    reject(new Error("Error occurred"));
                }
            }).catch(ex => {
                reject(ex);

            });

        });
    };

    static addContact = (contactInfo) => {

        return new Promise((resolve, reject) => {


            console.log(
                `%c [ service teamService addContact ] - SessionId : ${auth.getSession()}, Data : ${JSON.stringify(
                    { contactInfo }
                )}`,
                "background: #990033; color: #ffffff; font-size:12px"
            );

            let url = `${userUrl}/user-auth-service/api/v1/user/contacts`;

            fetch(url, {
                method: "POST",
                headers: {
                    "authorization": auth.getBearerToken(),
                    "Content-Type": "application/json"
                },
                body: JSON.stringify([contactInfo])
            }).then(res => {
                return res.json();

            }).then(data => {
                if (data.data && data.data.contacts) {
                    resolve(data.data.contacts);
                } else {
                    resolve(null);
                }

            }).catch(ex => {
                reject(ex);

            });

        });
    };

    static removeProfile = (id) =>{ 
     
        return new Promise((resolve, reject) => {
            let url = `${userUrl}/user-auth-service/api/v1/user/contacts`;

            fetch(url, {
                method: "DELETE",
                headers: {
                    "authorization": auth.getBearerToken(),
                    "Content-Type": "application/json",
                },
                body: JSON.stringify([{id:id}])
            }).then(res => {
                return res.json();
            }).then(data => {
                data.status === 0 ? resolve(true) : reject(new Error(data.error ? data.error.errorMessage : "Error occurred"));
            }).catch(ex => {
                reject(ex);

            });
        })
    }

}
