import * as types from "../actions/actionTypes";
import produce from "immer";

export const teamReducer = produce((draft = {}, action) => {
    switch (action.type) {
        case types.TEAM_MEMBERS_LOAD_SUCCESS:
            {
                //draft = action.team;
                return draft;

            }
        case types.RM_GROUP_FOUND:
            {
                draft = { ...draft, ...action.rmUsers };
                return draft;

            }
        case types.RELOAD_RM_USERS:
            {
                draft = action.rmUsers;
                return draft;

            }
        case types.NEW_CONTACT_ADDED:
            {
                if (action.user) {
                    draft[action.user.userId] = action.user;
                }
                return draft;
            }
        case types.SET_AVATAR_URL:
            {
                if (draft[action.data.userId]) {
                    draft[action.data.userId].avatarURL = action.data.avatarURL;

                }
                return draft;
            }
        case types.TEMP_AGENTS_LOADED:
            {
                let idsToDelete = Object.keys(draft).reduce((acc, key) => {
                    if (draft[key].isTempAgent) {
                        acc.push(key);
                    }

                    return acc;

                }, []);

                let currentDraft = JSON.parse(JSON.stringify(draft));

                idsToDelete.forEach(id => {
                    delete currentDraft[id];
                });

                draft = { ...currentDraft, ...action.tempAgents };
                return draft;

            }
        case types.SET_CONTACT_ACW_STATUS:
            {
                if (draft[action.userId]) {
                    draft[action.userId].isACW = true;

                }
                return draft;
            }

        case types.REMOVE_CONTACT:
            {
                if (draft[action.userId]) {
                    delete draft[action.userId];

                }
                return draft;
            }

        default:
            return draft;
    }
});