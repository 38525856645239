import { Logger } from './logger.js';

class ReliableSender {
    /*
    * Msg queue item
    *
    *  mid => { msg, state, completeCb, timer }
    *
    */

    /*
    *   connection is livechat2 connection
    *   options = { expiryInMilliseconds:5000, sentQueueSize: 2 }
    *
    *   ReliableSender stops sending when connection has ended
    */
    constructor (connection, options) {
        Logger.info(`Initializing Reliable Sender`);
        this.connection = connection;
        this.messageQueue = [];
        this.sentQueue = [];  // Keep max of 2 items in this queue otherwise difficult to handle failure
        this.state = {
            new: 0,
            sent: 1,
            nacked: 2,
            acked: 3,
            timedout: 4,
        };
        this.options = options || { expiryInMilliseconds: 5000, sentQueueSize: 2 };
        this.TE = this.options.expiryInMilliseconds;
        this.SentSize = this.options.sentQueueSize;
        this.exitNow = false;
        Logger.debug(`Expiry in milliseconds=${this.TE} SentQueueSize=${this.SentSize}`);
        const this_ = this;
        let next = null;
        next = () => {
            if (this_.connection.hasEnded()) {
                this_.close();
                if (this.exitNow)
                    return;
            }

            if (this_.sentQueue.length <= 0 && this_.messageQueue.length <= 0) {
                //If there are no items in the queue, we will keep a timeout of 1sec
                this_.scheduleHandle = setTimeout(next, 1000);
                return;
            }

            //Logger.info(`Next: Init Queue length=${this_.messageQueue.length}, Sent length=${this.sentQueue.length}`);
            let arr = [];
            if (this_.sentQueue.length > 0) {
                let mids = {};
                for (let i = 0; i < this_.sentQueue.length; i++) {
                    let sentItem = this_.sentQueue[ i ];
                    if (sentItem.state == this_.state.sent) {
                        arr.push(i);
                        if (!mids.hasOwnProperty(sentItem.mid))
                            mids[ sentItem.mid ] = 1;
                        else
                            continue;
                        Logger.debug(`Item for removal : ${sentItem.mid} ${Date.now()}`);
                        continue;
                    }
                }

                for (var i = arr.length - 1; i >= 0; i--) {
                    this.sentQueue.splice(arr[ i ], 1);
                }

                arr = [];

                for (let i = 0; i < this_.sentQueue.length; i++) {
                    let now = new Date();
                    if (this_.sentQueue[ i ].t.getTime() <= now.getTime()) {
                        this_.sentQueue[ i ].tc++;
                        if (this_.sentQueue[ i ].tc > 2) {
                            Logger.debug(`Next: Removing Timed out ${this_.sentQueue[ i ].mid}, Queue length= ${this_.messageQueue.length}, Sent length=${this.sentQueue.length}`);
                            this_.sentQueue[ i ].state = this_.state.timedout;
                            arr.push(i);
                            if (this_.sentQueue[ i ].cb != null) {
                                this_.sentQueue[ i ].cb(this_.sentQueue[ i ].externalMid ? this_.sentQueue[ i ].externalMid : this_.sentQueue[ i ].mid, false, this_.state.nacked);
                            }
                        }
                        else {
                            Logger.debug(`Next: Setting Time out ${this_.sentQueue[ i ].mid}, Queue length= ${this_.messageQueue.length}, Sent length=${this.sentQueue.length}`);
                            let expiry = Date.now();
                            expiry += (this_.TE);
                            this_.sentQueue[ i ].t = (new Date(expiry));
                            this_.sentQueue[ i ].tc++;
                        }
                    }
                }

                for (var i = arr.length - 1; i >= 0; i--) {
                    this.sentQueue.splice(arr[ i ], 1);
                }
            }

            arr = [];

            for (let i = 0; i < this_.messageQueue.length; i++) {
                //Check in orderly fashion, see if sent has items
                //If sent has space to send, then send

                let toSendItem = this_.messageQueue[ i ];

                if (this_.sentQueue.length < this_.SentSize) {
                    if (this_.connection.canSend()) {
                        let expiry = Date.now();
                        expiry += (this_.TE);
                        toSendItem.t = new Date(expiry);

                        this_.sentQueue.push(toSendItem);
                        Logger.debug(`Next: Sending ${toSendItem.mid}, Sent length=${this.sentQueue.length}`);
                        this_.connection.sendMessage(toSendItem.msg);

                        arr.push(i);
                    }
                }
            }

            for (var i = arr.length - 1; i >= 0; i--) {
                this_.messageQueue.splice(arr[ i ], 1);
            }

            //Logger.info(`Next: After next Queue length= ${this_.messageQueue.length}, Sent length=${this.sentQueue.length}`);
            if (this.exitNow)
                return;
            this_.scheduleHandle = setTimeout(next, 0);
        };

        this_.scheduleHandle = setTimeout(next, 0);
    }

    // close the ReliableSender
    close = () => {
        if (this.scheduleHandle)
            clearTimeout(this.scheduleHandle);

        this.exitNow = true;
        this.messageQueue.length = 0;
        this.sentQueue.length = 0;
        Logger.info(`Reliable Sender Ended`);
    };

    // enqueue the message
    enqueueMessage = (mid, msgStr, completionCb, externalMid) => {
        /*
        *  Signature of the completion handler
        *
        *  completionCb( mid, true/false, msgstate )
        *
        *  completionCb is nullable
        */

        /*
        *  enqueue msgStr and sent messages in the order received
        *  wait for ack or nack for each message
        *  messages can individually timeout in the queue
        *  on ack invoke completionCb( mid, true, this.state.acked )
        *
        *  on nack invoke completionCb( mid, false, this.state.nacked )
        *
        *  on timeout block remaining message processing and retry in 2 seconds
        *
        *  on timing out for the second time invoke completionCb( mid, false, this.state.acked.timedout )
        *
        */
        let state = this.state.new;
        Logger.debug(`Item for Send : ${mid} ${msgStr}`);
        this.messageQueue.push({ mid: mid, msg: msgStr, state: state, cb: completionCb, t: Date.now(), tc: 0, externalMid });
    };

    // callback for received ack message
    ackReceived = (mid) => {
        Logger.debug(`Received ack for ${mid}`)
        // check already sent items, set their state as sent and invoke the callback
        for (let i = 0; i < this.sentQueue.length; i++) {
            let sentItem = this.sentQueue[ i ];
            if (sentItem.mid == mid && sentItem.state != this.state.sent) {
                sentItem.state = this.state.sent;
                Logger.debug(`Raising ack callback for ${sentItem.mid}, Sent length=${this.sentQueue.length}`);
                if (sentItem.cb != null) {
                    sentItem.cb(sentItem.externalMid ? sentItem.externalMid : sentItem.mid, true, this.state.acked);
                    break;
                }
            }
        }
    };

    // callback for received nack message
    nackReceived = (mid) => {
        Logger.debug(`Received nack for ${mid}`);
        // check already sent items, set their state as sent and invoke the callback
        for (let i = 0; i < this.sentQueue.length; i++) {
            let sentItem = this.sentQueue[ i ];
            if (sentItem.mid == mid) {
                sentItem.state = this.state.sent;
                Logger.debug(`Raising nack callback for ${sentItem.mid}, Sent length=${this.sentQueue.length}`);
                if (sentItem.cb != null) {
                    sentItem.cb(sentItem.externalMid ? sentItem.externalMid : sentItem.mid, false, this.state.nacked);
                    break;
                }
            }
        }
    };

    // get the current elements in queue to be sent
    countInQueue = () => {
        return this.messageQueue.length;
    };
}

export { ReliableSender }