import React from 'react';
import PropTypes from 'prop-types';
import presenceMap from '../../../config/json/presenseMap';

import './online-status.scss'


//online
//offline
//busy
//other
const OnlineStatus = ({status, type, size}) => (
    <div className={`online-status-wrp ${type} ${size} ${status ? presenceMap[status.toLowerCase()] : "offline"}`}/>
);

OnlineStatus.propTypes = {
    status: PropTypes.string.isRequired,
};

OnlineStatus.defaultProps = {
    status: 'offline',
};

export default OnlineStatus;
