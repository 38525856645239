// import axios from 'axios';

const create = () => {
    // Set config defaults when creating the instance
    // let instance = axios.create({
    //     baseURL: 'http://localhost:2000/api/v1/',
    //     'Content-Type': 'application/json'
    // });
    // instance.defaults.headers.common['Authorization'] = 'test';
};

export default {
    create,
};
