import React from 'react';
import PropTypes from 'prop-types';
import PanelTitle from "../PanelTitle/PanelTitle";

//online
//offline
//busy
//other
const TextBoxWithIcon = ({ placeholder, size, style, onChangeHandler, onKeyPressHandler, onType, textValue }) => (
    <>
        {/* <input onChange={onType} type="text" */}
        <input type="text" onChange={onChangeHandler} onKeyPress={onKeyPressHandler}
            value={textValue}
            className={`form-control ${size} ${style}`}
            placeholder={placeholder} />
    </>
);

TextBoxWithIcon.propTypes = {
    placeholder: PropTypes.string.isRequired,
};

PanelTitle.defaultProps = {
    placeholder: 'Type here...',
};


export default TextBoxWithIcon;
