import React from 'react';
import {
    GalleryEmptyPage,
    MediaDocument
} from '../../common';
import { Scrollbars } from "react-custom-scrollbars";

let scrollBarDocs = null;

function Documents(props) {


    return (
        <>
            <div className={'media-body-wrapper p-10'}>

                <Scrollbars
                    ref={c => {
                        scrollBarDocs = c;
                    }}
                    onScrollStop={() => {
                        let values = scrollBarDocs ? scrollBarDocs.getValues() : null;
                        if (values.top > 0.999) {
                            //scrolled to bottom
                            props.onScrollEnd("documents");
                        }
                    }}
                    thumbMinSize={0}
                    style={{ width: 300, height: props.viewPointHeight - 80 }}>
                    {props.documents}
                </Scrollbars>

            </div>


        </>
    );
}


export default Documents;
