import React from 'react';
import PropTypes from 'prop-types';
import {OnlineStatus, ProfileImage} from "../index";


const ParticipationImage = ({src, fullName, size, userState, active,onClick}) => (
    <>
        <div onClick={onClick}
            className={`Participation-image ${size} ${userState} ${active ? 'active' : ''} `}>
            {src &&
            <img alt={fullName.substring(1, 0)} src={src}/>
            }
            {src === null &&
            <div className={'default-img-text '}>
                {fullName.substring(1, 0)}
            </div>
            }
        </div>
    </>
);

ParticipationImage.propTypes = {
    fullName: PropTypes.string.isRequired,
    userState: PropTypes.string.isRequired,
};

ParticipationImage.defaultProps = {
    src: null,
    firstLatter: 'A',
    fullName: 'U',
    userState: 'connected',
    active: false
};

export default ParticipationImage;
