import React from 'react';
import Utils from '../../../utils/common';
import moment from 'moment-timezone';
import * as auth from "../../../utils";
import { connect } from "react-redux";
import { MenuIcon } from "../../common";
import {
    Edit
} from "react-feather";

const Event = (props) => {
    let showStatusButtons = () => {
        if ((props.meeting_status && props.meeting_status.status === 'Started') || (props.status && props.status !== 'NotAccepted') || (props.block.creator === auth.getUserId())) {
            return false;
        } else {
            return true;
        }
    }

    let isVisible = showStatusButtons();
    return (
        <div className="Weekly-item m-5 pb-5 pb-20" key={props.block.id}>
            <div className="col-sm-12 float-left pl-0">
                <div className="img-round"><img className="img-round" alt='pin-icon.png' src={require('../../../assets/schedule/images/meet-1.png')} /></div>

                <div className="call-doctor-for-test-6 f-400" title={props.block.title} style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "no-wrap" }}>{props.block.title}
                    {props.block.creator === auth.getUserId() && <div style={{ display: "inline", float: "right", height: "0px !important", cursor: "pointer" }}>
                        <div
                            title="Reschedule"
                            onClick={() => {
                                props.setUIState('MEETING_INFO_UPDATE', props.block);
                            }}>
                            {<Edit size="15px" />}
                        </div>
                    </div>}
                </div>
                <p className="x15-mar-2020-900-a-6 sourcesanspro-normal-steel-blue-15px mb-2">
                    {moment(Utils.getLocalDate(props.block.startDate)).format("DD MMM YYYY")}
                        - {Utils.getLocalTime(props.block.startTime)}</p>
            </div>
            {/* <div className="col-sm-3 float-left pr-0">
                <div className=" float-right mt-5 sourcesanspro-normal-steel-blue-14px ">
                    {moment(Utils.getLocalDate(props.block.createdOn)).format("DD MMM YYYY")}</div>
            </div> */}
            <div className="ml-60 float-left widt">
                {isVisible && <>
                    <button className="btn btn-primary group accept float-left mt-5 mr-5" onClick={() => {
                        props.onSetParticipantState('Accept', props.block);

                    }}>
                        <div className=" personal sourcesanspro-normal-white-14px ">Accept</div>
                    </button>
                    <button className="btn btn-primary group float-left mt-5 mr-5" onClick={() => {
                        props.onSetParticipantState('Deny', props.block);

                    }}>
                        <div className=" personal sourcesanspro-normal-white-14px ">Reject</div>
                    </button>
                    <button className="btn btn-primary group maybe float-left mt-5 mr-5" onClick={() => {
                        props.onSetParticipantState('MayBe', props.block);

                    }}>
                        <div className=" personal sourcesanspro-normal-white-14px ">Maybe</div>
                    </button>
                </>}

                {/* <p className="ask-for-blood-tests sourcesanspro-normal-ebony-clay-14px 
                    float-left width-100 mt-2 pt-1 mb-0">
                    {props.block.title}<br />{props.block.description}
                </p> */}
            </div>
        </div>
    )
};

function mapStateToProps(state, ownProps) {

    return {
        meeting_status: state.meetings[ownProps.block.id]
    };
}

export default connect(mapStateToProps)(Event);