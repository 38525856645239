import React from 'react';
import PropTypes from 'prop-types';
import './gallery-full-view.scss';


const GalleryFullView = ({galleryViewType, url}) => (
    <>
        <section className={'gv-full-screen-wrapper'}>
            <img src={url} alt="Umbrella"/>
        </section>

    </>
);

GalleryFullView.propTypes = {};

GalleryFullView.defaultProps = {};

export default GalleryFullView;
