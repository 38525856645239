class MeetingStatus {

    constructor(obj) {
        this.meetingId = obj.meetingId;
        this.status = obj.status;
        this.interactionId = obj.interactionId;
    }

}

export { MeetingStatus }