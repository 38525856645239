export class conversationApi {

    constructor (config, livechat) {
        this._livechat = livechat;
        this.chatHistoryUrl = config ? config.chatHistoryUrl : "";
    }

    /**
    * api-method
    * Retrieves all conversations related to a specific user
    * @param {string} userId User Id of the user (Cannot be blank)
    * @returns {Promise} Promise object is an array of objects with conversation information
    */
    getUserConversations = userId => {
        return new Promise((resolve, reject) => {
            let url = `${this.chatHistoryUrl}/api/v1/conversations/${userId}`;

            fetch(url, {
                method: "GET",
                headers: {
                    "authorization": `Bearer ${this._livechat.getAuthToken()}`
                }
            })
                .then(res => {
                    return res.json();
                })
                .then(data => {
                    if (data && data.isSuccess) {
                        resolve(data.result);
                    } else {
                        reject(new Error("Error occurred"));
                    }
                })
                .catch(ex => {
                    reject(ex);
                });
        });
    };

    /**
    * api-method
    * Retrieves set of starred messages on converation thread between from and to user provided from a given timestamp
    * @param {integer} conversation_type - type of the conversation (User to user = 0 , Group = 3) (Cannot be blank)
    * @param {integer} timestamp - unix timestamp in utc (Cannot be blank)
    * @param {string} from from user id (Cannot be blank)
    * @param {string} to to user id (Cannot be blank)
    * @param {integer} limit number of records to retrieve (Cannot be blank)
    * @returns {Promise} Promise object is an array of objects of starred messages
    */
    getStarredMessages = (conversation_type, timestamp, from, to, limit) => {
        return new Promise((resolve, reject) => {
            let url = `${this.chatHistoryUrl}/api/v1/conversation/messages?type=${conversation_type}&dateFrom=${timestamp}&from=${from}&to=${to}&limit=${limit}&filterBy=starred&filterValue=true`;

            fetch(url, {
                method: "GET",
                headers: {
                    "authorization": `Bearer ${this._livechat.getAuthToken()}`
                }
            })
                .then(res => {
                    return res.json();
                })
                .then(data => {
                    if (data && data.isSuccess) {
                        resolve(data.result);
                    } else {
                        reject(new Error("Error occurred"));
                    }
                })
                .catch(ex => {
                    reject(ex);
                });
        });
    };

    /**
    * api-method
    * Allows user to star or unstar a message
    * @param {string} mid - message id that is being starred or unstarred (Cannot be blank)
    * @param {boolean} isStarring - indication whether message should be starred or unstarred (star = true, unstar = false) (Cannot be blank)
    * @param {string} from user id of the person who is starring or unstarring the message (Cannot be blank)
    * @returns {Promise} Promise object is bollean value specifying whether operation is successful or not
    */
    starMessage = (from, mid, isStarring) => {
        return new Promise((resolve, reject) => {
            let url = `${this.chatHistoryUrl}/api/v1/conversation/update/${this._livechat.getUserId()}?from=${from}&mid=${mid}&isStarred=${isStarring}`;

            fetch(url, {
                method: "PUT",
                headers: {
                    "authorization": `Bearer ${this._livechat.getAuthToken()}`
                }
            })
                .then(res => {
                    return res.json();
                })
                .then(data => {
                    if (data && data.isSuccess) {
                        resolve(true);
                    } else {
                        reject(new Error("Error occurred"));
                    }
                })
                .catch(ex => {
                    reject(ex);
                });
        });
    };

    /**
    * api-method
    * Allows user to search a messages from conversation history
    * @param {string} user - userId of the user that is searching for the message (Cannot be blank)
    * @param {string} to - conversation other party id - can be other party's userId or a group id if the conversation is a group (Cannot be blank)
    * @param {integer} conversation_type whether the conversation is a 1 to 1 chat or a group (0 for 1 to 1 chat / 3 for group) (Cannot be blank)
    * @param {string} searchMessage - message or part of a word to be searched inside conversation thread
    * @returns {Promise} Promise object resolves to an array of conversation message objects that satisfy search criteria
    */
    searchMessages = (user, to, conversation_type, searchMessage) => {
        return new Promise((resolve, reject) => {
            let url = `${this.chatHistoryUrl}/api/v1/conversation/search?user=${user}&type=${conversation_type}&to=${to}&limit=100&msg=${searchMessage}`;

            fetch(url, {
                method: "GET",
                headers: {
                    "authorization": `Bearer ${this._livechat.getAuthToken()}`
                }
            })
                .then(res => {
                    return res.json();
                })
                .then(data => {
                    if (data && data.isSuccess) {
                        resolve(data.result);
                    } else {
                        //break operation and return empty list
                        reject(new Error("Error occurred"));
                    }
                })
                .catch(ex => {
                    reject(ex);
                });
        });
    };

    /**
    * api-method
    * Allows user to retrieve messages from conversation history
    * @param {string} from - userId of the first party involved in the conversation (Cannot be blank)
    * @param {string} to - user or group id of the other party of the conversation (Cannot be blank)
    * @param {integer} type whether the conversation is a 1 to 1 chat or a group (0 for 1 to 1 chat / 1 for group) (Cannot be blank)
    * @param {integer} timestamp - timestamp where you want to query the messages from
    * @param {integer} limit - amount of messages to be retrieved
    * @returns {Promise} Promise object resolves to an array of conversation message objects that happened before the given timestamp
    */
    getBeforeMessages = (type, from, to, timestamp, limit) => {
        let tempType = 0;
        if (type === 3) {
            tempType = 1;
        }
        let url = `${this.chatHistoryUrl}/api/v1/conversation/messages/before?type=${tempType}&dateFrom=${timestamp}&from=${from}&to=${to}&limit=${limit}`;

        return new Promise(function (resolve, reject) {
            fetch(url, {
                method: "GET",
                headers: {
                    "authorization": `Bearer ${this._livechat.getAuthToken()}`
                }
            })
                .then(res => {
                    return res.json();
                })
                .then(data => {
                    if (data && data.isSuccess) {
                        resolve(data.result);
                    } else {
                        reject(new Error("Error occurred"));
                    }
                })
                .catch(ex => {
                    reject(ex);
                });
        });
    };

    /**
    * api-method
    * Allows user to retrieve messages from conversation history
    * @param {string} from - userId of the first party involved in the conversation (Cannot be blank)
    * @param {string} to - user or group id of the other party of the conversation (Cannot be blank)
    * @param {integer} type whether the conversation is a 1 to 1 chat or a group (0 for 1 to 1 chat / 1 for group) (Cannot be blank)
    * @param {integer} timestamp - timestamp where you want to query the messages from
    * @param {integer} limit - amount of messages to be retrieved
    * @returns {Promise} Promise object resolves to an array of conversation message objects that happened after the given timestamp
    */
    getAfterMessages = (type, from, to, timestamp, limit) => {

        let tempType = 0;
        if (type === 3) {
            tempType = 1;
        }
        let url = `${this.chatHistoryUrl}/api/v1/conversation/messages/after?type=${tempType}&dateFrom=${timestamp}&from=${from}&to=${to}&limit=${limit}`;

        return new Promise(function (resolve, reject) {
            fetch(url, {
                method: "GET",
                headers: {
                    "authorization": `Bearer ${this._livechat.getAuthToken()}`
                }
            })
                .then(res => {
                    return res.json();
                })
                .then(data => {
                    if (data && data.isSuccess) {
                        resolve(data.result);
                    } else {
                        reject(new Error("Error occurred"));
                    }
                })
                .catch(ex => {
                    reject(ex);
                });
        });
    };

    /**
    * api-method
    * Allows user to retrieve media messages from conversation history
    * @param {string} from - userId of the first party involved in the conversation (Cannot be blank)
    * @param {string} to - user or group id of the other party of the conversation (Cannot be blank)
    * @param {integer} type whether the conversation is a 1 to 1 chat or a group (0 for 1 to 1 chat / 3 for group) (Cannot be blank)
    * @param {integer} dateFrom - timestamp where you want to query the messages from
    * @param {integer} limit - amount of messages to be retrieved
    * @returns {Promise} Promise object resolves to an array of conversation media message objects that happened before the given timestamp
    */
    getMediaFromHistory = (type, dateFrom, from, to, limit) => {
        return new Promise(function (resolve, reject) {
            let url = `${this.chatHistoryUrl}/api/v1/conversation/messages?type=${type}&dateFrom=${dateFrom}&from=${from}&to=${to}&limit=${limit}&filterBy=media&filterValue=true`;

            fetch(url, {
                method: "GET",
                headers: {
                    "authorization": `Bearer ${this._livechat.getAuthToken()}`
                }
            }).then(res => {
                return res.json();
            }).then(data => {
                if (data && data.isSuccess) {
                    resolve(data.result);
                } else {
                    reject(new Error("Error occurred"));
                }
            })
                .catch(ex => {
                    reject(ex);
                });
        });
    };
}
