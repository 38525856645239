import React from 'react';
import PropTypes from 'prop-types';

//online
//offline
//busy
//icon = <MessageCircle/>
//other
const TextWithIcon = ({icon, text, size, onClick,iconPosition,space}) => (
    <div onClick={onClick} className={`menu-with-icon d-flex ${space} ${size}`}>
        {iconPosition === 'left-position' &&
        <div className={'icon'}>
            {icon}
        </div>
        }
        <div className={'text'}>
            {text}
        </div>
        {iconPosition === 'right-position' &&
        <div className={'icon'}>
            {icon}
        </div>
        }
    </div>
);

TextWithIcon.propTypes = {
    // onClick: PropTypes.func.isRequired,
    // text: PropTypes.string.isRequired,
    // icon: PropTypes.string.isRequired,
};

TextWithIcon.defaultProps = {
    size: 'sm',
    text: null
};

export default TextWithIcon;
