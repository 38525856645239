import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { message } from "antd/lib/index";
import sdkHandler from "../../../lib";
import * as auth from "../../../utils";
import * as selectedProfileActions from "../../../redux/actions/selectedProfileActions";
import * as presenceActions from "../../../redux/actions/presenceActions";
import { ButtonRounded } from "../../common";
import { __APP_DEFAULT_WORKSPACE__, __APP_IDENTITY_PROVIDER__, __APP_MODES__ } from "../../../config/baseUrls";
import AuthService from "../../../service/authService.js";
import { Modal } from 'antd';
import phoneEngine from '../phone/phoneEngine/phoneEngineHandler';
import * as logger from "../../../lib/logSdk/logger";

let appModes = JSON.parse(__APP_MODES__);

const CallbackRedirect = props => {

    let location = useLocation();

    const [connectivityStatus, setConnectivityStatus] = useState({
        loginState: true,
        isSessionExist: false,
        message: "Please wait while we log you in automatically..."
    });

    const proceedToReInitialize = () => {
        console.log('Callback Auto Login Process', `loginToSystem - proceedToReInitialize `, "session retake");
        sdkHandler.startSession(true);
    };

    const initiateLogin = () => {

        setConnectivityStatus({ loginState: true, isSessionExist: false, message: "Please wait while we log you in automatically..." });

        const params = new Map(location.search.slice(1).split('&').map(kv => kv.split('=')));
        let session = params.get('token');
        logger.debug("AgentConsole", "Callback login", `------ AgentConsole ------ Session : ${session}`);
        if (location && location.state && location.state.detail) {
            session = location.state.detail.session;
        }

        console.log('Callback Auto Login Process - START');
        sdkHandler.validateLoginSession(session).then(response => {
            console.log('Callback Auto Login Process', `loginToSystem - isAuthenticationSuccess : ${response.validated}`);
            if (response.validated) {
                auth.setToken(response.token, response.result.userName);
                auth.setUser(response.result.userName);
                auth.setDisplayName(response.result.userName);
                auth.setUserType("IAM_USER");
                /* auth.setCustomData({ displayname, isVideo }); */
                /* response.tokenInfo && response.tokenInfo.organizations && auth.setWorkspaces(response.tokenInfo.organizations); */
                auth.setRefreshToken(response.token);
                /* response.token && auth.setWorkspace(__APP_DEFAULT_WORKSPACE__, response.result.userName); */
                auth.setPassword('');
                props.selected_profile_actions.profileSelected({});
                auth.resetMediaDeviceErrorCount();
            } else {
                message.error(response.token ? response.token : "Session validation failed - Please re-login");
                logger.error("CallbackRedirect", "initiateLogin", "Session validation failed");
                setConnectivityStatus({ loginState: false, isSessionExist: false, message: "Log in error" });

                setTimeout(() => {

                    props.history.push(`/error`);

                    /* window.location.reload();
                    console.log("AutoLogin", "uireload", "useEffect", "window.location.reload"); */

                }, 2000);
            }

        }).catch(ex => {
            console.error(ex);
            logger.error("AgentConsole", "loginToSystem", ex.message);
            setConnectivityStatus({ loginState: false, isSessionExist: false, message: "Log in error" });

            setTimeout(() => {

                props.history.push(`/error`);

                /* window.location.reload();
                console.log("AutoLogin", "uireload", "useEffect", "window.location.reload"); */

            }, 2000);
        });
    }

    useEffect(() => {
        sdkHandler.subscribeEvent(
            "callback_login-onStartSuccess",
            "onStartSuccess",
            sid => {
                console.log('AutoLogin Process', 'onStartSuccess', `sid : ${sid}`);
                auth.setSession(sid);
                if (connectivityStatus.loginState) {
                    let presenceObj = { userid: auth.getUserId(), status: "online" };
                    let mode = appModes.find(m => m.value === ':OCM');
                    __APP_IDENTITY_PROVIDER__ !== 'IAM' && mode && auth.setMode(mode);
                    props.presence_actions.sendPresenceSuccess(presenceObj);
                    sdkHandler.sendPresenceNotification(JSON.stringify(presenceObj));
                    console.log(`Routing to Main Console`);
                    props.history.push(`/console`);
                    return;
                } else {
                    console.error(`Start Success Failed with Login State - SessionId : ${auth.getSession()}`);
                    logger.error("AgentConsole", "onStartSuccess", `Start Success Failed with Login State - SessionId : ${auth.getSession()}`);
                    phoneEngine.phoneFunctions.hangup("caller", "callee");
                    /* __APP_IDENTITY_PROVIDER__ === 'GUARDIAN' && authService.logout(auth.getRefreshToken(), "SESSION_END"); */
                    /* sdkHandler.logoutFromSystem("", "SESSION_END", ""); */
                    sdkHandler.endSession();
                    auth.userLogOut();
                    setConnectivityStatus({ loginState: false, isSessionExist: false, message: "Log in error" });

                    setTimeout(() => {

                        props.history.push(`/error`);

                        /* window.location.reload();
                        console.log("AutoLogin", "uireload", "useEffect", "window.location.reload"); */

                    }, 2000);

                }
            }
        );
        sdkHandler.subscribeEvent(
            "callback_login-onStartFailed",
            "onStartFailed",
            error => {

                let errorObj = JSON.parse(error);
                if (errorObj.reasonCode === 'SESSION_EXIST') {
                    console.warn(`Session Exists - Current Session : ${auth.getSession()}`);
                    logger.warn("AgentConsole", "onStartFailed", `Session Exists - Current Session : ${auth.getSession()}`);
                    setConnectivityStatus({ loginState: true, isSessionExist: true, message: "Another session in progress" });
                    //proceedToReInitialize();

                } else {
                    console.log('Auto Login Process', 'onStartFailed');
                    logger.error("AgentConsole", "onStartFailed", `onStartFailed - Current Session : ${auth.getSession()}`);
                    if (errorObj.reasonCode === "LICENSE_VALIDATION_FAILED_MAX_SESSION_REACHED") {
                        console.log('Auto Login Process', 'onStartFailed - Licence error');
                        message.error("Max sessions allowed for your license reached");
                    }
                    phoneEngine.phoneFunctions.hangup("caller", "callee");
                    /* __APP_IDENTITY_PROVIDER__ === 'GUARDIAN' && authService.logout(auth.getRefreshToken(), "SESSION_END");
                    sdkHandler.logoutFromSystem("", "SESSION_END", ""); */
                    sdkHandler.endSession();
                    auth.userLogOut();
                    // message.error("Fail To Initialize Socket Connection.");
                    console.error("signing-onStartFailed.", error);
                    setConnectivityStatus({ loginState: false, isSessionExist: false, message: "Log in error" });

                    setTimeout(() => {

                        props.history.push(`/error`);
                        /* window.location.reload();
                        console.log("AutoLogin", "uireload", "onStartFailed", "window.location.reload"); */

                    }, 2000);


                }

            }
        );

        console.log(`Callback Auto login Mounting - Attempting to Initiate Login`);

        initiateLogin();

    }, []);


    return (
        <>
            <Modal
                title="Ongoing Session - Do you wish to retake ?"
                closable={false}
                visible={connectivityStatus.isSessionExist}
                maskClosable={false}
                onOk={proceedToReInitialize}
                confirmLoading={false}
                onCancel={() => {
                    phoneEngine.phoneFunctions.hangup("caller", "callee");
                    /* __APP_IDENTITY_PROVIDER__ === 'GUARDIAN' && authService.logout(auth.getRefreshToken(), "SESSION_TAKEOVER_CANCELLED"); */
                    //sdkHandler.logoutFromSystem("", "SESSION_TAKEOVER_CANCELLED", "");
                    auth.userLogOut();
                    props.history.push(`/logout`);
                    /* window.location.reload();
                    console.log("AutoLogin", "uireload", "onCancel", "window.location.reload"); */
                }}
            >
                <p><img style={{ width: "fit-content", display: "block", marginLeft: "auto", marginRight: "auto" }} src={require("../../../assets/img/chatEmpty1.svg")}
                    alt="User Avatar" />
                </p>
            </Modal>

            <section className={`main-login-wrapper`}>
                <div className={'top-left-leaf-bg'}>
                    <img src={require("../../../assets/img/bg/login/topRightLeaf.svg")}
                        alt="User Avatar" />
                </div>


                {/*====== login from main wrapper start here ========*/}
                <div className={'container'}>
                    <div className={'login-layout-wrp d-flex justify-content-between'}>
                        <div className="sign-up-form-main-wrapper ">
                            <div className="sign-up-form-inner-wrapper">
                                <div className={`sign-up-form`}>
                                    <div className={'character-wrp error-page text-center'}>
                                        <img src={require("../../../assets/img/bg/login/MainCharacter.svg")}
                                            alt="User Avatar" />
                                    </div>
                                    <div className={"sign-in-body-wrapper text-center"}>
                                        <div className={'text-wrapper connectivityStatusMsg '}>
                                            {connectivityStatus.message}
                                        </div>
                                        {!connectivityStatus.loginState &&
                                            <div className={`login-bnt-hide mt-5`}>
                                                <ButtonRounded
                                                    title="TRY AGAIN"
                                                    onClick={initiateLogin}
                                                    size="btn-lg btn-block "
                                                    type="btn-primary" />
                                            </div>
                                        }
                                        {connectivityStatus.loginState &&
                                            <div className={'pre-loader-login mt-5'}>
                                                <div className="loader9" />
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={'login-footer-bg-wrapper d-flex'}>
                    <div className={'left-plant'}>
                        <img src={require("../../../assets/img/bg/login/footerPlantLeft.svg")}
                            alt="User Avatar" />
                    </div>
                    <div className={'transparent-plant'}>
                        <img src={require("../../../assets/img/bg/login/transparentPlant.svg")}
                            alt="User Avatar" />
                    </div>
                    <div className={'left-single-plant'}>
                        <img src={require("../../../assets/img/bg/login/singlePlant.svg")}
                            alt="User Avatar" />
                    </div>

                    <div className={'right-plant'}>
                        <img src={require("../../../assets/img/bg/login/rightPlant.svg")}
                            alt="User Avatar" />
                    </div>

                </div>

                <div className={'sky-bg'}>
                    <img src={require("../../../assets/img/bg/login/skyBg.svg")}
                        alt="User Avatar" />
                </div>

                <div className={'left-round-wrp'} />
                <div className={'top-bg-round-wrp'} />


                <div className={'stars-wrapper'}>
                    <img src={require("../../../assets/img/bg/login/stars.svg")}
                        alt="User Avatar" />
                </div>
                <div className={'stars-wrapper-2'}>
                    <img src={require("../../../assets/img/bg/login/starsOnly.svg")}
                        alt="User Avatar" />
                </div>

            </section>

        </>
    );
};

function mapDispatchToProps(dispatch) {
    return {
        selected_profile_actions: bindActionCreators(selectedProfileActions, dispatch),
        presence_actions: bindActionCreators(presenceActions, dispatch)
    };
}

export default connect(
    null,
    mapDispatchToProps
)(CallbackRedirect);
