import React from 'react';
import PropTypes from 'prop-types';
import { UserPlus, Pause, Mic, MicOff, PhoneOutgoing, Airplay } from "react-feather";
import { ChatUser, MenuIcon, TitleWithSmallText, UserImage, ParticipationImage } from "../index";
import { Scrollbars } from "react-custom-scrollbars";
import ScrollAnimation from 'react-animate-on-scroll';
import navigationRestrictions from "../../../config/navigationRestrictions";
import liveChatClient from "../../../lib";

//online 
//offline
//busy
//other
const ChatEmptyMessage = ({ viewPointHeight, viewPointWidth }) => (
    <div className={`chat-empty-wrapper`}
        style={{ width: viewPointWidth - ( navigationRestrictions.meeting.meeting_only? 0 : 380), height: viewPointHeight }}> 

        <div className={'chat-empty-inner-wrapper'}>

            <div className={`chat-empty-img-wrp d-flex`}>
                {/*<ScrollAnimation delay="450"*/}
                {/*                 animateIn="fadeInUp"*/}
                {/*                 animateOut="fadeOutDown">*/}
                {/*    <img className={'chatCharacter1'} src={require("../../../assets/img/chatEmpty1.svg")}*/}
                {/*         alt="User Avatar"/>*/}
                {/*</ScrollAnimation>*/}

                <ScrollAnimation delay={650}
                    animateIn="fadeInUp"
                    animateOut="fadeOutDown">
                    <img src={require("../../../assets/img/chatEmpty2.svg")}
                        className={'chatEmpty2'}
                        alt="User Avatar" />
                </ScrollAnimation>


                <div className={'circal-main-wrp'}>
                    <div className={'circal-zoom'} />
                    <div className={'circal-zoom'} />
                    <div className={'circal-zoom'} />
                    <div className={'circal-zoom'} />
                    <div className={'circal-zoom'} />
                    <div className={'circal-zoom'} />
                    <div className={'circal-zoom'} />
                </div>


                <div className="particles-wrapper">
                    <div className="particles floating-bubbles"></div>
                </div>
            </div>

            <div className={'empty-message-1'}>
                <ScrollAnimation delay={350}
                    animateIn="fadeInUp"
                    animateOut="fadeOutDown">
                    {navigationRestrictions.chat.empty_page.heading}
                </ScrollAnimation>
            </div>
            <div className={'empty-message-2'}>
                <ScrollAnimation delay={500}
                    animateIn="fadeInUp"
                    animateOut="fadeOutDown">
                    {navigationRestrictions.chat.empty_page.sub_heading}
                </ScrollAnimation>
            </div>

            {/*<ScrollAnimation delay="450"*/}
            {/*                 animateIn="fadeIn"*/}
            {/*                 animateOut="fadeDown">*/}
            {/*    <div className={'empty-chat-bg'}>*/}
            {/*        <img src={require("../../../assets/img/bgChat.svg")}*/}
            {/*             alt="User Avatar"/>*/}
            {/*    </div>*/}
            {/*</ScrollAnimation>*/}
        </div>


    </div>
);

// OutGoingCall.propTypes = {
//     status: PropTypes.string.isRequired,
// };

ChatEmptyMessage.defaultProps = {};

export default ChatEmptyMessage;
