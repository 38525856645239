import React from 'react';
import PropTypes from 'prop-types';
import {OnlineStatus, ProfileImage} from "../index";


const VideoCallerProfile = ({src, fullName, connectStatus, type, activeUser, theme, size}) => (
    <>

        <div className={`video-profile-caller-center ${size}`}>
            <div className={`user-profile-img-wrp ${theme} ${size}`}>
                {src === null &&
                <img src={require("../../../assets/img/inComingCallDefaultUser.svg")}
                     alt="User Avatar"/>
                }
                {src !== null &&
                <img src={src}
                     className={'with-url'}
                     alt="User Avatar"/>
                }
            </div>

            <div className={`profile-full-name ${theme} ${size}`}>
                {fullName}
            </div>

            <div className={`on-call-status ${theme} ${size}`}>
                {connectStatus}
            </div>
        </div>

    </>
);

VideoCallerProfile.propTypes = {
    //src: PropTypes.string.isRequired,
    fullName: PropTypes.string.isRequired,
};

VideoCallerProfile.defaultProps = {
    src: null,
    firstLatter: '',
    fullName: '',
    size: '',
};

export default VideoCallerProfile;
