import React from 'react';
import PropTypes from 'prop-types';
import navigationRestrictions from "../../../config/navigationRestrictions";
import {ProfileImage} from "../../common";
import {getSession, getUserId} from "../../../utils";


const MobileHeader = ({props, status}) => (
    <>
        <div className={'mobile-header-banner-1'}>
            <img src={require("../../../assets/img/mobile/mobileHeaderTop.svg")}
                 alt="User Avatar"/>
        </div>
        <div className={'mobile-header-banner-bg'}>
            <img src={require("../../../assets/img/mobile/star.svg")}
                 alt="bgStar"/>
        </div>
        <section className={'mobile-action-panel-header d-flex'}>
            <div className={'mobile-profile-image-wrp'}
                 onClick={props.onClickOpenMobileProfileMenu}>
                <ProfileImage
                    type={'mobile'}
                    src={props.my_profile && props.my_profile.avatarURL ? props.my_profile.avatarURL : null}
                    status={status}/>
            </div>
            <div className={'mobile-profile-details-wrapper d-flex flex-column'}>
                <div className={'m-p-name'}>Hi,
                    {props.my_profile && props.my_profile.firstName ? props.my_profile.firstName : null}</div>
                <div className={'m-p-workspace'}>@workspace</div>
            </div>

        </section>

    </>
);


export default MobileHeader;
