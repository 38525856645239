export default {
    call: "",
    clientProfile: {
        user: {firstName: ""},
        contactsData: [],
        hadProfile: false,
        contactType: "primary",
        contact: {},
        userName: ""
    },
    fileShareStatus: false
};
