export class fileApi {

    constructor (config, livechat) {
        this._livechat = livechat;
        this.fileServiceUrl = config ? config.fileServiceUrl : "";
        this.fileMetaServiceUrl = config ? config.fileMetaServiceUrl : "";
    }

    /**
    * api-method
    * Allows the user to upload a file from local storage.
    * @param {File} file File object retrieved from your local system (Cannot be blank)
    * @param {string} refId Unique id to identify file as (Cannot be blank)
    * @param {string} conversationId Conversation id of the chat thread to group file as (Cannot be blank)
    * @returns {Promise} Promise that resolves to an object of upload file information
    */
    uploadFile = (file, refId, conversationId) => {
        return new Promise((resolve, reject) => {

            let fd = new FormData();
            fd.append("file", file);
            fd.append("organization_id", "tetherfisl");
            fd.append("interaction_id", refId);
            fd.append("uploaded_by", this._livechat.getUserId());
            fd.append("conv_id", conversationId);

            fetch(`${this.fileServiceUrl}/upload`, {
                method: "POST",
                headers: {
                    "authorization": `Bearer ${this._livechat.getAuthToken()}`
                },
                body: fd
            }).then(response => response.json())
                .then(data => {
                    resolve(data.result);
                }).catch(error => {
                    reject(error);
                });

        });

    };

    /**
    * api-method
    * Allows user to download a file via browser for a given file with upload refId.
    * @param {string} refId Unique id to identify file as (Cannot be blank)
    * @param {string} fileName file name to save the file as in your local system (Cannot be blank)
    */
    downloadFile = (refId, fileName) => {

        var xhr = new XMLHttpRequest();
        xhr.open("GET", `${this.fileMetaServiceUrl}/download/media/${refId}`, true);
        xhr.responseType = "blob";
        xhr.onload = function () {
            var urlCreator = window.URL || window.webkitURL;
            var imageUrl = urlCreator.createObjectURL(this.response);
            var tag = document.createElement('a');
            tag.href = imageUrl;
            tag.download = fileName;
            document.body.appendChild(tag);
            tag.click();
            document.body.removeChild(tag);
        }
        xhr.send();
    };

}