import React, { useState, forwardRef, useImperativeHandle } from 'react';
import { PanelTitle } from "../../common";
import actionTitleMap from "../../../config/json/actionTitleMap";
import navigationRestrictions from "../../../config/navigationRestrictions";
import ConnectivityStatusIndicator from "../../common/ConnectivityStatusIndicator/ConnectivityStatusIndicator";
import phoneEngine from "../phone/phoneEngine/phoneEngineHandler";

const ActionPanelTitle = forwardRef((props, ref) => {
    const [titleValue, setTitleValue] = useState(1);

    useImperativeHandle(ref, () => ({
        onSelectionChange: (value) => {
            setTitleValue(value);
        }
    }));

    return (
        <div className={"apm-title"} style={{ display: "inline-flex" }}>
            <PanelTitle title={actionTitleMap[titleValue] ? actionTitleMap[titleValue] : "Chat"} />
            {navigationRestrictions.connectivity_indicator.active && !navigationRestrictions.profile.summerized_info.active &&
                <div style={{ marginLeft: "10px" }}>
                    <ConnectivityStatusIndicator phoneEngine={phoneEngine} onClickSelfView={props.onClickSelfView}/>
                </div>}
        </div>
    )
});

export default ActionPanelTitle;
