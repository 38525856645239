import React from 'react';
import PropTypes from 'prop-types';


//online
//offline
//busy
//icon = <MessageCircle/>
//other
const MenuIcon = ({ icon, size, onClick, status, iconType, color, title }) => (
    <>
        {iconType !== 'material' &&

            <div
                title={title ? title : ""}
                onClick={onClick}
                className={`menu-icon ${color}  ${size} ${status}`}>
                {icon}
            </div>
        }
        {iconType === 'material' &&
            <div
                title={title ? title : ""}
                onClick={onClick}
                className={`menu-icon material ${color} ${size} ${status}`}>
                {icon}
            </div>
        }
    </>
);

MenuIcon.propTypes = {
    size: PropTypes.string.isRequired,
};

MenuIcon.defaultProps = {
    size: 'sm',
    status: 'default',
};

export default MenuIcon;
