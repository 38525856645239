import React, { useEffect } from "react";

const AudioListener = ({ mediaStream }) => {
  const audioRef = React.createRef();
  useEffect(() => {
    try {
      audioRef.current.srcObject = mediaStream;
    } catch (ex) {
      console.error(ex);
    }
  }, []);
  return (
    <>
      <audio ref={audioRef} autoPlay playsInline></audio>
    </>
  );
};

AudioListener.defaultProps = {};

export default AudioListener;
