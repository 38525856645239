import * as types from "../actions/actionTypes";
import produce from "immer";
import renderState from "../../service/renderStateHandler";

export const chatReducer = produce((draft = {}, action) => {

    switch (action.type) {
        case types.ADD_NEW_CHAT_USER:
            {
                draft[action.user.username] = { messages: {}, unseen_count: 0, conversation_dates: [], is_loaded: true, chat_render_event: "ADD_NEW_CHAT_USER" };
                return draft;
            }
        case types.RESET_MESSAGES:
            {
                draft[action.data.user].messages = {};
                draft[action.data.user].search_mode = action.data.search_mode;
                return draft;
            }
        case types.SEND_NEW_MESSAGE_SUCCESS:
            {
                if (action.message.to.id) {

                    let message = {};
                    action.message.scroll = true;
                    action.message.is_sent_successfull = action.message.conversation_type === 3 ? "SUCCESS" : "PENDING";
                    message[action.message.mid] = action.message;
                    if (!draft[action.message.to.id]) {
                        draft[action.message.to.id] = { messages: {}, unseen_count: 0, conversation_dates: [], is_loaded: true, chat_render_event: "SEND_NEW_MESSAGE_SUCCESS" }
                    }

                    draft[action.message.to.id].messages = { ...draft[action.message.to.id].messages, ...message };
                    draft[action.message.to.id].scroll = true;
                    draft[action.message.to.id].chat_render_event = "SEND_NEW_MESSAGE_SUCCESS";
                    draft[action.message.to.id].last_seen_anchor_point = action.message.mid;
                    draft[action.message.to.id].created_at = action.message.created_at;
                    draft[action.message.to.id].message_content = action.message.message_content;
                    draft[action.message.to.id].message_type = action.message.message_type;
                    draft[action.message.to.id].sender_id = action.message.from.id;

                    if (action.message.media && action.message.media.length > 0) {
                        if (draft[action.message.to.id].media_history) {
                            draft[action.message.to.id].media_history = { ...action.message, ...draft[action.message.to.id].media_history };
                        } else {
                            draft[action.message.to.id].media_history = { [action.message.mid]: action.message };
                        }
                    }
                    return draft;

                } else {
                    return draft;
                }
            }
        case types.RESEND_MESSAGE:
            {
                if (action.message.to.id) {

                    delete draft[action.message.to.id].messages[action.message.mid];

                    let message = {};
                    action.message.scroll = true;
                    action.message.is_sent_successfull = action.message.conversation_type === 3 ? "SUCCESS" : "PENDING";;
                    message[action.message.mid] = action.message;
                    if (!draft[action.message.to.id]) {
                        draft[action.message.to.id] = { messages: {}, unseen_count: 0, conversation_dates: [], is_loaded: true, chat_render_event: "SEND_NEW_MESSAGE_SUCCESS" }
                    }

                    draft[action.message.to.id].messages = { ...draft[action.message.to.id].messages, ...message };
                    draft[action.message.to.id].scroll = true;
                    draft[action.message.to.id].chat_render_event = "RESEND_MESSAGE";
                    draft[action.message.to.id].last_seen_anchor_point = action.message.mid;
                    draft[action.message.to.id].created_at = action.message.created_at;
                    draft[action.message.to.id].message_content = action.message.message_content;
                    draft[action.message.to.id].message_type = action.message.message_type;
                    draft[action.message.to.id].sender_id = action.message.from.id;

                    if (action.message.media && action.message.media.length > 0) {
                        if (draft[action.message.to.id].media_history) {
                            draft[action.message.to.id].media_history = { ...action.message, ...draft[action.message.to.id].media_history };
                        } else {
                            draft[action.message.to.id].media_history = { [action.message.mid]: action.message };
                        }
                    }
                    return draft;

                } else {
                    return draft;
                }
            }
        case types.SEND_CALL_MESSAGE_SUCCESS:
            {
                if (action.data.to.id) {
                    let message = {};
                    message[action.data.mid] = action.data;
                    if (!draft[action.data.to.id]) {
                        draft[action.data.to.id] = { messages: { ...message } }
                    } else {
                        draft[action.data.to.id].messages = { ...draft[action.data.to.id].messages, ...message };
                    }
                    draft[action.message.to.id].chat_render_event = "SEND_CALL_MESSAGE_SUCCESS";

                    return draft;
                } else {
                    return draft;
                }

            }
        case types.SEND_SEEN_STATUS_SUCCESS:
            {
                if (action.data.lastSeenId) {
                    draft[action.data.user].last_seen_anchor_point = action.data.lastSeenId;
                }

                draft[action.data.user].unseen_count = 0;
                draft[action.data.user].unseen_messages = [];
                draft[action.data.user].chat_render_event = "SEND_SEEN_STATUS_SUCCESS";
                return draft;
            }
        case types.UPDATE_MESSAGE:
            {
                if (draft[action.message.to.id] && draft[action.message.to.id].messages[action.message.mid]) {
                    draft[action.message.to.id].messages[action.message.mid] = action.message;
                }
                draft[action.message.to.id].chat_render_event = "UPDATE_MESSAGE";
                return draft;
            }
        case types.UPDATE_UPLOAD_FAIL_MESSAGE:
            {
                if (draft[action.user] && draft[action.user].messages[action.mid]) {
                    draft[action.user].messages[action.mid].isUploading = 'FAILED';
                }
                draft[action.user].chat_render_event = "UPDATE_UPLOAD_FAIL_MESSAGE";
                return draft;
            }
        case types.SET_CALL_STATUS:
            {
                if (action.data.direction === "receive") {
                    //get from
                    if (draft[action.data.from] && draft[action.data.from].messages[action.data.sessionId] && draft[action.data.from].messages[action.data.sessionId].type === 1)
                        draft[action.data.from].messages[action.data.sessionId].status = action.data.status;
                    draft[action.data.from].chat_render_event = "SET_CALL_STATUS";
                } else {
                    //from and to is inverted here
                    if (draft[action.data.from] && draft[action.data.from].messages[action.data.sessionId] && draft[action.data.from].messages[action.data.sessionId].type === 1)
                        draft[action.data.from].messages[action.data.sessionId].status = action.data.status;
                    draft[action.data.from].chat_render_event = "SET_CALL_STATUS";

                }
                return draft;
            }
        case types.STAR_UNSTAR_MESSAGE_SUCCESS:
            {
                if (draft[action.conv_user] && draft[action.conv_user].messages[action.mid]) {
                    draft[action.conv_user].messages[action.mid].is_starred = action.is_starred;
                    draft[action.conv_user].chat_render_event = "STAR_UNSTAR_MESSAGE_SUCCESS";
                }

                return draft;
            }
        case types.SET_REMOTE_PARTY_SEEN:
            {
                if (draft[action.data.user] && draft[action.data.user].messages[action.data.mid]) {
                    draft[action.data.user].messages[action.data.mid].is_seen = true;
                    draft[action.data.user].messages[action.data.mid].is_sent_successfull = "SUCCESS";
                    draft[action.data.user].messages[action.data.mid].seen_at = action.data.seenAt;
                    draft[action.data.user].chat_render_event = "SET_REMOTE_PARTY_SEEN";
                }

                return draft;
            }
        case types.SET_MESSAGE_DELIVERED_STATE:
            {
                if (draft[action.data.user] && draft[action.data.user].messages[action.data.mid]) {
                    draft[action.data.user].messages[action.data.mid].is_delivered = true;
                    draft[action.data.user].messages[action.data.mid].is_sent_successfull = "SUCCESS";
                    draft[action.data.user].chat_render_event = "SET_MESSAGE_DELIVERED_STATE";
                }

                return draft;
            }
        case types.SET_MESSAGE_SEND_STATE:
            {
                if (draft[action.data.user] && draft[action.data.user].messages[action.data.mid]) {
                    draft[action.data.user].messages[action.data.mid].is_sent_successfull = action.data.sentState;
                    draft[action.data.user].chat_render_event = "SET_MESSAGE_SEND_STATE";
                }

                return draft;
            }
        case types.RECEIVE_NEW_MESSAGE:
            {
                if (action.data.message.from.id) {
                    let message = {};
                    message[action.data.message.mid] = action.data.message;
                    if (action.data.message.conversation_type === 3 || action.data.message.conversation_type === 4) {
                        if (!draft[action.data.message.to.id]) {
                            draft[action.data.message.to.id] = { messages: {}, unseen_count: 0, conversation_dates: [], is_loaded: true, chat_render_event: "RECEIVE_NEW_MESSAGE" };
                        }

                        if (action.data.deleteOld) {
                            let msgIds = Object.keys(draft[action.data.message.to.id].messages);
                            let length = msgIds.length;
                            if (length > 400) {

                                let count = 1;

                                msgIds.forEach(id => {
                                    if (count <= length - 200) {
                                        count++;
                                        delete draft[action.data.message.to.id].messages[id];

                                    } else {
                                        return;
                                    }
                                });
                            }
                        }

                        draft[action.data.message.to.id].messages = { ...draft[action.data.message.to.id].messages, ...message };
                        if (!draft[action.data.message.to.id].unread_timestamp) {
                            draft[action.data.message.to.id].unread_timestamp = action.data.message.created_at;
                        }
                        draft[action.data.message.to.id].last_message_received = action.data.message.mid;
                        draft[action.data.message.to.id].created_at = action.data.message.created_at;
                        draft[action.data.message.to.id].message_content = action.data.message.message_content;
                        draft[action.data.message.to.id].message_type = action.data.message.message_type;
                        draft[action.data.message.to.id].sender_id = action.data.message.from.id;
                        draft[action.data.message.to.id].chat_render_event = "RECEIVE_NEW_MESSAGE";
                        if (action.data.increaseCount) {
                            draft[action.data.message.to.id].unseen_count = draft[action.data.message.to.id].unseen_count + 1;
                            if (!draft[action.data.message.to.id].unseen_messages) {
                                draft[action.data.message.to.id].unseen_messages = [];
                            }
                            draft[action.data.message.to.id].unseen_messages.push(action.data.message.mid);
                        }

                        if (action.data.message.media && action.data.message.media.length > 0) {
                            if (draft[action.data.message.to.id].media_history) {
                                draft[action.data.message.to.id].media_history = { ...action.data.message, ...draft[action.data.message.to.id].media_history };
                            } else {
                                draft[action.data.message.to.id].media_history = { [action.data.message.mid]: action.data.message };
                            }
                        }

                    } else {
                        if (!draft[action.data.message.from.id]) {
                            draft[action.data.message.from.id] = { messages: {}, unseen_count: 0, conversation_dates: [], is_loaded: true, chat_render_event: "RECEIVE_NEW_MESSAGE" };
                        }

                        if (action.data.deleteOld) {
                            let msgIds = Object.keys(draft[action.data.message.from.id].messages);
                            let length = msgIds.length;
                            if (length > 400) {

                                let count = 1;

                                msgIds.forEach(id => {
                                    if (count <= length - 200) {
                                        count++;
                                        delete draft[action.data.message.from.id].messages[id];

                                    } else {
                                        return;
                                    }
                                });
                            }
                        }
                        draft[action.data.message.from.id].messages = { ...draft[action.data.message.from.id].messages, ...message };
                        if (!draft[action.data.message.from.id].unread_timestamp) {
                            draft[action.data.message.from.id].unread_timestamp = action.data.message.created_at;
                        }
                        draft[action.data.message.from.id].last_message_received = action.data.message.mid;
                        draft[action.data.message.from.id].created_at = action.data.message.created_at;
                        draft[action.data.message.from.id].message_content = action.data.message.message_content;
                        draft[action.data.message.from.id].message_type = action.data.message.message_type;
                        draft[action.data.message.from.id].sender_id = action.data.message.from.id;
                        draft[action.data.message.from.id].chat_render_event = "RECEIVE_NEW_MESSAGE";
                        if (action.data.increaseCount) {
                            draft[action.data.message.from.id].unseen_count = draft[action.data.message.from.id].unseen_count + 1;

                            if (!draft[action.data.message.from.id].unseen_messages) {
                                draft[action.data.message.from.id].unseen_messages = [];
                            }
                            draft[action.data.message.from.id].unseen_messages.push(action.data.message.mid);
                        }

                        if (action.data.message.media && action.data.message.media.length > 0) {
                            if (draft[action.data.message.from.id].media_history) {
                                draft[action.data.message.from.id].media_history = { ...action.data.message, ...draft[action.data.message.from.id].media_history };
                            } else {
                                draft[action.data.message.from.id].media_history = { [action.data.message.mid]: action.data.message };
                            }
                        }

                    }

                    return draft;

                } else {
                    return draft;
                }
            }
        case types.RESET_UNSEEN_COUNT:
            {
                draft[action.user.username].unseen_count = 0;
                draft[action.user.username].chat_render_event = "RESET_UNSEEN_COUNT";
                return draft;
            }

        case types.GET_ALL_UNREAD_CHAT_COUNT_SUCCESS:
            {
                draft = { ...draft, ...action.chatCounts };
                return draft;
            }
        case types.GET_INITIAL_CHAT_HISTORY_SUCCESS:
            {
                if (!draft[action.old_chats.user]) {
                    draft[action.old_chats.user] = {};
                }

                let currentReceivedMessages = draft[action.old_chats.user].messages ? JSON.parse(JSON.stringify(draft[action.old_chats.user].messages)) : null;
                let currentUnseenMessages = draft[action.old_chats.user].unseen_messages ? JSON.parse(JSON.stringify(draft[action.old_chats.user].unseen_messages)) : null;

                if (action.old_chats && Object.keys(action.old_chats.messages).length > 0) {

                    draft[action.old_chats.user].messages = action.old_chats.messages;

                    /* if (!draft[action.old_chats.user]) {
                        draft[action.old_chats.user] = action.old_chats.messages;
                    } else {
                        draft[action.old_chats.user].messages = { ...action.old_chats.messages, ...draft[action.old_chats.user].messages };
                    } */
                    draft[action.old_chats.user].prev_timestamp = action.old_chats.prev_timestamp;

                    /* if (!draft[action.old_chats.user].unseen_messages) {
                        draft[action.old_chats.user].unseen_messages = [];
                    } */
                    draft[action.old_chats.user].unseen_messages = [...action.old_chats.unseen_messages];
                    if (action.old_chats.is_first) {
                        draft[action.old_chats.user].chat_render_event = "GET_INITIAL_CHAT_HISTORY_SUCCESS";
                        draft[action.old_chats.user].last_seen_anchor_point = action.old_chats.last_seen_mid_history;
                        renderState.focusToLastRead = true;
                    } else {
                        draft[action.old_chats.user].chat_render_event = "GET_INITIAL_CHAT_HISTORY_SUCCESS";
                        renderState.scrollReadyState = true;
                    }

                    if (action.old_chats.last_seen_mid_history) {
                        draft[action.old_chats.user].next_page_anchor_point = action.old_chats.last_seen_mid_history;
                    }

                }

                draft[action.old_chats.user].is_loaded = true;

                if (action.unread_chats) {

                    if (!draft[action.unread_chats.user].messages) {
                        draft[action.unread_chats.user].messages = {};
                    }
                    if (!draft[action.unread_chats.user].unseen_messages) {
                        draft[action.unread_chats.user].unseen_messages = [];
                    }

                    if (action.old_chats && Object.keys(action.old_chats.messages).length > 0) {
                        draft[action.unread_chats.user].messages = { ...draft[action.unread_chats.user].messages, ...action.unread_chats.messages };

                    } else {
                        draft[action.unread_chats.user].messages = { ...action.unread_chats.messages };
                    }

                    if (action.old_chats && action.old_chats.unseen_messages && action.old_chats.unseen_messages.length > 0) {
                        draft[action.unread_chats.user].unseen_messages = [...draft[action.unread_chats.user].unseen_messages, ...action.unread_chats.unseen_messages];

                    } else {
                        draft[action.unread_chats.user].unseen_messages = [...action.unread_chats.unseen_messages];
                    }

                }

                if (currentReceivedMessages) {
                    if (!draft[action.unread_chats.user].messages) {
                        draft[action.unread_chats.user].messages = {};
                    }

                    draft[action.unread_chats.user].messages = { ...draft[action.unread_chats.user].messages, ...currentReceivedMessages };

                }

                if (currentUnseenMessages) {
                    if (!draft[action.unread_chats.user].unseen_messages) {
                        draft[action.unread_chats.user].unseen_messages = [];
                    }
                    draft[action.unread_chats.user].unseen_messages = [...draft[action.unread_chats.user].unseen_messages, ...currentUnseenMessages];
                }

                return draft;

            }
        case types.GET_UNREAD_CHATS_SUCCESS:
            {
                let unseen_buffer = Object.entries(action.data.bufferMessages).map(msg => msg.mid);
                if (action.data.chats) {

                    if (!draft[action.data.chats.user]) {
                        draft[action.data.chats.user] = { ...action.data.chats.messages, ...action.data.bufferMessages };
                    } else {
                        draft[action.data.chats.user].messages = { ...draft[action.data.chats.user].messages, ...action.data.chats.messages, ...action.data.bufferMessages };

                    }
                    if (!draft[action.data.chats.user].unseen_messages) {
                        draft[action.data.chats.user].unseen_messages = [...unseen_buffer];
                    }
                    draft[action.data.chats.user].is_loaded = true;
                    draft[action.data.chats.user].unseen_messages = [...action.data.chats.unseen_messages, ...draft[action.data.chats.user].unseen_messages, ...unseen_buffer];
                    draft[action.data.chats.user].chat_render_event = "GET_UNREAD_CHATS_SUCCESS";
                    return draft;
                } else {
                    return draft;
                }
            }
        case types.GET_OLD_CHATS_SUCCESS:
            {
                if (action.chats && Object.keys(action.chats.messages).length > 0) {

                    if (!draft[action.chats.user]) {
                        draft[action.chats.user] = action.chats.messages;
                    } else {
                        draft[action.chats.user].messages = { ...action.chats.messages, ...draft[action.chats.user].messages };
                    }
                    draft[action.chats.user].is_loaded = true;
                    draft[action.chats.user].prev_timestamp = action.chats.prev_timestamp;

                    if (!draft[action.chats.user].unseen_messages) {
                        draft[action.chats.user].unseen_messages = [];
                    }
                    draft[action.chats.user].unseen_messages = [...action.chats.unseen_messages, ...draft[action.chats.user].unseen_messages];
                    if (action.chats.is_first) {
                        draft[action.chats.user].chat_render_event = "GET_OLD_CHATS_FIRST_SUCCESS";
                        draft[action.chats.user].last_seen_anchor_point = action.chats.last_seen_mid_history;
                        renderState.focusToLastRead = true;
                    } else {
                        draft[action.chats.user].chat_render_event = "GET_OLD_CHATS_NEXT_SUCCESS";
                        renderState.scrollReadyState = true;
                    }

                    if (action.chats.last_seen_mid_history) {
                        draft[action.chats.user].next_page_anchor_point = action.chats.last_seen_mid_history;
                    }

                    return draft;

                } else {
                    draft[action.chats.user].is_loaded = true;
                    return draft;
                }

            }
        case types.GET_ALL_CHATS_SUCCESS:
            {
                if (action.chats) {

                    if (!draft[action.chats.user]) {
                        draft[action.chats.user] = action.chats.messages;
                    } else {
                        draft[action.chats.user].messages = { ...action.chats.messages, ...draft[action.chats.user].messages };
                    }
                    draft[action.chats.user].is_loaded = true;
                    draft[action.chats.user].days_remaining = action.chats.days_remaining;
                    draft[action.chats.user].chat_render_event = "GET_ALL_CHATS_SUCCESS";
                    return draft;

                } else {
                    return draft;
                }

            }
        case types.CHAT_USER_RENAMED:
            {
                if (draft[action.data.previousId]) {
                    let tempUsr = draft[action.data.previousId];
                    delete draft[action.data.previousId];
                    draft[action.data.profileId] = tempUsr;
                    draft[action.data.profileId].chat_render_event = "CHAT_USER_RENAMED";
                    //add new element
                }
                return draft;

            }
        case types.SET_LAST_SEEN_ANCHOR_POINT:
            {
                if (draft[action.data.user_id]) {
                    draft[action.data.user_id].last_seen_anchor_point = action.data.last_seen_anchor_point;
                    draft[action.data.user_id].chat_render_event = "SET_LAST_SEEN_ANCHOR_POINT";
                    //add new element
                }
                return draft;

            }
        case types.APPEND_CHAT_MEDIA:
            {
                if (action.data.user_id) {
                    if (draft[action.data.user_id]) {
                        if (draft[action.data.user_id].media_history && Object.keys(draft[action.data.user_id].media_history).length > 0) {
                            draft[action.data.user_id].media_history = { ...draft[action.data.user_id].media_history, ...action.data.media };
                        } else {
                            draft[action.data.user_id].media_history = action.data.media;
                        }
                        return draft;
                    }
                }
                return draft;
            }
        default:
            return draft;
    }

});
