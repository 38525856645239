import React, { useState, useEffect ,useCallback } from "react";
import { ButtonRounded, DeviceNotSupported } from "../../common";
import { Spin, Input } from "antd";
import { message } from "antd/lib/index";
import { Camera, CameraOff } from "react-feather";
import queryString from "query-string";
import WorkspaceService from "../../../service/workspaceService";
import AuthService from "../../../service/authService.js";
import UserAuthService from "../../../service/userAuthService";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import Webcam from "react-webcam";
import validator from "validator";
import ImageUploader from "react-images-upload";
import axios from "axios";
import * as fileService from "../../../service/fileService";
import { fileMetaServiceUrl } from "../../../config/baseUrls";
import uuid from "uuid/v1";
import { useLocation } from "react-router-dom";

import "./styles/user-landing.scss";
import "./styles/signup-responsive.scss";

import { useForm } from "react-hook-form";
import { EyeOff, Eye } from "react-feather";
import navigationRestrictions from "../../../config/navigationRestrictions";
import * as auth from "../../../utils";
import * as logger from "../../../lib/logSdk/logger";

const workspaceService = new WorkspaceService();
const authService = new AuthService();
const userAuthService = new UserAuthService();

const videoConstraints = {
  width: 200,
  height: 200,
  facingMode: "user",
};

const SingUpComponent = (props) => {
  const { register, errors, handleSubmit, watch } = useForm({});
  const [signUpFormWizard, SetSignUpFormWizard] = useState(1);
  const [workspaceName, setWorkspaceName] = useState("");
  const [memberId, setMemberId] = useState("");
  const [workspaceId, setWorkspaceId] = useState("");
  const [username, setUsername] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [isLoading, setIsLoading] = useState(false);
  const [isOpenCam, setIsOpenCam] = useState(false);
  const [_errors, setErrors] = useState({});
  const [formIsValid, setFormIsValid] = useState(true);
  const [formHeight, setFormHeight] = useState("550px");
  const [percentage, setPercentage] = useState(0);
  const [cancelTokenSource, setCancelTokenSource] = useState(true);
  const [imageSrc, setImageSrc] = useState();
  const [avatarUrl, setAvatarUrl] = useState("");
  const [uploadPhoto, setUploadPhoto] = useState(false);
  const webcamRef = React.useRef(null);
  const [isLoadingConfig, setIsLoadingConfig] = useState(true);
  const [inputType, setInputType] = useState("password");

  const capture = React.useCallback(() => {
    const imageSrcc = webcamRef.current.getScreenshot();
    console.log(imageSrcc);
    setImageSrc(imageSrcc);
    setIsOpenCam(false);
    setFormHeight("550px");
  }, [webcamRef]);
  let location = useLocation();

  const [passwordPolicy, setPasswordPolicy] = useState(
    auth.getPasswordPolicy()
  );
  const getPasswordPolicy = async () => {
    try {
      if (navigationRestrictions.login.password_policy.active !== "enable") {
        return;
      }
      setIsLoadingConfig(true);
      const token = await executeRecaptcha("SingUpComponent");

      const policy = await workspaceService.getPasswordPolicy("sddsfs", token);
      if (policy) {
        setPasswordPolicy(policy);
        auth.setPasswordPolicy(policy);
      }
    } catch (error) {
      console.error("SingUpComponent", error);
      logger.error("SingUpComponent","getPasswordPolicy", error.message);
    } finally {
      setIsLoadingConfig(false);
      
    }
  };

  

  const validate = useCallback(async () => {
    try {      

      if(executeRecaptcha === undefined)
        return;

      getPasswordPolicy();

      if (location && location.state && location.state.detail) {
        const workspName = location.state.detail.workspaceName;
        const mId = location.state.detail.memberId;
        const wId = location.state.detail.workspaceId;
        const uname = location.state.detail.username;
        setWorkspaceName(workspName);
        setMemberId(mId);
        setWorkspaceId(wId);
        setUsername(uname);

        logger.debug("AgentConsole","SingUpComponent",`------ AgentConsole ------ User : ${uname}`);
        if (workspName && mId && wId && uname) {
          console.log("Try To Create Profile with Invitation");
        } else {
          console.log("Try To Create Profile Without Invitation");
          message.error("Try To Create Profile Without Invitation.");
          props.history.push("/");
        }
      } else {
        console.log("Try To Create Profile Without Invitation");
        message.error("Try To Create Profile Without Invitation.");
        props.history.push("/");
      }
      
    } catch (ex) {
      console.error("SingUpComponent", "useEffect", ex);
      message.error("Fail To Validate Request.");
      logger.error("SingUpComponent","validate", ex.message);
      props.history.push("/");
    }
  });

  useEffect(() => { 
    validate();
  }, [validate]);

  const uploadFile = async () => {
    try {
      const token = await executeRecaptcha("signupPage");
      let fileId = uuid();
      setCancelTokenSource(axios.CancelToken.source());

      const response = await fetch(imageSrc);
      const blob = await response.blob();

      fileService.uploadFile(
        blob,
        fileId,
        fileId,
        cancelTokenSource,
        (fileId, percentage) => {
          setPercentage(percentage);
        },
        (result) => {
          let profileUrl = null;
          try {
            if (result && result.data && result.data.isSuccess) {
              profileUrl = result.data.result?result.data.result.streamURL:null;
              setAvatarUrl(profileUrl);              
              console.log(
                "signup",
                "uploadFile",
                `profile picture uploaded : ${profileUrl}`
              );
            }else {
              console.error(`Fail To Upload File ${fileId}`);
              message.error(`Unable To Save Profile Picture`, 5);
              logger.error("SingUpComponent","fileService.uploadFile",`Fail To Upload File ${fileId}`);
            } 
          } catch (error) {
            message.error(`Unable To Save Profile Picture ${fileId}`, 5);
            console.error('uploadFile',"callback",`RecordUpload - Fail To Upload File ${fileId}`, error);
            logger.error("SingUpComponent","uploadFile-callback",error.message);
          }finally{
            saveProfileExecute(profileUrl);
          }
/* 

          if (result && result.data && result.data.isSuccess) {
            setAvatarUrl(result.data.result.streamURL);
            console.log(
              "signup",
              "uploadFile",
              `profile picture uploaded : ${result.data.result.streamURL}`
            );
            saveProfileExecute(result.data.result.streamURL);
          } else {
            console.error(`Fail To Upload File ${fileId}`);
            message.error(`Unable To Save Profile Picture`, 5);
            logger.error("SingUpComponent","fileService.uploadFile",`Fail To Upload File ${fileId}`);
            saveProfileExecute(null);
          }    */
        },
        token
      );
    } catch (ex) {
      saveProfileExecute(null);
      message.error(`Unable To Save Profile Picture`, 5);
      console.error('uploadFile',"RecordUpload - Fail To Upload File", ex);
      logger.error("SingUpComponent","uploadFile",ex.message);
    }
  };

  const updateInvitation = async (
    workspaceId,
    memberId,
    userId,
    autoSign,
    token
  ) => {
    try {
      token = await executeRecaptcha("signupPage");
      const responseUpdateInvite = await workspaceService.updateInvitation(
        workspaceId,
        memberId,
        userId,
        token
      );

      if (!responseUpdateInvite.isSuccess) {
        message.error("Fail to Update User Invitation");
      }

      setTimeout(() => {
        setIsLoading(false);
        if (autoSign) {
          props.history.push({
            pathname: "/auto_login",
            state: { detail: { username, password ,isVideo:true} },
          });
        } else {
          props.history.push("/sign-in");
        }
        // props.history.push("/sign-in");
      }, 1000);
    } catch (error) {
      message.error("Fail to Update User Invitation");
      console.error(error);
      logger.error("SingUpComponent","updateInvitation",error.message);
      props.history.push("/sign-in");
    }
  };

  const saveProfileExecute = async (url) => {
    try {
      let token = await executeRecaptcha("signupPage");

      const postData = {
        emailId: username,
        mobileNumber: mobileNumber,
        firstName: firstName,
        lastName: lastName,
        userType: "1",
        password: password,
        organization: workspaceName,
      };

      if (url) {
        postData.avatarURL = url;
        console.log(
          "signup",
          "saveProfileExecute",
          `profile create with picture : ${url}`
        );
      }
      const response = await userAuthService.saveProfile(
        postData,
        token,
        workspaceName
      );
      if (response.isSuccess) {
        const postData = {
          username: username,
          password: password,
        };
        token = await executeRecaptcha("signupPage");
        if (await authService.checkAccountAvailability(username, token)) {
          token = await executeRecaptcha("signupPage");
          if (await authService.saveIdentity(postData, token)) {
            updateInvitation(
              workspaceId,
              memberId,
              response.data.userId,
              true,
              token
            );
          } else {
            setIsLoading(false);
            message.error("Fail to Save User Identity");
          }
        } else {
          token = await executeRecaptcha("signupPage");
          setIsLoading(false);
          updateInvitation(
            workspaceId,
            memberId,
            response.data.userId,
            true,
            token
          );
        }
      } else {
        setIsLoading(false);
        message.error("Fail to Save User Profile");
      }
    } catch (error) {
      console.error("saveProfileExecute", error);
      logger.error("SingUpComponent","saveProfileExecute",error.message);
    }
  };
  const saveProfile = async () => {
    try {
      if (username === "" || workspaceName === "") {
        message.error("Invalid Request. Unable To Find Request Meta Data");
        return;
      }

      if (!executeRecaptcha) {
        return;
      }
      /* if (!validatePassword()) return; */
      setIsLoading(true);
      if (uploadPhoto && imageSrc) {
        uploadFile();
      } else {
        saveProfileExecute();
      }
    } catch (ex) {
      setIsLoading(false);
      console.error(ex);
      message.error("Fail to Save User Profile");
      logger.error("SingUpComponent","saveProfile",ex.message);
    }
  };

  const validateProfile = () => {
    let err = {};
    setFormIsValid(true);
    let validatefrm = true;
    if (firstName) {
      if (firstName.length < 3) {
        err["firstNameLength"] = "First name must be at least 3 characters";
        setErrors({ ...err });
        validatefrm = false;
      }
      if (!validator.isAlpha(firstName)) {
        err["firstName"] = "First name must contain alpha characters only";
        setErrors({ ...err });
        validatefrm = false;
      }
    } else {
      err["firstNameRequired"] = "First name is required";
      setErrors({ ...err });
      validatefrm = false;
    }

    if (lastName) {
      if (lastName.length < 3) {
        err["lastNameLength"] = "Last name must be at least 3 characters";
        setErrors({ ...err });
        validatefrm = false;
      }
      if (!validator.isAlpha(lastName)) {
        err["lastName"] = "Last name must contain alpha characters only";
        setErrors({ ...err });
        validatefrm = false;
      }
    } else {
      err["lastNameRequired"] = "Last name is required";
      setErrors({ ...err });
      validatefrm = false;
    }

    if (mobileNumber) {
      if (mobileNumber.length < 7) {
        err["mobileNumberLength"] = "Mobile number must be at Least 7 digits";
        setErrors({ ...err });
        validatefrm = false;
      }
      if (!validator.isNumeric(mobileNumber)) {
        err["mobileNumber"] = "Mobile number must contain digits only";
        setErrors({ ...err });
        validatefrm = false;
      }
    } else {
      err["mobileNumberRequired"] = "Mobile number is required";
      setErrors({ ...err });
      validatefrm = false;
    }
    if (!validatefrm) setFormHeight("650px");
    setFormIsValid(validatefrm);
    return validatefrm;
  };

  const validatePassword = () => {
    let err = {};
    setFormIsValid(true);
    let validatefrm = true;
    if (password) {
      if (password.length < 6) {
        err["passwordLength"] = "Password must be at least 6 characters";
        setErrors({ ...err });
        validatefrm = false;
      }
    } else {
      err["password"] = "Password is required";
      setErrors({ ...err });
      validatefrm = false;
    }

    if (confirmPassword) {
      if (password !== confirmPassword) {
        err["confirmPassword"] = " Both password should be same";
        setErrors({ ...err });
        validatefrm = false;
      }
    } else {
      err["confirmPasswordRequired"] = "Confirm Password is required";
      setErrors({ ...err });
      validatefrm = false;
    }
    setFormIsValid(validatefrm);
    return validatefrm;
  };

  const onPressNext = () => {
    if (signUpFormWizard === 1) {
      SetSignUpFormWizard(2);
    }
    if (signUpFormWizard === 2) {
      if (validateProfile()) SetSignUpFormWizard(3);
    }
  };

  const onPressSkip = () => {
    if (signUpFormWizard === 1) {
      setUploadPhoto(false);
      SetSignUpFormWizard(2);
    }
  };
  const onPressBack = () => {
    if (signUpFormWizard === 2) {
      setImageSrc("");
      setAvatarUrl("");
      SetSignUpFormWizard(1);
    }
    if (signUpFormWizard === 3) {
      SetSignUpFormWizard(2);
    }
  };

  const openCam = () => {
    // setFormHeight("650px");
    setIsOpenCam(!isOpenCam);
    setUploadPhoto(true);
  };

  const onDrop = (pictureFiles, pictureDataURLs) => {
    setImageSrc(pictureDataURLs[0]);
    setUploadPhoto(pictureDataURLs.length > 0);
    console.log(pictureDataURLs);
  };
  return (
    <>
      <DeviceNotSupported />
      <section className={`main-login-wrapper sign-up`}>
        <div className={"top-left-leaf-bg"}>
          <img
            src={require("../../../assets/img/bg/login/topRightLeaf.svg")}
            alt="User Avatar"
          />
        </div>

        {/*====== login from main wrapper start here ========*/}
        <div className={"container"}>
          <div className={"login-layout-wrp  d-flex justify-content-between"}>
            {/*======== login form start here ========*/}
            <div
              className="sign-up-form-main-wrapper sign-up-wrapper "
              style={{
                height: formHeight,
              }}
            >
              <div className="sign-up-form-inner-wrapper">
                <div>
                  <div className={`sign-up-form`}>
                    {/*<div className={'sign-up-character'}>*/}
                    {/*    <img src={require("../../../assets/img/bg/login/MainCharacter.svg")}/>*/}
                    {/*</div>*/}
                    <div className={`login-header-wrapper mt-5 text-center`}>
                      {signUpFormWizard === 1 && (
                        <>
                          <h2 className={"text-center"}>
                            Welcome to @{workspaceName}
                          </h2>
                          <small>Add your profile photo</small>

                          <div className={"profile-img-upload-wrapper"}>
                            <div className={"profile-image-upload"}>
                              {imageSrc && (
                                <img src={imageSrc} alt="User Avatar" />
                              )}

                              {!imageSrc && (
                                <img
                                  src={require("../../../assets/img/defaultUser.svg")}
                                  alt="User Avatar"
                                />
                              )}
                            </div>

                            <div
                              onClick={openCam}
                              className={
                                "img-upload-wrp d-flex justify-content-center align-items-center"
                              }
                            >
                              <Camera />
                            </div>
                          </div>

                          {!isOpenCam && (
                            <ImageUploader
                              withIcon={false}
                              buttonText="Choose images"
                              withLabel={false}
                              singleImage={true}
                              onChange={onDrop}
                              imgExtension={[
                                ".jpeg",
                                ".jpg",
                                ".png",
                                ".svg",
                                ".gif",
                              ]}
                              maxFileSize={5242880}
                            />
                          )}
                        </>
                      )}
                      {signUpFormWizard === 2 && (
                        <>
                          <h2>Profile Details</h2>
                          <small>This is step 1</small>
                        </>
                      )}
                      {signUpFormWizard === 3 && (
                        <>
                          <h2>Create your password</h2>
                          <small>Final step </small>
                        </>
                      )}
                    </div>

                    <div className={"sign-in-body-wrapper"}>
                      {/*==== sign up form wizard upload profile avatar =====*/}
                      {signUpFormWizard === 1 && (
                        <>
                          {isOpenCam ? (
                            <>
                              <Webcam
                                audio={false}
                                height={200}
                                ref={webcamRef}
                                screenshotFormat="image/jpeg"
                                width={200}
                                videoConstraints={videoConstraints}
                              />
                              <div class="row">
                                <div class="col-md-6 offset-md-3">
                                  <div class="row">
                                    <div
                                      class="col-md-8"
                                      onClick={capture}
                                      title="Take Picture"
                                    >
                                      <Camera color="red" />
                                    </div>
                                    <div
                                      class="col-md-4"
                                      onClick={() => {
                                        setIsOpenCam(false);
                                        setImageSrc("");
                                        setUploadPhoto(false);
                                      }}
                                      title="Cancel"
                                    >
                                      <CameraOff color="DarkGrey" />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          ) : (
                            <form className={"fadeIn"}>
                              <div className="form-group"></div>
                              <div className={`login-button-wrapper`}>
                                <div className={`login-bnt-hide`}>
                                  <ButtonRounded
                                    title="NEXT"
                                    onClick={onPressNext}
                                    size="btn-lg btn-block"
                                    type="btn-primary"
                                  />
                                </div>
                              </div>

                              {/*  <div className={"skip"} onClick={onPressSkip}>
                                  Skip
                                </div> */}
                            </form>
                          )}
                        </>
                      )}

                      {/*==== sign up form wizard first name and last name =====*/}
                      {signUpFormWizard === 2 && (
                        <form className={"fadeIn"}>
                          <div className={"d-flex justify-content-between"}>
                            <div
                              className={`form-group first-name ${
                                !formIsValid ? "error" : ""
                              }`}
                            >
                              <label htmlFor="exampleInputEmail1">
                                First Name <span>*</span>
                              </label>
                              <input
                                type="text"
                                id="userName"
                                name="userName"
                                className="form-control form-control-lg txt-login w100"
                                aria-describedby="emailHelp"
                                placeholder="John"
                                value={firstName}
                                maxLength={25}
                                onChange={(e) => setFirstName(e.target.value)}
                              />
                              {!formIsValid && (
                                <div className={"error-text last-name"}>
                                  <small style={{ color: "red" }}>
                                    {_errors["firstNameRequired"]}
                                  </small>
                                  <small style={{ color: "red" }}>
                                    {_errors["firstNameLength"]}
                                  </small>
                                  <small style={{ color: "red" }}>
                                    {_errors["firstName"]}
                                  </small>{" "}
                                </div>
                              )}
                            </div>
                            <div
                              className={`form-group ${
                                !formIsValid ? "error" : ""
                              }`}
                            >
                              <label htmlFor="exampleInputEmail1">
                                Last Name <span>*</span>
                              </label>
                              <input
                                type="text"
                                id="userPassword"
                                name="userPassword"
                                className="form-control form-control-lg txt-login  w200"
                                aria-describedby="emailHelp"
                                placeholder="Doe"
                                value={lastName}
                                maxLength={15}
                                onChange={(e) => setLastName(e.target.value)}
                              />
                              {!formIsValid && (
                                <div className={"error-text last-name"}>
                                  {" "}
                                  <small style={{ color: "red" }}>
                                    {_errors["lastNameRequired"]}
                                  </small>
                                  <small style={{ color: "red" }}>
                                    {_errors["lastNameLength"]}
                                  </small>
                                  <small style={{ color: "red" }}>
                                    {_errors["lastName"]}
                                  </small>
                                </div>
                              )}
                            </div>
                          </div>

                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1">
                              Mobile Number <span>*</span>
                            </label>
                            <input
                              type="text"
                              id="mobileNumber"
                              name="mobileNumber"
                              className="form-control form-control-lg txt-login"
                              aria-describedby="emailHelp"
                              placeholder="+94123456789"
                              value={mobileNumber}
                              maxLength={15}
                              onChange={(e) => setMobileNumber(e.target.value)}
                            />
                            {!formIsValid && (
                              <div className={"error-text last-name"}>
                                {" "}
                                <small style={{ color: "red" }}>
                                  {_errors["mobileNumberRequired"]}
                                </small>
                                <small style={{ color: "red" }}>
                                  {_errors["mobileNumberLength"]}
                                </small>
                                <small style={{ color: "red" }}>
                                  {_errors["mobileNumber"]}
                                </small>{" "}
                              </div>
                            )}
                          </div>
                          <div className={`login-button-wrapper`}>
                            <div className={`login-bnt-hide`}>
                              <ButtonRounded
                                title="NEXT"
                                onClick={onPressNext}
                                size="btn-lg btn-block"
                                type="btn-primary"
                              />
                            </div>
                            <div
                              className={"skip back-to-login-wrapper"}
                              onClick={onPressBack}
                            >
                              Back
                            </div>
                          </div>
                        </form>
                      )}

                      {/*===== sign up form wizard password =====*/}
                      {signUpFormWizard === 3 && (
                        <form onSubmit={handleSubmit(saveProfile)}>
                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1">
                              Password <span>*</span>
                            </label>

                            <input
                              id="password"
                              className="form-control form-control-lg txt-login"
                              aria-describedby="emailHelp"
                              placeholder="**************"
                              name="password"
                              type={inputType}
                              onChange={(e) => setPassword(e.target.value)}
                              ref={register({
                                required: "You must specify a password",
                                pattern: {
                                  value: passwordPolicy["passwordPolicy"]
                                    ? new RegExp(
                                        passwordPolicy["passwordPolicy"],
                                        "i"
                                      )
                                    : null,
                                  message: `Password must be a valid pattern. ${passwordPolicy["policyNames"]}`,
                                },
                              })}
                            />
                            {inputType === "password" ? (
                              <EyeOff
                                className="user_register_password_input_img"
                                onClick={() => setInputType("text")}
                              />
                            ) : (
                              <Eye
                                className="user_register_password_input_img"
                                onClick={() => setInputType("password")}
                              />
                            )}
                            {errors.password && (
                              <p style={{fontSize: "9px" , color:"red"}}>{errors.password.message}</p>
                            )}
                          </div>

                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1">
                              Confirm Password <span>*</span>
                            </label>

                            <input
                              id="confirmPassword"
                              className="form-control form-control-lg txt-login"
                              aria-describedby="emailHelp"
                              placeholder="**************"
                              name="confirmPassword"
                              type="password"
                              ref={register({
                                validate: (value) =>
                                  value === watch("password") ||"The passwords do not match",
                              })}
                            />
                            {errors.confirmPassword && (
                              <p style={{fontSize: "9px" , color:"red"}}>{errors.confirmPassword.message}</p>
                            )}
                          </div>

                          <div className={`login-button-wrapper`}>
                            <div className={`login-bnt-hide`}>
                              <div className={"mr-2"}>
                                {isLoading ? (
                                  <div className={"pre-loader-login"}>
                                    <div className="loader9" />
                                  </div>
                                ) : (
                                  <input
                                    type="submit"
                                    value="SIGN ME UP"
                                    className="btn btn-rounded btn-primary btn-lg btn-block"
                                    disabled={isLoadingConfig}
                                  />
                                  /*  <ButtonRounded
                                    title="SIGN ME UP"
                                    onClick={saveProfile}
                                    size="btn-lg btn-block"
                                    type="btn-primary "
                                  /> */
                                )}
                              </div>
                            </div>
                            {!isLoading && (
                              <div
                                className={"skip back-to-login-wrapper"}
                                onClick={onPressBack}
                              >
                                Back
                              </div>
                            )}
                          </div>
                        </form>
                      )}

                      <div className={"login-footer-wrp"}>
                        {/*<div className={'version'}>*/}
                        {/*    version <span>1.0.4</span>*/}
                        {/*</div>*/}
                        <div className={"rights version-text"}>
                          © 2020 tetherfi, All rights reserved.
                        </div>
                      </div>
                    </div>
                    <div className={"login-form-bg-sm"} />
                  </div>

                  <div className={"login-form-bg-lg"} />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={"login-footer-bg-wrapper d-flex"}>
          <div className={"left-plant"}>
            <img
              src={require("../../../assets/img/bg/login/footerPlantLeft.svg")}
              alt="User Avatar"
            />
          </div>
          <div className={"transparent-plant"}>
            <img
              src={require("../../../assets/img/bg/login/transparentPlant.svg")}
              alt="User Avatar"
            />
          </div>
          <div className={"left-single-plant"}>
            <img
              src={require("../../../assets/img/bg/login/singlePlant.svg")}
              alt="User Avatar"
            />
          </div>

          <div className={"right-plant"}>
            <img
              src={require("../../../assets/img/bg/login/rightPlant.svg")}
              alt="User Avatar"
            />
          </div>
        </div>

        <div className={"sky-bg"}>
          <img
            src={require("../../../assets/img/bg/login/skyBg.svg")}
            alt="User Avatar"
          />
        </div>

        <div className={"left-round-wrp"} />
        <div className={"top-bg-round-wrp"} />

        <div className={"stars-wrapper"}>
          <img
            src={require("../../../assets/img/bg/login/stars.svg")}
            alt="User Avatar"
          />
        </div>
        <div className={"stars-wrapper-2"}>
          <img
            src={require("../../../assets/img/bg/login/starsOnly.svg")}
            alt="User Avatar"
          />
        </div>
      </section>
    </>
  );
};

export default SingUpComponent;
