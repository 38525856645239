import React from 'react';
import { connect } from "react-redux";
import MeetingDashboard from "../Meetings/MeetingDashboard";
import Meetings from "../Meetings/Meetings";

class MeetingsWrapper extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            uiState: 'DASHBOARD',
            data: null
        }
    }

    setUIState(uiState, data = null) {
        this.setState({ ['uiState']: uiState, ['data']: data });
    }


    componentDidMount() {
    }

    render() {

        return (
            <>
                {this.state.uiState === 'DASHBOARD' && this.props.activeMenu === 'meeting' && !this.props.selected_profile.username && <MeetingDashboard
                    activeMenu={this.props.activeMenu}
                    onClickVideoCall={this.props.onClickVideoCall}
                    setUIState={this.setUIState.bind(this)}
                    viewPointHeight={this.props.viewPointHeight}
                    viewPointWidth={this.props.viewPointWidth} />
                }

                {(this.state.uiState === 'MEETING_INFO_SAVE' || this.state.uiState === 'MEETING_INFO_UPDATE') && this.props.activeMenu === 'meeting' && !this.props.selected_profile.username && <Meetings
                    activeMenu={this.props.activeMenu}
                    isUpdate={this.state.uiState === 'MEETING_INFO_UPDATE'}
                    setUIState={this.setUIState.bind(this)}
                    meetingObj={this.state.data}
                    viewPointHeight={this.props.viewPointHeight}
                    viewPointWidth={this.props.viewPointWidth}
                />
                }




            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        selected_profile: state.selected_profile
    };
}

export default connect(mapStateToProps)(MeetingsWrapper);
