import React, { useEffect, useState, useCallback, useRef } from 'react';
import {
    PanelTitle,
    MenuIcon,
    UploadImageThumbnail,
    SendButton,
    SearchTextBox,
    UploadVideoThumbnail,
    UploadFileThumbnail,
    DocumentPreview,
    AudioPreview,
    SearchTextBoxWrapper
} from '../../common';
import { Check, Plus, Send, X } from 'react-feather';
import { Route, Switch } from "react-router-dom";
import axios from "axios";
import { Scrollbars } from 'react-custom-scrollbars';
import uuid from "uuid/v1";
import * as fileService from "../../../service/fileService";
import { connect } from "react-redux";
import { fileMetaServiceUrl } from "../../../config/baseUrls";
import * as ChatService from "../../../service/chatService";
import * as auth from "../../../utils";
import { bindActionCreators } from "redux";
import * as chatActions from "../../../redux/actions/chatActions";
import * as uiPanelActions from "../../../redux/actions/uiPanelActions";
import * as eventEmitter from "../../../utils/eventEmitter";
import { imageTypes, videoTypes, audioTypes, documentTypes, videoPreviewSupported } from "../../../config/json/fileTypeMap";
import { notification } from 'antd';
import { message } from "antd/lib/index";
import navigationRestrictions from "../../../config/navigationRestrictions";
import { filterProfanity } from "../../../utils/profanityFilter";

import './styles/media-upload.scss';
import './styles/media-upload-responsive.scss';

function getAllowedMedia() {
    let allowedMedia = [];

    if (navigationRestrictions.media.allowed_media.video) {
        allowedMedia = [...allowedMedia, ...videoTypes];
    }
    if (navigationRestrictions.media.allowed_media.image) {
        allowedMedia = [...allowedMedia, ...imageTypes];
    }
    if (navigationRestrictions.media.allowed_media.audio) {
        allowedMedia = [...allowedMedia, ...audioTypes];
    }

    return allowedMedia;
}

const mediaTypes = getAllowedMedia();

let fileCategoryMapping = (() => {
    let typeMap = {};
    imageTypes.reduce((acc, type) => {
        acc[type] = "image";
        return acc;
    }, typeMap);

    videoTypes.reduce((acc, type) => {
        acc[type] = "video";
        return acc;
    }, typeMap);

    audioTypes.reduce((acc, type) => {
        acc[type] = "audio";
        return acc;
    }, typeMap);

    documentTypes.reduce((acc, type) => {
        acc[type] = "document";
        return acc;
    }, typeMap);

    return typeMap;

})();

function MediaUpload(props) {
    const childRef = useRef();

    const [uploadStatus, setUploadStatus] = useState("INIT");
    const [media, setMedia] = useState({ selected_media: null, files: {} });
    const [selectedImage, setSelectedImage] = useState({});

    let keyPressHandler = event => {
        if (event.key === "Enter") {
            sendMedia(childRef.current.getValue());
        }
    };

    useEffect(() => {
        let mediaState = { selected_media: null, files: props.ui_panel_upload_data.data.files };
        let keys = Object.keys(props.ui_panel_upload_data.data.files);
        if (keys && keys.length > 0) {
            mediaState.selected_media = keys[0];
        }
        setMedia(mediaState);

        let captionElement = document.getElementById("media_upload_caption");
        if (captionElement) {
            captionElement.focus();
        }
    }, [props.ui_panel_upload_data])

    useEffect(() => {
        console.log("MEDIA_UPLOAD", "useEffect");

    }, [props.firstFile, props.selected_profile]);

    const onFocusOutHandler = (val) => {

        setMedia(prevValue => {
            let { selected_media, files } = prevValue;
            let fileObj = { ...files[selected_media], caption: val };
            return { ...prevValue, files: { ...files, [selected_media]: { ...fileObj } } };
        });

    }

    function setMediaDetails(fileId, percentage) {
        console.log("PERCENTAGE", fileId, percentage, media);
        /* setMedia({ ...media, [fileId]: { ...media[fileId], upload_started: true, percentage: percentage } }); */
    }

    function uploadFiles(media) {
        return new Promise((resolve, reject) => {

            let funcArr = [];
            let mid = "";

            if (props.ui_panel_upload_data.data.type === 'MEDIA') {
                //send 1 message
                let mediaItem = media[Object.keys(media)[0]];
                mid = mediaItem.file_id;
                let msg = {
                    v: 1,
                    mid: mediaItem.file_id,
                    sid: mediaItem.file_id,
                    channel: "WEBCHAT",
                    message_type: mediaItem.type,
                    from: {
                        id: auth.getUserId(),
                        name: auth.getUser()
                    },
                    to: {
                        id: props.selected_profile.username,
                        name: props.selected_profile.displayName
                    },
                    media: [],
                    created_at: Date.now(),
                    message_content: mediaItem.caption,
                    other: null,
                    isUploading: "UPLOADING"
                };

                msg.media.push({
                    content_url: "",
                    content_size: 0,
                    content_title: "",
                    thumbnail_url: "",
                    mimeType: msg.message_type,
                    created_at: msg.created_at
                });


                msg.conversation_type = props.selected_profile.conversation_type;
                props.chat_actions.sendNewMessageSuccess(msg);

                props.ui_panel_actions.closeMediaUpload();
                eventEmitter.fireEvent("scrollbar", { scrollbar_status: "down" });
            }

            Object.keys(media).forEach(key => {

                let mediaItem = media[key];

                if (props.ui_panel_upload_data.data.type === 'DOCUMENT') {
                    let msg = {
                        v: 1,
                        mid: mediaItem.file_id,
                        sid: mediaItem.file_id,
                        channel: "WEBCHAT",
                        message_type: mediaItem.type,
                        from: {
                            id: auth.getUserId(),
                            name: auth.getUser()
                        },
                        to: {
                            id: props.selected_profile.username,
                            name: props.selected_profile.displayName
                        },
                        media: [],
                        created_at: Date.now(),
                        message_content: mediaItem.caption,
                        other: null,
                        isUploading: 'UPLOADING'
                    };

                    msg.media.push({
                        content_url: "",
                        content_size: 0,
                        content_title: "",
                        thumbnail_url: "",
                        mimeType: msg.message_type,
                        created_at: msg.created_at
                    });


                    msg.conversation_type = props.selected_profile.conversation_type;
                    props.chat_actions.sendNewMessageSuccess(msg);

                    props.ui_panel_actions.closeMediaUpload();
                    eventEmitter.fireEvent("scrollbar", { scrollbar_status: "down" });
                }

                let file = mediaItem.file;
                let fileId = mediaItem.file_id;
                let cancelTokenSource = axios.CancelToken.source();
                let conversation_id = [props.selected_profile.conversation_type === 3 ? "" : auth.getUserId(), props.selected_profile.username].sort().join('_')
                funcArr.push(fileService.uploadFilePromise(file, fileId, conversation_id, cancelTokenSource, null, mediaItem.type));

            });

            Promise.all(funcArr)
                .then(result => {

                    resolve({ result, mid });

                }).catch(ex => {

                    notification['error']({
                        message: 'Media Upload',
                        placement: 'bottomLeft',
                        description: 'There was an error uploading the files',
                        onClick: () => {
                            console.log('Notification Clicked!');
                        },
                    });

                    reject(ex);

                })

        });

    }

    function getAllMedia() {
        let images = [];
        Object.keys(media.files).map((key, index) => {
            let item = media.files[key];
            let url = URL.createObjectURL(item.file);
            if (item.type.toLowerCase().startsWith('image')) {
                images.push(
                    <UploadImageThumbnail
                        key={key}
                        active={(media.selected_media === key)}
                        src={url}
                        onClickRemoveImage={() => onClickRemoveMedia(key)}
                        onClickImage={() => {

                            setMedia(prevValue => {
                                return { ...prevValue, selected_media: key };
                            });

                            childRef.current.setValue(media.files[key].caption);


                        }}
                    />);
            } else if (item.type.toLowerCase().startsWith('video')) {
                images.push(
                    <UploadVideoThumbnail
                        key={key}
                        active={(media.selected_media === key)}
                        src={url}
                        type={item.type}
                        onClickRemoveImage={() => onClickRemoveMedia(key)}
                        onClickVideo={() => {
                            setMedia(prevValue => {
                                return { ...prevValue, selected_media: key };
                            });

                            childRef.current.setValue(media.files[key].caption);
                        }}
                    />);
            } else if (item.type.toLowerCase().startsWith('audio')) {
                images.push(
                    <UploadFileThumbnail
                        key={key}
                        active={(media.selected_media === key)}
                        fileSize={item.size}
                        fileType={'audio'}
                        type={item.type}
                        onClickRemoveImage={() => onClickRemoveMedia(key)}
                        onclick={() => {
                            setMedia(prevValue => {
                                return { ...prevValue, selected_media: key };
                            });

                            childRef.current.setValue(media.files[key].caption);
                        }}
                    />);
            } else if (item.type.toLowerCase() === 'application/pdf' || (item.name && (item.name.endsWith(".txt") || item.name.endsWith(".doc") || item.name.endsWith(".docx") || item.name.endsWith(".xls") || item.name.endsWith(".xlsx") || item.name.endsWith(".csv")))) {
                images.push(
                    <UploadFileThumbnail
                        key={key}
                        active={(media.selected_media === key)}
                        fileSize={item.size}
                        type={item.type}
                        fileType={'file'}
                        onClickRemoveImage={() => onClickRemoveMedia(key)}
                        onclick={() => {
                            setMedia(prevValue => {
                                return { ...prevValue, selected_media: key };
                            });

                            childRef.current.setValue(media.files[key].caption);
                        }}
                    />);
            }
        });
        return images;
    }

    function getSelectedMedia() {

        if (media.selected_media) {

            if (imageTypes.indexOf(media.files[media.selected_media].type) > -1) {
                let item = media.files[media.selected_media];
                let url = URL.createObjectURL(item.file);
                return (<img src={url}
                />);
            } else if (videoTypes.indexOf(media.files[media.selected_media].type) > -1) {
                let item = media.files[media.selected_media];
                let url = URL.createObjectURL(item.file);
                let isPreviewSupported = videoPreviewSupported.indexOf(item.type) >= 0 ? true : false;
                return (isPreviewSupported ? <video width="100%" height="100%" controls>
                    <source src={url} />
                    Your browser does not support HTML5 video.
                </video> : <div className={`media-g-preview-section video`} style={{ textAlign: "center" }}>
                    <div style={{ fontSize: "20px", fontWeight: "bold" }}>No Preview Available</div>
                </div>);
            } else if (audioTypes.indexOf(media.files[media.selected_media].type) > -1) {
                let item = media.files[media.selected_media];
                let url = URL.createObjectURL(item.file);
                return (<AudioPreview
                    src={url}
                    type={item.type}
                />);
            } else if (documentTypes.indexOf(media.files[media.selected_media].type) > -1) {

                if (media.files[media.selected_media].type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' || media.files[media.selected_media].type === 'application/msword' || media.files[media.selected_media].type === 'text/plain' ||
                    media.files[media.selected_media].type === 'application/vnd.ms-excel' || media.files[media.selected_media].type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || media.files[media.selected_media].type === 'text/csv') {
                    return (<div className={`media-g-preview-section video`} style={{ textAlign: "center" }}>
                        <div style={{ fontSize: "20px", fontWeight: "bold" }}>No Preview Available</div>
                    </div>)
                } else {
                    let item = media.files[media.selected_media];
                    let url = URL.createObjectURL(item.file);
                    return (<DocumentPreview
                        pageNumber={1}
                        src={url}
                    />);

                }

            }
        }
    }

    function onClickRemoveMedia(file_id) {
        let keys = Object.keys(media.files);
        if (media.selected_media === file_id) {
            let removeIndex = keys.indexOf(file_id);
            let key = null;
            if (removeIndex === 0) {
                if (keys.length >= 2) {
                    key = keys[1];
                } else {
                    //close
                    props.ui_panel_actions.closeMediaUpload();
                }
            } else {
                key = keys[0];
            }
            setMedia(prevValue => {
                let { files } = prevValue;
                let { [file_id]: value, ...rest } = files;
                return { ...prevValue, files: { ...rest }, selected_media: key };
            });
        } else {
            setMedia(prevValue => {
                let { files } = prevValue;
                let { [file_id]: value, ...rest } = files;
                return { ...prevValue, files: { ...rest } };
            });

        }
        /* setSelectedImage({});
        let newFiles = {};
        Object.keys(media.files).map((key, index) => {
            if (key !== file_id) {
                newFiles[key] = media[key];
            }
        });
        /* setMedia(newFiles); */
        /* if (Object.keys(media.files).length === 1) {//since 1 before update screen
            props.onClickCloseMediaUpload();
        } */
    }

    function uploadAnotherMedia(input) {
        //add to upload list
        let fileObj = {};

        for (const file of Array.from(input.target.files)) {
            if (props.ui_panel_upload_data.data.type === 'DOCUMENT') {
                if (documentTypes.indexOf(file.type) === -1) {
                    message.error(`Unsupported document file format`);
                    return;
                }
            }

            if (props.ui_panel_upload_data.data.type === 'MEDIA') {
                if (mediaTypes.indexOf(file.type) === -1) {
                    message.error(`Unsupported media file format`);
                    return;
                }
            }


            let fileId = uuid();
            fileObj[fileId] = {
                file_id: fileId,
                file: file,
                name: file.name,
                size: file.size,
                type: file.type,
                caption: ""
            };
        }

        setMedia(prevValue => {
            let { files } = prevValue;
            return { ...prevValue, files: { ...files, ...fileObj } };
        });

    }

    /*
    {
    "v": 1,
    "message_type": "text/html",
    "channel": "WEBCHAT",
    "media": [{
        "content_url": "",
        "content_size": 0,
        "content_title": "",
        "thumbnail_url": "",
        "mimeType": ""
    },{
        "content_url": "",
        "content_size": 0,
        "content_title": "",
        "thumbnail_url": "",
        "mimeType": ""
    }],
    "message_content": "Hi",
    "mid": "23452342",
    "created_at": 21342342342
}
     */
    function sendMultipleFiles(documentFiles, mediaFiles, mid) {
        //handle document files

        if (documentFiles.length > 0) {
            documentFiles.forEach(item => {

                let uniqueId = uuid();

                let url = `${fileMetaServiceUrl}/stream/media/${item.interaction_id}`;
                let thumbnail_url = null;

                let msg = {
                    v: 1,
                    mid: item.interaction_id,
                    sid: item.interaction_id,
                    channel: "WEBCHAT",
                    message_type: item.contentType,
                    from: {
                        id: auth.getUserId(),
                        name: auth.getDisplayName()
                    },
                    to: {
                        id: props.selected_profile.username,
                        name: props.selected_profile.username
                    },
                    media: [],
                    created_at: Date.now(),
                    message_content: documentFiles.length === 1 ? item.caption : "",
                    other: null,
                    isUploading: "DONE"
                };

                msg.media.push({
                    content_url: item.fileUrl ? item.fileUrl : url,
                    content_size: item.size,
                    content_title: item.original_name,
                    thumbnail_url: thumbnail_url,
                    mimeType: item.contentType,
                    created_at: msg.created_at
                });


                msg.conversation_type = props.selected_profile.conversation_type;
                let profanity = filterProfanity(msg.message_content);

                if (profanity.isProfane) {
                    notification.error({
                        message: `Message Blocked`,
                        description:
                            `Your message : "${profanity.message}", is flagged by profanity filter and cannot be sent`,
                        placement: 'bottomRight',
                        style: {
                            backgroundColor: "#ff751a",
                            color: "White"
                        },
                    });
                    ChatService.sendProfanityMessage(msg.message_content, props.selected_profile.InteractionId);

                } else {
                    ChatService.sendChatMessage(props.selected_profile.conversation_type, "text/html", props.selected_profile.username, msg, false, props.selected_profile.InteractionId);
                    //props.chat_actions.sendNewMessageSuccess(msg);
                    props.chat_actions.updateMessage(msg);

                }

            });

        }

        if (mediaFiles.length > 0) {

            let uniqueId = uuid();

            let msg = {
                v: 1,
                mid: mid,
                sid: mid,
                channel: "WEBCHAT",
                message_type: mediaFiles[0].contentType,
                from: {
                    id: auth.getUserId(),
                    name: auth.getDisplayName()
                },
                to: {
                    id: props.selected_profile.username,
                    name: props.selected_profile.username
                },
                media: [],
                created_at: Date.now(),
                message_content: mediaFiles.length === 1 ? mediaFiles[0].caption : "",
                other: null,
                isUploading: 'DONE'
            };

            mediaFiles.forEach(item => {

                let url = `${fileMetaServiceUrl}/stream/media/${item.interaction_id}`;
                let thumbnail_url = null;

                if (imageTypes.indexOf(item.contentType) !== -1) {
                    thumbnail_url = `${fileMetaServiceUrl}/stream/thumbnail/${item.interaction_id}/225`;
                }

                msg.media.push({
                    content_url: url,
                    content_size: item.size,
                    content_title: item.caption,
                    thumbnail_url: thumbnail_url,
                    mimeType: item.contentType,
                    created_at: msg.created_at
                });


            });

            msg.conversation_type = props.selected_profile.conversation_type;
            let profanity = filterProfanity(msg.message_content);

            if (profanity.isProfane) {
                notification.error({
                    message: `Message Blocked`,
                    description:
                        `Your message : "${profanity.message}", is flagged by profanity filter and cannot be sent`,
                    placement: 'bottomRight',
                    style: {
                        backgroundColor: "#ff751a",
                        color: "White"
                    },
                });
                ChatService.sendProfanityMessage(msg.message_content, props.selected_profile.InteractionId);

            } else {
                ChatService.sendChatMessage(props.selected_profile.conversation_type, "text/html", props.selected_profile.username, msg, false, props.selected_profile.InteractionId);
                //props.chat_actions.sendNewMessageSuccess(msg);
                props.chat_actions.updateMessage(msg);

            }


        }

        /* props.ui_panel_actions.closeMediaUpload();
        eventEmitter.fireEvent("scrollbar", { scrollbar_status: "down" }); */
    }

    function sendMedia(current_caption) {

        //Upload media

        setUploadStatus("UPLOADING");

        uploadFiles(media.files).then(({ result, mid }) => {
            setUploadStatus("UPLOADED");
            let uploadResults = result.reduce((acc, res) => {
                if (res.data.isSuccess) {

                    if (documentTypes.indexOf(res.data.result.contentType) > -1) {
                        //document
                        acc.documentFiles.push({ ...res.data.result, caption: res.data.result.interaction_id === media.selected_media ? current_caption : media.files[res.data.result.interaction_id].caption })
                    }
                    else if (mediaTypes.indexOf(res.data.result.contentType) > -1) {
                        acc.mediaFiles.push({ ...res.data.result, caption: res.data.result.interaction_id === media.selected_media ? current_caption : media.files[res.data.result.interaction_id].caption });
                    }

                } else {
                    if (documentTypes.indexOf(res.data.result.contentType) > -1) {
                        //document
                        acc.failedDocumentFiles.push(res.data.result);
                    }
                    else if (mediaTypes.indexOf(res.data.result.contentType) > -1) {
                        acc.failedMediaFiles.push(res.data.result);
                    }

                }

                return acc;
            }, { documentFiles: [], mediaFiles: [], failedDocumentFiles: [], failedMediaFiles: [] });

            if (uploadResults.mediaFiles.length > 0 && uploadResults.failedMediaFiles.length > 0) {
                //show warning but proceed with success files
                notification['warn']({
                    message: 'Media Upload',
                    placement: 'bottomLeft',
                    description: 'Some of the media files failed to upload',
                    onClick: () => {
                        console.log('Notification Clicked!');
                    },
                });

            }

            if (uploadResults.mediaFiles.length > 0 || uploadResults.documentFiles.length > 0) {
                sendMultipleFiles(uploadResults.documentFiles, uploadResults.mediaFiles, mid);
            }

            if (uploadResults.mediaFiles.length === 0 && uploadResults.failedMediaFiles.length > 0) {
                //call action
                props.chat_actions.updateUploadFailMessage(mid, props.selected_profile.username);
            }

            if (uploadResults.failedDocumentFiles.length > 0) {
                //call action
                uploadResults.failedDocumentFiles.forEach(item => {
                    props.chat_actions.updateUploadFailMessage(item.interaction_id, props.selected_profile.username);
                });

            }


        }).catch(ex => {
            setUploadStatus("ERROR");
        })

        /* setMediaLoading(true);
        if (Object.keys(media.files).length === 0) {
            setMediaLoading(false);
            notification['error']({
                message: 'Media Upload',
                placement: 'bottomLeft',
                description: 'Please upload one or more images to continue!',
                onClick: () => {
                    console.log('Notification Clicked!');
                },
            });
            setSelectedImage({});
            childRef.current.clear();
            props.onClickCloseMediaUpload();
        } else {
            setMediaLoading(false);
            let isAllUploaded = true;
            Object.keys(media).map((key, index) => {
                let item = media[key];
                if (item.upload_status !== 'UPLOADED') {
                    isAllUploaded = false;
                    notification['info']({
                        message: 'Media Upload',
                        placement: 'bottomLeft',
                        description: 'Images are still being uploaded. Please wait!',
                        onClick: () => {
                            console.log('Notification Clicked!');
                        },
                    });
                }
            });

            if (isAllUploaded) {
                sendMultipleFiles();
                setSelectedImage({});
                childRef.current.clear();
                props.onClickCloseMediaUpload();
            }
        } */
    }

    return (
        <>

            <div className={`upload-main-wrapper desktop-upload ${props.ui_panel_upload_data.show ? 'open-info-panel' : 'hide-panel'}`}
                style={{
                    width: props.viewPointWidth - (props.isOpenInfoPanel ? 690 : 390),
                    height: props.viewPointHeight
                }}>

                <div className={'upload-main-header-wrapper'}>
                    <div className={'modal-header d-flex'}>
                        <PanelTitle title={'Preview'} />
                        <MenuIcon icon={<X />}
                            onClick={() => {
                                props.ui_panel_actions.closeMediaUpload();
                                /* childRef.current.clear();
                                props.onClickCloseMediaUpload(); */
                            }}
                            size={'sm'} />
                    </div>
                </div>


                {uploadStatus === "UPLOADING" &&
                    <div className={`media-uploading-wrp`}>
                        <div className={'pre-loader-login media-uploading'}>
                            <div className="loader9" />
                            Uploading...
                        </div>
                    </div>
                }

                {uploadStatus !== "UPLOADING" && <div className={'image-preview-wrp'}
                    style={{
                        height: props.viewPointHeight - 280
                    }}>
                    <div className={'preview-inner-wrp'}
                        style={{
                            height: props.viewPointHeight - 400
                        }}>
                        <div className={'preview-img'}>
                            {/*<div className={'preview-inner-wrp audio'}>*/}
                            {/*    <AudioPreview/>*/}
                            {/*</div>*/}
                            {getSelectedMedia()}
                        </div>
                    </div>

                    {uploadStatus !== "UPLOADING" &&
                        <div className={`caption-wrapper`}
                            style={{
                                width: props.viewPointWidth - (props.isOpenInfoPanel ? 1000 : 800),
                            }}>
                            <div className={`add-caption-wrapper d-flex align-items-center justify-content-center`}>
                                <SearchTextBoxWrapper id={"media_upload_caption"} placeholder={'Add a caption…'}
                                    ref={childRef}
                                    onKeyPressHandler={keyPressHandler}
                                    onFocusOutHandler={onFocusOutHandler}
                                />
                            </div>
                        </div>
                    }

                </div>
                }
                {uploadStatus !== "UPLOADING" &&
                    <div className={'upload-send-btn-wrapper d-flex align-items-center justify-content-center'}>
                        <SendButton onClick={() => sendMedia(childRef.current.getValue())} />
                    </div>
                }


                <>
                    {uploadStatus !== "UPLOADING" && Object.keys(media.files).length > 0 &&
                        <div className={'image-action-wrp d-flex justify-content-center align-items-center'}>

                            <div className={'upload-img-view-wrp d-flex'}>
                                {getAllMedia()}
                            </div>

                            <div className={'add-new-img d-flex justify-content-center align-items-center'}>
                                <input
                                    /* accept={() => {
                                        if (Object.keys(media).length > 0) {
                                            let mType = media[Object.keys(media)[0]].type;

                                            if (imageTypes.indexOf(mType) > -1) {
                                                return imageTypes.join(',');
                                            } else if (videoTypes.indexOf(mType) > -1) {
                                                return videoTypes.join(',');
                                            } else if (documentTypes.indexOf(mType) > -1) {
                                                return "application/pdf";
                                            } else if (audioTypes.indexOf(mType) > -1) {
                                                return audioTypes.join(',');
                                            }
                                        }

                                        return imageTypes.join(',');
                                    }} */
                                    multiple
                                    accept={documentTypes.indexOf(media.files[Object.keys(media.files)[0]].type) > -1 ? documentTypes.join(',')
                                        : `${mediaTypes.join(',')}}`}
                                    id='selectAnotherImage_1' hidden type="file" name="file"
                                    onChange={(media) => {

                                        let fileObj = {};

                                        for (const file of Array.from(media.target.files)) {

                                            if (props.ui_panel_upload_data.data.type === 'DOCUMENT') {
                                                if (documentTypes.indexOf(file.type) === -1) {
                                                    message.error(`Unsupported document file format`);
                                                    return;
                                                }
                                            }

                                            if (props.ui_panel_upload_data.data.type === 'MEDIA') {
                                                if (mediaTypes.indexOf(file.type) === -1) {
                                                    message.error(`Unsupported media file format`);
                                                    return;
                                                }
                                            }

                                            let fileId = uuid();
                                            fileObj[fileId] = {
                                                file_id: fileId,
                                                file: file,
                                                name: file.name,
                                                size: file.size,
                                                type: file.type,
                                                caption: ""
                                            };

                                            if (file.name.endsWith(".doc")) {
                                                fileObj[fileId].type = "application/msword";
                                            }

                                            if (file.name.endsWith(".docx")) {
                                                fileObj[fileId].type = "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
                                            }

                                            if (file.name.endsWith(".xls")) {
                                                fileObj[fileId].type = "application/vnd.ms-excel";
                                            }

                                            if (file.name.endsWith(".xlsx")) {
                                                fileObj[fileId].type = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
                                            }

                                            if (file.name.endsWith(".csv")) {
                                                fileObj[fileId].type = "text/csv";
                                            }

                                            if (file.name.endsWith(".txt")) {
                                                fileObj[fileId].type = "text/plain";
                                            }
                                        }

                                        setMedia(prevValue => {
                                            let { files } = prevValue;
                                            return { ...prevValue, files: { ...files, ...fileObj } };
                                        });
                                        /* uploadAnotherMedia(m); */
                                        media.target.value = null;
                                    }} />
                                <MenuIcon
                                    icon={<Plus />}
                                    size={'lg'}
                                    onClick={() => {
                                        document.getElementById("selectAnotherImage_1").click();
                                    }}
                                />
                            </div>

                        </div>
                    }
                </>

            </div>


        </>
    );
}


function mapStateToProps(state) {
    return {
        selected_profile: state.selected_profile,
        ui_panel_upload_data: state.ui_panel_data.media_upload_panel
    };
}

function mapDispatchToProps(dispatch) {
    return {
        chat_actions: bindActionCreators(chatActions, dispatch),
        ui_panel_actions: bindActionCreators(uiPanelActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(MediaUpload);
