import React, { useState,useEffect } from "react";
import { Modal, Button ,message} from "antd";
import * as logger from "../../../lib/logSdk/logger";
import WorkspaceService from "../../../service/workspaceService";
import * as auth from "../../../utils";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { ExclamationCircleOutlined } from '@ant-design/icons';
import livechatclient from "../../../lib";
import navigationRestrictions from "../../../config/navigationRestrictions";

const workspaceService = new WorkspaceService();
let workspace, userId,username,notJoinToOfficialAccount;
const WorkspaceRequest = () => {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [officialAccount, setOfficialAccount] = useState("");
  

  useEffect(() => {    
    loadBasicData();
  }, []);

  
  /* const onChangeHandler = (wp,username) => {
    if (wp !== auth.getWorkspace()) {
        let refreshTokenData = auth.getRefreshTokenObj();
        if (refreshTokenData) {
            auth.addWorkspace(workspace)
            livechatclient.switchWorkspace(authUrl, username, refreshTokenData.refreshToken, wp).then(response => {

                if (response.isSuccess) {
                    message.success(`Switched to workspace ${wp}`);
                    //setSelectedWSName(workspace);
                } else {
                    message.error(`Error occurred when switching to workspace ${wp}`);
                    return;
                }

            }).catch(ex => {
                message.error(`Error occurred when switching to workspace ${wp}`);
                console.error(ex);
                return;
            })

        }

        }
    } */

  const loadBasicData = async () => {
      try {
        notJoinToOfficialAccount = auth.getOfficialDomainRequest()===null;
        userId = auth.getUserId();
        username = auth.getUser();
        const domain = username.split('@')[1];
        setOfficialAccount(domain);
        const tokens = await Promise.all([executeRecaptcha("isOfficialDomain"),executeRecaptcha("IsUserExistsInTheOrganization")]);
       
        workspace = await workspaceService.isOfficialDomain(domain,tokens[0]);    
        console.log("WorkspaceRequest",`----------------------- workspace.officialDomain :${workspace.officialDomain} , domain=${domain} , notJoinToOfficialAccount =${notJoinToOfficialAccount}, navigationRestrictions.account.official_account_check =${navigationRestrictions.account.official_account_check} , ?? ${workspace && workspace.officialDomain === domain && notJoinToOfficialAccount === true && navigationRestrictions.account.official_account_check === true}`)    
        if(workspace && workspace.officialDomain === domain && notJoinToOfficialAccount === true && navigationRestrictions.account.official_account_check === true){
            
            const isExisting = await workspaceService.IsUserExistsInTheOrganization(workspace.workspaceid,userId,tokens[1]);
           
            if(isExisting === false){
                Modal.confirm({
                    title:"Official Account",
                    icon: <ExclamationCircleOutlined />,
                    content: `Do you want to join the official account ${domain}?`,
                    okText: 'Yes',
                    cancelText: 'No',
                    onOk() {
                        console.log("WorkspaceRequest",'OK');
                        handleOk();
                      },
                    onCancel() {
                        console.log("WorkspaceRequest",'Cancel');
                        handleCancel();
                    }
                  });
            }
        }
      } catch (error) {
        console.error("WorkspaceRequest", "loadBasicData", error);
        logger.error("WorkspaceRequest", "loadBasicData", error.message);
      }
  };

  const handleOk =  async() => {
    try {
        const token = await executeRecaptcha("isOfficialDomain")
       const reply = await workspaceService.save_join_request(workspace.workspaceid, userId,username,token);
       if(reply){        
        message.success(reply.type === "member" ? `Your official account join request approved and routing to ${officialAccount}`:'Successfully send official account join request ');
        livechatclient.addedToOfficialWorkspace(workspace);
       }else{
        message.error('This is an error message');
       }
    } catch (error) {
        console.error("WorkspaceRequest", "handleOk", error);
        logger.error("WorkspaceRequest", "handleOk", error.message);
    }
    
  };

  const handleCancel = () => {
  };

  return (
    <>
      
    </>
  );
};

export default WorkspaceRequest;
