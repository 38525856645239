import React from 'react';
import PropTypes from 'prop-types';


const Button = ({onClick, title, size, type, disabled}) => (
    <button
        type="button"
        onClick={onClick}
        className={`btn btn-rounded ${size} ${type}`}
        disabled={disabled}>
        {title}
    </button>
);

Button.propTypes = {
    // onClick: PropTypes.func.isRequired,
    // title: PropTypes.string.isRequired,
    // size: PropTypes.string.isRequired,
    // type: PropTypes.string.isRequired,
    // disabled: PropTypes.bool,
};

Button.defaultProps = {
    type: 'btn-primary',
    size: 'btn-sm',
    disabled: false,
};

export default Button;
