import * as types from "../actions/actionTypes";
import produce from "immer";

export const timelineReducer = produce((draft = { customerId: "", interactions: {}, loadingState: "LOADING" }, action) => {
    switch (action.type) {

        case types.TIMELINE_LOADED:
            draft.customerId = action.customerId;
            if (Object.keys(action.timeline).length >= 10) {
                draft.loadingState = "LOADED";
            } else {
                draft.loadingState = "END";
            }
            draft.interactions = { ...draft.interactions, ...action.timeline };
            break;
        case types.RESET_TIMELINE:
            draft = { customerId: "", interactions: {}, loadingState: "LOADING" }
            break;
        default: break;
    }

    return draft;
});