import { CUSTOMER_360,OPEN_CUSTOMER_360} from "../actions/actionTypes";

const externalApiControl = {
  controls: {
    interactionId: null,
    interactionChannel: null
  },
};

const externalApiReducer = (state = externalApiControl, action) => {
  switch (action.type) {
    case CUSTOMER_360:
      return state; 
      case OPEN_CUSTOMER_360:
      return state; 
    default:
      return state;
  }
};

export default externalApiReducer;
