import React from 'react';
import PropTypes from 'prop-types';
import {ProfileImage, TitleWithSmallText, Button} from "../index";


const ChatPreLoader = ({isLoading}) => (
    <>
        <div className={`pre-loader-chat-wrapper ${isLoading ? 'active' : 'hide'}`}>
            <div className={'pre-loader'}>
                <div className={'pre-loader-login'}>
                    <div className="loader9"/>
                </div>
            </div>
        </div>
    </>
);

ChatPreLoader.propTypes = {
    isLoading: PropTypes.bool.isRequired,
};

ChatPreLoader.defaultProps = {
    isLoading: false
};

export default ChatPreLoader;
