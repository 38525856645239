import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faLine, faViber, faWeixin, faTelegram, faTwitter, faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';

const SocialMediaIcon = (props) => {

    const getSMIcon = (channel) => {
        switch (channel) {
            case 'FB': {
                return (<FontAwesomeIcon icon={faFacebook} className={`sm-fb ${props.size}`} />);
            }
            case 'LINE': {
                return (<FontAwesomeIcon icon={faLine} className={`sm-line ${props.size}`} />);
            }
            case 'WHATSAPP': {
                return (<FontAwesomeIcon icon={faWhatsapp} className={`sm-whatsapp ${props.size}`} />);
            }
            case 'TWITTER': {
                return (<FontAwesomeIcon icon={faTwitter} className={`sm-twitter ${props.size}`} />);
            }
            case 'VIBER': {
                return (<FontAwesomeIcon icon={faViber} className={`sm-viber ${props.size}`} />);
            }
            case 'TELEGRAM': {
                return (<FontAwesomeIcon icon={faTelegram} className={`sm-telegram ${props.size}`} />);
            }
            case 'WE': {
                return (<FontAwesomeIcon icon={faWeixin} className={`sm-wechat ${props.size}`} />);
            }
            default: {
                return (<FontAwesomeIcon icon={faQuestionCircle} className={`sm-unknown ${props.size}`} />);
            }
        }
    }


    return (
        <>
            {getSMIcon(props.channel)}

        </>

    );
};

export default SocialMediaIcon;
