import * as types from "./actionTypes";
import { PresenceService } from "../../service/presenceService";
import { presenceMethod } from "../../config/baseUrls";

export function getPresenceAll(workspace, userId) {
    return dispatch => {

        if (presenceMethod === 'PAGING') {
            PresenceService.getAllPresenceByPage(workspace , userId)
                .then(presenceMembers => {
                    let presence = presenceMembers.reduce((acc, item) => {
                        if (item.user === userId) {
                            item.status = "online";
                        }
                        acc[item.user] = item;
                        return acc;
                    }, {});
                    dispatch(getAllPresenceForTeamSuccess(presence));
                }).catch(ex => {
                    throw ex;
                });

        } else {
            PresenceService.getAllPresenceForTeam(workspace)
                .then(presenceMembers => {
                    let presence = presenceMembers.reduce((acc, item) => {
                        if (item.user === userId) {
                            item.status = "online";
                        }
                        acc[item.user] = item;
                        return acc;
                    }, {});
                    dispatch(getAllPresenceForTeamSuccess(presence));
                }).catch(ex => {
                    throw ex;
                });

        }

    }
}

export function getAllPresenceForTeamSuccess(presence) {
    return { type: types.GET_ALL_PRESSENCE_SUCCESS, presence };
}

export function receivePresence(presence) {
    return { type: types.RECEIVE_PRESENCE, presence };
}

export function expirePresence(user) {
    return { type: types.EXPIRE_PRESENCE, user };
}

export function setLocalConnectivity(user) {
    return { type: types.SET_LOCAL_CONNECTIVITY, user };
}

export function receivePresenceUpdate(presence) {
    return { type: types.RECEIVE_PRESENCE_UPDATE, presence };
}

export function sendPresenceSuccess(presence) {
    return { type: types.SEND_PRESENCE_SUCCESS, presence };
}