import { Logger } from "./logger";
import { AppEventMessage } from "./message";

class SignatureCaptureManager {
    signatureRequestId;
    isSignatureRequestSent;
    signatureRequestTimeout;

    constructor () {
        this.signatureRequestId = "";
        this.isSignatureRequestSent = false;
        this.signatureRequestTimeout = null;
    }

    SetSignatureRequestTimeout = (id, isRequestSent) => {
        this.signatureRequestId = id;
        this.isSignatureRequestSent = isRequestSent;
        this.signatureRequestTimeout = setTimeout(function () {
            Logger.error(`Signature Capture Request Timeout reached`);
            signatureManager.signatureRequestId = "";
            signatureManager.isSignatureRequestSent = false;
            clearTimeout(signatureManager.signatureRequestTimeout);
        }, 69000);
    }

    ProcessSignatureResponseAck = responseObject => {
        if (responseObject.requestId === this.signatureRequestId) {
            this.isSignatureRequestSent = false;
            clearTimeout(this.signatureRequestTimeout);
            this.signatureRequestId = "";
            return true;
        } else {
            Logger.error(`Signature Capture Response received for unknown request: ${JSON.stringify(responseObject)}`);
            return false;
        }
    }
}

var signatureManager = new SignatureCaptureManager();

class SnapshotCaptureManager {
    snapshotRequestId;
    isSnapshotRequestSent;
    snapshotRequestTimeout;

    constructor () {
        this.snapshotRequestId = "";
        this.isSnapshotRequestSent = false;
        this.snapshotRequestTimeout = null;
    }

    SetSnapshotRequestTimeout = (id, isRequestSent) => {
        this.snapshotRequestId = id;
        this.isSnapshotRequestSent = isRequestSent;
        this.snapshotRequestTimeout = setTimeout(function () {
            Logger.error(`Snapshot Capture Request Timeout reached`);
            snapshotManager.snapshotRequestId = "";
            snapshotManager.isSnapshotRequestSent = false;
            clearTimeout(snapshotManager.snapshotRequestTimeout);
        }, 69000);
    }

    ProcessSnapshotResponseAck = responseObject => {
        if (responseObject.requestId === this.snapshotRequestId) {
            this.isSnapshotRequestSent = false;
            clearTimeout(this.snapshotRequestTimeout);
            this.snapshotRequestId = "";
            return true;
        } else {
            Logger.error(`Snapshot Capture Response received for unknown request: ${JSON.stringify(responseObject)}`);
            return false;
        }
    }
}

var snapshotManager = new SnapshotCaptureManager();

class SignatureCaptureResponseObject {
    livechatReference;
    responseTimeout;
    requestId;
    userId;
    requesterUserId;

    constructor (requestData, livechat) {
        this.requestId = requestData.requestId;
        this.userId = requestData.userId;
        this.requesterUserId = requestData.requesterUserId;
        this.livechatReference = livechat;

        this.responseTimeout = setTimeout(() => {
            this.requestId = "";
            this.userId = "";
            this.requesterUserId = "";
            this.livechatReference = null;
            Logger.error(`Signature Response Object timed out`)
        }, 60000);
    }

    accept = signatureData => {
        if (signatureData != null && signatureData !== "") {
            if (this.requestId !== "" && this.userId !== "" && this.requesterUserId !== "") {
                clearTimeout(this.responseTimeout);

                var responseModel = {};
                responseModel.requestId = this.requestId;
                responseModel.userId = this.userId;
                responseModel.requesterUserId = this.requesterUserId;
                responseModel.isAccepted = true;
                responseModel.data = signatureData;

                var messageObject = new AppEventMessage();
                messageObject.requesterUserId = this.livechatReference.livechat.getUserId();
                messageObject.messageSubType = "user-signature-request-ack";
                messageObject.content = responseModel;

                this.livechatReference.sendAppEvent(JSON.stringify(messageObject), `user:${this.requesterUserId}`);

                //TO-DO: Inform conversations service as well

                return true;
            }
            else {
                Logger.error(`Unable to send signature capture response, as a response timeout has occurred`);
                return false;
            }
        }
        else {
            Logger.error(`Signature Data is empty`);
            return false;
        }

    }

    reject = () => {
        if (this.requestId !== "" && this.userId !== "" && this.requesterUserId !== "") {
            clearTimeout(this.responseTimeout);

            var responseModel = {};
            responseModel.requestId = this.requestId;
            responseModel.userId = this.userId;
            responseModel.requesterUserId = this.requesterUserId;
            responseModel.isAccepted = false;
            responseModel.data = null;

            var messageObject = new AppEventMessage();
            messageObject.requesterUserId = this.livechatReference.livechat.getUserId();
            messageObject.messageSubType = "user-signature-request-ack";
            messageObject.content = responseModel;

            this.livechatReference.sendAppEvent(JSON.stringify(messageObject), `user:${this.requesterUserId}`);

            return true;
        }
        else {
            Logger.error(`Unable to send signature capture response, as a response timeout has occurred`);
            return false;
        }
    }
}

class SnapshotCaptureResponseObject {
    livechatReference;
    responseTimeout;
    requestId;
    userId;
    requesterUserId;

    constructor (requestData, livechat) {
        this.requestId = requestData.requestId;
        this.userId = requestData.userId;
        this.requesterUserId = requestData.requesterUserId;
        this.livechatReference = livechat;

        this.responseTimeout = setTimeout(() => {
            this.requestId = "";
            this.userId = "";
            this.requesterUserId = "";
            this.livechatReference = null;
            Logger.error(`Snapshot Response Object timed out`);
        }, 60000);
    }

    accept = snapshotData => {
        if (snapshotData != null && snapshotData !== "") {
            if (this.requestId !== "" && this.userId !== "" && this.requesterUserId !== "") {
                clearTimeout(this.responseTimeout);

                var responseModel = {};
                responseModel.requestId = this.requestId;
                responseModel.userId = this.userId;
                responseModel.requesterUserId = this.requesterUserId;
                responseModel.isAccepted = true;
                responseModel.data = snapshotData;

                var messageObject = new AppEventMessage();
                messageObject.requesterUserId = this.livechatReference.livechat.getUserId();
                messageObject.messageSubType = "user-snapshot-request-ack";
                messageObject.content = responseModel;

                this.livechatReference.sendAppEvent(JSON.stringify(messageObject), `user:${this.requesterUserId}`);

                //TO-DO: Inform conversations service as well

                return true;
            }
            else {
                Logger.error(`Unable to send snapshot capture response, as a response timeout has occurred`);
                return false;
            }
        }
        else {
            Logger.error(`Snapshot Data is empty`);
            return false;
        }
    }

    reject = () => {
        if (this.requestId !== "" && this.userId !== "" && this.requesterUserId !== "") {
            clearTimeout(this.responseTimeout);

            var responseModel = {};
            responseModel.requestId = this.requestId;
            responseModel.userId = this.userId;
            responseModel.requesterUserId = this.requesterUserId;
            responseModel.isAccepted = false;
            responseModel.data = null;

            var messageObject = new AppEventMessage();
            messageObject.requesterUserId = this.livechatReference.livechat.getUserId();
            messageObject.messageSubType = "user-snapshot-request-ack";
            messageObject.content = responseModel;

            this.livechatReference.sendAppEvent(JSON.stringify(messageObject), `user:${this.requesterUserId}`);

            return true;
        }
        else {
            Logger.error(`Unable to send snapshot capture response, as a response timeout has occurred`);
            return false;
        }
    }
}

export {
    signatureManager, SignatureCaptureResponseObject, snapshotManager, SnapshotCaptureResponseObject
};