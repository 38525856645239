import React from 'react';
import PropTypes from 'prop-types';
import { UserPlus, Pause, Mic, MicOff, PhoneOutgoing, Airplay, Check } from "react-feather";
import { ChatUser, MenuIcon, TitleWithSmallText, UserImage, ParticipationImage } from "../index";
import { Scrollbars } from "react-custom-scrollbars";
import { videoPreviewSupported } from "../../../config/json/fileTypeMap";


const MediaVideo = ({ src, type, active, viewGallery, selectThis }) => {

    let isPreviewSupported = videoPreviewSupported.indexOf(type) >= 0 ? true : false;
    return (
        <div
            className={`media-wrapper ${active ? 'active' : ''}`} style={{ paddingTop: isPreviewSupported ? '13%' : '21%' }}>
            <div className={'check'} onClick={selectThis}>
                <MenuIcon icon={<Check />}
                    size={'sm'} />
            </div>
            <div>
                <video width="140" height="150" onClick={viewGallery} poster={isPreviewSupported ? null : require('../../../assets/img/no_preview.jpg')}>
                    <source src={src} type={type} />
                    Your browser does not support HTML5 video.
                </video>

            </div>


        </div>
    )
};


MediaVideo.defaultProps = {};

export default MediaVideo;
