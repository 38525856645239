import React, { useEffect, useState,useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { OnVoiceActivity } from "../../../redux/actions/callControlsActions";
import {   Spin   } from "antd";

const Participator = ({
  id,
  fullName,
  status,
  active,
  isScreenShare,
  mediaStream,
  resetStatus,
  isVideoMinimizeScreen,
  userId,
  activeSetting,audioDeviceId,voiceActivity,interactionId
}) => {
  const state = useSelector((state) => state);
  const dispatch = useDispatch();
  const videoRef = React.createRef();
  const unmounted = useRef(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isActiveCaller, setIsActiveCaller] = useState(activeSetting);
  const [isScreenShared, setIsScreenShared] = useState(isScreenShare);
  const [userName,setUserName] = useState(fullName);

  const voiceActivityDetected=()=>{
    try {
      if (userId === state.callControls.userId){
        setIsActiveCaller({
          isActive: true,
          zIndex: 8,
          currentMainId: state.callControls.userId,
          border:null,
        });
      }        

      if (userId === state.callControls.previousUserId)
        setIsActiveCaller({
          isActive: false,
          zIndex: 9,
          currentMainId: state.callControls.userId,
          border:null,
        });
    } catch (ex) {
      console.error(ex);
    }
  }
  useEffect(() => {
    voiceActivityDetected();
  }, [state.callControls.controls.isVoiceActivityDetected]);

  useEffect(() => {
    try {
      if(interactionId && userId?.startsWith("EXT-") && state.interactions[interactionId]?.UserDetails[userId]){
        const temp_user = {...state.interactions[interactionId].UserDetails[userId]} ;
        if (temp_user.firstName) {
         const name = `${temp_user.firstName} ${(temp_user.lastName === null || temp_user.lastName === undefined )?'' :temp_user.lastName}`;
         setUserName(name);
         console.log("Participator","useEffect","state.interactions", `update User Name: ${name} , olf name : ${fullName}`);
        }
      }
    } catch (error) {
      console.error("Participator","useEffect","state.interactions", error);
    }    

  }, [state.interactions]);
  //state.interactions[interactionId]?.UserDetails] 

  const screenShareVoiceActivityDetected=()=>{
    try {
      if (userId === state.callControls.userId){
        setIsActiveCaller({
          isActive: false,
          zIndex: 9,
          currentMainId: state.callControls.userId,
          border:'2px solid rgb(230, 17, 17)',
        });
      }        

      if (userId === state.callControls.previousUserId)
        setIsActiveCaller({
          isActive: false,
          zIndex: 9,
          currentMainId: state.callControls.userId,
          border:null,
        });
    } catch (ex) {
      console.error(ex);
    }
  }
  useEffect(() => {
    screenShareVoiceActivityDetected();
  }, [state.callControls.controls.isScreenShareVoiceActivityDetected]);

  const screenAdded =()=>{
    try {
     
      if (state.callControls.isScreenShared) {
        setIsActiveCaller(
          userId === state.callControls.userId
            ? {
                isActive: true,
                zIndex: 8,
                currentMainId: state.callControls.userId,
              }
            : {
                isActive: false,
                zIndex: 9,
                currentMainId: state.callControls.userId,
              }
        );
        setIsScreenShared(true);
      }
    } catch (ex) {
      console.error(ex);
    }
  }

  useEffect(() => {
    screenAdded();
  }, [state.callControls.controls.isScreenAdded]);

  const screenRemoved=()=>{
    try {
      if (state.callControls.isScreenRemoved) {
        setIsScreenShared(false);

        setIsActiveCaller((userId === state.callControls.userId) ? {
          isActive: true,
          zIndex: 8,
          currentMainId: state.callControls.userId,
        }:{
          isActive: false,
          zIndex: 9,
          currentMainId: state.callControls.userId,
        });


        /* if (userId === state.callControls.userId)
          setIsActiveCaller({
            isActive: true,
            zIndex: 8,
            currentMainId: state.callControls.userId,
          });

        if (userId === state.callControls.previousUserId)
          setIsActiveCaller({
            isActive: false,
            zIndex: 9,
            currentMainId: state.callControls.userId,
          });
 */
        
      }
    } catch (ex) {
      console.error(ex);
    }
  }
  useEffect(() => {
    screenRemoved();
  }, [state.callControls.controls.isScreenRemoved]);

  useEffect(() => {
    (async ()=>{
      if(audioDeviceId){
        const audio = document.getElementById("audio_device_id")
        await audio.setSinkId(audioDeviceId);
        console.log('Audio is being played on ' + audio.sinkId);
      }    
    })();
  }, [audioDeviceId]);

  const bindMedia=()=>{
    try {
      setIsLoading(true);
      videoRef.current.srcObject = mediaStream;
      videoRef.current.addEventListener("loadeddata", (event) => {
        try {
          if (!unmounted.current) {
            setIsLoading(false);
            resetStatus();
          }         
        } catch (error) {
          console.log('Participator',"loadeddata",error);
        }
      });
      setIsActiveCaller(activeSetting);

      (async ()=>{
        if(audioDeviceId){
          const audio = document.getElementById("audio_device_id")
          await audio.setSinkId(audioDeviceId);
          console.log('Audio is being played on ' + audio.sinkId);
        }        
      })();
     
    } catch (ex) {
      console.error(ex);
    }
  }
  useEffect(() => {
    bindMedia();
  }, [mediaStream]);

  useEffect(() => {
    return () => { unmounted.current = true }
  }, []);

  
  const getCursor = () => {
    let cursor = "pointer";
    if (fullName === "Unknown") {
      cursor = "not-allowed";
    } else {
      if (isScreenShared) {
        cursor = "not-allowed";
        if (isActiveCaller.isActive) {
          cursor = "move";
        }
      }
      if (isActiveCaller.isActive) {
        cursor = "move";
      }
    }
    return cursor;
  };
  return (
    <>
      <div
        className={`local-video-wrapper active mt-2   ${isVideoMinimizeScreen ? "minimizer-screen" : "full-screen"}  ${isActiveCaller.isActive ? "active-caller" : ""} `}
        onClick={() => {
          if(voiceActivity){
            console.debug("Participator","onClick","this feature disable with voice detection");
            return;
          }
          if (!isActiveCaller.isActive &&
            userId !== "-99999" &&
            isActiveCaller.currentMainId !== "-99999" &&
            fullName !== "Unknown" &&
            !isScreenShared
          )
            dispatch(
              OnVoiceActivity({
                userId: userId,
                userName: fullName,
                previousUserId: isActiveCaller.currentMainId,
              })
            ); 
        }}
        style={{ zIndex: isActiveCaller.zIndex }}
      >
        <div  title={userName}  className={` ${ isVideoMinimizeScreen ? "minimizer-screen" : "full-screen" }   ${active ? "active" : ""} ${ isActiveCaller.isActive ? "active-caller" : "Participation-image"  } `} style={{ cursor: getCursor() , border:isActiveCaller.border}} >
          <video id='audio_device_id' ref={videoRef} autoPlay playsInline className="remote-video-tag" data-isactive={!isVideoMinimizeScreen && isActiveCaller.isActive}></video>
          {!isVideoMinimizeScreen && (
            <div className={`video-element-name ${  isVideoMinimizeScreen ? "minimizer-screen" : "full-screen" }`} style={{ cursor: fullName === "Unknown" ? "not-allowed" : "pointer", }} >
              {userName}
            </div>
          )}
           {isLoading && (
              <Spin                
                style={{
                  margin: "auto",
                  position: "absolute",
                  top:"50%"
                }}
              />
            )}
        </div>

       
      </div>
    </>
  );
};

Participator.defaultProps = {};

export default Participator;
