import React from 'react';
import { Popover, Button } from 'antd';
import PropTypes from 'prop-types';
import navigationRestrictions from "../../../config/navigationRestrictions";
import { ProfileImage } from "../../common";
import { getSession, getUserId, getUser, getDisplayName } from "../../../utils";
import ConnectivityStatusIndicator from "../../common/ConnectivityStatusIndicator/ConnectivityStatusIndicator";
import phoneEngine from "../phone/phoneEngine/phoneEngineHandler";
import liveChatClient from "../../../lib";
import * as auth from "../../../utils";
import { message } from "antd/lib/index";
import * as logger from "../../../lib/logSdk/logger";


const content = ({ src, conversation_type, history, showLogoutButton ,onClickSelfView}) => {

    const logout = () => {
        try {

            liveChatClient.logoutFromSystem("", "NORMAL", "").then(response => {
                liveChatClient.endSession();
                phoneEngine.phoneFunctions.hangup("caller", "callee");
                auth.userLogOut();
                setTimeout(() => {

                    history.push("/logout");
                    window.location.reload();
                    console.log("IDFCHeader", "uireload", "window.location.reload");

                }, 2000);


            }).catch(error => {
                console.error("IDFCHeader", "logout", "Error in logout", error);
                logger.error("IDFCHeader", "logout", error.message);
                message.error("Error in logout");


            });

            //window.location.reload();
        } catch (error) {
            console.error("IDFCHeader", "logout", "Error in logout", error);
            logger.error("IDFCHeader", "logout-Error in logout", error.message);
            message.error("Error in logout");
        }
    };

    let fullName = auth.getDisplayName() ? auth.getDisplayName() : auth.getUserId();
    /* let style = {
        padding: "7px",
        fontSize: "1.2rem", background: "rgb(227, 23, 62)"
    }; */
    const intials = fullName ? fullName.split(' ').map(fullName => fullName[0]).join('').toUpperCase() : "";

    return (
        <div className={"chat-hover-menu-box"}>
            <div className={'profile-menu-bg-top'}>
                <img src={require("../../../assets/img/menuBg.svg")} alt="User Avatar" />
            </div>
            <div className={'profile-menu-bg-left'}>
                <img
                    src={require("../../../assets/img/menuBg.svg")}
                    alt="User Avatar"
                />
            </div>

            {/* <div className={'ch-img-wrp slider-icon position-relative mt-3 mb-4'}>
                {src ? <img alt="C" src={src} /> : (intials ? <div class="n-profileImage l-text">{intials}</div> : <img src={require("../../../assets/img/defaultUser.svg")}
                    alt="User Avatar" />)}
                <div className="m-bubble-wrapper"><div className="bubble-set one"><div className="bubble"></div>
                    <div className="bubble"></div></div><div className="bubble-set two"><div className="bubble"></div>
                        <div className="bubble"></div></div></div>
            </div> */}
            {navigationRestrictions.connectivity_indicator.active &&

                <div className={'connectivity-wrapper right-top'}>
                    <ConnectivityStatusIndicator phoneEngine={phoneEngine} onClickSelfView={onClickSelfView}/>
                </div>

            }

            <br></br>

            <div className="m-profile-details d-flex flex-column text-center mt-3 mb-1">
                <div className="full-name">{getDisplayName()}</div>
            </div>
            <div className={'d-flex font-sizer rounded position-relative'}>
                <div className={'title pr-1 '}>{`Agent Id : `}</div>
                <div className={'value'}>{getUserId()}</div>
            </div>
            <div className={'d-flex font-sizer rounded position-relative'}>
                <div className={'title pr-1 '}>{`Session Id : `}</div>
                <div className={'value'}>{getSession()}</div>
            </div>
            {navigationRestrictions.profile.summerized_info.active && (showLogoutButton ? <button onClick={logout} className={"btn btn-rounded btn-md btn-block btn-primary log-out between-center"}>
                Log Out
          </button> : <button disabled className={"btn btn-rounded btn-md btn-block log-out between-center"}>
                    Log Out
          </button>)}
            {/* <button className={"btn btn-rounded btn-md btn-block btn-primary log-out between-center"}>
            Log Out
          </button> */}
        </div>
    )
};


const IDFCHeader = ({ props, status, src, conversation_type, showLogoutButton }) => {

    let fullName = auth.getDisplayName() ? auth.getDisplayName() : auth.getUserId();

    return (
        <>
            {(navigationRestrictions.profile.summerized_info.active) &&
                <div className={`apm-header-wrapper IDFC-header-wrapper d-flex`}>
                    {navigationRestrictions.connectivity_indicator.active &&
                        <div className={'connectivity-wrapper'}>
                            <ConnectivityStatusIndicator phoneEngine={phoneEngine} onClickSelfView={props.onClickSelfView} />
                        </div>
                    }

                    <Popover
                        content={content({ src, conversation_type, history: props.history, showLogoutButton,onClickSelfView:props.onClickSelfView })}
                        placement="rightTop"
                        trigger="click"
                    >
                        <div className={`right-wrp user-delete-icon icon-wrapper d-flex`} style={{ marginBottom: "20px" }}>
                            <div className={"icon-wrp mic-wrp more-user-icon"}>
                                <ProfileImage
                                    textType="chatRight"
                                    type={'md'}
                                    src={props.my_profile && props.my_profile.avatarURL ? props.my_profile.avatarURL : null}
                                    status={status}
                                    firstLatter={fullName ? fullName.split(' ').map(fullName => fullName[0]).join('').toUpperCase() : ""}
                                    fontWeight="900"
                                    fontSize="1.2rem" />
                            </div>
                        </div>
                    </Popover>

                    {navigationRestrictions.profile.summerized_info.active &&
                        <div className={'IDFC-header-wrapper d-flex'}>
                            {navigationRestrictions.profile.image.active && <ProfileImage
                                type={'md'}
                                src={props.my_profile && props.my_profile.avatarURL ? props.my_profile.avatarURL : null}
                                status={status}
                                firstLatter={fullName ? fullName.split(' ').map(fullName => fullName[0]).join('').toUpperCase() : ""}
                                padding="7px"
                                fontSize="1.2rem" />}
                            <div className={'IDFC-agent-details-wrapper d-flex flex-column'}>

                                <div className={'IDFC-profile-name'}>
                                    <div className={'m-p-name'}>{`Hi, `}
                                        {getDisplayName() ? getDisplayName() : getUser()}</div>
                                </div>

                                <div className={'d-flex'}>
                                    <div className={'title'}>{`Agent Id : `}</div>
                                    <div className={'value'}>{getUserId()}</div>
                                </div>
                                <div className={'d-flex'}>
                                    <div className={'title'}>{`Session Id : `}</div>
                                    <div className={'value'}>{getSession()}</div>
                                </div>
                            </div>
                        </div>
                    }


                </div>}

        </>
    )
};


export default IDFCHeader;
