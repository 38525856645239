import React from 'react';
import moment, { duration } from 'moment';
import "../../../assets/schedule/css/all.css";
import "../../../assets/schedule/css/custom.css";
import { MeetingSDK } from "../../../lib/MeetingsSdk";
import Participants from "./MeetingParticipant";
import CommentBox from "./Comment";
import { getUserId } from "../../../utils";
import { message } from "antd/lib/index";
import { connect } from "react-redux";
import { Participant } from '../../../lib/MeetingsSdk/Participant';
import { NotificationService } from '../../../service/notificationService';
import { EmailService } from '../../../service/emailService';
import Utils from '../../../utils/common';
import TagsInput from '../../common/TagsInput/Tagsinput';

import momentTz from 'moment-timezone';
import { meetingDurations, meetingReminderDuration, uiBaseUrl } from '../../../config/baseUrls';
import navigationRestrictions from "../../../config/navigationRestrictions";


let tzNames = momentTz.tz.names();

let meetingClient = new MeetingSDK();

class Meetings extends React.Component {

    constructor(props) {
        super(props);

        this.initialState = {
            id: 0,
            creator: getUserId(),
            title: "",
            description: "",
            participants: [],
            location: null,
            startDate: moment().format("YYYY-MM-DD"),
            startTime: moment().format("HH:mm"),
            duration: parseInt(meetingDurations.split(',')[0]),
            timeZone: momentTz.tz.guess(),
            isAllDay: false,
            isRecurrence: false,
            recurrencePattern: {
                type: 0,
                every: 1,
                month: [],
                weekDays: [],
                date: []
            },
            recurrenceRange: {
                type: 0,
                value: '2100-02-04T06:05:36.458Z'
            },
            configurations: [
                {
                    feature: 'autoMeetingID',
                    featureValue: "true",
                    enable: true
                },
                {
                    feature: 'personalMeetingID',
                    featureValue: "true",
                    enable: true
                },
                {
                    feature: 'passCodeSec',
                    featureValue: "true",
                    enable: true
                },
                {
                    feature: 'waitingRoomSec',
                    featureValue: "true",
                    enable: true
                },
                {
                    feature: 'videoHost',
                    featureValue: 'on',
                    enable: true
                },
                {
                    feature: 'videoParticipants',
                    featureValue: 'on',
                    enable: true
                },
                {
                    feature: 'audioHost',
                    featureValue: 'on',
                    enable: true
                },
                {
                    feature: 'audioParticipants',
                    featureValue: 'on',
                    enable: true
                },
                {
                    feature: 'alternativeHosts',
                    featureValue: '',
                    enable: true
                },
                {
                    feature: 'joinAnyTime',
                    featureValue: "true",
                    enable: true
                },
                {
                    feature: 'muteParticipants',
                    featureValue: "true",
                    enable: true
                },
                {
                    feature: 'onlyAuthenticatedUsers',
                    featureValue: "true",
                    enable: true
                },
                {
                    feature: 'automaticallyRecord',
                    featureValue: "true",
                    enable: true
                },
                {
                    feature: 'comments',
                    featureValue: "",
                    enable: true
                },
                {
                    feature: 'externalId',
                    featureValue: "",
                    enable: true
                },
                {
                    feature: 'guestEmails',
                    featureValue: [],
                    enable: true
                }

            ],
            url: 'https://meet.google.com/ibe-wrdn-dbc',
            status: 'CREATED',
            allowGuest: false,
            calender: 'OUTLOOK',
            urlType: 'zoom',
            passCode: 'no passcode',
            isActive: true,
            createdBy: getUserId(),
            createdOn: new Date().toISOString(),
            modifiedBy: getUserId(),
            modifiedOn: new Date().toISOString(),
            notifyBefore: null,
            configurationObj: {},
            guestEmails: []
        };

        if (props.meetingObj) {
            this.convertObject = (obj) => {
                let timeMoment = momentTz.tz(`${moment(obj.startDate).format('YYYY-MM-DD')} ${obj.startTime}`, "UTC");
                obj.startDate = timeMoment.tz(momentTz.tz.guess()).format('YYYY-MM-DD');
                obj.startTime = timeMoment.tz(momentTz.tz.guess()).format('HH:mm');
                obj.modifiedBy = getUserId();
                obj.modifiedOn = new Date().toISOString();
                obj.guestEmails = [];
                return obj;
            }
            this.state = this.convertObject(props.meetingObj);
        } else {
            this.state = this.initialState;
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeOptions = this.handleChangeOptions.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onAddParticipants = this.onAddParticipants.bind(this);

        const result = this.setConfigurations(this.state.configurations, 'feature');
        if (result['guestEmails'] && result['guestEmails'].featureValue) {
            try {
                result['guestEmails'].featureValue = JSON.parse(result['guestEmails'].featureValue);
            } catch (ex) {
                result['guestEmails'].featureValue = [];

            }

        }
        this.state.configurationObj = result;

        this.comment_box = React.createRef();

    }

    setConfigurations = (array, key) => {
        const initialValue = {};
        return array.reduce((obj, item) => {
            return {
                ...obj,
                [item[key]]: item,
            };
        }, initialValue);
    };

    handleChange = (event) => {
        try {
            const target = event.target;
            let key = target.name;
            let value = null
            if (key === 'duration') {
                value = parseFloat(target.value);
            } else {
                value = target.type === 'checkbox' ? target.checked : target.value;
            }

            this.setState({ [key]: value });
        } catch (error) {
            console.log(error);
        }
    }

    handleChangeOptions = (event) => {
        try {
            const target = event.target;
            let feature = target.name;
            let value = target.type === 'checkbox' ? target.checked.toString() : target.value;
            let index = this.state.configurations.findIndex(x => x.feature === feature)
            let newConfigurations = [...this.state.configurations]
            newConfigurations[index] = {
                ...newConfigurations[index],
                featureValue: value
            }
            this.setState({ configurations: newConfigurations });

            const result = this.setConfigurations(newConfigurations, 'feature');
            this.setState({ configurationObj: result });

        } catch (error) {
            console.log(error);
        }

    }

    enableSelector = () => {
        document.getElementById("myDropdown").classList.toggle("show");
        document.getElementById("Group-btn").classList.toggle("active");
    }

    shareLink = () => {

    }

    sendEmailToNewlyAddedUsers = () => {



    }

    onSubmit = (event) => {
        try {
            event.preventDefault();

            let value = this.comment_box.current.state.comment;

            /* let index = this.state.configurations.findIndex(x => x.feature === 'comments')
            let newConfigurations = [...this.state.configurations]
            newConfigurations[index] = {
                ...newConfigurations[index],
                value: value
            } */
            /* this.setState({ configurations: newConfigurations }); */

            /* const result = this.setConfigurations(newConfigurations, 'feature');
            this.setState({ configurationObj: result }); */

            let meetingObj = JSON.parse(JSON.stringify(this.state));
            let index = meetingObj.configurations.findIndex(x => x.feature === 'comments')
            let indexExtId = meetingObj.configurations.findIndex(x => x.feature === 'externalId')
            let indexEmails = meetingObj.configurations.findIndex(x => x.feature === 'guestEmails')
            meetingObj.startDate = moment(`${this.state.startDate} ${this.state.startTime}`).toISOString();
            meetingObj.startTime = momentTz(`${this.state.startDate} ${this.state.startTime}`).tz("UTC").format('HH:mm');
            meetingObj.timeZone = this.state.timeZone;
            meetingObj.OffSet = momentTz().tz(this.state.timeZone).format('Z');

            if (index !== -1) {
                meetingObj.configurations[index] = {
                    feature: 'comments',
                    featureValue: value,
                    enable: true
                }
            } else {
                meetingObj.configurations.push({
                    feature: 'comments',
                    featureValue: "",
                    enable: true
                });

            }


            if (indexExtId !== -1) {
                meetingObj.configurations[indexExtId] = {
                    feature: 'externalId',
                    featureValue: this.state.configurationObj['externalId'] ? this.state.configurationObj['externalId'].featureValue : "",
                    enable: true
                }

            } else {
                meetingObj.configurations.push({
                    feature: 'externalId',
                    featureValue: "",
                    enable: true
                });

            }


            if (indexEmails !== -1) {
                meetingObj.configurations[indexEmails] = {
                    feature: 'guestEmails',
                    featureValue: this.state.configurationObj['guestEmails'] ? JSON.stringify(this.state.configurationObj['guestEmails'].featureValue) : "[]",
                    enable: true
                }
            } else {
                meetingObj.configurations.push({
                    feature: 'guestEmails',
                    featureValue: "[]",
                    enable: true
                });

            }

            if (this.props.isUpdate) {
                meetingClient.updateMeeting(meetingObj).then(result => {
                    if (result.statusCode === 1) {
                        message.info(`Meeting Updated`);

                        let current = moment();
                        let meetingStartTime = moment(`${this.state.startDate} ${this.state.startTime}`);

                        let duration = moment.duration(meetingStartTime.diff(current));

                        let min = duration.asMinutes();

                        for (let p of meetingObj.participants) {
                            EmailService.SendEmail(p.email, null, 'Meeting Invitation', `You have been invited to join Meeting - ${meetingObj.title}`, `<p>You have been invited to join Meeting - <a href="${uiBaseUrl}/meeting/${meetingObj.id}/join/video">${meetingObj.title}</a></p><p>Meeting is scheduled to start on ${Utils.getLocalDate(meetingObj.startDate)} at ${Utils.getLocalTime(meetingObj.startTime)}</p>`)

                        }

                        if (this.state.configurationObj['guestEmails'] && this.state.configurationObj['guestEmails'].featureValue) {
                            for (let i = 0; i < this.state.configurationObj['guestEmails'].featureValue.length; i++) {
                                EmailService.SendEmail(this.state.configurationObj['guestEmails'].featureValue[i], null, 'Meeting Invitation', `You have been invited to join Meeting - ${meetingObj.title}`, `<p>You have been invited to join Meeting - <a href="${uiBaseUrl}/meeting/${result.id}/join/video">${meetingObj.title}</a></p><p>Meeting is scheduled to start on ${Utils.getLocalDate(meetingObj.startDate)} at ${Utils.getLocalTime(meetingObj.startTime)}</p>`)
                            }

                        }

                        NotificationService.addInstantNotification({
                            notificationHandler: "UCHandler",
                            notificationData: JSON.stringify({
                                Message: JSON.stringify({ message_type: 'MEETING_UPDATED', message: 'Meeting updated', otherData: { meetingId: meetingObj.id, title: meetingObj.title, creator: meetingObj.creator, startDate: meetingObj.startDate, startTime: meetingObj.startTime } }),
                                MessageType: 'UPDATEMEETING',
                                MeetingCreator: getUserId(),
                                Participants: meetingObj.participants.reduce((acc, p) => {
                                    if (p.participant !== getUserId()) {
                                        acc.push(p.participant);
                                    }
                                    return acc;
                                }, [])
                            }),
                            appId: "UC",
                            appReferenceId: `${meetingObj.id}`,
                            appReferenceType: `MeetingUpdate_${meetingReminderDuration}_${meetingObj.id}`,
                            createdBy: getUserId()
                        }).then(result => {

                            if (result.statusCode === 0) {
                                message.error(result.statusMessage);
                            } else {
                                if (result.statusCode !== 1) {
                                    message.error('Error occurred setting meeting event');
                                }
                            }

                        }).catch(ex => {

                            console.error(ex);

                            message.error('Error occurred setting meeting event');

                        });

                        if (min > meetingReminderDuration) {

                            let notificationObj = {
                                notificationHandler: "UCHandler",
                                startDate: momentTz(`${this.state.startDate} ${this.state.startTime}`).subtract(meetingReminderDuration, 'minutes').tz("UTC").format('YYYY-MM-DD'),
                                time: momentTz(`${this.state.startDate} ${this.state.startTime}`).subtract(meetingReminderDuration, 'minutes').tz("UTC").format('HH:mm'),
                                isRecurrence: false,
                                notificationData: JSON.stringify({
                                    Message: JSON.stringify({ message_type: 'MEETING_REMINDER', message: 'Meeting Reminder', otherData: { meetingId: meetingObj.id, title: meetingObj.title, creator: meetingObj.creator, startDate: meetingObj.startDate, startTime: meetingObj.startTime } }),
                                    MessageType: 'REMINDERMEETING',
                                    MeetingCreator: getUserId()
                                }),
                                appId: "UC",
                                appReferenceId: `${meetingObj.id}`,
                                appReferenceType: `Reminder_${meetingReminderDuration}_${meetingObj.id}`,
                                createdBy: getUserId()
                            }

                            NotificationService.updateScheduleNotification(notificationObj).then(result => {

                                if (result.statusCode === 0) {
                                    message.error(result.statusMessage);
                                } else {
                                    if (result.statusCode !== 1) {
                                        message.error('Error occurred setting meeting reminder');
                                    }
                                }

                            }).catch(ex => {

                                console.error(ex);

                                message.error('Error occurred setting meeting reminder');

                            });

                        }

                        this.props.setUIState('DASHBOARD');

                    } else if (result.statusCode === 0) {
                        message.error(result.statusMessage);
                    } else {
                        message.error('Error occurred while updating meeting');
                    }

                    console.log(`Meeting update details : ${JSON.stringify(result)}`);
                }).catch(e => {
                    message.error(`Meeting Update Failed`);
                    console.log(e);
                });

            } else {

                if (this.props.my_profile) {
                    meetingObj.participants.push(new Participant({
                        participant: this.props.my_profile.userId,
                        type: 'Required',
                        participantType: this.props.my_profile.userType,
                        email: this.props.my_profile.emailId
                    }));

                    meetingClient.addNewMeeting(meetingObj).then(result => {
                        if (result.statusCode === 1) {
                            message.info(`Meeting Saved`);

                            let current = moment();
                            let meetingStartTime = moment(`${this.state.startDate} ${this.state.startTime}`);

                            let duration = moment.duration(meetingStartTime.diff(current));

                            let min = duration.asMinutes();

                            for (let p of meetingObj.participants) {
                                EmailService.SendEmail(p.email, null, 'Meeting Invitation', `You have been invited to join Meeting - ${meetingObj.title}`, `<p>You have been invited to join Meeting - <a href="${uiBaseUrl}/meeting/${result.id}/join/video">${meetingObj.title}</a></p><p>Meeting is scheduled to start on ${Utils.getLocalDate(meetingObj.startDate)} at ${Utils.getLocalTime(meetingObj.startTime)}</p>`)

                            }

                            if (this.state.configurationObj['guestEmails'] && this.state.configurationObj['guestEmails'].featureValue) {
                                for (let i = 0; i < this.state.configurationObj['guestEmails'].featureValue.length; i++) {
                                    EmailService.SendEmail(this.state.configurationObj['guestEmails'].featureValue[i], null, 'Meeting Invitation', `You have been invited to join Meeting - ${meetingObj.title}`, `<p>You have been invited to join Meeting - <a href="${uiBaseUrl}/meeting/${result.id}/join/video">${meetingObj.title}</a></p><p>Meeting is scheduled to start on ${Utils.getLocalDate(meetingObj.startDate)} at ${Utils.getLocalTime(meetingObj.startTime)}</p>`)
                                }

                            }





                            NotificationService.addInstantNotification({
                                notificationHandler: "UCHandler",
                                notificationData: JSON.stringify({
                                    Message: JSON.stringify({ message_type: 'MEETING_CREATED', message: 'New meeting created', otherData: { meetingId: result.id, title: meetingObj.title, creator: meetingObj.creator, startDate: meetingObj.startDate, startTime: meetingObj.startTime } }),
                                    MessageType: 'CREATEMEETING',
                                    MeetingCreator: getUserId(),
                                    Participants: meetingObj.participants.reduce((acc, p) => {
                                        if (p.participant !== getUserId()) {
                                            acc.push(p.participant);
                                        }
                                        return acc;
                                    }, [])
                                }),
                                appId: "UC",
                                appReferenceId: `${result.id}`,
                                appReferenceType: `MeetingCreate_${meetingReminderDuration}_${result.id}`,
                                createdBy: getUserId()
                            }).then(result => {

                                if (result.statusCode === 0) {
                                    message.error(result.statusMessage);
                                } else {
                                    if (result.statusCode !== 1) {
                                        message.error('Error occurred setting meeting event');
                                    }
                                }

                            }).catch(ex => {

                                console.error(ex);

                                message.error('Error occurred setting meeting event');

                            });

                            if (min > meetingReminderDuration) {

                                NotificationService.addScheduleNotification({
                                    notificationHandler: "UCHandler",
                                    startDate: momentTz(`${this.state.startDate} ${this.state.startTime}`).subtract(meetingReminderDuration, 'minutes').tz("UTC").format('YYYY-MM-DD'),
                                    time: momentTz(`${this.state.startDate} ${this.state.startTime}`).subtract(meetingReminderDuration, 'minutes').tz("UTC").format('HH:mm'),
                                    isRecurrence: false,
                                    notificationData: JSON.stringify({
                                        Message: JSON.stringify({ message_type: 'MEETING_REMINDER', message: 'Meeting Reminder', otherData: { meetingId: result.id, title: meetingObj.title, creator: meetingObj.creator, startDate: meetingObj.startDate, startTime: meetingObj.startTime } }),
                                        MessageType: 'REMINDERMEETING',
                                        MeetingCreator: getUserId()
                                    }),
                                    appId: "UC",
                                    appReferenceId: `${result.id}`,
                                    appReferenceType: `Reminder_${meetingReminderDuration}_${result.id}`,
                                    createdBy: getUserId()
                                }).then(result => {

                                    if (result.statusCode === 0) {
                                        message.error(result.statusMessage);
                                    } else {
                                        if (result.statusCode !== 1) {
                                            message.error('Error occurred setting meeting reminder');
                                        }
                                    }

                                }).catch(ex => {

                                    console.error(ex);

                                    message.error('Error occurred setting meeting reminder');

                                });

                            }



                            this.props.setUIState('DASHBOARD');
                        } else if (result.statusCode === 0) {
                            message.error(result.statusMessage);
                        } else {
                            message.error('Error occurred while saving meeting');
                        }

                        console.log(`Meeting save details : ${JSON.stringify(result)}`);

                    }).catch(e => {
                        message.error(`Meeting Save Failed`);
                        console.log(e);
                    });

                } else {
                    message.error(`Login user profile not found`);
                }


            }


        } catch (error) {
            console.log(error);
        }

    }

    onAddParticipants = (obj, type) => {
        try {
            if (type) {
                this.setState({
                    participants: [...this.state.participants, obj]
                });
            } else {
                const newList = this.state.participants.filter((item) => item.participant !== obj.participant);
                this.setState({ participants: newList });

            }
        } catch (error) {
            console.log(error);
        }

    }

    checkFormValidity = () => {
        return !!this.state.title
    }

    cancel = (event) => {
        // eslint-disable-next-line react/no-direct-mutation-state
        this.props.setUIState('DASHBOARD');
    }

    render() {

        return (
            <>

                {this.props.activeMenu === 'meeting' && <div className={`chat-empty-wrapper`}
                    style={{ width: this.props.viewPointWidth - ( navigationRestrictions.meeting.meeting_only? 0 : 380), height: this.props.viewPointHeight }}>
                    <form onSubmit={this.onSubmit}>
                        <div className="schedule">
                            <div className="row rectangle-436 dove-gray-border-1px m-0">
                                <div className="schedule-a-meeting sourcesanspro-semi-bold-steel-blue-22px">Schedule A Meeting</div>
                                {/* <Scrollbars
                            autoHide
                            thumbMinSize={0}
                            style={{ width: props.viewPointWidth - 380, height: props.viewPointHeight }}
                        > */}
                                <div style={{ float: "left", overflowY: "auto", height: "100vh" }}>


                                    <div className="col-md-6 float-left">
                                        <div className="form-group  pb-0 mb-4">
                                            <label className="sourcesanspro-semi-bold-steel-blue-18px" htmlFor="exampleInputEmail1">Topic</label>
                                            <input value={this.state.title} type="text" className="form-control web-rectangle ronchi-border-1px" id="Topic"
                                                aria-describedby="TopicHelp" placeholder="Enter meeting topic"
                                                name="title" onChange={this.handleChange} />
                                        </div>
                                        <div className="form-group row  m-0 pb-10">
                                            <label htmlFor="startdate" className="col-sm-2 p-0 sourcesanspro-semi-bold-steel-blue-18px">Start</label>
                                            <div className="col-sm-6 p-0">
                                                <input type="date" value={this.state.startDate} className="form-control web-rectangle ronchi-border-1px" id="startdate"
                                                    placeholder="Friday, January 01, 2021" name="startDate" onChange={this.handleChange} />
                                            </div>
                                            <div className="col-sm-4 pl-2 pr-0">
                                                <input type="Time" value={this.state.startTime} className="form-control web-rectangle ronchi-border-1px" id="startTime" name="startTime"
                                                    onChange={this.handleChange}
                                                    placeholder="10:00 AM" />
                                            </div>
                                        </div>
                                        <div className="form-group row  m-0 pb-10">
                                            <label htmlFor="DisplayView" className="col-md-2 p-0 label sourcesanspro-semi-bold-steel-blue-18px">Duration</label>
                                            <div className="select-container col-md-4 pl-2 pr-0">
                                                <select value={this.state.duration} name="duration"
                                                    onChange={this.handleChange} className="form-control web-rectangle ronchi-border-1px " id="">
                                                    {meetingDurations.split(',').map(duration => {
                                                        return (<option value={`${parseInt(duration)}`}>{`${duration} minutes`}</option>)
                                                    })}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="form-group row  m-0 pb-10 ">
                                            <label htmlFor="DisplayView" className="col-md-2 p-0 label sourcesanspro-semi-bold-steel-blue-18px">Time Zone</label>
                                            <div className="select-container col-sm-10 p-0 ">
                                                <select name="timeZone" value={this.state.timeZone} onChange={this.handleChange} className="form-control web-rectangle ronchi-border-1px " id="">
                                                    {tzNames.map(tzName => <option value={tzName}>{tzName}</option>)}
                                                    {/* momentTz.tz.zone(tzName).abbr(Date.now()) */}
                                                </select>
                                            </div>
                                            {/* <div className="form-check pt-10 pb-5">
                                                <label className="form-check-label col-sm-12">
                                                    <input className="styled-checkbox web-rectangle ronchi-border-1px" id="styled-checkbox-1" type="checkbox"
                                                        checked={this.state.isRecurrence} name="isRecurrence" onChange={this.handleChange} />
                                                    <label htmlFor="styled-checkbox-1"></label>
              Recurring Meeting
            </label>
                                            </div> */}
                                        </div>
                                        {navigationRestrictions.meeting.ext_id.active && <div className="form-group  pb-0 mb-4">
                                            <label className="sourcesanspro-semi-bold-steel-blue-18px" htmlFor="exampleInputEmail1">External Id</label>
                                            <input value={this.state.configurationObj['externalId'] ? this.state.configurationObj['externalId'].featureValue : ""} type="text" className="form-control web-rectangle ronchi-border-1px" id="ExternalId"
                                                aria-describedby="TopicHelp" placeholder="Enter external id"
                                                name="externalId" onChange={this.handleChangeOptions} />
                                        </div>}
                                        {/* <div className="form-box float-left width-100-p pt-5 pb-5 mb-1">
                                            <div className="security sourcesanspro-semi-bold-steel-blue-18px">Meeting ID</div>
                                            <label className="form-check-label col-sm-6 float-left">
                                                <input className="styled-checkbox web-rectangle ronchi-border-1px" id="styled-checkbox-33" type="checkbox"
                                                    checked={this.state.configurationObj['autoMeetingID'] && this.state.configurationObj['autoMeetingID'].featureValue === 'true'}
                                                    name="autoMeetingID" onChange={this.handleChangeOptions} />
                                                <label htmlFor="styled-checkbox-33"></label>
            Generate Automatically
          </label>
                                            <label className="form-check-label col-sm-6 float-left">
                                                <input className="styled-checkbox web-rectangle ronchi-border-1px" id="styled-checkbox-34" type="checkbox"
                                                    checked={this.state.configurationObj['personalMeetingID'] && this.state.configurationObj['personalMeetingID'].featureValue === 'true'}
                                                    name="personalMeetingID" onChange={this.handleChangeOptions} />
                                                <label htmlFor="styled-checkbox-34"></label>
            Personal Meeting ID
          </label>
                                        </div> */}
                                        {/* <div className="form-box float-left width-100-p pt-5 pb-5 mb-1">
                                            <div className="security sourcesanspro-semi-bold-steel-blue-18px">Security</div>
                                            <label className="form-check-label col-sm-12 float-left">
                                                <input className="styled-checkbox web-rectangle ronchi-border-1px float-left" id="styled-checkbox-12"
                                                    checked={this.state.configurationObj['passCodeSec'] && this.state.configurationObj['passCodeSec'].featureValue === 'true'}
                                                    type="checkbox" name="passCodeSec" onChange={this.handleChangeOptions} />
                                                <label htmlFor="styled-checkbox-12"></label>
            Passcode (Only users with the passcode can join the meeting)

          </label>
                                            <label className="form-check-label col-sm-12 float-left">
                                                <input className="styled-checkbox web-rectangle ronchi-border-1px" id="styled-checkbox-14"
                                                    checked={this.state.configurationObj['waitingRoomSec'] && this.state.configurationObj['waitingRoomSec'].featureValue === 'true'}
                                                    type="checkbox" name="waitingRoomSec" onChange={this.handleChangeOptions} />
                                                <label htmlFor="styled-checkbox-14"></label>
            Waiting Room (Only users admitted by the host can join the meeting)

          </label>
                                        </div> */}
                                        {/* <div className="form-box float-left width-100-p pt-5 pb-5 mb-1">
                                            <div className="video sourcesanspro-semi-bold-steel-blue-18px">Video</div>
                                            <div className="auto-flex4">
                                                <div className="host sourcesanspro-normal-dove-gray-16px mr-25">Host</div>
                                                <div className="w-100">
                                                    <div className="radio">
                                                        <input id="radio-1" value="on"
                                                            checked={this.state.configurationObj['videoHost'] && this.state.configurationObj['videoHost'].featureValue === "on"}
                                                            name="videoHost" type="radio" onChange={this.handleChangeOptions} />
                                                        <label htmlFor="radio-1" className="radio-label">On</label>
                                                    </div>
                                                    <div className="radio">
                                                        <input id="radio-2" value="off"
                                                            checked={this.state.configurationObj['videoHost'] && this.state.configurationObj['videoHost'].featureValue === "off"}
                                                            name="videoHost" type="radio" onChange={this.handleChangeOptions} />
                                                        <label htmlFor="radio-2" className="radio-label">Off</label>
                                                    </div>
                                                </div>

                                                <div className="participants sourcesanspro-normal-dove-gray-16px mr-25">Participants</div>
                                                <div className="w-100">
                                                    <div className="radio">
                                                        <input id="radio-3" value="on"
                                                            checked={this.state.configurationObj['videoParticipants']
                                                                && this.state.configurationObj['videoParticipants'].featureValue === "on"}
                                                            name="videoParticipants" type="radio" onChange={this.handleChangeOptions} />
                                                        <label htmlFor="radio-3" className="radio-label">On</label>
                                                    </div>
                                                    <div className="radio">
                                                        <input id="radio-4" value="off"
                                                            checked={this.state.configurationObj['videoParticipants']
                                                                && this.state.configurationObj['videoParticipants'].featureValue === "off"}
                                                            name="videoParticipants" type="radio" onChange={this.handleChangeOptions} />
                                                        <label htmlFor="radio-4" className="radio-label">Off</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-box float-left width-100-p pt-5 pb-5 mb-1">
                                            <div className="audio sourcesanspro-semi-bold-steel-blue-18px">Audio</div>
                                            <div className="auto-flex4">
                                                <div className="host sourcesanspro-normal-dove-gray-16px mr-25">Host</div>
                                                <div className="w-100">
                                                    <div className="radio">
                                                        <input id="radio-20" value="on"
                                                            checked={this.state.configurationObj['audioHost'] && this.state.configurationObj['audioHost'].featureValue === "on"}
                                                            name="audioHost" type="radio" onChange={this.handleChangeOptions} />
                                                        <label htmlFor="radio-20" className="radio-label">On</label>
                                                    </div>
                                                    <div className="radio">
                                                        <input id="radio-21" value="off"
                                                            checked={this.state.configurationObj['audioHost'] && this.state.configurationObj['audioHost'].featureValue === "off"}
                                                            name="audioHost" type="radio" onChange={this.handleChangeOptions} />
                                                        <label htmlFor="radio-21" className="radio-label">Off</label>
                                                    </div>
                                                </div>

                                                <div className="participants sourcesanspro-normal-dove-gray-16px mr-25">Participants</div>
                                                <div className="w-100">
                                                    <div className="radio">
                                                        <input id="radio-23" value="on"
                                                            checked={this.state.configurationObj['audioParticipants']
                                                                && this.state.configurationObj['audioParticipants'].featureValue === "on"}
                                                            name="audioParticipants" type="radio" onChange={this.handleChangeOptions} />
                                                        <label htmlFor="radio-23" className="radio-label">On</label>
                                                    </div>
                                                    <div className="radio">
                                                        <input id="radio-22" value="off"
                                                            checked={this.state.configurationObj['audioParticipants']
                                                                && this.state.configurationObj['audioParticipants'].featureValue === "off"}
                                                            name="audioParticipants" type="radio" onChange={this.handleChangeOptions} />
                                                        <label htmlFor="radio-22" className="radio-label">Off</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
 */}
                                        <CommentBox ref={this.comment_box} comment={this.state.configurationObj['comments'] ? this.state.configurationObj['comments'].featureValue : ""}></CommentBox>

                                        <div className="footer-btn">
                                            <button className="btn btn-primary round-b btn-save mr-10" disabled={!this.checkFormValidity()} style={{ cursor: `${!this.checkFormValidity() ? 'not-allowed' : 'pointer'}` }} type="submit">{this.props.isUpdate ? 'Update' : 'Save'}</button>
                                            <button className="btn btn-defualt round-b" onClick={this.cancel}> Cancel</button>
                                        </div>
                                        {/* <div className="form-box float-left width-100-p pt-5 pb-5 mb-1">
                                            <div className="calender sourcesanspro-semi-bold-steel-blue-18px">Calender</div>
                                            <div className="auto-flex4">

                                                <div className="w-100">
                                                    <div className="radio">
                                                        <input id="radio-6" name="calender" onChange={this.handleChange} checked={this.state.calender === 'GOOGLE'} value="GOOGLE" type="radio" />
                                                        <label htmlFor="radio-6" className="radio-label">Google</label>
                                                    </div>
                                                </div>
                                                <div className="w-100">
                                                    <div className="radio">
                                                        <input id="radio-5" name="calender" onChange={this.handleChange} checked={this.state.calender === 'OUTLOOK'} value="OUTLOOK" type="radio" />
                                                        <label htmlFor="radio-5" className="radio-label">Outlook</label>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group float-left width-100-p pt-5 pb-5 mb-1">
                                            <label className="sourcesanspro-semi-bold-steel-blue-18px" htmlFor="alternativeHosts">Alternative Hosts</label>
                                            <input type="text" className="form-control web-rectangle ronchi-border-1px" id="alternativeHosts"
                                                aria-describedby="TopicHelp" placeholder=""
                                                value={this.state.configurationObj['alternativeHosts'] && this.state.configurationObj['alternativeHosts'].featureValue}
                                                name="alternativeHosts" onChange={this.handleChangeOptions} />

                                        </div> */}
                                    </div>
                                    <div className="col-md-1 float-left"></div>
                                    <div className="col-md-5 float-left">
                                        <div className="landing-m">
                                            <img src={require("../../../assets/schedule/images/landing-msg.png")} alt="landing-msg.png" />
                                        </div>
                                        {/* <div className="group-3959 share-code">
                                            <div className="text-1 sourcesanspro-semi-bold-masala-20px">{this.state.url}</div>
                                        </div> */}
                                        <div className="share-btn float-right with-300">
                                            {/* <button type="button" onClick={() => { navigator.clipboard.writeText(this.state.url) }} className="btn btn-primary round-b btn-share mr-1"><i className="fas fa-copy"></i></button>
                                            <button type="button" onClick={this.shareLink} className="btn btn-primary round-b btn-share mr-1"><i className="fas fa-share-alt"></i></button> */}

                                            <div className="dropdown">
                                                <button id="Group-btn" onClick={this.enableSelector} type="button"
                                                    className="btn btn-primary round-b btn-share dropbtn "><i className="fa fa-users-medical"></i></button>

                                                <div id="myDropdown" className="dropdown-content">
                                                    <Participants participants={this.state.participants} onSelectParticipant={this.onAddParticipants}></Participants>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-box float-left width-100-p pt-10 pb-5 ">

                                            <div className="advanced-options-pwaQtw sourcesanspro-semi-bold-steel-blue-18px">Add Guest Emails</div>
                                            <br />
                                            <TagsInput label="New Email" tags={this.state.configurationObj['guestEmails'] ? this.state.configurationObj['guestEmails'].featureValue : []} setTags={(tags) => {
                                                this.handleChangeOptions({
                                                    target: {
                                                        name: 'guestEmails',
                                                        value: tags
                                                    }
                                                });
                                                //this.setState({ guestEmails: tags });
                                            }}></TagsInput>


                                            {/* <div className="advanced-options-pwaQtw sourcesanspro-semi-bold-steel-blue-18px">Advanced Options</div> */}
                                            {/* <label className="form-check-label col-sm-12 float-left">
                                                <input className="styled-checkbox web-rectangle ronchi-border-1px" id="styled-checkbox-17" type="checkbox"
                                                    checked={this.state.configurationObj['joinAnyTime']
                                                        && this.state.configurationObj['joinAnyTime'].featureValue === 'true'} name="joinAnyTime" onChange={this.handleChangeOptions} />
                                                <label htmlFor="styled-checkbox-17"></label>
            Allow participants to join anytime.
          </label> */}
                                            {/* <label className="form-check-label col-sm-12 float-left">
                                                <input className="styled-checkbox web-rectangle ronchi-border-1px" id="styled-checkbox-18" type="checkbox"
                                                    checked={this.state.configurationObj['muteParticipants']
                                                        && this.state.configurationObj['muteParticipants'].featureValue === 'true'} name="muteParticipants" onChange={this.handleChangeOptions} />
                                                <label htmlFor="styled-checkbox-18"></label>
            Mute participants upon entry.
          </label> */}
                                            {/* <label className="form-check-label col-sm-12 float-left">
                                                <input className="styled-checkbox web-rectangle ronchi-border-1px" id="styled-checkbox-19" type="checkbox"
                                                    checked={this.state.configurationObj['onlyAuthenticatedUsers']
                                                        && this.state.configurationObj['onlyAuthenticatedUsers'].featureValue === 'true'} name="onlyAuthenticatedUsers" onChange={this.handleChangeOptions} />
                                                <label htmlFor="styled-checkbox-19"></label>
            Only authenticated users can join.
          </label> */}
                                            {/* <label className="form-check-label col-sm-12 float-left">
                                                <input className="styled-checkbox web-rectangle ronchi-border-1px" id="styled-checkbox-20" type="checkbox"
                                                    checked={this.state.configurationObj['automaticallyRecord']
                                                        && this.state.configurationObj['automaticallyRecord'].featureValue === 'true'} name="automaticallyRecord" onChange={this.handleChangeOptions} />
                                                <label htmlFor="styled-checkbox-20"></label>
            Automatically record meeting on the local computer.
          </label> */}
                                        </div>
                                        {/* <div className="form-group float-left width-100-p pt-10 pb-5">
          <label className="sourcesanspro-semi-bold-steel-blue-18px" htmlFor="comments">Comments</label>
          <textarea type="textarea" className="form-control web-rectangle-2 ronchi-border-1px textarea-box h-120"
            id="comments" value={this.state.configurationObj['comments'] 
            && this.state.configurationObj['comments'].value} name="comments" onChange={this.handleChangeOptions} ></textarea>
        </div> */}

                                        {/* <CommentBox ref={this.comment_box} comment={this.state.configurationObj['comments'] ? this.state.configurationObj['comments'].featureValue : ""}></CommentBox>

                                        <div className="footer-btn">
                                            <button className="btn btn-primary round-b btn-save mr-10" disabled={!this.checkFormValidity()} type="submit">{this.props.isUpdate ? 'Update' : 'Save'}</button>
                                            <button className="btn btn-defualt round-b" onClick={this.cancel}> Cancel</button>
                                        </div> */}
                                    </div>

                                    {/* <div class="col-md-6 float-left">
                                    <div class="form-group  pb-0 mb-4">
                                        <label class="sourcesanspro-semi-bold-steel-blue-18px" for="exampleInputEmail1">Topic</label>
                                        <input type="text" class="form-control web-rectangle ronchi-border-1px" id="Topic" aria-describedby="TopicHelp" placeholder="TMeet Schedule Meeting.Enter email" />

                                    </div>
                                    <div class="form-group row  m-0 pb-10">
                                        <label for="startdate" class="col-sm-2 p-0 sourcesanspro-semi-bold-steel-blue-18px">Start</label>
                                        <div class="col-sm-7 p-0">
                                            <input type="date" class="form-control web-rectangle ronchi-border-1px" id="startdate" placeholder="Friday, January 01, 2021" />
                                        </div>
                                        <div class="col-sm-3 pl-2 pr-0">
                                            <input type="Time" class="form-control web-rectangle ronchi-border-1px" id="enddate" placeholder="10:00 AM" /></div>
                                    </div>
                                    <div class="form-group row  m-0 pb-10">
                                        <label for="DisplayView" class="col-md-2 p-0 label sourcesanspro-semi-bold-steel-blue-18px">Duration</label>
                                        <div class="select-container col-md-6 p-0">
                                            <select name="" class="form-control web-rectangle ronchi-border-1px " id="">
                                                <option value="Option 1">1 hour</option>
                                                <option value="Option 2"> 2 hour</option>
                                                <option value="Option 3"> 3 hour</option>
                                            </select>
                                        </div>
                                        <div class="select-container col-md-4 pl-2 pr-0">
                                            <select name="" class="form-control web-rectangle ronchi-border-1px " id="">
                                                <option value="Option 1">30 minutes</option>
                                                <option value="Option 2">40 minutes</option>
                                                <option value="Option 3">50 minutes </option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="form-group row  m-0 pb- ">
                                        <label for="DisplayView" class="col-md-2 p-0 label sourcesanspro-semi-bold-steel-blue-18px">Time Zone</label>
                                        <div class="select-container col-sm-10 p-0 ">
                                            <select name="" class="form-control web-rectangle ronchi-border-1px " id="">
                                                <option value="Option 1">Singapore</option>
                                                <option value="Option 2">India</option>
                                                <option value="Option 3">USA</option>
                                            </select>
                                        </div>
                                        <div class="form-check pt-10 pb-5">
                                            <label class="form-check-label col-sm-12">
                                                <input class="styled-checkbox web-rectangle ronchi-border-1px" id="styled-checkbox-1" type="checkbox" value="value1" />
                                                <label for="styled-checkbox-1"></label>
              Recurring Meeting
            </label>
                                        </div>
                                    </div>
                                    <div class="form-box float-left width-100-p pt-5 pb-5 mb-1">
                                        <div class="security sourcesanspro-semi-bold-steel-blue-18px">Meeting ID</div>
                                        <label class="form-check-label col-sm-6 float-left">
                                            <input class="styled-checkbox web-rectangle ronchi-border-1px" id="styled-checkbox-33" type="checkbox" value="value1" />
                                            <label for="styled-checkbox-33"></label>
              Generate Automatically
            </label>
                                        <label class="form-check-label col-sm-6 float-left">
                                            <input class="styled-checkbox web-rectangle ronchi-border-1px" id="styled-checkbox-34" type="checkbox" value="value1" />
                                            <label for="styled-checkbox-34"></label>
              Personal Meeting ID
            </label>
                                    </div>

                                    <div class="form-box float-left width-100-p pt-5 pb-5 mb-1">
                                        <div class="security sourcesanspro-semi-bold-steel-blue-18px">Security</div>
                                        <label class="form-check-label col-sm-12 float-left">
                                            <input class="styled-checkbox web-rectangle ronchi-border-1px float-left" id="styled-checkbox-12" type="checkbox" value="value1" />
                                            <label for="styled-checkbox-12"></label>
              Passcode (Only users with the passcode can join the meeting)

          </label>
                                        <label class="form-check-label col-sm-12 float-left">
                                            <input class="styled-checkbox web-rectangle ronchi-border-1px" id="styled-checkbox-14" type="checkbox" value="value1" />
                                            <label for="styled-checkbox-14"></label>
              Waiting Room (Only users admitted by the host can join the meeting)

          </label>
                                    </div>

                                    <div class="form-box float-left width-100-p pt-5 pb-5 mb-1">
                                        <div class="video sourcesanspro-semi-bold-steel-blue-18px">Video</div>
                                        <div class="auto-flex4">
                                            <div class="host sourcesanspro-normal-dove-gray-16px mr-25">Host</div>
                                            <div class="w-100">
                                                <div class="radio">
                                                    <input id="radio-1" name="host" type="radio" />
                                                    <label for="radio-1" class="radio-label">On</label>
                                                </div>
                                                <div class="radio">
                                                    <input id="radio-2" name="host" type="radio" />
                                                    <label for="radio-2" class="radio-label">Off</label>
                                                </div>
                                            </div>

                                            <div class="participants sourcesanspro-normal-dove-gray-16px mr-25">Participants</div>
                                            <div class="w-100">
                                                <div class="radio">
                                                    <input id="radio-3" name="participants2" type="radio" />
                                                    <label for="radio-3" class="radio-label">On</label>
                                                </div>
                                                <div class="radio">
                                                    <input id="radio-4" name="participants2" type="radio" />
                                                    <label for="radio-4" class="radio-label">Off</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>



                                    <div class="form-box float-left width-100-p pt-5 pb-5 mb-1">
                                        <div class="audio sourcesanspro-semi-bold-steel-blue-18px">Audio</div>
                                        <div class="auto-flex4">
                                            <div class="host sourcesanspro-normal-dove-gray-16px mr-25">Host</div>
                                            <div class="w-100">
                                                <div class="radio">
                                                    <input id="radio-20" name="audio" type="radio" />
                                                    <label for="radio-20" class="radio-label">On</label>
                                                </div>
                                                <div class="radio">
                                                    <input id="radio-21" name="audio" type="radio" />
                                                    <label for="radio-21" class="radio-label">Off</label>
                                                </div>
                                            </div>

                                            <div class="participants sourcesanspro-normal-dove-gray-16px mr-25">Participants</div>
                                            <div class="w-100">
                                                <div class="radio">
                                                    <input id="radio-23" name="participants" type="radio" />
                                                    <label for="radio-23" class="radio-label">On</label>
                                                </div>
                                                <div class="radio">
                                                    <input id="radio-22" name="participants" type="radio" />
                                                    <label for="radio-22" class="radio-label">Off</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-box float-left width-100-p pt-5 pb-5 mb-1">
                                        <div class="calender sourcesanspro-semi-bold-steel-blue-18px">Calender</div>
                                        <div class="auto-flex4">

                                            <div class="w-100">
                                                <div class="radio">
                                                    <input id="radio-6" name="radio6" type="radio" />
                                                    <label for="radio-6" class="radio-label">Google</label>
                                                </div>
                                            </div>
                                            <div class="w-100">
                                                <div class="radio">
                                                    <input id="radio-5" name="radio6" type="radio" />
                                                    <label for="radio-5" class="radio-label">Outlook</label>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group float-left width-100-p pt-5 pb-5 mb-1">
                                        <label class="sourcesanspro-semi-bold-steel-blue-18px" for="exampleInputEmail1">Alternative Hosts</label>
                                        <input type="text" class="form-control web-rectangle ronchi-border-1px" id="Topic" aria-describedby="TopicHelp" placeholder="john.m@tetherfi.com" />

                                    </div>



                                </div>
                                <div class="col-md-1 float-left"></div>
                                <div class="col-md-5 float-left">
                                    <div class="landing-m">
                                        <img src={require("../../../assets/schedule/images/landing-msg.png")} />
                                    </div>
                                    <div class="group-3959 share-code">
                                        <div class="text-1 sourcesanspro-semi-bold-masala-20px">https://meet.google.com/ibe-wrdn-dbc</div>
                                    </div>
                                    <div class="share-btn float-right with-300">
                                        <button type="button" class="btn btn-primary round-b btn-share"><i class="fas fa-copy"></i></button>
                                        <button type="button" class="btn btn-primary round-b btn-share"><i class="fas fa-share-alt"></i></button>

                                        <div class="dropdown">
                                            <button id="Group-btn" onclick="myFunction()" type="button" class="btn btn-primary round-b btn-share dropbtn "><i class="fa fa-users-medical"></i></button>

                                            <div id="myDropdown" class="dropdown-content">
                                                <div class="search-box float-left">
                                                    <fieldset class="field-container">
                                                        <input type="text" placeholder="Add Guests" class="field" />
                                                        <div class="icons-container">
                                                            <div class="icon-search"></div>
                                                            <div class="icon-close">
                                                                <div class="x-up"></div>
                                                                <div class="x-down"></div>
                                                            </div>
                                                        </div>
                                                    </fieldset>
                                                    <span class="checed-search"><i class="far fa-check-circle"></i></span>
                                                </div>
                                                <div class="group-list float-left width">
                                                    <div class="friend-drawer friend-drawer--onhover">
                                                        <img class="profile-image" src={require("../../../assets/schedule/images/Group1.png")} alt="" />
                                                        <div class="text">
                                                            <h6>Renee Olson</h6>
                                                        </div>
                                                    </div>
                                                    <div class="friend-drawer friend-drawer--onhover">
                                                        <img class="profile-image" src={require("../../../assets/schedule/images/g-3.png")} alt="" />
                                                        <div class="text">
                                                            <h6>Claude Castro</h6>
                                                        </div>
                                                    </div>
                                                    <div class="friend-drawer friend-drawer--onhover">
                                                        <img class="profile-image" src={require("../../../assets/schedule/images/g-2.png")} alt="" />
                                                        <div class="text">
                                                            <h6>Group 1</h6>
                                                        </div>
                                                    </div>
                                                    <div class="friend-drawer friend-drawer--onhover">
                                                        <img class="profile-image" src={require("../../../assets/schedule/images/g-2.png")} alt="" />
                                                        <div class="text">
                                                            <h6>Group 2</h6>
                                                        </div>
                                                    </div>
                                                    <div class="friend-drawer friend-drawer--onhover">
                                                        <img class="profile-image" src={require("../../../assets/schedule/images/g-2.png")} alt="" />
                                                        <div class="text">
                                                            <h6>Group 3</h6>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="form-box float-left width-100-p pt-10 pb-5 ">
                                        <div class="advanced-options-pwaQtw sourcesanspro-semi-bold-steel-blue-18px">Advanced Options</div>
                                        <label class="form-check-label col-sm-12 float-left">
                                            <input class="styled-checkbox web-rectangle ronchi-border-1px" id="styled-checkbox-17" type="checkbox" value="value1" />
                                            <label for="styled-checkbox-17"></label>
              Allow participants to join anytime.
            </label>
                                        <label class="form-check-label col-sm-12 float-left">
                                            <input class="styled-checkbox web-rectangle ronchi-border-1px" id="styled-checkbox-18" type="checkbox" value="value1" />
                                            <label for="styled-checkbox-18"></label>
              Mute participants upon entry.
            </label>
                                        <label class="form-check-label col-sm-12 float-left">
                                            <input class="styled-checkbox web-rectangle ronchi-border-1px" id="styled-checkbox-19" type="checkbox" value="value1" />
                                            <label for="styled-checkbox-19"></label>
             Only authenticated users can join.
            </label>
                                        <label class="form-check-label col-sm-12 float-left">
                                            <input class="styled-checkbox web-rectangle ronchi-border-1px" id="styled-checkbox-20" type="checkbox" value="value1" />
                                            <label for="styled-checkbox-20"></label>
              Automatically record meeting on the local computer.
            </label>
                                    </div>
                                    <div class="form-group float-left width-100-p pt-10 pb-5">
                                        <label class="sourcesanspro-semi-bold-steel-blue-18px" for="comments">Alternative Hosts</label>
                                        <textarea type="textarea" class="form-control web-rectangle-2 ronchi-border-1px textarea-box h-120" id="coments" > </textarea>
                                    </div>

                                    <div class="footer-btn">
                                        <button class="btn btn-primary round-b btn-save mr-10"> Save</button>
                                        <button class="btn btn-defualt round-b"> Cancel</button>
                                    </div>

                                </div>
 */}

                                </div>

                                {/* </Scrollbars> */}

                            </div>

                        </div>
                    </form>
                </div>
                }


            </>
        );

    }

}

function mapStateToProps(state) {

    return {
        my_profile: state.team[getUserId()]
    };
}

export default connect(mapStateToProps)(Meetings);
