import React from 'react';
import PropTypes from 'prop-types';
import {
    UserPlus,
    Pause,
    Mic,
    MicOff,
    PhoneOutgoing,
    Airplay,
    ArrowLeft,
    X,
    CornerUpRight,
    Download
} from "react-feather";
import { ChatUser, MenuIcon, TitleWithSmallText, UserImage, ParticipationImage } from "../index";
import navigationRestrictions from "../../../config/navigationRestrictions";


//online
//offline
//busy
//other
const MediaActionTab = ({ selectedCount, onClickGoBack, onClickCloseThis, onClickForward, onClickDownload, selectedTab }) => (
    <>
        <div className={'left-wrp d-flex align-items-center '}>
            <div>
                {selectedCount === 0 &&
                    <MenuIcon size={'x-sm'}
                        onClick={onClickGoBack}
                        icon={<ArrowLeft />} />
                }
            </div>
            <div className={'mr-2'}>
                {selectedCount > 0 &&
                    <MenuIcon size={'x-sm'}
                        onClick={onClickCloseThis}
                        icon={<X />} />
                }
            </div>
            <>
                {selectedCount > 0 &&
                    <div className={`select-item-count`}>
                        {selectedCount} selected
                    </div>
                }
            </>
        </div>

        <div className={'right-wrp d-flex align-items-center'}>
            <div className={'mr-2'}>
                {selectedCount > 0 && navigationRestrictions.chat.forwarding.active &&
                    <MenuIcon size={'x-sm'}
                        onClick={onClickForward}
                        icon={<CornerUpRight />} />
                }
            </div>
            <div>
                {selectedCount > 0 && selectedTab !== 'links' &&
                    <MenuIcon size={'x-sm'}
                        onClick={onClickDownload}
                        icon={<Download />} />
                }
            </div>
        </div>
    </>
);


MediaActionTab.defaultProps = {};

export default MediaActionTab;
