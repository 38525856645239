import * as types from "../actions/actionTypes";
import produce from "immer";

export const meetingReducer = produce((draft = {}, action) => {
    switch (action.type) {

        case types.MEETINGS_STATUSES_LOADED:
            draft = action.meetingStatusList;
            break;
        case types.START_MEETING:
            draft[action.data.meetingId] = {
                meetingId: action.data.meetingId,
                interactionId: action.data.interactionId,
                status: "Started"
            };
            break;
        case types.DELETE_MEETING:
            if (draft[action.meetingId]) {
                delete draft[action.meetingId];
            }
            break;
        default: break;
    }

    return draft;
});