import React, {
    useEffect,
    useRef,
    useState,
    forwardRef,
    useImperativeHandle
} from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
/* import ChatMessage from "../../common/timline/chatMessage";
import CallMessage from "../../common/timline/callMessage";
import MediaMessage from "../../common/timline/mediaMessage";
import FileMessage from "../../common/timline/fileMessage"; */
import moment from "moment";
import * as chatActions from "../../../redux/actions/chatActions";
import * as chatSearchActions from "../../../redux/actions/chatSearchActions";
import * as selectedProfileActions from "../../../redux/actions/selectedProfileActions";
import * as groupActions from "../../../redux/actions/groupActions";
import * as typingActions from "../../../redux/actions/typingActions";
import * as ChatService from "../../../service/chatService";
import globalFlags from "../../../utils/globalFlags";
import { fileMetaServiceUrl, __APP_INTERACTION_MODE__, __APP_TYPING_STOP_TIMER_ENABLED__ } from "../../../config/baseUrls";
import { imageTypes } from "../../../config/json/fileTypeMap";
/* import { ChannelFactory } from "../../../service/signaling_sdk"; */
import { getLoggedInUser, getWorkspace, getUserId } from "../../../utils";
//import EmptyChat from "../chat/template/EmptyChat";
import alertSound from "../../../assets/mp3/alert.mp3";
import liveChatClient from "../../../lib";
import * as eventEmitter from "../../../utils/eventEmitter";
import renderState from "../../../service/renderStateHandler";
import * as fileService from "../../../service/fileService";
import uuid from 'uuid/v1';
import * as auth from "../../../utils";
import { checkReceiveSequence } from "../../../utils/messageSequenceHandler";
import {
    setLastTypingEvent,
    hideTyping,
    isTypingStopped,
    resetLastTypingEvent,
    getLastTypingEventTime,
    resetKeyPressTime
} from "../../../service/typingDetector";
import { WEB_NOTIFICATION, PHONE_NOTIFICATION } from '../../../redux/actions/actionTypes';
import { getWbState } from '../Whiteboard/wbState';

import {
    ProfileImage,
    ChatLeftReplay,
    ChatRightReplay,
    ChatDateSeparate,
    ChatMessage,
    ChatLeftDocument,
    ChatRightDocument,
    ChatMultipleMedia,
    ChatLeftVideo,
    ChatRightVideo,
    ChatCenterMessage,
    ChatUnknownTypeMessage,
    ChatRightAudio,
    ChatLeftAudio,
    Typing,
    ChatPreLoader,
    ChatImage,
    ChatRightMultipleMedia,
    CoBrowsingCenterMessage
} from "../../common";
import { Video, PhoneMissed, Airplay, Repeat, Triangle, Layout } from "react-feather";
import ScrollAnimation from "react-animate-on-scroll";
import navigationRestrictions from "../../../config/navigationRestrictions";

import { useDispatch } from "react-redux";
import { onShowNotification } from "../../../redux/actions/webNotificationActions";
import * as logger from "../../../lib/logSdk/logger";
import { filterProfanity } from "../../../utils/profanityFilter";
import { notification } from "antd/lib/index";

let localDataTimeline = {
    days_remaining: []
};

let scrollValuesGlobal = null;

const getChatUser = username => {
    return async (dispatch, getState) => {
        const state = getState(username);
        return state.chats[username];
    };
};

const isScrollBarAtBottom = (scrollValues) => {
    if (scrollValues.scrollTop < 50) {
        return scrollValues.top > 0.92;
    } else if (scrollValues.scrollTop >= 50 && scrollValues.scrollTop < 100) {
        return scrollValues.top > 0.98;
    } else if (scrollValues.scrollTop >= 100 && scrollValues.scrollTop < 200) {
        return scrollValues.top > 0.985;
    } else if (scrollValues.scrollTop >= 200 && scrollValues.scrollTop < 500) {
        return scrollValues.top > 0.99;
    } else if (scrollValues.scrollTop >= 500 && scrollValues.scrollTop < 1000) {
        return scrollValues.top > 0.995;
    } else if (scrollValues.scrollTop >= 1000 && scrollValues.scrollTop < 10000) {
        return scrollValues.top > 0.999;
    } else if (scrollValues.scrollTop >= 10000) {
        return scrollValues.top > 0.9999;
    }

    return false;

}


let uploadedMedia = [];

const ChatPanelComponent = forwardRef((props, ref) => {
    const dispatchNotification = useDispatch();

    const [typingIndicatorTimers, setTypingIndicatorTimers] = useState({});

    function onPressChatFilterIcon(_filter) {
        console.log("event fire...");
        console.log(_filter);
    }

    const relatedMessageClick = ({ mid, created_at }) => {

        let chatElement = document.getElementById(mid);
        if (chatElement) {
            chatElement.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
        } else {
            //load from history
            let userId = getUserId();

            props.chat_search_actions.getInitialSearchMessages(props.selected_profile.conversation_type, userId, props.selected_profile.username, created_at, 20);
        }
    }

    const starMessage = (message, isStarring) => {
        if (message) {
            let from = message.sender_id;
            if (message.from && message.from.id) {
                from = message.from.id;
            }
            props.chat_actions.starUnstarMessage(from, message, isStarring, props.selected_profile.username);
        }

    }

    const resendMessage = (message) => {
        if (message) {
            props.chat_actions.resendMessage(message);
        }
    }

    const sendForwardMessages = (users, msgObj) => {

        let profanity = filterProfanity(msgObj.message_content);

        if (profanity.isProfane) {
            notification.error({
                message: `Message Blocked`,
                description:
                    `Your message : "${profanity.message}", is flagged by profanity filter and cannot be sent`,
                placement: 'bottomRight',
                style: {
                    backgroundColor: "#ff751a",
                    color: "White"
                },
            });
            ChatService.sendProfanityMessage(msgObj.message_content, props.selected_profile.InteractionId);

        } else {

            for (let user of users) {
                let uniqueId = uuid();

                let toId = user.conversation_type === 3 ? user.Id : user.userId;

                let msg = {
                    v: 1,
                    mid: uniqueId,
                    sid: uniqueId,
                    channel: "WEBCHAT",
                    message_type: msgObj.message_type,
                    related_message: msgObj,
                    message_action: 3,
                    from: {
                        id: auth.getUserId(),
                        name: auth.getDisplayName()
                    },
                    to: {
                        id: toId,
                        name: toId
                    },
                    created_at: Date.now(),
                    message_content: msgObj.message_content,
                    other: null,
                    media: msgObj.media,
                    isUploading: "DONE"
                };

                msg.conversation_type = user.conversation_type;
                ChatService.sendChatMessage(user.conversation_type, msgObj.message_content, toId, msg, false, user.InteractionId);
                props.chat_actions.sendNewMessageSuccess(msg);

                if (user.userId === props.selected_profile.username) {
                    eventEmitter.fireEvent("scrollbar", { scrollbar_status: "down" });
                }

                if (msgObj.media) {
                    msgObj.media.forEach(media => {
                        let file_id = null;
                        if (media.mimeType.startsWith("image") && media.thumbnail_url) {
                            let match = media.thumbnail_url.match(new RegExp("stream/thumbnail/" + "(.*)" + "/225"));
                            if (match && match.length === 2) {
                                file_id = match[1];
                            }
                        } else {
                            let match = media.content_url.match(new RegExp("stream/media/" + "(.*)"));
                            if (match && match.length === 2) {
                                file_id = match[1];
                            }

                            /* https://mediastreamer.qa.tetherfi.cloud/stream/media/72bb0770-71d3-11eb-8cab-dd494d3ccd25 */

                        }
                        fileService.tagConversationToFile(user.conversation_type === 3 ? "" : msg.from.id, msg.to.id, getWorkspace(), file_id, media.content_url, media.mimeType, media.content_size, media.content_title);

                    });
                }

                if (msgObj.message_type === "html/link") {
                    fileService.saveLink(msg.conversation_type === 3 ? "" : msg.from.id, msg.to.id, msgObj.message_content, getWorkspace(), uniqueId);
                }
            }

        }

    }

    const onDownload = async (mediaFiles) => {

        mediaFiles.forEach(file => {

            let filename = file.content_title ? file.content_title : "file";

            fileService.directDownloadFile(filename, file.content_url);

        })
        /* if (url) {
            const response = await fileService.directDownloadFile(filename, url);
        } */
    };

    useImperativeHandle(ref, () => ({
        onDownArrowClick() {
            //if on search mode revert back to normal
            if (localDataTimeline.search_mode) {
                renderState.focusToLastRead = true;
                renderState.scrollReadyState = false;
                props.chat_search_actions.resetSearchMessages();
            }

            props.getScrollbarTimeline().scrollToBottom();

        },
        onScrollStop() {
            let scrollbarTL = props.getScrollbarTimeline();

            let values = scrollbarTL ? scrollbarTL.getValues() : null;
            if (values.top === 0) {
                //load more data
                if (
                    localDataTimeline.search_mode &&
                    localDataTimeline.prev_search_timestamp
                ) {
                    props.chat_search_actions.getBeforeSearchMessages(
                        localDataTimeline.conversation_type,
                        getUserId(),
                        localDataTimeline.selected_user,
                        localDataTimeline.prev_search_timestamp,
                        50
                    );
                } else {
                    if (localDataTimeline.prev_timestamp) {
                        props.chat_actions.getOldMessages(
                            "tetherfi",
                            localDataTimeline.conversation_type,
                            getUserId(),
                            localDataTimeline.selected_user,
                            localDataTimeline.prev_timestamp + 1,
                            50,
                            false
                        );
                    }
                }
            } else if (isScrollBarAtBottom(values)) {
                /* } else if (values.top > 0.99) { */
                //check last message received by other part is seen
                if (
                    localDataTimeline.search_mode &&
                    localDataTimeline.next_search_timestamp
                ) {
                    props.chat_search_actions.getAfterSearchMessages(
                        localDataTimeline.conversation_type,
                        getUserId(),
                        localDataTimeline.selected_user,
                        localDataTimeline.next_search_timestamp,
                        50
                    );
                } else {
                    if (localDataTimeline.selected_user && localDataTimeline.unseen_count) {
                        props.store_actions
                            .getChatUser(localDataTimeline.selected_user)
                            .then(chatUser => {
                                if (chatUser) {
                                    if (chatUser.messages[chatUser.last_message_received] && chatUser.messages[chatUser.last_message_received].message_type === 1) {
                                        //call
                                        props.chat_actions.sendSeenStatusSuccess(
                                            localDataTimeline.selected_user,
                                            chatUser.last_message_received
                                        );
                                    } else {
                                        //chat

                                        if (localDataTimeline.unseen_messages && localDataTimeline.unseen_messages.length > 0) {

                                            ChatService.sendSeenStatus(
                                                localDataTimeline.conversation_type,
                                                2,
                                                getUserId(),
                                                localDataTimeline.selected_user,
                                                chatUser.unseen_messages,
                                                chatUser.messages
                                            );

                                        }

                                        let lastMessage = null;

                                        let msgArr = Object.keys(chatUser.messages);
                                        if (msgArr) {
                                            lastMessage = msgArr[msgArr.length - 1];
                                        }

                                        props.chat_actions.sendSeenStatusSuccess(
                                            localDataTimeline.selected_user,
                                            lastMessage
                                        );
                                    }
                                }
                            });
                    }
                }
            }

        }
    }));

    console.log("======== RENDER - TIMELINE =======");

    const [audio, setAudio] = useState(new Audio(alertSound));

    let scrollbarTL = props.getScrollbarTimeline();
    scrollValuesGlobal = scrollbarTL ? scrollbarTL.getValues() : null;

    /* if (document.visibilityState === "visible" && scrollValues && isScrollBarAtBottom(scrollValues) && props.chat && props.chat.chat_render_event === 'RECEIVE_NEW_MESSAGE') {
        renderState.scrollDownOnMessage = true;
    }
    
    if (props.typing_status && props.typing_status.is_typing && scrollValues && isScrollBarAtBottom(scrollValues)) {
        renderState.scrollDownOnTyping = true;
    } */

    const mounted = useRef();
    useEffect(() => {
        if (!mounted.current) {
            mounted.current = true;
        } else {
            if (
                localDataTimeline.selected_user &&
                localDataTimeline.prev_user != localDataTimeline.selected_user && props.activeMenu === 'chat'
            ) {
                if (!localDataTimeline.search_mode) {

                    let scrollbarTL = props.getScrollbarTimeline();

                    /* if (renderState.scrollDownOnMessage && document.visibilityState === "visible") {
                        renderState.scrollDownOnMessage = false;
                        scrollbarTL.scrollToBottom();
                    } */

                    ////////


                    let scrollValues = scrollbarTL ? scrollbarTL.getValues() : null;

                    if (
                        scrollValues &&
                        document.visibilityState === "visible" && props.chat &&
                        props.chat.unseen_count &&
                        props.chat.unseen_messages &&
                        props.chat.unseen_messages.length > 0
                    ) {
                        if (
                            isScrollBarAtBottom(scrollValues) ||
                            (scrollValues.top === 0 && props.chat.messages && scrollValues.clientHeight === scrollValues.scrollHeight)
                        ) {

                            ChatService.sendSeenStatus(
                                props.selected_profile.conversation_type,
                                2,
                                getUserId(),
                                props.selected_profile.username,
                                props.chat.unseen_messages,
                                props.chat.messages
                            );

                            let lastMessage = null;

                            if (props.chat.unseen_messages && props.chat.unseen_messages.length > 0) {
                                let msgArr = Object.keys(props.chat.messages);

                                if (msgArr) {
                                    lastMessage = msgArr[msgArr.length - 1];
                                }
                            }

                            props.chat_actions.sendSeenStatusSuccess(props.selected_profile.username, lastMessage);
                        }
                    }
                    ////////

                    if (renderState.focusToLastRead) {
                        renderState.focusToLastRead = false;

                        let chatElement = document.getElementById(
                            localDataTimeline.last_seen_anchor_point
                        );
                        if (chatElement) {
                            chatElement.scrollIntoView({ block: "center", inline: "nearest" });
                        }
                    }

                    if (renderState.scrollReadyState) {
                        renderState.scrollReadyState = false;
                        let chatElement = document.getElementById(
                            localDataTimeline.next_page_anchor_point
                        );
                        if (chatElement) {
                            chatElement.scrollIntoView();
                        }
                    }
                } else {
                    let chatElement = document.getElementById(
                        localDataTimeline.search_anchor_point
                    );
                    if (chatElement) {
                        if (localDataTimeline.is_initial_search) {
                            chatElement.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
                        } else {
                            chatElement.scrollIntoView({ block: localDataTimeline.anchor_position });
                        }

                    }
                }
            }
        }
    }, [props.chat, props.selected_profile, props.search_messages, props.getScrollbarTimeline, props.whiteboard.wbState]);

    const mounted2 = useRef();
    useEffect(() => {
        if (!mounted2.current) {
            mounted2.current = true;
        } else {
            if (
                localDataTimeline.selected_user &&
                localDataTimeline.prev_user != localDataTimeline.selected_user
            ) {
                if (!localDataTimeline.search_mode) {

                    let scrollbarTL = props.getScrollbarTimeline();

                    if (props.typing_status && props.typing_status.is_typing && scrollValuesGlobal && isScrollBarAtBottom(scrollValuesGlobal)) {
                        scrollbarTL.scrollToBottom();
                    }

                    /* if (renderState.scrollDownOnTyping) {
                        renderState.scrollDownOnTyping = false;
                        scrollbarTL.scrollToBottom();
                    } */

                }
            }
        }
    }, [props.typing_status]);

    const mounted3 = useRef();
    useEffect(() => {
        if (!mounted3.current) {
            mounted3.current = true;
        } else {
            if (
                localDataTimeline.selected_user &&
                localDataTimeline.prev_user != localDataTimeline.selected_user
            ) {
                if (!localDataTimeline.search_mode) {

                    let scrollbarTL = props.getScrollbarTimeline();

                    if (document.visibilityState === "visible" && scrollValuesGlobal && isScrollBarAtBottom(scrollValuesGlobal) && props.chat && props.chat.chat_render_event === 'RECEIVE_NEW_MESSAGE') {
                        scrollbarTL.scrollToBottom();
                    }

                    /* if (renderState.scrollDownOnMessage && document.visibilityState === "visible") {
                        renderState.scrollDownOnMessage = false;
                        scrollbarTL.scrollToBottom();
                    } */

                }
            }
        }
    }, [props.chat]);

    useEffect(() => {
        return () => {
            for(let [key, value] of Object.entries(typingIndicatorTimers)){
                clearTimeout(value);
            }
        }
    }, [])
    const addRefUploadFinished = ref_id => {
        uploadedMedia.push(ref_id);
    };

    let timelineUi = <></>;

    /* let timelineUi = <h1>No chats to show screen....</h1>; */
    let arrowCounter = <></>;

    if (props.chat && props.chat.is_loaded && props.chat.messages && Object.keys(props.chat.messages).length === 0) {
        //show no chats screen
        timelineUi = <div className={`chat-empty-wrapper`}>

            <div className={'chat-empty-inner-wrapper'}>

                <div className={`chat-empty-img-wrp message-empty-wrp`}>
                    <ScrollAnimation delay="450"
                        animateIn="fadeInUp"
                        animateOut="fadeOutDown">
                        <img src={require("../../../assets/img/messageEmpty.svg")}
                            className={'messageEmpty'}
                            alt="User Avatar" />
                    </ScrollAnimation>
                    <div className={'bubble-set chat-empty-msg one'}>
                        <div className={'bubble'} />
                        <div className={'bubble'} />
                    </div>
                    <div className={'bubble-set chat-empty-msg two'}>
                        <div className={'bubble'} />
                        <div className={'bubble'} />
                    </div>
                </div>


                <div className={'empty-message-1 small-gray'}>
                    {props.selected_profile.conversation_type === 3 ? 'You have not messaged on this group yet' : 'You have not messaged this person yet'}
                </div>
                <div className={'empty-message-2 small-gray'}>
                    Start by typing something on the text box below
                </div>
            </div>


            {/*<ScrollAnimation delay="450"*/}
            {/*    animateIn="fadeIn"*/}
            {/*    animateOut="fadeDown">*/}
            {/*    <div className={'empty-chat-bg'}>*/}
            {/*        <img src={require("../../../assets/img/bgChat.svg")}*/}
            {/*            alt="User Avatar" />*/}
            {/*    </div>*/}
            {/*</ScrollAnimation>*/}

        </div>;

    } else {

        if (props.chat) {
            let loggedUser = getUserId();

            if (props.selected_profile) {
                localDataTimeline.selected_user = props.selected_profile.username;
                localDataTimeline.selected_user_interaction = props.selected_profile.InteractionId;
                localDataTimeline.conversation_type =
                    props.selected_profile.conversation_type;
            }
            if (props.chat) {
                localDataTimeline.unseen_count = props.chat.unseen_count;
                localDataTimeline.last_message_received = props.chat.last_message_received;
                localDataTimeline.last_message_seen = props.chat.last_message_seen;
            }

            let lastSeenMessageId = null;
            let dayMap = {};

            let chatDisplay = null;

            if (localDataTimeline.search_mode) {
                chatDisplay =
                    props.search_messages && props.search_messages.messages
                        ? props.search_messages.messages
                        : null;
            } else {
                chatDisplay =
                    props.chat && props.chat.messages ? props.chat.messages : null;
            }

            if (
                chatDisplay &&
                props.selected_profile &&
                props.selected_profile.username
            ) {
                //find last seen
                let nextDate = null;
                Object.keys(chatDisplay).forEach(key => {
                    let msgDate = moment
                        .unix(chatDisplay[key].created_at / 1000)
                        .format("DD-MM-YYYY");
                    if (nextDate !== msgDate) {
                        nextDate = msgDate;
                        dayMap[key] = nextDate;
                    }

                    if (chatDisplay[key].is_seen || chatDisplay[key].status === 2) {
                        lastSeenMessageId = key;
                    }
                });

                let separator = null;
                let isSeenMessageFound = false;
                let previousMessage = null;
                let nextMessage = null;

                let arr = Object.keys(chatDisplay);

                if (arr.length > 0) {
                    timelineUi = arr.map((key, index) => {

                        let nextMessageKey = arr[index + 1];

                        if (nextMessageKey) {
                            nextMessage = chatDisplay[nextMessageKey];
                        }

                        let isUnseen = false;

                        if (lastSeenMessageId === key) {
                            isSeenMessageFound = true;
                        }

                        if (isSeenMessageFound && lastSeenMessageId !== key) {
                            isUnseen = true;
                        }

                        let direction = "receive";
                        if (loggedUser === chatDisplay[key].from.id) {
                            direction = "send";
                        }

                        if (dayMap[key]) {
                            separator = <ChatDateSeparate date={dayMap[key]} />;
                        } else {
                            separator = null;
                        }

                        let uiElement = <></>;

                        let showImage = true;
                        let showTime = true;

                        let time = navigationRestrictions.chat.show_full_date ? moment
                            .unix(chatDisplay[key].created_at / 1000)
                            .format("YYYY/MM/DD h:mm:ss A") : moment
                                .unix(chatDisplay[key].created_at / 1000)
                                .format("h:mm A");

                        if (
                            previousMessage &&
                            previousMessage.from.id === chatDisplay[key].from.id
                        ) {
                            showImage = false;

                            let prevMsgTime = navigationRestrictions.chat.show_full_date ? moment
                                .unix(previousMessage.created_at / 1000)
                                .format("YYYY/MM/DD h:mm:ss A") : moment
                                    .unix(previousMessage.created_at / 1000)
                                    .format("h:mm A");

                            if (prevMsgTime !== time) {
                                showImage = true;
                            }
                        }

                        if (nextMessage) {
                            let nextMessageTime = navigationRestrictions.chat.show_full_date ? moment
                                .unix(nextMessage.created_at / 1000)
                                .format("YYYY/MM/DD h:mm:ss A") : moment
                                    .unix(nextMessage.created_at / 1000)
                                    .format("h:mm A");

                            if (nextMessageTime === time) {
                                showTime = false;
                            }

                            if (nextMessage.from.id !== chatDisplay[key].from.id) {
                                showTime = true;
                            }
                        }

                        let fromTeamMember = null;
                        let fullNameReply = "";
                        if (props.selected_profile.conversation_type === 4 && props.interactions && props.interactions[props.selected_profile.username] && props.selected_profile.InteractionInfo && __APP_INTERACTION_MODE__ === 'USER') {
                            fromTeamMember = props.interactions[props.selected_profile.username].UserDetails[chatDisplay[key].from.id];
                        }
                        if (props.team) {
                            if (!fromTeamMember && chatDisplay[key].from.id) {
                                fromTeamMember = props.team[chatDisplay[key].from.id];
                            }

                            if (chatDisplay[key].message_action === 2 && chatDisplay[key].related_message && chatDisplay[key].related_message.from && chatDisplay[key].related_message.from.id) {

                                let fromTeamMemberReply = null;

                                if (props.selected_profile.conversation_type === 4 && props.interactions && props.interactions[props.selected_profile.username] && props.selected_profile.InteractionInfo && __APP_INTERACTION_MODE__ === 'USER') {
                                    fromTeamMemberReply = props.interactions[props.selected_profile.username].UserDetails[chatDisplay[key].related_message.from.id];
                                } else {
                                    fromTeamMemberReply = props.team[chatDisplay[key].related_message.from.id];
                                }
                                if (fromTeamMemberReply) {
                                    fullNameReply = `${fromTeamMemberReply.firstName} ${fromTeamMemberReply.lastName ? fromTeamMemberReply.lastName : ""}`
                                }


                            }
                        }

                        let fullName =
                            fromTeamMember && fromTeamMember.firstName && fromTeamMember.lastName
                                ? `${fromTeamMember.firstName} ${fromTeamMember.lastName}`
                                : (fromTeamMember && fromTeamMember.firstName ? fromTeamMember.firstName : chatDisplay[key].from.id);

                        if (!fromTeamMember && chatDisplay[key].from.id === auth.getUserId()) {
                            fullName = auth.getDisplayName();
                        }



                        if (chatDisplay[key].message_action === 2 && chatDisplay[key].related_message) {

                            //add reply template

                            let relatedUrl = "";

                            if (chatDisplay[key].related_message.media && chatDisplay[key].related_message.media.length > 0) {
                                relatedUrl = chatDisplay[key].related_message.media[0].content_url;
                            }

                            if (direction === "send") {
                                uiElement = (
                                    <ChatRightReplay
                                        mid={key}
                                        relatedMessageType={chatDisplay[key].related_message.message_type}
                                        relatedMessageMediaSrc={relatedUrl}
                                        msgObj={chatDisplay[key]}
                                        fullName={showImage ? fullName : ""}
                                        isImage={showImage}
                                        fullNameReply={fullNameReply}
                                        lastSeen={time}
                                        src={fromTeamMember && fromTeamMember.avatarURL ? fromTeamMember.avatarURL : null}
                                        lastSeenStatus={chatDisplay[key].is_seen || chatDisplay[key].status === 2 ? true : false}
                                        /* lastSeenStatus={lastSeenMessageId === key ? true : false} */
                                        msg={chatDisplay[key].message_content}
                                        isLink={chatDisplay[key].message_type === "html/link"}
                                        onClickReplayMessage={props.onClickReplayMessage}
                                        sendForwardMessages={sendForwardMessages}
                                        onClickForwardMessage={props.onClickForwardMessage}
                                        relatedMessageClick={relatedMessageClick}
                                        onClickStar={starMessage}
                                        isStarred={chatDisplay[key].is_starred}
                                        onMessageResendPressed={resendMessage}
                                    />
                                );
                            } else {
                                uiElement = (
                                    <ChatLeftReplay
                                        mid={key}
                                        relatedMessageType={chatDisplay[key].related_message.message_type}
                                        relatedMessageMediaSrc={relatedUrl}
                                        msgObj={chatDisplay[key]}
                                        fullName={showImage ? fullName : ""}
                                        isImage={showImage}
                                        src={fromTeamMember && fromTeamMember.avatarURL ? fromTeamMember.avatarURL : null}
                                        fullNameReply={fullNameReply}
                                        lastSeen={time}
                                        lastSeenStatus={lastSeenMessageId === key ? true : false}
                                        msg={chatDisplay[key].message_content}
                                        isLink={chatDisplay[key].message_type === "html/link"}
                                        onClickReplayMessage={props.onClickReplayMessage}
                                        sendForwardMessages={sendForwardMessages}
                                        onClickForwardMessage={props.onClickForwardMessage}
                                        relatedMessageClick={relatedMessageClick}
                                        onClickStar={starMessage}
                                        isStarred={chatDisplay[key].is_starred}
                                        conversation_type={props.selected_profile.conversation_type}
                                    />
                                );
                            }

                        } else {

                            if (chatDisplay[key].media && chatDisplay[key].media.length > 1) {

                                uiElement = (
                                    <ChatMultipleMedia
                                        mid={key}
                                        direction={direction}
                                        msgObj={chatDisplay[key]}
                                        fullName={showImage ? fullName : ""}
                                        isImage={showImage}
                                        lastSeen={time}
                                        isForwarded={chatDisplay[key].message_action === 3}
                                        lastSeenStatus={chatDisplay[key].is_seen || chatDisplay[key].status === 2 ? true : false}
                                        msg={chatDisplay[key].message_content}
                                        imgDesc={chatDisplay[key].message_content}
                                        src={fromTeamMember && fromTeamMember.avatarURL ? fromTeamMember.avatarURL : null}
                                        onDownloadClick={onDownload}
                                        onClickOpenGallery={props.onClickOpenGallery}
                                        mediaFiles={chatDisplay[key].media}
                                        onClickReplayMessage={props.onClickReplayMessage}
                                        sendForwardMessages={sendForwardMessages}
                                        onClickForwardMessage={props.onClickForwardMessage}
                                        onClickStar={starMessage}
                                        isStarred={chatDisplay[key].is_starred}
                                        conversation_type={props.selected_profile.conversation_type}
                                        onMessageResendPressed={resendMessage}
                                    />
                                );

                            } else {

                                switch (chatDisplay[key].message_type) {
                                    case 1: {
                                        let timeDuration = "";

                                        if (chatDisplay[key].updatedAt && chatDisplay[key].status === 200) {
                                            let startTime = moment.unix(chatDisplay[key].created_at / 1000);
                                            let endTime = moment(chatDisplay[key].updatedAt);
                                            let diff = endTime.diff(startTime);
                                            let duration = moment.duration(diff);

                                            timeDuration = `${timeDuration} ${duration.hours() ? `${duration.hours()} hours` : ""
                                                }`;
                                            timeDuration = `${timeDuration} ${duration.minutes() ? ` ${duration.minutes()} minutes` : ""
                                                }`;
                                            timeDuration = `${timeDuration} ${duration.seconds() ? ` ${duration.seconds()} seconds` : ""
                                                }`;
                                            timeDuration = `call lasted ${timeDuration}`;
                                        }

                                        let fromTeamMember = null;
                                        if (props.team) {
                                            if (chatDisplay[key].from.id) {
                                                fromTeamMember = props.team[chatDisplay[key].from.id];
                                            }
                                        }

                                        break;
                                    }
                                    case "document/pdf":
                                    case "application/pdf":
                                    case "text/csv":
                                    case "text/plain":
                                    case "application/msword":
                                    case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
                                    case "application/vnd.ms-excel":
                                    case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": {
                                        let content_url = "";
                                        let filename = "";

                                        if (chatDisplay[key].media && chatDisplay[key].media.length > 0) {
                                            content_url = chatDisplay[key].media[0].content_url;
                                            filename = chatDisplay[key].media[0].content_title ? chatDisplay[key].media[0].content_title : "document";
                                        }
                                        if (direction === "send") {
                                            uiElement = (
                                                <ChatRightDocument
                                                    mid={key}
                                                    msgObj={chatDisplay[key]}
                                                    fullName={showImage ? fullName : ""}
                                                    isImage={showImage}
                                                    lastSeen={time}
                                                    isForwarded={chatDisplay[key].message_action === 3}
                                                    src={fromTeamMember && fromTeamMember.avatarURL ? fromTeamMember.avatarURL : null}
                                                    lastSeenStatus={chatDisplay[key].is_seen || chatDisplay[key].status === 2 ? true : false}
                                                    /* lastSeenStatus={lastSeenMessageId === key ? true : false} */
                                                    msg={chatDisplay[key].message_content}
                                                    contentUrl={content_url}
                                                    filename={filename}
                                                    onDownloadClick={onDownload}
                                                    onClickOpenGallery={props.onClickOpenGallery}
                                                    mediaFiles={chatDisplay[key].media}
                                                    onClickReplayMessage={props.onClickReplayMessage}
                                                    sendForwardMessages={sendForwardMessages}
                                                    onClickForwardMessage={props.onClickForwardMessage}
                                                    onClickStar={starMessage}
                                                    isStarred={chatDisplay[key].is_starred}
                                                    fileType={chatDisplay[key].message_type}
                                                    onMessageResendPressed={resendMessage}
                                                />
                                            );
                                        } else {
                                            uiElement = (
                                                <ChatLeftDocument
                                                    mid={key}
                                                    msgObj={chatDisplay[key]}
                                                    fullName={showImage ? fullName : ""}
                                                    isImage={showImage ? time : null}
                                                    lastSeen={time}
                                                    isForwarded={chatDisplay[key].message_action === 3}
                                                    src={fromTeamMember && fromTeamMember.avatarURL ? fromTeamMember.avatarURL : null}
                                                    msg={chatDisplay[key].message_content}
                                                    contentUrl={content_url}
                                                    filename={filename}
                                                    onDownloadClick={onDownload}
                                                    onClickOpenGallery={props.onClickOpenGallery}
                                                    mediaFiles={chatDisplay[key].media}
                                                    onClickReplayMessage={props.onClickReplayMessage}
                                                    sendForwardMessages={sendForwardMessages}
                                                    onClickForwardMessage={props.onClickForwardMessage}
                                                    onClickStar={starMessage}
                                                    isStarred={chatDisplay[key].is_starred}
                                                    conversation_type={props.selected_profile.conversation_type}
                                                    fileType={chatDisplay[key].message_type}
                                                />
                                            );
                                        }
                                        break;
                                    }
                                    case "text/html":
                                    case "html/link":
                                    case 5: {
                                        uiElement = (
                                            <ChatMessage
                                                mid={key}
                                                direction={direction}
                                                msgObj={chatDisplay[key]}
                                                fullName={showImage ? fullName : ""}
                                                isImage={showImage}
                                                lastSeen={time}
                                                isForwarded={chatDisplay[key].message_action === 3}
                                                src={fromTeamMember && fromTeamMember.avatarURL ? fromTeamMember.avatarURL : null}
                                                lastSeenStatus={chatDisplay[key].is_seen || chatDisplay[key].status === 2 ? true : false}
                                                msg={chatDisplay[key].message_content}
                                                isLink={chatDisplay[key].message_type === "html/link"}
                                                onClickReplayMessage={props.onClickReplayMessage}
                                                sendForwardMessages={sendForwardMessages}
                                                onClickForwardMessage={props.onClickForwardMessage}
                                                onClickStar={starMessage}
                                                onMessageResendPressed={resendMessage}
                                                isStarred={chatDisplay[key].is_starred}
                                                conversation_type={props.selected_profile.conversation_type}

                                            />
                                        );
                                        break;
                                    }
                                    case 3: {
                                        let fromTeamMember = null;
                                        if (props.team) {
                                            if (chatDisplay[key].from.id) {
                                                fromTeamMember = props.team[chatDisplay[key].from.id];
                                            }
                                        }

                                        break;
                                    }
                                    case "video/mp4":
                                    case "video/avi":
                                    case "video/webm": {
                                        let content_url = "";
                                        let filename = "";
                                        let mimeType = "";

                                        if (chatDisplay[key].media && chatDisplay[key].media.length > 0) {
                                            content_url = chatDisplay[key].media[0].content_url;
                                            filename = chatDisplay[key].media[0].content_title ? chatDisplay[key].media[0].content_title : "video_file";
                                        }

                                        if (direction === "send") {
                                            uiElement = (
                                                <ChatRightVideo
                                                    mid={key}
                                                    msgObj={chatDisplay[key]}
                                                    fullName={showImage ? fullName : ""}
                                                    isImage={showImage}
                                                    lastSeen={time}
                                                    isForwarded={chatDisplay[key].message_action === 3}
                                                    lastSeenStatus={chatDisplay[key].is_seen || chatDisplay[key].status === 2 ? true : false}
                                                    /* lastSeenStatus={lastSeenMessageId === key ? true : false} */
                                                    msg={chatDisplay[key].message_content}
                                                    imgDesc={chatDisplay[key].message_content}
                                                    playing={false}
                                                    controls={true}
                                                    src={fromTeamMember && fromTeamMember.avatarURL ? fromTeamMember.avatarURL : null}
                                                    contentUrl={content_url}
                                                    filename={filename}
                                                    onDownloadClick={onDownload}
                                                    onClickOpenGallery={props.onClickOpenGallery}
                                                    mediaFiles={chatDisplay[key].media}
                                                    onClickReplayMessage={props.onClickReplayMessage}
                                                    sendForwardMessages={sendForwardMessages}
                                                    onClickForwardMessage={props.onClickForwardMessage}
                                                    onClickStar={starMessage}
                                                    isStarred={chatDisplay[key].is_starred}
                                                    isDelivered={chatDisplay[key].is_delivered}
                                                    is_sent_successfull={chatDisplay[key].is_sent_successfull}
                                                    onMessageResendPressed={resendMessage}
                                                />
                                            );
                                        } else {
                                            uiElement = (
                                                <ChatLeftVideo
                                                    mid={key}
                                                    msgObj={chatDisplay[key]}
                                                    fullName={showImage ? fullName : ""}
                                                    isImage={showImage ? time : null}
                                                    lastSeen={time}
                                                    isForwarded={chatDisplay[key].message_action === 3}
                                                    msg={chatDisplay[key].message_content}
                                                    imgDesc={chatDisplay[key].message_content}
                                                    playing={false}
                                                    controls={true}
                                                    src={fromTeamMember && fromTeamMember.avatarURL ? fromTeamMember.avatarURL : null}
                                                    contentUrl={content_url}
                                                    filename={filename}
                                                    onDownloadClick={onDownload}
                                                    onClickOpenGallery={props.onClickOpenGallery}
                                                    mediaFiles={chatDisplay[key].media}
                                                    onClickReplayMessage={props.onClickReplayMessage}
                                                    sendForwardMessages={sendForwardMessages}
                                                    onClickForwardMessage={props.onClickForwardMessage}
                                                    onClickStar={starMessage}
                                                    isStarred={chatDisplay[key].is_starred}
                                                    conversation_type={props.selected_profile.conversation_type}
                                                />
                                            );
                                        }
                                        break;
                                    }
                                    case "audio/webm":
                                    case "audio/mp3":
                                    case "audio/mp4":
                                    case "audio/mpeg":
                                    case "audio/webm;codecs=opus": {
                                        let content_url = "";
                                        let filename = "";

                                        if (chatDisplay[key].media && chatDisplay[key].media.length > 0) {
                                            content_url = chatDisplay[key].media[0].content_url;
                                            filename = chatDisplay[key].media[0].content_title ? chatDisplay[key].media[0].content_title : "audio_file";
                                        }

                                        if (direction === "send") {
                                            uiElement = (
                                                <ChatRightAudio
                                                    audioSrc={content_url}
                                                    msgObj={chatDisplay[key]}
                                                    audioType={chatDisplay[key].channel_type}
                                                    lastSeenStatus={chatDisplay[key].is_seen || chatDisplay[key].status === 2 ? true : false}
                                                    /* lastSeenStatus={lastSeenMessageId === key ? true : false} */
                                                    mid={key}
                                                    isForwarded={chatDisplay[key].message_action === 3}
                                                    fullName={showImage ? fullName : ""}
                                                    isImage={showImage}
                                                    lastSeen={time}
                                                    msg={chatDisplay[key].message_content}
                                                    contentUrl={content_url}
                                                    filename={filename}
                                                    src={fromTeamMember && fromTeamMember.avatarURL ? fromTeamMember.avatarURL : null}
                                                    onDownloadClick={onDownload}
                                                    onClickOpenGallery={props.onClickOpenGallery}
                                                    mediaFiles={chatDisplay[key].media}
                                                    onClickReplayMessage={props.onClickReplayMessage}
                                                    sendForwardMessages={sendForwardMessages}
                                                    onClickForwardMessage={props.onClickForwardMessage}
                                                    onClickStar={starMessage}
                                                    isStarred={chatDisplay[key].is_starred}
                                                    isDelivered={chatDisplay[key].is_delivered}
                                                    is_sent_successfull={chatDisplay[key].is_sent_successfull}
                                                    onMessageResendPressed={resendMessage}
                                                />
                                            );
                                        } else {
                                            uiElement = (
                                                <ChatLeftAudio
                                                    audioSrc={content_url}
                                                    msgObj={chatDisplay[key]}
                                                    audioType={chatDisplay[key].channel_type}
                                                    isLastSeen={lastSeenMessageId === key ? true : false}
                                                    mid={key}
                                                    isForwarded={chatDisplay[key].message_action === 3}
                                                    fullName={showImage ? fullName : ""}
                                                    isImage={showImage}
                                                    lastSeen={time}
                                                    msg={chatDisplay[key].message_content}
                                                    contentUrl={content_url}
                                                    filename={filename}
                                                    src={fromTeamMember && fromTeamMember.avatarURL ? fromTeamMember.avatarURL : null}
                                                    onDownloadClick={onDownload}
                                                    onClickOpenGallery={props.onClickOpenGallery}
                                                    mediaFiles={chatDisplay[key].media}
                                                    onClickReplayMessage={props.onClickReplayMessage}
                                                    sendForwardMessages={sendForwardMessages}
                                                    onClickForwardMessage={props.onClickForwardMessage}
                                                    onClickStar={starMessage}
                                                    isStarred={chatDisplay[key].is_starred}
                                                    conversation_type={props.selected_profile.conversation_type}
                                                />
                                            );
                                        }
                                        break;

                                    }
                                    case 4:
                                    case "image/jpeg":
                                    case "image/jpg":
                                    case "image/png":
                                    case "image/bmp":
                                    case "SIGNATURE_SNAPSHOT":
                                    case "SELFVIEW_SNAPSHOT": {
                                        let thumbnail_url = "";
                                        let content_url = "";
                                        let filename = "";

                                        if (chatDisplay[key].media && chatDisplay[key].media.length > 0) {
                                            thumbnail_url = chatDisplay[key].media[0].thumbnail_url;
                                            content_url = chatDisplay[key].media[0].content_url;
                                            filename = chatDisplay[key].media[0].content_title ? chatDisplay[key].media[0].content_title : "image_file";
                                        }

                                        /* if (direction === "send") {
                                            
                                        } else {
                                            uiElement = (
                                                <ChatLeftImage
                                                    mid={key}
                                                    msgObj={chatDisplay[key]}
                                                    fullName={showImage ? fullName : ""}
                                                    isImage={showImage}
                                                    lastSeen={time}
                                                    msg={chatDisplay[key].message_content}
                                                    imgDesc={chatDisplay[key].message_content}
                                                    imgThumb={thumbnail_url}
                                                    contentUrl={content_url}
                                                    filename={filename}
                                                    src={fromTeamMember && fromTeamMember.avatarURL ? fromTeamMember.avatarURL : null}
                                                    onDownloadClick={onDownload}
                                                    onClickOpenGallery={props.onClickOpenGallery}
                                                    mediaFiles={chatDisplay[key].media}
                                                    onClickReplayMessage={props.onClickReplayMessage}
                                                    sendForwardMessages={sendForwardMessages}
                                                    onClickForwardMessage={props.onClickForwardMessage}
                                                    onClickStar={starMessage}
                                                    isStarred={chatDisplay[key].is_starred}
                                                    conversation_type={props.selected_profile.conversation_type}
                                                />
                                            );
                                        } */

                                        uiElement = (
                                            <ChatImage
                                                mid={key}
                                                direction={direction}
                                                msgObj={chatDisplay[key]}
                                                fullName={showImage ? fullName : ""}
                                                isImage={showImage}
                                                lastSeen={time}
                                                isForwarded={chatDisplay[key].message_action === 3}
                                                lastSeenStatus={chatDisplay[key].is_seen || chatDisplay[key].status === 2 ? true : false}
                                                msg={chatDisplay[key].message_content}
                                                imgDesc={chatDisplay[key].message_content}
                                                imgThumb={thumbnail_url}
                                                contentUrl={content_url}
                                                filename={filename}
                                                src={fromTeamMember && fromTeamMember.avatarURL ? fromTeamMember.avatarURL : null}
                                                onDownloadClick={onDownload}
                                                onClickOpenGallery={props.onClickOpenGallery}
                                                mediaFiles={chatDisplay[key].media}
                                                onClickReplayMessage={props.onClickReplayMessage}
                                                sendForwardMessages={sendForwardMessages}
                                                onClickForwardMessage={props.onClickForwardMessage}
                                                onClickStar={starMessage}
                                                isStarred={chatDisplay[key].is_starred}
                                                conversation_type={props.selected_profile.conversation_type}
                                                onMessageResendPressed={resendMessage}
                                            />
                                        );
                                        break;
                                    }
                                    case "10": {
                                        let fromTeamMember = null;
                                        if (props.team) {
                                            if (chatDisplay[key].from.id) {
                                                fromTeamMember = props.team[chatDisplay[key].from.id];
                                            }
                                        }

                                        let to = props.selected_profile;

                                        let url = URL.createObjectURL(chatDisplay[key].audioBlob);
                                        //create view element
                                        let viewArray = [{
                                            content_url: url,
                                            thumbnail_url: url,
                                            mimeType: chatDisplay[key].audioBlob.type.split(';')[0],
                                            created_at: chatDisplay[key].created_at,
                                            content_size: chatDisplay[key].audioBlob.size
                                        }];

                                        if (direction === "send") {
                                            uiElement = (
                                                <ChatRightAudio
                                                    audioSrc={url}
                                                    audioType={chatDisplay[key].channel_type}
                                                    isDelivered={chatDisplay[key].is_delivered}
                                                    is_sent_successfull={chatDisplay[key].is_sent_successfull}
                                                    lastSeenStatus={chatDisplay[key].is_seen || chatDisplay[key].status === 2 ? true : false}
                                                    /* lastSeenStatus={lastSeenMessageId === key ? true : false} */
                                                    isUpload={
                                                        uploadedMedia.filter(item => item === chatDisplay[key].mid)
                                                            .length === 0
                                                    }
                                                    audioBlob={chatDisplay[key].audioBlob}
                                                    mid={key}
                                                    fullName={showImage ? fullName : ""}
                                                    isImage={showImage}
                                                    lastSeen={time}
                                                    toUser={to}
                                                    isForwarded={chatDisplay[key].message_action === 3}
                                                    uploadMsg={'uploading...'}
                                                    src={fromTeamMember && fromTeamMember.avatarURL ? fromTeamMember.avatarURL : null}
                                                    msg={chatDisplay[key].message_content}
                                                    conversation_id={chatDisplay[key].conversation_id}
                                                    addRefUploadFinished={addRefUploadFinished}
                                                    onClickReplayMessage={props.onClickReplayMessage}
                                                    onClickStar={starMessage}
                                                    isStarred={chatDisplay[key].is_starred}
                                                    onDownloadClick={onDownload}
                                                    mediaFiles={viewArray}
                                                    contentUrl={url}
                                                    onClickForwardMessage={props.onClickForwardMessage}
                                                    sendForwardMessages={sendForwardMessages}
                                                    onClickOpenGallery={props.onClickOpenGallery}
                                                    onMessageResendPressed={resendMessage}
                                                //fileId={props.chat.messages[key].mid}
                                                //content_url={props.chat.messages[key].thumbUrl}
                                                // channel_type={
                                                //     props.chat.messages[key]
                                                //         ? props.chat.messages[key].channel_type
                                                //         : ""
                                                // }
                                                //channel={props.chat.messages[key].channel}
                                                //thumbnailBlob={props.chat.messages[key].thumbnailBlob}
                                                //duration={props.chat.messages[key].duration}
                                                //direction={direction}
                                                />
                                            );
                                        }
                                        break;
                                    }
                                    case "11": {
                                        let fromTeamMember = null;
                                        if (props.team) {
                                            if (chatDisplay[key].from.id) {
                                                fromTeamMember = props.team[chatDisplay[key].from.id];
                                            }
                                        }

                                        let to = props.selected_profile;

                                        if (direction === "send") {
                                            let vidUrl = URL.createObjectURL(chatDisplay[key].videoBlob);
                                            //create view element
                                            let viewArray = [{
                                                content_url: vidUrl,
                                                thumbnail_url: vidUrl,
                                                mimeType: chatDisplay[key].videoBlob.type.split(';')[0],
                                                created_at: chatDisplay[key].created_at,
                                                content_size: chatDisplay[key].videoBlob.size
                                            }];

                                            uiElement = (
                                                <ChatRightVideo
                                                    contentUrl={chatDisplay[key].vidUrl}
                                                    isDelivered={chatDisplay[key].is_delivered}
                                                    is_sent_successfull={chatDisplay[key].is_sent_successfull}
                                                    lastSeenStatus={chatDisplay[key].is_seen || chatDisplay[key].status === 2 ? true : false}
                                                    /* lastSeenStatus={lastSeenMessageId === key ? true : false} */
                                                    isUpload={
                                                        uploadedMedia.filter(item => item === chatDisplay[key].mid)
                                                            .length === 0
                                                    }
                                                    controls={true}
                                                    playing={false}
                                                    videoBlob={chatDisplay[key].videoBlob}
                                                    mid={key}
                                                    fullName={showImage ? fullName : ""}
                                                    isImage={showImage}
                                                    lastSeen={time}
                                                    toUser={to}
                                                    isForwarded={chatDisplay[key].message_action === 3}
                                                    uploadMsg={'uploading...'}
                                                    src={fromTeamMember && fromTeamMember.avatarURL ? fromTeamMember.avatarURL : null}
                                                    msg={chatDisplay[key].message_content}
                                                    channel={chatDisplay[key].channel}
                                                    conversation_id={chatDisplay[key].conversation_id}
                                                    addRefUploadFinished={addRefUploadFinished}
                                                    onClickReplayMessage={props.onClickReplayMessage}
                                                    onClickOpenGallery={props.onClickOpenGallery}
                                                    mediaFiles={viewArray}
                                                    onClickStar={starMessage}
                                                    isStarred={chatDisplay[key].is_starred}
                                                    onDownloadClick={onDownload}
                                                    onClickForwardMessage={props.onClickForwardMessage}
                                                    sendForwardMessages={sendForwardMessages}
                                                    onMessageResendPressed={resendMessage}
                                                />
                                            );
                                        }
                                        break;
                                    }
                                    case "CHAT_MISSED_AUDIO_CALL":
                                    case "CHAT_MISSED_VIDEO_CALL":
                                    case "CHAT_MISSED_SCREEN_SHARING": {
                                        uiElement = (
                                            <ChatCenterMessage
                                                selected_profile={props.selected_profile}
                                                msgObj={chatDisplay[key]}
                                                OnClickOutGoingCall={() => {
                                                    try {
                                                        props.makeCall(chatDisplay[key].message_type);
                                                    } catch (error) {
                                                        console.error("chatPanel", "OnClickOutGoingCall", error);
                                                        logger.error("chatPanel", "OnClickOutGoingCall", error.message);
                                                    }
                                                }}
                                                title={"Call Details"}
                                                icon={chatDisplay[key].message_type === "CHAT_MISSED_AUDIO_CALL" ? <PhoneMissed /> : (chatDisplay[key].message_type === "CHAT_MISSED_VIDEO_CALL" ? <Video /> : <Airplay />)}
                                                msg={chatDisplay[key].from.id === loggedUser ? `You ${chatDisplay[key].message_action === -9 ? 'Rejected' : 'Missed'} Call From ${props.selected_profile.displayName} at ${time}` : `${props.selected_profile.displayName} Not Answer The Call at ${time}`}
                                                messageType={chatDisplay[key].message_type === "CHAT_MISSED_AUDIO_CALL" ? "audio" : "video"}
                                                enableCallBackFeature={(chatDisplay[key].message_type === "CHAT_MISSED_AUDIO_CALL" || chatDisplay[key].message_type === "CHAT_MISSED_VIDEO_CALL")}
                                            />
                                        );
                                        break;
                                    }
                                    case "TRANSFER": {
                                        uiElement = (
                                            <ChatCenterMessage
                                                selected_profile={props.selected_profile}
                                                msgObj={chatDisplay[key]}
                                                title={"Chat Transfered"}
                                                icon={<Repeat />}
                                                msg={chatDisplay[key].message_content}
                                            />
                                        );
                                        break;
                                    }
                                    case "COBROWSE_REQUEST": {
                                        uiElement = (
                                            <ChatCenterMessage
                                                selected_profile={props.selected_profile}
                                                msgObj={chatDisplay[key]}
                                                title={"Co-Browse Request"}
                                                icon={<Layout />}
                                                msg={`Co-Browse initiated by ${fullName}`}
                                            />
                                        );
                                        break;
                                    }
                                    case "WHITEBOARD_REQUEST": {
                                        uiElement = (
                                            <ChatCenterMessage
                                                selected_profile={props.selected_profile}
                                                msgObj={chatDisplay[key]}
                                                title={"Whiteboard Notification"}
                                                icon={<Triangle />}
                                                msg={`${fullName} joined the session`}
                                            />
                                        );
                                        break;
                                    }
                                    default: {
                                        uiElement = (<ChatUnknownTypeMessage msgObj={chatDisplay[key]} />);
                                        break;
                                    }

                                }

                            }


                        }

                        previousMessage = chatDisplay[key];

                        return (
                            <>
                                {separator}
                                {uiElement}
                            </>
                        );
                    });
                }
            }
        }

    }

    useEffect(() => {

        setInterval(() => {
            if (isTypingStopped() && localDataTimeline.selected_user) {
                //send stopTyping
                resetKeyPressTime(
                    localDataTimeline.conversation_type,
                    getUserId(),
                    localDataTimeline.selected_user,
                    localDataTimeline.selected_user_interaction,
                    localDataTimeline.selected_profile.isACW
                );
            }
        }, 5000);

        const uiStatusHandler = {
            onVisible: () => {
                let scrollBarTimeline = props.getScrollbarTimeline();
                let scrollValues = scrollBarTimeline
                    ? scrollBarTimeline.getValues()
                    : null;
                let loggedUser = getUserId();

                if (
                    !localDataTimeline.search_mode &&
                    scrollValues &&
                    (isScrollBarAtBottom(scrollValues) || scrollValues.clientHeight === scrollValues.scrollHeight) &&
                    localDataTimeline.selected_user &&
                    loggedUser &&
                    localDataTimeline.unseen_count &&
                    localDataTimeline.unseen_messages &&
                    localDataTimeline.unseen_messages.length > 0
                ) {
                    props.store_actions
                        .getChatUser(localDataTimeline.selected_user)
                        .then(chatUser => {
                            if (chatUser) {
                                if (
                                    chatUser.messages[chatUser.last_message_received] &&
                                    chatUser.messages[chatUser.last_message_received]
                                        .message_type === 1
                                ) {
                                    //call
                                    props.chat_actions.sendSeenStatusSuccess(
                                        localDataTimeline.selected_user,
                                        chatUser.last_message_received
                                    );
                                } else {
                                    //chat
                                    ChatService.sendSeenStatus(
                                        localDataTimeline.conversation_type,
                                        2,
                                        loggedUser,
                                        localDataTimeline.selected_user,
                                        localDataTimeline.unseen_messages,
                                        chatUser.messages
                                    );

                                    let lastMessage = null;

                                    if (
                                        chatUser.unseen_messages &&
                                        chatUser.unseen_messages.length > 0
                                    ) {
                                        let msgArr = Object.keys(chatUser.messages);

                                        if (msgArr) {
                                            lastMessage = msgArr[msgArr.length - 1];
                                        }
                                    }
                                    props.chat_actions.sendSeenStatusSuccess(
                                        localDataTimeline.selected_user,
                                        lastMessage
                                    );
                                }
                            }
                        });
                }
            }
        };

        eventEmitter.subscribeToEvents("scrollbar", data => {
            let scrollbarTL = props.getScrollbarTimeline();
            if (data.scrollbar_status === "down") {
                setTimeout(() => scrollbarTL.scrollToBottom(), 250);
                //scrollBarTimeline.scrollToBottom();
            }
        });

        liveChatClient.subscribeEvent("chat", "onUserMessage", (msg, to) => {
            let message = JSON.parse(msg);

            let user = localDataTimeline.team && message.from.id ? localDataTimeline.team[message.from.id] : null;

            let fullName = user && user.firstName ? `${user.firstName} ${user.lastName ? user.lastName : ""}` : message.from.id;
            if ((document.visibilityState !== "visible" || !globalFlags.__FOCUSSED__) && navigationRestrictions.chat.alerts.active) {
                try {
                    //play sound
                    if (audio) {
                        audio.play();
                    } else {
                        new Audio(alertSound).play();
                    }
                } catch (error) {
                    console.error("chatPanel", "onUserMessage", "play sound", error);
                }

                dispatchNotification(onShowNotification({
                    action_type: WEB_NOTIFICATION,
                    message: `You Are Receiving a Message From ${fullName}`,
                    duration: 5
                }))
            }

            if (!getWbState() && (message.message_type === 'COBROWSE_REQUEST' || message.message_type === 'WHITEBOARD_REQUEST')) {

                eventEmitter.fireEvent(message.message_type === 'COBROWSE_REQUEST' ? 'cobrowse_events' : 'whiteboard_events', { fullName, from: message.conversation_type === 0 ? message.from.id : message.to.id });

            }

            let panelId = message.conversation_type === 4 ? message.to.id : message.from.id;

            //CHECK SEQUENCING
            let sequenceResult = checkReceiveSequence(panelId, message.conversation_type === 4 ? message.from.id : null, message);

            if (message.mid) {

                let scrollBarTimeline = props.getScrollbarTimeline();
                let scrollValues = scrollBarTimeline
                    ? scrollBarTimeline.getValues()
                    : null;
                if (localDataTimeline.selected_user === message.from.id) {
                    //reset typing
                    resetLastTypingEvent();
                }

                if (sequenceResult.load_history) {
                    //MAKE UI UNRESPONSIVE
                    //LOAD FROM HISTORY

                    eventEmitter.fireEvent("disable_ui", null);

                    ChatService.getUnreadMessages("tetherfi", message.conversation_type, getUserId(), panelId, sequenceResult.created_at, 50, null)
                        .then(chats => {
                            eventEmitter.fireEvent("enable_ui", null);

                            let curMessage = {};
                            curMessage[message.mid] = message;

                            let missingMessages = { ...chats.messages, ...curMessage };
                            let isBottom = isScrollBarAtBottom(scrollValues);

                            Object.entries(missingMessages).forEach(missingMsg => {

                                props.chat_actions.receiveNewMessage(missingMsg[1], true, isBottom);

                            });


                        }).catch(ex => {
                            eventEmitter.fireEvent("enable_ui", null);
                            props.chat_actions.receiveNewMessage(message, true, isScrollBarAtBottom(scrollValues));
                            console.log(ex);
                        });
                } else {

                    props.chat_actions.receiveNewMessage(message, true, isScrollBarAtBottom(scrollValues));

                }

                if (localDataTimeline.selected_user === panelId) {

                    if (document.visibilityState !== "visible") {
                        props.showArrowOnEvent();
                    }
                }

            }

        });

        liveChatClient.subscribeEvent("chat", "onGroupMessageReceived", msg => {
            if (document.visibilityState !== "visible") {
                try {
                    //play sound
                    if (audio) {
                        audio.play();
                    } else {
                        new Audio(alertSound).play();
                    }
                } catch (error) {
                    console.error("chatPanel", "onGroupMessageReceived", "play sound", error);
                }


                let user = localDataTimeline.team && msg.from.id ? localDataTimeline.team[msg.from.id] : null;

                let fullName = user && user.firstName && user.lastName ? `${user.firstName} ${user.lastName}` : msg.from.id;

                let group = localDataTimeline.chat_group && msg.from.id ? localDataTimeline.chat_group[msg.to.id] : null;

                let groupName = group && group.Name ? group.Name : msg.to.id;
                dispatchNotification(onShowNotification({
                    action_type: WEB_NOTIFICATION,
                    message: `Group ${groupName} Receiving a message from ${fullName}`,
                    duration: 5
                }));
            }

            if (msg.mid) {
                let scrollBarTimeline = props.getScrollbarTimeline();
                let scrollValues = scrollBarTimeline
                    ? scrollBarTimeline.getValues()
                    : null;
                if (localDataTimeline.selected_user === msg.to.id) {
                    //reset typing
                    resetLastTypingEvent();
                }
                props.chat_actions.receiveNewMessage(msg, true, isScrollBarAtBottom(scrollValues));
                //props.my_clientele_actions.addNewClientForChat(message);
                /* if (document.visibilityState === "visible" && scrollValues && isScrollBarAtBottom(scrollValues)) {
                    scrollBarTimeline.scrollToBottom();
                } */

                if (localDataTimeline.selected_user === msg.to.id) {

                    if (document.visibilityState !== "visible") {
                        props.showArrowOnEvent();
                    }
                }
            }
        });

        liveChatClient.subscribeEvent("chat", "onTypingState", (state, from) => {
            let message = state;

            let userId = message.from;

            if (message.conversation_type === 3 || message.conversation_type === 4) {
                userId = message.to;
            }

            if (message.status === "START") {
                if (localDataTimeline.selected_user === userId) {
                    let scrollBarTimeline = props.getScrollbarTimeline();
                    let scrollValues = scrollBarTimeline
                        ? scrollBarTimeline.getValues()
                        : null;
                    if (scrollValues && isScrollBarAtBottom(scrollValues)) {
                        renderState.scrollDownOnMessage = true;
                        //scrollBarTimeline.scrollToBottom();
                    }
                }

                props.typing_actions.setTyping({
                    user_id: userId,
                    typing_user: message.from
                });


                if (__APP_TYPING_STOP_TIMER_ENABLED__==="YES") {
                    if(typingIndicatorTimers[userId]){
                        clearTimeout(typingIndicatorTimers[userId]);
                        setTypingIndicatorTimers(previous=>{
                            let {[userId]: omit, ...res} = previous
                                return res;
                        })
                    }
    
    
                    let typingReceTimer=setTimeout(()=>{
                        props.typing_actions.removeTyping({ user_id: userId });	
                    } , 8000);
    
                    setTypingIndicatorTimers(previous=>{
                        return {...previous, [userId] : typingReceTimer};
                    })
                }


            } else {
                //might need to implement who stopped typing on group scenario
                props.typing_actions.removeTyping({ user_id: userId });

                if (__APP_TYPING_STOP_TIMER_ENABLED__==="YES") {
                    if(typingIndicatorTimers[userId]){
                        clearTimeout(typingIndicatorTimers[userId]);
                        setTypingIndicatorTimers(previous=>{
                            let {[userId]: omit, ...res} = previous
                                return res;
                        })
                    }
                }
            }


            /* if (message.conversation_type === 0) {
                            if (!getLastTypingEventTime() && localDataTimeline.selected_user && localDataTimeline.selected_user === message.from) {
                                setLastTypingEvent();
    
                                props.typing_actions.setTyping({ user_id: localDataTimeline.selected_user, typing_user: localDataTimeline.selected_user });
                                //setShowTyping(true);
                            }
                        } */
        });



        liveChatClient.subscribeEvent("chat", "onReadRecipt", recipt => {
            let status = JSON.parse(recipt);

            let chatThreadUserHandle = (status.conversation_type === 3 || status.conversation_type === 4) ? status.to.id : status.from.id;

            if (chatThreadUserHandle && status.mid && status.seen_at) {
                props.chat_actions.setRemotePartySeen(
                    chatThreadUserHandle,
                    status.seen_at,
                    status.mid
                );
            }
        });

        liveChatClient.subscribeEvent("chat", "onDeliveryRecipt", recipt => {
            let content = JSON.parse(recipt);

            if (content.to && content.mid) {
                props.chat_actions.setMessageDeliveredState(
                    content.to.id,
                    content.mid
                );
            }
        });

        document.addEventListener("visibilitychange", event => {
            if (document.visibilityState === "hidden") {
                console.log("Hidden");
            } else if (document.visibilityState === "visible") {
                uiStatusHandler.onVisible();
            }
        });
    }, [typingIndicatorTimers]);

    //if last_message_seen = last_message_received
    //do nothing
    //else
    //if last_message_received is not null
    //update seen status with last_message_received id
    //else
    //do nothing    

    if (props.chat && props.chat.unseen_count) {
        arrowCounter = (
            <div className="count-wrapper d-flex-center">
                {props.chat.unseen_count}
            </div>
        );
    }

    return (
        <>
            {props.activeMenu === 'chat' && (props.whiteboard.wbState === 'CLOSED' || props.whiteboard.wbState === 'MINIMIZED') && <main
                className={`msger-chat`}>
                {timelineUi}
                {/* <CoBrowsingCenterMessage
                    msg={'Sarab requess to view & control your current tab'}
                    title={'Request co-browsing '}
                    allowed={false}
                    icon={<Layout/>}
                />
                <CoBrowsingCenterMessage
                    allowed={true}
                    msg={'Sarab requess to view & control your current tab'}
                    title={'Request co-browsing '}
                    icon={<Layout/>}
                /> */}
                {props.selected_profile && props.typing_status && props.typing_status.is_typing && props.selected_profile.isACW !== true && <Typing isImage={true} />}
            </main>}
        </>
    );
});

ChatPanelComponent.propTypes = {
    chat: PropTypes.object,
    selected_profile: PropTypes.object.isRequired,
    chat_actions: PropTypes.object.isRequired,
    selected_profile_actions: PropTypes.object.isRequired,
    typing_actions: PropTypes.object.isRequired
};

function mapStateToProps(state) {
    let mapState = {
        selected_profile: state.selected_profile,
        team: state.team,
        search_messages: state.chat_search_messages,
        chat_group: state.chat_group.GroupListInfo,
        interactions: state.interactions,
        whiteboard: state.whiteboard
        //typing_status: state.typing_status
    };

    localDataTimeline.team = state.team;
    localDataTimeline.chat_group = state.chat_group.GroupListInfo;

    localDataTimeline.search_mode = state.chat_search_messages.search_mode;
    localDataTimeline.prev_search_timestamp =
        state.chat_search_messages.previous_timestamp;
    localDataTimeline.next_search_timestamp =
        state.chat_search_messages.next_timestamp;

    localDataTimeline.search_anchor_point =
        state.chat_search_messages.search_anchor_point;

    localDataTimeline.anchor_position =
        state.chat_search_messages.anchor_position ? state.chat_search_messages.anchor_position : "center";

    localDataTimeline.is_initial_search = state.chat_search_messages.is_initial_search;

    if (state.selected_profile && state.selected_profile.username) {
        //check if initial load
        mapState.chat = state.chats[state.selected_profile.username];
        localDataTimeline.selected_profile = state.selected_profile;

        if (state.chats[state.selected_profile.username]) {
            localDataTimeline.prev_timestamp =
                state.chats[state.selected_profile.username].prev_timestamp;
            localDataTimeline.unseen_count =
                state.chats[state.selected_profile.username].unseen_count;
            localDataTimeline.unseen_messages =
                state.chats[state.selected_profile.username].unseen_messages;
            localDataTimeline.chat_render_event =
                state.chats[state.selected_profile.username].chat_render_event;
            localDataTimeline.last_seen_anchor_point =
                state.chats[state.selected_profile.username].last_seen_anchor_point;
            localDataTimeline.next_page_anchor_point =
                state.chats[state.selected_profile.username].next_page_anchor_point;
        }
    }

    if (state.selected_profile && state.selected_profile.username) {
        mapState.typing_status = state.typing_status[state.selected_profile.username];
    }

    return mapState;
}

function mapDispatchToProps(dispatch) {
    return {
        chat_actions: bindActionCreators(chatActions, dispatch),
        chat_search_actions: bindActionCreators(chatSearchActions, dispatch),
        group_actions: bindActionCreators(groupActions, dispatch),
        store_actions: bindActionCreators({ getChatUser }, dispatch),
        typing_actions: bindActionCreators(typingActions, dispatch),
        selected_profile_actions: bindActionCreators(
            selectedProfileActions,
            dispatch
        )
    };
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
    forwardRef: true
})(ChatPanelComponent);
