import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from "react-router-dom";
import {
    MediaActionTab,
    MediaImage,
    MediaThumbnail,
    MediaVideo,
    MediaDocument,
    MediaAudio,
    MediaLinks, GalleryEmptyPage
} from '../../common';
import { connect } from "react-redux";
import { Link, Route, Switch } from "react-router-dom";
import uuid from 'uuid/v1';

import MediaFile from "../MediaFile/MediaFile";
import Documents from "../Documents/Documents";
import Links from "../Links/Links";
import mime from "mime-types";
import * as auth from "../../../utils";
import * as ChatService from "../../../service/chatService";
import { bindActionCreators } from "redux";
import * as chatActions from "../../../redux/actions/chatActions";
import moment from 'moment';
import * as eventEmitter from "../../../utils/eventEmitter";
import { getUserId, getWorkspace } from "../../../utils";
import * as fileService from "../../../service/fileService";
import navigationRestrictions from "../../../config/navigationRestrictions";
import { fileMetaServiceUrl } from "../../../config/baseUrls";
import * as logger from "../../../lib/logSdk/logger";


const DEF_LOAD_COUNT = 50;

let media = {
    media: {},
    documents: {},
    links: {}
};
let selectedCount = 0;
let nextPageTimestamp = 0;
let selected_user_profile = "";
let api_call_status = false;

function MediaPanel(props) {
    // const [media, setMedia] = useState({
    //     "aaaa": {
    //         id: "aaaa",
    //         url: 'https://tetherfi.cloud:3022/stream/media/jayz1111',
    //         thumbnail: 'https://tetherfi.cloud:3022/stream/thumbnail/jayz1111/225',
    //         title: "Title Undefined",
    //         isSelected: false,
    //         mimeType: 'image/png',
    //         common_type: getCommonType('image/png'),
    //         shared_by: "User Undefined",
    //         size: "Size Undefined",
    //         sizeBytes: 500,
    //         shared_date: "Date Undefined",
    //     },
    //     "bbbb": {
    //         id: "bbbb",
    //         url: 'https://tetherfi.cloud:3022/stream/media/sach1',
    //         thumbnail: '',
    //         title: "Title Undefined",
    //         isSelected: false,
    //         mimeType: 'application/pdf',
    //         common_type: getCommonType('application/pdf'),
    //         shared_by: "User Undefined",
    //         size: "Size Undefined",
    //         sizeBytes: 500,
    //         shared_date: "Date Undefined",
    //     },
    //     "ccc": {
    //         id: "ccc",
    //         url: 'https://google.lk',
    //         thumbnail: '',
    //         title: "Title Undefined",
    //         isSelected: false,
    //         mimeType: 'html/link',
    //         common_type: getCommonType('html/link'),
    //         shared_by: "User Undefined",
    //         size: "Size Undefined",
    //         sizeBytes: 500,
    //         shared_date: "Date Undefined",
    //     }
    // });
    //const [media, setMedia] = useState({});
    //const [selectedCount, setSelectedCount] = useState(0);
    ///let location = window.location && window.location.pathname.toString().split('/');
    const [location, setLocation] = useState(window.location && window.location.pathname.toString().split('/'));
    const [rerenderId, setRerenderId] = useState(0);

    let loc = useLocation();
    let history = useHistory();

    useEffect(() => {
        async function loadData() {
            if (selected_user_profile && selected_user_profile !== props.selected_profile.username) {
                onClickGoBack();
            } else {
                let myUserId = auth.getUserId();
                if (props.team && props.team[myUserId]) {

                    if (selected_user_profile !== props.selected_profile.username) {
                        selected_user_profile = props.selected_profile.username;
                        try {
                            await loadMediaMetaData(getUserId(), props.selected_profile.username);
                            await loadDocumentMetaData(getUserId(), props.selected_profile.username);
                            await loadLinkMetaData(props.selected_profile.conversation_type === 3 ? "" : getUserId(), props.selected_profile.username);
                            api_call_status = true;
                        } catch (e) {
                            console.log('ERROR', e.message);
                        }
                        setRerenderId(Date.now());
                    }


                }

            }

        }

        loadData();



    }, [props.selected_profile]);

    async function onScrollEnd(type) {
        type === "media" && await loadMediaMetaData(getUserId(), props.selected_profile.username);
        type === "documents" && await loadDocumentMetaData(getUserId(), props.selected_profile.username);
        type === "links" && await loadLinkMetaData(props.selected_profile.conversation_type === 3 ? "" : getUserId(), props.selected_profile.username);

        setRerenderId(Date.now());
    }

    function loadMediaMetaData(from, to) {
        return new Promise(function (resolve, reject) {
            fileService.getRecentFilesForConversation(props.selected_profile.conversation_type === 3 ? "" : from, to, Object.keys(media.media).length, 15, "MEDIA")
                .then(data => {
                    media.media = data.reduce((acc, file) => {
                        let strId = uuid();
                        file.url = `${fileMetaServiceUrl}/stream/media/${file.file_id}`;
                        file.sizeDisplay = (file.size) ? `${file.size} kb` : "";
                        file.createdAt = file.createdAt ? moment(file.createdAt).format('DD/MM/YYYY') : "";
                        file.uploaded_by = props.team && props.team[file.uploaded_by] ? `${props.team[file.uploaded_by].firstName} ${props.team[file.uploaded_by].lastName}` : file.uploaded_by;
                        acc[strId] = file;
                        return acc;
                    }, media.media);

                    resolve(true);
                }).catch(error => {
                    console.error("MediaPanel", "loadMediaMetaData", error);
                    logger.error("MediaPanel", "loadMediaMetaData", error.message);
                    resolve(true);
                });
        });
    }

    function loadDocumentMetaData(from, to) {
        return new Promise(function (resolve, reject) {
            fileService.getRecentFilesForConversation(props.selected_profile.conversation_type === 3 ? "" : from, to, Object.keys(media.documents).length, 15, "DOCUMENT")
                .then(data => {
                    media.documents = data.reduce((acc, file) => {
                        let strId = uuid();
                        file.url = `${fileMetaServiceUrl}/stream/file/${file.file_id}/${file.original_name}`;
                        file.sizeDisplay = (file.size) ? `${file.size} kb` : "";
                        file.createdAt = file.createdAt ? moment(file.createdAt).format('DD/MM/YYYY') : "";
                        file.uploaded_by = props.team && props.team[file.uploaded_by] ? `${props.team[file.uploaded_by].firstName} ${props.team[file.uploaded_by].lastName}` : file.uploaded_by;
                        acc[strId] = file;
                        return acc;
                    }, media.documents);

                    resolve(true);
                }).catch(error => {
                    console.error("MediaPanel", "getRecentFilesForConversation", error);
                    logger.error("MediaPanel", "getRecentFilesForConversation", error.message);
                    resolve(true);
                });
        });
    }

    function loadLinkMetaData(from, to) {
        return new Promise(function (resolve, reject) {
            fileService.getRecentLinksForConversation(from, to, Object.keys(media.links).length, 15)
                .then(data => {
                    media.links = data.reduce((acc, link) => {
                        let strId = uuid();
                        link.createdAt = link.createdAt ? moment(link.createdAt).format('DD/MM/YYYY') : "";
                        link.uploaded_by = props.team && props.team[link.uploaded_by] ? `${props.team[link.uploaded_by].firstName} ${props.team[link.uploaded_by].lastName}` : link.uploaded_by;
                        acc[strId] = link;
                        return acc;
                    }, media.links);
                    resolve(true);
                }).catch(error => {
                    console.error("MediaPanel", "getRecentLinksForConversation", error);
                    logger.error("MediaPanel", "getRecentLinksForConversation", error.message);
                    resolve(true);
                });
        });
    }

    function getCommonType(mimeType) {
        let commonType = "text";
        if (mimeType.startsWith("audio")) {
            commonType = "audio";
        } else if (mimeType.startsWith("video")) {
            commonType = "video";
        } else if (mimeType.startsWith("image")) {
            commonType = "image";
        } else if (mimeType.startsWith("application")) {
            commonType = "application";
        } else if (mimeType.includes("link")) {
            commonType = "link";
        }
        return commonType;
    }


    function onPressChatFilterIcon(_filter) {
        console.log('event fire...');
        console.log(_filter);
    }

    function viewGallery(file_id) {
        console.log("VIEW_GALLERY", file_id)
    };

    function selectThis(file_id, type) {
        console.log("SELECT_THIS", file_id);
        let item = media[type][file_id];
        item.isSelected = !item.isSelected;
        media[type] = {
            ...media[type],
            [file_id]: item
        }
        /* setMedia({
            ...media,
            [file_id]: item
        }); */
        if (item.isSelected) {
            selectedCount = selectedCount + 1;
            //setSelectedCount(selectedCount + 1);
        } else {
            selectedCount = selectedCount - 1;
            //setSelectedCount(selectedCount - 1);
        }
        setRerenderId(Date.now());
    }


    function getMedia() {
        let itemArray = [];
        Object.keys(media.media).map((key, index) => {
            let item = media.media[key];
            if (item.content_type.toLowerCase().startsWith('image')) {
                itemArray.push(
                    <MediaImage
                        src={`${fileMetaServiceUrl}/stream/thumbnail/${item.file_id}/225`}
                        viewGallery={() => openMediaGallery(key, "media")}
                        selectThis={() => selectThis(key, "media")}
                        active={item.isSelected} />
                );
            } else if (item.content_type.toLowerCase().startsWith('video')) {
                itemArray.push(
                    <div
                        className={`${item.url.startsWith('blob') || item.url.endsWith('_recorded') ? 'mirror-media' : ''}`}>
                        <MediaVideo src={item.url}
                            viewGallery={() => openMediaGallery(key, "media")}
                            selectThis={() => selectThis(key, "media")}
                            type={item.content_type}
                            active={item.isSelected} />
                    </div>
                );
            } else if (item.content_type.toLowerCase().startsWith('audio')) {
                itemArray.push(<MediaAudio viewGallery={() => openMediaGallery(key, "media")}
                    selectThis={() => selectThis(key, "media")}
                    active={item.isSelected} />);
            }
        });

        return itemArray.length > 0 ? itemArray : (api_call_status ? (<><GalleryEmptyPage
            notFoundMessage={'SORRY!!! Media Not found.'}
            type={'media'}
        /></>) : []);
    }

    function getDocuments() {
        let itemArray = [];
        Object.keys(media.documents).map((key, index) => {
            let item = media.documents[key];
            let fileExtension = mime.extension(item.content_type);
            if ((fileExtension === 'xlsx' || fileExtension === 'xls') && item.original_name && item.original_name.endsWith('.csv')) {
                fileExtension = 'csv';
            }
            itemArray.push(
                <div className={'doc-wrapper'}>
                    <MediaDocument
                        onClickOpenGallery={() => window.open(item.url, "_blank")}
                        name={item.uploaded_by}
                        fileType={fileExtension}
                        date={item.createdAt}
                        viewPointHeight={props.viewPointHeight}
                        fileName={item.original_name}
                        size={item.sizeDisplay}
                        active={item.isSelected}
                        url={item.url}
                        selectThis={() => selectThis(key, "documents")} />
                </div>
            );
        });
        return itemArray.length > 0 ? itemArray : (api_call_status ? (<><GalleryEmptyPage
            notFoundMessage={'SORRY!!! Document Not found.'}
            type={'doc'}
        /></>) : []);
    }

    function getLinks() {
        let itemArray = [];
        Object.keys(media.links).map((key, index) => {
            let item = media.links[key];
            itemArray.push(
                <div className={'doc-wrapper'}>
                    <MediaLinks
                        onClickOpenGallery={() => window.open(item.url, "_blank")}
                        name={item.uploaded_by}
                        fileType={'link'}
                        date={item.createdAt}
                        link={item.url}
                        linkString={getShortenedUrl(item.url)}
                        active={item.isSelected}
                        selectThis={() => selectThis(key, "links")} />
                </div>
            );
        });
        return itemArray.length > 0 ? itemArray : (api_call_status ? (<><GalleryEmptyPage
            notFoundMessage={'SORRY!!! Link Not found.'}
            type={'link'}
        /></>) : []);
    }

    function getShortenedUrl(url) {
        if (url.length > 30) {
            url = url.slice(0, 27);
            url = url + "..."
        }
        return url;
    }

    function onClickGoBack() {
        console.log("GO BACK");
        media = {
            media: {},
            documents: {},
            links: {}
        };
        selectedCount = 0;
        api_call_status = false;
        selected_user_profile = "";
        nextPageTimestamp = 0;
        let matchArr = loc.pathname.match(/^(.*?)\/profile/);
        if (matchArr && matchArr.length >= 1) {
            console.log(JSON.stringify(matchArr));
            history.push(loc.pathname.match(/^(.*?)\/profile/)[0]);
        }

        //props.onClickCloseInfoPanel();
    }

    function resetSelection() {

        Object.keys(media.media).forEach(key => {
            media.media[key].isSelected = false;
        });
        Object.keys(media.documents).forEach(key => {
            media.documents[key].isSelected = false;
        });
        Object.keys(media.links).forEach(key => {
            media.links[key].isSelected = false;
        });

        selectedCount = 0;
        setRerenderId(Date.now());
    }

    function onClickForward() {
        //create json for sending
        props.onClickForwardMessage((users) => {
            for (let user of users) {

                let toId = user.conversation_type === 3 ? user.Id : user.userId;

                for (const [key, item] of Object.entries(media.media)) {
                    if (item.isSelected) {


                        let uniqueId = uuid();

                        let msg = {
                            v: 1,
                            mid: uniqueId,
                            sid: uniqueId,
                            channel: "WEBCHAT",
                            message_type: item.content_type,
                            conversation_type: user.conversation_type,
                            from: {
                                id: auth.getUserId(),
                                name: auth.getDisplayName()
                            },
                            to: {
                                id: toId,
                                name: toId
                            },
                            created_at: Date.now(),
                            message_content: '',
                            other: null,
                            isUploading: "DONE"
                        };

                        msg.media = [{
                            created_at: msg.created_at,
                            content_url: item.url,
                            content_size: item.size ? parseInt(item.size) : 0,
                            content_title: item.original_name,
                            thumbnail_url: item.content_type.toLowerCase().startsWith('image') ? `${fileMetaServiceUrl}/stream/thumbnail/${item.file_id}/225` : null,
                            mimeType: item.content_type
                        }];

                        ChatService.sendChatMessage(user.conversation_type, "text/html", toId, msg, false, user.InteractionId);
                        props.chat_actions.sendNewMessageSuccess(msg);

                        if (user.userId === props.selected_profile.username) {
                            eventEmitter.fireEvent("scrollbar", { scrollbar_status: "down" });
                        }

                        let file_id = item.file_id;
                        /* if (item.content_type.toLowerCase().startsWith('image')) {
                            file_id = item.file_id;
                        } */
                        fileService.tagConversationToFile(user.conversation_type === 3 ? "" : msg.from.id, msg.to.id, getWorkspace(), file_id, item.url, item.content_type, item.size, item.original_name);


                    }
                }

                for (const [key, item] of Object.entries(media.documents)) {
                    if (item.isSelected) {

                        let uniqueId = uuid();

                        let msg = {
                            v: 1,
                            mid: uniqueId,
                            sid: uniqueId,
                            channel: "WEBCHAT",
                            message_type: item.content_type,
                            conversation_type: user.conversation_type,
                            from: {
                                id: auth.getUserId(),
                                name: auth.getDisplayName()
                            },
                            to: {
                                id: toId,
                                name: toId
                            },
                            created_at: Date.now(),
                            message_content: '',
                            other: null,
                            isUploading: "DONE"
                        };

                        msg.media = [{
                            created_at: msg.created_at,
                            content_url: item.url,
                            content_size: item.size ? parseInt(item.size) : 0,
                            content_title: item.original_name,
                            thumbnail_url: null,
                            mimeType: item.content_type
                        }];

                        ChatService.sendChatMessage(user.conversation_type, "text/html", toId, msg, false, user.InteractionId);
                        props.chat_actions.sendNewMessageSuccess(msg);

                        if (user.userId === props.selected_profile.username) {
                            eventEmitter.fireEvent("scrollbar", { scrollbar_status: "down" });
                        }

                        fileService.tagConversationToFile(user.conversation_type === 3 ? "" : msg.from.id, msg.to.id, getWorkspace(), item.file_id, item.url, item.content_type, item.size, item.original_name);
                    }
                }

                for (const [key, item] of Object.entries(media.links)) {
                    if (item.isSelected) {

                        let uniqueId = uuid();

                        let msg = {
                            v: 1,
                            mid: uniqueId,
                            sid: uniqueId,
                            channel: "WEBCHAT",
                            message_type: "html/link",
                            conversation_type: user.conversation_type,
                            from: {
                                id: auth.getUserId(),
                                name: auth.getDisplayName()
                            },
                            to: {
                                id: toId,
                                name: toId
                            },
                            created_at: Date.now(),
                            message_content: item.url,
                            other: null
                        };

                        msg.media = [{
                            content_url: item.url,
                            mimeType: "html/link"
                        }];

                        ChatService.sendChatMessage(user.conversation_type, "text/html", toId, msg, false, user.InteractionId);
                        props.chat_actions.sendNewMessageSuccess(msg);

                        if (user.userId === props.selected_profile.username) {
                            eventEmitter.fireEvent("scrollbar", { scrollbar_status: "down" });
                        }

                        fileService.saveLink(msg.conversation_type === 3 ? "" : msg.from.id, msg.to.id, msg.message_content, getWorkspace(), uniqueId);
                    }
                }
            }

            resetSelection();
        });
    }

    function onClickDownload() {
        console.log("DOWNLOAD_CLICKED");
        for (const [key, item] of Object.entries(media.media)) {
            if (item.isSelected) {
                let url = item.url;
                let filename = item.original_name ? item.original_name : "file";
                if (url && filename) {
                    fileService.directDownloadFile(filename, url);
                }
            }
        }

        for (const [key, item] of Object.entries(media.documents)) {
            if (item.isSelected) {
                let url = item.url;
                let filename = item.original_name ? item.original_name : "file";
                if (url && filename) {
                    fileService.directDownloadFile(filename, url);
                }
            }
        }

        resetSelection();
    }

    const openMediaGallery = (key, type) => {
        let mediaObj = media[type][key];
        if (mediaObj) {
            let converted = [{
                content_size: mediaObj.size,
                content_title: "",
                content_url: mediaObj.url,
                created_at: mediaObj.createdAt,
                mimeType: mediaObj.content_type,
                thumbnail_url: mediaObj.content_type.toLowerCase().startsWith('image') ? `${fileMetaServiceUrl}/stream/thumbnail/${mediaObj.file_id}/225` : ""
            }];
            props.onClickOpenGallery(converted);

        }

    }

    return (
        <>
            <div className={`chat-info-wrapper ${props.isOpenInfoPanel ? 'show-this' : 'hide-this'}`}>
                <div className={'ch-header-wrapper'}>
                    <div className={'media-action-panel back d-flex justify-content-between'}>
                        <MediaActionTab
                            selectedCount={selectedCount}
                            onClickForwardMessage={props.onClickForwardMessage}
                            onClickGoBack={onClickGoBack}
                            onClickCloseThis={onClickGoBack}
                            onClickForward={onClickForward}
                            onClickDownload={onClickDownload}
                            selectedTab={location[location.length - 1]}
                        />
                    </div>

                    <div className={'action-panel back'}>
                        <div className={'tab-panel d-flex justify-content-between'}>
                            {navigationRestrictions.media.recent_media.media_tab.active && <Link onClick={() => {
                                setLocation(['media'])
                            }}
                                to={`/console/${props.selected_profile ? props.selected_profile.username : 'default'}/profile/media/media`}>
                                <div
                                    className={`tab ${location[location.length - 1] === 'media' ? "active" : 'inactive'}`}>
                                    Media
                                </div>
                            </Link>}
                            {navigationRestrictions.media.recent_media.document_tab.active && <Link onClick={() => {
                                setLocation(['documents']);
                            }}
                                to={`/console/${props.selected_profile ? props.selected_profile.username : 'default'}/profile/media/documents`}>
                                <div
                                    className={`tab ${location[location.length - 1] === 'documents' ? "active" : 'inactive'}`}>
                                    Documents
                                </div>
                            </Link>}
                            {navigationRestrictions.media.recent_media.link_tab.active && <Link onClick={() => {
                                setLocation(['links'])
                            }}
                                to={`/console/${props.selected_profile ? props.selected_profile.username : 'default'}/profile/media/links`}>
                                <div
                                    className={`tab ${location[location.length - 1] === 'links' ? "active" : 'inactive'}`}>
                                    Links
                                </div>
                            </Link>}
                        </div>
                    </div>

                </div>
                <Switch>
                    {navigationRestrictions.media.recent_media.document_tab.active && <Route path="/console/:handle/profile/media/documents">
                        <Documents
                            onScrollEnd={onScrollEnd}
                            viewPointHeight={props.viewPointHeight}
                            onClickOpenGallery={props.onClickOpenGallery}
                            documents={getDocuments()}
                        />
                    </Route>}
                    {navigationRestrictions.media.recent_media.link_tab.active && <Route path="/console/:handle/profile/media/links">
                        <Links
                            onScrollEnd={onScrollEnd}
                            viewPointHeight={props.viewPointHeight}
                            onClickOpenGallery={props.onClickOpenGallery}
                            links={getLinks()}
                        />
                    </Route>}
                    {navigationRestrictions.media.recent_media.media_tab.active && <Route path="/console/:handle/profile/media/media">
                        <MediaFile
                            onScrollEnd={onScrollEnd}
                            viewPointHeight={props.viewPointHeight}
                            media={getMedia()}
                        />
                    </Route>}
                </Switch>
            </div>
        </>
    );
}

function mapStateToProps(state) {
    let mapState = {
        team: state.team,
        chats: state.chats,
        selected_profile: state.selected_profile
    };
    return mapState;
}

function mapDispatchToProps(dispatch) {
    return {
        chat_actions: bindActionCreators(chatActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(MediaPanel);
