import React, {Component} from "react";

class CountdownTimer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            count: props.timeLimit + 1,
            displayedTime: "00:00"
        };
    }

    componentDidMount() {
        this.setState({displayedTime: this.format(this.props.timeLimit)});
    }

    componentWillUnmount() {
        try {
            clearInterval(this.timer);
        } catch (ex) {
            console.error("CountdownTimer -componentWillUnmount ", ex);
        }
    }

    timer = setInterval(() => {
        const newCount = this.state.count - 1;
        this.setState({count: newCount >= 0 ? newCount : 0});
        this.format(this.state.count);
        if (newCount === 0) {
            clearInterval(this.timer);
            if (this.props.completed) {
                this.props.completed();
            }
        }
    }, 1000);

    format(time) {
        let seconds = time % 60;
        let minutes = Math.floor(time / 60);
        minutes = minutes.toString().length === 1 ? "0" + minutes : minutes;
        seconds = seconds.toString().length === 1 ? "0" + seconds : seconds;
        this.setState({displayedTime: minutes + ":" + seconds});
    }

    render() {
        return (
            <div>{this.state.displayedTime}</div>
        );
    }
}

export default CountdownTimer;
