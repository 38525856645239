import { Logger } from './logger.js';

class InteractionManager {
    isConferenceRequestSent;
    conferenceRequestTimeout;
    conferenceRequestId;

    isTransferRequestSent;
    TransferRequestTimeout;
    transferRequestId;

    isInteractionListProcessed;

    constructor () {
        this.isConferenceRequestSent = false;
        this.conferenceRequestTimeout = null;
        this.conferenceRequestId = "";
        this.isTransferRequestSent = false;
        this.TransferRequestTimeout = null;
        this.transferRequestId = "";
        this.isInteractionListProcessed = false;
    }

    SetConferenceRequestTimeout = (id, isRequestSent) => {
        this.conferenceRequestId = id;
        this.isConferenceRequestSent = isRequestSent;
        this.conferenceRequestTimeout = setTimeout(function () {
            Logger.error(`Interaction Conference Request Timeout reached`);
            interactionManager.conferenceRequestId = "";
            interactionManager.isConferenceRequestSent = false;
            clearTimeout(interactionManager.conferenceRequestTimeout);
        }, 15000);
    }

    SetTransferRequestTimeout = (id, isRequestSent) => {
        this.transferRequestId = id;
        this.isTransferRequestSent = isRequestSent;
        this.TransferRequestTimeout = setTimeout(function () {
            Logger.error(`Interaction Conference Request Timeout reached`);
            interactionManager.transferRequestId = "";
            interactionManager.isTransferRequestSent = false;
            clearTimeout(interactionManager.TransferRequestTimeout);
        }, 15000);
    }

    ProcessConferenceResponseAck = (responseObject) => {
        if (responseObject.RequestId === this.conferenceRequestId) {
            this.isConferenceRequestSent = false;
            clearTimeout(this.conferenceRequestTimeout);
            this.conferenceRequestId = "";
            return true;
        } else {
            Logger.error(`Conference Response received for unknown interaction: ${JSON.stringify(responseObject)}`);
            return false;
        }
    }

    ProcessTransferResponseAck = (responseObject) => {
        if (responseObject.RequestId === this.transferRequestId) {
            this.isTransferRequestSent = false;
            clearTimeout(this.TransferRequestTimeout);
            this.transferRequestId = "";
            return true;
        } else {
            Logger.error(`Transfer Response received for unknown interaction: ${JSON.stringify(responseObject)}`);
            return false;
        }
    }

    ProcessTransferSuccess = (obj, livechatRef) => {
        try {
            var loggedInUser = livechatRef.session.userid;
            var isUserFound = false;
            if (obj.InteractionInformation != null) {
                if (obj.InteractionInformation.UserInformation != null) {
                    obj.InteractionInformation.UserInformation.every(user => {
                        if (user.Id === loggedInUser) {
                            isUserFound = true;
                            livechatRef.setInteractionId(obj.InteractionId, obj.InteractionInformation.CustomerInfo);
                        }

                    });

                    if (isUserFound === false) {
                        livechatRef.deleteInteractionId(obj.InteractionId);
                    }
                }
            }
        } catch (e) {
            Logger.error(`Error in ProcessTransferSuccess`, e);
        }
    }

    ProcessConferenceSuccess = (obj, livechatRef) => {
        try {
            var loggedInUser = livechatRef.session.userid;
            var isUserFound = false;
            if (obj.InteractionInformation != null) {
                if (obj.InteractionInformation.UserInformation != null) {
                    obj.InteractionInformation.UserInformation.every(user => {
                        if (user.Id === loggedInUser) {
                            isUserFound = true;
                            if (user.ConferenceInformation.IsCall === false) {
                                livechatRef.setInteractionId(obj.InteractionId, obj.InteractionInformation.CustomerInfo);
                            }
                        }

                    });

                    if (isUserFound) {
                        livechatRef.setInteractionId(obj.InteractionId, obj.InteractionInformation.CustomerInfo);
                    }
                    else {
                        livechatRef.deleteInteractionId(obj.InteractionId);
                    }
                }
            }
        } catch (e) {
            Logger.error(`Error in ProcessConferenceSuccess`, e);
        }
    }

    ProcessInteractionEnd = (obj, livechatRef) => {
        try {
            if (obj.InteractionId != null)
                livechatRef.deleteInteractionId(obj.InteractionId);
            else
                Logger.error(`InteractionId received is null/undefined`);
        } catch (e) {
            Logger.error(`Error in ProcessInteractionEnd`, e);
        }
    }

    ProcessUserInteractionList = (obj, livechatRef) => {
        try {
            if (this.isInteractionListProcessed === false) {
                var loggedInUser = livechatRef.session.userid;
                if (obj != null) {
                    obj.forEach(interaction => {
                        if (interaction.UserId === loggedInUser) {
                            if (interaction.InteractionListInformation != null) {
                                interaction.InteractionListInformation.forEach(interactionInfo => {
                                    if (interactionInfo.UserInformation != null)
                                        interactionInfo.UserInformation.some(userInformation => {
                                            if (userInformation.Id === loggedInUser) {
                                                if (userInformation.ConferenceInformation.IsCall === false)
                                                    livechatRef.setInteractionId(interactionInfo.InteractionId, interactionInfo.CustomerInfo);
                                                return true;
                                            }
                                            else
                                                return false;
                                        });
                                });
                            }
                        }
                    });
                }
                this.isInteractionListProcessed = true;
            }
        } catch (e) {
            Logger.error(`Error in ProcessUserInteractionList`, e);
        }
    }

    ProcessInteractionStart = (obj, livechatRef) => {
        try {
            if (this.isInteractionListProcessed === false) {
                var loggedInUser = livechatRef.session.userid;
                if (obj != null) {
                    if (obj.UserId === loggedInUser) {
                        if (obj.InteractionListInformation != null) {
                            obj.InteractionListInformation.forEach(interactionInfo => {
                                if (interactionInfo.UserInformation != null)
                                    interactionInfo.UserInformation.some(userInformation => {
                                        if (userInformation.Id === loggedInUser) {
                                            if (userInformation.ConferenceInformation.IsCall === false)
                                                livechatRef.setInteractionId(interactionInfo.InteractionId, interactionInfo.CustomerInfo);
                                            return true;
                                        }
                                        else
                                            return false;
                                    });
                            });
                        }
                    }
                }
            }
        } catch (e) {
            Logger.error(`Error in ProcessInteractionStart`, e);
        }
    }
}
var interactionManager = new InteractionManager();

class InteractionConferenceModel {
    RequestId;
    UserId;
    RequestorUserId;
    InteractionId;
    IsChat;
    IsCall;
    InteractionInformation;

    constructor () {
        this.RequestId = "";
        this.UserId = "";
        this.RequestorUserId = "";
        this.InteractionId = "";
        this.IsChat = false;
        this.IsCall = false;
        this.InteractionInformation = {};
    }
}

class InteractionConferenceResponseModel extends InteractionConferenceModel {
    isAccepted;
    constructor () {
        super();
        this.isAccepted = false;
    }
}

class InteractionConferenceResponseObject extends InteractionConferenceModel {
    livechatReference;

    responseTimeout;

    constructor (requestData, livechat) {
        super();
        this.RequestId = requestData.RequestId;
        this.UserId = requestData.UserId;
        this.RequestorUserId = requestData.RequestorUserId;
        this.InteractionId = requestData.InteractionId;
        this.IsCall = requestData.IsCall;
        this.IsChat = requestData.IsChat;
        this.livechatReference = livechat;
        this.InteractionInformation = requestData.InteractionInformation;

        this.responseTimeout = setTimeout(function () {
            this.RequestId = "";
            this.UserId = "";
            this.RequestorUserId = "";
            this.InteractionId = "";
            this.IsCall = false;
            this.IsChat = false;
            this.livechatReference = null;
            this.InteractionInformation = {};
            Logger.error(`Conference Response Object timed out`)
        }, 12000);
    }

    accept = function () {
        if (this.RequestId !== "" && this.UserId !== "" && this.RequestorUserId !== "" && this.InteractionId !== "") {
            var responseModel = new InteractionConferenceResponseModel();
            responseModel.RequestId = this.RequestId;
            responseModel.UserId = this.UserId;
            responseModel.RequestorUserId = this.RequestorUserId;
            responseModel.InteractionId = this.InteractionId;
            responseModel.IsCall = this.IsCall;
            responseModel.IsChat = this.IsChat;
            responseModel.isAccepted = true;
            responseModel.InteractionInformation = this.InteractionInformation;

            this.livechatReference.sendLv2Message("interaction-conference-response", JSON.stringify(responseModel), "service:GMS");

            clearTimeout(this.responseTimeout);
        }
        else
            Logger.error(`Unable to send conference response, as response timeout has occurred`);
    }

    reject = function () {
        if (this.RequestId !== "" && this.UserId !== "" && this.RequestorUserId !== "" && this.InteractionId !== "") {
            var responseModel = new InteractionConferenceResponseModel();
            responseModel.RequestId = this.RequestId;
            responseModel.UserId = this.UserId;
            responseModel.RequestorUserId = this.RequestorUserId;
            responseModel.InteractionId = this.InteractionId;
            responseModel.isCall = this.isCall;
            responseModel.isChat = this.isChat;
            responseModel.isAccepted = false;
            responseModel.InteractionInformation = this.InteractionInformation;

            this.livechatReference.sendLv2Message("interaction-conference-response", JSON.stringify(responseModel), "service:GMS");

            clearTimeout(this.responseTimeout);
        }
        else
            Logger.error("Unable to send conference response, as response timeout has occurred");
    }
}

class InteractionTransferModel {
    RequestId;
    UserId;
    RequestorUserId;
    InteractionId;
    InteractionInformation;

    constructor () {
        this.RequestId = "";
        this.UserId = "";
        this.RequestorUserId = "";
        this.InteractionId = "";
        this.CustomerInfo = "";
        this.CustomerUserId = "";
        this.InteractionInformation = {};
    }
}

class InteractionTransferResponseModel extends InteractionTransferModel {
    isAccepted;
    constructor () {
        super();
        this.isAccepted = false;
    }
}

class InteractionTransferResponseObject extends InteractionTransferModel {
    livechatReference;
    responseTimeout;

    constructor (requestData, livechat) {
        super();
        this.RequestId = requestData.RequestId;
        this.UserId = requestData.UserId;
        this.RequestorUserId = requestData.RequestorUserId;
        this.InteractionId = requestData.InteractionId;
        this.livechatReference = livechat;
        this.InteractionInformation = requestData.InteractionInformation;

        this.responseTimeout = setTimeout(() => {
            this.RequestId = "";
            this.UserId = "";
            this.RequestorUserId = "";
            this.InteractionId = "";
            this.livechatReference = null;
            this.InteractionInformation = {};
            Logger.error(`Transfer Response Object timed out`)
        }, 12000);
    }

    accept = () => {
        if (this.RequestId !== "" && this.UserId !== "" && this.RequestorUserId !== "" && this.InteractionId !== "") {
            var responseModel = new InteractionTransferResponseModel();
            responseModel.RequestId = this.RequestId;
            responseModel.UserId = this.UserId;
            responseModel.RequestorUserId = this.RequestorUserId;
            responseModel.InteractionId = this.InteractionId;
            responseModel.isAccepted = true;
            responseModel.InteractionInformation = this.InteractionInformation;

            this.livechatReference.sendLv2Message("interaction-transfer-response", JSON.stringify(responseModel), "service:GMS");

            clearTimeout(this.responseTimeout);
        }
        else
            Logger.error(`Unable to send transfer response, as response timeout has occurred`);
    }

    reject = () => {
        if (this.RequestId !== "" && this.UserId !== "" && this.RequestorUserId !== "" && this.InteractionId !== "") {
            var responseModel = new InteractionTransferResponseModel();
            responseModel.RequestId = this.RequestId;
            responseModel.UserId = this.UserId;
            responseModel.RequestorUserId = this.RequestorUserId;
            responseModel.InteractionId = this.InteractionId;
            responseModel.isAccepted = false;
            responseModel.InteractionInformation = this.InteractionInformation;

            this.livechatReference.sendLv2Message("interaction-transfer-response", JSON.stringify(responseModel), "service:GMS");

            clearTimeout(this.responseTimeout);
        }
        else
            Logger.error(`Unable to send transfer response, as response timeout has occurred`);
    }
}

class InteractionEndModel {
    interactionId;
    reason;
    customData;
    userId;
    constructor () {
        this.interactionId = "";
        this.reason = "";
        this.customData = {};
        this.userId = "";
    }
}

class InteractionActionRequestModel {
    InteractionId;
    Action;
    RequestorUserId;
    constructor () {
        this.RequestorUserId = "";
        this.Action = "";
        this.InteractionId = {};
    }
}

export {
    interactionManager,
    InteractionConferenceModel, InteractionConferenceResponseObject,
    InteractionTransferModel, InteractionTransferResponseObject,
    InteractionEndModel, InteractionActionRequestModel
};