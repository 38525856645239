import * as ChatService from "./chatService";
import { __APP_TYPING_RECEIVE_TIMEOUT__ } from "../config/baseUrls";

let lastKeyPressTime = null;
let lastTypingEventReceived = null;
let typingEventData = {};


export const setReceivedTypingEvent = (handleId) => {

    typingEventData[handleId] = Date.now();
}

export const deleteReceivedTypingEvent = (handleId) => {

    delete typingEventData[handleId];
}

export const checkLastTypingEvent = (handleId) => {

    let curTime = Date.now();
    let prevTime = typingEventData[handleId];

    if (prevTime && curTime - prevTime >= __APP_TYPING_RECEIVE_TIMEOUT__) {
        delete typingEventData[handleId];
        return true;

    } else {
        return false;
    }
}

export const setKeyPressTime = (converstationType, from, to, interactionId) => {

    if (from && to) {
        if (!lastKeyPressTime) {
            ChatService.sendTypingEvent(converstationType, from, to, "START", interactionId);
            lastKeyPressTime = Date.now();
        } else {
            if (Date.now() - lastKeyPressTime > 3000) {
                ChatService.sendTypingEvent(converstationType, from, to, "START", interactionId);
                lastKeyPressTime = Date.now();

            }
        }

    }

}

export const getLastTypingEventTime = () => {
    return lastTypingEventReceived;
}

export const setLastTypingEvent = () => {
    lastTypingEventReceived = Date.now();
}

export const resetLastTypingEvent = () => {
    lastTypingEventReceived = null;
}

export const hideTyping = () => {
    if (!lastTypingEventReceived || (lastTypingEventReceived && Date.now() - lastTypingEventReceived > 5000)) {
        return true;
    } else {
        return false;
    }
}

export const isTypingStopped = () => {
    if (lastKeyPressTime && Date.now() - lastKeyPressTime > 4000) {
        return true;
    } else {
        return false;
    }
}

export const resetKeyPressTime = (converstationType, from, to, interactionId, isACW) => {
    lastKeyPressTime = null;
    if (!isACW) {
        ChatService.sendTypingEvent(converstationType, from, to, "STOP", interactionId);
    }

    //send typing stop
}


export const setLastReceivedTypingEvent = () => {
    lastTypingEventReceived = Date.now();
}